<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司编码">
          <el-input v-model="queryForm.orgCode"  placeholder="分公司编码" />
        </el-form-item>
        <el-form-item label="分公司名称">
          <el-input v-model="queryForm.orgName"  placeholder="分公司名称" />
        </el-form-item>
        <el-form-item label="配送中心编码">
          <el-input v-model="queryForm.dcCode"  placeholder="配送中心编码" />
        </el-form-item>
        <el-form-item label="配送中心名称">
          <el-input v-model="queryForm.dcName"  placeholder="配送中心名称" />
        </el-form-item>
        <el-form-item label="终端编码">
          <el-input v-model="queryForm.shopCode" placeholder="终端编码" />
        </el-form-item>
        <el-form-item label="终端名称">
          <el-input v-model="queryForm.shopName"  placeholder="终端名称" />
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input v-model="queryForm.skuCode"  placeholder="商品编码" />
        </el-form-item>
        <el-form-item label="商品型号">
          <el-input v-model="queryForm.skuModel"  placeholder="商品型号" />
        </el-form-item>
        <el-form-item label="整单结案状态">
          <el-select v-model="queryForm.jaStatus">
            <el-option v-for="item in options.orderCaseStatusOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="整单结案原因">
          <el-select v-model="queryForm.jaReason">
            <el-option v-for="item in options.orderCaseReasonQueryOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="queryForm.orderStatus">
            <el-option v-for="item in options.orderStatusOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="订单号">
          <el-input v-model="queryForm.orderNo" size="mini" placeholder="订单号" />
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="queryForm.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 350px !important;"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openFh">批量发货</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box=card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openFh">批量发货</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
      v-loading="loading"
      class="customer-table"
      border
           size="small"
      ref="table"
      highlight-current-row
    >
      <el-table-column label="订单状态" prop="orderStatus"  width="120"/>
      <el-table-column label="下单时间" prop="createTime" width="200"  />
      <el-table-column label="订单号" prop="orderNo" width="200" />
      <el-table-column label="订单金额" prop="orderMoney"  width="120"  />
      <el-table-column label="整单结案状态" prop="jaStatus"   width="140"/>
      <el-table-column label="整单结案原因" prop="reason"  width="140" />
      <el-table-column label="备注" prop="remark" width="200"  />
      <el-table-column label="终端编码" prop="shopCode" width="120" />
      <el-table-column label="终端名称" prop="shopName"  width="140" />
      <el-table-column label="下单人" prop="createUser"   width="140"/>
      <el-table-column label="下单人手机号" prop="phoneNumber" width="140" />
      <el-table-column label="配送中心编码" prop="dcCode"  width="140" />
      <el-table-column label="配送中心名称" prop="dcName"  width="140" />
      <el-table-column label="分公司编码" prop="orgCode" width="140"/>
      <el-table-column label="分公司名称" prop="orgName"  width="140"/>
      <el-table-column label="操作" width="120" fixed="right" align="center">
        <template #default="{ row }">
          <div>
            <el-row>
              <el-col :span="6"><el-button size="mini" type="text" style="border: 0" @click="openDetail(row)">查看明细</el-button></el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="订单明细" :visible.sync="skuDetail.visible" append-to-body width="1000px" :close-on-click-modal="false" max-height="400px" @close='handleCancel'>
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="skuDetail.queryForm" @keyup.enter.native="handleQuerySkuDetail">
        <el-form-item label="商品编码">
          <el-input v-model="skuDetail.queryForm.skuCode" size="mini" clearable placeholder="输入商品编码" />
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="skuDetail.queryForm.skuName" size="mini" clearable placeholder="输入商品名称" />
        </el-form-item>
        <el-form-item label="单品结案状态">
          <el-select v-model="skuDetail.queryForm.jaStatus">
            <el-option v-for="item in options.orderCaseStatusOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuerySkuDetail">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-circle-check" type="danger" v-if="skuDetail.operationVisible" @click="openCloseCase('batchSingle', null)">批量单品结案</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-success" type="danger" v-if="skuDetail.operationVisible" @click="openCloseCase('order', null)">整单结案</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="skuDetail.pageData.rows"
                v-loading="skuDetail.loading"
                size="mini"
                height="300px"
                @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="30"/>
        <el-table-column label="商品编码" prop="skuCode" width="100" align="center" />
        <el-table-column label="属性" prop="attributeName" width="60" align="center" />
        <el-table-column label="商品型号" prop="skuModel" width="80" align="center" />
        <el-table-column label="商品名" prop="skuName" min-width="200" align="center" />
        <el-table-column label="单价" prop="finalPrice" width="40" align="center" />
        <el-table-column label="下单数量" prop="orderNum" width="80" align="center" />
        <el-table-column label="发货数量" prop="fhNum" width="80" align="center" />
        <el-table-column label="未发数量" prop="wfNum" width="80" align="center">
          <template #default="{ row }">
            <span>{{row.orderNum - row.fhNum}}</span>
          </template>
        </el-table-column>
        <el-table-column label="结案状态" prop="jaStatus" width="80" align="center" />
        <el-table-column label="单品结案原因" prop="reason" width="150" align="center" />
        <el-table-column label="操作" width="60" fixed="right">
          <template #default="{ row }">
            <el-button v-if="row.jaStatus === '未结案' && skuDetail.operationVisible" size="mini" type="text" style="border: 0" @click="openCloseCase('single', row)">单品结案</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="skuDetail.pageData.total" :page.sync="skuDetail.queryForm.page" :limit.sync="skuDetail.queryForm.limit" @pagination="handleQuerySkuDetail" />
    </el-dialog>

    <el-dialog :title="batchFh.importBaseParams.title" :visible.sync="batchFh.importBaseParams.visible" append-to-body width="1000px">
      <el-row>
        <el-col :span="5" align="right" style="font-weight: bold">文件：</el-col>
        <el-col :span="10">
          <el-upload
            ref="upload"
            class="upload-demo"
            drag
            :limit="1"
            action="#"
            :auto-upload="false"
            :file-list="batchFh.importBaseParams.fileList"
            :on-change="(file, fileList) => handleUploadFileChange(file, fileList, 'change')"
            :on-remove="(file, fileList) => handleUploadFileChange(file, fileList, 'remove')"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              <span>仅允许导入xls、xlsx格式文件。</span>
              <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="batchFh.importBaseParams.templateFileDownloadUrl">下载模板</el-link>
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="15" :offset="5" align="left">
          <el-link type="danger" :underline="false">说明：导入成功后，将新增一条发货记录，仅导入本次发货的数量</el-link>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px; margin-bottom: 20px">
        <el-col :span="10" :offset="5" align="left">
          <el-button size="mini" type="success" @click="handleImportFh">导入</el-button>
          <el-button size="mini" type="info" @click="batchFh.importBaseParams.visible = false">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="选择结案原因" :visible.sync="orderClose.visible" append-to-body width="400px">
      <el-radio-group v-model="orderClose.form.jaReason">
          <el-radio class="select-radio" :label="item.label" :key="item.label" v-for="item in options.orderCaseReasonOptions.filter(i => i.value !== undefined)">
              {{ item.label }} <el-input v-if="item.value === '其他'" v-model="orderClose.form.otherReason" size="mini" style="width: 200px; margin-left: 10px"/>
          </el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="orderClose.visible = false">取消</el-button>
        <el-button type="danger" size="mini" @click="handleCloseCase">确定结案</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index.vue";
import {
  topShopDict,
  getNoStockOrder,
  exportNoStockOrder,
  getNoStockOrderDetail,
  noStockOrderClose,
  noStockOrderItemClose,
  uploadNoStockOrderItemFh,
  downloadNoStockOrderItemFh,
} from "@/api/appTask";
import SearchFilter from "@/components/SearchFilter/index";
import {Notification} from "element-ui";

export default {
  name: "noStockOrder",
  components: {Pagination, SearchFilter},
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      options: {
        orderStatusOptions: [{value: undefined, label: '全部'},{value: 1, label: '待发货'},{value: 2, label: '已部分发货'},{value: 3, label: '已全部发货'},{value: 8, label: '已取消'}],
        orderCaseStatusOptions: [{value: undefined, label: '全部'},{value: 1, label: '已结案'},{value: 0, label: '未结案'}],
        orderCaseReasonOptions: [{value: undefined, label: '全部'},{value: '无库存，进不到货', label: '无库存，进不到货'},{value: '下错单', label: '下错单'},{value: '已换货', label: '已换货'},{value: '无库存，客户不要了', label: '无库存，客户不要了'},{value: '其他', label: '其他'}],
        orderCaseReasonQueryOptions: [{value: undefined, label: '全部'},{value: '单品全部结案', label: '单品全部结案'},{value: '无库存，进不到货', label: '无库存，进不到货'},{value: '下错单', label: '下错单'},{value: '已换货', label: '已换货'},{value: '无库存，客户不要了', label: '无库存，客户不要了'},{value: '其他', label: '其他'}],
      },
      queryForm: {
        current: 1,
        size: 100,
        orderStatus: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        orderNo: undefined,
        orgCode: undefined,
        orgName: undefined,
        dcCode: undefined,
        dcName: undefined,
        shopCode: undefined,
        shopName: undefined,
        skuCode: undefined,
        skuModel: undefined,
        timeRange: [],
        jaStatus: '未结案',
        jaReason: '',
      },
      skuDetail: {
        loading: false,
        visible: false,
        operationVisible: true,
        pageData: {
          total: 0,
          rows: [],
          selections: [],
        },
        queryForm: {
          current: 1,
          size: 10,
          orderNo: undefined,
          skuCode: undefined,
          skuName: undefined,
          jaStatus: '',
        }
      },
      orderClose: {
        visible: false,
        type: undefined,
        param: undefined,
        form: {
          orderNo: undefined,
          jaReason: undefined,
          otherReason: undefined,
        }
      },
      loading: false,
      dictParam: {},
      dictFun: topShopDict,
      orgAllAuth: false,
      batchFh: {
        importBaseParams: {
          visible: false,
          title: "批量发货",
          type: "batchFh",
          apiParams: "importList",
          fileList: [],
          accept: ['.xls', '.xlsx'],
          previewDataLength: 10,
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E6%97%A0%E5%BA%93%E5%AD%98%E4%B8%8B%E5%8D%95%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%8F%91%E8%B4%A7%E4%BF%A1%E6%81%AF.xlsx',
          // templateSampleHead: [
          //   { label: "配送中心编码", prop: "dcCode", minWidth: "80px" },
          //   { label: "是否支持无库存下单", prop: "supportNoStock", minWidth: "80px" },
          //   { label: "商品范围", prop: "itemRange", minWidth: "80px" },
          // ],
          // templateSampleLists: [
          //   {
          //     dcCode: '001',
          //     supportNoStock: '支持',
          //     itemRange: '全部无库存商品',
          //   },
          //   {
          //     dcCode: '002',
          //     supportNoStock: '不支持',
          //     itemRange: '清单中的无库存商品',
          //   },
          // ],
        },
      },
    };
  },
  computed: {
    timeDefault () {
      let date = new Date()
      // 通过时间戳计算
      let defalutStartTime = date.getTime() - 7 * 24 * 3600 * 1000 // 转化为时间戳
      let defalutEndTime = date.getTime()
      let startDateNs = new Date(defalutStartTime)
      let endDateNs = new Date(defalutEndTime)
      // 月，日 不够10补0
      defalutStartTime = startDateNs.getFullYear() + '-' + ((startDateNs.getMonth() + 1) >= 10 ? (startDateNs.getMonth() + 1) : '0' + (startDateNs.getMonth() + 1)) + '-' + (startDateNs.getDate() >= 10 ? startDateNs.getDate() : '0' + startDateNs.getDate())
      defalutEndTime = endDateNs.getFullYear() + '-' + ((endDateNs.getMonth() + 1) >= 10 ? (endDateNs.getMonth() + 1) : '0' + (endDateNs.getMonth() + 1)) + '-' + (endDateNs.getDate() >= 10 ? endDateNs.getDate() : '0' + endDateNs.getDate())
      defalutStartTime = defalutStartTime + ' 00:00:00'
      defalutEndTime = defalutEndTime + ' 23:59:59'
      return [defalutStartTime, defalutEndTime]
    }
  },

  watch: {
  },
  created() {
    this.queryForm.timeRange = this.timeDefault
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      if (this.queryForm.timeRange) {
        if (this.queryForm.timeRange.length > 0) {
          this.queryForm.createTimeBegin = this.queryForm.timeRange[0];
        }
        if (this.queryForm.timeRange.length > 1) {
          this.queryForm.createTimeEnd = this.queryForm.timeRange[1];
        }
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
      getNoStockOrder(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
        setTimeout(() => this.loading = false, 50)
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleCancel() {
      this.handleFilter()
    },
    resetCloseOrder() {
      this.orderClose.type = undefined;
      this.orderClose.param = undefined;
      this.orderClose.form = {
        orderNo: undefined,
        jaReason: undefined,
        otherReason: undefined,
      }
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 100,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.skuDetail.pageData.selections = selections;
    },
    handleExport() {
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportNoStockOrder(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', `无库存下单订单记录.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    openFh() {
      this.batchFh.importBaseParams.visible = true;
    },
    handleImportFh() {
      if (this.batchFh.importBaseParams.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.batchFh.importBaseParams.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      uploadNoStockOrderItemFh(fd).then(res => {
        if (res.code === 200) {
          Notification.success('批量发货成功！');
        } else {
          downloadNoStockOrderItemFh(fd).then(res => {
            this.loading = false;
            if (!res) {
              return
            }
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = window.URL.createObjectURL(new Blob([res]))
            link.setAttribute('download', `无库存订单发货失败记录.xlsx`)
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
        }
        this.$refs.upload.clearFiles()
      })
      setTimeout(() => {this.handleFilter();}, 1000);
      this.batchFh.importBaseParams.visible = false;
    },
    openDetail(row) {
      this.skuDetail.queryForm.orderNo = row.orderNo;
      this.skuDetail.operationVisible = row.orderStatus !== '已取消';
      this.handleFilterSkuDetail();
      this.skuDetail.visible = true;
    },
    handleQuerySkuDetail() {
      this.skuDetail.loading = true;
      getNoStockOrderDetail(this.skuDetail.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.skuDetail.pageData.rows = res.data?.records;
        this.skuDetail.pageData.total = res.data?.total;
        setTimeout(() => this.skuDetail.loading = false, 50)
      });
    },
    handleFilterSkuDetail() {
      this.skuDetail.queryForm.current = 1;
      this.handleQuerySkuDetail();
    },
    openCloseCase(type, param) {
      if (type === 'batchSingle' && (!this.skuDetail.pageData.selections || this.skuDetail.pageData.selections.length === 0)) {
        Notification.warning("请选择要操作的行！");
        return;
      }
      this.orderClose.visible = true;
      this.orderClose.type = type;
      this.orderClose.param = param;
      this.orderClose.form.orderNo = this.skuDetail.queryForm.orderNo;
      this.orderClose.form.jaReason = '无库存，进不到货';
      this.orderClose.form.otherReason = '';
    },
    handleCloseCase() {
      if (!this.orderClose.form.jaReason) {
        Notification.warning("请选择结案原因！");
        return;
      }
      if (this.orderClose.form.jaReason === '其他' && (!this.orderClose.form.otherReason || this.orderClose.form.otherReason.trim() === '')) {
        Notification.warning("请填写其他结案原因！")
        return;
      }
      if (this.orderClose.type === 'order') {
        noStockOrderClose(this.orderClose.form).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilterSkuDetail();
          }
        });
      } else if (this.orderClose.type === 'single') {
        const data = [];
        data.push({
          id: this.orderClose.param.id,
          orderNo: this.orderClose.form.orderNo,
          dcSaleItemId: this.orderClose.param.dcSaleItemId,
          skuCode: this.orderClose.param.skuCode,
          reason: this.orderClose.form.jaReason,
          otherReason: this.orderClose.form.otherReason,
        })
        noStockOrderItemClose(data).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilterSkuDetail();
          }
        });
      } else if (this.orderClose.type === 'batchSingle') {
        const data = [];
        this.skuDetail.pageData.selections.forEach(item => {
          data.push({
            id: item.id,
            orderNo: this.orderClose.form.orderNo,
            dcSaleItemId: item.dcSaleItemId,
            skuCode: item.skuCode,
            reason: this.orderClose.form.jaReason,
            otherReason: this.orderClose.form.otherReason,
          })
        })
        noStockOrderItemClose(data).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilterSkuDetail();
          }
        });
      }
      this.orderClose.visible = false;
      this.resetCloseOrder();
    },
    handleUploadFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.batchFh.importBaseParams.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.batchFh.importBaseParams.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.batchFh.importBaseParams.fileList = [];
      } else {
        this.batchFh.importBaseParams.fileList = fileList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}

.select-radio {
  display: block;
  line-height: 23px;
  white-space: normal;
  margin-right: 0;
}
</style>
