var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入帖子内容关键字",
                        },
                        model: {
                          value: _vm.queryForm.experienceContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "experienceContent", $$v)
                          },
                          expression: "queryForm.experienceContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "话题所属分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.authOrgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "authOrgCode", $$v)
                                },
                                expression: "queryForm.authOrgCode",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入用户编码或姓名",
                        },
                        model: {
                          value: _vm.queryForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "userName", $$v)
                          },
                          expression: "queryForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "帖子状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.experienceStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "experienceStatus", $$v)
                            },
                            expression: "queryForm.experienceStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "ALL" },
                          }),
                          _c("el-option", {
                            attrs: { label: "屏蔽", value: "shield" },
                          }),
                          _c("el-option", {
                            attrs: { label: "加精", value: "essence" },
                          }),
                          _c("el-option", {
                            attrs: { label: "置顶", value: "sticky" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "帖子类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.experienceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "experienceType", $$v)
                            },
                            expression: "queryForm.experienceType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "ALL" },
                          }),
                          _c("el-option", {
                            attrs: { label: "帖子", value: "experience" },
                          }),
                          _c("el-option", {
                            attrs: { label: "评论", value: "answer" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属话题" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.queryForm.topicName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "topicName", $$v)
                            },
                            expression: "queryForm.topicName",
                          },
                        },
                        _vm._l(_vm.topicNameOptions, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { value: item, label: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.itemType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "itemType", $$v)
                            },
                            expression: "queryForm.itemType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "ALL" },
                          }),
                          _c("el-option", {
                            attrs: { label: "晨光商品", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "非晨光商品", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否显示到看板" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.showInBoard,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "showInBoard", $$v)
                            },
                            expression: "queryForm.showInBoard",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: undefined },
                          }),
                          _c("el-option", {
                            attrs: { label: "显示", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "不显示", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户角色" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.feedbackUserType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "feedbackUserType", $$v)
                            },
                            expression: "queryForm.feedbackUserType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: undefined },
                          }),
                          _c("el-option", {
                            attrs: { label: "巡店员", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "终端", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExperienceListExport },
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-upload", type: "primary" },
              on: { click: _vm.openExperienceListImport },
            },
            [_vm._v("商品反馈帖子导入")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "帖子内容",
                  prop: "experienceContent",
                  width: "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _c("span", [_vm._v(_vm._s(row.experienceContent))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图片/视频", width: "500", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "left" } },
                          _vm._l(row.images, function (item) {
                            return _c("el-image", {
                              key: item,
                              staticStyle: { height: "80px", margin: "5px" },
                              attrs: {
                                "preview-src-list": [item],
                                src: item,
                                lazy: "",
                              },
                            })
                          }),
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "left" } },
                          _vm._l(row.videos, function (item) {
                            return _c("video", {
                              key: item,
                              staticStyle: { margin: "5px" },
                              attrs: {
                                src: item,
                                width: "200px",
                                height: "160px",
                                controls: "",
                              },
                            })
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品信息", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _vm._l(row.modelNames, function (item) {
                        return _c(
                          "div",
                          { key: item, staticStyle: { "text-align": "left" } },
                          [_c("span", [_vm._v("商品：" + _vm._s(item))])]
                        )
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "nickName", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgAuthName", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "所属话题", prop: "topicName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.experienceStatus !== "B"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExperienceShield("B", row)
                                  },
                                },
                              },
                              [_vm._v("屏蔽")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.experienceStatus === "B"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExperienceShield("Q", row)
                                  },
                                },
                              },
                              [_vm._v("已屏蔽")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.essenceFlag === "1" &&
                        row.experienceType === "experience"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExperienceEssenceFlag(
                                      0,
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("已加精")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.essenceFlag === "0" &&
                        row.experienceType === "experience"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExperienceEssenceFlag(
                                      1,
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("加精")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.stickyFlag === "1" &&
                        row.experienceType === "experience"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExperienceStickyFlag(
                                      0,
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("已置顶")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.stickyFlag === "0" &&
                        row.experienceType === "experience"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleExperienceStickyFlag(
                                      1,
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("置顶")]
                            )
                          : _vm._e(),
                        row.showInBoard === 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showExperienceShowInBoard(row)
                                  },
                                },
                              },
                              [_vm._v("显示到看板")]
                            )
                          : _vm._e(),
                        row.showInBoard === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.disableExperienceShowInBoard(row)
                                  },
                                },
                              },
                              [_vm._v("不显示到看板")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "更新时间", prop: "createTime", width: "140" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "类型",
                  prop: "experienceTypeName",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "浏览量", prop: "viewCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "评论", prop: "answerCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "点赞", prop: "likeCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "收藏", prop: "collectCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "帖子ID", prop: "experienceId", width: "230" },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品反馈帖子导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-top": "10px" },
              model: {
                value: _vm.importDialog.importType,
                callback: function ($$v) {
                  _vm.$set(_vm.importDialog, "importType", $$v)
                },
                expression: "importDialog.importType",
              },
            },
            [_c("el-radio", { attrs: { label: 0 } }, [_vm._v("新增")])],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.importDialog.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }