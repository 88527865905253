<template>
  <div style="padding: 15px" v-loading="mainLoading">
    <div style="margin-bottom: 10px">
      <el-select clearable v-model="queryForm.dataPlatform" size="mini" placeholder="请选择平台" style="margin-right: 10px">
        <el-option v-for="item in platformOptions" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <el-select clearable v-model="queryForm.spiderState" size="mini" placeholder="请选择状态" style="margin-right: 10px">
        <el-option v-for="item in statusOptions" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <el-select clearable v-model="queryForm.storeType" size="mini" placeholder="请选择店铺经营类型" style="margin-right: 10px">
        <el-option v-for="item in storeOptions" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <el-button class="form-btn-item" size="mini" type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
      <el-button class="form-btn-item" size="mini" type="primary" icon="el-icon-plus" @click="handleAdd">新建账号</el-button>
    </div>
    <el-table :data="pageData.rows" max-height="500px" v-loading="loading" size="mini" border highlight-current-row>
      <el-table-column label="平台" prop="dataPlatform" />
      <el-table-column label="经营类型" prop="storeType" />
      <el-table-column label="店铺名称" prop="shopName" />
      <el-table-column label="手机号" prop="phone" />
      <el-table-column label="采集状态" prop="spiderState">
        <template slot-scope="{ row }">
          <el-tag v-if="row.spiderState !== '等待输入验证码'" :type="row.spiderState | stateFilter">
            {{ row.spiderState }}
          </el-tag>
          <el-tag v-else :type="row.spiderState | stateFilter" style="cursor: pointer" @click="handleSpiderValidate(row)">
            {{ row.spiderState }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="账户状态" prop="accountState">
        <template slot-scope="{ row }">
          <el-tag :type="row.accountState | accountStateFilter">
            {{ accountStateMap[row.accountState] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="执行人" prop="executor" />
      <el-table-column label="上次执行采集时间" prop="lastSpiderTime" />
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="{ row }">
          <el-button
            size="mini"
            type="primary"
            :disabled="row.spiderState === '采集中' || row.accountState === '0'"
            @click="handleSpider(row)"
          >
            执行采集
          </el-button>
          <el-button size="mini" type="warning" @click="handleEditAccount(row)">账号编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :currentPage="queryForm.current" :pageSize="queryForm.size" @pageChange="handleFilter" />

    <el-dialog :title="dialogTitle[currentTitle]" :visible.sync="dialog.dialogVisible" width="400px" @close="resetForm()">
      <div>
        <ul v-if="currentTitle === 'add'" style="padding-left: 10px; margin-top: 0px !important">
          说明：
          <li style="margin-bottom: 5px">
            1.请确保
            <font style="font-weight: 600">[平台]</font>
            、
            <font style="font-weight: 600">[店铺名称]</font>
            务必维护正确，为保证采集数据的准确性，这两个字段
            <font style="font-weight: 600">不允许修改</font>
            ；
          </li>
          <li>
            2.
            <font style="font-weight: 600">阿里、京东、拼多多</font>
            平台新建账户需要填写
            <font style="font-weight: 600">[账号]、[密码]</font>
            ，其余平台只需要填写手机号；
          </li>
        </ul>
        <ul v-if="currentTitle === 'edit'" style="padding-left: 10px; margin-top: 0px !important">
          说明：
          <li style="margin-bottom: 5px">1.当前允许修改账户[账号]、[密码]、[手机号]，修改后立即生效</li>
          <li style="margin-bottom: 5px">2.账户状态[停用]表示 当前账户短期不需要采集，列表中仍可见，当日状态为[停用]</li>
          <li>3.[账户删除]，表示店铺不再运营，列表中不可见该账户（谨慎操作）</li>
        </ul>
      </div>
      <el-form ref="addForm" :model="dialog.form" :rules="dialog.rules" label-width="80px">
        <el-form-item label="选择平台" prop="platform" size="mini" class="item-width">
          <el-select v-if="currentTitle === 'add'" v-model="dialog.form.platform" style="width: 72%" placeholder="请选择平台">
            <el-option v-for="item in platformOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <span v-if="currentTitle === 'edit'">{{ dialog.form.platform }}</span>
        </el-form-item>
        <el-form-item label="店铺名称" prop="shopName" size="mini" class="item-width">
          <el-input v-if="currentTitle === 'add'" v-model="dialog.form.shopName" placeholder="请输入店铺名称"></el-input>
          <span v-if="currentTitle === 'edit'">{{ dialog.form.shopName }}</span>
        </el-form-item>
        <el-form-item label="店铺类型" prop="storeType" size="mini" class="item-width">
          <el-select clearable v-model="dialog.form.storeType" style="width: 72%" placeholder="请选择店铺类型">
            <el-option v-for="item in storeOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号" size="mini" class="item-width">
          <el-input v-model="dialog.form.accountName" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" size="mini" class="item-width">
          <el-input v-if="currentTitle === 'add'" v-model="dialog.form.password" placeholder="请输入密码"></el-input>
          <el-input
            v-else
            v-model="dialog.form.editPassword"
            :placeholder="currentTitle === 'edit' && dialog.form.password ? '*******' : '请输入密码'"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone" size="mini" class="item-width">
          <el-input v-model="dialog.form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item v-if="currentTitle === 'edit'" label="状态" size="mini" class="item-width">
          <el-radio-group v-model="dialog.form.accountState">
            <el-radio label="1"><el-tag type="success">正常</el-tag></el-radio>
            <el-radio label="0"><el-tag type="danger">停用</el-tag></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAccount">确 定</el-button>
        <el-popconfirm title="请确认是否永久删除该账号" @confirm="handleDelAccount">
          <el-button slot="reference" type="danger"  v-if="currentTitle === 'edit'" style="margin-left: 10px">永久删除</el-button>
        </el-popconfirm>
      </div>
    </el-dialog>

    <el-dialog title="请输入验证码" :visible.sync="spiderDialog.dialogVisible" width="400px">
      <div style="margin-bottom: 5px">平台: {{ spiderDialog.platform }}</div>
      <div style="margin-bottom: 5px">店铺: {{ spiderDialog.shopName }}</div>
      <span>登录验证，请查看手机{{ spiderDialog.phone }}的验证码。</span>
      <div style="margin-top: 10px; display: inline-block">
        <el-input
          v-model="spiderDialog.code"
          size="mini"
          style="width: 120px; display: inline; margin-right: 10px"
          placeholder="请输入验证码"
        />
        <el-button round :disabled="showText !== '获取验证码'" size="mini" style="display: inline" @click="getVerifyCode">
          {{ showText }}
        </el-button>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="spiderDialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSpiderLoginValidate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { urlParams } from "@/mixins";
import {
  listSpiderData,
  addSpiderUser,
  getSingleSpider,
  spiderLoginValidate,
  spider,
  getAccount,
  updateSpiderUser,
  delAccount,
  getPlatformData
} from "@/api/spider";
import { Message } from "element-ui";
import Pagination from "../../../components/Element/Pagination.vue";
export default {
  components: { Pagination },
  // 采集数据状态查看
  name: "Spider",
  mixins: [urlParams],
  filters: {
    stateFilter(state) {
      const stateMap = {
        采集成功: "success",
        采集失败: "danger",
        采集中: "warning",
        未采集: "info",
        等待输入验证码: "primary",
      };
      return stateMap[state];
    },
    accountStateFilter(state) {
      const stateMap = {
        1: "success",
        0: "danger",
      };
      return stateMap[state];
    },
  },
  data() {
    const isPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        // const reg = /^[0-9-]{1,}$/
        const phoneReg = /^1(3|4|5|6|7|8|9)[0-9]\d{8}$/g;
        if (phoneReg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      loading: false,
      queryForm: {
        current: 1,
        size: 10,
        dataPlatform: undefined,
        spiderState: undefined,
        dept: this.$route.query.dept? this.$route.query.dept: 'tech'
      },
      pageData: {
        total: 0,
        rows: [],
      },
      accountStateMap: { 1: "正常", 0: "停用" },
      platformOptions: [],
      statusOptions: ["采集中", "未采集", "采集失败", "采集成功", "等待输入验证码"],
      storeOptions: ["直营", "分销"],
      dialog: {
        dialogVisible: false,
        form: {
          accountId: undefined,
          platform: undefined,
          shopName: undefined,
          phone: undefined,
          accountName: undefined,
          password: undefined,
          storeType: undefined,
          editPassword: undefined,
          accountState: undefined,
          dept: this.$route.query.dept? this.$route.query.dept: 'tech'
        },
        rules: {
          accountName: [{ required: true, message: "账号不能为空", trigger: "change" }],
          platform: [{ required: true, message: "平台不能为空", trigger: "change" }],
          shopName: [{ required: true, message: "店铺名称不能为空", trigger: "change" }],
          phone: [{ required: true, validator: isPhone, trigger: "change" }],
          storeType: [{ required: true, message: "店铺类型不能为空", trigger: "change" }],
        },
      },
      spiderDialog: {
        dialogVisible: false,
        phone: undefined,
        code: undefined,
        platform: undefined,
        shopName: undefined,
      },
      showText: undefined,
      showTime: undefined,
      cuurentRow: undefined,
      mainLoading: false,
      dialogTitle: {
        add: "新建账号",
        edit: "账号编辑",
      },
      currentTitle: undefined,
      queryTimer: undefined,
      dept: this.$route.query.dept? this.$route.query.dept: 'tech'
    };
  },
  created() {
    getPlatformData(this.dept).then(res => {
      this.platformOptions = res.data;
    })
  },
  mounted() {
    this.handleQuery();
    setInterval(() => {
      this.handleFilter();
    }, 5000);
    console.log(this.$route.query)
  },
  beforeDestroy() {
    if (this.queryTimer) {
      clearInterval(this.queryTimer);
    }
    if (this.showTime) {
      clearInterval(this.showTime);
    }
  },
  methods: {
    handleQuery() {
      this.queryForm.current = 1;
      this.handleFilter();
    },
    handleFilter(data) {
      this.loading = true;
      let param = "";
      if (data) {
        this.queryForm.current = data.current_page;
        this.queryForm.size = data.per_page;
      }
      for (var key in this.queryForm) {
        if (this.queryForm[key]) {
          param += key + "=" + this.queryForm[key] + "&";
        }
      }
      param = "?" + param.substring(0, param.length - 1);
      listSpiderData(param).then(res => {
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleAdd() {
      this.currentTitle = "add";
      this.$nextTick(() => {
        this.resetForm();
        this.$refs["addForm"].clearValidate();
      });
      this.dialog.dialogVisible = true;
    },
    resetForm() {
      this.dialog.form = {
        accountId: undefined,
        platform: undefined,
        shopName: undefined,
        phone: undefined,
        accountName: undefined,
        password: undefined,
        storeType: undefined,
        editPassword: undefined,
        accountState: undefined,
        dept: this.dept
      };
    },
    submitAccount() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          if (this.currentTitle === "add") {
            const data = Object.assign({}, this.dialog.form, { accountState: "1" });
            addSpiderUser(data).then(res => {
              if (res.code === 200) {
                this.dialog.dialogVisible = false;
                Message.success("新增成功");
                this.handleQuery();
              } else {
                Message.error(res.msg);
              }
            });
          } else if (this.currentTitle === "edit") {
            if (this.dialog.form.editPassword) {
              this.dialog.form.password = this.dialog.form.editPassword;
            }
            const data = Object.assign({}, this.dialog.form);
            updateSpiderUser(data).then(res => {
              if (res.code === 200) {
                this.dialog.dialogVisible = false;
                Message.success("修改成功");
                this.handleQuery();
              } else {
                Message.error(res.msg);
              }
            });
          }
        }
      });
    },
    handleSpider(row) {
      this.cuurentRow = row;
      spider(row).then(res => {
        if (res.code === 200) {
          Message.success("操作成功");
        } else {
          Message.error(res.msg);
        }
      });
    },
    getVerifyCode() {
      spider(this.cuurentRow).then(res => {
        if (res.code === 200) {
          this.validateInterval();
        } else {
          Message.error(res.msg);
        }
      });
    },
    validateInterval() {
      this.showTime = 60;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (this.showTime === 1) {
          this.showText = "获取验证码";
          clearInterval(this.timer);
        } else {
          this.showText = "重新获取(" + this.showTime + "秒)";
        }
        this.showTime--;
      }, 1000);
    },
    handleSpiderLoginValidate() {
      const data = {
        validationCode: this.spiderDialog.code,
        dataPlatform: this.cuurentRow.dataPlatform,
        shopName: this.cuurentRow.shopName,
        uuid: this.cuurentRow.uuid,
      };
      spiderLoginValidate(data).then(res => {
        if (res.code === 200) {
          this.spiderDialog.dialogVisible = false;
          Message.success("操作成功");
        } else {
          Message.error(res.msg);
        }
      });
    },
    handleEditAccount(row) {
      this.currentTitle = "edit";
      this.dialog.dialogVisible = true;
      getAccount(row.accountId).then(res => {
        if (res.data) {
          this.dialog.form = Object.assign(this.dialog.form, res.data);
        }
      });
    },
    handleSpiderValidate(row) {
      getSingleSpider(row.uuid).then(res => {
        if (res.data.spiderState === "等待输入验证码") {
          // 需要验证码
          this.spiderDialog.code = undefined;
          this.spiderDialog.phone = row.phone;
          this.spiderDialog.platform = row.dataPlatform;
          this.spiderDialog.shopName = row.shopName;
          this.spiderDialog.dialogVisible = true;
          this.validateInterval();
          this.cuurentRow = row;
        } else {
          Message.warning("状态已变更！");
          this.handleQuery();
        }
      });
    },
    handleDelAccount() {
      delAccount(this.dialog.form.accountId).then(res => {
        if (res.code === 200) {
          this.dialog.dialogVisible = false;
          Message.success("操作成功");
          this.handleQuery();
        } else {
          Message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-width {
  margin-bottom: 15px !important;
  ::v-deep .el-input__inner {
    max-width: 200px !important;
  }
  ::v-deep .el-dialog__body {
    padding-top: 10px !important;
  }
}
</style>
