<template>
  <div style="padding: 20px" v-loading="homeLoading">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="商品ID">
          <el-input v-model="queryForm.skuId" clearable />
        </el-form-item>
        <el-form-item label="是否已确认">
          <el-select v-model="queryForm.ifConfirm" clearable>
            <el-option value="Y" label="是" /><el-option value="N" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item label="系统匹配结果">
          <DictSelect
            :value.sync="queryForm.systemMatchResult"
            linkageRef="dc,shop"
            :init="true"
            singleProps
            :init-options="options.matchResultOptions"
          />
        </el-form-item>
        <el-form-item label="平台">
          <DictSelect
            ref="platform"
            type="platform"
            :value.sync="queryForm.platforms"
            singleProps
            multiple
            linkageRef="shop-name"
            :init="true"
            :init-options="options.platformsOptions"
          />
        </el-form-item>
        <el-form-item label="店铺名称">
          <DictSelect
            ref="shop-name"
            type="shop-name"
            parentLinkageRef
            :value.sync="queryForm.shopNames"
            singleProps
            :dictFun="dictShopFun"
            multiple
            remote
            @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
          />
        </el-form-item>
        <el-form-item label="商品型号">
          <DictSelect
            ref="goods"
            type="ec-cargo-no"
            :value.sync="queryForm.ecCargoNos"
            singleProps
            :dictFun="dictGoodsFun"
            multiple
            remote
          />
        </el-form-item>
        <el-form-item label="变动确认">
          <el-select v-model="queryForm.ifChanged" clearable>
            <el-option value="已变化" label="待确认" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否组装">
          <el-select v-model="queryForm.ifCombined" clearable>
            <el-option value="是" label="是" /><el-option value="否" label="否" />
          </el-select>
        </el-form-item>
<!--        <el-form-item label="是否下架">
          <el-select v-model="queryForm.ifOnSales" clearable>
            <el-option value="是" label="是" /><el-option value="否" label="否" />
          </el-select>
        </el-form-item>-->
        <el-form-item label=" 是否有最终匹配">
          <el-select v-model="queryForm.ifFinalMatchSkuModel" clearable>
            <el-option value="全部" label="全部" />
            <el-option value="1" label="是" />
            <el-option value="0" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleBatchMatchConfirm">批量确认匹配</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <p><strong>SKU数据：</strong> 总计 {{ countData.skuCount }} 条,
          <strong>精确匹配（1:1或人工维护）：</strong> {{ countData.exactMatch }} 条,
          <span style="color: red">{{ countData.waitMatch }}</span>条待匹配</p>
      </el-row>
    </div>
    <el-table
      ref="tableRef"
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @selection-change="handleSelectionChange">
      >
      <el-table-column type="selection" width="90" :selectable="checkboxInit"></el-table-column>
      <el-table-column label="平台" prop="ptName" min-width="80" />
      <el-table-column label="页面标题" prop="title" min-width="200" />
      <el-table-column label="SKUID" prop="skuId" min-width="100" />
      <el-table-column label="商品名称" prop="skuName" min-width="200">
        <template #default="{ row }">
          <a target="_blank" style="color: #6a6ae9;font-size: 12px" :href="row.skuUrl">{{ row.skuName }}</a>
        </template>
      </el-table-column>
      <el-table-column label="商品图片" prop="skuPic" width="100">
        <template #default="{ row }">
          <el-image :preview-src-list="[row.skuPic]" :src="row.skuPic" lazy style="height: 80px;margin: 5px;" />
        </template>
      </el-table-column>
      <el-table-column label="店铺名称" prop="shopName" min-width="200">
        <template #default="{ row }">
          <a target="_blank" style="color: #6a6ae9;font-size: 12px" :href="row.shopUrl">{{ row.shopName }}</a>
        </template>
      </el-table-column>
      <el-table-column label="首次出现时间" prop="firstSpiderTime" min-width="140" />
<!--      <el-table-column label="是否下架" prop="ifOnSales" min-width="80" />-->
      <el-table-column label="是否组装" prop="ifCombined" min-width="80">
<!--        <template slot-scope="scope">-->
<!--          <span v-if="scope.$index!==currentIndex">{{ scope.row.ifCombined }}</span>-->
<!--          <el-select v-else v-model="currentRow.ifCombined" size="mini" clearable>-->
<!--            <el-option value="是" label="是" /><el-option value="否" label="否" />-->
<!--          </el-select>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column label="系统匹配型号" prop="skuModel" width="120" />
      <el-table-column label="人工维护型号" prop="skuModelByPerson" width="120">
<!--        <template slot-scope="scope">
          <span v-if="scope.$index!==currentIndex">{{ scope.row.skuModelByPerson }}</span>
          <DictSelect
            v-else
            type="ec-cargo-no"
            :value.sync="currentRow.skuModelByPerson"
            singleProps
            :dictFun="dictGoodsFun"
            remote
            size="mini"
          />
        </template>-->
      </el-table-column>
      <el-table-column label="人工型号维护时间" prop="skuModelModified" width="140" />
      <el-table-column label="售卖价格" prop="price" width="100" >
        <template #default="{ row }">
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            v-if="row.screenshotImageUrl"
            >
            <el-image v-if="row.screenshotImageUrl" :preview-src-list="[row.screenshotImageUrl]" :src="row.screenshotImageUrl" />
<!--            <span v-else>图片待上传</span>-->
            <el-button slot="reference" type="text">{{ row.price }}</el-button>
          </el-popover>
          <span v-else>{{ row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="系统匹配售卖规格" prop="skuSpecificationsBySystem" width="110" />
      <el-table-column label="人工维护售卖规格" prop="skuSpecificationsByPerson" width="110">
<!--        <template slot-scope="scope">
          <span v-if="scope.$index!==currentIndex">{{ scope.row.skuSpecificationsByPerson }}</span>
          <el-input v-else v-model="currentRow.skuSpecificationsByPerson" size="mini" clearable />
        </template>-->
      </el-table-column>
      <el-table-column label="人工规格维护时间" prop="skuSpecificationsModified" width="140" />
      <el-table-column label="系统最后采集时间" prop="lastSpiderTime" width="140" />
      <el-table-column label="最终匹配" prop="finalMatch" width="140" />
      <el-table-column label="操作" width="180" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="button-class">
            <el-row>
              <el-col :span="12">
                <el-button size="mini" type="text" style="border: 0px;" @click="handleSkuModelEdit(scope.row)">编辑</el-button>
                <el-button v-if="scope.row.ifShowConfirm === 'N'" size="mini" type="text" style="border: 0px;" @click="handleMatchConfirm(scope.row)">确认</el-button>
              </el-col>
            </el-row>
            <el-row>
<!--              <el-col v-if="scope.$index!==currentIndex" :span="6">-->
<!--                <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(scope.row, scope.$index)">是否组装编辑</el-button>-->
<!--              </el-col>-->
<!--              <el-col v-if="scope.$index==currentIndex" :span="6">-->
<!--                <el-button size="mini" type="text" style="border: 0px;" @click="() => {currentRow=undefined; currentIndex=undefined; }">取消</el-button>-->
<!--              </el-col>-->
<!--              <el-col v-if="scope.$index==currentIndex" :span="6">-->
<!--                <el-button size="mini" type="text" style="border: 0px;" @click="submitIfCombinedEdit(currentRow, '1')">保存</el-button>-->
<!--              </el-col>-->
              <el-col :span="12">
                <el-popconfirm
                  confirm-button-text='编辑'
                  cancel-button-text='保持原样'
                  icon="el-icon-info"
                  icon-color="yellow"
                  confirm-button-type="text"
                  title="当前SKU售卖商品可能存在变化，请确认；"
                  @confirm="handleEdit(scope.row,  scope.$index)"
                  @cancel="submitEdit(scope.row, '2')"
                >
                  <el-button v-if="scope.row.ifShowLinkChange==='Y'" slot="reference" size="mini" type="text" style="border: 0px;">链接变动确认</el-button>
                </el-popconfirm>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />

    <el-dialog title="请选择正确的匹配关系" :visible.sync="skuModelUpdateDialog.visible" append-to-body width="1200px">
      <div style="overflow-y: scroll;height: 860px">
        <el-form ref="saveSkuModelUpdateFormRef" size="mini" :inline="true" :model="skuModelUpdateDialog.form">
          <div>
            <el-row style="width: 1100px">
              <el-col>
                <div>
                  <span style="font-size: 16px;font-weight: bold">识别到可能是以下商品,请选择:</span>
                </div>
                <div v-for="item in skuModelUpdateOptions"
                     :key="item.skuModel"
                     :label="item.skuModel" style="display: inline-block;margin-top: 1%;margin-left: 1%">
                  <el-carousel height="200px">
                    <el-carousel-item v-for="item1 in item.skuUrls" :key="item1">
                      <el-image :preview-src-list="[item1]" :src="item1" />
                    </el-carousel-item>
                  </el-carousel>
                  <div class="button-group">
                    <el-button :title="item.skuModelName" class="btn button-online" :class="{'selected': skuModelUpdateDialog.selectSkuModelButton === item.skuModel}" style="width: 200px;font-size: 10px;margin-top: 1%" @click="handSkuModelChange(item)">{{item.skuModel}}<br>{{'型号品名：' + item.skuModelName}}</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div style="border: 1px dashed red;margin-top: 1%;width: 1120px">
            <el-form-item label="最终型号" class="redLabel" style="margin-top: 1%;margin-left: 2%;color: #ea2838"></el-form-item>
            <el-form-item label="请从上方选择自动带入型号或下拉直接选择:" style="margin-top: 1%">
              <DictSelect
                ref="skuModel"
                type="sku_model"
                :value.sync="skuModelUpdateDialog.form.skuModelByPerson"
                :dictFun="dictSkuModelsFun"
                :init-props="{ label: 'skuModel', value: 'skuModel'}"
                @change="val =>handSkuModelInfoChange(val)"
                remote
              />
            </el-form-item>
          </div>
          <div>
            <el-row>
              <el-col :span="12">
                <div style="display: inline-block;margin-left: 30%;margin-top: 1%;border: 1px dashed black;height: 350px">
                  <el-carousel height="300px" style="width: 300px;">
                    <el-carousel-item v-for="item in skuModelUpdateDialog.selectSkuModelOptions" :key="item">
                      <el-image :preview-src-list="[item]" :src="item" />
                    </el-carousel-item>
                  </el-carousel>
                  <div style="margin-left: 20%;height: 20px">
                    <span style="font-size: 12px">你最终选择匹配的商品图片<br>{{'型号品名：' + skuModelUpdateDialog.skuModelName}}</span>
                  </div>
                </div>

              </el-col>
              <el-col :span="12">
                <div style="display: inline-block;margin-left: 10%;margin-top: 1%;border: 1px dashed black;height: 350px">
                  <el-carousel height="300px" style="width: 300px;">
                    <el-carousel-item v-for="item in [skuModelUpdateDialog.form.skuPic]" :key="item">
                      <el-image :preview-src-list="[item]" :src="item" />
                    </el-carousel-item>
                  </el-carousel>
                  <div style="margin-left: 45%;height: 20px">
                    <span style="font-size: 12px">店铺商品图片<br>{{'型号品名：' + skuModelUpdateDialog.shopSkuModelName}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div style="margin-top: 1%">
            <div>
              <span style="font-size: 16px;font-weight: bold">识别到规格,请确认或修改:</span>
            </div>
            <div style="border: 1px dashed red;margin-top: 1%;width: 1120px">
              <el-form-item label="最终规格" class="redLabel" style="margin-top: 1%;margin-left: 2%"></el-form-item>
              <el-form-item label="识别到规格:" prop="skuSpecificationsBySystem" style="margin-top: 1%" >
                <span>{{skuModelUpdateDialog.form.skuSpecificationsBySystem}}</span>
              </el-form-item>
              <el-form-item label="人工维护售卖规格" prop="skuSpecificationsByPerson" style="margin-top: 1%" >
                <el-input v-model="skuModelUpdateDialog.form.skuSpecificationsByPerson" clearable placeholder="请输入规格" />
              </el-form-item>
              <el-form-item label="是否组装" prop="ifCombined" style="margin-top: 1%" >
<!--                <el-select v-model="skuModelUpdateDialog.form.ifCombined" size="mini" clearable>
                  <el-option value="是" label="是" /><el-option value="否" label="否" />
                </el-select>-->
                <DictSelect
                  ref="ifSpu"
                  type="ifSpu"
                  :value.sync="skuModelUpdateDialog.form.ifCombined"
                  :dictFun="dictSpuFun"
                  @getOtherJsonParam="(callback) => callback({ platform: skuModelUpdateDialog.form.ptName})"
                  style="width: 500px"
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="position: absolute; bottom: 5px;width: 90%;background-color: white;height: 30px">
          <div style="display: inline-block; float: right;margin-top: 1%">
            <el-button type="primary" size="mini" @click="skuModelUpdateDialog.visible=false">取消</el-button>
            <el-button type="primary" size="mini" @click="submitSkuModelEdit">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  pageTechMatch,
  getTechMatchOptions,
  getTechMatchShopNameList,
  getTechMatchGoodsList,
  getTechMatchCountData,
  updateTechMatch,
  exportTechMatch,
  updateIfCombined, getSkuModelInfoList, priceControlConfirm, priceControlBatchConfirm, getTechSpuPriceControlOptions
} from "@/api/eCommerce";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
// import watermark from 'watermarkjs';

export default {
  name: "TechMatch",
  components: { Pagination, DictSelect},
  data() {
    return {
      homeLoading: false,
      tabName: 'tm',
      dictShopFun: getTechMatchShopNameList,
      dictGoodsFun: getTechMatchGoodsList,
      dictSkuModelsFun: getSkuModelInfoList,
      dictSpuFun: getTechSpuPriceControlOptions,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        skuId: undefined,
        systemMatchResult: undefined,
        platforms: undefined,
        shopNames: undefined,
        ecCargoNos: undefined,
        ifChanged: undefined,
        ifCombined: undefined,
        ifOnSales: undefined,
        ifFinalMatchSkuModel: '全部',
        ifConfirm: undefined,
      },
      loading: false,
      dictParam: {},
      currentRow: {},
      currentIndex: undefined,
      options: {
        matchResultOptions: [],
        platformsOptions: [],
        shopNameOptions : [],
        ecCargoNoOptions: []
      },
      parseTime: parseTime,
      currentUser: undefined,
      countData: {
        skuCount: undefined,
        exactMatch: undefined,
        waitMatch: undefined,
      },
      skuModelUpdateDialog: {
        visible: false,
        form: {
          ptName: undefined,
          skuId: undefined,
          shopName: undefined,
          skuModelByPerson: undefined,
          skuSpecificationsByPerson: undefined,
          operateType: undefined,
          finalMatchSkuSpecifications: undefined,
          finalMatchSkuModel: undefined,
          skuPic: undefined,
          ifCombined: undefined,
          skuSpecificationsBySystem: undefined,
        },
        skuModelName: '',
        shopSkuModelName: '',
        selectSkuModelOptions: [],
        selectSkuModelButton: undefined,
      },
      skuModelUpdateOptions: [],
    };
  },
  watch: {
  },
  created() {
    getTechMatchCountData().then(res => {
      this.countData.skuCount = res.data.totalQuantity;
      this.countData.exactMatch = res.data.preciseMatchQuantity;
      this.countData.waitMatch = res.data.waitMatchQuantity;
    })
    this.queryForm.systemMatchResult = "全部";
    getTechMatchOptions().then(res => {
      this.options.matchResultOptions = res.data.systemMatchResult;
      this.options.platformsOptions = res.data.platformResults;
      this.queryForm.platforms = res.data.platformResults;
      this.handleFilter();
      this.$forceUpdate();
    })
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageTechMatch(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        systemMatchResult: undefined,
        platforms: undefined,
        shopNames: undefined,
        ecCargoNos: undefined,
        ifChanged: undefined,
        ifCombined: undefined,
        skuId: undefined,
        ifOnSales: undefined,
        ifFinalMatchSkuModel: '全部',
        ifConfirm: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleEdit(row, index) {
      this.currentRow = deepClone(row);
      this.currentIndex = index;
    },
    submitEdit(row, operateType) {
      updateTechMatch({...row, ...{ operateType: operateType }}).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.currentIndex = undefined;
          this.currentRow = undefined;
          this.handleFilter();
        }
      })
    },
    handleConfirm(row, index) {
      this.$confirm('当前SKU售卖商品可能存在变化，请确认；', '提示', {
        confirmButtonText: '编辑',
        cancelButtonText: '保持原样',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        this.handleEdit(row, index);
      }).catch(() => {
        this.submitEdit(row, "2")
      });
    },
    handleExport() {
      this.homeLoading = true;
      exportTechMatch(this.queryForm).then(res => {
        if (res.code == 200) {
          if (res.data) {
            const link = document.createElement('a');
            link.href = res.data;
            link.download = "匹配关系导出";
            link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
            link.click()
          } else {
            Notification.info("无可下载数据！");
          }
        }
        this.homeLoading = false;
      })
    },
    handleJumpQuery(skuId) {
      this.queryForm.skuId = skuId;
      this.handleFilter();
    },
    handleSkuModelEdit(row) {
      this.rsetSkuModelUpdateDialogForm();
      this.skuModelUpdateDialog.visible = true;
      this.skuModelUpdateOptions = deepClone(row.skuModelInfoDtos);
      this.skuModelUpdateDialog.form = deepClone(row);
      // if(row.label === '系统匹配(1:1)'){
      //   this.skuModelUpdateDialog.form.skuModelByPerson = row.finalMatchSkuModel;
      //   this.skuModelUpdateDialog.selectSkuModelOptions = row.skuModelInfoDtos[0].skuUrls;
      // }
      if(row.finalMatchSkuModel){
        this.skuModelUpdateDialog.form.skuModelByPerson = row.finalMatchSkuModel;

        // this.skuModelUpdateDialog.selectSkuModelOptions = row.skuModelInfoDtos.filter(item => {
        //   return item.skuModel.indexOf(this.skuModelUpdateDialog.form.skuModelByPerson) !== -1
        // })[0].skuUrls;
        if(row.finalMatchSkuModelInfoDtos.length > 0){
          this.skuModelUpdateDialog.selectSkuModelOptions = row.finalMatchSkuModelInfoDtos[0].skuUrls;
          this.skuModelUpdateDialog.skuModelName = row.finalMatchSkuModelInfoDtos[0].skuModelName;
        }
        this.skuModelUpdateDialog.selectSkuModelButton = row.finalMatchSkuModel;
      }
      this.skuModelUpdateDialog.form.skuSpecificationsByPerson = row.skuSpecificationsByPerson;
      this.skuModelUpdateDialog.form.skuSpecificationsBySystem = row.skuSpecificationsBySystem;
      this.skuModelUpdateDialog.form.shopName = row.shopName;
      this.skuModelUpdateDialog.shopSkuModelName = row.skuName;
      const resp = this.skuModelUpdateDialog.form.skuModelByPerson;
      const parameter = this.skuModelUpdateDialog.form.ptName;
      this.$nextTick(async () => {
        if (this.$refs.skuModel) {
          await this.$refs.skuModel.remoteMethod(resp);
        }
        if (this.$refs.ifSpu) {
          await this.$refs.ifSpu.remoteMethod(parameter)
        }
      });
    },
    submitSkuModelEdit() {
      const argument = deepClone(this.skuModelUpdateDialog.form);
      argument.operateType = '1';
      updateTechMatch(argument).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.rsetSkuModelUpdateDialogForm();
          this.skuModelUpdateDialog.visible = false;
          // this.handleFilter();
          this.handleQuery();
        }
      })
    },
    handSkuModelChange(item){
      this.skuModelUpdateDialog.form.skuModelByPerson = item.skuModel;
      this.skuModelUpdateDialog.selectSkuModelOptions = item.skuUrls;
      this.skuModelUpdateDialog.selectSkuModelButton = item.skuModel;
      this.skuModelUpdateDialog.skuModelName = item.skuModelName;
    },
    rsetSkuModelUpdateDialogForm(){
      this.skuModelUpdateDialog.form = {
        ptName: undefined,
        skuId: undefined,
        shopName: undefined,
        skuModelByPerson: undefined,
        skuSpecificationsByPerson: 1,
        operateType: undefined,
        finalMatchSkuSpecifications: undefined,
        finalMatchSkuModel: undefined,
        skuPic: undefined,
      };
      this.skuModelUpdateDialog.selectSkuModelOptions = [];
      this.skuModelUpdateOptions = [];
      this.selectSkuModelButton = undefined;
      this.skuModelUpdateDialog.skuModelName = '';
      this.skuModelUpdateDialog.shopSkuModelName = '';
    },
    submitIfCombinedEdit(row, operateType) {
      updateIfCombined({...row, ...{ operateType: operateType }}).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.currentIndex = undefined;
          this.currentRow = undefined;
          this.handleFilter();
        }
      })
    },
    handSkuModelInfoChange(val){
      if(val===''){
        this.skuModelUpdateDialog.selectSkuModelOptions = [];
        this.skuModelUpdateDialog.selectSkuModelButton = undefined;
        this.skuModelUpdateDialog.skuModelName = '';
      }
      if(this.$refs.skuModel.itemOptions !== null){
        if(this.$refs.skuModel.itemOptions.length > 0){
          const obj = this.$refs.skuModel.itemOptions.find(e => e.skuModel === val);
          this.skuModelUpdateDialog.selectSkuModelOptions = obj.skuUrls;
          this.skuModelUpdateDialog.selectSkuModelButton = obj.skuModel;
          this.skuModelUpdateDialog.skuModelName = obj.skuModelName;
        }
      }
    },
    formattedMessage(item) {
      if(item){
        return item.skuModelName.replace(/\n/g, "<br>");
      }
    },
    handleMatchConfirm(row) {
      priceControlConfirm(row).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    handleBatchMatchConfirm(row) {
      let matchParam = [];
      if (row && row.skuId) {
        matchParam.push({ ptName: row.ptName, skuId: row.skuId, shopName: row.shopName, finalMatch: row.finalMatch, ifShowConfirm: row.ifShowConfirm});
      } else if (this.pageData.selections.length > 0) {
        matchParam = this.pageData.selections.map(e => {return { ptName: e.ptName, skuId: e.skuId, shopName: e.shopName, finalMatch: e.finalMatch, ifShowConfirm: e.ifShowConfirm}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      matchParam = matchParam.filter(item => {
        return item.ifShowConfirm.indexOf('N') !== -1;
      });
      priceControlBatchConfirm({ matchBatchConfirms: matchParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    checkboxInit(row,index){
      if(row.ifShowConfirm === 'N'){
        return 1;
      }else {
        return 0;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.redLabel .el-form-item__label{
  color: #ea2838;
}
.button-group .btn {
  height: 66px; /* 设置统一的高度 */
  /* 其他按钮样式 */
}

.selected {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.button-online {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
