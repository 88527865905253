var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-form",
            {
              ref: "searchRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.handleFilter },
                      model: {
                        value: _vm.queryForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "type", $$v)
                        },
                        expression: "queryForm.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "箱数", value: "Count" },
                      }),
                      _c("el-option", {
                        attrs: { label: "金额", value: "Total" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "大类" } },
                [
                  _c("DictSelect", {
                    ref: "main",
                    attrs: {
                      type: "main",
                      dictFun: _vm.dictFun,
                      value: _vm.queryForm.mainCategoryCode,
                      linkageRef: "mid",
                      multiple: "",
                      "collapse-tags": "",
                      clearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "mainCategoryCode",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "中类" } },
                [
                  _c("DictSelect", {
                    ref: "mid",
                    attrs: {
                      type: "mid",
                      dictFun: _vm.dictFun,
                      value: _vm.queryForm.midCategoryCode,
                      linkageRef: "sub",
                      multiple: "",
                      "collapse-tags": "",
                      parentLinkageRef: "",
                      clearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "midCategoryCode",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小类" } },
                [
                  _c("DictSelect", {
                    ref: "sub",
                    attrs: {
                      type: "sub",
                      dictFun: _vm.dictFun,
                      value: _vm.queryForm.subCategoryCode,
                      multiple: "",
                      "collapse-tags": "",
                      parentLinkageRefclearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "subCategoryCode",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "系列" } },
                [
                  _c("DictSelect", {
                    ref: "series",
                    attrs: {
                      type: "series",
                      dictFun: _vm.dictFun,
                      singleProps: "",
                      value: _vm.queryForm.series,
                      multiple: "",
                      "collapse-tags": "",
                      clearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "series", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赛道" } },
                [
                  _c("DictSelect", {
                    ref: "race",
                    attrs: {
                      type: "race",
                      dictFun: _vm.dictFun,
                      value: _vm.queryForm.raceCode,
                      multiple: "",
                      "collapse-tags": "",
                      clearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "raceCode", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "料号" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.materialCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "materialCode", $$v)
                      },
                      expression: "queryForm.materialCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.skuModel,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuModel", $$v)
                      },
                      expression: "queryForm.skuModel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品名" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.skuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuName", $$v)
                      },
                      expression: "queryForm.skuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "级别" } },
                [
                  _c("DictSelect", {
                    ref: "grade",
                    attrs: {
                      type: "grade",
                      dictFun: _vm.dictFun,
                      singleProps: "",
                      value: _vm.queryForm.grade,
                      multiple: "",
                      "collapse-tags": "",
                      clearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "grade", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "负责人" } },
                [
                  _c("DictSelect", {
                    ref: "user",
                    attrs: {
                      type: "user",
                      dictFun: _vm.dictFun,
                      value: _vm.queryForm.responsible,
                      multiple: "",
                      "collapse-tags": "",
                      fullLabel: "",
                      clearable: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "responsible", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "本次下单需求" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ordered,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ordered", $$v)
                        },
                        expression: "queryForm.ordered",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "有值", value: "1" } }),
                      _c("el-option", { attrs: { label: "无值", value: "0" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否238" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.is238,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "is238", $$v)
                        },
                        expression: "queryForm.is238",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: true } }),
                      _c("el-option", { attrs: { label: "否", value: false } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        title: "下载当前查询的商品箱数或金额相关字段数据",
                      },
                      on: { click: _vm.handleDownload },
                    },
                    [_vm._v("下载")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        title: "导出当前查询的商品下单数量、下单金额和消化时间",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleImport },
                    },
                    [_vm._v("导入")]
                  ),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v(
                      "单位： " +
                        _vm._s(_vm.currentQueryType === "Count" ? "箱" : "万元")
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "15px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.pageData.rows,
                height: "calc(100vh - 220px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-align": "center",
                "header-cell-style": {
                  background: "#F0F0F2",
                  color: "black",
                  "text-align": "center",
                },
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "40", fixed: "left" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "型号",
                  prop: "skuModel",
                  fixed: "left",
                  "show-overflow-tooltip": "",
                  align: "center",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "料号",
                  prop: "materialCode",
                  width: "120",
                  "show-overflow-tooltip": "",
                  fixed: "left",
                  align: "center",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "品名",
                  prop: "skuName",
                  width: "220",
                  fixed: "left",
                  "show-overflow-tooltip": "",
                  align: "left",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "级别",
                  prop: "grade",
                  width: "60",
                  fixed: "left",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "去年同期月均",
                  prop: "tqAvgMthAmount",
                  width: "120",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.tqAvgMthAmount } }, [
                          _vm._v(
                            " " + _vm._s(_vm.fidData(row.tqAvgMthAmount, 2))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "近半年销售情况",
                    prop: "a",
                    align: "center",
                    "show-overflow-tooltip": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "月均",
                      prop: "avgMthAmount",
                      align: "center",
                      sortable: "",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.avgMthAmount } }, [
                              _vm._v(
                                " " + _vm._s(_vm.fidData(row.avgMthAmount, 2))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.recentFieldMap["recent1"],
                      prop: "recent1",
                      align: "center",
                      sortable: "",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.recent1 } }, [
                              _vm._v(" " + _vm._s(_vm.fidData(row.recent1, 2))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.recentFieldMap.recent2,
                      prop: "recent2",
                      align: "center",
                      sortable: "",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.recent2 } }, [
                              _vm._v(" " + _vm._s(_vm.fidData(row.recent2, 2))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.recentFieldMap.recent3,
                      prop: "recent3",
                      align: "center",
                      sortable: "",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.recent3 } }, [
                              _vm._v(" " + _vm._s(_vm.fidData(row.recent3, 2))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.recentFieldMap.recent4,
                      prop: "recent4",
                      align: "center",
                      sortable: "",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.recent4 } }, [
                              _vm._v(" " + _vm._s(_vm.fidData(row.recent4, 2))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.recentFieldMap.recent5,
                      prop: "recent5",
                      align: "center",
                      sortable: "",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.recent5 } }, [
                              _vm._v(" " + _vm._s(_vm.fidData(row.recent5, 2))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.recentFieldMap.recent6,
                      prop: "recent6",
                      align: "center",
                      sortable: "",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", { attrs: { title: row.recent6 } }, [
                              _vm._v(" " + _vm._s(_vm.fidData(row.recent6, 2))),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: {
                  label: "当月销售",
                  prop: "byXs",
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.byXs } }, [
                          _vm._v(" " + _vm._s(_vm.fidData(row.byXs, 2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "总部当前库存",
                  prop: "qmKc",
                  align: "center",
                  width: "120",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.qmKc } }, [
                          _vm._v(" " + _vm._s(_vm.fidData(row.qmKc, 2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库存系数",
                  prop: "stockRatio",
                  align: "center",
                  "show-overflow-tooltip": "",
                  width: "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.stockRatio } }, [
                          _vm._v(" " + _vm._s(_vm.fidData(row.stockRatio, 2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购未交",
                  prop: "cgwj",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  width: "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.cgwj } }, [
                          _vm._v(" " + _vm._s(_vm.fidData(row.cgwj, 2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "欠量未发",
                  prop: "allQl",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  width: "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.allQl } }, [
                          _vm._v(" " + _vm._s(_vm.fidData(row.allQl, 2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购未转单",
                  prop: "cgwzd",
                  align: "center",
                  "show-overflow-tooltip": "",
                  sortable: "",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.cgwzd } }, [
                          _vm._v(" " + _vm._s(_vm.fidData(row.cgwzd, 2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label:
                    _vm.currentQueryType === "Count" ? "下单箱数" : "下单金额",
                  width: "140",
                  prop: "orderNeed",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "controls-position": "right",
                            placeholder: "请输入内容",
                            size: "mini",
                            min: 0,
                          },
                          on: {
                            change: (val) =>
                              _vm.handleSave(row, val, "orderNeed"),
                          },
                          model: {
                            value: row.orderNeed,
                            callback: function ($$v) {
                              _vm.$set(row, "orderNeed", $$v)
                            },
                            expression: "row.orderNeed",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "剩余备货库存",
                  prop: "lefBackStock",
                  width: "120",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.lefBackStock } }, [
                          _vm._v(
                            " " + _vm._s(_vm.fidData(row.lefBackStock, 2))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "备货库存系数",
                  prop: "stockBackRatio",
                  width: "120",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { attrs: { title: row.stockBackRatio } }, [
                          _vm._v(
                            " " + _vm._s(_vm.fidData(row.stockBackRatio, 2))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "计划消化时间",
                  prop: "expectUsedDate",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-date-picker", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            size: "mini",
                            type: "date",
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "default-time": "23:59:59",
                          },
                          on: {
                            change: (val) =>
                              _vm.handleSave(row, val, "expectUsedDate"),
                          },
                          model: {
                            value: row.expectUsedDate,
                            callback: function ($$v) {
                              _vm.$set(row, "expectUsedDate", $$v)
                            },
                            expression: "row.expectUsedDate",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "赛道",
                  prop: "raceName",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "大类",
                  prop: "mainCategoryName",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "中类",
                  prop: "midCategoryName",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "小类",
                  prop: "subCategoryName",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "系列",
                  prop: "goodsSeriesName",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否238",
                  prop: "is238",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "零售价",
                  prop: "price",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "箱规",
                  prop: "bottomSpec",
                  "show-overflow-tooltip": "",
                  sortable: "",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              pageSizes: [10, 20, 30, 50, 100, 200, 500],
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }