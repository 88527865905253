<template>
  <div>
    <div class="block" style=" padding-top: 2%; ">
      <label class="demonstration" style="font-size: 15px; padding-right: 1%;">创建时间</label>
      <el-date-picker
        v-model="dateRangeValue"
        type="daterange"
        align="right"
        unlink-panels
        clearable
        size="mini"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        @change="getDateRange">
      </el-date-picker>
      <label for="qd-select" style="font-size: 15px; padding-left: 2%">渠道：</label>
      <el-radio-group v-model="radioGroup" style="font-size: 15px; padding-right: 1%;" size="mini"
                      @input="handleRadioGroupInput">
        <el-radio-button label="全部"></el-radio-button>
        <el-radio-button label="短信"></el-radio-button>
        <el-radio-button label="企微"></el-radio-button>
      </el-radio-group>
      <label style="font-size: 15px; padding-right: 1%;padding-left: 1%">选择分组</label>
      <el-select v-model="radio2"
                 style="font-size: 15px; padding-right: 1%; width:26%"
                 clearable
                 filterable
                 placeholder="请选择"
                 id="fz-select"
                 size="mini"
                 @change="handleSelectChange"
      >
        <el-option
          v-for="item in GroupDataListCD"
          :key="item.name"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="mini" style="font-size: 15px;" @click="getConsumeStatistics">查询
      </el-button>
      <i style="font-size: 12px; padding-left: 1%;">仅支持查询2023/5/1之后创建的分组</i>
    </div>

    <div>
      <div class="title-cl" v-if="qwruleGroupData != null && dxruleGroupData==null">分组更新时间
        {{ qwruleGroupData.ruleGroupUpdateTime }}
      </div>
      <div class="title-cl" v-if="qwruleGroupData == null && dxruleGroupData!=null">分组更新时间
        {{ dxruleGroupData.ruleGroupUpdateTime }}
      </div>
      <div class="title-cl" v-if="qwruleGroupData != null && dxruleGroupData!=null">分组更新时间
        {{ qwruleGroupData.ruleGroupUpdateTime }}
      </div>
      <div v-if="qwruleGroupData != null">
        <span class="title-cl">企微触达比例</span>
        <span class="title-cl">{{ qwruleGroupData.reachRatio }}</span>
        <span class="title-cl">企微创建时间</span>
        <span class="title-cl">{{ qwruleGroupData.reachTime }}</span>
        <span class="title-cl" v-if="qwruleGroupData.qwMsgConfigFlag==3"><a style="color: #66b1ff"
                                                                            href="https://t-datart.bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/vizs/8a953495625d49959361cecd89c08088"
                                                                            target="_blank">前往配置企微消息</a>,匹配成功后第二天数据可看</span>
        <span class="title-cl" v-if="qwruleGroupData.qwMsgConfigFlag==2">当前企微消息匹配失败,<a style="color: #66b1ff"
                                                                                       href="https://t-datart.bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/vizs/8a953495625d49959361cecd89c08088"
                                                                                       target="_blank">前往配置企微消息</a>,匹配成功后第二天数据可看</span>
      </div>
      <div v-if="dxruleGroupData != null">
        <span class="title-cl">短信触达比例</span>
        <span class="title-cl">{{ dxruleGroupData.reachRatio }}</span>
        <span class="title-cl">短信创建时间</span>
        <span class="title-cl">{{ dxruleGroupData.reachTime == "" ? '未找到短信发送记录' : dxruleGroupData.reachTime }}</span>
      </div>
    </div>

    <h2><i class="el-icon-s-opportunity" style="padding-top: 1%;"></i> 触达漏斗</h2>

    <div style="display: flex; align-items: center; ">
      <label for="qd-select" style="font-size: 15px; ">渠道：</label>
      <el-radio-group v-model="radio1" id="qd-select" style="font-size: 15px; padding-right: 1%;" size="mini"
                      @input="handleRadio1Input">
        <el-radio-button label="全部" v-if="radio1 === '全部' || radioTR ===1"></el-radio-button>
        <el-radio-button label="短信" v-if="radio1 ==='短信' || radioTR ===1"></el-radio-button>
        <el-radio-button label="企微" v-if="radio1 ==='企微' || radioTR ===1"></el-radio-button>
      </el-radio-group>

      <label style="font-size: 15px;">消费跟踪周期：</label>
      <el-radio-group v-model="radioD" id="xfzq-select" @input="handleRadioDInput">
        <el-radio :label="0">当天</el-radio>
        <el-radio :label="3">3天</el-radio>
        <el-radio :label="7">7天</el-radio>
        <el-radio :label="30">30天</el-radio>
      </el-radio-group>
    </div>

    <div class="rea_">
      <el-row :gutter="60">

        <el-col :span="24" :sm="12">

          <div class="chart-container">
            <!--            <div class="progress-wrapper">-->
            <div class="progress-container">
              <div class="progress-bar" :style="{ width: (num + '%') }"></div>
              <div class="progress-text">
                <div class="progress-value">需触达人数（所选分组人数）</div>
                <div class="progress-total">{{ xcxDict.planReachNum }}</div>
              </div>

            </div>

            <div class="progress-summary" style="text-align: right;"> {{ xcxDict.actualReachPct }}</div>
            <!--            </div>-->
            <div class="progress-container">
              <div class="progress-bar2" :style="{ width: xcxDict.actualReachPct }"></div>
              <div class="progress-text">
                <div class="progress-value">{{ radioDText }}实际触达人数</div>
                <div class="progress-total">{{ xcxDict.actualReachNum }}</div>
              </div>
            </div>
            <div class="progress-summary" style="text-align: right;">{{ xcxDict.xsJyrsPct }}</div>

            <div class="progress-container">
              <div class="progress-bar4"
                   :style="{ width: (parseFloat(xcxDict.xsJyrsPct)/100) * parseFloat(xcxDict.actualReachPct) +'%'}"></div>
              <div class="progress-text">
                <div class="progress-value">小程序消费人数</div>
                <div class="progress-total">{{ xcxDict.xsJyrs }}</div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :span="24" :sm="12">
          <div class="chart-container">
            <!--            <div class="progress-wrapper">-->
            <div class="progress-container">
              <div class="progress-bar" :style="{ width: (num + '%') }"></div>
              <div class="progress-text">
                <div class="progress-value">需触达人数（所选分组人数）</div>
                <div class="progress-total">{{ xcxDict.planReachNum }}</div>
              </div>

            </div>

            <div class="progress-summary" style="text-align: right;"> {{ xcxDict.actualReachPct }}</div>
            <!--            </div>-->
            <div class="progress-container">
              <div class="progress-bar2" :style="{ width: xcxDict.actualReachPct }"></div>
              <div class="progress-text">
                <div class="progress-value">{{ radioDText }}实际触达人数</div>
                <div class="progress-total">{{ xcxDict.actualReachNum }}</div>
              </div>
            </div>
            <div class="progress-summary" style="text-align: right;">{{ xcxDict.xxJyrsPct }}</div>

            <div class="progress-container">
              <div class="progress-bar4"
                   :style="{ width: (parseFloat(xcxDict.xxJyrsPct)/100)*parseFloat(xcxDict.actualReachPct) +'%'}"></div>
              <div class="progress-text">
                <div class="progress-value">到店消费人数</div>
                <div class="progress-total">{{ xcxDict.xxJyrs }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <h2 v-show="channel=='企微'"><i class="el-icon-s-opportunity"  ></i> 门店触达任务统计</h2>
    <div class="rea_" v-show="channel=='企微'">
      <div class="top-title" style="width: 81%; ">
        <div class="title-right">
          <el-button type="primary" icon="el-icon-download" @click="handleDownloadReachConsumeStoreTaskStatisticsExport">
            下载明细清单
          </el-button>
        </div>
      </div>
      <el-table
        :data="storeTaskDataQW"
        border
        style="width: 80%; ">
        <el-table-column
          prop="storeCode"
          label="门店编码"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="storeName"
          label="门店名称"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="dqName"
          label="大区名称"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="xqName"
          label="小区名称"
          align="center">
        </el-table-column>
        <el-table-column
          prop="taskStaffNum"
          label="有任务的店员数"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="taskDoneNum"
          label="完成任务店员数"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="taskReachNum"
          label="任务涉及会员数"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="actualReachNum"
          label="已触达会员数"
          width="180"
          align="center">
        </el-table-column>
        <el-table-column
          prop="reachExecutionRate"
          label="触达执行率"
          width="180"
          align="center">
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding-top: 2%; padding-bottom: 3%"
        background
        :current-page="storeTaskpageQW"
        :page-size="smsFailpageSizeQW"
        :total="storeTasktotalQW"
        layout="prev, pager, next"
        @current-change="storeTaskChangeQW"
      >
      </el-pagination>
    </div>
    <h2><i class="el-icon-s-opportunity"></i>未触达任务统计</h2>
    <div style="display: flex; align-items: center; ">

      <label style="font-size: 15px;">渠道：</label>
      <el-radio-group v-model="radio3" id="wqd-select" style="font-size: 15px;" size="mini"
                      @input="handleRadio3Input">
        <el-radio-button label="短信" v-if="radio3 ==='短信'  || radioTR ===1"></el-radio-button>
        <el-radio-button label="企微" v-if="radio3 ==='企微'  || radioTR ===1"></el-radio-button>
      </el-radio-group>

    </div>
    <div class="rea_" v-show="radio3 === '短信'">
      <div class="top-title">
        <div class="title-right">
          <el-button type="primary" icon="el-icon-download" @click="handleDownloadMessageDetail">下载明细清单</el-button>
        </div>
      </div>
      <!--      <hr>-->
<!--      <el-row :gutter="60">-->
<!--        <el-col :span="24" :sm="24">-->
<!--          <div class="chart-container">-->
          <div >
            <label  style="padding-left: 1%; font-size: 15px;">失败总人数：{{ WcdTotalFailNumData.totalFailNum }}</label>
            <label  style="padding-left: 5%; font-size: 15px;">占触达分组占比：{{ WcdTotalFailNumData.reachFailNumPct }}</label>
            <el-table
              :data="serverData"
              border
            >
              <el-table-column
                prop="reachErrorMsg"
                label="短信发送失败原因"
                width="360"
                align="center">
              </el-table-column>
              <el-table-column
                prop="reachFailNum"
                label="人数"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column label="人数占比" align="center" >
                <template slot-scope="{ row }">
                  <div class="progress-container-table">
                    <div class="progress-bar3" :style="{ width: (parseFloat(row.reachFailNumPct)  + '%') }"></div>
                    <div class="progress-text">
                      <div class="progress-total">{{ parseFloat(row.reachFailNumPct) }}%</div>
                    </div>

                  </div>

                  <!--                  <el-progress class="progress-container-table" :text-inside="true"  :percentage="parseFloat(row.reachFailNumPct)" />-->

                </template>
              </el-table-column>
            </el-table>


            <!--            <el-pagination-->
            <!--              style="padding-top: 2%;"-->
            <!--              background-->
            <!--              :current-page="smsFailpage"-->
            <!--              :page-size="smsFailpageSize"-->
            <!--              :total="smsFailtotal"-->
            <!--              layout="prev, pager, next"-->
            <!--              @current-change="smsFailHandlePageChange"-->
            <!--            >-->
            <!--            </el-pagination>-->

          </div>
<!--        </el-col>-->
<!--      </el-row>-->
    </div>

    <div class="rea_" v-show="radio3 === '企微'">
      <div class="top-title">
        <div class="title-right">
          <el-button type="primary" icon="el-icon-download" @click="handleDownloadQwFailStore">下载统计结果</el-button>
          <el-button type="primary" icon="el-icon-download" @click="handleDownloadQwFail">下载明细清单</el-button>
<!--          <div v-if="isDownloading" class="loading-indicator"></div>-->
        </div>
      </div>
      <!--      <hr>-->
<!--      <el-row :gutter="60">-->
<!--        <el-col :span="24" :sm="24">-->
<!--          <div class="chart-container">-->
          <div >
            <label  style="padding-left: 1%; font-size: 15px;">失败总人数：{{ WcdTotalFailNumData.totalFailNum }}</label>
            <label  style="padding-left: 5%; font-size: 15px;">占触达分组占比：{{ WcdTotalFailNumData.reachFailNumPct }}</label>
<!--            <el-descriptions title="" >-->
<!--              <el-descriptions-item label="失败总人数">-->
<!--                {{ WcdTotalFailNumData.totalFailNum }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item label="占触达分组占比">-->
<!--                {{ WcdTotalFailNumData.reachFailNumPct }}-->
<!--              </el-descriptions-item>-->
<!--            </el-descriptions>-->
            <el-table
              :data="serverDataQW"
              border
              style="width: 80%; ">
              <el-table-column
                prop="storeCode"
                label="门店ID"
                width="180"
                align="center">
              </el-table-column>
              <el-table-column
                prop="storeName"
                label="门店名称"
                width="180"
                align="center">
              </el-table-column>
              <el-table-column
                prop="reachFailNum"
                label="人数"
                width="180"
                align="center">
              </el-table-column>
              <el-table-column
                prop="reachFailNumPct"
                label="人数占比"
                align="center">
              </el-table-column>
              <el-table-column
                prop="reachFailAttributionNum"
                label="门店归属人数"
                width="180"
                align="center">
              </el-table-column>
              <el-table-column
                prop="reachFailAttributionNumPct"
                label="门店归属人数占比"
                width="180"
                align="center">
              </el-table-column>
            </el-table>
            <el-pagination
              style="padding-top: 2%;"
              background
              :current-page="smsFailpageQW"
              :page-size="smsFailpageSizeQW"
              :total="smsFailtotalQW"
              layout="prev, pager, next"
              @current-change="smsFailHandlePageChangeQW"
            >
            </el-pagination>
          </div>
<!--        </el-col>-->
<!--      </el-row>-->
    </div>

    <h2><i class="el-icon-s-opportunity"></i> 实际触达人群消费数据</h2>
    <div style="display: flex; align-items: center;padding-bottom: 0% ">
      <label class="child-header-check">对比维度：</label>
      <el-radio-group v-model="radioCheck" size="medium" @input="handleRadioCheckInput">
        <el-radio-button label="时间"></el-radio-button>
        <el-radio-button label="分组"></el-radio-button>
      </el-radio-group>
      <label class="child-header-check" style="padding-left: 5%;">消费渠道：</label>
      <el-radio-group v-model="radioExpend" size="medium" @input="handleRadioExpendInput">
        <el-radio-button label="ALL">全部</el-radio-button>
        <el-radio-button label="1">门店</el-radio-button>
        <el-radio-button label="2">小程序</el-radio-button>
      </el-radio-group>
    </div>

    <div class="rea_">

      <div class="header" v-show="radioCheck === '时间'">
        <label for="qd-select" class="child-header-one">渠道：</label>
        <el-radio-group v-model="radio5" id="qd-selectcdrq" style="font-size: 15px; padding-right: 1%;" size="mini"
                        @input="handleRadio5Input">
          <el-radio-button label="短信" v-if="radio5 ==='短信' || radioTR ===1 "></el-radio-button>
          <el-radio-button label="企微" v-if="radio5 ==='企微' || radioTR ===1"></el-radio-button>
        </el-radio-group>
        <el-radio-group v-model="toDaymodelTi" id="xfzq-selectxcdffxrq" @input="handleToDaymodelTiInput">
          <el-radio :label="0">时间-到天</el-radio>
          <el-radio :label="1">时间-聚合</el-radio>
        </el-radio-group>


      </div>

      <div v-show="toDaymodelTi === 0 && radioCheck === '时间'">
        <div class="top-title" style="width: 81%; ">
          <div class="title-right">
            <el-button type="primary" icon="el-icon-download" @click="handleDownloadConsumeTodayStatisticsExport">
              下载明细清单
            </el-button>
          </div>
        </div>
        <el-table
          :data="toDayStatisticsDataList"
          border
          style="width: 80%; ">
          <el-table-column prop="target" label="指标" align="center" fixed></el-table-column>
          <el-table-column prop="reachQ7d" label="触达前7天" align="center"></el-table-column>
          <el-table-column prop="reachQ6d" label="触达前6天" align="center"></el-table-column>
          <el-table-column prop="reachQ5d" label="触达前5天" align="center"></el-table-column>
          <el-table-column prop="reachQ4d" label="触达前4天" align="center"></el-table-column>
          <el-table-column prop="reachQ3d" label="触达前3天" align="center"></el-table-column>
          <el-table-column prop="reachQ2d" label="触达前2天" align="center"></el-table-column>
          <el-table-column prop="reachQ1d" label="触达前1天" align="center"></el-table-column>
          <el-table-column prop="reachToday" label="触达当天" align="center"></el-table-column>
          <el-table-column prop="reachH1d" label="触达后1天" align="center"></el-table-column>
          <el-table-column prop="reachH2d" label="触达后2天" align="center"></el-table-column>
          <el-table-column prop="reachH3d" label="触达后3天" align="center"></el-table-column>
          <el-table-column prop="reachH4d" label="触达后4天" align="center"></el-table-column>
          <el-table-column prop="reachH5d" label="触达后5天" align="center"></el-table-column>
          <el-table-column prop="reachH6d" label="触达后6天" align="center"></el-table-column>
          <el-table-column prop="reachH7d" label="触达后7天" align="center"></el-table-column>

        </el-table>
      </div>


      <div v-show="toDaymodelTi === 1 && radioCheck === '时间'">

        <div>
          <div class="top-title" style="width: 81%; ">
            <div class="title-right">
              <el-button type="primary" icon="el-icon-download" @click="handleDownloadConsumePolyStatisticsExport">
                下载明细清单
              </el-button>
            </div>
          </div>
          <el-table
            :data="polyStatisticsDataList"
            border
            style="width: 80%; ">
            <el-table-column prop="target" label="指标" align="center" fixed></el-table-column>
            <el-table-column prop="reachRs" label="实际触达人数" align="center" fixed></el-table-column>
            <el-table-column prop="orderQty" label="订单数" align="center"></el-table-column>
            <el-table-column prop="sellAmount" label="销售额（含卡/券）" align="center"></el-table-column>
            <el-table-column prop="customerUnitPrice" label="客单价（销售额（含卡/券）/订单数）" align="center"></el-table-column>
            <el-table-column prop="jyrs" label="消费人数" align="center"></el-table-column>
            <el-table-column prop="xfRate" label="消费率（消费人数/实际触达人数）" align="center">
              <template slot-scope="scope">
                {{ (scope.row.xfRate) + '%' }}
              </template>
            </el-table-column>
            <el-table-column prop="xbkBkRs" label="办卡人数" align="center"></el-table-column>
            <el-table-column prop="xbkKkRs" label="开卡人数" align="center"></el-table-column>
            <el-table-column prop="xbkXkRs" label="续卡人数" align="center"></el-table-column>
            <el-table-column prop="bkRate" label="办卡率（办卡人数/消费人数）" align="center">
              <template slot-scope="scope">
                {{ (scope.row.bkRate) + '%' }}
              </template>
            </el-table-column>
            <el-table-column prop="yqRate" label="用券率（用券人数/消费人数）" align="center">
              <template slot-scope="scope">
                {{ (scope.row.yqRate) + '%' }}
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="header">
          <div class="store-polycontainer">
            <div class="store-polytable-wrapper">
              <div class="top-title" style="width: 81%; ">
                <div class="title-right">
                  <el-button type="primary" icon="el-icon-download"
                             @click="handleDownloadConsumeStorePolyStatisticsExport">下载明细清单
                  </el-button>
                </div>
              </div>
              <!--              :row-class-name="tableRowClassName"-->
              <el-table
                :data="storePolyStatisticsDataList"
                border
                :cell-class-name="tableRowClassName"
                style="width: 80%; ">
                <el-table-column prop="target" label="指标" align="center" fixed></el-table-column>
                <el-table-column prop="storeCode" label="门店ID" align="center"></el-table-column>
                <el-table-column prop="storeName" label="门店名称" align="center"></el-table-column>
                <el-table-column prop="reachRs" label="实际触达人数" align="center"></el-table-column>
                <el-table-column prop="orderQty" label="订单数" align="center"></el-table-column>
                <el-table-column prop="sellAmount" label="销售额（含卡/券）" align="center"></el-table-column>
                <el-table-column prop="customerUnitPrice" label="客单价（销售额（含卡/券）/订单数）"
                                 align="center"></el-table-column>
                <el-table-column prop="jyrs" label="消费人数" align="center"></el-table-column>
                <el-table-column prop="xfRate" label="消费率（消费人数/实际触达人数）" align="center">
                  <template slot-scope="scope">
                    {{ (scope.row.xfRate) + '%' }}
                  </template>
                </el-table-column>
                <el-table-column prop="xbkBkRs" label="办卡人数" align="center"></el-table-column>
                <el-table-column prop="xbkKkRs" label="开卡人数" align="center"></el-table-column>
                <el-table-column prop="xbkXkRs" label="续卡人数" align="center"></el-table-column>
                <el-table-column prop="bkRate" label="办卡率（办卡人数/消费人数）" align="center">
                  <template slot-scope="scope">
                    {{ (scope.row.bkRate) + '%' }}
                  </template>
                </el-table-column>
                <el-table-column prop="yqRate" label="用券率（用券人数/消费人数）" align="center">
                  <template slot-scope="scope">
                    {{ (scope.row.yqRate) + '%' }}
                  </template>
                </el-table-column>

              </el-table>
            </div>
            <div class="store-polypagination-wrapper">
              <el-pagination
                style="padding-top: 2%;"
                background
                :current-page="storePolySPage"
                :page-size="storePolySPageSize"
                :total="storePolySPageSizeTotle"
                layout="prev, pager, next"
                @current-change="storePolySPageChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <!--    分组-->
      <div v-show="radioCheck === '分组'">
        <div class="header">

          <label class="child-header">对比组：</label>
          <el-radio-group v-model="radiosjwd" id="xfzq-selectcdsjwdrqgroup" @input="handleRadiosGwdInput">
            <el-radio :label="0" class="header-option">对照组</el-radio>
            <el-radio :label="3" class="header-option">应触达未触达</el-radio>
            <el-radio :label="1" class="header-option">全部未触达</el-radio>
            <el-radio :label="2" class="header-option">自定义</el-radio>
          </el-radio-group>

          <div v-show="radiosjwd === 2">
            <!--          <label class="header-option" style="padding-left: 1%;">分组名称：</label>-->
            <el-select v-model="radioChe" style="font-size: 15px; padding-right: 1%; width:300px" clearable
                       filterable
                       placeholder="请选择分组名称"
                       id="fz-select"
                       size="mini"
                       @input="handleRadiosCheInput"
            >
              <el-option
                v-for="item in GroupDataList"
                :key="item.name"
                :label="item.name"
                :value="item.ruleGroupChannelId"
              >
              </el-option>
            </el-select>
          </div>

        </div>
        <div>
          <div class="top-title" style="width: 81%; ">
            <div class="title-right">
              <el-button type="primary" icon="el-icon-download"
                         @click="handleDownloadConsumeGroupCompareStatisticsExport">
                下载明细清单
              </el-button>
            </div>
          </div>
          <div>
            <el-table
              :data="SmsGroupCompareStatisticsDataList"
              border
              style="width: 80%; ">

              <el-table-column prop="reachChannel" label="渠道" align="center" fixed></el-table-column>
              <el-table-column prop="groupName" label="分组名称" align="center"></el-table-column>
              <el-table-column prop="target" label="指标" align="center" ></el-table-column>
              <el-table-column prop="reachRs" label="实际触达人数" align="center"></el-table-column>
              <el-table-column prop="orderQty" label="订单数" align="center"></el-table-column>
              <el-table-column prop="sellAmount" label="销售额（含卡/券）" align="center"></el-table-column>
              <el-table-column prop="customerUnitPrice" label="客单价（销售额（含卡/券）/订单数）" align="center"></el-table-column>
              <el-table-column prop="jyrs" label="消费人数" align="center"></el-table-column>
              <el-table-column prop="xfRate" label="消费率（消费人数/实际触达人数）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.xfRate !== '/' ? scope.row.xfRate + '%' : scope.row.xfRate }}
                </template>
              </el-table-column>
              <el-table-column prop="xbkBkRs" label="办卡人数" align="center"></el-table-column>
              <el-table-column prop="xbkKkRs" label="开卡人数" align="center"></el-table-column>
              <el-table-column prop="xbkXkRs" label="续卡人数" align="center"></el-table-column>
              <el-table-column prop="bkRate" label="办卡率（办卡人数/消费人数）" align="center">
                <template slot-scope="scope">
                  {{ (scope.row.bkRate) + '%' }}
                </template>
              </el-table-column>
              <el-table-column prop="yqRate" label="用券率（用券人数/消费人数）" align="center">
                <template slot-scope="scope">
                  {{ (scope.row.yqRate) + '%' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="top-title" style="width: 81%; ">
            <div class="title-right">
              <el-button type="primary" icon="el-icon-download"
                         @click="handleDownloadGroupToDayCompareStatisticsExport">
                下载明细清单
              </el-button>
            </div>
          </div>
          <div>
            <el-table
              :data="GroupToDayStatisticsDataList"
              border
              style="width: 80%; ">
              <el-table-column prop="reachChannel" label="渠道" align="center" fixed></el-table-column>
              <el-table-column prop="groupName" label="分组名称" align="center"></el-table-column>
              <el-table-column prop="target" label="时间" align="center" ></el-table-column>
              <el-table-column prop="reachRs" label="实际触达人数" align="center"></el-table-column>
              <el-table-column prop="orderQty" label="订单数" align="center"></el-table-column>
              <el-table-column prop="sellAmount" label="销售额（含卡/券）" align="center"></el-table-column>
              <el-table-column prop="customerUnitPrice" label="客单价（销售额（含卡/券）/订单数）" align="center"></el-table-column>
              <el-table-column prop="jyrs" label="消费人数" align="center"></el-table-column>
              <el-table-column prop="xfRate" label="消费率（消费人数/实际触达人数）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.xfRate !== '/' ? scope.row.xfRate + '%' : scope.row.xfRate }}
                </template>
              </el-table-column>
              <el-table-column prop="xbkBkRs" label="办卡人数" align="center"></el-table-column>
              <el-table-column prop="xbkKkRs" label="开卡人数" align="center"></el-table-column>
              <el-table-column prop="xbkXkRs" label="续卡人数" align="center"></el-table-column>
              <el-table-column prop="bkRate" label="办卡率（办卡人数/消费人数）" align="center">
                <template slot-scope="scope">
                  {{ (scope.row.bkRate) + '%' }}
                </template>
              </el-table-column>
              <el-table-column prop="yqRate" label="用券率（用券人数/消费人数）" align="center">
                <template slot-scope="scope">
                  {{ (scope.row.yqRate) + '%' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

      </div>

    </div>




    <div class="end-page">

    </div>
  </div>

</template>

<script>
import Chart from 'chart.js';
// import download from 'downloadjs';
import {
  getCDGroupData,
  getCDconsumeStatistics,
  getSmsFail,
  getSmsFailQW,
  getSmsTodayStatistics,
  getSmsStorePolyStatistics,
  getSmsPolyStatistics,
  getSmsGroupCompareStatistics,
  getSmsSmsFailUserDetails,
  getSmsQWFailUserDetails,
  getSmsQWFailStoreDetails,
  getReachFailStatistics,
  getConsumeTodayStatisticsExport,
  getConsumePolyStatisticsExport,
  getConsumeStorePolyStatisticsExport,
  getConsumeGroupCompareStatisticsExport,
  getConsumeRuleGroupDetails,
  getGroupToDayStatistics,
  getGroupToDayStatisticsExport,
  getReachConsumeStoreTaskStatistics,
  getReachConsumeStoreTaskStatisticsExport,
} from "@/api/tagPlatform";
// import echarts from 'echarts'
// import ECharts from 'vue-echarts/components/ECharts.vue'
import {Message} from "element-ui";


export default {
  // components: {
  //   // 'v-chart': ECharts
  // },
  data() {
    return {
      chartOptions: {},
      radioCheck: '时间',
      // isDataLoaded:true,
      SmsGroupCompareStatisticsDataList: [],
      GroupToDayStatisticsDataList: [],
      toDayStatisticsDataList: [],
      polyStatisticsDataList: [],
      storePolyStatisticsDataList: [],
      radiosjwd: 0, // 全部未触达:若选择时填全部未触达
      radioxffx: 0,
      toDaymodelTi: 0,
      toDaymodelDay: 0,
      polyStatisticsModelTi: 0,
      polyStatisticsModelDa: 0,
      smsFailx: [],
      smsFaily: [],
      num: 100,
      serverData: [],
      serverDataQW: [],
      storeTaskDataQW: [],
      consumeTrackPeriod: 'T',
      channel: 'ALL',
      ruleGroupId: null,
      barChartData: null,
      radio1: '全部',
      radioTR: 1,
      radio2: '',
      radio3: '短信',
      radio5: '短信',
      radioD: 0,
      radioDText: '当天',
      radioExpend: 'ALL',
      radioGroup: '全部',
      smsFailpage: 1,
      smsFailpageSize: 100,
      smsFailtotal: 1,
      smsFailpageQW: 1,
      storeTaskpageQW: 1,
      smsFailpageSizeQW: 10,
      smsFailtotalQW: 1,
      storeTasktotalQW: 1,
      storePolySPage: 1,
      storePolySPageSize: 10,
      storePolySPageSizeTotle: 1,
      radioChe: '',
      contrastGroupName: '',
      xcxDict: {},
      GroupDataList: [],
      GroupDataListCD: [],
      GroupDataListOriginal: [],
      WcdTotalFailNumData: {},
      WcdReachFailNumPot: '',
      RuleGroupData: null,
      qwruleGroupData: null,
      dxruleGroupData: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRangeValue: null,
      version: null,
      isDownloading: false, // 标记是否正在下载
      ruleGroupChannelId: null,
      contrastRuleGroupChannelId: null,
    }

  },
  watch: {
    // radioD: {
    //   handler(newVal) {
    //
    //     if (newVal === 0) {
    //
    //       this.consumeTrackPeriod = 'T'
    //     } else if (newVal === 3) {
    //       this.consumeTrackPeriod = 'T0-T2'
    //     } else if (newVal === 7) {
    //       this.consumeTrackPeriod = 'T0-T6'
    //     } else {
    //       this.consumeTrackPeriod = 'T0-T29'
    //     }
    //     console.log(this.consumeTrackPeriod)
    //     this.getData();
    //   },
    //
    // },


  },
  // computed: {
  //   // 当前页显示的数据
  //   smsFailcurrentData() {
  //     const startIndex = (this.smsFailpage - 1) * this.smsFailpageSize;
  //     const endIndex = startIndex + this.smsFailpageSize;
  //     return this.serverData.slice(startIndex, endIndex);
  //   },
  //   smsFailcurrentDataWQ(){
  //     const startIndex = (this.smsFailpageQW - 1) * this.smsFailpageSizeQW;
  //     const endIndex = startIndex + this.smsFailpageSizeQW;
  //     return this.serverDataQW.slice(startIndex, endIndex);
  //   },
  // },
  mounted() {
    // 画图
    // let myChart = echarts.init(this.$refs.chart);
    // myChart.setOption(this.chartOptions);


    // console.log("已经挂载了这个组件");
    // const canvas = document.getElementById('bar-chart');
    // const ctx = canvas.getContext('2d');

    // new Chart(ctx, {
    //   type: 'horizontalBar',
    //   grid: {								  // 个人使用是用来做图表位置偏移
    //     left: '5%',
    //     right: '10%',
    //     top: '20%',
    //     bottom: '10%',
    //     containLabel: true
    //   },
    //   data: this.barChartData,
    //   options: {
    //     aspectRatio: 3, // 设置宽高比例为 3:1
    //     barPercentage: 0.5, // 设置柱状元素宽度为刻度宽度的一半
    //     categoryPercentage: 0.5, // 设置同一类别下柱状元素宽度之间的间距为 0.8
    //     responsive: true, // 自适应大小，不需要手动指定宽高
    //     barThickness: 2, // 柱形实际宽度，单位为像素
    //     maxBarThickness: 30, // 柱形最大宽度，单位为像素
    //     tooltips: {
    //       callbacks: {
    //         title: function (tooltipItem, data) {
    //           return '';
    //         },
    //         label: function (tooltipItem, data) {
    //           return tooltipItem.xLabel + '%';
    //           // + tooltipItem.yLabel;
    //         }
    //       }
    //     },
    //     scales: {
    //       xAxes: [{
    //
    //         ticks: {
    //           min: 0,  //最小值
    //           max: 100,  //最大值
    //           // 将刻度值转成百分比形式
    //           callback: function (value, index, values) {
    //             return value + '%';
    //           }
    //         },
    //         // //隐藏刻度线
    //         // axisTick: {
    //         //   show: false
    //         // },
    //         // //隐藏坐标线
    //         // axisLine: {
    //         //   show: false
    //         // },
    //         // 隐藏坐标线
    //         gridLines: {
    //           display: false
    //         }
    //       }],
    //       yAxes: [{
    //
    //         gridLines: {
    //           display: false
    //         },
    //         ticks: {
    //           beginAtZero: true
    //         }
    //       }]
    //     },
    //
    //     legend: {
    //       display: true
    //     },
    //
    //
    //   },
    //
    // });


  },
  created() {
    console.log("已经创建了这个组件");
    this.GroupData();
  },
  methods: {
    handleSelectChange(selected) {

      this.version = this.GroupDataListCD.filter(item => item.name === selected).version
    },
    // 行 斑马线
    tableRowClassName({row, rowIndex}) {
      const grouIndex = row.groupIndex
      if (grouIndex % 2 === 0) {
        return 'row-class-1';
      }
    },
    // 处理页码变化
    handleRadioDInput(value) {
      // do something with the selected value

      if (value === 0) {
        this.radioDText = '当天';
        this.consumeTrackPeriod = 'T'
      } else if (value === 3) {
        this.radioDText = '3天';
        this.consumeTrackPeriod = 'T0-T2'
      } else if (value === 7) {
        this.radioDText = '7天';
        this.consumeTrackPeriod = 'T0-T6'
      } else {
        this.radioDText = '30天';
        this.consumeTrackPeriod = 'T0-T29'
      }
      if (this.radio1 === '全部') {
        const dataChannel = 'ALL'
        this.getData(dataChannel);
      } else {
        const dataChannel = this.radio1
        this.getData(dataChannel);
      }
    },
    handleRadioGroupInput(value) {
      if (value === '全部') {
        this.GroupDataListCD = this.GroupDataListOriginal
      } else {
        this.GroupDataListCD = this.GroupDataListOriginal.filter(item => item.channel == value)
      }
    },
    handleRadio1Input(value) {
      if (value === '全部') {
        const dataChannel = 'ALL'
        this.getData(dataChannel);
      } else {
        const dataChannel = value
        this.getData(dataChannel);
      }
    },
    handleRadio3Input(value) {
      if (value === '短信') {
        this.getSmsFail()
      } else {
        this.getSmsFailQW()
      }
      this.getReachFailStatistics()
    },
    handleRadio5Input(value) {
      console.log(value)
      if (this.toDaymodelTi === 0) {
        this.getSmstoDayStatisticsData()
      } else if (this.toDaymodelTi === 1) {
        this.getSmspolyStatisticsData();
        this.getSmsStorePolyStatisticsData();
      }
    },
    handleRadioCheckInput(value) {
      console.log('handleRadioCheckInput')
      if (value === '时间') {
        if (this.toDaymodelTi === 0) {
          this.getSmstoDayStatisticsData()
        } else if (this.toDaymodelTi === 1) {
          this.getSmspolyStatisticsData();
          this.getSmsStorePolyStatisticsData();
        }
      } else {
        this.getSmsGroupCompareStatisticsData();
      }
    },
    handleRadioExpendInput(value) {
      console.log('handleRadioExpendInput')
      if (this.radioCheck === '时间') {
        if (this.toDaymodelTi === 0) {
          this.getSmstoDayStatisticsData()
        } else if (this.toDaymodelTi === 1) {
          this.getSmspolyStatisticsData();
          this.getSmsStorePolyStatisticsData();
        }
      } else {
        this.getSmsGroupCompareStatisticsData();
      }
    },
    handleToDaymodelTiInput(value) {
      if (value === 0) {
        this.getSmstoDayStatisticsData()
      } else if (value === 1) {
        this.getSmspolyStatisticsData();
        this.getSmsStorePolyStatisticsData();
      } else {
        this.getSmsGroupCompareStatisticsData();
      }
    },
    handleRadiosGwdInput(value) {
      this.getSmsGroupCompareStatisticsData();
    },
    handleRadiosCheInput(value) {
      // this.contrastGroupName = value
      this.contrastRuleGroupChannelId = value
      // const selectedOptionOne = this.GroupDataList.find(item => item.name === this.radioChe)
      this.getSmsGroupCompareStatisticsData();


    },


    smsFailHandlePageChange(newPage) {
      this.smsFailpage = newPage;
      this.getSmsFail()
    },
    storePolySPageChange(newPage) {
      this.storePolySPage = newPage;
      this.getSmsStorePolyStatisticsData()
    },

    smsFailHandlePageChangeQW(newPage) {
      this.smsFailpageQW = newPage;
      this.getSmsFailQW()
    },
    storeTaskChangeQW(newPage) {
      this.storeTaskpageQW = newPage;
      this.reachConsumeStoreTaskStatistics()
    },
    handleDownload() {
      // 执行文件下载的逻辑
    },
    async handleDownloadMessageDetail() {
      const MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio3,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }
      getSmsSmsFailUserDetails(MessageDetailData).then(resJson =>{
        const downloadLink = resJson.msg;
        const code = resJson.code;
        if (code === 200) {
          // 触发文件下载
          window.location.href = downloadLink
          // download(downloadLink);
        }
      })
    },
    async handleDownloadQwFail() {
      // this.isDownloading = true;
      const MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio3,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }

      const res = await getSmsQWFailUserDetails(MessageDetailData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
        // download(downloadLink);
      }
      // this.isDownloading = false; // 下载完成后隐藏加载指示器
    },
    async handleDownloadConsumeTodayStatisticsExport() {
      let MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio5,
        consumeChannel: '',
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      };
      console.log('this.radioExpend')
      console.log(this.radioExpend)
      if (this.radioExpend == "ALL") {
        MessageDetailData.consumeChannel = '';
      } else {
        MessageDetailData.consumeChannel = this.radioExpend
      }
      const res = await getConsumeTodayStatisticsExport(MessageDetailData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
        // download(downloadLink);
      }
    },
    async handleDownloadReachConsumeStoreTaskStatisticsExport() {
      let MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      };


      getReachConsumeStoreTaskStatisticsExport(MessageDetailData).then(res => {
        const downloadLink = res.msg;
        const code = res.code;
        if (code === 200) {
          // 触发文件下载
          window.location.href = downloadLink
          // download(downloadLink);
        }
      })

    },
    async handleDownloadConsumePolyStatisticsExport() {
      let MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio5,
        consumeChannel: '',
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      };
      console.log('this.radioExpend')
      console.log(this.radioExpend)
      if (this.radioExpend == "ALL") {
        MessageDetailData.consumeChannel = '';
      } else {
        MessageDetailData.consumeChannel = this.radioExpend
      }
      const res = await getConsumePolyStatisticsExport(MessageDetailData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
        // download(downloadLink);
      }
    },
    async handleDownloadConsumeStorePolyStatisticsExport() {
      let MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio5,
        consumeChannel: '',
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      };
      console.log('this.radioExpend')
      console.log(this.radioExpend)
      if (this.radioExpend == "ALL") {
        MessageDetailData.consumeChannel = '';
      } else {
        MessageDetailData.consumeChannel = this.radioExpend
      }
      const res = await getConsumeStorePolyStatisticsExport(MessageDetailData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
        // download(downloadLink);
      }
    },
    async handleDownloadConsumeGroupCompareStatisticsExport() {
      let SmsGroupCompareSData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.channel,
        groupName: this.radio2,
        allNoReach: '',
        consumeChannel: '',
        contrastRuleGroupChannelId: undefined,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }
      if (this.radioExpend == "ALL") {
        SmsGroupCompareSData.consumeChannel = '';
      } else {
        SmsGroupCompareSData.consumeChannel = this.radioExpend
      }

      if (this.radiosjwd === 1) {
        SmsGroupCompareSData.allNoReach = '全部未触达'

      } else if (this.radiosjwd === 3) {
        SmsGroupCompareSData.ycdWcd = 'Y'

      }else if (this.contrastRuleGroupChannelId !== '' && this.radiosjwd === 2) {
        // SmsGroupCompareSData.contrastGroupName = this.contrastGroupName
        SmsGroupCompareSData.contrastRuleGroupChannelId = this.contrastRuleGroupChannelId

      } else if (this.contrastRuleGroupChannelId === '' && this.radiosjwd === 2) {
        // SmsGroupCompareSData.contrastGroupName = 'N'
      }

      getConsumeGroupCompareStatisticsExport(SmsGroupCompareSData).then(res => {
        const downloadLink = res.msg;
        const code = res.code;
        if (code === 200) {
          // 触发文件下载
          window.location.href = downloadLink
          // download(downloadLink);
        }
      })
    },
    async handleDownloadGroupToDayCompareStatisticsExport() {
      let SmsGroupCompareSData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.channel,
        groupName: this.radio2,
        allNoReach: '',
        consumeChannel: '',
        contrastRuleGroupChannelId: undefined,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }
      if (this.radioExpend == "ALL") {
        SmsGroupCompareSData.consumeChannel = '';
      } else {
        SmsGroupCompareSData.consumeChannel = this.radioExpend
      }

      if (this.radiosjwd === 1) {
        SmsGroupCompareSData.allNoReach = '全部未触达'

      }  else if (this.radiosjwd === 3) {
        SmsGroupCompareSData.ycdWcd = 'Y'

      }else if (this.contrastRuleGroupChannelId !== '' && this.radiosjwd === 2) {
        // SmsGroupCompareSData.contrastGroupName = this.contrastGroupName
        SmsGroupCompareSData.contrastRuleGroupChannelId = this.contrastRuleGroupChannelId

      } else if (this.contrastRuleGroupChannelId === '' && this.radiosjwd === 2) {
        // SmsGroupCompareSData.contrastGroupName = 'N'
      }

      getGroupToDayStatisticsExport(SmsGroupCompareSData).then(res => {
        const downloadLink = res.msg;
        const code = res.code;
        if (code === 200) {
          // 触发文件下载
          window.location.href = downloadLink
          // download(downloadLink);
        }
      })
    },
    async handleDownloadQwFailStore() {
      const MessageDetailData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio3,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }

      const res = await getSmsQWFailStoreDetails(MessageDetailData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
        // download(downloadLink);
      }
    },

    async getSmsStorePolyStatisticsData() {
      // 触达人群消费门店维度聚合指标统计
      console.log('getSmsStorePolyStatisticsData')

      const SmsStorePolyStatisticsData = {
        page: this.storePolySPage,
        limit: this.storePolySPageSize,
        ruleGroupId: this.ruleGroupId,
        channel: this.radio5,
        consumeChannel: this.radioExpend,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }
      //this.storePolyStatisticsDataList
      getSmsStorePolyStatistics(SmsStorePolyStatisticsData).then(resJson => {
        const sDataList = resJson.data.datas;
        this.storePolySPageSizeTotle = resJson.data.total;

        let lastStoreCode = null; // 初始化上一条数据的 storeCode 为 null
        let groupIndex = 0; // 记录当前是第几个分组
        const newDataList = [];
        // 循环遍历每条数据
        for (let i = 0; i < sDataList.length; i++) {
          const data = sDataList[i];

          // 判断当前数据是否属于和前一条数据相同的分组
          const sameGroup = data.storeCode === lastStoreCode;

          // 如果属于同一分组，则做相应的处理
          if (sameGroup) {
            // TODO: 处理属于同一分组的数据
            // 在相应的处理中将 groupIndex 拼入到新数据的字段中
            const newData = {
              ...data,
              groupIndex,
            };
            newDataList.push(newData);
          } else {
            // 如果进入了新的分组，则做相应的处理
            // 将 groupIndex 自增 1，并将其拼接到新数据的字段中
            groupIndex++;
            const newData = {
              ...data,
              groupIndex,
            };
            newDataList.push(newData);
            // 记录当前数据的 storeCode 作为下一条数据的上一条 storeCode
            lastStoreCode = data.storeCode;
          }
        }
        // this.storePolyStatisticsDataList = newDataList
        // 格式化浮点数保留两位小数
        this.storePolyStatisticsDataList = newDataList.map(item => {
          for (let key in item) {
            if (typeof item[key] === 'number') {
              item[key] = parseFloat(item[key].toFixed(2)); // 将浮点数保留两位小数
            }
          }
          return item;
        });
      })
    },

    async getSmspolyStatisticsData() {
      // 触达人群消费聚合指标统计
      console.log('getSmspolyStatisticsData')

      var SmspolyStatisticsData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio5,
        consumeChannel: this.radioExpend,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }

      const res = await getSmsPolyStatistics(SmspolyStatisticsData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      // this.polyStatisticsDataList = resJson.data;
      // 格式化浮点数保留两位小数
      this.polyStatisticsDataList = resJson.data.map(item => {
        for (let key in item) {
          if (typeof item[key] === 'number') {
            item[key] = parseFloat(item[key].toFixed(2)); // 将浮点数保留两位小数
          }
        }
        return item;
      });


    },

    async getSmstoDayStatisticsData() {
      //触达人群消费到天指标统计


      const SmstoDayStatisticsData = {
        ruleGroupId: this.ruleGroupId,
        channel: this.radio5,
        consumeChannel: this.radioExpend,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }


      const res = await getSmsTodayStatistics(SmstoDayStatisticsData)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      // this.toDayStatisticsDataList = resJson.data;
      this.toDayStatisticsDataList = resJson.data.map(item => {
        for (let key in item) {
          if (typeof item[key] === 'number') {
            item[key] = parseFloat(item[key].toFixed(2)); // 将浮点数保留两位小数
          }
        }
        return item;
      });
      // 遍历数组，对满足条件的项进行修改
      this.toDayStatisticsDataList.forEach(item => {
        // 假设要对 name 字段为 '某个值' 的项添加百分号
        if (item.target === '办卡率（办卡人数/消费人数）' || item.target === '用券率（用券人数/消费人数）' || item.target === '消费率（消费人数/实际触达人数）') {
          item.reachH1d = item.reachH1d + '%';
          item.reachH2d = item.reachH2d + '%';
          item.reachH3d = item.reachH3d + '%';
          item.reachH4d = item.reachH4d + '%';
          item.reachH5d = item.reachH5d + '%';
          item.reachH6d = item.reachH6d + '%';
          item.reachH7d = item.reachH7d + '%';
          item.reachQ1d = item.reachQ1d + '%';
          item.reachQ2d = item.reachQ2d + '%';
          item.reachQ3d = item.reachQ3d + '%';
          item.reachQ4d = item.reachQ4d + '%';
          item.reachQ5d = item.reachQ5d + '%';
          item.reachQ6d = item.reachQ6d + '%';
          item.reachQ7d = item.reachQ7d + '%';
          item.reachToday = item.reachToday + '%';
          // 也可以使用模板字符串拼接百分号：`${item.someField}%`
        }
      });

    },
    async getSmsGroupCompareStatisticsData() {
      if (this.radiosjwd === 1) {
        const SmsGroupCompareSData = {
          ruleGroupId: this.ruleGroupId,
          channel: this.channel,
          allNoReach: '全部未触达',
          groupName: this.radio2,
          consumeChannel: this.radioExpend,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        getSmsGroupCompareStatistics(SmsGroupCompareSData).then(res => {
          this.SmsGroupCompareStatisticsDataList = res.data.map(item => {
            if (item.groupName === '全部未触达' && item.reachRs === 0) {
              return {...item, reachRs: '/', xfRate: '/'};
            }
            return item;
          });
        })
        getGroupToDayStatistics(SmsGroupCompareSData).then(res => {
          this.GroupToDayStatisticsDataList = res.data.map(item => {
            if (item.groupName === '全部未触达' && item.reachRs === 0) {
              return {...item, reachRs: '/', xfRate: '/'};
            }
            return item;
          });
        })
      } else if (this.contrastRuleGroupChannelId !== '' && this.radiosjwd === 2) {
        const SmsGroupCompareSData = {
          ruleGroupId: this.ruleGroupId,
          channel: this.channel,
          groupName: this.radio2,
          contrastGroupName: this.contrastGroupName,
          consumeChannel: this.radioExpend,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
          contrastRuleGroupChannelId: this.contrastRuleGroupChannelId,
        }
        getSmsGroupCompareStatistics(SmsGroupCompareSData).then(res => {
          this.SmsGroupCompareStatisticsDataList = res.data;
        })
        getGroupToDayStatistics(SmsGroupCompareSData).then(res => {
          this.GroupToDayStatisticsDataList = res.data;
        })
      } else if (this.contrastRuleGroupChannelId === '' && this.radiosjwd === 2) {

        const SmsGroupCompareSData = {
          ruleGroupId: this.ruleGroupId,
          channel: this.channel,
          contrastGroupName: 'N',
          groupName: this.radio2,
          consumeChannel: this.radioExpend,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        getSmsGroupCompareStatistics(SmsGroupCompareSData).then(res => {
          this.SmsGroupCompareStatisticsDataList = res.data;
        })
        getGroupToDayStatistics(SmsGroupCompareSData).then(res => {
          this.GroupToDayStatisticsDataList = res.data;
        })
      } else if ( this.radiosjwd === 3) {
        const SmsGroupCompareSData = {
          ruleGroupId: this.ruleGroupId,
          channel: this.channel,
          groupName: this.radio2,
          consumeChannel: this.radioExpend,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
          ycdWcd:"Y"

        }


        getSmsGroupCompareStatistics(SmsGroupCompareSData).then(res => {
          this.SmsGroupCompareStatisticsDataList = res.data;
        })
        getGroupToDayStatistics(SmsGroupCompareSData).then(res => {
          this.GroupToDayStatisticsDataList = res.data;
        })
      } else {
        const SmsGroupCompareSData = {
          ruleGroupId: this.ruleGroupId,
          channel: this.channel,
          groupName: this.radio2,
          consumeChannel: this.radioExpend,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        getSmsGroupCompareStatistics(SmsGroupCompareSData).then(res => {
          this.SmsGroupCompareStatisticsDataList = res.data;
        })
        getGroupToDayStatistics(SmsGroupCompareSData).then(res => {
          this.GroupToDayStatisticsDataList = res.data;
        })
      }


    }
    ,

    async getData(dataChannel) {

      if (this.ruleGroupChannelId != null) {
        var da = {
          ruleGroupId: this.ruleGroupId,
          channel: dataChannel,
          consumeTrackPeriod: this.consumeTrackPeriod,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        getCDconsumeStatistics(da).then(res => {
          this.xcxDict = res.data
        })


      }


    }
    ,
    getDateRange() {
      this.radioGroup = '全部'
      this.GroupData()
      // this.GroupDataListCD = this.GroupDataListCD.filter(item => {
      //   return item.startDate > this.dateRangeValue[0] && item.startDate < this.dateRangeValue[1]
      // })
    },

    getConsumeStatistics() {
      // 获取 el-select 绑定的 option 的 data 值

      const selectedOptionOne = this.GroupDataList.find(item => item.name === this.radio2)
      // this.ruleGroupId = selectedOptionOne.ruleGroupId
      this.contrastGroupName = ''
      this.radioD = 0
      this.consumeTrackPeriod = 'T'
      this.toDaymodelTi = 0
      this.radioChe = ''
      this.radioExpend = 'ALL'
      this.radiosjwd = 0
      this.smsFailpage = 1
      this.smsFailpageQW = 1
      this.storeTaskpageQW = 1
      this.storePolySPage = 1
      this.isDataLoaded = false;
      this.radioCheck = '时间'
      this.dxruleGroupData = null
      this.qwruleGroupData = null
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });

      // this.getChannel(selectedOptionOne.channels)
      // 如果仅有一个元素，则将该元素的值作为 channel 的值
      this.ruleGroupChannelId = selectedOptionOne.ruleGroupChannelId
      // if (selectedOptionOne.channels.length === 1) {
      //   this.channel = selectedOptionOne.channels[0]
      //   this.radio1 = selectedOptionOne.channels[0]
      // } else {
      //   this.channel = 'ALL'
      //   // 否则，将 channel 的值设置为 'ALL'
      //   this.radio1 = '全部'
      //
      // }
      this.channel = selectedOptionOne.channel
      this.radio1 = selectedOptionOne.channel
      if (this.channel === '短信') {
        this.radio3 = '短信'
        this.radio5 = '短信'
        this.radioTR = 0
        this.getSmsFail();
      } else if (this.channel === '企微') {
        // this.getSmsFailQW()
        this.radio3 = '企微'
        this.radio5 = '企微'
        this.radioTR = 0
        this.getSmsFailQW()
        this.reachConsumeStoreTaskStatistics()
      } else {

        this.radio3 = '短信'
        this.radio5 = '短信'
        this.radioTR = 1
        this.getSmsFail();

      }
      this.getData(this.channel)
      this.getReachFailStatistics()
      this.getSmstoDayStatisticsData()
      this.ConsumeRuleGroupDetails()
      // setTimeout(() => {
      //   loading.close();
      // }, 1000);
    },


    async getSmsFailQW() {
      console.log('getSmsFailQW')
      try {
        // const response = await fetch('https://your-backend-api.com/data');
        // const data = await response.json();
        var bodyData = {
          page: this.smsFailpageQW,
          limit: this.smsFailpageSizeQW,
          ruleGroupId: this.ruleGroupId,
          channel: this.radio3,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        const res = await getSmsFailQW(bodyData)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组

        this.serverDataQW = resJson.data.datas;

        this.smsFailtotalQW = resJson.data.total;


      } catch (error) {
        console.error(error);
      }
    },
    async reachConsumeStoreTaskStatistics() {
      console.log('reachConsumeStoreTaskStatistics')
      try {
        // const response = await fetch('https://your-backend-api.com/data');
        // const data = await response.json();
        var bodyData = {
          page: this.storeTaskpageQW,
          limit: this.smsFailpageSizeQW,
          ruleGroupId: this.ruleGroupId,
          channel: this.radio3,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        getReachConsumeStoreTaskStatistics(bodyData).then(res => {
          this.storeTaskDataQW = res.data.datas
          this.storeTasktotalQW = res.data.total
        })


      } catch (error) {
        console.error(error);
      }
    },
    async ConsumeRuleGroupDetails() {
      var bodyData = {
        // ruleGroupName: this.radio2,
        version: this.version,
        ruleGroupChannelId: this.ruleGroupChannelId,
      }
      getConsumeRuleGroupDetails(bodyData).then(res => {
        console.log(getConsumeRuleGroupDetails)
        console.log(res.data)
        this.ruleGroupData = res.data
        // 循环遍历ruleGroupData数组
        this.ruleGroupData.forEach(item => {
          // 判断reachChannel的值
          if (item.reachChannel === '企微') {
            // 如果reachChannel是企微，赋值给qwruleGroupData
            console.log('qwruleGroupData')
            console.log(item)
            this.qwruleGroupData = item;
          } else if (item.reachChannel === '短信') {
            // 如果reachChannel是短信，dxruleGroupData
            console.log('dxruleGroupData')
            console.log(item)
            this.dxruleGroupData = item;
          }
        });

      })
    },
    async getReachFailStatistics() {
      console.log('getReachFailStatistics')
      try {
        // const response = await fetch('https://your-backend-api.com/data');
        // const data = await response.json();
        var bodyData = {
          ruleGroupId: this.ruleGroupId,
          channel: this.radio3,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        getReachFailStatistics(bodyData).then(res => {
          this.WcdTotalFailNumData = res.data;
        })

      } catch (error) {
        console.error(error);
      }
    },
    async getSmsFail() {
      console.log('getSmsFail')
      try {
        // const response = await fetch('https://your-backend-api.com/data');
        // const data = await response.json();
        var bodyData = {
          page: this.smsFailpage,
          limit: this.smsFailpageSize,
          ruleGroupId: this.ruleGroupId,
          channel: this.radio3,
          version: this.version,
          ruleGroupChannelId: this.ruleGroupChannelId,
        }
        const res = await getSmsFail(bodyData)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组

        this.serverData = resJson.data.datas;

        this.smsFailtotal = resJson.data.total;


        const smsFaily = this.serverData.map(option => option.reachErrorMsg);
        const smsFailx = this.serverData.map(option => parseFloat(option.reachFailNumPct.replace('%', '')));
        this.chartOptions = {
          title: {
            text: '人数占比'
          },
          tooltip: {},
          xAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} %' // 标签内容格式化模板，value 代表刻度数值
            }
          },
          yAxis: {
            type: 'category',
            data: smsFaily
          },
          series: [{
            name: '短信发送失败原因',
            type: 'bar',
            data: smsFailx
          }]
        }


      } catch (error) {
        console.error(error);
      }
    }
    ,
    async GroupData() {
      console.log('GroupData')
      console.log('dateRangeValue')
      console.log(this.dateRangeValue)
      try {
        // const response = await fetch('https://your-backend-api.com/data');
        // const data = await response.json();
        if (this.dateRangeValue == null) {
          const bodyData = {
          }
          getCDGroupData(bodyData).then(res => {
            this.GroupDataList = res.data;
            this.GroupDataListCD = res.data.filter(item => !item.name.includes('未触达'));
            this.GroupDataListOriginal = res.data.filter(item => !item.name.includes('未触达'));
          });
        } else {
          const bodyData = {
            startDate: this.dateRangeValue[0],
            endDate: this.dateRangeValue[1],
          }
          getCDGroupData(bodyData).then(res => {
            this.GroupDataList = res.data
            this.GroupDataListCD = res.data.filter(item => !item.name.includes('未触达'))
            this.GroupDataListOriginal = res.data.filter(item => !item.name.includes('未触达'))
          });
        }


        // const promise1 = Promise.resolve(result);
        // promise1.then((value) => {
        //   this.GroupDataList = JSON.parse(JSON.stringify(value)).data
        //   this.GroupDataListCD = this.GroupDataList.filter(item => !item.name.includes('未触达'))
        //   // console.log(this.GroupDataList)
        //   // this.total = JSON.parse(JSON.stringify(value)).data.count;
        //
        //
        // });


      } catch (error) {
        console.error(error);
      }
    }
    ,
  }

}
</script>

<style lang="scss" scoped>
::v-deep .row-class-1 {
  background-color: #f0f9eb;;
}

::v-deep {
  .row-class-2 {
    background-color: #75886e;
  }
}

.rea_ {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  height: 100%;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
}

.rea_ > * {
  flex-grow: 1;
}

.progress-container {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #e5e9f2;
  margin-top: 2%;
  /* 设置在小屏幕下占用12列的宽度 */
  //@media (min-width: 768px) {
  //  width: 50%;
  //}
}

// 进度条百分比的文字
.progress-summary {
  margin-top: 2%;
  width: 100%;
  font-size: 14px;
}


.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #33b5e5;
}

.progress-bar2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #5cb87a;
}

.progress-bar3 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #5cb87a;
}

.progress-bar4 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1989fa;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
}

.progress-value {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.progress-total {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.percentage {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}


.top-title {
  margin-top: 1%;
  //margin-bottom: 1%;
  display: flex;
  justify-content: space-between; //justify-content: space-between; 属性将左右两个部分放到两端
  align-items: center;
  height: 100%;
  padding: 0 20px;
  color: #F56C6C;
}

.title-left {
  flex: 1;
  display: flex;
  align-items: center;

}

.title-right {
  display: flex;
  justify-content: flex-end; //justify-content: flex-end; 属性将其内容右对齐
  align-items: center;
  flex: 1;
}

.chart-container {
  position: relative;
  height: auto;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 3%;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;

}


//.chart-container canvas {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//}

/* 文本放进度条右侧*/
.progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//标题
.header {
  display: flex;
  align-items: center;
  padding-bottom: 1%;
  padding-top: 1%;
}

// 标题选项间设置
.child-header {
  font-size: 15px;
  padding-left: 1%;
  //padding-right: 5%;
}

//选项设置
.header-option {
  font-size: 10px;
  color: #150a0a;
  margin-right: 20px;
  //background: #f5f5f5;
}

.child-header-check {
  color: #333;
  font-size: 16px;
}

.child-header-one {
  font-size: 15px;
  padding-left: 1%;
  color: #333;
  //padding-right: 5%;
}

.store-polycontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.store-polytable-wrapper {
  flex: 1;
  overflow-y: auto;
}

.store-polypagination-wrapper {
  padding-top: 0.5%;
  padding-bottom: 1%;
}

// 表根内进度条
.progress-container-table {
  width: 100%;
  height: 100%;
  border-radius: 0;

}

.end-page {
  padding-bottom: 5%;
  padding-top: 10%;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

//.download-btn {
//  position: absolute;
//  top: 0;
//  right: 0;
//  margin: 10px;
//}
.title-cl {
  font-size: 12px;
  padding-left: 1%;
  padding-top: 1%;
}


</style>
