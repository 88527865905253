
<template>
    <mg-container
        :isShowTips="true"
        :isDisablePage="tipsConfig.isDisablePage"
        :tipsConfig="(tipsConfig.tipsType && tipsConfig.tips[tipsConfig.tipsType]) || tipsConfig.tips['default']"
    >
        <!-- 头部搜索 -->
        <template #headerSearch>
            <div class="head-signboard-box">
                <mg-signboard title="要货确认" />
                <mg-signboard :title="branchCompanyInfos && branchCompanyInfos.name" :isHavBar="false" />
            </div>
            <mg-search-form
                ref="mgSearchFormRef"
                :form-item-list="formItemList"
                :row="2"
                label-width="110px"
                size="mini"
                @getSearchFormData="handleSearch"
                @isToggleForm="handleToggleForm"
            >
                <template #buttonScope>
                    <el-button
                        size="mini"
                        type="primary"
                        :disabled="btnStatus.isSave"
                        @click="handleTableHeadAction('save')"
                    >保存</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('confirmPurchase')"
                    >确认要货</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('abnormalCheck')"
                    >异常检核</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('importEstimatedSales')"
                    >导入预估销量</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        :loading="btnStatus.isExport"
                        @click="handleTableHeadAction('export')"
                    >导出</el-button>
                </template>
            </mg-search-form>
        </template>
        <!-- 列表 -->
        <template #table>
             <mg-vxe-table
                class="mg-list-box"
                ref="mgTable"
                :autoLoad="true"
                :columns.sync="purchaseConfirmationColumns"
                :api="handleCargoConfirmLists"
                :apiParams="queryForm"
                :row-config="{
                    keyField: 'cargoNo'
                }"
                :isRowClick="false"
                :configurable="true"
                businessType="BRANCHOFFICE_PURCHASECONFIRMATION_LISTS"
                :extraParams="{ isEdit: false, isSelect: false }"
                :tableHeight="tableHeight"
                @selection-change="handleSelection"
                @select="handleSelect"
            >
                <template #checkbox>
                    <vxe-column key="checkbox" type="checkbox" fixed="left" width="50px"></vxe-column>
                </template>
                <template #boNextMthConfirmOrderCount="{ row }">
                    <el-input
                        size="mini"
                        :disabled="!row.isEdit"
                        v-model.number="row.boNextMthConfirmOrderCount"
                        @change="handleChangeRow('boNextMthConfirmOrderCount', row)"
                    ></el-input>
                </template>
                <template #boNextTwoMthConfirmOrderCount="{ row }">
                    <el-input
                        size="mini"
                        :disabled="!row.isEdit"
                        v-model.number="row.boNextTwoMthConfirmOrderCount"
                        @change="handleChangeRow('boNextTwoMthConfirmOrderCount', row)"
                    ></el-input>
                </template>
                <template #exceptionTypes="{ row }">
                    {{ row.exceptionTypes || '' }}
                </template>
                <template #boNoSaleDays="{ row }">
                  <span :style="{color: row.boNoSaleDays >= 30 ? 'red': 'black'}">{{ row.boNoSaleDays }}</span>
                </template>
                <template #operate="{ row }">
                    <el-button-group>
                        <el-button size="mini" :type="!row.isEdit ? 'primary' : ''" @click="handleTableAction('default', row)">默认</el-button>
                        <el-button size="mini" :type="row.isEdit ? 'primary' : ''" @click="handleTableAction('edit', row)">修改</el-button>
                    </el-button-group>
                </template>
            </mg-vxe-table>
        </template>
        <!-- 要货确认异常清单 -->
        <el-dialog
            class="dialog-box"
            width="80%"
            :close-on-click-modal="false"
            :visible.sync="dialogObj.isConfirm"
            :destroy-on-close="true"
        >
            <div class="header" slot="title">
                <span class="title">要货异常</span>
                <el-button class="refreshBtn" size="mini" icon="el-icon-refresh" @click="handleRefreshBtn" ></el-button>
            </div>
            <mg-table
                v-if="dialogObj.isConfirm"
                :isShowTopBar="true"
                :autoLoad="true"
                :columns="dialogObj.dialogColumns"
                :api="dialogObj.api"
                :apiParams="dialogObj.queryForm"
                :extraParams="{ isEdit: 1 }"
                ref="dialogMgTable"
                @selection-change="HandleSelectRows"
            >
                <template #topleft>
                    <el-button type="primary" size="mini" @click="handleBatchConfirm" :disabled="dialogObj.selectRows.length === 0">批量确认</el-button>
                    <el-button type="primary" size="mini" :loading="uploadLoading" @click="handleUploadFile" >导入</el-button>
                    <el-button type="primary" size="mini" :loading="downloadLoading" @click="handleDownloadFile" >导出</el-button>
                    <el-button size="mini" :loading="downloadLoading" @click="handleDownloadTemplate" >模版下载</el-button>
                </template>
                <template #selection>
                    <el-table-column type="selection" fixed="left"></el-table-column>
                </template>
                <template #exceptionTypes="{ row }">
                    {{ row.exceptionTypes && enumTypes.exceptionTypeLists[row.exceptionTypes] || '--' }}
                </template>
                <template #boNextMthConfirmOrderCount="{ row }">
                    <el-input
                        size="mini"
                        v-input-number
                        :disabled="[1, 3].includes(row.isEdit)"
                        clearable
                        v-model="row.boNextMthConfirmOrderCount"
                        @change="handleDialogRowChange(row)"
                    ></el-input>
                </template>
                <el-table-column show-overflow-tooltip label="操作" min-width="120px" fixed="right" align="center">
                    <template #default="{ row }">
                        <el-button-group>
                            <el-button size="mini" :type="row.isEdit === 3 ? 'primary' : ''" @click="handleDialogRowEvent('confirm', row)">确认</el-button>
                            <el-button size="mini" :type="row.isEdit === 2 ? 'primary' : ''" @click="handleDialogRowEvent('edit', row)">修改</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </mg-table>
            <!-- 操作按钮 -->
            <div class="footer" slot="footer">
                <el-button size="small" @click="handleDialogEvent('cancel')">取 消</el-button>
                <el-button size="small" type="primary" :disabled="dialogObj.lists.length > 0" :loading="btnStatus.isConfirm" @click="handleDialogEvent('confirm')">确 认</el-button>
            </div>
        </el-dialog>
        <!-- 导入弹窗 -->
        <mg-importDialog
            :visible="importDialog.visible"
            :urlParams="urlParams"
            :importBaseParams="importBaseParams(importDialog.importBaseParams)"
            @handleImportSuccess="handleImportSuccess"
        />
    </mg-container>
  </template>

<script>
import {
    urlParams,
    branchCompanyInfos,
    optionsLists
} from '@/mixins';
import { excelExport } from "@/utils";
import { purchaseConfirmationColumns, dialogColumns } from '../config';
import {
    handleCargoConfirmLists,
    handleCargoConfirmEdit,
    handleConfigProductUpdateMonth,
    handleCargoConfirmCheckSubmit,
    handleCargoConfirmCheckDetails,
    handleCargoConfirmCheckDetailsUpdate,
    handleCargoConfirmCheckDetailsConfirm,
    handleCargoConfirmCheckDetailsThrowOrder,
    handleCargoConfirmCheckDetailsBatchConfirm,
    handleCargoConfirmCheckImport,
    handleCargoConfirmCheckExport,
} from '@/api/branchOffice';
import { pageDataAvailable } from '../mixins'
export default {
    name: 'purchaseConfirmation',
    mixins: [ urlParams, branchCompanyInfos, optionsLists, pageDataAvailable ],
    components: {
        mgContainer: () => import('@/mg-components/mg-container'),
        mgSearchForm: () => import("@/mg-components/mg-search-form"),
        mgTable: () => import("@/mg-components/mg-table"),
        mgVxeTable: () => import("@/mg-components/mg-vxe-table"),
        mgSignboard: () => import('@/components/Signboard'),
        mgImportDialog: () => import('../components/importDialog')
    },
    computed: {
        importBaseParams() {
            const importDialog = this.importDialog;
            return (importParams) => {
                return importParams.find(item => item.type === importDialog.currentImportType)
            }
        },
        tableHeight() {
            const otherParams = this.otherParams
            return otherParams.isToggleForm ? 'calc(100vh - 360px)' : 'calc(100vh - 300px)'
        }
    },
    data() {
        return {
            formItemList: [
                {
                    type: 'select',
                    label: '赛道',
                    model: 'race',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'select',
                    label: '大类',
                    model: 'mainCategoryName',
                    initialValue: '',
                    options: [],
                    change: this.handleMainCategoryNameChange
                },
                {
                    type: 'select',
                    label: '中类',
                    model: 'midCategoryName',
                    initialValue: '',
                    options: [],
                    change: this.handleMidCategoryNameChange
                },
                {
                    type: 'select',
                    label: '小类',
                    model: 'subCategoryName',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'select',
                    label: '产品等级',
                    model: 'grade',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'input',
                    label: '货号',
                    model: 'cargoNo',
                    initialValue: '',
                    placeholder: '请输入货号'
                },
                {
                    type: 'input',
                    label: '品名',
                    model: 'skuName',
                    initialValue: '',
                    placeholder: '请输入品名'
                },
                {
                    type: 'input',
                    label: '料号',
                    model: 'materialCode',
                    initialValue: '',
                    placeholder: '请输入料号'
                },
                {
                    type: 'select',
                    label: '是否有异常',
                    model: 'whetherAbnormal',
                    initialValue: '',
                    options: [
                        { label: '是', value: '1' },
                        { label: '否', value: '0' },
                    ]
                },
                {
                    type: 'select',
                    label: '是否下单',
                    model: 'whetherPlaceOrder',
                    initialValue: '',
                    options: [
                        { label: '是', value: '1' },
                        { label: '否', value: '0' },
                    ]
                },
                {
                    type: 'select',
                    label: '次月库存系数状态',
                    model: 'boNextMthExpectKcRatioStatus',
                    initialValue: '',
                    options: [
                        { label: '超过库存上限', value: '1' },
                        { label: '低于库存下限', value: '2' },
                        { label: '库存正常', value: '3' }
                    ]
                },
                {
                    type: 'select',
                    label: '次月库存系数',
                    model: 'boNextMthExpectKcRatioRange',
                    initialValue: [],
                    options: [
                        { label: '小于0', value: '小于0' },
                        { label: '(0, 0.5]', value: '(0, 0.5]' },
                        { label: '(0.5, 1]', value: '(0.5, 1]' },
                        { label: '(1, 1.5]', value: '(1, 1.5]' },
                        { label: '(1.5, 2]', value: '(1.5, 2]' },
                        { label: '(2, 2.5]', value: '(2, 2.5]' },
                        { label: '(2.5, 3]', value: '(2.5, 3]' },
                        { label: '(3, 3.5]', value: '(3, 3.5]' },
                        { label: '大于3.5', value: '大于3.5' }
                    ]
                },
                {
                    type: 'select',
                    label: '未动销天数',
                    model: 'boNoSaleDays',
                    initialValue: '',
                    options: [
                        { label: '小于30天', value: '小于30天' },
                        { label: '30-90天', value: '30-90天' },
                        { label: '90天以上', value: '90天以上' }
                    ]
                },
                {
                    type: 'select',
                    label: '是否当季商品',
                    model: 'isSingleSeasonSku',
                    initialValue: '',
                    options: [
                        { label: '是', value: '是' },
                        { label: '否', value: '否' }
                    ]
                },
                {
                    type: 'select',
                    label: '是否可订货',
                    model: 'whetherKdh',
                    initialValue: '',
                    options: [
                        { label: '是', value: '1' },
                        { label: '否', value: '0' },
                    ]
                },
                {
                    type: 'select',
                    label: '检核异常类型',
                    model: 'exceptionTypes',
                    initialValue: '',
                    options: [
                        { label: '正常', value: '正常' },
                        { label: '缺货风险', value: '缺货风险' },
                        { label: '超量下单', value: '超量下单' }
                    ]
                }
            ],
            purchaseConfirmationColumns,
            btnStatus: {
                isSave: true,                   // 是否保存
                isExport: false,                // 是否导出
                isConfirm: false,               // 是否确认抛单
            },
            dialogObj: {
                isConfirm: false,
                dialogColumns,
                queryForm: {
                    page: 1,
                    limit: 20,
                    month: this.$dayjs().format('YYYYMM')
                },
                api: handleCargoConfirmCheckDetails,
                lists: [],
                selectRows: []
            },
            handleCargoConfirmLists,
            queryForm: {
                page: 1,
                limit: 20,
                race: '',
                mainCategoryName: '',
                midCategoryName: '',
                subCategoryName: '',
                grade: '',
                cargoNo: '',
                skuName: '',
                materialCode: '',
                whetherAbnormal: '',
                whetherPlaceOrder: '',
                boNextMthExpectKcRatioStatus: '',
                boNextMthExpectKcRatioRange: '',
                boNoSaleDays: '',
            },
            selectRows: [],
            importDialog: {
                currentImportType: 'importEstimatedSales',
                importBaseParams: [
                    {
                        visible: false,
                        title: '批量导入预估销量',
                        type: 'importEstimatedSales',
                        tipsText: '必需字段: 商品编码、本月待销量、次月预估销量',
                        optionalTipsText: '选填字段: 次次月预估销量',
                        templateSampleHead: [
                            {label: '商品编码', prop: 'skuCode', minWidth: '80px'},
                            {label: '本月待销量', prop: 'thisMthExpectSaleCount', minWidth: '80px'},
                            {label: '次月预估销量', prop: 'nextMthExpectSaleCount', minWidth: '80px'},
                            {label: '次次月预估销量', prop: 'nextTwoMthExpectSaleCount', minWidth: '80px'},
                        ],
                        templateSampleLists: [
                            {
                                skuCode: '00000011',
                                thisMthExpectSaleCount: 1,
                                nextMthExpectSaleCount: 1,
                                nextTwoMthExpectSaleCount: 1,
                            }
                        ],
                        api: handleConfigProductUpdateMonth,
                        apiParams: 'dorisCompanySalesDtos',
                        templateFileDownloadUrl: `${ process.env.VUE_APP_OSS_TEMPLATE_PATH }/导入预估销量模板.xlsx`
                    },
                ]
            },
            customColumnSortDialogParams: {
                visible: false,
                columns: purchaseConfirmationColumns,
                orginColumns: purchaseConfirmationColumns,
                disabledCols: ['-', 'cargoNo'],
            },
            storageKey: 'pdb_purchaseConfirmation',
            uploadLoading: false,
            downloadLoading: false,
            // 枚举值集合
            enumTypes: {
                // 异常类型
                exceptionTypeLists: {
                    '1': '次月底负库存',
                    '2': '次月底超高库存',
                },
                // 检核异常类型
                exceptionTypes: {
                    '0': '缺货风险',
                    '1': '超量下单'
                }
            },
            // 辅助参数
            otherParams: {
                // 是否收起表单
                isToggleForm: false
            }
        }
    },
    watch: {
        selectRows: {
            handler(n) {
                const btnStatus = this.btnStatus;
                if (n.length > 0) {
                    btnStatus.isSave = false;
                }
                if (n.length === 0) {
                    btnStatus.isSave = true;
                }
            }
        }
    },
    mounted() {
        const queryForm = this.queryForm;
        this.urlMonth = this.$route.query?.month || '';
        this.handleUrlParams().then(res => {
            if ( !(res && res['x-token']) ) {
                this.queryForm = {
                    ...res,
                    ...queryForm
                }
            }
            this.handleSelectOptions()
        });
    },
    methods: {
        // 表头搜索
        handleSearch(params) {
            this.queryForm = {
                ...this.queryForm,
                ...params,
                boNextMthExpectKcRatioRange: params?.boNextMthExpectKcRatioRange?.join(';') || null
            }
            this.handleFetchData()
        },
        // 加载列表数据
        handleFetchData() {
            this.$nextTick(() => {
                this.$refs['mgTable']?.loadData()
            })
        },
        // 表单顶部事件
        handleTableHeadAction(type) {
            const form = this.form;
            const dialogObj = this.dialogObj;
            const importDialog = this.importDialog;
            /**
            * save                     -- 保存
            * confirmPurchase          -- 确认要货
            * importEstimatedSales     -- 导入预估销量
            * export                   -- 导出
            */
            const funcObj = {
                save: async () => {
                    const paramsObj = {
                        boRequireCargoBatchUpdateRequest: this.selectRows,
                    }
                    try {
                        const { code, msg } = await handleCargoConfirmEdit(paramsObj)
                        if (code === 200) {
                            this.handleFetchData();
                            return this.$message.success(msg)
                        }
                        if (code !== 200) return this.$message.error(msg)
                    } catch(err) {
                        throw new Error(err)
                    }
                },
                confirmPurchase: async () => {
                    try {
                        const { code, data, msg } = await handleCargoConfirmCheckSubmit(dialogObj.queryForm);
                        if (code === 200) return this.$message.success('确认要货已成功')
                    } catch(err) {
                        throw new Error(err)
                    }
                },
                abnormalCheck: () => {
                    dialogObj.isConfirm = true;
                    setTimeout(() => {
                        dialogObj.lists = this.$refs['dialogMgTable']?.list || []
                    }, 500)
                },
                importEstimatedSales: () => {
                    if(['importEstimatedSales'].includes(type)) {
                        const currentImportOption = importDialog.importBaseParams.find(item => item.type === type);
                        importDialog.currentImportType = currentImportOption.type;
                        currentImportOption.visible = true;
                        return false;
                    }
                },
                export: async () => {
                    const btnStatus = this.btnStatus;
                    const queryForm = this.queryForm;
                    this.handleResetFields();
                    const topHeaders = {}
                    const filterPurchaseConfirmationColumns = this.purchaseConfirmationColumns.filter(item => item.prop)
                    filterPurchaseConfirmationColumns.map(item => {
                        return topHeaders[item.prop] = item.label
                    })
                    try {
                        btnStatus.isExport = true;
                        queryForm.page = 1;
                        queryForm.limit = 1000000;
                        const { code, data, msg } = await handleCargoConfirmLists(queryForm);
                        if ( code === 200 && data?.datas.length > 0) {
                            const newItems = data.datas && data.datas.map(item => {
                                return {
                                ...item,
                                }
                            }) || [];
                            excelExport({
                                topHeaders,
                                data: newItems,
                                title: '要货确认列表'
                            })
                        }
                    } catch(err) {
                        throw new Error(err)
                    } finally {
                        btnStatus.isExport = false;
                    }
                }
            };
            funcObj[type]()
        },
        handleResetFields() {
            this.queryForm = {
                page: 1,
                limit: 20,
                race: '',
                mainCategoryName: '',
                midCategoryName: '',
                subCategoryName: '',
                grade: '',
                cargoNo: '',
                skuName: '',
                materialCode: '',
                whetherAbnormal: '',
                whetherPlaceOrder: '',
                boNextMthExpectKcRatioStatus: '',
                boNextMthExpectKcRatioRange: '',
            };
            this.$refs['mgTable'].pagination.page = 1;
            this.$refs['mgTable'].pagination.limit = 20;
        },
        // dialog行操作事件
        handleDialogRowEvent(type, row) {
            /**
            * confirm  -- 确认
            * edit     -- 修改
            */
            const funcObj = {
                confirm: () => {
                    if ( [1, 2].includes(row.isEdit) ) row.isEdit = 3;
                    this.$confirm('此操作: 会将异常检核状态标记为手工确认, 在异常检核清单中不显示, 是否确定?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.handleDialogRowSubmitEvent('confirm', { ...row, status: 2 }).finally(() => {
                            row.isEdit = 1;
                        });
                    }).catch(() => {
                        row.isEdit = 1;
                        this.$message.info('已取消确认');
                    });

                },
                edit: () => {
                    if ( [1, 3].includes(row.isEdit) ) row.isEdit = 2;
                }
            };
            funcObj[type]()
        },
        // 检核列表行操作事件
        handleDialogRowSubmitEvent(type, row) {
            const dialogObj = this.dialogObj;
            const apis = {
                confirm: handleCargoConfirmCheckDetailsConfirm,
                edit: handleCargoConfirmCheckDetailsUpdate,
            };
            const tips = {
                confirm: '已确认成功',
                edit: '已修改成功'
            }
            return new Promise(async (resolve, reject) => {
                try {
                    const paramsObj = {
                        ...row,
                    }
                    const { code, msg } = await apis[type](paramsObj);
                    this.$refs['dialogMgTable']?.loadData().then(res => {
                        dialogObj.lists = res?.data?.datas || []
                    });
                    if (code === 200) this.$message.success(tips[type]);
                    resolve();
                } catch(err) {
                    reject(err)
                    throw new Error(err)
                }
            })
        },
        // 要货检核弹窗 -- 要货量改变时监听事件
        handleDialogRowChange(row) {
            this.handleDialogRowSubmitEvent('edit', row).finally(() => {
                row.isEdit = 1;
            });
        },
        // 弹窗底部事件
        handleDialogEvent(type) {
            const dialogObj = this.dialogObj;
            const btnStatus = this.btnStatus;
            /**
            * cancel   -- 取消
            * confirm  -- 确认
            */
            const funcObj = {
                cancel: () => {
                    dialogObj.isConfirm = false;
                    if ( btnStatus.isConfirm ) btnStatus.isConfirm = false;
                },
                confirm: () => {
                    const queryForm = this.queryForm;
                    new Promise(async (resolve, reject) => {
                        try {
                            btnStatus.isConfirm = true;
                            queryForm.page = 1;
                            queryForm.limit = 1000000;
                            const { code, data, msg } = await handleCargoConfirmLists(queryForm);
                            if (code === 200) {
                                const items = data.datas || []
                                resolve(items)
                            }
                            if (code !== 200) btnStatus.isConfirm = false;
                        } catch(err) {
                            reject(err)
                            btnStatus.isConfirm = false;
                            throw new Error(err)
                        }
                    }).then(async res => {
                        if (res?.length > 0) {
                            try {
                                const currentMonth = this.$dayjs().format('YYYYMM');
                                const paramsObj = {
                                    month: currentMonth,
                                    status: 1
                                };
                                const { code, msg } = await handleCargoConfirmCheckDetailsThrowOrder(paramsObj)
                                if (code === 200) {
                                    this.handleResetFields();
                                    this.handleFetchData();
                                    return this.$message.success('DRP抛单成功')
                                }
                            } catch(err) {
                                throw new Error(err)
                            } finally {
                                dialogObj.isConfirm = false;
                                btnStatus.isConfirm = false;
                            }
                        }
                    }).catch(err => {
                        btnStatus.isConfirm = false;
                        throw new Error(err)
                    })
                },
            };
            funcObj[type]()
        },
        // 表格操作
        handleTableAction(type, row) {
            const funcObj = {
                default:() => {
                    if ( row.isEdit ) {
                        row.isEdit = false;
                        this.$refs['mgTable'].$refs['mgTable'].setCheckboxRow(row, false)
                    }
                },
                edit: () => {
                    if ( !row.isEdit ) {
                        row.isEdit = true;
                        this.$refs['mgTable'].$refs['mgTable'].setCheckboxRow(row, true)
                    }
                }
            };
            funcObj[type]()
        },

        // 行input变化监听
        handleChangeRow(type, row) {
            const reg = /^[0-9]d*$/;
            console.log(type, row);
            const funcObj = {
                boNextMthConfirmOrderCount: () => {
                    if (!String(row.boNextMthConfirmOrderCount)) return this.$message.error('次月确认要货量不能为空')
                    if (String(row.boNextMthConfirmOrderCount) && !reg.test(row.boNextMthConfirmOrderCount)) return this.$message.error('格式有误, 须大于0的整数')
                },
                boNextTwoMthConfirmOrderCount: () => {
                    if (!String(row.boNextTwoMthConfirmOrderCount)) return this.$message.error('次次月确认要货量不能为空')
                    if (String(row.boNextTwoMthConfirmOrderCount) && !reg.test(row.boNextTwoMthConfirmOrderCount)) return this.$message.error('格式有误, 须大于0的整数')
                }
            };
            funcObj[type]();
        },
        // 多选时函数监听
        handleSelection(e) {
            this.selectRows = e;
        },
        handleSelect(e, row) {
            if (row.isEdit) row.isEdit = false;
            else row.isEdit = true;
        },
        // 导入成功回调
        handleImportSuccess(e) {
            const importDialog = this.importDialog;
            if(['importEstimatedSales'].includes(e.type)) {
                const currentImportOption = importDialog.importBaseParams.find(item => item.type === e.type);
                importDialog.currentImportType = currentImportOption.type;
                currentImportOption.visible = false;
            }
            this.handleFetchData();
        },
        // 要货检核弹窗顶部列表刷新
        handleRefreshBtn() {
            const dialogObj = this.dialogObj;
            const dialogMgTableRefs = this.$refs['dialogMgTable'];
            dialogObj.queryForm.page = 1;
            dialogObj.queryForm.limit = 20;
            dialogMgTableRefs.pagination.page = 1;
            dialogMgTableRefs.pagination.limit = 20;
            dialogMgTableRefs?.loadData().then(res => {
                dialogObj.lists = res?.data?.datas || []
            });
        },
        // 要货检核异常批量确认
        async handleBatchConfirm() {
            const dialogObj = this.dialogObj;
            try {
                const { code, msg } = await handleCargoConfirmCheckDetailsBatchConfirm(dialogObj.selectRows);
                if (code === 200) {
                    this.$refs['dialogMgTable']?.loadData().then(res => {
                        dialogObj.lists = res?.data?.datas || []
                    });
                    return this.$message.success(msg)
                }
            } catch(err) {
                throw new Error(err)
            }
        },
        // 导入
        handleUploadFile() {
            this.chooseFile('application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').then(res => {
                const formData = new FormData()
                formData.append('file', res);
                this.uploadLoading = true;
                handleCargoConfirmCheckImport(formData).then(() => {
                    this.handleRefreshBtn();
                }).finally(() => {
                    this.uploadLoading = false;
                });
            })

        },
        // 导出
        handleDownloadFile() {
            this.downloadLoading = true;
            handleCargoConfirmCheckExport({}).then(res => {
                if(res.code) return;
                var blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                const url = URL.createObjectURL(blob);
                const alink = document.createElement('a');
                alink.style.display = 'none';
                alink.href = url;
                alink.setAttribute('download', '确认要货检核数据.xlsx');
                alink.click();
                URL.revokeObjectURL(alink.href);
                document.body.removeChild(alink)
            }).finally(() => {
                this.downloadLoading = false;
            });

        },
        // 模版下载
        handleDownloadTemplate() {
            window.open('/template/检核确认数据模版.xlsx')
        },
        // 检核异常批量选择
        HandleSelectRows(rows) {
            this.dialogObj.selectRows = rows.map(item => {
                return {
                    ...item,
                    status: 2
                }
            });
        },
        // 选择文件
        chooseFile(accept = "image/*") {
            return new Promise((resolve,) => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept=accept;
                input.onchange = e => {
                    console.log(e);
                    resolve(input.files[0])
                };
                input.click();
            })
        },
        // 下拉列表选项
        handleSelectOptions() {
            // 产品等级
            const gradeField = {
                key: 'grade',
                label: 'label',
                value: 'value'
            }
            // 赛道
            const raceField = {
                key: 'race',
                label: 'label',
                value: 'value'
            }
            // 大类
            const categoryField = {
                key: 'mainCategoryName',
                label: 'max_name',
                value: 'max_code'
            }
            const paramsObj = {
                dict_type: 'plu_grade',
                operator: this.urlParams?.operator || ''
            }
            Promise.all([
                this.handleProductGradeOption({ formItemList: this.formItemList, field: gradeField, paramsObj }),
                this.handleRaceNamesOption({ formItemList: this.formItemList, field: raceField, paramsObj }),
                this.handleProductMaxCatagoryOption({ formItemList: this.formItemList, field: categoryField, paramsObj })
            ])
        },
        // 根据大类获取中类
        handleMainCategoryNameChange(val) {
            const queryForm = this.queryForm
            const mgSearchFormRef = this.$refs['mgSearchFormRef']
            const formItemList = this.formItemList
            queryForm.midCategoryCode = '';
            mgSearchFormRef.form['midCategoryName'] = ''
            if (!val) return;
            const paramsObj = {
                max_code: val,
                operator: this.urlParams?.operator || ''
            };
            const field = {
                key: 'midCategoryName',
                label: 'mid_name',
                value: 'mid_code'
            }
            this.handleProductMidCatagoryOption({ formItemList: formItemList, field, paramsObj })
        },
        // 根据中类获取小类
        handleMidCategoryNameChange(val) {
            const queryForm = this.queryForm
            const mgSearchFormRef = this.$refs['mgSearchFormRef']
            const formItemList = this.formItemList
            queryForm.subCategoryName = '';
            mgSearchFormRef.form['subCategoryName'] = ''
            if (!val) return;
            const paramsObj = {
                mid_code: val,
                operator: this.urlParams?.operator || ''
            };
            const field = {
                key: 'subCategoryName',
                label: 'min_name',
                value: 'min_code'
            }
            this.handleProductMinCatagoryOption({ formItemList: formItemList, field, paramsObj })
        },
        // 是否收起
        handleToggleForm(val) {
            this.otherParams.isToggleForm = val
        }
    }
}
</script>

<style lang="scss" scoped src="@/style/branchOffice/common.scss"></style>
<style lang="scss" scoped src="@/style/branchOffice/purchaseConfirmation.scss"></style>
<style lang="scss" scoped>
    .mg-list-box {
        ::v-deep {
            .vxe-header--row .col--checkbox {
                .c--title {
                    width: 36px!important;
                }
            }
        }
    }
</style>
