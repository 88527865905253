<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      
    }
  }
}
</script>
<style lang="scss" scoped>
  #app {
    background: #f9f9f9;
  }
</style>
