<template>
  <div style="padding: 10px;" v-loading="targetLoading">
    <div class="mg-search" style="padding-bottom: 5px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
        <DictSelect
          ref="org"
          type="org"
          :value.sync="queryForm.orgCode"
          linkageRef="dc,capacity,area-tag"
          :dictFun="dictFun"
          fullLabel
          default-first-option
          :clearable="false"
          @changeLinkageRef="handleChangeLinkageRef"
        />
      </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            linkageRef="capacity,area-tag,ht-shop"
            :dictFun="dictFun"
            fullLabel
            @getOtherJsonParam="(callback) => callback(dictParam)"
            parentLinkageRef
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
            ref="area-tag"
            type="area-tag"
            :value.sync="queryForm.areaTag"
            :dictFun="dictFun"
            parentLinkageRef
            alwaysChange
            :clearable="false"
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="年份">
          <DictSelect ref="year" :value.sync="queryForm.year" version="v2" singleProps type="year" init :init-options="yearOptions" :defaultIndex="0" :clearable="false" />
        </el-form-item>
        <el-form-item>
          <el-button :disabled="!(queryForm.orgCode && queryForm.dcCode && queryForm.areaTag)" class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
        </el-form-item>
        <div>
          <el-row>
            <el-col :span="4">
              <span style="margin-right:12px;font-weight: 700;">设置方式</span>
              <el-select v-model="queryForm.type" size="mini" placeholder="请选择设置方式" @change="handleSettingChange">
                <el-option value="1" label="设置区域整体目标"/>
                <el-option value="2" label="设置单店目标" />
              </el-select>
            </el-col>
            <el-col :span="18">
              <el-button v-if="queryForm.type === '2'" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('shop')">导入单店目标</el-button>
              <el-button v-if="queryForm.type === '2'" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('shop')">导出单店目标</el-button>
              <el-button v-if="queryForm.type === '1'" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('area')">导入区域目标</el-button>
              <el-button v-if="queryForm.type === '1'" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('area')">导出区域目标</el-button>
              <el-form-item v-if="queryForm.type === '2'" label="店铺编码" style="margin-left: 5px;">
                <DictSelect
                  ref="shop"
                  type="ht-shop"
                  :value.sync="queryForm.shopCode"
                  :dictFun="dictFun"
                  fullLabel
                  alwaysChange
                  remote
                  @getOtherJsonParam="(callback) => callback(dictParam)"
                  @changeLinkageRef="handleChangeLinkageRef"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div v-if="queryForm.type === '2'" class="div-class">
      <el-tabs v-model="activeTab" type="card" @tab-click="(val) => {
        if (val.name == 'x') {
          this.queryForm.yearMth = this.queryForm.year;
        } else {
          this.queryForm.yearMth = this.queryForm.year + val.name;
        }
        handleFilter()
      }">
        <el-tab-pane v-for="item in dateTabs" :label="item.label" :name="item.value" :key="item.value" />
      </el-tabs>
    </div>
    <div v-if="queryForm.type === '2'" class="div-class">
      <el-table
        :data="pageData.rows"
        height="calc(100vh - 350px)"
        v-loading="loading"
        size="mini"
        border
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="店铺编码/名称" prop="shop" width="300">
          <template #default="{ row }">
            <span> {{ row.shopCode==='ALL' ? '全部':row.shopCode + "-" + row.shopName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属配送中心" prop="dcName">
          <template #default="{ row }">
            <span>{{ row.dcCode==='ALL' ? '全部':row.dcCode + "-" + row.dcName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="目标进货金额" prop="amountTarget" width="120">
          <template slot-scope="{ row }">
            <el-input-number
              v-model="row.amountTarget"
              placeholder="请输入"
              style="width: 100%;"
              size="mini"
              :precision="1"
              :min="0"
              :controls="false"
              :class="row.amountType!=='1' ? 'table-num-class': ''"
              @change="(cv, ov) => {
              }"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template #default="{ row }">
            <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleCommit(row)">保存</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
    </div>
    <div v-if="queryForm.type === '1'" style="margin: 20px;">
      <el-table
        v-loading="loading"
        :data="pageData.list"
        height="420px"
        size="mini"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="时间" prop="mth" width="100" />
        <el-table-column label="目标" prop="amountTarget" width="200">
          <template #default="{ row }">
            <span>进货金额 </span>
            <el-input-number
              v-model="row.amountTarget"
              placeholder="请输入"
              style="width: 70px;"
              size="mini"
              :precision="1"
              :min="0"
              :controls="false"
            ></el-input-number>
            <span>元</span>
          </template>
        </el-table-column>
      </el-table>
      <el-button size="mini" type="primary" style="border: 0px; width: 20%" @click="handleSaveAreaTargets()">保存</el-button>
      <el-button size="mini" type="primary" style="border: 0px; width: 20%" @click="handleQuery">取消</el-button>
    </div>

    <el-dialog :title="importDialog.title[importDialog.type]" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="targetUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template[importDialog.type]">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px; overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dictSelect, pageAreaShopTarget, saveShopTargets, getAreaTargetDetail,getTargetType,  exportAreaTargetData, importShopTargets, importAreaTarget, changeAmountTYpe, saveAreaTargets, exportShopTargetData } from "@/api/appTask";
import Pagination from "@/components/Pagination";
import DictSelect from "@/components/DictSelect";
import { parseParam, parseTime, excelExport, deepClone } from "@/utils";
import {Notification} from "element-ui";

export default {
  name: "TargetV2",
  components: { DictSelect, Pagination },
  props: {
    authOrgCodes: {
      type: Array,
      default() {
        return [];
      }
    },
    timestamp: {
      type: Number,
      required: true,
    },
    param: {
      type: Object,
      default() {
        return {
          orgCode: undefined,
          dcCode: undefined,
          areaTag: undefined,
        }
      }
    }
  },
  data() {
    return {
      targetLoading: false,
      loading: false,
      pageData: {
        rows: [],
        list: [],
        total: 0,
      },
      queryForm: {
        mtTaskId: undefined,
        orgCode: undefined,
        dcCode: undefined,
        areaTag: undefined,
        shopCode: undefined,
        year: undefined,
        current: 1,
        size: 10,
        yearMth: undefined,
        type: '1',
        taskRange: undefined
      },
      total: {
        mtTaskId: undefined,
        yearMth: undefined,
        totalAmount: undefined,
        amountRefer: undefined,
        avgModel: undefined,
        modelRefer: undefined,
        avgIncreaseModel: undefined,
        increaseRefer: undefined,
        handTotal: 0,
        autoTotal: 0,
      },
      totalShops: 0,
      allTargetForm: {
        orgCode: undefined,
        modelTarget: undefined,
        modelType: undefined,
        increaseModelTarget: undefined,
        increaseType: undefined,
        amountTarget: undefined,
        amountType: undefined,
        taskRange: 0,
      },
      dictFun: dictSelect,
      dictParam: {
        mtTaskId: undefined
      },
      defaultOrgOptions: [],
      activeTab: 'x',
      dateTabs: [],
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        type: undefined,
        title:{
          area: '区域整体目标导入',
          shop: '店铺目标导入'
        },
        template: {
          area: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%8C%BA%E5%9F%9F%E6%95%B4%E4%BD%93%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          shop: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%88%86%E7%BB%84%E5%BA%97%E9%93%BA%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        },
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      yearOptions: [],
      exportHeaders: {
        area: {
          dcCode: '配送中心编码',
          areaTag: '区域分组名称',
          year: '年份',
          target: '全年目标',
          target1: '1月目标',
          target2: '2月目标',
          target3: '3月目标',
          target4: '4月目标',
          target5: '5月目标',
          target6: '6月目标',
          target7: '7月目标',
          target8: '8月目标',
          target9: '9月目标',
          target10: '10月目标',
          target11: '11月目标',
          target12: '12月目标',
          errorMsg: '校验结果',
        },
        shop: {
          dcCode: '配送中心编码',
          shopCode: '店铺编码',
          year: '年份',
          target: '全年目标',
          target1: '1月目标',
          target2: '2月目标',
          target3: '3月目标',
          target4: '4月目标',
          target5: '5月目标',
          target6: '6月目标',
          target7: '7月目标',
          target8: '8月目标',
          target9: '9月目标',
          target10: '10月目标',
          target11: '11月目标',
          target12: '12月目标',
          errorMsg: '校验结果',
        }
      },
    }
  },
  computed: {
    yearMth() {
      if (this.activeTab === 'x') {
        return this.queryForm.year;
      } else if (this.activeTab.length === 1) {
        return this.queryForm.year + "0" + this.activeTab;
      } else {
        return this.queryForm.year + this.activeTab;
      }
    },
    totalAmount() {
      return this.allTargetForm.amountTarget> 0? this.totalShops * this.allTargetForm.amountTarget: 0;
    },
    settingDisable() {
      return this.queryForm.year !== '' +new Date().getFullYear() &&  this.queryForm.yearMth !== 'ALL';
    },
    orgOptions() {
      if (this.defaultOrgOptions.length > 1 && this.queryForm.type === '0' && this.authOrgCodes.indexOf('ALL') > -1) {
        return [{ value: 'ALL', label: '全部'}].concat(this.defaultOrgOptions)
      } else {
        if (this.queryForm.orgCode === 'ALL') {
          this.queryForm.orgCode = this.defaultOrgOptions[0].value;
        }
        return this.defaultOrgOptions;
      }
    }
  },
  watch: {
    timestamp: {
      async handler(newVal, oldVal) {
        this.resetQuery();

        this.$nextTick(async () => {
          this.queryForm.orgCode = this.param.orgCode;
          await this.$refs.org.remoteMethod('', true);
          await this.$refs.dc.remoteMethod('', true);
          this.queryForm.dcCode = this.param.dcCode;
          await this.$refs["area-tag"].remoteMethod('', true);
          this.queryForm.dcCode = this.param.dcCode;
          this.queryForm.areaTag = this.param.areaTag;
          this.queryForm.year = this.param.year;
          this.queryForm.yearMth = this.param.year;
          this.handleFilter();
        })
      },
      immediate: true
    }
  },
  created() {
    if (this.dateTabs.length === 0) {
      this.dateTabs.push({ value:  'x', label: '全年'})
      for (let i = 1; i < 13; i++) {
        this.dateTabs.push({ value: i> 9 ? i + '': '0' + i, label: i+ '月'})
      }
    }
    const currentY = new Date().getFullYear();
    this.yearOptions = [(currentY-1)+ '', currentY + '', (currentY + 1) + ""];
  },
  methods: {
    handleQuery() {
      this.loading = true;
      if (this.queryForm.taskRange === '1') {
        this.queryForm.yearMth = this.yearMth;
      }
      if (!this.queryForm.orgCode || !this.queryForm.dcCode || !this.queryForm.areaTag) {
        Notification.warning("分公司编码、配送中心编码及区域分组均不可为空！")
        return;
      }
      const queryParam = deepClone(this.queryForm);
      getTargetType(queryParam).then(res => {
        this.queryForm.type = res.data;

        if (this.queryForm.type === '1') {
          getAreaTargetDetail(queryParam).then(res => {
            this.loading = false;
            this.pageData.list = res.data;
          })
        } else {
          pageAreaShopTarget(queryParam).then(res => {
            this.loading = false;
            if (!res.data) {
              return false;
            }
            this.pageData.total = res.data?.total;
            this.pageData.rows = res.data?.records;
          });
        }
      })
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        dcCode: undefined,
        areaTag: undefined,
        shopCode: undefined,
        year: this.queryForm.year,
        yearMth: this.queryForm.yearMth,
        type: '1',
        taskRange: undefined
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    resetForm() {
      const total = {
        mtTaskId: undefined,
        yearMth: undefined,
        totalAmount: undefined,
        amountRefer: undefined,
        avgModel: undefined,
        modelRefer: undefined,
        avgIncreaseModel: undefined,
        increaseRefer: undefined,
        handTotal: 0,
        autoTotal: 0
      }
      this.total = Object.assign({}, total);
      this.$forceUpdate();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.dictParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleCommit(row) {
      saveShopTargets({ list: [row] }).then(res => {
        if (res.code === 200) {
          Notification.success({
            title: '提示',
            message: '操作成功!',
            position: 'top',
            duration: 3000
          });
          this.handleFilter();
        }
      })
    },
    handleSaveAreaTargets() {
      saveAreaTargets({ list: this.pageData.list}).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    handleExport(type) {
      this.targetLoading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      let fun = exportAreaTargetData;
      if (type =='shop') {
        fun = exportShopTargetData;
      }
      fun(param).then(res => {
        if (!res) {
          this.targetLoading = false;
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        const name = type ==='shop'? '店铺目标': '区域整体目标';
        link.setAttribute('download', name + '导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.targetLoading = false;
      })
    },
    handleImport(type) {
      this.importDialog.type = type;
      this.$nextTick(() => {
        if (this.$refs.targetUpload) {
          this.$refs.targetUpload.clearFiles();
        }
        this.importDialog.fileList = [];
      })
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.targetLoading = true;
      let fun = importShopTargets;
      if (this.importDialog.type === 'area') {
        fun = importAreaTarget;
      }
      fun(fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.targetLoading = false;
        this.$refs.targetUpload.clearFiles();
      }).catch((e) => {
        this.targetLoading = false;
      })
    },
    downloadImportResult(data) {
      let headers = {};
      let title = '店铺目标导入校验结果'
      if (this.importDialog.type === 'area') {
        title = '区域整体目标导入校验结果';
      }
      headers = this.exportHeaders[this.importDialog.type];
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
    handleSettingChange(val) {
      this.$confirm('确认切换目标配置方式？切换后将会清空当前已填写的全部内容，请谨慎操作。', '提示', { type: 'warning' }).then(() => {
        this.changeSetting(true);
        this.queryForm.shopCode = undefined
      }).catch(() => {
        this.queryForm.type = val === '1' ? '2': '1';
      })
    },
    changeSetting(flag) {
      changeAmountTYpe(this.queryForm).then((res) => {
        if (res.code == 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.div-class {
  margin: 5px;
  //::v-deep .el-tabs__item {
  //  border: solid 1px;
  //}
}

::v-deep .table-num-class .el-input__inner{
  color: #c7c70a;
}
</style>
