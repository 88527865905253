var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.show && _vm.list && _vm.list.length > 0
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "groupBox",
        },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              item.isLeaf === 0
                ? _c("TaskGroupItem", {
                    attrs: {
                      task: item,
                      type: _vm.type,
                      styles: _vm.computeStyles(item),
                    },
                  })
                : item.configJson.theme?.mode === "horizontal"
                ? _c("TaskHorizontalItem", {
                    attrs: {
                      task: item,
                      type: _vm.type,
                      styles: _vm.computeStyles(item),
                    },
                  })
                : _c("TaskDefaultItem", {
                    attrs: {
                      task: item,
                      type: _vm.type,
                      styles: _vm.computeStyles(item),
                    },
                  }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }