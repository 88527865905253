<template>
  <div>
    <div style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" inline label-width="auto" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-row>
          <el-form-item label="创建人" style="margin-top: 20px;" label-width="80">
            <el-select v-model="queryForm.createBy" size="mini" filterable placeholder="选择" clearable>
              <el-option v-for="item in options.creater" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="创建时间" style="text-align: right" label-width="80">
            <el-date-picker
              v-model="queryForm.createTime"
              size="mini"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="原分组名称">
            <el-select v-model="queryForm.ruleGroupName" size="mini" filterable placeholder="选择" clearable>
              <el-option v-for="item in options.ruleGroupNames" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="更新方式">
            <el-select v-model="queryForm.updateMode" size="mini" filterable placeholder="选择" clearable>
              <el-option v-for="item in options.updateModes" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-form-item label="更新状态" style="display: block;">
          <el-radio-group v-model="queryForm.updateStatus" class="full-width" size="small">
            <el-radio-button label="全部">全部</el-radio-button>
            <el-radio-button label="1">更新中</el-radio-button>
            <el-radio-button label="0">已停止更新</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(80vh - 200px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
    >
      <el-table-column type="index" width="40" />
      <el-table-column label="原分组" prop="ruleGroupName" width="220" />
      <el-table-column label="规则创建日期" prop="createTime" width="150" />
      <el-table-column label="更新方式" prop="updateMode" width="120" />
      <el-table-column label="更新频次" prop="updateFrequency" width="120" />
      <el-table-column label="自动更新状态" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.updateMode !== '手动' && scope.row.active !== null">{{ scope.row.active === 0 ? '已停止自动更新' : '已开启自动更新' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="自动更新操作" width="120" align="center">
        <template #default="{ row }">
          <el-button v-if="row.updateMode !== '手动' && row.active === 1" size="mini" type="text" class="table-button" @click="handleStopTask(row)">停止更新</el-button>
          <el-button :disabled="row.active === 0 && row.ruleGroupActive===0" v-else-if="row.updateMode !== '手动' && row.active === 0" size="mini" type="text" class="table-button" @click="handleStartTask(row)">开启更新</el-button>
        </template>
      </el-table-column>
      <el-table-column label="最新操作人" prop="updateBy" width="120" />
      <el-table-column label="最新更新时间" prop="updateTime" width="150" />
      <el-table-column label="操作" width="150" align="center" >
        <template #default="{ row }">
          <el-button size="mini" type="text" class="table-button" @click="handleRuleDetail(row)">查看分发规则</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleFilter" />

    <el-dialog title="" :visible.sync="detailDrawer.visible" width="600px" :close-on-click-modal="false">
      <el-descriptions title="" :column="1">
        <el-descriptions-item style="font-size: 14px;font-weight: bold" label="开始版本">{{ detailDrawer.form.version }}</el-descriptions-item>
        <el-descriptions-item style="font-size: 14px;font-weight: bold" label="分发规则"></el-descriptions-item>
      </el-descriptions>
      <div class="rule">
        <el-table :data="detailDrawer.ruleData">
          <el-table-column property="priority" label="优先级"></el-table-column>
          <el-table-column property="channel" label="渠道"></el-table-column>
          <el-table-column property="reachRatio" label="触达比例"></el-table-column>
          <el-table-column property="conditions" label="条件" width="200"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import {
  stopTask,
  startTask,
  queryGroupDistributeRulePageList,
  getGroupDistributeRuleCreatorList,
  getGroupDistributeRuleNameList, getGroupDistributeRuleUpdateModeList, getGroupDistributeRuleDetail
} from "@/api/tagPlatform";
import {Notification} from "element-ui";
import Pagination from '@/components/Pagination';

export default {
  name: "Rule",
  components: { Pagination },
  data() {
    return {
      loading: false,
      pageData: {
        total: 0,
        rows: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        createBy: undefined,
        startDate: undefined,
        endDate: undefined,
        ruleGroupName: undefined,
        updateMode: undefined,
        updateStatus: '全部',
        crateTime: undefined,
      },
      options: {
        creater: [],
        ruleGroupNames: [],
        updateModes: [],
      },
      detailDrawer: {
        visible: false,
        form: {
          version: undefined,
        },
        ruleData: [],
      },
    }
  },
  created() {
    getGroupDistributeRuleCreatorList().then(res => {
      this.options.creater = res.data;
    });
    getGroupDistributeRuleNameList().then(res => {
      this.options.ruleGroupNames = res.data;
    });
    getGroupDistributeRuleUpdateModeList().then(res => {
      this.options.updateModes = res.data;
    });
    this.handleQuery();
  },
  methods: {
    handleFilter() {
      this.loading =true;
      let param = {};
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Date) {
            param[key] = parseTime(this.queryForm[key]);
          } else if (this.queryForm[key] instanceof Array) {
            if (key === 'createTime') {
              param['startDate'] = this.queryForm[key][0];
              param['endDate'] = this.queryForm[key][1];
            }
          }else {
            if (!(key === 'updateStatus' && this.queryForm[key]==='全部')) {
              param[key] = this.queryForm[key];
            }
          }
        }
      }
      queryGroupDistributeRulePageList(param).then(res => {
        this.pageData.rows = res.data.datas;
        this.pageData.total = res.data.total;
        this.loading = false;
      })
    },
    handleQuery() {
      this.queryForm.page = 1;
      this.handleFilter();
    },
    handleStopTask(row) {
      stopTask(row.id).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功');
          this.handleQuery();
        }
      })
    },
    handleStartTask(row) {
      startTask(row.id).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功');
          this.handleQuery();
        }
      })
    },
    handleRuleDetail(row) {
      getGroupDistributeRuleDetail(row.id).then(res => {
        if (res.code === 200) {
          this.detailDrawer.visible = true;
          this.detailDrawer.form.version = res.data.version;
          this.detailDrawer.ruleData = res.data.groupDistributeRuleDetails;
          this.$forceUpdate();
        }
      })
    },
}
}
</script>

<style lang="scss" scoped>
.rule{
  ::v-deep .el-table{
    .cell {
      white-space: pre-line;
    }
  }
}
</style>

