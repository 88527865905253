<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,capacity,area-tag"
            init
            :initOptions="orgDictInitOptions"
            fullLabel
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            linkageRef="province,capacity,area-tag"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="省份">
          <DictSelect
            ref="province"
            type="province"
            :value.sync="queryForm.provinceCode"
            linkageRef="city"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="城市">
          <DictSelect
            ref="city"
            type="city"
            :value.sync="queryForm.cityCode"
            linkageRef="region"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区县">
          <DictSelect
            ref="region"
            type="region"
            :value.sync="queryForm.areaCode"
            linkageRef="business"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="商圈">
          <DictSelect ref="business" @getOtherJsonParam="(callback) => callback(shopJsonParam)" :value.sync="queryForm.businessAreaName" type="business" :dictFun="dictFun" parentLinkageRef />
        </el-form-item>
        <el-form-item label="店铺类型">
          <DictSelect
              ref="capacity"
              type="capacity"
              :value.sync="queryForm.shopCapacity"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
              ref="area-tag"
              type="area-tag"
              :value.sync="queryForm.areaTag"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺名称">
          <DictSelect :value.sync="queryForm.shopName" type="shopName" remote :dictFun="dictFun" />
        </el-form-item>
        <el-form-item label="店铺编码">
          <DictSelect ref="shopCode" :value.sync="queryForm.shopCode" remote type="shopCode" :dictFun="dictFun" />
        </el-form-item>
        <el-form-item label="是否重点终端">
          <DictSelect ref="tag" type="pre-shop" :value.sync="queryForm.ifOptimumShop" :dictFun="shopDict"/>
        </el-form-item>
        <el-form-item label="合作类型">
          <DictSelect ref="shopBiz" :value.sync="queryForm.shopBiz" type="shopBiz" :dictFun="dictFun" />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button v-if="queryForm.orgCode" class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAddAll">全部添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      row-class-name="rowClassName"
      @row-dblclick="handleRowDblClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90" />
      <el-table-column label="店铺编码/名称" prop="shop" width="250">
        <template #default="{ row }">
          <span>{{ row.shopCode + "-" + row.shopName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="省份/城市" prop="provinceName" width="140">
        <template #default="{ row }">
          <span>{{ row.provinceName + "-" + row.cityName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属配送中心" prop="dcName" width="180">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属区县" prop="areaName" width="100" />
      <el-table-column label="所属商圈" prop="businessAreaName" width="100" />
      <el-table-column label="区域分组" prop="areaTag" width="60" />
      <el-table-column label="店铺类型" prop="shopCapacity" width="60" />
      <el-table-column label="合作类型" prop="shopBiz" width="60" />
      <el-table-column label="是否重点终端" prop="ifOptimumShop" width="60">
        <template #default="{ row }">
          <span>{{ row.ifOptimumShop ==='1'? '是': '否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合作类型" prop="shopBiz" width="80" />
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button size="mini" @click="closeDrawer">取 消</el-button>
      <el-button size="mini" type="primary" :disabled="pageData.selections.length === 0" @click="addItems()">
        添加已选 {{ pageData.selections.length }} 店铺
      </el-button>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageShop, dictSelect, shopDict } from "@/api/appTask";
import DictSelect from "@/components/DictSelect";
import {deepClone} from "../../../../utils";
import {Message} from "element-ui";

export default {
  name: "ShopItem",
  components: { Pagination, DictSelect },
  props: {
    orgCode: {
      type: [String, Array],
      default: undefined
    },
    groupId: {
      type: String,
      default: undefined
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        provinceCode: undefined,
        cityCode: undefined,
        dcCode: undefined,
        areaCode: undefined,
        businessAreaName: undefined,
        shopLevel: undefined,
        shopGrade: undefined,
        shopBiz: undefined,
        shopName: undefined,
        potentiallevel: undefined,
        ifOptimumShop: undefined,
        areaTag: undefined,
        shopCapacity: undefined,
      },
      loading: false,
      dictFun: dictSelect,
      shopDict: shopDict,
      shopJsonParam: {},
      defaultOrgDictOptions: [],
      orgDictInitOptions: []
    };
  },
  watch: {
    orgCode: {
      handler(newVal) {
        if (newVal!== undefined) {
          let tmpCodes = [];
          if (newVal instanceof Array) {
            newVal = newVal.filter(e => e!=='ALL');
            tmpCodes = newVal;
            this.queryForm.orgCode = newVal[0];
          } else {
            tmpCodes = [newVal];
            this.queryForm.orgCode = newVal;
          }
          if (this.defaultOrgDictOptions.length === 0) {
            dictSelect('org').then(res => {
              this.defaultOrgDictOptions = res.data;
              this.orgDictInitOptions = this.defaultOrgDictOptions.filter(e => tmpCodes.indexOf(e.value) > -1);
            })
          } else {
            this.orgDictInitOptions = this.defaultOrgDictOptions.filter(e => tmpCodes.indexOf(e.value) > -1);
          }
        }
      },
      immediate: true
    },
    groupId(newVal) {
      if (newVal) {
        if (newVal !== 'ALL') {
          this.queryForm.groupId = newVal;
        } else {
          this.queryForm.groupId = undefined;
        }
        this.handleFilter();
      }
    },
    refresh(newVal) {
      if (newVal) {
        this.handleFilter();
      }
    }
  },
  created() {
    dictSelect('org').then(res => this.defaultOrgDictOptions = res.data);
  },
  mounted() {
    if (this.refresh) {
      this.handleFilter();
    }
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm, { exceptShopCodes: this.getExcepts() });
      pageShop(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleRowDblClick(row, column, event) {
      this.addItems(row);
    },
    closeDrawer() {
      this.$emit("close-drawer");
    },
    addItems(val) {
      if (val) {
        this.$emit("add-items", [val], 'shop');
      } else {
        this.$emit("add-items", this.pageData.selections, 'shop');
      }
      this.handleFilter();
    },
    getExcepts() {
      let keys;
      this.$emit("getExceptCodes", data => {
        keys = data;
      });
      return keys.join(",");
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    async handleAddAll() {
      this.loading = true;
      const size = 5000;
      const query = Object.assign({}, this.queryForm, {exceptShopCodes: this.getExcepts()}, {current: 1, size: 5000});

      await pageShop(query).then(async res => {
        let allData = deepClone(res.data.records);
        let totalPage = Math.ceil(res.data.total / size);
        for (let i = 2; i <= totalPage; i++) {
          const queryData = Object.assign(deepClone(query), {current: i, size: size});
          await pageShop(queryData).then(res => {
            allData = allData.concat(deepClone(res.data.records));
          });
        }

        this.loading = false;
        this.$emit('add-items', allData, 'shop');
        this.pageData.rows =[];
        this.pageData.total = 0;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .rowClassName {
  cursor: pointer !important;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}
</style>
