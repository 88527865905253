var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticClass: "item default", style: _vm.styles }, [
      _c("div", { staticClass: "taskInfo" }, [
        _c("div", { staticClass: "taskInfoL" }, [
          _c(
            "p",
            {
              staticClass: "taskName text-1 closed-p",
              style: { color: _vm.task.configJson.theme?.taskName?.color },
            },
            [_vm._v(_vm._s(_vm.task.taskName))]
          ),
          _c(
            "p",
            { staticClass: "taskRange closed-p", style: "width: fit-content;" },
            [
              _vm._v(
                _vm._s(
                  _vm.task.taskRange === "1"
                    ? "长期任务"
                    : "任务时间" +
                        _vm.parseShowTime(_vm.task?.startDate, "{m}.{d}") +
                        "-" +
                        _vm.parseShowTime(_vm.task?.endDate, "{m}.{d}")
                )
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "taskInfoR" }),
      ]),
      _c("div", { staticClass: "dataInfo" }, [
        _c("div", { staticClass: "dataInfoTitle" }, [
          _c("span", { staticClass: "badge" }, [
            _vm._v(_vm._s(_vm.task.taskRange === 1 ? "本月数据" : "任务期间")),
          ]),
          _c(
            "span",
            {
              staticClass: "more",
              style: "color:" + _vm.task.configJson.theme?.moreDataText?.color,
            },
            [_vm._v(" 更多数据 ")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "dataBox" },
          _vm._l(
            _vm.type === "shop"
              ? _vm.task.configJson.shop
              : _vm.task.configJson.summary,
            function (item, index) {
              return _c("div", { key: index, staticClass: "numBox" }, [
                _c(
                  "p",
                  {
                    staticClass: "label closed-p",
                    style:
                      "width: max-content;color:" +
                      _vm.hexToRgba(item.value.color, 0.7),
                  },
                  [_vm._v(_vm._s(item.title) + " ")]
                ),
                _c("p", { staticClass: "value closed-p" }, [
                  _c(
                    "span",
                    { style: "font-size: 13; color:" + item.value.color },
                    [
                      _c("span", [_vm._v(_vm._s(item.prefix))]),
                      _vm._v(" 0 " + _vm._s(item.unit)),
                    ]
                  ),
                ]),
              ])
            }
          ),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }