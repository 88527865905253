var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          linkageRef:
                            "dc,shop,responsible,area-type,shop-biz,shop-type,shop-capacity,exhibitStatus,businessAreaTypes",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          defaultIndex: 0,
                          clearable:
                            _vm.queryTop && _vm.orgAllAuth ? true : false,
                          init: true,
                          "init-options": this.options.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "dc",
                          value: _vm.queryForm.dcCode,
                          linkageRef: "province,responsible",
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          multiple: "",
                          "collapse-tags": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "省份" } },
                    [
                      _c("DictSelect", {
                        ref: "province",
                        attrs: {
                          type: "province",
                          value: _vm.queryForm.provinceCode,
                          linkageRef: "city",
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "provinceCode",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "城市" } },
                    [
                      _c("DictSelect", {
                        ref: "city",
                        attrs: {
                          type: "city",
                          value: _vm.queryForm.cityCode,
                          linkageRef: "area",
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "cityCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "区县" } },
                    [
                      _c("DictSelect", {
                        ref: "area",
                        attrs: {
                          type: "area",
                          value: _vm.queryForm.areaCode,
                          linkageRef: "business",
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "areaCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺" } },
                    [
                      _c("DictSelect", {
                        ref: "shop",
                        attrs: {
                          type: "shop",
                          value: _vm.queryForm.shopCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          remote: "",
                          "init-props": { label: "label", value: "fullCode" },
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "shopCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "包干负责人" } },
                    [
                      _c("DictSelect", {
                        ref: "responsible",
                        attrs: {
                          type: "responsible",
                          "always-change": true,
                          value: _vm.queryForm.responsible,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                        },
                        on: {
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "responsible",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商圈类型" } },
                    [
                      _c("DictSelect", {
                        ref: "area-type",
                        attrs: {
                          type: "area-type",
                          value: _vm.queryForm.businessAreaTypes,
                          multiple: "",
                          singleProps: "",
                          parentLinkageRef: "",
                          "dict-fun": _vm.basicDict,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "businessAreaTypes",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ queryTop: _vm.queryTop }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经营类型" } },
                    [
                      _c("DictSelect", {
                        ref: "businessAreaTypes",
                        attrs: {
                          type: "businessAreaTypes",
                          value: _vm.queryForm.businessTypes,
                          multiple: "",
                          singleProps: "",
                          parentLinkageRef: "",
                          "dict-fun": _vm.basicDict,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "businessTypes",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ queryTop: _vm.queryTop }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺标签" } },
                    [
                      _c("DictSelect", {
                        ref: "shop-capacity",
                        attrs: {
                          type: "shop-capacity",
                          value: _vm.queryForm.shopCapacitys,
                          multiple: "",
                          singleProps: "",
                          parentLinkageRef: "",
                          "dict-fun": _vm.basicDict,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "shopCapacitys",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ queryTop: _vm.queryTop }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "门店类型" } },
                    [
                      _c("DictSelect", {
                        ref: "shop-type",
                        attrs: {
                          type: "shop-type",
                          value: _vm.queryForm.shopTypes,
                          singleProps: "",
                          multiple: "",
                          parentLinkageRef: "",
                          "dict-fun": _vm.basicDict,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "shopTypes", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ queryTop: _vm.queryTop }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合作类型" } },
                    [
                      _c("DictSelect", {
                        ref: "shop-biz",
                        attrs: {
                          type: "shop-biz",
                          value: _vm.queryForm.shopBiz,
                          singleProps: "",
                          parentLinkageRef: "",
                          "dict-fun": _vm.basicDict,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "shopBiz", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ queryTop: _vm.queryTop }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "陈列现状" } },
                    [
                      _c("DictSelect", {
                        ref: "exhibitStatus",
                        attrs: {
                          type: "exhibitStatus",
                          value: _vm.queryForm.exhibitStatuss,
                          singleProps: "",
                          multiple: "",
                          parentLinkageRef: "",
                          "dict-fun": _vm.basicDict,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "exhibitStatuss",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ queryTop: _vm.queryTop }),
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.queryTop
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "重点店铺" } },
                        [
                          _c("DictSelect", {
                            ref: "tag",
                            attrs: {
                              type: "pre-shop",
                              value: _vm.queryForm.ifOptimumShop,
                              init: "",
                              "init-options": _vm.options.defaultOptions,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "ifOptimumShop",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺状态" } },
                    [
                      _c("DictSelect", {
                        attrs: {
                          value: _vm.queryForm.status,
                          init: "",
                          "init-options": _vm.options.statusOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "status", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          !_vm.queryForm.queryTop
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-upload",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleImport("common")
                    },
                  },
                },
                [_vm._v("包干负责人导入")]
              )
            : _vm._e(),
          !_vm.queryForm.queryTop
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-upload",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleImport("top")
                    },
                  },
                },
                [_vm._v("重点店铺导入")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出")]
          ),
          _vm.queryForm.queryTop
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-download",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleExportSummary()
                    },
                  },
                },
                [_vm._v("重点店铺统计导出")]
              )
            : _vm._e(),
          _vm.queryForm.queryTop
            ? _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("仅显示重点终端清单"),
              ])
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "shopTableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "分公司编码/名称", prop: "shop", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属配送中心", prop: "dcName", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.dcCode + "-" + row.dcName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "店铺编码/名称", prop: "shop", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.shopCode + "-" + row.shopName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "省份/城市",
                  prop: "provinceName",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.provinceName + "-" + row.cityName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属区县", prop: "areaName", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属商圈",
                  prop: "businessAreaName",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商圈类型",
                  prop: "businessAreaType",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "经营类型",
                  prop: "businessType",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "门店类型", prop: "shopType", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "店铺标签",
                  prop: "shopCapacity",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.shopCapacity) + " ")]),
                        _vm._v(" "),
                        row.auditShopCapacity
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("(" + _vm._s(row.auditShopCapacity) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "陈列现状",
                  prop: "exhibitStatus",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "合作类型", prop: "shopBiz", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "消费力", prop: "consumeLevel", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "包干负责人",
                  prop: "responsible",
                  width: "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.userName
                                ? row.userName + "-" + row.nickName
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "重点店铺",
                  prop: "ifOptimumShop",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.ifOptimumShop === "1" ? "是" : "否")
                          ),
                        ]),
                        row.auditIfOptimumShop
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    row.auditIfOptimumShop === "1" ? "是" : "否"
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "店铺状态", prop: "shopStatus", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.status === 1 ? "启用" : "禁用")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.queryForm.queryTop
                ? _c("el-table-column", {
                    attrs: { label: "是否合并", prop: "merged", width: "120" },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "button-class" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.queryForm.queryTop ? "查看" : "编辑"
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "店铺导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "goodsUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template[_vm.importDialog.type],
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "店铺更新",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "110px",
                inline: true,
                model: _vm.editDialog.form,
                disabled: _vm.queryForm.queryTop !== undefined,
                rules: _vm.editDialog.rules,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row", attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c("DictSelect", {
                            ref: "form-org",
                            attrs: {
                              disabled: "",
                              type: "org",
                              value: _vm.editDialog.form.orgCode,
                              linkageRef: "form-dc",
                              dictFun: _vm.dictFun,
                              fullLabel: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "orgCode",
                                  $event
                                )
                              },
                              changeLinkageRef: (ref, val, type) =>
                                _vm.handleChangeLinkageRef(
                                  ref,
                                  val,
                                  type,
                                  _vm.editDialog.form,
                                  "editFormDictParam"
                                ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "配送中心" } },
                        [
                          _c("DictSelect", {
                            ref: "form-dc",
                            attrs: {
                              disabled: "",
                              type: "dc",
                              value: _vm.editDialog.form.dcCode,
                              dictFun: _vm.dictFun,
                              parentLinkageRef: "",
                              fullLabel: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "dcCode",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback(_vm.editFormDictParam),
                              changeLinkageRef: (ref, val, type) =>
                                _vm.handleChangeLinkageRef(
                                  ref,
                                  val,
                                  type,
                                  _vm.editDialog.form,
                                  "editFormDictParam"
                                ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺编码" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.shopCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "shopCode", $$v)
                              },
                              expression: "editDialog.form.shopCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.shopName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "shopName", $$v)
                              },
                              expression: "editDialog.form.shopName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "包干负责人" } },
                        [
                          _c("DictSelect", {
                            ref: "user",
                            attrs: {
                              type: "user",
                              value: _vm.editDialog.form.userName,
                              dictFun: _vm.dictUserFun,
                              fullLabel: "",
                              remote: "",
                              version: "v3",
                              placeholder: "请输入账号或姓名",
                              "init-props": {
                                label: "nickName",
                                value: "userName",
                              },
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "userName",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({ pageSize: 50, pageNum: 1 }),
                              change: _vm.handleRespChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商圈名称" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.businessAreaName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "businessAreaName",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.businessAreaName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商圈类型" } },
                        [
                          _c("DictSelect", {
                            ref: "area-type",
                            attrs: {
                              type: "area-type",
                              value: _vm.editDialog.form.businessAreaType,
                              singleProps: "",
                              "dict-fun": _vm.basicDict,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "businessAreaType",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({
                                  queryTop: _vm.queryTop,
                                  orgCode: _vm.editDialog.form.orgCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "经营类型" } },
                        [
                          _c("DictSelect", {
                            ref: "businessAreaTypes",
                            attrs: {
                              type: "businessAreaTypes",
                              value: _vm.editDialog.form.businessType,
                              singleProps: "",
                              "dict-fun": _vm.basicDict,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "businessType",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({
                                  queryTop: _vm.queryTop,
                                  orgCode: _vm.editDialog.form.orgCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺标签" } },
                        [
                          _c("DictSelect", {
                            ref: "shop-capacity",
                            attrs: {
                              type: "shop-capacity",
                              value: _vm.editDialog.form.shopCapacity,
                              singleProps: "",
                              "dict-fun": _vm.basicDict,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "shopCapacity",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({
                                  queryTop: _vm.queryTop,
                                  orgCode: _vm.editDialog.form.orgCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店类型" } },
                        [
                          _c("DictSelect", {
                            ref: "shop-type",
                            attrs: {
                              type: "shop-type",
                              value: _vm.editDialog.form.shopType,
                              singleProps: "",
                              "dict-fun": _vm.basicDict,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "shopType",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({
                                  queryTop: _vm.queryTop,
                                  orgCode: _vm.editDialog.form.orgCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合作类型" } },
                        [
                          _c("DictSelect", {
                            ref: "shop-biz",
                            attrs: {
                              type: "shop-biz",
                              value: _vm.editDialog.form.shopBiz,
                              singleProps: "",
                              "dict-fun": _vm.basicDict,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "shopBiz",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({
                                  queryTop: _vm.queryTop,
                                  orgCode: _vm.editDialog.form.orgCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "陈列现状" } },
                        [
                          _c("DictSelect", {
                            ref: "exhibitStatus",
                            attrs: {
                              type: "exhibitStatus",
                              value: _vm.editDialog.form.exhibitStatus,
                              singleProps: "",
                              "dict-fun": _vm.basicDict,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "exhibitStatus",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({
                                  queryTop: _vm.queryTop,
                                  orgCode: _vm.editDialog.form.orgCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否重点终端",
                            prop: "ifOptimumShop",
                          },
                        },
                        [
                          _c("DictSelect", {
                            ref: "tag",
                            attrs: {
                              type: "tag",
                              clearable: false,
                              value: _vm.editDialog.form.ifOptimumShop,
                              init: "",
                              "init-options": _vm.options.defaultOptions,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "ifOptimumShop",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "经度" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.longitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "longitude", $$v)
                              },
                              expression: "editDialog.form.longitude",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "纬度" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.latitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "latitude", $$v)
                              },
                              expression: "editDialog.form.latitude",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "店铺地址",
                            "label-width": "90px",
                            prop: "address",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder:
                                "请输入店铺具体地址，多个地址以;分隔",
                            },
                            on: {
                              input: function ($event) {
                                _vm.editDialog.form.addressChanged = true
                              },
                            },
                            model: {
                              value: _vm.editDialog.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "address", $$v)
                              },
                              expression: "editDialog.form.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "年文具体量(万)" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              min: 1,
                              precision: 4,
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.xsVolume,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "xsVolume", $$v)
                              },
                              expression: "editDialog.form.xsVolume",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.queryForm.queryTop &&
                  _vm.editDialog.form.mergedShops &&
                  _vm.editDialog.form.mergedShops.length > 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合并前店铺数据",
                                "label-width": "140px",
                              },
                            },
                            _vm._l(
                              _vm.editDialog.form.mergedShops,
                              function (item, index) {
                                return _c("div", { key: index }, [
                                  _c("span", [_vm._v("配送中心编码：")]),
                                  _c("span", [_vm._v(_vm._s(item.dcCode))]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [_vm._v("店铺编码：")]
                                  ),
                                  _c("span", [_vm._v(_vm._s(item.shopCode))]),
                                ])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.queryForm.queryTop &&
                  _vm.editDialog.form.mergedShops &&
                  _vm.editDialog.form.mergedShops.length > 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合并后店铺数据",
                                "label-width": "140px",
                              },
                            },
                            [
                              _c("span", [_vm._v("配送中心编码：")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.editDialog.form.virDcCode)),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [_vm._v("店铺编码：")]
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm.editDialog.form.virShopCode)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              !_vm.queryForm.queryTop
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.submitDisabled,
                        type: "primary",
                        size: "mini",
                      },
                      on: { click: _vm.submitEdit },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: {
              "font-size": "16px",
              "overflow-y": "scroll",
              height: "400px",
            },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.noticeDialog.data && _vm.noticeDialog.data.length > 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.downloadImportResult },
                    },
                    [_vm._v("下载导入结果")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "按配送中心设置包干负责人",
            visible: _vm.respDialog.visible,
            "append-to-body": "",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.respDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "respForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "110px",
                inline: true,
                model: _vm.respDialog.form,
                rules: _vm.respDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配送中心", prop: "dcCode" } },
                [
                  _c("DictSelect", {
                    ref: "resp-form-dc",
                    attrs: {
                      type: "dc",
                      value: _vm.respDialog.form.dcCode,
                      fullLabel: "",
                      init: "",
                      "init-options": _vm.respDialog.dcs,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.respDialog.form, "dcCode", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更改后包干负责人", prop: "userName" } },
                [
                  _c("DictSelect", {
                    ref: "resp-user",
                    attrs: {
                      type: "user",
                      value: _vm.respDialog.form.userName,
                      dictFun: _vm.dictUserFun,
                      fullLabel: "",
                      remote: "",
                      version: "v3",
                      placeholder: "请输入账号或姓名",
                      "init-props": { label: "nickName", value: "userName" },
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.respDialog.form, "userName", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ pageSize: 50, pageNum: 1 }),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.respDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitUpdateDcResp },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }