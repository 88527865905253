<style>
.el-input__inner {
  padding-left: 5px !important;
  max-width: 350px;
}
</style>
<template>
  <div class="app-container raceActivityReport-container">
    <div class="raceActivityReport-container-body">
      <el-row class="mt10">
        <el-col :span="8" :offset="4">
          <el-form
            :model="queryParams"
            ref="queryFormLeft"
            size="small"
            label-width="110px"
            @keyup.enter.native="handleQuery"
          >
            <el-form-item label="批发出货日期">
              <el-date-picker
                v-model="queryParams.pfDate"
                value-format="yyyyMMdd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="一配出货日期">
              <el-date-picker
                v-model="queryParams.dc1ChDate"
                value-format="yyyyMMdd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="总部出货日期">
              <el-date-picker
                v-model="queryParams.hqChDate"
                value-format="yyyyMMdd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form
            :model="queryParams"
            ref="queryFormRight"
            size="small"
            label-width="110px"
            @keyup.enter.native="handleQuery"
          >
            <el-form-item label="活动名称">
              <el-select v-model="queryParams.activityName" placeholder="请选择" clearable>
                <el-option
                  v-for="item in options.activityName"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分公司">
              <el-select v-model="queryParams.orgs" placeholder="请选择" @change="orgsChange" clearable multiple value-key="code">
                <el-option
                  v-for="item in options.orgs"
                  :key="item.code"
                  :label="item.name + '[' + item.code + ']'"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="伙伴">
              <el-select v-model="queryParams.dcs" placeholder="请选择" clearable multiple :disabled="dcsDisabled" value-key="dcCode">
                <el-option
                  v-for="item in options.dcs"
                  :key="item.dcCode"
                  :label="item.dcName != '大客户' ? item.dcName + '[' + item.dcCode + ']' : item.dcName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" icon="Search" size="mini" @click="handleQuery">查询</el-button>
          <el-button icon="Refresh" size="mini" @click="download" :loading="downloadLoading">下载</el-button>
        </el-col>
      </el-row>

      <el-table border size="mini" ref="reportTable" v-loading="loading" :data="reportList">
        <el-table-column label="序号" type="index" align="center" width="80">
          <template #default="scope">
            {{(queryParams.page - 1) * queryParams.limit + scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column label="活动名称" align="center" prop="activityName" min-width="120px" />
        <el-table-column label="截止日期" align="center" prop="jzrq" min-width="120px" />
        <el-table-column label="查询起止日期" align="center" prop="cxqzrq" min-width="120px" />
        <el-table-column label="产品编码" align="center" prop="skuCode" min-width="120px" />
        <el-table-column label="产品名称" align="center" prop="skuName" min-width="120px" />
        <el-table-column label="分公司CODE" align="center" prop="boOrgCode" min-width="120px" />
        <el-table-column label="分公司名称" align="center" prop="boOrgName" min-width="120px" />
        <el-table-column label="零售价" align="center" prop="priceRetail" min-width="120px" />
        <el-table-column label="总部出货数量" align="center" prop="hqChNm" min-width="120px" />
        <el-table-column label="总部出货金额" align="center" prop="hqChAmout" min-width="120px" />
        <el-table-column label="二三配进货数量" align="center" prop="boChTodc23Nm" min-width="120px" />
        <el-table-column label="二三配进货金额" align="center" prop="boChTodc23Amout" min-width="120px" />
        <el-table-column label="一配出货数量" align="center" prop="dc1ChNm" min-width="120px" />
        <el-table-column label="一配出货金额" align="center" prop="dc1ChAmout" min-width="120px" />
        <el-table-column label="大客户进货数量" align="center" prop="boChTooNm" min-width="120px" />
        <el-table-column label="大客户进货金额" align="center" prop="boChTooAmout" min-width="120px" />
      </el-table>

      <!-- 分页器 -->
      <mg-pagination
        :total="total"
        :pageSize="queryParams.limit"
        :currentPage="queryParams.page"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import { paginationParams } from '@/mixins';
import {handleDcOptions, handleRaceActivityListOptions, handleRaceActivityReportLists} from "../../../api/raceActivity";
import {excelExport} from "@/utils";
export default {
  name: "index",
  components: {
    mgPagination: () => import("@/components/Element/Pagination"),
  },
  xmixins: [  paginationParams ],

  data() {
    return {
      loading: false,
      downloadLoading: false,
      queryParams: {
        page: 1,
        limit: 10,
        pfDate: [],
        dc1ChDate: [],
        hqChDate: [],
        orgs: [],
        dcs: [],
        activityName: '',
      },
      reportList: [],
      total: null,
      options: {
        orgs: [],
        dcs: [],
        activityName: [],
      },
      dcsDisabled: false,
    }
  },
  created() {
    this.handleRaceActivityListOptions();
  },
  mounted() {
  },
  methods: {
    async getRaceActivityList() {
      if (this.queryParams.activityName === '') {
        this.$message.warning('请选择活动名称');
        return;
      }
      try {
        this.loading = true;
        const response = await handleRaceActivityReportLists(this.queryParams);
        if (response.code != 200) {
          throw '错误码：' + response.code;
        }
        this.reportList = response.data.datas;
        this.total = response.data.total;
        this.loading = false;
      } catch (err) {
        this.boAddOrderList = [];
        this.total = 0;
        this.loading = false;
        console.log(err, 'err----获取赛道自助报表列表有误');
      }
    },

    /** 搜索框选项加载 */
    async handleRaceActivityListOptions() {
      try {
        const resCatagory = await handleRaceActivityListOptions();
        if (resCatagory.code == '200') {
          const {data} = resCatagory;
          this.options.activityName = data.activityName
          this.options.orgs = data.orgs;
        }
      } catch (err) {
        console.log(err, 'err-----获取字典选项接口错误');
      }
    },

    /** 分页监听 */
    pagingChange(e) {
      if (e.current_page) this.queryParams.page = e.current_page;
      if (e.per_page) this.queryParams.limit = e.per_page;
      this.getRaceActivityList();
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getRaceActivityList();
    },

    /** 下载表格数据 */
    async download() {
      const topHeaders = {};
      const column = this.$refs.reportTable.$children.filter(obj => obj.prop);
      column.forEach(obj => { topHeaders[obj.prop] = obj.label });
      try {
        this.downloadLoading = true;

        const params = JSON.parse(JSON.stringify(this.queryParams));
        params.page = 1;
        params.limit = 1000000;

        const response = await handleRaceActivityReportLists(params);

        if (response.code != 200) {
          throw '错误码：' + response.code;
        }

        this.downloadLoading = false;

        const list = response.data.datas;
        if (list.length > 0) {
          excelExport({
            topHeaders,
            data: list,
            title: '赛道自助报表列表'
          })
        }
        if (list.length == 0) {
          return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.loading = false;
        console.log(err, 'err--------赛道自助报表列表导出接口有误');
      }
    },

    async orgsChange() {
      if (this.queryParams.orgs.length > 1) {
        this.dcsDisabled = true
        this.options.dcs = []
        this.queryParams.dcs = []
      } else if (this.queryParams.orgs.length === 1) {
        try {
          console.log(this.queryParams.orgs)
          const res = await handleDcOptions({orgCode: this.queryParams.orgs[0].code})
          if (res.code == '200') {
            res.data.push({dcCode: '大客户', dcName: '大客户'})
            this.options.dcs = res.data
            this.dcsDisabled = false
          }
        } catch (err) {
          console.log(err, 'err-----获取分公司伙伴接口错误');
        }
      } else {
        this.dcsDisabled = true
        this.options.dcs = []
        this.queryParams.dcs = []
      }
    }

  }
}
</script>

<style scoped>

</style>
