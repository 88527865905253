var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowProgress
    ? _c("div", { staticClass: "mg-progress-container" }, [
        _c("div", { staticClass: "mg-progress-box" }, [
          _c(
            "div",
            { staticClass: "mg-progress-loading", style: _vm.progressStyle },
            [
              _vm.isShowProgress
                ? _c("el-progress", { attrs: { percentage: _vm.progress } })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mg-progress-tips" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.progressTips))]),
                  _vm._l(_vm.progressTipsDot, function (item, index) {
                    return _c("span", { key: index }, [_vm._v(".")])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }