<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,capacity,area-tag"
            :dictFun="dictFun"
            fullLabel
            default-first-option
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            linkageRef="capacity,area-tag,ht-shop"
            :dictFun="dictFun"
            fullLabel
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            parentLinkageRef
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
              ref="area-tag"
              type="area-tag"
              :value.sync="queryForm.areaTag"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              :clearable="false"
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺类型">
          <DictSelect
              ref="capacity"
              type="capacity"
              :value.sync="queryForm.shopCapacity"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺">
          <DictSelect
            ref="shop"
            type="ht-shop"
            :value.sync="queryForm.shopCode"
            :dictFun="dictFun"
            fullLabel
            alwaysChange
            remote
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="importDialog.visible = true">导入分组店铺</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出分组店铺</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 150px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      @sort-change="handleSortChange"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="分公司编码" prop="orgCode" width="80" />
      <el-table-column label="分公司名称" prop="orgName" width="80" />
      <el-table-column label="区域分组" prop="areaTag" width="100" />
      <el-table-column label="配送中心编码" prop="dcCode" width="100" />
      <el-table-column label="配送中心名称" prop="dcName" />
      <el-table-column label="店铺编码" prop="shopCode"  width="100" />
      <el-table-column label="店铺名称" prop="shopName" />
      <el-table-column label="店铺类型" prop="shopCapacity"  width="100" />
      <el-table-column label="操作" width="100" align="center">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px; width: 20%;margin-right: 15px" @click="handleEdit(row, 'edit')">编辑</el-button>
          <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleViewTarget(row)">目标</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog title="区域分组编辑" :visible.sync="saveDialog.visible" width="200px" append-to-body :close-on-click-modal="false">
      <el-form ref="areaTagForm" :model="saveDialog.form" :inline="true" :rules="saveDialog.rules" label-width="130px">
        <el-form-item label="修改区域分组名称" prop="areaTag" size="mini">
          <el-input
            v-model="saveDialog.form.areaTag"
            clearable
            placeholder="请输入区域分组"
          ></el-input>
        </el-form-item>
      </el-form>

    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button size="mini" @click="saveDialog.visible = false">取 消</el-button>
      <el-button size="mini" type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>

    <el-dialog title="店铺区域分组导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="goodsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog  title="店铺目标" :visible.sync="targetDialog.visible" append-to-body width="400px">
      <div>仅展示已配置的目标，修改请前往区域分组的目标里设置</div>
      <div>
        <strong style="margin-right: 10px">年份</strong>
        <el-select v-model="targetDialog.year" size="mini" @change="() => handleViewTarget()">
          <el-option v-for="item in yearOptions" :key="item" :label="item" :value="item" />
        </el-select>
      </div>
      <el-table
        :data="targetDialog.list"
        height="400px"
        v-loading="targetDialog.loading"
        size="mini"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="时间" prop="mth" align="center" />
        <el-table-column label="目标" prop="amountTarget" align="center">
          <template #default="{ row }">
            <span>进货金额   {{ row.amountTarget ? row.amountTarget: 0 }}  元</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  pageAreaTagShop,
  changeAreaTag,
  importShopAreaTag,
  getCurrentUser,
  dictSelect,
  shopDict,
  exportShopAreaTag, getAreaTargetDetail
} from "@/api/appTask";
import { parseParam, excelExport, deepClone } from "@/utils";
import {Message, MessageBox, Notification} from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "ShopDetail",
  components: { Pagination, DictSelect },
  props: {
    timestamp: {
      type: Number,
      required: true,
    },
    param: {
      type: Object,
      default() {
        return {
          orgCode: undefined,
          dcCode: undefined,
          areaTag: undefined,
        }
      }
    }
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      itemsPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      queryForm: {
        current: 1,
        size: 10,
        shopName: undefined,
        shopCapacity: undefined,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        areaTag: undefined
      },
      loading: false,
      userOptions: [],
      editDialog: {
        visible: false,
        form: {
          orgCode: undefined,
          dcCode: undefined,
          oldAreaTag: undefined,
          areaTag: undefined,
        },
        rules: {
          areaTag: [{ required: true, message: "区域分组不能为空", trigger: "change" }],
        }
      },
      saveDialog: {
        visible: false,
        form: {
          poiCode: undefined,
          dcCode: undefined,
          shopCode: undefined,
          areaTag: undefined
        },
        rules: {
          areaTag: [{ required: true, message: "区域分组不能为空", trigger: "change" }],
        },
      },
      currentUser: {},
      dictFun: dictSelect,
      shopDict: shopDict,
      disableCapacity: ['二档', '三档', 'TOP', '一档', '四档'],
      importDialog: {
        visible: false,
        type: 'common',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '分组店铺导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%88%86%E7%BB%84%E5%BA%97%E9%93%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
      currentRow: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      authOrgs: [],
      submitDisabled: false,
      targetDialog: {
        visible: false,
        loading: false,
        year: undefined,
        currentRow: {},
        list: []
      },
      shopJsonParam: {},
      exportHeaders: {
        dcCode: '配送中心编码',
        shopCode: '店铺编码',
        areaTag: '区域分组',
        errorMsg: '校验结果',
      },
      yearOptions: []
    };
  },
  watch: {
    timestamp: {
      async handler(newVal) {
        this.resetQuery();
        this.$nextTick(async () => {
          this.queryForm.orgCode = this.param.orgCode;
          await this.$refs.org.remoteMethod('', true);
          await this.$refs.dc.remoteMethod('', true);
          this.queryForm.dcCode = this.param.dcCode;
          await this.$refs["area-tag"].remoteMethod('', true);
          this.queryForm.dcCode = this.param.dcCode;
          this.queryForm.areaTag = this.param.areaTag;
          this.handleFilter();
        })
      },
      immediate: true
    }
  },
  created() {
    const currentY = new Date().getFullYear();
    this.yearOptions = [(currentY-1)+ '', currentY + '', (currentY + 1) + ""];
    getCurrentUser().then(res => this.currentUser = res.data);
    dictSelect("ht-org-all").then(res => {
      // if (res.data.length === 1) {
      //   this.queryForm.orgCode = res.data[0].value;
      // }
      this.authOrgs = res.data.map(e => e.value)
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      pageAreaTagShop(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        areaTag: undefined
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleResetFields() {
      const form = {
        poiCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        orgCode: undefined,
        oldAreaTag: undefined,
        areaTag: undefined
      };
      this.saveDialog.form = Object.assign({}, form);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val) {
      if (val) {
        this.queryForm.createTimeBegin = val[0];
        this.queryForm.createTimeEnd = val[1];
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    handleEdit(row) {
      this.currentRow = row;
      this.handleResetFields();
      const form = Object.assign({}, row);
      this.saveDialog.form = form;
      this.saveDialog.form.oldAreaTag = form.areaTag;
      this.saveDialog.visible = true;
    },
    handleViewTarget(row) {
      this.targetDialog.list = [];
      this.targetDialog.loading = true;
      if (row) {
        this.targetDialog.currentRow = deepClone(row);
        this.targetDialog.year = new Date().getFullYear() + '';
      }
      getAreaTargetDetail(this.targetDialog.currentRow).then(res => {
        this.targetDialog.list = res.data;
        this.targetDialog.visible = true;
        this.targetDialog.loading = false;
      })
    },
    submitForm() {
      this.$refs["areaTagForm"].validate(valid => {
        if (valid) {
          const data = Object.assign({}, this.saveDialog.form);
          changeAreaTag(data).then(res => {
            if (res.code === 200) {
              this.saveDialog.visible = false;
              Notification.success("操作成功！");
              this.$nextTick(() => {
                this.$refs.capacity.remoteMethod('', true);
                this.$refs['area-tag'].remoteMethod('', true);
              })
              this.handleQuery();
            }
          });
        }
      });
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleExport() {
      if (!this.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportShopAreaTag(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '分区分组数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.submitDisabled = true;
      importShopAreaTag(fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.submitDisabled = false;
        this.loading = false;
        this.$refs.goodsUpload.clearFiles();
      })
    },
    downloadImportResult(data) {
      let title = '分组店铺导入校验结果';
      excelExport({
        topHeaders: this.exportHeaders,
        data: data,
        title: title,
      });
    },
  },
};
</script>

<style lang="scss">

::v-deep {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .el-input__inner {
    padding-left: 5px !important;
    max-width: 200px !important;
  }
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}

</style>
