var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table-column",
    {
      attrs: {
        label: _vm.tableColumns.label,
        prop: _vm.tableColumns.prop,
        align: _vm.alignType,
        "class-name": _vm.tableColumns.headerCellClassName,
      },
    },
    [
      _vm._l(_vm.tableColumns.children, function (item, index) {
        return [
          item.children
            ? _c("mg-table-column", {
                key: index,
                attrs: { tableColumns: item, minWidth: item.minWidth },
              })
            : _c(
                "el-table-column",
                _vm._b(
                  {
                    key: index,
                    attrs: { align: "center" },
                    scopedSlots: _vm._u(
                      [
                        item.slot
                          ? {
                              key: "default",
                              fn: function (scoped) {
                                return [_vm._t(item.slot, null, null, scoped)]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  "el-table-column",
                  item,
                  false
                )
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }