<template>
  <el-form ref="groupForm" size="mini" label-width="120px" :inline="true" :model="form" class="common-form" label-position="left">
    <el-form-item :label="type === 'editTaskHome'? '选择任务':'任务名称'" prop="name">
<!--      <el-select v-if="type === 'editTaskHome'" v-model="dataForm.mtTaskId" placeholder="请选择任务" filterable @change="handleTaskChange">-->
<!--        <el-option v-for="item in filterTaskOptions" :key="item.mtTaskId" :label="item.taskName" :value="item.mtTaskId" />-->
<!--      </el-select>-->
      <span>{{dataForm.taskName}}</span>
    </el-form-item>
    <el-form-item v-if="type !== 'editTaskHome'" label="选择模板" prop="template">
      <el-radio-group v-model="dataForm.template" @input="handleTemplateChange">
        <el-radio label="block" value="block">竖版<img :src="viewShowTemplateUrl[imageTemplate]['block']" style="height: 80px; display: block;" /></el-radio>
        <el-radio label="horizontal" value="horizontal">横版<img :src="viewShowTemplateUrl[imageTemplate]['horizontal']" style="height: 80px; display: block;" /></el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="type !== 'editTaskHome'" label="颜色配置" prop="template">
      <el-row style="display: table">
        <el-col v-for="(item,index) in  (dataForm.template==='block'?options.blockColor:options.horizontalColor)" :key="index"  :span="dataForm.template==='horizontal' && item.value==='backGroundColor'? 24:6">
          <span style="vertical-align: text-bottom;">{{item.label}}</span><el-color-picker v-model="dataForm[item.value]" />
          <OssUpload v-if="dataForm.template==='horizontal' && item.value==='backGroundColor'" ref="ossUpload" :value-to.sync="dataForm.customImageUrl" :maxFileSize="1024" class="custom-picture-card" list-type="picture-card" style="display: table"
                     :limit="1" :always-show-button="false" tips="尺寸1065*375，底部150高勿放重要内容以免遮挡数据" :tipMessageShow="false"
                     :accept-type="imgTypes" />
        </el-col>
        <el-col>
          <el-button size="mini" type="text" @click="syncConfigToOtherTask">一键同步颜色配置到其他子任务</el-button>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="选择任务汇总的展示字段" prop="summaryFields" style="width: 800px">
      <div v-if="dataForm.template==='horizontal'" class="text-light">最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据</div>
      <div v-else class="text-light">最多可选4个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据</div>
      <el-checkbox-group v-model="dataForm.summaryFields" style="width: 700px" :max="dataForm.template==='horizontal'?2:4">
        <div v-for="(item, key) in summaryFieldMap" :key="key">
          <el-row>
            <el-col :span="3">
              <span class="text-light">{{ key }}</span>
            </el-col>
            <el-col v-for="e in item" :key="e.title" :span="5">
              <el-checkbox :disabled="e.key==='boostBonus' && !form.boostTask" :value="e.key" :label="e.key">{{e.title}}</el-checkbox>
            </el-col>
          </el-row>
        </div>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="选择店铺页的任务展示字段" prop="shopFields" style="width: 800px">
      <div v-if="dataForm.template==='horizontal'" class="text-light">最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据</div>
      <div v-else class="text-light">最多可选4个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据</div>
      <el-checkbox-group v-model="dataForm.shopFields" style="width: 700px" :max="dataForm.template==='horizontal'?2:4">
        <div v-for="(item, key) in shopFieldMap" :key="key">
          <el-row>
            <el-col :span="3">
              <span class="text-light">{{ key }}</span>
            </el-col>
            <el-col v-for="e in item" :key="e.title" :span="5">
              <el-checkbox :disabled="e.key==='boostBonus' && !form.boostTask" :value="e.key" :label="e.key">{{e.title}}</el-checkbox>
            </el-col>
          </el-row>
        </div>
      </el-checkbox-group>
    </el-form-item>
  </el-form>
</template>

<script>
import { homePageFields, defaultTaskJson, jlTaskJson, summaryFieldMap, shopFieldMap, horizontalTheme,
  blockTheme,shopFields, summaryFields, jlHorizontalTaskJson, horizontalTaskJson, orangeTheme } from '../../../config';
import OssUpload from "@/components/OssUpload";
import { listGroupTask } from "@/api/appTask";
import { hexToRgba, deepClone } from "@/utils";
import { Notification } from "element-ui";

export default {
  name: "TaskContent",
  components: { OssUpload },
  props: {
    type: {
      type: String,
      default: "add"
    },
    taskId: {
      type: String,
    },
    form: {
      type: Object,
      required: true
    },
    orgCode: {
      type: String,
      required: true
    },
    exceptTaskIds: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    imageTemplate: {
      type: String,
      default: 'red'
    }
  },
  watch: {
    form: {
      handler(nV, oV) {
        if (nV && nV.mtTaskId && (!oV || nV.mtTaskId!==oV.mtTaskId)) {
          this.getTaskOptions(nV.orgCode, nV);
        }
      },
      deep: true,
      immediate: true
    },
    orgCode: {
      handler(nV, oV) {
        // if (nV) {
        //   this.getTaskOptions(nV, undefined);
        // }
      },
      deep: true,
      immediate: true
    },
    dataForm: {
      handler(nV, oV) {
        if (nV) {
          this.refreshTaskForm(nV);
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      deepClone,
      taskForm: {},
      dataForm: {
        mtTaskId: undefined,
        template: 'horizontal',
        backGroundColor: undefined,
        taskName: undefined,
        badgeBackColor:undefined,
        badgeColor: undefined,
        taskTimeColor: undefined,
        value: undefined,
        moreDataText: undefined,
        jlColor: undefined,
        summaryFields: [],
        shopFields:[],
        customImageUrl: ''
      },
      viewShowTemplateUrl: {
        orange: {
          horizontal: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E6%A8%AA%E7%89%88%E7%A4%BA%E6%84%8F.png',
          block: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E7%AB%96%E7%89%88.png',
        },
        red: {
          horizontal: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E6%A8%AA%E7%89%88%E7%A4%BA%E6%84%8F.png',
          block: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E7%AB%96%E7%89%88.png',
        },
      },
      appShowTemplateUrl: {
        orange: {
          horizontal: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E6%A8%AA%E7%89%88%E8%83%8C%E6%99%AF.png',
          block: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E5%90%88%E9%9B%86-%E7%B4%AB%E8%89%B2.png',
        },
        red: {
          horizontal: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E6%A8%AA%E7%89%88%E8%83%8C%E6%99%AF.png',
          block: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E5%90%88%E9%9B%86-%E7%B4%AB%E8%89%B2.png',
        }
      },
      homePageFields,defaultTaskJson, jlTaskJson,summaryFieldMap, shopFieldMap,
      imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
      taskDialog: {
        visible: false,
      },
      submitLoading: false,
      field: {
        jlBlockSummary:['jhTotal', 'jhKss', 'jhKssDiff', 'boostBonus'],
        jlBlockShop:['jhTotal', 'jhKss', 'jhKssDiff', 'boostBonus'],
        defaultBlockSummary: ['jhTotal', 'jhKss', 'jhKssDiff', 'jhShopCount'],
        defaultBlockShop: ['jhTotal','jhTotalRate', 'jhKss', 'jhKssDiff',],
        jlHrizontalSummary: ['jhTotal', 'boostBonus'],
        jlHrizontalShop:['jhTotal', 'boostBonus'],
        defaultHrizontalSummary:['jhTotal', 'jhKss'],
        defaultHrizontalShop:['jhTotal', 'jhKss']
      },
      filterTaskOptions: [],
      options: {
        taskOptions: [],
        blockColor: [ { value: 'backGroundColor', label: '底色' },{ value: 'taskNameColor', label: '任务名颜色' },{ value: 'badgeBackColor', label: '角标标签底色' },{ value: 'badgeColor', label: '角标标签文字' }
          ,{ value: 'taskTimeColor', label: '任务时间颜色' },{ value: 'jlColor', label: '业务激励颜色' },{ value: 'fontColor', label: '数据颜色' },{ value: 'moreDataText', label: '”更多数据“颜色' }],
        horizontalColor: [ { value: 'backGroundColor', label: '底色' },{ value: 'taskNameColor', label: '任务名颜色' },{ value: 'badgeBackColor', label: '角标标签底色' },{ value: 'badgeColor', label: '角标标签文字' }
        ,{ value: 'fontColor', label: '数据颜色' },{ value: 'moreDataText', label: '”更多数据“颜色' }]
      },
      rules: {
        shopFields: [{type: 'array',  required: true, message:' ', trigger: 'blur'}],
        summaryFields: [{type: 'array',  required: true, message:' ', trigger: 'blur'}],
      }
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    formValid() {
      return new Promise((resolve) => {
        this.$refs.groupForm.validate(validate => {
          resolve(validate);
        })
      })
    },
    getDefaultData(form) {
      if (form) {
        if (form.configJson) {
          return form.configJson;
        } else {
          if (form.boostTask) {
            return jlHorizontalTaskJson;
          }
          return horizontalTaskJson;
        }
      } else {
        return horizontalTaskJson;
      }
    },
    transJsonToForm(form, json) {
      return {
        taskName: form.taskName,
        mtTaskId: form? form.mtTaskId:undefined,
        template: json.theme.mode? json.theme.mode:'horizontal',
        backGroundColor: json.theme.background.color,
        taskNameColor: json.theme.taskName.color,
        badgeBackColor:json.theme.badge.background.color,
        badgeColor: json.theme.badge.font.color,
        taskTimeColor: json.theme.taskTime.color,
        fontColor: json.theme.value.color,
        moreDataText: json.theme.moreDataText.color,
        jlColor: summaryFields.find(e => e.key==='boostBonus').label.color,
        summaryFields:json.summary.map(e => e.key),
        shopFields:json.shop.map(e => e.key),
        customImageUrl: ''
      }
    },
    getTaskOptions(org, form) {
      let change = true;
        this.taskForm = form;
        if (!this.taskForm.configJson) {
          this.taskForm.configJson = deepClone(this.getDefaultData(this.taskForm));
        } else {
          change =false;
        }
      let data =  this.transJsonToForm(this.taskForm, this.taskForm.configJson);
      const formImage = this.taskForm.configJson.theme.background.image;
      const defaultImage = defaultTaskJson.theme.background.image;
      data.customImageUrl = defaultImage!==formImage && formImage?formImage: defaultImage;
      this.dataForm = deepClone(data);
      if (change) {
        this.handleTemplateChange(data.template);
      }
    },
    handleTaskChange(val) {
      let task = this.options.taskOptions.find(e => e.mtTaskId === val);
      task.configJson = this.getDefaultData(task.boostTask);
      this.taskForm = deepClone(task);
      this.handleTemplateChange(this.dataForm.template);
    },
    handleTemplateChange(val) {
      let tmpForm = {};

      if (val === 'horizontal') {
        if ( this.taskForm && this.taskForm.boostTask) {
          tmpForm = this.transJsonToForm(this.dataForm, jlHorizontalTaskJson);
          tmpForm.summaryFields= this.field.jlHrizontalSummary;
          tmpForm.shopFields= this.field.jlHrizontalShop;
        } else {
          tmpForm = this.transJsonToForm(this.dataForm, horizontalTaskJson);
          tmpForm.summaryFields= this.field.defaultHrizontalSummary;
          tmpForm.shopFields= this.field.defaultHrizontalShop;
        }
      } else if (val === 'block') {
        if ( this.taskForm && this.taskForm.boostTask) {
          tmpForm = this.transJsonToForm(this.dataForm, jlTaskJson);
          tmpForm.summaryFields= this.field.jlBlockSummary;
          tmpForm.shopFields= this.field.jlBlockShop;
        } else {
          tmpForm = this.transJsonToForm(this.dataForm, defaultTaskJson);
          tmpForm.summaryFields= this.field.defaultBlockSummary;
          tmpForm.shopFields= this.field.defaultBlockShop;
        }
      }
      this.dataForm = tmpForm;
    },
    refreshTaskForm(nV) {
      if (!this.taskForm || !this.taskForm.mtTaskId) {
        return;
      }
      let tmpTask = deepClone(this.taskForm);
      let configJson = tmpTask.configJson;
      configJson.theme.mode = nV.template ? nV.template: 'block';
      configJson.summary = summaryFields.filter(e => nV.summaryFields.indexOf(e.key) > -1);
      configJson.shop = shopFields.filter(e => nV.shopFields.indexOf(e.key) > -1);
      configJson.theme.background.color = nV.backGroundColor;

      configJson.theme.background.color = nV.backGroundColor;
      configJson.theme.taskName.color = nV.taskNameColor;
      configJson.theme.badge.background.color = nV.badgeBackColor;
      configJson.theme.badge.font.color = nV.badgeColor;
      configJson.theme.taskTime.color = nV.taskTimeColor;
      configJson.theme.value.color = nV.fontColor;
      configJson.theme.moreDataText.color = nV.moreDataText;
      configJson.summary.forEach(e => {
        if (e.key === 'boostBonus') {
          e.value.color = nV.jlColor;
          e.label.color = hexToRgba(nV.jlColor, 0.7);
        } else {
          e.value.color = nV.fontColor;
          e.label.color = hexToRgba(nV.fontColor, 0.7);
        }
      })
      configJson.shop.forEach(e => {
        if (e.key === 'boostBonus') {
          e.label.color = nV.jlColor;
          e.value.color = nV.jlColor;
        } else {
          e.value.color = nV.fontColor;
          e.label.color = hexToRgba(nV.fontColor, 0.7);
        }
      })
      if (nV.template === 'horizontal') {
        if (this.dataForm.customImageUrl) {
          configJson.theme.background.image = this.dataForm.customImageUrl;
        } else {
          configJson.theme.background.image = defaultTaskJson.theme.background.image;
        }
      }
      tmpTask.configJson = configJson;
      tmpTask.timestamp = new Date().getTime();
      this.$emit('update:form', tmpTask);
    },
    syncConfigToOtherTask() {
      this.$emit('sync-config', deepClone(this.dataForm));
    }
  }
}
</script>

<style scoped>
.text-light {
  font-size: 11px;
  color: gray
}

::v-deep .custom-picture-card {
  display: inline-table !important;
  top: -11px !important;
  position: relative;
}
::v-deep .el-upload__tip {
    display: unset;
  }
</style>
