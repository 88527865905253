<template>
   <mg-container>
    <!-- 头部搜索 -->
    <template #headerSearch>
      <div class="head-signboard-box">
        <mg-signboard :title="'加单预警-当前明细'"/>
        <mg-signboard :title="branchCompanyInfos && branchCompanyInfos.name" :isHavBar="false" />
      </div>
       <mg-search-form
          ref="mgSearchFormRef"
          :form-item-list="formItemList"
          :row="2"
          label-width="110px"
          size="mini"
          @getSearchFormData="handleSearch"
        >
          <template #buttonScope>
            <el-button size="mini" @click="handleExport" :loading="status.isExport">导出</el-button>
            <el-button size="mini" @click="handleUnifyAddOrder">统一抛单</el-button>
            <el-button size="mini" @click="handleResetAddOrder" :loading="status.isResetAddOrder">重置加单</el-button>
          </template>
        </mg-search-form>
    </template>
    <!-- 列表 -->
    <template #table>
      <mg-table
        class="mg-list-box"
        ref="mgTable"
        :autoLoad="true"
        :columns.sync="addOrderWarningColumns"
        :api="handleAddOrderWarningLists"
        :apiParams="queryForm"
        :configurable="true"
        businessType="BRANCHOFFICE_ADDORDERWARNING_LISTS"
        height="calc(100vh - 270px)"
        max-height="auto"
      >
        <!-- 补单箱数 -->
        <template #boSupplyBoxCount="{ row }">
          <el-input
            size="mini"
            clearable
            v-model.number="row.boSupplyBoxCount"
            placeholder="请输入补单箱数"
            :disabled="row.kcStatus !== '库存不足'"
            @change="handleBoxCountEvent"
          />
        </template>
        <!-- 当月月底预计库存 -->
        <template #boThisMthExpectKcCountAfterSupply="{ row }">
          {{ calcBoThisMthExpectKcCountAfterSupply(row) }}
        </template>
        <!-- 当月月底预计库存系数 -->
        <template #boThisMthExpectKcRatioAfterSupply="{ row }">
          {{ calcBoThisMthExpectKcRatioAfterSupply(row) }}
        </template>

        <!-- 下月月底预计库存 -->
        <template #boNextMthExpectKcCount="{ row }">
          {{ calcBoNextMthExpectKcCount(row) }}
        </template>
        <!-- 下月月底预计库存系数 -->
        <template #boNextMthExpectKcRatio="{ row }">
          {{ calcBoNextMthExpectKcRatio(row) }}
        </template>

        <!-- 下下月月底预计库存 -->
        <template #boNextTwoMthExpectKcCount="{ row }">
          {{ calcBoNextTwoMthExpectKcCount(row) }}
        </template>
        <!-- 下下月月底预计库存系数 -->
        <template #boNextTwoMthExpectKcRatio="{ row }">
          {{ calcBoNextTwoMthExpectKcRatio(row) }}
        </template>

        <!-- 操作 -->
        <template #operate="{ row }">
          <el-button
            :underline="false"
            size="mini"
            type="text"
            :disabled="row.kcStatus !== '库存不足'"
            @click="handleTableRowEvent('addOrder', row)"
          >加单</el-button>
        </template>
      </mg-table>
    </template>

    <!-- 加单弹窗 -->
    <el-dialog
      class="add-dialog"
      title="加单"
      width="650px"
      :visible.sync="dialogObj.isAdd"
      :destroy-on-close="true"
      @closed="handleDialogClose"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="100px"
          size="mini"
          class="dialog-form"
          :model="dialogObj.dialogFormModel"
          :rules="dialogObj.dialogFormModelRules"
        >
          <template v-if="!isUnifyAddOrder">
            <el-row>
                <el-col>
                  <el-form-item label="货号: " size="mini">{{ dialogObj.dialogFormModel.cargoNo || '--' }}</el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                  <el-form-item label="品名: " size="mini">{{ dialogObj.dialogFormModel.skuName ? `${ dialogObj.dialogFormModel.skuCode || '' }-${ dialogObj.dialogFormModel.skuName }` : '--' }}</el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                  <el-form-item label="分公司: " size="mini">{{ dialogObj.dialogFormModel.companyName ? `${ dialogObj.dialogFormModel.companyCode || '' }-${ dialogObj.dialogFormModel.companyName }` : '--' }}</el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                  <el-form-item label="补单量: " size="mini">{{ dialogObj.dialogFormModel.boSupplyCount || 0 }}</el-form-item>
                </el-col>
            </el-row>
          </template>
          <el-row v-if="isUnifyAddOrder">
              <el-col :span="14">
                <el-form-item label="交付日期: " size="mini" prop="requestArrivalTime">
                  <el-date-picker
                      class="dialog-date-picker"
                      style="width: 120px"
                      v-model="dialogObj.dialogFormModel.requestArrivalTime"
                      type="date"
                      placeholder="请选择"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :pickerOptions="dialogObj.pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleDialogEvent('save')">保 存</el-button>
        <el-button size="small" @click="handleDialogEvent('cancel')">取 消</el-button>
      </div>
    </el-dialog>
    </mg-container>
</template>

<script>
import { excelExport } from "@/utils";
import {
  handleAddOrderWarningLists,
  handleAddOrderWarningCategory,
  handleAddOrderWarningPut,
  handleALLAddOrderWarningPut,
  handleResetAddOrderWarningPut
} from "@/api/branchOffice";
import { addOrderWarningColumns } from '../config';
import {
  optionsObj,
  urlParams,
  paginationParams,
  tableLoading,
  branchCompanyInfos,
  optionsLists
} from '@/mixins';
import { Message } from 'element-ui';
export default {
  name: "addOrderWarning",
  components: {
    mgContainer: () => import('@/mg-components/mg-container'),
    mgSearchForm: () => import("@/mg-components/mg-search-form"),
    mgSignboard: () => import('@/components/Signboard'),
    mgTable: () => import("@/mg-components/mg-table")
  },
  mixins: [
    optionsObj,
    urlParams,
    paginationParams,
    tableLoading,
    branchCompanyInfos,
    optionsLists
  ],
  computed: {
    /**
     * 当月底预计库存: 当月预计库存 + 补单量
     * boThisMthExpectKcCountAfterSupply = boThisMthExpectKcCount + boSupplyCount
     *
     * 当月底预计库存系数: 当月底预计库存 / 上6月月均销量
     * boThisMthExpectKcRatioAfterSupply = boThisMthExpectKcCountAfterSupply / boLastSixMthSaleCountAvg
     *
     * 次月底预计库存: 当月底预计库存 + 次月系统欠量
     * boNextMthExpectKcCount = boThisMthExpectKcCountAfterSupply + boNextMthQlCount
     *
     * 次月底预计库存系数: 次月底预计库存 / 上6月月均销量
     * boNextMthExpectKcRatio = boNextMthExpectKcCount / boLastSixMthSaleCountAvg
     *
     * 次次月预计库存: 次月底预计库存 + 次次月系统欠量
     * boNextTwoMthExpectKcCount = boNextMthExpectKcCount + boNextTwoMthQlCount
     *
     * 次次月预计库存系数: 次次月预计库存 / 上6月月均销量
     * boNextTwoMthExpectKcRatio = boNextTwoMthExpectKcCount / boLastSixMthSaleCountAvg
     */
    // 当月底预计库存
    calcBoThisMthExpectKcCountAfterSupply() {
      return row => {
        return row.boThisMthExpectKcCountAfterSupply = this.$np.round(this.$np.plus(row.boThisMthExpectKcCount || 0, row.boSupplyBoxCount || 0), 1) || 0
      }
    },
    // 当月底预计库存系数
    calcBoThisMthExpectKcRatioAfterSupply() {
      return row => {
        const boThisMthExpectKcCountAfterSupply = this.$np.round(this.$np.plus(row.boThisMthExpectKcCount || 0, row.boSupplyBoxCount || 0), 1);
        return row.boThisMthExpectKcRatioAfterSupply = row.boLastSixMthSaleCountAvg !== 0 ? this.$np.round(this.$np.divide(boThisMthExpectKcCountAfterSupply || 0, row.boLastSixMthSaleCountAvg || 0), 1) || 0 : 0;
      }
    },
    // 次月底预计库存
    calcBoNextMthExpectKcCount() {
      return row => {
        const boThisMthExpectKcCountAfterSupply = this.$np.round(this.$np.plus(row.boThisMthExpectKcCount || 0, row.boSupplyBoxCount || 0), 1);
        return row.boNextMthExpectKcCount = this.$np.round(this.$np.plus(boThisMthExpectKcCountAfterSupply || 0, row.boNextMthQlCount || 0), 1) || 0
      }
    },
    // 次月底预计库存系数
    calcBoNextMthExpectKcRatio() {
      return row => {
        const boThisMthExpectKcCountAfterSupply = this.$np.round(this.$np.plus(row.boThisMthExpectKcCount || 0, row.boSupplyBoxCount || 0), 1);
        const boNextMthExpectKcCount = this.$np.round(this.$np.plus(boThisMthExpectKcCountAfterSupply || 0, row.boNextMthQlCount || 0), 1) || 0
        return row.boNextMthExpectKcRatio = row.boLastSixMthSaleCountAvg !== 0 ? this.$np.round(this.$np.divide(boNextMthExpectKcCount, row.boLastSixMthSaleCountAvg || 0), 1) || 0 : 0;
      }
    },
    // 次次月预计库存
    calcBoNextTwoMthExpectKcCount() {
      return row => {
        const boThisMthExpectKcCountAfterSupply = this.$np.round(this.$np.plus(row.boThisMthExpectKcCount || 0, row.boSupplyBoxCount || 0), 1);
        const boNextMthExpectKcCount = this.$np.round(this.$np.plus(boThisMthExpectKcCountAfterSupply || 0, row.boNextMthQlCount || 0), 1) || 0
        return row.boNextTwoMthExpectKcCount = this.$np.round(this.$np.plus(boNextMthExpectKcCount, row.boNextTwoMthQlCount || 0), 1) || 0;
      }
    },
    // 次次月预计库存系数
    calcBoNextTwoMthExpectKcRatio() {
      return row => {
        const boThisMthExpectKcCountAfterSupply = this.$np.round(this.$np.plus(row.boThisMthExpectKcCount || 0, row.boSupplyBoxCount || 0), 1);
        const boNextMthExpectKcCount = this.$np.round(this.$np.plus(boThisMthExpectKcCountAfterSupply || 0, row.boNextMthQlCount || 0), 1) || 0
        const boNextTwoMthExpectKcCount = this.$np.round(this.$np.plus(boNextMthExpectKcCount, row.boNextTwoMthQlCount || 0), 1) || 0;
        return row.boNextTwoMthExpectKcRatio = row.boLastSixMthSaleCountAvg !== 0 ? this.$np.round(this.$np.divide(boNextTwoMthExpectKcCount, row.boLastSixMthSaleCountAvg || 0), 1) || 0 : 0
      }
    }

  },
  data() {
    return {
      formItemList: [
        {
          type: 'select',
          label: '库存',
          model: 'kcStatus',
          initialValue: '',
          options: []
        },
        {
          type: 'select',
          label: '赛道',
          model: 'race',
          initialValue: '',
          options: []
        },
        {
          type: 'select',
          label: '大类',
          model: 'mainCategoryCode',
          initialValue: '',
          options: [],
          change: this.handleMainCategoryNameChange
        },
        {
          type: 'select',
          label: '中类',
          model: 'midCategoryCode',
          initialValue: '',
          options: [],
          change: this.handleMidCategoryNameChange
        },
        {
          type: 'select',
          label: '小类',
          model: 'subCategoryCode',
          initialValue: '',
          options: []
        },
        {
          type: 'select',
          label: '分公司产品等级',
          model: 'grade',
          initialValue: '',
          options: []
        },
        {
          type: 'input',
          label: '货号',
          model: 'cargoNo',
          initialValue: '',
          placeholder: "请输入货号"
        },
        {
          type: 'input',
          label: '品名',
          model: 'skuName',
          initialValue: '',
          placeholder: "请输入品名"
        },
        {
          type: 'input',
          label: '料号',
          model: 'materialCode',
          initialValue: '',
          placeholder: "请输入料号"
        }
      ],
      status: {
        isLoading: false,
        isExport: false,
        isResetAddOrder: false
      },
      addOrderWarningColumns,
      handleAddOrderWarningLists,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      boAddOrderList: [],
      kcStatus: [],
      race: [],
      grade: [],
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        kcStatus: null,
        race: null,
        grade: null,
        mainCategoryCode: null,
        midCategoryCode: null,
        subCategoryCode: null,
        cargoNo: null,
        skuName: null,
        materialCode: null,
      },
      dialogObj: {
        isAdd: false,
        dialogFormModel: {
          cargoNo: '',
          skuCode: '',
          skuName: '',
          companyCode: '',
          companyName: '',
          boSupplyCount: '',
          requestArrivalTime: ''
        },
        dialogFormModelRules: {
          requestArrivalTime: [
            { required: true, message: '请选择交付日期', trigger: 'blur' },
          ]
        },
        pickerOptions: {
            disabledDate(time) {
                const dateTime = new Date();
                const startDateTime = dateTime.setDate(dateTime.getDate() - 1);
                return time.getTime() < new Date(startDateTime).getTime();
            }
        },
      },
      isUnifyAddOrder: false
    };
  },
  created() {
    this.handleAddOrderWaringOptions();
    this.handleUrlParams().then(res => {
      this.handleSelectOptions()
    });
  },
  methods: {
    // 表头搜索
    handleSearch(params) {
        this.queryForm = {
            ...this.queryForm,
            ...params,
            boNextMthExpectKcRatioRange: params?.boNextMthExpectKcRatioRange?.join(';') || null
        }
        this.handleFetchData()
    },
    // 加载列表数据
    handleFetchData() {
        this.$nextTick(() => {
            this.$refs['mgTable']?.loadData()
        })
    },

    /** 搜索框选项加载 */
    async handleAddOrderWaringOptions() {
      try {
        const resCatagory = await handleAddOrderWarningCategory();
        if (resCatagory.code == '200') {
          const {data} = resCatagory;
          this.kcStatus = data.kcStatus;
          this.race = data.race;
          this.grade = data.grade;
        }
      } catch (err) {
        console.log(err, 'err-----获取字典选项接口错误');
      }
    },

    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      console.log(selection);
    },
    // 表格事件
    handleTableRowEvent(type, row) {
      const dialogObj = this.dialogObj;
      const funcObj = {
        'addOrder': () => {
          const reg = /^(0|[1-9][0-9]*)$/;
          if ( row.boSupplyBoxCount === '' ) return Message.error('若需加单, 补单箱数不能为空')
          if ( !reg.test(row.boSupplyBoxCount) ) return Message.error('格式有误, 须整数且不能以0开头')
          if ( row.boSupplyBoxCount === 0 ) return Message.error('若需加单, 补单箱数需大于等于1')
          dialogObj.isAdd = true;
          dialogObj.dialogFormModel = { ...dialogObj.dialogFormModel, ...row, boSupplyCount: row.boSupplyBoxCount }
          dialogObj.dialogFormModel.requestArrivalTime = ''
        }
      };
      funcObj[type]()
    },
    // dialog事件
    handleDialogEvent(type) {
      const dialogObj = this.dialogObj;
      const funcObj = {
        'save': () => {
          this.$refs['dialogForm'].validate(async foo => {
            if (!foo) return;
            try {
              const { companyCode, skuCode, boSupplyCount, requestArrivalTime } = dialogObj.dialogFormModel;
              let paramObj = null;
              if (this.isUnifyAddOrder) paramObj = { requestArrivalTime: requestArrivalTime || '' };
              if (!this.isUnifyAddOrder) {
                paramObj = {
                  skuCode: skuCode || '',
                  boSupplyCount: Number(boSupplyCount) || ''
                };
              }
              const { code, msg } = this.isUnifyAddOrder ? await handleALLAddOrderWarningPut(paramObj) : await handleAddOrderWarningPut(paramObj)
              if (code === 200) {
                this.resetQuery();
                return Message.success('加单成功')
              }
              if (code != 200) return Message.error(msg)
            } catch(err) {
              throw new Error(err)
            } finally {
              dialogObj.isAdd = false;
            }
          })
        },
        'cancel': () => {
          dialogObj.isAdd = false;
        }
      };
      funcObj[type]()
    },
    // 弹窗回调
    handleDialogClose(e) {
      this.isUnifyAddOrder = false;
    },
    // 补单箱数监听事件
    handleBoxCountEvent(e) {
      const reg = /^(0|[1-9][0-9]*)$/;
      if ( e && !reg.test(e) ) return Message.error('格式有误, 须整数且不能以0开头')
    },
    // 导出
    async handleExport() {
      const status = this.status;
      this.queryParams.page = 1
      this.queryParams.limit = 1000000
      this.$refs['mgSearchFormRef']?.$refs['form']?.resetFields();
      const topHeaders = {}
      const propsAddOrderWarningColumns = this.addOrderWarningColumns.filter(item => item.prop)
      propsAddOrderWarningColumns.map(item => {
        return topHeaders[item.prop] = item.label
      })
      try {
        status.isExport = true;
        const { code, data, msg } = await handleAddOrderWarningLists(this.queryParams);
        status.isExport = false;
        if ( code === 200 && data?.boAddOrderDetailsList.length > 0) {
          const newItems = data.boAddOrderDetailsList && data.boAddOrderDetailsList.map(item => {
            return {
              ...item,
              boSupplyBoxCount: item.boSupplyCount || 0
            }
          }) || [];
          excelExport({
            topHeaders,
            data: newItems,
            title: '加单预警列表'
          })
        }
        if ( code != 200 ) return Message.warning(msg)
      } catch(err) {
        throw new Error(err)
      } finally {
        status.isExport = false;
      }

    },
    // 统一抛单
    handleUnifyAddOrder() {
      const dialogObj = this.dialogObj;
      this.isUnifyAddOrder = true;
      dialogObj.isAdd = true;
      dialogObj.dialogFormModel.requestArrivalTime = '';
    },
    // 重置加单
    async handleResetAddOrder() {
      const status = this.status;
      try {
        status.isResetAddOrder = true;
        const { code, msg } = await handleResetAddOrderWarningPut();
        if ( code === 200 ) {
          this.resetQuery();
          return Message.success('重置成功')
        }
        if (code != 200) return Message.error(msg)
      } catch (err) {
        throw new Error(err)
      } finally {
        status.isResetAddOrder = false;
      }
    },
    // 下拉列表选项
    handleSelectOptions() {
        // 产品等级
        const gradeField = {
            key: 'grade',
            label: 'label',
            value: 'value'
        }
        // 赛道
        const raceField = {
            key: 'race',
            label: 'label',
            value: 'value'
        }
        // 大类
        const categoryField = {
            key: 'mainCategoryCode',
            label: 'max_name',
            value: 'max_code'
        }
        const paramsObj = {
            dict_type: 'plu_grade',
            operator: this.urlParams?.operator || ''
        }
        Promise.all([
            this.handleProductGradeOption({ formItemList: this.formItemList, field: gradeField, paramsObj }),
            this.handleRaceNamesOption({ formItemList: this.formItemList, field: raceField, paramsObj }),
            this.handleProductMaxCatagoryOption({ formItemList: this.formItemList, field: categoryField, paramsObj })
        ])
    },
    // 根据大类获取中类
    handleMainCategoryNameChange(val) {
        const queryForm = this.queryForm
        const mgSearchFormRef = this.$refs['mgSearchFormRef']
        const formItemList = this.formItemList
        queryForm.midCategoryCode = '';
        mgSearchFormRef.form['midCategoryCode'] = ''
        if (!val) return;
        const paramsObj = {
            max_code: val,
            operator: this.urlParams?.operator || ''
        };
        const field = {
            key: 'midCategoryCode',
            label: 'mid_name',
            value: 'mid_code'
        }
        this.handleProductMidCatagoryOption({ formItemList: formItemList, field, paramsObj })
    },
    // 根据中类获取小类
    handleMidCategoryNameChange(val) {
        const queryForm = this.queryForm
        const mgSearchFormRef = this.$refs['mgSearchFormRef']
        const formItemList = this.formItemList
        queryForm.subCategoryCode = '';
        mgSearchFormRef.form['subCategoryCode'] = ''
        if (!val) return;
        const paramsObj = {
            mid_code: val,
            operator: this.urlParams?.operator || ''
        };
        const field = {
            key: 'subCategoryCode',
            label: 'min_name',
            value: 'min_code'
        }
        this.handleProductMinCatagoryOption({ formItemList: formItemList, field, paramsObj })
    }
  }
};
</script>

<style lang="scss" scoped src='@/style/branchOffice/addOrderWarning.scss'></style>
