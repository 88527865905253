<template>
  <div>
    <div class="mg-search">
      <div class="head-signboard-box">
        <mg-signboard title="新品上市跟进明细" />
        <div style="float: right">
          <el-select
            v-model="queryForm.companyCode"
            style="width: 90px; padding-right: 10px"
            size="small"
            filterable
            @change="handleChangeSelect"
          >
            <el-option
              v-for="item in companies"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-button
            size="small"
            type="primary"
            :loading="btnStatus.isExport"
            @click="handleTableHeadAction('export')"
            >导出</el-button
          >
        </div>
      </div>
    </div>
    <div class="mg-list-box">
      <mg-table
        :autoLoad="false"
        :columns="newPublishDetailColumns"
        :api="handleNewPublishListsByBranch"
        :apiParams="queryForm"
        :extraParams="{ isEdit: false }"
        ref="mgTable"
        height="calc(100vh - 300px)"
      >
      </mg-table>
    </div>
  </div>
</template>

<script>
import { newPublishDetailColumns } from "../config";
import { handleNewPublishListsByBranch, getAcceptOrg } from "@/api/newPublish";
import { excelExport } from "@/utils";

export default {
  // 分公司明细
  name: "newPublishDetail",
  components: {
    mgSignboard: () => import("@/components/Signboard"),
    mgTable: () => import("@/components/Element/mg-table"),
  },
  computed: {},
  data() {
    return {
      btnStatus: {
        isExport: false,
      },
      newPublishDetailColumns,
      handleNewPublishListsByBranch,
      getAcceptOrg,
      queryForm: {
        page: 1,
        limit: 10,
        companyCode: undefined,
      },
      disabledEdit: true,
      companies: [],
    };
  },
  created() {
    getAcceptOrg().then((res) => {
      this.companies = res.data;
      if (this.companies && this.companies.length > 0) {
        this.queryForm.companyCode = this.companies[0].code;
      }
    });
  },
  methods: {
    // 获取列表
    handleLists() {
      this.$nextTick(() => {
        this.$refs["mgTable"]?.loadData();
      });
    },
    handleChangeSelect(val) {
      if (val) {
        this.handleSubmit();
      }
    },
    // 顶部搜索
    handleSubmit() {
      this.queryForm.page = 1;
      this.handleLists();
    },
    // 表单顶部事件
    handleTableHeadAction(type) {
      const importDialog = this.importDialog;
      /**
       * reset                    -- 重置
       * importProductGrade       -- 导入产品等级
       * importInventoryFactor    -- 导入库存系数
       * importEstimatedSales     -- 导入预估销量
       * export                   -- 导出
       */
      if (["importAddReduce"].includes(type)) {
        const currentImportOption = importDialog.importBaseParams.find(
          (item) => item.type === type
        );
        importDialog.currentImportType = currentImportOption.type;
        currentImportOption.visible = true;
        return false;
      }
      const funcObj = {
        export: async () => {
          const btnStatus = this.btnStatus;
          const queryForm = this.queryForm;
          const topHeaders = {};
          const filterNewPublishDetailColumns =
            this.newPublishDetailColumns.filter((item) => item.prop);
          filterNewPublishDetailColumns.map((item) => {
            return (topHeaders[item.prop] = item.label);
          });
          try {
            btnStatus.isExport = true;
            queryForm.page = 1;
            queryForm.limit = 1000000;
            const { code, data } = await handleNewPublishListsByBranch(
              queryForm
            );
            if (code === 200 && data?.datas.length > 0) {
              const newItems =
                (data.datas &&
                  data.datas.map((item) => {
                    return {
                      ...item,
                    };
                  })) ||
                [];
              excelExport({
                topHeaders,
                data: newItems,
                title: "新品上市报表",
              });
            }
          } catch (err) {
            throw new Error(err);
          } finally {
            btnStatus.isExport = false;
          }
        },
      };
      funcObj[type]();
    },
  },
};
</script>

<style lang="scss" scoped src="@/style/branchOffice/common.scss"></style>
