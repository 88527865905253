<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter('hand')"  @reset="resetQuery" @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->


        <el-form-item label="关键字">
          <el-input v-model="queryForm.topicCollectionName" clearable placeholder="请输入板块标题关键字" />
        </el-form-item>
        <el-form-item v-if="authOrgOptions.length > 1" label="分公司">
          <el-select v-model="queryForm.authOrgCode" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="显示状态" >
          <el-select v-model="queryForm.ifShow">
            <el-option label="全部" value="ALL" />
            <el-option label="显示" value="1" />
            <el-option label="不显示" value="0" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd">创建新板块</el-button>
    <el-table
    style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
      class="customer-table"
      border
            size="small"
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column label="板块ID" prop="topicCollectionId"  width="200" show-overflow-tooltip/>
      <el-table-column label="板块标题" prop="topicCollectionName" width="180"/>
      <el-table-column label="分公司" prop="orgAuthName"  width="180" />
      <el-table-column label="角色权限" prop="rolePermission" />
      <el-table-column label="更新时间" prop="createTime" width="200" />
      <el-table-column label="话题数" prop="topicCount"  />
      <el-table-column label="帖子数" prop="experienceCount"  />
      <el-table-column label="浏览量" prop="viewCount"  />
      <el-table-column label="评论" prop="answerCount"   />
      <el-table-column label="点赞" prop="likeCount"   />
      <el-table-column label="收藏" prop="collectCount"  />
      <el-table-column label="状态" prop="ifShow"   >
        <template #default="{ row }">
          <span>{{ row.ifShow === 1? '显示': '不显示' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template #default="{ row }">
          <el-button size="mini" v-if="row.orgAuthCode==queryForm.authOrgCode || authOrgAll" type="text" style="border: 0px;" @click="handleEdit(row, 'view')">编辑</el-button>
          <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.ifShow === 1" type="text" style="border: 0px;" @click="handleShowUpdate(0, row)">不显示</el-button>
          <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.ifShow === 0" type="text" style="border: 0px;" @click="handleShowUpdate(1, row)">显示</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
</el-card>
    <el-dialog title="" :center="true" :visible.sync="saveDialog.visible" append-to-body width="750px" :close-on-click-modal="false" :before-close="handleBeforeClose">
      <div style="overflow-y: scroll;height: 600px">
        <el-form ref="saveFormRef" size="mini" label-width="auto" :inline="true" :rules="saveDialog.rules" :model="saveDialog.form">
          <div>
            <el-row>
              <el-col>
                <span style="color: #1482f0; font-size: 24px;">基本信息</span>
              </el-col>
              <el-col style="margin-top: 2%">
                <el-form-item label="板块标题" prop="topicCollectionName">
                  <el-input v-model="saveDialog.form.topicCollectionName" style="width: 350px" clearable placeholder="请输入板块标题,建议13字以内" />
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 2%">
                <el-form-item label="板块描述" prop="topicCollectionContent">
                  <el-input v-model="saveDialog.form.topicCollectionContent" style="width: 350px" clearable placeholder="请输入板块描述" />
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 2%">
                <el-form-item label="分公司" prop="orgAuthCode">
                  <el-select v-model="saveDialog.form.orgAuthCode">
                    <el-option v-for="item in authOrg" :label="item.label" :value="item.value" :key="item.key" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 2%">
                <el-form-item label="分组权限">
                  <el-radio-group v-model="saveDialog.form.authType">
                    <el-radio :label="0">不限制</el-radio><br>
                    <el-radio :label="1" ref="radio">
                      指定角色可见
                      <el-select size="mini" v-model="saveDialog.form.roleKeys" clearable filterable multiple	placeholder="请选择角色" @change="handleRoleChange">
                        <el-option
                          v-for="item in roleOptions"
                          :key="item.roleKey"
                          :label="item.roleName"
                          :value="item.roleKey">
                        </el-option>
                      </el-select>
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 2%">
                <el-form-item label="是否显示" prop="ifShow">
                  <el-radio-group v-model="saveDialog.form.ifShow">
                    <el-radio :label=1><span style="font-size: 12px;">显示</span></el-radio>
                    <el-radio style="font-size: 12px;" :label=0>不显示</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 2%;">
                <el-form-item label="排序权重" prop="weight">
                  <div style="width: 300px">
                    <el-input-number v-model="saveDialog.form.weight" :min=0  :controls="false" style="width: 60px" :precision ="0" /><span style="font-size: 12px;margin-left: 2%;width: 150px">数字越大,排序越靠前</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 2%">
                <el-form-item label="选择颜色" prop="color">
                  <el-radio-group v-model="saveDialog.form.color">
                    <el-radio
                      v-for="item in colorOptions"
                      :key="item.color"
                      :label="item.color"
                    >{{item.colorName}}
                     <img :src="horizontal[item.color]" :style="{height: '80px', display: 'block', 'max-width': '220px'}"/>
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="position: absolute; bottom: 5px;width: 90%;background-color: white;height: 30px">
          <div style="display: inline-block; float: right">
            <el-button type="primary" size="mini" @click="handleSave">提交</el-button>
            <el-button type="primary" size="mini" @click="saveDialog.visible = false">关闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="操作结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  queryPlatePageList,
  updatePlateShow,
  addPlate,
  queryPlateDetail,
  editPlate,
  queryPlateColorsList,
  getBizRoleList
} from "@/api/appTask";
import { Notification } from "element-ui";
import OssUpload from "@/components/OssUpload";
import SearchFilter from "@/components/SearchFilter/index";
import {deepClone} from "../../../utils";
export default {
  name: "BoostPacket",
  components: { Pagination, OssUpload,ImportDialog: () => import("@/components/ImportDialog"), SearchFilter },
  data() {
    return {
      deepClone,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        topicCollectionName: undefined,
        ifShow: 'ALL',
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      saveDialog: {
        visible: false,
        form: {
          topicCollectionId: undefined,
          topicCollectionName: undefined,
          topicCollectionContent: undefined,
          orgAuthCode: undefined,
          ifShow: 1,
          weight: undefined,
          color: undefined,
          authType: 0,
          roleKeys: [],
        },
        rules: {
          topicCollectionName: [{ required: true, message: " ", trigger: "change" }],
          orgAuthCode: [{ required: true, message: " ", trigger: "change" }],
          ifShow: [{ required: true, message: " ", trigger: "change" }],
          weight: [{ required: true, message: " ", trigger: "change" }],
        },
        defaultForm: {
          topicCollectionId: undefined,
          topicCollectionName: undefined,
          orgAuthCode: undefined,
          ifShow: 1,
          weight: undefined,
          color: undefined,
          authType: 0,
          roleKeys: []
        }
      },
      authOrgOptions: [],
      authOrg: [],
      authOrgCodes: [],
      noticeDialog: {
        visible: false,
        msg: undefined
      },
      jbpShareUrl: '#/questionnaire/detail?id=',
      colorOptions: [],
      roleOptions: [],
      horizontal: {
        yellow:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/forum/plate-yellow@2x.png',
        red: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/forum/plate-red@2x.png',
        purple: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/forum/plate-purple@2x.png',
        green:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/forum/plate-green@2x.png',
        blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/forum/plate-blue@2x.png',
      },
    };
  },
  watch: {
    "saveDialog.form.orgRange": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
      },
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrg = res.data;
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = this.authOrg.filter(e => e.value!=='ALL');
      if (res.data.length !== this.authOrgOptions.length) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.authOrgCode = this.authOrgOptions[0].value;
      }
      this.handleFilter();
    });

    queryPlateColorsList().then(res => {
      this.colorOptions = res.data;
    });

    getBizRoleList({}).then(res => {
      if (!res.data) {
        return false;
      }
      this.roleOptions = res.data;
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);
      if(this.queryForm.ifShow === 'ALL'){
        param.ifShow = undefined;
      }
      queryPlatePageList(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        topicCollectionName: undefined,
        ifShow: 'ALL',
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleAdd() {
      this.saveDialog.form = deepClone(this.saveDialog.defaultForm);
      if (this.authOrgOptions.length > 0) {
        this.saveDialog.form.orgRange = [this.authOrgOptions[0].value];
      }
      this.saveDialog.visible = true;
    },
    handleEdit(row) {
      queryPlateDetail(row.topicCollectionId).then(res => {
        if (res.code == 200) {
          if  (res.data) {
            this.saveDialog.form = res.data;
            this.saveDialog.visible = true;
            this.$forceUpdate();
          } else {
            Notification.error("板块已删除或不存在！");
          }
        }
      })
    },
    handleSave() {
      this.$refs.saveFormRef.validate(valid => {
        if (valid) {
          let form = deepClone(this.saveDialog.form);
          if (form.orgAuthCode.length === 0) {
            Notification.warning("请至少选择一个分公司！");
            return;
          }
          console.log(this.saveDialog.form.roleKeys)
          if(form.topicCollectionId){
            editPlate(form).then(res => {
              if (res.code === 200) {
                Notification.success("操作成功!");
                this.saveDialog.visible = false;
                // this.handleFilter();
                this.handleQuery();
              }
            })
          }else {
            addPlate(form).then(res => {
              if (res.code === 200) {
                Notification.success("操作成功!");
                this.saveDialog.visible = false;
                // this.handleFilter();
                this.handleQuery();
              }
            })
          }
        }
      })
    },
    handleRoleChange() {
      this.saveDialog.form.authType = 1;
    },
    handleShowUpdate(ifShow,row) {
      this.$confirm('请确认是否更改显示状态！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          topicCollectionId: row.topicCollectionId,
          ifShow: ifShow,
        }
        updatePlateShow(parameters).then(res => {
          if (res.code===200) {
            Notification.success("更新显示状态成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },

    handleBeforeClose(done) {
      this.$confirm('关闭后不会保存当前修改，请确认是否关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleDictChange(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          if (this.authOrgOptions.length === 0) {
            return;
          }
          if (this.authOrgOptions.length > 2) {
            if (oldVal && oldVal.length > 1) {
              if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.saveDialog.form.orgRange.filter(e => e !== 'ALL');
              } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = [];
              } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            } else {
              if (val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (val.indexOf('ALL') < -1 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            }
          }
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
