<template>
  <el-dialog title="筛选设置" :visible.sync="dialogVisible" width="853px" :before-close="handleClose">
    <p>已展示筛选项（可拖动进行排序)</p>
    <el-row :gutter="10">
      <draggable v-model="itemList">
        <transition-group>
          <template v-for="(item, index) in itemList">
            <el-col :key="item.model" v-if="!item.hidden" :span="6" class="set_form_item" style="cursor: pointer">
              <div class="set_form_item_left">
                <span style="display: flex; align-items: center" @click="handleRemove(item, index)">
                  <mg-svg-icon :icon-height="15" :icon-width="15" icon-class="mg-icon_delete"></mg-svg-icon>
                </span>
                {{ item.label }}
              </div>
              <i class="el-icon-tickets"></i>
            </el-col>
          </template>
        </transition-group>
      </draggable>
    </el-row>
    <p style="margin-top: 16px">未展示筛选项</p>
    <el-row :gutter="10">
      <template v-for="(item, index) in itemList">
        <el-col v-if="item.hidden" :key="item.model" :span="6" class="set_form_item" style="cursor: pointer">
          <div class="set_form_item_left">
            <span style="display: flex; align-items: center" @click="handleAdd(item, index)">
              <mg-svg-icon :icon-height="15" :icon-width="15" icon-class="mg-icon_add"></mg-svg-icon>
            </span>
            {{ item.label }}
          </div>
        </el-col>
      </template>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    name: 'SetFormItem',
    components: { draggable, mgSvgIcon: () => import('@/components/SvgIcon/icon') },
    props: {
      formItems: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        dialogVisible: false,
      }
    },
    computed: {
      itemList: {
        get() {
          return this.formItems
        },
        set(val) {
          this.$emit('update:formItems', val)
        },
      },
    },
    methods: {
      handleSubmit() {
        const formItem = this.$lodash.cloneDeep(this.itemList)
        formItem.forEach((item) => {
          item.initialValue = null
          if (item.type === 'select') {
            item.options = []
          }
        })
        console.log(formItem)
        console.log(JSON.stringify(formItem))
      },
      handleRemove(item) {
        const hiddenList = this.itemList.filter((item) => !item.hidden)
        if (hiddenList.length === 1) return
        this.itemList.forEach((data, index) => {
          if (item.model === data.model) {
            this.itemList.splice(index, 1)
            this.itemList.push({ ...data, hidden: true })
            this.$set(this.itemList)
          }
        })
      },
      handleAdd(item) {
        this.itemList.forEach((data, index) => {
          if (item.model === data.model) {
            this.itemList.splice(index, 1)
            this.itemList.push({ ...data, hidden: false })
            this.$set(this.itemList)
          }
        })
      },
      handleClose(done) {
        done()
      },
      show() {
        this.dialogVisible = true
      },
    },
  }
</script>

<style scoped lang="scss">
  p {
    margin: 0 0 6px;
    font-size: 12px;
  }
  .set_form_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 195px;
    height: 32px;
    margin-top: 8px;
    margin-left: 8px;
    font-size: 12px;
    line-height: 32px;
    cursor: pointer;
    background: rgba(37, 42, 61, 0.05);
    .set_form_item_left {
      display: flex;
      align-items: center;
      svg {
        padding-right: 8px;
      }
    }
  }
  ::v-deep {
    .el-dialog__body {
      padding: 10px 20px;
    }
  }
</style>
