<template>
  <el-form ref="groupForm" size="mini" label-width="120px" :inline="true" :model="page" class="common-form">
    <el-form-item label="合集名称" prop="title">
      <el-input v-model="page.title" placeholder="请输入合集名称" />
    </el-form-item>
    <el-form-item label="合集简介" prop="title">
      <el-input v-model="page.description" style="width: 400px" :autosize="{ minRows: 2, maxRows: 5}" type="textarea" maxlength="150" show-word-limit placeholder="选填，请输入简介" />
    </el-form-item>
    <el-form-item label="选择页面模板" prop="template">
      <el-radio-group v-model="page.template" @input="(val) => {
        page.background.customColor=colorMap[val];
        if (!page.customHeadImage) {
          page.background.image = templateUrl[val];
        }
        $forceUpdate();
      }">
        <el-radio label="red" value="red">红色系模板</el-radio>
        <el-radio label="orange" value="orange">橙色系模板</el-radio>
<!--        <el-radio label="yellow" value="yellow">黄色系模板</el-radio>-->
      </el-radio-group>
    </el-form-item>
    <el-form-item label="顶部头图" prop="template">
      <el-radio-group v-model="page.customHeadImage" @input="(v) => {
        if (!v) {
          page.background.image = templateUrl[page.template];
        } else {
          page.background.image = page.customImageUrl? page.customImageUrl:templateUrl[page.template];
        }
      }">
        <el-radio :label="false" :value="false">模板默认头图<img :src="templateUrl[page.template]" style="height: 80px; display: block;" /></el-radio>
        <el-radio :label="true" :value="true">自定义
          <OssUpload ref="ossUpload" :value-to.sync="page.customImageUrl" :maxFileSize="1024" list-type="picture-card" style="display: table"
                     :limit="1" :always-show-button="false" tips="尺寸1125*726，下方225高度勿放置重要内容会被遮挡，图片建议小于1M。合集标题请做在图上一并上传" :tipMessageShow="false"
                     :accept-type="imgTypes" />
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="颜色配置" prop="template">
      <div style="display: flex">
        <span style="display: inline-flex">页面背景色</span><el-color-picker v-model="page.background.customColor" />
      </div>
    </el-form-item>
    <el-form-item label="任务排序" prop="fields">
      <el-table
        :data="taskData.tasks"
        size="mini"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="任务名称" prop="taskName" width="200" />
        <el-table-column label="操作" width="300" align="right">
          <template slot-scope="scope">
            <el-row>
              <el-button-group>
                <el-button size="mini" type="primary"  @click="handleEdit(scope.row)">编辑样式</el-button>
                <el-button size="mini" type="primary"  @click="() => taskData.tasks.splice(scope.$index, 1)">移除</el-button>
                <el-button :disabled="scope.$index<=0" size="mini" type="primary" icon="el-icon-arrow-up" @click="handleMove(scope.$index, 'up')"></el-button>
                <el-button :disabled="scope.$index===taskData.tasks.length-1" size="mini" type="primary" icon="el-icon-arrow-down"  @click="handleMove(scope.$index, 'down')"></el-button>
              </el-button-group>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px">
<!--        <el-button size="mini" type="text" style="border: 0px; width: 100%; text-align: center" icon="el-icon-plus" @click="handleAdd">添加任务</el-button>-->
        <span>添加任务</span>
        <el-select v-model="checkedTasks" value-key="mtTaskId" multiple clearable filterable>
          <el-option v-for="item in filterTaskOptions" :key="item.mtTaskId" :label="item.taskName" :value="item" />
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="() => {
          taskData.tasks = taskData.tasks.concat(checkedTasks);
          checkedTasks = [];
        }">确认添加</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  defaultTaskJson,
  homePageFields,
  horizontalTaskJson,
  jlHorizontalTaskJson,
  summaryFields,
  theme
} from '../../../config';
import OssUpload from "@/components/OssUpload";
import { deepClone } from "@/utils";
import { listGroupTask } from "@/api/appTask";

export default {
  name: "GroupContent",
  components: { OssUpload },
  props: {
    type: {
      type: String,
      default: "add"
    },
    task: {
      type: Object,
      required: true,
    }
  },
  watch: {
    task: {
      handler(nV, oV) {
        if (nV.timestamp) {
          this.taskData = nV;
          return;
        }
        this.handleGetTasks(nV);
        const configJson = nV.configJson;
        const template = configJson.page.template;
        const customTemplate = configJson.page.customHeadImage? true: false;
        this.page = {
          title: configJson.page.title,
          template: configJson.page.template,
          customHeadImage: customTemplate,
          description: nV.description,
          background: {
            image: customTemplate ? configJson.page.background.image : this.templateUrl[template],
            customColor: configJson.page.background.color ? configJson.page.background.color : this.page.background.color
          },
          customImageUrl: ''
        };
        if (this.page.customHeadImage) {
          this.page.customImageUrl = this.page.background.image;
        }
        this.taskData = nV;
      },
      deep: true,
      immediate: true
    },
    // taskData: {
    //   handler(nV, oV) {
    //     this.$emit('update:task', nV);
    //   },
    //   deep: true,
    //   immediate: true
    // },
    page: {
      handler(nV, oV) {
        let tmpTask = deepClone(this.taskData);
        tmpTask.timestamp = new Date().getTime();
        let configJson = tmpTask.configJson;
        const custom = this.page.customHeadImage && this.page.customImageUrl;
        configJson.page = {
          title: this.page.title,
          template: this.page.template,
          customHeadImage: custom,
          background: {
            image: custom ? this.page.customImageUrl : this.templateUrl[this.page.template],
            color: this.page.background.customColor
          },
        }
        tmpTask.description = nV.description;

        tmpTask.configJson = configJson;
        this.$emit('update:task', tmpTask);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    filterTaskOptions() {
      return this.taskOptions.filter(e => this.taskData.tasks.filter(t => t.mtTaskId === e.mtTaskId).length ===0 && [1, 2].indexOf(e.status) > -1)
    }
  },
  data() {
    return {
      theme, deepClone,
      taskData: this.task,
      page: {
        title: undefined,
        template: 'red',
        customHeadImage: false,
        description: undefined,
        background: {
          image: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E5%A4%B4%E5%9B%BE%E8%83%8C%E6%99%AF.png',
          customColor: "#FA6D5E"
        },
        customImageUrl:''
      },
      templateUrl: {
        red:process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E5%A4%B4%E5%9B%BE%E8%83%8C%E6%99%AF.png',
        orange:process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E6%A9%99%E8%89%B2%E5%A4%B4%E5%9B%BE%E8%83%8C%E6%99%AF.png',
      },
      customImageUrl: '',
      homePageFields,
      colorMap : {
        red: '#FA6D5E',
        orange: '#FF905E'
      },
      imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
      taskOptions: [],
      checkedTasks: []
    }
  },
  methods: {
    handleRemove(index) {
      this.taskData.tasks.splice(index, 1);
    },
    handleEdit(row) {
      this.$emit('task-change', 'edit', row, this.page.template)
    },
    handleMove(index, type) {
      const row = this.taskData.tasks[index];
      if (type === "up") {
        this.taskData.tasks.splice(index, 1)
        this.taskData.tasks.splice(index - 1, 0, row)
      } else if (type === "down") {
        this.taskData.tasks.splice(index, 1)
        this.taskData.tasks.splice(index + 1, 0, row)
      }
      this.$emit('update:task', this.taskData);
    },
    handleAdd() {
      this.$emit('task-change', 'add', this.page.template)
    },
    formValid() {
      return new Promise((resolve) => {
        this.$refs.groupForm.validate(validate => {
          resolve(validate);
        })
      })
    },
    handleGetTasks(nV) {
      this.taskOptions = [];
      this.checkedTasks = [];
      if (nV && nV.mtTaskId) {
        listGroupTask('?orgCode=' + nV.orgCode +'&isLeaf=1&parentId=' + nV.mtTaskId).then((res) => {
          res.data.forEach(e => e.configJson=defaultTaskJson);
          this.taskOptions = res.data.filter(e => e.orgCode!=='ALL' && (e.parentId ==='root' || e.parentId===nV.mtTaskId));
        })
      }
    },
    getDefaultData(form) {
      if (form) {
        if (form.configJson) {
          return form.configJson;
        } else {
          if (form.boostTask) {
            return jlHorizontalTaskJson;
          }
          return horizontalTaskJson;
        }
      } else {
        return horizontalTaskJson;
      }
    },
    syncConfig(data) {
      this.taskData.tasks.forEach(e => {
        let configJson = deepClone(this.getDefaultData(e));
        if (configJson && typeof configJson === 'string') {
          configJson = JSON.parse(configJson);
        }
        configJson.theme.background.color = data.backGroundColor;
        configJson.theme.badge.background.color = data.badgeBackColor;
        configJson.theme.taskName.color = data.taskNameColor;
        configJson.theme.badge.font.color = data.badgeColor;
        configJson.theme.taskTime.color = data.taskTimeColor;
        configJson.theme.value.color = data.fontColor;
        configJson.theme.moreDataText.color = data.moreDataText;
        e.configJson = configJson;
      })
    }
  }
}
</script>

<style scoped>

</style>
