var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "groupForm",
      staticClass: "common-form",
      attrs: {
        size: "mini",
        "label-width": "120px",
        inline: true,
        model: _vm.form,
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "合集名称", prop: "name" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入合集名称" },
            model: {
              value: _vm.dataForm.name,
              callback: function ($$v) {
                _vm.$set(_vm.dataForm, "name", $$v)
              },
              expression: "dataForm.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "选择模板", prop: "template" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.dataForm.template,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "template", $$v)
                },
                expression: "dataForm.template",
              },
            },
            [
              _c("el-radio", { attrs: { label: "red", value: "red" } }, [
                _vm._v("模板1"),
                _c("img", {
                  staticStyle: { height: "80px", display: "block" },
                  attrs: { src: _vm.viewShowTemplateUrl["red"] },
                }),
              ]),
              _c("el-radio", { attrs: { label: "orange", value: "orange" } }, [
                _vm._v("模板2"),
                _c("img", {
                  staticStyle: { height: "80px", display: "block" },
                  attrs: { src: _vm.viewShowTemplateUrl["orange"] },
                }),
              ]),
              _c(
                "el-radio",
                { attrs: { label: "block", value: "block" } },
                [
                  _vm._v("自定义 "),
                  _c("OssUpload", {
                    ref: "ossUpload",
                    staticStyle: { display: "table" },
                    attrs: {
                      "value-to": _vm.dataForm.custom,
                      maxFileSize: 1024,
                      "list-type": "picture-card",
                      limit: 1,
                      "always-show-button": false,
                      tips: "尺寸495*620，小于</br>1M底部200高度无</br>防止重要内容，避</br>免遮挡数据。",
                      tipMessageShow: false,
                      "accept-type": _vm.imgTypes,
                    },
                    on: {
                      "update:valueTo": function ($event) {
                        return _vm.$set(_vm.dataForm, "custom", $event)
                      },
                      "update:value-to": function ($event) {
                        return _vm.$set(_vm.dataForm, "custom", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "数据颜色", prop: "color" } }, [
        _c("div", { staticStyle: { display: "inline-flex" } }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("span", { staticStyle: { display: "inline-flex" } }, [
                _vm._v("数据文字颜色"),
              ]),
              _c("el-color-picker", {
                model: {
                  value: _vm.dataForm.fontColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "fontColor", $$v)
                  },
                  expression: "dataForm.fontColor",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("span", { staticStyle: { display: "inline-flex" } }, [
                _vm._v("任务合集角标底色"),
              ]),
              _c("el-color-picker", {
                model: {
                  value: _vm.dataForm.badgeColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "badgeColor", $$v)
                  },
                  expression: "dataForm.badgeColor",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("span", { staticStyle: { display: "inline-flex" } }, [
                _vm._v("任务合集角标文字"),
              ]),
              _c("el-color-picker", {
                model: {
                  value: _vm.dataForm.badgeFontColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "badgeFontColor", $$v)
                  },
                  expression: "dataForm.badgeFontColor",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }