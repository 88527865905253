<template>
  <div>
    <div class="item taskGroupItem" :style="{ ...styles, backgroundImage: `url(${task.configJson?.theme?.background?.image})`}">
    <span class="badge">任务合集</span>
      <div class="taskName text-2">{{ task.taskName }}</div>
      <div class="taskData">
        <div
          v-for="(item,index) in  (type === 'shop' ?task.configJson.shop :task.configJson.summary)"
          :key="index"
          class="taskNum"
        >
          <p class="label" :style="'width: max-content;color:' + hexToRgba(item.value.color, 0.7)">{{ item.title }}
          </p>
          <p class="value">
            <span :style="'font-size: 13; color:' + item.value.color"><span>{{ item.prefix }}</span> 0 {{ item.unit }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  parseTime, hexToRgba } from "@/utils";

export default {
  name: "TaskGroupItem",
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: "shop"
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      parseShowTime: parseTime,
      hexToRgba
    }
  }
}

</script>

<style lang="scss" scoped src='@/style/appTask/groupItem.scss'></style>
