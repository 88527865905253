<template>
  <div style="padding: 15px">
    <div class="filter-container">
      <el-select clearable v-model="queryForm.dataPlatform" size="mini" class="filter-item filter-set-width" placeholder="请选择平台">
        <el-option v-for="item in platformOptions" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <el-input v-model="queryForm.shopName" size="mini" class="filter-item filter-set-width" placeholder="请输入店铺名称"></el-input>
      <el-date-picker
        v-model="queryForm.createTime"
        class="filter-item filter-daterange-width"
        size="mini"
        type="datetimerange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        @change="handleDateChange"
      ></el-date-picker>
      <el-select clearable v-model="queryForm.spiderState" class="filter-item filter-set-width" size="mini" placeholder="请选择状态">
        <el-option v-for="item in statusOptions" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
    </div>
    <el-table ref="tt" :data="pageData.rows" max-height="500px" v-loading="loading" size="mini" border highlight-current-row>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="right" inline class="demo-table-expand">
            <el-form-item label="详情">
              <span v-html="props.row.taskDetails"></span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="开始时间" prop="createTime" />
      <el-table-column label="平台" prop="dataPlatform" />
      <el-table-column label="店铺名称" prop="shopName" />
      <el-table-column label="执行人" prop="executor" />
      <el-table-column label="任务状态" prop="spiderState">
        <template slot-scope="{ row }">
          <el-tag v-if="row.spiderState" :type="row.spiderState | stateFilter">
            {{ row.spiderState }}
            <span>{{ formatSeconds(row.createTime, row.endTime) }}</span>
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :currentPage="queryForm.current" :pageSize="queryForm.size" @pageChange="handleFilter" />
  </div>
</template>

<script>
import { urlParams } from "@/mixins";
import { listSpiderLog, getPlatformData } from "@/api/spider";
import Pagination from "../../../components/Element/Pagination.vue";
export default {
  components: { Pagination },
  // 采集数据状态查看
  name: "Spider",
  mixins: [urlParams],
  filters: {
    stateFilter(state) {
      const stateMap = {
        采集成功: "success",
        采集失败: "danger",
        采集中: "primary",
      };
      return stateMap[state];
    },
  },
  data() {
    return {
      loading: false,
      queryForm: {
        current: 1,
        size: 10,
        dataPlatform: undefined,
        spiderState: undefined,
        shopName: undefined,
        createTimeStart: undefined,
        createTimeEnd: undefined,
        createTime: undefined,
        dept: this.$route.query.dept? this.$route.query.dept: 'tech'
      },
      pageData: {
        total: 0,
        rows: [],
      },
      platformOptions: [],
      statusOptions: ["采集中", "采集失败", "采集成功"],
    };
  },
  created() {
    getPlatformData(this.dept).then(res => {
      this.platformOptions = res.data;
    })
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      this.queryForm.current = 1;
      this.handleFilter();
    },
    handleFilter(data) {
      this.loading = true;
      let param = "";
      if (data) {
        this.queryForm.current = data.current_page;
        this.queryForm.size = data.per_page;
      }
      for (var key in this.queryForm) {
        if (this.queryForm[key] && !(this.queryForm[key] instanceof Array)) {
          param += key + "=" + this.queryForm[key] + "&";
        }
      }
      param = "?" + param.substring(0, param.length - 1);
      listSpiderLog(param).then(res => {
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleDateChange(val) {
      if (val && val.length > 0) {
        this.queryForm.createTimeStart = val[0] + "";
        this.queryForm.createTimeEnd = val[1] + "";
      } else {
        this.queryForm.createTimeStart = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    formatSeconds(start, end) {
      if (!end) {
        end = new Date() + "";
      }
      let result = (new Date(end).getTime() - new Date(start).getTime()) / 1000;
      let h = Math.floor(result / 3600);
      let m = Math.floor((result / 60) % 60);
      let s = Math.floor(result % 60);

      let res = "";
      if (h > 0) res += `${h}时`;
      if (m > 0) res += `${m}分`;
      res += `${s}秒`;

      return res;
    },
  },
};
</script>

<style lang="scss" scoped src="@/style/spider/index.scss"></style>
