var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "font-family": "Microsoft YaHei, Arial, sans-serif",
        "background-color": "#FFFFFF",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "14px",
            "padding-right": "1%",
            "padding-top": "1%",
          },
        },
        [
          _c(
            "label",
            {
              staticStyle: {
                "padding-right": "1%",
                "padding-left": "1%",
                color: "#000000",
                opacity: "0.85",
              },
            },
            [_vm._v("文具分类")]
          ),
          _c("el-cascader", {
            staticStyle: { width: "30%", "padding-right": "2%" },
            attrs: { size: "small", options: _vm.levelOptions, clearable: "" },
            model: {
              value: _vm.selectedOptions,
              callback: function ($$v) {
                _vm.selectedOptions = $$v
              },
              expression: "selectedOptions",
            },
          }),
          _c(
            "el-button",
            {
              staticStyle: {
                "text-align": "center",
                "justify-content": "center",
              },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.getConsumeStatistics },
            },
            [_vm._v("查询 ")]
          ),
        ],
        1
      ),
      _c(
        "el-menu",
        {
          staticClass: "ptClass",
          attrs: {
            "default-active": _vm.activeIndex,
            mode: "horizontal",
            "active-text-color": "#409EFF",
            "background-color": "#FFFFFF",
            "text-color": "#303133",
          },
          on: { select: _vm.handleClickPT },
        },
        [
          _c("el-menu-item", { attrs: { index: "first" } }, [
            _vm._v("全网汇总"),
          ]),
          _c("el-menu-item", { attrs: { index: "second" } }, [
            _vm._v("电商平台"),
          ]),
          _c("el-menu-item", { attrs: { index: "third" } }, [
            _vm._v("抖音话题"),
          ]),
          _c("el-menu-item", { attrs: { index: "fourth" } }, [
            _vm._v("小红书"),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex === "first",
              expression: "activeIndex==='first'",
            },
          ],
          staticStyle: { "padding-left": "1%", "padding-right": "1%" },
        },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "0px",
                "font-size": "15px",
                color: "#000000",
                opacity: "0.85",
              },
            },
            [_vm._v("热点词（每周一更新）")]
          ),
          _c("el-switch", {
            staticStyle: { "margin-top": "1%" },
            attrs: { "inactive-text": "剔除品牌关键词" },
            on: { change: _vm.firstBrandKeywordSwitchChange },
            model: {
              value: _vm.brandKeywordSwitch,
              callback: function ($$v) {
                _vm.brandKeywordSwitch = $$v
              },
              expression: "brandKeywordSwitch",
            },
          }),
          _c("el-switch", {
            staticStyle: { "margin-top": "1%", "margin-left": "1%" },
            attrs: { "inactive-text": "仅展示品牌关键词" },
            on: { change: _vm.simplyfirstBrandKeywordSwitchChange },
            model: {
              value: _vm.simplybrandKeywordSwitch,
              callback: function ($$v) {
                _vm.simplybrandKeywordSwitch = $$v
              },
              expression: "simplybrandKeywordSwitch",
            },
          }),
          _c(
            "div",
            {
              staticClass: "column-container",
              staticStyle: {
                "padding-top": "1%",
                color: "#333333",
                "font-size": "13px",
              },
            },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "ranking-box" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "singleTableRdc",
                        staticClass: "rdcTableClass",
                        staticStyle: { border: "0px !important" },
                        attrs: {
                          data: _vm.columnsList[0],
                          fit: "",
                          "row-class-name": "customCellClass",
                          "highlight-current-row": "",
                          height: "800px",
                        },
                        on: { "current-change": _vm.rdcHandleCurrentChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "keywordRank",
                            label: "排名",
                            align: "center",
                            width: "auto",
                            "min-width": 10,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.sortDa(scope.row.keywordRank),
                                    },
                                    [_vm._v(_vm._s(scope.row.keywordRank))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "关键词",
                            align: "center",
                            width: "auto",
                            "min-width": 25,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.itemKeyword)),
                                      ]),
                                      _c("el-button", {
                                        staticClass: "search-custom-icon",
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleKeywordsRankTrend(
                                              scope.row.itemKeyword,
                                              "全平台"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "较上周排名",
                            align: "center",
                            width: "auto",
                            "min-width": 20,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.lastWeekRank === "NEW"
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icon/marketDataDataBoard.svg",
                                        },
                                      })
                                    : Math.abs(scope.row.lastWeekRank) === 0
                                    ? _c("span", [_vm._v(_vm._s("不变"))])
                                    : _c(
                                        "div",
                                        { staticClass: "icon-wrapper" },
                                        [
                                          _c("i", {
                                            class: _vm.getArrowIconClass(
                                              scope.row.lastWeekRank
                                            ),
                                          }),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.getArrowIconClassNum(
                                                scope.row.lastWeekRank
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Math.abs(
                                                      scope.row.lastWeekRank
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            width: "auto",
                            "min-width": 25,
                            label: "较上周热度变化",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.heatChange === "NEW"
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icon/marketDataDataBoard.svg",
                                        },
                                      })
                                    : Math.abs(scope.row.heatChange) === 0
                                    ? _c("span", [_vm._v("不变")])
                                    : _c(
                                        "div",
                                        { staticClass: "icon-wrapper" },
                                        [
                                          _c("i", {
                                            class: _vm.getArrowIconClass(
                                              scope.row.heatChange
                                            ),
                                          }),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.getArrowIconClassNum(
                                                scope.row.heatChange
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Math.abs(
                                                      scope.row.heatChange
                                                    ) + "%"
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.ifQbtFlag === "N" &&
                        _vm.mgPenCommNumCount === "True"
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "mgKeywordRank",
                                label: "晨光品牌热词排名",
                                align: "center",
                                width: "auto",
                                "min-width": 20,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  {
                    staticClass: "ranking-box",
                    staticStyle: { height: "842px" },
                  },
                  [
                    _c("h3", { staticClass: "mgXszbClass" }, [
                      _vm._v(" 热点词：" + _vm._s(this.Rdcvalue)),
                    ]),
                    _vm.ifQbtFlag === "N" && _vm.mgPenCommNumCount === "True"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-size": "15px",
                                  color: "#000000",
                                  opacity: "0.85",
                                },
                              },
                              [_vm._v(" 晨光品牌热卖商品销售占比趋势")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.rdcQsTr === true,
                                    expression: "rdcQsTr === true",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: { click: _vm.getRdcCommList },
                              },
                              [_vm._v(" 查看最新TOP100商品清单 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("el-divider"),
                    _vm.rdcQsTr === true &&
                    _vm.ifQbtFlag === "N" &&
                    _vm.mgPenCommNumCount === "True"
                      ? _c("div", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.isLoadingRdc,
                              expression: "isLoadingRdc",
                            },
                          ],
                          ref: "chartRdcCommRankCanvas",
                          staticStyle: { width: "100%", height: "200px" },
                        })
                      : _vm._e(),
                    _vm.ifQbtFlag === "N" &&
                    _vm.rdcQsTr === false &&
                    _vm.mgPenCommNumCount === "True"
                      ? _c(
                          "el-empty",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.isLoadingRdc,
                                expression: "isLoadingRdc",
                              },
                            ],
                            attrs: {
                              "image-size": 50,
                              description: "未找到相关晨光品牌商品",
                              image: "/static/icon/未找到相关商品.svg",
                            },
                          },
                          [
                            _vm.ifQbtFlag === "N" && _vm.rdcQsTr === false
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { src: "/static/icon/叉叉.svg" },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        "商品名称中未出现相关热点词或近义词"
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          "font-size": "15px",
                          color: "#000000",
                          opacity: "0.85",
                          "margin-bottom": "0.5%",
                        },
                      },
                      [_vm._v("热点词关联信息")]
                    ),
                    _c("el-divider"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-bottom": "1%",
                          color: "#000000",
                          opacity: "0.85",
                          background: "#FFFFFF",
                          "padding-top": "1%",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: {
                              background: "#FFFFFF",
                              "padding-right": "2%",
                            },
                            attrs: { size: "small", fill: "#409EFF" },
                            on: { change: _vm.spqdNameRdcPtChange },
                            model: {
                              value: _vm.SpqdNameRdcPt,
                              callback: function ($$v) {
                                _vm.SpqdNameRdcPt = $$v
                              },
                              expression: "SpqdNameRdcPt",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwTm === "True",
                                    expression: "qwTm==='True'",
                                  },
                                ],
                                attrs: { label: "4" },
                              },
                              [_vm._v("天猫")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwJd === "True",
                                    expression: "qwJd==='True'",
                                  },
                                ],
                                attrs: { label: "5" },
                              },
                              [_vm._v("京东")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwDySp === "True",
                                    expression: "qwDySp==='True'",
                                  },
                                ],
                                attrs: { label: "1" },
                              },
                              [_vm._v("抖音商品")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwPdd === "True",
                                    expression: "qwPdd==='True'",
                                  },
                                ],
                                attrs: { label: "2" },
                              },
                              [_vm._v("拼多多")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwKs === "True",
                                    expression: "qwKs==='True'",
                                  },
                                ],
                                attrs: { label: "3" },
                              },
                              [_vm._v("快手")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwDyht === "True",
                                    expression: "qwDyht==='True' ",
                                  },
                                ],
                                attrs: { label: "6" },
                              },
                              [_vm._v("抖音话题")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.qwXhs === "True",
                                    expression: "qwXhs==='True' ",
                                  },
                                ],
                                attrs: { label: "7" },
                              },
                              [_vm._v("小红书")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-download",
                              size: "small",
                              loading: _vm.rdcCommDataDowloadling,
                            },
                            on: { click: _vm.getRdcCommDataDowload },
                          },
                          [_vm._v(" 下载 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-bottom": "1%",
                          color: "#000000",
                          opacity: "0.85",
                          background: "#FFFFFF",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: {
                              "font-size": "15px",
                              "padding-right": "1%",
                            },
                            attrs: { size: "mini" },
                            on: { input: _vm.handleAllRadioPtInput },
                            model: {
                              value: _vm.allRadioPt,
                              callback: function ($$v) {
                                _vm.allRadioPt = $$v
                              },
                              expression: "allRadioPt",
                            },
                          },
                          [
                            _vm.SpqdNameRdcPt === "1" ||
                            _vm.SpqdNameRdcPt === "2" ||
                            _vm.SpqdNameRdcPt === "3" ||
                            _vm.SpqdNameRdcPt === "4" ||
                            _vm.SpqdNameRdcPt === "5"
                              ? _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("全部商品 "),
                                ])
                              : _vm._e(),
                            _c("el-radio", { attrs: { label: "2" } }, [
                              _vm._v("仅关键词商品"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "1",
                            expression: "SpqdNameRdcPt=='1'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpDysp,
                              border: "",
                              fit: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemRank",
                                label: "排名",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemName",
                                label: "商品名称",
                                align: "center",
                                width: "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1f85ee" },
                                          attrs: {
                                            href: scope.row.url,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.itemName))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sellQtyStr",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "销售数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commentsQtyStr",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "点评数",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemPrice",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "价格",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPageDysp,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotleDysp,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "2",
                            expression: "SpqdNameRdcPt=='2' ",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpPddsp,
                              border: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemRank",
                                label: "排名",
                                width: "auto",
                                "min-width": 10,
                                align: "center",
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemName",
                                label: "商品名称",
                                align: "center",
                              },
                            }),
                            _vm.SpqdName == "3"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "shopTitle",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "所属店铺",
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sellQtyStr",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "销售数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemPrice",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "价格",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPagePddsp,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotlePddsp,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "3",
                            expression: "SpqdNameRdcPt=='3'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpKssp,
                              border: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemRank",
                                label: "排名",
                                width: "auto",
                                "min-width": 10,
                                align: "center",
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemName",
                                label: "商品名称",
                                align: "center",
                              },
                            }),
                            _vm.SpqdName == "3"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "shopTitle",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "所属店铺",
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sellQtyStr",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "销售数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemPrice",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "价格",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPageKssp,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotleKssp,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "4",
                            expression: "SpqdNameRdcPt=='4'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpTmsp,
                              border: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemRank",
                                label: "排名",
                                width: "auto",
                                "min-width": 10,
                                align: "center",
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemName",
                                label: "商品名称",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1f85ee" },
                                          attrs: {
                                            href: scope.row.itemUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.itemName))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "shopTitle",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "所属店铺",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1f85ee" },
                                          attrs: {
                                            href: scope.row.shopUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.shopTitle))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm.ifTm === "N"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "sellCount",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "周销售数量",
                                  },
                                })
                              : _vm._e(),
                            _vm.ifTm === "N"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "salesTotal",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "周销售金额",
                                  },
                                })
                              : _vm._e(),
                            _vm.ifTm === "N"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "averagePrice",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "成交均价",
                                  },
                                })
                              : _vm._e(),
                            _vm.ifTm === "Y"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "price",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "价格",
                                  },
                                })
                              : _vm._e(),
                            _vm.ifTm === "Y"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "sellCountStr",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                    label: "月销",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPageTmsp,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotleTmsp,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "5",
                            expression: "SpqdNameRdcPt=='5' ",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpJdsp,
                              border: "",
                              fit: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "itemRank",
                                label: "排名",
                                width: "auto",
                                "min-width": 10,
                                align: "center",
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commodityName",
                                label: "商品名称",
                                width: "auto",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1f85ee" },
                                          attrs: {
                                            href: scope.row.url,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scope.row.commodityName)
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "shopName",
                                label: "所属店铺",
                                width: "auto",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1f85ee" },
                                          attrs: {
                                            href: scope.row.shopUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.shopName))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commentCountStr",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "评论数",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "showPrice",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                                label: "价格",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPageJdsp,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotleJdsp,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "6",
                            expression: "SpqdNameRdcPt=='6' ",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpDyht,
                              border: "",
                              fit: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "topicRank",
                                label: "排名",
                                width: "auto",
                                "min-width": 10,
                                align: "center",
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 10,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "toppicName",
                                label: "抖音话题",
                                width: "auto",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "viewCount",
                                label: "最新浏览量",
                                width: "auto",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(scope.row.viewCount) + " "
                                      ),
                                      _c("el-button", {
                                        staticClass: "search-custom-icon",
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSycmTrendRdcDyht(
                                              scope.row.toppicName
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPageDyht,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotleDyht,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.SpqdNameRdcPt == "7",
                            expression: "SpqdNameRdcPt=='7' ",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-left": "0%",
                              "margin-right": "0%",
                              "margin-top": "2px",
                            },
                            attrs: {
                              data: _vm.SpqdTableDataRdcSpXhsTz,
                              border: "",
                              fit: "",
                              height: "350px",
                              "row-class-name": "customCellClass",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "artRank",
                                label: "排名",
                                align: "center",
                                width: "auto",
                                "min-width": 10,
                              },
                            }),
                            _vm.allRadioPt === "2"
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "itemKeyword",
                                    label: "热点词",
                                    align: "center",
                                    width: "auto",
                                    "min-width": 18,
                                  },
                                })
                              : _vm._e(),
                            _c("el-table-column", {
                              attrs: {
                                prop: "artType",
                                label: "类型",
                                align: "center",
                                width: "auto",
                                "min-width": 15,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "dataTime",
                                align: "center",
                                width: "auto",
                                label: "发布时间",
                                "min-width": 30,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                align: "center",
                                width: "auto",
                                label: "标题",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "subHashtags",
                                align: "center",
                                width: "auto",
                                label: "标签",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "subDescription",
                                align: "center",
                                width: "auto",
                                label: "正文",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "likesStr",
                                align: "center",
                                width: "auto",
                                "min-width": 20,
                                label: "点赞数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "collectsStr",
                                align: "center",
                                width: "auto",
                                "min-width": 20,
                                label: "收藏数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sharecountStr",
                                align: "center",
                                width: "auto",
                                "min-width": 20,
                                label: "分享数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "commentsStr",
                                align: "center",
                                width: "auto",
                                "min-width": 20,
                                label: "评论数量",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                width: "150px",
                                "min-width": 10,
                                label: "操作",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            color: "#1F85EE",
                                            "padding-right": "15px",
                                          },
                                          attrs: {
                                            href: scope.row.url,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s("查看"))]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "#1F85EE" },
                                          attrs: { size: "mini", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleXhsTrend(
                                                scope.row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("历史趋势 ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: {
                            "padding-top": "2%",
                            "padding-bottom": "6%",
                          },
                          attrs: {
                            background: "",
                            "current-page": _vm.scRdcDataPageXhstz,
                            "page-size": _vm.scRdcDataPageSize,
                            total: _vm.scRdcDataPageTotleXhstz,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.getRdcCommDataPageChange,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogTableVisibleRdc },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisibleRdc = $event
            },
          },
        },
        [
          _c("div", { staticClass: "rdcDilClass" }, [
            _c("label", { staticStyle: { "padding-left": "1%" } }, [
              _vm._v("商品数 " + _vm._s(_vm.rdcDialogSpqd.skuCount)),
            ]),
            _c("label", { staticStyle: { "padding-left": "5%" } }, [
              _vm._v(
                " 当月销售金额占比晨光品牌 " +
                  _vm._s(_vm.rdcDialogSpqd.dySalesProportionMg) +
                  " "
              ),
              _c("br"),
            ]),
            _c("label", { staticStyle: { "padding-left": "5%" } }, [
              _vm._v(
                " 当月销售金额占比晨光TOP100商品 " +
                  _vm._s(_vm.rdcDialogSpqd.dySalesProportionTop) +
                  " "
              ),
              _c("br"),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                padding: "2%",
                display: "flex",
                "align-items": "flex-end",
                "justify-content": "flex-end",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-download",
                    size: "small",
                    loading: _vm.rdcMgCommDataDowloadling,
                  },
                  on: { click: _vm.getRdcMgCommDataDowload },
                },
                [_vm._v(" 下载 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.rdcCommDataList, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "itemRank",
                  label: "排名",
                  "min-width": "5px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "itemKeyword",
                  label: "热点词",
                  "min-width": "10px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "skuName",
                  label: "商品名",
                  "min-width": "30px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#1f85ee" },
                            attrs: { href: scope.row.skuUrl, target: "_blank" },
                          },
                          [_vm._v(_vm._s(scope.row.skuName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "averagePrice",
                  label: "成交均价",
                  "min-width": "8px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "dySalesProportionMg",
                  label: "当月销售金额占比晨光品牌",
                  "min-width": "10px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "dySalesProportionTop",
                  label: "当月销售金额占比TOP100商品",
                  "min-width": "15px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "shopTitle",
                  label: "掌柜",
                  "min-width": "15px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#1f85ee" },
                            attrs: {
                              href: scope.row.shopUrl,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.shopTitle))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "padding-top": "2%", "padding-bottom": "6%" },
            attrs: {
              background: "",
              "current-page": _vm.scRdcDataPageMgComm,
              "page-size": _vm.scRdcDataPageSize,
              total: _vm.scRdcDataPageTotleMgComm,
              layout: "total, prev, pager, next",
            },
            on: { "current-change": _vm.getRdcCommListPageChange },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogTableVisibleDspt },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisibleDspt = $event
            },
          },
        },
        [
          _c("div", { staticClass: "rdcDilClass" }, [
            _c("label", { staticStyle: { "padding-left": "1%" } }, [
              _vm._v("商品数 " + _vm._s(_vm.rdcDialogSpqd.skuCount) + " "),
            ]),
            _c("label", { staticStyle: { "padding-left": "5%" } }, [
              _vm._v(
                "当月销售金额占比晨光品牌 " +
                  _vm._s(_vm.rdcDialogSpqd.dySalesProportionMg) +
                  " "
              ),
              _c("br"),
            ]),
            _c("label", { staticStyle: { "padding-left": "5%" } }, [
              _vm._v(
                " 当月销售金额占比晨光TOP100商品 " +
                  _vm._s(_vm.rdcDialogSpqd.dySalesProportionTop) +
                  " "
              ),
              _c("br"),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                padding: "2%",
                display: "flex",
                "align-items": "flex-end",
                "justify-content": "flex-end",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-download",
                    size: "small",
                    loading: _vm.rdcMgCommDataDowloadling,
                  },
                  on: { click: _vm.getDsptMgCommDataDowload },
                },
                [_vm._v(" 下载 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.rdcCommDataList, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "itemRank",
                  label: "排名",
                  "min-width": "5px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "itemKeyword",
                  label: "热点词",
                  "min-width": "10px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "skuName",
                  label: "商品名",
                  "min-width": "15px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#1f85ee" },
                            attrs: { href: scope.row.skuUrl, target: "_blank" },
                          },
                          [_vm._v(_vm._s(scope.row.skuName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "averagePrice",
                  label: "成交均价",
                  "min-width": "8px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "dySalesProportionMg",
                  label: "当月销售金额占比晨光品牌",
                  "min-width": "20px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "dySalesProportionTop",
                  label: "当月销售金额占比TOP100商品",
                  "min-width": "25px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "shopTitle",
                  label: "掌柜",
                  "min-width": "15px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#1f85ee" },
                            attrs: {
                              href: scope.row.shopUrl,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.shopTitle))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "padding-top": "2%", "padding-bottom": "6%" },
            attrs: {
              background: "",
              "current-page": _vm.scRdcDataPageMgComm,
              "page-size": _vm.scRdcDataPageSize,
              total: _vm.scRdcDataPageTotleMgComm,
              layout: "total, prev, pager, next",
            },
            on: { "current-change": _vm.getDsptCommListPageChange },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex === "second",
              expression: "activeIndex === 'second'",
            },
          ],
          staticStyle: {
            "padding-right": "1%",
            "padding-left": "1%",
            color: "#333333",
            "font-size": "13px",
          },
        },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "0px",
                "font-size": "15px",
                color: "#000000",
                opacity: "0.85",
              },
            },
            [_vm._v(" 商品关键词（每周一更新）")]
          ),
          _c("el-switch", {
            staticStyle: { "margin-top": "1%" },
            attrs: { "inactive-text": "剔除品牌关键词" },
            on: { change: _vm.secondBrandKeywordSwitchChange },
            model: {
              value: _vm.brandItemKeywordSwitch,
              callback: function ($$v) {
                _vm.brandItemKeywordSwitch = $$v
              },
              expression: "brandItemKeywordSwitch",
            },
          }),
          _c("el-switch", {
            staticStyle: { "margin-top": "1%", "margin-left": "1%" },
            attrs: { "inactive-text": "仅展示品牌关键词" },
            on: { change: _vm.simplySecondBrandKeywordSwitchChange },
            model: {
              value: _vm.simplybrandItemKeywordSwitch,
              callback: function ($$v) {
                _vm.simplybrandItemKeywordSwitch = $$v
              },
              expression: "simplybrandItemKeywordSwitch",
            },
          }),
          _c(
            "div",
            {
              staticClass: "column-container",
              staticStyle: {
                "padding-top": "1%",
                color: "#333333",
                "font-size": "13px",
              },
            },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "ranking-box" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "singleTableDspt",
                        staticClass: "rdcTableClass",
                        staticStyle: { border: "0px !important" },
                        attrs: {
                          data: _vm.columnsListPD[0],
                          fit: "",
                          height: "800px",
                          "row-class-name": "customCellClass",
                          "highlight-current-row": "",
                        },
                        on: { "current-change": _vm.dsptHandleCurrentChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "keywordRank",
                            label: "排名",
                            align: "center",
                            width: "auto",
                            "min-width": 10,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.sortDa(scope.row.keywordRank),
                                    },
                                    [_vm._v(_vm._s(scope.row.keywordRank))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "关键词",
                            align: "center",
                            width: "auto",
                            "min-width": 20,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.itemKeyword)),
                                      ]),
                                      _c("el-button", {
                                        staticClass: "search-custom-icon",
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleKeywordsRankTrend(
                                              scope.row.itemKeyword,
                                              "电商平台"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "较上周排名",
                            align: "center",
                            width: "auto",
                            "min-width": 20,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.lastWeekRank === "NEW"
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icon/marketDataDataBoard.svg",
                                        },
                                      })
                                    : Math.abs(scope.row.lastWeekRank) === 0
                                    ? _c("span", [_vm._v(_vm._s("不变"))])
                                    : _c(
                                        "div",
                                        { staticClass: "icon-wrapper" },
                                        [
                                          _c("i", {
                                            class: _vm.getArrowIconClass(
                                              scope.row.lastWeekRank
                                            ),
                                          }),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.getArrowIconClassNum(
                                                scope.row.lastWeekRank
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Math.abs(
                                                      scope.row.lastWeekRank
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            width: "auto",
                            "min-width": 20,
                            label: "较上周热度变化",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.heatChange === "NEW"
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icon/marketDataDataBoard.svg",
                                        },
                                      })
                                    : Math.abs(scope.row.heatChange) === 0
                                    ? _c("span", [_vm._v("不变")])
                                    : _c(
                                        "div",
                                        { staticClass: "icon-wrapper" },
                                        [
                                          _c("i", {
                                            class: _vm.getArrowIconClass(
                                              scope.row.heatChange
                                            ),
                                          }),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.getArrowIconClassNum(
                                                scope.row.heatChange
                                              ),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    Math.abs(
                                                      scope.row.heatChange
                                                    ) + "%"
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.ifQbtFlag === "N" &&
                        _vm.mgPenCommNumCount === "True"
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "mgKeywordRank",
                                label: "晨光品牌热词排名",
                                align: "center",
                                width: "auto",
                                "min-width": 20,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "ranking-box" },
                  [
                    _c("h3", { staticClass: "mgXszbClass" }, [
                      _vm._v(" 热点词：" + _vm._s(this.Ptvalue)),
                    ]),
                    _vm.ifQbtFlag === "N" && _vm.mgPenCommNumCount === "True"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-size": "15px",
                                  color: "#000000",
                                  opacity: "0.85",
                                },
                              },
                              [_vm._v(" 晨光品牌热卖商品销售占比趋势")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dspyQsTr === true,
                                    expression: "dspyQsTr === true",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: { click: _vm.getDsptMgCommList },
                              },
                              [_vm._v(" 查看最新TOP100商品清单 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("el-divider", { staticStyle: { "padding-top": "-2%" } }),
                    _vm.dspyQsTr === true &&
                    _vm.ifQbtFlag === "N" &&
                    _vm.mgPenCommNumCount === "True"
                      ? _c("div", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.isLoadingDspt,
                              expression: "isLoadingDspt",
                            },
                          ],
                          ref: "chartDsptCommRankCanvas",
                          staticStyle: { width: "100%", height: "200px" },
                        })
                      : _vm._e(),
                    _vm.ifQbtFlag === "N" &&
                    _vm.dspyQsTr === false &&
                    _vm.mgPenCommNumCount === "True"
                      ? _c(
                          "el-empty",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.isLoadingDspt,
                                expression: "isLoadingDspt",
                              },
                            ],
                            attrs: {
                              "image-size": 50,
                              description: "未找到相关晨光品牌商品",
                              image: "/static/icon/未找到相关商品.svg",
                            },
                          },
                          [
                            _vm.ifQbtFlag === "N" && _vm.dspyQsTr === false
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { src: "/static/icon/叉叉.svg" },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        "商品名称中未出现相关热点词或近义词"
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              "font-size": "15px",
                              "padding-right": "1%",
                              color: "#000000",
                              opacity: "0.85",
                              "margin-bottom": "0.5%",
                            },
                          },
                          [_vm._v(" 商品清单")]
                        ),
                        _c("el-divider"),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "1%" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticStyle: {
                              background: "#FFFFFF",
                              "padding-right": "2%",
                            },
                            attrs: { size: "small", fill: "#409EFF" },
                            on: { input: _vm.handleClickSpqd },
                            model: {
                              value: _vm.SpqdName,
                              callback: function ($$v) {
                                _vm.SpqdName = $$v
                              },
                              expression: "SpqdName",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dsTm === "True",
                                    expression: "dsTm==='True'",
                                  },
                                ],
                                attrs: { label: "4" },
                              },
                              [_vm._v("天猫")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dsJd === "True",
                                    expression: "dsJd==='True'",
                                  },
                                ],
                                attrs: { label: "5" },
                              },
                              [_vm._v("京东")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dsDysp === "True",
                                    expression: "dsDysp==='True'",
                                  },
                                ],
                                attrs: { label: "1" },
                              },
                              [_vm._v("抖音")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dsPdd === "True",
                                    expression: "dsPdd==='True'",
                                  },
                                ],
                                attrs: { label: "2" },
                              },
                              [_vm._v("拼多多")]
                            ),
                            _c(
                              "el-radio-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dsKs === "True",
                                    expression: "dsKs==='True'",
                                  },
                                ],
                                attrs: { label: "3" },
                              },
                              [_vm._v("快手")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-download",
                              size: "small",
                              loading: _vm.rdcCommDataDowloadling,
                            },
                            on: { click: _vm.getDsptCommDataDowload },
                          },
                          [_vm._v(" 下载 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          "padding-top": "1%",
                          color: "#333333",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "1%",
                              color: "#000000",
                              opacity: "0.85",
                              background: "#FFFFFF",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticStyle: {
                                  "font-size": "15px",
                                  "padding-right": "1%",
                                },
                                attrs: { size: "mini" },
                                on: { input: _vm.handleRadioPtInput },
                                model: {
                                  value: _vm.radioPt,
                                  callback: function ($$v) {
                                    _vm.radioPt = $$v
                                  },
                                  expression: "radioPt",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("全部商品"),
                                ]),
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v("仅关键词商品"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.SpqdName == "1"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.SpqdTableDataDy,
                                      border: "",
                                      fit: "",
                                      height: "460px",
                                      "row-class-name": "customCellClass",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemRank",
                                        label: "排名",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                      },
                                    }),
                                    _vm.radioPt === "2"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "itemKeyword",
                                            label: "热点词",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 18,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemName",
                                        label: "商品名称",
                                        align: "center",
                                        width: "auto",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      color: "#1f85ee",
                                                    },
                                                    attrs: {
                                                      href: scope.row.url,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.itemName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3224514708
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "sellQtyStr",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "销售数量",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "commentsQtyStr",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "点评数",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemPrice",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "价格",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.SpqdName == "2" || _vm.SpqdName == "3"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.SpqdTableDataDy,
                                      border: "",
                                      height: "460px",
                                      "row-class-name": "customCellClass",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemRank",
                                        label: "排名",
                                        width: "auto",
                                        "min-width": 10,
                                        align: "center",
                                      },
                                    }),
                                    _vm.radioPt === "2"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "itemKeyword",
                                            label: "热点词",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 18,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemName",
                                        label: "商品名称",
                                        align: "center",
                                      },
                                    }),
                                    _vm.SpqdName == "3"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "shopTitle",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 10,
                                            label: "所属店铺",
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "sellQtyStr",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "销售数量",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemPrice",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "价格",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.SpqdName == "4"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.SpqdTableDataDy,
                                      border: "",
                                      height: "460px",
                                      "row-class-name": "customCellClass",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemRank",
                                        label: "排名",
                                        width: "auto",
                                        "min-width": 10,
                                        align: "center",
                                      },
                                    }),
                                    _vm.radioPt === "2"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "itemKeyword",
                                            label: "热点词",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 18,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemName",
                                        label: "商品名称",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      color: "#1f85ee",
                                                    },
                                                    attrs: {
                                                      href: scope.row.itemUrl,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.itemName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2816339137
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "shopTitle",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "所属店铺",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      color: "#1f85ee",
                                                    },
                                                    attrs: {
                                                      href: scope.row.shopUrl,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.shopTitle
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3633443526
                                      ),
                                    }),
                                    _vm.ifTm === "N"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "sellCount",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 10,
                                            label: "周销售数量",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.ifTm === "N"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "salesTotal",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 10,
                                            label: "周销售金额",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.ifTm === "N"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "averagePrice",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 10,
                                            label: "成交均价",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.ifTm === "Y"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "price",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 10,
                                            label: "价格",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.ifTm === "Y"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "sellCountStr",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 10,
                                            label: "月销",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.SpqdName == "5"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.SpqdTableDataDy,
                                      border: "",
                                      fit: "",
                                      height: "460px",
                                      "row-class-name": "customCellClass",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "itemRank",
                                        label: "排名",
                                        width: "auto",
                                        "min-width": 10,
                                        align: "center",
                                      },
                                    }),
                                    _vm.radioPt === "2"
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "itemKeyword",
                                            label: "热点词",
                                            align: "center",
                                            width: "auto",
                                            "min-width": 18,
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "commodityName",
                                        label: "商品名称",
                                        width: "auto",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      color: "#1f85ee",
                                                    },
                                                    attrs: {
                                                      href: scope.row.url,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.commodityName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4001511202
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "shopName",
                                        label: "所属店铺",
                                        width: "auto",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "a",
                                                  {
                                                    staticStyle: {
                                                      color: "#1f85ee",
                                                    },
                                                    attrs: {
                                                      href: scope.row.shopUrl,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(scope.row.shopName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        316206625
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "commentCountStr",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "评论数",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "showPrice",
                                        align: "center",
                                        width: "auto",
                                        "min-width": 10,
                                        label: "价格",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-pagination", {
                          staticStyle: { "padding-top": "2%" },
                          attrs: {
                            background: "",
                            "current-page": _vm.smsFailpageQW,
                            "page-size": _vm.smsFailpageSizeQW,
                            total: _vm.smsFailtotalQW,
                            layout: "total, prev, pager, next",
                          },
                          on: {
                            "current-change": _vm.smsFailHandlePageChangeQW,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex === "third",
              expression: "activeIndex === 'third'",
            },
          ],
          staticStyle: { "padding-right": "1%", "padding-left": "1%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "column-container",
              staticStyle: {
                "font-size": "13px",
                "padding-top": "1%",
                color: "#333333",
              },
            },
            [
              _c("div", { staticClass: "columnDyht" }, [
                _c(
                  "div",
                  { staticClass: "ranking-box-dy" },
                  [
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-top": "1px",
                          color: "#000000",
                          "font-size": "14px",
                          opacity: "0.85",
                        },
                      },
                      [_vm._v("抖音热门话题")]
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "padding-bottom": "3%",
                          height: "80px",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "dy-center", attrs: { span: 6 } },
                          [_c("span", [_vm._v("排名")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-center", attrs: { span: 5 } },
                          [_c("span", [_vm._v("抖音话题")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-right", attrs: { span: 4 } },
                          [_c("span", [_vm._v("最新浏览量")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-center", attrs: { span: 8 } },
                          [_c("span", [_vm._v("手机端链接")])]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.tableDataDYHT, function (item) {
                      return _c(
                        "el-row",
                        {
                          key: item.topicRank,
                          staticStyle: { height: "30px" },
                        },
                        [
                          _c(
                            "el-col",
                            { staticClass: "dy-center", attrs: { span: 6 } },
                            [
                              _c("div", { class: _vm.sortDa(item.topicRank) }, [
                                _vm._v(" " + _vm._s(item.topicRank) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-center", attrs: { span: 5 } },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.toppicName) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-right", attrs: { span: 4 } },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.viewCount) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-center", attrs: { span: 8 } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-10px",
                                    "margin-bottom": "-10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "text-sele-col",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModal(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "columnDyht" }, [
                _c(
                  "div",
                  { staticClass: "ranking-box-dy" },
                  [
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-top": "1px",
                          color: "#000000",
                          "font-size": "14px",
                          opacity: "0.85",
                        },
                      },
                      [_vm._v("抖音增长话题")]
                    ),
                    _c(
                      "el-row",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "padding-bottom": "3%",
                          height: "80px",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { staticClass: "dy-center", attrs: { span: 6 } },
                          [_c("span", [_vm._v("排名")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-center", attrs: { span: 5 } },
                          [_c("span", [_vm._v("抖音话题")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-right", attrs: { span: 4 } },
                          [_c("span", [_vm._v("近一周浏览量占比增幅")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-right", attrs: { span: 4 } },
                          [_c("span", [_vm._v("最新浏览量")])]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "dy-center", attrs: { span: 6 } },
                          [_c("span", [_vm._v("手机端链接")])]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.tableDataDYZzht, function (item) {
                      return _c(
                        "el-row",
                        {
                          key: item.topicRank,
                          staticStyle: { height: "30px" },
                        },
                        [
                          _c(
                            "el-col",
                            { staticClass: "dy-center", attrs: { span: 6 } },
                            [
                              _c("div", { class: _vm.sortDa(item.topicRank) }, [
                                _vm._v(" " + _vm._s(item.topicRank) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-center", attrs: { span: 5 } },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.toppicName) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-right", attrs: { span: 4 } },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.lastWeekProportionIncrease) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-right", attrs: { span: 4 } },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.viewCount
                                        .toString()
                                        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { staticClass: "dy-center", attrs: { span: 6 } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-10px",
                                    "margin-bottom": "-10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "text-sele-col",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModal(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.modalVisible,
                    title: "抖音话题分享链接",
                    width: "400px",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.modalVisible = $event
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v("链接为: "),
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#1F85EE" },
                        attrs: {
                          href: _vm.selectedItem.shareUrl,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.selectedItem.shareUrl))]
                    ),
                  ]),
                  _c("p", [_vm._v("请复制到手机上，在手机上点击链接查看")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "margin-top": "20px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.copyLink },
                        },
                        [_vm._v("复制")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.modalVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", [
            _c(
              "h3",
              {
                staticStyle: {
                  display: "flex",
                  "margin-top": "1px",
                  "font-size": "17.55px",
                  "padding-top": "1%",
                },
              },
              [_vm._v("话题趋势")]
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _c("span", { staticStyle: { "font-size": "13px" } }, [
                      _vm._v("话题:"),
                    ]),
                    _c(
                      "el-select",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "justify-content": "center",
                          "padding-left": "1%",
                        },
                        attrs: {
                          size: "mini",
                          filterable: "",
                          placeholder: "选择",
                        },
                        on: { change: _vm.selectDyTopicChanged },
                        model: {
                          value: _vm.dyTopic,
                          callback: function ($$v) {
                            _vm.dyTopic = $$v
                          },
                          expression: "dyTopic",
                        },
                      },
                      _vm._l(_vm.dyTopicOptions, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-right": "1%",
                      "padding-left": "2%",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        on: { change: _vm.dyDateRangeTypeChange },
                        model: {
                          value: _vm.dyDateRangeType,
                          callback: function ($$v) {
                            _vm.dyDateRangeType = $$v
                          },
                          expression: "dyDateRangeType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "3" } }, [
                          _vm._v("近12周"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", {
              ref: "chartDyTopicRankCanvas",
              staticStyle: { width: "100%", height: "400px" },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeIndex == "fourth",
              expression: "activeIndex=='fourth' ",
            },
          ],
          staticStyle: { "padding-right": "1%", "padding-left": "1%" },
        },
        [
          _c(
            "h3",
            {
              staticStyle: {
                "margin-bottom": "0px",
                "font-size": "15px",
                color: "#000000",
                opacity: "0.85",
                "padding-bottom": "1%",
              },
            },
            [_vm._v("热门讨论帖")]
          ),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "1%" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { input: _vm.changeradioXhsRm },
                  model: {
                    value: _vm.radioXhsRm,
                    callback: function ($$v) {
                      _vm.radioXhsRm = $$v
                    },
                    expression: "radioXhsRm",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "人气值TOP20" } }),
                  _c("el-radio-button", { attrs: { label: "最近发布TOP20" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.radioXhsRm === "最近发布TOP20",
                  expression: "radioXhsRm==='最近发布TOP20'",
                },
              ],
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-left": "0%",
                    "margin-right": "0%",
                    "margin-top": "2px",
                  },
                  attrs: {
                    data: _vm.tableDataXhsRecently,
                    border: "",
                    fit: "",
                    "row-class-name": "customCellClass",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "artRank",
                      label: "排名",
                      align: "center",
                      width: "auto",
                      "min-width": 10,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "artType",
                      label: "类型",
                      align: "center",
                      width: "auto",
                      "min-width": 10,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dataTime",
                      align: "center",
                      width: "auto",
                      label: "发布时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      align: "center",
                      width: "auto",
                      label: "标题",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subHashtags",
                      align: "center",
                      width: "auto",
                      label: "标签",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subDescription",
                      align: "center",
                      width: "auto",
                      label: "正文",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "likesStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "点赞数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "collectsStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "收藏数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sharecountStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "分享数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "commentsStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "评论数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      width: "150px",
                      "min-width": 10,
                      label: "操作",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  color: "#1F85EE",
                                  "padding-right": "15px",
                                },
                                attrs: {
                                  href: scope.row.url,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s("查看"))]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#1F85EE" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleXhsTrend(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("历史趋势 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                staticStyle: { "padding-top": "2%", "padding-bottom": "2%" },
                attrs: {
                  background: "",
                  "current-page": _vm.xhsTopicRankPageQWRecently,
                  "page-size": _vm.xhsTopicRankPageSizeQWRecently,
                  total: _vm.xhsTopicRankTotalQWRecently,
                  layout: "total, prev, pager, next",
                },
                on: {
                  "current-change": _vm.xhsTopicRankHandlePageChangeQWRecently,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.radioXhsRm === "人气值TOP20",
                  expression: "radioXhsRm==='人气值TOP20'",
                },
              ],
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-left": "0%",
                    "margin-right": "0%",
                    "margin-top": "2px",
                  },
                  attrs: {
                    data: _vm.tableDataXhs,
                    border: "",
                    "row-class-name": "customCellClass",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "artRank",
                      label: "排名",
                      align: "center",
                      width: "auto",
                      "min-width": 10,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "artType",
                      label: "类型",
                      align: "center",
                      width: "auto",
                      "min-width": 10,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dataTime",
                      align: "center",
                      width: "auto",
                      label: "发布时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      align: "center",
                      width: "auto",
                      label: "标题",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subHashtags",
                      align: "center",
                      width: "auto",
                      label: "标签",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subDescription",
                      align: "center",
                      width: "auto",
                      label: "正文",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "likesStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "点赞数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "collectsStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "收藏数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sharecountStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "分享数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "commentsStr",
                      align: "center",
                      width: "auto",
                      "min-width": 15,
                      label: "评论数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      width: "150px",
                      "min-width": 10,
                      label: "操作",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  color: "#1F85EE",
                                  "padding-right": "15px",
                                },
                                attrs: {
                                  href: scope.row.url,
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s("查看"))]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#1F85EE" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleXhsTrend(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v(" 历史趋势 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                staticStyle: { "padding-top": "2%", "padding-bottom": "2%" },
                attrs: {
                  background: "",
                  "current-page": _vm.xhsTopicRankPageQW,
                  "page-size": _vm.xhsTopicRankPageSizeQW,
                  total: _vm.xhsTopicRankTotalQW,
                  layout: "total, prev, pager, next",
                },
                on: { "current-change": _vm.xhsTopicRankHandlePageChangeQW },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "ranking-box" }, [
            _c(
              "h3",
              {
                staticStyle: {
                  "margin-bottom": "0px",
                  "font-size": "15px",
                  color: "#000000",
                  opacity: "0.85",
                  "padding-bottom": "1%",
                },
              },
              [_vm._v("热点词云")]
            ),
            _c("div", { staticClass: "wordcloud-container" }, [
              _c("img", {
                staticClass: "wordcloud-image",
                attrs: { src: _vm.cloudImageURL, alt: "Word Cloud" },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "custom-dialog",
          attrs: {
            title: "关键词: " + _vm.keywordsRankTrendDialog.itemKeyword,
            visible: _vm.keywordsRankTrendDialog.visible,
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.keywordsRankTrendDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
            [_vm._v("排名周趋势")]
          ),
          _c("div", {
            ref: "chartkeywordsRankTrendCanvas",
            staticStyle: { width: "100%", height: "30vh" },
          }),
          _c("hr", { attrs: { width: "100%", color: "#AAAAAA", SIZE: "1" } }),
          _c(
            "span",
            { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
            [_vm._v("热度周趋势")]
          ),
          _c(
            "div",
            { staticStyle: { "padding-top": "1%" } },
            [
              _vm.hotKeywordsPlatform === "全平台"
                ? [
                    _c(
                      "el-checkbox-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handlePlatformsChange(
                              _vm.keywordsRankTrendDialog.itemKeyword
                            )
                          },
                        },
                        model: {
                          value: _vm.checkedPlatforms,
                          callback: function ($$v) {
                            _vm.checkedPlatforms = $$v
                          },
                          expression: "checkedPlatforms",
                        },
                      },
                      [
                        _c("el-checkbox", { attrs: { label: "全平台" } }),
                        _c("el-checkbox", { attrs: { label: "电商平台" } }),
                        _c("el-checkbox", { attrs: { label: "抖音话题" } }),
                        _c("el-checkbox", { attrs: { label: "小红书" } }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("div", {
            ref: "chartkeywordsHeatRankTrendCanvas",
            staticStyle: { width: "100%", height: "30vh" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "custom-dialog",
          attrs: { visible: _vm.sycmTrendDialog.visible, top: "10vh" },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.sycmTrendDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", [
            _c(
              "span",
              { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
              [_vm._v("商品名称：" + _vm._s(_vm.sycmTrendDialog.itemName))]
            ),
          ]),
          _c("div", { staticStyle: { "margin-top": "2%" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
              [_vm._v("价格趋势")]
            ),
          ]),
          _c("div", {
            ref: "chartSycmPriceTrendCanvas",
            staticStyle: { width: "100%", height: "30vh" },
          }),
          _c("hr", { attrs: { width: "100%", color: "#AAAAAA", SIZE: "1" } }),
          _c("div", { staticStyle: { "margin-top": "2%" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
              [_vm._v("月销趋势")]
            ),
          ]),
          _c("div", {
            ref: "chartSycmSellCountTrendCanvas",
            staticStyle: { width: "100%", height: "30vh" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "custom-dialog",
          attrs: { visible: _vm.sycmTrendDialogRdcDyht.visible, top: "10vh" },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.sycmTrendDialogRdcDyht, "visible", $event)
            },
          },
        },
        [
          _c("div", [
            _c(
              "span",
              { staticStyle: { "font-size": "14px", "font-weight": "bold" } },
              [_vm._v("话题趋势")]
            ),
          ]),
          _c("div", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "13px",
                  "font-weight": "bold",
                  "padding-top": "1%",
                },
              },
              [_vm._v("话题：" + _vm._s(_vm.sycmTrendDialogRdcDyht.topicName))]
            ),
          ]),
          _c("div", {
            ref: "chartSycmPriceTrendCanvasRdcDyht",
            staticStyle: { width: "100%", height: "30vh" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "custom-dialog",
          attrs: { visible: _vm.xhsTrendDialog.visible, top: "10vh" },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.xhsTrendDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "overflow-y": "scroll", height: "500px" } },
            [
              _c("div", { staticStyle: { "margin-top": "2%" } }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "font-weight": "bold" },
                  },
                  [_vm._v("点赞趋势")]
                ),
              ]),
              _c("div", {
                ref: "chartXhsLikesTrendCanvas",
                staticStyle: { width: "100%", height: "30vh" },
              }),
              _c("hr", {
                attrs: { width: "100%", color: "#AAAAAA", SIZE: "1" },
              }),
              _c("div", { staticStyle: { "margin-top": "2%" } }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "font-weight": "bold" },
                  },
                  [_vm._v("评论趋势")]
                ),
              ]),
              _c("div", {
                ref: "chartXhsCollectTrendCanvas",
                staticStyle: { width: "100%", height: "30vh" },
              }),
              _c("hr", {
                attrs: { width: "100%", color: "#AAAAAA", SIZE: "1" },
              }),
              _c("div", { staticStyle: { "margin-top": "2%" } }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "font-weight": "bold" },
                  },
                  [_vm._v("分享趋势")]
                ),
              ]),
              _c("div", {
                ref: "chartXhsShareTrendCanvas",
                staticStyle: { width: "100%", height: "30vh" },
              }),
              _c("hr", {
                attrs: { width: "100%", color: "#AAAAAA", SIZE: "1" },
              }),
              _c("div", { staticStyle: { "margin-top": "2%" } }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "14px", "font-weight": "bold" },
                  },
                  [_vm._v("评论趋势")]
                ),
              ]),
              _c("div", {
                ref: "chartXhsCommentTrendCanvas",
                staticStyle: { width: "100%", height: "30vh" },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }