import { render, staticRenderFns } from "./mg-table-column.vue?vue&type=template&id=d55a7cda"
import script from "./mg-table-column.vue?vue&type=script&lang=js"
export * from "./mg-table-column.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/jenkins/workspace/pdb-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d55a7cda')) {
      api.createRecord('d55a7cda', component.options)
    } else {
      api.reload('d55a7cda', component.options)
    }
    module.hot.accept("./mg-table-column.vue?vue&type=template&id=d55a7cda", function () {
      api.rerender('d55a7cda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mg-components/mg-table/mg-table-column.vue"
export default component.exports