<template>
  <div :class="['mg-pagination-table', isFullScreen ? 'mg-table--full-screen' : '']">
    <div class="mg-table-topbar">
      <div class="topbar-left">
        <slot name="topleft" />
      </div>
      <div class="topbar-right">
        <slot name="topright" />
        <!-- 搜索按钮 -->
        <template v-if="searchable">
          <el-button
            v-if="!showSearchBar"
            icon="iconfont mg-icon_sousuo_wubiankuang"
            @click="handleShowSearch"
            type="icon"
          />
          <el-button v-else plain icon="iconfont mg-icon_sousuo_wubiankuang" @click="handleShowSearch" type="icon" />
        </template>
        <!-- 列宽配置按钮 -->
        <el-button
          icon="iconfont mg-icon_shezhi_wubiankuang"
          type="icon"
          @click="visible = true"
          v-if="configurable"
        />
        <!-- 是否全屏按钮 -->
        <el-button
          class="mg-icon-full-screen"
          icon="el-icon-full-screen"
          type="icon"
          @click="handleFullScreen"
          v-if="isConfigFullScreen"
        />
        <!-- 小分页器 -->
        <mg-mini-pagination
          v-if="showMiniPagination"
          :page="page"
          :total-page="totalPage"
          :total="total"
          @page-change="handlePageChange"
        />
      </div>
    </div>
    <el-table
      ref="mgTable"
      v-loading="loading"
      size="small"
      empty-text="暂无数据"
      :span-method="cellMerge"
      border
      :class="[
        'mg-customer-table-container',
        { 'mg-customer-table--header': showSearchBar },
        isHeaderMiddle ? 'mg-customer-table-headerAlign' : '',
      ]"
      :data="initialData || list"
      :max-height="maxHeight"
      :height="isFullScreen ? 'calc(100vh - 90px)' : $attrs.height"
      @header-dragend="handleHeaderDragEnd"
      @row-click="handleRowClick"
      :row-class-name="getRowClassName"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot name="selection" />
      <div slot="empty" v-if="$slots.empty">
        <slot name="empty" />
      </div>
      <template v-if="isUseDefaultColumns">
        <template v-for="column in columns">
          <!-- 单元格合并 树形结构数据 -->
          <mg-table-column
            v-if="column.children && column.children.length > 0"
            :key="column.label"
            :tableColumns="column"
            :filter-method="filterHandler"
          />
          <!-- 自定义的表格行 不能使用 v-bind="column"  -->
          <el-table-column
            v-else-if="column.slot"
            :key="column.prop"
            :label="column.label"
            :width="column.width"
            :min-width="column.minWidth"
            :align="column.align || 'center'"
            :prop="column.prop"
            :sortable="column.sortable"
            :sort-by="column.sortBy"
            :fixed="column.fixed"
            show-overflow-tooltip
            :filter-method="filterHandler"
          >
            <template #default="scoped">
              <slot :name="column.slot" v-bind="scoped" />
            </template>
            <!-- 自定义表头 -->
            <template v-if="column.headerSlot" #header="scoped">
              <div
                :class="['mg-customer-table-header-cell-label', column.showHeaderAsterisk ? 'mg-header-asterisk' : '']"
              >
                <span style="color: #e60012; margin-top: 4px" v-if="column.showHeaderAsterisk">*</span>
                <span>{{ column.label }}</span>
              </div>
              <div v-if="showSearchBar" class="mg-customer-table-header-cell-body">
                <slot :name="column.headerSlot" v-bind="scoped" />
              </div>
            </template>
          </el-table-column>
          <!-- 使用默认表格列渲染 -->
          <template v-else-if="column.default">
            <slot :name="column.prop" />
          </template>
          <!-- 常规数值显示的表格行 -->
          <el-table-column
            v-else
            show-overflow-tooltip
            v-bind="column"
            :key="column.prop"
            :filters="[]"
            :index="getIndex"
            :filter-method="filterHandler"
            align="center"
          >
            <template v-if="column.headerSlot" #header="scoped">
              <div class="mg-customer-table-header-cell-label">{{ column.label }}</div>
              <div v-if="showSearchBar" class="mg-customer-table-header-cell-body">
                <slot :name="column.headerSlot" v-bind="scoped" />
              </div>
            </template>
          </el-table-column>
        </template>
      </template>
      <template v-esle>
        <slot />
      </template>
    </el-table>
    <el-pagination
      class="mg-large-pagination"
      v-if="showPagination"
      background
      :current-page="page"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pagination.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <ColumnConfig
      :visible.sync="visible"
      :columns="columns"
      @column-change="handleColumnChange"
      :appendToBody="appendToBody"
    />
  </div>
</template>

<script>
  import ConfigColumn from './mixins/column-config'
  import KeyupEnter from '@/mg-components/mg-table/mixins/keyup-enter'
  export default {
    mixins: [ConfigColumn, KeyupEnter],
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      isUseDefaultColumns: {
        type: Boolean,
        default: true,
      },
      appendToBody: {
        type: Boolean,
        default: true,
      },
      // 合并单元格
      cellMerge: {
        type: Function,
        default: null,
      },
      // 是否启用搜索
      searchable: {
        type: Boolean,
        default: false,
      },
      // 是否需要显示分页
      showPagination: {
        type: Boolean,
        default: true,
      },
      // 是否需要mini显示分页
      showMiniPagination: {
        type: Boolean,
        default: true,
      },
      initialData: Array,
      api: Function,
      apiParams: Object,
      autoLoad: Boolean,
      // 处理列表请求后的数据
      formatData: Function,
      maxHeight: {
        type: [String, Number],
        default: '500px',
      },
      isHeaderMiddle: {
        type: Boolean,
        default: false,
      },
      // 需要查询参数对象
      filtersForm: {
        type: Object,
        default: () => {},
      },
      // 是否允许点击行
      isClickRow: {
        type: Boolean,
        default: true,
      },
      // 额外参数
      extraParams: {
        type: Object,
        default: null
      },
      // 是否全屏显示
      isConfigFullScreen: {
        type: Boolean,
        default: true
      }
    },
    components: {
      mgMiniPagination: () => import('./components/mg-mini-pagination.vue'),
      mgTableColumn: () => import('./mg-table-column'),
    },
    data() {
      return {
        pagination: {
          page: this.apiParams?.page || 1,
          limit: this.apiParams?.limit || 20,
        },
        list: this.$attrs.data,
        loading: false,
        total: 0,
        showSearchBar: false,
        selectedRows: [],
        listsResult: null,
        isFullScreen: false
      }
    },
    watch: {
      'apiParams.page': function (page) {
        this.pagination.page = page
      },
      initialData: {
        immediate: true,
        handler(value, old) {
          // 只需要调用一次 doLayout
          if (old?.length === 0 && value?.length > 0) {
            this.$nextTick(() => {
              this.$refs.mgTable.doLayout()
            })
          }
        },
      },
      filtersForm: {
        handler(newVal) {
          Object.keys(newVal).forEach((key) => {
            const column = this.columns
              .map((item, index) => {
                if (item.prop && item.prop === key) {
                  return index
                } else {
                  return
                }
              })
              .filter((item) => !isNaN(item))
            this.setDefaultFilter(column[0], newVal[key])
          })
        },
        deep: true,
      },
    },
    computed: {
      page() {
        return this.pagination.page
      },
      totalPage() {
        return Math.ceil(this.total / this.pagination.limit)
      },
      getRawInstance() {
        return this.$refs.mgTable
      },
    },
    mounted() {
      Promise.resolve().then(() => {
        if (this.api && this.autoLoad) {
          this.loadData()
        }
      })
    },
    methods: {
      handleRowClick(row) {
        if (this.isClickRow) {
          const index = this.selectedRows.findIndex((item) => item === row)
          if (index == -1) {
            this.selectedRows.push(row)
            return
          }
          this.selectedRows.splice(index, 1)
        }
      },
      getRowClassName({ row }) {
        return this.selectedRows.find((item) => item === row) ? 'current-row' : ''
      },
      getIndex(index) {
        return index + 1 + (this.page - 1) * this.pagination.limit
      },
      // 重置
      resetClearFilter() {
        this.$refs.mgTable.clearFilter()
        this.$emit('resetHeaderForm')
      },
      // 表头过滤
      filterHandler(value, row, column) {
        const keys = Object.keys(this.filtersForm)
        if (row[column.property] && keys.includes(column.property)) {
          return (
            row[column.property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(this.filtersForm[column.property].toString().toLocaleLowerCase()) >= 0
          )
        } else {
          return true
        }
      },
      // 设置表头过滤参数
      setDefaultFilter(col, val) {
        const column = this.$refs.mgTable.columns[col]
        column.filteredValue = [val]
        this.$refs.mgTable.store.commit('filterChange', {
          column,
          values: column.filteredValue,
          silent: true,
        })
      },
      handleShowSearch() {
        this.showSearchBar = !this.showSearchBar
        if (!this.showSearchBar) {
          this.resetClearFilter()
        }
        this.$nextTick(() => {
          this.$refs.mgTable.doLayout()
        })
        this.$emit('searchable-change', this.showSearchBar)
      },
      handleCurrentChange(page) {
        this.pagination.page = page
        this.loadData()
      },
      handleSizeChange(limit) {
        this.pagination.page = 1
        this.pagination.limit = limit
        this.loadData()
      },
      handlePageChange(page) {
        this.handleCurrentChange(page)
      },
      loadData() {
        this.$emit('loading', true)
        this.loading = true
        return new Promise((resolve, reject) => {
          this.api({
            ...this.apiParams,
            ...this.pagination,
          })
            .then(async (res) => {
              if (typeof this.formatData === 'function') {
                await this.formatData(res.data?.items)
              }
              const dataKeys = res?.data && Object.keys(res.data) || []
              dataKeys.forEach(item => {
                if (this.$lodash.isArray(res.data[item])) {
                  if (this.extraParams) {
                    this.list = res.data?.datas && res.data?.datas.map(item => {
                      return {
                        ...item,
                        ...this.extraParams
                      }
                    }) || res.data[item]?.map(item => {
                      return {
                        ...item,
                        ...this.extraParams
                      }
                    }) || []
                  }
                  if ( !this.extraParams ) this.list = res.data?.datas || res.data[item] || []
                  this.$emit('update:initialData', [...(this.list || [])])
                  this.total = res.data?.total || 0
                  if (this.pagination.page > 1 && this.list.length === 0) {
                    this.pagination.page = 1
                    this.loadData()
                  }
                  this.$emit('getTableData', res.data)
                  this.selectedRows = []
                  this.listsResult = res
                  resolve(res)
                }
              })
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              this.loading = false
              this.$emit('loading', false)
              if (this.$attrs['show-summary']) {
                this.$nextTick(() => {
                  this.$refs.mgTable.doLayout()
                })
              }
            })
        })
      },
      setTableTotal(num) {
        this.total = num
      },
      clear() {
        this.list = []
        this.total = 0
      },
      // 全屏展示
      handleFullScreen() {
        this.isFullScreen = !this.isFullScreen
      }
    },
  }
</script>

<style lang="scss" scoped>
  .mg-pagination-table {
    .mg-table-topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .topbar-left,
      .topbar-right {
        position: relative;
        display: flex;
        align-items: center;
        .mg-icon-full-screen {
          ::v-deep {
            .el-icon-full-screen:before {
                display: block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 16px;
            }
          }
        }
      }
    }

    .mg-table--header {
      .mg-customer-table-header-cell-label {
        border-bottom: 1px solid #ebeef5;
      }
    }

    .mg-customer-table-header-cell-label {
      height: 28px;
      line-height: 28px;
      box-sizing: border-box;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Hiragino Sans GB,
        Microsoft Yahei, STHeiti, SimSun, sans-serifsans-serif;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .mg-customer-table-header-cell-body {
      padding: 0 5px;
    }
    .mg-customer-table-container {
      ::v-deep .el-table__header {
        // 奇数行 -- 奇数列
        .oddHeaderCellColspan:nth-child(odd), .inheritOddHeaderCellColspan {
          background: #d2efc8;
        }
        // 奇数行 -- 偶数列
        .oddHeaderCellColspan:nth-child(even), .inheritEvenHeaderCellColspan {
          background: #ffe3a6;
        }
      }
      // 偶数行 -- 奇数列
      ::v-deep .eventHeaderCellColspan:nth-child(odd) {
        background: #bcd4ff;
      }
      // 偶数行 -- 偶数列
      ::v-deep .eventHeaderCellColspan:nth-child(even) {
        background: #ffc3c3;
      }
    }
    .mg-customer-table-headerAlign {
      ::v-deep .el-table__cell {
        vertical-align: middle !important;
      }
    }
    .mg-large-pagination {
      margin-top: 10px;
    }
  }
  .mg-table--full-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow: auto;
    margin: 0;
    height: calc(100vh - 10px);
    background: #fff;
  }
  ::v-deep {
    .el-table__column-filter-trigger {
      display: none !important;
    }
    .el-table th.el-table__cell > .cell.highlight {
      color: #000000;
    }
    * {
      transition: none;
    }
  }
</style>
