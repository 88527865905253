<template>
  <div>
    <!-- 导入弹窗 -->
    <el-dialog
        class="dialog-box"
        width="1000px"
        :close-on-click-modal="false"
        :visible.sync="importBaseParams.visible"
        :destroy-on-close="true"
        :title="importBaseParams.title"
        @close="handleDialogClose"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div v-if="importBaseParams.visible">
            <mg-upload-excel
                ref="uploadExcelRef"
                :uploadDatas="uploadFileObj.tableData"
                :on-success="handleFileSuccess"
                :before-upload="beforeFileUpload"
                :templateFileDownloadUrl="importBaseParams.templateFileDownloadUrl"
                :tipsText="importBaseParams.tipsText"
                :optionalTipsText="importBaseParams.optionalTipsText"
                @handlePreview="handlePreview"
                @handleConfirmUpload="handleConfirmUpload"
            />
            <template v-if="uploadFileObj.preTableData.length > 0">
                <div class="previewTable">
                <mg-signboard :title="'文件预览'"/>
                <el-table
                    class="previewTable-lists"
                    :data="uploadFileObj.preTableData"
                    stripe
                    border
                    size="mini"
                    height="calc(100vh - 500px)"
                    max-height="auto"
                >
                    <el-table-column
                        v-for="item of uploadFileObj.tableHeader"
                        :key="item"
                        :prop="item"
                        :label="item"
                        align="center"
                    />
                </el-table>
                </div>
            </template>
            <template v-else>
                <div class="previewTable previewTable-sample">
                <mg-signboard title="文件上传模板示例"/>
                <el-table
                    class="previewTable-lists"
                    :data="importBaseParams.templateSampleLists"
                    stripe
                    border
                    size="mini"
                >
                    <template v-for="(item, index) in importBaseParams.templateSampleHead">
                    <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                    </template>
                </el-table>
                </div>
            </template>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props: {
        // 导入弹窗基础参数
        importBaseParams: {
            type: Object
        },
        // 接收url传参
        urlParams: {
            type: Object
        }
    },
    components: {
        mgUploadExcel: () => import('@/components/UploadExcel/index'),
        mgSignboard: () => import('@/components/Signboard'),
    },
    data() {
        return {
            uploadFileObj: {
                tableData: [],
                preTableData: [],
                tableHeader: [],
            },
        }
    },
    methods: {
        // 上传excel文件
        handleFileSuccess({results, header}) {
            const uploadFileObj = this.uploadFileObj;
            uploadFileObj.tableData = results;
            uploadFileObj.tableHeader = header
        },
        // 上传excel之前文件
        beforeFileUpload(file) {
            const uploadFileObj = this.uploadFileObj;
            uploadFileObj.tableData = [];
            uploadFileObj.preTableData = [];
            uploadFileObj.tableHeader = [];
            if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

            const isLt1M = file.size / 1024 / 1024 < 1;
            if (isLt1M) return true;
            this.$message({
                message: '请不要上传超过1M的文件',
                type: 'warning'
            })
            return false
        },
        // 预览上传文件
        handlePreview({data, isPreview}) {
            this.uploadFileObj.preTableData = data;
        },
        // 确认上传
        async handleConfirmUpload() {
            const uploadFileObj = this.uploadFileObj;
            const importBaseParams = this.importBaseParams;
            const headList = importBaseParams.templateSampleHead;
            for( let i = 0; i < headList.length; i++ ) {
                let item = headList[i];
                if (uploadFileObj.tableHeader.indexOf(item.label) === -1) return this.$message.warning('缺少' + item.label + '字段');
            }
            let newItems = [];
            uploadFileObj.tableData.map(item => {
                const tableDataKeys = Object.keys(item);
                let newItem = {};
                tableDataKeys.map((itemKey, i) => {
                    const itemList = headList[i];
                    const newItem1 = {
                        [itemList.prop]: item[itemList.label]
                    };
                    newItem = Object.assign(newItem, newItem1)
                })
                newItems.push(newItem)
            })
            const paramObj = {
                [importBaseParams.apiParams]: newItems
            };
            const { code, success, msg } = await importBaseParams.api(paramObj);
            uploadFileObj.tableData = [];
            uploadFileObj.preTableData = [];
            uploadFileObj.tableHeader = [];
            if (success) {
                this.$emit('handleImportSuccess', {
                    type: importBaseParams.type,
                    visible: false
                })
                this.handleDialogClose();
                return this.$message.success(msg);
            }
            if (!success) this.activeName = 'onlineWrite';
        },
        // dialog关闭回调
        handleDialogClose() {
            const uploadFileObj = this.uploadFileObj;
            uploadFileObj.tableData = [];
            uploadFileObj.preTableData = [];
            uploadFileObj.tableHeader = [];
        },
    }
}
</script>

<style>

</style>
