<template>
  <div v-loading.fullscreen.lock="homeLoading" style="padding: 20px">
    <div v-if="componentType === 'JL'">
      <el-tabs v-model="tabName">
        <el-tab-pane label="业务激励列表" name="salesman" />
        <el-tab-pane label="终端激励列表" name="shop" />
      </el-tabs>
      <div class="mg-search" style="padding-bottom: 15px">
        <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
          <el-form-item>
            <el-button class="form-btn-item" size="mini" type="primary" @click="handleEdit()">{{ tabName==='salesman'?'创建业务奖励':'创建终端奖励' }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="pageData.rows"
        height="calc(100vh - 250px)"
        v-loading="loading"
        size="mini"
        border
        ref="table"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="ID" prop="id" width="50" />
        <el-table-column label="奖励标题" prop="name" width="300" />
        <el-table-column label="创建者" prop="createUser" width="80" />
        <el-table-column label="类型" prop="businessType" width="120">
          <template #default="{row}">
            <span>{{ row.boostTarget==='shop'? '店铺激励':'业务激励' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="编辑时间" prop="createTime" width="160" />
        <el-table-column label="操作" width="100" align="center">
          <template #default="{ row }">
            <div class="button-class">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
              <el-button size="mini" type="text" style="border: 0px;" @click="handleDelBoost(row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
    </div>

    <el-drawer :title="jlDrawer.title[jlDrawer.type]" :visible.sync="jlDrawer.visible" append-to-body size="70%" direction="rtl" :close-on-click-modal="false" :wrapperClosable="false">
      <el-form ref="jlFormRef" size="mini" label-width="120px" :inline="true" :model="jlDrawer.form" :rules="jlDrawer.rules" class="common-form">
        <el-row :gutter="12" class="common-form-row">
          <el-col :span="12">
            <el-form-item label="奖励名称" prop="name">
              <el-input v-model="jlDrawer.form.name" maxlength="6" show-word-limit placeholder="6个字以内，例如“必备佳装“" />
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="奖励描述" prop="description">
              <el-input v-model="jlDrawer.form.description" placeholder="请输入奖励描述" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="店铺范围" style="padding-right: 22px;" class="radio-class">
              <el-radio-group v-model="jlDrawer.form.allShops" @input="val => {
                  jlDrawer.form.shopCapacities = [];
                  jlDrawer.form.shopLevels = [];
                }">
                <el-radio label="2">全部任务店铺</el-radio>
                <el-radio label="3">指定类型店铺</el-radio>
                <div v-if="jlDrawer.form.allShops==='3'" style="display: inline-block;">
                  <el-select v-model="jlDrawer.form.shopCapacities" style="width: 120px" size="mini" multiple clearable filterable placeholder="店铺类型（多选）">
                    <el-option v-for="item in options.capacityOptions" :key="item" :value="item" :label="item" />
                  </el-select>
                  <el-select v-model="jlDrawer.form.shopLevels" style="width: 120px" size="mini" multiple clearable filterable placeholder="店铺等级（多选）">
                    <el-option v-for="item in options.shopLevelOptions" :key="item" :value="item" :label="item" />
                  </el-select>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="任务时间" style="padding-right: 22px;" class="radio-class">
              <el-radio-group v-model="jlDrawer.form.taskTime" @input="val => {
                }">
                <el-radio label="1">与主任务一致</el-radio>
                <el-radio label="2">自定义</el-radio>
                <div v-if="jlDrawer.form.taskTime==='2'" style="display: inline-block;">
                  <el-date-picker
                    v-model="jlDrawer.form.taskDate"
                    type="daterange"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    @change="val => handleDateChange(val)"
                    format="yyyy-MM-dd"
                    :default-time="['00:00:00', '23:59:59']"
                  ></el-date-picker>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否核销审核" style="padding-right: 22px;" class="radio-class">
              <el-radio-group v-model="jlDrawer.form.ifNeedAudit">
                <el-radio label="N">否</el-radio>
                <el-radio label="Y">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否核销拍照" style="padding-right: 22px;" class="radio-class">
              <el-radio-group v-model="jlDrawer.form.ifNeedPicture">
                <el-radio label="N">否</el-radio>
                <el-radio label="Y">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="奖励类型">
              <el-select v-model="jlDrawer.form.boostType" style="width: 120px" size="mini" placeholder="请选择（单选）" @change="val => {
                if (val !== 'none') {
                  jlDrawer.form.rules = [this.getDefaultRules(val)]
                } else {
                  jlDrawer.form.rules = [];
                }
                form.roofOfShop = undefined
              }">
                <el-option v-for="item in computeBoostTypes('target')" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="jlDrawer.form.boostType!=='none' && jlDrawer.form.rules && jlDrawer.form.rules.length>0">
            <div class="mid-title">奖励条件设置</div>
            <el-row v-for="(item,index) in jlDrawer.form.rules" :key="index" style="margin-top: 20px">
              <el-col :span="23">
                <el-row v-if="jlDrawer.form.boostType==='grad'" style="margin-left: 5%"><span class="min-desc">{{'奖励档位' + (index+1) }}</span></el-row>
                <el-row style="border: 1px solid;margin-left: 5%;margin-bottom: -1px">
                  <el-col :span="2" class="div-column1"><div style="font-weight: 600;font-size: 15px">条件</div></el-col>
                  <el-col :span="22" class="div-column2">
                    <el-row class="row-column">
                      <el-col :span="2">
                        <div v-if="jlDrawer.form.boostType!=='per'">
                          <el-select v-model="item.conditionMode" size="mini">
                            <el-option label="且" value="and" />
                            <el-option label="或" value="or" />
                          </el-select>
                        </div>
                      </el-col>
                      <el-col :span="20">
                        <el-row style="margin-left: 15%">
                          <el-col v-for="(ele,cIndx) in item.conditions" :key="cIndx">
                            <span>指标 </span>
                            <el-select v-model="ele.measure" size="mini" style="width: 80px">
                              <el-option v-for="e in measureOptions[index][cIndx]" :key="e.value" :value="e.value" :label="e.label" />
                            </el-select>
                            <div v-if="ele.measure!='boost_reach'" style="display: inline-block">
                              <div style="display: inline-block" v-if="jlDrawer.form.boostType==='full'">
                                <span style="margin-left: 5px">{{ ele.measure?measureOptions[index][cIndx].find(m => m.value ===ele.measure).label + '≥': '≥' }}</span>
                                <el-input-number v-model="ele.numA" style="width: 60px" size="small" :min="0" :precision="0" :controls="false" />
                                <span style="margin-left: 5px">{{ ele.measure? (options.defaultQuotaOptions.find(o => o.value == ele.measure).unit):'' }}</span>
                              </div>
                              <div style="display: inline-block" v-if="jlDrawer.form.boostType==='per'">
                                <span style="margin-left: 5px">{{ (ele.measure?measureOptions[index][cIndx].find(m => m.value ===ele.measure).label: '') + '每达成' }}</span>
                                <el-input-number v-model="ele.numA" style="width: 60px" size="small" :min="0" :precision="0" :controls="false" />
                                <span style="margin-left: 5px">{{ ele.measure? (options.defaultQuotaOptions.find(o => o.value == ele.measure).unit):'' }}</span>
                              </div>
                              <div style="display: inline-block" v-if="jlDrawer.form.boostType==='grad' && ele.measure!='boost_reach'">
                                奖励类型
                                <el-select v-model="ele.operation" size="mini" style="width: 80px">
                                  <el-option v-for="e in computeJlType(index, cIndx)" :key="e.value" :value="e.value" :label="e.label" />
                                </el-select>
                                <div v-if="ele.operation && ele.operation.indexOf('a,b') > -1" style="display: inline-block; margin-left: 5px">
                                  <el-input-number v-model="ele.numA" style="width: 60px" size="small" :min="0" :precision="0" :controls="false" />
                                  <span> {{ ele.operation && ele.operation.indexOf('[a,b') > -1? '≤': '<' }} </span>
                                  <span style="margin-left: 5px">{{ (ele.measure?measureOptions[index][cIndx].find(m => m.value ===ele.measure).label: '')}}</span>
                                  <span> {{ ele.operation && ele.operation.indexOf('a,b]') > -1? '≤': '<' }} </span>
                                  <el-input-number v-model="ele.numB" style="width: 60px" size="small" :min="0" :precision="0" :controls="false" />
                                </div>
                                <div v-else style="display: inline-block; margin-left: 5px">
                                  <span style="margin-left: 5px">{{ (ele.measure?measureOptions[index][cIndx].find(m => m.value ===ele.measure).label: '')}}</span>
                                  <span> {{ '≥' }} </span>
                                  <el-input-number v-model="ele.numA" style="width: 60px" size="small" :min="0" :precision="0" :controls="false" />
                                </div>
                              </div>
                            </div>
                            <div v-else style="display: inline-block">
                              <el-select v-model="ele.configJson.relatedBoost" multiple filterable remote :loading="remoteLoading" size="mini" style="width:320px"
                                         :remote-method="(val) => handleTaskBoostOptions(index, cIndx, val, '')">
                                <el-option
                                  v-for="b in getTaskBoostOptions(index, cIndx)"
                                  :key="b.id"
                                  :label="b.name"
                                  :value="b.id">
                                </el-option>
                              </el-select>
                            </div>
                            <el-select v-model="ele.configJson.allSkus" size="mini" style="width: 110px; margin-left: 20px" placeholder="商品范围" @change="ele.skus = []">
                              <el-option v-for="e in options.skuOptions" :key="e.value" :value="e.value" :label="e.label" />
                            </el-select>
                            <el-button v-if="ele.configJson && ele.configJson.allSkus === '3'" size="mini" type="primary" @click="handleChooseSkus(index, cIndx)">选择商品范围</el-button>
                            <el-button v-if="item.conditions.length > 1" size="mini" type="text" icon="el-icon-delete" @click="handleRemoveCondition(index, cIndx)"/>
                          </el-col>
                          <el-col>
                            <el-button v-if="jlDrawer.form.boostType!=='per'" size="mini" type="text" icon="el-icon-circle-plus-outline" @click="handleAddCondition(index)">添加条件</el-button>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="1">
                        <el-button v-if="jlDrawer.form.rules.length > 1 && index > 0" size="mini" type="text" icon="el-icon-delete" @click="handleRemoveRule(index)" />
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="23">
                <el-row style="border: 1px solid;margin-left: 5%">
                  <el-col :span="2" class="div-column1"><div style="font-weight: 600;font-size: 15px">奖励</div></el-col>
                  <el-col v-if="jlDrawer.form.bonusTarget==='salesman'" :span="20" class="div-column3">
                    <el-row style="text-align: center;">
                      <div v-if="jlDrawer.form.boostType==='full'" style="display: inline-block;">
                        <el-select v-model="item.boostWay" size="mini" style="width: 100px">
                          <el-option label="正常奖励" value="1" /><el-option label="百分比奖励" value="2" /><el-option label="百分比仅奖励超出部分" value="3" /><el-option label="满(每奖励)" value="4" />
                        </el-select>
                        <span v-if="item.boostWay!=='1'" style="margin-left: 5px;">关联奖励指标</span>
                        <el-select v-if="item.boostWay && item.boostWay!=='1'" v-model="item.virRelConditionId" size="mini" style="width: 80px">
                          <el-option v-for="c in getConditions(item)" :value="c.value" :label="c.label" :key="c.value" />
                        </el-select>
                      </div>
                      <div v-if="item.boostWay==='1' && (!item.modules || item.modules.length===0)" style="display: inline-block;">
                        <div v-if="jlDrawer.form.boostType==='full'"><span>单店奖励</span> <el-input-number v-model="item.bonus" style="width: 60px" size="small" :min="0" :precision="1" :controls="false" />元</div>
                        <div v-if="jlDrawer.form.boostType==='grad'"><span>奖励</span>  奖励<el-input-number v-model="item.bonus" style="width: 60px" size="small" :min="0" :precision="1" :controls="false" />元</div>
                        <div v-if="jlDrawer.form.boostType==='per'"><span>奖励</span> <el-input-number v-model="item.bonus" style="width: 60px" size="small" :min="0" :precision="1" :controls="false" />元
                          <span> 奖励封顶</span><el-input-number style="width: 60px" size="small" :min="0" :precision="1" :controls="false" :max="999999" v-model="jlDrawer.form.roofOfShop" />元</div>
                      </div>
                      <div v-if="['2', '3', '4'].indexOf(item.boostWay) > -1" style="display: inline-block;">
                        <div>
                          <span>达成后按照</span> <strong style="font-size: 14px">{{ item.virRelConditionId? getConditions(item).find(e => e.value === item.virRelConditionId)?.label: '' }}</strong>

                          <span v-if="['4'].indexOf(item.boostWay) > -1"> 每达成</span>
                          <el-input-number v-model="item.boostNum" style="width: 70px" size="small" :min="0" :precision="2" :controls="false" />
                          <span v-if="['4'].indexOf(item.boostWay) > -1 && item.virRelConditionId">
                            {{ item.virRelConditionId? getConditions(item).find(e => e.value === item.virRelConditionId)?.unit: '' }}  奖励
                          </span>
                        <el-input-number v-if="['4'].indexOf(item.boostWay) > -1" v-model="item.bonus" style="width: 70px" size="small" :min="0" :precision="2" :controls="false" />
                        <span v-if="['4'].indexOf(item.boostWay) > -1">元</span>

                        <span v-if="['2', '3'].indexOf(item.boostWay) > -1">% 奖励,</span>
                        <span> 封顶</span>
                        <el-input-number style="width: 70px" size="small" :min="0" :precision="1" :controls="false" :max="999999" v-model="jlDrawer.form.roofOfShop" />元
                        </div>
                      </div>
                    </el-row>
                    <div style="width: 70%;text-align: center">
                      <el-row v-for="m in item.modules" :key="m.id">
                        <el-col style="margin-top: 7px">
                          <strong style="font-size: 14px;">{{m.name}}</strong>
                          <div v-if="['2', '3', '4'].indexOf(item.boostWay) < 0" style="display: inline-block; margin-left: 10px">
                            <span style="margin-left: 5px;">&nbsp;奖励&nbsp;</span>
                              <el-input-number v-model="m.moduleMoney" size="small" style="width: 60px" :controls="false" :min="0" />
                              元
                          </div>

                        </el-col>
                      </el-row>
                      <el-row v-show="item.modules && item.modules.length>1">
                        <el-col style="margin-top: 7px">以上均达成
                          <span style="margin-left: 10px;">&nbsp;奖励&nbsp;</span>
                          <el-input-number v-model="item.fullModuleBonus"  size="small" style="width: 60px" :controls="false" :min="0" />元
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                  <el-col v-if="jlDrawer.form.bonusTarget==='shop'" class="div-column2" :span="20" style="display: block;padding-left: 10%;">
                    <el-row style="display: block;">
                      <el-col :span="3">数量</el-col><el-col :span="4">单位</el-col><el-col :span="6">奖励内容</el-col>
                    </el-row>
                    <el-row style="display: block;">
                      <el-col :span="3">
                        <el-input-number v-model="item.bonus" style="width: 60px" size="small" :min="0" :precision="0" :controls="false" />
                      </el-col>
                      <el-col :span="4">
                        <el-input v-model="item.unit" style="width: 80px" size="mini" placeholder="元/款/架等" />
                      </el-col>
                      <el-col :span="6">
                        <el-input v-model="item.bonusText" style="width: 180px" size="mini" clearable placeholder="例如“滞销品回收费用支持”" />
                      </el-col>
                    </el-row>
                    <el-row style="display: block;">
                      <el-col class="min-desc">App显示预览: 预计可得 {{ verifyUndefined(item.bonus) + '' +  verifyUndefined(item.unit) + '' + verifyUndefined(item.bonusText) }}</el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <div v-if="jlDrawer.form.boostType==='grad'" style="margin-left: 10%">
              <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="() => jlDrawer.form.rules.push(this.getDefaultRules())">添加奖励档位</el-button>
            </div>
          </el-col>
          <el-col v-if="jlDrawer.form.boostType==='none'">
            <div class="mid-title">奖励条件设置</div>
            <div style="margin-left: 5%; margin-top: 15px">
              <span class="min-desc">App上仅展示奖励描述:  </span><span>{{ jlDrawer.form.description }}</span>
            </div>
          </el-col>

          <el-col v-if="jlDrawer.form.bonusTarget==='salesman'">
            <div style="margin-bottom: 15px"><span class="mid-title">店铺数奖励  </span> <span class="min-desc">选配，指单个业务员满足以上条件的店铺数量</span></div>
            <div style="margin-left: 5%">
              <div>
                <el-checkbox v-model="jlDrawer.form.minShop"><span style="font-weight: 400;font-size: 16px;">奖励起步线  </span></el-checkbox>
               <div style="display: inline-block;margin-left: 10px;"><span>满足以上条件的店铺数 &lt; </span>
              <el-input-number style="width: 60px" size="small" :min="1" :precision="0" :controls="false" v-model="jlDrawer.form.minShopNum" />家 则不发放以上任何奖励</div>
              </div>
              <div>
                <el-checkbox v-model="jlDrawer.form.otherJl"><span style="font-weight: 400;font-size: 16px">额外奖励  </span></el-checkbox>
                  <div style="display: inline-block;width: 50%;margin-left: 10px;"><span>满足以上条件的店铺数 </span>
                    <el-select v-model="jlDrawer.form.shopsBoostType" style="width: 60px" size="mini" placeholder="" @change="() => {
                      jlDrawer.form.gradExpJson = [deepClone(jlDrawer.defaultGradExp)];
                      jlDrawer.form.modelFullBonus = undefined;
                      jlDrawer.form.modelFullShops = undefined;
                      jlDrawer.form.modelPerShops = undefined;
                      jlDrawer.form.modelPerBonus = undefined;
                    }">
                      <el-option v-for="item in computeBoostTypes('always')" :key="item.value" :value="item.value" :label="item.shorLabel" />
                    </el-select>
                    <div v-if="jlDrawer.form.shopsBoostType==='grad'" style="display: table;margin-left: 10%;">
                      <el-row v-if="jlDrawer.form.gradExpJson && jlDrawer.form.gradExpJson.length>0">
                        <el-col v-for="(item,index) in jlDrawer.form.gradExpJson" :key="index">
                          {{'档位' + (index + 1)}}
                          <el-input-number v-model="item.numA" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />
                          {{" ≤ 店铺数 < "}}
                          <el-input-number v-model="item.numB" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />
                          <span>，额外奖励</span>
                          <el-input-number v-model="item.bonus" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />元
                          <el-button v-if="jlDrawer.form.gradExpJson.length > 1" size="mini" type="text" icon="el-icon-delete" @click="() => jlDrawer.form.gradExpJson.splice(index, 1)"/>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-button size="mini" type="text" icon="el-icon-circle-plus-outline" @click="() => jlDrawer.form.gradExpJson.push(deepClone(jlDrawer.defaultGradExp))">添加奖励档位</el-button>
                      </el-row>
                    </div>
                    <div v-if="jlDrawer.form.shopsBoostType==='full'" style="display: inline-block">
                      <el-input-number v-model="jlDrawer.form.modelFullShops" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />
                      家，额外奖励
                      <el-input-number v-model="jlDrawer.form.modelFullBonus" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />元
                    </div>
                    <div v-if="jlDrawer.form.shopsBoostType==='per'" style="display: inline-block">
                      <el-input-number v-model="jlDrawer.form.modelPerShops" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />
                      家，额外奖励
                      <el-input-number v-model="jlDrawer.form.modelPerBonus" style="width: 60px" size="small" :min="1" :precision="0" :controls="false" />元
                    </div>
                  </div>
                </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="float:right; width: 20%">
        <el-button type="primary" size="mini" @click="handleCloseDrawer">取消</el-button>
        <el-button type="primary" size="mini" @click="submitEdit">确定</el-button>
      </div>
    </el-drawer>

    <el-dialog title="商品选择" :visible.sync="skuDialog.visible" append-to-body width="700px" :close-on-click-modal="false" @close="() => {
      if ($refs.skuItemRef) {
        $refs.skuItemRef.reset();
      }
      }">
      <div>
        <Items ref="skuItemRef" :task-id.sync="taskId" :skus.sync="skuDialog.skus" :scope="importScope + ''" :page-sku="pageTaskGoods" :boost="true" />
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="saveDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitTaskSku">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageTaskBoost, getBoostDetail, saveBoost, delBoost, getTaskShopOptions, pageTaskGoods, getTaskBoostOptions } from "@/api/appTask";
import { parseTime } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import {deepClone} from "../../../../utils";
import Items from "../../components/Items";
import {v4 as uuidv4} from "uuid";

export default {
  name: "JL",
  components: {Items, Pagination, DictSelect},
  props: {
    taskId: {
      type: String,
      default: '0'
    },
    importScope: {
      type: Number,
      default: 0
    },
    componentType: {
      type: String,
      default: 'JL'
    },
    componentBoostId: {
      type: [Number, String],
    },
    boostInfo: {
      type: Object,
    },
    modules: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      uuidv4,
      tabName: 'salesman',
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        bonusTarget: 'salesman',
        deleted: '0',
      },
      homeLoading: false,
      loading: false,
      remoteLoading: false,
      dictParam: {},
      formDictParam: {},
      editDialog: {
        visible: false,
        form: {
        },
      },
      pageTaskGoods, deepClone,
      jlDrawer: {
        visible: false,
        rules: {
          name: [{ required: true, message: "  ", trigger: "change" }],
          description: [{ required: true, message: " ", trigger: "change" }],
        },
        form: {
          mtTaskId: undefined,
          name: '',
          description: '',
          skuTaskId: '-1',
          boostType: '',
          minShopNum: 1,
          shopsBoostType: '',
          modelFullShops: undefined,
          modelFullBonus: undefined,
          modelPerShops: undefined,
          modelPerBonus: undefined,
          modelGradExp: '',
          shopsDescription: '',
          bonusTarget: '',
          salesLimit: '',
          roofOfShop: undefined,
          deleted: 0,
          rules: [],
          allShops: "2",
          shopCapacities: [],
          shopLevels: [],
          gradExpJson: [{
            numA: undefined,
            numB: undefined,
            bonus: undefined
          }],
          minShop: false,
          otherJl: false,
          taskTime: '1',
          taskDate: [],
          startDate: undefined,
          endDate: undefined,
          verificationId: undefined,
          modules: []
        },
        defaultForm: {
          mtTaskId: undefined,
          name: '',
          description: '',
          skuTaskId: '-1',
          boostType: 'full',
          minShopNum: 1,
          shopsBoostType: 'full',
          modelFullShops: undefined,
          modelFullBonus: undefined,
          modelPerShops: undefined,
          modelPerBonus: undefined,
          modelGradExp: '',
          shopsDescription: '',
          bonusTarget: '',
          salesLimit: 'ALL',
          roofOfShop: undefined,
          deleted: 0,
          rules: [],
          allShops: "2",
          shopCapacities: [],
          shopLevels: [],
          gradExpJson: [{
            numA: undefined,
            numB: undefined,
            bonus: undefined
          }],
          minShop: false,
          otherJl: false,
          taskTime: '1',
          taskDate: [],
          startDate: undefined,
          endDate: undefined,
          verificationId: undefined,
          modules: [],
          ifNeedAudit: 'N',
          ifNeedPicture: 'N',
        },
        defaultRules: {
          boostId: undefined,
          conditionMode: 'and',
          bonus: undefined,
          bonusText: undefined,
          unit: undefined,
          grade: 0,
          deleted: 0,
          conditions: [],
          boostWay: '1'
        },
        defaultConditions: {
          boostRuleId: undefined,
          refTaskId: undefined,
          measure: 'kss',
          operation: undefined,
          numA: undefined,
          numB: undefined,
          deleted: 0,
          skus: [],
          configJson: {
            taskId: undefined,
            shopCapacities: undefined,
            shopLevels: undefined,
            allShops: undefined,
            allSkus: '2',
            taskTime: '1',
            taskDate: [],
            startDate: undefined,
            endDate: undefined,
            relatedBoost: undefined,
          },
          virRelConditionId: undefined
        },
        defaultGradExp: {
          numA: undefined,
          numB: undefined,
          bonus: undefined
        },
        type: 'sale',
        title: {
          'sale': '业务员激励配置',
          'shop': '终端激励配置'
        }
      },
      skuDialog: {
        visible: false,
        msg: undefined,
        index: undefined,
        cIndex: undefined,
        skus: [],
      },
      noticeDialog: {
        msg: undefined,
        visible: false,
      },
      skuDrawer: {
        visible: false,
      },
      options: {
        capacityOptions: [],
        shopLevelOptions: [],
        boostTypes: [
          { value: 'full', label: '满XX奖励', shorLabel: '满', otherLabel: '满额奖励'},
          { value: 'per', label: '每XX奖励', shorLabel: '每满'},
          { value: 'grad', label: '梯度奖励', shorLabel: '阶梯', otherLabel: '梯度奖励'},
          { value: 'none', label: '以上都不是'}],
        defaultQuotaOptions: [{ value: 'jh_total', label: '进货金额', unit: '元'}, { value: 'kss', label: '上柜款数', unit: '款'},
          { value: 'kss_diff', label: '新增款数', unit: '款'}, { value: 'jh_count', label: '进货数量', unit: '个'},{ value: 'boost_reach', label: '激励达成', unit: ''}],
        skuOptions: [{ value: '1', label: '全部商品'},{ value: '2', label: '全部任务商品'},{ value: '3', label: '指定商品'}],
        allOperations: [{ value: '[a,b)', label: '梯度奖励', shorLabel: '阶梯'}, { value: 'gte', label: '满额奖励', shorLabel: '满'}],
        taskBoosts: [[]]
      },
    };
  },
  watch: {
    taskId: {
      handler(nV, oV) {
        this.getTaskOptions(nV);
        this.queryForm.mtTaskId = nV;
        if (this.componentType === 'JL') {
          this.handleFilter();
        }
      },
      immediate: true,
      deeper: true
    },
    tabName: {
      handler(nV, oV) {
        this.queryForm.bonusTarget = nV;
        this.handleFilter();
      },
      immediate: true,
      deeper: true
    },
    componentBoostId: {
      handler(nV) {
        if (this.componentType === 'HX') {
          if (nV) {
            this.handleEdit({ id: nV}, this.modules)
          } else {
            this.handleEdit(undefined, this.modules)
          }
        }
      },
      immediate: true,
      deeper: true
    }
  },
  computed: {
    measureOptions() {
      let arr = [];
      this.jlDrawer.form.rules.forEach((e, k) => {
        if (!arr[k]) {
          arr[k] = [];
        }
        const currMeasures = e.conditions.filter(cc => cc.measure!==undefined).map(cm => cm.measure);
        e.conditions.forEach((c, ck) => {
          if (!arr[k][ck]) {
            arr[k][ck] = [];
          }
          arr[k][ck] = this.options.defaultQuotaOptions;
        })
      })
      return arr;
    },
    shopDescription() {
      const tmpForm = deepClone(this.jlDrawer.form)
      tmpForm.shopsDescription = undefined;
      let desc = '';
      if (tmpForm.bonusTarget === 'salesman') {
        if (tmpForm.minShop) {
          desc+='达成店铺数未满' + tmpForm.minShopNum + '家，无法获得任何激励。';
        } else {
          desc+='达成店铺数未满1家，无法获得任何激励。';
        }
        if (tmpForm.otherJl) {
          switch (tmpForm.shopsBoostType) {
            case 'grad':
              tmpForm.gradExpJson.forEach((item, index) => {
                desc+= item.numA+ '≤达成店铺数<'+item.numB +'，得'+item.bonus+'元激励。'
              })
              break;
            case 'full':
              desc+='达成店铺数满'+tmpForm.modelFullShops+' 家，得'+tmpForm.modelFullBonus+'元激励。';
              break;
            case 'per':
              desc+='达成店铺数每满'+tmpForm.modelPerShops+'家，得'+tmpForm.modelPerBonus+'元激励。';
              break;
          }
        }
      }
      return desc;
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageTaskBoost(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    async handleEdit(row, modules) {
      if (row) {
        await getBoostDetail(row.id).then(res => {
          let tmpForm = this.getDefaultForm();
          tmpForm = Object.assign(tmpForm, res.data);
          this.jlDrawer.form = this.setData(tmpForm);
          this.$forceUpdate();
        })
      } else {
        if (this.componentType === 'HX' && this.boostInfo) {
          this.jlDrawer.form = this.setData(this.boostInfo);
        } else {
          this.jlDrawer.form = this.getDefaultForm();
        }
      }
      if (modules) {
        this.jlDrawer.form.rules.forEach(e => {
          let tmpModules = [];
          modules.forEach(m => {
            if (e.modules) {
              const emodule = e.modules.find(em => m.id ===em.id)
              if (emodule) {
                tmpModules.push({id: m.id, name: m.name, moduleMoney: emodule.moduleMoney})
              } else {
                tmpModules.push({id: m.id, name: m.name, moduleMoney: 0})
              }
            } else {
              tmpModules.push({id: m.id, name: m.name, moduleMoney: 0})
            }
          })
          e.modules = tmpModules;
          if (e.boostWay !== '4') {
            e.bonus = 0;
          }
        })
      }
      this.$forceUpdate();
      this.jlDrawer.visible = true;
    },
    setData(tmpForm) {
      if (tmpForm.rules) {
        tmpForm.rules.forEach((rule, pIndex) => {
          if (rule.conditions) {
            rule.conditions.forEach((cond, cIndex) => {
              if (!cond.configJson) {
                cond.configJson = {
                  taskId: tmpForm.mtTaskId,
                  shopCapacities: undefined,
                  shopLevels: undefined,
                  allShops: '2',
                  allSkus: '2',
                  taskTime: '1',
                  taskDate: [],
                  startDate: undefined,
                  endDate: undefined,
                }
              } else {
                cond.configJson = JSON.parse(cond.configJson);
                tmpForm.allShops = cond.configJson.allShops;
                tmpForm.shopLevels = cond.configJson.shopLevels.split(',');
                tmpForm.shopCapacities = cond.configJson.shopCapacities.split(',');
                tmpForm.taskTime = cond.configJson.taskTime ? cond.configJson.taskTime: '1';
                tmpForm.taskDate = cond.configJson.startDate ? [cond.configJson.startDate, cond.configJson.endDate]: [];
                tmpForm.startDate = cond.configJson.startDate ? cond.configJson.startDate: undefined;
                tmpForm.endDate = cond.configJson.endDate ? cond.configJson.endDate: undefined;
                if (cond.configJson.relatedBoost) {
                  this.handleTaskBoostOptions(pIndex, cIndex, '', cond.configJson.relatedBoost);
                  cond.configJson.relatedBoost = cond.configJson.relatedBoost.split(',').map(d => parseInt(d));
                }
              }
            })
          }
        })
      }

      if (tmpForm.minShopNum > 1) {
        tmpForm.minShop = true;
      }
      if (tmpForm.shopsBoostType) {
        tmpForm.otherJl = true;
        if (tmpForm.shopsBoostType === 'grad') {
          tmpForm.gradExpJson = tmpForm.modelGradExp.split('|').map(e => {
            const arr = e.split('->');
            const numArr = arr[0].replaceAll('[','').replaceAll(')', '');
            return { numA: numArr[0], numB: numArr[1], bonus: arr[1]}
          })
        }
      }
      return tmpForm
    },
    getDefaultForm() {
      let form = deepClone(this.jlDrawer.defaultForm);
      form.bonusTarget = this.tabName;
      form.mtTaskId = this.taskId;
      form.rules = [this.getDefaultRules()]
      return form;
    },
    getDefaultRules() {
      let rules = deepClone(this.jlDrawer.defaultRules);
      rules.conditions = [this.getDefaultCondition()]
      return rules;
    },
    getDefaultCondition() {
      let condition = deepClone(this.jlDrawer.defaultConditions);
      condition.virRelConditionId = uuidv4().replaceAll('-','')
      return condition;
    },
    submitEdit() {
      this.$refs.jlFormRef.validate(valida => {
        if (valida) {
          let tmpForm = deepClone(this.jlDrawer.form);
          const msg = this.validateForm(tmpForm);
          console.log(tmpForm)
          if (msg!='') {
            this.noticeDialog.msg = msg;
            this.noticeDialog.visible = true;
            return;
          }
          tmpForm.shopsDescription = this.shopDescription;
          if (this.componentType === 'JL') {
            this.homeLoading = true;
            saveBoost(tmpForm).then(res => {
              this.homeLoading = false;
              if (res.code===200) {
                this.$message.success("操作成功！");
                this.jlDrawer.visible = false
                this.handleFilter();
              }
            })
          } else {
            this.$emit('handleHxBoostChange', tmpForm);
            this.jlDrawer.visible = false
          }
        }
      })
    },
    validateShopVal(form) {
      if (form.allShops === '3' && this.filterEmpty(form.shopLevels).length === 0 && this.filterEmpty(form.shopCapacities).length === 0) {
        return '指定的店铺类型不能全部为空;';
      }
      return ''
    },
    validateForm(form) {
      let msg ='';
      if (form.taskTime === '2' && !form.startDate) {
        msg+='自定义任务时间未填写;'
      }
      const bonusTarget = form.bonusTarget;
      const boostType = form.boostType;
      msg+=this.validateShopVal(form);
      if (bonusTarget === 'salesman') {
        if (boostType === 'per' && form.roofOfShop== undefined) {
          msg+='未填写具体的封顶奖励金额;'
        }
        if (form.minShop && !form.minShopNum) {
          msg+='店铺数奖励->起步线奖励 未设置奖励起步线的店铺数;'
        } else if (!form.minShop) {
          form.minShopNum = 1;
        }
        if (form.otherJl) {
          if (form.shopsBoostType!=='grad') {
            if (form.shopsBoostType === 'per') {
              if (!form.modelPerShops) {
                msg+='未填写 店铺数奖励->额外奖励中的 满足条件的店铺数;'
              }
              if (!form.modelPerBonus) {
                msg+='未填写 店铺数奖励->额外奖励中的 额外奖励金额;'
              }
            } else if (form.shopsBoostType === 'full') {
              if (!form.modelFullShops) {
                msg+='未填写 店铺数奖励->额外奖励中的 满足条件的店铺数;'
              }
              if (!form.modelFullBonus) {
                msg+='未填写 店铺数奖励->额外奖励中的 额外奖励金额;'
              }
            }
          } else {
            let modelGradExp = []
            form.gradExpJson.forEach((item, index) => {
              let valida = true;
              if (!item.numA || !item.numB) {
                msg+=' 店铺数奖励->额外奖励中的 档位 ' + (index+1) + ' 店铺数范围未填写完整;'
                valida = false;
              }
              if (item.bonus== undefined) {
                msg+=' 店铺数奖励->额外奖励中的 档位 ' + (index+1) + ' 额外奖励金额未填写;'
                valida = false;
              }
              if (valida) {
                modelGradExp.push('[' + item.numA + ',' + item.numB + ')->' + item.bonus);
              }
            })
            form.modelGradExp = modelGradExp.join('|');
          }
        } else {
          form.modelFullBonus = undefined;
          form.modelFullShops = undefined;
          form.modelPerShops = undefined;
          form.modelPerBonus = undefined;
          form.modelGradExp = undefined;
          form.shopsBoostType = undefined;
        }
      } else {
        form.modelFullBonus = undefined;
        form.modelFullShops = undefined;
        form.modelPerShops = undefined;
        form.modelPerBonus = undefined;
        form.modelGradExp = undefined;
        form.shopsBoostType = undefined;
      }
      form.gradExpJson = undefined;
      if (boostType !== 'none') {
        form.rules.forEach((item, index) => {
          const ruleMsg = this.validateRule(index, item, boostType, bonusTarget, form);
          msg+=ruleMsg!=='' ? ruleMsg + '</br>': '';
        })
      }
      return msg;
    },
    verifyUndefined(val) {
      if (val === undefined) {
        return '';
      }
      return val;
    },
    validateRule(index, rule, boostType, bonusTarget, form) {
      let msg = '';
      const prefixMsg = ' 奖励条件->奖励档位 ' + (index + 1);
      if (rule.boostWay =='2') {
        if (!rule.virRelConditionId) {
          msg+= prefixMsg + '  未绑定具体指标;'
        }
        if (!rule.boostNum || rule.boostNum === 0) {
          msg+= prefixMsg + '  奖励百分比需要大于0;'
        }
        if (!form.roofOfShop=== undefined) {
          msg+= prefixMsg + '  封顶奖励不可为空;'
        }
        if (rule.modules) {
          rule.modules.forEach(e => {
            e.moduleMoney = 1;
          })
        }
        rule.bonus = 1;
      } else if (!rule.bonus) {
        let moduleBonus = 0;
        if (rule.modules) {
          rule.modules.forEach(e => {
            moduleBonus+=e.moduleMoney;
          })
        }
        moduleBonus+=(rule.fullModuleBonus ? rule.fullModuleBonus: 0);
        if (moduleBonus== undefined) {
          msg = prefixMsg + ' 未填写具体的奖励金额;'
        }
      } else if (rule.modules) {
        rule.modules.forEach(e => {
          e.moduleMoney = 0;
        })
      }
      if (bonusTarget === 'shop') {
        if (!rule.unit) {
          msg+= prefixMsg + ' 未填写奖励单位;'
        }
        if (!rule.bonusText) {
          msg+= prefixMsg + '  未填写具体的奖励描述;'
        }
      } else {
        rule.unit = '元'
      }
      if (msg!=='') {
        msg += "</br>";
      }
      if (boostType !== 'none') {
        rule.conditions.forEach((item, cIndex) => {
          item.grade = index+1;
          const conditionMsg = this.validateCondition(index, cIndex, item, boostType, form);
          msg+=conditionMsg!=='' ? conditionMsg + '</br>': '';
        })
      }
      return msg;
    },
    validateCondition(index, cIndex, condition, boostType, form) {
      let msg = ''
      const prefixMsg = '奖励档位' + (index+1) + ' 第' + (cIndex +1) + '个条件';

      if (condition.measure == 'boost_reach') {
        condition.operation = 'in';
        if (!condition.configJson.relatedBoost || condition.configJson.relatedBoost.length === 0) {
          msg+= prefixMsg + '  指标 激励达成未选择具体的关联激励;'
        } else {
          condition.configJson.relatedBoost = condition.configJson.relatedBoost.join(',');
        }
      } else {
        if (boostType !== 'none') {
          if (condition.configJson.allSkus === '3' && condition.skus.length === 0) {
            msg+= prefixMsg + ' 未选择具体的指定商品;';
          }
          if (boostType === 'full') {
            if (condition.numA== undefined) {
              msg+= prefixMsg + ' 奖励 未填写具体的奖励金额;'
            }
            condition.operation = 'gte'
          } else if (boostType === 'per') {
            if (condition.numA== undefined) {
              msg+= prefixMsg + '奖励 未填写具体的奖励金额;'
            }
            condition.operation = 'gte'
          } else if (boostType === 'grad') {
            const measureLabel = this.options.allOperations.find(e => e.value===condition.operation).label;
            if (!condition.operation) {
              msg+= prefixMsg + ' 指标 ' + measureLabel + ' 未选择奖励类型;'
            } else {
              if (condition.operation.indexOf('a,b')>-1 && (!condition.numA || !condition.numB)) {
                msg+= prefixMsg + '  指标 ' + measureLabel + ' 具体的数值范围;'
              } else if (condition.operation.indexOf('a,b')<0 && !condition.numA) {
                msg+= prefixMsg + '  指标 ' + measureLabel + '  具体的数值范围;'
              }
            }
          }
        }

        condition.configJson.relatedBoost = ''
      }
      condition.configJson.allShops = form.allShops;
      condition.configJson.shopLevels = form.shopLevels.join(',');
      condition.configJson.shopCapacities = form.shopCapacities.join(',');
      condition.configJson.taskId = form.mtTaskId;
      condition.configJson.taskTime = form.taskTime;
      condition.configJson.startDate = form.startDate;
      condition.configJson.endDate = form.endDate;
      condition.configJson = JSON.stringify(condition.configJson);
      return msg;
    },
    filterEmpty(arr) {
      return arr.filter(e => e);
    },
    handleDel(row) {
      delBoost(row.id).then(res => {
        if (res.code===200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    getTaskOptions(v) {
      getTaskShopOptions(v).then(res => {
        this.options.capacityOptions = res.data.capacityOptions;
        this.options.shopLevelOptions = res.data.shopLevelOptions;
      })
    },
    handleRemoveRule(index) {
      this.jlDrawer.form.rules.splice(index, 1)
      this.$forceUpdate();
    },
    handleRemoveCondition(index, cIndex) {
      this.jlDrawer.form.rules[index].conditions.splice(cIndex, 1)
      this.options.taskBoosts[index].splice(cIndex, 1)
      this.$forceUpdate();
    },
    computeJlType(index, cIndex) {
      if (index === 0 && cIndex == 0) {
        return [{ value: '[a,b)', label: '梯度奖励', shorLabel: '阶梯'}];
      } else if (index === 0 && cIndex !== 0) {
        return [{ value: 'gte', label: '满额奖励', shorLabel: '满'}];
      } else {
        let curConditions = this.jlDrawer.form.rules[index].conditions.filter(e => e.operation && e.operation.indexOf('a,b') > -1);
        if (curConditions.length > 0 && this.jlDrawer.form.rules[index].conditions.length > 1) {
          if (this.jlDrawer.form.rules[index].conditions[cIndex].operation && this.jlDrawer.form.rules[index].conditions[cIndex].operation.indexOf('a,b') > -1) {
            return [{ value: '[a,b)', label: '梯度奖励', shorLabel: '阶梯'}, { value: 'gte', label: '满额奖励', shorLabel: '满'}];
          } else {
            return [{ value: 'gte', label: '满额奖励', shorLabel: '满'}];
          }
        } else {
          return [{ value: '[a,b)', label: '梯度奖励', shorLabel: '阶梯'}, { value: 'gte', label: '满额奖励', shorLabel: '满'}];
        }
      }
    },
    handleAddCondition(index) {
      let defaultRc = this.getDefaultCondition();
      const currMeasures = this.jlDrawer.form.rules[index].conditions.filter(cc => cc.measure!==undefined).map(cm => cm.measure);
      const currentMeasure = this.options.defaultQuotaOptions.find(e => currMeasures.indexOf(e.value) < 0).value;
      defaultRc.measure = currentMeasure;
      this.jlDrawer.form.rules[index].conditions.push(defaultRc);
      this.$forceUpdate();
    },
    handleChooseSkus(index,cIndex) {
      this.skuDialog.index = index;
      this.skuDialog.cIndex = cIndex;
      this.skuDialog.visible = true;
      this.skuDialog.skus = this.jlDrawer.form.rules[index].conditions[cIndex].skus;
    },
    submitTaskSku() {
      const index = this.skuDialog.index;
      const cIndex = this.skuDialog.cIndex;
      if (this.$refs.skuItemRef) {
        this.jlDrawer.form.rules[index].conditions[cIndex].skus = deepClone(this.$refs.skuItemRef.form.skus);
      }
      this.skuDialog.visible = false;
    },
    computeBoostTypes(type) {
      if (this.jlDrawer.form.bonusTarget === 'salesman' || type ==='always') {
        return this.options.boostTypes.filter(e => e.value !=='none');
      } else {
        return this.options.boostTypes;
      }
    },
    handleDelBoost(row) {
      this.$confirm('该操作不可逆，请确认是否删除该激励！', '提示', { type: 'warning' }).then(() => {
        delBoost(row.id).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    handleDateChange(val) {
      if (val) {
        this.jlDrawer.form.startDate = parseTime(val[0].getTime(), '{y}-{m}-{d}') + ' 00:00:00';
        this.jlDrawer.form.endDate = parseTime(val[1].getTime(), '{y}-{m}-{d}') + ' 23:59:59';
      } else {
        this.jlDrawer.form.startDate = undefined;
        this.jlDrawer.form.endDate = undefined;
      }
    },
    handleCloseDrawer() {
      this.jlDrawer.visible = false
      this.$emit('handleHxBoostChange', undefined);
    },
    handleTaskBoostOptions(pIndex, cIndex, query, ids) {
      if (!query && !ids) {
        return;
      }
      this.remoteLoading = true;
      if (!this.options.taskBoosts[pIndex]) {
        this.options.taskBoosts[pIndex] = [];
      }
      getTaskBoostOptions("query=" + query + "&boostIds=" + ids + "&currentBoostId="+ (this.jlDrawer.form.id?this.jlDrawer.form.id: '')).then(res => {
        this.options.taskBoosts[pIndex][cIndex] = res.data;
        this.remoteLoading = false;
      })
    },
    getTaskBoostOptions(pIndex, cIndex) {
      if (this.options.taskBoosts[pIndex]) {
        return this.options.taskBoosts[pIndex][cIndex]
      }
      return []
    },
    getConditions(r) {
      return r.conditions.filter(e => e.measure!='boost_reach').map(e => { return { label: this.options.defaultQuotaOptions.find(d => e.measure==d.value).label, value: e.virRelConditionId, unit: this.options.defaultQuotaOptions.find(d => e.measure==d.value).unit }})
    }
  },
};
</script>

<style lang="scss" scoped>
label {
  font-weight: 500 !important;
}
::v-deep .radio-class>label{
  padding-right: 22px;
}
.mid-title {
  color: #1482f0;
  font-size: 22px;
  margin-left: 20px;
}
.min-desc {
  color: #1482f0;
  margin-left: 10px
}
.border-div {
  border-left-width: 1px;
  border-left-style: solid;
  text-align: center;
}
.div-column1 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}
.row-column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.div-column2 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  border: 1px;
  border-left-width: 1px;
  border-left-style: solid;
}
.div-column3 {
  min-height: 40px;
  border: 1px;
  border-left-width: 1px;
  border-left-style: solid;
}
</style>

<style lang="scss" scoped src='@/style/common/index.scss'></style>
