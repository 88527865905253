var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mg-pagination-table" },
    [
      _vm.isShowTopBar
        ? _c("div", { staticClass: "mg-table-topbar" }, [
            _c("div", { staticClass: "topbar-left" }, [_vm._t("topleft")], 2),
            _c("div", { staticClass: "topbar-right" }, [_vm._t("topright")], 2),
          ])
        : _vm._e(),
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "mgTable",
              class: [
                "mg-customer-table-container",
                { "mg-customer-table--header": _vm.showSearchBar },
                _vm.isHeaderMiddle ? "mg-customer-table-headerAlign" : "",
              ],
              attrs: {
                size: "mini",
                "empty-text": "暂无数据",
                border: "",
                data: _vm.initialData || _vm.list,
                "max-height": _vm.maxHeight,
              },
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._t("selection"),
          _vm.$slots.empty
            ? _c(
                "div",
                { attrs: { slot: "empty" }, slot: "empty" },
                [_vm._t("empty")],
                2
              )
            : _vm._e(),
          _vm._l(_vm.columns, function (column) {
            return [
              column.children && column.children.length > 0
                ? _c("mg-table-column", {
                    key: column.label,
                    attrs: { tableColumns: column },
                  })
                : column.slot
                ? _c("el-table-column", {
                    key: column.prop,
                    attrs: {
                      label: column.label,
                      width: column.width,
                      "min-width": column.minWidth,
                      align: column.align,
                      prop: column.prop,
                      sortable: column.sortable,
                      "sort-by": column.sortBy,
                      fixed: column.fixed,
                      "show-overflow-tooltip": column.showOverflowTooltip,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scoped) {
                            return [_vm._t(column.slot, null, null, scoped)]
                          },
                        },
                        column.headerSlot
                          ? {
                              key: "header",
                              fn: function (scoped) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mg-customer-table-header-cell-label",
                                    },
                                    [_vm._v(_vm._s(column.label))]
                                  ),
                                  _vm.showSearchBar
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mg-customer-table-header-cell-body",
                                        },
                                        [
                                          _vm._t(
                                            column.headerSlot,
                                            null,
                                            null,
                                            scoped
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  })
                : column.default
                ? [_vm._t(column.prop)]
                : _c(
                    "el-table-column",
                    _vm._b(
                      {
                        key: column.prop,
                        attrs: { "show-overflow-tooltip": "" },
                        scopedSlots: _vm._u(
                          [
                            column.headerSlot
                              ? {
                                  key: "header",
                                  fn: function (scoped) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mg-customer-table-header-cell-label",
                                        },
                                        [_vm._v(_vm._s(column.label))]
                                      ),
                                      _vm.showSearchBar
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mg-customer-table-header-cell-body",
                                            },
                                            [
                                              _vm._t(
                                                column.headerSlot,
                                                null,
                                                null,
                                                scoped
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      },
                      "el-table-column",
                      column,
                      false
                    )
                  ),
            ]
          }),
          _vm._t("default"),
        ],
        2
      ),
      _vm.showPagination
        ? _c("el-pagination", {
            staticClass: "mg-pagination-paging",
            attrs: {
              background: "",
              "current-page": _vm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.pagination.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }