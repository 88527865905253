import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/request";

// --------------数据采集页面-------------------
// 杂物社 仓到店 明细页面分页查询-------
export function page(data) {
  return axiosPost("/adsZwsBh/page", data, 1);
}

/**
 * 单个更新
 * @param {*} data
 * @returns
 */
export function update(data) {
  return axiosPost("/adsZwsBhAdj", data, 1);
}

/**
 * 生成配货单数据
 * @returns {*}
 */
export function distribute() {
  return axiosPut("/adsZwsBhAdj/distribute", {}, 1);
}

/**
 * 获取当前更新日期
 * @param data
 * @returns {*}
 */
export function getCurrentDate(data) {
  return axiosGet("/dwsZwsOflPhBasedataRunflag", data, 1);
}


/**
 * 批量更新
 * @param {*} data
 * @returns
 */
export function updateBatch(data) {
  return axiosPut("/adsZwsBhAdj/batch", data, 1);
}
/**
 * 获取下拉
 * @param {*} type
 * @param {*} data
 * @returns
 */
export function dictSelect(type, data) {
  return axiosGet("/adsZwsBh/dict/" + type + data, null, 1);
}
/**
 *
 * @returns 获取配货单数据
 */
export function selectSupplyGoods() {
  return axiosGet("/adsZwsBh/supplyGoods", null, 1);
}

/**
 *获取各门店补货商品统计数据
 * @returns {*}
 */
export function selectStoreCount() {
  return axiosGet("/adsZwsBh/storeCount", null, 1);
}

/**
 * 按门店获取补货商品数据
 * @param data
 * @returns {*}
 */
export function selectSupplyGoodsByStore(data) {
  return axiosGet("/adsZwsBh/supplyGoodsByStore/" + data, null, 1);
}

/**
 * 获取报表数据
 * @param {*} data
 * @returns
 */
export function getChartData(data) {
  return axiosGet("/adsZwsBh/chartData" + data, null, 1);
}

/**
 *
 * @param data
 * @returns {*}
 */
export function getSummaryData(data) {
  return axiosGet("/adsZwsBh/summaryData" + data, null, 1);
}

/**
 * 获取下载记录
 * @returns {*}
 */
export function getDownloadRecord(data) {
  return axiosGet("/downloadRecord/list" + data, null, 1);
}

/**
 * 获取全部日期
 * @returns {*}
 */
export function getAllDate() {
  return axiosGet("/adsZwsBh/allDate", null, 1);
}

export function refreshCache(data) {
  return axiosGet("/adsZwsBh/refresh?dateKey=" + data, null, 1);
}

export function getDayChartData(data) {
  return axiosGet("/adsZwsBh/dayChart" + data, null, 1);
}

export function getHolidayData() {
  return axiosGet("/adsZwsBh/holiday", null, 1);
}

export function getHoliday() {
  return axiosGet("/adsZwsBh/hol-name", null, 1);
}

export function addCompleteRecord(data) {
  return axiosPut("/adsZwsBh/record/add", data, 1);
}

export function deleteCompleteRecord(data) {
  return axiosDelete("/adsZwsBh/record/delete/" + data.storeCode + '/' + data.itemCode, null, 1);
}

export function exportBhData(data) {
  return axiosPost("/adsZwsBh/submit/export/task", data, 1);
}

// 获取标记全门店配货sku数量，单独配货门店数量
export function getTbStatistics(data) {
  return axiosPost("/adsZwsBh/tb/statistics", data, 1);
}

/**
 *   统计填报数据
 * @param data
 * @returns {*}
 */
export function getReportData() {
  return axiosGet("/adsZwsBh/report", null, 1);
}
