<template>
  <el-dialog
    :visible="isOpen"
    title="表格字段设置"
    width="1000px"
    :close-on-click-modal="false"
    @close="handleClose"
    :append-to-body="appendToBody"
  >
    <div class="columns-config">
      <div class="show-columns">
        <div class="header">
          <span>可拖动表格字段进行排序</span>
          <span class="reset-btn" :class="{ active: isDraggable }" @click="handleReset">
            重置
            <i class="iconfont mg-icon_shuaxinshuju" />
          </span>
        </div>
        <draggable
          v-model="showColumns"
          group="columns"
          class="drag-group"
          v-bind="dragOptions"
          @update="handleDragUpdate"
        >
          <span
            class="drag-group-item"
            v-for="col in showColumns"
            v-bind:key="col.prop || col.type"
            :class="{ fixed: col.fixed }"
          >
            {{ col.label }}
            <i class="iconfont mg-icon-bianqianguding" v-if="col.fixed" />
          </span>
        </draggable>
      </div>
    </div>
    <span slot="footer">
      <div class="column-count">列字段总数:{{ showColumns.length }}</div>
      <el-button size="small" type="primary" @click="handleSubmit" :disabled="columns.length === 0">提交</el-button>
      <el-button size="small" @click="handleCancel">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    display: 'Transitions',
    props: {
      visible: Boolean,
      columns: {
        type: Array,
        default: [],
      },
      appendToBody: {
        type: Boolean,
        default: false,
      },
    },
    components: { draggable },
    data() {
      return {
        isDraggable: false,
        showColumns: [],
      }
    },
    watch: {
      columns: {
        immediate: true,
        handler(list) {
          const columns = list.map(item => { if (item?.label) return item }).filter((item) => item !== undefined)
          this.showColumns = columns
        },
      },
    },
    computed: {
      dragOptions() {
        return {
          ghostClass: 'ghost',
        }
      },
      isOpen() {
        return this.visible
      },
    },
    methods: {
      handleReset() {
        this.showColumns = [].concat(this.columns)
      },
      handleCancel() {
        this.handleReset()
        this.handleClose()
      },
      handleClose() {
        this.$emit('update:visible', false)
      },
      handleDragUpdate() {
        this.isDraggable = true
      },
      handleSubmit() {
        const noChange = this.showColumns.every((item, index) => this.columns[index].label === item.label)
        if (!noChange) {
          this.$emit('column-change', this.showColumns)
        }
        this.handleClose()
      }
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      padding: 10px 20px;
    }
  }
  .columns-config {
    display: flex;
    .show-columns {
      flex: 1;
      margin-right: 5px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 10px;
        .reset-btn {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #999;
          &.active {
            color: #e60012;
            i {
              color: #e60012;
            }
          }
          i {
            font-size: 14px;
            margin-left: 2px;
          }
        }
      }
      .drag-group {
        height: 320px;
        overflow: auto;
        background: #fff;
        border: 1px solid rgba(37, 42, 61, 0.1);
        padding: 10px 0 10px 10px;
        box-sizing: border-box;
        .drag-group-item {
          padding: 5px 10px;
          cursor: move;
          margin-right: 10px;
          margin-bottom: 10px;
          display: inline-block;
          background: rgba(37, 42, 61, 0.05);
          &.fixed {
            opacity: 0.6;
            cursor: pointer;
            pointer-events: none;
            i {
              color: #999;
            }
          }
        }
      }
    }
  }
  .column-count {
    font-size: 12px;
    color: rgba(37, 42, 61, 0.8);
    line-height: 32px;
    float: left;
  }
</style>
<style>
  .ghost {
    background: rgba(230, 0, 18, 0.15) !important;
  }
</style>
