/** *@name:index *@author:dingyongya *@date:2022/6/16 11:06 *@update:2022/6/16 11:06 */

<template>
  <el-tooltip :disabled="tagDisabled" :content="content" placement="top" popper-class="mg-tooltip" ref="mgTooltip">
    <slot />
  </el-tooltip>
</template>

<script>
  export default {
    name: 'index',
    props: {
      tag: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        tagDisabled: true,
        content: '',
      }
    },
    mounted() {
      const childWidth = this.$refs.mgTooltip.$el.firstElementChild.offsetWidth
      const elWidth = this.$refs.mgTooltip.$el.offsetWidth
      if (childWidth > elWidth) {
        this.tagDisabled = false
      }
      if (['inputSearch', 'input', 'autocomplete'].includes(this.tag.type)) {
        this.content = `${this.tag.label}: ${this.tag.value}`
      } else if (['selectTree'].includes(this.tag.type)) {
        this.content = `${this.tag.label}: ${this.tag.value.map((item) => item.name).toString()}`
      } else if (['select'].includes(this.tag.type)) {
        this.content = `${this.tag.label}: ${this.tag.value.map((item) => item.label).toString()}`
      }
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .el-tooltip__popper {
      max-width: 1100px;
    }
  }
</style>
