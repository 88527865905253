var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "筛选设置",
        visible: _vm.dialogVisible,
        width: "853px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("p", [_vm._v("已展示筛选项（可拖动进行排序)")]),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "draggable",
            {
              model: {
                value: _vm.itemList,
                callback: function ($$v) {
                  _vm.itemList = $$v
                },
                expression: "itemList",
              },
            },
            [
              _c(
                "transition-group",
                [
                  _vm._l(_vm.itemList, function (item, index) {
                    return [
                      !item.hidden
                        ? _c(
                            "el-col",
                            {
                              key: item.model,
                              staticClass: "set_form_item",
                              staticStyle: { cursor: "pointer" },
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "set_form_item_left" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemove(item, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("mg-svg-icon", {
                                      attrs: {
                                        "icon-height": 15,
                                        "icon-width": 15,
                                        "icon-class": "mg-icon_delete",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(item.label) + " "),
                              ]),
                              _c("i", { staticClass: "el-icon-tickets" }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticStyle: { "margin-top": "16px" } }, [
        _vm._v("未展示筛选项"),
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _vm._l(_vm.itemList, function (item, index) {
            return [
              item.hidden
                ? _c(
                    "el-col",
                    {
                      key: item.model,
                      staticClass: "set_form_item",
                      staticStyle: { cursor: "pointer" },
                      attrs: { span: 6 },
                    },
                    [
                      _c("div", { staticClass: "set_form_item_left" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdd(item, index)
                              },
                            },
                          },
                          [
                            _c("mg-svg-icon", {
                              attrs: {
                                "icon-height": 15,
                                "icon-width": 15,
                                "icon-class": "mg-icon_add",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" " + _vm._s(item.label) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }