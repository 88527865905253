<style>
.main-container {
  overflow: hidden !important;
}

.app-main {
  overflow: hidden !important;
}

.app-wrapper {
  overflow: hidden !important;
}

#app {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;
  height: 16px;
}

.el-date-editor .el-input__inner {
  padding-left: 30px !important;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 140px;
  padding-left: 5px !important;
}
.el-date-editor .el-input__inner {
  max-width: 140px;
}
</style>
<template>
  <div class="thirdPartyPage-page">
    <el-row>
      <el-col class="signboard" style="padding: 10px 0 10px 0;margin-bottom: 10px;" :md="8" :lg="6" :xl="4">
        排单计划确认
      </el-col>
      <el-col class="thirdPartyPage-page-head" style="padding:5px 0;" :md="8" :lg="6" :xl="4">
        <!-- 订单中心 -->
        <el-button
          v-if="user.role_code_list.includes('selfManager') || user.role_code_list.includes('leader')"
          size="mini"
          type="primary"
          @click="handleHeadAction('resetSelect')"
        >重 新 选 择
        </el-button>
        &nbsp;
        <el-button
          v-if="user.role_code_list.includes('selfManager') || user.role_code_list.includes('leader')"
          size="mini"
          type="danger"
          @click="handleHeadAction('sendRequisition')"
          :disabled="billStatus !== 0"
        >{{billStatus === 0 ? '抛 请 购 单' : '已 抛 单'}}</el-button>
      </el-col>
    </el-row>
    <el-tabs tab-position="top" style="width: 100%; height: 100vh;overflow: scroll;" v-model="activeName">
      <el-tab-pane v-for="(item, index) in iframeArrays" :key="index + 'iframe'" :label="item.name"
                   :name="item.tabName">
        <template>
          <div v-if="item.tabName === '5'">
            <actualProd-scheduligPlan/>
          </div>
          <div v-else-if="item.tabName === '2'">
            <ArrangePluReport/>
          </div>
          <iframe v-else :src="item.iframeSrc" frameborder="0" style="width: 100%; height: 90vh;"></iframe>
        </template>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      class="add-dialog"
      title="抛 请 购 单"
      width="650px"
      :visible.sync="this.showDialog"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="80px"
          size="medium"
          class="dialog-form"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item label="到货日期" prop="wantDate">
                <el-date-picker
                  v-model="wantDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择到货日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleSubmitRequisition" :loading="this.sendRequisitionBtnLoading">抛 单</el-button>
        <el-button size="small" @click="handleCancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {urlParams} from '@/mixins';
import {handleschedulingPlanCustomAdjFinalConfirm} from '@/api/schedulingPlan';
import {handleResetArrangeStrategy} from '@/api/home';
import {handleUserInfo} from '@/api/config';
import moment from "moment/moment";
import {handleSubmitRequisition} from "../../../api/home";
import {handleProcessStatus} from "../../../api/config";

export default {
  // 排产计划
  name: 'prodCenterSchedulingPlan',
  mixins: [urlParams],
  components: {
    ActualProdScheduligPlan: () => import('../components/ActualProdScheduligPlan'),
    ArrangePluReport: () => import('../components/ArrangePluReport')
  },
  data() {
    return {
      wantDate : moment().add(1, 'month').endOf('month').format("YYYY-MM-DD"),
      user: {
        role_code_list: [],
        username: '',
        real_name: '',
        arrange_resource: false,
      },
      iframeArrays: [
        {
          name: '销售中心月单',
          tabName: '1',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E9%2594%2580%25E5%2594%25AE%25E4%25B8%25AD%25E5%25BF%2583%25E6%259C%2588%25E5%258D%2595.cpt&ref_t=design&ref_c=0607e98b-a8a1-44ba-860c-64b9ba2ce384',
        },
        {
          name: '请购单明细',
          tabName: '2',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25AF%25B7%25E8%25B4%25AD%25E5%258D%2595%25E6%2598%258E%25E7%25BB%2586.cpt&ref_t=design&ref_c=dae2a225-a7dc-4f5c-a2b0-726588f23da2',
        },
        {
          name: '生产中心产需平衡',
          tabName: '3',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E7%2594%259F%25E4%25BA%25A7%25E4%25B8%25AD%25E5%25BF%2583%25E4%25BA%25A7%25E9%259C%2580%25E5%25B9%25B3%25E8%25A1%25A1.cpt&ref_t=design&ref_c=dae2a225-a7dc-4f5c-a2b0-726588f23da2',
        },
        {
          name: '生产中心产线实际排产计划',
          tabName: '4',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E7%2594%259F%25E4%25BA%25A7%25E4%25B8%25AD%25E5%25BF%2583%25E4%25BA%25A7%25E7%25BA%25BF%25E5%25AE%259E%25E9%2599%2585%25E6%258E%2592%25E4%25BA%25A7%25E8%25AE%25A1%25E5%2588%2592.cpt&ref_t=design&ref_c=dae2a225-a7dc-4f5c-a2b0-726588f23da2',
        },
        {
          name: '实际排产计划',
          tabName: '5',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E5%25AE%259E%25E9%2599%2585%25E6%258E%2592%25E4%25BA%25A7%25E8%25AE%25A1%25E5%2588%2592.cpt&ref_t=design&ref_c=4149f041-76d2-48e3-ad15-501cfc51f417',
        },
        {
          name: '总部库存情况',
          tabName: '6',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E6%2580%25BB%25E9%2583%25A8%25E5%25BA%2593%25E5%25AD%2598%25E6%2583%2585%25E5%2586%25B5.cpt&ref_t=design&ref_c=7ef9bea4-759e-44ce-ac8d-04b2a6c37d27',
        },
        {
          name: '总部库存大于3',
          tabName: '7',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E6%2580%25BB%25E9%2583%25A8%25E5%25BA%2593%25E5%25AD%2598%25E5%25A4%25A7%25E4%25BA%258E3.cpt&ref_t=design&ref_c=eb0ff519-d716-479d-87dc-f5174f363200',
        },
        {
          name: '分公司库存情况',
          tabName: '8',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E5%2588%2586%25E5%2585%25AC%25E5%258F%25B8%25E5%25BA%2593%25E5%25AD%2598%25E6%2583%2585%25E5%2586%25B5.cpt&ref_t=design&ref_c=7ef9bea4-759e-44ce-ac8d-04b2a6c37d27',
        }
      ],
      operator: '',
      depart: '',
      isConfirm: false,
      showDialog: false,
      sendRequisitionBtnLoading : false,
      activeName: '1',
      billStatus : 0,
    }
  },
  created() {
    this.handleUrlParams().then(res => {
      this.operator = res.operator;
      this.depart = res.depart;
      this.setUser();
    })
  },
  methods: {
    setUser: async function () {
      const params = {operator: this.urlParams.operator};
      const resUser = await handleUserInfo(params);
      console.log(resUser);
      if (resUser.code === '0000') {
        const {data} = resUser;
        this.user = data;
      }

      const {data} = await handleProcessStatus(params);
      const {process_status} = data;
      this.billStatus = process_status.bill_status;
    },

    // 取消抛单
    handleCancel() {
      this.showDialog = false;
    },
    // 提交抛单
    async handleSubmitRequisition() {
      try {
        this.sendRequisitionBtnLoading = true;
        const params = {
          operator: this.operator,
          want_date: this.wantDate
        };
        const {success} = await handleSubmitRequisition(params);
        if (success) {
          this.$message.success("请购抛单成功！");
          this.showDialog = false;
          this.billStatus = false;
        }
        this.sendRequisitionBtnLoading = false;
      } catch (err) {
        console.log(err, '--------提交抛单失败');
        this.sendRequisitionBtnLoading = false;
      }
    },
    handleHeadAction(type) {
      const funcObj = {
        'confirm': async () => {
          this.$confirm('需要确认最终排单么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const params = {
              operator: this.operator
            };
            try {
              const {code, msg} = await handleschedulingPlanCustomAdjFinalConfirm(params);
              if (code === '0000') {
                this.isConfirm = true;
                return this.$message.success(msg);
              }
            } catch (err) {
              this.isConfirm = false;
              console.log(err, 'err------最终确认排单接口有误');
            }
          }).catch(() => {
            this.$message('最终确认未确认');
          });
        },
        'resetSelect': async () => {
          try {
            const params = {
              operator: this.operator,
              month: ''
            };
            const {msg, success} = await handleResetArrangeStrategy(params);
            if (success) {
              this.$router.push({
                path: '/schedulingPlan/strategySelection',
                query: {
                  operator: this.operator
                }
              });
            }
            if (!success) return this.$message.error(msg);

          } catch (err) {
            console.log(err, '--------重置排单计划失败')
          }

        },
        'sendRequisition': async () => {
          this.showDialog = true;
        }
      };
      funcObj[type]();
    }
  }
}
</script>

<style lang="scss" scoped>
.thirdPartyPage-page {
  height: 100vh;

  &-head {
    text-align: center;
    padding: 10px 0 20px 0;
  }

  ::v-deep .el-tabs__content {
    height: 100vh;
    overflow: scroll;
  }
}
</style>
