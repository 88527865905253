var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _c("GroupItem", { attrs: { list: _vm.list, type: "summary" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { color: "dodgerblue", "text-align": "center" } },
      [_c("h1", [_vm._v("APP界面预览")]), _c("p", [_vm._v("首页任务列表")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }