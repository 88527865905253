import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/request";

/**
 *
 * ----------------------------- home相关接口 -----------------------------
 */

// 加单审核预览分页查询
export function handleArrangePlanLists(data) {
    return axiosPost('/pdb_plan/plan_page', data);
}

// 排单计划进度查询
export function handleProcessStatus(data) {
    return axiosPost('/pdb_plan/process_status', data);
}

// 重置排产计划策略选择
export function handleResetArrangeStrategy(data) {
    return axiosPost('/arrange/reset_arrange_strategy', data);
}

// 更新月均计划依据
export function handleMonthAvgType(data) {
    return axiosPost('/pdb_plan/update_month_avg_type', data);
}

// 生产流程重置
export function handleResetProdFlow(data) {
    return axiosPost('/pdb_plan/reset_prod_flow', data);
}

// 提交抛单请求
export function handleSubmitRequisition(data) {
  return axiosPost('/arrange/send_requisition', data);
}



