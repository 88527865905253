<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="small"  label-width="120px" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <el-select v-model="queryForm.orgCode" filterable clearable default-first-option>
            <el-option v-for="item in queryOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="queryForm.taskName" placeholder="请输入任务名称" clearable />
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="queryForm.createDate"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="handleDateChange($event, 'create')"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="任务时间">
          <el-date-picker
            v-model="queryForm.taskDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryForm.status" clearable>
            <el-option value="3" label="已结束">已结束</el-option>
            <el-option value="2" label="已开始">已开始</el-option>
            <el-option value="1" label="未开始">未开始</el-option>
            <el-option value="0" label="草稿">草稿</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型">
          <el-select v-model="queryForm.taskRange" clearable>
            <el-option value="0" label="阶段任务">阶段任务</el-option>
            <el-option value="1" label="长期任务">长期任务</el-option>
            <el-option value="2" label="大篷车任务" />
            <el-option value="3" label="大篷车任务-新品" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="orgAuthAll" label="创建人">
          <DictSelect
            ref="org"
            type="task-creater"
            :value.sync="queryForm.createUser"
            :dictFun="dictFun"
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="small"  icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="small"  icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="small"  icon="el-icon-plus" type="primary" @click="handleAdd('add')">任务</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <el-button style="margin: 10px 0;" class="form-btn-item" size="small"  icon="el-icon-plus" type="primary" @click="handleAdd('add')">任务</el-button>

    <el-table
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      class="customer-table"
      border
           size="small"
      highlight-current-row
      @sort-change="handleSortChange"
    >
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column label="任务ID" prop="mtTaskId" width="150" show-overflow-tooltip/>
      <el-table-column label="任务名称" prop="taskName" width="200" />
      <el-table-column label="分公司名称" prop="orgName" width="100" />
      <el-table-column label="任务类型" prop="taskType" width="80">
        <template #default="{ row }">
          <span>{{ row.taskRange === "1" ? "长期任务" : (row.taskTag ? taskTagMap[row.taskTag]: "阶段任务") }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" width="80">
        <template #default="{ row }">
          <el-tag :type="$options.filters.statusFilter(row.status)">
            {{ statusMap[row.status] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="description" min-width="200" />
      <el-table-column label="任务开始日期" prop="startDate" width="120" >
        <template #default="{ row }">
            {{ !row.startDate ? "-" : parseShowTime(row.startDate,'{y}-{m}-{d}') }}
        </template>
      </el-table-column>
      <el-table-column label="任务结束日期" prop="endDate" width="120" >
        <template #default="{ row }">
          {{ !row.endDate ? "-" : parseShowTime(row.endDate,'{y}-{m}-{d}') }}
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="createUser"  width="80" />
      <el-table-column label="创建日期" prop="createTime" width="200" />
      <el-table-column label="操作" width="160" align="center" fixed="right">
        <template #default="{ row }">
          <el-row>
            <el-col v-if="buttonShow(row, 'view')" :span="8">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row, 'view')">查看</el-button>
            </el-col>
            <el-col v-if="buttonShow(row, 'edit')" :span="8">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row, 'edit')">编辑</el-button>
            </el-col>
            <el-col v-if="buttonShow(row, 'target')" :span="8">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleSetTarget(row)">目标</el-button>
            </el-col>

            <el-col v-show="buttonShow(row, 'end')" :span="8">
              <el-popconfirm
                :title="'结束后会停止任务(包括展示和数据计算),可再次编辑和重新开始'"
                @confirm="updateTaskStatus(row.mtTaskId + '/3')"
              >
                <el-button slot="reference" size="mini" type="text" style="border: 0px;">结束</el-button>
              </el-popconfirm>
            </el-col>
            <el-col v-show="buttonShow(row, 'start')" :span="8">
              <el-popconfirm
                :title="'请确认是否要开始任务(' + row.taskName + ')? '"
                @confirm="updateTaskStatus(row.mtTaskId + '/2')"
              >
                <el-button slot="reference" size="mini" type="text" style="border: 0px;">开始</el-button>
              </el-popconfirm>
            </el-col>
            <el-col v-show="buttonShow(row, 'run')" :span="8">
              <el-popconfirm
                :title="'请确认是否要执行任务(' + row.taskName + ')?'"
                @confirm="runTaskJob(row.mtTaskId)"
              >
                <el-button size="mini" type="text" style="border: 0px;">执行</el-button>
              </el-popconfirm>
            </el-col>
            <el-col v-show="buttonShow(row, 'del')" :span="8">
              <el-popconfirm
                :title="'请确认是否要删除任务(' + row.taskName + ')?'"
                @confirm="handleDelete(row)"
              >
                <el-button slot="reference" size="mini" type="text" style="border: 0px;">删除</el-button>
              </el-popconfirm>
            </el-col>
            <el-col v-if="buttonShow(row, 'jl')"  :span="8">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleSetJl(row)">激励</el-button>
            </el-col>

            <el-col v-if="buttonShow(row, 'copy')"  :span="8">
              <el-button size="mini" v-if="orgAuthAll" type="text" style="border: 0px;" @click="handleTaskCopy(row)">复制</el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="saveDialog.dialogTitle[saveTitle]" :visible.sync="saveDialog.dialogVisible" width="70%" :close-on-click-modal="false">
      <div v-if="saveTitle==='view'">
        <el-descriptions>
          <el-descriptions-item label="任务ID">{{ currentRow.mtTaskId }}</el-descriptions-item>
          <el-descriptions-item label="状态"><el-tag size="small" :type="$options.filters.statusFilter(currentRow.status)">
            {{ statusMap[currentRow.status] }}
          </el-tag></el-descriptions-item>
          <el-descriptions-item label="创建人">{{ currentRow.createUser }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ currentRow.createTime }}</el-descriptions-item>
        </el-descriptions>
        <el-divider style="margin: 12px 0px" />
        <el-descriptions title="基本信息">
          <el-descriptions-item label="任务名称">{{ currentRow.taskName }}</el-descriptions-item>
          <el-descriptions-item label="任务日期">{{ parseShowTime(currentRow.startDate,'{y}/{m}/{d}') +'至'+ parseShowTime(currentRow.endDate,'{y}/{m}/{d}')}}</el-descriptions-item>
          <el-descriptions-item label="描述">{{ currentRow.description }}</el-descriptions-item>
          <el-descriptions-item label="分公司">{{ currentRow.orgName }}</el-descriptions-item>
          <el-descriptions-item label="核销截止时间">{{ parseShowTime(currentRow.writeOffEndTime,'{y}-{m}-{d}') }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <Edit ref="edit" :type="saveTitle" :task-id.sync="currentRow.mtTaskId" :specialAuth="specialAuth" @set-size="handleSetSize" />
      <div slot="footer" style="float: left" class="dialog-footer" >
        <span>商品总数量:{{ saveDialog.skuLength }}</span>
        <span style="margin-left: 15px;">店铺总数量:{{ saveDialog.shopLength }}</span>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" :disabled="editLoading" @click="saveDialog.dialogVisible = false">取 消</el-button>
        <el-button v-if="saveTitle==='view'" :disabled="editLoading" type="primary" size="mini" @click="submitForm('view')">确 定</el-button>
        <el-button v-if="saveTitle==='add' || currentRow.status === 0" :disabled="editLoading" size="mini" @click="submitForm('save')">保 存</el-button>
        <el-button v-if="saveTitle!=='view'" :disabled="editLoading" size="mini" type="primary" @click="submitForm('commit')">提 交</el-button>
      </div>
    </el-dialog>

    <el-drawer :title="'设置目标 (' + currentRow.taskName + ')'" :visible.sync="targetDialog.visible" class="closed-header" size="90%" direction="rtl" append-to-body :close-on-click-modal="false" :wrapperClosable="false">
      <TargetV2 ref="target" :task-info="currentRow" :auth-org-codes="authOrgs" @show-commit="(val) => {targetDialog.showCommit = val}" />
      <div class="dialog-footer" style="text-align: right;position: absolute; right: 50px;">
        <el-button v-show="targetDialog.showCommit === '0'" class="form-btn-item" size="mini" type="primary" @click="() => $refs.target.handleCommitUnify()">提交</el-button>
        <el-button size="mini" type="primary" @click="targetDialog.visible = false">关 闭</el-button>
      </div>
    </el-drawer>
    <el-drawer title="任务激励设置" :visible.sync="taskJlDrawer.visible" class="closed-header" size="90%" direction="rtl" append-to-body :close-on-click-modal="false" :wrapperClosable="false">
      <JL :taskId="currentRow.mtTaskId" :import-scope="currentRow.importScope" />
      <div class="dialog-footer" style="text-align: right;position: absolute; right: 50px;">
        <el-button size="mini" type="primary" @click="taskJlDrawer.visible = false">关 闭</el-button>
      </div>
    </el-drawer>

    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
    deleteTask,
    dictSelect,
    getCurrentUser,
    pageTask,
    runTaskJob,
    saveTask,
    taskCopy,
    updateTaskStatus
} from "@/api/appTask";
import DictSelect from "@/components/DictSelect";
import Pagination from "@/components/Pagination";
import SearchFilter from "@/components/SearchFilter/index";
import { parseTime } from "@/utils";
import { MessageBox, Notification } from "element-ui";
import Edit from "./Edit";
import JL from "./Jl";
import TargetV2 from "./TargetV2";

export default {
  name: "Task",
  components: { Pagination, DictSelect, Edit, TargetV2, JL, SearchFilter},
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "",
        1: "",
        2: "primary",
        3: "danger",
        4: "info",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      taskTagMap: {
        DPC: '大篷车任务',
        DPC_NEW: '大篷车任务-新品',
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        taskName: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        createDate: undefined,
        startDate: undefined,
        endDate: undefined,
        startDateBegin: undefined,
        startDateEnd: undefined,
        endDateBegin: undefined,
        endDateEnd: undefined,
        status: undefined,
        createUser: undefined,
        taskDate: undefined,
        taskRange: undefined,
        isLeaf: '1'
      },
      loading: false,
      userOptions: [],
      orgOptions: [],
      queryOrgOptions: [],
      saveTitle: undefined,
      statusMap: {
        0: "草稿",
        1: "未开始",
        2: "已开始",
        3: "已结束",
        4: "已暂停",
      },
      currentRow: {
        taskId: ""
      },
      saveDialog: {
        dialogVisible: false,
        dialogTitle: {
          add: "新增任务",
          edit: "修改任务",
          view: "查看任务",
        },
        skuLength: 0,
        shopLength: 0,
        refresh: false,
      },
      dictFun: dictSelect,
      parseShowTime: parseTime,
      targetDialog: {
        visible: false,
        showCommit: undefined
      },
      taskJlDrawer: {
        visible: false,
      },
      taskDateRange: undefined,
      currentUser: undefined,
      editLoading: false,
      authOrgs: [],
      ifAllAuth: undefined,
      jlAuthUsers: ['wangsiyong','tongmeng','yuanzhengliang','xiajinlong','chenyouyi'],
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      orgAuthAll: false
    };
  },
  watch: {
    "saveDialog.dialogVisible": {
      handler(newVal) {
        if (!newVal) {
          this.currentRow = {};
        }
      },
      immediate: true,
      deep: true,
    },
    "targetDialog.visible": {
      handler(newVal) {
        if (!newVal) {
          this.currentRow = {};
        }
      },
      immediate: true,
      deep: true,
    }
  },
  computed: {
    specialAuth() {
      return this.currentUser && this.jlAuthUsers.indexOf(this.currentUser.userName) > -1
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      // if (res.data.length === 1) {
      //   this.queryForm.orgCode = res.data[0].value;
      // }
      this.authOrgs = res.data.map(e => e.value)
      this.orgOptions = res.data;
      if (res.data.filter(e => e.value == 'ALL').length > 0) {
        this.orgAuthAll = true
      }
      this.handleFilter();
    });
    dictSelect("ht-org").then(res => {
      this.queryOrgOptions = res.data;
      this.queryOrgOptions.splice(0, 0, { value: 'ALL', label: '全国'})
      this.handleFilter();
    });
    getCurrentUser().then(res => this.currentUser = res.data);
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let param = "";
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Date) {
            param+='&' + key + "=" + parseTime(this.queryForm[key]);
          } else if (!this.queryForm[key] instanceof Array) {
            param+='&' + key + "=" + this.queryForm[key];
          } else {
            param+='&' + key + "=" + this.queryForm[key];
          }
        }
      }
      pageTask("?" + param.substring(1)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        orgCode: undefined,
        taskName: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        startDateBegin: undefined,
        startDateEnd: undefined,
        endDateBegin: undefined,
        endDateEnd: undefined,
        createDate: undefined,
        status: undefined,
        createUser: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val, type) {
      if (type === 'create') {
        if (val) {
          this.queryForm.createTimeBegin = val[0];
          this.queryForm.createTimeEnd = val[1];
        } else {
          this.queryForm.createTimeBegin = undefined;
          this.queryForm.createTimeEnd = undefined;
        }
      } else if (type === 'start') {
        if (val) {
          this.queryForm.startDateBegin = val;
        } else {
          this.queryForm.startDateBegin = undefined;
        }
      }
      else if (type === 'end') {
        if (val) {
          this.queryForm.endDateBegin = val;
        } else {
          this.queryForm.endDateBegin = undefined;
        }
      }
    },
    handleAdd() {
      this.saveDialog.dialogVisible = true;
      this.editLoading = false;
      this.saveTitle = "add";
      this.currentRow = { taskId: undefined };
      this.$nextTick(() => {
        this.$refs.edit.reset();
      })
    },
    handleEdit(row, type) {
      this.currentRow  =row;
      this.saveDialog.dialogVisible = true;
      this.editLoading = false;
      this.saveTitle = type;
    },
    submitForm(type) {
      if (this.saveTitle === "view") {
        this.saveDialog.dialogVisible = false;
      } else {
        this.$refs.edit.$refs["form"].validate(valid => {
          if (valid) {
            const data = Object.assign({}, this.$refs.edit.form);
            if (data.shareUrl && !data.shareName) {
              Notification.warning('请填写联盟活动对应的活动名称！');
              return;
            }
            if (this.$refs.edit.form.skus.length > 10000) {
              Notification.warning('添加的商品数量最大不可超过10000');
              return;
            }
            if (this.$refs.edit.form.shops.length > 10000) {
              Notification.warning('添加的店铺数量最大不可超过10000');
              return;
            }
            const msg = this.$refs.edit.validateFormData();
            if (msg) {
              Notification.warning(msg);
              return;
            }
            if (this.$refs.edit.form.shops.length === 0 && (!this.$refs.edit.form.dcs || this.$refs.edit.form.dcs.length === 0)) {
              // this.$confirm('您还没有添加店铺，将默认视为全部店铺范围是否确认？', '提示', { type: 'warning' }).then(() => {
              //   this.confirmCommit(type);
              // }).catch(() => {
              // })
              Notification.warning('请至少添加一个店铺/店铺所属配送中心或添加一个要货配送中心');
              return;
            } else {
             this.confirmCommit(type);
            }
          }
        });
      }
    },
    confirmCommit(type) {
      this.editLoading = true;
      this.$refs.edit.form.status = type === 'commit' && this.$refs.edit.form.status === 0 ? 1 : this.$refs.edit.form.status;
      let data = Object.assign({}, this.$refs.edit.form);
      const orgData = Object.assign({}, this.$refs.edit.orgFilterMap);
      let orgDataArray = [];
      for(let key in orgData) {
        if (data.checkedOrgCodes.indexOf(key) > -1) {
          const compareDateStart = (orgData[key].compareDate && orgData[key].compareDate.length > 0 && orgData[key].selections.filter(d => d.key==='固定期').length > 0) ? parseTime(orgData[key].compareDate[0], '{y}-{m}-{d}') + ' 00:00:00': undefined;
          const compareDateEnd = (orgData[key].compareDate && orgData[key].compareDate.length > 0 && orgData[key].selections.filter(d => d.key==='固定期').length > 0) ? parseTime(orgData[key].compareDate[1], '{y}-{m}-{d}') + ' 23:59:59' : undefined;

          const ksCompareDateStart = (orgData[key].ksCompareDate && orgData[key].ksCompareDate.length > 0 && orgData[key].ksCompareChoose === '1') ? parseTime(orgData[key].ksCompareDate[0], '{y}-{m}-{d}') + ' 00:00:00': undefined;
          const ksCompareDateEnd = (orgData[key].ksCompareDate && orgData[key].ksCompareDate.length > 0 && orgData[key].ksCompareChoose === '1') ? parseTime(orgData[key].ksCompareDate[1], '{y}-{m}-{d}') + ' 23:59:59' : undefined;
          orgDataArray.push({ orgCode: key, sort: orgData[key].sort, queryConfig: orgData[key].queryConfig, appShow: orgData[key].appShow, ifBatchAddCart: orgData[key].ifBatchAddCart,
            compareDateStart: compareDateStart, compareDateEnd: compareDateEnd, customName: orgData[key].customName, ksCompareStartTime: ksCompareDateStart, ksCompareEndTime: ksCompareDateEnd });
        }
      }
      if (!(data.checkedOrgCodes instanceof Array)) {
        data.checkedOrgCodes = [data.checkedOrgCodes];
      }
      if (data.writeOffEndTime) {
        data.writeOffEndTime = parseTime(new Date(data.writeOffEndTime).getTime(), '{y}-{m}-{d}') + ' 23:59:59';
      }
      data.confOrgList = orgDataArray;
      saveTask(data).then(res => {
        this.editLoading = false;
        if (res.code === 200) {
          if (res.data) {
            this.noticeDialog.msg = "以下商品包不在当前任务的分公司及配送中心适用范围之内，请确认修改后重新提交。具体如下：" + res.data;
            this.noticeDialog.visible = true;
          } else {
            if ((!data.mtTaskId || data.runFlow) && data.status!==0) {
              MessageBox.alert('最多两小时后，你可在聚宝盆APP查看相关任务数据！', '操作成功');
            } else {
              MessageBox.alert('更新成功！', '操作成功');
            }
            this.saveDialog.dialogVisible = false;
            this.handleFilter();
          }
        }
      });
    },
    handleDelete(row) {
      deleteTask(row.mtTaskId).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      });
    },
    handleSetSize(skuLength, shopLength) {
      this.saveDialog.skuLength = skuLength;
      this.saveDialog.shopLength = shopLength;
    },
    updateTaskStatus(data) {
      updateTaskStatus(data).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    runTaskJob(data) {
      runTaskJob(data).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    handleSetTarget(row) {
      this.currentRow = row;
      this.targetDialog.visible = true;
      if (this.$refs.target) {
        this.$refs.target.handleQuery();
      }
    },
    getALlAuth() {
      if (this.ifAllAuth!==undefined) {
        return this.ifAllAuth;
      }
      this.$nextTick(() => {
        if (this.orgOptions.length > 0) {
          this.ifAllAuth = this.orgOptions.filter(e => e.value === 'ALL').length > 0 ? true: false;
        }
        return this.ifAllAuth;
      })
    },
    buttonShow(row, type) {
      switch(type) {
        case 'view':
          return row.status !== 0;
        case 'edit':
          return this.authOrgs.indexOf(row.orgCode) > -1;
        case 'start':
          return this.authOrgs.indexOf(row.orgCode) > -1 && (row.status === 1 || row.status === 4 || row.status === 3);
        case 'end':
          return this.authOrgs.indexOf(row.orgCode) > -1 && (row.status === 2 || row.status === 4);
        case 'pause':
          return this.authOrgs.indexOf(row.orgCode) > -1 && row.status === 2;
        case 'del':
          return this.authOrgs.indexOf(row.orgCode) > -1;
        case 'run':
          return this.authOrgs.indexOf(row.orgCode) > -1 && row.status !== 0 && row.status !== 3;
        case 'target':
          return (row.status !== 0 && row.status !== 3) || (row.taskName!='经典畅销238(上柜行动)' && row.orgCode!='ALL');
        case 'org-conf':
          return row.status !== 3;
        case 'jl':
          return (this.authOrgs.indexOf('ALL') > -1 && row.orgCode==='ALL') || row.orgCode!='ALL';
        case 'copy':
          return this.authOrgs.indexOf(row.orgCode) > -1;
      }
    },
    get232Show(row) {
      return ((row.if232 === 1 && this.getALlAuth()) || row.if232 !== 1);
    },
    handleSetJl(row) {
      this.currentRow = row;
      this.taskJlDrawer.visible = true;
    },
    handleTaskCopy(row) {
      this.loading = true;
      taskCopy(row.mtTaskId).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   padding-top: 10px;
//   padding-bottom: 5px;
// }
// .checkNumber {
//   font-size: 16px;
//   margin-bottom: 5px;
// }
// ::v-deep .el-divider--horizontal {
//   margin: 8px 0px;
// }
// ::v-deep .el-drawer__header {
//   margin-bottom: 2px;
//   padding: 2px;
//   margin-top: 20px;
// }
.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
</style>
