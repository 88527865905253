var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isOpen,
        title: "表格字段设置",
        width: "1000px",
        "close-on-click-modal": false,
        "append-to-body": _vm.appendToBody,
      },
      on: { close: _vm.handleClose },
    },
    [
      _c("div", { staticClass: "columns-config" }, [
        _c(
          "div",
          { staticClass: "show-columns" },
          [
            _c("div", { staticClass: "header" }, [
              _c("span", [_vm._v("可拖动表格字段进行排序")]),
              _c(
                "span",
                {
                  staticClass: "reset-btn",
                  class: { active: _vm.isDraggable },
                  on: { click: _vm.handleReset },
                },
                [
                  _vm._v(" 重置 "),
                  _c("i", { staticClass: "iconfont mg-icon_shuaxinshuju" }),
                ]
              ),
            ]),
            _c(
              "draggable",
              _vm._b(
                {
                  staticClass: "drag-group",
                  attrs: { group: "columns" },
                  on: { update: _vm.handleDragUpdate },
                  model: {
                    value: _vm.showColumns,
                    callback: function ($$v) {
                      _vm.showColumns = $$v
                    },
                    expression: "showColumns",
                  },
                },
                "draggable",
                _vm.dragOptions,
                false
              ),
              _vm._l(_vm.showColumns, function (col) {
                return _c(
                  "span",
                  {
                    key: col.prop || col.type,
                    staticClass: "drag-group-item",
                    class: { fixed: col.fixed },
                  },
                  [
                    _vm._v(" " + _vm._s(col.label) + " "),
                    col.fixed
                      ? _c("i", {
                          staticClass: "iconfont mg-icon-bianqianguding",
                        })
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "column-count" }, [
            _vm._v("列字段总数:" + _vm._s(_vm.showColumns.length)),
          ]),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                disabled: _vm.columns.length === 0,
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }