var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "schedulingPlan-iframe-page" }, [
    _c(
      "div",
      { staticClass: "schedulingPlan-iframe-search" },
      [
        _c(
          "el-form",
          {
            ref: "actualProdPlan-form",
            staticClass: "main-search",
            attrs: {
              size: "mini",
              "label-width": "auto",
              inline: true,
              model: _vm.form,
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "年月", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入年月(yyyyMM)" },
                  model: {
                    value: _vm.form.plan_month,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "plan_month", $$v)
                    },
                    expression: "form.plan_month",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "生产类型", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.prod_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prod_type", $$v)
                      },
                      expression: "form.prod_type",
                    },
                  },
                  _vm._l(_vm.options.prodTypeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "产品等级", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", multiple: "" },
                    model: {
                      value: _vm.form.grade,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "grade", $$v)
                      },
                      expression: "form.grade",
                    },
                  },
                  _vm._l(_vm.options.productGradeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "大类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleOptionChange($event, "max")
                      },
                    },
                    model: {
                      value: _vm.form.productFirstCategorie,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productFirstCategorie", $$v)
                      },
                      expression: "form.productFirstCategorie",
                    },
                  },
                  _vm._l(
                    _vm.options.productMaxCategorieOtions,
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "中类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleOptionChange($event, "mid")
                      },
                    },
                    model: {
                      value: _vm.form.productMiddleCategorie,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productMiddleCategorie", $$v)
                      },
                      expression: "form.productMiddleCategorie",
                    },
                  },
                  _vm._l(
                    _vm.options.productMidCategorieOtions,
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "小类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleOptionChange($event, "min")
                      },
                    },
                    model: {
                      value: _vm.form.productLittleCategorie,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productLittleCategorie", $$v)
                      },
                      expression: "form.productLittleCategorie",
                    },
                  },
                  _vm._l(
                    _vm.options.productMinCategorieOtions,
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "产品赛道", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "90px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.race_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "race_name", $$v)
                      },
                      expression: "form.race_name",
                    },
                  },
                  _vm._l(_vm.options.raceNameLists, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "原始料号", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入原始料号" },
                  model: {
                    value: _vm.form.material_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "material_code", $$v)
                    },
                    expression: "form.material_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "是否有客订", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.bo_kd_plu_count_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bo_kd_plu_count_type", $$v)
                      },
                      expression: "form.bo_kd_plu_count_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "是", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "否", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "预排产量分类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.arrange_count_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "arrange_count_type", $$v)
                      },
                      expression: "form.arrange_count_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 0,
                      attrs: { label: "全部", value: 0 },
                    }),
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "未下单", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "已下单", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "form-btn-item",
                attrs: { size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.handleSearchAction("search")
                  },
                },
              },
              [_vm._v("查 询 ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "form-btn-item",
                attrs: { size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.handleSearchAction("reset")
                  },
                },
              },
              [_vm._v("重 置 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  type: "primary",
                  loading: _vm.btnStatus.isExportLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTableHeadAction("export")
                  },
                },
              },
              [_vm._v("导 出 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "schedulingPlan-iframe-content" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableStatus.isLoading,
                expression: "tableStatus.isLoading",
              },
            ],
            ref: "multipleTable",
            staticClass: "table",
            attrs: {
              stripe: "",
              border: "",
              height: "500",
              size: "mini",
              data: _vm.lists,
            },
          },
          [
            _vm._l(_vm.tableObj.arrangePluReportHead, function (item, index) {
              return [
                ["plan_month", "prod_type", "material_code"].includes(item.prop)
                  ? _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            fixed: "left",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    )
                  : _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    ),
              ]
            }),
          ],
          2
        ),
        _c("v-pagination", {
          attrs: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            currentPage: _vm.currentPage,
          },
          on: { pageChange: _vm.pagingChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }