var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    !_vm.taskId
      ? _c(
          "div",
          {
            staticStyle: {
              "font-size": "18px",
              "font-weight": "700",
              "text-align": "center",
              cursor: "pointer",
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              height: "100%",
              "background-color": "rgb(245 249 252)",
            },
          },
          [
            _c(
              "el-row",
              [
                _c("el-col", [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return (() =>
                            _vm.handleChangeTask(
                              _vm.type,
                              "edit",
                              "task"
                            )).apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        staticStyle: { color: "#1f85ee" },
                      }),
                      _c("span", { staticStyle: { color: "#1f85ee" } }, [
                        _vm._v("添加任务/合集"),
                      ]),
                    ]
                  ),
                ]),
                _vm.showType === "home"
                  ? _c("el-col", { staticStyle: { "padding-top": "20px" } }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return (() =>
                                _vm.handleChangeTask(
                                  _vm.type,
                                  "edit",
                                  "group"
                                )).apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            staticStyle: { color: "#1f85ee" },
                          }),
                          _c("span", { staticStyle: { color: "#1f85ee" } }, [
                            _vm._v("创建合集"),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.taskId
      ? _c(
          "div",
          {
            staticClass: "taskBox",
            staticStyle: { cursor: "pointer" },
            attrs: { tabindex: "1" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return (() => _vm.$emit("setCurrentTask", _vm.taskId)).apply(
                  null,
                  arguments
                )
              },
            },
          },
          [
            _c("TaskItem", {
              attrs: { task: _vm.task, "module-type": _vm.type },
            }),
            _vm.currentTaskId === _vm.taskId
              ? _c(
                  "div",
                  {
                    class:
                      _vm.index === 0
                        ? _vm.type === "horizontal" || _vm.type === "highRowOne"
                          ? "popover bottom"
                          : "popover right"
                        : "popover left",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "border-bottom": "1px solid",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (() =>
                                  _vm.handleChangeTask(
                                    _vm.type,
                                    "edit",
                                    undefined
                                  )).apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.task && _vm.task.isLeaf === 0
                                  ? "编辑入口样式"
                                  : "编辑样式"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.task && _vm.task.isLeaf === 0
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "border-bottom": "1px solid",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.handleChangeTask(
                                        _vm.type,
                                        "detail",
                                        undefined
                                      )).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("编辑合集内的任务")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.shopAll
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "border-bottom": "1px solid",
                            },
                            attrs: { title: "同步任务配置至其他分公司" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.handleChangeTask(
                                        _vm.type,
                                        "sync",
                                        undefined
                                      )).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("同步")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.task && _vm.task.isLeaf === 0
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "border-bottom": "1px solid",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.handleChangeTask(
                                        _vm.type,
                                        "target",
                                        undefined
                                      )).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("合集目标")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "border-bottom": "1px solid",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return (() =>
                                  _vm.handleChangeTask(
                                    _vm.type,
                                    "remove",
                                    undefined
                                  )).apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("移除")]
                        ),
                      ],
                      1
                    ),
                    _vm.task && _vm.task.isLeaf === 0
                      ? _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.handleChangeTask(
                                        _vm.type,
                                        "delete",
                                        undefined
                                      )).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("解散")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }