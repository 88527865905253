var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", "default-first-option": "" },
                      on: { change: _vm.handleQuery },
                      model: {
                        value: _vm.queryForm.orgCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "orgCode", $$v)
                        },
                        expression: "queryForm.orgCode",
                      },
                    },
                    _vm._l(_vm.queryOrgOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          value: item.value,
                          label: item.value + "-" + item.label,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.queryForm.orgCode
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-search",
                            type: "primary",
                          },
                          on: { click: _vm.handleFilter },
                        },
                        [_vm._v("查询")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", disabled: _vm.loading },
              on: { click: _vm.saveModuleTask },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _vm._m(0),
      _vm.showView
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            _vm._l(_vm.taskModuleList, function (module, index) {
              return _c("div", { key: module.title }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "box-shadow": "0 2px 12px 0 rgba(0,0,0,0.73)",
                      border: "1px dashed rgba(0,0,0,0)",
                      padding: "2px 3px",
                      "margin-top": "8px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "groupBox",
                        staticStyle: {
                          "margin-top": "5px",
                          "background-color": "white",
                        },
                      },
                      [
                        _c(
                          "el-container",
                          {
                            staticStyle: {
                              "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                              border: "1px dashed #f5dae0",
                            },
                          },
                          [
                            _c(
                              "el-main",
                              {
                                staticStyle: {
                                  "padding-top": "0px",
                                  overflow: "unset",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "grid-template-areas": "'a'",
                                      display: "grid",
                                      "min-height": "40px",
                                      gap: "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "grid-area": "a" } },
                                      [
                                        _c("el-divider", [
                                          _vm._v(_vm._s(module.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.taskModuleDataSortedf(index),
                      function (item, moduleIndex) {
                        return _c(
                          "div",
                          {
                            key: item.title,
                            staticClass: "groupBox",
                            staticStyle: {
                              "margin-top": "5px",
                              "background-color": "white",
                            },
                          },
                          [
                            _c(
                              "el-container",
                              {
                                staticStyle: {
                                  "box-shadow":
                                    "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                                  border: "1px dashed #f5dae0",
                                },
                              },
                              [
                                _c(
                                  "el-main",
                                  {
                                    staticStyle: {
                                      "padding-top": "0px",
                                      overflow: "unset",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "flex-end",
                                          "margin-top": "-3px",
                                          height: "auto",
                                        },
                                      },
                                      [
                                        _vm.shopAll &&
                                        module.taskModuleType === "ALL"
                                          ? _c("el-button", {
                                              attrs: {
                                                title: "同步模块配置",
                                                size: "mini",
                                                type: "text",
                                                icon: "el-icon-refresh",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleSyncDialog(
                                                    item.title
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "text",
                                            icon: "el-icon-upload2",
                                            disabled: moduleIndex === 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleModuleMove(
                                                item.title,
                                                "up",
                                                index
                                              )
                                            },
                                          },
                                        }),
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "text",
                                            icon: "el-icon-download",
                                            disabled:
                                              moduleIndex ===
                                              _vm.taskModuleList[index]
                                                .taskModuleData.length -
                                                1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleModuleMove(
                                                item.title,
                                                "down",
                                                index
                                              )
                                            },
                                          },
                                        }),
                                        _vm.shopAll ||
                                        module.taskModuleType !== "ALL"
                                          ? _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                                icon: "el-icon-delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteModule(
                                                    item.title,
                                                    index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("ModuleItem", {
                                      attrs: {
                                        title: item.title,
                                        list: item.list,
                                        moduleTasks: _vm.allTaskList,
                                        type: item.type,
                                        currentTask: _vm.currentTask,
                                        shopAll:
                                          _vm.shopAll &&
                                          module.taskModuleType === "ALL",
                                      },
                                      on: {
                                        setCurrentTask: (taskId) => {
                                          if (
                                            _vm.shopAll ||
                                            module.taskModuleType !== "ALL"
                                          ) {
                                            if (_vm.currentTask === taskId) {
                                              _vm.currentTask = undefined
                                            } else {
                                              _vm.currentTask = taskId
                                            }
                                          }
                                        },
                                        handleChangeTask: (
                                          type,
                                          action,
                                          insideIndex,
                                          taskType
                                        ) =>
                                          _vm.handleChangeTask(
                                            index,
                                            moduleIndex,
                                            type,
                                            insideIndex,
                                            _vm.allTaskList,
                                            action,
                                            taskType
                                          ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "groupBox",
                        staticStyle: {
                          "margin-top": "5px",
                          "background-color": "white",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "700",
                              "text-align": "center",
                              cursor: "pointer",
                              overflow: "unset",
                              "background-color": "#ffeff2",
                              "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                              border: "1px dashed #f5dae0",
                              padding: "5px",
                            },
                            on: {
                              click: () =>
                                _vm.handleAddModule(
                                  index,
                                  module.taskModuleType
                                ),
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-plus",
                              staticStyle: { color: "red" },
                            }),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(" 添加模块"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.drawer.title,
            visible: _vm.drawer.visible,
            direction: "ltr",
            "custom-class": "drawerClass",
            size: "100%",
            "show-close": false,
            wrapperClosable: false,
            "modal-append-to-body": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "visible", $event)
            },
          },
        },
        [
          _vm.drawer.action === "edit"
            ? _c("TaskContentV2", {
                ref: "taskContent",
                attrs: {
                  templateType: _vm.drawer.templateType,
                  form: _vm.drawer.form,
                  orgCode: _vm.queryForm.orgCode,
                  tasks: _vm.drawer.tasks,
                  isGroup: _vm.drawer.isGroup,
                },
              })
            : _vm._e(),
          _vm.drawer.action === "detail"
            ? _c("taskGroupDetailV2", {
                ref: "taskGroupDetail",
                attrs: {
                  timestamp: new Date().getTime(),
                  orgCode: _vm.queryForm.orgCode,
                  taskList: _vm.drawer.tasks,
                  form: _vm.drawer.form,
                  childTasks: _vm.drawer.childTasks,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                width: "200px",
                position: "absolute",
                bottom: "150px",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.confirm } },
                [_vm._v("确 认")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.drawer.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "closed-header",
          attrs: {
            title:
              "设置目标 (" + _vm.targetDialog.form.taskName
                ? _vm.targetDialog.form.taskName
                : "" + ")",
            visible: _vm.targetDialog.visible,
            size: "90%",
            direction: "rtl",
            "append-to-body": "",
            "close-on-click-modal": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.targetDialog, "visible", $event)
            },
          },
        },
        [
          _vm.queryForm.orgCode
            ? _c("TargetV2", {
                ref: "target",
                attrs: {
                  "task-info": _vm.targetDialog.form,
                  "auth-org-codes": [_vm.queryForm.orgCode],
                },
                on: {
                  "show-commit": (val) => {
                    _vm.targetDialog.showCommit = val
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.targetDialog.showCommit === "0",
                      expression: "targetDialog.showCommit === '0'",
                    },
                  ],
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: () => _vm.$refs.target.handleCommitUnify() },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.targetDialog.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步",
            visible: _vm.syncDialog.visible,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.syncDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "common-form",
                  attrs: {
                    size: "mini",
                    inline: "",
                    rules: _vm.syncDialog.rules,
                    "label-width": "160px",
                    model: _vm.syncDialog.form,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "分公司" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                    multiple: "",
                                    "collapse-tags": true,
                                  },
                                  model: {
                                    value: _vm.syncDialog.form.targetOrgCodes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.syncDialog.form,
                                        "targetOrgCodes",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "syncDialog.form.targetOrgCodes",
                                  },
                                },
                                _vm._l(
                                  _vm.exceptCurrentOrgOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.value + "-" + item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: () => {
                                      _vm.syncDialog.form.targetOrgCodes =
                                        [].concat(
                                          _vm.exceptCurrentOrgOptions.map(
                                            (e) => e.value
                                          )
                                        )
                                    },
                                  },
                                },
                                [_vm._v("全选")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.syncDialog.form.moduleName
                        ? _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "仅同步商品图" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.syncDialog.form.singleSkuImage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.syncDialog.form,
                                            "singleSkuImage",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "syncDialog.form.singleSkuImage",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("否")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("是")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { margin: "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSync },
                    },
                    [_vm._v("同步")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.syncDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { color: "dodgerblue", "text-align": "center" } },
      [_c("h1", [_vm._v("APP界面预览")]), _c("p", [_vm._v("首页任务列表")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }