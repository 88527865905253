<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter('hand')"  @reset="resetQuery" @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item label="关键字">
          <el-input v-model="queryForm.topicName" clearable placeholder="请输入话题标题关键字" />
        </el-form-item>
        <el-form-item v-if="authOrgOptions.length > 1" label="话题所属分公司">
          <el-select v-model="queryForm.authOrgCode" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="话题状态" >
          <el-select v-model="queryForm.topicStatus">
            <el-option label="全部" value="ALL" />
            <el-option label="未审核" value="P" />
            <el-option label="已通过" value="Q" />
            <el-option label="已拒绝" value="A" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属板块">
          <el-select v-model="queryForm.topicCollectionName" filterable>
            <el-option v-for="item in topicCollectionNameOptions" :key="item" :value="item" :label="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否推荐" >
          <el-select v-model="queryForm.ifRecommend">
            <el-option label="全部" value="ALL" />
            <el-option label="推荐" value="1" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
          <el-button class="form-btn-item" size="mini" type="success" @click="handleBatchTopicStatusUpdate('Q')">批量通过</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleBatchTopicStatusUpdate('Q')">批量通过</el-button>
    <el-table
      style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
      class="customer-table"
      border
           size="small"
      v-loading="loading"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90"  :selectable="(row, index) => {
          return computeButtonShow(row.topicStatus, '已通过');
      }" />
      <el-table-column label="话题ID" prop="topicId" width="230"  />
      <el-table-column label="话题标题" prop="topicName" width="200"  />
      <el-table-column label="话题内容" prop="topicContent" width="500"  >
        <template slot-scope="{ row }">
          <div style="text-align: left">
            <span>{{row.topicContent}}</span>
          </div>
          <div style="text-align: left;font-size: 10px;color: #C5C5C5" v-for="(item) in row.modelNames" :key="item">
            <span>商品：{{ item }}</span>
          </div>
          <div style="text-align: left;font-size: 10px;color: #C5C5C5">
            <span v-if="row.taskName && row.taskName !==''">任务：{{row.taskName}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="nickName" width="100" />
      <el-table-column label="分公司" prop="orgAuthName" width="100" />
      <el-table-column label="所属板块" prop="topicCollectionName" width="200" />
      <el-table-column label="更新时间" prop="createTime" width="140" />
      <el-table-column label="帖子数" prop="experienceCount" width="100"  />
      <el-table-column label="浏览量" prop="viewCount" width="100"  />
      <el-table-column label="评论" prop="answerCount" width="100"  />
      <el-table-column label="点赞" prop="likeCount" width="100" />
      <el-table-column label="收藏" prop="collectCount" width="100" />
      <el-table-column label="状态" prop="topicStatus" width="150"  />
      <el-table-column label="类型" prop="type" width="100"  />
      <el-table-column label="操作" width="250" align="center" fixed="right">
        <template #default="{ row }">
          <el-button size="mini" v-if="row.orgAuthCode==queryForm.authOrgCode || authOrgAll" :disabled="row.topicStatus === '已通过'" type="text" style="border: 0px;" @click="handleTopicStatusUpdate('Q', row)">通过</el-button>
          <el-button size="mini" v-if="row.orgAuthCode==queryForm.authOrgCode || authOrgAll" :disabled="row.topicStatus === '已拒绝'" type="text" style="border: 0px;" @click="handleTopicStatusUpdate('A', row)">不通过</el-button>
          <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.recommendFlag === '1'" type="text" style="border: 0px;" @click="handleTopicRecommendUpdate('0', row)">取消推荐</el-button>
          <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.recommendFlag === '0'" type="text" style="border: 0px;" @click="handleTopicRecommendUpdate('1', row)">设为推荐</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
</el-card>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  auditBatchHx, batchUpdateTopicStatus,
  dictSelect,
  queryPlateTitleList,
  queryTopicPageList, updateTopicRecommend, updateTopicStatus
} from "@/api/appTask";
import { Notification } from "element-ui";
import OssUpload from "@/components/OssUpload";
import {deepClone} from "../../../utils";
import SearchFilter from "@/components/SearchFilter/index";
export default {
  name: "BoostPacket",
  components: { Pagination, OssUpload,ImportDialog: () => import("@/components/ImportDialog"), SearchFilter },
  data() {
    return {
      deepClone,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        topicName: undefined,
        topicStatus: 'ALL',
        ifRecommend: 'ALL',
        topicCollectionName: '全部',
        authOrgCode: '',
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      authOrgOptions: [],
      authOrg: [],
      authOrgCodes: [],
      noticeDialog: {
        visible: false,
        msg: undefined
      },
      jbpShareUrl: '#/questionnaire/detail?id=',
      topicCollectionNameOptions: [],
    };
  },
  watch: {
    "saveDialog.form.orgRange": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
      },
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrg = res.data;
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = this.authOrg.filter(e => e.value!=='ALL');
      if (res.data.length !== this.authOrgOptions.length) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.authOrgCode = this.authOrgOptions[0].value;
      }
      this.handleFilter();
    });

    queryPlateTitleList().then(res => {
      this.topicCollectionNameOptions = res.data;
      console.log("topicCollectionNameOptions===",this.topicCollectionNameOptions)
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);
      queryTopicPageList(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        topicName: undefined,
        topicStatus: 'ALL',
        ifRecommend: 'ALL',
        topicCollectionName: '全部',
        authOrgCode: '',
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleTopicStatusUpdate(topicStatus,row) {
      this.$confirm('请确认是否更改话题状态！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          topicId: row.topicId,
          topicStatus: topicStatus,
        }
        updateTopicStatus(parameters).then(res => {
          if (res.code===200) {
            Notification.success("更新话题状态成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleTopicRecommendUpdate(ifRecommend,row) {
      this.$confirm('请确认是否更改推荐状态！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          topicId: row.topicId,
          ifRecommend: ifRecommend,
        }
        updateTopicRecommend(parameters).then(res => {
          if (res.code===200) {
            Notification.success("更新是否推荐成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后不会保存当前修改，请确认是否关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleDictChange(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          if (this.authOrgOptions.length === 0) {
            return;
          }
          if (this.authOrgOptions.length > 2) {
            if (oldVal && oldVal.length > 1) {
              if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.saveDialog.form.orgRange.filter(e => e !== 'ALL');
              } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = [];
              } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            } else {
              if (val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (val.indexOf('ALL') < -1 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            }
          }
        })
      }
    },
    handleBatchTopicStatusUpdate(topicStatus) {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少选择一条正确的记录！");
        return;
      }
      let data = deepClone(this.pageData.selections);
      if (topicStatus === 'Q') {
        if (data.filter(e => e.topicStatus !=='已通过').length === 0) {
          Notification.warning("请至少选择一条正确的记录，已通过的不可重新通过！");
          return;
        }
        data = data.filter(e => e.topicStatus !=='已通过');
      }
      this.$confirm('请确认是否批量通过勾选的' + data.length + '条数据！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          topicIds: data.map(e => e.topicId),
          topicStatus: topicStatus,
        }
        batchUpdateTopicStatus(parameters).then(res => {
          if (res.code===200) {
            Notification.success("操作成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    computeButtonShow(status, type) {
      if (status !== type) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
