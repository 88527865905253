var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc,shop",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          clearable: _vm.orgAllAuth,
                          init: true,
                          "init-options": this.options.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          multiple: "",
                          "collapse-tags": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          change: function ($event) {
                            _vm.dictParam = Object.assign({}, _vm.queryForm)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺" } },
                    [
                      _c("DictSelect", {
                        ref: "shop",
                        attrs: {
                          type: "shop",
                          value: _vm.queryForm.shopCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          remote: "",
                          "init-props": { label: "label", value: "fullCode" },
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "shopCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status",
                          },
                        },
                        _vm._l(_vm.options.statusOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提交时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.queryForm.createTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "createTimes", $$v)
                          },
                          expression: "queryForm.createTimes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.queryForm.updateTimes,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "updateTimes", $$v)
                          },
                          expression: "queryForm.updateTimes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _vm.orgAllAuth
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleBatchAudit("1")
                    },
                  },
                },
                [_vm._v("批量通过")]
              )
            : _vm._e(),
          _vm.orgAllAuth
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleBatchAudit("2")
                    },
                  },
                },
                [_vm._v("批量拒绝")]
              )
            : _vm._e(),
          !_vm.orgAllAuth
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.handleBatchAudit("3")
                    },
                  },
                },
                [_vm._v("批量撤回")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出")]
          ),
          _c("span", { staticStyle: { "margin-left": "10px", color: "red" } }, [
            _c("span", [_vm._v("重点终端(未审核):")]),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-left": "3px" } }, [
              _vm._v("增加: "),
              _c("strong", [_vm._v(_vm._s(_vm.summary.addNum))]),
              _vm._v(" 条"),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-left": "3px" } }, [
              _vm._v("删除: "),
              _c("strong", [_vm._v(_vm._s(_vm.summary.reduceNum))]),
              _vm._v(" 条"),
            ]),
            _c("span", { staticStyle: { "margin-left": "3px" } }, [
              _vm._v(
                _vm._s(_vm.summary.realAddNum < 0 ? "合计减少:" : "合计增加:") +
                  " "
              ),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm.summary.realAddNum < 0
                      ? 0 - _vm.summary.realAddNum
                      : _vm.summary.realAddNum
                  )
                ),
              ]),
              _vm._v("家"),
            ]),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "50",
                  selectable: (row, index) => {
                    return row.status === 0
                  },
                },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "shop", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属配送中心", prop: "dcName", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.dcCode +
                                (row.dcCode !== row.beforeDcCode ||
                                row.shopCode !== row.beforeShopCode
                                  ? "(" + row.beforeDcCode + ")"
                                  : "") +
                                "-" +
                                row.dcName
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "店铺", prop: "shop", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.shopCode +
                                (row.dcCode !== row.beforeDcCode ||
                                row.shopCode !== row.beforeShopCode
                                  ? "(" + row.beforeShopCode + ")"
                                  : "") +
                                "-" +
                                row.shopName
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交人", prop: "createUser", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.createUser
                                ? row.createUser +
                                    (row.createUserName
                                      ? "-" + row.createUserName
                                      : "")
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "审核状态", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.$options.filters.statusFilter(
                                row.status
                              ),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.options.statusOptions.find(
                                    (e) => e.value === row.status
                                  ).label
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核人", prop: "updateUser", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.updateUser
                                ? row.updateUser +
                                    (row.updateUserName
                                      ? "-" + row.updateUserName
                                      : "")
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核时间", prop: "updateTime", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "140",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _vm.orgAllAuth && row.status === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAudit(row, "audit")
                                      },
                                    },
                                  },
                                  [_vm._v("审核")]
                                )
                              : _vm._e(),
                            !_vm.orgAllAuth && row.status === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAudit(row, "withdraw")
                                      },
                                    },
                                  },
                                  [_vm._v("撤回")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(row, "view")
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialog.type === "view" ? "查看" : "审核",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "800px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.editDialog.rules,
                "label-width": "160px",
                model: _vm.editDialog.form,
              },
            },
            [
              _c("el-row", { staticClass: "common-form-row" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "10px" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "分公司", prop: "orgCode" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.editDialog.form.orgCode +
                                    "-" +
                                    _vm.editDialog.form.orgName
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "配送中心", prop: "dcCode" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.editDialog.form.dcCode +
                                    (_vm.editDialog.form.dcCode !=
                                      _vm.editDialog.form.beforeDcCode ||
                                    _vm.editDialog.form.beforeShopCode !=
                                      _vm.editDialog.form.beforeShopCode
                                      ? "(" +
                                        _vm.editDialog.form.beforeDcCode +
                                        ")"
                                      : "") +
                                    "-" +
                                    _vm.editDialog.form.dcName
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "店铺", prop: "shopCode" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.editDialog.form.shopCode +
                                    (_vm.editDialog.form.dcCode !=
                                      _vm.editDialog.form.beforeDcCode ||
                                    _vm.editDialog.form.beforeShopCode !=
                                      _vm.editDialog.form.beforeShopCode
                                      ? "(" +
                                        _vm.editDialog.form.beforeShopCode +
                                        ")"
                                      : "") +
                                    "-" +
                                    _vm.editDialog.form.shopName
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { staticClass: "form-label-class" }, [
                      _vm._v("审核前数据"),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否重点店铺",
                              prop: "ifOptimumShop",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.editDialog.form.oldIfOptimumShop === "1"
                                    ? "是"
                                    : "否"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "终端容量标签",
                              prop: "shopCapacity",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.editDialog.form.oldShopCapacity)
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { staticClass: "form-label-class" }, [
                      _vm._v("申请变更数据"),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否重点店铺" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.editDialog.form.ifOptimumShop === "1"
                                    ? "是"
                                    : "否"
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "终端容量标签" } },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.editDialog.form.shopCapacity)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-col", { staticClass: "form-label-class" }, [
                      _vm._v("审核员变更数据"),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否重点店铺" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "" },
                                on: {
                                  change: () =>
                                    (_vm.editDialog.form.auditShopCapacity =
                                      undefined),
                                },
                                model: {
                                  value: _vm.editDialog.form.auditIfOptimumShop,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editDialog.form,
                                      "auditIfOptimumShop",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editDialog.form.auditIfOptimumShop",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "是", value: "1" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "否", value: "0" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "终端容量标签" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "", filterable: "" },
                                model: {
                                  value: _vm.editDialog.form.auditShopCapacity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editDialog.form,
                                      "auditShopCapacity",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editDialog.form.auditShopCapacity",
                                },
                              },
                              _vm._l(_vm.shopCapacities, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { lable: item, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "备注",
                              prop: "oldIfOptimumShop",
                              "label-width": "120px",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { clearable: "", type: "textarea" },
                              model: {
                                value: _vm.editDialog.form.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editDialog.form, "remark", $$v)
                                },
                                expression: "editDialog.form.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "提交人", prop: "name" } },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.editDialog.form.createUser)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "提交时间", prop: "name" } },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.editDialog.form.createTime)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.editDialog.form.status !== 0
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "审核人", prop: "name" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.editDialog.form.updateUser)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editDialog.form.status !== 0
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "审核时间", prop: "name" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.editDialog.form.updateTime)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          !_vm.editDialog.disabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.editDialog.type === "audit" && _vm.orgAllAuth
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAudit(1)
                            },
                          },
                        },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                  _vm.editDialog.type === "audit" && _vm.orgAllAuth
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAudit(2)
                            },
                          },
                        },
                        [_vm._v("审核不通过")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }