var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.task && _vm.task.configJson
    ? _c(
        "div",
        [
          _vm.moduleType === "big"
            ? _c("TaskBig", {
                attrs: { "class-name": "group", task: _vm.task },
              })
            : _vm._e(),
          _vm.moduleType === "small"
            ? _c("TaskSmall", {
                attrs: { "class-name": "group", task: _vm.task },
              })
            : _vm._e(),
          _vm.moduleType === "mini"
            ? _c("TaskMini", {
                attrs: { "class-name": "group", task: _vm.task },
              })
            : _vm._e(),
          _vm.moduleType === "horizontal" || _vm.moduleType === "group"
            ? _c("TaskHorizontal", { attrs: { task: _vm.task } })
            : _vm._e(),
          _vm.moduleType === "highRowOne"
            ? _c("TaskHighRowOne", { attrs: { task: _vm.task } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }