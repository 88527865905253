var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "div",
            { staticClass: "head-signboard-box" },
            [_c("mg-signboard", { attrs: { title: "新品上市智能化报表" } })],
            1
          ),
          _c(
            "el-form",
            {
              ref: "mgSearchRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "大类", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.handleOptionChange($event, "max")
                        },
                      },
                      model: {
                        value: _vm.queryForm.mainCategoryName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "mainCategoryName", $$v)
                        },
                        expression: "queryForm.mainCategoryName",
                      },
                    },
                    _vm._l(
                      _vm.options.productMaxCategorieOtions,
                      function (item) {
                        return _c("el-option", {
                          key: item.label,
                          attrs: { label: item.label, value: item.label },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "中类", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.handleOptionChange($event, "mid")
                        },
                      },
                      model: {
                        value: _vm.queryForm.midCategoryName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "midCategoryName", $$v)
                        },
                        expression: "queryForm.midCategoryName",
                      },
                    },
                    _vm._l(
                      _vm.options.productMidCategorieOtions,
                      function (item) {
                        return _c("el-option", {
                          key: item.label,
                          attrs: { label: item.label, value: item.label },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小类", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.subCategoryName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "subCategoryName", $$v)
                        },
                        expression: "queryForm.subCategoryName",
                      },
                    },
                    _vm._l(
                      _vm.options.productSubCategorieOtions,
                      function (item) {
                        return _c("el-option", {
                          key: item.label,
                          attrs: { label: item.label, value: item.label },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品负责人", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.personLiable,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "personLiable", $$v)
                        },
                        expression: "queryForm.personLiable",
                      },
                    },
                    _vm._l(_vm.personLiableOptions, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.activity,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "activity", $$v)
                        },
                        expression: "queryForm.activity",
                      },
                    },
                    _vm._l(_vm.activityOptions, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "货号", size: "mini" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "请输入货号",
                      clearable: "",
                    },
                    model: {
                      value: _vm.queryForm.cargoNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "cargoNo", $$v)
                      },
                      expression: "queryForm.cargoNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品名", size: "mini" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "请输入品名",
                      clearable: "",
                    },
                    model: {
                      value: _vm.queryForm.skuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuName", $$v)
                      },
                      expression: "queryForm.skuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "料号", size: "mini" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "请输入品名",
                      clearable: "",
                    },
                    model: {
                      value: _vm.queryForm.materialCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "materialCode", $$v)
                      },
                      expression: "queryForm.materialCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "可用库存类型", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ifAvailableStock,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ifAvailableStock", $$v)
                        },
                        expression: "queryForm.ifAvailableStock",
                      },
                    },
                    _vm._l(_vm.avaliableStockType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "仓位组织", prop: "stockOrg", size: "mini" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryForm.stockOrg,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "stockOrg", $$v)
                        },
                        expression: "queryForm.stockOrg",
                      },
                    },
                    _vm._l(_vm.stockOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "系列", prop: "series", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryForm.series,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "series", $$v)
                        },
                        expression: "queryForm.series",
                      },
                    },
                    _vm._l(_vm.seriesOptions, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "加减单回复类型",
                    prop: "callBackType",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryForm.callBackType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "callBackType", $$v)
                        },
                        expression: "queryForm.callBackType",
                      },
                    },
                    _vm._l(_vm.callBackTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTableHeadAction("reset")
                    },
                  },
                },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTableHeadAction("importAddReduce")
                    },
                  },
                },
                [_vm._v("导入加减单数据")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    loading: _vm.btnStatus.isExport,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleTableHeadAction("export")
                    },
                  },
                },
                [_vm._v("导出")]
              ),
              _vm.authUsers.indexOf(_vm.currentUser) > -1
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.btnStatus.isDeliveryExport,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleStockDialog("抛单DRP")
                        },
                      },
                    },
                    [_vm._v(" 抛单DRP ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    loading: _vm.btnStatus.isResetStatus,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleStockDialog("重置流程")
                    },
                  },
                },
                [_vm._v("重置流程")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleFilterColumns },
                },
                [_vm._v("列编辑")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mg-list-box" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.pageData.rows,
                height: "calc(100vh - 180px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
              },
              on: { "header-dragend": _vm.handleDrag },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  fixed: "left",
                  width: "50",
                },
              }),
              _vm._l(_vm.checkedColumns, function (item) {
                return [
                  _vm.newPublishReportColumns[item]
                    ? _c("el-table-column", {
                        key: item,
                        attrs: {
                          prop: _vm.newPublishReportColumns[item].prop,
                          "min-width": _vm.newPublishReportColumns[item].width,
                          label: _vm.newPublishReportColumns[item].label,
                          align: _vm.newPublishReportColumns[item].align,
                          fixed: _vm.newPublishReportColumns[item].fixed,
                          sortable: _vm.newPublishReportColumns[item].sortable,
                          "show-overflow-tooltip":
                            _vm.newPublishReportColumns[item]
                              .showOverflowTooltip,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row[
                                          _vm.newPublishReportColumns[item].prop
                                        ]
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "操作",
                  "min-width": "120px",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTableRowEvent(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("分公司详情")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleLists,
            },
          }),
        ],
        1
      ),
      _c("mg-importDialog", {
        attrs: {
          urlParams: _vm.urlParams,
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
        },
        on: { handleImportSuccess: _vm.handleImportSuccess },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分公司详情弹窗",
            visible: _vm.dialogVisible,
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-tag",
            {
              staticStyle: {
                width: "80%",
                "margin-bottom": "15px",
                "margin-left": "10%",
                "text-align": "center",
              },
              attrs: { effect: "dark" },
            },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "span",
                  { staticStyle: { "font-size": "15px", flex: "1" } },
                  [_vm._v("料号：" + _vm._s(_vm.currentRow.materialCode))]
                ),
                _c(
                  "span",
                  { staticStyle: { "font-size": "15px", flex: "1" } },
                  [
                    _vm._v(
                      "可用库存总量：" +
                        _vm._s(
                          _vm.currentRow.availableStock
                            ? _vm.currentRow.availableStock
                            : 0
                        ) +
                        " (RDC：" +
                        _vm._s(
                          _vm.currentRow.rdcOnStock - _vm.currentRow.rdcOweCount
                            ? _vm.currentRow.rdcOnStock -
                                _vm.currentRow.rdcOweCount
                            : 0
                        ) +
                        " 华南：" +
                        _vm._s(
                          _vm.currentRow.hnOnStock - _vm.currentRow.hnOweCount
                            ? _vm.currentRow.hnOnStock -
                                _vm.currentRow.hnOweCount
                            : 0
                        ) +
                        " 上海：" +
                        _vm._s(
                          _vm.currentRow.shOnStock - _vm.currentRow.shOweCount
                            ? _vm.currentRow.shOnStock -
                                _vm.currentRow.shOweCount
                            : 0
                        ) +
                        ")"
                    ),
                  ]
                ),
                _c(
                  "span",
                  { staticStyle: { "font-size": "15px", flex: "1" } },
                  [_vm._v("库位：" + _vm._s(_vm.currentRow.stockOrg))]
                ),
                _c(
                  "span",
                  { staticStyle: { "font-size": "15px", flex: "1" } },
                  [_vm._v("预计库存：" + _vm._s(_vm.currentRow.expectStock))]
                ),
                _c(
                  "span",
                  { staticStyle: { "font-size": "15px", flex: "1" } },
                  [_vm._v("优先区域：" + _vm._s(_vm.currentRow.firstArea))]
                ),
              ]),
            ]
          ),
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                { staticStyle: { margin: "auto" }, attrs: { width: "auto" } },
                [
                  _c("el-button", {
                    attrs: {
                      size: "mini",
                      disabled:
                        _vm.nextUpperDisabled ||
                        (_vm.queryForm.page === 1 && _vm.currentIndex < 1),
                      icon: "el-icon-arrow-left",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleNextSku("upper")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-main",
                [
                  _c("new-publish-sku-detail", {
                    ref: "mgDialog",
                    attrs: { skuCode: _vm.currentRow.skuCode },
                    on: {
                      "next-upper": function ($event) {
                        _vm.nextUpperDisabled = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-aside",
                { staticStyle: { margin: "auto" }, attrs: { width: "auto" } },
                [
                  _c("el-button", {
                    attrs: {
                      size: "mini",
                      disabled:
                        _vm.nextUpperDisabled ||
                        (_vm.queryForm.page >= _vm.queryForm.maxPage &&
                          _vm.currentIndex >= _vm.queryForm.limit - 1),
                      icon: "el-icon-arrow-right",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleNextSku("next")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.stockDialog.title,
            visible: _vm.stockDialog.visible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.stockDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "stockForm",
              attrs: { model: _vm.stockDialog.form, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "活动",
                    prop: "activity",
                    rules: [
                      {
                        required: true,
                        message: "不能为空",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.stockDialog.form.activity,
                        callback: function ($$v) {
                          _vm.$set(_vm.stockDialog.form, "activity", $$v)
                        },
                        expression: "stockDialog.form.activity",
                      },
                    },
                    _vm._l(_vm.activityOptions, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTableHeadAction("deliveryExport")
                    },
                  },
                },
                [_vm._v("配送导出（仅导出数据）")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.stockDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleDialogAction },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "列编辑",
            visible: _vm.columnsDialog.visible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.columnsDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "25%",
                position: "relative",
                float: "right",
                right: "60%",
                top: "-70px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(false)
                    },
                  },
                },
                [_vm._v("清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(true)
                    },
                  },
                },
                [_vm._v("全选")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: { width: "80%", position: "relative", top: "-20px" },
            },
            [_vm._v(_vm._s(_vm.checkForm.checkedColumns.join(", ")))]
          ),
          _vm._l(_vm.checkForm.filterColumns, function (item, key, index) {
            return _c(
              "div",
              {
                key: index,
                staticStyle: {
                  "margin-bottom": "15px",
                  display: "inline-block",
                },
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckedChange($event, key)
                      },
                    },
                    model: {
                      value: item.checked,
                      callback: function ($$v) {
                        _vm.$set(item, "checked", $$v)
                      },
                      expression: "item.checked",
                    },
                  },
                  _vm._l(item.list, function (option) {
                    return _c(
                      "el-checkbox",
                      {
                        key: option,
                        staticStyle: { width: "22%" },
                        attrs: { label: option },
                      },
                      [_vm._v(_vm._s(option) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmChecked },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }