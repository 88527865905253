<template>
  <div style="font-family: Microsoft YaHei, Arial, sans-serif;background-color: #FFFFFF">
    <div style="font-size: 14px; padding-right: 1%;padding-top: 1%;">
      <label style="padding-right: 1%;padding-left: 1%;color: #000000;opacity:0.85">文具分类</label>
      <!--      <span class="demonstration">hover 触发子菜单</span>-->
      <!--      <el-cascader-->
      <!--        style=" width: 30%;padding-right: 2%"-->
      <!--        size="small"-->
      <!--        :props="props"-->
      <!--        :options="firstLevelOptions"-->
      <!--        v-model="selectedOptions"-->
      <!--        clearable-->
      <!--      ></el-cascader>-->
      <el-cascader
        style=" width: 30%;padding-right: 2%;"
        size="small"
        :options="levelOptions"
        v-model="selectedOptions"
        clearable
      ></el-cascader>
      <el-button style="text-align: center;justify-content: center" type="primary" size="small"
                 @click="getConsumeStatistics">查询
      </el-button>
    </div>
    <el-menu
      class="ptClass"
      :default-active="activeIndex"
      mode="horizontal"
      @select="handleClickPT"
      active-text-color="#409EFF"
      background-color="#FFFFFF"
      text-color="#303133"
    >
      <el-menu-item index="first">全网汇总</el-menu-item>
      <el-menu-item index="second">电商平台</el-menu-item>
      <el-menu-item index="third">抖音话题</el-menu-item>
      <el-menu-item index="fourth">小红书</el-menu-item>
    </el-menu>
    <div class="line"></div>
    <!--    <div style="font-size: 15px; padding-right: 1%;padding-top: 1%">关键词：{{ spiderKeywords }}</div>-->
    <div v-show="activeIndex==='first'" style="padding-left: 1%;padding-right: 1%;">
      <h3 style="margin-bottom: 0px;font-size: 15px;color: #000000;opacity: 0.85">热点词（每周一更新）</h3>
      <el-switch
        style="margin-top: 1%"
        v-model="brandKeywordSwitch"
        inactive-text="剔除品牌关键词"
        @change="firstBrandKeywordSwitchChange"
      >
      </el-switch>
      <el-switch
        style="margin-top: 1%;margin-left: 1%"
        v-model="simplybrandKeywordSwitch"
        inactive-text="仅展示品牌关键词"
        @change="simplyfirstBrandKeywordSwitchChange"
      >
      </el-switch>
      <div class="column-container" style="padding-top: 1%;color: #333333 ;font-size: 13px">
        <div class="column">
          <div class="ranking-box">
            <el-table
              :data="columnsList[0]"
              fit
              row-class-name="customCellClass"
              highlight-current-row
              @current-change="rdcHandleCurrentChange"
              class="rdcTableClass"
              height="800px"
              style="border: 0px !important;"
              ref="singleTableRdc"
            >
              <el-table-column
                prop="keywordRank"
                label="排名"
                align="center"
                width="auto"
                :min-width="10"
              >
                <template slot-scope="scope">
                  <span :class="sortDa(scope.row.keywordRank)">{{ scope.row.keywordRank }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="关键词"
                align="center"
                width="auto"
                :min-width="25"
              >
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center;justify-content: center;">
                    <span>{{ scope.row.itemKeyword }}</span>
                    <el-button size="mini" type="text" class="search-custom-icon"
                               @click="handleKeywordsRankTrend(scope.row.itemKeyword, '全平台')"></el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="较上周排名"
                align="center"
                width="auto"
                :min-width="20"
              >
                <template slot-scope="scope">
                  <img v-if="scope.row.lastWeekRank === 'NEW'" src="/static/icon/marketDataDataBoard.svg">
                  <span v-else-if="Math.abs(scope.row.lastWeekRank) === 0">{{ '不变' }}</span>
                  <div class="icon-wrapper" v-else>
                    <i :class="getArrowIconClass(scope.row.lastWeekRank)"></i>
                    <span :class="getArrowIconClassNum(scope.row.lastWeekRank)">
                      {{ Math.abs(scope.row.lastWeekRank) }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="auto"
                :min-width="25"
                label="较上周热度变化"
              >
                <template slot-scope="scope">
                  <img v-if="scope.row.heatChange === 'NEW'" src="/static/icon/marketDataDataBoard.svg">
                  <span v-else-if="Math.abs(scope.row.heatChange) === 0 ">不变</span>
                  <div class="icon-wrapper" v-else>
                    <i :class="getArrowIconClass(scope.row.heatChange)"></i>
                    <span :class="getArrowIconClassNum(scope.row.heatChange)">
                                  {{ Math.abs(scope.row.heatChange) + '%' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="ifQbtFlag === 'N' && mgPenCommNumCount ==='True'"
                prop="mgKeywordRank"
                label="晨光品牌热词排名"
                align="center"
                width="auto"
                :min-width="20"
              >
              </el-table-column>
            </el-table>
            <!--                    style="color: #333333;font-size: 13px;padding-bottom: 1%">-->
          </div>
        </div>
        <div class="column">
          <div class="ranking-box" style="height: 842px">
            <h3 class="mgXszbClass">
              热点词：{{ this.Rdcvalue }}</h3>
            <div style="display: flex;justify-content: space-between;"
                 v-if="ifQbtFlag === 'N' && mgPenCommNumCount ==='True'">
              <h3 style="margin-bottom: 0px;font-size: 15px;color: #000000;opacity: 0.85;">
                晨光品牌热卖商品销售占比趋势</h3>
              <el-button v-show="rdcQsTr === true" type="text" @click="getRdcCommList">
                查看最新TOP100商品清单
              </el-button>
            </div>
            <el-divider></el-divider>
            <div v-if="rdcQsTr === true && ifQbtFlag === 'N' && mgPenCommNumCount ==='True'"
                 style="width: 100%; height: 200px;"
                 ref="chartRdcCommRankCanvas" v-loading="isLoadingRdc">
            </div>
            <el-empty
              v-if="ifQbtFlag === 'N' && rdcQsTr === false && mgPenCommNumCount ==='True'"
              :image-size="50"
              description="未找到相关晨光品牌商品"
              v-loading="isLoadingRdc"
              image="/static/icon/未找到相关商品.svg"
            >
              <div style="display: flex;align-items: center;justify-content: center"
                   v-if="ifQbtFlag === 'N' && rdcQsTr === false">
                <img src="/static/icon/叉叉.svg" style="margin-right: 5px; ">
                <span>商品名称中未出现相关热点词或近义词</span>
              </div>
            </el-empty>
            <h3 style="font-size: 15px;color: #000000;opacity: 0.85;margin-bottom: 0.5%">热点词关联信息</h3>
            <el-divider></el-divider>
            <div style="padding-bottom: 1%;color: #000000;opacity: 0.85;background: #FFFFFF;padding-top: 1%">
              <!--                            <label style="padding-right: 1%;color: #000000;opacity:0.85">热点词：</label>
                                          <el-select
                                            v-model="Rdcvalue"
                                            placeholder="请选择"
                                            clearable
                                            size="small"
                                            @input="handlePtvalueInput"
                                          >
                                            <el-option
                                              v-for="item in PtoptionsRdc"
                                              :key="item.itemKeyword"
                                              :label="item.itemKeyword"
                                              :value="item.itemKeyword">
                                            </el-option>
                                          </el-select>
                                          <label style="padding-left: 2%;padding-right:1%;color: #000000;opacity:0.85">平台：</label>-->
              <el-radio-group
                v-model="SpqdNameRdcPt"
                size="small"
                fill="#409EFF"
                style="background: #FFFFFF;padding-right: 2%"
                @change="spqdNameRdcPtChange"
              >
                <el-radio-button v-show="qwTm==='True'" label="4">天猫</el-radio-button>
                <el-radio-button v-show="qwJd==='True'" label="5">京东</el-radio-button>
                <el-radio-button v-show="qwDySp==='True'" label="1">抖音商品</el-radio-button>
                <el-radio-button v-show="qwPdd==='True'" label="2">拼多多</el-radio-button>
                <el-radio-button v-show="qwKs==='True'" label="3">快手</el-radio-button>
                <el-radio-button v-show="qwDyht==='True' " label="6">抖音话题</el-radio-button>
                <el-radio-button v-show="qwXhs==='True' " label="7">小红书</el-radio-button>
              </el-radio-group>
              <!--              <el-button type="primary" icon="el-icon-search" size="small" @click="getRdcCommDataPt">查询</el-button>-->
              <el-button type="primary" icon="el-icon-download" size="small" @click="getRdcCommDataDowload"
                         :loading=rdcCommDataDowloadling>
                下载
              </el-button>

            </div>
            <div style="padding-bottom: 1%;color: #000000;opacity: 0.85;background: #FFFFFF">
              <el-radio-group v-model="allRadioPt" style="font-size: 15px; padding-right: 1%;" size="mini"
                              @input="handleAllRadioPtInput">
                <el-radio
                  v-if="SpqdNameRdcPt==='1' || SpqdNameRdcPt==='2' || SpqdNameRdcPt==='3' || SpqdNameRdcPt==='4' || SpqdNameRdcPt==='5'"
                  label="1">全部商品
                </el-radio>
                <el-radio label="2">仅关键词商品</el-radio>
              </el-radio-group>
            </div>
            <div v-show="SpqdNameRdcPt=='1'">
              <el-table
                :data="SpqdTableDataRdcSpDysp"
                border
                fit
                height="350px"
                row-class-name="customCellClass"
              >
                <el-table-column
                  prop="itemRank"
                  label="排名"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  prop="itemName"
                  label="商品名称"
                  align="center"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank" style="color: #1f85ee">{{ scope.row.itemName }}</a>
                  </template>
                </el-table-column>
                <!--          <el-table-column
                            prop="shopTitle"
                            align="center"
                            label="所属店铺">
                          </el-table-column>-->
                <el-table-column
                  prop="sellQtyStr"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="销售数量"
                >
                </el-table-column>
                <el-table-column
                  prop="commentsQtyStr"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="点评数">
                </el-table-column>
                <el-table-column
                  prop="itemPrice"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="价格">
                </el-table-column>
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPageDysp"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotleDysp"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
            <div v-show="SpqdNameRdcPt=='2' ">
              <el-table
                :data="SpqdTableDataRdcSpPddsp"
                border
                height="350px"
                row-class-name="customCellClass"
                style="width: 100%;"
              >
                <el-table-column
                  prop="itemRank"
                  label="排名"
                  width="auto"
                  :min-width="10"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  prop="itemName"
                  label="商品名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="SpqdName=='3'"
                  prop="shopTitle"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="所属店铺">
                </el-table-column>
                <el-table-column
                  prop="sellQtyStr"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="销售数量">
                </el-table-column>
                <!--          <el-table-column
                            prop="commentsQty"
                            align="center"
                            label="点评数">
                          </el-table-column>-->
                <el-table-column
                  prop="itemPrice"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="价格">
                </el-table-column>
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPagePddsp"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotlePddsp"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
            <div v-show="SpqdNameRdcPt=='3'">
              <el-table
                :data="SpqdTableDataRdcSpKssp"
                border
                height="350px"
                row-class-name="customCellClass"
                style="width: 100%;"
              >
                <el-table-column
                  prop="itemRank"
                  label="排名"
                  width="auto"
                  :min-width="10"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  prop="itemName"
                  label="商品名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="SpqdName=='3'"
                  prop="shopTitle"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="所属店铺">
                </el-table-column>
                <el-table-column
                  prop="sellQtyStr"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="销售数量">
                </el-table-column>
                <!--          <el-table-column
                            prop="commentsQty"
                            align="center"
                            label="点评数">
                          </el-table-column>-->
                <el-table-column
                  prop="itemPrice"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="价格">
                </el-table-column>
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPageKssp"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotleKssp"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
            <div v-show="SpqdNameRdcPt=='4'">
              <el-table
                :data="SpqdTableDataRdcSpTmsp"
                border
                height="350px"
                row-class-name="customCellClass"
              >
                <el-table-column
                  prop="itemRank"
                  label="排名"
                  width="auto"
                  :min-width="10"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  prop="itemName"
                  label="商品名称"
                  align="center"
                >
                  <template slot-scope="scope">
                    <a :href="scope.row.itemUrl" target="_blank" style="color: #1f85ee">{{ scope.row.itemName }}</a>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="shopTitle"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="所属店铺">
                  <template slot-scope="scope">
                    <a :href="scope.row.shopUrl" target="_blank" style="color: #1f85ee">{{ scope.row.shopTitle }}</a>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="ifTm === 'N'"
                  prop="sellCount"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="周销售数量">
                </el-table-column>
                <el-table-column
                  v-if="ifTm === 'N'"
                  prop="salesTotal"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="周销售金额">
                </el-table-column>
                <el-table-column
                  v-if="ifTm === 'N'"
                  prop="averagePrice"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="成交均价">
                </el-table-column>

                <el-table-column
                  v-if="ifTm === 'Y'"
                  prop="price"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="价格">
                </el-table-column>
                <el-table-column
                  v-if="ifTm === 'Y'"
                  prop="sellCountStr"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="月销">
                </el-table-column>
                <!--                <el-table-column-->
                <!--                  prop="price"-->
                <!--                  align="center"-->
                <!--                  width="auto"-->
                <!--                  :min-width="10"-->
                <!--                  label="价格">-->
                <!--                  <template slot-scope="scope">-->
                <!--                    {{ scope.row.price }}-->
                <!--                    <el-button size="mini" type="text" class="search-custom-icon"-->
                <!--                               @click="handleSycmTrend(scope.row.itemName)"></el-button>-->
                <!--                  </template>-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                  prop="sellCountStr"-->
                <!--                  align="center"-->
                <!--                  width="auto"-->
                <!--                  :min-width="10"-->
                <!--                  label="月销">-->
                <!--                  <template slot-scope="scope">-->
                <!--                    {{ scope.row.sellCountStr }}-->
                <!--                    <el-button size="mini" type="text" class="search-custom-icon"-->
                <!--                               @click="handleSycmTrend(scope.row.itemName)"></el-button>-->
                <!--                  </template>-->
                <!--                </el-table-column>-->
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPageTmsp"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotleTmsp"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
            <div v-show="SpqdNameRdcPt=='5' ">
              <el-table
                :data="SpqdTableDataRdcSpJdsp"
                border
                fit
                height="350px"
                row-class-name="customCellClass"
              >
                <el-table-column
                  prop="itemRank"
                  label="排名"
                  width="auto"
                  :min-width="10"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  prop="commodityName"
                  label="商品名称"
                  width="auto"
                  align="center"
                >
                  <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank" style="color: #1f85ee">{{ scope.row.commodityName }}</a>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="shopName"
                  label="所属店铺"
                  width="auto"
                  align="center"
                >
                  <template slot-scope="scope">
                    <a :href="scope.row.shopUrl" target="_blank" style="color: #1f85ee">{{ scope.row.shopName }}</a>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="commentCountStr"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="评论数">
                </el-table-column>
                <el-table-column
                  prop="showPrice"
                  align="center"
                  width="auto"
                  :min-width="10"
                  label="价格">
                </el-table-column>
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPageJdsp"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotleJdsp"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
            <div v-show="SpqdNameRdcPt=='6' ">
              <el-table
                :data="SpqdTableDataRdcSpDyht"
                border
                fit
                height="350px"
                row-class-name="customCellClass"
              >
                <el-table-column
                  prop="topicRank"
                  label="排名"
                  width="auto"
                  :min-width="10"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  prop="toppicName"
                  label="抖音话题"
                  width="auto"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="viewCount"
                  label="最新浏览量"
                  width="auto"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.viewCount }}
                    <el-button size="mini" type="text" class="search-custom-icon"
                               @click="handleSycmTrendRdcDyht(scope.row.toppicName)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPageDyht"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotleDyht"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
            <div v-show="SpqdNameRdcPt=='7' ">
              <el-table
                :data="SpqdTableDataRdcSpXhsTz"
                border
                fit
                height="350px"
                row-class-name="customCellClass"
                style="width: 100%;margin-left: 0%;margin-right: 0%;margin-top: 2px">
                <el-table-column
                  prop="artRank"
                  label="排名"
                  align="center"
                  width="auto"
                  :min-width="10"
                >
                </el-table-column>
                <el-table-column
                  v-if="allRadioPt==='2'"
                  prop="itemKeyword"
                  label="热点词"
                  align="center"
                  width="auto"
                  :min-width="18"
                >
                </el-table-column>
                <el-table-column
                  prop="artType"
                  label="类型"
                  align="center"
                  width="auto"
                  :min-width="15"
                >
                </el-table-column>
                <el-table-column
                  prop="dataTime"
                  align="center"
                  width="auto"
                  label="发布时间"
                  :min-width="30"
                >
                </el-table-column>
                <el-table-column
                  prop="title"
                  align="center"
                  width="auto"
                  label="标题">
                </el-table-column>
                <el-table-column
                  prop="subHashtags"
                  align="center"
                  width="auto"
                  label="标签">
                </el-table-column>
                <el-table-column
                  prop="subDescription"
                  align="center"
                  width="auto"
                  label="正文">
                </el-table-column>
                <el-table-column
                  prop="likesStr"
                  align="center"
                  width="auto"
                  :min-width="20"
                  label="点赞数量">
                </el-table-column>
                <el-table-column
                  prop="collectsStr"
                  align="center"
                  width="auto"
                  :min-width="20"
                  label="收藏数量">
                </el-table-column>
                <el-table-column
                  prop="sharecountStr"
                  align="center"
                  width="auto"
                  :min-width="20"
                  label="分享数量">
                </el-table-column>
                <el-table-column
                  prop="commentsStr"
                  align="center"
                  width="auto"
                  :min-width="20"
                  label="评论数量">
                </el-table-column>
                <el-table-column
                  align="center"
                  width="150px"
                  :min-width="10"
                  label="操作">
                  <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank" style="color: #1F85EE;padding-right: 15px">{{ '查看' }}</a>
                    <el-button style="color: #1F85EE" size="mini" type="text" @click="handleXhsTrend(scope.row.id)">历史趋势
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                style="padding-top: 2%;padding-bottom: 6%;"
                background
                :current-page="scRdcDataPageXhstz"
                :page-size="scRdcDataPageSize"
                :total="scRdcDataPageTotleXhstz"
                layout="total, prev, pager, next"
                @current-change="getRdcCommDataPageChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisibleRdc"
    >
      <div class="rdcDilClass">
        <label style="padding-left: 1%">商品数 {{ rdcDialogSpqd.skuCount }}</label>
        <label style="padding-left: 5%">
          当月销售金额占比晨光品牌 {{ rdcDialogSpqd.dySalesProportionMg }}
          <br>
          <!--          <div style="font-size: 8px;padding-left: 5%">
                      ({{ rdcDialogSpqd.thisYearDayRank }})
                    </div>-->
        </label>
        <label style="padding-left: 5%">
          当月销售金额占比晨光TOP100商品 {{ rdcDialogSpqd.dySalesProportionTop }}
          <br>
          <!--          <div style="font-size: 8px;padding-left: 5%">
                      ({{ rdcDialogSpqd.lastWeekDayRank }})
                    </div>-->
        </label>
        <!--        <label style="padding-left: 5%;display: flex;">
                  环比上上周
                  <i
                     :class="getArrowIconClass(parseFloat(rdcDialogSpqd.hbLastWeekSellProportion.replace('%','')))"></i>
                  <span :class="getArrowIconClassNum(parseFloat(rdcDialogSpqd.hbLastWeekSellProportion.replace('%','')))">
                                                {{ rdcDialogSpqd.hbLastWeekSellProportion }}
                                              </span>
                </label>-->
      </div>
      <div style="padding: 2%;display: flex;align-items: flex-end;justify-content: flex-end;">
        <el-button type="primary" plain icon="el-icon-download" size="small" @click="getRdcMgCommDataDowload"
                   :loading=rdcMgCommDataDowloadling>
          下载
        </el-button>
      </div>

      <el-table
        :data="rdcCommDataList"
        border
      >
        <el-table-column
          align="center"
          property="itemRank"
          label="排名" min-width="5px">
        </el-table-column>
        <el-table-column
          align="center"
          property="itemKeyword" label="热点词"
          min-width="10px"></el-table-column>
        <el-table-column align="center" property="skuName" label="商品名" min-width="30px">
          <template slot-scope="scope">
            <a :href="scope.row.skuUrl" target="_blank" style="color: #1f85ee">{{ scope.row.skuName }}</a>
          </template>
        </el-table-column>
        <el-table-column align="center" property="averagePrice" label="成交均价"
                         min-width="8px"></el-table-column>
        <el-table-column align="center" property="dySalesProportionMg" label="当月销售金额占比晨光品牌"
                         min-width="10px"></el-table-column>
        <el-table-column align="center" property="dySalesProportionTop" label="当月销售金额占比TOP100商品"
                         min-width="15px"></el-table-column>
        <el-table-column align="center" property="shopTitle" label="掌柜"
                         min-width="15px">
          <template slot-scope="scope">
            <a :href="scope.row.shopUrl" target="_blank" style="color: #1f85ee">{{ scope.row.shopTitle }}</a>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding-top: 2%;padding-bottom: 6%;"
        background
        :current-page="scRdcDataPageMgComm"
        :page-size="scRdcDataPageSize"
        :total="scRdcDataPageTotleMgComm"
        layout="total, prev, pager, next"
        @current-change="getRdcCommListPageChange"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogTableVisibleDspt"
    >
      <div class="rdcDilClass">
        <label style="padding-left: 1%">商品数 {{ rdcDialogSpqd.skuCount }}
        </label>
        <label style="padding-left: 5%">当月销售金额占比晨光品牌 {{ rdcDialogSpqd.dySalesProportionMg }}
          <br>
          <!--          <div style="font-size: 8px;padding-left: 5%">
                      ({{ rdcDialogSpqd.thisYearDayRank }})
                    </div>-->
        </label>
        <label style="padding-left: 5%">
          当月销售金额占比晨光TOP100商品 {{ rdcDialogSpqd.dySalesProportionTop }}
          <br>
          <!--          <div style="font-size: 8px;padding-left: 5%">
                      ({{ rdcDialogSpqd.lastWeekDayRank }})
                    </div>-->
        </label>
        <!--        <label style="padding-left: 5%;display: flex;">
                  环比上上周
                  <i :class="getArrowIconClass(parseFloat(rdcDialogSpqd.hbLastWeekSellProportion.replace('%','')))"></i>
                  <span :class="getArrowIconClassNum(parseFloat(rdcDialogSpqd.hbLastWeekSellProportion.replace('%','')))">
                                                {{ rdcDialogSpqd.hbLastWeekSellProportion }}
                                              </span>
                </label>-->
      </div>
      <div style="padding: 2%;display: flex;align-items: flex-end;justify-content: flex-end;">
        <el-button type="primary" plain icon="el-icon-download" size="small" @click="getDsptMgCommDataDowload"
                   :loading=rdcMgCommDataDowloadling>
          下载
        </el-button>
      </div>

      <el-table
        :data="rdcCommDataList"
        border
      >
        <el-table-column
          align="center"
          property="itemRank"
          label="排名" min-width="5px">
        </el-table-column>
        <el-table-column
          align="center"
          property="itemKeyword" label="热点词"
          min-width="10px"></el-table-column>
        <el-table-column align="center" property="skuName" label="商品名" min-width="15px">
          <template slot-scope="scope">
            <a :href="scope.row.skuUrl" target="_blank" style="color: #1f85ee">{{ scope.row.skuName }}</a>
          </template>
        </el-table-column>
        <el-table-column align="center" property="averagePrice" label="成交均价"
                         min-width="8px"></el-table-column>
        <el-table-column align="center" property="dySalesProportionMg" label="当月销售金额占比晨光品牌"
                         min-width="20px"></el-table-column>
        <el-table-column align="center" property="dySalesProportionTop" label="当月销售金额占比TOP100商品"
                         min-width="25px"></el-table-column>
        <el-table-column align="center" property="shopTitle" label="掌柜"
                         min-width="15px">
          <template slot-scope="scope">
            <a :href="scope.row.shopUrl" target="_blank" style="color: #1f85ee">{{ scope.row.shopTitle }}</a>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding-top: 2%;padding-bottom: 6%;"
        background
        :current-page="scRdcDataPageMgComm"
        :page-size="scRdcDataPageSize"
        :total="scRdcDataPageTotleMgComm"
        layout="total, prev, pager, next"
        @current-change="getDsptCommListPageChange"
      >
      </el-pagination>
    </el-dialog>
    <div v-show="activeIndex === 'second'"
         style="padding-right: 1%;padding-left: 1%;color: #333333 ;font-size: 13px">
      <h3 style="margin-bottom: 0px;font-size: 15px;color: #000000;opacity: 0.85">
        商品关键词（每周一更新）</h3>
      <el-switch
        style="margin-top: 1%"
        v-model="brandItemKeywordSwitch"
        inactive-text="剔除品牌关键词"
        @change="secondBrandKeywordSwitchChange"
      ></el-switch>
      <el-switch
        style="margin-top: 1%;margin-left: 1%"
        v-model="simplybrandItemKeywordSwitch"
        inactive-text="仅展示品牌关键词"
        @change="simplySecondBrandKeywordSwitchChange"
      ></el-switch>
      <div class="column-container" style="padding-top: 1%;color: #333333 ;font-size: 13px">
        <div class="column">
          <div class="ranking-box">
            <el-table
              :data="columnsListPD[0]"
              fit
              height="800px"
              ref="singleTableDspt"
              row-class-name="customCellClass"
              highlight-current-row
              @current-change="dsptHandleCurrentChange"
              class="rdcTableClass"
              style="border: 0px !important;"
            >
              <el-table-column
                prop="keywordRank"
                label="排名"
                align="center"
                width="auto"
                :min-width="10"
              >
                <template slot-scope="scope">
                  <span :class="sortDa(scope.row.keywordRank)">{{ scope.row.keywordRank }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="关键词"
                align="center"
                width="auto"
                :min-width="20"
              >
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center;justify-content: center;">
                    <span>{{ scope.row.itemKeyword }}</span>
                    <el-button size="mini" type="text" class="search-custom-icon"
                               @click="handleKeywordsRankTrend(scope.row.itemKeyword, '电商平台')"></el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="较上周排名"
                align="center"
                width="auto"
                :min-width="20"
              >
                <template slot-scope="scope">
                  <img v-if="scope.row.lastWeekRank === 'NEW'" src="/static/icon/marketDataDataBoard.svg">
                  <span v-else-if="Math.abs(scope.row.lastWeekRank) === 0">{{ '不变' }}</span>
                  <div class="icon-wrapper" v-else>
                    <i :class="getArrowIconClass(scope.row.lastWeekRank)"></i>
                    <span :class="getArrowIconClassNum(scope.row.lastWeekRank)">
                      {{ Math.abs(scope.row.lastWeekRank) }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="auto"
                :min-width="20"
                label="较上周热度变化"
              >
                <template slot-scope="scope">
                  <img v-if="scope.row.heatChange === 'NEW'" src="/static/icon/marketDataDataBoard.svg">
                  <span
                    v-else-if="Math.abs(scope.row.heatChange) === 0">不变</span>
                  <div class="icon-wrapper" v-else>
                    <i :class="getArrowIconClass(scope.row.heatChange)"></i>
                    <span :class="getArrowIconClassNum(scope.row.heatChange)">
                                  {{ Math.abs(scope.row.heatChange) + '%' }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="ifQbtFlag === 'N' && mgPenCommNumCount ==='True'"
                prop="mgKeywordRank"
                label="晨光品牌热词排名"
                align="center"
                width="auto"
                :min-width="20"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="column">
          <div class="ranking-box">
            <h3 class="mgXszbClass">
              热点词：{{ this.Ptvalue }}</h3>
            <div style="display: flex;justify-content: space-between;"
                 v-if="ifQbtFlag === 'N' && mgPenCommNumCount ==='True'">
              <h3 style="margin-bottom: 0px;font-size: 15px;color: #000000;opacity: 0.85;">
                晨光品牌热卖商品销售占比趋势</h3>
              <el-button v-show="dspyQsTr === true" type="text" @click="getDsptMgCommList">
                查看最新TOP100商品清单
              </el-button>
            </div>
            <el-divider style="padding-top: -2%"></el-divider>
            <div v-if="dspyQsTr === true && ifQbtFlag === 'N' && mgPenCommNumCount ==='True'"
                 style="width: 100%; height: 200px;"
                 ref="chartDsptCommRankCanvas" v-loading="isLoadingDspt">
            </div>
            <el-empty
              v-if="ifQbtFlag === 'N' && dspyQsTr === false && mgPenCommNumCount ==='True'"
              :image-size="50"
              description="未找到相关晨光品牌商品"
              v-loading="isLoadingDspt"
              image="/static/icon/未找到相关商品.svg"
            >
              <div style="display: flex;align-items: center;justify-content: center"
                   v-if="ifQbtFlag === 'N' && dspyQsTr === false">
                <img src="/static/icon/叉叉.svg" style="margin-right: 5px; ">
                <span>商品名称中未出现相关热点词或近义词</span>
              </div>
            </el-empty>
            <div>
              <h3 style="font-size: 15px;padding-right: 1%;color: #000000;opacity: 0.85;margin-bottom: 0.5%">
                商品清单</h3>
              <el-divider></el-divider>
            </div>
            <!--      <span style="font-size: 17.55px; font-weight: bold">商品清单</span>-->
            <div style="padding-top: 1%;">
              <el-radio-group
                v-model="SpqdName"
                @input="handleClickSpqd"
                size="small"
                fill="#409EFF"
                style="background: #FFFFFF;padding-right: 2%"
              >
                <el-radio-button v-show="dsTm==='True'" label="4">天猫</el-radio-button>
                <el-radio-button v-show="dsJd==='True'" label="5">京东</el-radio-button>
                <el-radio-button v-show="dsDysp==='True'" label="1">抖音</el-radio-button>
                <el-radio-button v-show="dsPdd==='True'" label="2">拼多多</el-radio-button>
                <el-radio-button v-show="dsKs==='True'" label="3">快手</el-radio-button>
              </el-radio-group>
              <el-button type="primary" icon="el-icon-download" size="small" @click="getDsptCommDataDowload"
                         :loading=rdcCommDataDowloadling>
                下载
              </el-button>
              <!--          <el-tabs-->
              <!--            v-model="SpqdName"-->
              <!--            type="card"-->
              <!--            @tab-click="handleClickSpqd"-->
              <!--            active-text-color="#f9f9f9"-->
              <!--            background-color="#409EFF"-->
              <!--          >-->
              <!--            <el-tab-pane label="抖音" name="1"></el-tab-pane>-->
              <!--            <el-tab-pane label="拼多多" name="2"></el-tab-pane>-->
              <!--            <el-tab-pane label="快手" name="3"></el-tab-pane>-->
              <!--            <el-tab-pane label="天猫" name="4"></el-tab-pane>-->
              <!--            <el-tab-pane label="京东" name="5"></el-tab-pane>-->
              <!--          </el-tabs>-->
            </div>
            <div
              style="font-size: 15px;padding-top: 1%;color: #333333 ;font-size: 13px">
              <div style="padding-bottom: 1%;color: #000000;opacity: 0.85;background: #FFFFFF">
                <el-radio-group v-model="radioPt" style="font-size: 15px; padding-right: 1%;" size="mini"
                                @input="handleRadioPtInput">
                  <el-radio label="1">全部商品</el-radio>
                  <el-radio label="2">仅关键词商品</el-radio>
                </el-radio-group>
              </div>
              <div v-if="SpqdName=='1'">
                <el-table
                  :data="SpqdTableDataDy"
                  border
                  fit
                  height="460px"
                  row-class-name="customCellClass"
                >
                  <el-table-column
                    prop="itemRank"
                    label="排名"
                    align="center"
                    width="auto"
                    :min-width="10"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="radioPt==='2'"
                    prop="itemKeyword"
                    label="热点词"
                    align="center"
                    width="auto"
                    :min-width="18"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="itemName"
                    label="商品名称"
                    align="center"
                    width="auto"
                  >
                    <template slot-scope="scope">
                      <a :href="scope.row.url" target="_blank" style="color: #1f85ee">{{ scope.row.itemName }}</a>
                    </template>
                  </el-table-column>
                  <!--          <el-table-column
                              prop="shopTitle"
                              align="center"
                              label="所属店铺">
                            </el-table-column>-->
                  <el-table-column
                    prop="sellQtyStr"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="销售数量"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="commentsQtyStr"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="点评数">
                  </el-table-column>
                  <el-table-column
                    prop="itemPrice"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="价格">
                  </el-table-column>
                </el-table>

              </div>
              <div v-if="SpqdName=='2' || SpqdName=='3'">
                <el-table
                  :data="SpqdTableDataDy"
                  border
                  height="460px"
                  row-class-name="customCellClass"
                  style="width: 100%;"
                >
                  <el-table-column
                    prop="itemRank"
                    label="排名"
                    width="auto"
                    :min-width="10"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="radioPt==='2'"
                    prop="itemKeyword"
                    label="热点词"
                    align="center"
                    width="auto"
                    :min-width="18"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="itemName"
                    label="商品名称"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="SpqdName=='3'"
                    prop="shopTitle"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="所属店铺">
                  </el-table-column>
                  <el-table-column
                    prop="sellQtyStr"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="销售数量">
                  </el-table-column>
                  <!--          <el-table-column
                              prop="commentsQty"
                              align="center"
                              label="点评数">
                            </el-table-column>-->
                  <el-table-column
                    prop="itemPrice"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="价格">
                  </el-table-column>
                </el-table>

              </div>
              <div v-if="SpqdName=='4'">
                <el-table
                  :data="SpqdTableDataDy"
                  border
                  height="460px"
                  row-class-name="customCellClass"
                  style="width: 100%;">
                  <el-table-column
                    prop="itemRank"
                    label="排名"
                    width="auto"
                    :min-width="10"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="radioPt==='2'"
                    prop="itemKeyword"
                    label="热点词"
                    align="center"
                    width="auto"
                    :min-width="18"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="itemName"
                    label="商品名称"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <a :href="scope.row.itemUrl" target="_blank" style="color: #1f85ee">{{ scope.row.itemName }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="shopTitle"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="所属店铺">
                    <template slot-scope="scope">
                      <a :href="scope.row.shopUrl" target="_blank" style="color: #1f85ee">{{
                          scope.row.shopTitle
                        }}</a>
                    </template>
                  </el-table-column>
                  <!--                  <el-table-column
                                      v-if="ifTm === 'N'"
                                      prop="tradeIndex"
                                      align="center"
                                      width="auto"
                                      :min-width="10"
                                      label="交易指数">
                                    </el-table-column>
                                    <el-table-column
                                      v-if="ifTm === 'N'"
                                      prop="tradeGrowthRange"
                                      align="center"
                                      width="auto"
                                      :min-width="10"
                                      label="交易增长幅度">
                                      <template slot-scope="scope">
                                        <div
                                          :class="getArrowIconClassNum(parseFloat( scope.row.tradeGrowthRange?scope.row.tradeGrowthRange.replace('%', ''):0))">
                                          {{ scope.row.tradeGrowthRange }}
                                        </div>
                                      </template>
                                    </el-table-column>-->
                  <el-table-column
                    v-if="ifTm === 'N'"
                    prop="sellCount"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="周销售数量">
                  </el-table-column>
                  <el-table-column
                    v-if="ifTm === 'N'"
                    prop="salesTotal"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="周销售金额">
                  </el-table-column>
                  <el-table-column
                    v-if="ifTm === 'N'"
                    prop="averagePrice"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="成交均价">
                  </el-table-column>

                  <el-table-column
                    v-if="ifTm === 'Y'"
                    prop="price"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="价格">
                  </el-table-column>
                  <el-table-column
                    v-if="ifTm === 'Y'"
                    prop="sellCountStr"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="月销">
                  </el-table-column>
                  <!--                  <el-table-column-->
                  <!--                    prop="price"-->
                  <!--                    align="center"-->
                  <!--                    width="auto"-->
                  <!--                    :min-width="10"-->
                  <!--                    label="价格">-->
                  <!--                    <template slot-scope="scope">-->
                  <!--                      {{ scope.row.price }}-->
                  <!--                      <el-button size="mini" type="text" class="search-custom-icon"-->
                  <!--                                 @click="handleSycmTrend(scope.row.itemName)"></el-button>-->
                  <!--                    </template>-->
                  <!--                  </el-table-column>-->
                  <!--                  <el-table-column-->
                  <!--                    prop="sellCountStr"-->
                  <!--                    align="center"-->
                  <!--                    width="auto"-->
                  <!--                    :min-width="10"-->
                  <!--                    label="月销">-->
                  <!--                    <template slot-scope="scope">-->
                  <!--                      {{ scope.row.sellCountStr }}-->
                  <!--                      <el-button size="mini" type="text" class="search-custom-icon"-->
                  <!--                                 @click="handleSycmTrend(scope.row.itemName)"></el-button>-->
                  <!--                    </template>-->
                  <!--                  </el-table-column>-->

                </el-table>
              </div>
              <div v-if="SpqdName=='5' ">
                <el-table
                  :data="SpqdTableDataDy"
                  border
                  fit
                  height="460px"
                  row-class-name="customCellClass"
                >
                  <el-table-column
                    prop="itemRank"
                    label="排名"
                    width="auto"
                    :min-width="10"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    v-if="radioPt==='2'"
                    prop="itemKeyword"
                    label="热点词"
                    align="center"
                    width="auto"
                    :min-width="18"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="commodityName"
                    label="商品名称"
                    width="auto"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <a :href="scope.row.url" target="_blank" style="color: #1f85ee">{{
                          scope.row.commodityName
                        }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="shopName"
                    label="所属店铺"
                    width="auto"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <a :href="scope.row.shopUrl" target="_blank" style="color: #1f85ee">{{ scope.row.shopName }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="commentCountStr"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="评论数">
                  </el-table-column>
                  <el-table-column
                    prop="showPrice"
                    align="center"
                    width="auto"
                    :min-width="10"
                    label="价格">
                  </el-table-column>
                </el-table>
              </div>
              <el-pagination
                style="padding-top: 2%;"
                background
                :current-page="smsFailpageQW"
                :page-size="smsFailpageSizeQW"
                :total="smsFailtotalQW"
                layout="total, prev, pager, next"
                @current-change="smsFailHandlePageChangeQW"
              >
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <div v-show="activeIndex === 'third'" style="padding-right: 1%;padding-left: 1%;">
      <div class="column-container" style="font-size: 15px;padding-top: 1%;color: #333333 ;font-size: 13px">
        <div class="columnDyht">
          <div class="ranking-box-dy">
            <h3 style="display: flex;margin-top: 1px;color: #000000;font-size: 14px;opacity: 0.85">抖音热门话题</h3>
            <el-row style="font-weight: bold;padding-bottom: 3%;height: 80px">
              <el-col :span="6" class="dy-center">
                <span>排名</span>
              </el-col>
              <el-col :span="5" class="dy-center">
                <span>抖音话题</span>
              </el-col>
              <el-col :span="4" class="dy-right">
                <span>最新浏览量</span>
              </el-col>
              <el-col :span="8" class="dy-center">
                <span>手机端链接</span>
              </el-col>
            </el-row>
            <el-row v-for="item in tableDataDYHT" :key="item.topicRank" style="height: 30px">
              <el-col :span="6" class="dy-center">
                <div :class="sortDa(item.topicRank)">
                  {{ item.topicRank }}
                </div>
              </el-col>
              <el-col :span="5" class="dy-center">
                <div>
                  {{ item.toppicName }}
                </div>
              </el-col>
              <el-col :span="4" class="dy-right">
                <div>
                  {{ item.viewCount }}
                  <!--                  {{ item.viewCount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}-->
                </div>
              </el-col>
              <el-col :span="8" class="dy-center">
                <div style="margin-top: -10px; margin-bottom: -10px;">
                  <el-button type="text" @click="openModal(item)" class="text-sele-col">查看</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="columnDyht">
          <div class="ranking-box-dy">
            <h3 style="display: flex;margin-top: 1px;color: #000000;font-size: 14px;opacity: 0.85">抖音增长话题</h3>
            <el-row style="font-weight: bold;padding-bottom: 3%;height: 80px">
              <el-col :span="6" class="dy-center">
                <span>排名</span>
              </el-col>
              <el-col :span="5" class="dy-center">
                <span>抖音话题</span>
              </el-col>
              <el-col :span="4" class="dy-right">
                <span>近一周浏览量占比增幅</span>
              </el-col>
              <el-col :span="4" class="dy-right">
                <span>最新浏览量</span>
              </el-col>
              <el-col :span="6" class="dy-center">
                <span>手机端链接</span>
              </el-col>
            </el-row>
            <el-row v-for="item in tableDataDYZzht" :key="item.topicRank" style="height: 30px">
              <el-col :span="6" class="dy-center">
                <div :class="sortDa(item.topicRank)">
                  {{ item.topicRank }}
                </div>
              </el-col>
              <el-col :span="5" class="dy-center">
                <div>
                  {{ item.toppicName }}
                </div>
              </el-col>
              <el-col :span="4" class="dy-right">
                <div>
                  {{ item.lastWeekProportionIncrease }}
                </div>
              </el-col>
              <el-col :span="4" class="dy-right">
                <div>
                  {{ item.viewCount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
                </div>
              </el-col>
              <el-col :span="6" class="dy-center">
                <div style="margin-top: -10px; margin-bottom: -10px;">
                  <el-button type="text" @click="openModal(item)" class="text-sele-col">查看</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-dialog :visible.sync="modalVisible" title="抖音话题分享链接" width="400px" center>
          <p>链接为: <a style="color: #1F85EE" :href="selectedItem.shareUrl" target="_blank">{{
              selectedItem.shareUrl
            }}</a></p>
          <p>请复制到手机上，在手机上点击链接查看</p>
          <div style="display: flex; justify-content: center; margin-top: 20px;">
            <el-button type="primary" @click="copyLink">复制</el-button>
            <el-button @click="modalVisible = false">取消</el-button>
          </div>
        </el-dialog>
      </div>
      <div>
        <h3 style="display: flex;margin-top: 1px;font-size: 17.55px;padding-top: 1%">话题趋势</h3>
        <div style="display: flex; align-items: center;">
          <div style="flex: 1;">
            <span style="font-size: 13px;">话题:</span>
            <el-select style="text-align: center;justify-content: center;padding-left: 1%" v-model="dyTopic" size="mini"
                       filterable placeholder="选择" @change="selectDyTopicChanged">
              <el-option v-for="item in dyTopicOptions" :key="item" :label="item" :value="item"/>
            </el-select>
          </div>
          <div style="padding-right: 1%;padding-left: 2%;">
            <el-radio-group v-model="dyDateRangeType" @change="dyDateRangeTypeChange" size="small">
              <!--              <el-radio-button label="1">近7天</el-radio-button>
                            <el-radio-button label="2">近1个月</el-radio-button>-->
              <el-radio-button label="3">近12周</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div style="width: 100%; height: 400px;" ref="chartDyTopicRankCanvas">
        </div>
      </div>
    </div>
    <div v-show="activeIndex=='fourth' " style="padding-right: 1%;padding-left: 1%;">
      <h3 style="margin-bottom: 0px;font-size: 15px;color: #000000;opacity: 0.85;padding-bottom: 1%">热门讨论帖</h3>
      <div style="padding-bottom: 1%">
        <el-radio-group v-model="radioXhsRm" @input="changeradioXhsRm" size="small">
          <el-radio-button label="人气值TOP20"></el-radio-button>
          <el-radio-button label="最近发布TOP20"></el-radio-button>
        </el-radio-group>
      </div>
      <div v-show="radioXhsRm==='最近发布TOP20'">
        <el-table
          :data="tableDataXhsRecently"
          border
          fit
          row-class-name="customCellClass"
          style="width: 100%;margin-left: 0%;margin-right: 0%;margin-top: 2px">
          <el-table-column
            prop="artRank"
            label="排名"
            align="center"
            width="auto"
            :min-width="10"
          >
          </el-table-column>
          <el-table-column
            prop="artType"
            label="类型"
            align="center"
            width="auto"
            :min-width="10"
          >
          </el-table-column>
          <el-table-column
            prop="dataTime"
            align="center"
            width="auto"
            label="发布时间">
          </el-table-column>
          <el-table-column
            prop="title"
            align="center"
            width="auto"
            label="标题">
          </el-table-column>
          <el-table-column
            prop="subHashtags"
            align="center"
            width="auto"
            label="标签">
          </el-table-column>
          <el-table-column
            prop="subDescription"
            align="center"
            width="auto"
            label="正文">
          </el-table-column>
          <el-table-column
            prop="likesStr"
            align="center"
            width="auto"
            :min-width="15"
            label="点赞数量">
          </el-table-column>
          <el-table-column
            prop="collectsStr"
            align="center"
            width="auto"
            :min-width="15"
            label="收藏数量">
          </el-table-column>
          <el-table-column
            prop="sharecountStr"
            align="center"
            width="auto"
            :min-width="15"
            label="分享数量">
          </el-table-column>
          <el-table-column
            prop="commentsStr"
            align="center"
            width="auto"
            :min-width="15"
            label="评论数量">
          </el-table-column>
          <el-table-column
            align="center"
            width="150px"
            :min-width="10"
            label="操作">
            <template slot-scope="scope">
              <a :href="scope.row.url" target="_blank" style="color: #1F85EE;padding-right: 15px">{{ '查看' }}</a>
              <el-button style="color: #1F85EE" size="mini" type="text" @click="handleXhsTrend(scope.row.id)">历史趋势
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="padding-top: 2%;padding-bottom: 2%"
          background
          :current-page="xhsTopicRankPageQWRecently"
          :page-size="xhsTopicRankPageSizeQWRecently"
          :total="xhsTopicRankTotalQWRecently"
          layout="total, prev, pager, next"
          @current-change="xhsTopicRankHandlePageChangeQWRecently"
        ></el-pagination>
      </div>
      <div v-show="radioXhsRm==='人气值TOP20'">
        <el-table
          :data="tableDataXhs"
          border
          row-class-name="customCellClass"
          fit
          style="width: 100%;margin-left: 0%;margin-right: 0%;margin-top: 2px">
          <el-table-column
            prop="artRank"
            label="排名"
            align="center"
            width="auto"
            :min-width="10"
          >
          </el-table-column>
          <el-table-column
            prop="artType"
            label="类型"
            align="center"
            width="auto"
            :min-width="10"
          >
          </el-table-column>
          <el-table-column
            prop="dataTime"
            align="center"
            width="auto"
            label="发布时间">
          </el-table-column>
          <el-table-column
            prop="title"
            align="center"
            width="auto"
            label="标题">
          </el-table-column>
          <el-table-column
            prop="subHashtags"
            align="center"
            width="auto"
            label="标签">
          </el-table-column>
          <el-table-column
            prop="subDescription"
            align="center"
            width="auto"
            label="正文">
          </el-table-column>
          <el-table-column
            prop="likesStr"
            align="center"
            width="auto"
            :min-width="15"
            label="点赞数量">
          </el-table-column>
          <el-table-column
            prop="collectsStr"
            align="center"
            width="auto"
            :min-width="15"
            label="收藏数量">
          </el-table-column>
          <el-table-column
            prop="sharecountStr"
            align="center"
            width="auto"
            :min-width="15"
            label="分享数量">
          </el-table-column>
          <el-table-column
            prop="commentsStr"
            align="center"
            width="auto"
            :min-width="15"
            label="评论数量">
          </el-table-column>
          <el-table-column
            align="center"
            width="150px"
            :min-width="10"
            label="操作">
            <template slot-scope="scope">
              <a :href="scope.row.url" target="_blank" style="color: #1F85EE;padding-right: 15px">{{ '查看' }}</a>
              <el-button style=";color: #1F85EE" size="mini" type="text" @click="handleXhsTrend(scope.row.id)">
                历史趋势
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="padding-top: 2%;padding-bottom: 2%"
          background
          :current-page="xhsTopicRankPageQW"
          :page-size="xhsTopicRankPageSizeQW"
          :total="xhsTopicRankTotalQW"
          layout="total, prev, pager, next"
          @current-change="xhsTopicRankHandlePageChangeQW"
        ></el-pagination>
      </div>
      <div class="ranking-box">
        <h3 style="margin-bottom: 0px;font-size: 15px;color: #000000;opacity: 0.85;padding-bottom: 1%">热点词云</h3>
        <div class="wordcloud-container">
          <img class="wordcloud-image" :src="cloudImageURL" alt="Word Cloud"/>
        </div>
      </div>
    </div>
    <el-dialog class="custom-dialog" :title="'关键词: '+ keywordsRankTrendDialog.itemKeyword"
               :visible.sync="keywordsRankTrendDialog.visible" top="10vh">
      <span style="font-size: 14px;font-weight: bold">排名周趋势</span>
      <div style="width: 100%; height: 30vh" ref="chartkeywordsRankTrendCanvas">
      </div>
      <hr width="100%" color=#AAAAAA SIZE=1>
      <span style="font-size: 14px;font-weight: bold;">热度周趋势</span>
      <div style="padding-top: 1%">
        <template v-if="hotKeywordsPlatform === '全平台'">
          <el-checkbox-group v-model="checkedPlatforms"
                             @change="handlePlatformsChange(keywordsRankTrendDialog.itemKeyword)">
            <el-checkbox label="全平台"></el-checkbox>
            <el-checkbox label="电商平台"></el-checkbox>
            <el-checkbox label="抖音话题"></el-checkbox>
            <el-checkbox label="小红书"></el-checkbox>
          </el-checkbox-group>
        </template>
      </div>
      <div style="width: 100%; height: 30vh" ref="chartkeywordsHeatRankTrendCanvas">
      </div>
    </el-dialog>

    <el-dialog class="custom-dialog"
               :visible.sync="sycmTrendDialog.visible" top="10vh">
      <div>
        <span style="font-size: 14px;font-weight: bold">商品名称：{{ sycmTrendDialog.itemName }}</span>
      </div>
      <div style="margin-top: 2%">
        <span style="font-size: 14px;font-weight: bold">价格趋势</span>
      </div>
      <div style="width: 100%; height: 30vh" ref="chartSycmPriceTrendCanvas">
      </div>
      <hr width="100%" color=#AAAAAA SIZE=1>
      <div style="margin-top: 2%">
        <span style="font-size: 14px;font-weight: bold">月销趋势</span>
      </div>
      <div style="width: 100%; height: 30vh" ref="chartSycmSellCountTrendCanvas">
      </div>
    </el-dialog>

    <el-dialog class="custom-dialog"
               :visible.sync="sycmTrendDialogRdcDyht.visible" top="10vh">
      <div>
        <span style="font-size: 14px;font-weight: bold">话题趋势</span>
      </div>
      <div>
        <span style="font-size: 13px;font-weight: bold;padding-top: 1%">话题：{{
            sycmTrendDialogRdcDyht.topicName
          }}</span>
      </div>

      <div style="width: 100%; height: 30vh" ref="chartSycmPriceTrendCanvasRdcDyht">
      </div>
    </el-dialog>

    <el-dialog class="custom-dialog"
               :visible.sync="xhsTrendDialog.visible" top="10vh">
      <div style="overflow-y: scroll;height: 500px">
        <div style="margin-top: 2%">
          <span style="font-size: 14px;font-weight: bold">点赞趋势</span>
        </div>
        <div style="width: 100%; height: 30vh" ref="chartXhsLikesTrendCanvas">
        </div>
        <hr width="100%" color=#AAAAAA SIZE=1>
        <div style="margin-top: 2%">
          <span style="font-size: 14px;font-weight: bold">评论趋势</span>
        </div>
        <div style="width: 100%; height: 30vh" ref="chartXhsCollectTrendCanvas">
        </div>
        <hr width="100%" color=#AAAAAA SIZE=1>
        <div style="margin-top: 2%">
          <span style="font-size: 14px;font-weight: bold">分享趋势</span>
        </div>
        <div style="width: 100%; height: 30vh" ref="chartXhsShareTrendCanvas">
        </div>
        <hr width="100%" color=#AAAAAA SIZE=1>
        <div style="margin-top: 2%">
          <span style="font-size: 14px;font-weight: bold">评论趋势</span>
        </div>
        <div style="width: 100%; height: 30vh" ref="chartXhsCommentTrendCanvas">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  getCDGroupData,
  getCDGroupDataMidCategory,
  getCDGroupDataThirdCategory,
  getRankingChangeClass,
  getyDHotTopicClass,
  getyDIncreaseClass,
  getXhsHotTopicClass,
  getItemOtherPlatformsClass,
  getItemSycmClass,
  getItemJdszClass,
  getStationeryClassifyKeywordsListClass,
  getStationeryClassifyTreeDataList,
  getDyTopicTrendListDataList,
  queryKeywordsRankTrendList,
  queryDyTopicList,
  queryKeywordsHeatRankTrendList,
  queryZjfbTopic,
  querySycmPriceTrendList,
  querySycmSellCountTrendList,
  queryXhsLikesTrendList,
  queryXhsCollectTrendList,
  queryXhsShareTrendList,
  queryXhsCommentTrendList,
  queryKeywordsOtherPlatformsList,
  queryKeywordsSycmList,
  queryKeywordsJdszList,
  queryKeywordsXhsTopicList,
  queryKeywordsDyTopicList,
  queryKeywordsOtherPlatformsListExport,
  queryKeywordsSycmListExport,
  queryKeywordsJdszListExport,
  queryKeywordsXhsTopicListExport,
  queryKeywordsDyTopicListExport,
  queryItemSycmListExport,
  queryItemOtherPlatformsListExport,
  queryItemJdszListExport,
  queryKeywordsMgItemSellTrendList,
  queryKeywordsMgItemStatistics,
  queryKeywordsMgItemList,
  queryKeywordsMgItemListExport,
  queryStationeryClassifySpecialTreeDataList,
  getItemTmClass,
  queryTmItemListExport,
  queryKeywordsMgBrandItemTrendList,
  queryKeywordsMgBrandItemStatistics, queryKeywordsMgBrandItemList, queryKeywordsMgBrandItemListExport,
  queryKeywordsMgBrandSkucountStatistics, queryKeywordsBrandRankListStatistics,
} from "@/api/marketData";
import * as echarts from 'echarts'
import {debounce} from 'lodash';


export default {

  data() {
    return {

      props: {
        lazy: true,
        lazyLoad: this.loadSubcategoriesData
      },
      firstLevelOptions: [],
      secondLevelOptions: [],
      thirdLevelOptions: [],
      // selectedOptions: ['修正类', '修正','修正带'],
      selectedOptions: ['修正类', '修正带'],
      tableData: [],
      tableDataPD: [],
      tableDataDYHT: [],
      tableDataXhs: [],
      tableDataXhsRecently: [],
      tableDataDYZzht: [],
      columnsList: [[], [], []],
      columnsListPD: [[], [], []],
      SpqdName: '4',
      SpqdNameRdcPt: '4',
      SpqdTableDataDy: [],
      SpqdTableDataRdcSp: [],
      SpqdTableDataRdcSpDysp: [],
      SpqdTableDataRdcSpPddsp: [],
      SpqdTableDataRdcSpKssp: [],
      SpqdTableDataRdcSpTmsp: [],
      SpqdTableDataRdcSpJdsp: [],
      SpqdTableDataRdcSpDyht: [],
      SpqdTableDataRdcSpXhsTz: [],
      spiderKeywords: '',
      levelOptions: [],
      radioPt: '2',
      allRadioPt: '2',
      Ptvalue: '',
      Rdcvalue: '',
      Ptoptions: [],
      PtoptionsRdc: [],
      smsFailpageQW: 1,
      smsFailpageSizeQW: 10,
      smsFailtotalQW: 1,
      xhsTopicRankPageQW: 1,
      xhsTopicRankPageQWRecently: 1,
      xhsTopicRankPageSizeQW: 10,
      xhsTopicRankPageSizeQWRecently: 10,
      xhsTopicRankTotalQW: 1,
      xhsTopicRankTotalQWRecently: 1,
      dyHorizontalAxis: [],
      dyLongitudinalAxis: [],
      chartDyTopicRankInstance: null, // 图表实例
      chartRdcCommRankInstance: null, // 图表实例
      chartRdcCommRankInstanceLast12Week: null, // 图表实例
      chartDsptCommRankInstance: null, // 图表实例
      chartKeywordsRankTrendInstance: null, // 图表实例
      chartKeywordsHeatRankTrendInstance: null, // 图表实例
      chartSycmPriceTrendInstance: null, // 图表实例
      chartSycmSellCountTrendInstance: null, // 图表实例
      chartXhsLikesTrendInstance: null, // 图表实例
      chartXhsCollectTrendInstance: null, // 图表实例
      chartXhsShareTrendInstance: null, // 图表实例
      chartXhsCommentTrendInstance: null, // 图表实例
      keywordsRankTrendList: [],
      keywordsRankTrendDialog: {
        visible: false,
        itemKeyword: '',
      },
      keywordsRankTrendOption: {
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true
          }
        ]
      },
      dyTopicOptions: [],
      dyTopic: '',
      dyDateRangeType: '3',
      keywordsRankTrendYMin: undefined,
      keywordsRankTrendYMax: undefined,
      keywordsRankTrendYInterval: undefined,
      checkedPlatforms: ['全平台'],
      platformOptions: ['全平台', '电商平台', '抖音话题', '小红书'],
      keywordsHeatRankTrendYMin: undefined,
      keywordsHeatRankTrendYMax: undefined,
      keywordsHeatRankTrendYInterval: undefined,
      hotKeywordsPlatform: '',
      dyTopicRankYMin: undefined,
      rdcCommRankYMin: undefined,
      dsptCommRankYMin: undefined,
      dyTopicRankYMax: undefined,
      rdcCommRankYMax: undefined,
      dsptCommRankYMax: undefined,
      dyTopicRankYInterval: undefined,
      rdcCommRankYInterval: undefined,
      dsptCommRankYInterval: undefined,
      activeIndex: 'first',
      cloudImageURL: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/wordcloud_keyword_xhs_te.png',
      radioXhsRm: '人气值TOP20',
      sycmTrendDialog: {
        visible: false,
        itemName: '',
      },
      sycmTrendDialogRdcDyht: {
        visible: false,
        topicName: '',
      },
      sycmPriceTrendYMin: undefined,
      sycmPriceTrendYMinRdcDyht: undefined,
      sycmPriceTrendYMax: undefined,
      sycmPriceTrendYMaxRdcDyht: undefined,
      sycmPriceTrendYInterval: undefined,
      sycmPriceTrendYIntervalRdcDyht: undefined,

      sycmSellCountTrendYMin: undefined,
      sycmSellCountTrendYMax: undefined,
      sycmSellCountTrendYInterval: undefined,
      modalVisible: false,
      selectedItem: {},
      xhsTrendDialog: {
        visible: false,
      },
      xhsLikesTrendYMin: undefined,
      xhsLikesTrendYMax: undefined,
      xhsLikesTrendYInterval: undefined,
      xhsCollectTrendYMin: undefined,
      xhsCollectTrendYMax: undefined,
      xhsCollectTrendYInterval: undefined,
      xhsShareTrendYMin: undefined,
      xhsShareTrendYMax: undefined,
      xhsShareTrendYInterval: undefined,
      xhsCommentTrendYMin: undefined,
      xhsCommentTrendYMax: undefined,
      xhsCommentTrendYInterval: undefined,
      ifTm: undefined,
      scRdcDataPageDysp: 1,
      scRdcDataPagePddsp: 1,
      scRdcDataPageKssp: 1,
      scRdcDataPageTmsp: 1,
      scRdcDataPageJdsp: 1,
      scRdcDataPageDyht: 1,
      scRdcDataPageXhstz: 1,
      scRdcDataPageMgComm: 1,
      scRdcDataPageSize: 10,
      scRdcDataPageTotleDysp: 1,
      scRdcDataPageTotlePddsp: 1,
      scRdcDataPageTotleKssp: 1,
      scRdcDataPageTotleTmsp: 1,
      scRdcDataPageTotleJdsp: 1,
      scRdcDataPageTotleDyht: 1,
      scRdcDataPageTotleXhstz: 1,
      scRdcDataPageTotleMgComm: 1,
      rdcCommDataDowloadling: false,
      rdcMgCommDataDowloadling: false,
      rdcCurrentRow: '',
      dsptCurrentRow: '',
      rdcQsTr: false,
      dspyQsTr: false,
      dialogTableVisibleRdc: false,
      dialogTableVisibleDspt: false,
      rdcCommDataList: [],
      rdcDialogSpqd: {'hbLastWeekSellProportion': ''},
      isLoadingRdc: false,
      isLoadingDspt: false,
      debouncedHandleCurrentChangeRdc: null,
      debouncedHandleCurrentChangeDspt: null,
      brandKeywordSwitch: false,
      simplybrandKeywordSwitch: false,
      brandItemKeywordSwitch: false,
      simplybrandItemKeywordSwitch: false,
      ifQbtFlag: 'Y',
      dsTm: 'True',
      dsJd: 'True',
      dsPdd: 'True',
      dsKs: 'True',
      dsDysp: 'True',
      qwTm: 'True',
      qwKs: 'True',
      qwJd: 'True',
      qwPdd: 'True',
      qwDyht: 'True',
      qwDySp: 'True',
      qwXhs: 'True',
      mgPenCommNumCount: 'True',
    };
  },
  computed: {},
  watch: {},

  created() {
    console.log("已经创建了这个组件");
    // this.getFirstLevel()
    this.getConsumeStatistics();
    this.debouncedHandleCurrentChangeRdc = debounce(this.rdcHandleCurrentChange, 500);
    this.debouncedHandleCurrentChangeDspt = debounce(this.dsptHandleCurrentChange, 500);

  },
  methods: {
    getDsptMgCommList() {
      console.log('getDsptMgCommList')
      this.dialogTableVisibleDspt = true
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: this.Ptvalue,
        limit: this.scRdcDataPageSize,
        page: this.scRdcDataPageMgComm
      }
      // queryKeywordsMgItemList(json_data).then(res => {
      //   this.rdcCommDataList = res.data.datas
      //   this.scRdcDataPageTotleMgComm = res.data.total
      //   this.$nextTick(() => {
      //     // 在表格渲染之后绘制图表
      //     this.rdcCommDataList.forEach(row => {
      //       const chartId = `chartRdcCommRankCanvasLast12Week_${row.skuCode}`;
      //       console.log('chartId')
      //       console.log(chartId)
      //       const chartContainer = this.$refs[chartId];
      //       if (chartContainer) {
      //         const chartInstance = echarts.init(chartContainer);
      //         // 使用row数据绘制曲线图
      //         const data1 = row.itemSellProportionTrends.itemSellProportionTrendDtos.map(i => i.statsDate)
      //         const data2 = row.itemSellProportionTrends.itemSellProportionTrendDtos.map(i => parseFloat(i.itemSellProportion.replace('%', '')))
      //         // 例如：将row数据中的某些字段提取为曲线图的坐标点数据
      //
      //         const option = {
      //           xAxis: {
      //             show: false, // 将横坐标轴隐藏
      //             type: 'category',
      //             data: data1
      //           },
      //           yAxis: {
      //             show: false, // 将纵坐标轴隐藏
      //             type: 'value'
      //           },
      //           series: [
      //             {
      //               data: data2,
      //               type: 'line',
      //               smooth: true,
      //               symbol: "none"
      //             }
      //           ],
      //           grid: {
      //             show: false, // 将绘图网格隐藏
      //             top: 0,
      //             right: 0,
      //             bottom: 0,
      //             left: 0
      //           }
      //         };
      //         chartInstance.setOption(option);
      //       }
      //     });
      //   });
      // })

      queryKeywordsMgBrandItemList(json_data).then(res => {
        this.rdcCommDataList = res.data.datas
        this.scRdcDataPageTotleMgComm = res.data.total
      })


      // queryKeywordsMgItemStatistics(json_data).then(res => {
      //   this.rdcDialogSpqd = res.data
      //   console.log('console.log(this.rdcDialogSpqd)')
      //   console.log(this.rdcDialogSpqd)
      // })

      queryKeywordsMgBrandItemStatistics(json_data).then(res => {
        this.rdcDialogSpqd = res.data
        console.log('console.log(this.rdcDialogSpqd)')
        console.log(this.rdcDialogSpqd)
      })


    },
    getRdcCommList() {
      console.log('getRdcCommList')
      this.dialogTableVisibleRdc = true
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: this.Rdcvalue,
        limit: this.scRdcDataPageSize,
        page: this.scRdcDataPageMgComm
      }
      // queryKeywordsMgItemList(json_data).then(res => {
      //   this.rdcCommDataList = res.data.datas
      //   this.scRdcDataPageTotleMgComm = res.data.total
      //   this.$nextTick(() => {
      //     // 在表格渲染之后绘制图表
      //     this.rdcCommDataList.forEach(row => {
      //       const chartId = `chartRdcCommRankCanvasLast12Week_${row.skuCode}`;
      //       const chartContainer = this.$refs[chartId];
      //       if (chartContainer) {
      //         const chartInstance = echarts.init(chartContainer);
      //
      //         // 使用row数据绘制曲线图
      //         const data1 = row.itemSellProportionTrends.itemSellProportionTrendDtos.map(i => i.statsDate)
      //         const data2 = row.itemSellProportionTrends.itemSellProportionTrendDtos.map(i => parseFloat(i.itemSellProportion.replace('%', '')))
      //         // 例如：将row数据中的某些字段提取为曲线图的坐标点数据
      //
      //         const option = {
      //           xAxis: {
      //             show: false, // 将横坐标轴隐藏
      //             type: 'category',
      //             data: data1
      //           },
      //           yAxis: {
      //             show: false, // 将纵坐标轴隐藏
      //             type: 'value'
      //           },
      //           series: [
      //             {
      //               data: data2,
      //               type: 'line',
      //               smooth: true,
      //               symbol: "none"
      //             }
      //           ],
      //           grid: {
      //             show: false, // 将绘图网格隐藏
      //             top: 0,
      //             right: 0,
      //             bottom: 0,
      //             left: 0
      //           }
      //         };
      //         chartInstance.setOption(option);
      //       }
      //     });
      //   });
      // })

      queryKeywordsMgBrandItemList(json_data).then(res => {
        this.rdcCommDataList = res.data.datas
        this.scRdcDataPageTotleMgComm = res.data.total
      })

      // queryKeywordsMgItemStatistics(json_data).then(res => {
      //   this.rdcDialogSpqd = res.data
      //   console.log('console.log(this.rdcDialogSpqd)')
      //   console.log(this.rdcDialogSpqd)
      // })

      queryKeywordsMgBrandItemStatistics(json_data).then(res => {
        this.rdcDialogSpqd = res.data
        console.log('console.log(this.rdcDialogSpqd)')
        console.log(this.rdcDialogSpqd)
      })


    },
    setCurrentRdc(row) {
      this.$refs.singleTableRdc.setCurrentRow(row);
    },
    setCurrentDspt(row) {
      this.$refs.singleTableDspt.setCurrentRow(row);
    },
    tableRowClassName({row, rowIndex}) {
      return 'backgroud-row';
    },
    rdcHandleCurrentChange(currentRow, oldCurrentRow) {
      console.log(currentRow)
      console.log(oldCurrentRow)
      this.isLoadingRdc = true;
      this.rdcCurrentRow = currentRow
      this.scRdcDataPageMgComm = 1
      this.Rdcvalue = currentRow.itemKeyword
      this.getPdZhFl()
      this.getRdcCommDataPt()
      this.rdcCommDateRangeTypeChange(currentRow.itemKeyword)

    },
    dsptHandleCurrentChange(currentRow, oldCurrentRow) {
      console.log(oldCurrentRow)
      // if (this.isLoadingDspt) {
      //   // 请求未完成，忽略当前切换
      //   return;
      // }
      this.isLoadingDspt = true;
      console.log('this.isLoadingDspt')
      console.log(this.isLoadingDspt)
      this.dsptCurrentRow = currentRow
      this.scRdcDataPageMgComm = 1
      this.Ptvalue = currentRow.itemKeyword
      this.radioPt = '2'
      this.SpqdName = '4'
      this.smsFailpageQW = 1
      this.getDsptSpPtList()
      this.ItemSycmClass()
      this.dsptCommDateRangeTypeChange(currentRow.itemKeyword)

    },
    loadSubcategoriesData(node, resolve) {
      console.log('loadSubcategoriesData')
      const {level, value} = node;
      console.log('value')
      console.log(level)
      console.log(value)

      if (level === 0) {
        // Load first-level categories
        getCDGroupData().then(res => {
          this.firstLevelOptions = res.data.map(category => ({
            value: category,
            label: category,
            leaf: false
          }));
          console.log('this.firstLevelOptions ')
          console.log(this.firstLevelOptions)
          resolve(this.firstLevelOptions);
        });
      } else if (level === 1) {
        // Load subcategories for selected first-level category
        const selectedFirstLevelCategory = this.firstLevelOptions.find(category => category.value === value);
        if (selectedFirstLevelCategory) {
          const jsonData = {
            oneCategory: selectedFirstLevelCategory.value
          }
          getCDGroupDataMidCategory(jsonData).then(res => {
            this.secondLevelOptions = res.data.map(subcategory => ({
              value: subcategory,
              label: subcategory,
              leaf: level === 2 // If it's the third level, mark as a leaf
            }));
            resolve(this.secondLevelOptions);
          });
        }
      } else {
        const selectedSecondLevelCategory = this.secondLevelOptions.find(category => category.value === value);
        if (selectedSecondLevelCategory) {
          const jsonData = {
            midCategory: selectedSecondLevelCategory.value
          }
          getCDGroupDataThirdCategory(jsonData).then(res => {
            const subcategories = res.data.map(subcategory => ({
              value: subcategory,
              label: subcategory,
              leaf: 'leaf' // If it's the third level, mark as a leaf
            }));
            resolve(subcategories);
          });
        }
      }
    },
    RankingChangeClass(change) {
      return {
        'ranking-change-cell': true,
        'increase': change > 0,
        'decrease': change < 0
      };
    },
    getArrowIconClass(change) {
      return {
        'arrow-icon': true,
        'increase': change > 0,
        'decrease': change < 0
      };
    },
    sortDa(rank) {
      const rankN = parseInt(rank)
      if (rankN == 1) {
        return 'rank-first-level'
      } else if (rankN == 2) {
        return 'rank-second-level'
      } else if (rankN == 3) {
        return 'rank-third-level'
      } else {
        return 'rank-else-level'
      }
    },
    getArrowIconClassNum(numericValue) {
      // const percentageValue = rank.toString().match(/([-+]?[0-9]*\.?[0-9]+)%/);
      //
      // if (percentageValue) {
      //   // 将百分比部分转换为数值
      //   const numericValue = parseFloat(percentageValue[1]) / 100;
      // console.log('numericValue')
      // console.log(numericValue)
      if (numericValue > 0) {
        return 'green-font';
      } else if (numericValue < 0) {
        return 'red-font';
      }
      // }
      // return '';
    },

    getKeywordsRankList() {
      console.log('getKeywordsRankList')
      console.log(this.selectedOptions)
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '全平台',
        brandKeywordSwitch: this.brandKeywordSwitch,
      }
      getRankingChangeClass(json_data).then(res => {
        this.tableData = res.data.keywordsDetailsDtos
        this.PtoptionsRdc = res.data.keywordsDetailsDtos
        this.Rdcvalue = this.PtoptionsRdc[0].itemKeyword
        this.ifQbtFlag = res.data.ifQbt
        this.allRadioPt = '2'
        this.getRdcCommDataPt()
        console.log('this.tableData')
        console.log(this.tableData)
        this.columnsList = [[], [], []];
        this.tableData.forEach((item, index) => {
          if (index < 30) {
            this.columnsList[0].push(item);
          } else if (index >= 30 && index < 60) {
            this.columnsList[1].push(item);
          } else {
            this.columnsList[2].push(item);
          }
        });
        this.setCurrentRdc(this.columnsList[0][0])
      })

    },
    simplyfirstgetKeywordsRankList() {
      console.log('simplyfirstgetKeywordsRankList')
      console.log(this.selectedOptions)

      if (this.simplybrandKeywordSwitch == true) {
        const json_data = {
          oneCategory: this.selectedOptions[0],
          // midCategory: this.selectedOptions[1],
          thirdCategory: this.selectedOptions[1],
          platformType: '全平台',
          brandKeywordSwitch: this.simplybrandKeywordSwitch,
        }
        queryKeywordsBrandRankListStatistics(json_data).then(res => {
          this.tableData = res.data.keywordsDetailsDtos
          this.PtoptionsRdc = res.data.keywordsDetailsDtos
          this.Rdcvalue = this.PtoptionsRdc[0].itemKeyword
          this.ifQbtFlag = res.data.ifQbt
          this.allRadioPt = '2'
          this.getRdcCommDataPt()
          console.log('this.tableData')
          console.log(this.tableData)
          this.columnsList = [[], [], []];
          this.tableData.forEach((item, index) => {
            if (index < 30) {
              this.columnsList[0].push(item);
            } else if (index >= 30 && index < 60) {
              this.columnsList[1].push(item);
            } else {
              this.columnsList[2].push(item);
            }
          });
          this.setCurrentRdc(this.columnsList[0][0])
        })
      }
    },
    stationeryClassifyTreeDataList() {
      // getStationeryClassifyTreeDataList().then(res => {
      //   this.levelOptions = res.data
      // })
      queryStationeryClassifySpecialTreeDataList().then(res => {
        this.levelOptions = res.data
      })
    },
    getConsumeStatistics() {
      this.columnsList = [[], [], []];
      this.columnsListPD = [[], [], []];
      this.Ptoptions = [];
      this.PtoptionsRdc = [];
      this.Ptvalue = '';
      this.Rdcvalue = '';
      this.SpqdName = '4';
      this.SpqdNameRdcPt = '4';
      this.activeIndex = 'first';
      this.xhsTopicRankPageQW = 1;
      this.xhsTopicRankPageQWRecently = 1;
      this.SpqdTableDataDy = [];
      this.SpqdTableDataRdcSpDysp = []
      this.SpqdTableDataRdcSpKssp = []
      this.SpqdTableDataRdcSpPddsp = []
      this.SpqdTableDataRdcSpTmsp = []
      this.SpqdTableDataRdcSpJdsp = []
      this.SpqdTableDataRdcSpDysp = []
      this.SpqdTableDataRdcSpXhsTz = []
      this.scRdcDataPageMgComm = 1
      this.isLoadingRdc = false
      this.isLoadingDspt = false
      this.mgPenCommNumCount = 'True'
      this.stationeryClassifyTreeDataList();
      if (this.simplybrandKeywordSwitch == true) {
        this.simplyfirstgetKeywordsRankList()
      } else {
        this.getKeywordsRankList()
      }
      if (this.simplybrandItemKeywordSwitch == true) {
        this.brandItemKeywordSwitch = false
        this.getSimplyKeywordsRankListDS()
      }else {
        this.getKeywordsRankListDS()
      }
      this.stationeryClassifyKeywordsListClass();
      this.getKeywordsMgBrandSkucountStatistics()
    },
    getKeywordsMgBrandSkucountStatistics() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],

      }
      queryKeywordsMgBrandSkucountStatistics(json_data).then(res => {
        const mgpenCommcounttotle = res.data.skuCount
        if (mgpenCommcounttotle < 20) {
          this.mgPenCommNumCount = 'False'
          console.log('*****mgPenCommNumCount*****:False')
          console.log(mgpenCommcounttotle)
        } else {
          console.log('*****mgPenCommNumCount*****')
          console.log(mgpenCommcounttotle)
        }
      })
    },
    getRdcCommDataDowload() {
      const pt = this.SpqdNameRdcPt
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        itemKeyword: this.Rdcvalue,
        type: this.allRadioPt,
      }
      this.rdcCommDataDowloadling = true
      if (pt === '1') {
        // json_data.platformType = '抖音商品'
        json_data.platformType = '抖音'
        queryItemOtherPlatformsListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '2') {
        json_data.platformType = '拼多多'
        queryItemOtherPlatformsListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '3') {
        json_data.platformType = '快手'
        queryItemOtherPlatformsListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '4') {
        json_data.platformType = '天猫'
        // json_data.type = '2'
        // queryKeywordsSycmListExport(json_data).then(resJson => {
        //   const downloadLink = resJson.data;
        //   const code = resJson.code;
        //   if (code === 200) {
        //     // 触发文件下载
        //     window.location.href = downloadLink
        //     // download(downloadLink);
        //   }
        // })
        queryTmItemListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '5') {
        json_data.platformType = '京东'
        queryItemJdszListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '6') {
        json_data.platformType = '抖音话题'
        queryKeywordsDyTopicListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '7') {
        json_data.platformType = '小红书'
        queryKeywordsXhsTopicListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      }
      this.rdcCommDataDowloadling = false
    },
    getRdcMgCommDataDowload() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: this.Rdcvalue,
      }
      // queryKeywordsMgItemListExport(json_data).then(resJson => {
      //   const downloadLink = resJson.data;
      //   const code = resJson.code;
      //   if (code === 200) {
      //     // 触发文件下载
      //     window.location.href = downloadLink
      //     // download(downloadLink);
      //   }
      // })

      queryKeywordsMgBrandItemListExport(json_data).then(resJson => {
        const downloadLink = resJson.data;
        const code = resJson.code;
        if (code === 200) {
          // 触发文件下载
          window.location.href = downloadLink
          // download(downloadLink);
        }
      })
    },
    getDsptMgCommDataDowload() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: this.Ptvalue,
      }
      // queryKeywordsMgItemListExport(json_data).then(resJson => {
      //   const downloadLink = resJson.data;
      //   const code = resJson.code;
      //   if (code === 200) {
      //     // 触发文件下载
      //     window.location.href = downloadLink
      //     // download(downloadLink);
      //   }
      // })

      queryKeywordsMgBrandItemListExport(json_data).then(resJson => {
        const downloadLink = resJson.data;
        const code = resJson.code;
        if (code === 200) {
          // 触发文件下载
          window.location.href = downloadLink
          // download(downloadLink);
        }
      })
    },
    getDsptCommDataDowload() {
      const pt = this.SpqdName
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        itemKeyword: this.Ptvalue,
        type: this.radioPt
      }
      this.rdcCommDataDowloadling = true
      if (pt === '1') {
        json_data.platformType = '抖音'
        queryItemOtherPlatformsListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '2') {
        json_data.platformType = '拼多多'
        queryItemOtherPlatformsListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '3') {
        json_data.platformType = '快手'
        queryItemOtherPlatformsListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '4') {
        json_data.platformType = '天猫'
        // queryItemSycmListExport(json_data).then(resJson => {
        //   const downloadLink = resJson.data;
        //   const code = resJson.code;
        //   if (code === 200) {
        //     // 触发文件下载
        //     window.location.href = downloadLink
        //     // download(downloadLink);
        //   }
        // })
        queryTmItemListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      } else if (pt === '5') {
        json_data.platformType = '京东'
        queryItemJdszListExport(json_data).then(resJson => {
          const downloadLink = resJson.data;
          const code = resJson.code;
          if (code === 200) {
            // 触发文件下载
            window.location.href = downloadLink
            // download(downloadLink);
          }
        })
      }
      this.rdcCommDataDowloadling = false
    },
    getPdZhFl() {
      // 判断商品是否有数据
      const json_data = {
        limit: this.scRdcDataPageSize,
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        page: 1,
        itemKeyword: this.Rdcvalue,
        type: '1',
      }
      json_data.platformType = '抖音'
      getItemOtherPlatformsClass(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwDySp = "False"
        } else {
          this.qwDySp = "True"
        }
      })
      json_data.platformType = '拼多多'
      getItemOtherPlatformsClass(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwPdd = "False"
        } else {
          this.qwPdd = "True"
        }
      })
      json_data.platformType = '快手'
      getItemOtherPlatformsClass(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwKs = "False"
        } else {
          this.qwKs = "True"
        }
      })
      json_data.platformType = '天猫'
      getItemTmClass(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwTm = "False"
          this.SpqdNameRdcPt = '5'
          this.handleAllRadioPtInput()
        } else {
          this.qwTm = "True"
        }
      })
      json_data.platformType = '京东'
      getItemJdszClass(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwJd = "False"
        } else {
          this.qwJd = "True"
        }
      })
      json_data.platformType = '抖音话题'
      queryKeywordsDyTopicList(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwDyht = "False"
        } else {
          this.qwDyht = "True"
        }
      })
      json_data.platformType = '小红书'
      queryKeywordsXhsTopicList(json_data).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          this.qwXhs = "False"
        } else {
          this.qwXhs = "True"
        }
      })
      const json_data_gl = {
        limit: this.scRdcDataPageSize,
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        page: 1,
        itemKeyword: this.Rdcvalue,
        type: '2',
      }
      json_data_gl.platformType = '天猫'
      getItemTmClass(json_data_gl).then(res => {
        const totle_num = res.data.total;
        if (totle_num == 0) {
          const json_data = {
            page: 1,
            limit: this.scRdcDataPageSize,
            oneCategory: this.selectedOptions[0],
            // midCategory: this.selectedOptions[1],
            thirdCategory: this.selectedOptions[1],
            platformType: '',
            itemKeyword: this.Rdcvalue,
            type: this.allRadioPt,
          }
          json_data.platformType = '京东'
          getItemJdszClass(json_data).then(res => {
            const totle_num_jd = res.data.total
            if (totle_num_jd == 0) {
              json_data.platformType = '抖音'
              json_data.page = this.scRdcDataPageDysp
              getItemOtherPlatformsClass(json_data).then(res => {
                const totle_num_dy = res.data.total
                if (totle_num_dy == 0) {
                  json_data.platformType = '拼多多'
                  getItemOtherPlatformsClass(json_data).then(res => {
                    const totle_num_pdd = res.data.total
                    if (totle_num_pdd == 0) {
                      json_data.platformType = '快手'
                      getItemOtherPlatformsClass(json_data).then(res => {
                        const totle_num_ks = res.data.total
                        if (totle_num_ks == 0) {
                          json_data.platformType = '抖音话题'
                          queryKeywordsDyTopicList(json_data).then(res => {
                            this.SpqdTableDataRdcSpDyht = res.data.datas;
                            this.scRdcDataPageTotleDyht = res.data.total
                            const totle_num_dyht = res.data.total
                            if (totle_num_dyht == 0) {
                              json_data.platformType = '小红书'
                              queryKeywordsXhsTopicList(json_data).then(res => {
                                this.SpqdTableDataRdcSpXhsTz = res.data.datas;
                                this.scRdcDataPageTotleXhstz = res.data.total
                                const totle_num_xhs = res.data.total
                                if (totle_num_xhs == 0) {
                                  this.SpqdNameRdcPt = '4'
                                  this.handleAllRadioPtInput()
                                } else {
                                  this.SpqdNameRdcPt = '7'
                                }
                              })
                            } else {
                              this.SpqdNameRdcPt = '6'
                            }
                          })
                        } else {
                          this.SpqdNameRdcPt = '3'
                          this.handleAllRadioPtInput()
                        }
                      })
                    } else {
                      this.SpqdNameRdcPt = '2'
                      this.handleAllRadioPtInput()
                    }
                  })
                } else {
                  this.SpqdNameRdcPt = '1'
                  this.handleAllRadioPtInput()
                }
              })
            } else {
              this.SpqdNameRdcPt = '5'
              this.handleAllRadioPtInput()
            }
          })

        }
      })
      // const json_data_top = {
      //   oneCategory: this.selectedOptions[0],
      //   // midCategory: this.selectedOptions[1],
      //   thirdCategory: this.selectedOptions[1],
      //   itemKeyword: this.Rdcvalue,
      //   limit: this.scRdcDataPageSize,
      //   page: 1
      // }
      // queryKeywordsMgBrandItemList(json_data_top).then(res => {
      //   const totle_num = res.data.total;
      //   if (totle_num <= 20) {
      //     this.ifQbtFlag = "Y"
      //     console.log('******totle_num******')
      //     console.log(totle_num)
      //   } else if (totle_num >= 20)
      //     this.ifQbtFlag = "N"
      //   console.log('******totle_num******')
      //   console.log(totle_num)
      // })

    },
    getRdcCommData() {
      const pt = this.SpqdNameRdcPt
      const json_data = {
        limit: this.scRdcDataPageSize,
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        page: '',
        itemKeyword: this.Rdcvalue,
        type: this.allRadioPt,
      }
      if (pt === '1') {
        // json_data.platformType = '抖音商品'
        json_data.platformType = '抖音'
        json_data.page = this.scRdcDataPageDysp
        getItemOtherPlatformsClass(json_data).then(res => {
          this.SpqdTableDataRdcSpDysp = res.data.datas;
          this.scRdcDataPageTotleDysp = res.data.total
        })
      } else if (pt === '2') {
        json_data.platformType = '拼多多'
        json_data.page = this.scRdcDataPagePddsp
        getItemOtherPlatformsClass(json_data).then(res => {
          this.SpqdTableDataRdcSpPddsp = res.data.datas;
          this.scRdcDataPageTotlePddsp = res.data.total
        })
      } else if (pt === '3') {
        json_data.platformType = '快手'
        json_data.page = this.scRdcDataPageKssp
        getItemOtherPlatformsClass(json_data).then(res => {
          this.SpqdTableDataRdcSpKssp = res.data.datas;
          this.scRdcDataPageTotleKssp = res.data.total
        })
      } else if (pt === '4') {
        json_data.platformType = '天猫'
        json_data.page = this.scRdcDataPageTmsp
        // json_data.type = '2'
        // queryKeywordsSycmList(json_data).then(res => {
        //   this.SpqdTableDataRdcSpTmsp = res.data.datas;
        //   this.scRdcDataPageTotleTmsp = res.data.total
        // })
        getItemTmClass(json_data).then(res => {
          this.SpqdTableDataRdcSpTmsp = res.data.datas;
          this.scRdcDataPageTotleTmsp = res.data.total
        })
      } else if (pt === '5') {
        json_data.platformType = '京东'
        json_data.page = this.scRdcDataPageJdsp
        getItemJdszClass(json_data).then(res => {
          this.SpqdTableDataRdcSpJdsp = res.data.datas;
          this.scRdcDataPageTotleJdsp = res.data.total;
        })
      } else if (pt === '6') {
        json_data.platformType = '抖音话题'
        json_data.page = this.scRdcDataPageDyht
        queryKeywordsDyTopicList(json_data).then(res => {
          this.SpqdTableDataRdcSpDyht = res.data.datas;
          this.scRdcDataPageTotleDyht = res.data.total;
        })
      } else if (pt === '7') {
        json_data.platformType = '小红书'
        json_data.page = this.scRdcDataPageXhstz
        queryKeywordsXhsTopicList(json_data).then(res => {
          this.SpqdTableDataRdcSpXhsTz = res.data.datas;
          this.scRdcDataPageTotleXhstz = res.data.total
        })
      }


    },
    getDsptCommListPageChange(newPage) {
      this.scRdcDataPageMgComm = newPage
      this.getDsptMgCommList()
    },
    getRdcCommListPageChange(newPage) {
      this.scRdcDataPageMgComm = newPage
      this.getRdcCommList()

    },
    getRdcCommDataPageChange(newPage) {
      const pt = this.SpqdNameRdcPt
      if (pt === '1') {
        this.scRdcDataPageDysp = newPage
      } else if (pt === '2') {
        this.scRdcDataPagePddsp = newPage
      } else if (pt === '3') {
        this.scRdcDataPageKssp = newPage
      } else if (pt === '4') {
        this.scRdcDataPageTmsp = newPage
      } else if (pt === '5') {
        this.scRdcDataPageJdsp = newPage
      } else if (pt === '6') {
        this.scRdcDataPageDyht = newPage
      } else if (pt === '7') {
        this.scRdcDataPageXhstz = newPage
      }
      this.getRdcCommData()
    },
    getRdcCommDataPt() {
      this.scRdcDataPageDysp = 1
      this.scRdcDataPagePddsp = 1
      this.scRdcDataPageKssp = 1
      this.scRdcDataPageTmsp = 1
      this.scRdcDataPageJdsp = 1
      this.scRdcDataPageDyht = 1
      this.scRdcDataPageXhstz = 1
      this.SpqdNameRdcPt = "4"
      const json_data = {
        page: '',
        limit: this.scRdcDataPageSize,
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        itemKeyword: this.Rdcvalue,
        type: this.allRadioPt,
      }
      // json_data.platformType = '抖音商品'
      json_data.platformType = '抖音'
      json_data.page = this.scRdcDataPageDysp
      getItemOtherPlatformsClass(json_data).then(res => {
        this.SpqdTableDataRdcSpDysp = res.data.datas;
        this.scRdcDataPageTotleDysp = res.data.total
      })
      json_data.platformType = '拼多多'
      json_data.page = this.scRdcDataPagePddsp
      getItemOtherPlatformsClass(json_data).then(res => {
        this.SpqdTableDataRdcSpPddsp = res.data.datas;
        this.scRdcDataPageTotlePddsp = res.data.total
      })
      json_data.platformType = '快手'
      json_data.page = this.scRdcDataPageKssp
      getItemOtherPlatformsClass(json_data).then(res => {
        this.SpqdTableDataRdcSpKssp = res.data.datas;
        this.scRdcDataPageTotleKssp = res.data.total
      })
      json_data.platformType = '天猫'
      json_data.page = this.scRdcDataPageTmsp
      // json_data.type = '2'
      // queryKeywordsSycmList(json_data).then(res => {
      //   this.SpqdTableDataRdcSpTmsp = res.data.datas;
      //   this.scRdcDataPageTotleTmsp = res.data.total
      //   this.ifTm = res.data.ifTm
      // })
      getItemTmClass(json_data).then(res => {
        this.SpqdTableDataRdcSpTmsp = res.data.datas;
        this.scRdcDataPageTotleTmsp = res.data.total
        this.ifTm = res.data.ifTm
      })
      json_data.platformType = '京东'
      json_data.page = this.scRdcDataPageJdsp
      getItemJdszClass(json_data).then(res => {
        this.SpqdTableDataRdcSpJdsp = res.data.datas;
        this.scRdcDataPageTotleJdsp = res.data.total
      })
      json_data.platformType = '抖音话题'
      json_data.page = this.scRdcDataPageDyht
      queryKeywordsDyTopicList(json_data).then(res => {
        this.SpqdTableDataRdcSpDyht = res.data.datas;
        this.scRdcDataPageTotleDyht = res.data.total
      })
      json_data.platformType = '小红书'
      json_data.page = this.scRdcDataPageXhstz
      queryKeywordsXhsTopicList(json_data).then(res => {
        this.SpqdTableDataRdcSpXhsTz = res.data.datas;
        this.scRdcDataPageTotleXhstz = res.data.total
      })
    },
    getKeywordsRankListDS() {
      console.log('getKeywordsRankListPT')
      console.log(this.selectedOptions)
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '电商平台',
        brandKeywordSwitch: this.brandItemKeywordSwitch,
      }
      getRankingChangeClass(json_data).then(res => {
        this.tableDataPD = res.data.keywordsDetailsDtos
        this.Ptoptions = res.data.keywordsDetailsDtos
        this.Ptvalue = this.Ptoptions[0].itemKeyword
        this.ifQbtFlag = res.data.ifQbt
        if (this.SpqdName == '1' || this.SpqdName == '2' || this.SpqdName == '3') {
          this.ItemOtherPlatformsClass();
        }
        this.columnsListPD = [[], [], []];
        this.tableDataPD.forEach((item, index) => {
          if (index < 30) {
            this.columnsListPD[0].push(item);
          } else if (index >= 30 && index < 60) {
            this.columnsListPD[1].push(item);
          } else {
            this.columnsListPD[2].push(item);
          }
        });
        this.setCurrentDspt(this.columnsListPD[0][0])
      })

    },
    getSimplyKeywordsRankListDS() {
      console.log('getSimplyKeywordsRankListDS')
      console.log(this.selectedOptions)

      if (this.simplybrandItemKeywordSwitch == true) {
        const json_data = {
          oneCategory: this.selectedOptions[0],
          // midCategory: this.selectedOptions[1],
          thirdCategory: this.selectedOptions[1],
          platformType: '电商平台',
          brandKeywordSwitch: this.simplybrandItemKeywordSwitch,
        }
        queryKeywordsBrandRankListStatistics(json_data).then(res => {
          this.tableDataPD = res.data.keywordsDetailsDtos
          this.Ptoptions = res.data.keywordsDetailsDtos
          this.Ptvalue = this.Ptoptions[0].itemKeyword
          this.ifQbtFlag = res.data.ifQbt
          if (this.SpqdName == '1' || this.SpqdName == '2' || this.SpqdName == '3') {
            this.ItemOtherPlatformsClass();
          }
          this.columnsListPD = [[], [], []];
          this.tableDataPD.forEach((item, index) => {
            if (index < 30) {
              this.columnsListPD[0].push(item);
            } else if (index >= 30 && index < 60) {
              this.columnsListPD[1].push(item);
            } else {
              this.columnsListPD[2].push(item);
            }
          });
          this.setCurrentDspt(this.columnsListPD[0][0])
        })
      }


    },
    async getKeywordsRankListDYHT() {
      console.log('getKeywordsRankListDYHT')
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
      }
      await getyDHotTopicClass(json_data).then(res => {
        this.tableDataDYHT = res.data
      })
    },
    async getKeywordsRankListDYZzht() {
      console.log('getKeywordsRankListDYZzht')
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
      }
      await getyDIncreaseClass(json_data).then(res => {
        this.tableDataDYZzht = res.data
      })
    },
    getKeywordsRankListXhs() {
      console.log('getKeywordsRankListXhs')
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.xhsTopicRankPageQW,
        limit: this.xhsTopicRankPageSizeQW
      }
      getXhsHotTopicClass(json_data).then(res => {
        this.tableDataXhs = res.data.datas
        this.xhsTopicRankTotalQW = res.data.total
      })
    },
    getKeywordsRankListXhsRecently() {
      console.log('getKeywordsRankListXhsRecently')
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.xhsTopicRankPageQWRecently,
        limit: this.xhsTopicRankPageSizeQWRecently
      }
      queryZjfbTopic(json_data).then(res => {
        this.tableDataXhsRecently = res.data.datas
        this.xhsTopicRankTotalQWRecently = res.data.total
      })
    },

    changeradioXhsRm() {
      if (this.radioXhsRm === '人气值TOP20') {
        this.getKeywordsRankListXhs()
      } else if (this.radioXhsRm === '最近发布TOP20') {
        this.getKeywordsRankListXhsRecently()
      }
    },
    getDsptSpPtList() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.smsFailpageQW,
        limit: this.smsFailpageSizeQW,
        platformType: '',
        itemKeyword: this.Ptvalue,
        type: '1',
      }
      json_data.platformType = '抖音'
      getItemOtherPlatformsClass(json_data).then(res => {
        const totle_num = res.data.total
        if (totle_num == 0) {
          this.dsDysp = 'False'
        } else {
          this.dsDysp = 'True'
        }
      })
      json_data.platformType = '拼多多'
      getItemOtherPlatformsClass(json_data).then(res => {
        const totle_num = res.data.total
        if (totle_num == 0) {
          this.dsPdd = 'False'
        } else {
          this.dsPdd = 'True'
        }
      })
      json_data.platformType = '快手'
      getItemOtherPlatformsClass(json_data).then(res => {
        const totle_num = res.data.total
        if (totle_num == 0) {
          this.dsKs = 'False'
        } else {
          this.dsKs = 'True'
        }
      })
      const json_data_sycm = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.smsFailpageQW,
        limit: this.smsFailpageSizeQW,
        itemKeyword: this.Ptvalue,
        type: '1',
        platformType: '生意参谋',
      }
      // getItemSycmClass(json_data).then(res => {
      //   this.SpqdTableDataDy = res.data.datas
      //   this.smsFailtotalQW = res.data.total
      //   this.ifTm = res.data.ifTm
      // })
      getItemTmClass(json_data_sycm).then(res => {
        const totle_num = res.data.total
        if (totle_num == 0) {
          this.dsTm = 'False'
          this.SpqdName = '5'
          this.ItemJdszClass()
        } else {
          this.dsTm = 'True'
        }
      })
      const json_data_jd = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.smsFailpageQW,
        limit: this.smsFailpageSizeQW,
        itemKeyword: this.Ptvalue,
        type: '1',
        platformType: '京东商智',
      }
      getItemJdszClass(json_data_jd).then(res => {
        const totle_num = res.data.total
        if (totle_num == 0) {
          this.dsJd = 'False'
        } else {
          this.dsJd = 'True'
        }
      })
      const json_data_sycm_gl = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: 1,
        limit: this.smsFailpageSizeQW,
        itemKeyword: this.Ptvalue,
        type: '2',
        platformType: '生意参谋',
      }
      getItemTmClass(json_data_sycm_gl).then(res => {
        const totle_num = res.data.total
        if (totle_num == 0) {
          const json_data_ds_jd = {
            oneCategory: this.selectedOptions[0],
            // midCategory: this.selectedOptions[1],
            thirdCategory: this.selectedOptions[1],
            page: 1,
            limit: this.smsFailpageSizeQW,
            itemKeyword: this.Ptvalue,
            type: '2',
            platformType: '京东商智',
          }
          getItemJdszClass(json_data_ds_jd).then(res => {
            const totle_num_jd = res.data.total
            if (totle_num_jd == 0) {
              const json_data_dy = {
                oneCategory: this.selectedOptions[0],
                // midCategory: this.selectedOptions[1],
                thirdCategory: this.selectedOptions[1],
                page: this.smsFailpageQW,
                limit: this.smsFailpageSizeQW,
                platformType: '',
                itemKeyword: this.Ptvalue,
                type: '2',
              }
              json_data_dy.platformType = '抖音'
              getItemOtherPlatformsClass(json_data_dy).then(res => {
                // this.SpqdTableDataDy = res.data.datas
                // this.smsFailtotalQW = res.data.total
                const totle_num_dy = res.data.total
                if (totle_num_dy == 0) {
                  json_data_dy.platformType = '拼多多'
                  getItemOtherPlatformsClass(json_data_dy).then(res => {
                    // this.SpqdTableDataDy = res.data.datas
                    // this.smsFailtotalQW = res.data.total
                    const totle_num_dy = res.data.total
                    if (totle_num_dy == 0) {
                      json_data_dy.platformType = '快手'
                      getItemOtherPlatformsClass(json_data_dy).then(res => {
                        // this.SpqdTableDataDy = res.data.datas
                        // this.smsFailtotalQW = res.data.total
                        const totle_num_dy = res.data.total
                        if (totle_num_dy == 0) {
                          this.SpqdName = '4'
                          this.ItemSycmClass()
                        } else {
                          this.SpqdName = '3'
                          this.ItemOtherPlatformsClass()
                        }
                      })
                    } else {
                      this.SpqdName = '2'
                      this.ItemOtherPlatformsClass()
                    }
                  })
                } else {
                  this.SpqdName = '1'
                  this.ItemOtherPlatformsClass()
                }
              })
            } else {
              this.SpqdName = '5'
              this.ItemJdszClass()
            }
          })

        }
      })
      // const json_data_top = {
      //   oneCategory: this.selectedOptions[0],
      //   // midCategory: this.selectedOptions[1],
      //   thirdCategory: this.selectedOptions[1],
      //   itemKeyword: this.Ptvalue,
      //   limit: this.scRdcDataPageSize,
      //   page: 1
      // }
      // queryKeywordsMgBrandItemList(json_data_top).then(res => {
      //   const totle_num = res.data.total;
      //   if (totle_num <= 20) {
      //     this.ifQbtFlag = "Y"
      //     console.log('******totle_num******')
      //     console.log(totle_num)
      //   } else if (totle_num >= 20)
      //     this.ifQbtFlag = "N"
      //   console.log('******totle_num******')
      //   console.log(totle_num)
      // })

    },
    ItemOtherPlatformsClass() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.smsFailpageQW,
        limit: this.smsFailpageSizeQW,
        platformType: '',
        itemKeyword: this.Ptvalue,
        type: this.radioPt,
      }
      if (this.SpqdName == '1') {
        json_data.platformType = '抖音'
      } else if (this.SpqdName == '2') {
        json_data.platformType = '拼多多'
      } else if (this.SpqdName == '3') {
        json_data.platformType = '快手'
      }
      getItemOtherPlatformsClass(json_data).then(res => {
        this.SpqdTableDataDy = res.data.datas
        this.smsFailtotalQW = res.data.total
      })
    },
    ItemSycmClass() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.smsFailpageQW,
        limit: this.smsFailpageSizeQW,
        itemKeyword: this.Ptvalue,
        type: this.radioPt,
        platformType: '生意参谋',
      }
      // getItemSycmClass(json_data).then(res => {
      //   this.SpqdTableDataDy = res.data.datas
      //   this.smsFailtotalQW = res.data.total
      //   this.ifTm = res.data.ifTm
      // })
      getItemTmClass(json_data).then(res => {
        this.SpqdTableDataDy = res.data.datas
        this.smsFailtotalQW = res.data.total
        this.ifTm = res.data.ifTm
      })
    },
    ItemJdszClass() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        page: this.smsFailpageQW,
        limit: this.smsFailpageSizeQW,
        itemKeyword: this.Ptvalue,
        type: this.radioPt,
        platformType: '京东商智',
      }
      getItemJdszClass(json_data).then(res => {
        this.SpqdTableDataDy = res.data.datas
        this.smsFailtotalQW = res.data.total
      })
    },
    async handleClickPT(value) {
      this.scRdcDataPageMgComm = 1
      console.log('handleClickPT')
      console.log(value)
      this.activeIndex = value

      if (value === 'first') {
        if (this.simplybrandKeywordSwitch == true) {
          this.simplyfirstgetKeywordsRankList()
        } else {
          this.getKeywordsRankList()
        }
        // this.setCurrentRdc()
      } else if (value === 'second') {
        if (this.simplybrandItemKeywordSwitch == true) {
          this.brandItemKeywordSwitch = false
          this.getSimplyKeywordsRankListDS()
        }else {
          this.getKeywordsRankListDS()
        }
        // this.setCurrentDspt()
      } else if (value === 'third') {
        await this.getKeywordsRankListDYHT();
        await this.getKeywordsRankListDYZzht();
        await this.getDyTopicList();
        if (this.dyTopic && this.dyTopic !== '') {
          await this.dyTopicTrendListDataList();
        }
      } else if (value === 'fourth') {
        this.getKeywordsRankListXhs()
        this.cloudImageURL = 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/wordcloud_keyword_xhs_' + this.selectedOptions[1] + '.png'
        console.log(this.cloudImageURL)
      }
    },
    handleClickSpqd(value) {
      console.log('handleClickPT')
      console.log(value)
      this.smsFailpageQW = 1
      this.radioPt = '2'
      if (value == '1') {
        this.ItemOtherPlatformsClass()
      } else if (value == '2') {
        this.ItemOtherPlatformsClass()
      } else if (value == '3') {
        this.ItemOtherPlatformsClass()
      } else if (value == '4') {
        this.ItemSycmClass()
      } else if (value == '5') {
        this.ItemJdszClass()
      }
    },
    stationeryClassifyKeywordsListClass() {
      const json_data = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
      }
      getStationeryClassifyKeywordsListClass(json_data).then(res => {
        this.spiderKeywords = res.data.join(',');
      })
    },
    handlePtvalueInput() {
      this.smsFailpageQW = 1
      if (this.SpqdName == '1') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '2') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '3') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '4') {
        this.ItemSycmClass()
      } else if (this.SpqdName == '5') {
        this.ItemJdszClass()
      }
    },
    handleRadioPtInput() {
      this.smsFailpageQW = 1
      if (this.SpqdName == '1') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '2') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '3') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '4') {
        this.ItemSycmClass()
      } else if (this.SpqdName == '5') {
        this.ItemJdszClass()
      }
    },
    smsFailHandlePageChangeQW(newPage) {
      this.smsFailpageQW = newPage;
      if (this.SpqdName == '1') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '2') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '3') {
        this.ItemOtherPlatformsClass()
      } else if (this.SpqdName == '4') {
        this.ItemSycmClass()
      } else if (this.SpqdName == '5') {
        this.ItemJdszClass()
      }
    },
    xhsTopicRankHandlePageChangeQW(newPage) {
      this.xhsTopicRankPageQW = newPage;
      this.getKeywordsRankListXhs();
    },
    xhsTopicRankHandlePageChangeQWRecently(newPage) {
      this.xhsTopicRankPageQWRecently = newPage;
      this.getKeywordsRankListXhsRecently();
    },
    async dyTopicTrendListDataList() {
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        type: this.dyDateRangeType,
        topicName: this.dyTopic,
      }
      await getDyTopicTrendListDataList(parameters).then(res => {
        this.dyTopicRankYMin = undefined;
        this.dyTopicRankYMax = undefined;
        this.dyTopicRankYInterval = undefined;
        if (res.code === 200) {
          this.dyTopicRankYMin = res.data.ymin;
          this.dyTopicRankYMax = res.data.ymax;
          this.dyTopicRankYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawDyTopicRankChart(
              res.data.dyTopicTrendDtos.map(i => i.statsDate),
              res.data.dyTopicTrendDtos.map(i => i.viewCount),
            );
          });
        }
      })
    },
    drawDyTopicRankChart(data1, data2) {
      if (this.chartDyTopicRankInstance) {
        this.chartDyTopicRankInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 80,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.dyTopicRankYMin,
          max: this.dyTopicRankYMax,
          interval: this.dyTopicRankYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====options=====", options)
      this.chartDyTopicRankInstance = echarts.init(this.$refs.chartDyTopicRankCanvas)
      this.chartDyTopicRankInstance.setOption(options)
    },
    drawDsptCommRankChart(data1, data2) {
      if (this.chartDsptCommRankInstance) {
        this.chartDsptCommRankInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            var xAxisValue = params[0].axisValue; // 获取横坐标数据
            var lines = '';
            for (var i = 0; i < params.length; i++) {
              var dataValue = params[i].value + '%'; // 添加百分号
              var lineColor = params[i].color; // 获取曲线的颜色标识
              lines += '<span style="color:' + lineColor + '">' + dataValue + '</span><br/>';
              // lines += '<span style="color:' + lineColor + '">' + params[i].seriesName + ': ' + dataValue + '</span><br/>';
            }
            var tooltipContent = xAxisValue + '<br/>' + lines;
            return tooltipContent;
          }
        },
        grid: {
          left: 80,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.dsptCommRankYMin,
          max: this.dsptCommRankYMax,
          interval: this.dsptCommRankYInterval,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====options=====", options)
      this.chartDsptCommRankInstance = echarts.init(this.$refs.chartDsptCommRankCanvas)
      this.chartDsptCommRankInstance.setOption(options)
    },
    drawRdcCommRankChart(data1, data2) {
      if (this.chartRdcCommRankInstance) {
        this.chartRdcCommRankInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            var xAxisValue = params[0].axisValue; // 获取横坐标数据
            var lines = '';
            for (var i = 0; i < params.length; i++) {
              var dataValue = params[i].value + '%'; // 添加百分号
              var lineColor = params[i].color; // 获取曲线的颜色标识
              // lines += '<span style="color:' + lineColor + '">' + params[i].seriesName + ': ' + dataValue + '</span><br/>';
              lines += '<span style="color:' + lineColor + '">' + dataValue + '</span><br/>';
            }
            var tooltipContent = xAxisValue + '<br/>' + lines;
            return tooltipContent;
          }
        },
        grid: {
          left: 80,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.rdcCommRankYMin,
          max: this.rdcCommRankYMax,
          interval: this.rdcCommRankYInterval,
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====options=====", options)
      this.chartRdcCommRankInstance = echarts.init(this.$refs.chartRdcCommRankCanvas)
      this.chartRdcCommRankInstance.setOption(options)
    },
    drawRdcCommRankChartLast12Week(data1, data2) {
      if (this.chartRdcCommRankInstanceLast12Week) {
        this.chartRdcCommRankInstanceLast12Week.dispose();
      }
      const options = {
        xAxis: {
          show: false, // 将横坐标轴隐藏
          type: 'category',
          data: data1
        },
        yAxis: {
          show: false, // 将纵坐标轴隐藏
          type: 'value'
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ],
        grid: {
          show: false, // 将绘图网格隐藏
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      };
      console.log("=====options=====", options)
      this.chartRdcCommRankInstanceLast12Week = echarts.init(this.$refs.chartRdcCommRankCanvasLast12Week)
      this.chartRdcCommRankInstanceLast12Week.setOption(options)
    },

    handleKeywordsRankTrend(itemKeyword, type) {
      this.hotKeywordsPlatform = type;
      this.keywordsRankTrendDialog.itemKeyword = '';
      this.keywordsRankTrendYMin = undefined;
      this.keywordsRankTrendYMax = undefined;
      this.keywordsRankTrendYInterval = undefined;
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: itemKeyword,
        platformType: type,
      }
      this.keywordsRankTrendDialog.itemKeyword = itemKeyword;
      queryKeywordsRankTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.keywordsRankTrendDialog.visible = true;
          this.keywordsRankTrendYMin = res.data.ymin;
          this.keywordsRankTrendYMax = res.data.ymax;
          this.keywordsRankTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawKeywordsRankTrendChart(
              res.data.keywordRankTrendDtos.map(i => i.statsDate),
              res.data.keywordRankTrendDtos.map(i => i.keywordRank),
            );
          });
        }
      })
      this.getKeywordsHeatRankTrendList(itemKeyword, type);
    },

    getKeywordsHeatRankTrendList(itemKeyword, type) {
      if (type === '全平台') {
        this.checkedPlatforms = ['全平台'];
      } else if (type === '电商平台') {
        this.checkedPlatforms = ['电商平台'];
      }
      this.keywordsRankTrendDialog.itemKeyword = '';
      this.keywordsHeatRankTrendYMin = undefined;
      this.keywordsHeatRankTrendYMax = undefined;
      this.keywordsHeatRankTrendYInterval = undefined;
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: itemKeyword,
        platforms: this.checkedPlatforms,
        platformType: this.hotKeywordsPlatform,
      }
      this.keywordsRankTrendDialog.itemKeyword = itemKeyword;
      queryKeywordsHeatRankTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.keywordsRankTrendDialog.visible = true;
          this.keywordsHeatRankTrendYMin = res.data.ymin;
          this.keywordsHeatRankTrendYMax = res.data.ymax;
          this.keywordsHeatRankTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawKeywordsHeatRankTrendChart(
              res.data.allPlatformKeywordHeatRankTrendDtos.map(i => i.statsDate),
              res.data.allPlatformKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.dsKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.dyKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.xhsKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.dsKeywordHeatRankTrendDtos.map(i => i.statsDate),
              res.data.dyKeywordHeatRankTrendDtos.map(i => i.statsDate),
              res.data.xhsKeywordHeatRankTrendDtos.map(i => i.statsDate),
            );
          });
        }
      })
    },

    drawKeywordsRankTrendChart(data1, data2) {
      if (this.chartKeywordsRankTrendInstance) {
        this.chartKeywordsRankTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          inverse: 'false',
          min: this.keywordsRankTrendYMin,
          max: this.keywordsRankTrendYMax,
          interval: this.keywordsRankTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====options====", options)
      this.chartKeywordsRankTrendInstance = echarts.init(this.$refs.chartkeywordsRankTrendCanvas)
      this.chartKeywordsRankTrendInstance.setOption(options)
    },

    async drawKeywordsHeatRankTrendChart(data1, data2, data3, data4, data5, data6, data7, data8) {
      if (this.chartKeywordsHeatRankTrendInstance) {
        this.chartKeywordsHeatRankTrendInstance.dispose();
      }
      let xAxisData = [];
      if (data1.length > 0) {
        xAxisData = data1;
      } else if (data6.length > 0) {
        xAxisData = data6;
      } else if (data7.length > 0) {
        xAxisData = data7;
      } else if (data8.length > 0) {
        xAxisData = data8;
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value',
          min: this.keywordsHeatRankTrendYMin,
          max: this.keywordsHeatRankTrendYMax,
          interval: this.keywordsHeatRankTrendYInterval
        },
        series: [
          {
            name: '全平台',
            type: 'line',
            data: data2,
            smooth: true
          },
          {
            name: '电商平台',
            type: 'line',
            data: data3,
            smooth: true
          },
          {
            name: '抖音话题',
            type: 'line',
            data: data4,
            smooth: true
          },
          {
            name: '小红书',
            type: 'line',
            data: data5,
            smooth: true
          }
        ]
      };
      console.log("=====options====", options)
      this.chartKeywordsHeatRankTrendInstance = echarts.init(this.$refs.chartkeywordsHeatRankTrendCanvas)
      this.chartKeywordsHeatRankTrendInstance.setOption(options)
    },

    async getDyTopicList() {
      this.dyTopicOptions = [];
      this.dyTopic = '';
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1]
      }
      await queryDyTopicList(parameters).then(res => {
        if (res.code === 200) {
          this.dyTopicOptions = res.data;
          this.dyTopic = res.data[0];
        }
      })
    },

    dyDateRangeTypeChange() {
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        type: this.dyDateRangeType,
        topicName: this.dyTopic,
      }

      getDyTopicTrendListDataList(parameters).then(res => {
        this.dyTopicRankYMin = undefined;
        this.dyTopicRankYMax = undefined;
        this.dyTopicRankYInterval = undefined;
        if (res.code === 200) {
          this.dyTopicRankYMin = res.data.ymin;
          this.dyTopicRankYMax = res.data.ymax;
          this.dyTopicRankYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawDyTopicRankChart(
              res.data.dyTopicTrendDtos.map(i => i.statsDate),
              res.data.dyTopicTrendDtos.map(i => i.viewCount),
            );
          });
        }
      })
    },
    async rdcCommDateRangeTypeChange(itemKeyword) {
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: itemKeyword
      }

      await queryKeywordsMgBrandItemTrendList(parameters).then(res => {
        this.rdcCommRankYMin = undefined;
        this.rdcCommRankYMax = undefined;
        this.rdcCommRankYInterval = undefined;
        if (res.code === 200) {
          this.rdcCommRankYMin = res.data.ymin;
          this.rdcCommRankYMax = res.data.ymax;
          this.rdcCommRankYInterval = res.data.yinterval;
          if (res.data.itemSellProportionTrendDtos.length !== 0) {
            this.rdcQsTr = true
            this.isLoadingRdc = false;
            this.$nextTick(() => {
              this.drawRdcCommRankChart(
                res.data.itemSellProportionTrendDtos.map(i => i.statsDate),
                res.data.itemSellProportionTrendDtos.map(i => parseFloat(i.itemSellProportion.replace('%', ''))),
              );
            });
          } else {
            this.rdcQsTr = false
            this.isLoadingRdc = false;
          }

        } else {
          this.isLoadingRdc = false;
        }
      })
    },
    async dsptCommDateRangeTypeChange(itemKeyword) {
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: itemKeyword
      }

      await queryKeywordsMgBrandItemTrendList(parameters).then(res => {
        this.dsptCommRankYMin = undefined;
        this.dsptCommRankYMax = undefined;
        this.dsptCommRankYInterval = undefined;
        if (res.code === 200) {
          this.dsptCommRankYMin = res.data.ymin;
          this.dsptCommRankYMax = res.data.ymax;
          this.dsptCommRankYInterval = res.data.yinterval;
          if (res.data.itemSellProportionTrendDtos.length !== 0) {
            this.dspyQsTr = true
            this.isLoadingDspt = false;
            console.log('this.isLoadingDspt')
            console.log(this.isLoadingDspt)
            this.$nextTick(() => {
              this.drawDsptCommRankChart(
                res.data.itemSellProportionTrendDtos.map(i => i.statsDate),
                res.data.itemSellProportionTrendDtos.map(i => parseFloat(i.itemSellProportion.replace('%', ''))),
              );
            });
          } else {
            this.dspyQsTr = false
            this.isLoadingDspt = false;
            console.log('this.isLoadingDspt')
            console.log(this.isLoadingDspt)
          }

        } else {
          this.isLoadingDspt = false;
        }
      })
    },
    selectDyTopicChanged() {
      this.dyTopicRankYMin = undefined;
      this.dyTopicRankYMax = undefined;
      this.dyTopicRankYInterval = undefined;

      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        type: this.dyDateRangeType,
        topicName: this.dyTopic,
      }

      getDyTopicTrendListDataList(parameters).then(res => {
        if (res.code === 200) {
          this.dyTopicRankYMin = res.data.ymin;
          this.dyTopicRankYMax = res.data.ymax;
          this.dyTopicRankYInterval = res.data.yinterval;

          this.$nextTick(() => {
            this.drawDyTopicRankChart(
              res.data.dyTopicTrendDtos.map(i => i.statsDate),
              res.data.dyTopicTrendDtos.map(i => i.viewCount),
            );
          });
        }
      })
    },

    async handlePlatformsChange(itemKeyword) {
      this.keywordsHeatRankTrendYMin = undefined;
      this.keywordsHeatRankTrendYMax = undefined;
      this.keywordsHeatRankTrendYInterval = undefined;
      if (this.checkedPlatforms.length === 0) {
        this.checkedPlatforms = ['全平台'];
      }
      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        itemKeyword: itemKeyword,
        platforms: this.checkedPlatforms,
        platformType: this.hotKeywordsPlatform,
      }
      await queryKeywordsHeatRankTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.keywordsRankTrendDialog.visible = true;
          this.keywordsHeatRankTrendYMin = res.data.ymin;
          this.keywordsHeatRankTrendYMax = res.data.ymax;
          this.keywordsHeatRankTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawKeywordsHeatRankTrendChart(
              res.data.allPlatformKeywordHeatRankTrendDtos.map(i => i.statsDate),
              res.data.allPlatformKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.dsKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.dyKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.xhsKeywordHeatRankTrendDtos.map(i => i.degreeOfHeat),
              res.data.dsKeywordHeatRankTrendDtos.map(i => i.statsDate),
              res.data.dyKeywordHeatRankTrendDtos.map(i => i.statsDate),
              res.data.xhsKeywordHeatRankTrendDtos.map(i => i.statsDate),
            );
          });
        }
      })
    },
    handleSycmTrend(itemName) {
      this.sycmTrendDialog.visible = true;
      this.sycmPriceTrendYMin = undefined;
      this.sycmPriceTrendYMax = undefined;
      this.sycmPriceTrendYInterval = undefined;
      var parameters = {
        itemName: itemName,
      }
      this.sycmTrendDialog.itemName = itemName;
      querySycmPriceTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.sycmPriceTrendYMin = res.data.ymin;
          this.sycmPriceTrendYMax = res.data.ymax;
          this.sycmPriceTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawSycmPriceTrendChart(
              res.data.sycmPriceTrendDtos.map(i => i.statsDate),
              res.data.sycmPriceTrendDtos.map(i => i.price),
            );
          });
        }
      });
      this.getSycmSellCountTrendList(itemName);
    },

    handleSycmTrendRdcDyht(topicName) {
      this.sycmTrendDialogRdcDyht.visible = true;
      this.sycmPriceTrendYMinRdcDyht = undefined;
      this.sycmPriceTrendYMaxRdcDyht = undefined;
      this.sycmPriceTrendYIntervalRdcDyht = undefined;

      var parameters = {
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        type: 2,
        topicName: topicName,
      }

      this.sycmTrendDialogRdcDyht.topicName = topicName;
      getDyTopicTrendListDataList(parameters).then(res => {
        if (res.code === 200) {
          this.sycmPriceTrendYMinRdcDyht = res.data.ymin;
          this.sycmPriceTrendYMaxRdcDyht = res.data.ymax;
          this.sycmPriceTrendYIntervalRdcDyht = res.data.yinterval;
          this.$nextTick(() => {
            this.drawSycmPriceTrendChartRdcDyht(
              res.data.dyTopicTrendDtos.map(i => i.statsDate),
              res.data.dyTopicTrendDtos.map(i => i.viewCount),
            );
          });
        }
      })
    },
    drawSycmPriceTrendChartRdcDyht(data1, data2) {
      if (this.chartSycmPriceTrendInstanceRdcDyht) {
        this.chartSycmPriceTrendInstanceRdcDyht.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.sycmPriceTrendYMinRdcDyht,
          max: this.sycmPriceTrendYMaxRdcDyht,
          interval: this.sycmPriceTrendYIntervalRdcDyht
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      this.chartSycmPriceTrendInstanceRdcDyht = echarts.init(this.$refs.chartSycmPriceTrendCanvasRdcDyht)
      this.chartSycmPriceTrendInstanceRdcDyht.setOption(options)
    },
    drawSycmPriceTrendChart(data1, data2) {
      if (this.chartSycmPriceTrendInstance) {
        this.chartSycmPriceTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.sycmPriceTrendYMin,
          max: this.sycmPriceTrendYMax,
          interval: this.sycmPriceTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      this.chartSycmPriceTrendInstance = echarts.init(this.$refs.chartSycmPriceTrendCanvas)
      this.chartSycmPriceTrendInstance.setOption(options)
    },
    getSycmSellCountTrendList(itemName) {
      this.sycmSellCountTrendYMin = undefined;
      this.sycmSellCountTrendYMax = undefined;
      this.sycmSellCountTrendYInterval = undefined;
      var parameters = {
        itemName: itemName,
      }
      querySycmSellCountTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.sycmSellCountTrendYMin = res.data.ymin;
          this.sycmSellCountTrendYMax = res.data.ymax;
          this.sycmSellCountTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawSycmSellCounteTrendChart(
              res.data.sycmSellCountTrendDtos.map(i => i.statsDate),
              res.data.sycmSellCountTrendDtos.map(i => i.sellCount),
            );
          });
        }
      })
    },
    drawSycmSellCounteTrendChart(data1, data2) {
      if (this.chartSycmSellCountTrendInstance) {
        this.chartSycmSellCountTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.sycmSellCountTrendYMin,
          max: this.sycmSellCountTrendYMax,
          interval: this.sycmSellCountTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("===sycmSellCountTrendOptions===", options)
      this.chartSycmSellCountTrendInstance = echarts.init(this.$refs.chartSycmSellCountTrendCanvas)
      this.chartSycmSellCountTrendInstance.setOption(options)
    },
    openModal(item) {
      this.modalVisible = true;
      this.selectedItem = item;
    },
    copyLink() {
      const el = document.createElement('textarea');
      el.value = this.selectedItem.shareUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message.success('链接已成功复制');
      this.modalVisible = false
    },

    async handleXhsTrend(id) {
      this.xhsTrendDialog.visible = true;
      this.xhsLikesTrendYMin = undefined;
      this.xhsLikesTrendYMax = undefined;
      this.xhsLikesTrendYInterval = undefined;
      var parameters = {
        id: id,
      }
      await queryXhsLikesTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.xhsLikesTrendYMin = res.data.ymin;
          this.xhsLikesTrendYMax = res.data.ymax;
          this.xhsLikesTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawXhsLikesTrendChart(
              res.data.xhsLikesTrendDtos.map(i => i.statsDate),
              res.data.xhsLikesTrendDtos.map(i => i.likes),
            );
          });
        }
      })
      await this.getXhsCollectTrendList(id);
      await this.getXhsShareTrendList(id);
      await this.getXhsCommentTrendList(id);
    },

    drawXhsLikesTrendChart(data1, data2) {
      if (this.chartXhsLikesTrendInstance) {
        this.chartXhsLikesTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.xhsLikesTrendYMin,
          max: this.xhsLikesTrendYMax,
          interval: this.xhsLikesTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====xXhsLikesTrendChart.options====", options)
      this.chartXhsLikesTrendInstance = echarts.init(this.$refs.chartXhsLikesTrendCanvas)
      this.chartXhsLikesTrendInstance.setOption(options)
    },

    async getXhsCollectTrendList(id) {
      this.xhsCollectTrendYMin = undefined;
      this.xhsCollectTrendYMax = undefined;
      this.xhsCollectTrendYInterval = undefined;
      var parameters = {
        id: id,
      }
      await queryXhsCollectTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.xhsCollectTrendYMin = res.data.ymin;
          this.xhsCollectTrendYMax = res.data.ymax;
          this.xhsCollectTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawXhsCollectTrendChart(
              res.data.xhsCollectTrendDtos.map(i => i.statsDate),
              res.data.xhsCollectTrendDtos.map(i => i.collects),
            );
          });
        }
      })
    },
    drawXhsCollectTrendChart(data1, data2) {
      if (this.chartXhsCollectTrendInstance) {
        this.chartXhsCollectTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.xhsCollectTrendYMin,
          max: this.xhsCollectTrendYMax,
          interval: this.xhsCollectTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====xhsCollectTrendChart.options====", options)
      this.chartXhsCollectTrendInstance = echarts.init(this.$refs.chartXhsCollectTrendCanvas)
      this.chartXhsCollectTrendInstance.setOption(options)
    },

    async getXhsShareTrendList(id) {
      this.xhsShareTrendYMin = undefined;
      this.xhsShareTrendYMax = undefined;
      this.xhsShareTrendYInterval = undefined;
      var parameters = {
        id: id,
      }
      await queryXhsShareTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.xhsShareTrendYMin = res.data.ymin;
          this.xhsShareTrendYMax = res.data.ymax;
          this.xhsShareTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawXhsShareTrendChart(
              res.data.xhsShareTrendDtos.map(i => i.statsDate),
              res.data.xhsShareTrendDtos.map(i => i.sharecount),
            );
          });
        }
      })
    },
    drawXhsShareTrendChart(data1, data2) {
      if (this.chartXhsShareTrendInstance) {
        this.chartXhsShareTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.xhsShareTrendYMin,
          max: this.xhsShareTrendYMax,
          interval: this.xhsShareTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====xhsShareTrendChart.options====", options)
      this.chartXhsShareTrendInstance = echarts.init(this.$refs.chartXhsShareTrendCanvas)
      this.chartXhsShareTrendInstance.setOption(options)
    },
    async getXhsCommentTrendList(id) {
      this.xhsCommentTrendYMin = undefined;
      this.xhsCommentTrendYMax = undefined;
      this.xhsCommentTrendYInterval = undefined;
      var parameters = {
        id: id,
      }
      await queryXhsCommentTrendList(parameters).then(res => {
        if (res.code === 200) {
          this.xhsCommentTrendYMin = res.data.ymin;
          this.xhsCommentTrendYMax = res.data.ymax;
          this.xhsCommentTrendYInterval = res.data.yinterval;
          this.$nextTick(() => {
            this.drawXhsCommentTrendChart(
              res.data.xhsCommentTrendDtos.map(i => i.statsDate),
              res.data.xhsCommentTrendDtos.map(i => i.comments),
            );
          });
        }
      })
    },
    drawXhsCommentTrendChart(data1, data2) {
      if (this.chartXhsCommentTrendInstance) {
        this.chartXhsCommentTrendInstance.dispose();
      }
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 50,
          right: 50,
        },
        xAxis: {
          type: 'category',
          data: data1
        },
        yAxis: {
          type: 'value',
          min: this.xhsCommentTrendYMin,
          max: this.xhsCommentTrendYMax,
          interval: this.xhsCommentTrendYInterval
        },
        series: [
          {
            data: data2,
            type: 'line',
            smooth: true
          }
        ]
      };
      console.log("=====xhsCommentTrendChart.options====", options)
      this.chartXhsCommentTrendInstance = echarts.init(this.$refs.chartXhsCommentTrendCanvas)
      this.chartXhsCommentTrendInstance.setOption(options)
    },
    firstBrandKeywordSwitchChange() {
      if (this.brandKeywordSwitch == true) {
        this.simplybrandKeywordSwitch = false
      }
      this.getKeywordsRankList()
    },
    simplyfirstBrandKeywordSwitchChange() {
      if (this.simplybrandKeywordSwitch == true) {
        this.brandKeywordSwitch = false
        this.simplyfirstgetKeywordsRankList()
      } else {
        this.getKeywordsRankList()
      }

    },
    secondBrandKeywordSwitchChange() {
      if (this.brandItemKeywordSwitch == true) {
        this.simplybrandItemKeywordSwitch = false
      }
      this.getKeywordsRankListDS()
    },
    simplySecondBrandKeywordSwitchChange() {
      if (this.simplybrandItemKeywordSwitch == true) {
        this.brandItemKeywordSwitch = false
        this.getSimplyKeywordsRankListDS()
      }else {
        this.getKeywordsRankListDS()
      }

    },
    handleAllRadioPtInput() {
      this.scRdcDataPageDysp = 1
      this.scRdcDataPagePddsp = 1
      this.scRdcDataPageKssp = 1
      this.scRdcDataPageTmsp = 1
      this.scRdcDataPageJdsp = 1
      // this.SpqdNameRdcPt = "4"
      const json_data = {
        page: '',
        limit: this.scRdcDataPageSize,
        oneCategory: this.selectedOptions[0],
        // midCategory: this.selectedOptions[1],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        itemKeyword: this.Rdcvalue,
        type: this.allRadioPt,
      }
      if (this.SpqdNameRdcPt == '1') {
        json_data.platformType = '抖音'
        json_data.page = this.scRdcDataPageDysp
        getItemOtherPlatformsClass(json_data).then(res => {
          this.SpqdTableDataRdcSpDysp = res.data.datas;
          this.scRdcDataPageTotleDysp = res.data.total
        })
      } else if (this.SpqdNameRdcPt == '2') {
        json_data.platformType = '拼多多'
        json_data.page = this.scRdcDataPagePddsp
        getItemOtherPlatformsClass(json_data).then(res => {
          this.SpqdTableDataRdcSpPddsp = res.data.datas;
          this.scRdcDataPageTotlePddsp = res.data.total
        })
      } else if (this.SpqdNameRdcPt == '3') {
        json_data.platformType = '快手'
        json_data.page = this.scRdcDataPageKssp
        getItemOtherPlatformsClass(json_data).then(res => {
          this.SpqdTableDataRdcSpKssp = res.data.datas;
          this.scRdcDataPageTotleKssp = res.data.total
        })
      } else if (this.SpqdNameRdcPt == '4') {
        json_data.platformType = '天猫'
        json_data.page = this.scRdcDataPageTmsp
        getItemTmClass(json_data).then(res => {
          this.SpqdTableDataRdcSpTmsp = res.data.datas;
          this.scRdcDataPageTotleTmsp = res.data.total
          this.ifTm = res.data.ifTm
        })
      } else if (this.SpqdNameRdcPt == '5') {
        json_data.platformType = '京东'
        json_data.page = this.scRdcDataPageJdsp
        getItemJdszClass(json_data).then(res => {
          this.SpqdTableDataRdcSpJdsp = res.data.datas;
          this.scRdcDataPageTotleJdsp = res.data.total
        })
      }
    },
    spqdNameRdcPtChange() {
      console.log("======this.SpqdNameRdcPt", this.SpqdNameRdcPt)
      const json_data = {
        page: '',
        limit: this.scRdcDataPageSize,
        oneCategory: this.selectedOptions[0],
        thirdCategory: this.selectedOptions[1],
        platformType: '',
        itemKeyword: this.Rdcvalue,
        type: this.allRadioPt,
      }
      if (this.SpqdNameRdcPt === '6' || this.SpqdNameRdcPt === '7') {
        this.allRadioPt = '2'
        this.scRdcDataPageDyht = 1
        this.scRdcDataPageXhstz = 1
        if (this.SpqdNameRdcPt === '6') {
          json_data.platformType = '抖音话题'
          json_data.page = this.scRdcDataPageDyht
          queryKeywordsDyTopicList(json_data).then(res => {
            this.SpqdTableDataRdcSpDyht = res.data.datas;
            this.scRdcDataPageTotleDyht = res.data.total
          })
        } else if (this.SpqdNameRdcPt === '7') {
          json_data.platformType = '小红书'
          json_data.page = this.scRdcDataPageXhstz
          queryKeywordsXhsTopicList(json_data).then(res => {
            this.SpqdTableDataRdcSpXhsTz = res.data.datas;
            this.scRdcDataPageTotleXhstz = res.data.total
          })
        }
      } else {
        this.handleAllRadioPtInput()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.ranking-cell {
  //background-color: #767171; /* 底色方框的背景颜色 */
  width: 30px;
  text-align: center;
}

.ranking-change-cell {
  display: flex;
  align-items: center;
}

.increase::before {
  //content: '\2191'; /* 上箭头符号，可替换为其他图标或字体图标 */
  background-image: url("/static/icon/marketDataBoardUp.svg");
  transform: scale(0.9);

  //color: green; /* 上升排名的颜色 */
  //background-image: url("/static/icon/marketDataBoardUp.svg");

}

.decrease::before {
  //content: '\2193'; /* 下箭头符号，可替换为其他图标或字体图标 */
  background-image: url("/static/icon/marketDataBoardLow.svg");
  //color: red; /* 下降排名的颜色 */
  transform: scale(0.9) rotate(180deg);
}

.arrow-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
}

.arrow-icon::before {
  content: ''; /* 空内容 */
  display: block;
  position: absolute;
  top: 20%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  transform-origin: center center;
}

.column-container {
  display: flex;

  .ranking-box .el-row {
    display: flex;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 13px;
    padding: 1%;
  }

  .ranking-box-dy .el-row {
    display: flex;
    align-items: center;
    color: rgb(51, 51, 51);
    font-size: 13px;
    padding-bottom: 1%;
  }

  .column-item {
    display: flex;
    align-items: center;
  }
}

.column {
  flex: 1;
  border: 1px solid #C5C5C5; /* 左边框样式 */
}

.column + .column {
  margin-left: 20px;
  padding-right: 2%;
  flex: 2.2;
  border-left: 1px solid #C5C5C5; /* 右边框样式 */
}

.columnDyht {
  flex: 1;
}

.columnDyht + .columnDyht {
  margin-left: 20px;
  flex: 1;
}

.ranking-box {
  //border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 2px;
  //display: flex;
  justify-content: center;
  //align-items: center;
  //text-align: center;
}

.ranking-box-dy {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: 2px;
  //display: flex;
  justify-content: center;
  //align-items: center;
  //text-align: center;
}

.center {
  //margin-top: 5px;
  //height: 25px;
  display: flex;
  align-items: center;
  //justify-content: center;
}

.rightt {
  //margin-top: 5px;
  //height: 25px;
  display: flex;
  //justify-content: right;
  //align-items: flex-end;
  text-align: right;

}


.header-row th, tbody {
  padding: 30px 0;
  text-align: center; /* 居中对齐表头文本 */
  width: 33%;
}

.header-row-di th, tbody {
  padding: 20px 0;
  text-align: center; /* 居中对齐表头文本 */
  width: 25%;
}

.ptClass {
  padding-top: 0%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ptClass .el-menu-item:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}

.SpqdClass {
  padding-top: 1%;
  display: flex;
  width: 100%;
}

.SpqdNrClass {

  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}


.custom-icon {
  display: inline-block;
  width: 30px;
  height: 22px;
  background-image: url("/static/icon/marketDataDataBoard.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.search-custom-icon {
  //display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url("/static/icon/趋势.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 5px;
}


img {
  //display: block;
  height: 20px;
}

.market-img {
  height: 20px;
}

.arrow-icon::before {
  left: 0;
  top: 0;
}


.green-font {
  color: #32C2B1;
}

.red-font {
  color: #FF847D;
}

.rank-first-level {
  background: #FF847D;
  color: #eeeff1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.rank-second-level {
  background: #FFA49F;
  color: #eeeff1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.rank-third-level {
  background: #FFC4C1;
  color: #eeeff1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.rank-else-level {
  background: #D7D7D7;
  color: #eeeff1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.wrapper {
  display: flex;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper i {
  margin-right: 10px; /* 调整图标和数值之间的间距 */
}

.el-dialog.custom-dialog {
  font-size: 14px;
  font-weight: bold;
}

.el-dialog {
  font-size: 14px !important;
  font-weight: bold;
}

.wordcloud-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wordcloud-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.dy-center {
  display: flex;
  justify-content: center;
}

.dy-right {
  display: flex;
  justify-content: flex-end;
}

.text-sele-col {
  color: #1F85EE;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .backgroud-row {
  background: #FFFFFF !important;;
}

::v-deep {
  .el-table {
    .customCellClass td {
      background-color: #FFFFFF !important; /* 设置背景色 */
    }
  }
}

.table-placeholder {
  min-height: 600px; /* 设置最小高度 */
  transition: min-height 0.3s; /* 添加过渡效果 */
}

.table-placeholder tbody {
  opacity: 0; /* 数据为空时隐藏表格内容 */
}

::v-deep {
  .el-table__row {
    td:not(.is-hidden):first-child, td:not(.is-hidden):last-child {
      bottom: 0px;
    }
  }

  .el-table__body tr.current-row > td {
    //color: #e5d7c9;
    background-color: #FFF2E0 !important;
    //background-image: linear-gradient(to right, rgb(255, 192, 111) 20px, rgb(255, 192, 111) 20px);
    //background-position: left;
    //background-repeat: no-repeat;
  }

  //.rdcTableClass .el-table  th.el-table__cell.is-leaf,
  //.rdcTableClass .el-table td.el-table__cell {
  //  border-bottom: 0px solid #DEE0E3 ;
  //}
  .el-table .cell {
    line-height: 20px;
  }
}

.mgXszbClass {
  background: linear-gradient(to right, #FFF2E0 20px, white) !important;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  opacity: 0.85;
  margin-top: -0.1%;
}

.rdcDilClass {
  background-color: rgb(255, 247, 236) !important;
  height: 60px;
  display: flex;
  //align-items: center;
  padding-top: 2%;
}

::v-deep .el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 2px;
}

::v-deep {
  .el-switch__label.is-active {
    color: #0B1523;
  }
}
</style>
