import {getLocationUrl, getUrlParams} from '@/utils';
import {
  handleProductGrade,
  handleProductMaxCatagory,
  handleProductMidCatagory,
  handleProductMinCatagory,
  handleProductUnitList,
  handleRaceNameList,
  handleUserInfo,
} from '@/api/config';
import {handleRoleList} from "../api/config";
import { handleBranchCompanyInfos } from '@/api/branchOffice';


const pageLoading = {
  data() {
    return {
      pageStatus: {
        isLoading: true
      }
    }
  },
  mounted() {
    this.cancelPageLoading();
  },
  methods: {
    cancelPageLoading() {
      let timer = null;
      const pageStatus = this.pageStatus;
      clearTimeout(timer);
      timer = setTimeout(() => {
        pageStatus.isLoading = false;
      }, 800);
    }
  }
};

const tableLoading = {
  data() {
    return {
      tableStatus: {
        isLoading: true,
        isAuditLoading: true,
        elementLoadingText: '正在加载...'
      }
    }
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      let timer = null;
      const tableStatus = this.tableStatus;
      clearTimeout(timer);
      timer = setTimeout(() => {
        tableStatus.isLoading = false;
        tableStatus.isAuditLoading = false;
      }, 800);
    }
  }
};

const paginationParams = {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  }
};

const urlParams = {
  data() {
    return {
      urlParams: {
        operator: '',
        "x-token": '',
      }
    }
  },
  created() {
    this.handleUrlParams();
  },
  methods: {
    handleUrlParams() {
      const urlStr = getLocationUrl();
      const urlParamsObj = getUrlParams(urlStr);
      if (urlParamsObj) {
        return new Promise((resolve, reject) => {
          Object.keys(urlParamsObj).map(item => {
            this.urlParams[item] = urlParamsObj[item] || '';
          });
          resolve(this.urlParams);
        })
      }
    }
  }
};

// 旧版本公共下拉列表项 -- 为了兼容历史版本, 暂时保留, 后续有此类公共的可直接引用新版本的方法
const optionsObj = {
  data() {
    return {
      options: {
        productGradeOptions: [],
        productMaxCategorieOtions: [],
        productMidCategorieOtions: [],
        productMinCategorieOtions: [],
        raceNameLists: [],
        roleOptions : []
      },
      user: {
        role_code_list:[],
        username: '',
        real_name: '',
        arrange_resource: false,
      },
      form: {
        productGrade: '',                       // 产品等级
        productFirstCategorie: '',              // 大类code
        productFirstCategorieName: '',          // 大类name
        productMiddleCategorie: '',             // 中类code
        productMiddleCategorieName: '',         // 中类name
        productLittleCategorie: '',             // 小类code
        productLittleCategorieName: '',         // 小类name
        prod_type: ''                           //生产类型
      },
      queryForm: {
        mainCategoryCode: '',
        midCategoryCode: '',
        subCategoryCode: '',
      }
    }
  },
  created() {
    this.handleConfOptions();
  },
  methods: {
    async handleConfOptions() {
      try {
        const params = {
          dict_type: 'plu_grade',
          operator: this.urlParams.operator
        };
        let gradeNewItems = [],
          catagoryNewItems = [];
        const resGrade = await handleProductGrade(params);
        const resMaxCatagory = await handleProductMaxCatagory(params);
        const resRaceName = await handleRaceNameList(params);
        const resUser = await handleUserInfo(params);
        const resRole = await handleRoleList(params);
        if (resUser.code == '0000') {
          const {data} = resUser;
          this.user = data;
        }

        if (resRole.code == '0000') {
          const {data} = resRole;
          const {role_list} = data;
          this.options.roleOptions = role_list;
          // console.log(data);
        }

        if (resGrade.code == '0000') {
          const {data} = resGrade;
          const {dict_def_dtos} = data;
          dict_def_dtos.map(item => {
            let gradeNewItem = {
              label: item.dict_label,
              label_id: item.id,
              value: item.dict_value
            };
            gradeNewItems.push(gradeNewItem);
          });
          this.options.productGradeOptions = gradeNewItems;
        }
        if (resMaxCatagory.code == '0000') {
          const {data} = resMaxCatagory;
          const {catagory_list} = data;
          catagory_list.map(item => {
            let catagoryNewItem = {
              label: item.max_name,
              value: item.max_code
            };
            catagoryNewItems.push(catagoryNewItem);
          });
          this.options.productMaxCategorieOtions = catagoryNewItems;
        }

        if (resRaceName.code == '0000') {
          let newItems = [];
          const {race_name_list} = resRaceName.data;
          // console.log(race_name_list);
          race_name_list.map(item => {
            let newItem = {
              label: item,
              value: item
            };
            newItems.push(newItem);
          });
          this.options.raceNameLists = newItems;
        }

      } catch (err) {
        console.log(err, 'err-----获取产品等级选项接口错误');
      }
    },
    handleOptionChange(e, type) {
      const funcObj = {
        'max': async () => {
          if (!e) this.form.productFirstCategorieName = '';
          this.queryForm.midCategoryCode = '';
          this.queryForm.subCategoryCode = '';
          const options = this.options;
          options.productMaxCategorieOtions.map(item => {
            if (item.value === e || item.label === e) {
              this.form.productFirstCategorieName = item.label
            }
          });
          const selectMaxedOption = options.productMaxCategorieOtions.find(item => item.label === e || item.value === e)
          const params = {
            max_code: selectMaxedOption?.value,
            operator: this.urlParams.operator
          };
          let newItems = [];
          const {code, data} = await handleProductMidCatagory(params);
          if (code == '0000') {
            const {catagory_list} = data;
            catagory_list.map(item => {
              let newItem = {
                ...item,
                label: item.mid_name,
                value: item.mid_code
              };
              newItems.push(newItem);
            });
            this.options.productMidCategorieOtions = newItems;
          }
        },
        'mid': async () => {
          if (!e) this.form.productMiddleCategorieName = '';
          this.queryForm.subCategoryCode = '';
          const options = this.options;
          options.productMidCategorieOtions.map(item => {
            if (item.value === e || item.label === e) {
              this.form.productMiddleCategorieName = item.label
            }
          });
          const selectMidedOption = options.productMidCategorieOtions.find(item => item.label === e || item.value === e)
          const params = {
            mid_code: selectMidedOption?.value,
            operator: this.urlParams.operator
          };
          let newItems = [];
          const {code, data} = await handleProductMinCatagory(params);
          if (code == '0000') {
            const {catagory_list} = data;
            catagory_list.map(item => {
              let newItem = {
                ...item,
                label: item.min_name,
                value: item.min_code
              };
              newItems.push(newItem);
            });
            this.options.productMinCategorieOtions = newItems;
          }
        },
        'min': () => {
          if (!e) this.form.productLittleCategorieName = '';
          const options = this.options;
          options.productMinCategorieOtions.map(item => {
            if (item.value === e || item.label === e) {
              this.form.productLittleCategorieName = item.label
            }
          });
        }
      };
      funcObj[type]();
    },
  }
};

const unitOptions = {
  data() {
    return {
      unitLists: []
    }
  },
  created() {
    this.handleUnitOptions();
  },
  methods: {
    async handleUnitOptions() {
      try {
        let newItems = [];

        const params = {
          operator: this.urlParams.operator
        };
        const {code, data} = await handleProductUnitList(params);
        // console.log(data);
        if (code == '0000') {
          const {production_unit_list} = data;
          production_unit_list.map(item => {
            let newItem = {
              label: item,
              value: item
            };
            newItems.push(newItem);
          });
          this.unitLists = newItems;
        }
      } catch (err) {
        this.unitLists = [];
      }
    }
  }
};

const branchCompanyInfos = {
  data() {
    return {
      branchCompanyInfos: null
    }
  },
  created() {
    this.handleBranchCompanyinfo();
  },
  methods: {
    async handleBranchCompanyinfo() {
      try {
        const { code, data } = await handleBranchCompanyInfos({})
        if ( code === 200 ) {
          this.branchCompanyInfos = {
            ...data,
            name: `分公司: ${ data.name || '' }`
          }
        };
        if ( code !== 200 ) this.branchCompanyInfos = null;
      } catch(err) {
        this.branchCompanyInfos = null;
        throw new Error(err)
      }
    }
  }
}
// 新版本公共下拉列表项
const optionsLists = {
  methods: {
    // 产品等级
    async handleProductGradeOption({ formItemList, field, paramsObj }) {
      try {
        const { code, data } = await handleProductGrade(paramsObj);
        if (code == '0000') {
          const { dict_def_dtos } = data
          const lists = dict_def_dtos?.map(item => {
            return {
              ...item,
              label: item.dict_label,
              label_id: item.id,
              value: item.dict_value
            }
          }) || []
          if (paramsObj) this.$format.handleSetOptions(formItemList, lists, field)
        }
      } catch (error) {
        console.log(error, 'error-----获取产品等级选项接口错误');
      }
    },
    // 产品赛道
    async handleRaceNamesOption({ formItemList, field, paramsObj }) {
      try {
        const { code, data } = await handleRaceNameList(paramsObj)
        if (code === '0000') {
          const { race_name_list } = data
          const lists = race_name_list?.map(item => {
            return {
              ...item,
              label: item,
              value: item
            }
          }) || []
          if (paramsObj) this.$format.handleSetOptions(formItemList, lists, field)
        }
      } catch (error) {
        console.log(error, 'error-----获取产品赛道接口错误');
      }
    },
    // 大类
    async handleProductMaxCatagoryOption({ formItemList, field, paramsObj }) {
      try {
        const { code, data } = await handleProductMaxCatagory(paramsObj)
        if (code === '0000') {
          const { catagory_list } = data
          const lists = catagory_list?.map(item => {
            return {
              ...item,
              label: item.max_name,
              value: item.max_code
            }
          }) || []
          if (paramsObj) this.$format.handleSetOptions(formItemList, lists, field)
        }
      } catch (error) {
        console.log(error, 'error-----获取大类接口错误');
      }
    },
    // 中类
    async handleProductMidCatagoryOption({ formItemList, field, paramsObj }) {
      try {
          const { code, data } = await handleProductMidCatagory(paramsObj); 
          if (code === '0000') {
              const { catagory_list } = data;
              const lists = catagory_list.map(item => {
                  return {
                      ...item,
                      label: item.mid_name,
                      value: item.mid_code
                  }
              })
              if (paramsObj) this.$format.handleSetOptions(formItemList, lists, field)
          }
      } catch(error) {
          console.log(error, 'error-----获取中类接口错误');
      }
    },
    // 小类
    async handleProductMinCatagoryOption({ formItemList, field, paramsObj }) {
      try {
        const { code, data } = await handleProductMinCatagory(paramsObj)
        if (code === '0000') {
          const { catagory_list } = data;
          const lists = catagory_list.map(item => {
              return {
                  ...item,
                  label: item.mid_name,
                  value: item.mid_code
              }
          })
          if (paramsObj) this.$format.handleSetOptions(formItemList, lists, field)
        }
      } catch(error) {
        console.log(error, 'error-----获取小类接口错误');
      }
    }
  }
}
export {
  pageLoading,            // 页面loading加载
  tableLoading,           // 表格loading加载
  paginationParams,       // 分页参数
  urlParams,              // 获取url参数
  optionsObj,             // 旧版本 -- 产品等级&&产品大中小类的选项值
  unitOptions,            // 生产单位
  branchCompanyInfos,     // 获取分公司信息
  optionsLists,           // 新版本 -- 产品等级&&产品大中小类的选项值
}
