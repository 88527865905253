<template>
  <div>
    <div style="padding: 10px">
      <el-button class="form-btn-item" size="mini" type="primary" @click="handleEdit">去圈选</el-button>
      <el-form ref="queryFormRef" size="mini" label-width="auto" inline :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="业务域">
          <el-radio-group v-model="queryForm.buDomain" size="mini">
            <el-radio-button v-for="item in options.buDomains" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分组类型">
          <el-radio-group v-model="queryForm.tagType" size="mini">
            <el-radio-button v-for="item in options.tagTypes" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="创建人">
          <el-select v-model="queryForm.createBy" size="mini" filterable placeholder="选择" clearable>
            <el-option v-for="item in options.creater" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键词搜索">
          <el-input v-model="queryForm.ruleGroupName" placeholder="请输入关键词" clearable>
            <i slot="suffix" class="el-icon-search" @click="handleQuery"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="主题">
          <el-select v-model="queryForm.topic" size="mini" filterable placeholder="选择" allow-create clearable>
            <el-option v-for="item in options.topics" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="更新方式">
          <el-select v-model="queryForm.autoUpdateFrequency" size="mini" filterable placeholder="选择" allow-create clearable>
            <el-option v-for="item in options.autoUpdateFrequencyOptions" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="更新状态" style="display: block;">
          <el-radio-group v-model="queryForm.updateStatus" class="full-width" size="small">
            <el-radio-button label="全部">全部</el-radio-button>
            <el-radio-button label="1">更新中</el-radio-button>
            <el-radio-button label="0">已停止更新</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="keyClass">
          <div style="display: inline-flex">
            <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      @sort-change="handleSortChange"
    >
      <el-table-column type="selection" width="40"/>
      <el-table-column type="index" width="40" />
      <el-table-column label="分组ID" prop="ruleGroupId" sortable width="100" />
      <el-table-column label="业务域" prop="buDomain" sortable />
      <el-table-column label="分组类型" prop="ruleGroupType" sortable />
      <el-table-column label="主题" prop="topic" sortable />
      <el-table-column label="分组名称" prop="ruleGroupName" sortable />
      <el-table-column label="创建人" prop="createBy" sortable />
      <el-table-column label="状态" prop="ruleGroupStatus" sortable>
        <template #default="{ row }">
          <el-tag :type="tagStatusMap[row.ruleGroupStatus]">
            {{ row.ruleGroupStatus }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="更新方式" prop="updateMode" sortable />
      <el-table-column label="更新频次" prop="updateFrequency"/>
      <el-table-column label="最新更新人" prop="updateBy" sortable />
      <el-table-column label="最新更新时间" prop="updateTime" sortable />
      <el-table-column label="操作" width="260" align="center">
        <template #default="{ row }">
          <el-button :disabled="row.ruleGroupStatus==='创建中'" size="mini" type="text" class="table-button" @click="handleViewDetail(row)">查看详情</el-button>
          <el-button :disabled="row.ruleGroupStatus==='创建中'" size="mini" type="text" class="table-button" @click="handleEdit(row)">编辑</el-button>
          <el-popconfirm title="是否删除改分组？" @confirm="handleDeleteGroup(row)">
            <el-button :disabled="row.ruleGroupStatus==='创建中'" slot="reference" size="mini" type="text" class="table-button">删除</el-button>
          </el-popconfirm>
          <el-button :disabled="row.ruleGroupStatus!=='创建成功'" size="mini" type="text" class="table-button" @click="downloadLog(row)">导出</el-button>
          <el-button :disabled="row.ruleGroupStatus==='创建中'" size="mini" type="text" class="table-button" @click="handleExecute(row)">手动更新</el-button>
        </template>
      </el-table-column>
      <el-table-column label="自动更新状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.updateMode !== '手动'">{{ scope.row.active === 0 ? '已停止自动更新' : '已开启自动更新' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="自动更新操作" align="center">
        <template #default="{ row }">
          <el-button v-if="row.updateMode !== '手动' && row.active === 1" :disabled="row.ruleGroupStatus!=='创建成功'" size="mini" type="text" class="table-button" @click="handleStopTask(row)">停止更新</el-button>
          <el-button v-else-if="row.updateMode !== '手动' && row.active === 0" :disabled="row.ruleGroupStatus!=='创建成功'" size="mini" type="text" class="table-button" @click="handleStartTask(row)">开启更新</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleFilter" />

    <el-drawer title="分组详情" :visible.sync="detailDrawer.visible" direction="rtl" size="40%">
      <el-descriptions title="" :column="1" style="padding: 15px;">
        <el-descriptions-item label="分组名称">{{ detailDrawer.form.ruleGroupName }}</el-descriptions-item>
        <el-descriptions-item label="分组ID">{{ detailDrawer.form.ruleGroupId }}</el-descriptions-item>
        <el-descriptions-item label="业务域">{{ detailDrawer.form.buDomain }}</el-descriptions-item>
        <el-descriptions-item label="分组类型">{{ detailDrawer.form.ruleGroupType }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{ detailDrawer.form.createBy }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ detailDrawer.form.createTime }}</el-descriptions-item>
        <el-descriptions-item label="更新方式">{{ detailDrawer.form.updateMode==='1'?'手动':'自动' }}</el-descriptions-item>
        <el-descriptions-item label="分组条件" :labelStyle="{'min-width':'70px'}"><span v-html="detailDrawer.form.conditionShow"></span><el-button title="查看分组条件视图模式" size="mini" type="primary" icon="el-icon-view" circle style="margin-left: 10px" @click="conditionDialog.visible=true" /></el-descriptions-item>
        <el-descriptions-item label="更新日志">
          <el-table
            :data="detailDrawer.pageData.rows"
            size="mini"
            border
            highlight-current-row
            :row-style="{height:0+'px'}"
            :cell-style="{padding:0+'px'}"
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            :row-key="row => row.id"
            :expand-row-keys="detailDrawer.expandRows"
            @expand-change="handleTableExpand"
          >
            <el-table-column type="expand" width="50">
              <template slot-scope="props">
                <el-descriptions :column="2">
                  <el-descriptions-item v-for="(item, index) in detailDrawer.currentExpandData" :key="index" :label="item.ruleName">{{ item.executeResult }}</el-descriptions-item>
                </el-descriptions>
              </template>
            </el-table-column>
            <el-table-column label="版本" prop="version" width="100" />
            <el-table-column label="覆盖范围" prop="executeResult" width="100" />
            <el-table-column label="更新人" prop="updateBy" width="100" />
            <el-table-column label="更新时间" prop="updateTime" width="100" />
            <el-table-column label="操作" width="80" align="center" fixed="right">
              <template #default="{ row }">
                <el-button size="mini" type="text" style="border: 0px;" @click="downloadLog(row)">下载</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="detailDrawer.pageData.total" :page.sync="detailDrawer.logQuery.page" :limit.sync="detailDrawer.logQuery.limit" @pagination="handleFilter" />
        </el-descriptions-item>
      </el-descriptions>
      <el-button style="float: right; margin-right: 50px;" type="primary" size="mini" @click="detailDrawer.visible = false">确定</el-button>
    </el-drawer>

    <el-drawer :visible.sync="loopDrawer.visible" direction="ttb" size="70%" class="closed-header" :wrapperClosable="false">
      <LoopSelect v-if="loopDrawer.visible" :stepForm.sync="loopDrawer" :action-type="loopDrawer.action" @submit-over="handleSubmitLoop" />
    </el-drawer>

    <el-dialog :title="conditionDialog.title" :visible.sync="conditionDialog.visible" width="700px" append-to-body>
      <StepTwo ref="stepTwo" :form-two.sync="conditionDialog.data" action-type="view" />
    </el-dialog>

  </div>
</template>

<script>
import {
  getAllDict,
  getGroupPageList,
  getGroupCreater,
  getGroupDetail,
  downloadGroupExecuteLog,
  deleteGroup,
  executeGroup,
  getGroupExecuteLog,
  getGroupExecuteLogDetail,
  stopTask, startTask, getRuleGroupTopicList, getRuleGroupTAutoUpdateFrequencyList
} from "@/api/tagPlatform";
import { deepClone, downloadByUrl } from "@/utils";
import Pagination from "@/components/Pagination";
import { Notification } from "element-ui";
import LoopSelect from "../loopSelect";
import StepTwo from "../loopSelect/Components/StepTwo";

export default {
  name: "TagGroup",
  components: {StepTwo, LoopSelect, Pagination },
  data() {
    return {
      loading: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        createBy: undefined,
        ruleGroupName: undefined,
        buDomain: '全部',
        tagType: '全部',
        order: {
          column: undefined,
          operator: undefined
        },
        topic: undefined,
        autoUpdateFrequency: undefined,
        updateStatus: '全部',
      },
      options: {
        buDomains: [],
        tagTypes: [],
        tagStatus: [],
        creater: [],
        tagForm: ['逻辑标签', '手工标签'],
        topics: [],
        autoUpdateFrequencyOptions: [],
      },
      tagStatusMap: {
        '创建中': 'primary',
        '创建成功': 'success',
        '创建失败': 'danger',
      },
      detailDrawer: {
        visible: false,
        tableEditable: false,
        explainEditable: false,
        logQuery: {
          ruleGroupId: undefined,
          page: 1,
          limit: 10,
        },
        pageData: {
          rows: [],
          total: 0,
        },
        form: {
          id: undefined,
          ruleGroupName: undefined,
          ruleGroupId: undefined,
          buDomain: undefined,
          ruleGroupType: undefined,
          createBy: undefined,
          createTime: undefined,
          updateMode: undefined,
          autoUpdateFrequency: undefined,
          conditionShow: undefined,
          cronExpression: undefined,
          remark: undefined,
          ruleCombinationMode: undefined,
          rules: [],
          includeRuleGroupIds: [],
          tagIds: [],
        },
        originTagDefinitions: [],
        originTagExplain: undefined,
        expandRows: [],
        currentExpandData: {}
      },
      currentRow: {},
      loopDrawer: {
        visible: false,
        action: undefined,
        formOne: {
          ruleGroupName: undefined,
          buDomain: undefined,
          ruleGroupType: undefined,
          updateMode: '1',
          cronExpression: undefined,
          autoUpdateFrequency: '手动',
          dateType: '天',
          weekDay: '2',
          dayType: 'first',
          day: undefined,
          remark: undefined,
          groupType: 'add',
        },
        formTwo: {
          conditionGroupType: '交集',
          conditionGroups: [],
          exceptGroups: [],
          checkedGroupObjs: [],
        },
        formThree: {
          referenceQuota: [],
        },
        mainForm: {},
        notShowTypes: ['为空', '不为空'],
        multipleOptions: ['包含', '不包含'],
        conditionShow: {'交集': "and" , '并集': 'or', '差集': 'not in'}
      },
      conditionDialog: {
        visible: false,
        title: '条件视图',
        data: undefined
      }
    }
  },
  created() {
    getAllDict().then(res => {
      this.options.buDomains = ['全部'].concat(res.data.buDomains);
      this.options.tagTypes = ['全部'].concat(res.data.tagTypes);
      this.options.tagStatus = [{ name: '全部', code: '全部'}].concat(res.data.tagStatus);
    })
    getGroupCreater().then(res => {
      this.options.creater = res.data;
    })
    getRuleGroupTopicList().then(res => {
      this.options.topics = res.data;
    })
    getRuleGroupTAutoUpdateFrequencyList().then(res => {
      this.options.autoUpdateFrequencyOptions = res.data;
    })
    this.handleQuery();
  },
  methods: {
    handleFilter() {
      this.loading =true;
      let param = deepClone(this.queryForm);
      for (const key in param) {
        if (param[key] === '全部') {
          param[key] = undefined
        }
      }
      getGroupPageList(param).then(res => {
        this.pageData.rows = res.data.datas;
        this.pageData.total = res.data.total;
        this.loading = false;
      })
    },
    handleQuery() {
      this.queryForm.page = 1;
      this.handleFilter();
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleQuery();
    },
    resetLoopForm() {
      this.loopDrawer.mainForm = {};
      this.loopDrawer.formOne = {
        ruleGroupName: undefined,
        buDomain: undefined,
        ruleGroupType: undefined,
        updateMode: '1',
        cronExpression: undefined,
        autoUpdateFrequency: '手动',
        dateType: '天',
        weekDay: '2',
        dayType: 'first',
        day: undefined,
        remark: undefined,
        topic: undefined,
        createMode: undefined,
      };
      this.loopDrawer.formTwo= {
        conditionGroupType: '交集',
        conditionGroups: [],
        exceptGroups: [],
        checkedGroupObjs: [],
      };
      this.loopDrawer.formThree = {
        referenceQuota: []
      }
    },
    transForm(form, data) {
      if (form === 'formOne') {
        let dayInfo = {
          dateType: '天',
          weekDay: '2',
          dayType: 'first',
        }
        dayInfo.dateType = data.autoUpdateFrequency !== '手动'? data.updateMode.replaceAll('按', ''): '天';

        if (data.cronExpression) {
          const cronArray = data.cronExpression.split(' ');
          switch (dayInfo.dateType) {
            case '天':
              dayInfo.weekDay = 2;
              dayInfo.dayType = 'first';
              dayInfo.dateType = '天';
              break;
            case '周':
              dayInfo.weekDay = cronArray[cronArray.length -1];
              dayInfo.dayType = 'first';
              dayInfo.dateType = '周';
              break;
            case '月':
              switch (data.cronExpression) {
                case '0 0 1 1 * ?':
                  dayInfo.dayType = 'first';
                  break;
                case '0 0 1 L * ?':
                  dayInfo.dayType = 'last';
                  break;
                default:
                  dayInfo.dayType = 'any';
                  break;
              }
              dayInfo.weekDay = 2;
              dayInfo.dateType = '月';
              break;
          }
        }
        return {
          buDomain: data.buDomain,
          autoUpdateFrequency: data.autoUpdateFrequency,
          updateMode: data.updateMode,
          ruleGroupName: data.ruleGroupName,
          ruleGroupType: data.ruleGroupType,
          remark: data.remark,
          cronExpression: data.cronExpression,
          topic: data.topic,
          createMode: data.createMode,
          groupType: 'edit',
          ...dayInfo
        };
      } else if (form === 'formTwo') {
        const conditionType = data.ruleCombinationMode;
        let conditionGroups = [];
        if (data.ruleDtos) {
          conditionGroups = data.ruleDtos.map(e => {
            let group = {};
            group.conditionName = e.ruleName;
            group.conditionType = e.conditionCombinationMode;
            if (e.ruleDetails) {
              group.conditions = e.ruleDetails.map(ele => {
                let temp = {
                  tagId: ele.tagId,
                  tagName: ele.tagName,
                  tagValType: ele.tagValType,
                  compareType: ele.parentContrastOperator,
                  compareValue: undefined,
                  leftCompareValue: undefined,
                  rightCompareValue: undefined,
                  leftCompareSymbol: undefined,
                  rightCompareSymbol: undefined,}
                if (this.loopDrawer.multipleOptions.indexOf(ele.parentContrastOperator) > -1) {
                  temp.compareValue = ele.tagVal.split(',')
                  return temp;
                } else if (ele.tagVal !== null && ele.tagVal !== undefined) {
                  temp.compareValue = ele.tagVal;
                  return temp;
                } else if (this.loopDrawer.notShowTypes.indexOf(ele.parentContrastOperator) > -1) {
                  return temp;
                }else if (ele.contrastDefinitions && ele.contrastDefinitions.length > 0) {
                  const range = {
                    leftCompareValue: ele.contrastDefinitions[0].tagVal,
                    rightCompareValue: ele.contrastDefinitions[1].tagVal,
                    leftCompareSymbol: ele.contrastDefinitions[0].contrastOperator,
                    rightCompareSymbol: ele.contrastDefinitions[1].contrastOperator,}
                  temp = Object.assign(temp, range)
                  return temp;
                }
              })
            }
            return group;
          })
        }
        return {
          conditionGroupType: conditionType,
          conditionGroups: conditionGroups,
          exceptGroups: data.excludeRuleGroupDtos ? data.excludeRuleGroupDtos.map(e => e.ruleGroupId): [],
          checkedGroupObjs: data.excludeRuleGroupDtos ? data.excludeRuleGroupDtos: [],
        }
      }
    },
    handleEdit(row) {
      this.resetLoopForm();
      if (row && row.id) {
        getGroupDetail(row.id).then(res => {
          if (res.code === 200) {
            this.loopDrawer.mainForm = {
              id: row.id,
            }
            this.loopDrawer.formOne = this.transForm('formOne', res.data);
            console.log("=============this.loopDrawer.formOne", this.loopDrawer.formOne)
            this.loopDrawer.formTwo = this.transForm('formTwo', res.data);
            console.log("=============this.loopDrawer.formTwo", this.loopDrawer.formTwo)
            this.loopDrawer.formThree = {
              referenceQuota: res.data.tpTagDtos ? res.data.tpTagDtos.map(e => e.id): [],
            }
            this.loopDrawer.visible = true;
            this.loopDrawer.action = 'edit';
            this.$forceUpdate();
          }
        })
      } else {
        this.loopDrawer.action = 'add';
        this.loopDrawer.visible = true;
      }
    },
    handleSubmitLoop() {
      this.loopDrawer.visible = false;
      this.handleQuery();
    },
    handleDeleteGroup(row) {
      deleteGroup(row.id).then(res => {
        if (res.code === 200) {
          Notification.success('删除成功');
          this.handleQuery();
        }
      })
    },
    handleExecute(row) {
      executeGroup(row.ruleGroupId).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功');
          this.handleQuery();
        }
      })
    },
    handleViewDetail(row) {
      this.detailDrawer.form = {
        id: undefined,
        ruleGroupName: undefined,
        ruleGroupId: undefined,
        buDomain: undefined,
        ruleGroupType: undefined,
        createBy: undefined,
        createTime: undefined,
        updateMode: undefined,
        autoUpdateFrequency: undefined,
        conditionShow: undefined,
        cronExpression: undefined,
        remark: undefined,
        ruleCombinationMode: undefined,
        rules: [],
        includeRuleGroupIds: [],
        tagIds: [],
      };
      getGroupDetail(row.id).then(res => {
        if (res.code === 200) {
          this.conditionDialog.data = {};
          this.detailDrawer.form = res.data;
          const conditionType = ' <font style="color: dodgerblue"> '+ this.loopDrawer.conditionShow[res.data.ruleCombinationMode] +' </font>';
          let tempShow = [];
          if (res.data.ruleDtos) {
            tempShow = res.data.ruleDtos.map(e => {
              if (e.ruleDetails.length > 0) {
                const show = e.ruleDetails.map(ele => {
                  if (ele.tagVal !== null && ele.tagVal !== undefined) {
                    return ele.tagName + ' <strong> ' + ele.parentContrastOperator + ' </strong> ' +ele.tagVal
                  } else if (this.loopDrawer.notShowTypes.indexOf(ele.parentContrastOperator) > -1) {
                    return ele.tagName + ' <strong> ' + ele.parentContrastOperator + ' </strong> '
                  } else if (ele.contrastDefinitions && ele.contrastDefinitions.length > 0) {
                    return ele.contrastDefinitions[0].tagVal + ' <strong> ' + ele.contrastDefinitions[0].contrastOperator + ' </strong> ' + ele.tagName + ' <strong> ' + ele.contrastDefinitions[1].contrastOperator + '</strong> '+ ele.contrastDefinitions[1].tagVal
                  }
                }).join(e.conditionCombinationMode);
                return show;
              }
              return;
            }).filter(e => e!==undefined);
          }
          if (res.data.excludeRuleGroupDtos && res.data.excludeRuleGroupDtos.length > 0) {
            tempShow.push(' <strong>排除</strong> "' + res.data.excludeRuleGroupDtos.map(e => e.ruleGroupName).join('、') + ' " 中的用户');
          }
          this.detailDrawer.form.conditionShow = tempShow.length > 1? '(' + tempShow.join(')  ' + conditionType + '  (') + ')': tempShow.join(conditionType);
          this.detailDrawer.visible = true;
          this.conditionDialog.data = this.transForm('formTwo', res.data);
          this.$forceUpdate();
        }
      })
      this.detailDrawer.logQuery = { ruleGroupId: row.ruleGroupId , page: 1, limit: 5 };
      this.handleQueryLogs();
    },
    handleQueryLogs() {
      getGroupExecuteLog(this.detailDrawer.logQuery).then((res) => {
        this.detailDrawer.pageData.rows = res.data.datas;
        this.detailDrawer.pageData.total = res.data.total;
      })
    },
    downloadLog(row) {
      const param = { ruleGroupId: row.ruleGroupId, version: row.version };
      this.loading = true;
      downloadGroupExecuteLog(param).then((res) => {
        if (res.code === 200 && res.data) {
          downloadByUrl(res.data);
          this.loading = false;
        } else {
          Notification.warning('无可下载的数据');
          this.loading = false;
        }
      })
    },
    handleTableExpand(row, expandedRows) {
      this.detailDrawer.expandRows = [];
      if (expandedRows && expandedRows.length > 0) {
        this.detailDrawer.expandRows.push(row.id);
        getGroupExecuteLogDetail({ ruleGroupId: row.ruleGroupId, version: row.version }).then((res) => {
          this.detailDrawer.currentExpandData = res.data.ruleExecuteResults;
        })
      }
    },
    handleStopTask(row) {
      stopTask(row.ruleGroupId).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功');
          this.handleQuery();
        }
      })
    },
    handleStartTask(row) {
      startTask(row.ruleGroupId).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功');
          this.handleQuery();
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.keyClass {
  ::v-deep .el-input__inner {
    min-width: 250px !important;
  }
}

::v-deep .el-date-editor .el-input__inner {
  text-align: center;
}

.half-width {
  width: 45%;
  ::v-deep .el-input .el-input__inner {
    max-width: 100% !important;
  }
  ::v-deep .el-input__inner {
    max-width: 100% !important;
  }
  ::v-deep .el-date-editor .el-input__inner {
    max-width: 100% !important;
  }
}

::v-deep .el-descriptions-row .el-descriptions-item__label {
  font-weight: 600;
}

::v-deep .el-descriptions-item__container .labelClass {
  width: 80px;
}

::v-deep .demo-table-expand {
  font-size: 12px;
}
::v-deep .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
::v-deep .demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

::v-deep .table-button {
  border: 0px;
  padding: 5px !important
}

::v-deep .closed-header .el-drawer__header {
  margin-bottom: 2px;
  padding: 2px;
}
</style>
