<template>
    <el-dialog
        title="订单范围"
        :visible.sync="visible"
        width="400px"
        center
        @close="closeDialog"
        >
        <div class="dialogContent">
          <p>查看截止今天该配送的订单的满足情况（即剔除了没到下次送货时间的订单，满足指记账）</p>
          
          <div class="dimensionSelectWrap">
            <el-select v-model="dimension" placeholder="请选择" >
              <el-option
                v-for="item in dimensionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <!-- 按周 -->
          <div v-if="dimension==='week'">
            <div v-for="(item, index) in weekForm" class="routeSelectBox">
              <span class="label">{{item.label}}</span>
              <el-select v-model="item.routeIds" multiple placeholder="请选择送货路线">
                <el-option
                  v-for="item in routes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span class="placeRemoveIcon"></span>
            </div>
          </div>

          <!-- 按天 -->
          <div v-if="dimension==='day'">
            <div v-for="(item, index) in dayForm" class="routeSelectBox routeSelectBox1">
              <span class="label">{{baseDayMap[index]}}（{{item.label}}）</span>
              <el-select v-model="item.routeIds" multiple placeholder="请选择送货路线">
                <el-option
                  v-for="item in routes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <i class="el-icon-remove" @click="()=>{dayForm.splice(index,1)}" v-if="index!==0 && index === dayForm.length - 1"></i>
              <span class="placeRemoveIcon" v-else></span>
            </div>
            <i class="el-icon-circle-plus" @click="addItem"></i>
          </div>
        </div> 
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { getRouteSelectList, saveRouteRule, getRouteRule } from "@/api/appTask";
  export default {
    props: ['orgCode', 'dcCode'],
    data() {
      return {
        visible: false,
        dimension: 'week',
        dimensionOptions: [
          { label: '按周', value: 'week' },
          { label: '按日', value: 'day' },
        ],
        baseDayMap: {
          0: '今天',
          1: '明天',
          2: '后天',
          3: '第四天',
          4: '第五天',
          5: '第六天',
          6: '第七天',
          7: '第八天',
          8: '第九天',
          9: '第十天',
          10: '第十一天',
          11: '第十二天',
          12: '第十三天',
          13: '第十四天',
          14: '第十五天',
          15: '第十六天',
          16: '第十七天',
          17: '第十八天',
          18: '第十九天',
          19: '第二十天'
        },
        routes: [],
        weekForm: [
          { dayOfWeek: '1', routeIds: [], label: '周一' },
          { dayOfWeek: '2', routeIds: [], label: '周二' },
          { dayOfWeek: '3', routeIds: [], label: '周三' },
          { dayOfWeek: '4', routeIds: [], label: '周四' },
          { dayOfWeek: '5', routeIds: [], label: '周五' },
          { dayOfWeek: '6', routeIds: [], label: '周六' },
          { dayOfWeek: '7', routeIds: [], label: '周日' },
        ],
        dayForm: [
          { dateKey: dayjs().format('YYYY-MM-DD'), routeIds: [], label: dayjs().format('M.D') },
        ]
      };
    },
    created() {
      this.visible = true;

      getRouteSelectList(`?size=100&orgCode=${this.orgCode}&dcCode=${this.dcCode}&page=1`).then(res => {
        this.routes = res.data;
        console.log('routes',this.routes)
      })

      getRouteRule({orgCode:this.orgCode,dcCode:this.dcCode}).then(res => {
        console.log(res);
        const resData = res.data;

        this.weekForm = this.weekForm?.map(i => {
          if(resData?.weeks?.find(item => item.dayOfWeek === i.dayOfWeek)) {
            return {
              ...i,
              routeIds: resData?.weeks?.find(item => item.dayOfWeek === i.dayOfWeek).routeIds ?? []
            }
          } else {
            return i
          }
        }) ?? this.weekForm;
        console.log('weekForm',this.weekForm)

        this.dayForm = (resData?.days && resData?.days.length) ? resData?.days?.map(i => {
          return {
            dateKey: i.dateKey, 
            routeIds: i.routeIds ?? [],
            label: dayjs(i.dateKey).format('M.D')
          }
        }) : this.dayForm;
      })
    },
    methods: {
      addItem() {
        if(this.dayForm.length === 20) return;
        const lastItemDateKey = this.dayForm[this.dayForm.length - 1].dateKey;
        this.dayForm.push(
          { dateKey: dayjs(lastItemDateKey).add(1,'day').format('YYYY-MM-DD'), routeIds: [], label: dayjs(lastItemDateKey).add(1,'day').format('M.D') },
        )
      },
      closeDialog() {
        this.visible = false;
        this.$emit("closeRouteSettingDialog");
      },
      confirm() {
        const dayEmptyIndex = this.dayForm.findIndex(i => !i.routeIds.length);
        if(dayEmptyIndex > -1) {
          this.$notify({
            title: '警告',
            message: `${this.baseDayMap[dayEmptyIndex]}（${this.dayForm[dayEmptyIndex].label}）未配置路线`,
            type: 'warning'
          });
          // Notification.success(`${this.baseDayMap[dayEmptyIndex]}（${this.dayForm[dayEmptyIndex].label}）未配置路线`)
          return;
        }

        this.closeDialog();

        const params = {
          orgCode: this.orgCode,
          dcCode: this.dcCode,
        }

        if(this.dimension === 'week') {
          params['weeks'] = this.weekForm.map(i => {
            return {
              dayOfWeek: i.dayOfWeek,
              routeIds: i.routeIds
            }
          });
        } else {
          params['days'] = this.dayForm.map(i => {
            return {
              dateKey: i.dateKey,
              routeIds: i.routeIds
            }
          })
        }

        console.log('params',params);

        saveRouteRule(params).then(res => {
          Notification.success('保存成功')
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
.dimensionSelectWrap {
  text-align: right;
  margin-bottom: 10px;
  padding-right: 25px;
}

.routeSelectBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    width: 3em;
  }
  ::v-deep(.el-select) {
    flex: 1;
    .el-input__inner {
      max-width: unset!important;
    }
  }
  i {
    color: red;
    font-size: 20px;
    margin-left: 5px;
    opacity: 0;
  }
}

.routeSelectBox1 {
  .label {
    width: 9em;
  }
  i {
    opacity: 1;
  }
}

.placeRemoveIcon {
  display: inline-block;
  width: 25px;
}
    
.el-icon-circle-plus {
  color: red;
  font-size: 20px;
  margin-left: 6em;
}
</style>
