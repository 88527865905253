<template>
  <div class="schedulingPlan-iframe-page">
    <div class="schedulingPlan-iframe-search">
      <!--            <v-signboard :title="'请购单明细'" />-->
      <el-form
        class="main-search"
        size="mini"
        label-width="auto"
        ref="actualProdPlan-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleSubmit"
      >
        <el-form-item label="年月" size="mini">
          <el-input v-model="form.plan_month" clearable placeholder="请输入年月(yyyyMM)"/>
        </el-form-item>
        <el-form-item label="生产类型" size="mini">
          <el-select clearable v-model="form.prod_type">
            <el-option
              v-for="item in options.prodTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品等级" size="mini">
          <el-select clearable v-model="form.grade" multiple>
            <el-option
              v-for="item in options.productGradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="大类" size="mini">
          <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
            <el-option
              v-for="item in options.productMaxCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中类" size="mini">
          <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
            <el-option
              v-for="item in options.productMidCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小类" size="mini">
          <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
            <el-option
              v-for="item in options.productMinCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品赛道" size="mini">
          <el-select clearable v-model="form.race_name" style="width:90px">
            <el-option
              v-for="item in options.raceNameLists"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原始料号" size="mini">
          <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
        </el-form-item>

        <el-form-item label="是否有客订" size="mini">
          <el-select clearable v-model="form.bo_kd_plu_count_type">
            <el-option label="是" :key="1" :value="1"></el-option>
            <el-option label="否" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="预排产量分类" size="mini">
          <el-select clearable v-model="form.arrange_count_type">
            <el-option label="全部" :key="0" :value="0"></el-option>
            <el-option label="未下单" :key="1" :value="1"></el-option>
            <el-option label="已下单" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          class="form-btn-item"
          size="mini"
          type="primary"
          @click="handleSearchAction('search')"
        >查 询
        </el-button>
        <el-button
          class="form-btn-item"
          size="mini"
          @click="handleSearchAction('reset')"
        >重 置
        </el-button>

        <el-button
          size="mini"
          type="primary"
          :loading="btnStatus.isExportLoading"
          @click="handleTableHeadAction('export')"
        >导 出
        </el-button>
      </el-form>
    </div>
    <div class="schedulingPlan-iframe-content">
      <el-table
        class="table"
        stripe
        border
        height="500"
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
      >
        <template v-for="(item, index) in tableObj.arrangePluReportHead">
          <el-table-column v-if="['plan_month', 'prod_type', 'material_code'].includes(item.prop)" fixed="left"
                           v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import {arrangePluReportHead} from '../config';
import {tableLoading, paginationParams, urlParams, optionsObj} from '@/mixins';
import {excelExport} from "@/utils";
import {handleArrangePluReportList} from '@/api/schedulingPlan';
import {keepTwoDecimals} from "../../../utils";

export default {
  // 实际排产计划
  name: 'ArrangePluReport',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      form: {
        plan_month: '',
        prod_type: '',
        grade: '',
        race_name: '',
        main_category_name: '',
        mid_category_name: '',
        sub_category_name: '',
        material_code: ''
      },
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      options: {
        prodTypeOptions: [
          {label: '生产中心', value: '生产中心'},
          {label: 'OEM中心', value: 'OEM中心'},
          {label: '办公', value: '办公'},
          {label: '生产中心/办公', value: '生产中心/办公'},
        ]
      },
      btnStatus: {
        isDisable: true,
        isExportLoading: false,         // 导出loading
      },
      tableObj: {
        arrangePluReportHead
      },
      lists: []
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.handleUrlParams().then(res => {
      this.getLists();
    })
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          bo_kd_plu_count_type: form.bo_kd_plu_count_type || 0,
          arrange_count_type: form.arrange_count_type || 0,
          operator: this.urlParams.operator,
          plan_month: form.plan_month || '',
          prod_type: form.prod_type || '',
          grade: form.grade || '',
          race_name: form.race_name || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          material_code: form.material_code || '',
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        this.tableStatus.isLoading = true;
        const {code, data, msg} = await handleArrangePluReportList(params);
        if (data) {
          const {item_list, total} = data;
          console.log(item_list, 'item_list-=-=-=-')
          this.modifyData(item_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取请购单明细列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            plan_month: item.plan_month || '--',
            prod_type: item.prod_type || '--',
            plu_code: item.plu_code || '--',
            plu_name: item.plu_name || '--',
            material_code: item.material_code || '--',
            cargono: item.cargono || '--',
            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            grade: item.grade || '--',
            qg_count_box: item.qg_count_box || 0,
            qg_count: item.qg_count || 0,
            hq_last_month_stock_count_box: item.hq_last_month_stock_count_box || 0,
            hq_last_month_store_ratio: item.hq_last_month_store_ratio || 0,
            bo_kd_plu_count_box: item.bo_kd_plu_count_box || 0,
            stock_up_count_box: item.stock_up_count_box || 0,
            actual_arrange_count_box: item.actual_arrange_count_box || 0,
            total_previous_put_count_box: item.total_previous_put_count_box || 0,
            theory_shipment_count_box: item.theory_shipment_count_box || 0,
            confirm_arrange_count_box: item.confirm_arrange_count_box || 0,
            adjust_count_box: item.adjust_count_box || 0,
            hq_expect_store_count_box: item.hq_expect_store_count_box || 0,
            hq_month_avg_sale_count_box: item.hq_month_avg_sale_count_box || 0,
            hq_expect_store_ratio: item.hq_expect_store_ratio || 0.00,
            bo_kd_fill_rate: item.bo_kd_fill_rate || 0.00,
            bo_kd_fill_rate_text: keepTwoDecimals((item.bo_kd_fill_rate || 0) * 100) + '%',
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 头部筛选相关操作
    handleSearchAction(type) {
      const funcObj = {
        'search': () => {           // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {            // 重置
          this.form = {
            plan_month: '',
            prod_type: '',
            grade: '',
            race_name: '',
            main_category_name: '',
            mid_category_name: '',
            sub_category_name: '',
            plu_code: ''
          };
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      const topHeaders = {
        plan_month: '年月',
        material_code: '原始料号',
        cargono: '货号',
        prod_type: '生产类型',
        plu_mnemonic_code: '型号',
        plu_code: '商品编码',
        plu_name: '品名',
        race_name: '赛道',
        main_category_name: '大类',
        mid_category_name: '中类',
        sub_category_name: '小类',
        grade: '等级',
        qg_count_box: '请购单量',
        hq_last_month_stock_count_box: new Date().getFullYear() + "" + ((new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1): (new Date().getMonth() + 1)) + '月底总部库存',
        hq_last_month_store_ratio: new Date().getFullYear() + "" + ((new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + '月底总部库存系数',
        bo_kd_plu_count_box: '分公司客订',
        // stock_up_count_box: '备货',
        total_previous_put_count_box: '已下量',
        theory_shipment_count_box: '理论请购量',
        actual_arrange_count_box: '实际排产量',
        adjust_count_box: '调整量',
        hq_expect_store_count_box: '月底预计库存',
        hq_month_avg_sale_count_box: '月均销量',
        hq_expect_store_ratio: '月底预计库存系数',
        bo_kd_fill_rate_text: '客订满足率',
      };
      try {
        this.btnStatus.isExportLoading = true;
        const {code, data} = await handleArrangePluReportList({operator: this.urlParams.operator, is_return_all: true});
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {item_list} = data;
          if (item_list.length > 0) {
            for (let i = 0; i < item_list.length; i++) {
              item_list[i]['bo_kd_fill_rate_text'] = keepTwoDecimals((item_list[i].bo_kd_fill_rate || 0) * 100) + '%'
            }
            excelExport({
              topHeaders,
              data: item_list,
              title: '请购单明细'
            })
          }
          if (item_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------请购单明细导出接口有误');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.schedulingPlan-iframe {
  &-page {

  }

  &-search {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  &-content {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;

    .list-head {
      margin-bottom: 20px;
    }
    ::v-deep .el-table__empty-text {
      text-align: left;
    }
  }
}
</style>

