var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "groupForm",
      staticClass: "common-form",
      attrs: {
        size: "mini",
        "label-width": "120px",
        inline: true,
        model: _vm.form,
        "label-position": "left",
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.type === "editTaskHome" ? "选择任务" : "任务名称",
            prop: "name",
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.dataForm.taskName))])]
      ),
      _vm.type !== "editTaskHome"
        ? _c(
            "el-form-item",
            { attrs: { label: "选择模板", prop: "template" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.handleTemplateChange },
                  model: {
                    value: _vm.dataForm.template,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "template", $$v)
                    },
                    expression: "dataForm.template",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { attrs: { label: "block", value: "block" } },
                    [
                      _vm._v("竖版"),
                      _c("img", {
                        staticStyle: { height: "80px", display: "block" },
                        attrs: {
                          src: _vm.viewShowTemplateUrl[_vm.imageTemplate][
                            "block"
                          ],
                        },
                      }),
                    ]
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: "horizontal", value: "horizontal" } },
                    [
                      _vm._v("横版"),
                      _c("img", {
                        staticStyle: { height: "80px", display: "block" },
                        attrs: {
                          src: _vm.viewShowTemplateUrl[_vm.imageTemplate][
                            "horizontal"
                          ],
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type !== "editTaskHome"
        ? _c(
            "el-form-item",
            { attrs: { label: "颜色配置", prop: "template" } },
            [
              _c(
                "el-row",
                { staticStyle: { display: "table" } },
                [
                  _vm._l(
                    _vm.dataForm.template === "block"
                      ? _vm.options.blockColor
                      : _vm.options.horizontalColor,
                    function (item, index) {
                      return _c(
                        "el-col",
                        {
                          key: index,
                          attrs: {
                            span:
                              _vm.dataForm.template === "horizontal" &&
                              item.value === "backGroundColor"
                                ? 24
                                : 6,
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: { "vertical-align": "text-bottom" },
                            },
                            [_vm._v(_vm._s(item.label))]
                          ),
                          _c("el-color-picker", {
                            model: {
                              value: _vm.dataForm[item.value],
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, item.value, $$v)
                              },
                              expression: "dataForm[item.value]",
                            },
                          }),
                          _vm.dataForm.template === "horizontal" &&
                          item.value === "backGroundColor"
                            ? _c("OssUpload", {
                                ref: "ossUpload",
                                refInFor: true,
                                staticClass: "custom-picture-card",
                                staticStyle: { display: "table" },
                                attrs: {
                                  "value-to": _vm.dataForm.customImageUrl,
                                  maxFileSize: 1024,
                                  "list-type": "picture-card",
                                  limit: 1,
                                  "always-show-button": false,
                                  tips: "尺寸1065*375，底部150高勿放重要内容以免遮挡数据",
                                  tipMessageShow: false,
                                  "accept-type": _vm.imgTypes,
                                },
                                on: {
                                  "update:valueTo": function ($event) {
                                    return _vm.$set(
                                      _vm.dataForm,
                                      "customImageUrl",
                                      $event
                                    )
                                  },
                                  "update:value-to": function ($event) {
                                    return _vm.$set(
                                      _vm.dataForm,
                                      "customImageUrl",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "text" },
                          on: { click: _vm.syncConfigToOtherTask },
                        },
                        [_vm._v("一键同步颜色配置到其他子任务")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "800px" },
          attrs: { label: "选择任务汇总的展示字段", prop: "summaryFields" },
        },
        [
          _vm.dataForm.template === "horizontal"
            ? _c("div", { staticClass: "text-light" }, [
                _vm._v(
                  "最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据"
                ),
              ])
            : _c("div", { staticClass: "text-light" }, [
                _vm._v(
                  "最多可选4个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据"
                ),
              ]),
          _c(
            "el-checkbox-group",
            {
              staticStyle: { width: "700px" },
              attrs: { max: _vm.dataForm.template === "horizontal" ? 2 : 4 },
              model: {
                value: _vm.dataForm.summaryFields,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "summaryFields", $$v)
                },
                expression: "dataForm.summaryFields",
              },
            },
            _vm._l(_vm.summaryFieldMap, function (item, key) {
              return _c(
                "div",
                { key: key },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "text-light" }, [
                          _vm._v(_vm._s(key)),
                        ]),
                      ]),
                      _vm._l(item, function (e) {
                        return _c(
                          "el-col",
                          { key: e.title, attrs: { span: 5 } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  disabled:
                                    e.key === "boostBonus" &&
                                    !_vm.form.boostTask,
                                  value: e.key,
                                  label: e.key,
                                },
                              },
                              [_vm._v(_vm._s(e.title))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { width: "800px" },
          attrs: { label: "选择店铺页的任务展示字段", prop: "shopFields" },
        },
        [
          _vm.dataForm.template === "horizontal"
            ? _c("div", { staticClass: "text-light" }, [
                _vm._v(
                  "最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据"
                ),
              ])
            : _c("div", { staticClass: "text-light" }, [
                _vm._v(
                  "最多可选4个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据"
                ),
              ]),
          _c(
            "el-checkbox-group",
            {
              staticStyle: { width: "700px" },
              attrs: { max: _vm.dataForm.template === "horizontal" ? 2 : 4 },
              model: {
                value: _vm.dataForm.shopFields,
                callback: function ($$v) {
                  _vm.$set(_vm.dataForm, "shopFields", $$v)
                },
                expression: "dataForm.shopFields",
              },
            },
            _vm._l(_vm.shopFieldMap, function (item, key) {
              return _c(
                "div",
                { key: key },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("span", { staticClass: "text-light" }, [
                          _vm._v(_vm._s(key)),
                        ]),
                      ]),
                      _vm._l(item, function (e) {
                        return _c(
                          "el-col",
                          { key: e.title, attrs: { span: 5 } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  disabled:
                                    e.key === "boostBonus" &&
                                    !_vm.form.boostTask,
                                  value: e.key,
                                  label: e.key,
                                },
                              },
                              [_vm._v(_vm._s(e.title))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }