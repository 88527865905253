<template>
  <div style="padding: 20px">
    <div style="margin-bottom: 10px">
      <el-row :gutter="20">
        <el-col :span="2">
          <div class="el-statistic"><span class="number">
        {{ dashboard.totalGoods }}
      </span></div><div class="con"><span class="title"> 全部商品</span><!----></div>
        </el-col>
        <el-col :span="2">
          <div class="el-statistic"><span class="number-red">
          {{ dashboard.maintainGoods }}
           </span></div><div class="con"><span class="title"> 需维护品类</span></div>
        </el-col>
      </el-row>
    </div>
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="商品型号">
          <el-input v-model="queryForm.keyWord" size="mini" clearable placeholder="商品名称/商品型号" />
        </el-form-item>
        <el-form-item label="一级品类">
          <DictSelect
            ref="one"
            type="one"
            :value.sync="queryForm.oneCategory"
            linkageRef="two"
            :dictFun="dictFun"
            default-first-option
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="二级品类">
          <DictSelect
            ref="two"
            type="two"
            :value.sync="queryForm.twoCategory"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="大类">
          <DictSelect
            ref="main"
            type="main"
            :value.sync="queryForm.mainCategoryName"
            linkageRef="mid"
            :dictFun="dictFun"
            default-first-option
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="中类">
          <DictSelect
            ref="mid"
            type="mid"
            :value.sync="queryForm.midCategoryName"
            :dictFun="dictFun"
            linkageRef="sub"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="小类">
          <DictSelect
            ref="sub"
            type="sub"
            :value.sync="queryForm.subCategoryName"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="品类维护">
          <el-select v-model="queryForm.isMaintain" clearable>
            <el-option label="需要维护" :value="false" />
            <el-option label="已维护" :value="true" />
          </el-select>
        </el-form-item>

        <el-form-item label="MDM录入日期">
          <el-date-picker
            v-model="lrDay"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            date-format="yyyy-MM-dd"
            @change="handleDateChange($event, 'create')"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出商品信息</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport">导入商品数据</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="categoryDrawer.visible = true">品类维护</el-button>
          <el-popover
            placement="right"
            width="400"
            trigger="click">
            <div>
              使用说明：<br>
                &nbsp;&nbsp;&nbsp;1.排序按照二级品类排序，排序的上移、下移、跳转操作是二级品类下的排序变化<br>
                &nbsp;&nbsp;&nbsp;2.商品调整二级品类，排序默认为目标品类的最后一位。<br>
                &nbsp;&nbsp;&nbsp;3.商品管理批量导入功能，需要全量导入，二级品类下排序不能有重复。可以批量更新品类和序号  （仅更新商品品类和排序规则）<br>
                &nbsp;&nbsp;&nbsp;4.品类批量维护 需要全量导入，且排序不能有重复 （删表更新，不会影响已维护商品的品类）
            </div>
            <el-link icon="el-icon-info" type="info" slot="reference" style="margin-left: 10px">使用说明</el-link>
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="商品型号" prop="skuModel" width="90" fixed="left" />
      <el-table-column label="一级品类" prop="oneCategory" width="90" fixed="left" />
      <el-table-column label="二级品类" prop="twoCategory" width="90" fixed="left" />
      <el-table-column label="商品名称" prop="skuName" width="280" />
      <el-table-column label="大类" prop="mainCategoryName" width="80"  />
      <el-table-column label="中类" prop="midCategoryName" width="80" />
      <el-table-column label="小类" prop="subCategoryName" width="110" />
      <el-table-column label="商品图片" prop="skuUrl" width="90">
        <template #default="{ row }">
          <el-image :src="row.skuUrl" :preview-src-list="handleImageUrls(row)" lazy class="skuUrlThum">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="最新操作时间" prop="opTime" width="140" />
      <el-table-column label="MDM录入日期" prop="lrDay" width="120" />
      <el-table-column label="分公司最新使用时间" prop="orgUpdateTime" width="160" />
<!--      <el-table-column label="分公司使用" prop="orgUse" width="80">-->
<!--        <template #default="{ row }">-->
<!--          <el-switch v-model="row.orgUse" disabled />-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="排序" prop="sort" width="60" />
      <el-table-column label="快速排序" align="center" width="250">
        <template #default="{ row }">
          <div class="button-class">
            <el-button v-if="row.sort > 1" size="mini" type="text" style="border: 0px;" @click="handleMove(row, 'up')">上一位</el-button>
            <el-button v-if="row.skuModel !== currentRow.skuModel" size="mini" type="text" style="border: 0px;" @click="handleJump(row)">跳转</el-button>
            <span v-if="row.skuModel === currentRow.skuModel" style="margin-right: 10px; margin-left: 10px">
            <el-input-number size="mini" placeholder="请输入内容" style="width: 80px;" min="1" :precision="0" :controls="false" v-model="jumpVal" />
            <i class="el-icon-close icon-close-class" @click="currentRow = {}" />
            <i class="el-icon-check icon-check-class" @click="handleMove(row, 'any')" />
          </span>
            <el-button size="mini" type="text" style="border: 0px;" @click="handleMove(row, 'down')">下一位</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template #default="{ row }">
          <div class="button-class">
            <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
            <el-button size="mini" type="text" style="border: 0px;" @click="handleViewLog(row)">日志</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog title="商品品类维护"  :visible.sync="editDialog.visible" append-to-body width="300px">
<!--      <div>您当前选择的类别是：<br> &nbsp;&nbsp;&nbsp;&nbsp;<span style="color: red">{{ editDialog.form.oneCategory + '>' +  editDialog.form.twoCategory}}</span></div>-->
      <el-form ref="form" size="mini" :rules="editDialog.rules" label-width="auto" :model="editDialog.form">
        <el-form-item label="一级品类" prop="oneCategory">
          <DictSelect
            ref="edit-one"
            type="one"
            :value.sync="editDialog.form.oneCategory"
            linkageRef="edit-two"
            :dictFun="dictFun"
            default-first-option
            @changeLinkageRef="(ref, val, type) => handleChangeLinkageRef(ref, val, type, editDialog.form, 'formDictParam')"
          />
        </el-form-item>
        <el-form-item label="二级品类" prop="twoCategory">
          <DictSelect
            ref="edit-two"
            type="two"
            :value.sync="editDialog.form.twoCategory"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(formDictParam)"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitGoods">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="商品导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="goodsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px; overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>

    <el-drawer title="商品日志" :visible.sync="skuModelDrawer.visible" size="50%" direction="rtl" append-to-body>
      <el-collapse v-model="skuModelDrawer.activeNames" style="margin-left: 20px">
        <el-collapse-item title="最新分公司信息 Consistency" name="1">
          <el-descriptions title="">
            <el-descriptions-item label="分公司">{{ skuModelDrawer.orgInfo.orgInfo }}</el-descriptions-item>
            <el-descriptions-item label="时间">{{ skuModelDrawer.orgInfo.opTime }}</el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
        <el-collapse-item title="操作日志" name="2">
          <el-table
            :data="skuModelDrawer.skuLogs"
            height="calc(100vh - 250px)"
            v-loading="loading"
            size="mini"
            border
            ref="table"
            highlight-current-row
            :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
          >
            <el-table-column label="明细" prop="content" width="300" />
            <el-table-column label="操作时间" prop="opTime" width="140" />
            <el-table-column label="操作人" prop="opUser" width="100" />
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-drawer>

    <el-drawer title="品类维护" :visible.sync="categoryDrawer.visible" size="50%" direction="ltr" append-to-body>
      <Category />
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { goodsDict,  pageGoods, exportGoods, updateGoods, importGoods, jumpLocation, getDashBoard, getModelLog } from "@/api/appTask";
import { parseParam, parseTime } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import Category from "./compoments/Category";

export default {
  name: "Goods",
  components: {Category, Pagination, DictSelect},
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        keyWord: undefined,
        oneCategory: undefined,
        twoCategory: undefined,
        mainCategoryName: undefined,
        midCategoryName: undefined,
        subCategoryName: undefined,
        isMaintain: undefined,
        orgUse: undefined,
        lrDayBegin: undefined,
        lrDayEnd: undefined
      },
      lrDay: undefined,
      loading: false,
      dictFun: goodsDict,
      dictParam: {},
      formDictParam: {},
      editDialog: {
        visible: false,
        form: {
          oneCategory: undefined,
          twoCategory: undefined,
          skuModel: undefined,
          sort: undefined,
        },
        rules: {
          oneCategory: [{ required: true, message: " ", trigger: "change" }],
          twoCategory: [{ required: true, message: " ", trigger: "change" }],
        }
      },
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%95%86%E5%93%81%E5%93%81%E7%B1%BB%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      },
      jumpVal: undefined,
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      currentRow: {},
      dashboard: {
        totalGoods: undefined,
        maintainGoods: undefined
      },
      skuModelDrawer: {
        visible: false,
        orgInfo: {
          orgInfo: '',
          opTime: undefined
        },
        skuLogs: [],
        activeNames: ["1", "2"]
      },
      categoryDrawer: {
        visible: false,
      }
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.handleFilter();
    this.getGoodsDashboard();
  },
  methods: {
    handleDateChange(val) {
      if (val) {
        this.queryForm.lrDayBegin = parseTime(val[0], "{y}-{m}-{d}");
        this.queryForm.lrDayEnd = parseTime(val[1], "{y}-{m}-{d}");
      } else {
        this.queryForm.lrDayBegin = undefined;
        this.queryForm.lrDayEnd = undefined;
      }
    },
    getGoodsDashboard() {
      getDashBoard().then((res) => this.dashboard = res.data);
    },
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageGoods(parseParam(query)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        keyWord: undefined,
        oneCategory: undefined,
        twoCategory: undefined,
        mainCategoryName: undefined,
        midCategoryName: undefined,
        subCategoryName: undefined,
        isMaintain: undefined,
        orgUse: undefined,
        lrDayBegin: undefined,
        lrDayEnd: undefined
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              this[dict] = Object.assign({}, form)
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImageUrls(row) {
      console.log(row.skuUrls)
      return row.skuUrls && row.skuUrls.length > 0? row.skuUrls.split(','): [];
    },
    handleExport() {
      this.loading = true;
      exportGoods(this.queryForm).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '商品数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleEdit(row) {
      this.editDialog.form = {
        oneCategory: row.oneCategory,
        twoCategory: row.twoCategory,
        skuModel: row.skuModel,
        sort: row.sort
      }
      this.editDialog.visible = true;
    },
    submitGoods() {
      this.$refs.form.validate(valiad => {
        if (valiad) {
          this.loading = true;
          updateGoods(this.editDialog.form).then(res => {
            if (res.code === 200) {
              this.loading = false;
              Notification.success("操作成功!");
              this.editDialog.visible = false;
              this.getGoodsDashboard();
              this.handleFilter();
            }
          })
        }
      })
    },
    handleImport() {
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.loading = true;
      importGoods(fd).then((res) => {
        if (res && res.msg.indexOf("很抱歉") > -1) {
          this.noticeDialog.msg = res.msg;
          this.noticeDialog.visible = true;
        } else {
          this.noticeDialog.msg = res.msg;
          this.noticeDialog.visible = true;
          this.importDialog.visible = false;
          this.handleFilter();
          this.getGoodsDashboard();
        }
        this.loading = false;
        this.$refs.goodsUpload.clearFiles();
      })
    },
    handleJump(row) {
      this.jumpVal = undefined;
      this.currentRow = row;
    },
    handleMove(row, type) {
      let index = row.sort;
      switch (type) {
        case "up":
          index = index - 1;
          break;
        case "down":
          index = index + 1;
          break;
        case "any":
          index = this.jumpVal;
          break;
      }
      if (!index && type === 'any') {
        Notification.warning('跳转位置不能为空！');
        return;
      }
      this.loading = true;
      jumpLocation(row.skuModel, index).then(res => {
        if (res && res.code == 200) {
          this.loading = false;
          Notification.success('操作成功！');
          this.handleFilter();
          if (type === 'any') {
            this.jumpVal = undefined;
            this.currentRow = {};
          }
        }
      })
    },
    handleViewLog(row) {
      getModelLog(row.skuModel).then((res) => {
        if (res && res.code === 200) {
          this.skuModelDrawer.skuLogs = res.data.skuLogs;
          if (res.data.orgInfo) {
            this.skuModelDrawer.orgInfo = {
              orgInfo: res.data.orgInfo.orgCode + "-" + res.data.orgInfo.orgName,
              opTime:  res.data.orgInfo.opTime
            };
          } else {
            this.skuModelDrawer.orgInfo = {
              orgInfo: '',
              opTime: ''
            }
          }
          this.skuModelDrawer.visible = true;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-input__inner {
  padding-left: 5px !important;
  max-width: 200px !important;
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}
.skuUrlThum {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  ::v-deep {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

::v-deep {
  .icon-check-class {
    color: blue;
    cursor: pointer;
    font-size: 18px !important;
  }
  .icon-close-class {
    color: gray;
    cursor: pointer;
    font-size: 18px !important;
  }
}

.button-class {
  line-height: 60px;
}
.el-statistics {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  text-align: center;
}
.number {
  font-size: 25px;
}

.number-red {
  font-size: 25px;
  color: red;
}
</style>
