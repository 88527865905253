var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            {
              staticStyle: {
                width: "420px",
                "background-color": "#d0d6db",
                padding: "15px",
                height: "700px",
                "overflow-y": "scroll",
              },
            },
            [
              _c("AppWebPreview", {
                attrs: { list: _vm.pageData.rows, type: "summary" },
              }),
            ],
            1
          ),
          _c(
            "el-main",
            [
              _c(
                "div",
                {
                  staticClass: "mg-search",
                  staticStyle: { "padding-bottom": "15px" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryFormRef",
                      attrs: {
                        size: "mini",
                        "label-width": "auto",
                        inline: true,
                        model: _vm.queryForm,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.orgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgCode", $$v)
                                },
                                expression: "queryForm.orgCode",
                              },
                            },
                            _vm._l(_vm.queryOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.handleFilter },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.pageData.rows,
                    height: "calc(100vh - 250px)",
                    size: "mini",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "任务名称",
                      prop: "taskName",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isLeaf === 0
                              ? _c(
                                  "i",
                                  {
                                    staticClass: "el-icon-folder",
                                    staticStyle: { color: "dodgerblue" },
                                  },
                                  [_vm._v("合集")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _c("span", [_vm._v(_vm._s(scope.row.taskName))]),
                            scope.row.isLeaf !== 0 &&
                            scope.row.orgCode === "ALL"
                              ? _c("span", [_vm._v(" (全国)")])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "right", width: "330" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-row",
                              { attrs: { type: "flex", justify: "end" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0px" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(
                                              scope.row,
                                              scope.row.isLeaf === 0
                                                ? "editHome"
                                                : "editTaskHome"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.isLeaf === 0
                                              ? "入口样式"
                                              : "编辑样式"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                scope.row.isLeaf === 0
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 5 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(
                                                  scope.row,
                                                  "groupContent"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("合集内容")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.isLeaf === 0
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 3 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSetTarget(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v("目标"),
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "top-start",
                                                  width: "300",
                                                  trigger: "hover",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: { slot: "" },
                                                    slot: "default",
                                                  },
                                                  [
                                                    _vm._v(" 提示内容："),
                                                    _c("br"),
                                                    _vm._v(
                                                      " ①合集目标的店铺范围是合集内所有子任务的全部店铺；"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " ②子任务配置的目标不会影响合集目标；"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      " ③目标是配置到具体店铺的，可以选择“统一设置”或“单店设置”，其中单店设置支持Excel导入。"
                                                    ),
                                                    _c("br"),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                scope.row.isLeaf === 0
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 3 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteGroupTask(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("解散")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        disabled: scope.$index <= 0,
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-arrow-up",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleMove(
                                            scope.$index,
                                            "up"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 3 } },
                                  [
                                    _c("el-button", {
                                      attrs: {
                                        disabled:
                                          scope.$index ===
                                          _vm.pageData.rows.length - 1,
                                        size: "mini",
                                        type: "primary",
                                        icon: "el-icon-arrow-down",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleMove(
                                            scope.$index,
                                            "down"
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        border: "0px",
                        width: "50%",
                        "text-align": "center",
                      },
                      attrs: {
                        size: "mini",
                        type: "text",
                        icon: "el-icon-plus",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("新建任务合集")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refreshView("list")
                        },
                      },
                    },
                    [_vm._v("刷新预览")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.saveSort },
                    },
                    [_vm._v("保存排序")]
                  ),
                ],
                1
              ),
              _c(
                "el-drawer",
                {
                  attrs: {
                    title: _vm.groupDialog.dialogTitle[_vm.saveTitle],
                    visible: _vm.groupDialog.dialogVisible,
                    direction: "ltr",
                    size: "100%",
                    "custom-class": "drawerClass",
                    wrapperClosable: false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.groupDialog, "dialogVisible", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-container",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-aside",
                        {
                          staticStyle: {
                            width: "420px",
                            "background-color": "#d0d6db",
                            padding: "15px",
                            height: "700px",
                            "overflow-y": "scroll",
                          },
                        },
                        [
                          _vm.saveTitle !== "groupContent"
                            ? _c("AppWebPreview", {
                                attrs: {
                                  list: _vm.groupDialog.tableTasks,
                                  type: "summary",
                                },
                                on: {
                                  "update:list": function ($event) {
                                    return _vm.$set(
                                      _vm.groupDialog,
                                      "tableTasks",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.saveTitle === "groupContent"
                            ? _c("TaskGroupDetail", {
                                attrs: {
                                  task: _vm.groupDialog.taskForm,
                                  list: _vm.groupDialog.taskForm.tasks,
                                },
                                on: {
                                  "update:task": function ($event) {
                                    return _vm.$set(
                                      _vm.groupDialog,
                                      "taskForm",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-main",
                        { staticStyle: { "overflow-x": "hidden" } },
                        [
                          _vm.saveTitle === "add" ||
                          _vm.saveTitle === "editHome"
                            ? _c("GroupHome", {
                                ref: "groupHomeRef",
                                attrs: { form: _vm.groupDialog.form },
                                on: {
                                  "update:form": function ($event) {
                                    return _vm.$set(
                                      _vm.groupDialog,
                                      "form",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.saveTitle === "groupContent"
                            ? _c("GroupContent", {
                                ref: "groupContentRef",
                                attrs: { task: _vm.groupDialog.taskForm },
                                on: {
                                  "update:task": function ($event) {
                                    return _vm.$set(
                                      _vm.groupDialog,
                                      "taskForm",
                                      $event
                                    )
                                  },
                                  "task-change": (type, row, template) =>
                                    _vm.handleGroupTasksChange(
                                      type,
                                      row,
                                      template
                                    ),
                                },
                              })
                            : _vm._e(),
                          _vm.saveTitle === "editTaskHome"
                            ? _c("TaskContent", {
                                ref: "taskContentRef",
                                attrs: {
                                  form: _vm.groupDialog.taskHomeForm,
                                  imageTemplate: undefined,
                                  type: _vm.saveTitle,
                                  orgCode: _vm.queryForm.orgCode,
                                  exceptTaskIds: [],
                                },
                                on: {
                                  "update:form": function ($event) {
                                    return _vm.$set(
                                      _vm.groupDialog,
                                      "taskHomeForm",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.refreshView(
                                        _vm.refreshMap[_vm.saveTitle]
                                      )
                                    },
                                  },
                                },
                                [_vm._v("刷新预览")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitForm()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.saveTitle === "add"
                                        ? "提交并进行下一步"
                                        : "提 交"
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled: _vm.editLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.groupDialog.dialogVisible = false
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-drawer",
                {
                  attrs: {
                    title: _vm.taskDialog.title[_vm.taskDialog.type],
                    visible: _vm.taskDialog.visible,
                    direction: "ltr",
                    "custom-class": "drawerClass",
                    size: "100%",
                    wrapperClosable: false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.taskDialog, "visible", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-container",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-aside",
                        {
                          staticStyle: {
                            width: "420px",
                            "background-color": "#d0d6db",
                            padding: "15px",
                            height: "700px",
                            "overflow-y": "scroll",
                          },
                        },
                        [
                          _vm.taskDialog.type === "add" ||
                          _vm.taskDialog.type === "edit"
                            ? _c("TaskGroupDetail", {
                                attrs: {
                                  type:
                                    _vm.taskDialog.type === "add" ||
                                    _vm.taskDialog.type === "edit"
                                      ? "shop"
                                      : "summary",
                                  task: _vm.groupDialog.taskForm,
                                  list: _vm.taskDialog.viewList,
                                },
                                on: {
                                  "update:task": function ($event) {
                                    return _vm.$set(
                                      _vm.groupDialog,
                                      "taskForm",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-main",
                        { staticStyle: { "overflow-x": "hidden" } },
                        [
                          _c("TaskContent", {
                            ref: "taskContentRef",
                            attrs: {
                              form: _vm.taskDialog.form,
                              type: _vm.taskDialog.type,
                              orgCode: _vm.queryForm.orgCode,
                              imageTemplate: _vm.taskDialog.template,
                              exceptTaskIds: _vm.groupDialog.taskForm.tasks.map(
                                (e) => e.mtTaskId
                              ),
                            },
                            on: {
                              "update:form": function ($event) {
                                return _vm.$set(_vm.taskDialog, "form", $event)
                              },
                              "sync-config": (val) =>
                                _vm.$refs.groupContentRef.syncConfig(val),
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.refreshView("taskContent")
                                    },
                                  },
                                },
                                [_vm._v("刷新预览")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitTaskContentForm(
                                        "task_inside"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("提 交")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled: _vm.editLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.taskDialog.visible = false
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "closed-header",
          attrs: {
            title: "设置目标 (" + _vm.currentRow.taskName + ")",
            visible: _vm.targetDialog.visible,
            size: "90%",
            direction: "rtl",
            "append-to-body": "",
            "close-on-click-modal": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.targetDialog, "visible", $event)
            },
          },
        },
        [
          _vm.queryForm.orgCode
            ? _c("TargetV2", {
                ref: "target",
                attrs: {
                  "task-info": _vm.currentRow,
                  "auth-org-codes": [_vm.queryForm.orgCode],
                },
                on: {
                  "show-commit": (val) => {
                    _vm.targetDialog.showCommit = val
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.targetDialog.showCommit === "0",
                      expression: "targetDialog.showCommit === '0'",
                    },
                  ],
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: () => _vm.$refs.target.handleCommitUnify() },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.targetDialog.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.noticeDialog,
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.noticeDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                height: "80px",
                "font-size": "20px",
                color: "red",
                "font-weight": "700",
              },
            },
            [
              _vm._v(
                "任务排版全面升级，新版后台全力开发中。如需调整样式和顺序请联系聚宝盆同事进行配置"
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }