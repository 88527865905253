var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", "default-first-option": "" },
                      on: {
                        change: () => {
                          _vm.queryForm.code = undefined
                          _vm.handleFilter()
                        },
                      },
                      model: {
                        value: _vm.queryForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "type", $$v)
                        },
                        expression: "queryForm.type",
                      },
                    },
                    _vm._l(_vm.options.resourceOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { value: item.value, label: item.tab },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.queryForm.type === "TASK"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            "default-first-option": "",
                            clearable: "",
                          },
                          on: {
                            change: (val) =>
                              _vm.handleResourceOrgChange(
                                _vm.queryForm.type,
                                "query"
                              ),
                          },
                          model: {
                            value: _vm.queryForm.orgCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "orgCode", $$v)
                            },
                            expression: "queryForm.orgCode",
                          },
                        },
                        _vm._l(_vm.options.orgOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.queryForm.type === "TASK"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "任务" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            "default-first-option": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "code", $$v)
                            },
                            expression: "queryForm.code",
                          },
                        },
                        _vm._l(_vm.options.taskOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.queryForm.type === "HX"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "核销名称" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", "default-first-option": "" },
                          model: {
                            value: _vm.queryForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "code", $$v)
                            },
                            expression: "queryForm.code",
                          },
                        },
                        _vm._l(_vm.options.hxOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.queryForm.type === "QD_HX"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道核销" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", "default-first-option": "" },
                          model: {
                            value: _vm.queryForm.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "code", $$v)
                            },
                            expression: "queryForm.code",
                          },
                        },
                        _vm._l(_vm.options.qdHxOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleEditResource()
                        },
                      },
                    },
                    [_vm._v("新增资源")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: { size: "mini", type: "danger" },
                      on: { click: _vm.handleDelAuthBatch },
                    },
                    [_vm._v("批量删除")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 450px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "90", align: "center" },
          }),
          _vm.queryForm.type === "TASK"
            ? _c("el-table-column", {
                attrs: { label: "分公司编码/名称", prop: "shop", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  358871047
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "名称", prop: "resourceName", width: "250" },
          }),
          _c("el-table-column", {
            attrs: { label: "是否授权", prop: "auth", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-switch", {
                      on: {
                        change: (val) => {
                          _vm.submitAuth(row)
                        },
                      },
                      model: {
                        value: row.auth,
                        callback: function ($$v) {
                          _vm.$set(row, "auth", $$v)
                        },
                        expression: "row.auth",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAddUserResource(row)
                              },
                            },
                          },
                          [_vm._v("添加用户")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAuth(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          pageSizes: [10, 20, 50, 100, 200],
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          ref: "editDialogRef",
          attrs: {
            title: "新增资源数据",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "300px",
            "close-on-click-modal": false,
            tabindex: "1",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
            opened: _vm.onDialogOpened,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.editDialog.rules,
                "label-width": "120px",
                model: _vm.editDialog.form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "default-first-option": "",
                              },
                              on: {
                                change: () => {
                                  _vm.editDialog.form.resourceCode = undefined
                                },
                              },
                              model: {
                                value: _vm.editDialog.form.resourceType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editDialog.form,
                                    "resourceType",
                                    $$v
                                  )
                                },
                                expression: "editDialog.form.resourceType",
                              },
                            },
                            _vm._l(
                              _vm.options.resourceOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { value: item.value, label: item.tab },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否授权", prop: "auth" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.editDialog.form.auth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editDialog.form, "auth", $$v)
                                },
                                expression: "editDialog.form.auth",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "TASK"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "分公司" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                    clearable: "",
                                  },
                                  on: {
                                    change: (val) =>
                                      _vm.handleResourceOrgChange(
                                        _vm.editDialog.form.resourceType,
                                        "edit"
                                      ),
                                  },
                                  model: {
                                    value: _vm.editDialog.form.orgCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "orgCode",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.orgCode",
                                  },
                                },
                                _vm._l(_vm.options.orgOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "TASK"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "任务", prop: "resourceCode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.resourceCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "resourceCode",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.resourceCode",
                                  },
                                },
                                _vm._l(
                                  _vm.options.taskOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "HX"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "核销名称",
                                prop: "resourceCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.resourceCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "resourceCode",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.resourceCode",
                                  },
                                },
                                _vm._l(_vm.options.hxOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "QD_HX"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "渠道核销",
                                prop: "resourceCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.resourceCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "resourceCode",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.resourceCode",
                                  },
                                },
                                _vm._l(
                                  _vm.options.qdHxOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.editDialog.disabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAuth(_vm.editDialog.form)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "editDialogRef",
          attrs: {
            title: "新增资源数据",
            visible: _vm.userDialog.visible,
            "append-to-body": "",
            width: "300px",
            "close-on-click-modal": false,
            tabindex: "1",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userDialog, "visible", $event)
            },
            opened: _vm.onDialogOpened,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.userDialog.rules,
                "label-width": "120px",
                model: _vm.userDialog.form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户数据" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.userDialog.form.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.userDialog.form, "userName", $$v)
                              },
                              expression: "userDialog.form.userName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.editDialog.disabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.userDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.submitBatchAuth(_vm.userDialog.form)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: {
              "font-size": "16px",
              "overflow-y": "scroll",
              height: "400px",
            },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }