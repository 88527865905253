<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="80px" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="商品型号">
          <el-input v-model="queryForm.skuModel" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item v-if="scope==='1' && !showUrl" label="商品编码">
          <el-input v-model="queryForm.skuCode" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="queryForm.skuName" placeholder="请输入" clearable />
        </el-form-item>
        <DimCateGorySearch
          ref="category"
          style="display: inline;"
          :main.sync="queryForm.mainCategoryCode"
          :mid.sync="queryForm.midCategoryCode"
          :sub.sync="queryForm.subCategoryCode"
        />
        <el-form-item label="标签">
          <el-input v-model="queryForm.label" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button v-if="!showUrl" class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAddAll">全部添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 300px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      row-class-name="rowClassName"
      @row-dblclick="handleRowDblClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90"/>
      <el-table-column v-if="scope==='1' && !showUrl" label="商品编码" prop="skuCode" width="80" />
      <el-table-column label="商品型号" prop="skuModel" width="100" />
      <el-table-column label="商品名称" prop="skuName" />
      <el-table-column v-if="scope==='1' && !showUrl" label="规格" prop="spec" width="80" />
      <el-table-column label="大类" prop="mainCategoryName"  width="100" />
      <el-table-column label="中类" prop="midCategoryName"  width="100" />
      <el-table-column label="小类" prop="subCategoryName"  width="100" />
      <el-table-column v-if="boost" label="标签" prop="label"  width="100" />
      <el-table-column v-if="showUrl" label="图片" prop="skuUrl" width="120">
        <template #default="{ row }">
          <el-image :src="row.skuUrl" :preview-src-list="[row.skuUrl]" lazy>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <div slot="footer" v-if="!showUrl" class="dialog-footer" style="text-align: right">
      <el-button size="mini" @click="closeDrawer">取 消</el-button>
      <el-button size="mini" type="primary" :disabled="pageData.selections.length === 0" @click="addItems()">添加已选 {{ pageData.selections.length }} 商品</el-button>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageSkuModel, dictSelect } from "@/api/appTask";
import { parseParam } from "@/utils";
import DimCateGorySearch from "../../components/DimCategorySearch";
import {deepClone} from "../../../../utils";
import {Notification} from "element-ui";

export default {
  name: "SkuItem",
  components: {DimCateGorySearch, Pagination},
  props: {
    groupId: {
      type: String,
      default: undefined
    },
    refresh: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: '0'
    },
    pageSkus: {
      type: Function,
      default: pageSkuModel
    },
    taskId: {
      type: String,
    },
    boost: {
      type: Boolean,
      default: false
    },
    showUrl: {
      type: Boolean,
      default: false
    },
    skuModel: {
      type: String,
      default: undefined
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        skuModel: this.skuModel,
        skuCode: undefined,
        skuName: undefined,
        taskId: this.taskId,
        mainCategoryCode: undefined,
        midCategoryCode: undefined,
        subCategoryCode: undefined,
        label: undefined
      },
      loading: false,
    };
  },
  watch: {
    groupId(newVal) {
      if (newVal) {
        if (newVal !== 'ALL') {
          this.queryForm.groupId = newVal;
        } else {
          this.queryForm.groupId = undefined;
        }
        this.handleFilter();
      }
    },
    refresh(newVal) {
      if (newVal) {
        this.handleFilter();
      }
    },
    scope(newVal) {
      if (newVal === '1') {
        this.queryForm.groupId = undefined;
      } else {
        this.queryForm.groupId = undefined;
        this.queryForm.skuCode = undefined;
      }
      this.handleQuery();
    },
    taskId: {
      handler(nV, oV) {
        this.queryForm.taskId = nV;
        this.handleFilter();
      }
    }
  },
  computed: {},
  mounted() {
    if (this.refresh) {
      this.handleFilter();
    }
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm, {exceptModels: this.getExceptModels(), exceptSkuCodes: this.getExceptSkuCodes()});
      this.pageSkus(this.scope, query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        skuModel: undefined,
        skuCode: undefined,
        skuName: undefined,
        taskId: this.taskId,
        mainCategoryCode: undefined,
        midCategoryCode: undefined,
        subCategoryCode: undefined,
      }
      this.queryForm = queryForm;
      this.$nextTick(() => {
        this.$refs.category.clearAll();
      })
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleRowDblClick(row, column, event) {
      if (this.showUrl) {
        if (!row.skuUrl) {
          Notification.warning("当前商品无图片，请确认后重新选择！")
        } else {
          this.$emit('handleDbClickModel', row)
          this.closeDrawer()
        }
      } else {
        this.addItems(row);
      }
    },
    closeDrawer() {
      this.$emit('close-drawer')
    },
    addItems(val) {
      if (val) {
        this.$emit('add-items', [val], 'sku')
      } else {
        this.$emit('add-items', this.pageData.selections, 'sku')
      }
      this.handleFilter();
    },
    getExceptModels() {
      let keys;
      this.$emit('getExceptModels', (data) => {
        keys = data;
      });
      return keys.join(',');
    },
    getExceptSkuCodes() {
      let keys = [];
      this.$emit('getExceptSkuCodes', (data) => {
        keys = data;
      });
      return keys.join(',');
    },
    handleAddAll() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm, {exceptModels: this.getExceptModels(), exceptSkuCodes: this.getExceptSkuCodes()}, {current: 1, size: 10000});
      this.pageSkus(this.scope,query).then(res => {
        this.loading = false;
        const allData = deepClone(res.data.records);
        this.$emit('add-items', allData, 'sku');
        this.pageData.rows =[];
        this.pageData.total = 0;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .rowClassName {
  cursor: pointer !important;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

</style>
