<template>
  <div class="mg-pagination">
    <div class="page-button" :class="{ disabled: disabledPre }" @click="handlePageChange(-1)">
      <mg-svg-icon :iconWidth="18" :iconHeight="20" iconClass="mg-icon_shangyiye" iconColor="red" :iconMarginLeft="0" />
    </div>
    <el-popover :disabled="disabled" ref="popover" placement="bottom" width="120" trigger="hover" v-model="visible">
      <span class="total-size">共 {{ total }} 条</span>
      <div class="jump-page">
        <span>前往</span>
        <el-input size="small" style="width: 50px" v-model.number="jumpPage" @keyup.enter.native="handleConfirm" />
        <span>页</span>
      </div>
    </el-popover>
    <span class="page-box" v-popover:popover>{{ page || 1 }}/{{ totalPage || 1 }}</span>
    <div class="page-button" :class="{ disabled: disabledNext }" @click="handlePageChange(1)">
      <mg-svg-icon :iconWidth="18" :iconHeight="20" iconClass="mg-icon_xiayiye" :iconMarginLeft="0" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      page: Number,
      totalPage: Number,
      total: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        visible: false,
        jumpPage: this.page,
      }
    },
    components: {
      mgSvgIcon: () => import('@/components/SvgIcon/icon'),
    },
    computed: {
      disabled() {
        return this.totalPage <= 0
      },
      disabledPre() {
        return this.page <= 1
      },
      disabledNext() {
        return this.page >= this.totalPage
      },
    },
    methods: {
      handlePageChange(page) {
        page = this.page + page
        if (page < 1 || page > this.totalPage) return
        this.$emit('page-change', page)
      },
      handleConfirm() {
        if (this.jumpPage > 0 && this.jumpPage <= this.totalPage) {
          this.visible = false
          if (this.jumpPage !== this.page) {
            this.handlePageChange(this.jumpPage - this.page)
          }
        }
      },
    },
  }
</script>
<style lang="scss">
  .jump-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .total-size {
    display: block;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    padding: 0px 10px 8px 10px;
  }
</style>
<style lang="scss" scoped>
  .mg-pagination {
    line-height: 26px;
    border: 1px solid rgba(37, 42, 61, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    .page-button {
      padding: 0 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .page-box {
      border-left: 1px solid rgba(37, 42, 61, 0.2);
      border-right: 1px solid rgba(37, 42, 61, 0.2);
      padding: 0 18px;
      color: #252a3d;
    }
  }
</style>
