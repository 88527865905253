<template>
  <div v-clickoutside="handleClickOutside" class="mg-select-tree">
    <el-input
      v-on="$listeners"
      v-bind="$attrs"
      v-model="selectParam"
      class="select-param-input"
      :placeholder="placeholder || '请选择'"
      @click.native="handleInput"
      @input="filterInput"
      @mouseenter.native="paramInputHover"
      @mouseleave.native="paramInputOut"
    >
      <i slot="suffix" class="el-input__icon" :class="icon" @click="iconClick(icon)" />
    </el-input>
    <div v-show="treeShowFlag" style="width: 100%;position:absolute;z-index: 3333;">
      <div class="mg-triangle"><span /></div>
      <div class="mg-drop-down">
        <el-tree
            v-on="$listeners"
            v-bind="$attrs"
            ref="tree"
            :data="treeData"
            :filter-node-method="filterNode"
            highlight-current
            class="mg-drop-down-tree"
            @check="handleCheckChangeTree"
        />
      </div>
    </div>
  </div>
</template>
<script>
// clickoutside自定义指令，当鼠标点击指令绑定元素的外部时会触发该方法。
import Clickoutside from 'element-ui/src/utils/clickoutside';
export default {
  directives: { Clickoutside }, // 声明指令
  props: {
    treeData: {
      type: [Array, Object],
      default: () => [],
    },
    // value监听的是父组件的v-model
    value: {
      type: [String, Array],
      default: () => [],
    },
    checkedNodes: {
      type: Array,
      default: []
    },
    placeholder: {
      type: String
    }

  },
  data() {
    return {
      treeShowFlag: false,
      defaultProps: {
        children: "children",
        label: "name"
      },
      selectParam: '',
      icon: 'el-icon-arrow-down'
    }
  },
  watch: {
    treeShowFlag(val) {
      this.icon = val ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
    },
    checkedNodes(val) {
      if ( !val ) {
          this.selectParam = '';
          return false;
      };
      this.selectParam = val.join(',');
    }
  },
  methods: {
    // 点击外部区域收起下拉框
    handleClickOutside() {
      this.treeShowFlag = false
    },
    // 点击输入框展开/收起下拉框
    handleInput() {
      this.treeShowFlag = !this.treeShowFlag
      this.$emit('click');
    },
    // 输入文字进行模糊查询
    filterInput: function(val) {
      this.treeShowFlag = true
      this.$refs.tree.filter(val)
    },
    // 鼠标悬浮，如果由内容的话就显示清除图标
    paramInputHover: function() {
      if (this.selectParam !== '') {
        this.icon = 'el-icon-circle-close'
      }
    },
    // 鼠标由悬浮离开，如果图标显示的是清除的话，就换成原来的图标
    paramInputOut: function() {
      if (this.icon === 'el-icon-circle-close') {
        this.icon = this.treeShowFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
      }
    },
    // 点击清除图标时清除输入框的内容
    iconClick: function(icon) {
      if (icon === 'el-icon-circle-close') {
        this.treeShowFlag = false // 由于此事件会同时触发handleInput(),所以将treeShowFlag设为false，防止出现下拉列表
        this.selectParam = ''
        this.filterInput(this.selectParam) // 内容清除后显示所有数据
        this.$emit('change', { checkedNodes: null, checkedKeys: null, halfCheckedNodes: null, halfCheckedKeys: null })
      }
    },
    // 节点过滤（模糊查询）
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleCheckChangeTree(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys) {
        this.$emit('change', { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys })
    }
  }
}
</script>
<style lang="scss" scoped>
    .mg-select-tree {
        width: 100%;
        display:inline-block;
        transition: 0.5s;
        padding: 0;
        .select-param-input {
            width: 100%;
            cursor: pointer;
        }
    }
    .mg-drop-down {
        border-radius: 3px;
        max-height: 200px;
        min-width: 200px;
        padding: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.2);
    }
    .mg-drop-down .mg-drop-down-tree {
        overflow: auto;
    }
    .mg-triangle {
        width:0;
        height:0;
        border-width:0 8px 7px;
        border-style:solid;
        border-color:transparent transparent rgb(220, 223, 230);
        margin-left: 60px;
        margin-top: 3px;
        position:relative;
    }
    .mg-triangle span{
        display:block;
        width:0;
        height:0;
        border-width:0 7px 6px;
        border-style:solid;
        border-color:transparent transparent rgb(255, 255, 255);
        position:absolute;
        top:1px;
        left:-7px;
    }
</style>