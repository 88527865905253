<template>
    <el-form  :label-width="labelWidth" :size="size">
        <div id="searchFilter" :gutter="10" style="display: flex; flex-wrap: wrap">
            <slot></slot>
            <div class="rigth">
                <el-form-item class="operate" label-width="0">
                    <el-button type="primary" @click="handleQuery">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                    <img :src="expandAndCollapse" alt="" class="img-size" :class="fold? 'up-bold' :'down-bold'" @click="shiftCollapsiable" v-show="collapsiable">
                </el-form-item>
            </div>
        </div>
    </el-form>
</template>
<script>
import expandAndCollapse from "@/assets/images/expand-and-collapse.png";
export default {
    name: 'SearchFilter',

    props: {
        // 最大展示数，超过则隐藏，为0时不限制
        // maxShow: {
        //     type: Number,
        //     default: 0,
        // },
        rows:{
            type: Number,
            default: 2,
        },
        labelWidth: {
            type: String,
            default: '120px',
        },
        size: {
            type: String,
            default: 'small',
        },
        model:{
        type: Object
    }
    },
    data() {
        return {
            collapsiable: true,
            fold: true,
            index: 0,
            maxShow: 0,
            expandAndCollapse: expandAndCollapse
        }
    },
    created() {



    },
    mounted() {
        this.$nextTick(() => {
        const group = window.document.querySelectorAll(`#searchFilter .el-form-item.el-form-item--${this.size}`)
        const len = group?.length ? group?.length-1  : 0
        group.forEach((item, index) => {
            const previousTop = group[1]
            if (item.offsetTop != previousTop.offsetTop && index != 0 && this.index == 0) {
                console.log(index);
                this.index = index * this.rows
            }
        })
        if(this.index != 0 && this.index < len){
            this.collapsiable = true
        }else{
            this.collapsiable = false
        }         
    })
    },
    methods: {
        shiftCollapsiable() {
            this.fold = !this.fold
            this.maxShow = this.index
            this.minShowCtrol()
        },
        // 通过maxShow控制元素显示/折叠
        minShowCtrol() {
            const group = window.document.querySelectorAll(`#searchFilter .el-form-item.el-form-item--${this.size}`)
            const len = group?.length ? group?.length-1  : 0
                group.forEach((item, index) => {
                    if (index > this.maxShow - 1 && index < len) {
                        item.hidden = !this.fold
                    }
                })
        },
        handleQuery() {
            this.$emit('search')
        },
        handleReset() {
            this.$emit('reset')
        },
    },
}
</script>
<style lang="scss" scoped>
.rigth {

    margin-left: auto;
    ::v-deep(.el-form-item__content){
        display: flex;
        align-items: center;
    }
    .operate {
        width: 160px;
        text-align: right;
    }
    .img-size{
        margin-left: 10px;
        width: 16px;
        display: block;
        height: 16px;
        cursor: pointer;
    }
    .up-bold{
        transform: rotate(180deg);
    }
    .down-bold{
        transform: rotate(0deg);
    }
    :deep(.el-button--small){
        padding: 6px 12px;
        min-width: 0;
    }
}
</style>