<template>
  <div>
    <div v-if="type === '0'" style="grid-template-areas: 'a'; display: grid; min-height: 40px; gap: 10px">
      <div style="grid-area: a;">
        <el-divider>{{title}}</el-divider>
      </div>
    </div>

    <div v-if="type === '1'" style="grid-template-areas: 'a b'; display: grid; min-height: 120px; gap: 10px; grid-template-columns: 1fr 1fr">
      <div style="grid-area: a;; width: 100%">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
      <div style="grid-area: b;; width: 100%">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[1]" :type="computeType(1)" :index="1" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 1, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>

    <div v-if="type === '2'" style="grid-template-areas: 'a b' 'a c'; display: grid; min-height: 250px; gap: 10px; grid-template-rows: 1fr 1fr; grid-template-columns: 1fr 1fr">
      <div style="grid-area: a;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
      <div style="grid-area: b;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[1]" :type="computeType(1)" :index="1" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 1, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
      <div style="grid-area: c;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[2]" :type="computeType(2)" :index="2" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 2, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>

    <div v-if="type === '3'" style="grid-template-areas: 'a b'; display: grid; min-height: 250px; gap: 10px; grid-template-columns: 1fr 1fr;">
      <div style="grid-area: a;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
      <div style="grid-area: b;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[1]" :type="computeType(1)" :index="1" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 1, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>

    <div v-if="type === '4'" style="grid-template-areas: 'a b c'; display: grid; min-height: 165px; gap: 10px; grid-template-columns: 1fr 1fr 1fr;">
      <div style="grid-area: a;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
      <div style="grid-area: b;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[1]" :type="computeType(1)" :index="1" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 1, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
      <div style="grid-area: c;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[2]" :type="computeType(2)" :index="2" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 2, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>

    <div v-if="type === '5'" style="grid-template-areas: 'a'; display: grid; min-height: 80px; gap: 10px">
      <div style="grid-area: a;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>

    <div v-if="type === '6'" style="grid-template-areas: 'a'; display: grid; min-height: 80px; gap: 10px">
      <div style="grid-area: a;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>

    <div v-if="type === '7'" style="grid-template-areas: 'a'; display: grid; min-height: 80px; gap: 10px">
      <div style="grid-area: a;">
        <AddTask :shopAll="shopAll" :showType="showType" :currentTask="currentTaskId" :taskId="taskIds[0]" :type="computeType(0)" :index="0" :moduleTasks="moduleTasks" :handleChangeTask="(type, action, taskType) => handleChangeTask(type, action, 0, taskType)" @setCurrentTask="setCurrentTask" />
      </div>
    </div>
  </div>
</template>

<script>
import AddTask from "../AddTask";
export default {
  name: "ModuleItem",
  components: {AddTask},
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: "1"
    },
    title: {
      type: String,
      default: ""
    },
    moduleTasks: {
      type: Array,
      default() {
        return []
      }
    },
    currentTask: {
      type: String,
    },
    showType: {
      type: String,
      default: "home"
    },
    shopAll: {
      type: Boolean,
    }
  },
  watch: {
    currentTask: {
      handler(nV, oV) {
        this.currentTaskId = nV;
      },
    },
    list: {
      handler(nV, oV) {
        this.taskIds = nV;
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      currentTaskId: undefined,
      taskIds: [],
    }
  },
  computed: {
  },
  methods: {
    handleChangeTask(type, action, index, taskType) {
      this.$emit('handleChangeTask',  type, action, index, taskType)
    },
    setCurrentTask(taskId) {
      this.$emit('setCurrentTask', taskId);
    },
    computeType(index) {
      switch (this.type) {
        case "1":
          return "small";
        case "2":
          if (index === 0) {
            return "big";
          } else {
            return "small";
          }
        case "3":
          return "big";
        case "4":
          return "mini"
        case "5":
          return "horizontal"
        case "7":
          return "highRowOne"
        default:
          return "horizontal"
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  h4 {
    margin: 0px;
  }
  h1 {
    margin: 0px;
  }
}

</style>
