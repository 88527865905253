<template>
  <div class="signboard-box">
    <el-row>
        <el-col :class="['signboard', isHavBar ? 'signboardBar' : '']">{{ title }}<span v-if="backBtn" style="margin-left: 20px;"><el-link type="info" onclick="history.go(-1)">返回</el-link></span></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Signboard',
  props: {
    title: {
      type: String,
      default: '公告牌标题'
    },
    backBtn : {
      type: Boolean,
      default: false
    },
    isHavBar: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .signboard-box {

  }
</style>
