<style>
.main-container {
  overflow: hidden !important;
}

.app-main {
  overflow: hidden !important;
}

.app-wrapper {
  overflow: hidden !important;
}

#app {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
<template>
  <div class="thirdPartyPage-page">
    <el-tabs tab-position="top" style="width: 100%; height: 100vh;" v-model="activeName">
      <el-tab-pane v-for="(item, index) in iframeArrays" :key="index + 'iframe'" :label="item.name"
                   :name="item.tabName">
        <template>
          <div v-if="item.tabName == '1'">
            <plu-order-detail/>
          </div>
          <iframe v-else :src="item.iframeSrc" frameborder="0" style="width: 100%; height: 1800px;"></iframe>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {urlParams} from '@/mixins';
import PluOrderDetail from "./pluOrderDetail";
export default {
  // 请购单临时汇总报表
  name: 'pluOrderReport',
  mixins: [urlParams],
  components: {
    PluOrderDetail
  },
  data() {
    return {
      user: {
        role_code_list:[],
        username: '',
        real_name: '',
        arrange_resource: false,
      },
      iframeArrays: [
        {
          name: '请购单明细',
          tabName: '1',
        },
        {
          name: '产线产能疑问',
          tabName: '2',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E4%25BA%25A7%25E7%25BA%25BF%25E4%25BA%25A7%25E8%2583%25BD%25E7%2596%2591%25E9%2597%25AE.cpt&ref_t=design&ref_c=73ddd091-2b97-4b12-a326-26640b29eeef'
        },

        {
          name: '请购单临时汇总',
          tabName: '3',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25AF%25B7%25E8%25B4%25AD%25E5%258D%2595%25E6%25B1%2587%25E6%2580%25BB.cpt&ref_t=design&ref_c=4ad39279-d2e7-4ac6-8bb3-3c8730b6528e',
        },
        {
          name: '请购单产能核检',
          tabName: '4',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%2FDW%2F%5B8ba2%5D%5B5355%5D%5B90e8%5D%2F%5B8bf7%5D%5B8d2d%5D%5B5355%5D%5B4ea7%5D%5B80fd%5D%5B68c0%5D%5B6838%5D.cpt',
        },
        {
          name: '产需平衡(算法前)',
          tabName: '5',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E4%25BA%25A7%25E9%259C%2580%25E5%25B9%25B3%25E8%25A1%25A1%2528%25E7%25AE%2597%25E6%25B3%2595%25E5%2589%258D%2529.cpt&ref_t=design&ref_c=ae5d35d2-e463-4a67-a92c-26784bbd834e',
        }
      ],
      operator: '',
      depart: '',
      isConfirm: false,
      activeName: '1'
    }
  },
  created() {
    this.handleUrlParams().then(res => {
      this.operator = res.operator;
      this.depart = res.depart;
    })
  },
  methods: {
    handleHeadAction(type) {
      const funcObj = {

      };
      funcObj[type]();
    }
  }
}
</script>

<style lang="scss" scoped>
.thirdPartyPage-page {
  height: 100vh;

  &-head {
    text-align: center;
    padding: 10px 0 20px 0;
  }

  ::v-deep .el-tabs__content {
    height: 100vh;
    overflow: scroll;
  }
}
</style>
