import {axiosGet, axiosPost, axiosPut, axiosDelete, axiosDownload} from "@/utils/request";

/**
 * ----------------------------- 备货申请 -----------------------------
 */

// 分页
export function pageStockUpData(data) {
  return axiosPost('/stock-up/page', data, 1);
}
// 前6月对应名称
export function getSixMthFields() {
  return axiosGet('/stock-up/fields', {}, 1);
}
export function getDictData(data, json) {
  return axiosPost('/stock-up/dict/' + data, json, 1);
}
export function saveStockUp(data) {
  return axiosPut('/stock-up', data, 1);
}
export function exportData(data) {
  return axiosDownload("/stock-up/export", data, 1);
}
export function importData(data) {
  return axiosDownload("/stock-up/import", data, 1);
}
