<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="一级品类">
          <DictSelect
            ref="one"
            type="one"
            :value.sync="queryForm.oneCategory"
            linkageRef="two"
            :dictFun="dictFun"
            default-first-option
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="二级品类">
          <DictSelect
            ref="two"
            type="two"
            :value.sync="queryForm.twoCategory"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出品类信息</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="importDialog.visible = true">导入品类排序</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
    >
      <el-table-column label="一级品类" prop="oneCategory" width="90" />
      <el-table-column label="一级品类排序" prop="sortOne" width="90" />
      <el-table-column label="二级品类" prop="twoCategory" width="90" />
      <el-table-column label="二级品类排序" prop="sortTwo" width="90" />
<!--      <el-table-column label="操作" width="120" align="center" >-->
<!--        <template #default="{ row }">-->
<!--          <div class="button-class">-->
<!--            <el-button size="mini" type="text" style="border: 0px; color: red;" @click="handleDel(row)">删除</el-button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <ImportDialog :importBaseParams="importDialog" @handleImportCallBack="handleImportData" append-to-body :modal-append-to-body="false" />

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pageCategory,  delCateGory, exportCategory, importCateGory, goodsDict } from "@/api/appTask";
import DictSelect from "@/components/DictSelect";
import Pagination from "@/components/Pagination";
import { parseParam } from "@/utils"
import {Notification} from "element-ui";

export default {
  name: "Category",
  components: { Pagination, ImportDialog: () => import("@/components/ImportDialog") ,DictSelect },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        oneCategory: undefined,
        twoCategory: undefined,
      },
      loading: false,
      dictFun: goodsDict,
      dictParam: {},
      importDialog: {
        visible: false,
        title: "品类导入",
        type: "import",
        tipsText: "提示：四项均不能为空",
        templateSampleHead: [
          { label: "一级品类", prop: "oneCategory", minWidth: "80px" },
          { label: "一级品类排序", prop: "sortOne", minWidth: "80px" },
          { label: "二级品类", prop: "twoCategory", minWidth: "80px" },
          { label: "二级品类排序", prop: "sortTwo", minWidth: "80px" },
        ],
        templateSampleLists: [
          {
            oneCategory: "书写工具",
            twoCategory: "尺",
            sortOne: 1,
            sortTwo: 1,
          },
        ],
        templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%93%81%E7%B1%BB%E6%8E%92%E5%BA%8F%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        apiParams: "importList"
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      }
    };
  },
  created() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageCategory(parseParam(query)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.dictParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDel(row) {
      this.$confirm('请确认是否删除所选的数据（' + row.oneCategory +'>' + row.twoCategory + ')?', '提示', { type: 'warning' }).then(() => {
        delCateGory({ request: row }).then((res) => {
          if (res && res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    handleExport() {
      this.loading = true;
      exportCategory(this.queryForm).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '品类数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleImportData(val) {
      this.importDialog.visible = false;
      this.loading = true;
      const data = {
        list: Object.assign([], val["importList"])
      }
      importCateGory(data).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.noticeDialog.msg = res.msg;
          this.noticeDialog.visible= true;
          this.handleFilter();
        }
      });
    },
  }
}
</script>

<style scoped>

</style>
