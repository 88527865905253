import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";

/**
 * ----------------------------- 赛道活动相关接口 -- 开始 -----------------------------
 */

// 赛道活动 -- 查询条件搜索框加载
export function handleRaceActivityListOptions() {
  return axiosGet('/race-activity/category', null, 1);
};

// 赛道活动 -- 列表
export function handleRaceActivityLists(data) {
  return axiosPost('/race-activity/list-page', data, 1);
};

// 赛道自助报表 -- 列表
export function handleRaceActivityReportLists(data) {
  return axiosPost('/race-activity/report-page', data, 1);
};

// 新增活动
export function handleAddActivityPost(data) {
  return axiosPost('/race-activity/add', data, 1);
};

// 获取分公司伙伴
export function handleDcOptions(data) {
  return axiosGet('/race-activity/org-dc/' + data.orgCode, data, 1);
};

// 获取用户导入的活动名称
export function handleRaceActivityNameList(data) {
  return axiosGet('/race-activity/user/list-activity', data, 1);
};

// 删除活动
export function handledeleteActivity(data) {
  return axiosDelete('/race-activity', data, 1);
};

/**
 * ----------------------------- 赛道活动相关接口 -- 结束 -----------------------------
 */
