<template>

  <el-tabs v-model="tabName" type="card">
    <el-tab-pane name="module" label="分公司重点打造阵地">
      <OrgDcModule style="margin-top: 8px;" />
    </el-tab-pane>
    <el-tab-pane name="target" label="店铺打造目标">
      <ShopTarget style="margin-top: 8px;" />
    </el-tab-pane>
  </el-tabs>

</template>

<script>
import ShopTarget from './shopTarget'
import OrgDcModule from './orgDcModule'

export default {
  name: "Module",
  components: {ShopTarget, OrgDcModule},
  data() {
    return {
      tabName: 'module'
    }
  }
}
</script>

<style scoped>

</style>
