<template>
  <div>
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <el-select v-model="queryForm.orgCode" filterable default-first-option @change="handleQuery">
            <el-option v-for="item in queryOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-if="queryForm.orgCode" class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="mini" :disabled="loading" @click="saveModuleTask">保存</el-button>
    </div>

    <div style="color:dodgerblue; text-align: center"><h1>APP界面预览</h1><p>首页任务列表</p></div>

    <div v-loading="loading" v-if="showView">
      <div v-for="(module, index) in taskModuleList" :key="module.title">
        <div style="box-shadow: 0 2px 12px 0 rgba(0,0,0,0.73); border: 1px dashed rgba(0,0,0,0); padding: 2px 3px; margin-top: 8px">

          <!--        <div style="background-color: white">-->
          <!--          <el-divider>{{module.title}}</el-divider>-->
          <!--        </div>-->


          <div class="groupBox" style="margin-top: 5px; background-color: white">
            <el-container style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); border: 1px dashed #f5dae0">
              <el-main style="padding-top: 0px; overflow: unset;">
                <div style="grid-template-areas: 'a'; display: grid; min-height: 40px; gap: 10px">
                  <div style="grid-area: a;">
                    <el-divider>{{module.title}}</el-divider>
                  </div>
                </div>
              </el-main>
            </el-container>
          </div>

          <div v-for="(item,moduleIndex) in taskModuleDataSortedf(index)" class="groupBox" style="margin-top: 5px; background-color: white" :key="item.title">
            <el-container style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); border: 1px dashed #f5dae0">
              <el-main style="padding-top: 0px; overflow: unset;">
                <div style="display: flex; justify-content: flex-end; margin-top: -3px; height: auto">
                  <el-button v-if="shopAll && module.taskModuleType==='ALL'" title="同步模块配置" size="mini" type="text" icon="el-icon-refresh" @click="handleSyncDialog(item.title)"></el-button>
                  <el-button size="mini" type="text" icon="el-icon-upload2" :disabled="moduleIndex===0" @click="handleModuleMove(item.title, 'up', index)"></el-button>
                  <el-button size="mini" type="text" icon="el-icon-download" :disabled="moduleIndex===taskModuleList[index].taskModuleData.length-1" @click="handleModuleMove(item.title, 'down', index)"></el-button>
                  <el-button v-if="shopAll || module.taskModuleType!=='ALL'" size="mini" type="text" icon="el-icon-delete" @click="deleteModule(item.title, index)"></el-button>
                </div>
                <ModuleItem :title="item.title" :list="item.list" :moduleTasks="allTaskList" :type="item.type" :currentTask="currentTask" @setCurrentTask="(taskId) => {
                  if (shopAll || module.taskModuleType!=='ALL') {
                    if (currentTask === taskId) {
                      currentTask = undefined;
                    } else {
                      currentTask = taskId;
                    }
                  }
                }" @handleChangeTask="(type,action, insideIndex,taskType) => handleChangeTask(index,moduleIndex, type, insideIndex, allTaskList, action,taskType)" :shopAll="shopAll && module.taskModuleType==='ALL'" />
              </el-main>
            </el-container>
          </div>

          <div class="groupBox" style="margin-top: 5px; background-color: white">
            <div
              style="font-size: 18px; font-weight: 700; text-align: center; cursor: pointer; overflow: unset;
              background-color: #ffeff2;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); border: 1px dashed #f5dae0;padding: 5px;"
              @click="() => handleAddModule(index, module.taskModuleType)">
            <i class="el-icon-plus" style="color: red"></i>
              <span style="color: red">  添加模块</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer :title="drawer.title" :visible.sync="drawer.visible" direction="ltr" custom-class="drawerClass" size="100%" :show-close="false" :wrapperClosable="false" :modal-append-to-body="false" append-to-body>
      <TaskContentV2 v-if="drawer.action === 'edit'" ref="taskContent" :templateType="drawer.templateType" :form="drawer.form" :orgCode="queryForm.orgCode" :tasks="drawer.tasks" :isGroup="drawer.isGroup" />
      <taskGroupDetailV2 v-if="drawer.action === 'detail'" ref="taskGroupDetail" :timestamp="new Date().getTime()" :orgCode="queryForm.orgCode"
                         :taskList="drawer.tasks" :form="drawer.form" :childTasks="drawer.childTasks" />
<!--      <TaskGroupV2 v-if="drawer.templateType==='group' && drawer.action === 'edit'"  ref="taskGroup" :timestamp="new Date().getTime()" :form="drawer.form" />-->
      <div style="width: 200px;    position: absolute;    bottom: 150px;    right: 50px;">
        <el-button size="mini" @click="confirm">确 认</el-button>
        <el-button size="mini" @click="drawer.visible = false">取 消</el-button>
      </div>
    </el-drawer>


    <el-drawer :title="'设置目标 (' + targetDialog.form.taskName? targetDialog.form.taskName:'' + ')'" :visible.sync="targetDialog.visible" class="closed-header" size="90%" direction="rtl" append-to-body :close-on-click-modal="false" :wrapperClosable="false">
      <TargetV2 v-if="queryForm.orgCode" ref="target" :task-info="targetDialog.form" :auth-org-codes="[queryForm.orgCode]"  @show-commit="(val) => {targetDialog.showCommit = val}" />
      <div class="dialog-footer" style="text-align: right;position: absolute; right: 50px;">
        <el-button v-show="targetDialog.showCommit === '0'" class="form-btn-item" size="mini" type="primary" @click="() => $refs.target.handleCommitUnify()">提交</el-button>
        <el-button size="mini" type="primary" @click="targetDialog.visible = false">关 闭</el-button>
      </div>
    </el-drawer>

    <el-dialog title="同步" :visible.sync="syncDialog.visible" width="700px" append-to-body>
      <div>
        <el-form ref="form" size="mini" inline :rules="syncDialog.rules" label-width="160px" :model="syncDialog.form" class="common-form">
          <el-row>
            <el-col>
              <el-form-item label="分公司">
                <el-select v-model="syncDialog.form.targetOrgCodes" filterable default-first-option multiple :collapse-tags="true">
                  <el-option v-for="item in exceptCurrentOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
                </el-select>
                <el-button type="primary" @click="() => {
                  syncDialog.form.targetOrgCodes = [].concat(exceptCurrentOrgOptions.map(e => e.value))
                }">全选</el-button>
              </el-form-item>
            </el-col>
            <el-col v-if="!syncDialog.form.moduleName">
              <el-form-item label="仅同步商品图">
                <el-radio-group v-model="syncDialog.form.singleSkuImage">
                  <el-radio :label="false">否</el-radio>
                  <el-radio :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-row style="margin: 10px">
          <el-button size="small" type="primary" @click="handleSync">同步</el-button>
          <el-button size="small" @click="syncDialog.visible = false">取消</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listGroupTask,
  dictSelect,
  listModuleTask,
  saveModuleTask, getGroupTaskDetail, syncModule
} from "@/api/appTask";
import { defaultTaskJson, groupTaskJson, jlTaskJson } from "../../config";
import { v4 as uuidv4 } from "uuid";
import ModuleItem from "../components/ModuleItem";
import TaskContentV2 from "../components/taskContentV2";
import taskGroupDetailV2 from "../components/taskGroupDetailV2";
import { deepClone } from "@/utils";
import {Notification} from "element-ui";
import TargetV2 from "../../task/TargetV2";

export default {
  name: "AppWebPreview",
  components: { ModuleItem, TaskContentV2, TargetV2, taskGroupDetailV2 },
  props: {
    addModule: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      queryOrgOptions: [],
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        parentId: 'root',
        isLeaf: 0,
      },
      dictFun: dictSelect,
      currentTask: undefined,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      allTaskList: [],
      taskModuleList: [
        {
          title: '全国任务',
          taskModuleType: 'ALL',
          taskList: [],
          taskNotInModuleList: [],
          taskModuleData: [],
        },
        {
          title: '本地任务',
          taskModuleType: 'LOCAL',
          taskList: [],
          taskNotInModuleList: [],
          taskModuleData: [],
        },
        {
          title: '伙伴专区',
          taskModuleType: 'PARTNER',
          taskList: [],
          taskNotInModuleList: [],
          taskModuleData: [],
        }
      ],
      taskModuleData: [],
      drawer: {
        visible: false,
        orgCode: undefined,
        title: '',
        task: undefined,
        action: '',
        templateType: undefined,
        taskId: undefined,
        form: undefined,
        index: undefined,
        moduleIdx: undefined,
        insideIndex: undefined,
        childTasks: [],
        tasks: [],
        timestamp: undefined,
        taskModuleType: undefined,
        isGroup: undefined
      },
      showView: true,
      removeGroupTasks: [],
      targetDialog: {
        visible: false,
        showCommit: undefined,
        form: {}
      },
      shopAll: false,
      syncDialog: {
        visible: false,
        form: {
          orgCode: undefined,
          moduleName: undefined,
          mtTaskId: undefined,
          singleSkuImage: false,
          targetOrgCodes: []
        }
      }
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.queryOrgOptions = res.data.filter(e => e.value!='ALL');
      this.shopAll = res.data.filter(e => e.value==='ALL').length > 0? true:false;
      this.queryForm.orgCode = this.queryOrgOptions[0].value;
      this.handleFilter();
    });
  },
  computed: {
    taskModuleDataSorted() {
      return [...this.taskModuleData].sort((a, b) => { return a.sort - b.sort; })
    },
    exceptCurrentOrgOptions() {
      return this.queryOrgOptions.filter(e => e.value!==this.queryForm.orgCode);
    }
  },
  methods: {
    handleQuery() {
      this.loading = true;
      this.taskModuleListReset();
      listModuleTask({orgCode: this.queryForm.orgCode}).then(res => {
        this.loading = false;
        if (!res.data) {
          this.taskListQuery();
          return;
        }
        this.taskModuleList[0].taskModuleData = res.data.filter(e => e.taskModuleType === this.taskModuleList[0].taskModuleType);
        this.taskModuleList[1].taskModuleData = res.data.filter(e => e.taskModuleType === this.taskModuleList[1].taskModuleType);
        this.taskModuleList[2].taskModuleData = res.data.filter(e => e.taskModuleType === this.taskModuleList[2].taskModuleType);

        this.taskListQuery();
      })
    },
    setConfigJson(e) {
      if (e.isLeaf === 0) {
        if (!e.configJson) {
          e.configJson = deepClone(groupTaskJson);
          e.configJson.theme.cover.background.image = e.skuUrl;
        } else {
          e.configJson= JSON.parse(e.configJson);
        }
      } else {
        if (!e.configJson) {
          if (e.boostTask) {
            e.configJson = deepClone(jlTaskJson);
          } else {
            e.configJson = deepClone(defaultTaskJson);
          }
          e.configJson.theme.cover.background.image = e.skuUrl;
        } else {
          e.configJson = JSON.parse(e.configJson);
        }
      }
    },
    taskListQuery() {
      listGroupTask("?orgCode=" + this.queryForm.orgCode).then(res => {
        if (!res.data) {
          return false;
        }
        res.data.forEach(e => {
          this.setConfigJson(e);
        })
        this.allTaskList = res.data;
        for (let i = 0; i < 3; i++) {
          const taskList = res.data.filter(e => (e.taskModuleType ? e.taskModuleType : 'ALL') === this.taskModuleList[i].taskModuleType);
          this.taskModuleList[i].taskList = taskList;

          const taskInModule = [];
          this.taskModuleList[i].taskModuleData.forEach(item => {
            if (item.list) {
              taskInModule.push(...item.list)
            }
          });

          this.taskModuleList[i].taskNotInModuleList = this.taskModuleList[i].taskList.filter(item => !taskInModule.includes(item.mtTaskId));

          const sorted = this.taskModuleList[i].taskModuleData.sort((a, b) => { return a.sort - b.sort; });
          const maxSort = sorted[sorted.length - 1] ? sorted[sorted.length - 1].sort : 0;

          let tmpTaskList = this.taskModuleList[i].taskNotInModuleList.filter(e => e.parentId === 'root' && e.isLeaf === 1);
          for (let j = 1; j < tmpTaskList.length + 2; j++) {
            if (j % 2 === 0) {
              this.taskModuleList[i].taskModuleData.push({ type: '1', title: uuidv4(), list: [tmpTaskList[j-2]?.mtTaskId, tmpTaskList[j-1]?.mtTaskId], sort: maxSort + j/2, childTasks: [] })
            }
          }

          this.taskModuleList[i].taskModuleData.forEach(e => {

            let otherChildTasks = [];
            e.list.forEach(l => {
              if (l && l!=='') {
                let childTaskIds = [];
                e.childTasks.filter(c => c.parentId === l).forEach(cl => {
                  cl.list.forEach(cT => {
                    if(cT) childTaskIds.push(cT)
                  })
                });
                let newChildTaskIds = this.allTaskList.filter(t => {
                  return childTaskIds.indexOf(t.mtTaskId) < 0 && t.parentId===l
                }).map(t => t.mtTaskId);
                let sort = newChildTaskIds.length;
                if (newChildTaskIds.length > 0) {
                  for (let j = 0; j < newChildTaskIds.length + 2; j++) {
                    if (j % 2 === 0 && (newChildTaskIds[j] || newChildTaskIds[j+1])) {
                      otherChildTasks.push( { type: '1', title: uuidv4(), list: [newChildTaskIds[j], newChildTaskIds[j+1]], sort: sort + 1 + j/2, childTasks: [], parentId: l, taskModuleType: e.taskModuleType });
                    }
                  }
                }
              }
            })
            e.childTasks = e.childTasks.concat(otherChildTasks);
          })
        }
        this.loading = false;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleModuleMove(moduleId, type, moduleIndex) {
      const sorted = this.taskModuleList[moduleIndex].taskModuleData.sort((a, b) => { return a.sort - b.sort; });
      const index = sorted.findIndex(e => e.title === moduleId);
      if (index === 0 && type === 'up') {
        return;
      }
      if (index === sorted.length && type === 'down') {
        return;
      }

      const row = this.taskModuleList[moduleIndex].taskModuleData[index];
      if (type === "up") {
        this.taskModuleList[moduleIndex].taskModuleData[index].sort = index -1;
        this.taskModuleList[moduleIndex].taskModuleData[index-1].sort = index;
        this.taskModuleList[moduleIndex].taskModuleData.splice(index, 1)
        this.taskModuleList[moduleIndex].taskModuleData.splice(index - 1, 0, row)
      } else if (type === "down") {
        this.taskModuleList[moduleIndex].taskModuleData[index].sort = index + 1;
        this.taskModuleList[moduleIndex].taskModuleData[index+1].sort = index;
        this.taskModuleList[moduleIndex].taskModuleData.splice(index, 1)
        this.taskModuleList[moduleIndex].taskModuleData.splice(index + 1, 0, row)
      }
      this.$forceUpdate();
    },
    deleteModule(moduleId, moduleIndex) {
      const removeModule = this.taskModuleList[moduleIndex].taskModuleData.find(e => e.title === moduleId)
      const removeTasks = removeModule.list.filter(e => e);
      this.removeGroupTasks = this.removeGroupTasks.filter(e => e && removeTasks.indexOf(e.parentId) < 0).concat(removeModule.childTasks);
      this.taskModuleList[moduleIndex].taskModuleData = this.taskModuleList[moduleIndex].taskModuleData.filter(e => e.title !== moduleId)

      // if (removeTasks.length > 0) {
      //   this.allTaskList.forEach(e => {
      //     if (removeTasks.indexOf(e.mtTaskId) > -1 || removeTasks.indexOf(e.parentId) > -1) {
      //       e.parentId ='root';
      //       if (e.boostTask) {
      //         e.configJson = deepClone(jlTaskJson);
      //       } else {
      //         e.configJson = deepClone(defaultTaskJson);
      //       }
      //       e.configJson.theme.cover.background.image = e.skuUrl;
      //     }
      //   })
      // }
    },
    handleAddModule(moduleIndex, moduleType) {
      this.addModule('module', moduleIndex, moduleType);
    },
    addTaskModule(type, moduleIndex) {
      const sorted = this.taskModuleList[moduleIndex].taskModuleData.sort((a, b) => { return a.sort - b.sort; });
      const maxSort = sorted[sorted.length - 1] ? sorted[sorted.length - 1].sort : 0;
      switch (type) {
        case "1":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: ['', ''], sort: maxSort + 1 });
          break;
        case "2":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: ['', '', ''], sort: maxSort + 1 });
          break;
        case "3":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: ['', ''], sort: maxSort + 1 });
          break;
        case "4":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: ['', '', ''], sort: maxSort + 1 });
          break;
        case "5":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: [''], sort: maxSort + 1 });
          break;
        case "6":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: [''], sort: maxSort + 1 });
          break;
        case "7":
          this.taskModuleList[moduleIndex].taskModuleData.push({ type, title: uuidv4(), list: [''], sort: maxSort + 1 });
          break;
      }
    },
    addSplit(title) {
      const sorted = this.taskModuleData.sort((a, b) => { return a.sort - b.sort; });
      const maxSort = sorted[sorted.length - 1] ? sorted[sorted.length - 1].sort : 0;
      this.taskModuleData.push({ type: '0', title, list: null, sort: maxSort + 1 });
    },
    taskModuleDataSortedf(index) {
      return [...this.taskModuleList[index].taskModuleData].sort((a, b) => { return a.sort - b.sort; })
    },
    saveModuleTask() {
      let moduleTaskList = [];
      let errorMsg = [];
      let errModule = [];
      this.taskModuleList.forEach(e => {
        for (let j = 0; j < e.taskModuleData.length; j++) {
          if (e.taskModuleData[j].list.length === e.taskModuleData[j].list.filter(e => !e).length) {
            errModule.push("1");
          }

          if (errorMsg.length === 0 && errModule.length === 0) {
            const moduleNameHome = uuidv4();
            for (let k = 0; k < e.taskModuleData[j].list.length; k++) {
              let groupTasks = [];
              if (e.taskModuleData[j].list[k]) {
                const task = this.allTaskList.find(d => d.mtTaskId === e.taskModuleData[j].list[k]);
                if (task.isLeaf === 0) {
                  // 获取合集模块的子任务
                  if (e.taskModuleData[j].childTasks) {
                    e.taskModuleData[j].childTasks.filter(e => {
                      if (e.parentId === task.mtTaskId) {
                        groupTasks.push(e);
                      }
                    });
                  }
                  if (groupTasks.length === 0) {
                    groupTasks = [].concat(this.removeGroupTasks.filter(e => e && e.parentId === task.mtTaskId));
                  }
                  if (!groupTasks || groupTasks.length === 0) {
                    errorMsg.push(task.taskName);
                  } else {
                    let empty = false;
                    groupTasks.forEach(ele => {
                      const emptyCount = ele.list.filter(e => !e).length
                      if (ele.list.length === emptyCount) {
                        empty = true;
                      }
                    })
                    if (empty) {
                      errorMsg.push(task.taskName);
                    }
                  }
                }

                const jsonStr = (typeof  task.configJson ==='string') ? task.configJson: JSON.stringify(task.configJson);
                let childTasks = [];
                if (groupTasks.length > 0) {
                  groupTasks.forEach(c => {
                    let gIndex = 1;
                    const moduleName = uuidv4();
                    c.list.forEach(f => {
                      if (f) {
                        const groupTask = this.allTaskList.find(d => d.mtTaskId === f);
                        const gJsonStr = JSON.stringify(groupTask.configJson)
                        const groupModuleTask = { mtTaskId: f, orgCode: this.queryForm.orgCode, moduleName: moduleName, moduleType: c.type,
                          moduleSort: c.sort, insideSort: gIndex, configJson: gJsonStr, taskName: groupTask?.taskName, description: groupTask.description, taskModuleType: e.taskModuleType };
                        childTasks.push(groupModuleTask);
                      }
                      gIndex++;
                    })
                  })
                }

                const moduleTaskDto = { mtTaskId: e.taskModuleData[j].list[k], orgCode: this.queryForm.orgCode, moduleName: moduleNameHome, moduleType: e.taskModuleData[j].type,
                  moduleSort: e.taskModuleData[j].sort, insideSort: k+1, configJson: jsonStr, taskName: task?.taskName, childTasks: childTasks, taskModuleType: e.taskModuleType, description: task.description };
                moduleTaskList.push(moduleTaskDto);
              }
            }
          }
        }
      })
      this.allTaskList.forEach(e => {
        if (e.isLeaf === 0 && e.deleted === 1 && e.mtTaskId.indexOf('T') === 0) {
          const jsonStr = (typeof  e.configJson ==='string') ? e.configJson: JSON.stringify(e.configJson);
          const moduleTaskDto = { mtTaskId: e.mtTaskId, orgCode: this.queryForm.orgCode, moduleName: uuidv4(), moduleType: e.taskModuleType, deleted: 1,
            moduleSort: 0, insideSort:0, configJson: jsonStr, taskName: e?.taskName, childTasks: [], taskModuleType: e.taskModuleType, description: e.description };
          moduleTaskList.push(moduleTaskDto);
        }
      })
      if (errorMsg.length > 0) {
        Notification.error("合集对应的子任务不可为空！具体如下：" + errorMsg.join("、"));
        return;
      }
      if (errModule.length > 0) {
        Notification.error("模块内的任务不可完全为空！共" + errModule.length + '个模块');
        return;
      }
      this.loading = true;
      const param = { list: moduleTaskList };

      saveModuleTask(param, this.queryForm.orgCode).then((res) => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        } else {
          this.loading = false;
        }
      })
    },
    taskModuleListReset() {
      this.taskModuleData = [];
      this.allTaskList = [];
      this.taskModuleList[0].taskModuleData = [];
      this.taskModuleList[0].taskList = [];
      this.taskModuleList[0].taskNotInModuleList = [];
      this.taskModuleList[1].taskModuleData = [];
      this.taskModuleList[1].taskList = [];
      this.taskModuleList[1].taskNotInModuleList = [];
      this.taskModuleList[2].taskModuleData = [];
      this.taskModuleList[2].taskList = [];
      this.taskModuleList[2].taskNotInModuleList = [];
    },
    handleRemove() {

    },
    handleChangeTask(index, moduleIndex, type, insideIndex, tasks, action, taskType) {
      this.drawer.templateType = type;
      this.drawer.index = index;
      this.drawer.moduleIdx = moduleIndex;
      this.drawer.insideIndex = insideIndex;
      this.drawer.action = action;
      this.drawer.taskId = this.taskModuleList[index].taskModuleData[moduleIndex].list[insideIndex];
      if (action === 'sync') {
        this.handleSyncDialog(undefined, this.drawer.taskId);
        return;
      }
      let childTasks = [];
      if (this.taskModuleList[index].taskModuleData[moduleIndex].childTasks) {
        this.taskModuleList[index].taskModuleData[moduleIndex].childTasks.filter(e => {
          if (e.parentId === this.drawer.taskId) {
            childTasks.push(e);
          }
        });
      }
      if (childTasks.length === 0) {
        childTasks = [].concat(this.removeGroupTasks.filter(e => e && e.parentId === this.drawer.taskId));
      }
      this.drawer.childTasks = childTasks;
      const timestamp = new Date().getTime();
      this.drawer.taskModuleType = this.taskModuleList[index].taskModuleType;
      const allTaskIds = this.getAllTaskIds();
      let tmpForm = {};
      tmpForm = this.allTaskList.find(e => e && e.mtTaskId === this.drawer.taskId);
      this.drawer.isGroup = taskType === 'group' || (tmpForm && tmpForm.isLeaf !==undefined &&  tmpForm.isLeaf === 0) ? true : false;
      if (this.drawer.isGroup && tmpForm && action === 'target') {
        this.handleSetTarget(tmpForm);
        return;
      }
      if (this.drawer.action === 'remove') {
        let list = deepClone(this.taskModuleList[index].taskModuleData[moduleIndex].list);
        list[insideIndex] = "";
        this.taskModuleList[index].taskModuleData[moduleIndex].list = list;
        this.currentTask = undefined;
        this.$forceUpdate();
        return;
      } else if (action === 'delete') {
        let list = deepClone(this.taskModuleList[index].taskModuleData[moduleIndex].list);
        list[insideIndex] = "";
        if (this.drawer.isGroup) {
          // 将合集子任务恢复可选择状态
          this.allTaskList.forEach(e => {
            if (e.parentId === this.drawer.taskId) {
              e.parentId = 'root';
            } else if (e.mtTaskId === this.drawer.taskId) {
              // 将合集标记为删除
              e.deleted = 1;
            }
          })
        }
        this.taskModuleList[index].taskModuleData[moduleIndex].list = list;
        this.currentTask = undefined;
        this.$forceUpdate();
        return;
      }

      this.drawer.form = tmpForm ? deepClone(tmpForm): (this.drawer.isGroup ?
        {
          mtTaskId: 'G' + this.queryForm.orgCode + new Date().getTime(),
          isLeaf: 0,
          configJson: deepClone(groupTaskJson),
          taskModuleType: this.drawer.taskModuleType,
          timestamp: timestamp
        } : {timestamp: timestamp});
      tasks = tasks.filter(e => {
        if (!this.shopAll && e.orgCode === 'ALL') {
          return false;
        }
        if (!this.drawer.isGroup && ((allTaskIds.indexOf(e.mtTaskId) < 0 && e.parentId=='root') || e.mtTaskId === this.drawer.taskId)) {
          return true;
        } else if (this.drawer.isGroup) {
          if (action === 'edit' && this.drawer.taskId === e.mtTaskId) {
            return true;
          } else if ((e.isLeaf === 1 && e.parentId=='root') || e.parentId === this.drawer.taskId) {
            return true;
          } else {
            return false;
          }
        }
      });
      if (this.drawer.isGroup) {
        let allChildTaskIds = [];
          this.drawer.childTasks.forEach(e => {
            allChildTaskIds = allChildTaskIds.concat(e.list.filter(c => c))
          })
        let tmpTaskList = tasks.filter(e => allChildTaskIds.indexOf(e.mtTaskId) < 0 && e.parentId === this.drawer.taskId);
        let sort = this.drawer.childTasks.length;
        for (let j = 1; j < tmpTaskList.length + 2; j++) {
          if (j % 2 === 0) {
            this.drawer.childTasks.push({ type: '1', title: uuidv4(), list: [tmpTaskList[j-2]?.mtTaskId, tmpTaskList[j-1]?.mtTaskId], sort: sort + 1 + j/2, childTasks: [] })
          }
        }
      }
      if (tasks.length === 0 && type !== 'group') {
        Notification.warning("已无可添加的任务！");
        return;
      }
      this.drawer.tasks = tasks;
      this.drawer.visible = true;
    },
    getAllTaskIds() {
      let array = [];
      this.taskModuleList.forEach(c => {
        c.taskModuleData.forEach(e => {
          array = array.concat(e.list.filter(e => e))
        })
      })
      return array;
    },
    confirm() {
      if (this.drawer.action === 'edit') {
        if (!this.drawer.isGroup) {
          if (this.$refs.taskContent) {
            if (!this.$refs.taskContent.taskForm || !this.$refs.taskContent.taskForm.mtTaskId) {
              Notification.warning("请选择相应的任务！");
              return;
            }
            this.taskModuleList[this.drawer.index].taskModuleData[this.drawer.moduleIdx].list.splice(this.drawer.insideIndex, 1, this.$refs.taskContent.taskForm.mtTaskId);
            this.allTaskList = this.allTaskList.filter(e => e.mtTaskId !== this.$refs.taskContent.taskForm.mtTaskId);
            let taskForm = deepClone(this.$refs.taskContent.taskForm);
            if (taskForm.isLeaf !== 0 && !this.$refs.taskContent.taskForm.configJson.theme.cover.background.image) {
              taskForm.configJson.theme.cover.background.image = taskForm.skuUrl;
            } else if (taskForm.isLeaf === 0) {
              let childTasks = this.allTaskList.filter(e => e.parentId === taskForm.mtTaskId);
              if (childTasks.length > 0 && !taskForm.configJson.theme.cover.custom) {
                taskForm.configJson.theme.cover.background.image = childTasks[0].skuUrl;
              }
            }
            this.allTaskList.push(taskForm);
            this.drawer.visible = false;
          }
        } else {
          if (this.$refs.taskContent) {
            if (!this.$refs.taskContent.dataForm.taskName) {
              Notification.warning("请填写合集名称！");
              return;
            }
            let taskForm = this.$refs.taskContent.taskForm;
            if (!taskForm.mtTaskId) {
              taskForm.orgCode = this.drawer.orgCode;
              taskForm.mtTaskId = 'G' + this.queryForm.orgCode + new Date().getTime();
            }
            taskForm.taskModuleType = this.drawer.taskModuleType;
            this.taskModuleList[this.drawer.index].taskModuleData[this.drawer.moduleIdx].list.splice(this.drawer.insideIndex, 1, taskForm.mtTaskId);
            this.allTaskList = this.allTaskList.filter(e => e.mtTaskId !== taskForm.mtTaskId);
            if (taskForm.isLeaf === 0) {
              let childTasks = this.allTaskList.filter(e => e.parentId === taskForm.mtTaskId);
              if (childTasks.length > 0 && !taskForm.configJson.theme.cover.custom) {
                taskForm.configJson.theme.cover.background.image = childTasks[0].skuUrl;
              }
            }
            this.allTaskList.push(taskForm);
            this.drawer.visible = false;
          }
        }
      } else if (this.drawer.action === 'detail') {
        if (this.$refs.taskGroupDetail) {
          let groupTasks = this.$refs.taskGroupDetail.groupTasks;
          groupTasks.forEach(e => {
            e.parentId = this.drawer.taskId;
          })
          let groupTaskForm = this.$refs.taskGroupDetail.taskForm;
          let childTaskModules = [];
          if (this.taskModuleList[this.drawer.index].taskModuleData[this.drawer.moduleIdx].childTasks) {
            childTaskModules = this.taskModuleList[this.drawer.index].taskModuleData[this.drawer.moduleIdx].childTasks.filter(e => e.parentId !== groupTaskForm.mtTaskId);
          }
          this.taskModuleList[this.drawer.index].taskModuleData[this.drawer.moduleIdx].childTasks = childTaskModules.concat(groupTasks);
          let groupTaskIds = [];
          this.$refs.taskGroupDetail.groupTasks.forEach(e => {
            groupTaskIds = groupTaskIds.concat(e.list.filter(e => e))
          })
          let tmpList = this.allTaskList.filter(e => groupTaskIds.indexOf(e.mtTaskId) < 0);
          let childTaskList = this.$refs.taskGroupDetail.allTaskList.filter(e => groupTaskIds.indexOf(e.mtTaskId) > -1);

          if (childTaskList.length > 0 && !groupTaskForm.configJson.theme.cover.custom) {
            groupTaskForm.configJson.theme.cover.background.image = childTaskList[0].skuUrl;
          }
          this.allTaskList = tmpList.concat(childTaskList);

          this.taskModuleList.forEach(t => {
            t.taskModuleData.forEach(e => {
              let list = [];
              e.list.forEach(l => {
                if (groupTaskIds.indexOf(l) > -1) {
                  l = "";
                  list.push("");
                } else {
                  list.push(l);
                }
                e.list = list;
              })
            })
          })
          // this.taskModuleList[this.drawer.index].taskModuleData = tmpModuleData;
          this.allTaskList.forEach(e => {
            if (this.$refs.taskGroupDetail.taskForm.mtTaskId === e.mtTaskId) {
              e.configJson = groupTaskForm.configJson;
              e.description = groupTaskForm.description;
            }
            // 将移除的子任务 重置
            if (groupTaskIds.indexOf(e.mtTaskId) < 0 && e.parentId === groupTaskForm.mtTaskId) {
              e.parentId = 'root';
            }
          })
          this.drawer.visible = false;
        }
      }
    },
    handleSetTarget(form) {
      this.targetDialog.form = deepClone(form);
      getGroupTaskDetail(form.mtTaskId).then(res => {
        if (res.data.tasks.length > 0) {
          this.targetDialog.visible = true;
        } else {
          Notification.warning("当前任务并未绑定相应的任务，请在编辑合集内容内绑定任务提交后，再开始进行目标设置！");
        }
      })
    },
    handleSyncDialog(moduleName, taskId) {
      this.$confirm('请确认是否已保存所需同步的配置数据?', '提示', { type: 'warning' }).then(() => {
        this.syncDialog.form = {
          orgCode: this.queryForm.orgCode,
          moduleName: moduleName,
          mtTaskId: taskId,
          singleSkuImage: false,
          targetOrgCodes: []
        }
        this.syncDialog.visible = true;
      }).catch(() => {
      })
    },
    handleSync() {
      syncModule(this.syncDialog.form).then((res) => {
        if (res.code ==200) {
          Notification.success("同步成功！");
          this.syncDialog.visible = false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-main {
    padding: 5px 5px 0px 5px;
    overflow: unset;
  }

  .el-drawer__header {
    margin: 0px;
    height: 0px;
    padding: 0px;
  }
}
</style>
