<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter('hand')" @reset="resetQuery" @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item v-if="authOrgOptions.length > 1" label="问卷范围">
          <el-select v-model="queryForm.orgRange" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="queryForm.title" clearable placeholder="请输入问卷标题关键字" />
        </el-form-item>
        <el-form-item label="问卷类型">
          <el-select v-model="queryForm.type" clearable>
            <el-option label="考试" value="KS" /><el-option label="问卷" value="WJ" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('WJ')">创建调研问卷</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('KS')">创建考试</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('WJ')">创建调研问卷</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('KS')">创建考试</el-button>
    <el-table
    style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
      class="customer-table"
      border
          size="small"
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column label="问卷标题" prop="title"  />
      <el-table-column label="创建者" prop="createBy"  />
      <el-table-column label="问卷类型" prop="type"  >
        <template #default="{ row }">
          <span>{{ row.type === 'KS'? '考试': '问卷' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="200" />
      <el-table-column label="操作" width="340" align="center">
        <template #default="{ row }">
          <el-button size="mini" v-if="row.orgRange==queryForm.orgRange || authOrgAll || showButton" type="text" style="border: 0px;" @click="handleEdit(row, 'view')">编辑</el-button>
          <el-button size="mini"  v-if="row.orgRange==queryForm.orgRange || authOrgAll || showButton" type="text" style="border: 0px;" @click="handleDel(row)">删除</el-button>
          <el-button size="mini" style="border: 0px;" type="text" @click="handleExport(row)">导出问卷结果</el-button>
          <el-button size="mini"  v-if="row.orgRange==queryForm.orgRange || authOrgAll || showButton" style="border: 0px;" type="text" @click="handleCopy(row)">复制链接</el-button>
          <el-button size="mini" style="border: 0px;" type="text" :disabled="row.type !== 'KS'" @click="handleQuestionExport(row)">下载试题</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="saveDialog.titleMap[saveDialog.type]" :center="true" :visible.sync="saveDialog.visible" append-to-body width="750px" :close-on-click-modal="false" :before-close="handleBeforeClose">
      <div ref="divRef" style="overflow-y: scroll;height: 500px">
        <el-form ref="saveFormRef" size="mini" label-width="auto" :inline="true" :rules="saveDialog.rules" :model="saveDialog.form">
          <div>
            <el-row>
              <el-col>
                <span style="color: #1482f0; font-size: 24px;">{{ saveDialog.titleMap[saveDialog.type] }}</span>
              </el-col>
              <el-col>
                <el-form-item label="问卷标题" prop="title">
                  <el-input v-model="saveDialog.form.title" style="width: 350px" clearable placeholder="请输入标题" />
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="分享文案" prop="content">
                  <el-input v-model="saveDialog.form.content"  style="width: 350px" clearable placeholder="最多20字，例如“⻓线⼀盘货考试开始了，点击开始作答”" />
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="问卷时间" prop="rangeTime">
                  <el-date-picker v-model="saveDialog.form.rangeTime" style="width: 250px" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col v-if="saveDialog.form.type === 'KS'">
                <el-form-item label="是否随机">
                  <el-radio-group v-model="saveDialog.form.random">
                    <el-radio label="N"><span style="font-size: 12px;">不随机，展示全部题⽬</span></el-radio>
                    <el-radio style="font-size: 12px;" label="random"><span style="font-size: 12px;">随机抽取</span>
                      <el-input-number v-model="saveDialog.form.randomNum" :min=0  :controls="false" style="width: 60px" :precision ="0" /><span style="font-size: 12px;">个题⽬，题⽬和选项都会随机展示</span></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="问卷范围" prop="orgRange">
                  <el-select v-model="saveDialog.form.orgRange" multiple collapse-tags>
                    <el-option v-for="item in authOrg" :label="item.label" :value="item.value" :key="item.key" />
                  </el-select>
                  <el-button v-if="showButton" class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
                  <el-button v-if="showButton" class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(false)">取消全选</el-button>
                </el-form-item>
              </el-col>
              <el-col v-if="saveDialog.form.type === 'KS'">
                <el-form-item label="考试时间限制" prop="examTimeLimit">
                  <el-input v-model="saveDialog.form.examTimeLimit" style="width: 60px" clearable placeholder="请输入考试时间限制,数字,单位分钟" /><span style="font-size: 12px;"> 分钟</span>
                </el-form-item>
              </el-col>
              <el-col v-if="saveDialog.form.type === 'KS'">
                <el-form-item label="考试次数限制">
                  <el-radio-group v-model="saveDialog.form.examNumLimitLabel">
                    <el-radio label="N"><span style="font-size: 12px;">不限</span></el-radio>
                    <el-radio style="font-size: 12px;" label="Y"><span style="font-size: 12px;">限制</span>
                      <el-input-number v-if="saveDialog.form.examNumLimitLabel === 'Y'" v-model="saveDialog.form.examNumLimit" :min=0 :controls="false" style="width: 60px" :precision ="0" /><span style="font-size: 12px;">次</span></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div style="margin-bottom: 30px; margin-top: 25px">
            <el-row>
              <el-col>
                <span style="color: #1482f0; font-size: 24px;">题目设置</span>
                <span><el-button style="margin: auto 5px; position: relative; bottom: 4px;" size="mini" type="primary" @click="handleImport()">导入题目</el-button></span>
              </el-col>
              <el-col v-for="(item, index) in saveDialog.form.questions" :key="index" style="margin-bottom: 20px">
                <div>
                  <span style="font-weight: 600">{{index+1}}.{{ questionMap[item.type] }}&nbsp;&nbsp;</span><el-checkbox v-model="item.require">必答</el-checkbox> {{ saveDialog.form.type==='WJ'?'':'题目分数:'}}<el-input-number v-if="saveDialog.form.type==='KS'" size="small" :controls="false" style="width: 60px" v-model="item.score" :min=0 :precision ="0" />
                </div>
                <div>
                  <el-row>
                    <el-col :span="21" style="background-color: rgb(243 243 243);border-radius: 3px;">
                      <div style="margin: 5px">
                        <span style="font-weight: 600">题目</span>&nbsp;<el-input v-model="item.title" size="mini" style="width: 180px" placeholder="请输入题目" />
                        <el-button class="form-btn-item" size="mini" type="text" @click="handleSelectPhoto(index, undefined, 'question')">选择图片</el-button>
                        <OssUpload ref="ossUpload" :value-to.sync="item.imgUrl" list-type="picture-card" :maxFileSize="10240" :tipMessageShow="false" buttonName="上传图片" buttonType="text"
                                   style="display: inline-block; position: relative !important; bottom: 7px" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                      </div>
                      <el-row v-if="item.type!=='blank'" style="margin-left: 30px; margin-bottom: 2px">
                        <el-col v-for="(ele, i) in item.chooses" :key="i">
                          <span style="font-weight: 600">选项{{i+1}}</span> <el-input v-model="ele.title" size="mini" style="width: 180px" placeholder="请输入选项内容" />
                          <el-button style="margin: 0px;" icon="el-icon-plus" size="mini" circle @click="() => {item.chooses.splice(i + 1, 0, deepClone(saveDialog.defaultChoose));}"></el-button>
                          <el-button :disabled="item.chooses.length===1" style="margin: 0px;" icon="el-icon-minus" size="mini" circle  @click="() => {item.chooses.splice(i, 1);}"></el-button>
                          <el-button style="margin: 0px;" :disabled="i === 0" icon="el-icon-top" size="mini" circle @click="() => {item.chooses.splice(i, 1); item.chooses.splice(i - 1, 0, ele)}"></el-button>
                          <el-button style="margin: 0px;" :disabled="i === item.chooses.length-1" icon="el-icon-bottom" size="mini" circle @click="() => {item.chooses.splice(i, 1);item.chooses.splice(i + 1, 0, ele)}"></el-button>
                          <el-checkbox v-if="saveDialog.form.type === 'KS'" style="margin-left: 10px" v-model="ele.answer">正确答案</el-checkbox>
                          <el-button class="form-btn-item" size="mini" type="text" @click="handleSelectPhoto(index, i, 'choose', ele.title)">选择图片</el-button>
                          <OssUpload ref="ossUpload" :value-to.sync="ele.imgUrl" list-type="picture-card" :maxFileSize="10240" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']"
                                     style="display: inline-block; position: relative !important; bottom: 7px" buttonName="上传图片" buttonType="text" />
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="3">
                      <el-row>
                        <el-col>
                          <el-button size="mini" type="primary" @click="() => handleRemoveRow(index)">移除</el-button>
                        </el-col>
                        <el-col>
                          <el-button :disabled="index===0" size="mini" type="primary" @click="() => {saveDialog.form.questions.splice(index, 1); saveDialog.form.questions.splice(index - 1, 0, item)}">上移</el-button>
                        </el-col>
                        <el-col>
                          <el-button :disabled="index===saveDialog.form.questions.length-1" size="mini" type="primary" @click="() => {saveDialog.form.questions.splice(index, 1);saveDialog.form.questions.splice(index + 1, 0, item)}">下移</el-button>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>

            <el-row style="position: absolute; bottom: 10px;">

            </el-row>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="position: absolute; bottom: 5px;width: 90%;background-color: white;height: 30px">
          <div style="display: inline-block; text-align: left">
            <el-button size="mini" type="primary" @click="handleAddQuestion('single')">添加单选题</el-button>
            <el-button size="mini" type="primary" @click="handleAddQuestion('multiple')">添加多选题</el-button>
            <el-button v-if="saveDialog.type === 'WJ'" size="mini" type="primary" @click="handleAddQuestion('blank')">添加填空题</el-button>
          </div>
          <div style="display: inline-block; float: right">
            <el-button :disabled="buttonDisabled"  type="primary" size="mini" @click="handleSave">提交</el-button>
            <el-button type="primary" size="mini" @click="saveDialog.visible = false">关闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
                  @handleImportCallBack="handleImportData" append-to-body/>

    <el-dialog title="操作结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="商品图片(双击选择的图片)" :visible.sync="photoDialog.visible" width="50%" :close-on-click-modal="false">
      <el-form ref="form" :rules="photoDialog.rules" size="mini" :inline="true" :model="photoDialog.form" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="16">
            <el-form-item label="商品型号" prop="skuModel" label-width="110px">
              <DictSelect
                ref="modelDict"
                :type="''"
                :value.sync="photoDialog.form.skuModel"
                :dictFun="dictSkuModels"
                fullLabel
                remote
                :init-props="{ value: 'skuModel', label: 'skuName'}"
                version="v3"
                @change="(val) => handleChangeModel(val)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="商品图片" label-width="110px">
              <el-row>
                <el-col v-for="item in photoDialog.form.skuUrls" :key="item" :span="12" style="cursor: pointer">
                  <el-image :src="item" @dblclick="handleDbClickModel(item)" />
                </el-col>
              </el-row>
              <div v-if="photoDialog.form.skuUrls.length===0" style="text-align: center; margin: 10% 0;">
                暂无图片
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" :disabled="photoDialog.buttonLoading" @click="photoDialog.visible = false">取 消</el-button>
<!--        <el-button v-if="photoDialog.type!=='view'" :disabled="photoDialog.buttonLoading" size="mini" type="primary" @click="submitForm()">确 定</el-button>-->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  pageSurvey,
  saveSurvey,
  exportSurveyData,
  getSurveyDetail,
  delSurvey,
  exportQuestionData,getModelUrls,dictSkuModels
} from "@/api/appTask";
import { Notification } from "element-ui";
import OssUpload from "@/components/OssUpload";
import SkuItem from "@/views/appTask/skuGroup/SkuItem";
import {deepClone} from "../../../utils";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect";
export default {
  name: "AppServey",
  components: { Pagination, OssUpload, SkuItem, ImportDialog: () => import("@/components/ImportDialog"), DictSelect, SearchFilter },
  data() {
    return {
      deepClone,dictSkuModels,
      questionMap: {
        single: '单选题',
        multiple: '多选题',
        blank: '填空题',
        '单选题': 'single',
        '多选题': 'multiple',
        '填空题': 'blank',
      },
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        title: undefined,
        type: undefined,
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      saveDialog: {
        visible: false,
        type: undefined,
        titleMap: {
          KS: '考试卷设置',
          WJ: '调查问卷设置',
        },
        form: {
          id: undefined,
          type: undefined,
          title: undefined,
          content: undefined,
          startTime: undefined,
          endTime:undefined,
          rangeTime: [],
          orgRange: [],
          random: 'N',
          randomNum: 1,
          deleted: 0,
          questions: [],
          examTimeLimit: undefined,
          examNumLimit: 'N',
          examNumLimitLabel: 'N',
        },
        rules: {
          title: [{ required: true, message: " ", trigger: "change" }],
          content: [{ required: true, message: " ", trigger: "change" }],
          rangeTime: [{ type: 'array',required: true, message: " ", trigger: "change" }],
        },
        defaultQuestion:{
          title: undefined,
          type: undefined,
          score: 1,
          imgUrl: '',
          sort: undefined,
          require: true,
          deleted: 0,
          chooses:[{
            title: undefined,
            imgUrl: '',
            sort: undefined,
            require: undefined,
            deleted: 0,
            answer: undefined
          }]
        },
        defaultChoose: {
          title: undefined,
          imgUrl: '',
          sort: undefined,
          require: undefined,
          deleted: 0,
          answer: undefined
        },
        defaultForm: {
          id: undefined,
          type: undefined,
          title: undefined,
          content: undefined,
          startTime: undefined,
          endTime:undefined,
          rangeTime: [],
          orgRange: [],
          random: 'N',
          randomNum: 1,
          deleted: 0,
          questions: [],
          examTimeLimit: undefined,
          examNumLimit: 'N',
          examNumLimitLabel: 'N',
        }
      },
      importDialog: {
        currentImportType: "WJ",
        importBaseParams: [
          {
            visible: false,
            title: "问卷题目导入",
            type: "WJ",
            tipsText: "提示：题目类型及题目内容及答案选项不能为空",
            templateSampleHead: [{label: "题型", prop: "type", minWidth: "80px"},{label: "题目", prop: "title", minWidth: "80px"},{label: "题目(商品型号)", prop: "titleModel", minWidth: "80px"},
              {label: "A", prop: "A", minWidth: "80px"},{label: "A(商品型号)", prop: "modelA", minWidth: "80px"},
              {label: "B", prop: "B", minWidth: "80px"},{label: "B(商品型号)", prop: "modelB", minWidth: "80px"},
              {label: "C", prop: "C", minWidth: "80px"},{label: "C(商品型号)", prop: "modelC", minWidth: "80px"},
              {label: "D", prop: "D", minWidth: "80px"},{label: "D(商品型号)", prop: "modelD", minWidth: "80px"},
              {label: "E", prop: "E", minWidth: "80px"},{label: "E(商品型号)", prop: "modelE", minWidth: "80px"},
              {label: "F", prop: "F", minWidth: "80px"},{label: "F(商品型号)", prop: "modelF", minWidth: "80px"}],
            templateSampleLists: [
              {
                type: "单选题",
                title: "下列哪个数字最大？",
                titleModel: '1004',
                A: "1",
                modelA: '1004',
                B: "2",
                modelB: '1004',
                C: "3",
                modelC: '1004',
                D: "4",
                modelD: '1004',
                E: "5",
                modelE: '1004',
                F: "6",
                modelF: '1004',
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E9%97%AE%E5%8D%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          },
          {
            visible: false,
            title: "考试题目导入",
            type: "KS",
            tipsText: "提示：题目类型及题目内容及答案选项不能为空，答案最多为6个选项",
            templateSampleHead: [{label: "题型", prop: "type", minWidth: "80px"},{label: "题目", prop: "title", minWidth: "80px"},{label: "题目(商品型号)", prop: "titleModel", minWidth: "80px"},
              {label: "A", prop: "A", minWidth: "80px"},{label: "A(商品型号)", prop: "modelA", minWidth: "80px"},
              {label: "B", prop: "B", minWidth: "80px"},{label: "B(商品型号)", prop: "modelB", minWidth: "80px"},
              {label: "C", prop: "C", minWidth: "80px"},{label: "C(商品型号)", prop: "modelC", minWidth: "80px"},
              {label: "D", prop: "D", minWidth: "80px"},{label: "D(商品型号)", prop: "modelD", minWidth: "80px"},
              {label: "E", prop: "E", minWidth: "80px"},{label: "E(商品型号)", prop: "modelE", minWidth: "80px"},
              {label: "F", prop: "F", minWidth: "80px"},{label: "F(商品型号)", prop: "modelF", minWidth: "80px"},
              {label: "正确答案", prop: "correct", minWidth: "80px"},{label: "分值", prop: "score", minWidth: "80px"},],
            templateSampleLists: [
              {
                type: "多选题",
                title: "下列哪个数字大于4？",
                titleModel: '1004',
                A: "1",
                modelA: '1004',
                B: "2",
                modelB: '1004',
                C: "3",
                modelC: '1004',
                D: "4",
                modelD: '1004',
                E: "5",
                modelE: '1004',
                F: "6",
                modelF: '1004',
                correct: 'EF',
                score: 1
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E8%80%83%E8%AF%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          },
        ],
      },
      authOrgOptions: [],
      authOrg: [],
      authOrgCodes: [],
      noticeDialog: {
        visible: false,
        msg: undefined
      },
      jbpShareUrl: '#/questionnaire/detail?id=',
      queryOrgOptions: [],
      buttonDisabled: false,
      photoDialog: {
        visible: false,
        form: {
          skuModel: undefined,
          qIndex: undefined,
          cIndex: undefined,
          type: undefined,
          model: undefined,
          skuUrls: [],
        }
      }
    };
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
    showButton() {
      if (this.authOrg.length > 20 && this.authOrg.filter(e => e.value==='ALL').length===0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    "saveDialog.form.orgRange": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
      },
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrg = res.data;
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = this.authOrg.filter(e => e.value!=='ALL');
      if (res.data.length !== this.authOrgOptions.length) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.orgRange = this.authOrgOptions[0].value;
      }
      this.handleFilter();
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);

      pageSurvey(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
            const queryForm = {
                current: 1,
                size: 10,
                title: undefined,
                type: undefined,
            };
            this.queryForm = Object.assign({}, queryForm);
            this.$forceUpdate();
        },
    handleAdd(type) {
      this.saveDialog.type = type;
      this.saveDialog.form = deepClone(this.saveDialog.defaultForm);
      if (this.authOrgOptions.length > 0) {
        this.saveDialog.form.orgRange = [this.authOrgOptions[0].value];
      }
      this.saveDialog.form.type = type;
      this.importDialog.currentImportType = type;
      this.saveDialog.visible = true;
    },
    handleEdit(row) {
      getSurveyDetail(row.id).then(res => {
        if (res.code == 200) {
          if  (res.data) {
            if (res.data.random.includes("random")) {
              const random = res.data.random;
              res.data.random = random.split('_')[0];
              res.data.randomNum = random.split('_')[1];
            }
            if(res.data.examNumLimit && res.data.examNumLimit !== 'N'){
              res.data.examNumLimitLabel = 'Y';
            }else {
              res.data.examNumLimitLabel = 'N';
            }
            res.data.rangeTime = [res.data.startTime, res.data.endTime];
            this.saveDialog.form = res.data;
            this.saveDialog.form.orgRange = this.saveDialog.form.orgRange? this.saveDialog.form.orgRange.split(','): [];
            this.saveDialog.type = res.data.type;
            this.importDialog.currentImportType = res.data.type;
            this.buttonDisabled = false;
            this.saveDialog.visible = true;
            this.$forceUpdate();
          } else {
            Notification.error("问卷已删除或不存在！");
          }
        }
      })
    },
    handleAddQuestion(type) {
      let question = deepClone(this.saveDialog.defaultQuestion);
      question.type = type;
      this.saveDialog.form.questions.push(question);
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.divRef.scrollTop = this.$refs.divRef.scrollHeight;
        })
      }, 200)
    },
    handleImport() {
      const type = this.importDialog.currentImportType;
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === type);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    async handleImportData(val) {
      const tmp = val['importList'];
      if (tmp.length > 0) {
        let newArray = [];
        let allModels = this.getAllModels(tmp);
        let modelUrl = {}
        if (allModels.length > 0) {
          await getModelUrls({ skuModels: allModels}).then(res => {
            res.data.forEach(e => {
              modelUrl[e.skuModel] = e.skuUrl;
            })
          })
        }
        let errModels = []
        let errorMsg = [];
        tmp.forEach(e => {
          let question = deepClone(this.saveDialog.defaultQuestion);
          question.type = this.questionMap[e.type];
          if (!question.type) {
            errorMsg.push(1);
          }
          question.title = e.title;
          question.score = e.score;
          question.require = true;
          question.chooses = [];

          if (e.titleModel) {
            if (modelUrl[e.titleModel]) {
              question.imgUrl = modelUrl[e.titleModel];
            } else {
              errModels.push(e.titleModel);
            }
          }

          if (question.type !== 'blank') {
            this.getChoose('A', e, question, modelUrl, errModels);
            this.getChoose('B', e, question, modelUrl, errModels);
            this.getChoose('C', e, question, modelUrl, errModels);
            this.getChoose('D', e, question, modelUrl, errModels);
            this.getChoose('E', e, question, modelUrl, errModels);
            this.getChoose('F', e, question, modelUrl, errModels);
          }
          newArray.push(question);
        })
        if(errorMsg.length > 0) {
          this.noticeDialog.visible = true;
          this.noticeDialog.msg =  "导入失败，存在题目未设置或设置错误的题目类型，请填写后重新导入！";
          return;
        }
        this.saveDialog.form.questions = newArray;

        const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === this.importDialog.currentImportType);
        this.importDialog.currentImportType = currentImportOption.type;
        currentImportOption.visible = false;
        if (errModels.length > 0) {
          this.noticeDialog.visible = true;
          this.noticeDialog.msg =  "以下商品型号未匹配到商品图片，请在题目内重新设置，具体如下：</br>" + errModels.join("、");
          return;
        }
      } else {
        Notification.warning("导入的数据不能为空！")
      }
    },
    getChoose(key, e, question, modelUrl, errModels) {
      if (e[key]) {
        let choose = deepClone(this.saveDialog.defaultChoose);
        choose.title = e[key];
        if (e.correct && e.correct.includes(key)) {
          choose.answer = true;
        }
        question.chooses.push(choose);
        const modelKey = 'model' + key;
        if (e[modelKey]) {
          if (modelUrl[e.modelKey]) {
            choose.imgUrl = modelUrl[e.modelKey];
          } else {
            errModels.push(e.modelKey);
          }
        }
      }
    },
    getAllModels(list) {
      let models = [];
      models = models.concat(list.filter(e => e.titleModel).map(e => e.titleModel));
      models = models.concat(list.filter(e => e.modelA).map(e => e.modelA));
      models = models.concat(list.filter(e => e.modelB).map(e => e.modelB));
      models = models.concat(list.filter(e => e.modelC).map(e => e.modelC));
      models = models.concat(list.filter(e => e.modelD).map(e => e.modelD));
      models = models.concat(list.filter(e => e.modelE).map(e => e.modelE));
      models = models.concat(list.filter(e => e.modelF).map(e => e.modelF));
      return Array.from(new Set(models));
    },
    handleSave() {
      this.$refs.saveFormRef.validate(valid => {
        if (valid) {
          let form = deepClone(this.saveDialog.form);
          if (form.questions.length === 0) {
            Notification.warning("请至少添加一个问题！");
            return;
          }
          if (form.orgRange.length === 0) {
            Notification.warning("请至少选择一个分公司！");
            return;
          }
          form.orgRange = form.orgRange.join(',')
          let errorArray = this.verifyForm(form);
          if (errorArray.length > 0) {
            this.noticeDialog.visible = true;
            this.noticeDialog.msg = errorArray.join("<br>");
            return;
          }
          if (form.random.includes('random')) {
            form.random = form.random + '_' + form.randomNum;
          }
          if(form.examNumLimitLabel === 'N'){
            form.examNumLimit = 'N';
          }
          form.startTime = form.rangeTime[0];
          form.endTime = form.rangeTime[1];
          this.buttonDisabled = true;
          saveSurvey(form).then(res => {
            if (res.code === 200) {
              Notification.success("操作成功!");
              this.saveDialog.visible = false;
              this.handleFilter();
            }
            this.buttonDisabled = false;
          })
        }
      })
    },
    verifyForm(form) {
      let errorArray = [];
      form.questions.forEach((e, index) => {
        if (!e.title) {
          errorArray.push("第" + (index + 1) +"题，题目不能为空;")
        }
        if (form.type === 'KS' && !e.score) {
          errorArray.push("第" + (index + 1) +"题，分值不能为空;")
        }
        if (e.type!=='blank' && e.chooses.length > 0) {
          e.chooses.forEach((c,cIndex) => {
            if (!c.title) {
              errorArray.push("第" + (index + 1) +"题，第 "+(cIndex +1)+"选项内容不能为空;")
            }
          })
          if (form.type ==='KS') {
            const rightAnswer = e.chooses.filter(e => e.answer);
            if (rightAnswer.length <1) {
              errorArray.push("第" + (index + 1) +"题，正确答案不能为空;")
            }
            if (e.type === 'single' && rightAnswer.length > 1) {
              errorArray.push("第" + (index + 1) +"题，单选题最多只能有一个正确答案;")
            }
          }
        }
      })
      return errorArray;
    },
    handleExport(row) {
      this.loading = true;
      exportSurveyData(row.id).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', (row.type==='KS'?'考试': '问卷') + '(' + row.title + ')结果导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleQuestionExport(row) {
      this.loading = true;
      exportQuestionData(row.id).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '试题导出('+ row.title +').xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleDel(row) {
      this.$confirm('请确认是否删除该问卷！', '提示', { type: 'warning' }).then(() => {
        delSurvey(row.id).then(res => {
          if (res.code===200) {
            Notification.success("删除成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    handleCopy(row) {
      const url = process.env.VUE_APP_JBP_WEB_URL + this.jbpShareUrl + row.id
      const oInput = document.createElement('textarea')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      Notification.success("复制成功！");
      oInput.remove()
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后不会保存当前修改，请确认是否关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleRemoveRow(index) {
      this.$confirm('请确认是否移除该题？')
        .then(() => {
          this.saveDialog.form.questions.splice(index, 1)
        })
        .catch(() => {});
    },
    handleDictChange(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          if (this.authOrgOptions.length === 0) {
            return;
          }
          if (this.authOrgOptions.length > 2) {
            if (oldVal && oldVal.length > 1) {
              if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.saveDialog.form.orgRange.filter(e => e !== 'ALL');
              } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = [];
              } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            } else {
              if (val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (val.indexOf('ALL') < -1 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            }
          }
        })
      }
    },
    handleCheckAll(val) {
      if (val) {
        this.saveDialog.form.orgRange = this.authOrgOptions.map(e => e.value)
      } else {
        this.saveDialog.form.orgRange = [];
      }
    },
    handleSelectPhoto(qIndex, cIndex, type, model) {
      this.photoDialog.form.qIndex = qIndex;
      this.photoDialog.form.cIndex = cIndex;
      this.photoDialog.form.type = type;
      this.photoDialog.form.skuModel = model;
      this.photoDialog.form.skuUrls = [];
      if (model) {
        this.$nextTick(async () => {
          await this.$refs.modelDict.remoteMethod(model, false);
          const item = this.$refs.modelDict.itemOptions.find(e => e.skuModel === model);
          this.photoDialog.form.skuUrls = item && item.skuUrls? item.skuUrls.split(','): [];
        })
      }
      this.photoDialog.visible = true;
    },
    handleChangeModel(val) {
      this.$nextTick(() => {
        const item = this.$refs.modelDict.itemOptions.find(e => e.skuModel === val);
        this.photoDialog.form.skuUrls = item && item.skuUrls? item.skuUrls.split(','): [];
      })
    },
    handleDbClickModel(skuUrl) {
      if (skuUrl) {
        if (this.photoDialog.form.type == 'choose') {
          this.saveDialog.form.questions[this.photoDialog.form.qIndex].chooses[this.photoDialog.form.cIndex].imgUrl = skuUrl;
        } else {
          this.saveDialog.form.questions[this.photoDialog.form.qIndex].imgUrl = skuUrl;
        }
        this.photoDialog.visible = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
