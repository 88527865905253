var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container raceActivity-container" },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "110px",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleQuery.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "赛道" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.queryParams.brand,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "brand", $$v)
                    },
                    expression: "queryParams.brand",
                  },
                },
                _vm._l(_vm.options.brand, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动名称" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.queryParams.activityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "activityName", $$v)
                    },
                    expression: "queryParams.activityName",
                  },
                },
                _vm._l(_vm.options.activityName, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动状态" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.queryParams.activityStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "activityStatus", $$v)
                    },
                    expression: "queryParams.activityStatus",
                  },
                },
                _vm._l(_vm.options.activityStatus, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "Search", size: "mini" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", loading: _vm.downloadLoading },
                  on: { click: _vm.download },
                },
                [_vm._v("下载")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.addActivity } },
                [_vm._v("添加活动")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.deleteActivity } },
                [_vm._v("删除活动")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "activityTable",
          attrs: { border: "", size: "mini", data: _vm.activityList },
        },
        [
          _c("el-table-column", {
            attrs: { label: "id", align: "center", type: "index", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.queryParams.page - 1) * _vm.queryParams.limit +
                            scope.$index +
                            1
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "活动名称", align: "center", prop: "activityName" },
          }),
          _c("el-table-column", {
            attrs: { label: "所属赛道", align: "center", prop: "brand" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "财务核算口径",
              align: "center",
              prop: "financeAccountOrigin",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "活动开始日期",
              align: "center",
              prop: "startTime",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "活动结束日期", align: "center", prop: "endTime" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司编码", align: "center", prop: "orgCode" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司名称", align: "center", prop: "orgName" },
          }),
          _c("el-table-column", {
            attrs: { label: "产品编码", align: "center", prop: "skuCode" },
          }),
          _c("el-table-column", {
            attrs: { label: "产品名称", align: "center", prop: "skuName" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "活动状态",
              align: "center",
              prop: "activityStatus",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "更新时间", align: "center", prop: "createTime" },
          }),
          _c("el-table-column", {
            attrs: { label: "创建人id", align: "center", prop: "createBy" },
          }),
        ],
        1
      ),
      _c("mg-pagination", {
        attrs: {
          total: _vm.total,
          pageSize: _vm.queryParams.limit,
          currentPage: _vm.queryParams.page,
        },
        on: { pageChange: _vm.pagingChange },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "新增活动",
            width: "650px",
            visible: _vm.dialogObj.isAdd,
            "destroy-on-close": true,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogObj, "isAdd", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "addActivity_form",
                  attrs: {
                    model: _vm.dialogObj.activity,
                    "label-width": "140px",
                    "label-position": "right",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称", prop: "activityName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            "格式：年月日+赛道+活动名，如：20220525儿美儿童节",
                        },
                        model: {
                          value: _vm.dialogObj.activity.activityName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dialogObj.activity,
                              "activityName",
                              $$v
                            )
                          },
                          expression: "dialogObj.activity.activityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "赛道", prop: "brand" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.dialogObj.activity.brand,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogObj.activity, "brand", $$v)
                            },
                            expression: "dialogObj.activity.brand",
                          },
                        },
                        _vm._l(_vm.options.brand, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动日期", prop: "activityTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyyMMdd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.dialogObj.activity.activityTime,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dialogObj.activity,
                              "activityTime",
                              $$v
                            )
                          },
                          expression: "dialogObj.activity.activityTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "财务核算口径",
                        prop: "financeAccountOrigin",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.dialogObj.activity.financeAccountOrigin,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dialogObj.activity,
                                "financeAccountOrigin",
                                $$v
                              )
                            },
                            expression:
                              "dialogObj.activity.financeAccountOrigin",
                          },
                        },
                        _vm._l(
                          _vm.dialogObj.financeAccountOriginOptions,
                          function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "导入文件",
                        prop: "importActivity_Org_Sku",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.upload("importActivity_Org_Sku")
                            },
                          },
                        },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", {
            staticClass: "footer",
            attrs: { slot: "footer" },
            slot: "footer",
          }),
        ]
      ),
      _c("mg-importDialog", {
        attrs: {
          urlParams: _vm.urlParams,
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          apiExtraParams: _vm.dialogObj.activity,
        },
        on: { handleImportSuccess: _vm.handleImportSuccess },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "删除活动",
            width: "650px",
            visible: _vm.dialogObj.deleteDialog.isDelete,
            "destroy-on-close": true,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogObj.deleteDialog, "isDelete", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "deleteActivity_form",
                  attrs: {
                    model: _vm.dialogObj.deleteDialog.form,
                    "label-width": "140px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "删除活动名称",
                        prop: "deleteNames",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            multiple: "",
                          },
                          model: {
                            value: _vm.dialogObj.deleteDialog.form.deleteNames,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dialogObj.deleteDialog.form,
                                "deleteNames",
                                $$v
                              )
                            },
                            expression:
                              "dialogObj.deleteDialog.form.deleteNames",
                          },
                        },
                        _vm._l(
                          _vm.dialogObj.deleteDialog.option.activityName,
                          function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDeleteCancel()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.dialogObj.deleteDialog.deleteLoding,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDeleteConfirm()
                    },
                  },
                },
                [_vm._v("确认删除")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }