var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    _vm._b(
      { attrs: { disabled: _vm.disabled } },
      "el-tooltip",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { ref: "dyyTooltipContent", staticClass: "dyy-tooltip-content" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }