
import {
    handleMonthOrderDataAvailable
} from '@/api/config'
const pageDataAvailable = {
    data() {
        return {
            tipsConfig: {
                tips: {
                    '0': {
                        type: 'error',
                        title: '当前数据不可用'
                    },
                    '1': {
                        type: 'success',
                        title: '当前数据可用'
                    },
                    'default': () => {}
                },
                tipsType: null,
                isDisablePage: false
            }
        }
    },
    created() {
        this.handleCurrentPageDataAvailable()
    },
    methods: {
        // 页面数据是否可用
        async handleCurrentPageDataAvailable() {
            const tipsConfig = this.tipsConfig
            try {
                const { code, data, msg } = await handleMonthOrderDataAvailable()
                if(code === 200) {
                    const { available } = data
                    tipsConfig.tipsType = available || null
                    tipsConfig.isDisablePage = available && Number(available) === 1 ? false : true || false
                }
            } catch (error) {
                throw new Error(error)
            }
        }
    }
}

export {
    // 判断当前页面数据是否可用
    pageDataAvailable
}