<template>
  <div  v-loading="homeLoading" class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
      <SearchFilter @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="ora-org"
            :value.sync="queryForm.orgCode"
            linkageRef="ora-dc-all"
            :dictFun="dictFun"
            fullLabel
            default-first-option
            :default-index="0"
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="ora-dc-all"
            type="ora-dc-all"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            :default-index="0"
            :clearable="false"
            alwaysChange
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleImport">导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <el-button v-if="queryForm.dcCode==='ALL'" class="form-btn-item" size="mini" type="primary" @click="handleCopy">一键复制到分公司下配送中心</el-button>
        </el-form-item> -->
        <!-- <el-row>
          <div style="padding: 10px">
            配置后，在聚宝盆App→订单管理tab→238补货预估中，可以重点查看这些商品
          </div>
        </el-row> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleImport">导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <el-button v-if="queryForm.dcCode==='ALL'" class="form-btn-item" size="mini" type="primary" @click="handleCopy">一键复制到分公司下配送中心</el-button>
          <span style="margin-left: 10px;">配置后，在聚宝盆App→订单管理tab→238补货预估中，可以重点查看这些商品</span>
    <el-table
      :data="pageData.rows"
      style="margin-top: 10px;"
      v-loading="loading"
      class="customer-table"
      border
      size="small"
      highlight-current-row
      @sort-change="handleSortChange"
    >
      <el-table-column label="商品编码" prop="skuCode"  />
      <el-table-column label="商品名称" prop="skuName" />
      <el-table-column label="商品型号" prop="skuModel"  />
      <el-table-column label="操作" width="80" align="center">
        <template #default="{ row }">
            <el-popconfirm
              confirm-button-text='确认'
              cancel-button-text='取消'
              icon="el-icon-info"
              icon-color="red"
              :title="'确认移除该商品吗？'"
              @confirm="handleDel(row)"
            >
              <el-button slot="reference" size="mini" type="text" style="border: 0px; ">删除</el-button>
            </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>

      <div>
        <span> <strong>导入类型</strong></span>
        <el-radio-group v-model="importDialog.type">
          <el-radio label="add">新增</el-radio>
            <el-radio label="override">覆盖</el-radio>
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="复用分公司" :visible.sync="copyDialog.visible" append-to-body width="500px">
      <el-form ref="copyForm" :model="copyDialog.form" :inline="true" :rules="copyDialog.rules" label-width="140px">
        <el-form-item label="复制到以下配送中心" prop="targetDcCodes">
          <DictSelect ref="sq-type" type="pre-shop" :value.sync="copyDialog.form.targetDcCodes" collapse-tags fullLabel multiple init :init-options.sync="copyDialog.dcOptions"/>
        </el-form-item>
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(false)">取消全选</el-button>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="copyDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitCopy">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect, page238Top,  remove238Top, import238Top, export238Top, copy38Top } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect";

export default {
  name: "238Top",
  components: { Pagination, DictSelect, SearchFilter},
  data() {
    return {
      homeLoading: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        dcCode: undefined,
      },
      loading: false,
      currentUser: {},
      dictFun: dictSelect,
      shopJsonParam: {},
      importDialog: {
        visible: false,
        type: 'add',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '238预估补货重点商品导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/238%E9%A2%84%E4%BC%B0%E8%A1%A5%E8%B4%A7%E9%87%8D%E7%82%B9%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
      exportHeaders: {
        orgCode: '分公司编码',
        dcCode: '配送中心编码',
        skuCode: '商品编码',
        skuModel: '商品型号',
        errorMsg: '校验结果',
      },
      submitDisabled: false,
      copyDialog: {
        visible: false,
        form: {
          targetDcCodes: [],
        },
        rules: {
          targetDcCodes: [{ type: 'array', required: true, message: ' ', trigger: 'change' },],
        },
        dcOptions: [],
      }
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.queryForm.orgCode && this.queryForm.dcCode) {
        clearInterval(this.timer)
        this.handleFilter();
      }
    }, 100)
  },
  methods: {
    handleQuery() {
      this.loading = true;
      if (!this.queryForm.orgCode || !this.queryForm.dcCode) {
        Notification.warning("分公司编码及配送中心编码不可为空");
        return
      }
      const query = Object.assign({}, this.queryForm);
      page238Top(query, this.queryForm.orgCode, this.queryForm.dcCode).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDel(row) {
      remove238Top(row).then(res => {
        if (res.code== 200) {
          Notification.success("操作成功");
          this.handleFilter();
        }
      })
    },
    handleImport() {
      this.importDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.importDialog.type='add'
      this.importDialog.visible = true;
      this.submitDisabled = false;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.submitDisabled = true;
      import238Top(this.importDialog.type, fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.submitDisabled = false;
        this.loading = false;
        this.$refs.upload.clearFiles();
      })
    },
    downloadImportResult(data) {
      let title = '238预估补货重点商品导入校验结果';
      let headers = this.exportHeaders;
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
    handleExport() {
      if (!this.queryForm.orgCode || !this.queryForm.dcCode) {
        Notification.warning("分公司编码及配送中心编码不可为空");
        return
      }
      this.loading = true;
      const query = Object.assign({}, this.queryForm, {size: 10000});
      export238Top(query, this.queryForm.orgCode, this.queryForm.dcCode).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '238预估补货重点商品数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCopy() {
      this.$nextTick(() => {
        this.copyDialog.dcOptions = [].concat(this.$refs['ora-dc-all'].itemOptions).filter(e => e.value!='ALL');
      })
      this.copyDialog.form.targetDcCodes = [];
      this.copyDialog.visible = true;
    },
    handleCheckAll(action) {
      if (action) {
        this.copyDialog.form.targetDcCodes = [].concat(this.$refs['ora-dc-all'].itemOptions).filter(e => e.value!='ALL').map(e => e.value);;
      } else {
        this.copyDialog.form.targetDcCodes = [];
      }
    },
    submitCopy() {
      this.$refs.copyForm.validate(valid => {
        if (valid) {
          const data = {
            dcCode: 'ALL',
            targetDcCodes: this.copyDialog.form.targetDcCodes
          }
          copy38Top(data).then(res => {
            if (res.code==200) {
              Notification.success("操作成功!");
              this.handleFilter()
            }
          })
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
