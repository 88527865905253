<template>
  <div style="padding: 10px;" v-loading="targetLoading">
    <div class="mg-search" style="padding-bottom: 5px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc"
            :dictFun="dictFun"
            fullLabel
            init
            :init-options="orgOptions"
            :disabled="orgDictDisabled"
            version="v2"
            :clearable="false"
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            linkageRef="area-tag,shop-code"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            version="v2"
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
            ref="area-tag"
            type="area-tag"
            :value.sync="queryForm.areaTag"
            linkageRef="shop-code"
            :dictFun="dictFun"
            singleProps
            version="v2"
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺编码">
          <DictSelect ref="shop-code" :value.sync="queryForm.shopCode" version="v2" remote fullLabel type="shop-code" parentLinkageRef :dictFun="dictFun" @getOtherJsonParam="(callback) => callback(dictParam)" />
        </el-form-item>
        <el-form-item v-if="queryForm.taskRange === '1'" label="年份">
          <DictSelect ref="year" :value.sync="queryForm.year" version="v2" singleProps type="year" init :init-options="yearOptions" :defaultIndex="0" :clearable="false" />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button v-if="queryForm.type === '1'" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <el-button v-if="queryForm.type === '1'" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport">导入</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="queryForm.taskRange === '1'" class="div-class">
      <el-tabs v-model="activeTab" type="card" @tab-click="handleFilter">
        <el-tab-pane v-for="item in dateTabs" :label="item.label" :name="item.value" :key="item.value" />
      </el-tabs>
    </div>
    <div>
      <el-row>
        <el-col :span="6">
          <span style="margin-right:12px;font-weight: 700;">设置方式</span>
          <el-select v-model="queryForm.type" :disabled="settingDisable" size="mini" placeholder="请选择设置方式" @change="handleSettingChange">
            <el-option value="1" label="单店设置目标" />
            <el-option value="0" label="统一设置目标" />
          </el-select>
        </el-col>
        <el-col v-if="taskInfo.taskRange==='0' && taskInfo.startDate" :span="8">
          <div style="transform: translate(-0%,30%);">
            <span style="margin-right:12px;font-weight: 700;">任务日期：</span><span>{{ transTaskDate() }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="queryForm.type==='1'"  style="margin-top: 10px;background-color: #e1e6e8;">
        <el-col :span="20" style="margin: 3px">
          <span style="color:dodgerblue"><strong style="font-size: 20px">快捷填写</strong>
          <el-popover
            placement="right"
            width="600"
            title="快捷填写说明"
            trigger="click">
            <div style="font-size: 12px;">
              <el-row>
                <el-col :span="24"><h3>总目标:</h3>
                 填写后，<font color="red">会用[总目标金额]减掉[己设目标的店铺]后再平分到[未设目标的店铺]</font>（保留两位小数）</el-col>
              </el-row>
              <el-row>
                <el-col :span="24"><h3>店均上柜款数目标：</h3>填写后，会自动填充到每一家<font color="red">未设款数目标的店铺）</font></el-col>
              </el-row>
              <el-row v-if="queryForm.taskRange==='0'">
                <el-col :span="24"><h3>店均目标新增上柜款数：</h3>填写后，会自动填充到每一家<font color="red">未设新增款数目标的店铺）</font></el-col>
              </el-row>
            </div>
            <el-link icon="el-icon-question" type="info" slot="reference" style="margin-left: 10px;margin-bottom: 10px;" />
          </el-popover></span>
        </el-col>
        <el-col :span="8">
          <span style="margin-right:12px;font-weight: 700;">总目标金额</span>
          <div style="display: inline-block">
            <el-input-number v-model="total.totalAmount" style="width: 100%" size="mini" :precision="2" :placeholder="total.amountRefer" :min="0" :controls="false" />
          </div>
        </el-col>
        <el-col v-if="queryForm.type==='1'" :span="8">
          <span style="margin-right:12px;font-weight: 700;">店均目标上柜款式数</span>
          <div style="display: inline-block">
            <el-input-number v-model="total.avgModel" size="mini" style="width: 100%" :precision="2" :min="0" :placeholder="total.modelRefer" :controls="false" />
          </div>
        </el-col>
        <el-col v-if="queryForm.type==='1'" :span="8">
          <span style="margin-right:12px;font-weight: 700;">店均目标新增上柜款式数</span>
          <div style="display: inline-block">
            <el-input-number v-model="total.avgIncreaseModel" size="mini" style="width: 100%" :precision="2" :min="0" :placeholder="total.increaseRefer" :controls="false" />
          </div>
        </el-col>
        <el-col :span="6">
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" style="margin-left: 10px;transform: translate(-0%,10%);" @click="handleCompute">自动计算未填写店铺</el-button>
          <div style="float:right"><div style="display: block">已填写/未填写</div><div>{{ total.handTotal }}/{{ total.autoTotal }}</div></div>
        </el-col>
      </el-row>
      <el-row v-if="queryForm.type === '1'" >
        <el-col :span="20" style="margin-top: 10px;">
          <span style="color:dodgerblue"><strong style="font-size: 20px">单个店铺目标</strong>
          <el-popover
            placement="right"
            width="600"
            title="单个店铺填写说明"
            trigger="click">
            <div style="font-size: 12px;">
              <el-row>
                <el-col :span="24"><h3>人工输入的店铺：:</h3>
                 指在本页面人工输入或表格导入，<font color="red">人工填写的目标不会受快捷填写的影响</font>，只能手动更改。</el-col>
              </el-row>
              <el-row>
                <el-col :span="24"><h3>快捷填写的店铺：</h3>未填写过的和快捷填写的店铺可随时使用上方的快捷填写来填充覆盖，此目标不会展示在App的店铺详情页面中，仅用于任务的汇总目标计算。</el-col>
              </el-row>
            </div>
            <el-link icon="el-icon-question" type="info" slot="reference" style="margin-left: 10px;margin-bottom: 10px;" />
          </el-popover></span>
        </el-col>
      </el-row>
    </div>
    <div v-if="queryForm.type === '1'" class="div-class">
      <el-table
        :data="pageData.rows"
        height="calc(100vh - 350px)"
        v-loading="loading"
        size="mini"
        border
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="店铺编码/名称" prop="shop" width="250">
          <template #default="{ row }">
            <span> {{ row.shopCode==='ALL' ? '全部':row.shopCode + "-" + row.shopName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属配送中心" prop="dcName">
          <template #default="{ row }">
            <span>{{ row.dcCode==='ALL' ? '全部':row.dcCode + "-" + row.dcName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="目标进货金额" prop="amountTarget" width="120">
          <template slot-scope="{ row }">
            <el-input-number
              v-model="row.amountTarget"
              placeholder="请输入"
              style="width: 100%;"
              size="mini"
              :precision="2"
              :min="0.01"
              :controls="false"
              :class="row.amountType!=='1' ? 'table-num-class': ''"
              @change="(cv, ov) => {
                if (cv!==undefined) {
                  row.amountType = '1';
                  if (queryForm.type === '0') {
                    row.amountType = '2';
                  }
                } else {
                  row.amountType = undefined;
                }
                handleCommit(row);
              }"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="目标上柜款式数" prop="modelTarget" width="120">
          <template slot-scope="{ row }">
            <el-input-number
              v-model="row.modelTarget"
              placeholder="请输入"
              style="width: 100%;"
              size="mini"
              :controls="false"
              :precision="2"
              :min="0.01"
              :class="row.modelType!=='1' ? 'table-num-class': ''"
              @change="(cv, ov) => {
                if (cv!==undefined) {
                  row.modelType = '1';
                  if (queryForm.type === '0') {
                    row.modelType = '2';
                  }
                } else {
                  row.modelType = undefined;
                }
                handleCommit(row);
              }"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="目标新增上柜款式数" prop="increaseModelTarget" width="140">
          <template slot-scope="{ row }">
            <el-input-number
              v-model="row.increaseModelTarget"
              placeholder="请输入"
              style="width: 100%;"
              size="mini"
              :precision="2"
              :controls="false"
              :min="0.01"
              :class="row.increaseType!=='1' ? 'table-num-class': ''"
              @change="(cv, ov) => {
                if (cv!==undefined) {
                  row.increaseType = '1';
                  if (queryForm.type === '0') {
                    row.increaseType = '2';
                  }
                } else {
                  row.increaseType = undefined;
                }
               handleCommit(row);
              }"
            ></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
    </div>
    <div v-if="queryForm.type === '0'" style="margin: 20px; width: 40%">
      <el-form ref="form" size="mini" v-loading="loading" label-width="170px" :inline="true" :model="allTargetForm" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="14">
            <el-form-item label="单店目标进货额">
              <el-input-number
                v-model="allTargetForm.amountTarget"
                placeholder="请输入整数数字，非必填"
                style="width: 100%;"
                size="mini"
                :precision="0"
                :min="0"
                :controls="false"
                @change="(cv, ov) => {
                if (cv!==undefined) {
                  allTargetForm.amountType = '1';
                  if (queryForm.type === '0') {
                    allTargetForm.amountType = '2';
                  }
                } else {
                  allTargetForm.amountType = undefined;
                }
              }"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总店铺数量">
              <span>{{ totalShops }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="单店目标上柜款式数">
              <el-input-number
                v-model="allTargetForm.modelTarget"
                placeholder="请输入整数数字，非必填"
                style="width: 100%;"
                size="mini"
                :controls="false"
                :precision="0"
                :min="0"
                @change="(cv, ov) => {
                if (cv!==undefined) {
                  allTargetForm.modelType = '1';
                  if (queryForm.type === '0') {
                    allTargetForm.modelType = '2';
                  }
                } else {
                  allTargetForm.modelType = undefined;
                }
              }"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总金额">
              <span>{{ totalAmount }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="单店目标新增上柜款式数">
              <el-input-number
                v-model="allTargetForm.increaseModelTarget"
                placeholder="请输入整数数字，非必填"
                style="width: 100%;"
                size="mini"
                :precision="0"
                :controls="false"
                :min="0"
                @change="(cv, ov) => {
                if (cv!==undefined) {
                  allTargetForm.increaseType = '1';
                  if (queryForm.type === '0') {
                    allTargetForm.increaseType = '2';
                  }
                } else {
                  allTargetForm.increaseType = undefined;
                }
              }"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>

    <el-dialog title="目标导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="targetUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px; overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { shopTargetDict, pageShopTagets, saveShopTargets, getTotalShopTarget, exportTaskShopTarget, autoComputeShopTarget, importTaskShopTarget,changeTargetSetting, getShopTargetSetting, getSettingCount } from "@/api/appTask";
import Pagination from "@/components/Pagination";
import DictSelect from "@/components/DictSelect";
import { parseParam, parseTime } from "@/utils";
import {Notification} from "element-ui";

export default {
  name: "TargetV2",
  components: { DictSelect, Pagination },
  props: {
    taskInfo: {
      type: Object,
      required: true
    },
    authOrgCodes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      targetLoading: false,
      loading: false,
      pageData: {
        rows: [],
        total: 0,
      },
      queryForm: {
        mtTaskId: undefined,
        orgCode: undefined,
        dcCode: undefined,
        areaTag: undefined,
        shopCode: undefined,
        year: undefined,
        current: 1,
        size: 10,
        yearMth: undefined,
        type: '1',
        taskRange: undefined
      },
      total: {
        mtTaskId: undefined,
        yearMth: undefined,
        totalAmount: undefined,
        amountRefer: undefined,
        avgModel: undefined,
        modelRefer: undefined,
        avgIncreaseModel: undefined,
        increaseRefer: undefined,
        handTotal: 0,
        autoTotal: 0,
      },
      totalShops: 0,
      allTargetForm: {
        orgCode: undefined,
        modelTarget: undefined,
        modelType: undefined,
        increaseModelTarget: undefined,
        increaseType: undefined,
        amountTarget: undefined,
        amountType: undefined,
        taskRange: 0,
      },
      dictFun: shopTargetDict,
      orgDictDisabled: true,
      dictParam: {
        mtTaskId: undefined
      },
      defaultOrgOptions: [],
      activeTab: 'x',
      dateTabs: [],
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        longTemplate: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E9%95%BF%E6%9C%9F%E4%BB%BB%E5%8A%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        shortTemplate: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E9%98%B6%E6%AE%B5%E4%BB%BB%E5%8A%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        template: undefined,
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      yearOptions: [],
    }
  },
  computed: {
    yearMth() {
      if (this.activeTab === 'x') {
        return this.queryForm.year;
      } else if (this.activeTab.length === 1) {
        return this.queryForm.year + "0" + this.activeTab;
      } else {
        return this.queryForm.year + this.activeTab;
      }
    },
    totalAmount() {
      return this.allTargetForm.amountTarget> 0? this.totalShops * this.allTargetForm.amountTarget: 0;
    },
    settingDisable() {
      return this.queryForm.year !== '' +new Date().getFullYear() &&  this.queryForm.yearMth !== 'ALL';
    },
    orgOptions() {
      if (this.defaultOrgOptions.length > 1 && this.queryForm.type === '0' && this.authOrgCodes.indexOf('ALL') > -1) {
        return [{ value: 'ALL', label: '全部'}].concat(this.defaultOrgOptions)
      } else {
        if (this.queryForm.orgCode === 'ALL') {
          this.queryForm.orgCode = this.defaultOrgOptions[0].value;
        }
        return this.defaultOrgOptions;
      }
    }
  },
  watch: {
    taskInfo: {
      handler(newVal, oldVal) {
        if (newVal && newVal.mtTaskId) {
          this.resetQuery();
          this.resetForm();
          this.queryForm.mtTaskId = newVal.mtTaskId;
          this.queryForm.taskRange = newVal.taskRange;
          if (newVal.taskRange === '0') {
            this.importDialog.template = this.importDialog.shortTemplate;
          } else if (newVal.taskRange === '1') {
            this.importDialog.template = this.importDialog.longTemplate;
          }
          this.loading = true;
          shopTargetDict('org?mtTaskId=' + newVal.mtTaskId).then(async res => {

            this.defaultOrgOptions =res.data.filter(e => this.authOrgCodes.indexOf(e.value) > -1);
            this.orgDictDisabled = this.defaultOrgOptions.length > 1 ? false: true;
            if (this.defaultOrgOptions.length === 0) {
              Notification.info("当前合集未绑定店铺！");
              return;
            }
            this.queryForm.orgCode = this.defaultOrgOptions[0].value;
            if (this.queryForm.taskRange === '0') {
              this.queryForm.yearMth ='ALL';
            } else {
              this.activeTab = 'x';
              this.$forceUpdate();
              await shopTargetDict('year').then(async res => {
                this.yearOptions = res.data;
              })
              this.queryForm.year = this.yearOptions[0];
              this.queryForm.yearMth = this.queryForm.year;
            }

            this.handleGetShopTargetSetting(newVal.mtTaskId, this.queryForm.orgCode, this.queryForm.yearMth)
          })
        }
      },
      immediate: true,
    },
    "queryForm.orgCode": {
      handler(nV, oV) {
      },
      deep:true,
    }
  },
  created() {
    if (this.dateTabs.length === 0) {
      this.dateTabs.push({ value:  'x', label: '全年'})
      for (let i = 1; i < 13; i++) {
        this.dateTabs.push({ value: i> 9 ? i + '': '0' + i, label: i+ '月'})
      }
    }
  },
  methods: {
    handleQuery() {
      this.loading = true;
      if (this.queryForm.taskRange === '1') {
        this.queryForm.yearMth = this.yearMth;
      }
      const queryParam = parseParam(Object.assign({}, this.queryForm));
      getTotalShopTarget(queryParam).then(res => {
        if (res.code === 200 && res.data) {
          this.total = res.data;
          this.total.amountRefer = '当前列表汇总: ' + this.total.totalAmount;
          this.total.totalAmount = undefined;
          this.total.modelRefer = '当前列表店均: ' + this.total.avgModel;
          this.total.avgModel = undefined;
          this.total.increaseRefer = '当前列表店均: ' + this.total.avgIncreaseModel;
          this.total.avgIncreaseModel = undefined;
        }
      })
      pageShopTagets(queryParam).then(res => {
        if (!res.data) {
            return false;
        }

        this.pageData.rows = this.setRows(res.data?.records);
        this.loading = false;
        if (this.queryForm.type === '0') {
          this.allTargetForm = this.pageData.rows[0]
          this.getTotalShops();
        } else {
          this.allTargetForm = {
            orgCode: this.queryForm.orgCode,
            modelTarget: undefined,
            modelType: undefined,
            increaseModelTarget: undefined,
            increaseType: undefined,
            amountTarget: undefined,
            amountType: undefined,
            yearMth: this.queryForm.yearMth,
            taskRange: this.queryForm.taskRange,
          }
        }
        this.pageData.total = res.data?.total;
      });
    },
    getTotalShops() {
      pageShopTagets(parseParam({ orgCode: this.queryForm.orgCode, mtTaskId: this.queryForm.mtTaskId, yearMth: this.queryForm.yearMth, type: '1', current:1, size: 1})).then(res => {
        this.totalShops = res.data.total;
      })
    },
    setRows(rows) {
      rows.forEach(e => {
        if (!e.modelTarget) e.modelTarget = undefined;
        if (!e.increaseModelTarget) e.increaseModelTarget = undefined;
        if (!e.amountTarget) e.amountTarget = undefined;
        return e;
      })
      return rows;
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        mtTaskId: this.taskInfo.mtTaskId,
        orgCode: this.taskInfo.orgCode,
        dcCode: undefined,
        areaTag: undefined,
        shopCode: undefined,
        year: undefined,
        yearMth: this.queryForm.yearMth,
        type: '1',
        taskRange: undefined
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    resetForm() {
      const total = {
        mtTaskId: undefined,
        yearMth: undefined,
        totalAmount: undefined,
        amountRefer: undefined,
        avgModel: undefined,
        modelRefer: undefined,
        avgIncreaseModel: undefined,
        increaseRefer: undefined,
        handTotal: 0,
        autoTotal: 0
      }
      this.total = Object.assign({}, total);
      this.$forceUpdate();
    },
    transTaskDate() {
      const start = this.taskInfo.startDate;
      const end = this.taskInfo.endDate;
      const dateRange = (new Date(end).getTime() - new Date(start).getTime())/ (24 * 60 * 60 * 1000);
      return parseTime(start, '{y}-{m}-{d}') + '至' + parseTime(end, '{y}-{m}-{d}') + "  (" + Math.ceil(dateRange) + "天)";
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        if (ref === 'dc' && this.queryForm.yearMth && this.queryForm.orgCode) {
          this.handleGetShopTargetSetting(this.queryForm.mtTaskId, this.queryForm.orgCode, this.queryForm.yearMth);
        }
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.dictParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleCommitUnify() {
      if (this.loading) {
        Notification.info('数据加载中请勿提交!');
        return;
      }
      this.handleCommit(this.allTargetForm);
    },
    handleCommit(row) {
      saveShopTargets({ list: [row] }).then(res => {
        if (res.code === 200) {
          Notification.success({
            title: '提示',
            message: '操作成功!',
            position: 'top-right',
            duration: 3000
          });
          this.handleFilter();
        }
      })
    },
    handleExport() {
      this.targetLoading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportTaskShopTarget(param).then(res => {
        if (!res) {
          this.targetLoading = false;
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', this.taskInfo.taskName + '导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.targetLoading = false;
      })
    },
    handleCompute() {
      this.targetLoading = true;
      const param = Object.assign({}, this.queryForm, this.total);
      autoComputeShopTarget(param).then(res => {
        this.targetLoading = false;
        if (res.code === 200) {
          Notification.success('操作成功!');
          this.handleFilter();
        }
      })
    },
    handleImport() {
      this.$nextTick(() => {
        if (this.$refs.targetUpload) {
          this.$refs.targetUpload.clearFiles();
        }
        this.importDialog.fileList = [];
      })
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.targetLoading = true;
      importTaskShopTarget(this.taskInfo.mtTaskId, this.queryForm.orgCode,fd).then((res) => {
        if (res && res.msg.indexOf("很抱歉") > -1) {
          this.noticeDialog.msg = res.msg;
          this.noticeDialog.visible = true;
        } else {
          this.noticeDialog.msg = res.msg;
          this.noticeDialog.visible = true;
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.targetLoading = false;
        this.$refs.targetUpload.clearFiles();
      }).catch((e) => {
        this.targetLoading = false;
      })
    },
    handleSettingChange(val) {
      getSettingCount(this.queryForm.mtTaskId, this.queryForm.orgCode).then(res => {
        if (res.code ===200) {
          if (res.data  && res.data > 0) {
            this.$confirm('确认切换目标配置方式？切换后将会清空当前已填写的全部内容，请谨慎操作。', '提示', { type: 'warning' }).then(() => {
              this.changeSetting(true);
              this.$emit('show-commit', val);
            }).catch(() => {
              this.queryForm.type = val === '0' ? '1': '0';
            })
          } else {
            this.changeSetting(false);
            this.$emit('show-commit', val);
          }
        }
      })
    },
    changeSetting(flag) {
      changeTargetSetting(this.queryForm.mtTaskId, this.queryForm.orgCode, this.queryForm.yearMth).then((res) => {
        if (res.code == 200) {
          if (flag) {
            Notification.success("操作成功！");
          }
          this.handleFilter();
        }
      })
    },
    handleGetShopTargetSetting(taskId, orgCode, yearMth) {
      getShopTargetSetting(taskId, orgCode, yearMth).then(res => {
        if (res.code === 200 && orgCode !=='ALL') {
          this.queryForm.type = res.data;
        }
        if (orgCode === 'ALL') {
          this.queryForm.type === '1';
        }
        this.$emit('show-commit', this.queryForm.type);
        this.$forceUpdate();
        this.handleFilter();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.div-class {
  margin: 5px;
  //::v-deep .el-tabs__item {
  //  border: solid 1px;
  //}
}

::v-deep .table-num-class .el-input__inner{
  color: #c7c70a;
}
</style>
