<template>
  <div style="padding: 20px">
    <el-container>
      <el-aside style="width: 420px; background-color: #d0d6db; padding: 15px;height: 700px; overflow-y: scroll;">
        <AppWebPreview ref="appWebPreview" :addModule="addModuleShow" />
      </el-aside>
      <el-main style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); margin-left: 10px">
        <div v-if="editType == 'split'">
          <p style="font-weight: 800; font-size: 16px;">添加分割线</p>
          <el-input style="margin: 10px; width: 100%" v-model="splitTitle" placeholder="建议10个字以内"></el-input>
          <el-row style="margin: 10px">
            <el-button size="small" type="primary" @click="addSplit()">添加</el-button>
            <el-button size="small" @click="handleCancel()">取消</el-button>
          </el-row>
        </div>
        <div v-if="editType == 'module'">
          <p style="font-weight: 800; font-size: 16px;">添加任务模块</p>
          <el-radio-group v-model="moduleType" @change="moduleTypeChange" style="margin: 10px; width: 100%">
            <el-radio :label="'1'">一排二小</el-radio>
            <el-radio v-if="taskModuleType!='PARTNER'" :label="'2'">一大二小</el-radio>
            <el-radio v-if="taskModuleType!='PARTNER'" :label="'3'">一排二大</el-radio>
            <el-radio v-if="taskModuleType!='PARTNER'" :label="'4'">一排三个</el-radio>
            <el-radio :label="'5'">一排一个</el-radio>
            <el-radio :label="'7'">一排一个（高）</el-radio>
          </el-radio-group>
          <el-container style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">
            <el-header style="display: flex; align-content: center">
              <p style="font-size: 17px; font-weight: 700">{{showCaseTitle}}</p>
            </el-header>
            <el-main>
              <el-image
                :src="showCaseImg"
                :fit="'contain'" style="height: 250px; width: 500px"></el-image>
            </el-main>
          </el-container>
          <el-row style="margin: 10px">
            <el-button size="small" type="primary" @click="addModule()">添加</el-button>
            <el-button size="small" @click="handleCancel()">取消</el-button>
          </el-row>
        </div>
        <div v-if="editType == 'taskGroup'">
          添加任务合集
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import AppWebPreview from "../taskModule/AppWebPreview";

export default {
  name: "taskModule",
  components: { AppWebPreview },
  created() {
  },
  data() {
    return {
      moduleIndex: -1,
      editType: '',
      splitTitle: '',
      moduleType: '1',
      showCaseTitle: '',
      showCaseImg: '',
      taskModuleType: undefined,
      showCaseImgList: {
        oneRowTwoSmall: {
          title: '示例：一排二小',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row2_2%402x.png'
        },
        oneBigTwoSmall: {
          title: '示例：一大二小',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1big2small%402x.png'
        },
        oneRowTwoBig: {
          title: '示例：一排二大',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row2%402x.png'
        },
        oneRowThree: {
          title: '示例：一排三个',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row3%402x.png'
        },
        oneRowOne: {
          title: '示例：一排一个',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row1%402x.png'
        },
        oneRowHighOne: {
          title: '示例：一排一个（高）',
          img: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/high_row_purple.png'
        },
      },
    }
  },
  watch: {
  },
  computed: {},
  methods: {
    addModuleShow(type, moduleIndex,taskModuleType) {
      this.editType = type;
      this.moduleIndex = moduleIndex;
      this.moduleTypeChange('1');
      this.splitTitle = '';
      this.moduleType='1'
      this.taskModuleType = taskModuleType;
    },
    addModule() {
      this.$refs.appWebPreview.addTaskModule(this.moduleType, this.moduleIndex);
      this.editType = '';
    },
    addSplit() {
      this.$refs.appWebPreview.addSplit(this.splitTitle);
      this.editType = '';
    },
    handleCancel() {
      this.editType = '';
    },
    moduleTypeChange(type) {
      switch (type) {
        case '1':
          this.showCaseImg = this.showCaseImgList.oneRowTwoSmall.img;
          this.showCaseTitle = this.showCaseImgList.oneRowTwoSmall.title;
          break;
        case '2':
          this.showCaseImg = this.showCaseImgList.oneBigTwoSmall.img;
          this.showCaseTitle = this.showCaseImgList.oneBigTwoSmall.title;
          break;
        case '3':
          this.showCaseImg = this.showCaseImgList.oneRowTwoBig.img;
          this.showCaseTitle = this.showCaseImgList.oneRowTwoBig.title;
          break;
        case '4':
          this.showCaseImg = this.showCaseImgList.oneRowThree.img;
          this.showCaseTitle = this.showCaseImgList.oneRowThree.title;
          break;
        case '5':
          this.showCaseImg = this.showCaseImgList.oneRowOne.img;
          this.showCaseTitle = this.showCaseImgList.oneRowOne.title;
          break;
        case '7':
          this.showCaseImg = this.showCaseImgList.oneRowHighOne.img;
          this.showCaseTitle = this.showCaseImgList.oneRowHighOne.title;
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>
