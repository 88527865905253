var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "signboard-box" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { class: ["signboard", _vm.isHavBar ? "signboardBar" : ""] },
            [
              _vm._v(_vm._s(_vm.title)),
              _vm.backBtn
                ? _c(
                    "span",
                    { staticStyle: { "margin-left": "20px" } },
                    [
                      _c(
                        "el-link",
                        { attrs: { type: "info", onclick: "history.go(-1)" } },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }