var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { attrs: { gutter: 10 } },
    [
      _c(
        "el-col",
        { attrs: { span: 16, offset: 4 } },
        [
          _c(
            "el-form",
            {
              ref: "addActivity_form",
              attrs: {
                model: _vm.activity,
                size: "medium",
                inline: false,
                "label-width": "140px",
                "label-position": _vm.labelPosition,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "活动名称",
                    prop: "activityName",
                    size: "medium",
                    required: "",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.activity.activityName,
                      callback: function ($$v) {
                        _vm.$set(_vm.activity, "activityName", $$v)
                      },
                      expression: "activity.activityName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "赛道",
                    prop: "brand",
                    size: "medium",
                    required: "",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { size: "medium" },
                    model: {
                      value: _vm.activity.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.activity, "brand", $$v)
                      },
                      expression: "activity.brand",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "活动日期",
                    prop: "activityTime",
                    required: "",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.activity.activityTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.activity, "activityTime", $$v)
                      },
                      expression: "activity.activityTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "财务核算口径",
                    prop: "financeAccountOrigin",
                    size: "medium",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.activity.financeAccountOrigin,
                        callback: function ($$v) {
                          _vm.$set(_vm.activity, "financeAccountOrigin", $$v)
                        },
                        expression: "activity.financeAccountOrigin",
                      },
                    },
                    _vm._l(_vm.financeAccountOriginOptions, function (item) {
                      return _c("el-option", {
                        key: item.guid,
                        attrs: { label: item.name, value: item.guid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "导入文件",
                    prop: "activityName",
                    size: "medium",
                    required: "",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.activity.activityName,
                      callback: function ($$v) {
                        _vm.$set(_vm.activity, "activityName", $$v)
                      },
                      expression: "activity.activityName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }