<template>
  <div class="app-wrapper">
      <div class="main-container">
          <app-main />
      </div>
  </div>
</template>

<script>
import { AppMain } from './components';
export default {
    name: 'Layout',
    components: {
        AppMain
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
    .app-wrapper {
        min-width: 1200px;
        background: #f9f9f9;
    }
</style>