var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticClass: "new-box",
    },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc,area-tag",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          "default-first-option": "",
                          "default-index": 0,
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "dc",
                          value: _vm.queryForm.dcCode,
                          linkageRef: "area-tag",
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.shopJsonParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域分组" } },
                    [
                      _c("DictSelect", {
                        ref: "area-tag",
                        attrs: {
                          type: "area-tag",
                          value: _vm.queryForm.areaTag,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          alwaysChange: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "areaTag", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.shopJsonParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: () => {
                  _vm.importDialog.visible = true
                  _vm.submitDisabled = false
                },
              },
            },
            [_vm._v("导入分组店铺")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出分组店铺")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "分公司编码-名称", prop: "orgCode" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心编码-名称", prop: "dcCode" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.dcCode + "-" + row.dcName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "区域分组", prop: "areaTag" },
              }),
              _c("el-table-column", {
                attrs: { label: "终端数", prop: "total" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "220", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleViewShopDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v("店铺明细")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑分组名")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      border: "0px",
                                      width: "20%",
                                    },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleViewTarget(row)
                                      },
                                    },
                                  },
                                  [_vm._v("目标")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      "confirm-button-text": "确认",
                                      "cancel-button-text": "取消",
                                      icon: "el-icon-info",
                                      "icon-color": "red",
                                      title:
                                        "确认删除区域分组“" +
                                        row.areaTag +
                                        "”? 删除后该区域分组下的店铺会归入“其它“分组",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.handleDel(row)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0px" },
                                        attrs: {
                                          slot: "reference",
                                          size: "mini",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.editDialog.visible,
            width: "200px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.editDialog.form,
                inline: true,
                rules: _vm.editDialog.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "修改区域分组名称",
                    prop: "areaTag",
                    size: "mini",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入区域分组" },
                    model: {
                      value: _vm.editDialog.form.areaTag,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDialog.form, "areaTag", $$v)
                      },
                      expression: "editDialog.form.areaTag",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitEditForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "区域分组导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "goodsUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分组目标",
            visible: _vm.targetDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.targetDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: { size: "mini" },
              on: { change: () => _vm.handleViewTarget() },
              model: {
                value: _vm.targetDialog.year,
                callback: function ($$v) {
                  _vm.$set(_vm.targetDialog, "year", $$v)
                },
                expression: "targetDialog.year",
              },
            },
            _vm._l(_vm.yearOptions, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.targetDialog.loading,
                  expression: "targetDialog.loading",
                },
              ],
              attrs: {
                data: _vm.targetDialog.list,
                height: "400px",
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "时间", prop: "mth", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "目标", prop: "amountTarget", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            "进货金额 " +
                              _vm._s(row.amountTarget ? row.amountTarget : 0) +
                              " 元"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "店铺明细",
            visible: _vm.shopDrawer.visible,
            size: "75%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.shopDrawer, "visible", $event)
            },
          },
        },
        [
          _c("ShopDetail", {
            ref: "shopDetail",
            attrs: {
              param: _vm.shopDrawer.currentRow,
              timestamp: _vm.shopDrawer.timestamp,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "区域目标设置",
            visible: _vm.targetDrawer.visible,
            size: "75%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.targetDrawer, "visible", $event)
            },
          },
        },
        [
          _c("Target", {
            ref: "target",
            attrs: {
              param: _vm.targetDrawer.currentRow,
              timestamp: _vm.targetDrawer.timestamp,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }