var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.calcStatus.calcLoading || _vm.tableStatus.isLoading,
          expression: "calcStatus.calcLoading || tableStatus.isLoading",
        },
      ],
      staticClass: "schedulingPlan-page strategySelection-page",
    },
    [
      _vm.calcStatus.isStartCalcBefore
        ? _c("div", { staticClass: "schedulingPlan-content" }, [
            _c(
              "div",
              { staticClass: "schedulingPlan-start" },
              [
                _vm.calcStatus.isStartCalc
                  ? _c(
                      "el-button",
                      {
                        staticClass: "schedulingPlan-toStart",
                        staticStyle: { padding: "24px 60px !important" },
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleStartAdjust },
                      },
                      [_vm._v("开始产需平衡调整 ")]
                    )
                  : _vm._e(),
                _vm.calcStatus.calcLoading
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "schedulingPlan-loading" },
                          [
                            _vm._v(
                              " 产需平衡算法正在执行中，可能会花费一些时间，请稍候... "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.calcStatus.isCalcComplete
              ? _c(
                  "div",
                  { staticClass: "schedulingPlan-content" },
                  [
                    _c("v-progress", {
                      attrs: {
                        title: _vm.progressObj.title,
                        titleColor: "#e30808",
                        salesPhaseProject: _vm.progressObj.salesPhaseProject,
                        prodPhaseProject: _vm.progressObj.prodPhaseProject,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : [
            _c(
              "div",
              { staticClass: "schedulingPlan-search" },
              [
                _c("v-signboard", { attrs: { title: "策略选择" } }),
                _c(
                  "el-form",
                  {
                    ref: "filialeCustome-form",
                    staticClass: "main-search",
                    attrs: {
                      size: "mini",
                      "label-width": "auto",
                      inline: true,
                      model: _vm.form,
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { md: 8, lg: 6, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "调整策略", size: "mini" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "" },
                                    on: { change: _vm.handleTacticsAdj },
                                    model: {
                                      value: _vm.form.tactics,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tactics", $$v)
                                      },
                                      expression: "form.tactics",
                                    },
                                  },
                                  _vm._l(
                                    _vm.options.tacticsOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleTableHeadAction(
                                          "adjust"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("确 认 选 择 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "schedulingPlan-content" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "prodCenter-tabs",
                    staticStyle: { height: "800px" },
                    attrs: { "tab-position": "top" },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "prodCenter-tabs-item",
                        attrs: { label: "预排产能图表", name: "barDiagramTab" },
                      },
                      [
                        _c("div", {
                          staticStyle: { height: "350px" },
                          attrs: { id: "barDiagram" },
                        }),
                      ]
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "prodCenter-tabs-item",
                        attrs: { label: "预排产品列表", name: "productTab" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "schedulingPlan-con" },
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.tableStatus.isLoading,
                                    expression: "tableStatus.isLoading",
                                  },
                                ],
                                ref: "multipleTable",
                                staticClass: "table",
                                attrs: {
                                  stripe: "",
                                  border: "",
                                  size: "mini",
                                  data: _vm.lists,
                                },
                                on: { "selection-change": _vm.handleselection },
                              },
                              [
                                _vm._l(
                                  _vm.tableObj.stacticsHead,
                                  function (item, index) {
                                    return [
                                      ["cargono"].includes(item.prop)
                                        ? _c(
                                            "el-table-column",
                                            _vm._b(
                                              {
                                                key: index,
                                                attrs: {
                                                  fixed: "left",
                                                  "show-overflow-tooltip": "",
                                                  align: "center",
                                                },
                                              },
                                              "el-table-column",
                                              item,
                                              false
                                            )
                                          )
                                        : _c(
                                            "el-table-column",
                                            _vm._b(
                                              {
                                                key: index,
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  align: "center",
                                                },
                                              },
                                              "el-table-column",
                                              item,
                                              false
                                            )
                                          ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                            _c("v-pagination", {
                              attrs: {
                                total: _vm.total,
                                pageSize: _vm.pageSize,
                                currentPage: _vm.currentPage,
                              },
                              on: { pageChange: _vm.pagingChange },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "prodCenter-tabs-item",
                        attrs: { label: "问题产线列表", name: "aae03Tab" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "schedulingPlan-search" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  loading: _vm.btnStatus.isExportLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTableHeadAction("export")
                                  },
                                },
                              },
                              [_vm._v("导 出 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.tableStatus.isLoading,
                                expression: "tableStatus.isLoading",
                              },
                            ],
                            ref: "aae03Table",
                            staticClass: "table",
                            attrs: {
                              stripe: "",
                              "max-height": "700px",
                              border: "",
                              size: "mini",
                              data: _vm.aae03List,
                            },
                          },
                          [
                            _vm._l(
                              _vm.tableObj.aae03Head,
                              function (item, index) {
                                return [
                                  _c(
                                    "el-table-column",
                                    _vm._b(
                                      {
                                        key: index,
                                        attrs: {
                                          "show-overflow-tooltip": "",
                                          align: "center",
                                        },
                                      },
                                      "el-table-column",
                                      item,
                                      false
                                    )
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }