<template>
  <el-tabs v-model="tabName" @tab-click="(val) => handleTabClick(val.name)">
    <el-tab-pane name="match" label="匹配关系">
      <TechMatch ref="match" />
    </el-tab-pane>
    <el-tab-pane name="price" label="控价分析">
      <PriceAnalysis ref="price" @handleJumpQuery="handleJumpQuery" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import PriceAnalysis from '../priceAnalysis/index'
import TechMatch from '../techMatch/index'

export default {
  name: "TechPrice",
  components: {TechMatch, PriceAnalysis},
  data() {
    return {
      tabName: 'match'
    }
  },
  methods: {
    handleJumpQuery(skuId) {
      this.tabName = 'match';
      this.$nextTick(() => {
        this.$refs.match.handleJumpQuery(skuId);
      })
    },
    handleTabClick(val) {
      this.$nextTick(() => {
        this.$refs[val].handleFilter();
      })
    }
  }
}
</script>
