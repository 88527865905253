var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-left": "20px" }, attrs: { loading: _vm.loading } },
    [
      _c(
        "el-form",
        { ref: "addForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择分组", prop: "groupObj" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    remote: "",
                    clearable: "",
                    filterable: "",
                    "value-key": "id",
                    placeholder: "请输入分组名称",
                    "remote-method": _vm.remoteMethod,
                    size: "mini",
                  },
                  on: { change: _vm.handleGroupChange },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.form.groupObj,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "groupObj", $$v)
                    },
                    expression: "form.groupObj",
                  },
                },
                _vm._l(_vm.options.groups, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item, label: item.ruleGroupName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "选择版本", prop: "version" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "请选择版本",
                    size: "mini",
                  },
                  model: {
                    value: _vm.form.version,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "version", $$v)
                    },
                    expression: "form.version",
                  },
                },
                _vm._l(_vm.options.versions, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { value: item, label: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "触达方式", prop: "reachMode" } },
            [
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "manual" },
                    on: { input: _vm.handleIfLimitDisabled },
                    model: {
                      value: _vm.form.reachMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reachMode", $$v)
                      },
                      expression: "form.reachMode",
                    },
                  },
                  [_vm._v("手动触达")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { disabled: _vm.autoDisabled, label: "auto" },
                    on: { input: _vm.handleIfLimitDisabled },
                    model: {
                      value: _vm.form.reachMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reachMode", $$v)
                      },
                      expression: "form.reachMode",
                    },
                  },
                  [_vm._v("自动触达")]
                ),
              ],
            ],
            2
          ),
          _vm.form.reachMode === "auto"
            ? _c("el-form-item", { attrs: { label: "触达频次" } }, [
                _c("span", [_vm._v(_vm._s(this.form.reachFrequency))]),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "规则编辑" } },
            [
              [
                _c(
                  "el-radio",
                  {
                    attrs: { disabled: _vm.ifLimitDisabled, label: "Y" },
                    on: { input: _vm.handleRuleDetails },
                    model: {
                      value: _vm.form.ifLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ifLimit", $$v)
                      },
                      expression: "form.ifLimit",
                    },
                  },
                  [_vm._v("限制额度")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "N" },
                    on: { input: _vm.handleRuleDetails },
                    model: {
                      value: _vm.form.ifLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ifLimit", $$v)
                      },
                      expression: "form.ifLimit",
                    },
                  },
                  [_vm._v("不限制额度")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "C" },
                    on: { input: _vm.handleRuleDetails },
                    model: {
                      value: _vm.form.ifLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ifLimit", $$v)
                      },
                      expression: "form.ifLimit",
                    },
                  },
                  [_vm._v("直推crm")]
                ),
              ],
              _vm.form.ifLimit !== "C"
                ? _c(
                    "div",
                    { staticStyle: { width: "32%" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.form.channelRuleDetails,
                            size: "mini",
                            border: "",
                            "highlight-current-row": "",
                            "row-style": { height: 0 + "px" },
                            "cell-style": { padding: 0 + "px" },
                            "header-cell-style": {
                              background: "rgb(227 224 224)",
                              color: "black",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "优先级",
                              type: "index",
                              width: "60",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "渠道",
                              prop: "channel",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.runDisabled,
                                          },
                                          on: {
                                            change: (val) =>
                                              _vm.handleChannelChange(
                                                val,
                                                scope.row
                                              ),
                                          },
                                          model: {
                                            value: scope.row.channel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "channel",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.channel",
                                          },
                                        },
                                        _vm._l(
                                          _vm.channels[
                                            "channel" + scope.$index
                                          ],
                                          function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                value: item,
                                                label: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1125960104
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "触达比例",
                              prop: "reachRatio",
                              width: "130",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          disabled:
                                            row.channel === "电话" ||
                                            _vm.runDisabled,
                                          controls: false,
                                          placeholder: "请输入比例",
                                          size: "mini",
                                          min: 1,
                                          max: 100,
                                        },
                                        model: {
                                          value: row.reachRatio,
                                          callback: function ($$v) {
                                            _vm.$set(row, "reachRatio", $$v)
                                          },
                                          expression: "row.reachRatio",
                                        },
                                      }),
                                      _c(
                                        "i",
                                        {
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v("%")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3953870701
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: !scope.row.channel,
                                            type: "plain",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAddTagCondition(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("条件编辑")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3948108792
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { border: "0px" },
                                          attrs: {
                                            size: "mini",
                                            type: "text",
                                            disabled: _vm.runDisabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.form.channelRuleDetails.splice(
                                                scope.$index,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4004841599
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _vm._l(
                                        row.tagConditions,
                                        function (item) {
                                          return _c(
                                            "span",
                                            { key: item.tagName },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.tagName +
                                                    "-" +
                                                    (item.order === "asc"
                                                      ? "升序"
                                                      : item.order === "desc"
                                                      ? "降序"
                                                      : "")
                                                )
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("br"),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              481586931
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm.form.channelRuleDetails.length < 3
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.runDisabled,
                                type: "text",
                                plain: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.form.channelRuleDetails.push({})
                                },
                              },
                            },
                            [_vm._v("新增渠道")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ifLimit === "C"
                ? _c(
                    "div",
                    { staticStyle: { width: "32%" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.form.channelRuleDetails,
                            size: "mini",
                            border: "",
                            "highlight-current-row": "",
                            "row-style": { height: 0 + "px" },
                            "cell-style": { padding: 0 + "px" },
                            "header-cell-style": {
                              background: "rgb(227 224 224)",
                              color: "black",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "优先级", type: "index" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "渠道", prop: "channel" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "触达比例", prop: "reachRatio" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          disabled:
                                            row.channel === "crm" ||
                                            _vm.runDisabled,
                                          controls: false,
                                          placeholder: "请输入比例",
                                          size: "mini",
                                          min: 1,
                                          max: 100,
                                        },
                                        model: {
                                          value: row.reachRatio,
                                          callback: function ($$v) {
                                            _vm.$set(row, "reachRatio", $$v)
                                          },
                                          expression: "row.reachRatio",
                                        },
                                      }),
                                      _c(
                                        "i",
                                        {
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v("%")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              503595225
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("el-form-item", [
            _vm.form.channelRuleDetails.length > 0
              ? _c(
                  "div",
                  { staticStyle: { "text-align": "center", width: "32%" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: _vm.runDisabled,
                        },
                        on: { click: _vm.handleViewResult },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.currentRunning.status
                                ? _vm.buttonShowMap[_vm.currentRunning.status]
                                : "生成运算结果"
                            )
                        ),
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.viewData,
                          size: "mini",
                          border: "",
                          "highlight-current-row": "",
                          "row-style": { height: 0 + "px" },
                          "cell-style": { padding: 0 + "px" },
                          "header-cell-style": {
                            background: "rgb(227 224 224)",
                            color: "black",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "渠道", prop: "channel" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "可触达总数",
                            prop: "totalReachCount",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "触达比例", prop: "reachRatio" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "实际触达",
                            prop: "actualReachCount",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "未触达", prop: "noReachCount" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.viewData.length > 0
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center", width: "32%" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.currentRunning.status === "创建成功"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.buttonDisabled === "confirm",
                        size: "mini",
                        type: "primary",
                      },
                      on: { click: _vm.submitAddDistribute },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分发渠道补充条件（" + _vm.currentRow.channel + ")",
            visible: _vm.addTagConditionOpen,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addTagConditionOpen = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAddTagConditionData()
                            },
                          },
                        },
                        [_vm._v("保 存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addTagConditionCancel()
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.addTagConditionData, size: "mini" } },
            [
              _c("el-table-column", {
                attrs: { prop: "tagName", label: "标签名称", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              placeholder: "支持搜索/下拉选择",
                            },
                            on: {
                              change: (val) => {
                                const item = _vm.numTagOptions.find(
                                  (e) => e.tagId === val
                                )
                                scope.row.tagName = item.tagName
                                scope.row.tagColName = item.tagColName
                              },
                            },
                            model: {
                              value: scope.row.tagId,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "tagId", $$v)
                              },
                              expression: "scope.row.tagId",
                            },
                          },
                          _vm._l(_vm.numTagOptions, function (item) {
                            return _c("el-option", {
                              key: item.tagId,
                              attrs: {
                                "value-key": "item.tagId",
                                label: item.tagName,
                                value: item.tagId,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "order", label: "排序" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              placeholder: "下拉选择升序/降序",
                            },
                            model: {
                              value: scope.row.order,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "order", $$v)
                              },
                              expression: "scope.row.order",
                            },
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { value: "asc", label: "升序" } },
                              [_vm._v("升序")]
                            ),
                            _c(
                              "el-option",
                              { attrs: { value: "desc", label: "降序" } },
                              [_vm._v("降序")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteAddTagConditionLine(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "Plus", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.addTagConditionLine()
                        },
                      },
                    },
                    [_vm._v("新增条件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }