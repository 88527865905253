var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "searchRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _vm.queryForm.supplyDate
                ? _c("CategorySearch", {
                    ref: "category",
                    staticStyle: { display: "-webkit-inline-box" },
                    attrs: {
                      main: _vm.queryForm.c1CatCode,
                      mid: _vm.queryForm.c2CatCode,
                      sub: _vm.queryForm.c3CatCode,
                      date: true,
                    },
                    on: {
                      "update:main": function ($event) {
                        return _vm.$set(_vm.queryForm, "c1CatCode", $event)
                      },
                      "update:mid": function ($event) {
                        return _vm.$set(_vm.queryForm, "c2CatCode", $event)
                      },
                      "update:sub": function ($event) {
                        return _vm.$set(_vm.queryForm, "c3CatCode", $event)
                      },
                      getDate: (callback) => callback(_vm.queryForm.supplyDate),
                    },
                  })
                : _vm._e(),
              _c("DictSelect", {
                ref: "itemSearch",
                attrs: {
                  value: _vm.queryForm.itemCode,
                  showLabel: "商品信息",
                  fullLabel: true,
                  placeholder: "可输入编号或名称",
                  type: "item",
                  date: true,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "itemCode", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleResetFields },
                },
                [_vm._v("清空")]
              ),
              _c("el-form-item", { staticStyle: { "padding-left": "5px" } }, [
                _vm._v("小类、商品请至少选择一个条件!"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("UpdateTime", { attrs: { time: _vm.updateTime } }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 150px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
          },
          on: { "sort-change": _vm.handleSortChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品编号", prop: "itemCode", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "itemName", width: "260" },
          }),
          _c("el-table-column", {
            attrs: { label: "大分类", prop: "c1CatName" },
          }),
          _c("el-table-column", {
            attrs: { label: "中分类", prop: "c2CatName" },
          }),
          _c("el-table-column", {
            attrs: { label: "小分类", prop: "c3CatName" },
          }),
          _c("el-table-column", {
            attrs: { label: "大仓库存", prop: "storageQty", sortable: "" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "预测补货数量",
              prop: "ycBhQtyTotal",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "最终补货数量",
              prop: "zzBhQtyTotal",
              sortable: "",
            },
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }