var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { border: "1px solid #ccc", "margin-top": "10px" } },
      [
        _c("Toolbar", {
          staticStyle: { "border-bottom": "1px solid #ccc" },
          attrs: { editor: _vm.editor, defaultConfig: _vm.toolbarConfig },
        }),
        _c("Editor", {
          staticStyle: { height: "400px", "overflow-y": "hidden" },
          attrs: {
            defaultConfig: _vm.editorConfig,
            defaultContent: _vm.content,
          },
          on: { onChange: _vm.onChange, onCreated: _vm.onCreated },
          model: {
            value: _vm.html,
            callback: function ($$v) {
              _vm.html = $$v
            },
            expression: "html",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }