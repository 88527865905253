var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "order-org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          "default-first-option": "",
                          "default-index": 0,
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "order-dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          clearable: true,
                          "default-index": 0,
                          alwaysChange: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ orgCode: _vm.queryForm.orgCode }),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "全部" },
                          model: {
                            value: _vm.queryForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "type", $$v)
                            },
                            expression: "queryForm.type",
                          },
                        },
                        _vm._l(_vm.options.typeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.queryForm.skuCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "skuCode", $$v)
                          },
                          expression: "queryForm.skuCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "rid" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.queryForm.rid,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "rid", $$v)
                          },
                          expression: "queryForm.rid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间范围" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.queryForm.timeRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "timeRange", $$v)
                          },
                          expression: "queryForm.timeRange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-table",
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { label: "请求id", prop: "rid" },
              }),
              _c("el-table-column", { attrs: { label: "类型", prop: "type" } }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心", prop: "dcCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品编码", prop: "skuCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作人", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作时间", prop: "createTime" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "请求参数",
                  prop: "request",
                  "min-width": "550",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.unpackage(scope.row.request)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }