<template>
  <div style="margin-left: 20px" :loading="loading" >
    <el-form ref="compareForm" :model="form" :rules="rules" inline label-width="120px">
      <el-form-item label="选择分组" prop="ruleGroupId">
        <el-select v-model="form.groupObj" remote clearable filterable value-key="name" placeholder="请输入分组名称" :remote-method="remoteMethod" size="mini" @change="val => handleGroupChange(val)">
          <el-option v-for="item in options.groups" :key="item.name" :value="item" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.groupType === '1'" prop="version">
        <el-select v-model="form.version" filterable placeholder="请选择版本" size="mini">
          <el-option v-for="item in options.versions" :key="item" :value="item" :label="item" />
        </el-select>
      </el-form-item>
      <div>
        <el-form-item label="选择比较分组" prop="compareRuleGroupId">
          <el-select v-model="form.compareGroupObj" remote clearable filterable value-key="name" placeholder="请输入比较分组名称" :remote-method="val => remoteMethod(val, 'compare')" size="mini" @change="val => handleGroupChange(val, 'compare')">
            <el-option v-for="item in options.compareGroups" :key="item.name" :value="item" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.compareGroupType === '1'" prop="compareVersion">
          <el-select v-model="form.compareVersion" filterable placeholder="请选择比较版本" size="mini">
            <el-option v-for="item in options.compareVersions" :key="item" :value="item" :label="item" />
          </el-select>
        </el-form-item>
      </div>
      <div style="width:20%; text-align: center">
        <el-button type="primary" size="mini" style="margin-left: 30px" @click="confirmGroup">确认</el-button>
      </div>
      <div>
        <el-form-item label="基础信息" class="full-width">
          <div style="display: inline-flex; width: 100%;display: inline-flex;">
            <div style="min-width: 30%;display: inline-flex;"><p class="p-margin" style="background-color: rgba(235, 246, 255, 1);">{{ form.groupName }}</p>  <p class="p-margin">更新时间: {{ form.baseInfo.groupInfo.createTime }}</p>  <p class="p-margin">覆盖范围: {{ form.baseInfo.groupInfo.count }}</p></div>
            <div style="width: 10%;text-align: center"><p>VS</p></div>
            <div style="min-width: 30%;display: inline-flex;float:right"><p class="p-margin" style="background-color: rgba(235, 246, 255, 1);">{{ form.compareGroupName }}</p>  <p class="p-margin">更新时间: {{ form.baseInfo.compareGroupInfo.createTime }}</p>  <p class="p-margin">覆盖范围: {{ form.baseInfo.compareGroupInfo.count }}</p></div>
          </div>
        </el-form-item>
      </div>
      <el-form-item label="标签对比" class="half-width" s>
        <el-select v-model="form.ids" multiple size="mini" filterable>
          <el-option v-for="item in options.tags" :key="item.tagId" :value="item.id" :label="item.tagName" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="handleTagChange">确认</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-top: 20px">
      <div  v-for="(item,index) in chartData" :id="'pieChart' + index" :ref="'bingChart' + index" :key="index" style="width: 48%; height: 240px;display: inline-block; border: 1px solid #ede4e4;margin: 10px 10px"></div>
    </div>
  </div>
</template>

<script>
import { getGroupData, getVersionByGroup, getTagList, getCompareGroupData, getCompareTagData } from "@/api/tagPlatform";
import { Notification } from "element-ui";
import * as echarts from "echarts";
import { deepClone } from "@/utils";

export default {
  name: "DataReview",
  data() {
    return {
      loading: false,
      form: {
        groupType: undefined,
        groupObj: undefined,
        groupName: undefined,
        ruleGroupId: undefined,
        version: undefined,
        compareGroupObj: undefined,
        compareGroupType: undefined,
        compareGroupName: undefined,
        compareRuleGroupId: undefined,
        compareVersion: undefined,
        channelRuleDetails: [],
        baseInfo: {
          groupInfo: {},
          compareGroupInfo: {},
        },
        ids: []
      },
      rules: {
        ruleGroupId: [{ required: true, message: ' ', trigger: 'change' },],
        version: [{ required: true, message: ' ', trigger: 'change' },],
        compareRuleGroupId: [{ required: true, message: ' ', trigger: 'change' },],
        compareVersion: [{ required: true, message: ' ', trigger: 'change' },]
      },
      options: {
        groups: [],
        compareGroups: [],
        versions: [],
        compareVersions: [],
        tags: [],
      },
      chartData: [],
      buttonDisabled: undefined,
      defaultPieOption: {
        title: {
          text: '测试标签',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        // legend: {
        //   bottom: 10,
        //   left: 'center',
        //   data: ['男', '女']
        // },
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['25%', '50%'],
            selectedMode: 'single',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
          {
            type: 'pie',
            radius: '65%',
            center: ['75%', '50%'],
            selectedMode: 'single',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      defaultBarOption: {
        title: {
          text: undefined
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            // name: '2012',
            type: 'bar',
            data: []
          },
          {
            // name: '2012',
            type: 'bar',
            data: []
          }
        ]
      }
    }
  },
  created() {
    getTagList({limit: 200}).then(res => {
      this.options.tags = res.data.datas;
    })
  },
  methods: {
    handleGroupChange(val, type) {
      if (type === 'compare') {
        this.form.compareVersion = undefined;
        if (!val) {
          this.form.compareRuleGroupId = undefined;
          this.form.compareGroupName= undefined;
          this.form.compareGroupType = undefined;
        } else {
          this.form.compareRuleGroupId = val.ruleGroupId;
          this.form.compareGroupName= val.name;
          this.form.compareGroupType = val.type;
        }
      } else {
        this.form.version = undefined;
        if (!val) {
          this.form.ruleGroupId = undefined;
          this.form.groupName= undefined;
          this.form.groupType = undefined;
        } else {
          this.form.ruleGroupId = val.ruleGroupId;
          this.form.groupName= val.name;
          this.form.groupType = val.type;
        }
      }
      if (val && (!type && this.form.groupType === '1' || type === 'compare' && this.form.compareGroupType === '1')) {
        getVersionByGroup(val.ruleGroupId).then(res => {
          if (type === 'compare') {
            this.form.compareVersion = res.data[0];
            this.options.compareVersions = res.data;
          } else {
            this.form.version = res.data[0];
            this.options.versions = res.data;
          }
        })
      }
    },
    remoteMethod(val, type) {
      if (val) {
        getGroupData(val).then(res => {
          if (type === 'compare') {
            this.options.compareGroups = res.data;
          } else {
            this.options.groups = res.data;
          }
        })
      }
    },
    confirmGroup() {
      this.$refs.compareForm.validate(valid => {
        if (valid) {
          getCompareGroupData(this.getCompareParam()).then(res => {
            if (res.data && res.data.groupDatas.length === 2) {
              this.form.baseInfo = {
                groupInfo: res.data.groupDatas[0],
                compareGroupInfo:res.data.groupDatas[1],
              }
            } else {
              Notification.error('获取分组对比数据失败！')
            }
          })
        }
      })
    },
    getCompareParam() {
      const groupDataDetailDto = {
        name: this.form.groupName,
        ruleGroupId: this.form.ruleGroupId,
        version: this.form.version,
        type: this.form.groupObj.type
      }
      const contrastGroupDataDetailDto = {
        name: this.form.compareGroupName,
        ruleGroupId: this.form.compareRuleGroupId,
        version: this.form.compareVersion,
        type: this.form.compareGroupObj.type
      }
      return { groupDataDetailDto, contrastGroupDataDetailDto }
    },
    handleTagChange() {
      this.$refs.compareForm.validate(valid => {
        if (valid) {
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() =>{
              this.initChart(this.form.ids);
            }, 500)
          } else {
            this.timer = setTimeout(() =>{
              this.initChart(this.form.ids);
            }, 500)
          }
        } else {
          this.form.ids = [];
        }
      })
    },
    initChart(val) {
      if (val.length > 0) {
        getCompareTagData({ ...this.getCompareParam(), ids: val }).then(res => {
          if (res.data && res.data.groupDatas.length > 0) {
            this.transChartData(res.data.groupDatas);
          }
        })
      }
    },
    transChartData(data) {
      this.chartData = [];

      let transData = {};
      data.map(e => {
        e.tagDataContrastDto.forEach(item => {
          if (!transData[item.tagName]) {
            transData[item.tagName] = [];
          }
          transData[item.tagName].push(item);
        })
      })
      console.log(transData);

      for (let key in transData) {
        let i =0;
        let option= {};
        transData[key].forEach(e => {

          if (e.tagValType!=='bigint') {
            e.tagDataContrastDetailDtos.map(ele => {
              ele.name = ele.tagVal;
              ele.value = ele.count;
              return ele;
            })
            if (i === 0) {
              option = deepClone(this.defaultPieOption);
            }
          } else {
            if (i === 0) {
              option = deepClone(this.defaultBarOption);
            }
            option.yAxis.data = e.tagDataContrastDetailDtos.map(ele => ele.tagVal);
            e.tagDataContrastDetailDtos = e.tagDataContrastDetailDtos.map(ele => {
              return ele.count;
            })
          }
          option.title.text = e.tagName;
          option.series[i].data = e.tagDataContrastDetailDtos;
          i++;
        })
        this.chartData.push(option);
      }
      this.$nextTick(() => {
        this.chartData.forEach(function(item,index){
          console.log(item)
          let myChart;
          if (item.series[0].type === 'pie') {
            myChart = echarts.init(document.getElementById('pieChart' + index));
          } else {
            myChart = echarts.init(this.$refs['bingChart' + 0]);
          }

          myChart.clear();
          setTimeout(() => {
            myChart.setOption(item);
          }, 500)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-form-item {
  margin-bottom: 0px;
}

p {
  margin-right: 10px;
  margin-top: 0px;
  height: 40px;
  padding: 0px 5px;
}

</style>
