import {getSTSToken} from "@/api/appTask"
import Client from '@/utils/client'
import {  parseTime } from "@/utils";

const defaultConfig = {
  menus: [
    'head', // 标题
    'bold', // 加粗
    'fontSize', // 字号
    'fontName', // 字体
    'italic', // 斜体
    'underline', // 下划线
    'strikeThrough', // 删除线
    'indent', // 缩进
    'lineHeight', // 行高
    'foreColor', // 字体颜色
    'backColor', // 背景色
    'link', // 链接
    'list', // 序列
    // 'todo', // 待办事项
    'justify', // 对齐
    'quote', // 引用
    // 'emoticon', // 表情
    'image', // 图片
    'video', // 视频
    // 'table', // 表格
    // 'code', // 代码
    'splitLine', // 分割线
    'undo', // 撤销
    'redo' // 恢复
  ],
  fontNames: [
    '黑体',
    '仿宋',
    '楷体',
    '宋体',
    '微软雅黑',
    'Arial'
  ], // 常用字体
  lineHeights: ['1', '1.15', '1.6', '2', '2.5', '3'],
  uploadImgAccept: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'], // 限制图片类型
  uploadVideoAccept: ['mp4'] // 限制视频类型
};

const defaultContent = [
  {
    type: 'paragraph',
    lineHeight: '2',
    children: [
      { text: '', fontFamily: '', fontSize: '16px' },
    ]
  },
]

const imageCustomUpload = {
  // form-data fieldName ，默认值 'wangeditor-uploaded-image'
  base64LimitSize: 10 * 1024,
  fieldName: 'custom-upload-image',
  // 单个文件的最大体积限制，默认为 2M
  maxFileSize: 11 * 1024 * 1024, // 1M
  // 最多可上传几个文件，默认为 100
  maxNumberOfFiles: 10,
  // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
  allowedFileTypes: ['image/*'],
  // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
  meta: {
    token: 'xxx',
    otherKey: 'yyy'
  },
  // 将 meta 拼接到 url 参数中，默认 false
  metaWithUrl: false,
  // 自定义增加 http  header
  headers: {
    Accept: 'text/x-json',
    otherKey: 'xxx'
  },
  // 跨域是否传递 cookie ，默认为 false
  withCredentials: true,
  // 超时时间，默认为 10 秒
  timeout: 5 * 1000, // 5 秒
  // 上传之前触发
  onBeforeUpload(file) { // TS 语法
    // onBeforeUpload(file) {    // JS 语法
    // file 选中的文件，格式如 { key: file }
    return file
    // 可以 return
    // 1. return file 或者 new 一个 file ，接下来将上传
    // 2. return false ，不上传这个 file
  },
  // 上传进度的回调函数
  onProgress(progress) {  // TS 语法
    // progress 是 0-100 的数字
    console.log('progress', progress)
  },
  // 单个文件上传成功之后
  onSuccess(file, res) {  // TS 语法
    console.log(`${file.name} 上传成功`, res)
  },
  // 单个文件上传失败
  onFailed(file, res) {   // TS 语法
    console.log(`${file.name} 上传失败`, res)
  },
  // 上传错误，或者触发 timeout 超时
  onError(file, err, res) {  // TS 语法
    console.log(`${file.name} 上传出错`, err, res)
  },
  async customUpload(file, insertFn) {  // TS 语法
    // async customUpload(file, insertFn) {                   // JS 语法
    // file 即选中的文件
    // 自己实现上传，并得到图片 url alt href
    // 最后插入图片
    debugger
    getSTSToken({"supplier":"ALI"}).then(res => {
      if (res.success) {
        const {expiration, accessKeyId, accessKeySecret, securityToken} = res.data;
        const dataObj = {
          region: 'oss-cn-shanghai',
          bucket: 'mg-data-oss',
          accessKeyId: accessKeyId,
          accessKeySecret: accessKeySecret,
          stsToken: securityToken,
          useFetch: true
        };
        try {
          const client = Client(dataObj);
          // 分片上传文件
          console.log("开始上传");
          // notification.info('上传中...')
          const fileName = Date.now() + "_" + file.name;
          client.put('pdb-front/' + process.env.NODE_ENV + '/' + parseTime(new Date(), '{y}{m}') + "/" + fileName, file, {
            progress: async function (p) {
              let e = {};
              e.percent = p * 100;
            }
          }).then(({res}) => {
            console.log(res);
            if (res.statusCode === 200) {
              // this.$message.success('上传成功')
              // this.editor.insertText('<img src="'+res.requestUrls[0]+'"')
              insertFn(res.requestUrls[0], fileName, res.requestUrls[0]);
              // this.$emit("update:valueTo",  res.requestUrls[0].replace("http://", "https://"));
              return res.requestUrls
            } else {
              // notification.error('上传失败');
            }
          }).catch(error => {
            // notification.error('上传失败');
          });

        } catch (error) {
          console.error(error);
          // notification.error('上传失败');
        }
      }
    })
  }
}

const videoCustomUpload = {
  // form-data fieldName ，默认值 'wangeditor-uploaded-image'
  base64LimitSize: 10 * 1024,
  fieldName: 'custom-upload-video',
  // 单个文件的最大体积限制，默认为 2M
  maxFileSize: 500 * 1024 * 1024, // 1M
  // 最多可上传几个文件，默认为 100
  maxNumberOfFiles: 10,
  // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
  allowedFileTypes: ['video/*'],
  // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
  meta: {
    token: 'xxx',
    otherKey: 'yyy'
  },
  // 将 meta 拼接到 url 参数中，默认 false
  metaWithUrl: false,
  // 自定义增加 http  header
  headers: {
    Accept: 'text/x-json',
    otherKey: 'xxx'
  },
  // 跨域是否传递 cookie ，默认为 false
  withCredentials: true,
  // 超时时间，默认为 10 秒
  timeout: 5 * 1000, // 5 秒
  // 上传之前触发
  onBeforeUpload(file) { // TS 语法
    // onBeforeUpload(file) {    // JS 语法
    // file 选中的文件，格式如 { key: file }
    return file
    // 可以 return
    // 1. return file 或者 new 一个 file ，接下来将上传
    // 2. return false ，不上传这个 file
  },
  // 上传进度的回调函数
  onProgress(progress) {  // TS 语法
    // progress 是 0-100 的数字
    console.log('progress', progress)
  },
  // 单个文件上传成功之后
  onSuccess(file, res) {  // TS 语法
    console.log(`${file.name} 上传成功`, res)
  },
  // 单个文件上传失败
  onFailed(file, res) {   // TS 语法
    console.log(`${file.name} 上传失败`, res)
  },
  // 上传错误，或者触发 timeout 超时
  onError(file, err, res) {  // TS 语法
    console.log(`${file.name} 上传出错`, err, res)
  },
  async customUpload(file, insertFn) {  // TS 语法
    // async customUpload(file, insertFn) {                   // JS 语法
    // file 即选中的文件
    // 自己实现上传，并得到图片 url alt href
    // 最后插入图片
    getSTSToken({"supplier":"ALI"}).then(res => {
      if (res.success) {
        const {expiration, accessKeyId, accessKeySecret, securityToken} = res.data;
        const dataObj = {
          region: 'oss-cn-shanghai',
          bucket: 'mg-data-oss',
          accessKeyId: accessKeyId,
          accessKeySecret: accessKeySecret,
          stsToken: securityToken,
          useFetch: true
        };
        try {
          const client = Client(dataObj);
          // 分片上传文件
          console.log("开始上传");
          // notification.info('上传中...')
          client.put('pdb-front/' +  process.env.NODE_ENV + "/" + parseTime(new Date(), '{y}{m}') + "/"  + file.name, file, {
            progress: async function (p) {
              let e = {};
              e.percent = p * 100;
            }
          }).then(({res}) => {
            console.log(res);
            if (res.statusCode === 200) {
              let video = document.createElement('video')
              video.setAttribute('crossOrigin', 'anonymous');//处理跨域
              video.setAttribute('src', res.requestUrls[0]);
              video.setAttribute('preload', 'auto');
              video.addEventListener('loadeddata', function () {
                let canvas = document.createElement("canvas"),
                height = video.videoHeight;
                canvas.width = (height/16) * 7.5;
                canvas.height = height;
                canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height); //绘制canvas
                // 获取视频封面图
                let dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
                insertFn(res.requestUrls[0], dataURL);
                canvas = null;
              });
            } else {
              // notification.error('上传失败');
            }
          }).catch(error => {
            // notification.error('上传失败');
          });


        } catch (error) {
          console.error(error);
          // notification.error('上传失败');
        }
      }
    })
  }
}
export {
  defaultConfig,
  defaultContent,
  imageCustomUpload,
  videoCustomUpload
};
