var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-card", { staticStyle: { margin: "20px" } }, [
        _c(
          "div",
          { staticStyle: { width: "350px", margin: "auto" } },
          [
            _c(
              "el-upload",
              {
                staticClass: "upload-demo",
                attrs: {
                  drag: "",
                  accept: ".xls,.xlsx",
                  action: "",
                  multiple: "",
                  "auto-upload": false,
                  "on-change": _vm.onChange,
                },
              },
              [
                _c("i", { staticClass: "el-icon-upload" }),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("将文件拖到此处，或"),
                  _c("em", [_vm._v("点击上传")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "el-upload__tip",
                    attrs: { slot: "tip" },
                    slot: "tip",
                  },
                  [
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-top": "-4px" },
                        attrs: {
                          href: "https://mg-app-oss.oss-cn-shanghai.aliyuncs.com/upload/%E9%85%8D%E9%80%81%E4%B8%AD%E5%BF%83%E9%94%80%E5%94%AE%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx",
                          type: "primary",
                          target: "_blank",
                        },
                      },
                      [_vm._v("下载文件导入模板")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "center", margin: "20px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.submit },
                  },
                  [_vm._v("提交")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }