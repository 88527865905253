
const carryoverMaterialListHead = [
  {label: '结转年月', prop: 'carryover_month', minWidth: '65px'},
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '结转数量', prop: 'carryover_count', minWidth: '100px'}
]

const carryoverMaterialTemplateSampleHead = [
  {label: '结转年月', prop: 'carryover_month', minWidth: '65px'},
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '结转数量', prop: 'carryover_count', minWidth: '100px'}
]

const materialDimReportHead = [
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '换算料号', prop: 'trans_material_code', minWidth: '140px'},
  {label: '原始料号+换算料号', prop: 'material_key', minWidth: '260px'},
  {label: '折算系数', prop: 'cnt', minWidth: '120px'},
  {label: '折算系数合计', prop: 'total_cnt', minWidth: '120px'},
  {label: '转换系数', prop: 'trans_rate', minWidth: '80px'},
  {label: '型号', prop: 'model_code', minWidth: '100px'},
  {label: '颜色/规格', prop: 'specification', minWidth: '80px'},
  {label: '单位', prop: 'unit', minWidth: '80px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '工艺', prop: 'craft', minWidth: '100px'},
  {label: '包装单位', prop: 'pack_unit', minWidth: '100px'},
  {label: '机装款式', prop: 'machinery_fit_style', minWidth: '100px'},
  {label: '模号', prop: 'mould_code', minWidth: '100px'},
  {label: '笔头', prop: 'tip_style', minWidth: '100px'},
  {label: '品类', prop: 'prod_main_category_name', minWidth: '100px'},
  {label: '小类', prop: 'prod_sub_category_name', minWidth: '100px'},
  {label: '外观色', prop: 'apparent_color', minWidth: '100px'},
  {label: '是否盲盒', prop: 'is_blind_box_text', minWidth: '100px'},
  {label: '型号', prop: 'product_model', minWidth: '100px'},
  {label: '箱数/托盘', prop: 'box_count_per_tray', minWidth: '100px'},
  {label: '包装量', prop: 'pack_count', minWidth: '100px'},
  {label: '数量/托盘', prop: 'plu_count_per_tray', minWidth: '100px'},
  {label: '起订量', prop: 'min_order_count', minWidth: '100px'},
  {label: '注塑机类型', prop: 'injection_machine_style', minWidth: '100px'},
  {label: '注塑机链接', prop: 'injection_machine_link', minWidth: '100px'},
  {label: '盲盒机产线', prop: 'blind_box_product_line', minWidth: '100px'},
  {label: '定位转印产线', prop: 'locate_print_product_line', minWidth: '100px'},
  {label: '笔类工艺类型', prop: 'pen_craft_total', minWidth: '100px'},
  {label: '唯一模号', prop: 'unique_mould_code', minWidth: '100px'},
  {label: '商品ID', prop: 'plu_id', minWidth: '100px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '100px'}
]

const pluDimReportHead = [
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '商品名称', prop: 'plu_name', minWidth: '250px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '100px'},
  {label: '包装单位', prop: 'pack_unit', minWidth: '100px'},
  {label: '等级', prop: 'grade', minWidth: '100px'},
  {label: '产品排序', prop: 'grade_sort', minWidth: '100px'},
  {label: '赛道编码', prop: 'race_code', minWidth: '100px'},
  {label: '赛道名称', prop: 'race_name', minWidth: '100px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '100px'},
  {label: '大类编码', prop: 'main_category_code', minWidth: '100px'},
  {label: '大类名称', prop: 'main_category_name', minWidth: '100px'},
  {label: '中类编码', prop: 'mid_category_code', minWidth: '100px'},
  {label: '中类名称', prop: 'mid_category_name', minWidth: '100px'},
  {label: '小类编码', prop: 'sub_category_code', minWidth: '100px'},
  {label: '小类名称', prop: 'sub_category_name', minWidth: '100px'},
  {label: '规格', prop: 'spec', minWidth: '100px'},
  {label: '箱规', prop: 'bottom_spec', minWidth: '100px'},
  {label: '是否在销', prop: 'is_on_sale_text', minWidth: '100px'},
  {label: '单位', prop: 'unit', minWidth: '100px'},
  {label: '商品ID', prop: 'plu_id', minWidth: '100px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '100px'},
  {label: '供应中心', prop: 'supply_center', minWidth: '100px'},
]

const configCapacityHead = [
  {label: '产线类型', prop: 'product_line_type', minWidth: '100px'},
  {label: '具体产线', prop: 'product_line', minWidth: '150px'},
  {label: '产能下限(%)', prop: 'prod_percent_down', minWidth: '100px'},
  {label: '产能上限(%)', prop: 'prod_percent_up', minWidth: '100px'},
  {label: '更新人', prop: 'update_by', minWidth: '100px'},
  {label: '更新时间', prop: 'update_time', minWidth: '120px'},
]

export {
  carryoverMaterialListHead,              // 生产中心 -- 配置中心 --- 上月结转_物料-- 表头
  carryoverMaterialTemplateSampleHead,    // 生产中心 -- 配置中心 --- 上月结转_物料 -- 表单上传示例 -- 表头
  materialDimReportHead,                  // 生产中心 -- 配置中心 --- 物料维表 -- 表头
  pluDimReportHead,                       // 生产中心 -- 配置中心 --- 产品维表 -- 表头
  configCapacityHead,                     // 生产中心 -- 配置中心 --- 产能上下限配置 -- 表头
}
