<template>
  <div v-if="!show && list && list.length > 0" v-loading="show" class="groupBox">
    <template v-for="(item,index) in list">
      <TaskGroupItem v-if="item.isLeaf===0" :task="item" :type="type" :styles="computeStyles(item)" />
      <TaskHorizontalItem v-else-if="item.configJson.theme?.mode==='horizontal'" :task="item" :type="type" :styles="computeStyles(item)" />
      <TaskDefaultItem v-else :task="item" :type="type" :styles="computeStyles(item)" />
    </template>
  </div>
</template>

<script>
import TaskDefaultItem from '../TaskDefaultItem';
import TaskGroupItem from "../TaskGroupItem";
import TaskHorizontalItem from "../TaskHorizontalItem";

export default {
  name: "GroupItem",
  components: { TaskDefaultItem, TaskGroupItem, TaskHorizontalItem },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: "summary"
    }
  },
  watch: {
    list:{
      handler(nV, oV) {
        this.show = true;
        setTimeout(() => this.show = false, 500);
      },
      deep: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    computeStyles(item) {
      return {
        '--bgColor': item.configJson.theme?.background?.image ? '':item.configJson.theme?.background?.color,
        '--taskNameColor': item.configJson.theme?.taskName?.color,
        '--badgeColor': item.configJson.theme?.badge?.background?.color,
        '--badgeFontColor': item.configJson.theme?.badge?.font?.color,
        '--taskTimeColor': item.configJson.theme?.taskTime?.color,
        '--moreDataTextColor': item.configJson.theme?.moreDataText?.color,
        backgroundImage: item.configJson.theme?.background?.image && `url(${item.configJson.theme?.background?.image})`
      };
    }
  }
}
</script>
<style lang="scss" scoped src='@/style/appTask/groupItem.scss'></style>
