<template>
  <el-select
    v-model="queryForm.value"
    filterable
    :remote="remote"
    :clearable="clearable"
    :placeholder="placeholder"
    :remote-method="query => remoteMethod(query)"
    :loading="valueLoading"
    :allow-create="allowCreate"
    :multiple="multiple"
    @change="handleChange($event, 'hand')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-option
      v-for="item in itemOptions"
      :key="singleProps? item : (valueKey? item[valueKey]: item[initProps.value])"
      :label="singleProps? item : (fullLabel ? item[initProps.value] + '-' + item[initProps.label] : item[initProps.label])"
      :value="singleProps? item : item[initProps.value]"
    ></el-option>
  </el-select>
</template>
<script>

import { dictSelect } from "@/api/stockToShop";

export default {
  name: "DictSelect",
  props: {
    value: {
      type: [String, Array, Number],
    },
    type: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    fullLabel: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    dictFun: {
      type: Function,
      default: dictSelect,
    },
    linkageRef: {
      type: String,
    },
    parentLinkageRef: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    defaultIndex: {
      type: Number,
    },
    alwaysChange: {
      type: Boolean,
      default: false,
    },
    init: {
      type: Boolean,
      default: false
    },
    initOptions:{
      type: Array,
      default() {
        return [];
      }
    },
    initProps: {
      type: Object,
      default() {
        return { label: 'label', value: 'value'};
      }
    },
    singleProps: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      default: 'v1'
    },
    valueKey: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      itemOptions: this.initOptions,
      valueLoading: false,
      nameLoading: false,
      queryForm: {
        value: undefined,
        label: undefined,
      },
      forceChange: false
    };
  },
  watch: {
    "queryForm.value": {
      handler(newVal) {
        if (this.value !== newVal) {
          this.$emit("update:value", newVal === "" ? undefined : newVal);
          this.handleChange(newVal, 'auto');
        }
      },
      immediate: true,
    },
    value: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== this.queryForm.value) {
          this.$forceUpdate();
          this.queryForm.value = newVal;
          this.handleChange(newVal, 'auto');
        }
      },
      immediate: true,
      deep: true
    },
    jsonParam: {
      handler(newVal) {
        this.jsonParam = newVal;
      },
      immediate: true,
      deep: true,
    },
    initOptions: {
      handler(newVal) {
        if (this.init) {
          this.itemOptions = newVal;
          if (this.initOptions.length > 0 && this.defaultIndex!==undefined) {
            this.setVal(this.initOptions)
          }
        }
      },
      immediate: true,
      deep: true,
    }
  },
  created() {
    if (!this.remote && !this.parentLinkageRef && !this.init) {
      this.remoteMethod("", true);
    }
  },
  methods: {
    async remoteMethod(query, force) {
      if ((query && query!=='') || (!this.remote && force)) {
        this.valueLoading = true;

        const data = "?query=" + query;
        await this.dictFun(this.type + data, this.getOtherJsonParam()).then(res => {
          this.valueLoading = false;
          if (res.code === 200) {
            this.itemOptions = res.data;
            if (force && res.data.length > 0 && !this.value && this.defaultIndex!==undefined) {
              this.setVal(res.data);
            }
          }
        });
      }
    },
    setVal(options) {
      if (this.singleProps) {
        if (this.queryForm.value instanceof Array) {
          this.queryForm.value = [options[this.defaultIndex]];
        } else {
          this.queryForm.value = options[this.defaultIndex];
        }
      } else {
        if (this.queryForm.value instanceof Array) {
          this.queryForm.value = [options[this.defaultIndex].value];
        } else {
          this.queryForm.value = options[this.defaultIndex].value;
        }
      }
    },
    handleChange(val, type) {
      if (this.version === 'v1') {
        this.handleChangeV1(val, type);
      } else if (this.version === 'v2') {
        this.handleChangeV2(val, type);
      } else {
        this.$emit("change", val, type)
      }
    },
    handleChangeV1(val, type) {
      if (this.linkageRef) {
        // 由于将jsonParam的其他参数未及时清空所以临时处理先清空然后重新触发更新下拉
        this.$emit("changeLinkageRef", this.linkageRef, undefined, type);
        setTimeout(() => this.$emit("changeLinkageRef", this.linkageRef, val, type), 300)
      } else {
        this.$emit("change", val);
      }
    },
    handleChangeV2(val, type) {
      if (this.linkageRef) {
        this.$emit("changeLinkageRef", this.linkageRef, val, type)
      } else {
        this.$emit("change", val);
      }
    },
    handleRefChange(val, type) {
      if (type !== 'auto') {
        this.clearAll();
      }
      this.handleChange(undefined, type);
      if (!this.alwaysChange && (!val || (val instanceof Array && val.length === 0))) {
        this.itemOptions = [];
      } else if (this.alwaysChange) {
        this.remoteMethod(val, true, type);
      } else {
        this.remoteMethod(val, false, type);
      }
    },
    getOtherJsonParam() {
      let jsonParam = {};
      this.$emit('getOtherJsonParam', (data) => {
        jsonParam = data;
      });
      return jsonParam;
    },
    clearAll() {
      this.queryForm.value = undefined;
      this.$emit("update:value", undefined);
    },
  },
};
</script>
