var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mg-container",
    {
      scopedSlots: _vm._u([
        {
          key: "headerSearch",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "head-signboard-box" },
                [
                  _c("mg-signboard", { attrs: { title: "配置中心-商品" } }),
                  _c("mg-signboard", {
                    attrs: {
                      title:
                        _vm.branchCompanyInfos && _vm.branchCompanyInfos.name,
                      isHavBar: false,
                    },
                  }),
                ],
                1
              ),
              _c("mg-search-form", {
                ref: "mgSearchFormRef",
                attrs: {
                  "form-item-list": _vm.formItemList,
                  row: 2,
                  "label-width": "110px",
                  size: "mini",
                },
                on: { getSearchFormData: _vm.handleSearch },
                scopedSlots: _vm._u([
                  {
                    key: "buttonScope",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              loading: _vm.uploadLoading,
                            },
                            on: { click: _vm.handleImport },
                          },
                          [_vm._v("分公司产品等级&库存系数导入")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction(
                                  "importEstimatedSales"
                                )
                              },
                            },
                          },
                          [_vm._v("批量导入预估销量")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction(
                                  "importOtherInventory"
                                )
                              },
                            },
                          },
                          [_vm._v("批量导入其他库存")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.btnStatus.isExport,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction("export")
                              },
                            },
                          },
                          [_vm._v("导出")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "table",
          fn: function () {
            return [
              _c(
                "mg-table",
                {
                  ref: "mgTable",
                  staticClass: "mg-list-box",
                  attrs: {
                    autoLoad: true,
                    configurable: true,
                    businessType: "BRANCHOFFICE_CONFIGURATIONCENTER_LISTS",
                    columns: _vm.configurationCenterColumns,
                    api: _vm.handleConfigProductLists,
                    apiParams: _vm.queryForm,
                    extraParams: { isEdit: false },
                    height: "calc(100vh - 280px)",
                    "max-height": "auto",
                  },
                  on: {
                    "update:columns": function ($event) {
                      _vm.configurationCenterColumns = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "branchGrade",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "mini",
                                clearable: "",
                                disabled: !row.isEdit,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeRow("branchGrade", row)
                                },
                              },
                              model: {
                                value: row.branchGrade,
                                callback: function ($$v) {
                                  _vm.$set(row, "branchGrade", $$v)
                                },
                                expression: "row.branchGrade",
                              },
                            },
                            _vm._l(_vm.productGradeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "safeStockRatio",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              type: "number",
                              disabled: !row.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeRow(
                                  "safeStockRatio",
                                  row
                                )
                              },
                            },
                            model: {
                              value: row.safeStockRatio,
                              callback: function ($$v) {
                                _vm.$set(row, "safeStockRatio", _vm._n($$v))
                              },
                              expression: "row.safeStockRatio",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "stockRatioAim",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              type: "number",
                              disabled: !row.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeRow("stockRatioAim", row)
                              },
                            },
                            model: {
                              value: row.stockRatioAim,
                              callback: function ($$v) {
                                _vm.$set(row, "stockRatioAim", _vm._n($$v))
                              },
                              expression: "row.stockRatioAim",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "stockRatioUp",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              type: "number",
                              disabled: !row.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeRow("stockRatioUp", row)
                              },
                            },
                            model: {
                              value: row.stockRatioUp,
                              callback: function ($$v) {
                                _vm.$set(row, "stockRatioUp", _vm._n($$v))
                              },
                              expression: "row.stockRatioUp",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "stockRatioDown",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              type: "number",
                              disabled: !row.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeRow(
                                  "stockRatioDown",
                                  row
                                )
                              },
                            },
                            model: {
                              value: row.stockRatioDown,
                              callback: function ($$v) {
                                _vm.$set(row, "stockRatioDown", _vm._n($$v))
                              },
                              expression: "row.stockRatioDown",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "thisMthExpectSaleCount",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              type: "number",
                              disabled: !row.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeRow(
                                  "thisMthExpectSaleCount",
                                  row
                                )
                              },
                            },
                            model: {
                              value: row.thisMthExpectSaleCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  row,
                                  "thisMthExpectSaleCount",
                                  _vm._n($$v)
                                )
                              },
                              expression: "row.thisMthExpectSaleCount",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "nextMthExpectSaleCount",
                      fn: function ({ row }) {
                        return [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              type: "number",
                              disabled: !row.isEdit,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeRow(
                                  "nextMthExpectSaleCount",
                                  row
                                )
                              },
                            },
                            model: {
                              value: row.nextMthExpectSaleCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  row,
                                  "nextMthExpectSaleCount",
                                  _vm._n($$v)
                                )
                              },
                              expression: "row.nextMthExpectSaleCount",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "操作",
                      "min-width": "120px",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: !row.isEdit ? "primary" : "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleTableRowEvent(
                                          "default",
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("默认")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: row.isEdit ? "primary" : "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleTableRowEvent(
                                          "edit",
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分公司产品等级&库存系数导入",
            visible: _vm.ratioAndGradeImportDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.ratioAndGradeImportDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "ratioAndGradeUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.ratioAndGradeImportDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.ratioAndGradeImportDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.ratioAndGradeImportDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("mg-importDialog", {
        attrs: {
          urlParams: _vm.urlParams,
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
        },
        on: { handleImportSuccess: _vm.handleImportSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }