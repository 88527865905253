<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
       <SearchFilter  @search="handleFilter('hand')" @reset="resetQuery"  @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item label="关键字">
          <el-input v-model="queryForm.experienceContent" clearable placeholder="请输入帖子内容关键字" />
        </el-form-item>
        <el-form-item v-if="authOrgOptions.length > 1" label="话题所属分公司">
          <el-select v-model="queryForm.authOrgCode" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户">
          <el-input v-model="queryForm.userName" clearable placeholder="请输入用户编码或姓名" />
        </el-form-item>
        <el-form-item label="帖子状态" >
          <el-select v-model="queryForm.experienceStatus">
            <el-option label="全部" value="ALL" />
            <el-option label="屏蔽" value="shield" />
            <el-option label="加精" value="essence" />
            <el-option label="置顶" value="sticky" />
          </el-select>
        </el-form-item>
        <el-form-item label="帖子类型" >
          <el-select v-model="queryForm.experienceType">
            <el-option label="全部" value="ALL" />
            <el-option label="帖子" value="experience" />
            <el-option label="评论" value="answer" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属话题">
          <el-select v-model="queryForm.topicName" filterable>
            <el-option v-for="item in topicNameOptions" :key="item" :value="item" :label="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="商品类型" >
          <el-select v-model="queryForm.itemType">
            <el-option label="全部" value="ALL" />
            <el-option label="晨光商品" value="1"/>
            <el-option label="非晨光商品" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示到看板" >
          <el-select v-model="queryForm.showInBoard">
            <el-option label="全部" :value="undefined" />
            <el-option label="显示" :value="1"/>
            <el-option label="不显示" :value="0" />
          </el-select>
        </el-form-item>
         <el-form-item label="用户角色" >
           <el-select v-model="queryForm.feedbackUserType">
             <el-option label="全部" :value="undefined" />
             <el-option label="巡店员" :value="1" />
             <el-option label="终端" :value="2"/>
           </el-select>
         </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExperienceListExport">导出</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExperienceListExport">导出</el-button>
      <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openExperienceListImport">商品反馈帖子导入</el-button>

      <el-table
      style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
           size="small"
           class="customer-table"
           border
      v-loading="loading"
      highlight-current-row
      >
        <el-table-column label="帖子内容" prop="experienceContent" width="300" >
          <template slot-scope="{ row }">
            <div style="text-align: left">
              <span>{{row.experienceContent}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="图片/视频" width="500" align="center" >
          <template slot-scope="{ row }">
            <div style="text-align: left">
              <el-image v-for="(item) in row.images" :preview-src-list="[item]" :key="item" :src="item" lazy style="height: 80px;margin: 5px;">
              </el-image>
            </div>
            <div style="text-align: left">
              <video v-for="(item) in row.videos" :key="item" :src="item" width="200px" height="160px" style="margin: 5px" controls >
              </video>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品信息" width="200"  >
          <template slot-scope="{ row }">
            <div style="text-align: left" v-for="(item) in row.modelNames" :key="item">
              <span>商品：{{ item }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="nickName" width="100"  />
        <el-table-column label="分公司" prop="orgAuthName" width="100"  />
        <el-table-column label="所属话题" prop="topicName" width="200"  />
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template #default="{ row }">
            <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.experienceStatus !== 'B'" type="text" style="border: 0px;" @click="handleExperienceShield('B', row)">屏蔽</el-button>
            <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.experienceStatus === 'B'" type="text" style="border: 0px;" @click="handleExperienceShield('Q', row)">已屏蔽</el-button>
            <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.essenceFlag === '1' && row.experienceType === 'experience'" type="text" style="border: 0px;" @click="handleExperienceEssenceFlag(0, row)">已加精</el-button>
            <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.essenceFlag === '0' && row.experienceType === 'experience'" type="text" style="border: 0px;" @click="handleExperienceEssenceFlag(1, row)">加精</el-button>
            <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.stickyFlag === '1' && row.experienceType === 'experience'" type="text" style="border: 0px;" @click="handleExperienceStickyFlag(0, row)">已置顶</el-button>
            <el-button size="mini"  v-if="(row.orgAuthCode==queryForm.authOrgCode || authOrgAll) && row.stickyFlag === '0' && row.experienceType === 'experience'" type="text" style="border: 0px;" @click="handleExperienceStickyFlag(1, row)">置顶</el-button>
            <el-button size="mini"  v-if="(row.showInBoard === 0) " type="text" style="border: 0px;" @click="showExperienceShowInBoard(row)">显示到看板</el-button>
            <el-button size="mini"  v-if="(row.showInBoard === 1) " type="text" style="border: 0px;" @click="disableExperienceShowInBoard(row)">不显示到看板</el-button>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="createTime" width="140"  />
        <el-table-column label="类型" prop="experienceTypeName" width="100"  />
        <el-table-column label="浏览量" prop="viewCount" width="100"  />
        <el-table-column label="评论" prop="answerCount" width="100"  />
        <el-table-column label="点赞" prop="likeCount" width="100"  />
        <el-table-column label="收藏" prop="collectCount" width="100"  />
        <el-table-column label="帖子ID" prop="experienceId" width="230"  />
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </el-card>
    <el-dialog title="商品反馈帖子导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <el-radio-group style="margin-top: 10px" v-model="importDialog.importType">
        <el-radio :label="0">新增</el-radio>
      </el-radio-group>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="importDialog.submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  experienceListExport, experienceListFailedRecordsExport,
  experienceListImport,
  queryExperiencePageList,
  queryTopicTitleList,
  updateExperienceEssenceFlag,
  updateExperienceShield,
  updateExperienceShowInBoard,
  updateExperienceStickyFlag,
} from "@/api/appTask";
import { Notification } from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import {deepClone} from "../../../utils";
export default {
  name: "BoostPacket",
  components: { Pagination, SearchFilter},
  data() {
    return {
      deepClone,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        experienceContent: undefined,
        userName: undefined,
        experienceStatus: 'ALL',
        experienceType: 'ALL',
        topicName: '全部',
        authOrgCode: '',
        itemType:'ALL',
        showInBoard: undefined,
        feedbackUserType: undefined,
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      authOrgOptions: [],
      authOrg: [],
      authOrgCodes: [],
      noticeDialog: {
        visible: false,
        msg: undefined
      },
      jbpShareUrl: '#/questionnaire/detail?id=',
      topicNameOptions: [],
      importDialog: {
        importType: 0,
        submitDisabled: false,
        visible: false,
        type: 'common',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%e5%95%86%e5%93%81%e5%8f%8d%e9%a6%88%e5%b8%96%e5%ad%90%e5%af%bc%e5%85%a5%e6%a8%a1%e6%9d%bf.xlsx'
      },
    };
  },
  watch: {
    "saveDialog.form.orgRange": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
      },
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrg = res.data;
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = this.authOrg.filter(e => e.value!=='ALL');
      if (res.data.length !== this.authOrgOptions.length) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.authOrgCode = this.authOrgOptions[0].value;
      }
      this.handleFilter();
    });

    queryTopicTitleList().then(res => {
      this.topicNameOptions = res.data;
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);
      queryExperiencePageList(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        experienceContent: undefined,
        userName: undefined,
        experienceStatus: 'ALL',
        experienceType: 'ALL',
        topicName: '全部',
        authOrgCode: '',
        itemType:'ALL',
        showInBoard: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleExperienceShield(experienceStatus,row) {
      this.$confirm('请确认是否屏蔽帖子或评论！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          experienceId: row.experienceId,
          experienceStatus: experienceStatus,
          experienceType: row.experienceType,
        }
        updateExperienceShield(parameters).then(res => {
          if (res.code===200) {
            Notification.success("更新屏蔽状态成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleExperienceEssenceFlag(essenceFlag,row) {
      this.$confirm('请确认是否更改加精状态！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          experienceId: row.experienceId,
          essenceFlag: essenceFlag,
        }
        updateExperienceEssenceFlag(parameters).then(res => {
          if (res.code===200) {
            Notification.success("更新加精状态成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleExperienceStickyFlag(stickyFlag,row) {
      this.$confirm('请确认是否更改置顶状态！', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          experienceId: row.experienceId,
          stickyFlag: stickyFlag,
        }
        updateExperienceStickyFlag(parameters).then(res => {
          if (res.code===200) {
            Notification.success("更新置顶状态成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    showExperienceShowInBoard(row) {
      this.$confirm('确认将帖子显示到APP的商品反馈看板中？', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          experienceId: row.realExperienceId,
          showInBoard: 1,
        }
        updateExperienceShowInBoard(parameters).then(res => {
          if (res.code===200) {
            Notification.success("操作成功！");
            // this.handleFilter();
            this.handleQuery();
         }
        })
      })
    },
    disableExperienceShowInBoard(row) {
      this.$confirm('确认取消将帖子显示到APP的商品反馈看板中？', '提示', { type: 'warning' }).then(() => {
        const parameters = {
          experienceId: row.realExperienceId,
          showInBoard: 0,
        }
        updateExperienceShowInBoard(parameters).then(res => {
          if (res.code===200) {
            Notification.success("操作成功！");
            // this.handleFilter();
            this.handleQuery();
          }
        })
      })
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后不会保存当前修改，请确认是否关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleDictChange(val, oldVal) {
    if (val) {
      this.$nextTick(() => {
        if (this.authOrgOptions.length === 0) {
          return;
        }
        if (this.authOrgOptions.length > 2) {
          if (oldVal && oldVal.length > 1) {
            if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
              this.saveDialog.form.orgRange = this.saveDialog.form.orgRange.filter(e => e !== 'ALL');
            } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
              this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
            } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
              this.saveDialog.form.orgRange = [];
            } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
              this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
            }
          } else {
            if (val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
              this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
            } else if (val.indexOf('ALL') < -1 && val.length === this.authOrg.length -1) {
              this.saveDialog.form.orgRange = this.authOrg.map(e => e.value);
            }
          }
        }
      })
    }
  },
    handleExperienceListExport() {
      this.loading = true;
      const param = deepClone(this.queryForm);
      experienceListExport(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '帖子导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    openExperienceListImport() {
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        fd.append("file",item.raw);
      })
      experienceListImport(fd).then(res => {
        if (res.code === 200) {
          Notification.success('批量导入成功！');
        } else {
          experienceListFailedRecordsExport(fd).then(res => {
            this.loading = false;
            if (!res) {
              return
            }
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = window.URL.createObjectURL(new Blob([res]))
            link.setAttribute('download', `商品反馈帖子导入失败记录.xlsx`)
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
        }
        this.$refs.upload.clearFiles()
      })
      setTimeout(() => {this.handleFilter();}, 1000);
      this.importDialog.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   padding-top: 10px;
//   padding-bottom: 5px;
// }

// .common-form {
//   &-row {
//     display: flex !important;
//     flex-wrap: wrap;
//   }
//   div .el-form-item {
//     display: flex !important;
//     ::v-deep {
//       .el-form-item__content {
//         width: 100%;
//         .el-input {
//           width: 100%;
//         }
//         .el-input-number {
//           width: 100%;
//         }
//         .el-select {
//           width: 100%;
//         }
//         .el-date-editor {
//           width: 100%;
//         }
//       }
//     }
//   }
// }

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
