var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress-box" },
    [
      _c(
        "el-row",
        { staticClass: "progress-loading" },
        [
          _vm.title
            ? _c(
                "el-col",
                {
                  staticClass: "signboard-title",
                  class: _vm.titleColor ? "" : "titleColor",
                  style: { color: _vm.titleColor ? _vm.titleColor : "" },
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _c("el-col", { staticClass: "steps-mark" }, [
            _c("span", { staticClass: "steps-mark-title" }, [
              _vm._v("流程状态说明"),
            ]),
            _c(
              "ul",
              { staticClass: "steps-mark-lists" },
              _vm._l(_vm.statusTips, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "steps-mark-item",
                    class: item.className,
                  },
                  [
                    _c("i", { staticClass: "iconfont", class: item.iconName }),
                    _c("span", [_vm._v(_vm._s(item.text))]),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "progress-plan-producting" }, [
        _c(
          "div",
          { staticClass: "producting-item" },
          [
            _c(
              "el-row",
              { staticClass: "sales-item-title producting-item-title" },
              [
                _c("el-col", { staticClass: "signboard-title" }, [
                  _vm._v("销售中心项目"),
                ]),
                _c("el-col", { staticClass: "status" }, [_vm._v("状态")]),
              ],
              1
            ),
            _vm.salesPhaseProject.length > 0
              ? _c(
                  "el-steps",
                  {
                    staticClass: "sales-steps",
                    attrs: { space: 50, direction: "vertical", active: 1 },
                  },
                  _vm._l(_vm.salesPhaseProject, function (item) {
                    return _c("el-step", {
                      key: item.label,
                      staticClass: "iconfont sales-steps-item step-item",
                      class: [
                        item.className,
                        item.isSelect ? "select-step-item" : "",
                      ],
                      attrs: { title: item.label, icon: item.icon },
                    })
                  }),
                  1
                )
              : _c("div", { staticClass: "empty-step sales-empty-step" }, [
                  _c("span", { staticClass: "empty-step-item" }, [
                    _vm._v("当前无项目"),
                  ]),
                  _c("span", { staticClass: "empty-step-status'" }, [
                    _vm._v("空"),
                  ]),
                ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "producting-item" },
          [
            _c(
              "el-row",
              {
                staticClass:
                  "prod-item-title producting-item-title producting-item-prod",
              },
              [
                _c("el-col", { staticClass: "signboard-title" }, [
                  _vm._v("生产中心项目"),
                ]),
                _c("el-col", { staticClass: "status" }, [_vm._v("状态")]),
              ],
              1
            ),
            _vm.prodPhaseProject.length > 0
              ? _c(
                  "el-steps",
                  {
                    staticClass: "producting-steps",
                    attrs: { space: 50, direction: "vertical", active: 1 },
                  },
                  _vm._l(_vm.prodPhaseProject, function (item) {
                    return _c("el-step", {
                      key: item.label,
                      staticClass: "iconfont producting-steps-item step-item",
                      class: [
                        item.className,
                        item.isSelect ? "select-step-item" : "",
                      ],
                      attrs: { title: item.label, icon: item.icon },
                    })
                  }),
                  1
                )
              : _c("div", { staticClass: "empty-step prod-empty-step" }, [
                  _c("span", { staticClass: "empty-step-status'" }, [
                    _vm._v("空"),
                  ]),
                  _c("span", { staticClass: "empty-step-item" }, [
                    _vm._v("当前无项目"),
                  ]),
                ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }