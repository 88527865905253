var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc,shop",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          clearable: _vm.orgAllAuth,
                          init: true,
                          "init-options": this.options.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "dc",
                          value: _vm.queryForm.dcCodes,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          multiple: "",
                          "collapse-tags": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCodes", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          change: function ($event) {
                            _vm.dictParam = Object.assign({}, _vm.queryForm)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否支持无库存下单" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "全部" },
                          model: {
                            value: _vm.queryForm.supportNoStock,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "supportNoStock", $$v)
                            },
                            expression: "queryForm.supportNoStock",
                          },
                        },
                        _vm._l(_vm.options.statusOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品范围" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "全部" },
                          model: {
                            value: _vm.queryForm.itemRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "itemRange", $$v)
                            },
                            expression: "queryForm.itemRange",
                          },
                        },
                        _vm._l(_vm.options.itemRangeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-upload", type: "primary" },
              on: { click: _vm.openImportConfig },
            },
            [_vm._v("开关配置")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-upload", type: "primary" },
              on: { click: _vm.openImportItems },
            },
            [_vm._v("导入商品")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-upload", type: "primary" },
              on: { click: _vm.openBatchConfig },
            },
            [_vm._v("批量设置")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心编码", prop: "dcCode", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.dcCode))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心名称", prop: "dcName", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.dcName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属分公司编码",
                  prop: "orgCode",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.orgCode))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属分公司名称",
                  prop: "orgName",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.orgName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否支持无库存下单",
                  prop: "supportNoStock",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                row.supportNoStock === 1 ? "success" : "info",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.supportNoStock === 1 ? "支持" : "不支持"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品范围", prop: "itemRange", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.supportNoStock === 1
                                ? row.itemRange == 0
                                  ? "全部无库存商品"
                                  : "清单中的无库存商品"
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "SKU数量", prop: "skuCount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.skuCount ?? 0))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "160", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("设置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDcItems(row)
                                  },
                                },
                              },
                              [_vm._v("查看商品")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.editDialog.rules,
                "label-width": "160px",
                model: _vm.editDialog.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分公司",
                            prop: "orgCode",
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.editDialog.form.orgCode +
                                  "-" +
                                  _vm.editDialog.form.orgName
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配送中心",
                            prop: "dcCode",
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.editDialog.form.dcCode +
                                  "-" +
                                  _vm.editDialog.form.dcName
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否支持无库存下单",
                            "label-width": "200px",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.editDialog.form.supportNoStock,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editDialog.form,
                                    "supportNoStock",
                                    $$v
                                  )
                                },
                                expression: "editDialog.form.supportNoStock",
                              },
                            },
                            _vm._l(
                              _vm.options.statusOptionsWithoutAll,
                              function (item) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.editDialog.form.supportNoStock === 1
                ? _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品范围",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.editDialog.form.itemRange,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "itemRange",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.itemRange",
                                  },
                                },
                                _vm._l(
                                  _vm.options.itemRangeOptionsWithoutAll,
                                  function (item) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: item.value,
                                        attrs: { label: item.value },
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitEdit },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量设置",
            visible: _vm.batchConfig.visible,
            "append-to-body": "",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.batchConfig, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                "label-width": "160px",
                model: _vm.batchConfig.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分公司",
                            prop: "orgCode",
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.batchConfig.form.orgCode +
                                  "-" +
                                  _vm.batchConfig.form.orgName
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配送中心",
                            prop: "dcCode",
                            "label-width": "200px",
                            "max-height": "200px",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.batchConfig.form.dcs
                                  .map(
                                    (item) => item.dcCode + "-" + item.dcName
                                  )
                                  .join("，")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否支持无库存下单",
                            "label-width": "200px",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.batchConfig.form.supportNoStock,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.batchConfig.form,
                                    "supportNoStock",
                                    $$v
                                  )
                                },
                                expression: "batchConfig.form.supportNoStock",
                              },
                            },
                            _vm._l(
                              _vm.options.statusOptionsWithoutAll,
                              function (item) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: item.value,
                                    attrs: { label: item.value },
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.batchConfig.form.supportNoStock === 1
                ? _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品范围",
                                "label-width": "200px",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.batchConfig.form.itemRange,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.batchConfig.form,
                                        "itemRange",
                                        $$v
                                      )
                                    },
                                    expression: "batchConfig.form.itemRange",
                                  },
                                },
                                _vm._l(
                                  _vm.options.itemRangeOptionsWithoutAll,
                                  function (item) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: item.value,
                                        attrs: { label: item.value },
                                      },
                                      [_vm._v(_vm._s(item.label))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitBatchEdit },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.batchConfig.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.importDc.importBaseParams.title,
            visible: _vm.importDc.importBaseParams.visible,
            "append-to-body": "",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDc.importBaseParams, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { span: 5, align: "right" },
                },
                [_vm._v("文件：")]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "dcConfigUpload",
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        limit: 1,
                        action: "#",
                        "auto-upload": false,
                        "file-list": _vm.importDc.importBaseParams.fileList,
                        "on-change": (file, fileList) =>
                          _vm.handleDcConfigFileChange(
                            file,
                            fileList,
                            "change"
                          ),
                        "on-remove": (file, fileList) =>
                          _vm.handleDcConfigFileChange(
                            file,
                            fileList,
                            "remove"
                          ),
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                          _c(
                            "el-link",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "vertical-align": "baseline",
                              },
                              attrs: {
                                type: "primary",
                                underline: false,
                                target: "_blank",
                                href: _vm.importDc.importBaseParams
                                  .templateFileDownloadUrl,
                              },
                            },
                            [_vm._v("下载模板")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-col", { attrs: { span: 15, offset: 5, align: "left" } }, [
                _c(
                  "div",
                  { staticClass: "previewTable previewTable-sample" },
                  [
                    _c("mg-signboard", {
                      attrs: { title: "文件上传模板示例" },
                    }),
                    _c(
                      "el-table",
                      {
                        staticClass: "previewTable-lists",
                        attrs: {
                          data: _vm.importDc.importBaseParams
                            .templateSampleLists,
                          stripe: "",
                          border: "",
                          size: "mini",
                        },
                      },
                      [
                        _vm._l(
                          _vm.importDc.importBaseParams.templateSampleHead,
                          function (item, index) {
                            return [
                              _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: {
                                      "show-overflow-tooltip": "",
                                      align: "center",
                                    },
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                )
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px", "margin-bottom": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10, offset: 5, align: "left" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "success" },
                      on: { click: _vm.handleImportDc },
                    },
                    [_vm._v("导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "info" },
                      on: {
                        click: function ($event) {
                          _vm.importDc.importBaseParams.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.importItems.importBaseParams.title,
            visible: _vm.importItems.importBaseParams.visible,
            "append-to-body": "",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.importItems.importBaseParams,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { span: 5, align: "right" },
                },
                [_vm._v("文件：")]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "dcItemUpload",
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        limit: 1,
                        action: "#",
                        "auto-upload": false,
                        "file-list": _vm.importItems.importBaseParams.fileList,
                        "on-change": (file, fileList) =>
                          _vm.handleDcItemFileChange(file, fileList, "change"),
                        "on-remove": (file, fileList) =>
                          _vm.handleDcItemFileChange(file, fileList, "remove"),
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                          _c(
                            "el-link",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "vertical-align": "baseline",
                              },
                              attrs: {
                                type: "primary",
                                underline: false,
                                target: "_blank",
                                href: _vm.importItems.importBaseParams
                                  .templateFileDownloadUrl,
                              },
                            },
                            [_vm._v("下载模板")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-col", { attrs: { span: 15, offset: 5, align: "left" } }, [
                _c(
                  "div",
                  { staticClass: "previewTable previewTable-sample" },
                  [
                    _c("mg-signboard", {
                      attrs: { title: "文件上传模板示例" },
                    }),
                    _c(
                      "el-table",
                      {
                        staticClass: "previewTable-lists",
                        attrs: {
                          data: _vm.importItems.importBaseParams
                            .templateSampleLists,
                          stripe: "",
                          border: "",
                          size: "mini",
                        },
                      },
                      [
                        _vm._l(
                          _vm.importItems.importBaseParams.templateSampleHead,
                          function (item, index) {
                            return [
                              _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: {
                                      "show-overflow-tooltip": "",
                                      align: "center",
                                    },
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                )
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { span: 5, align: "right" },
                },
                [_vm._v("导入方式：")]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.importItems.importBaseParams.importType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.importItems.importBaseParams,
                            "importType",
                            $$v
                          )
                        },
                        expression: "importItems.importBaseParams.importType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增量")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("覆盖")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px", "margin-bottom": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10, offset: 5, align: "left" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "success" },
                      on: { click: _vm.handleImportItems },
                    },
                    [_vm._v("导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "info" },
                      on: {
                        click: function ($event) {
                          _vm.importItems.importBaseParams.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "商品管理",
            visible: _vm.itemsDrawer.visible,
            size: "75%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.itemsDrawer, "visible", $event)
            },
          },
        },
        [
          _c("NoStockOrderDcItems", {
            ref: "shopDetail",
            attrs: {
              param: _vm.itemsDrawer.param,
              timestamp: _vm.itemsDrawer.timestamp,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }