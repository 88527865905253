<template>
    <el-dialog
        title="路线管理"
        :visible.sync="visible"
        width="400px"
        center
        @close="closeDialog"
        >
        <div class="routeManageTableWrap">
            <el-table
                :data="pageData.rows"
                v-loading="loading"
                size="mini"
                border
                highlight-current-row
                :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
            >
                <el-table-column label="路线名" prop="routeName" />
                <el-table-column label="显示名（中文名）" prop="routeCnName" />
                <el-table-column label="操作" align="center">
                <template #default="{ row }">
                    <el-button slot="edit" size="mini" type="text" style="border: 0px; " @click="edit(row)">编辑</el-button>
                    <el-button slot="delete" size="mini" type="text" style="border: 0px; " @click="del(row)">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            title="路线编辑"
            :visible.sync="showEditDialog"
            width="400px"
            center
            append-to-body
            >
            <el-form ref="form" :model="editform" label-width="120px">
                <el-form-item label="路线名">
                    <el-input v-model="editform.routeName"></el-input>
                </el-form-item>
                <el-form-item label="显示名（中文名）">
                    <el-input v-model="editform.routeCnName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog=false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="路线删除"
            :visible.sync="showDelDialog"
            width="400px"
            center
            append-to-body
            >
            <p>该路线下有{{currentItem?.shopCount ?? 0}}个店铺，是否改到其他路线中,如直接删除，该路线的店铺送货路线则为空</p>
            <el-select v-model="delform.routeId" placeholder="请选择" >
                <el-option
                    v-for="item in curRoutes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveDel(false)">直接删除</el-button>
                <el-button type="primary" @click="saveDel(true)">确认更改</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
import { Notification } from 'element-ui';
import { getRouteSelectList, getDcRouteList, editDcRoute, delDcRoute } from "@/api/appTask";

export default {
    props: ["orgCode","dcCode"],
    data() {
        return {
            loading: false,
            visible: false,
            showEditDialog: false,
            showDelDialog: false,
            routes: [],
            curRoutes: [],
            currentItem: {},
            editform: {
                routeName: "",
                routeCnName: ""
            },
            delform: {
                routeId: null,
            },
            pageData: {
                rows: [],
                total: 0
            }
        }
    },
    created() {
        this.visible = true;

        getRouteSelectList(`?size=100&orgCode=${this.orgCode}&dcCode=${this.dcCode}&page=1`).then(res => {
            this.routes = res.data;
            console.log('routes',this.routes)
        })

        this.handleQuery();
    },
    methods: {
        handleQuery() {
            if (!this.orgCode || !this.dcCode) {
                Notification.warning("分公司编码及配送中心编码不可为空");
                return;
            }
            this.loading = true;
            getDcRouteList(this.dcCode).then(res => {
                this.loading = false;
                if (res?.data && res?.data?.length) {
                    this.pageData.rows = res.data;
                    // this.pageData.total = res.data?.total;
                }
            });
        },
        closeDialog() {
            this.visible = false;
            this.$emit("closeRouteManageDialog");
        },
        edit(item) {
            this.currentItem = item;
            this.editform = {
                routeName: item.routeName,
                routeCnName: item.routeCnName
            }
            this.showEditDialog = true;
        },
        del(item) {
            this.currentItem = item;
            this.delform = {
                routeId: null,
            };
            this.curRoutes = this.routes.filter(i => i.value !== item.routeId);
            this.showDelDialog = true;
        },
        saveEdit() {
            const params = {...this.editform, routeId: this.currentItem.routeId, dcCode: this.dcCode };
            console.log('params',params)
            
            this.loading = true;
            editDcRoute(params).then(res => {
                this.loading = false;
                Notification.success('编辑成功')
                this.showEditDialog = false;
                this.handleQuery();
            })
        },
        saveDel(isToOtherRoute) {
            if(isToOtherRoute && !this.delform.routeId) {
                Notification.warning('请选择要切换的送货路线'); return;
            }
            const params = { dcCode: this.dcCode, routeId: this.currentItem.routeId, newRouteId: isToOtherRoute ? this.delform.routeId : null };
            console.log('params',params)
            
            this.loading = true;
            delDcRoute(params).then(res => {
                this.loading = false;
                Notification.success('删除成功')
                this.showDelDialog = false;
                this.handleQuery();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.routeManageTableWrap {
    max-height: 50vh;
    overflow: auto;
}
</style>