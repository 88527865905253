const theme = {
  "mode": "block",
  "template": "red",
  "background": {
    "color": "#EAF2FE",
    "image": "",
    "custom": false,
    template: '',
    skuImage: '',
  },
  "taskName": { "color": "#304E82", text: "" },
  "taskTime": { "color": "#69707F" },
  "moreDataText": { "color": "#3B8DDA" },
  "moreDataBg": { "color": "#EAF2FE" },
  "contentDataBg": { "color": "rgba(255, 255, 255, 0.606)" },
  "label": { "color": "#4B494A" },
  "value": { "color": "#4B494A" },
  "badge": {
    "background": { "color": "#FFF2D6" },
    "font": { "color": "#F9614A" }
  },
  cover: {
    custom: false,
    template: 'blue',
    skuImageType: '',
    background: {
      image: '',
      originSkuImage: '',
      removeBg: true
    }
  }
};

const groupTheme = {
  "mode": "block",
  "template": "red",
  "background": {
    "color": "#EAF2FE",
    "image": "",
    "custom": false,
    template: '',
    skuImage: '',
  },
  "taskName": { "color": "#304E82", text: "" },
  "taskTime": { "color": "#69707F" },
  "moreDataText": { "color": "#3B8DDA" },
  "moreDataBg": { "color": "#EAF2FE" },
  "contentDataBg": { "color": "rgba(255, 255, 255, 0.606)" },
  "label": { "color": "#4B494A" },
  "value": { "color": "#4B494A" },
  "badge": {
    "background": { "color": "#FFF2D6" },
    "font": { "color": "#F9614A" }
  },
  cover: {
    custom: false,
    template: 'blue',
    skuImageType: '',
    background: {
      image: '',
      originSkuImage: '',
      removeBg: true
    }
  }
}

const orangeTheme = {
  "mode": "block",
  "template": "red",
  "background": {
    "color": "#EAF2FE",
    "image": "",
    "custom": false,
    template: '',
    skuImage: '',
  },
  "taskName": { "color": "#304E82", text: "" },
  "taskTime": { "color": "#69707F" },
  "moreDataText": { "color": "#3B8DDA" },
  "moreDataBg": { "color": "#EAF2FE" },
  "contentDataBg": { "color": "rgba(255, 255, 255, 0.606)" },
  "label": { "color": "#4B494A" },
  "value": { "color": "#4B494A" },
  "badge": {
    "background": { "color": "#FFF2D6" },
    "font": { "color": "#F9614A" }
  },
  cover: {
    custom: false,
    template: 'blue',
    skuImageType: '',
    background: {
      image: '',
      originSkuImage: '',
      removeBg: true
    }
  }
};

const horizontalTheme = {
  "mode": "block",
  "template": "red",
  "background": {
    "color": "#EAF2FE",
    "image": "",
    "custom": false,
    template: '',
    skuImage: '',
  },
  "taskName": { "color": "#304E82", text: "" },
  "taskTime": { "color": "#69707F" },
  "moreDataText": { "color": "#3B8DDA" },
  "moreDataBg": { "color": "#EAF2FE" },
  "contentDataBg": { "color": "rgba(255, 255, 255, 0.606)" },
  "label": { "color": "#4B494A" },
  "value": { "color": "#4B494A" },
  "badge": {
    "background": { "color": "#FFF2D6" },
    "font": { "color": "#F9614A" }
  },
  cover: {
    custom: false,
    template: 'blue',
    skuImageType: '',
    background: {
      image: '',
      originSkuImage: '',
      removeBg: true
    }
  }
}

const blockTheme = {
  "mode": "block",
  "template": "red",
  "background": {
    "color": "#EAF2FE",
    "image": "",
    "custom": false,
    template: '',
    skuImage: '',
  },
  "taskName": { "color": "#304E82", text: "" },
  "taskTime": { "color": "#69707F" },
  "moreDataText": { "color": "#3B8DDA" },
  "moreDataBg": { "color": "#EAF2FE" },
  "contentDataBg": { "color": "rgba(255, 255, 255, 0.606)" },
  "label": { "color": "#4B494A" },
  "value": { "color": "#4B494A" },
  "badge": {
    "background": { "color": "#FFF2D6" },
    "font": { "color": "#F9614A" }
  },
  cover: {
    custom: false,
    template: 'blue',
    skuImageType: '',
    background: {
      image: '',
      originSkuImage: '',
      removeBg: true
    }
  }
}
const page = {
  "title": "",
  "template": "red",
  "customHeadImage": false,
  "background": {
    "image": "",
    "color": "#F9655C"
  }
};
const homePageFields = [
  {key:'brJhTotal', title: '本日进货额', "label":{ "color":'#FA675D'},"value":{"color":"#FA675D"},"prefix":null, type: 'amount' },
  {key:'dqJhTotal', title: '活动进货金额', "label":{ "color":'#FA675D'},"value":{"color":"#FA675D"},"prefix":null, type: 'amount' },
  { key:'byJhTotal', title:  '本月进货额', "label":{ "color":'#FA675D'},"value":{"color":"#FA675D"},"prefix":null, type: 'amount' },
  {  key:'jhShopCount', title:  '进货店铺数', "label":{ "color":'#FA675D'},"value":{"color":"#FA675D"},"prefix":null, type: 'number' },
  {  key:'boostBonus', title:  '预计业务激励', "label":{ "color":'#FF2435'},"value":{"color":"#FF2435"},"prefix":'¥', type: 'number' },
];

// const groupPageFields  =

const jeFields = [
  {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  {key:'jhTotalRate', title:  '进货额达成率', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'rate' },
  {key:'jhTotalDayAvg', title: '⽇均进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
];
const sgSummaryFields = [
  {key:'jhKss', title: '重点店均上柜', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
  {key:' jhKssTarget', title: '上柜款数⽬标', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number' },
];
const xzSummaryFields = [
  {key:'jhKssDiff', title: '重点店均新增', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
  {key:'jhKssDiffTarget', title: '新增款数⽬标', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number' },
];
const sgFields = [
  {key:'jhKss', title: '上柜款数', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
  {key:' jhKssTarget', title: '上柜款数⽬标', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number' },
];
const xzFields = [
  {key:'jhKssDiff', title: '新增款数', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
  {key:'jhKssDiffTarget', title: '新增款数⽬标', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number' },
];
const dpFields = [
  {key:'jhShopCount', title: '进货店铺数', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number' },
  {key:'dcShopCount', title: '达成目标店铺数', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number' },
]
const jlFields = [
  { key:'boostBonus', title: '预计业务激励', "label":{ "color":'#FF2435'},"value":{"color":"#FF2435"},"prefix":'¥', type: 'number' }
]
const summaryFields = [...jeFields, ...sgSummaryFields, ...xzSummaryFields, ...dpFields, ...jlFields];

const shopFields = [...jeFields, ...sgFields, ...xzFields, ...dpFields, ...jlFields];
const summaryFieldMap = {
  "进货金额": jeFields,
  "上柜款数": sgSummaryFields,
  "新增款数": xzSummaryFields,
  "店铺完成情况": dpFields,
  "业务奖励": jlFields,
}
const shopFieldMap = {
  "进货金额": jeFields,
  "上柜款数": sgFields,
  "新增款数": xzFields,
  "业务奖励": jlFields,
}

const jlSummaryFields = [
  {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  {key:'jhKss', title: '重点店均上柜', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
]

const defaultSummaryFields = [
  {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  {key:'jhKss', title: '重点店均上柜', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
]

const defaultShopFields = [
  {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  { key:'jhKss', title: '上柜款数', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
]

const jlShopFields = [
  {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  { key:'jhKss', title: '上柜款数', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
]

const groupSummaryFields = [
  {key:'brJhTotal', title: '本日进货额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  {key:'dqJhTotal', title: '活动进货金额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
]

const groupShopFields = [
  {key:'brJhTotal', title: '本日进货额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
  {key:'dqJhTotal', title: '活动进货金额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
]
const defaultTaskJson = {
  ...{theme: blockTheme},
  page,
  ...{ summary: defaultSummaryFields },
  ...{ shop: defaultShopFields },
}

const jlTaskJson = {
   ...{theme: blockTheme},
  page,
  ...{ summary: jlSummaryFields },
  ...{ shop: jlShopFields },
}

const jlHorizontalTaskJson = {
  ...{theme: horizontalTheme},
  page,
  ...{ summary: [
      {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
      {key:'boostBonus', title: '预计业务激励', "label":{ "color":'#FF2435'},"value":{"color":"#FF2435"},"prefix":'¥', type: 'number' },
    ] },
  ...{ shop: [
      {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
      {key:'boostBonus', title: '预计业务激励', "label":{ "color":'#FF2435'},"value":{"color":"#FF2435"},"prefix":'¥', type: 'number' },
    ] },
}

const horizontalTaskJson ={
    ...{theme: horizontalTheme},
  page,
  ...{ summary: [
      {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
      { key:'jhKss', title: '重点店均上柜', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
    ] },
  ...{ shop: [
      {key:'jhTotal', title: '进货⾦额', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'amount' },
      {key:'jhKss', title: '重点店均上柜', "label":{ "color":'#4B494A'},"value":{"color":"#4B494A"},"prefix":null, type: 'number', unit: '款' },
    ] },
}

const groupTaskJson = {
  ...{theme: groupTheme},
  page,
  ...{ summary: groupSummaryFields },
  ...{ shop: groupShopFields },
}

export {
  homePageFields,
  summaryFields,
  shopFields,
  jlSummaryFields,
  jlShopFields,
  theme,
  page,
  defaultTaskJson,
  jlTaskJson,
  groupTaskJson,
  summaryFieldMap,
  shopFieldMap,
  jlHorizontalTaskJson,
  horizontalTaskJson,
  orangeTheme,
  groupTheme,
  horizontalTheme,
  blockTheme
}
