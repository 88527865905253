<template>
  <div>
    <div>
      <p>表达式</p>
      <div style="margin-left: 15px;display: table; width: 100%; min-height: 40px; width: 100%">
        <p style="display: table-cell; vertical-align: middle;font-size: 14px;" class="border-div" v-html="conditionShow"></p>
      </div>
    </div>
    <div style="display: inline-block; margin-top: 10px;">
      <span style="margin-right: 50px;">条件组合方式</span>
      <el-radio-group :disabled="actionType==='view'" v-model="form.conditionGroupType">
        <el-radio v-for="item in options.conditionGroupTypes" :label="item.label" :key="item.value" />
      </el-radio-group>
    </div>
    <el-form :form="form" size="mini" :disabled="actionType==='view'" style="margin-left: 10px">
      <el-form-item v-for="(item, groupIndex) in form.conditionGroups" :key="groupIndex" :label="'条件组' + (groupIndex + 1)"  class="border-div">
        <el-button v-if="actionType!=='view'" type="text" style="float: right" @click="handleRemove(groupIndex)">删除</el-button>
        <div v-for="(ele,index) in item.conditions" :key="index" style="width: 100%; display: -webkit-box; margin-left: 20px;">
          <el-select v-if="index > 0" v-model="item.conditionType" class="small-width">
            <el-option v-for="e in options.conditionTypes" :key="e.value" :value="e.value" :label="e.label" />
          </el-select>
          <span v-else style="margin-left: 50px;"></span>
          <!--          <el-input v-if="ele.tagValType === 'group'" v-model="ele.tagName" disabled />-->
          <el-select v-model="ele.tagName" filterable @change="val => handleTagChange(val, groupIndex, index)">
            <el-option v-for="e in options.tags" :key="e.tagId" :value="e.tagName" :label="e.tagName" :disabled="e.disabled" />
          </el-select>
          <div v-if="ele.tagName" style="width: 80px">
            <el-select v-if="ele.tagValType==='bigint' || ele.tagValType==='decimal'" v-model="ele.compareType" style="text-align: center">
              <el-option v-for="e in options.numberCompareSymbols"  :key="e" :value="e" :label="e" />
              <el-option value="区间" label="区间" />
            </el-select>
            <el-select v-if="ele.tagValType==='boolean'" v-model="ele.compareType" style="text-align: center">
              <el-option v-for="e in options.booleanCompareSymbols"  :key="e" :value="e" :label="e" />
            </el-select>
            <el-select v-if="ele.tagValType==='string'" v-model="ele.compareType" style="text-align: center" @change="val => handleCompareChange(val, ele.tagName, groupIndex, index)">
              <el-option v-for="e in options.strCompareSymbols"  :key="e" :value="e" :label="e" />
            </el-select>
          </div>
          <template v-if="ele.compareType && notShowTypes.indexOf(ele.compareType) < 0">
            <el-input-number v-if="(ele.tagValType==='bigint' || ele.tagValType==='decimal') && ele.compareType !=='区间'" v-model="ele.compareValue" :controls="false" :precision="2" />
            <el-select v-if="(ele.tagValType==='bigint' || ele.tagValType==='decimal') && ele.compareType ==='区间'" v-model="ele.leftCompareSymbol" style="width: 80px">
              <el-option v-for="e in options.leftNumberCompareSymbols"  :key="e" :value="e" :label="e" />
            </el-select>
            <el-input-number v-if="(ele.tagValType==='bigint' || ele.tagValType==='decimal') && ele.compareType ==='区间'" v-model="ele.leftCompareValue" :controls="false" :precision="2" />

            <el-select v-if="(ele.tagValType==='bigint' || ele.tagValType==='decimal') && ele.compareType ==='区间'" v-model="ele.rightCompareSymbol" style="width: 80px">
              <el-option v-for="e in options.rightNumberCompareSymbols"  :key="e" :value="e" :label="e" />
            </el-select>
            <el-input-number v-if="(ele.tagValType==='bigint' || ele.tagValType==='decimal') && ele.compareType ==='区间'" v-model="ele.rightCompareValue" :controls="false" :precision="2" />

            <el-select v-if="ele.tagValType==='boolean'" v-model="ele.compareValue" >
              <el-option v-for="e in options.booleanCompareSymbols"  :key="e.value" :value="e.value" :label="e.label" />
            </el-select>
            <el-select v-if="ele.tagValType==='string' && options[groupIndex] && options[groupIndex][index]!==undefined &&
            options[groupIndex][index].length > 0 && compareMultipleOptions.indexOf(ele.compareType) > -1" v-model="ele.compareValue" multiple >
              <el-option v-for="e in options[groupIndex][index]"  :key="e.tagVal" :value="e.tagVal" :label="e.tagVal" />
            </el-select>
            <el-input v-if="ele.tagValType==='string' && options[groupIndex] && options[groupIndex][index]!==undefined &&
            options[groupIndex][index].length === 0 && compareMultipleOptions.indexOf(ele.compareType) > -1" type="textarea" style="width: 60%;" v-model="ele.compareValue" />
            <el-input v-if="ele.tagValType==='string' && compareMultipleOptions.indexOf(ele.compareType) < 0" v-model="ele.compareValue" />
          </template>
          <el-button v-if="actionType!=='view'" type="text" style="float: none;margin-left: 10px" @click="handleTagExample(ele.tagId)">标签示例</el-button>
          <el-button v-if="actionType!=='view'" type="text" style="float: none" @click="handleRemove(groupIndex, index)">删除</el-button>
<!--          <el-button v-if="actionType!=='view'" type="text" style="float: right" @click="handleRemove(groupIndex, index)">删除</el-button>-->
        </div>
        <el-button v-if="actionType!=='view'" type="text" plain icon="plus" size="mini" class="buttonAddClass" style="width: 100px" @click="handleAddCondition(groupIndex)">添加标签</el-button>
      </el-form-item>
      <el-form-item label="排除分组" class="border-div" style="margin-top: 10px">
        <el-form-item class="full-width" style="display: block">
          <el-select v-model="form.exceptGroups" multiple remote filterable placeholder="请输入关键字" :remote-method="handleQueryGroup" @change="handleExceptGroupChange">
            <el-option v-for="item in options.group" :key="item.ruleGroupId" :value="item.ruleGroupId" :label="item.ruleGroupName" />
          </el-select>
        </el-form-item>
      </el-form-item>
      <el-form-item>
        <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;">
          <el-button type="primary" plain icon="plus" size="mini" class="buttonAddClass" @click="handleAddGroup">添加条件</el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog title="" :visible.sync="dialogTagExampleVisible" append-to-body="true" width="800px" top="10vh">
      <div style="height: 500px">
        <el-form :model="tagExample">
          <el-form-item label="标签名称:">
            <span>{{tagExample.tagName}}</span>
          </el-form-item>
          <el-form-item v-if="tagExample.exhaustivityFlag==='N'" label="标签定义:">
            <span>{{tagExample.tagValDetailDtos[0].tagDefine}}</span>
          </el-form-item>
          <el-form-item v-if="tagExample.exhaustivityFlag==='N'" label="举例说明:">
            <span>{{tagExample.tagValDetailDtos[0].tagVal}}</span>
          </el-form-item>
          <el-form-item v-if="tagExample.exhaustivityFlag==='N' && tagExample.tagValType==='string'" label="操作说明:">
            <span>多内容使用英文逗号隔开</span>
          </el-form-item>
          <el-form-item v-if="tagExample.exhaustivityFlag==='Y'" label="标签详情:">
            <el-table :data="tagExample.tagValDetailDtos" height="400px">
              <el-table-column property="tagVal" label="标签值" width="200"></el-table-column>
              <el-table-column property="tagDefine" label="标签定义" width="550"></el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {deepClone} from "@/utils";
import {getTagList, getGroupList, getTagDetail, getSpecialTagDetail, getTagExample} from "@/api/tagPlatform";

export default {
  name: "StepTwo",
  props: {
    formTwo: {
      type: Object,
      default() {
        return {
          conditionGroupType: '交集',
          conditionGroups: [],
          exceptGroups: [],
          checkedGroupObjs: [],
        }
      }
    },
    actionType: {
      type: String,
      required: true,
    }
  },
  computed: {
    conditionShow() {
      let show = [];
      if (this.form.conditionGroups.length > 0) {
        this.form.conditionGroups.forEach(e => {
          if (e.conditions.length > 0) {
            let temp = [];
            e.conditions.forEach(ele => {
              if (ele.tagName && ele.compareType) {
                if (ele.compareType === '区间' && ele.leftCompareSymbol && ele.leftCompareValue!==undefined && ele.rightCompareSymbol && ele.rightCompareValue!==undefined) {
                  temp.push(ele.leftCompareValue + ' <strong> ' + ele.leftCompareSymbol  + ' </strong> ' + ele.tagName  + ' <strong> ' + ele.rightCompareSymbol  + ' </strong> ' + ele.rightCompareValue)
                } else if (this.notShowTypes.indexOf(ele.compareType) > -1) {
                  temp.push(ele.tagName  + ' <strong> ' + ele.compareType + ' </strong> ')
                } else if (ele.compareValue !== undefined) {
                  temp.push(ele.tagName  + ' <strong> ' + ele.compareType  + ' </strong> ' + ele.compareValue)
                }
              }
            })
            if (temp.length > 0) {
              show.push(temp.length > 1 ? ' (' + temp.join(' ' + e.conditionType + ' ') + ') ': temp.join(' '));
            }
          }
        })
      }
      if (this.form.exceptGroups.length > 0) {
        show.push('非 "' + this.form.checkedGroupObjs.map(e => e.ruleGroupName).join('、') + '" 中用户')
      }
      let showExpression = '';
      for (let i = 0; i < show.length; i++) {
        if (i === show.length - 1) {
          showExpression+= ' ' + show[i];
        } else {
          showExpression+= ' ' + show[i] + ' <font style="color: dodgerblue"> '+ this.options.conditionShow[this.form.conditionGroupType] +' </font>';
        }
      }
      return showExpression;
    }
  },
  watch: {
    formTwo:{
      handler: function (newVal) {
        this.refreshAllSelect(newVal);
        this.form = newVal;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      form: this.formTwo,
      defaultConditionGroup: {
        conditionName: undefined,
        conditionType: '且',
        conditions: [
        ]
      },
      defaultCondition:{
        tagId: undefined,
        tagName: undefined,
        tagValType: undefined,
        compareType: undefined,
        compareValue: undefined,
        leftCompareValue: undefined,
        rightCompareValue: undefined,
        leftCompareSymbol: undefined,
        rightCompareSymbol: undefined,
      },
      options: {
        conditionGroupTypes: [{ value: '1', label: '交集'}, { value: '2', label: '并集'},{ value: '3', label: '差集'},],
        conditionTypes: [{ value: '且', label: '且'}, { value: '或', label: '或'},{ value: '非', label: '非'},],
        tags: [],
        leftNumberCompareSymbols: ['>', '>='],
        rightNumberCompareSymbols: ['<=', '<'],
        numberCompareSymbols: ['>', '>=', '=', '<=', '<'],
        strCompareSymbols: ['包含', '不包含', '为空', '不为空'],
        booleanCompareSymbols: [{ label: '是', value: 1},{ label: '否', value: 0},],
        group: this.formTwo.checkedGroupObjs,
        conditionShow: {'交集': "and" , '并集': 'or', '差集': 'not in'}
      },
      notShowTypes: ['为空', '不为空'],
      compareMultipleOptions: ['包含', '不包含'],
      dialogTagExampleVisible: false,
      tagExample:{
        tagName: undefined,
        exhaustivityFlag: undefined,
        tagValDetailDtos: [],
        tagValType: undefined,
      }
    }
  },
  created() {
    getTagList({ status: 1, limit: 1000 }).then(res => {
      if (res.data && res.code===200) {
        res.data.datas.forEach(e => {
          e.disabled = e.tagStatus!=='启用'? true: false;
          return e;
        })
        this.options.tags = res.data.datas;
      }
    })
  },
  mounted() {
    if (this.actionType === 'add') {
      this.$nextTick(() => {
        this.handleAddGroup();
        this.handleAddCondition(0);
      })
    }
  },
  methods: {
    handleRemove(groupIndex, removeIndex) {
      if (removeIndex !== undefined) {
        if (removeIndex === 0) {
          this.form.conditionGroups[groupIndex].conditions = this.form.conditionGroups[groupIndex].conditions.slice(1);
        } else {
          this.form.conditionGroups[groupIndex].conditions.splice(removeIndex, 1);
        }
      } else {
        if (groupIndex === 0) {
          this.form.conditionGroups = this.form.conditionGroups.slice(1);
        } else {
          this.form.conditionGroups.splice(groupIndex, 1);
        }
      }
    },
    handleAddGroup() {
      this.form.conditionGroups.push(deepClone(this.defaultConditionGroup));
      this.options[this.defaultConditionGroup.length - 1] = {};
    },
    handleAddCondition(groupIndex) {
      this.form.conditionGroups[groupIndex].conditions.push(deepClone(this.defaultCondition));
      if (!this.options[groupIndex]) {
        this.options[groupIndex] = {};
      }
      this.options[groupIndex][this.form.conditionGroups[groupIndex].conditions.length - 1] = [];
    },
    handleTagChange(val, groupIndex, index) {
      const tag = this.options.tags.find(e => e.tagName === val);
      this.form.conditionGroups[groupIndex].conditions[index] = Object.assign(this.form.conditionGroups[groupIndex].conditions[index], {
        tagId: undefined,
        tagValType: undefined,
        compareType: undefined,
        compareValue: undefined,
        leftCompareValue: undefined,
        rightCompareValue: undefined,
        leftCompareSymbol: undefined,
        rightCompareSymbol: undefined,
      });
      if (val) {
        this.form.conditionGroups[groupIndex].conditions[index].tagId = tag.tagId;
        this.form.conditionGroups[groupIndex].conditions[index].tagValType = tag.tagValType;
      }
    },
    handleQueryGroup(val) {
      this.options.group = [];
      getGroupList({ ruleGroupName: val }).then(res => {
        if (res.data && res.code===200) {
          this.options.group = res.data;
        }
      })
    },
    handleExceptGroupChange(val) {
      if (val && val.length > 0) {
        const tempArray = this.form.checkedGroupObjs.concat(this.options.group.filter(e => val.indexOf(e.ruleGroupId) > -1)).filter(e => val.indexOf(e.ruleGroupId) > -1);
        var temp = {};   //用于判断重复
        var result = [];  //最后的新数组

        tempArray.map(item => {
          if(!temp[item.ruleGroupId]){
            result.push(item);
            temp[item.ruleGroupId] = true;
          }
        });
        this.form.checkedGroupObjs = result;
      }
    },
    refreshAllSelect(val) {
      val.conditionGroups.forEach((ele, index) => {
        ele.conditions.forEach((e, i) => {
          this.handleCompareChange(e.compareType, e.tagName, index, i);
        })
      })
    },
    async handleCompareChange(val, tagName, groupIndex, index) {
      if (this.compareMultipleOptions.indexOf(val) > -1) {
        if (this.options.tags.length === 0) {
          await getTagList({ status: 1, limit: 1000 }).then( async res => {
            if (res.data && res.code===200) {
              this.options.tags = res.data.datas;
            }
          })
        }
        const id = this.options.tags.find(e => e.tagName === tagName).id;
        getSpecialTagDetail(id).then(res => {
          if (!this.options[groupIndex]) {
            this.options[groupIndex] = {};
          }
          this.options[groupIndex][index] = res.data.tagDefinitions.filter(e => e.tagVal && e.tagVal!=='');
          this.$forceUpdate();
        })
      }
    },
    handleTagExample(tagId) {
      console.log("tagName===>>>" + tagId);
      getTagExample(tagId).then(res => {
        if (res && res.code === 200) {
          this.tagExample.tagName = res.data.tagName;
          this.tagExample.exhaustivityFlag = res.data.exhaustivityFlag;
          this.tagExample.tagValDetailDtos = res.data.tagValDetailDtos;
          this.tagExample.tagValType = res.data.tagValType;
          this.dialogTagExampleVisible = true;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.border-div {
  border: 1px solid rgb(231 224 224);
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.buttonAddClass {
  border: 1px dashed;
  width: 25%;
  margin-right: 30px;
}

.small-width {
  width: 50px;
  .el-input .el-input__inner {
    max-width: 100% !important;
  }
  .el-input__inner {
    max-width: 100% !important;
  }
  .el-date-editor .el-input__inner {
    max-width: 100% !important;
  }
}

</style>
