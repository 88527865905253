var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container raceActivityReport-container" },
    [
      _c(
        "div",
        { staticClass: "raceActivityReport-container-body" },
        [
          _c(
            "el-row",
            { staticClass: "mt10" },
            [
              _c(
                "el-col",
                { attrs: { span: 8, offset: 4 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryFormLeft",
                      attrs: {
                        model: _vm.queryParams,
                        size: "small",
                        "label-width": "110px",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "批发出货日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyyMMdd",
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.queryParams.pfDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "pfDate", $$v)
                              },
                              expression: "queryParams.pfDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "一配出货日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyyMMdd",
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.queryParams.dc1ChDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "dc1ChDate", $$v)
                              },
                              expression: "queryParams.dc1ChDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "总部出货日期" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyyMMdd",
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.queryParams.hqChDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "hqChDate", $$v)
                              },
                              expression: "queryParams.hqChDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryFormRight",
                      attrs: {
                        model: _vm.queryParams,
                        size: "small",
                        "label-width": "110px",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动名称" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.queryParams.activityName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "activityName", $$v)
                                },
                                expression: "queryParams.activityName",
                              },
                            },
                            _vm._l(_vm.options.activityName, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                multiple: "",
                                "value-key": "code",
                              },
                              on: { change: _vm.orgsChange },
                              model: {
                                value: _vm.queryParams.orgs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "orgs", $$v)
                                },
                                expression: "queryParams.orgs",
                              },
                            },
                            _vm._l(_vm.options.orgs, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  label: item.name + "[" + item.code + "]",
                                  value: item,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "伙伴" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                clearable: "",
                                multiple: "",
                                disabled: _vm.dcsDisabled,
                                "value-key": "dcCode",
                              },
                              model: {
                                value: _vm.queryParams.dcs,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "dcs", $$v)
                                },
                                expression: "queryParams.dcs",
                              },
                            },
                            _vm._l(_vm.options.dcs, function (item) {
                              return _c("el-option", {
                                key: item.dcCode,
                                attrs: {
                                  label:
                                    item.dcName != "大客户"
                                      ? item.dcName + "[" + item.dcCode + "]"
                                      : item.dcName,
                                  value: item,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "Search", size: "mini" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "Refresh",
                        size: "mini",
                        loading: _vm.downloadLoading,
                      },
                      on: { click: _vm.download },
                    },
                    [_vm._v("下载")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "reportTable",
              attrs: { border: "", size: "mini", data: _vm.reportList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.queryParams.page - 1) *
                                _vm.queryParams.limit +
                                scope.$index +
                                1
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "活动名称",
                  align: "center",
                  prop: "activityName",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "截止日期",
                  align: "center",
                  prop: "jzrq",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "查询起止日期",
                  align: "center",
                  prop: "cxqzrq",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "产品编码",
                  align: "center",
                  prop: "skuCode",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: "center",
                  prop: "skuName",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司CODE",
                  align: "center",
                  prop: "boOrgCode",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司名称",
                  align: "center",
                  prop: "boOrgName",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "零售价",
                  align: "center",
                  prop: "priceRetail",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "总部出货数量",
                  align: "center",
                  prop: "hqChNm",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "总部出货金额",
                  align: "center",
                  prop: "hqChAmout",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "二三配进货数量",
                  align: "center",
                  prop: "boChTodc23Nm",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "二三配进货金额",
                  align: "center",
                  prop: "boChTodc23Amout",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "一配出货数量",
                  align: "center",
                  prop: "dc1ChNm",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "一配出货金额",
                  align: "center",
                  prop: "dc1ChAmout",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "大客户进货数量",
                  align: "center",
                  prop: "boChTooNm",
                  "min-width": "120px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "大客户进货金额",
                  align: "center",
                  prop: "boChTooAmout",
                  "min-width": "120px",
                },
              }),
            ],
            1
          ),
          _c("mg-pagination", {
            attrs: {
              total: _vm.total,
              pageSize: _vm.queryParams.limit,
              currentPage: _vm.queryParams.page,
            },
            on: { pageChange: _vm.pagingChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }