<template>
  <el-form ref="groupForm" size="mini" label-width="120px" :inline="true" :model="form" :rules="rules" class="common-form">
    <el-form-item label="合集名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="请输入合集名称" />
    </el-form-item>
    <el-form-item label="选择模板" prop="template">
      <el-radio-group v-model="dataForm.template">
<!--        <el-radio label="blue" value="blue">模板1<img :src="viewShowTemplateUrl['blue']" style="height: 80px; display: block;" /></el-radio>-->
        <el-radio label="red" value="red">模板1<img :src="viewShowTemplateUrl['red']" style="height: 80px; display: block;" /></el-radio>
        <el-radio label="orange" value="orange">模板2<img :src="viewShowTemplateUrl['orange']" style="height: 80px; display: block;" /></el-radio>
        <el-radio label="block" value="block">自定义
          <OssUpload ref="ossUpload" :value-to.sync="dataForm.custom" :maxFileSize="1024" list-type="picture-card" style="display: table"
                     :limit="1" :always-show-button="false" tips="尺寸495*620，小于</br>1M底部200高度无</br>防止重要内容，避</br>免遮挡数据。" :tipMessageShow="false"
                     :accept-type="imgTypes" />
        </el-radio>
      </el-radio-group>
    </el-form-item>
<!--    <el-form-item label="展示数据" prop="fields">-->
<!--      <el-checkbox-group v-model="dataForm.fields" :max="2">-->
<!--        <el-checkbox v-for="(item,key) in homePageFields" :disabled="item.title==='预计业务激励' && (saveTitle==='add' || !dataForm.boostTask)" :value="item.key" :label="item.key" :key="key">{{item.title}}</el-checkbox>-->
<!--      </el-checkbox-group>-->
<!--    </el-form-item>-->
    <el-form-item label="数据颜色" prop="color">
      <div style="display: inline-flex">
        <div style="display: flex">
          <span style="display: inline-flex">数据文字颜色</span><el-color-picker v-model="dataForm.fontColor" />
        </div>
        <div style="display: flex">
          <span style="display: inline-flex">任务合集角标底色</span><el-color-picker v-model="dataForm.badgeColor" />
        </div>
        <div style="display: flex">
          <span style="display: inline-flex">任务合集角标文字</span><el-color-picker v-model="dataForm.badgeFontColor" />
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { homePageFields } from '../../../config';
import OssUpload from "@/components/OssUpload";

export default {
  name: "GroupHome",
  components: { OssUpload },
  props: {
    saveTitle: {
      type: String,
      default: "add"
    },
    form: {
      type: Object,
      required: true,
      default() {
        return {
          name: undefined,
          custom: '',
          template: 'red',
          fields: ['brJhTotal', 'byJhTotal'],
          fontColor: undefined,
          badgeFontColor: undefined,
          badgeColor: undefined,
          boostTask: undefined,
        }
      }
    }
  },
  watch: {
    form: {
      handler(nV, oV) {
        this.dataForm = nV;
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      dataForm: this.form,
      viewShowTemplateUrl: {
        red: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E5%B0%81%E9%9D%A2%E7%A4%BA%E6%84%8F.png',
        blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E5%90%88%E9%9B%86-%E7%B4%AB%E8%89%B2.png',
        orange: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E6%A9%99%E8%89%B2%E5%B0%81%E9%9D%A2.png'
      },
      homePageFields,
      imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
      rules: {
        name: [{required: true, message: '  ', trigger: 'blur'}],
        template: [{required: true, message:' ', trigger: 'blur'}],
        fields: [{type: 'array',  required: true, message:' ', trigger: 'blur'}],
      }
    }
  },
  methods: {
    formValid() {
      return new Promise((resolve) => {
        this.$refs.groupForm.validate(validate => {
          resolve(validate);
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
