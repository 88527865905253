var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.type === "0"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a'",
              display: "grid",
              "min-height": "40px",
              gap: "10px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [_c("el-divider", [_vm._v(_vm._s(_vm.title))])],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "1"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a b'",
              display: "grid",
              "min-height": "120px",
              gap: "10px",
              "grid-template-columns": "1fr 1fr",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a", width: "100%" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "grid-area": "b", width: "100%" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[1],
                    type: _vm.computeType(1),
                    index: 1,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 1, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "2"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a b' 'a c'",
              display: "grid",
              "min-height": "250px",
              gap: "10px",
              "grid-template-rows": "1fr 1fr",
              "grid-template-columns": "1fr 1fr",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "grid-area": "b" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[1],
                    type: _vm.computeType(1),
                    index: 1,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 1, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "grid-area": "c" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[2],
                    type: _vm.computeType(2),
                    index: 2,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 2, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "3"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a b'",
              display: "grid",
              "min-height": "250px",
              gap: "10px",
              "grid-template-columns": "1fr 1fr",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "grid-area": "b" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[1],
                    type: _vm.computeType(1),
                    index: 1,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 1, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "4"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a b c'",
              display: "grid",
              "min-height": "165px",
              gap: "10px",
              "grid-template-columns": "1fr 1fr 1fr",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "grid-area": "b" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[1],
                    type: _vm.computeType(1),
                    index: 1,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 1, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "grid-area": "c" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[2],
                    type: _vm.computeType(2),
                    index: 2,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 2, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "5"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a'",
              display: "grid",
              "min-height": "80px",
              gap: "10px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "6"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a'",
              display: "grid",
              "min-height": "80px",
              gap: "10px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
    _vm.type === "7"
      ? _c(
          "div",
          {
            staticStyle: {
              "grid-template-areas": "'a'",
              display: "grid",
              "min-height": "80px",
              gap: "10px",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "grid-area": "a" } },
              [
                _c("AddTask", {
                  attrs: {
                    shopAll: _vm.shopAll,
                    showType: _vm.showType,
                    currentTask: _vm.currentTaskId,
                    taskId: _vm.taskIds[0],
                    type: _vm.computeType(0),
                    index: 0,
                    moduleTasks: _vm.moduleTasks,
                    handleChangeTask: (type, action, taskType) =>
                      _vm.handleChangeTask(type, action, 0, taskType),
                  },
                  on: { setCurrentTask: _vm.setCurrentTask },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }