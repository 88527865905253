/**
 * ----------------------------- 排单计划 -----------------------------
 */
import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";


/**
 * ----------------------------- 策略选择相关接口 -----------------------------
 */


// 查询排单计算状态
export function handleschedulingPlanSeeStatus(data) {
  return axiosPost('/arrange/compute_process_status', data);
}

// 启动计算
export function handleschedulingPlanStartCompute(data) {
  return axiosPost('/arrange/start_compute', data);
}


/**
 * ----------------------------- 自定义调整相关接口 -----------------------------
 */


// 自定义调整 -- 预排产能调整SKU列表
export function handleschedulingPlanCustomAdjLists(data) {
  return axiosPost('/arrange/arrange_plu_page', data);
}

// 自定义调整 -- 预排产能调整SKU保存
export function handleschedulingPlanCustomAdjSave(data) {
  return axiosPost('/arrange/save_arrange_plu', data);
}
// 自定义调整 -- 预排产能调整SKU保存
export function handleSchedulingUpdateToTheory(data) {
  return axiosPost('/arrange/update_to_theory', data);
}

// 自定义调整 -- 预排产能调整SKU确认
export function handleschedulingPlanCustomAdjConfirm(data) {
  return axiosPost('/arrange/confirm_arrange_plu', data);
}

// 自定义调整 -- 最终排产确认
export function handleschedulingPlanCustomAdjFinalConfirm(data) {
  return axiosPost('/arrange/final_confirm_arrange', data);
}

// 自定义调整 -- 中类排产执行情况
export function handleMidClassifyArrangeReportPage(data) {
  return axiosPost('/arrange/mid_classify_arrange_report_page', data);
}

// 自定义调整 -- 大类排产执行情况
export function handleMainClassifyArrangeReportPage(data) {
  return axiosPost('/arrange/main_classify_arrange_report_page', data);
}

// 自定义调整 -- 取整方式调整
export function handleUpdateBoxRoundType(data) {
  return axiosPost('/arrange/update_box_round_type', data);
}

/**
 * ----------------------------- 报表相关接口 -----------------------------
 */

// 实际排产计划
export function handleActualProdScheduligPlanLists(data) {
  return axiosPost('/report/final_arrange_material_report', data);
}


// 请购单明细报表
export function handleArrangePluReportList(data) {
  return axiosPost('/report/arrange_plu_report', data);
}

// 排单计划确认 -- 确认排单 -- 请购单多包装单位SKU抛单数量确认列表
export function handleRequisitionSkuPage(data) {
  return axiosPost('/requisition/requisition_sku_page', data);
}

// 排单计划确认 -- 确认排单 -- 请购单多包装单位SKU抛单数量确认保存
export function handleUpdateRequisitionSku(data) {
  return axiosPost('/requisition/update_requisition_sku', data);
}










