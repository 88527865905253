<template>
  <div :class="className">
    <h4 class="item small2" :style="computeStyles(task)">
      <div class="taskInfo">
        <div class="taskInfoL">
          <p class="taskName text-1 closed-p" :style="{'color': task.configJson.theme?.taskName?.color}">{{task.configJson.theme.taskName.text? task.configJson.theme.taskName.text: task.taskName}}</p>
          <p v-show="task.isLeaf!==0" class="taskRange closed-p" :style="'width: fit-content;'">{{task.taskRange === '1' ? "长期任务" : ((task.startDate && task.endDate) ? (parseShowTime(task.startDate, '{m}.{d}')+'-' +parseShowTime(task.endDate, '{m}.{d}')): '') }}</p>
          <p v-show="task.isLeaf===0" class="taskRange closed-p" :style="'width: fit-content;'" v-html="'&nbsp;'"></p>
        </div>
      </div>
      <div class="content">
        <div class="dataBox">
          <div v-for="(item,index) in task.configJson.summary"
               :key="index" class="numBox">
            <p class="label closed-p" :style="'width: max-content;color:' + hexToRgba(item.value.color,0.7)">{{ item.title }}
            </p>
            <p class="value closed-p">
              <span :style="'font-size: 13; color:' + item.value.color"><span>{{item.prefix}}</span> 0 {{item.unit}}</span>
            </p>
          </div>
        </div>
        <div class="cover">
          <img :src="task.configJson.theme.cover && task.configJson.theme.cover.background ? task.configJson.theme.cover.background.image: task.skuUrl" alt="" />
        </div>
      </div>

      <div class="more">
        <span :style="'color:' + task.configJson.theme?.moreDataText?.color">{{ task.isLeaf!==0? '更多数据': '任务合集' }}<i v-if="task.isLeaf!==0" class="el-icon-arrow-down" /></span>
      </div>

    </h4>
  </div>
</template>

<script>
import { parseTime, hexToRgba } from "@/utils";

export default {
  name: "TaskSmall",
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    className: {
      type: String,
      default: "group"
    }
  },
  data() {
    return {
      parseShowTime: parseTime,
      hexToRgba,
    }
  },
  methods: {
    computeStyles(item) {
      return {
        '--bgColor': item.configJson.theme?.background?.image ? '':item.configJson.theme?.background?.color,
        '--taskNameColor': item.configJson.theme?.taskName?.color,
        '--badgeColor': item.configJson.theme?.badge?.background?.color,
        '--badgeFontColor': item.configJson.theme?.badge?.font?.color,
        '--taskTimeColor': item.configJson.theme?.taskTime?.color,
        '--moreDataTextColor': item.configJson.theme?.moreDataText?.color,
        '--moreDataTextBgColor': item.configJson.theme?.moreDataBg?.color,
        '--contentDataTextBgColor': item.configJson.theme?.contentDataBg?.color,
        backgroundImage: item.configJson.theme?.background?.image && `url(${item.configJson.theme?.background?.image})`
      };
    }
  }
}

</script>

<style lang="scss" scoped src='@/style/appTask/groupItemV2.scss'></style>
