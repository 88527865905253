/**
 * ----------------------------- 上月结余量_物料 导入 -----------------------------
 */
import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";


// 上月结余量_物料列表
export function handleCarryoverMaterialLists(data) {
  return axiosPost('/carryover_material/carryover_material_page', data);
}

// 上月结余量_物料保存
export function handleCarryoverMaterialSave(data) {
  return axiosPost('/carryover_material/update_carryover_material', data);
}

// 上月结余量_物料删除
export function handleCarryoverMaterialDelete(data) {
  return axiosPost('/carryover_material/delete_carryover_material', data);
}

// 上月结余量_物料导入
export function handleCarryoverMaterialUpload(data) {
  return axiosPost('/carryover_material/import_carryover_material', data);
}



// ---- 物料维表 ---

// 物料维表_分页列表
export function handleDimMaterialPage(data) {
  return axiosPost('/dim_material/material_page', data);
}

// 物料维表_更新折算系数
export function handleDimMaterialUpdateCnt(data) {
  return axiosPost('/dim_material/update_cnt', data);
}



// ---- 产品维表 ---

// 产品维表_分页列表
export function handleDimPluPage(data) {
  return axiosPost('/dim_plu/plu_page', data);
}



// ---- 产能上下限配置 ---

// 产能上下限配置_分页列表
export function handleConfigCapacityPage(data) {
  return axiosPost('/config_capacity/config_capacity_page', data);
}

// 产能上下限配置_更新
export function handleConfigCapacityUpdate(data) {
  return axiosPost('/config_capacity/update_config', data);
}

// 产能上下限配置_创建
export function handleConfigCapacityCreate(data) {
  return axiosPost('/config_capacity/create_config', data);
}

// 产能上下限配置_删除
export function handleConfigCapacityDelete(data) {
  return axiosPost('/config_capacity/delete_config', data);
}
