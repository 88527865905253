var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  ref: "queryFormRef",
                  attrs: { model: _vm.queryForm },
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司", prop: "orgCode" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          defaultIndex: 0,
                          clearable: false,
                          init: true,
                          "init-options": this.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品包编码", prop: "spCode" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "商品包编码" },
                        model: {
                          value: _vm.queryForm.spCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "spCode", $$v)
                          },
                          expression: "queryForm.spCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联盟商品包编码", prop: "lmSpCode" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "商品包编码" },
                        model: {
                          value: _vm.queryForm.lmSpCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "lmSpCode", $$v)
                          },
                          expression: "queryForm.lmSpCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品包名称", prop: "spName" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "商品包名称" },
                        model: {
                          value: _vm.queryForm.spName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "spName", $$v)
                          },
                          expression: "queryForm.spName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "一级品类",
                        prop: "oneGradeCategoryName",
                      },
                    },
                    [
                      _c("DictSelect", {
                        ref: "one_other",
                        attrs: {
                          type: "one_other",
                          value: _vm.queryForm.oneGradeCategoryName,
                          linkageRef: "two_other",
                          dictFun: _vm.dictFun,
                          "default-first-option": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "oneGradeCategoryName",
                              $event
                            )
                          },
                          changeLinkageRef: (ref, val, type) =>
                            _vm.handleChangeLinkageRef(
                              ref,
                              val,
                              type,
                              _vm.queryForm,
                              "dictParam"
                            ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "二级品类",
                        prop: "twoGradeCategoryName",
                      },
                    },
                    [
                      _c("DictSelect", {
                        ref: "two_other",
                        attrs: {
                          type: "two_other",
                          value: _vm.queryForm.twoGradeCategoryName,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "twoGradeCategoryName",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "全国范围", prop: "orgAll" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.queryForm.orgAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "orgAll", $$v)
                            },
                            expression: "queryForm.orgAll",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: true, label: "是" },
                          }),
                          _c("el-option", {
                            attrs: { value: false, label: "否" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              staticStyle: { margin: "0 0 10px 0" },
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit(undefined, "add")
                },
              },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品包编码", prop: "spCode", width: "220" },
              }),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "spName", width: "220" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "一级品类",
                  prop: "oneGradeCategoryName",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "二级品类",
                  prop: "twoGradeCategoryName",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "头图", prop: "imageUrl", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-image",
                          {
                            attrs: {
                              src: row.imageUrl
                                ? row.imageUrl.split(",")[0]
                                : "",
                              "preview-src-list": _vm.handleImageUrls(
                                row.imageUrl
                              ),
                              lazy: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品详情图",
                  prop: "groupImageUrl",
                  width: "110",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-image",
                          {
                            attrs: {
                              src: row.groupImageUrl
                                ? row.groupImageUrl.split(",")[0]
                                : "",
                              "preview-src-list": _vm.handleImageUrls(
                                row.groupImageUrl
                              ),
                              lazy: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "全国范围", prop: "orgAll", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.orgAll ? "是" : "否"))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "联盟商品包编码",
                  prop: "lmSpCode",
                  width: "160px",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "createUser", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建日期", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "140",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.orgAllAuth || !row.orgAll
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row, "view")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm.orgAllAuth || !row.orgAll
                          ? _c(
                              "el-popconfirm",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  title:
                                    "请确认是否要删除商品包(" +
                                    row.spName +
                                    ")?",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handleDelete(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: {
                                      slot: "reference",
                                      size: "mini",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [10, 20, 50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _vm.editDialog.visible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.editDialog.dialogTitle[_vm.editDialog.type],
                visible: _vm.editDialog.visible,
                width: "70%",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.editDialog, "visible", $event)
                },
              },
            },
            [
              _c("Edit", {
                ref: "edit",
                attrs: {
                  type: _vm.editDialog.type,
                  spCode: _vm.editDialog.spCode,
                },
                on: {
                  "update:spCode": function ($event) {
                    return _vm.$set(_vm.editDialog, "spCode", $event)
                  },
                  "update:sp-code": function ($event) {
                    return _vm.$set(_vm.editDialog, "spCode", $event)
                  },
                },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        disabled: _vm.editDialog.buttonLoading,
                      },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm.editDialog.type !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.editDialog.buttonLoading,
                            size: "mini",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }