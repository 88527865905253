var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc,capacity,area-tag",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      "default-first-option": "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "capacity,area-tag,ht-shop",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      parentLinkageRef: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组" } },
                [
                  _c("DictSelect", {
                    ref: "area-tag",
                    attrs: {
                      type: "area-tag",
                      value: _vm.queryForm.areaTag,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaTag", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺类型" } },
                [
                  _c("DictSelect", {
                    ref: "capacity",
                    attrs: {
                      type: "capacity",
                      value: _vm.queryForm.shopCapacity,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCapacity", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺" } },
                [
                  _c("DictSelect", {
                    ref: "shop",
                    attrs: {
                      type: "ht-shop",
                      value: _vm.queryForm.shopCode,
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      alwaysChange: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.importDialog.visible = true
                        },
                      },
                    },
                    [_vm._v("导入分组店铺")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出分组店铺")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 150px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
          },
          on: { "sort-change": _vm.handleSortChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司编码", prop: "orgCode", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司名称", prop: "orgName", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "区域分组", prop: "areaTag", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心编码", prop: "dcCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心名称", prop: "dcName" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺编码", prop: "shopCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺类型", prop: "shopCapacity", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          border: "0px",
                          width: "20%",
                          "margin-right": "15px",
                        },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "edit")
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { border: "0px", width: "20%" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleViewTarget(row)
                          },
                        },
                      },
                      [_vm._v("目标")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "区域分组编辑",
            visible: _vm.saveDialog.visible,
            width: "200px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "areaTagForm",
              attrs: {
                model: _vm.saveDialog.form,
                inline: true,
                rules: _vm.saveDialog.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "修改区域分组名称",
                    prop: "areaTag",
                    size: "mini",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入区域分组" },
                    model: {
                      value: _vm.saveDialog.form.areaTag,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "areaTag", $$v)
                      },
                      expression: "saveDialog.form.areaTag",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.saveDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "店铺区域分组导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "goodsUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "店铺目标",
            visible: _vm.targetDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.targetDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", [
            _vm._v("仅展示已配置的目标，修改请前往区域分组的目标里设置"),
          ]),
          _c(
            "div",
            [
              _c("strong", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v("年份"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { size: "mini" },
                  on: { change: () => _vm.handleViewTarget() },
                  model: {
                    value: _vm.targetDialog.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.targetDialog, "year", $$v)
                    },
                    expression: "targetDialog.year",
                  },
                },
                _vm._l(_vm.yearOptions, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.targetDialog.loading,
                  expression: "targetDialog.loading",
                },
              ],
              attrs: {
                data: _vm.targetDialog.list,
                height: "400px",
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "时间", prop: "mth", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "目标", prop: "amountTarget", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            "进货金额 " +
                              _vm._s(row.amountTarget ? row.amountTarget : 0) +
                              " 元"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }