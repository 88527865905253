var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "ht-org",
                          value: _vm.queryForm.orgCode,
                          fullLabel: "",
                          init: true,
                          "init-options": this.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "标签名称" },
                        model: {
                          value: _vm.queryForm.tagName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "tagName", $$v)
                          },
                          expression: "queryForm.tagName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.queryForm.queryDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "queryDate", $$v)
                          },
                          expression: "queryForm.queryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleEdit },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "名称", prop: "tagName" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgName" },
              }),
              _c("el-table-column", {
                attrs: { label: "开始时间", prop: "startDate", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "结束时间", prop: "endDate", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0px" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(row, "view")
                                          },
                                        },
                                      },
                                      [_vm._v("查看")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.orgAllAuth || row.orgCode !== "ALL"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(
                                                  row,
                                                  "edit"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.orgAllAuth || row.orgCode !== "ALL"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-popconfirm",
                                          {
                                            attrs: {
                                              title:
                                                "请确认是否要删除标签(" +
                                                row.tagName +
                                                ")?",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.handleDelete(row)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { border: "0px" },
                                                attrs: {
                                                  slot: "reference",
                                                  size: "mini",
                                                  type: "text",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [10, 20, 50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialog.dialogTitle[_vm.editDialog.type],
            visible: _vm.editDialog.visible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c("Edit", {
            ref: "edit",
            attrs: { type: _vm.editDialog.type, id: _vm.editDialog.id },
            on: {
              "update:id": function ($event) {
                return _vm.$set(_vm.editDialog, "id", $event)
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.editDialog.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.editDialog.type !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.editDialog.buttonLoading,
                        size: "mini",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }