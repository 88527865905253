var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: ["mg-svg-icon", _vm.iconHref],
      style: {
        width: `${_vm.iconWidth}px`,
        height: `${_vm.iconHeight}px`,
        color: `${_vm.iconColor}`,
        cursor: _vm.hasCursor ? _vm.cursorType : "",
        marginLeft: `${_vm.iconMarginLeft}px`,
      },
      on: {
        click: _vm.handleClick,
        mouseover: _vm.handleMouseOver,
        mouseleave: _vm.handleMouseLeave,
      },
    },
    [_c("use", { attrs: { "xlink:href": _vm.iconLinkHref } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }