<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
      <el-form ref="queryFormRef" size="mini" label-width="auto" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter">
        <el-form-item label="分公司" v-if="orgDictInitOptions.length > 1">
          <el-select  v-model="queryForm.orgCode" filterable @change="handleOrgChange">
            <el-option v-for="item in orgDictInitOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="配送中心" v-if="dcDictInitOptions.length > 1">
          <el-select  v-model="queryForm.dcCode" filterable clearable>
            <el-option v-for="item in dcDictInitOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport()">导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    </div>
    <el-card style="margin: 10px;">
    <el-tabs v-model="activeTab" type="card" @tab-click="() => {}">
      <el-tab-pane v-for="item in dateTabs" :label="item.label" :name="item.value" :key="item.value" />
    </el-tabs>
    <el-table
      :data="pageData.rows"
      v-loading="loading"
      size="small"
      class="customer-table"
      border
      ref="detailTable"
      highlight-current-row
      lazy
    >
      <el-table-column label="分公司" prop="shop" width="120" fixed="left">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心" prop="dcName" width="250" fixed="left">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="近90天上柜款数目标" prop="modelTarget" width="180">
        <template #default="{ row }">
          <el-input-number :disabled="!currentRow.dcCode ||currentRow.dcCode!=row.dcCode" v-model="row.modelTarget" clearable :controls="false" size="mini" :min="0" placeholder="" />
        </template>
      </el-table-column>

      <el-table-column label="书写阵地" prop="sxModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode ||currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.sxModule" />
        </template>
      </el-table-column>
      <el-table-column label="修正阵地" prop="xzModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.xzModule" />
        </template>
      </el-table-column>
      <el-table-column label="橡皮阵地" prop="xpModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.xpModule" />
        </template>
      </el-table-column>
      <el-table-column label="尺规阵地" prop="cgModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.cgModule" />
        </template>
      </el-table-column>
      <el-table-column label="优事贴阵地" prop="ystModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.ystModule" />
        </template>
      </el-table-column>
      <el-table-column label="木铅削笔阵地" prop="mqxbModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.mqxbModule" />
        </template>
      </el-table-column>
      <el-table-column label="活动铅笔/芯阵地" prop="hdqbModule" width="140" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.hdqbModule" />
        </template>
      </el-table-column>
      <el-table-column label="儿美阵地" prop="emModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.emModule" />
        </template>
      </el-table-column>
      <el-table-column label="本册阵地" prop="bcModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.bcModule" />
        </template>
      </el-table-column>
      <el-table-column label="桌面办公阵地" prop="zmbgModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.zmbgModule" />
        </template>
      </el-table-column>
      <el-table-column label="彩色荧光阵地" prop="csygModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.csygModule" />
        </template>
      </el-table-column>
      <el-table-column label="钢笔阵地" prop="gbModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" class="checked-class" v-model="row.gbModule" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center" fixed="right">
        <template #default="{ row }">
          <el-button v-if="'ALL'===row.dcCode" title="一键同步点亮阵地至其他配送中心" size="mini" type="text" style="border: 0px;" @click="handleSyncModule(row)">一键同步</el-button>
          <el-button v-if="!currentRow.dcCode || currentRow.dcCode!=row.dcCode" size="mini" type="text" style="border: 0px;" @click="() => {currentRow = deepClone(row);}">编辑</el-button>
          <el-button v-if="currentRow.dcCode==row.dcCode" size="mini" type="text" style="border: 0px;" @click="() => {currentRow = {}}">取消</el-button>
          <el-button v-if="currentRow.dcCode==row.dcCode" size="mini" type="text" style="border: 0px;" @click="handleConfirmSubmit(row)">确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" :pageSizes="[10, 20, 30, 50, 100]" @pagination="handleQuery" />
</el-card>
    <el-dialog title="阵地打造导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <div>
            <span>仅允许导入xls、xlsx格式文件。</span>
            <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
          </div>
          <div style="color: red">本次导入将覆盖之前导入对应月份的数据</div>
          <div>
            <el-radio-group v-model="importDialog.importAction">
              <el-radio label="success" >仅成功导入</el-radio>
              <el-radio label="error"> 失败亦导入</el-radio>
            </el-radio-group>
            <div style="font-size: 15px;color: red; margin: 5px;">
              <span v-if="importDialog.importAction==='success'">数据全部校验成功后才可导入</span>
              <span v-else>数据全部校验存在错误数据后，仅导入成功数据</span>
            </div>
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="操作提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px;overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dictSelect,listModules, importModuleMths, branchDict, updateModules,exportModules, syncModuleDc } from "@/api/appTask";
import {Notification} from "element-ui";
import { deepClone, excelExport } from "@/utils";
import Pagination from "@/components/Pagination";

export default {
  name: "OrgDcModule",
  components: { Pagination },
  data() {
    return {
      deepClone,
      activeTab: undefined,
      dateTabs: [],
      orgDictInitOptions: [],
      dcDictInitOptions: [],
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      loading: false,
      queryForm: {
        orgCode: undefined,
        dcCode: undefined,
        current: 1,
        size: 10,
      },
      currentRow: {},
      exportHeaders: {
        orgCode: '分公司编码',
        dcCode: '配送中心编码',
        yearMth: '年月',
        modelTarget: '近90天上柜款数目标',
        sxModule: '书写阵地',
        xzModule: '修正阵地',
        xpModule: '橡皮阵地',
        cgModule: '尺规阵地',
        ystModule: '优事贴阵地',
        mqxbModule: '木削铅笔阵地',
        hdqbModule: '活动铅笔阵地',
        emModule: '儿美阵地',
        bcModule: '本册阵地',
        zmbgModule: '桌面办公阵地',
        csygModule: '彩色荧光阵地',
        gbModule: '钢笔阵地',
        errorMsg: '校验信息',
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
        data: undefined
      },
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        importAction: 'success',
        title: '店铺目标导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/module/%E6%89%93%E9%80%A0%E9%87%8D%E7%82%B9%E9%98%B5%E5%9C%B0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV2.xlsx',
      },
      moduleFields: [ 'sxModule','xzModule','xpModule','cgModule','ystModule','mqxbModule','hdqbModule','emModule','bcModule','zmbgModule','csygModule','gbModule'],
    }
  },
  created() {
    if (this.dateTabs.length === 0) {
      for (let i = 1; i < 13; i++) {
        this.dateTabs.push({ value: i> 9 ? i + '': '0' + i, label: i+ '月'})
      }
    }
    const mth = new Date().getMonth() + 1;
    this.activeTab = mth > 9 ? mth + '': '0' + mth;
    dictSelect('org').then(res => {
      this.orgDictInitOptions = res.data;
      this.queryForm.orgCode= res.data[0].value;
      if (res.data.length === 1) {
        this.defaultOrgCode = res.data[0].value;
        this.queryForm.orgCode = this.defaultOrgCode;
      }
      this.handleOrgChange(this.queryForm.orgCode)
      this.handleFilter(this.queryForm.orgCode);
    });
  },
  computed: {
    yearMth() {
      if (this.activeTab) {
        return new Date().getFullYear() + this.activeTab;
      } else {
        return null;
      }
    },
  },
  watch: {
    yearMth: {
      handler(nV, oV) {
        if (nV && this.queryForm.orgCode) {
          this.handleFilter();
        }
      }
    }
  },
  methods: {
    handleOrgChange(val) {
      this.dcDictInitOptions = [];
      this.queryForm.dcCode= undefined
      if (val) {
        dictSelect('dc?query=' + val + '&orgCode=' + val).then(res => {
          this.dcDictInitOptions = res.data;
          this.dcDictInitOptions.splice(0, 0, { value: 'ALL', label: '整个分公司'})
        })
      }
    },
    handleQuery() {
      this.currentRow = {};
      this.loading = true;
      this.queryForm.yearMth = this.yearMth + "";
      const query = Object.assign({}, this.queryForm);
      listModules(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    handleImport() {
      this.importDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      // fd.append("action", this.importDialog.importAction)
      this.loading = true;
      importModuleMths(fd, this.importDialog.importAction).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (this.importDialog.importAction!=='success') {
          this.noticeDialog.data = errList;
        } else {
          this.noticeDialog.data = res.data;
        }
        if (errList.length > 0) {
          let msg = "很抱歉操作失败,共" + res.data.length+"条,失败" + errList.length+ "条,具体如下：</br>"
          errList.forEach(e => msg+= "配送中心编码:" + e.dcCode + ": " + e.errorMsg + "</br>")
          this.noticeDialog.msg = msg;
        } else {
          let msg = "操作成功,共" + res.data.length+"条!"
          this.noticeDialog.msg = msg;
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.noticeDialog.visible = true;
        this.loading = false;
        this.$refs.upload.clearFiles();
      })
    },
    downloadImportResult() {
      if (this.noticeDialog.data.length === 0) {
        this.$message.warning("无可导出的错误数据！");
        return;
      }
      let headers = this.exportHeaders;
      let title = '238-打造重点阵地导入明细';
      excelExport({
        topHeaders: headers,
        data: this.noticeDialog.data,
        title: title,
      });
    },
    transferModuleField(param) {
      this.moduleFields.forEach(e => {
        if (param[e]) {
          param[e] = 1
        } else {
          param[e] = 0
        }
      })
    },
    handleConfirmSubmit(row) {
      this.loading =true;
      let param = deepClone(row);
      param.yearMth = this.yearMth;
      this.transferModuleField(param);
      updateModules( {list: [param] } ).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.$message.success("操作成功!");
          this.currentRow = {};
          this.handleFilter();
        }
      })
    },
    handleSyncModule(row) {
      this.loading= true;
      let param = deepClone(row);
      param.yearMth = this.yearMth;
      this.transferModuleField(param);
      syncModuleDc(param).then(res => {
        if (res.code === 200) {
          this.loading= false;
          this.$message.success("操作成功!");
          this.currentRow = {};
          this.handleFilter();
        }
      })
    },
    handleExport() {
      this.loading = true;
      this.queryForm.yearMth = this.yearMth + "";
      const query = Object.assign({}, this.queryForm);
      exportModules(query).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '重点打造阵地导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
  }
}
</script>

<style scoped>
::v-deep .checked-class .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1D9AEE;
}
</style>
