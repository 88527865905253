<template>
  <svg
    :class="['mg-svg-icon', iconHref]"
    :style="{
      width: `${iconWidth}px`,
      height: `${iconHeight}px`,
      color: `${iconColor}`,
      cursor: hasCursor ? cursorType : '',
      marginLeft: `${iconMarginLeft}px`,
    }"
    @click="handleClick"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <use :xlink:href="iconLinkHref"></use>
  </svg>
</template>

<script>
  export default {
    props: {
      // 默认展示图标
      iconClass: {
        type: String,
        default: '',
      },
      // 悬浮时图标
      iconHoverClass: {
        type: String,
        default: '',
      },
      // 点击时图标
      iconClickClass: {
        type: String,
        default: '',
      },
      // 图标宽度
      iconWidth: {
        type: Number,
        default: 32,
      },
      // 图标高度
      iconHeight: {
        type: Number,
        default: 32,
      },
      // 图标边距
      iconMarginLeft: {
        type: Number,
        default: 8,
      },
      // 图标悬浮时鼠标类型
      cursorType: {
        type: String,
        default: 'pointer',
      },
      // 是否需要图标悬浮时鼠标类型
      hasCursor: {
        type: Boolean,
        default: true,
      },
      iconColor: String,
    },
    computed: {
      iconLinkHref() {
        return this.iconHref && `#${this.iconHref}`
      },
    },
    watch: {
      iconClass: {
        handler: function (n, o) {
          if ( n != o ) this.iconHref = n;
        },
        deep: true
      }
    },
    data() {
      return {
        iconHref: '',
        isClick: false,
      }
    },
    mounted() {
      this.iconHref = this.iconClass
    },
    methods: {
      // 图标点击事件
      handleClick() {
        this.$emit('handleClick')
        if (!this.iconClickClass) return
        if (!this.isClick) {
          this.iconHref = this.iconClickClass
          this.isClick = true
        } else {
          this.iconHref = this.iconClass
          this.isClick = false
        }
      },
      // 鼠标移入
      handleMouseOver() {
        if (!this.iconHoverClass) return
        if (!this.isClick && this.iconHoverClass) this.iconHref = this.iconHoverClass
      },
      // 鼠标移出
      handleMouseLeave() {
        if (!this.iconClass) return
        if (!this.isClick && this.iconClass) this.iconHref = this.iconClass
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mg-svg-icon {
    // cursor: pointer;
  }
</style>
