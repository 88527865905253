<template>
  <div>
    <el-table ref="table" :data="companyRows" max-height="500px" v-loading="loading" empty-text="暂无数据" size="mini" border highlight-current-row>
      <el-table-column
        v-for="(item, index) in companyColumns"
        :key="index"
        :prop="item.prop"
        :width="item.width"
        :label="item.label"
        :align="item.align"
        :fixed="item.fixed"
      >
        <template slot-scope="{ row }">
          <el-input-number
            v-if="(row.row === '本次确认单量' || row.row === '最新一次加减单回复量') && item.prop !== 'row' && item.label != '合计'"
            v-model="row[item.prop]"
            controls-position="right"
            placeholder="请输入内容"
            style="width: 100%;color: red;"
            size="mini"
            @change="handleRowChange($event, row.row)"
          ></el-input-number>
          <span v-else :style="(row.row === '剩余未做单量'||row.row === '累积加减单确认量'||row.row === '最新一次加减单申请量'||row.row === '最新一次加减单回复量') && item.prop!=='row'?( row[item.prop] < 0?'color: red; font-size:14px !important; font-weight: 900 !important; ':(row[item.prop] > 0 ?'font-weight: 900 !important;font-size:14px !important;': '')): 'font-size:14px !important;'">{{ row[item.prop]===0? '-': row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-button-group style="margin-left: 30%; margin-top: 15px">
      <el-button type="primary" :disabled="buttonDisabled" @click="handleSaveConfirm('save')">保存加减单回复量</el-button>
      <el-button type="warning" style="margin-left: 50px" :disabled="buttonDisabled" @click="handleSaveConfirm('confirm')">
        确认做单量[配送订单] ({{ status }})
      </el-button>
      <el-button v-if="buttonShow" type="warning" style="margin-left: 50px" @click="handleReloadList('confirm')">
        仅显示加减单数据
      </el-button>
      <el-button v-if="!buttonShow" type="success" style="margin-left: 50px" @click="handleReloadList('all')">全部显示</el-button>
    </el-button-group>
  </div>
</template>

<script>
import { urlParams } from "@/mixins";
import { handleGetNewpublishDetailBySku, handleSaveConfirmOrder, handleConfirmOrder } from "@/api/newPublish";
import { deepClone } from "@/utils";
import { fixSortClomuns } from "../config";

export default {
  // 商品配置中心
  name: "newPublishSKuDetail",
  mixins: [urlParams],
  components: {},
  props: {
    skuCode: {
      type: String,
      require: true,
    },
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  data() {
    return {
      btnStatus: {
        isExport: false,
      },
      fixSortClomuns,
      defaultCompanyColumns: [
        {
          label: "分公司",
          prop: "row",
          showOverflowTooltip: true,
          width: "150",
          align: "center",
          fixed: "left",
        },
        {
          label: "合计",
          prop: "sum",
          showOverflowTooltip: true,
          width: "120",
          align: "center",
          fixed: "left",
        },
      ],
      companyColumns: [],
      defaultCompanyRows: [
        { row: "活动订货量" },
        { row: "累积加减单确认量" },
        { row: "最新一次加减单申请量" },
        { row: "最新一次加减单回复量" },
        { row: "合计订单" },
        { row: "已配送量" },
        { row: "系统欠量" },
        { row: "剩余未做单量" },
        { row: "系统推荐量" },
        { row: "本次确认单量" },
      ],
      companyRows: [],
      handleGetNewpublishDetailBySku,
      handleSaveConfirmOrder,
      handleConfirmOrder,
      queryForm: {},
      statusRwos: [],
      companyCodeRows: [],
      currentData: undefined,
      buttonDisabled: false,
      buttonShow: true,
      noConfrimList: [],
      tableList: [],
      loading: false,
      status: '未确认',
    };
  },
  created() {
    const queryForm = this.queryForm;
    this.handleUrlParams().then(res => {
      if (!(res && res["x-token"])) {
        this.queryForm = {
          ...res,
          ...queryForm,
        };
      }
    });
  },
  methods: {
    // 获取列表
    handleLists(data) {
      this.currentData = data;
      this.companyColumns = deepClone(this.defaultCompanyColumns);
      this.companyRows = deepClone(this.defaultCompanyRows);
      this.tableList = [];
      this.loading = true;
      this.handleGetNewpublishDetailBySku(data).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.forEach(element => {
            this.status = element.status === '1'? '已确认': (element.status === '2'? '已抛单': '未确认');
            // 添加默认排序
            element.sort = 99;
            const name = element.headstockName + "-" + element.companyName;
            for (var i = 0; i <= this.fixSortClomuns.length - 1; i++) {
              if (name === this.fixSortClomuns[i]) {
                // 添加排序号
                element.sort = i;
                break;
              }
            }
          });
          // 升序排序
          res.data.sort((a, b) => {
            return a.sort - b.sort;
          });
          this.tableList = res.data;
          this.handleData(this.tableList);
        }
        this.buttonDisabled = false;
        this.loading = false;
        this.$emit('next-upper');
      });
    },
    handleData(data) {
      let i = 0;
      // 初始化合计列
      for (var j = 0; j < 10; j++) {
        this.companyRows[j]["sum"] = 0;
      }

      data.forEach(element => {
        if (element.latestConfirmCount !== 0) {
          this.noConfrimList.push(element.headstockName + "-" + element.companyName);
        }
        this.companyColumns.push({
          label: element.headstockName + "-" + element.companyName,
          prop: "data_" + i,
          showOverflowTooltip: true,
          width: "120",
          align: "center",
        });
        // 合计
        this.companyRows[0]["sum"] = this.companyRows[0]["sum"] + (element.totalBoxCount ? element.totalBoxCount : 0); // 活动订货量
        this.companyRows[1]["sum"] = this.companyRows[1]["sum"] + (element.oldConfirmTotal ? element.oldConfirmTotal : 0); // 累积加减单确认量
        this.companyRows[2]["sum"] = this.companyRows[2]["sum"] + (element.latestApplyCount ? element.latestApplyCount : 0); // 最新一批次加减单申请量
        this.companyRows[3]["sum"] = this.companyRows[3]["sum"] + (element.latestConfirmCount ? element.latestConfirmCount : 0); // 最新一批次加减单确认量
        this.companyRows[4]["sum"] = this.companyRows[4]["sum"] + (element.totalOrder ? element.totalOrder : 0); // 合计订单
        this.companyRows[5]["sum"] = this.companyRows[5]["sum"] + (element.deliverCount ? element.deliverCount : 0); // 已配送量
        this.companyRows[6]["sum"] = this.companyRows[6]["sum"] + (element.oweTotal ? element.oweTotal : 0); // 系统欠量
        this.companyRows[7]["sum"] = this.companyRows[7]["sum"] + (element.leftOwe ? element.leftOwe : 0); // 剩余未做单量
        this.companyRows[8]["sum"] = this.companyRows[8]["sum"] + (element.recommendCount ? element.recommendCount : 0); // 系统推荐量
        this.companyRows[9]["sum"] = this.companyRows[9]["sum"] + (element.confirmCount ? element.confirmCount : 0); // 本次确认单量

        if (i === data.length - 1) {
          for (var s = 0; s < 10; s++) {
            this.companyRows[s]["sum"] = this.companyRows[s]["sum"].toFixed(0);
          }
        }
        this.companyRows[0]["data_" + i] = element.totalBoxCount; // 活动订货量
        this.companyRows[1]["data_" + i] = element.oldConfirmTotal; // 累积加减单确认量
        this.companyRows[2]["data_" + i] = element.latestApplyCount; // 最新一批次加减单申请量
        this.companyRows[3]["data_" + i] = element.latestConfirmCount; // 最新一批次加减单确认量
        this.companyRows[4]["data_" + i] = element.totalOrder; // 合计订单
        this.companyRows[5]["data_" + i] = element.deliverCount; // 已配送量
        this.companyRows[6]["data_" + i] = element.oweTotal; // 系统欠量
        this.companyRows[7]["data_" + i] = element.leftOwe; // 剩余未做单量
        this.companyRows[8]["data_" + i] = element.recommendCount; // 系统推荐量
        this.companyRows[9]["data_" + i] = element.confirmCount; // 本次确认单量

        this.statusRwos["data_" + i] = element.status; // 当前状态
        this.companyCodeRows["data_" + i] = element.companyCode; // 当前状态
        i++;
      });
    },
    handleReloadList(val) {
      // 显示加减单数据或全部数据
      this.loading = true;
      this.companyColumns = deepClone(this.defaultCompanyColumns);
      this.companyRows = deepClone(this.defaultCompanyRows);
      if (val === "confirm") {
        let tempList = [];
        this.tableList.forEach(e => {
          if (e.latestApplyCount !== 0) {
            tempList.push(e);
          }
        });
        this.handleData(tempList);
        this.buttonShow = false;
      } else if (val === "all") {
        this.handleData(this.tableList);
        this.buttonShow = true;
      }
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    handleSaveConfirm(type) {
      let data = [];
      let i = 0;
      let msg = "";
      let noticeMsg = "";
      // 调整数据格式改为对应的接口入参
      this.companyColumns.forEach(ele => {
        if (ele.prop !== "row" && ele.prop !== "sum") {
          debugger
          if (type === 'save' && this.companyRows[3]["data_" + i] < 0 && (this.companyRows[3]["data_" + i] + this.companyRows[6]["data_" +i]) < 0 ) {
            noticeMsg = noticeMsg + ele.label + "、";
          } else {
            // 确认做单量不能大于剩余做单量
            const plus = this.companyRows[7]["data_" + i] + this.companyRows[3]["data_" + i];
            if (this.companyRows[7]["data_" + i] < 0 && this.companyRows[9]["data_" + i] === 0) {
              data.push({
                skuCode: this.skuCode,
                companyCode: this.companyCodeRows["data_" + i],
                responseCount: this.companyRows[3]["data_" + i],
                confirmDoCount: this.companyRows[9]["data_" + i],
                status: this.statusRwos["data_" + i],
              });
            } else if (this.companyRows[9]["data_" + i] > this.companyRows[7]["data_" + i] && type === "confirm") {
              msg = msg + ele.label + "、";
            } else if (plus < 0 && type === "save") {
              data.push({
                skuCode: this.skuCode,
                companyCode: this.companyCodeRows["data_" + i],
                responseCount: this.companyRows[3]["data_" + i],
                confirmDoCount: this.companyRows[9]["data_" + i],
                status: this.statusRwos["data_" + i],
              });
            } else {
              data.push({
                skuCode: this.skuCode,
                companyCode: this.companyCodeRows["data_" + i],
                responseCount: this.companyRows[3]["data_" + i],
                confirmDoCount: this.companyRows[9]["data_" + i],
                status: this.statusRwos["data_" + i],
              });
            }
          }
          i++;
        }
      });
      if (msg) {
        this.$message.warning(msg.substring(0, msg.length - 1) + " 确认做单量不能大于剩余做单量");
        return false;
      }
      if (noticeMsg) {
        this.$message.warning(noticeMsg.substring(0, noticeMsg.length - 1) + " 欠量不足无法减单");
        return false;
      }

      const funs = {
        save: () => {
          this.handleSaveConfirmOrder({ saveList: data }).then(() => {
            this.$message.success("操作成功");
            this.handleLists(this.currentData);
          });
        },
        confirm: () => {
          this.handleConfirmOrder({ saveList: data }).then(() => {
            this.$message.success("操作成功");
            this.handleLists(this.currentData);
          });
        },
      };
      this.buttonDisabled = true;
      funs[type]();
    },
    handleRowChange(val, row) {
      let index =9;
      if (row === '最新一次加减单回复量') {
        index = 3;
      }
        const confirmRow = this.companyRows[index];
        let newSum = 0;
        for (let i in confirmRow) {
          if (i !== 'sum' && i !== 'row') {
            newSum = newSum + (confirmRow[i] ? confirmRow[i] : 0);
          }
        }
        this.companyRows[index].sum = newSum;
        this.$refs['table'].doLayout();
    }
  },
};
</script>

<style lang="scss" scoped src="@/style/branchOffice/common.scss"></style>
