<template>
  <h2 class="item horizontalItem" :style="styles">
    <span class="badge" style="top: 4px; position: relative;">{{ task.taskRange === '1' ? "长期任务" : '任务时间' + parseShowTime(task?.startDate, '{m}.{d}') + '-' + parseShowTime(task?.endDate, '{m}.{d}') }}</span>
    <div class="taskName">
      <div class="taskNameText text-1">{{ task.taskName }}</div>
    </div>
    <div class="taskData">
      <div
        v-for="(item,index) in  (type === 'shop' ?task.configJson.shop :task.configJson.summary)"
        :key="index"
        class="taskNum"
      >
        <p class="label" :style="'width: max-content;color:' + item.label.color">{{ item.title }}
        </p>
        <p class="value">
          <span :style="'font-size: 13; color:' + item.value.color"><span>{{ item.prefix }}</span> 0 {{ item.unit }}</span>
        </p>
      </div>
    </div>
    <p class="moreData"><span class="more" :style="'background-color:' + task.configJson.theme.badge.background.color">    更多数据  21  </span></p>
  </h2>
</template>

<script>
import { parseTime, hexToRgba } from "@/utils";

export default {
  name: "TaskHorizontalItem",
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: "shop"
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      parseShowTime: parseTime,
      hexToRgba
    }
  }
}

</script>

<style lang="scss" scoped src='@/style/appTask/groupItem.scss'></style>
