<template>
  <div class="mg-fixed-top" :style="{ height }">
    <div class="mg-fixed-top--body" ref="mgFixedTopBody">
      <div :style="{ 'display': 'flex', 'align-items': 'center', 'width': isFullWidth ? '100%' : '' }">
        <slot name="left" />
      </div>
      <div class="mg-fixed-top-right" style="flex: 1; text-align: right">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isFullWidth: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
         height: 0
      }
    },
    mounted() {
      setTimeout(() => {
        if (this?.$refs?.mgFixedTopBody) {
          this.height = this.$refs?.mgFixedTopBody.clientHeight + 'px'
        }
      }, 500)
    },
  }
</script>

<style lang="scss" scoped>
  .mg-fixed-top {
    &.is-collapse {
      .mg-fixed-top--body {
        width: calc(100% - 65px);
      }
    }
    &--body {
      width: 100%;
      position: fixed;
      transform: translate3d(-3px, -6.5px, 0);
      box-sizing: border-box;
      background: #fff;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
    }
  }
</style>
