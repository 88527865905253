<template>
  <div class="prodCenter-page">
    <el-tabs class="prodCenter-tabs" :tab-position="'top'" v-model="activeName" style="height: 200x;">
      <!-- 模具产能 -- 在线填写 -->
      <el-tab-pane label="在线填写" class="prodCenter-tabs-item" name="onlineWrite">
        <div class="prodCenter-content moldProd-content" v-if="activeName == 'onlineWrite'">
          <v-signboard :title="'模具产能盘点'"/>

          <el-form
            class="main-search"
            size="mini"
            label-width="auto"
            ref="moldProdCapacity-form"
            :inline="true"
            :model="form"
          >
            <el-row>
              <el-col class="list-head">
                <el-form-item label="生产单位" size="mini">
                  <el-input v-model="form.production_unit" clearable placeholder="请输入生产单位"/>
                </el-form-item>
                <el-form-item label="模号" size="mini">
                  <el-input v-model="form.mould_code" clearable placeholder="请输入模号"/>
                </el-form-item>
                <el-button
                  size="mini"
                  @click="handleTableHeadAction('search')">查 询
                </el-button>
                <el-button
                  size="mini"
                  @click="reverseChecked()">反 选
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="btnStatus.isSave"
                  @click="handleTableHeadAction('save')">保 存
                </el-button>
                <el-button
                  size="mini"
                  type="warning"
                  :disabled="btnStatus.isDelete"
                  @click="handleTableHeadAction('delete')">删 除
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleTableHeadAction('batchUpdateWorkDayCount')">批量修改工作日数
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleTableHeadAction('confirm')">确 认
                </el-button>
                <span style="margin-left: 10px;font-size: 14px;color: green" v-text="confirmTips" id="confirmTips">未确认</span>
              </el-col>
            </el-row>
          </el-form>
          <el-table
            class="table"
            stripe
            border
            size="mini"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="55"/>
            <el-table-column label="序号" align="center" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableObj.moldProdCapacityHead">
              <!-- 工作日数 / 日总产能（万） -- 可编辑 -->
              <el-table-column v-if="['work_day_count', 'day_prod_count'].includes(item.prop)" v-bind="item"
                               show-overflow-tooltip :key="index" align="center">
                <template slot-scope="scope">
                  <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'work_day_count'"
                            v-model="scope.row.work_day_count" size="mini" clearable/>
                  <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'day_prod_count'"
                            v-model="scope.row.day_prod_count" size="mini" clearable/>
                </template>
              </el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </el-tab-pane>
      <!-- 模具产能 -- 表单上传 -->
      <el-tab-pane label="表单上传" class="prodCenter-tabs-item" name="formWrite">
        <div clas="form-upload-box" v-if="activeName == 'formWrite'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'"/>
              <el-row>
                <el-col class="handleConfirmUpload">
                  <el-button size="mini" type="primary">确认上传</el-button>
                </el-col>
              </el-row>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item"/>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'"/>
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 批量修改工作日数 -->
    <el-dialog
      class="batch-update-work-day-count-dialog"
      title="批量修改工作日数"
      width="650px"
      :visible.sync="batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="80px"
          size="mini"
          class="dialog-form"
          :model="batchUpdateWorkDayCountDialogFormModel"
          :rules="batchUpdateWorkDayCountDialogFormModelRules"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item label="工作日数" size="mini" prop="work_day_count">
                <el-input
                  v-input-number
                  v-model="batchUpdateWorkDayCountDialogFormModel.work_day_count"
                  min="0"
                  max="31"
                  maxlength="2"
                  placeholder="请填写工作日数"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="doBatchUpdateWorkDayCount">保 存</el-button>
        <el-button size="small" @click="cancelBatchUpdateWorkDayCount">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams} from '@/mixins';
import {thousandsSeparator} from "@/utils";
import {moldProdCapacityHead, moldProdTemplateSampleHead} from '../config';
import {
  handlemoldProdLists,
  handlemoldProdSave,
  handlemoldProdConfirm,
  handlemoldProdimportExcel,
  handlemoldProdDelete
} from '@/api/prodCenter';
import {batchUpdateMouldWorkDayCount, queryCapacityConfirmStatus} from "../../../api/prodCenter";

export default {
  // 模具产能
  name: 'moldProdCapacity',
  mixins: [tableLoading, paginationParams, urlParams],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      form: {
        production_unit: '',
        mould_code: '',
      },
      btnStatus: {
        isDelete: true,                    // 是否删除
        isSave: true,                    // 是否保存
        isConfirm: true                  // 是否确认
      },
      lists: [],
      tableObj: {
        moldProdCapacityHead
      },
      uploadFileObj: {
        tipsText: '必需字段: 生产单位、模号、工作日数、日总产能',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: moldProdTemplateSampleHead,
        templateSampleLists: [
          {
            check_month: '',
            production_unit: '一分厂',
            mould_code: '35101',
            work_day_count: '22',
            day_prod_count: '130,000'
          }
        ]
      },
      selectRows: [],
      urlMonth: '',
      activeName: 'onlineWrite',
      batchUpdateWorkDayCountDialogStatus: {
        isBatchUpdateWorkDayCount: false,         // 是否批量更新工作天数
      },
      batchUpdateWorkDayCountDialogFormModel: {
        check_month: '',
        work_day_count: 0
      },
      batchUpdateWorkDayCountDialogFormModelRules: {
        work_day_count: [{required: true, message: '请填写工作日数', trigger: 'blur'}]
      },
      confirmTips: "尚未确认"
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isConfirm = false;
          btnStatus.isDelete = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isConfirm = true;
          btnStatus.isDelete = true;
        }
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
      this.checkConfirmTips();
    })

    let formPrams = {
      ...this.form,
      production_unit: '',
      mould_code: '',
    };
    this.form = formPrams;
  },
  methods: {
    // 获取数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          production_unit: form.production_unit || '',
          mould_code: form.mould_code || '',
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          ...formParams
        };
        const {code, data, msg} = await handlemoldProdLists(params);
        if (data) {
          const {capacity_list, total} = data;
          this.modifyData(capacity_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----模具产能盘点列表有误');
      }
    },
    async checkConfirmTips() {
      const params = {
        month: this.urlMonth || '',
        operator: this.urlParams.operator,
      };
      const {data} = await queryCapacityConfirmStatus(params);
      if (data) {
        const {capacity_list} = data;
        for (let i = 0; i < capacity_list.length; i++) {
          let capacity = capacity_list[i];
          if (capacity.capacity_type == '模具' && capacity.confirmed) {
            this.confirmTips = '已确认';
          }
        }
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            check_month: item.check_month || '--',
            production_unit: item.production_unit || '--',
            mould_code: item.mould_code || '--',
            prod_percent_up: item.prod_percent_up || 0,
            prod_percent_up_text: (item.prod_percent_up || 0) + "%",
            prod_percent_down: item.prod_percent_down || 0,
            prod_percent_down_text: (item.prod_percent_down || 0) + "%",
            month_prod_count: thousandsSeparator(item.month_prod_count, 0) || 0,
            work_day_count: item.work_day_count || 0,
            day_prod_count: item.day_prod_count || 0,
            id: item.id
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    //实现反选
    reverseChecked() {
      this.lists.map(row => {
        for (let i = 0; i < this.selectRows.length; i++) {
          if (this.selectRows[i].id == row.id) {
            this.$refs['multipleTable'].toggleRowSelection(row, false);
            return;
          }
        }
        this.$refs['multipleTable'].toggleRowSelection(row, true);
      });
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 表格头部操作项
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {           // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              check_month: item.check_month || '',
              day_prod_count: item.day_prod_count || '',
              work_day_count: item.work_day_count || '',
              id: item.id || '',
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            capacity_list: newItems
          };
          const {code, msg} = await handlemoldProdSave(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'batchUpdateWorkDayCount': () => {                     // 批量更新工作天数
          this.batchUpdateWorkDayCountDialogFormModel = {
            check_month: '',
            work_day_count: 0
          };
          this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = true;
        },
        'delete': async () => {             // 删除
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item.id || '');
          });
          const params = {
            operator: this.urlParams.operator,
            capacity_id_list: newItems
          };
          const {code, msg} = await handlemoldProdDelete(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('删除成功');
          }
          if (code != '0000') this.getLists();
        },
        'confirm': async () => {        // 确认
          this.$confirm('您需要确认模具产能么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const params = {
              month: this.urlMonth,
              operator: this.urlParams.operator
            };
            const {success} = await handlemoldProdConfirm(params);
            this.$refs['multipleTable'].clearSelection();
            if (success) {
              this.tableStatus.isLoading = true;
              this.getLists();
              this.checkConfirmTips();
              return this.$message.success('模具产能盘点已确认');
            }
            if (!success) this.getLists();


          }).catch(() => {
            this.$message('模具产能未确认');
          });

        },
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {                // 重置
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header
    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;
      if (uploadFileObj.tableHeader.indexOf('生产单位') === -1) return this.$message.warning('缺少生产单位字段');
      if (uploadFileObj.tableHeader.indexOf('模号') === -1) return this.$message.warning('缺少模号字段');
      if (uploadFileObj.tableHeader.indexOf('工作日数') === -1) return this.$message.warning('缺少工作日数字段');
      if (uploadFileObj.tableHeader.indexOf('日总产能') === -1) return this.$message.warning('缺少日总产能字段');
      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let newItem = {
          check_month: item['年月'],
          production_unit: item['生产单位'],
          mould_code: item['模号'],
          work_day_count: item['工作日数'],
          day_prod_count: item['日总产能']
        }
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        capacity_list: newItems
      };
      const {code, success, msg} = await handlemoldProdimportExcel(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (success) return this.$message.success(msg);
      if (!success) this.activeName = 'onlineWrite';
    },

    // 批量更新工作天数
    doBatchUpdateWorkDayCount() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.batchUpdateWorkDayCountDialogFormModel,
            operator: this.urlParams.operator
          };
          const {code, success} = await batchUpdateMouldWorkDayCount(params);
          if (success) {
            this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = false;
            this.getLists();
            return this.$message.success('批量更新工作天数成功');
          }
        } catch (err) {
          this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = false;
          console.log(err, 'err------批量更新工作天数有误');
        }
      })
    },
    // 批量更新工作天数取消
    cancelBatchUpdateWorkDayCount() {
      this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = false;
    },

  }
}
</script>

<style lang="scss" scoped src='@/style/prodCenter/layoutMain.scss'></style>
<style lang="scss" scoped>
.moldProd-content {
  margin-top: 0;
}
</style>
