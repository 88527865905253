<template>
  <div style="height: 100%">
    <div v-if="!taskId" style="font-size: 18px; font-weight: 700; text-align: center; cursor: pointer;
    display: flex; align-items:center; justify-content: center; height: 100%; background-color: rgb(245 249 252)">
      <el-row>
        <el-col>
          <div @click.stop="() => handleChangeTask(type, 'edit', 'task')">
            <i class="el-icon-plus" style="color: #1f85ee"></i>
            <span style="color: #1f85ee">添加任务/合集</span>
          </div>
        </el-col>
        <el-col v-if="showType==='home'" style="padding-top: 20px;">
          <div @click.stop="() => handleChangeTask(type, 'edit', 'group')">
            <i class="el-icon-plus" style="color: #1f85ee"></i>
            <span style="color: #1f85ee">创建合集</span>
          </div>
        </el-col>
      </el-row>

    </div>
    <div v-if="taskId" style="cursor: pointer" class="taskBox" tabindex="1" @click.stop="() => $emit('setCurrentTask',taskId)">
      <TaskItem :task="task" :module-type="type" />
      <div v-if="currentTaskId === taskId" :class="index===0? ((type ==='horizontal' || type==='highRowOne') ? 'popover bottom':'popover right'):'popover left'">
        <div style="text-align: center;border-bottom: 1px solid">
          <el-button type="text" size="mini" @click.stop="() => handleChangeTask(type, 'edit', undefined)">{{ task && task.isLeaf===0? '编辑入口样式': '编辑样式' }}</el-button>
        </div>
        <div v-if="task && task.isLeaf===0" style="text-align: center;border-bottom: 1px solid">
          <el-button type="text" size="mini" @click.stop="() => handleChangeTask(type, 'detail', undefined)">编辑合集内的任务</el-button>
        </div>
        <div v-if="shopAll" style="text-align: center;border-bottom: 1px solid" title="同步任务配置至其他分公司">
          <el-button type="text" size="mini" @click.stop="() => handleChangeTask(type, 'sync', undefined)">同步</el-button>
        </div>
        <div v-if="task && task.isLeaf===0" style="text-align: center;border-bottom: 1px solid">
          <el-button type="text" size="mini" @click.stop="() => handleChangeTask(type, 'target', undefined)">合集目标</el-button>
        </div>
        <div style="text-align: center;border-bottom: 1px solid">
          <el-button type="text" size="mini" @click.stop="() => handleChangeTask(type, 'remove', undefined)">移除</el-button>
        </div>
        <div v-if="task && task.isLeaf===0" style="text-align: center;">
          <el-button type="text" size="mini" @click.stop="() => handleChangeTask(type, 'delete', undefined)">解散</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskItem from '../TaskItem';

export default {
  name: "AddTask",
  components: { TaskItem },
  props: {
    taskId: {
      type: String,
      default: ""
    },
    handleChangeTask: {
      type: Function,
      default: null
    },
    moduleTasks: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
    },
    currentTask: {
      type: String,
    },
    showType: {
      type: String,
    },
    shopAll: {
      type: Boolean,
    }
  },
  watch: {
    currentTask: {
      handler(nV, oV) {
        this.currentTaskId = nV;
      }
    }
  },
  data() {
    return {
      currentTaskId: undefined
    }
  },
  computed: {
    task() {
      const filterTask = this.moduleTasks.filter(e => e && this.taskId && e.mtTaskId === this.taskId);
      return filterTask.length > 0? filterTask[0]:undefined;
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.taskBox{
  position: relative;
  &:focus .popover {
    display: block;
  }
}

.popover {
  position: absolute;
  background-color: #fff;
  padding: 0 10px;
  //bottom: 0;
  //left:50%;
  //transform:translate(-50%, 100%);
  z-index: 1;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #cfbaba;
  border-radius: 4px;
  &.left {
    top: 25%;
    left: -40%;
  }
  &.right {
    top: 25%;
    left: 100%;
  }
  &.bottom {
    bottom: 0;
    left:50%;
    transform:translate(-50%, 100%);
  }
}
//top: 0;
//right:50%;
//       transform:translate(100%, -50%);
</style>
