var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-left": "20px" }, attrs: { loading: _vm.loading } },
    [
      _c(
        "el-form",
        {
          ref: "compareForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            inline: "",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择分组", prop: "ruleGroupId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    remote: "",
                    clearable: "",
                    filterable: "",
                    "value-key": "name",
                    placeholder: "请输入分组名称",
                    "remote-method": _vm.remoteMethod,
                    size: "mini",
                  },
                  on: { change: (val) => _vm.handleGroupChange(val) },
                  model: {
                    value: _vm.form.groupObj,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "groupObj", $$v)
                    },
                    expression: "form.groupObj",
                  },
                },
                _vm._l(_vm.options.groups, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { value: item, label: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.groupType === "1"
            ? _c(
                "el-form-item",
                { attrs: { prop: "version" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择版本",
                        size: "mini",
                      },
                      model: {
                        value: _vm.form.version,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "version", $$v)
                        },
                        expression: "form.version",
                      },
                    },
                    _vm._l(_vm.options.versions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { value: item, label: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "选择比较分组", prop: "compareRuleGroupId" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        remote: "",
                        clearable: "",
                        filterable: "",
                        "value-key": "name",
                        placeholder: "请输入比较分组名称",
                        "remote-method": (val) =>
                          _vm.remoteMethod(val, "compare"),
                        size: "mini",
                      },
                      on: {
                        change: (val) => _vm.handleGroupChange(val, "compare"),
                      },
                      model: {
                        value: _vm.form.compareGroupObj,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "compareGroupObj", $$v)
                        },
                        expression: "form.compareGroupObj",
                      },
                    },
                    _vm._l(_vm.options.compareGroups, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { value: item, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.compareGroupType === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "compareVersion" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "请选择比较版本",
                            size: "mini",
                          },
                          model: {
                            value: _vm.form.compareVersion,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "compareVersion", $$v)
                            },
                            expression: "form.compareVersion",
                          },
                        },
                        _vm._l(_vm.options.compareVersions, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { value: item, label: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "20%", "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "30px" },
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.confirmGroup },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { staticClass: "full-width", attrs: { label: "基础信息" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "inline-flex", width: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "min-width": "30%",
                            display: "inline-flex",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "p-margin",
                              staticStyle: {
                                "background-color": "rgba(235, 246, 255, 1)",
                              },
                            },
                            [_vm._v(_vm._s(_vm.form.groupName))]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "p-margin" }, [
                            _vm._v(
                              "更新时间: " +
                                _vm._s(_vm.form.baseInfo.groupInfo.createTime)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "p-margin" }, [
                            _vm._v(
                              "覆盖范围: " +
                                _vm._s(_vm.form.baseInfo.groupInfo.count)
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "10%", "text-align": "center" },
                        },
                        [_c("p", [_vm._v("VS")])]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "min-width": "30%",
                            display: "inline-flex",
                            float: "right",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "p-margin",
                              staticStyle: {
                                "background-color": "rgba(235, 246, 255, 1)",
                              },
                            },
                            [_vm._v(_vm._s(_vm.form.compareGroupName))]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "p-margin" }, [
                            _vm._v(
                              "更新时间: " +
                                _vm._s(
                                  _vm.form.baseInfo.compareGroupInfo.createTime
                                )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "p-margin" }, [
                            _vm._v(
                              "覆盖范围: " +
                                _vm._s(_vm.form.baseInfo.compareGroupInfo.count)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "half-width", attrs: { label: "标签对比", s: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", size: "mini", filterable: "" },
                  model: {
                    value: _vm.form.ids,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "ids", $$v)
                    },
                    expression: "form.ids",
                  },
                },
                _vm._l(_vm.options.tags, function (item) {
                  return _c("el-option", {
                    key: item.tagId,
                    attrs: { value: item.id, label: item.tagName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleTagChange },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        _vm._l(_vm.chartData, function (item, index) {
          return _c("div", {
            key: index,
            ref: "bingChart" + index,
            refInFor: true,
            staticStyle: {
              width: "48%",
              height: "240px",
              display: "inline-block",
              border: "1px solid #ede4e4",
              margin: "10px 10px",
            },
            attrs: { id: "pieChart" + index },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }