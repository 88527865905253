var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thirdPartyPage-page" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "signboard",
              staticStyle: {
                padding: "10px 0 10px 0",
                "margin-bottom": "10px",
              },
              attrs: { md: 8, lg: 6, xl: 4 },
            },
            [_vm._v(" 排单计划确认 ")]
          ),
          _c(
            "el-col",
            {
              staticClass: "thirdPartyPage-page-head",
              staticStyle: { padding: "5px 0" },
              attrs: { md: 8, lg: 6, xl: 4 },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "warning",
                    disabled: _vm.isConfirm,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleHeadAction("showRequisitionSku")
                    },
                  },
                },
                [_vm._v("确 认 排 单 ")]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    "font-size": "14px",
                    color: "green",
                  },
                  attrs: { id: "confirmTips" },
                  domProps: { textContent: _vm._s(_vm.confirmTips) },
                },
                [_vm._v("未确认排产")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.iframeArrays, function (item, index) {
          return _c(
            "el-tab-pane",
            {
              key: index + "iframe",
              attrs: { label: item.name, name: item.tabName },
            },
            [
              [
                item.tabName == "3"
                  ? _c("div", [_c("actualProd-scheduligPlan")], 1)
                  : item.tabName == "4"
                  ? _c("div", [_c("ArrangePluReport")], 1)
                  : _c("iframe", {
                      staticStyle: { width: "100%", height: "85vh" },
                      attrs: { src: item.iframeSrc, frameborder: "0" },
                    }),
              ],
            ],
            2
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "请购单多包装单位SKU抛单数量确认",
            width: "900px",
            visible: _vm.dialogStatus.isShowRequisitionSku,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogStatus, "isShowRequisitionSku", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableStatus.isLoading,
                      expression: "tableStatus.isLoading",
                    },
                  ],
                  ref: "multipleTable",
                  staticClass: "table",
                  attrs: {
                    stripe: "",
                    border: "",
                    size: "mini",
                    data: _vm.lists,
                  },
                  on: { "selection-change": _vm.handleselection },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      width: "55",
                      fixed: "left",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.$index +
                                    (_vm.currentPage - 1) * _vm.pageSize +
                                    1
                                ) + " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._l(
                    _vm.tableObj.requisitionSkuHead,
                    function (item, index) {
                      return [
                        ["requisition_count_box"].includes(item.prop)
                          ? _c(
                              "el-table-column",
                              _vm._b(
                                {
                                  key: index,
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            item.prop == "requisition_count_box"
                                              ? _c("el-input", {
                                                  staticClass:
                                                    "tgqrcount-cell-ipt",
                                                  attrs: {
                                                    type: "number",
                                                    size: "mini",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .requisition_count_box,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "requisition_count_box",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.requisition_count_box",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                "el-table-column",
                                item,
                                false
                              )
                            )
                          : ["cargono"].includes(item.prop)
                          ? _c(
                              "el-table-column",
                              _vm._b(
                                {
                                  key: index,
                                  attrs: {
                                    fixed: "left",
                                    "show-overflow-tooltip": "",
                                    align: "center",
                                  },
                                },
                                "el-table-column",
                                item,
                                false
                              )
                            )
                          : _c(
                              "el-table-column",
                              _vm._b(
                                {
                                  key: index,
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    align: "center",
                                  },
                                },
                                "el-table-column",
                                item,
                                false
                              )
                            ),
                      ]
                    }
                  ),
                ],
                2
              ),
              _c("v-pagination", {
                attrs: {
                  total: this.total,
                  pageSize: this.pageSize,
                  currentPage: this.currentPage,
                },
                on: { pageChange: this.pagingChange },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: { click: _vm.handleAddSubmit },
                },
                [_vm._v("确 认 抛 单")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleAddCancel },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }