<template>
  <div >
    <div style="color:dodgerblue; text-align: center"><h1>APP界面预览</h1><p>任务合集页</p></div>
    <div class="page">
      <div class="content" :style="{'--bgColor': task.configJson.page?.background?.color}">
        <div class="contentInner" :style="{'background-image': task.configJson.page?.background?.image && `url(${task.configJson.page?.background?.image})`}">
          <div class="groupName">{{task.configJson.page.customHeadImage ? '': task.configJson.page.title}}</div>
          <div class="introduction">
            <p class="introductionTitle">活动简介</p>
            <div class="desc">{{task.description}}</div>
          </div>
          <div>
            <GroupItem :list.sync="list" type="summary" :style="{ 'background-color': task.configJson.page?.background?.color, 'border-radius': 'unset' }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  parseTime } from "@/utils";
import GroupItem from "../GroupItem";

export default {
  name: "TaskGroupDetail",
  components: { GroupItem },
  props: {
    task: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: "shop"
    },
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      parseShowTime: parseTime,
    }
  }
}

</script>

<style lang="scss" src='@/style/appTask/groupDetail.scss'></style>
