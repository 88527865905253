<template>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
        style="height: 400px; overflow-y: hidden"
        :defaultConfig="editorConfig"
        v-model="html"
        @onChange="onChange"
        @onCreated="onCreated"
        :defaultContent="content"
      />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { defaultContent, imageCustomUpload, videoCustomUpload } from './config'
import {getSTSToken} from "@/api/appTask"
import Client from '@/utils/client'

export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
      required: true,
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.html = newVal;
      },
      immediate: true
    }
  },
  data() {
    return {
      editor: null,
      html: this.value,
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {}
      },
      dataObj: {},
      content: [],
    };
  },
  methods: {
    getAliToken(){ //获取Token
      return new Promise((resolve, reject) => {
        getSTSToken({"supplier":"ALI"}).then(res => {
          if (res.success) {
            const {expiration, accessKeyId, accessKeySecret, securityToken} = res.data;
            this.expiration = expiration;
            this.dataObj = {
              region: this.region,
              bucket: this.bucket,
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
              stsToken: securityToken,
              useFetch: true
            };
            resolve(true);
          } else {
            reject(false);
          }
        }).catch(err => {
          console.log(err);
          reject(false);
        })
      })
    },
    init() {
      this.editorConfig.MENU_CONF['uploadImage'] = imageCustomUpload;
      this.editorConfig.MENU_CONF['uploadVideo'] = videoCustomUpload;
      this.editorConfig.MENU_CONF['color'] = {
        colors: [
          '#E60012',
          '#3B8DDA',
          '#357FAE',
          '#5E564F',
          '#69707F',
          "rgb(0, 0, 0)",
          "rgb(38, 38, 38)",
          "rgb(89, 89, 89)",
          "rgb(140, 140, 140)",
          "rgb(191, 191, 191)",
          "rgb(217, 217, 217)",
          "rgb(233, 233, 233)",
          "rgb(245, 245, 245)",
          "rgb(250, 250, 250)",
          "rgb(255, 255, 255)",
          "rgb(225, 60, 57)",
          "rgb(231, 95, 51)",
          "rgb(235, 144, 58)",
          "rgb(245, 219, 77)",
          "rgb(114, 192, 64)",
          "rgb(89, 191, 192)",
          "rgb(66, 144, 247)",
          "rgb(54, 88, 226)",
          "rgb(106, 57, 201)",
          "rgb(216, 68, 147)",
          "rgb(251, 233, 230)",
          "rgb(252, 237, 225)",
          "rgb(252, 239, 212)",
          "rgb(252, 251, 207)",
          "rgb(231, 246, 213)",
          "rgb(218, 244, 240)",
          "rgb(217, 237, 250)",
          "rgb(224, 232, 250)",
          "rgb(237, 225, 248)",
          "rgb(246, 226, 234)",
          "rgb(255, 163, 158)",
          "rgb(255, 187, 150)",
          "rgb(255, 213, 145)",
          "rgb(255, 251, 143)",
          "rgb(183, 235, 143)",
          "rgb(135, 232, 222)",
          "rgb(145, 213, 255)",
          "rgb(173, 198, 255)",
          "rgb(211, 173, 247)",
          "rgb(255, 173, 210)",
          "rgb(255, 77, 79)",
          "rgb(255, 122, 69)",
          "rgb(255, 169, 64)",
          "rgb(255, 236, 61)",
          "rgb(115, 209, 61)",
          "rgb(54, 207, 201)",
          "rgb(64, 169, 255)",
          "rgb(89, 126, 247)",
          "rgb(146, 84, 222)",
          "rgb(247, 89, 171)",
          "rgb(207, 19, 34)",
          "rgb(212, 56, 13)",
          "rgb(212, 107, 8)",
          "rgb(212, 177, 6)",
          "rgb(56, 158, 13)",
          "rgb(8, 151, 156)",
          "rgb(9, 109, 217)",
          "rgb(29, 57, 196)",
          "rgb(83, 29, 171)",
          "rgb(196, 29, 127)",
          "rgb(130, 0, 20)",
          "rgb(135, 20, 0)",
          "rgb(135, 56, 0)",
          "rgb(97, 71, 0)",
          "rgb(19, 82, 0)",
        ]
      }
      this.content = defaultContent;
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      // 初始化设置展示内容
      if (this.value && this.value !== '') {
        this.editor.setHtml(this.value)
      }
    },
    onChange(editor) {
      let html = editor.getHtml();
      html = html.replaceAll('<hr', '<hr style="border: 0.5px solid #EDEDED;"')
      this.$emit("update:value", html);
      this.$emit('change', html)
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;

    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;

    },
    async handleHttpRequest(file, insertFn){ //上传OSS
      try {
        const client = Client(this.dataObj);
        // 分片上传文件
        console.log("开始上传");
        this.$message.info('上传中...')
        const dateDir =  new Date().getFullYear() + new Date().getMonth() + "/"
        await client.put('pdb-front/' + process.env.NODE_ENV + '/' + dateDir + new Date().getTime() + '_' + file.name, file, {
          progress: async function (p) {
            let e = {};
            e.percent = p * 100;
          }
        }).then(({res}) => {
          console.log(res);
          if (res.statusCode === 200) {
            this.$message.success('上传成功')
            this.editor.insertText('<img src="'+res.requestUrls[0]+'"')
            insertFn(res.requestUrls[0], file.name, res.requestUrls[0]);
            // this.$emit("update:valueTo",  res.requestUrls[0].replace("http://", "https://"));
            return res.requestUrls
          } else {
            Notification.error('上传失败');
          }
        }).catch(error => {
          Notification.error('上传失败');
        });

      } catch (error) {
        console.error(error);
        Notification.error('上传失败');
      }
    },
    clearContent() {
      this.editor.clear();
    }
  },
  created() {
    this.init();
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>Ajax 异步设置内容 HTML</p>";
    // }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
  upload(f) {
    console.log(f)
  }
};
</script>

<style scoped>
::v-deep .w-e-textarea-divider hr {
  background-color: #EDEDED;
}
</style>

<style src="@wangeditor/editor/dist/css/style.css"></style>
