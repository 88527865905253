import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";


export function queryItemOtherPlatformsListExport() {
  return axiosGet("/bi/rpa_data", [], 6);
}
export function rpaPushUrl(data) {
  return axiosPost("/bi/rpa_push_url", data, 6);
}

export function rpaTbData(data) {
  return axiosPost("/bi/rpa_tb_data", data, 6);
}

/**
 * rpa采集  删除
 * @param data
 * @returns {*}
 */
export function delRpaData(data) {
  return axiosPost("/bi/del_rpa_data", data, 6);
}


/**
 * rpa采集  获取步骤编码
 * @param data
 * @returns {*}
 */
export function getStepData() {
  return axiosGet("/bi/get_step_data", [], 6);
}


/**
 * rpa采集 流程编辑查询
 * @param data
 * @returns {*}
 */
export function getEditRpaData(data) {
  return axiosPost("/bi/get_edit_rpa_data", data, 6);
}
