var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { padding: "20px" } }, [
    _c(
      "div",
      { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
      [
        _c(
          "el-form",
          {
            ref: "searchRef",
            attrs: {
              size: "mini",
              "label-width": "auto",
              inline: true,
              model: _vm.queryForm,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "补货日期" } },
              [
                _c(
                  "el-select",
                  {
                    on: { change: _vm.handleDateChange },
                    model: {
                      value: _vm.queryForm.supplyDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "supplyDate", $$v)
                      },
                      expression: "queryForm.supplyDate",
                    },
                  },
                  _vm._l(_vm.dateOptions, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c("DictSelect", {
              ref: "storeSearch",
              attrs: {
                title: _vm.queryForm.storeCode,
                value: _vm.queryForm.storeCode,
                showLabel: "店铺信息",
                fullLabel: true,
                placeholder: "可输入编号或名称",
                type: "store",
                date: true,
              },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(_vm.queryForm, "storeCode", $event)
                },
                getDate: (callback) => callback(_vm.queryForm.supplyDate),
              },
            }),
            _c("DictSelect", {
              ref: "itemSearch",
              attrs: {
                value: _vm.queryForm.itemCode,
                showLabel: "商品信息",
                fullLabel: true,
                placeholder: "可输入编号或名称",
                type: "item",
                date: true,
              },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(_vm.queryForm, "itemCode", $event)
                },
                getDate: (callback) => callback(_vm.queryForm.supplyDate),
              },
            }),
            _c(
              "el-form-item",
              { attrs: { label: "" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.handleQuery },
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.handleResetFields },
                  },
                  [_vm._v("清空")]
                ),
              ],
              1
            ),
            _c("el-form-item", [_vm._v("至少选择一个店铺或一个商品!")]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-bottom": "10px" } },
      [
        _c("UpdateTime", {
          attrs: { time: _vm.updateTime },
          on: {
            "update:time": function ($event) {
              _vm.updateTime = $event
            },
          },
        }),
        _vm._m(0),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.pageData.rows,
              "max-height": "500px",
              size: "mini",
              border: "",
              "highlight-current-row": "",
              "header-cell-style": {
                background: "rgb(227 224 224)",
                color: "black",
              },
            },
            on: { "sort-change": _vm.handleSortChange },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "店铺编号",
                prop: "storeCode",
                width: "100",
                fixed: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "店铺名称",
                prop: "storeName",
                width: "160",
                fixed: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "商品编号",
                prop: "itemCode",
                width: "100",
                fixed: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "商品名称",
                prop: "itemName",
                width: "180",
                fixed: "left",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "大仓库存",
                prop: "storageQty",
                sortable: "",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "门店库存",
                prop: "storeQty",
                sortable: "",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "最小起订量",
                prop: "minDisNum",
                sortable: "",
                width: "120",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "中包装",
                prop: "middleBoxGauge",
                sortable: "",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "标准陈列量",
                prop: "displayNum",
                sortable: "",
                width: "120",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "预测补货数量",
                prop: "ycBhQty",
                sortable: "",
                width: "120",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "最终补货数量",
                prop: "zzBhQty",
                sortable: "",
                width: "120",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "预测周转",
                prop: "ycZz",
                sortable: "",
                width: "100",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "最终补货周转", prop: "ycBhZz", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            row.sellQtyLast1
                              ? (
                                  (row.zzBhQty + row.storeQty) /
                                  row.sellQtyLast1
                                ).toFixed(2)
                              : 0
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "调整说明", prop: "remark", width: "120" },
            }),
            _vm.showColumns["modelName"]
              ? _c("el-table-column", {
                  attrs: { label: "模型", prop: "modelName", width: "100" },
                })
              : _vm._e(),
            _vm.showColumns["a"]
              ? _c("el-table-column", {
                  attrs: { label: "a", prop: "a", width: "100" },
                })
              : _vm._e(),
            _vm.showColumns["b"]
              ? _c("el-table-column", {
                  attrs: { label: "b", prop: "b", width: "100" },
                })
              : _vm._e(),
            _vm.showColumns["r2"]
              ? _c("el-table-column", {
                  attrs: { label: "r2", prop: "r2", width: "100" },
                })
              : _vm._e(),
            _vm.showColumns["sse"]
              ? _c("el-table-column", {
                  attrs: { label: "sse", prop: "sse", width: "100" },
                })
              : _vm._e(),
            _vm.showColumns["mse"]
              ? _c("el-table-column", {
                  attrs: { label: "nse", prop: "mse", width: "100" },
                })
              : _vm._e(),
            _vm.showColumns["smoothingLevel"]
              ? _c("el-table-column", {
                  attrs: {
                    label: "smoothing_level",
                    prop: "smoothingLevel",
                    width: "100",
                  },
                })
              : _vm._e(),
            _vm.showColumns["smoothingTrend"]
              ? _c("el-table-column", {
                  attrs: {
                    label: "smoothing_trend",
                    prop: "smoothingTrend",
                    width: "100",
                  },
                })
              : _vm._e(),
            _vm.showColumns["smoothingSeasonal"]
              ? _c("el-table-column", {
                  attrs: {
                    label: "smoothing_seasonal",
                    prop: "smoothingSeasonal",
                    width: "100",
                  },
                })
              : _vm._e(),
            _vm.showColumns["dampingTrend"]
              ? _c("el-table-column", {
                  attrs: {
                    label: "damping_trend",
                    prop: "dampingTrend",
                    width: "100",
                  },
                })
              : _vm._e(),
            _vm.showColumns["initialLevel"]
              ? _c("el-table-column", {
                  attrs: {
                    label: "initial_level",
                    prop: "initialLevel",
                    width: "100",
                  },
                })
              : _vm._e(),
            _vm.showColumns["initialTrend"]
              ? _c("el-table-column", {
                  attrs: {
                    label: "initial_trend",
                    prop: "initialTrend",
                    width: "100",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("Pagination", {
          attrs: {
            total: _vm.pageData.total,
            page: _vm.queryForm.current,
            limit: _vm.queryForm.size,
          },
          on: {
            "update:page": function ($event) {
              return _vm.$set(_vm.queryForm, "current", $event)
            },
            "update:limit": function ($event) {
              return _vm.$set(_vm.queryForm, "size", $event)
            },
            pagination: _vm.handleQuery,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dayType,
            expression: "dayType",
          },
        ],
        staticStyle: { "margin-top": "10px" },
      },
      [
        _c(
          "div",
          { staticStyle: { float: "right", "z-index": "99999999999999" } },
          [
            _c(
              "el-radio-group",
              {
                attrs: { size: "mini" },
                on: { input: _vm.handleChartTypeChange },
                model: {
                  value: _vm.holidayChartType,
                  callback: function ($$v) {
                    _vm.holidayChartType = $$v
                  },
                  expression: "holidayChartType",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "周日均" } }),
                _c("el-radio-button", { attrs: { label: "同期" } }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "font-size": "12px", "margin-bottom": "5px" } },
          [
            _vm._v(" 下一个节日: "),
            _c("span", { staticStyle: { color: "#e67488" } }, [
              _vm._v(
                _vm._s(_vm.dayType) + " (" + _vm._s(_vm.holidayDateRange) + ")"
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { staticStyle: { "font-size": "12px", "margin-bottom": "5px" } },
          [
            _vm._v(" 距离节日开始 "),
            _c("span", { staticStyle: { color: "#e67488" } }, [
              _vm._v(_vm._s(_vm.beginDay > 0 ? _vm.beginDay : 0)),
            ]),
            _vm._v(" 天,距离节日结束 "),
            _c("span", { staticStyle: { color: "#e67488" } }, [
              _vm._v(_vm._s(_vm.endDay)),
            ]),
            _vm._v(" 天 "),
          ]
        ),
        _vm._m(1),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.dayType,
            expression: "!dayType",
          },
        ],
      },
      [_vm._m(2)]
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dayType && _vm.holidayChartType === "同期",
          expression: "dayType &&holidayChartType === '同期'",
        },
      ],
      ref: "history",
      staticStyle: {
        width: "800px",
        height: "350px",
        border: "0.5px solid #e3d6d6",
        margin: "0 auto",
      },
      attrs: { id: "1" },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dayType && _vm.holidayChartType === "周日均",
          expression: "dayType && holidayChartType === '周日均'",
        },
      ],
      ref: "holiday",
      staticStyle: {
        width: "100%",
        height: "350px",
        border: "0.5px solid #e3d6d6",
      },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.dayType,
          expression: "!dayType",
        },
      ],
      ref: "common",
      staticStyle: {
        width: "100%",
        height: "350px",
        border: "0.5px solid #e3d6d6",
      },
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.dayType || _vm.holidayChartType === "周日均",
            expression: "!dayType || holidayChartType === '周日均'",
          },
        ],
        staticClass: "block",
        staticStyle: { "margin-top": "10px" },
      },
      [
        _c("el-date-picker", {
          staticStyle: { "min-width": "400px" },
          attrs: {
            type: "daterange",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "picker-options": _vm.pickerOptions,
            "default-value": _vm.defaultDayDate,
            size: "mini",
            "default-time": ["00:00:00", "00:00:00"],
          },
          model: {
            value: _vm.dayDate,
            callback: function ($$v) {
              _vm.dayDate = $$v
            },
            expression: "dayDate",
          },
        }),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "10px" },
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.handleConfirmDate },
          },
          [_vm._v("确认")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "margin-bottom": "10px",
              "margin-left": "10px",
              color: "gray",
            },
          },
          [_vm._v("说明：阴影部分为期初库存为0的日期")]
        ),
        _c("div", {
          ref: "dayChart",
          staticStyle: {
            width: "100%",
            height: "350px",
            border: "0.5px solid #e3d6d6",
            "margin-top": "10px",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dayType && _vm.holidayChartType === "同期",
            expression: "dayType && holidayChartType === '同期'",
          },
        ],
      },
      [
        _c(
          "el-select",
          {
            attrs: {
              placeholder: "选择同期年份",
              size: "mini",
              "value-key": "year",
            },
            on: { change: _vm.handleHistoryHolidayData },
            model: {
              value: _vm.historyHolidayDate,
              callback: function ($$v) {
                _vm.historyHolidayDate = $$v
              },
              expression: "historyHolidayDate",
            },
          },
          _vm._l(_vm.holidayDateOptions, function (item) {
            return _c("el-option", {
              key: item.year,
              attrs: { value: item, label: item.year },
            })
          }),
          1
        ),
        _c("div", {
          ref: "historyDayChart",
          staticStyle: {
            width: "100%",
            height: "350px",
            border: "0.5px solid #e3d6d6",
            "margin-top": "10px",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-left": "10px" } }, [
      _c("h3", { staticStyle: { margin: "0px" } }, [_vm._v("概览")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { margin: "10px" } }, [
      _c("h3", { staticStyle: { margin: "0px" } }, [_vm._v("销售趋势")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-left": "10px" } }, [
      _c("h3", { staticStyle: { margin: "10px" } }, [_vm._v("销售数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }