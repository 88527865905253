var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          defaultIndex: 0,
                          clearable: true,
                          init: true,
                          "init-options": this.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "未开始", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "进行中", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已过期", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已结束", value: "3" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.queryForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "name", $$v)
                          },
                          expression: "queryForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit(undefined, "add")
                },
              },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-tabs",
            {
              staticStyle: { "margin-top": "10px" },
              on: {
                "tab-click": (val) => {
                  _vm.queryForm.type = _vm.tabName
                  _vm.handleQuery()
                },
              },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "分公司对伙伴", name: "JBP" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "伙伴对终端", name: "DHH" },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "活动名", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: { label: "活动简称", prop: "shortName" },
              }),
              _c("el-table-column", {
                attrs: { label: "时间范围", prop: "endDate", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.parseTime(row.startDate, "{y}.{m}.{d}") +
                                "-" +
                                _vm.parseTime(row.endDate, "{y}.{m}.{d}")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "orgAll" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.statusMap[row.status]))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建日期", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "240", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              [
                                _vm.orgAllAuth || !row.orgAll
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEdit(
                                                  row,
                                                  "edit"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.orgAllAuth || !row.orgAll
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-popconfirm",
                                          {
                                            attrs: {
                                              title:
                                                "请确认是否要删除活动(" +
                                                row.spName +
                                                ")?",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.handleDelete(row)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { border: "0px" },
                                                attrs: {
                                                  slot: "reference",
                                                  disabled: row.type == "DHH",
                                                  size: "mini",
                                                  type: "text",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (_vm.orgAllAuth || row.orgCode !== "ALL") &&
                                _vm.notShowTypes.indexOf(row.type) < 0 &&
                                row.status !== 3
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleHide(row, "1")
                                              },
                                            },
                                          },
                                          [_vm._v("隐藏")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                (_vm.orgAllAuth || row.orgCode !== "ALL") &&
                                _vm.notShowTypes.indexOf(row.type) < 0 &&
                                row.status == 3
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleHide(row, "0")
                                              },
                                            },
                                          },
                                          [_vm._v("取消隐藏")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [10, 20, 50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _vm.editDialog.visible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.editDialog.dialogTitle[_vm.editDialog.type],
                visible: _vm.editDialog.visible,
                width: "50%",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.editDialog, "visible", $event)
                },
              },
            },
            [
              _c("Edit", {
                ref: "edit",
                attrs: {
                  type: _vm.editDialog.type,
                  "form-type": _vm.queryForm.type,
                  id: _vm.editDialog.id,
                },
                on: {
                  "update:id": function ($event) {
                    return _vm.$set(_vm.editDialog, "id", $event)
                  },
                },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        disabled: _vm.editDialog.buttonLoading,
                      },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm.editDialog.type !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.editDialog.buttonLoading,
                            size: "mini",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm()
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }