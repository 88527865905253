import {axiosDelete, axiosDownload, axiosGet, axiosPost} from "@/utils/request";

// -----------------------自动补货相关接口开始--------------------------------
export function getAllCategories() {
  return axiosGet("/dcSku/allCatrgories?withAll=true", null, 7);
}

export function getSupplyConfigList(data) {
  return axiosPost("/dc-auto-supply/config/page", data, 7);
}

export function getSupplyConfig(data) {
  return axiosPost("/dc-auto-supply/config", data, 7);
}

export function updateSupplyConfig(data) {
  return axiosPost("/dc-auto-supply/config-update", data, 7);
}

export function getSupplySkuList(data) {
  return axiosPost("/dc-auto-supply/sku-list", data, 7);
}

export function importSkuExcel(fd, data) {
  return axiosPost("/dc-auto-supply/importFile/" + data.orgCode + "/" + data.dcCode + "/" + data.mode, fd, 7);
}

export function exportSkuExcel(data) {
  return axiosDownload("/dc-auto-supply/sku/export", data, 7);
}

export function getSupplyRestockingList(data) {
  return axiosPost("/dc-auto-supply/restocking-list", data, 7);
}

export function getSupplyRestockingSummary(data) {
  return axiosPost("/dc-auto-supply/restocking-summary", data, 7);
}

export function exportRestockingExcel(data) {
  return axiosDownload("/dc-auto-supply/restocking/export", data, 7);
}

export function getSupplySuggestList(data) {
  return axiosPost("/dc-auto-supply/suggest-list", data, 7);
}

export function getSupplyHeadSuggestList(data) {
  return axiosPost("/dc-auto-supply/head-suggest-list", data, 7);
}

export function exportHeadSuggestExcel(data) {
  return axiosDownload("/dc-auto-supply/head-suggest/export", data, 7);
}

export function exportRestockingSuggestExcel(data) {
  return axiosDownload("/dc-auto-supply/restocking-suggest/export", data, 7);
}

export function updateSku(data) {
  return axiosPost("/dc-auto-supply/sku-update", data, 7);
}

export function removeSku(data) {
  return axiosPost("/dc-auto-supply/sku-delete", data, 7);
}

export function getAutoSupplyLog(data) {
  return axiosPost("/dc-auto-supply/config/log", data, 7);
}

export function batchUpdateSku(data) {
  return axiosPost("/dc-auto-supply/sku/batch-update", data, 7);
}
export function batchRemoveSku(data) {
  return axiosDelete("/dc-auto-supply/sku/batch-remove", data, 7);
}
// -----------------------自动补货相关接口结束--------------------------------
