var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合并后配送中心编码", prop: "dcCode" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      size: "mini",
                      type: "merge-dc",
                      value: _vm.queryForm.mdcCode,
                      linkageRef: "shop",
                      dictFun: _vm.dictSelect,
                      remote: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "mdcCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合并后店铺编码", prop: "shopCode" } },
                [
                  _c("DictSelect", {
                    ref: "shop",
                    attrs: {
                      size: "mini",
                      type: "merge-shop",
                      value: _vm.queryForm.mshopCode,
                      dictFun: _vm.dictSelect,
                      remote: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "mshopCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ dcCode: _vm.queryForm.mdcCode }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合并前配送中心编码", prop: "dcCode" } },
                [
                  _c("DictSelect", {
                    ref: "before-dc",
                    attrs: {
                      size: "mini",
                      type: "merge-before-dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "before-shop",
                      dictFun: _vm.dictSelect,
                      remote: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合并前店铺编码", prop: "shopCode" } },
                [
                  _c("DictSelect", {
                    ref: "before-shop",
                    attrs: {
                      size: "mini",
                      type: "merge-before-shop",
                      value: _vm.queryForm.shopCode,
                      dictFun: _vm.dictSelect,
                      remote: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ dcCode: _vm.queryForm.dcCode }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
            "cell-class-name": "table-cell-class",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c(
            "el-table-column",
            { attrs: { label: "合并后", prop: "mdcCode", width: "200" } },
            [
              _c("el-table-column", {
                attrs: { label: "配送中心", prop: "mdcCode", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.mdcCode + "-" + row.mdcName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "店铺", prop: "mshopCode" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.mshopCode + "-" + row.mshopName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "合并前", prop: "shopCode", width: "200" } },
            [
              _c("el-table-column", {
                attrs: { label: "配送中心", prop: "shopCode", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.shops, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c("span", [
                                _vm._v(_vm._s(item.dcCode + "-" + item.dcName)),
                              ]),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "店铺", prop: "shopName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.shops, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.shopCode + "-" + item.shopName)
                                ),
                              ]),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否主店铺", prop: "ifMain", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.shops, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c("span", [
                                _vm._v(_vm._s(item.ifMain === 1 ? "是" : "否")),
                              ]),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作人", prop: "createUser", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.shops, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c("span", [_vm._v(_vm._s(item.opUser))]),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作时间", prop: "createTime", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.shops, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c("span", [_vm._v(_vm._s(item.opTime))]),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { label: "操作", width: "140", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              border: "0px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              disabled: row.mshopCode.indexOf("_") > -1,
                              size: "mini",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row, "edit")
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              title: "请确认是否要删除当前合并店铺数据?",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  slot: "reference",
                                  disabled: row.mshopCode.indexOf("_") > -1,
                                  size: "mini",
                                  type: "text",
                                },
                                slot: "reference",
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          pageSizes: [10, 20, 50, 100, 200],
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialog.dialogTitle[_vm.editDialog.type],
            visible: _vm.editDialog.visible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "140px",
                inline: true,
                rules: _vm.editDialog.rules,
                model: _vm.editDialog.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row", attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主配送中心编码" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.mdcCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "mdcCode", $$v)
                              },
                              expression: "editDialog.form.mdcCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主配送中心名称", prop: "mdcName" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.mdcName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "mdcName", $$v)
                              },
                              expression: "editDialog.form.mdcName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主店铺编码" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.mshopCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "mshopCode", $$v)
                              },
                              expression: "editDialog.form.mshopCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主店铺名称", prop: "mshopName" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.mshopName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "mshopName", $$v)
                              },
                              expression: "editDialog.form.mshopName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "tmTable",
                  attrs: {
                    data: _vm.editDialog.form.shops,
                    height: "250px",
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(235 235 235)",
                      color: "black",
                      textAlign: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "配送中心编码",
                      prop: "dcCode",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(row.dcCode + "-" + row.dcName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "店铺编码",
                      prop: "shopCode",
                      width: "280",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(row.shopCode + "-" + row.shopName)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否主店铺",
                      prop: "ifMain",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(row.ifMain === 1 ? "是" : "否")),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创建人", prop: "opUser", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创建日期", prop: "opTime", width: "140" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "160",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: () =>
                                    _vm.editDialog.form.shops.splice(
                                      scope.$index,
                                      1
                                    ),
                                },
                              },
                              [_vm._v("移除")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: scope.row.ifMain === 1,
                                  size: "mini",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeMain(scope.row)
                                  },
                                },
                              },
                              [_vm._v("切换主店铺")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    border: "1px skyblue dashed",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { size: "mini", type: "text" },
                      on: { click: _vm.handleAddRow },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.editDialog.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.editDialog.type !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.editDialog.buttonLoading,
                        size: "mini",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择店铺",
            visible: _vm.shopDialog.visible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.shopDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "shopForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "140px",
                inline: true,
                rules: _vm.shopDialog.rules,
                model: _vm.shopDialog.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "配送中心编码", prop: "dcCode" } },
                        [
                          _c("DictSelect", {
                            ref: "mergeDc",
                            attrs: {
                              size: "mini",
                              type: "merge-dc",
                              value: _vm.shopDialog.form.dcCode,
                              linkageRef: "merge-shop",
                              dictFun: _vm.dictSelect,
                              remote: "",
                              fullLabel: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.shopDialog.form,
                                  "dcCode",
                                  $event
                                )
                              },
                              change: (val) =>
                                _vm.handleFormChange(val, "mergeDc"),
                              changeLinkageRef: _vm.handleChangeLinkageRef,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺编码", prop: "shopCOde" } },
                        [
                          _c("DictSelect", {
                            ref: "mergeShop",
                            attrs: {
                              size: "mini",
                              type: "merge-shop",
                              value: _vm.shopDialog.form.shopCode,
                              linkageRef: "regionSave",
                              dictFun: _vm.dictSelect,
                              remote: "",
                              fullLabel: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.shopDialog.form,
                                  "shopCode",
                                  $event
                                )
                              },
                              change: (val) =>
                                _vm.handleFormChange(val, "mergeShop"),
                              getOtherJsonParam: (callback) =>
                                callback({
                                  dcCode: _vm.shopDialog.form.dcCode,
                                }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.shopDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitShopForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }