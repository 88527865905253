<template>
  <div class="new-box">
    <div class="mg-search">
      <el-card style="margin: 10px">
        <SearchFilter @search="handleQueryAll" @reset="resetQuery" @keyup.enter.native="handleQueryAll">
              <el-form-item v-if="!defaultOrgCode" label="分公司">
                <DictSelect
                  ref="org"
                  type="ht-org"
                  :value.sync="queryForm.orgCode"
                  linkageRef="dc"
                  init
                  :initOptions="orgDictInitOptions"
                  fullLabel
                  :clearable="false"
                  :default-index="0"
                  placeholder="请选择分公司"
                  @changeLinkageRef="handleQueryChangeLinkageRef"
                />
              </el-form-item>
              <el-form-item label="配送中心">
                <DictSelect
                  ref="dc"
                  type="ht-dc"
                  :value.sync="queryForm.dcCode"
                  :dictFun="dictFun"
                  parentLinkageRef
                  fullLabel
                  @getOtherJsonParam="(callback) => callback(queryForm)"
                />
              </el-form-item>
              <el-form-item label="用户">
                <el-input v-model="queryForm.userName" placeholder="请输入用户账号或姓名" clearable />
              </el-form-item>
              <el-form-item label="职级">
                <el-select clearable v-model="queryForm.positionId" class="filter-item filter-set-width" size="mini" placeholder="请选择">
                  <el-option v-for="item in positionOptions" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="是否主要负责人">
                <el-select clearable v-model="queryForm.principal" class="filter-item filter-set-width" size="mini" placeholder="请选择">
                  <el-option v-for="item in options.principalOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账号状态">
                <el-select clearable v-model="queryForm.status" class="filter-item filter-set-width" size="mini" placeholder="请选择">
                  <el-option v-for="item in options.userStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
        </SearchFilter>
      </el-card>
    </div>
    <el-row>
      <el-col :span="7">
        <el-card style="margin: 10px;">
          <el-scrollbar style="height:calc(100vh); width: 100%">
              <el-tree
                ref="tree"
                node-key="id"
                class="unselect"
                v-loading="aside.loading"
                :highlight-current="true"
                :data="aside.treeData"
                :props="aside.defaultProps"
                :default-expanded-keys="aside.nodeKey"
                :expand-on-click-node="false"
                @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span><i v-if="data.positionId === 1 || data.positionId === 3" class="el-icon-folder"/><i v-else class="el-icon-user"/>{{node.label}}
                <i class="positionColor"> [{{positionOptions.filter(item => item.value === data.positionId).map(item => item.label)[0]}}]</i>
                </span>
              </span>
              </el-tree>
          </el-scrollbar>
        </el-card>
      </el-col>
      <el-col :span="17">
        <el-card style="margin: 10px;">
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit(undefined)">新增组织用户</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport">组织用户导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">组织用户导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-delete" type="warning" @click="handleBatchDelete">删除组织用户</el-button>
          <el-table
            style="margin-top: 10px;"
            :data="pageData.rows"
            v-loading="loading"
            size="small"
            row-class-name="rowClassName"
            @selection-change="handleSelectionChange"
            :show-checkbox="true"
          >
            <el-table-column type="selection" width="88"/>
            <el-table-column label="用户" prop="userName">
              <template #default="{ row }">
                <span>{{ row.userName + "-" + row.nickName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="账号状态" prop="status"/>
            <el-table-column label="分公司" prop="orgCode">
              <template #default="{ row }">
                <span>{{ row.orgCode + "-" + row.orgName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="配送中心" prop="dcCode">
              <template #default="{ row }">
                <span>{{ row.dcCode === 'ALL' ? '' : row.dcCode + "-" + row.dcName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="职级" prop="position"/>
            <el-table-column label="是否主要负责人" prop="principal"/>
            <el-table-column label="岗位名称" prop="positionName"/>
            <el-table-column label="团队名称" prop="teamName">
              <template #default="{ row }">
                <span>{{row.positionId === 6 ? '' : row.teamName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="主要负责人" prop="principalUser">
              <template #default="{ row }">
                <span>{{row.principalUserName === null ? '' : row.principalUserName + "-" + row.principalNickName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="上级" prop="parentUser">
              <template #default="{ row }">
                <span>{{row.parentUserName === null ? '' : row.parentUserName + "-" + row.parentNickName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" show-tooltip-when-overflow />
            <el-table-column label="操作" fixed="right" width="180px" align="center">
              <template #default="{ row }">
                <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
                <el-button size="mini" type="text" style="border: 0px;" @click="handleDelRow(row)">删除</el-button>
                <el-button v-if="row.positionId < 6" :disabled="row.principal==='是' && row.positionId <6" size="mini" type="text" style="border: 0px;" @click="handleChangeMain(row)">设为主负责人</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :title="editDialog.form.id?'编辑组织人员':'新增组织人员'" :visible.sync="editDialog.visible" append-to-body width="450px" :close-on-click-modal="false" top="5%">
      <el-form ref="editFormRef" size="mini" label-width="120px" label-position="right" :inline="true" :model="editDialog.form">
         <el-form-item label="关联用户" prop="userName" :required="true">
           <DictSelect
             ref="user"
             type="user"
             :disabled="editDialog.form.id!==undefined"
             :value.sync="editDialog.form.userName"
             :dictFun="dictUserFun"
             fullLabel
             remote
             version="v3"
             placeholder="请输入用户账号或姓名"
             :init-props="{ label: 'nickName', value: 'userName'}"
             @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
             @change="handleRespChange"
           />
         </el-form-item>
         <el-form-item label="分公司" prop="orgCode" :required="true">
           <DictSelect
             ref="org"
             type="org"
             :disabled="editDialog.form.id!==undefined"
             :value.sync="editDialog.form.orgCode"
             linkageRef="dc"
             init
             :initOptions="orgDictInitOptions"
             fullLabel
             :clearable="false"
             placeholder="请选择分公司"
             @changeLinkageRef="handleChangeLinkageRef"
           />
         </el-form-item>
         <el-form-item label="职级" prop="positionId" :required="true">
           <el-select v-model="editDialog.form.positionId" @change="val => handPositionChange(val, 'position')">
             <el-option v-for="item in positionOptions" :key="item.value" :label="item.label" :value="item.value" />
           </el-select>
         </el-form-item>
         <el-form-item label="配送中心" v-show="editDialog.form.positionId >= 3" prop="dcCode" :required="true">
           <el-select v-show="editDialog.form.positionId && editDialog.form.positionId === 3" v-model="editDialog.form.dcCode" filterable clearable @change="val => handPositionChange(val, 'dc')">
             <el-option v-for="item in allDcOptions" :key="item.value" :label="item.dcCode + '-' + item.dcName" :value="item.dcCode" />
           </el-select>
           <el-select v-show="editDialog.form.positionId && editDialog.form.positionId > 3" v-model="editDialog.form.dcCode" filterable clearable @change="val => handPositionChange(val, 'dc')">
             <el-option v-for="item in branchDcOptions" :key="item.value" :label="item.value + '-' + item.label" :value="item.value" />
           </el-select>
         </el-form-item>
         <el-form-item label="上级" v-show="editDialog.form.positionId > 1" :required="true">
           <el-select v-model="editDialog.form.parentId" filterable @change="val => {$forceUpdate();}">
             <el-option v-for="item in parentOptions" :key="item.id" :label="item.userName + (item.nickName ? '-' + item.nickName: '')" :value="item.id" />
           </el-select>
         </el-form-item>
         <el-form-item label="是否主要负责人" v-if="editDialog.form.positionId<6" :required="true">
           <el-radio-group v-model="editDialog.form.principal" :disabled="editDialog.principalDisabled && editDialog.form.positionId<5 && editDialog.form.positionId !==2"
                           @input="() => {handleGetDeputy(); $forceUpdate();}">
             <el-radio :label="1">是</el-radio>
             <el-radio :label="0">否</el-radio>
           </el-radio-group>
           <div style="width: 250px">
             <span style="color: red;">非主要负责人，不能成为下一职级的上级，可作为副手，享有主要负责人同等数据权限。</span>
           </div>
         </el-form-item>
         <el-form-item label="主要负责人" v-if="editDialog.form.positionId > 1 && editDialog.form.positionId<6 && editDialog.form.principal!==1" :required="true">
           <el-select v-model="editDialog.form.principalId" filterable clearable @change="val => {$forceUpdate();}">
             <el-option v-for="item in editDialog.deputyList" :key="item.id" :label="item.nickName + (item.positionName ? '-' + item.positionName: '')" :value="item.id" />
           </el-select>
         </el-form-item>
         <el-form-item label="岗位名称" prop="positionName" :required="true">
           <el-input v-model="editDialog.form.positionName" placeholder="请输入岗位名称" clearable @input="val => {
              editDialog.form.positionName = val
              $forceUpdate();
            }" />
         </el-form-item>
         <el-form-item label="团队名称" prop="teamName" v-if="editDialog.form.positionId < 6" :required="true">
           <el-input v-model="editDialog.form.teamName" placeholder="请输入团队名称" clearable @input="val => {
             editDialog.form.teamName = val
             $forceUpdate();
           }" />
         </el-form-item>
         <el-form-item label="备注">
           <el-input v-model="editDialog.form.remark" type="textarea" placeholder="请输入备注" clearable @input="val => {
              editDialog.form.remark = val
              $forceUpdate();
            }"  />
         </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button :disabled="buttonLoading" type="primary" size="mini" @click="handleSave">确定</el-button>
        <el-button type="primary" size="mini" @click="editDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="岗位人员" :visible.sync="viewDialog.visible" append-to-body width="1000px" :close-on-click-modal="false">
      <el-table
        ref="viewTableRef"
        :data="viewDialog.list"
        height="500px"
        v-loading="viewDialog.loading"
        size="mini"
        border
        style="margin-top: 5px"
      >
        <el-table-column v-if="viewDialog.queryParam.positionId < 6" label="主负责人" prop="orgName" width="80">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.principal===1?'是':'否' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" prop="nickName" width="150">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.userName + '-' + row.nickName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联组织" prop="orgName" width="200" >
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''" v-if="row.positionId<3">{{ row.orgCode + '-' + row.orgName }}</span>
            <span v-else :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''" >{{ row.dcCode ? (row.dcCode + "-" + (row.dcName? row.dcName: '')) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上级" prop="parentName" width="150">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ (row.parentUserName && row.parentName) ? (row.parentUserName + '-' + row.parentName): '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="岗位" prop="positionName" width="100">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.positionName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip>
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="{ row }">
            <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
            <el-button size="mini" type="text" style="border: 0px;" @click="handleDelRow(row)">删除</el-button>
            <el-button v-if="row.positionId < 6" :disabled="row.principal===1 && row.positionId <6" size="mini" type="text" style="border: 0px;" @click="handleChangeMain(row)">设为主负责人</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;border: 1px dashed skyblue;"> <el-button size="mini" type="text" style="border: 0px; width: 100%" @click="handleAdd">新增</el-button></div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <!--        <el-button type="primary" size="mini" @click="handleSave">确定</el-button>-->
        <el-button type="primary" size="mini" @click="viewDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div style="margin-top: 10px">
        <span><strong>导入类型</strong> </span>
        <el-radio-group v-model="importDialog.type">
          <el-radio label="add">新增
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              content="此方式导入，将在该职级下新增该用户，如果已经存在，导入不成功">
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </el-radio>
          <el-radio label="override">覆盖
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              content="此方式导入，将清空本分公司所有组织用户数据，导入本文档中的数据">
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </el-radio>
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  saveOrgBranch,
  checkMain,
  listBranch,
  delBranch,
  importBranchV2,
  exportBranchV2,
  branchDict,
  userSelect,
  changeMain,
  getOrgBranchTreeV1, pageOrgBranchV1, batchDelBranch
} from "@/api/appTask";
import DictSelect from "@/components/DictSelect";

import SearchFilter from "@/components/SearchFilter/index";
import { deepClone, excelExport } from "@/utils";
import {Notification} from "element-ui";

export default {
  name: "OrgBranch",
  components: { Pagination, DictSelect, SearchFilter},
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 20,
        orgCode: undefined,
        dcCode: undefined,
        userName: undefined,
        positionId: undefined,
        principal: undefined,
        status: undefined,
        branchIds: undefined,
        nodeSelectPositionId: undefined,
      },
      conditionChanged: false,
      loading: false,
      dictFun: dictSelect,
      dictUserFun: userSelect,
      orgDictInitOptions: [],
      positionOptions: [
        { value: 1, label : '正/副总经理'},
        { value: 2, label : '大区经理/省级经理'},
        { value: 3, label : '配送中心负责人'},
        { value: 4, label : '区域经理'},
        { value: 5, label : '业务经理'},
        { value: 6, label : '业务员'},
      ],
      options: {
        principalOptions: [{value: 1, label: '是'}, {value: 0, label: '否'}],
        userStatusOptions: [{value: 0, label: '正常'}, {value: 1, label: '关闭'}]
      },
      allDcOptions: [],
      branchDcOptions: [],
      parentOptions: [],
      defaultOrgCode: undefined,
      addOrgBranchInit: {
        orgCode: undefined,
        dcCode: undefined,
        positionId: undefined,
        parentId: undefined,
        principalId: undefined,
      },
      editDialog: {
        visible: false,
        principalDisabled: true,
        rules: {

        },
        deputyList: [],
        form: {
          orgCode: undefined,
          dcCode: undefined,
          positionId: undefined,
          userName: undefined,
          nickName: undefined,
          positionName: undefined,
          teamName: undefined,
          principal: 0,
          parentId: undefined,
          principalId: undefined,
          remark: undefined,
          parentName: undefined,
          parentPositionName: undefined,
        }
      },
      exportHeaders:
        {
          userName: '用户账号',
          positionIdName: '职级',
          orgCode: '分公司编码',
          dcCode: '配送中心编码',
          ifMain: '是否主要负责人',
          positionName: '岗位名称',
          teamName: '团队名称',
          mainUserName: '主要负责人账号',
          parentUserName: '上级账号',
          remark: '备注',
          errorMsg: '校验信息',
        },
      importDialog: {
        visible: false,
        type: 'add',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '分公司组织导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%88%86%E5%85%AC%E5%8F%B8%E7%BB%84%E7%BB%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV2.xlsx'
      },
      proMap: {
        name1: 1,
        name2: 2,
        name3: 3,
        name4: 4,
        name5: 5,
        name6: 6,
      },
      principalMap: {
        name1: 'p1Id',
        name2: 'p2Id',
        name3: 'p3Id',
        name4: 'p4Id',
        name5: 'p5Id',
        name6: 'p6Id',
      },
      viewDialog: {
        visible: false,
        loading: false,
        queryParam: {
          orgCode: undefined,
          dcCode: undefined,
          positionId: undefined,
        },
        list: [],
      },
      buttonLoading: false,
      colFields: ['orgCode','p1Id', 'p2Id','dcCode','p3Id','p4Id', 'p5Id', 'p6Id'],
      spanArr: [],
      aside: {
        loading: false,
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'teamName'
        },
        nodeKey: []
      },
      submitDisabled: false,
      defaultCheckedKeys: []
    };
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  created() {
    dictSelect('ht-org').then(res => {
      this.orgDictInitOptions = res.data;
      if (res.data.length > 0) {
        if (res.data.length === 1) {
          this.defaultOrgCode = res.data[0].value;
          this.queryForm.orgCode = this.defaultOrgCode;
          this.$refs['dc'].handleRefChange(this.defaultOrgCode, 'auto');
          this.handleQueryAll();
        }
      }
    });
  },
  mounted() {

  },
  watch: {
    'queryForm.orgCode' () {
      this.queryForm.branchIds = undefined;
      this.handleQueryAll();
    },
    'queryForm.dcCode' () {
      this.queryForm.branchIds = undefined;
      this.handleQueryAll();
    },
    'queryForm.userName' () {
      this.conditionChanged = true;
    },
    // 'queryForm.positionId' () {
    //   this.conditionChanged = true;
    // },
    'editDialog.form.orgCode' () {
      this.setTeamNameIfExist();
    }
  },
  methods: {
    handleQueryAll() {
      this.queryForm.branchIds = [];
      this.handleQueryTree();
      this.handleFilter();
    },
    handleQuery() {
      this.loading = true;
      if (this.conditionChanged === true) {
        this.handleQueryTree();
        this.conditionChanged = false;
      }

      const param = deepClone(this.queryForm);
      if (param.nodeSelectPositionId && !param.positionId) {
        param.positionId = param.nodeSelectPositionId;
      }
      pageOrgBranchV1(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
      // 清除节点选中
      this.queryForm.nodeSelectPositionId = undefined;
    },
    handleQueryTree() {
      this.aside.loading = true;
      getOrgBranchTreeV1(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.aside.treeData = res.data;
        if (this.aside.treeData?.length === 1) {
          this.aside.nodeKey.push(this.aside.treeData[0].id)
          if (this.aside.treeData[0].children?.length === 1) {
            this.aside.nodeKey.push(this.aside.treeData[0].children[0].id);
          }
        } else {
          this.aside.nodeKey = [];
        }
        this.aside.loading = false;
      })
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        orgCode: this.queryForm.orgCode,
        branchIds: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.addOrgBranchInit = {};
      this.$forceUpdate();
      this.handleQueryAll();
    },
    handleNodeClick(data, node) {
      this.parentTreeNodeList = [];
      this.getTreeParent(data, node);
      this.queryForm.nodeSelectPositionId = data.positionId;
      this.queryForm.branchIds = this.parentTreeNodeList.reverse();
      this.handleFilter()

      // 设置新增用户默认所属组织
      this.addOrgBranchInit.orgCode = data.orgCode;
      this.addOrgBranchInit.positionId = data.positionId;
      this.addOrgBranchInit.parentId = data.parentId;
      this.addOrgBranchInit.principalId = data.principalId;
      if (data.dcCode !== 'ALL') {
        this.addOrgBranchInit.dcCode = data.dcCode;
        this.loadOrgBranchDictByParam({orgCode: data.orgCode,positionId: data.positionId, dcCode: data.dcCode})
      } else {
        this.loadOrgBranchDictByParam({orgCode: data.orgCode, positionId: data.positionId})
      }
      this.$forceUpdate()
    },
    getTreeParent(data, node) {
      if (data.positionId === 1){
        this.parentTreeNodeList.push(data.id);
        return;
      }else if (data.positionId === 2) {
        this.parentTreeNodeList.push(data.id);
        this.parentTreeNodeList.push(data.parentId);
        return;
      } else {
        this.parentTreeNodeList.push(data.id);
        if (node.parent != null) {
          this.getTreeParent(node.parent.data, node.parent);
        }
      }
    },
    handleEdit(row) {
      if (row) {
        this.loadOrgBranchDictByParam(row);
      } else {
        const data = {
          orgCode: this.addOrgBranchInit.orgCode ? this.addOrgBranchInit.orgCode : this.defaultOrgCode,
          dcCode: this.addOrgBranchInit.dcCode,
          positionId: this.addOrgBranchInit.positionId,
          userName: undefined,
          nickName: undefined,
          positionName: undefined,
          teamName: undefined,
          principal: 0,
          parentId: this.addOrgBranchInit.parentId,
          principalId: this.addOrgBranchInit.principalId,
          remark: undefined,
          parentName: undefined,
          parentPositionName: undefined
        };
        this.editDialog.form = deepClone(data);
        this.handleCheckMain(data);
        this.setEditPositionName();
      }
      this.buttonLoading = false;
      this.editDialog.visible = true;
    },
    loadOrgBranchDictByParam(row) {
      if (row.positionId > 3) {
        branchDict('branch-dc?query=' + row.orgCode).then(res => {
          this.branchDcOptions = res.data;
        })
      } else if (row.positionId === 3) {
        branchDict('all-dc?query=' + row.orgCode).then(res => {
          this.allDcOptions = res.data;
        })
      }
      if (row.positionId) {
        row.positionName = this.positionOptions.find(e => e.value === row.positionId).label;
      }
      if (row.positionId > 1) {
        let param = {
          positionId: row.positionId -1,
          dcCode: row.dcCode,
          orgCode: row.orgCode,
          principal: 1,
        };
        if (param.positionId < 3) {
          param.dcCode = 'ALL';
        }
        listBranch(param).then(res => {
          this.parentOptions = res.data;
        })
      }
      const resp = row.userName?row.userName: '';
      this.$nextTick(() => {
        this.getUser(resp, row);
      })
      this.editDialog.form = deepClone(row);
      this.editDialog.form.principal = row.principal === '是' ? 1 : 0;
      this.handleGetDeputy(row);
      this.$forceUpdate();
    },
    setEditPositionName() {
      if (this.editDialog.form.positionId) {
        if (this.editDialog.form.positionId === 1) {
          this.editDialog.form.positionName = this.editDialog.form.principal === 1 ? '总经理' : '副总经理';
        } else if (this.editDialog.form.positionId === 2) {
          this.editDialog.form.positionName = '大区经理';
        } else {
          this.editDialog.form.positionName = this.positionOptions.find(e => e.value === this.editDialog.form.positionId).label;
        }
      }
    },
    getUser(resp, row) {
      if (this.$refs.user) {
        new Promise(async (resolve, reject) => {
          await this.$refs.user.remoteMethod(resp)
          resolve()
        }).then(() => {
          this.editDialog.form.userName = row.userName;
          this.editDialog.form.nickName = row.nickName;
          this.editDialog.form.teamName = row.teamName;
          this.$forceUpdate();
        })
      } else {
        setTimeout(() => this.getUser(resp, row), 200)
      }
    },
    handleQueryChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              this[dict] = Object.assign({}, form)
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        if (type === 'hand') {
          this.editDialog.form.dcCode = undefined;
          this.editDialog.form.positionId = undefined;
          // this.editDialog.form.userName = undefined;
          // this.editDialog.form.teamName = undefined;
          // this.editDialog.form.nickName = undefined;
          this.editDialog.form.positionName = undefined;
          this.editDialog.form.principal = 0;
          this.editDialog.form.parentId = undefined;
          this.editDialog.form.principalId = undefined;
          this.editDialog.form.remark = undefined;
          this.editDialog.form.parentName = undefined;
          this.editDialog.form.parentPositionName = undefined;
          this.handleCheckMain();
        }
      });
    },
    handleDcChange() {
      checkMain(this.editDialog.form).then(res => {
        this.editDialog.principalDisabled = res.data;
      })
    },
    handleCheckMain(row) {
      let param = deepClone(this.editDialog.form);
      param.principal =1;
      checkMain(param).then(res => {
        if (!res.data) {
          this.editDialog.form.principal = 1;
          this.editDialog.principalDisabled = true;
        } else {
          this.editDialog.principalDisabled = false;
          if (this.editDialog.form.positionId === 3 || this.editDialog.form.positionId===1) {
            this.editDialog.principalDisabled = true;
          }
          this.editDialog.form.principal = 0;
        }
        this.handleGetDeputy(row);
      })
    },
    handleAdd() {
      const row = {
        parentId: this.viewDialog.queryParam.parentId,
        positionId: this.viewDialog.queryParam.positionId,
        orgCode: this.viewDialog.queryParam.orgCode,
        dcCode: this.viewDialog.queryParam.dcCode,
        principalId: this.viewDialog.queryParam.principalId,
      }
      if (row.positionId) {
        row.positionName = this.positionOptions.find(e => e.value === row.positionId).label;
      }
      row.principal = 0;
      this.handleEdit(row);
      this.handleCheckMain(row);
      this.$forceUpdate();
    },
    handleGetDeputy(row) {
      if (!this.editDialog.form.principal && this.editDialog.form.positionId > 1 && this.editDialog.form.positionId < 6) {
        let param = {orgCode: this.editDialog.form.orgCode, dcCode: this.editDialog.form.dcCode, positionId: this.editDialog.form.positionId, principal: 1};
        listBranch(param).then(res => {
          this.editDialog.deputyList = res.data;
          if (res.data && res.data.length > 0) {
            if (row && row.principalId) {
              this.editDialog.form.principalId = row.principalId;
            } else {
              this.editDialog.form.principalId = res.data[0].id;
            }
          }
        })
      }
      this.setEditPositionName();
    },
    handPositionChange(val, type) {
      this.editDialog.form.principalId = undefined;
      let param = {
        positionId: this.editDialog.form.positionId -1,
        dcCode: this.editDialog.form.dcCode,
        orgCode: this.editDialog.form.orgCode,
        principal: 1,
      };
      if (param.positionId < 3) {
        param.dcCode = 'ALL';
      }
      if (type === 'position') {
        param.parentId = undefined;
        param.dcCode = undefined;
        param.principalId = undefined;
      } else if (type === 'dc') {
        param.principalId = undefined;
      }
      param.principal =1;
      listBranch(param).then(res => {
        this.parentOptions = res.data;
      })
      this.handleCheckMain();
      if (type === 'position') {
        this.branchDcOptions = [];
        if (val > 3) {
          this.editDialog.form.dcCode = this.editDialog.form.dcCode === 'ALL' ? undefined : this.editDialog.form.dcCode;
          branchDict('branch-dc?query=' + this.editDialog.form.orgCode).then(res => {
            this.branchDcOptions = res.data;
          })
        } else if (val === 3) {
          this.editDialog.form.dcCode = this.editDialog.form.dcCode === 'ALL' ? undefined : this.editDialog.form.dcCode;
          branchDict('all-dc?query=' + this.editDialog.form.orgCode).then(res => {
            this.allDcOptions = res.data;
          })
        } else {
          this.editDialog.form.dcCode = 'ALL';
        }
        if (val) {
          const name = this.positionOptions.find(e => e.value === val).label;
          this.editDialog.form.positionName = name
        }
      }

      // 区域经理上级为配送中心主负责人
      if (this.editDialog.form.positionId === 4) {
        this.editDialog.form.parentId = this.parentOptions.filter(e => e.principal = 1)[0]?.id
      } else {
        this.editDialog.form.parentId = undefined;
      }
      this.editDialog.form.principal = 0;
      this.$forceUpdate();
    },
    handleRespChange(val, type) {
      this.$nextTick(() => {
        if (type === 'hand') {
          let user = this.$refs.user.itemOptions.find(e => e.userName === val);
          user = user? user: { nickName: '', userName: ''}
          this.editDialog.form.userName = user.userName;
          // this.$set(this.editDialog.form, 'userName', user.userName)
          this.editDialog.form.nickName = user.nickName;
          this.editDialog.form.teamName = user.nickName + '团队';
          this.setTeamNameIfExist();
          this.$forceUpdate();
        }
      });
    },
    setTeamNameIfExist() {
      let nickName = this.editDialog.form.nickName;
      if (nickName && nickName !== '' && this.editDialog.form.orgCode) {
        const data = {
          orgCode: this.editDialog.form.orgCode,
          allName: '%'+nickName+'%',
        }
        listBranch(data).then(res => {
          if (res.data && res.data.length > 0) {
            this.editDialog.form.teamName = res.data[0].teamName;
            this.$forceUpdate();
          }
        })
      }
    },
    handleSave() {
      if (!this.editDialog.form.userName) {
        Notification.warning("关联用户不能为空");
        return;
      }
      if (!this.editDialog.form.orgCode) {
        Notification.warning("分公司不能为空");
        return;
      }
      if (!this.editDialog.form.dcCode) {
        Notification.warning("配送中心不能为空");
      }
      if (!this.editDialog.form.positionId) {
        Notification.warning("岗位不能为空");
        return;
      }
      if (!this.editDialog.form.positionName) {
        Notification.warning("岗位名称不能为空");
        return;
      }
      if (!this.editDialog.form.teamName) {
        Notification.warning("团队名称不能为空");
        return;
      }
      if (!this.editDialog.form.positionId > 1 && !this.editDialog.form.parentId) {
        Notification.warning("上级不能为空");
        return;
      }
      if (this.editDialog.form.positionId === 3) {
        if (!this.editDialog.form.dcCode) {
          Notification.warning("配送中心不能为空");
          return;
        }
        if (this.editDialog.form.principal === 0 && !this.editDialog.form.principalId) {
          Notification.warning("主要负责人不能为空");
          return;
        }
      }
      if (this.editDialog.form.positionId === 6) {
        this.editDialog.form.principal = 1;
      }
      this.buttonLoading = true;
      const param = deepClone(this.editDialog.form);
      saveOrgBranch(param).then(res => {
        this.buttonLoading = false;
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.resetQuery();
          this.handleFilter();
          this.editDialog.visible =false;
          // if (this.viewDialog.visible) {
          //   this.handleListBranch(this.viewDialog.queryParam);
          // }
          this.$refs.editFormRef.resetFields();
        } else {
          Notification.error(res.msg);
        }
      })
    },
    handleImport() {
      this.importDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.importDialog.type='add'
      this.importDialog.visible = true;
      this.submitDisabled = false;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.submitDisabled = true;
      importBranchV2(this.importDialog.type, fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.submitDisabled = false;
        this.loading = false;
        this.$refs.upload.clearFiles();
      })
    },
    downloadImportResult(data) {
      let title = '分公司组织导入校验结果';
      let headers = this.exportHeaders;
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
    handleExport() {
      this.loading = true;
      const param = Object.assign({}, this.queryForm);
      exportBranchV2(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '分公司组织导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCellClick(row, cell) {
      if (cell.property !== 'dcCode' && cell.property !== 'orgCode') {
        const positionId = this.proMap[cell.property];
        const principalId = this.principalMap[cell.property];
        let parentId;
        switch (cell.property) {
          case 'name2':
            parentId = row.p1Id;
            break;
          case 'name3':
            parentId = row.p2Id;
            break;
          case 'name4':
            parentId = row.p3Id;
            break;
          case 'name5':
            parentId = row.p4Id;
            break;
          case 'name6':
            parentId = row.p5Id;
            break;
        }
        let param = {
          orgCode: row.orgCode,
          dcCode: positionId > 2 ? row.dcCode : 'ALL',
          positionId: positionId,
          principalId: row[principalId],
          parentId: parentId
        }
        this.viewDialog.queryParam = param;
        this.viewDialog.list = [];
        this.handleListBranch(param);
      }
    },
    handleDelRow(row) {
      this.$confirm('请确认是否删除所选的数据?', '提示', { type: 'warning' }).then(() => {
        delBranch(row.id).then(res => {
          if (res.code ===200) {
            Notification.success("操作成功！");
            this.resetQuery();
            // this.handleListBranch(this.viewDialog.queryParam);
          } else {
            Notification.error(res.msg);
          }
        })
      }).catch(() => {
      })
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleBatchDelete() {
      if (!this.pageData.selections || this.pageData.selections.length <= 0) {
        Notification.warning('请先选中要批量删除的用户！');
        return;
      }
      this.$confirm('请确认是否删除所选的数据?', '提示', { type: 'warning' }).then(() => {
        let data = [];
        this.pageData.selections.forEach(selection => {
          if (selection.id) {
            data.push(selection.id);
          }
        });
        batchDelBranch(data).then(res => {
          if (res.code ===200) {
            Notification.success("操作成功！");
            this.resetQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleListBranch(param) {
      this.viewDialog.loading = true;
      listBranch(param).then(res => {
        this.viewDialog.list = res.data;
        this.viewDialog.visible = true;
        this.viewDialog.loading = false;
      })
    },
    handleChangeMain(row) {
      let param = Object.assign({}, row);
      param.principal = 1;
      changeMain(param).then((res) => {
        if (res.code ===200) {
          Notification.success("操作成功！");
          this.resetQuery();
          // this.viewDialog.queryParam.principalId = row.id;
          // if (row.positionId ==1) {
          //   this.viewDialog.queryParam.parentId = undefined;
          // }
          // this.handleListBranch(this.viewDialog.queryParam);
        } else {
          Notification.error(res.msg);
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
  overflow: visible
}

::v-deep .rowClassName {
  cursor: pointer !important;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

</style>

<style lang="scss">
::v-deep .columnClass {
  color: #0040ff !important;
  vertical-align: middle;
}
.aside-option {
  width: 400px;
  margin: 20px 0;
  //height: calc(100vh);
  background-color: #fff;
  font-weight: normal!important;
}
/* 鼠标浮动过的背景颜色 */
//.el-tree-node__content:hover {
//  background: #409EFF;
//  color: rgb(255, 255, 255);
//}
///* 鼠标点击时节点的背景颜色 */
//.el-tree-node:focus>.el-tree-node__content {
//  background-color: #409EFF !important;
//  color: rgb(255, 255, 255);
//}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #409EFF !important;
  color: rgb(255, 255, 255);
}
.positionColor {
  color: #909399;
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content>.custom-tree-node>span>.positionColor {
  background-color: #409EFF !important;
  color: rgb(255, 255, 255);
}
.unselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;

  /* 以下两个属性目前并未支持，写在这里为了减少风险 */
  -o-user-select: none;
  user-select: none;
}
</style>
