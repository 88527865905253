var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "prodCenter-page" }, [
    _c(
      "div",
      { staticClass: "prodCenter-search" },
      [
        _c("v-signboard", { attrs: { title: "起订量调整确认列表" } }),
        _c(
          "el-form",
          {
            ref: "minimumOrderAdjust-form",
            staticClass: "main-search",
            attrs: {
              size: "mini",
              "label-width": "auto",
              inline: true,
              model: _vm.form,
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品等级", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", multiple: "" },
                            model: {
                              value: _vm.form.productGrade,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "productGrade", $$v)
                              },
                              expression: "form.productGrade",
                            },
                          },
                          _vm._l(
                            _vm.options.productGradeOptions,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "大类", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.handleOptionChange($event, "max")
                              },
                            },
                            model: {
                              value: _vm.form.productFirstCategorie,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "productFirstCategorie", $$v)
                              },
                              expression: "form.productFirstCategorie",
                            },
                          },
                          _vm._l(
                            _vm.options.productMaxCategorieOtions,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "中类", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.handleOptionChange($event, "mid")
                              },
                            },
                            model: {
                              value: _vm.form.productMiddleCategorie,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "productMiddleCategorie",
                                  $$v
                                )
                              },
                              expression: "form.productMiddleCategorie",
                            },
                          },
                          _vm._l(
                            _vm.options.productMidCategorieOtions,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "小类", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.handleOptionChange($event, "min")
                              },
                            },
                            model: {
                              value: _vm.form.productLittleCategorie,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "productLittleCategorie",
                                  $$v
                                )
                              },
                              expression: "form.productLittleCategorie",
                            },
                          },
                          _vm._l(
                            _vm.options.productMinCategorieOtions,
                            function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产类型", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.form.prod_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "prod_type", $$v)
                              },
                              expression: "form.prod_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "生产中心", value: "生产中心" },
                            }),
                            _c("el-option", {
                              attrs: { label: "OEM中心", value: "OEM中心" },
                            }),
                            _c("el-option", {
                              attrs: { label: "办公", value: "办公" },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: "生产中心/办公",
                                value: "生产中心/办公",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否有客订", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.form.bo_kd_plu_count_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bo_kd_plu_count_type", $$v)
                              },
                              expression: "form.bo_kd_plu_count_type",
                            },
                          },
                          [
                            _c("el-option", {
                              key: 1,
                              attrs: { label: "是", value: 1 },
                            }),
                            _c("el-option", {
                              key: 2,
                              attrs: { label: "否", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "原始料号", size: "mini" } },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入原始料号",
                          },
                          model: {
                            value: _vm.form.material_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "material_code", $$v)
                            },
                            expression: "form.material_code",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8, lg: 6, xl: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "是否需要确认", size: "mini" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.form.prod_confirm_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "prod_confirm_type", $$v)
                              },
                              expression: "form.prod_confirm_type",
                            },
                          },
                          [
                            _c("el-option", {
                              key: 1,
                              attrs: { label: "是", value: 1 },
                            }),
                            _c("el-option", {
                              key: 2,
                              attrs: { label: "否", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    attrs: {
                      size: "mini",
                      type: "primary",
                      loading: _vm.btnStatus.isSub,
                    },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("查 询 ")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    attrs: { size: "mini", loading: _vm.btnStatus.isReset },
                    on: { click: _vm.handleReset },
                  },
                  [_vm._v("重 置 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: "primary",
                      loading: _vm.btnStatus.isExportLoading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleTableHeadAction("export")
                      },
                    },
                  },
                  [_vm._v("导 出 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.handleTableHeadAction("adjust")
                      },
                    },
                  },
                  [_vm._v("确 认 调 整 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-col",
          {
            staticClass: "prodCenter-tips",
            staticStyle: { "margin-top": "10px" },
          },
          [_vm._v("本月度低于起订量的产品如下表所示，请确认调整")]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableStatus.isLoading,
                expression: "tableStatus.isLoading",
              },
            ],
            ref: "multipleTable",
            staticClass: "table minimumOrderAdjust-table",
            attrs: { stripe: "", border: "", size: "mini", data: _vm.lists },
            on: { "selection-change": _vm.handleselection },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "40", fixed: "left" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "序号",
                align: "center",
                width: "50",
                fixed: "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            scope.$index +
                              (_vm.currentPage - 1) * _vm.pageSize +
                              1
                          ) + " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._l(
              _vm.tableObj.minimumOrderConfirmHead,
              function (item, index) {
                return [
                  item.prop == "is_prod_confirm"
                    ? _c(
                        "el-table-column",
                        _vm._b(
                          {
                            key: index,
                            attrs: { fixed: "right", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "prodConfirm-btn" },
                                        [
                                          scope.row.need_prod_confirm == 1
                                            ? _vm._l(
                                                scope.row.prodConfirmBtn,
                                                function (item1, confirId) {
                                                  return _c(
                                                    "el-button",
                                                    {
                                                      key: confirId + "only",
                                                      attrs: {
                                                        type: item1.isSelect
                                                          ? "primary"
                                                          : "",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleProdConfirm(
                                                            scope.$index,
                                                            confirId,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item1.btnText) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            : _c(
                                                "el-link",
                                                {
                                                  staticStyle: {
                                                    "font-size": "13px",
                                                  },
                                                  attrs: {
                                                    type: "info",
                                                    disabled: "",
                                                  },
                                                },
                                                [_vm._v("无需操作")]
                                              ),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          "el-table-column",
                          item,
                          false
                        )
                      )
                    : [
                        "plan_month",
                        "sub_category_name",
                        "material_code",
                        "trans_material_code",
                      ].includes(item.prop)
                    ? _c(
                        "el-table-column",
                        _vm._b(
                          {
                            key: index,
                            attrs: {
                              fixed: "left",
                              "show-overflow-tooltip": "",
                              align: "center",
                            },
                          },
                          "el-table-column",
                          item,
                          false
                        )
                      )
                    : _c(
                        "el-table-column",
                        _vm._b(
                          {
                            key: index,
                            attrs: {
                              "show-overflow-tooltip": "",
                              align: "center",
                            },
                          },
                          "el-table-column",
                          item,
                          false
                        )
                      ),
                ]
              }
            ),
          ],
          2
        ),
        _c("v-pagination", {
          attrs: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            currentPage: _vm.currentPage,
          },
          on: { pageChange: _vm.pagingChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }