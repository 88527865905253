<template>
  <div class="new-box">
    <div class="mg-search" >
      <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery"  @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="ht-org"
            :value.sync="queryForm.orgCode"
            fullLabel
            :init="true"
            :init-options="this.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="标签名称">
          <el-input v-model="queryForm.tagName"  clearable placeholder="标签名称" />
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="queryForm.queryDate"
            type="datetime"
            placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit">新增</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
       <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit">新增</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
      class="customer-table"
      border
           size="small"
      v-loading="loading"
      ref="table"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="名称" prop="tagName"/>
      <el-table-column label="分公司" prop="orgName" />
      <el-table-column label="开始时间" prop="startDate" width="200" />
      <el-table-column label="结束时间" prop="endDate" width="200" />
      <el-table-column label="创建人" prop="createUser"  />
      <el-table-column label="创建时间" prop="createTime" width="200" />
      <el-table-column label="操作" width="180" align="center">
        <template #default="{ row }">
          <div>
            <el-row>
              <el-col :span="6"><el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row, 'view')">查看</el-button></el-col>
              <el-col v-if="orgAllAuth || row.orgCode!=='ALL'" :span="6"><el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row, 'edit')">编辑</el-button></el-col>
              <el-col v-if="orgAllAuth || row.orgCode!=='ALL'" :span="6">
                <el-popconfirm
                  :title="'请确认是否要删除标签(' + row.tagName + ')?'"
                  @confirm="handleDelete(row)"
                >
                  <el-button slot="reference" size="mini" type="text" style="border: 0px;">删除</el-button>
                </el-popconfirm>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="editDialog.dialogTitle[editDialog.type]" :visible.sync="editDialog.visible" width="50%" :close-on-click-modal="false">
      <Edit ref="edit" :type="editDialog.type" :id.sync="editDialog.id" />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" :disabled="editDialog.buttonLoading" @click="editDialog.visible = false">取 消</el-button>
        <el-button v-if="editDialog.type!=='view'" :disabled="editDialog.buttonLoading" size="mini" type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageSkuTags, getSkuTagDetail, saveSkuTag, delSkuTag, dictSelect } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import Edit from "./compoments/Edit";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect";

export default {
  name: "SkuTag",
  components: { Pagination, Edit, DictSelect, SearchFilter },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      dictSelect,
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        tagName: undefined,
        queryDate: undefined,
      },
      queryDate: [],
      loading: false,
      dictParam: {},
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      editDialog: {
        visible: false,
        disabled: false,
        buttonLoading: false,
        dialogTitle: {
          edit: '编辑',
          view: '查看'
        },
        id: undefined,
        type: 'edit',
        form: {
          orgs: [],
          models: [],
        },
        rules: {
        },
      },
      orgAllAuth: false,
      orgOptions: []
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.orgOptions = res.data;
      this.handleFilter();
    })
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      pageSkuTags(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImageUrls(urls) {
      return urls && urls.length > 0? urls.split(','): [];
    },
    handleEdit(row, type) {
      this.editDialog.id = row? row.id : undefined;
      this.editDialog.type = type;
      if (this.$refs.edit && !row.id) {
        this.$refs.edit.getDetail(undefined);
      }
      this.editDialog.visible = true;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this[dict] = {oneCategory: this.queryForm.oneGradeCategoryName}
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDelete(row) {
      delSkuTag(row.id).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      });
    },
    submitForm() {
      this.$refs.edit.$refs.form.validate(valiad => {
        if (valiad) {
          let form = deepClone(this.$refs.edit.form);
          if (form.models.length  == 0) {
            Notification.warning("商品数据不能为空！");
            return;
          }
          let orgs = [];
          form.orgs.forEach(e => {
              orgs.push(e);
          })
          form.startDate = form.rangeTime[0];
          form.endDate = form.rangeTime[1];
          form.id = this.editDialog.id
          form.orgs = this.orgOptions.filter(e => form.orgs.indexOf(e.value) > -1 && e.value!=='ALL').map(e =>  {return { orgCode: e.value, orgName: e.label}});
          saveSkuTag(form).then(res => {
            if (res.code  == 200) {
              Notification.success("操作成功");
              this.editDialog.visible = false;
              this.handleFilter();
            }
          })
        }
      })
    },
    handleQuerySku(type, page) {
      const filterData = this.form[type].filter(e => {
        return !this.querySkuKeyWord || e.skuModel.indexOf(this.querySkuKeyWord) > -1;
      })
      const total = this[page].current * this[page].size;
      this[page].total = filterData.length;
      this[page].rows = filterData.slice(this[page].size * (this[page].current - 1), total >= filterData.length ? filterData.length : this[page].current * this[page].size);
      const tableRef = 'skuTableRef';
      this.$nextTick(() => {
        if(this.$refs[tableRef]) {
          this.$refs[tableRef].doLayout();
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
.skuUrlThum {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  ::v-deep {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
