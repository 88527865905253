<template>
  <el-select ref="goodsCategoryInput" v-model="selectedCategory.categoryCode" @clear="clearSkuCategory" clearable placeholder="请选择" popper-class="virtualSelect" fixed>
    <el-input
      placeholder="输入关键字搜索"
      v-model="options.goodsCategory.filterText"
      @click.stop.native
      class="sel-input"
      v-if="options.goodsCategory.data.length !== 0"
    >
    </el-input>
    <el-option :value="selectedCategory.categoryCode" :label="selectedCategory.categoryName" class="sel-option">
      <el-tree
        :data="options.goodsCategory.data"
        :props="options.goodsCategory.dataProps"
        :node-key="selectedCategory.categoryCode"
        :accordion="true"
        ref="goodsCategory"
        @node-click="handleNodeClick"
        :filter-node-method="filterNode"
        :popper-append-to-body="false"
      >
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
import { getAllCategories } from "@/api/biOrg";

export default {
  name: "GoodsCategory",
  props: {},
  data() {
    return {
      selectedCategory: {
        categoryCode: undefined,
        categoryName: undefined,
        categoryType: undefined,
      },
      options: {
        goodsCategory: {
          filterText: '',
          defaultExpandAll: false,
          data:  [],
          dataProps: {
            value: 'categoryCode',
            label: 'categoryName',
            children: 'subCategories'
          },
        },
      },
      formattedResult: {
        mainCategoryCode: undefined,
        midCategoryCode: undefined,
        subCategoryCode: undefined,
      }
    };
  },
  created() {
    getAllCategories().then(res => this.options.goodsCategory.data = this.filterTree(res.data, node => node.categoryCode === ''));
  },
  watch: {
    'options.goodsCategory.filterText' (val) {
      this.$refs.goodsCategory.filter(val)
    },
  },
  methods: {
    filterNode (value, data) {
      if (!value) return true
      return data.categoryName.indexOf(value) !== -1
    },
    handleNodeClick (node) {
      this.selectedCategory.categoryCode = node.categoryCode;
      this.selectedCategory.categoryName = node.categoryName;
      this.selectedCategory.categoryType = node.type;
      if (this.selectedCategory.categoryType && this.selectedCategory.categoryType) {
        this.resetSkuCategory();
        switch (this.selectedCategory.categoryType) {
          case 'main':
            this.formattedResult.mainCategoryCode = this.selectedCategory.categoryCode;
            break;
          case 'mid':
            this.formattedResult.midCategoryCode = this.selectedCategory.categoryCode;
            break;
          case 'sub':
            this.formattedResult.subCategoryCode = this.selectedCategory.categoryCode;
            break;
        }
      }
      this.$emit("selected", this.formattedResult)
      this.$refs.goodsCategoryInput.blur();
    },
    clearSkuCategory() {
      this.selectedCategory.categoryCode = undefined;
      this.selectedCategory.categoryName = undefined;
      this.selectedCategory.categoryType = undefined;
      this.resetSkuCategory();
      this.$emit("selected", this.formattedResult)
    },
    resetSkuCategory() {
      this.formattedResult.mainCategoryCode = undefined;
      this.formattedResult.midCategoryCode = undefined;
      this.formattedResult.subCategoryCode = undefined;
    },
    filterTree(tree, predicate) {
      return tree.filter(node => {
        if (predicate(node)) {
          return false;
        }
        if (node.subCategories) {
          node.subCategories = this.filterTree(node.subCategories, predicate);
        }
        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sel-option {
  width: 200px;
  height: auto;
  max-height: 200px;
  overflow: visible;
  background-color: #fff;
  cursor: pointer;
  font-weight: normal!important;
  padding: 0 5px;
}
.sel-input {
  width: 200px;
  margin: 5px;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: #ffffff;
}
</style>
