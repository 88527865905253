var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-aside",
        {
          staticStyle: {
            width: "400px",
            height: "100vh",
            "border-right": "1px solid #e3e0e0",
            "background-color": "#d0d6db",
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { color: "dodgerblue", "text-align": "center" } },
              [_c("h1", [_vm._v("APP界面预览")])]
            ),
            _vm.taskForm.configJson
              ? _c(
                  "div",
                  { staticClass: "group col2" },
                  [
                    _vm.templateType === "big"
                      ? _c("TaskBig", {
                          attrs: {
                            "class-name": "group col2",
                            task: _vm.taskForm,
                          },
                        })
                      : _vm._e(),
                    _vm.templateType === "small"
                      ? _c("TaskSmall", {
                          attrs: {
                            "class-name": "group col2",
                            task: _vm.taskForm,
                          },
                        })
                      : _vm._e(),
                    _vm.templateType === "mini"
                      ? _c("TaskMini", {
                          attrs: {
                            "class-name": "group col3",
                            task: _vm.taskForm,
                          },
                        })
                      : _vm._e(),
                    _vm.templateType === "horizontal"
                      ? _c("TaskHorizontal", { attrs: { task: _vm.taskForm } })
                      : _vm._e(),
                    _vm.templateType === "highRowOne"
                      ? _c("TaskHighRowOne", { attrs: { task: _vm.taskForm } })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "el-main",
        [
          _c(
            "el-form",
            {
              ref: "groupForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "120px",
                inline: true,
                model: _vm.form,
                "label-position": "left",
              },
            },
            [
              !_vm.isGroup
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择任务", prop: "name" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled:
                              (_vm.form && _vm.form.mtTaskId !== undefined) ||
                              _vm.uploading,
                            placeholder: "请选择任务",
                            filterable: "",
                          },
                          on: { change: _vm.handleTaskChange },
                          model: {
                            value: _vm.dataForm.mtTaskId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "mtTaskId", $$v)
                            },
                            expression: "dataForm.mtTaskId",
                          },
                        },
                        _vm._l(_vm.tasks, function (item) {
                          return _c("el-option", {
                            key: item.mtTaskId,
                            attrs: {
                              label:
                                item.isLeaf === 0
                                  ? item.taskName + "(合集)"
                                  : item.taskName,
                              value: item.mtTaskId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isGroup
                ? _c(
                    "el-form-item",
                    { attrs: { label: "合集名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入合集名称" },
                        model: {
                          value: _vm.dataForm.taskName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataForm, "taskName", $$v)
                          },
                          expression: "dataForm.taskName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "显示名（选填，如输入空格则不显示名称）",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.dataForm.taskText,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "taskText", $$v)
                      },
                      expression: "dataForm.taskText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "扣除白色背景", prop: "name" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.uploading },
                      on: {
                        input: (val) => {
                          _vm
                            .handleRemoveSkuBg(val, _vm.dataForm.skuImage)
                            .then((res) => {
                              _vm.dataForm.skuImage = res
                            })
                        },
                      },
                      model: {
                        value: _vm.dataForm.removeSkuBg,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "removeSkuBg", $$v)
                        },
                        expression: "dataForm.removeSkuBg",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true, value: true } }, [
                        _vm._v("是"),
                      ]),
                      _c(
                        "el-radio",
                        { attrs: { label: false, value: false } },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务样式", prop: "template" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: _vm.handleImageTemplateChange },
                      model: {
                        value: _vm.dataForm.backGroundCustom,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "backGroundCustom", $$v)
                        },
                        expression: "dataForm.backGroundCustom",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: false, value: false } },
                        [
                          _vm._v("使用模板 "),
                          _vm.templateType !== "horizontal" &&
                          _vm.templateType !== "highRowOne"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "商品图", prop: "name" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: _vm.uploading },
                                      on: {
                                        input: (val) => {
                                          if (
                                            !_vm.dataForm.skuCustom &&
                                            _vm.taskForm &&
                                            _vm.taskForm.skuUrl
                                          ) {
                                            _vm.dataForm.skuImage =
                                              _vm.taskForm.skuUrl
                                          }
                                          // if (dataForm.skuCustom) {
                                          //   dataForm.taskNameColor = colorMap.auto.taskNameColor;
                                          //   dataForm.bgColor = colorMap.auto.bgColor;
                                          // }
                                        },
                                      },
                                      model: {
                                        value: _vm.dataForm.skuCustom,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "skuCustom",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.skuCustom",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: false, value: false },
                                        },
                                        [_vm._v("自动")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true, value: true } },
                                        [
                                          _c("span", [_vm._v("自定义")]),
                                          _vm.dataForm.skuCustom
                                            ? _c("OssUpload", {
                                                ref: "ossUpload",
                                                staticClass:
                                                  "custom-picture-card special-tips",
                                                staticStyle: {
                                                  display: "table",
                                                },
                                                attrs: {
                                                  "value-to":
                                                    _vm.dataForm.skuImage,
                                                  maxFileSize: 1024,
                                                  "list-type": "picture-card",
                                                  limit: 1,
                                                  "always-show-button": false,
                                                  tips: "尺寸5116*750",
                                                  tipMessageShow: false,
                                                  "accept-type": _vm.imgTypes,
                                                },
                                                on: {
                                                  "update:valueTo": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.dataForm,
                                                      "skuImage",
                                                      $event
                                                    )
                                                  },
                                                  "update:value-to": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.dataForm,
                                                      "skuImage",
                                                      $event
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.dataForm.backGroundCustom
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "模板背景图", prop: "name" },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        input: (val) => {
                                          if (
                                            !_vm.dataForm.backGroundCustom &&
                                            _vm.bgImg[_vm.templateType]
                                          ) {
                                            _vm.dataForm.backGroundImage =
                                              _vm.bgImg[_vm.templateType][val]
                                            _vm.dataForm.bgTemplate =
                                              _vm.dataForm.backTemplate
                                          } else if (
                                            !_vm.dataForm.backGroundCustom
                                          ) {
                                            _vm.dataForm.backGroundImage = ""
                                          }

                                          if (
                                            _vm.templateType === "mini" ||
                                            _vm.templateType === "small"
                                          ) {
                                            _vm.dataForm.taskNameColor =
                                              _vm.colorMap[
                                                _vm.dataForm.backTemplate
                                              ].taskNameColor
                                            _vm.dataForm.bgColor =
                                              _vm.colorMap[
                                                _vm.dataForm.backTemplate
                                              ].bgColor
                                            _vm.dataForm.moreDataBg =
                                              _vm.colorMap[
                                                _vm.dataForm.backTemplate
                                              ].moreDataBg
                                            if (_vm.taskForm.isLeaf === 0) {
                                              _vm.dataForm.moreDataText =
                                                _vm.colorMap[
                                                  _vm.dataForm.backTemplate
                                                ].taskNameColor
                                            }
                                          } else if (
                                            _vm.templateType === "horizontal" ||
                                            _vm.templateType === "highRowOne"
                                          ) {
                                            _vm.dataForm.taskNameColor =
                                              "#ffffff"
                                            _vm.dataForm.fontColor = "#ffffff"
                                          } else if (
                                            _vm.templateType === "big"
                                          ) {
                                            _vm.dataForm.taskNameColor =
                                              _vm.colorMap[
                                                _vm.templateType +
                                                  "_" +
                                                  _vm.dataForm.backTemplate
                                              ].taskNameColor
                                          }
                                        },
                                      },
                                      model: {
                                        value: _vm.dataForm.backTemplate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "backTemplate",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.backTemplate",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        _vm._l(
                                          _vm.template[_vm.templateType],
                                          function (item, key) {
                                            return _c(
                                              "el-col",
                                              { key: key, attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    staticStyle: {
                                                      "min-width": "150px",
                                                    },
                                                    attrs: {
                                                      label: key,
                                                      value: key,
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      style:
                                                        _vm.templateType ===
                                                          "horizontal" ||
                                                        _vm.templateType ===
                                                          "highRowOne"
                                                          ? {
                                                              height: "60px",
                                                              display: "block",
                                                              "max-width":
                                                                "200px",
                                                            }
                                                          : {
                                                              height: "80px",
                                                              display: "block",
                                                            },
                                                      attrs: { src: item },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.templateType !== "small" &&
                      _vm.templateType !== "mini"
                        ? _c(
                            "el-radio",
                            {
                              staticStyle: { display: "block" },
                              attrs: { label: true, value: true },
                            },
                            [
                              _vm._v("自定义 "),
                              _vm.dataForm.backGroundCustom
                                ? _c("OssUpload", {
                                    ref: "ossUpload",
                                    staticClass:
                                      "custom-picture-card special-tips",
                                    staticStyle: { display: "table" },
                                    attrs: {
                                      "value-to": _vm.dataForm.backGroundImage,
                                      maxFileSize: 1024,
                                      "list-type": "picture-card",
                                      limit: 1,
                                      "always-show-button": false,
                                      tips: _vm.tipsMap[_vm.templateType],
                                      tipMessageShow: false,
                                      "accept-type": _vm.imgTypes,
                                    },
                                    on: {
                                      "update:valueTo": function ($event) {
                                        return _vm.$set(
                                          _vm.dataForm,
                                          "backGroundImage",
                                          $event
                                        )
                                      },
                                      "update:value-to": function ($event) {
                                        return _vm.$set(
                                          _vm.dataForm,
                                          "backGroundImage",
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.templateType === "small" || _vm.templateType === "mini"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "颜色配置", prop: "template" } },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { display: "table" } },
                        _vm._l(_vm.options.blockColor, function (item, index) {
                          return _c(
                            "el-col",
                            { key: index, attrs: { span: 6 } },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "vertical-align": "text-bottom",
                                  },
                                },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _c("el-color-picker", {
                                attrs: {
                                  "show-alpha": item.value === "contentDataBg",
                                },
                                model: {
                                  value: _vm.dataForm[item.value],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, item.value, $$v)
                                  },
                                  expression: "dataForm[item.value]",
                                },
                              }),
                              item.value === "bgColor"
                                ? _c("OssUpload", {
                                    ref: "ossUpload",
                                    refInFor: true,
                                    staticClass: "custom-picture-card",
                                    staticStyle: { display: "table" },
                                    attrs: {
                                      "value-to": _vm.dataForm.backGroundImage,
                                      maxFileSize: 1024,
                                      "list-type": "picture-card",
                                      limit: 1,
                                      "always-show-button": false,
                                      tips: _vm.tipsMap[_vm.templateType],
                                      tipMessageShow: false,
                                      "accept-type": _vm.imgTypes,
                                    },
                                    on: {
                                      "update:valueTo": function ($event) {
                                        return _vm.$set(
                                          _vm.dataForm,
                                          "backGroundImage",
                                          $event
                                        )
                                      },
                                      "update:value-to": function ($event) {
                                        return _vm.$set(
                                          _vm.dataForm,
                                          "backGroundImage",
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.templateType === "big" ||
              _vm.templateType === "horizontal" ||
              _vm.templateType === "highRowOne"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "数据颜色", prop: "color" } },
                    [
                      _c("div", { staticStyle: { display: "inline-flex" } }, [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { display: "inline-flex" } },
                              [_vm._v("数据文字颜色")]
                            ),
                            _c("el-color-picker", {
                              model: {
                                value: _vm.dataForm.fontColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "fontColor", $$v)
                                },
                                expression: "dataForm.fontColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { display: "inline-flex" } },
                              [_vm._v("标题颜色")]
                            ),
                            _c("el-color-picker", {
                              model: {
                                value: _vm.dataForm.taskNameColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "taskNameColor", $$v)
                                },
                                expression: "dataForm.taskNameColor",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { display: "inline-flex" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.taskForm.isLeaf !== 0
                                      ? "更多数据底色"
                                      : "任务合集底色"
                                  )
                                ),
                              ]
                            ),
                            _c("el-color-picker", {
                              model: {
                                value: _vm.dataForm.moreDataBg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "moreDataBg", $$v)
                                },
                                expression: "dataForm.moreDataBg",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { display: "inline-flex" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.taskForm.isLeaf !== 0
                                      ? "更多数据颜色"
                                      : "任务合集颜色"
                                  )
                                ),
                              ]
                            ),
                            _c("el-color-picker", {
                              model: {
                                value: _vm.dataForm.moreDataText,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "moreDataText", $$v)
                                },
                                expression: "dataForm.moreDataText",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isNoGroup
                ? _c(
                    "div",
                    [
                      _c("label", [_vm._v("设置展示字段")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.showCustomFields = !_vm.showCustomFields
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.showCustomFields ? "隐藏" : "展开"))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCustomFields && _vm.isNoGroup,
                      expression: "showCustomFields && isNoGroup",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "800px" },
                      attrs: {
                        label: "选择任务汇总的展示字段",
                        prop: "summaryFields",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-light" }, [
                        _vm._v(
                          "最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据"
                        ),
                      ]),
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { width: "700px" },
                          attrs: { max: 2 },
                          model: {
                            value: _vm.dataForm.summaryFields,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "summaryFields", $$v)
                            },
                            expression: "dataForm.summaryFields",
                          },
                        },
                        _vm._l(_vm.summaryFieldMap, function (item, key) {
                          return _c(
                            "div",
                            { key: key },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 3 } }, [
                                    _c("span", { staticClass: "text-light" }, [
                                      _vm._v(_vm._s(key)),
                                    ]),
                                  ]),
                                  _vm._l(item, function (e) {
                                    return _c(
                                      "el-col",
                                      { key: e.title, attrs: { span: 5 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              value: e.key,
                                              label: e.key,
                                            },
                                          },
                                          [_vm._v(_vm._s(e.title))]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "800px" },
                      attrs: {
                        label: "选择店铺页的任务展示字段",
                        prop: "shopFields",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-light" }, [
                        _vm._v(
                          "最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据"
                        ),
                      ]),
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { width: "700px" },
                          attrs: { max: 2 },
                          model: {
                            value: _vm.dataForm.shopFields,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "shopFields", $$v)
                            },
                            expression: "dataForm.shopFields",
                          },
                        },
                        _vm._l(_vm.shopFieldMap, function (item, key) {
                          return _c(
                            "div",
                            { key: key },
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 3 } }, [
                                    _c("span", { staticClass: "text-light" }, [
                                      _vm._v(_vm._s(key)),
                                    ]),
                                  ]),
                                  _vm._l(item, function (e) {
                                    return _c(
                                      "el-col",
                                      { key: e.title, attrs: { span: 5 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              value: e.key,
                                              label: e.key,
                                            },
                                          },
                                          [_vm._v(_vm._s(e.title))]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }