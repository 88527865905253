let time = new Date();
let currentYear = time.getFullYear();
let currentMonth = time.getMonth() + 1;

let planYear = currentYear;
let preYear = currentYear;
let planMonth = currentMonth + 1;
let preMonth = currentMonth - 1;
if (planMonth > 12) {
  planMonth = planMonth - 12;
  planYear = planYear + 1;
}
if (preMonth <= 0) {
  preYear = preYear - 1;
  preMonth = 12;
}

const currentYM = currentYear + "" + (currentMonth < 10 ? "0" + currentMonth: currentMonth);
const planYM = planYear + "" + (planMonth < 10 ? "0" + planMonth : planMonth);
const preYm = preYear + "" + (preMonth < 10 ? "0" + preMonth : preMonth);
const currentShortYM = (currentMonth < 10 ? "0" + currentMonth: currentMonth);
const planShortYM = (planMonth < 10 ? "0" + planMonth : planMonth);
const preShortYm = (preMonth < 10 ? "0" + preMonth : preMonth);


const customAdjustHead = [
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '品名', prop: 'plu_name', minWidth: '150px'},
  {label: '等级', prop: 'grade', minWidth: '60px'},
  {label: '月均销量', prop: 'hq_month_avg_sale_count_box', minWidth: '80px'},
  {label: currentShortYM + '月底预计库存', prop: 'hq_last_month_store_count_box', minWidth: '120px'},
  {label: currentShortYM + '月底预计库存系数', prop: 'hq_last_month_store_ratio', minWidth: '140px'},
  {label: '分公司当前库存', prop: 'bo_last_month_stock_count_box', minWidth: '120px'},
  {label: '分公司当前库存系数', prop: 'bo_last_month_stock_ratio', minWidth: '140px'},
  {label: planShortYM + '月系统欠量', prop: 'hq_this_month_ql_box', minWidth: '120px'},
  {label: planShortYM + '月月单客订', prop: 'bo_kd_plu_count_box', minWidth: '120px'},
  {label: planShortYM + '月合计订单', prop: 'hq_this_month_total_count_box', minWidth: '120px'},
  {label: planShortYM +'月已下量', prop: 'hq_previous_put_count_box', minWidth: '120px'},
  {label: planShortYM + '月底预计库存', prop: 'hq_expect_store_count_box', minWidth: '120px'},
  {label: planShortYM + '月底预计库存系数', prop: 'hq_expect_store_ratio', minWidth: '140px'},
  {label: '预排产量', prop: 'confirm_arrange_box_count', minWidth: '130px'},
  {label: '理论请购量', prop: 'theory_shipment_count_box', minWidth: '120px'},
  {label: '算法调整量', prop: 'algorithm_adjust_count_box', minWidth: '100px'},

  {label: '起订量', prop: 'min_order_count_box', minWidth: '100px'},
  {label: '统计工艺', prop: 'pen_craft_total', minWidth: '100px'},
  {label: '模号', prop: 'mould_code', minWidth: '60px'},
  {label: '是否预淘汰', prop: 'dead_sku_type', minWidth: '100px'},
  {label: '箱数/托盘', prop: 'box_count_per_tray', minWidth: '100px'},

  {label: '赛道', prop: 'race_name', minWidth: '60px'},
  {label: '大类', prop: 'main_category_name', minWidth: '80px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '80px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '110px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '110px'},

  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '料号', prop: 'material_code', minWidth: '140px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px'},
  {label: '年月', prop: 'plan_month', minWidth: '80px'},
  {label: '客订满足率', prop: 'bo_kd_fill_rate_text', minWidth: '90px'},
  {label: '关联的调整产线及最终产能', prop: 'adjust_info', minWidth: '200px'},
  {label: '供应中心', prop: 'prod_type', minWidth: '80px'},
];

const stacticsHead = [
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '品名', prop: 'plu_name', minWidth: '100px'},
  {label: '赛道', prop: 'race_name', minWidth: '100px'},
  {label: '大类', prop: 'main_category_name', minWidth: '100px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '100px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '100px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '100px'},
  {label: '产品等级', prop: 'grade', minWidth: '100px'},
  {label: planYM + '本月预排产量', prop: 'confirm_arrange_box_count', minWidth: '120px'},
  {label: '请购上限', prop: 'prod_count_up_box', minWidth: '100px'},
  {label: '请购下限', prop: 'prod_count_down_box', minWidth: '100px'},
  {label: planYM + '预计月底库存系数', prop: 'hq_expect_store_ratio', minWidth: '150px'},
  {label: '分公司客订量', prop: 'bo_kd_plu_count_box', minWidth: '120px'},
  {label: '客订满足率', prop: 'bo_kd_fill_rate_text', minWidth: '90px'},
];

const aae03Head = [
  {label: '产线类型', prop: 'product_line_type', minWidth: '80px'},
  {label: '计算标识', prop: 'unique_code', minWidth: '80px'},
  {label: '生产上限汇总', prop: 'total_prod_count_up', minWidth: '80px'},
  {label: '生产下限汇总', prop: 'total_prod_count_down', minWidth: '80px'},
  {label: '原因', prop: 'reason', minWidth: '150px'},
  {label: '可调整接单小计', prop: 'adjustable_order_subtotal_count', minWidth: '80px'},
  {label: '可调整生产上限汇总', prop: 'adjustable_prod_count_up', minWidth: '80px'},
  {label: '可调整生产下限汇总', prop: 'adjustable_prod_count_down', minWidth: '80px'},
  {label: '老品商品编码', prop: 'relevance_plu_old', minWidth: '120px'},
  {label: '新品商品编码', prop: 'relevance_plu_other', minWidth: '120px'},
  {label: '创建时间', prop: 'create_time', minWidth: '120px'},
];

const actualProdScheduligPlanHead = [
  {label: '年月', prop: 'plan_month', minWidth: '80px'},
  {label: '订单性质', prop: 'race_name', minWidth: '100px'},
  {label: '单号', prop: 'order_no', minWidth: '150px'},
  {label: '接单日期', prop: 'accept_order_date', minWidth: '100px'},
  {label: '成品交期', prop: 'deliver_date', minWidth: '100px'},
  {label: '系列', prop: 'series', minWidth: '100px'},
  {label: '型号', prop: 'model_code', minWidth: '100px'},
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '换算料号', prop: 'trans_material_code', minWidth: '140px'},
  {label: '单位', prop: 'unit', minWidth: '80px'},
  {label: '颜色/规格', prop: 'specification', minWidth: '80px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '(统计)工艺', prop: 'craft', minWidth: '100px'},
  {label: '机装款式', prop: 'machinery_fit_style', minWidth: '100px'},
  {label: '模号', prop: 'mould_code', minWidth: '100px'},
  {label: '(统计)笔头', prop: 'tip_style', minWidth: '100px'},
  {label: '当月必交量(已换算)', prop: 'this_mth_count', minWidth: '130px'},
  {label: '换算比例', prop: 'trans_rate', minWidth: '80px'},
  {label: '月排产量(未换算)', prop: 'mth_pc_count_whs', minWidth: '120px'},
  {label: '月排产量(已换算)', prop: 'mth_pc_count_yhs', minWidth: '120px'},
  {label: '已下量', prop: 'total_previous_put_count', minWidth: '80px'},
  {label: '再下量', prop: 'this_mth_count', minWidth: '120px'},
  {label: '数量/托盘', prop: 'plu_count_per_tray', minWidth: '80px'},
  {label: '产品级别', prop: 'grade', minWidth: '80px'},
  // // 原始料号维度：上月底总部库存、理论请购量、月均销量、月底预计库存、库存系数
  {label: currentYM + '月底总部库存', prop: 'hq_last_month_store_count', minWidth: '160px'},
  {label: '理论请购量', prop: 'theory_shipment_count', minWidth: '100px'},
  {label: '月均销量', prop: 'hq_month_avg_sale_count', minWidth: '80px'},
  {label: planYM + '月底预计库存', prop: 'hq_expect_store_count', minWidth: '160px'},
  {label: planYM + '月底库存系数', prop: 'hq_expect_store_ratio', minWidth: '160px'},
  {label: '订单类型', prop: 'order_type', minWidth: '100px'},
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '供应中心', prop: 'prod_type', minWidth: '80px'},
];

const arrangePluReportHead = [
  {label: '年月', prop: 'plan_month', minWidth: '80px'},
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '生产类型', prop: 'prod_type', minWidth: '80px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '品名', prop: 'plu_name', minWidth: '150px'},

  {label: '赛道', prop: 'race_name', minWidth: '60px'},
  {label: '大类', prop: 'main_category_name', minWidth: '80px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '80px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '100px'},
  {label: '等级', prop: 'grade', minWidth: '50px'},

  {label: '请购单量', prop: 'qg_count_box', minWidth: '80px'},
  {label: currentYM + '月底总部库存', prop: 'hq_last_month_stock_count_box', minWidth: '160px'},
  {label: currentYM + '月底总部库存系数', prop: 'hq_last_month_store_ratio', minWidth: '160px'},
  {label: '分公司客订', prop: 'bo_kd_plu_count_box', minWidth: '100px'},
  // {label: '备货', prop: 'stock_up_count_box', minWidth: '100px'},
  {label: '已下量', prop: 'total_previous_put_count_box', minWidth: '100px'},
  {label: '理论请购量', prop: 'theory_shipment_count_box', minWidth: '100px'},
  {label: '实际排产量', prop: 'actual_arrange_count_box', minWidth: '100px'},
  {label: '调整量', prop: 'adjust_count_box', minWidth: '100px'},
  {label: planYM + '月底预计库存', prop: 'hq_expect_store_count_box', minWidth: '160px'},
  {label: '月均销量', prop: 'hq_month_avg_sale_count_box', minWidth: '100px'},
  {label: planYM + '月底预计库存系数', prop: 'hq_expect_store_ratio', minWidth: '160px'},
  {label: '客订满足率', prop: 'bo_kd_fill_rate_text', minWidth: '100px'},
];

const midClassifyArrangeReportHead = [
  {label: '大类', prop: 'main_category_name', minWidth: '100px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '150px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '150px'},
  {label: '等级', prop: 'grade', minWidth: '50px'},

  {label: currentYM + '月底预计库存系数', prop: 'hq_last_month_store_ratio', minWidth: '160px'},
  {label: '库存系数目标', prop: 'hq_store_ratio_aim', minWidth: '100px'},
  {label: '下月底预计库存系数', prop: 'hq_expect_store_ratio', minWidth: '140px'},
  {label: '在销SKU数', prop: 'on_sale_sku_count', minWidth: '100px'},
  {label: '客订SKU数', prop: 'bo_kd_sku_count', minWidth: '100px'},
  {label: '下单SKU数', prop: 'confirm_arrange_sku_count', minWidth: '100px'},
  {label: '下单超一倍SKU数', prop: 'higher_than_two_order_sku_count', minWidth: '140px'},
  {label: '下单低一半SKU数', prop: 'lower_than_half_order_sku_count', minWidth: '140px'},
  {label: '下单超库存水位SKU数', prop: 'higher_than_store_up_sku_count', minWidth: '150px'},
  {label: '未下单且低库存水位SKU数', prop: 'lower_than_store_down_sku_count', minWidth: '180px'},
  {label:  planYM + '月底预计库存（万支）', prop: 'hq_expect_store_count_w', minWidth: '200px'},
  {label: '月均销量（万支）', prop: 'hq_month_avg_sale_count_w', minWidth: '140px'},
  {label: '提前已下量（万支）', prop: 'hq_old_plu_previous_put_count_w', minWidth: '160px'},
  {label: '系统已有欠量（万支）', prop: 'hq_this_month_ql_w', minWidth: '160px'},
  {label: '分公司客订量（万支）', prop: 'bo_kd_plu_count_w', minWidth: '160px'},
  {label: '理论请购量（万支）', prop: 'theory_shipment_count_w', minWidth: '160px'},
  {label: '预排产量（万支）', prop: 'confirm_arrange_count_w', minWidth: '160px'},
];

const mainClassifyArrangeReportHead = [
  {label: '大类', prop: 'main_category_name', minWidth: '100px'},
  {label: '等级', prop: 'grade', minWidth: '50px'},

  {label: currentYM + '月底预计库存系数', prop: 'hq_last_month_store_ratio', minWidth: '160px'},
  // {label: '下月底预计库存系数', prop: 'hq_expect_store_ratio', minWidth: '100px'},
  {label: '在销SKU数', prop: 'on_sale_sku_count', minWidth: '100px'},
  {label: '客订SKU数', prop: 'bo_kd_sku_count', minWidth: '100px'},
  {label: '下单SKU数', prop: 'confirm_arrange_sku_count', minWidth: '100px'},
  {label: '下单超一倍SKU数', prop: 'higher_than_two_order_sku_count', minWidth: '120px'},
  {label: '下单低一半SKU数', prop: 'lower_than_half_order_sku_count', minWidth: '120px'},
  {label: '下单超库存水位SKU数', prop: 'higher_than_store_up_sku_count', minWidth: '160px'},
  {label: '未下单且低库存水位SKU数', prop: 'lower_than_store_down_sku_count', minWidth: '180px'},
  {label:  planYM + '月底预计库存（万支）', prop: 'hq_expect_store_count_w', minWidth: '190px'},
  {label: '月均销量（万支）', prop: 'hq_month_avg_sale_count_w', minWidth: '140px'},
  {label: '提前已下量（万支）', prop: 'hq_old_plu_previous_put_count_w', minWidth: '140px'},
  {label: '系统已有欠量（万支）', prop: 'hq_this_month_ql_w', minWidth: '160px'},
  {label: '分公司客订量（万支）', prop: 'bo_kd_plu_count_w', minWidth: '160px'},
  {label: '理论请购量（万支）', prop: 'theory_shipment_count_w', minWidth: '140px'},
  {label: '预排产量（万支）', prop: 'confirm_arrange_count_w', minWidth: '140px'},
];

const pluOrderDetailHead = [
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '品名', prop: 'plu_name', minWidth: '150px'},
  {label: '等级', prop: 'grade', minWidth: '60px'},
  {label: '月均销量', prop: 'hg_month_avg_sale_count', minWidth: '100px'},
  {label: currentYM + '月底库存数量', prop: 'hg_last_month_store_count', minWidth: '160px'},
  {label: currentYM + '预计库存系数', prop: 'hq_last_month_stock_ratio', minWidth: '160px'}, // 上月底预计库存系数（10月）
  {label: '分公司库存/支', prop: 'bo_last_month_stock_count', minWidth: '120px'}, // 分公司库存
  {label: '分公司库存系数', prop: 'bo_last_month_stock_ratio', minWidth: '120px'}, // 分公司库存系数
  {label: planYM + '已有欠量', prop: 'hq_this_month_ql', minWidth: '120px'},
  {label: '分公司客订', prop: 'confirm_bo_kd_plu_count', minWidth: '100px'},
  {label: '分公司客订家数', prop: 'bo_last_month_order_count', minWidth: '120px'}, // 分公司客订家数
  {label: planYM + '合计订单', prop: 'hq_this_month_total_count', minWidth: '120px'}, // 合计订单=分公司客订+当月欠量
  {label: '已下量', prop: 'total_previous_put_count', minWidth: '100px'},
  {label: '理论请购量', prop: 'theory_shipment_count', minWidth: '120px'},
  {label: planYM + '底预计库存/支', prop: 'prepare_shipment_count', minWidth: '140px'},
  {label: planYM + '月底预计库存系数', prop: 'hq_this_month_stock_ratio', minWidth: '160px'}, // 本月底预计库存系数
  {label: '请购上限', prop: 'prod_count_up', minWidth: '100px'},
  {label: '请购下限', prop: 'prod_count_down', minWidth: '100px'},
  {label: '库存系数上限', prop: 'store_ratio_up', minWidth: '100px'},
  {label: '库存系数下限', prop: 'store_ratio_down', minWidth: '100px'},
  {label: '库存系数目标', prop: 'store_ratio_aim', minWidth: '100px'},
  {label: '库存系数上限对应库存/支', prop: 'store_ratio_up_count', minWidth: '160px'}, // 库存系数上限对应库存/支
  {label: '库存系数下限对应库存/支', prop: 'store_ratio_down_count', minWidth: '160px'}, // 库存系数下限对应库存/支
  {label: '库存系数目标对应库存/支', prop: 'store_ratio_aim_count', minWidth: '160px'}, // 库存系数目标对应库存/支
  {label: preYm + '库存支数', prop: 'hq_before_last_month_stock_count', minWidth: '140px'}, // 上上月库存箱数（9月）
  {label: currentYM + '已销量', prop: 'hq_last_month_sale_count', minWidth: '140px'}, // 上月已销量（10月）
  {label: currentYM + '欠量', prop: 'hq_last_month_ql_count', minWidth: '140px'}, // 上月欠量（10月）
  {label: currentYM + '结余量', prop: 'last_month_carryover_count', minWidth: '140px'}, // 上月结余量（10月）
  {label: currentYM + '排产量', prop: 'hq_last_month_pc_count', minWidth: '140px'}, // 上月排产量（10月）
  {label: '赛道', prop: 'race_name', minWidth: '60px'},
  {label: '大类', prop: 'main_category_name', minWidth: '80px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '80px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '110px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '110px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px'},
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '箱规', prop: 'bottom_spec', minWidth: '60px'}, // 箱规
  {label: '生产类型', prop: 'prod_type', minWidth: '80px'},
  {label: '状态', prop: 'flow_status', minWidth: '80px'},
  {label: '操作人', prop: 'update_by', minWidth: '80px'},
  {label: '锁状态', prop: 'lock_state', minWidth: '80px'},
  {label: '年月', prop: 'order_month', minWidth: '65px'},
]


export {
  customAdjustHead,                // 排单计划 -- 自定义调整 -- SKU明细 -- 表头
  midClassifyArrangeReportHead,       // 排单计划 -- 自定义调整 -- 中类排单执行计划 -- 表头
  mainClassifyArrangeReportHead,       // 排单计划 -- 自定义调整 -- 大类排单执行计划 -- 表头
  stacticsHead,                    // 排单计划 -- 策略调整 -- 表头
  actualProdScheduligPlanHead,     // 排单计划 -- 实际计划 -- 表头
  arrangePluReportHead,            // 排单计划 -- 请沟单明细 -- 表头
  aae03Head,            // 排单计划 -- 问题产线列表 -- 表头
  pluOrderDetailHead,              // 请购单临时汇总 -- 请购单明细 -- 表头
}
