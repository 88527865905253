var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-aside",
            {
              staticStyle: {
                width: "420px",
                "background-color": "#d0d6db",
                padding: "15px",
                height: "700px",
                "overflow-y": "scroll",
              },
            },
            [
              _c("AppWebPreview", {
                ref: "appWebPreview",
                attrs: { addModule: _vm.addModuleShow },
              }),
            ],
            1
          ),
          _c(
            "el-main",
            {
              staticStyle: {
                "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                "margin-left": "10px",
              },
            },
            [
              _vm.editType == "split"
                ? _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-weight": "800",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v("添加分割线")]
                      ),
                      _c("el-input", {
                        staticStyle: { margin: "10px", width: "100%" },
                        attrs: { placeholder: "建议10个字以内" },
                        model: {
                          value: _vm.splitTitle,
                          callback: function ($$v) {
                            _vm.splitTitle = $$v
                          },
                          expression: "splitTitle",
                        },
                      }),
                      _c(
                        "el-row",
                        { staticStyle: { margin: "10px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSplit()
                                },
                              },
                            },
                            [_vm._v("添加")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancel()
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editType == "module"
                ? _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-weight": "800",
                            "font-size": "16px",
                          },
                        },
                        [_vm._v("添加任务模块")]
                      ),
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { margin: "10px", width: "100%" },
                          on: { change: _vm.moduleTypeChange },
                          model: {
                            value: _vm.moduleType,
                            callback: function ($$v) {
                              _vm.moduleType = $$v
                            },
                            expression: "moduleType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("一排二小"),
                          ]),
                          _vm.taskModuleType != "PARTNER"
                            ? _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("一大二小"),
                              ])
                            : _vm._e(),
                          _vm.taskModuleType != "PARTNER"
                            ? _c("el-radio", { attrs: { label: "3" } }, [
                                _vm._v("一排二大"),
                              ])
                            : _vm._e(),
                          _vm.taskModuleType != "PARTNER"
                            ? _c("el-radio", { attrs: { label: "4" } }, [
                                _vm._v("一排三个"),
                              ])
                            : _vm._e(),
                          _c("el-radio", { attrs: { label: "5" } }, [
                            _vm._v("一排一个"),
                          ]),
                          _c("el-radio", { attrs: { label: "7" } }, [
                            _vm._v("一排一个（高）"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-container",
                        {
                          staticStyle: {
                            "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                          },
                        },
                        [
                          _c(
                            "el-header",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-content": "center",
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "17px",
                                    "font-weight": "700",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.showCaseTitle))]
                              ),
                            ]
                          ),
                          _c(
                            "el-main",
                            [
                              _c("el-image", {
                                staticStyle: {
                                  height: "250px",
                                  width: "500px",
                                },
                                attrs: { src: _vm.showCaseImg, fit: "contain" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticStyle: { margin: "10px" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addModule()
                                },
                              },
                            },
                            [_vm._v("添加")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancel()
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editType == "taskGroup"
                ? _c("div", [_vm._v(" 添加任务合集 ")])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }