<template>
  <div>
    <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls, .csv" @change="handleClick">
    <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
      <div class="upload-area">
          拖拽上传 or
            <el-button
                class="upload-btn el-icon-upload"
                :loading="loading"
                style="margin-left:8px;"
                size="mini"
                type="primary"
                @click="handleUpload"
            >选择文件</el-button>
            <el-button
                v-if="uploadDatas.length > 0"
                class="upload-btn el-icon-view"
                style="margin-left:8px;"
                size="mini"
                type="primary"
                @click="handlePreview"
            >点击预览</el-button>
            <el-button
              v-if="uploadDatas.length > 0"
              type="primary"
              style="margin-left:8px;"
              size="mini"
              @click="handleConfirmUpload"
            >确 认 上 传</el-button>
      </div>
      <div class="warning" v-if="uploadDatas.length === 0 && templateFileDownloadUrl">
        仅支持Xlsx格式文件,格式可参考模板
        <em @click.stop="handleDownloadTemplate">下载模板</em>
      </div>
      <div class="upload-tips" v-if="tipsText">
        <span class="upload-tips-text" v-html="tipsText"></span><br/>
        <span class="upload-optional-tips-text" v-if="optionalTipsText">{{ optionalTipsText }}</span>
      </div>
      <div v-if="importType">
        <el-radio-group v-model="importAction">
          <el-radio v-for="item in importType" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
        <div style="font-size: 14px;color: red; margin: 3px;">
          <span v-if="importActionLabel">{{ importActionLabel }}</span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {read, utils} from "xlsx";

export default {
  props: {
    beforeUpload: Function,  // eslint-disable-line
    onSuccess: Function,     // eslint-disable-line
    tipsText: {
        type: String,
        default: ''
    },
    optionalTipsText: {
        type: String,
        default: ''
    },
    uploadDatas: {
        type: Array,
        default: []
    },
    templateFileDownloadUrl: String,
    importType: {
      type: Array,
    }
  },
  data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      },
      importAction: this.importType && this.importType.length >0? this.importType[0].value: undefined
    }
  },
  computed:{
    importActionLabel() {
      if(this.importType && this.importType.length >0) {
        const action = this.importType.find(e => e.value === this.importAction)
        return action? action.tips: undefined
      }
      return undefined
    }
  },
  methods: {
      generateData({ header, results }) {
      this.excelData.header = header
      this.excelData.results = results
      this.onSuccess && this.onSuccess(this.excelData)
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.loading) return
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$message.error('Only support uploading one file!')
        return
      }
      const rawFile = files[0] // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )')
        return false
      }
      this.upload(rawFile)
      e.stopPropagation()
      e.preventDefault()
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['excel-upload-input'].click()
    },
    handleClick(e) {
      const files = e.target.files
      const rawFile = files[0] // only use files[0]
      if (!rawFile) return
      this.upload(rawFile)
    },
    upload(rawFile) {
      this.$refs['excel-upload-input'].value = null // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile)
        return
      }
      const before = this.beforeUpload(rawFile)
      if (before) {
        this.readerData(rawFile)
      }
    },
    readerData: function (rawFile) {
      if (!this.isExcel(rawFile)) return;

      let isCSV = this.isCsv(rawFile);

      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = read(data, {type: isCSV ? 'string' : 'array', codepage: 936})
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          let results = JSON.parse(JSON.stringify(utils.sheet_to_json(worksheet, { defval: null })))
          // console.log("results", header, results);
          this.generateData({header, results})
          this.loading = false
          resolve()
        }

        if(isCSV) {
          reader.readAsText(rawFile)
        } else {
          reader.readAsArrayBuffer(rawFile)
        }

      })
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    isCsv(file) {
      return /\.(csv)$/.test(file.name)
    },
    handlePreview() {
        this.$emit('handlePreview', { data: this.uploadDatas, isPreview: true })
    },
    handleConfirmUpload() {
      this.$emit('handleConfirmUpload');
    },
    // 下载模板文件
    handleDownloadTemplate() {
        if (!this.templateFileDownloadUrl) return
        const a = document.createElement('a')
        a.style.display = 'none'
        a.download = this.templateFileDownloadUrl
        a.href = this.templateFileDownloadUrl
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    },
  }
}
</script>

<style lang="scss" scoped>
.excel-upload-input{
  display: none;
  z-index: -9999;
}
.drop{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  border: 2px dashed #bbb;
  padding: 45px 30px;
  .upload-tips {
    font-size: 13px;
    color: #e50d0d;
    margin-top: 8px;
    text-align: left;
    line-height: 120%;
  }
  .upload-optional-tips-text {
    display: inline-block;
    color: #d6d6d6;
    margin-top: 10px;
  }
  .upload-btn {
      ::v-deep span { margin-left: 4px; }
  }
  .warning {
    font-size: 12px;
    margin-top: 8px;
    cursor: pointer;
    em {
      color: #4846fd;
      font-weight: 600;
      font-style: normal;
    }
  }
}
</style>
