var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c("el-aside", { staticStyle: { width: "20%" } }, [
        _c(
          "div",
          {
            staticStyle: {
              position: "absolute",
              top: "80px",
              width: "inherit",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { margin: "20px", "font-size": "20px" } },
              [_vm._v("组件")]
            ),
            _c(
              "el-row",
              _vm._l(_vm.options.groups, function (item) {
                return _c(
                  "el-col",
                  {
                    key: item.type,
                    staticStyle: { "margin-bottom": "5px" },
                    attrs: { span: 12 },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "120px", color: "black" },
                        attrs: {
                          type: "info",
                          plain: "",
                          disabled: _vm.disableGroup(item.type),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddGroup(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "20px", "font-size": "20px" } },
              [_vm._v("数据")]
            ),
            _c(
              "el-row",
              _vm._l(_vm.options.data, function (item) {
                return _c(
                  "el-col",
                  {
                    key: item.type,
                    staticStyle: { "margin-bottom": "5px" },
                    attrs: { span: 12 },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "120px", color: "black" },
                        attrs: { type: "info", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddGroup(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-container",
        { staticStyle: { "max-width": "45%" } },
        [
          _c(
            "el-main",
            [
              _c(
                "el-form",
                {
                  ref: "configForm",
                  staticStyle: { "max-height": "100%" },
                  attrs: {
                    size: "mini",
                    "label-width": "auto",
                    inline: true,
                    "label-position": "left",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#1482f0",
                              "font-size": "24px",
                            },
                          },
                          [
                            _vm._v("基础设置"),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("（创建者须填写）")]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "max-length": "13",
                                  "show-word-limit": "",
                                  placeholder: "建议13字以内",
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "核销类型", prop: "type" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { input: _vm.handleTypeChange },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("活动核销"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("终端核销"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "审核流程", prop: "flowType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.flowType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "flowType", $$v)
                                    },
                                    expression: "form.flowType",
                                  },
                                },
                                _vm._l(
                                  _vm.options.flowTypeOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "展示时间", prop: "startDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "daterange",
                                  "start-placeholder": "开始时间",
                                  "end-placeholder": "结束时间",
                                  format: "yyyy-MM-dd",
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                on: {
                                  change: (val) => _vm.handleDateChange(val),
                                },
                                model: {
                                  value: _vm.form.showDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "showDate", $$v)
                                  },
                                  expression: "form.showDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分公司范围", prop: "orgCodes" },
                            },
                            [
                              _c("DictSelect", {
                                ref: "org",
                                attrs: {
                                  type: "org",
                                  value: _vm.form.orgCodes,
                                  linkageRef: "dc",
                                  fullLabel: "",
                                  multiple: "",
                                  dictFun: _vm.dictSelect,
                                  clearable: "",
                                  "collapse-tags": "",
                                  placeholder: "请选择分公司",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "orgCodes",
                                      $event
                                    )
                                  },
                                  change: _vm.handleOrgChange,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckAll(true)
                                    },
                                  },
                                },
                                [_vm._v("全选")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckAll(false)
                                    },
                                  },
                                },
                                [_vm._v("取消全选")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "显示图标" } },
                            [
                              _c("OssUpload", {
                                ref: "ossUpload",
                                attrs: {
                                  "value-to": _vm.form.shareIcon,
                                  "list-type": "picture-card",
                                  maxFileSize: 500,
                                  tipMessageShow: false,
                                  tips: "尺寸120*120,正方形图标",
                                  "accept-type": [
                                    ".jpg",
                                    ".jpeg",
                                    ".gif",
                                    ".png",
                                  ],
                                },
                                on: {
                                  "update:valueTo": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "shareIcon",
                                      $event
                                    )
                                  },
                                  "update:value-to": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "shareIcon",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属分组" } },
                            [
                              _c("DictSelect", {
                                ref: "tag",
                                attrs: {
                                  type: "tag",
                                  value: _vm.form.tagName,
                                  init: "",
                                  "init-options": _vm.options.tagNames,
                                  clearable: "",
                                  singleProps: "",
                                  "allow-create": "",
                                  placeholder: "请选择分公司",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(_vm.form, "tagName", $event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#1482f0",
                              "font-size": "24px",
                            },
                          },
                          [
                            _vm._v("活动资料表单配置"),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "20px" } },
                              [_vm._v("（用户填写项，此处为展示）")]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-col",
                        _vm._l(_vm.form.fields, function (item, index) {
                          return _c(
                            "el-row",
                            { key: index },
                            [
                              _c(
                                "el-col",
                                {
                                  class:
                                    _vm.currentGroup &&
                                    _vm.currentGroup.id == item.id
                                      ? "select-group"
                                      : "",
                                  staticStyle: {
                                    "background-color": "#f8f8f8",
                                    margin: "5px",
                                    padding: "5px",
                                    "border-radius": "1px",
                                  },
                                  attrs: { span: 12 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.fieldLabel
                                                        ? item.fieldLabel
                                                        : "文本"
                                                    )
                                                  ),
                                                ]),
                                                item.required
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      item.fieldType === "upload-module"
                                        ? _c("div", [
                                            !item.configJson.isOnlyBefore
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("span", [
                                                      _vm._v("上传照片"),
                                                    ]),
                                                    item.required
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [_vm._v("*")]
                                                        )
                                                      : _vm._e(),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          "(最少" +
                                                            item.configJson
                                                              .min +
                                                            "张,最多" +
                                                            item.configJson
                                                              .max +
                                                            "张)"
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "el-row",
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("打造前"),
                                                            ]),
                                                            item.required
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "red",
                                                                      },
                                                                  },
                                                                  [_vm._v("*")]
                                                                )
                                                              : _vm._e(),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  "(最少" +
                                                                    item
                                                                      .configJson
                                                                      .min +
                                                                    "张,最多" +
                                                                    item
                                                                      .configJson
                                                                      .max +
                                                                    "张)"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v("打造后"),
                                                            ]),
                                                            item.required
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "red",
                                                                      },
                                                                  },
                                                                  [_vm._v("*")]
                                                                )
                                                              : _vm._e(),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  "(最少" +
                                                                    item
                                                                      .configJson
                                                                      .afterMin +
                                                                    "张,最多" +
                                                                    item
                                                                      .configJson
                                                                      .afterMax +
                                                                    "张)"
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      item.fieldType === "select"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.configJson.multiple
                                                  ? "(多选)"
                                                  : "(单选)"
                                              )
                                            ),
                                          ])
                                        : item.fieldType === "upload"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                "(最少" +
                                                  item.configJson.min +
                                                  "张,最多" +
                                                  item.configJson.max +
                                                  "张)"
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.fieldType === "upload"
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                item.configJson.description
                                              )
                                            ),
                                          ])
                                        : item.fieldType === "data"
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                item.configJson.description
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      item.fieldType === "input"
                                        ? _c("el-input", {
                                            attrs: {
                                              readonly: "",
                                              placeholder: item.configJson
                                                .placeholder
                                                ? item.configJson.placeholder
                                                : "请输入",
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          })
                                        : item.fieldType === "input-number"
                                        ? _c("el-input", {
                                            attrs: {
                                              readonly: "",
                                              placeholder: item.configJson
                                                .placeholder
                                                ? item.configJson.placeholder
                                                : "请输入",
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          })
                                        : item.fieldType === "text-shop-info"
                                        ? _c("span", [
                                            _vm._v("xxxx店铺 C11111/0001"),
                                          ])
                                        : item.fieldType === "select"
                                        ? _c("el-select", {
                                            attrs: {
                                              disabled: "",
                                              placeholder: item.configJson
                                                .placeholder
                                                ? item.configJson.placeholder
                                                : "请选择",
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          })
                                        : item.fieldType === "dc-select"
                                        ? _c("el-select", {
                                            attrs: {
                                              disabled: "",
                                              placeholder: item.configJson
                                                .placeholder
                                                ? item.configJson.placeholder
                                                : "请选择",
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          })
                                        : item.fieldType === "date"
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              type: "date",
                                              readonly: "",
                                              placeholder: item.configJson
                                                .placeholder
                                                ? item.configJson.placeholder
                                                : "请选择日期",
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          })
                                        : item.fieldType === "date-range"
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              type: "daterange",
                                              readonly: "",
                                              "range-separator": "至",
                                              "start-placeholder": "开始日期",
                                              "end-placeholder": "结束日期",
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          })
                                        : item.fieldType === "upload"
                                        ? _c("OssUpload", {
                                            ref: "ossUpload" + index,
                                            refInFor: true,
                                            attrs: {
                                              "value-to": item.value,
                                              "list-type": "picture-card",
                                              maxFileSize: 500,
                                              tipMessageShow: false,
                                              "accept-type": [
                                                ".jpg",
                                                ".jpeg",
                                                ".gif",
                                                ".png",
                                              ],
                                            },
                                            on: {
                                              "update:valueTo": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "value",
                                                  $event
                                                )
                                              },
                                              "update:value-to": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "value",
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : item.fieldType === "upload-module"
                                        ? _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c("OssUpload", {
                                                    ref:
                                                      "ossUploadBefore" + index,
                                                    refInFor: true,
                                                    attrs: {
                                                      "value-to": item.value,
                                                      "list-type":
                                                        "picture-card",
                                                      maxFileSize: 500,
                                                      tipMessageShow: false,
                                                      "accept-type": [
                                                        ".jpg",
                                                        ".jpeg",
                                                        ".gif",
                                                        ".png",
                                                      ],
                                                    },
                                                    on: {
                                                      "update:valueTo":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "value",
                                                            $event
                                                          )
                                                        },
                                                      "update:value-to":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "value",
                                                            $event
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c("OssUpload", {
                                                    ref:
                                                      "ossUploadAfter" + index,
                                                    refInFor: true,
                                                    attrs: {
                                                      "value-to": item.value,
                                                      "list-type":
                                                        "picture-card",
                                                      maxFileSize: 500,
                                                      tipMessageShow: false,
                                                      "accept-type": [
                                                        ".jpg",
                                                        ".jpeg",
                                                        ".gif",
                                                        ".png",
                                                      ],
                                                    },
                                                    on: {
                                                      "update:valueTo":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "value",
                                                            $event
                                                          )
                                                        },
                                                      "update:value-to":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "value",
                                                            $event
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : item.fieldType === "data"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c("label", [
                                                        _vm._v("字段"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c("label", [
                                                        _vm._v("注释"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c("label", [
                                                        _vm._v("目标"),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._l(
                                                item.quotas.filter(
                                                  (e) => e.deleted == 0
                                                ),
                                                function (c, cIndex) {
                                                  return _c(
                                                    "el-row",
                                                    { key: cIndex },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _vm._v(
                                                            _vm._s(c.fieldName)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              c.fieldExplain
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 8 } },
                                                        [
                                                          _vm._v(
                                                            _vm._s(c.target)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    padding: "5px",
                                    margin: "5px",
                                  },
                                  attrs: { span: 7 },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: () => (_vm.currentGroup = item),
                                      },
                                    },
                                    [_vm._v("选中")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled:
                                          item.fieldType === "dc-select",
                                        type: "primary",
                                        size: "mini",
                                      },
                                      on: {
                                        click: () => {
                                          _vm.form.fields.splice(index, 1)
                                          _vm.$forceUpdate()
                                          if (
                                            _vm.currentGroup &&
                                            _vm.currentGroup.id === item.id
                                          ) {
                                            _vm.currentGroup = undefined
                                          }
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: _vm.form.fields.length <= 1,
                                        size: "mini",
                                        type: "primary",
                                      },
                                      on: {
                                        click: () => {
                                          _vm.form.fields.splice(index, 1)
                                          _vm.form.fields.splice(
                                            index - 1,
                                            0,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("上移")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: _vm.form.fields.length <= 1,
                                        size: "mini",
                                        type: "primary",
                                      },
                                      on: {
                                        click: () => {
                                          _vm.form.fields.splice(index, 1)
                                          _vm.form.fields.splice(
                                            index + 1,
                                            0,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下移")]
                                  ),
                                ],
                                1
                              ),
                              _vm.options.groupKeys.indexOf(item.fieldType) > -1
                                ? _c("el-col", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1482f0",
                                          "font-size": "24px",
                                        },
                                      },
                                      [_vm._v("子组件配置")]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { width: "95%", float: "right" },
                                },
                                _vm._l(item.fields, function (cItem, cIndex) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: cIndex,
                                      staticStyle: { "margin-left": "20px" },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          class:
                                            _vm.currentGroup &&
                                            _vm.currentGroup.id == cItem.id
                                              ? "select-group"
                                              : "",
                                          staticStyle: {
                                            "background-color": "#f8f8f8",
                                            margin: "5px",
                                            padding: "5px",
                                            "border-radius": "1px",
                                          },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              cItem.fieldLabel
                                                                ? cItem.fieldLabel
                                                                : "文本"
                                                            )
                                                          ),
                                                        ]),
                                                        cItem.required
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [_vm._v("*")]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              cItem.fieldType ===
                                              "upload-module"
                                                ? _c("div", [
                                                    !cItem.configJson
                                                      .isOnlyBefore
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "上传照片"
                                                              ),
                                                            ]),
                                                            cItem.required
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "red",
                                                                      },
                                                                  },
                                                                  [_vm._v("*")]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "el-row",
                                                              [
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 12,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "打造前"
                                                                      ),
                                                                    ]),
                                                                    cItem.required
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "*"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          "(最少" +
                                                                            cItem
                                                                              .configJson
                                                                              .min +
                                                                            "张,最多" +
                                                                            cItem
                                                                              .configJson
                                                                              .max +
                                                                            "张)"
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-col",
                                                                  {
                                                                    attrs: {
                                                                      span: 12,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "打造后"
                                                                      ),
                                                                    ]),
                                                                    cItem.required
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "*"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          "(最少" +
                                                                            cItem
                                                                              .configJson
                                                                              .afterMin +
                                                                            "张,最多" +
                                                                            cItem
                                                                              .configJson
                                                                              .afterMax +
                                                                            "张)"
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                              cItem.fieldType === "select"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        cItem.multiple
                                                          ? "(多选)"
                                                          : "(单选)"
                                                      )
                                                    ),
                                                  ])
                                                : cItem.fieldType === "upload"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        "(最少" +
                                                          cItem.configJson.min +
                                                          "张,最多" +
                                                          cItem.configJson.max +
                                                          "张)"
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              cItem.fieldType === "upload"
                                                ? _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        cItem.configJson
                                                          .description
                                                      )
                                                    ),
                                                  ])
                                                : cItem.fieldType === "data"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          cItem.configJson
                                                            .description
                                                        ) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              cItem.fieldType === "input"
                                                ? _c("el-input", {
                                                    attrs: {
                                                      readonly: "",
                                                      placeholder: cItem
                                                        .configJson.placeholder
                                                        ? cItem.configJson
                                                            .placeholder
                                                        : "请输入",
                                                    },
                                                    model: {
                                                      value: cItem.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          cItem,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "cItem.value",
                                                    },
                                                  })
                                                : cItem.fieldType ===
                                                  "input-number"
                                                ? _c("el-input", {
                                                    attrs: {
                                                      readonly: "",
                                                      placeholder: cItem
                                                        .configJson.placeholder
                                                        ? cItem.configJson
                                                            .placeholder
                                                        : "请输入",
                                                    },
                                                    model: {
                                                      value: cItem.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          cItem,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "cItem.value",
                                                    },
                                                  })
                                                : cItem.fieldType ===
                                                  "text-shop-info"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "xxxx店铺 C11111/0001"
                                                    ),
                                                  ])
                                                : cItem.fieldType === "select"
                                                ? _c("el-select", {
                                                    attrs: {
                                                      disabled: "",
                                                      placeholder: cItem
                                                        .configJson.placeholder
                                                        ? cItem.configJson
                                                            .placeholder
                                                        : "请选择",
                                                    },
                                                    model: {
                                                      value: cItem.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          cItem,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "cItem.value",
                                                    },
                                                  })
                                                : cItem.fieldType ===
                                                  "dc-select"
                                                ? _c("el-select", {
                                                    attrs: {
                                                      disabled: "",
                                                      placeholder: cItem
                                                        .configJson.placeholder
                                                        ? cItem.configJson
                                                            .placeholder
                                                        : "请选择",
                                                    },
                                                    model: {
                                                      value: cItem.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          cItem,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "cItem.value",
                                                    },
                                                  })
                                                : cItem.fieldType === "date"
                                                ? _c("el-date-picker", {
                                                    attrs: {
                                                      type: "date",
                                                      readonly: "",
                                                      placeholder: cItem
                                                        .configJson.placeholder
                                                        ? cItem.configJson
                                                            .placeholder
                                                        : "请选择日期",
                                                    },
                                                    model: {
                                                      value: cItem.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          cItem,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "cItem.value",
                                                    },
                                                  })
                                                : cItem.fieldType ===
                                                  "date-range"
                                                ? _c("el-date-picker", {
                                                    attrs: {
                                                      type: "daterange",
                                                      readonly: "",
                                                      "range-separator": "至",
                                                      "start-placeholder":
                                                        "开始日期",
                                                      "end-placeholder":
                                                        "结束日期",
                                                    },
                                                    model: {
                                                      value: cItem.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          cItem,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "cItem.value",
                                                    },
                                                  })
                                                : cItem.fieldType === "upload"
                                                ? _c("OssUpload", {
                                                    ref: "ossUpload" + cIndex,
                                                    refInFor: true,
                                                    attrs: {
                                                      "value-to": cItem.value,
                                                      "list-type":
                                                        "picture-card",
                                                      maxFileSize: 500,
                                                      tipMessageShow: false,
                                                      "accept-type": [
                                                        ".jpg",
                                                        ".jpeg",
                                                        ".gif",
                                                        ".png",
                                                      ],
                                                    },
                                                    on: {
                                                      "update:valueTo":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            cItem,
                                                            "value",
                                                            $event
                                                          )
                                                        },
                                                      "update:value-to":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            cItem,
                                                            "value",
                                                            $event
                                                          )
                                                        },
                                                    },
                                                  })
                                                : cItem.fieldType ===
                                                  "upload-module"
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c("OssUpload", {
                                                            ref:
                                                              "ossUploadBefore" +
                                                              cIndex,
                                                            refInFor: true,
                                                            attrs: {
                                                              "value-to":
                                                                cItem.value,
                                                              "list-type":
                                                                "picture-card",
                                                              maxFileSize: 500,
                                                              tipMessageShow: false,
                                                              "accept-type": [
                                                                ".jpg",
                                                                ".jpeg",
                                                                ".gif",
                                                                ".png",
                                                              ],
                                                            },
                                                            on: {
                                                              "update:valueTo":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    cItem,
                                                                    "value",
                                                                    $event
                                                                  )
                                                                },
                                                              "update:value-to":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    cItem,
                                                                    "value",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 12 } },
                                                        [
                                                          _c("OssUpload", {
                                                            ref:
                                                              "ossUploadAfter" +
                                                              cIndex,
                                                            refInFor: true,
                                                            attrs: {
                                                              "value-to":
                                                                cItem.value,
                                                              "list-type":
                                                                "picture-card",
                                                              maxFileSize: 500,
                                                              tipMessageShow: false,
                                                              "accept-type": [
                                                                ".jpg",
                                                                ".jpeg",
                                                                ".gif",
                                                                ".png",
                                                              ],
                                                            },
                                                            on: {
                                                              "update:valueTo":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    cItem,
                                                                    "value",
                                                                    $event
                                                                  )
                                                                },
                                                              "update:value-to":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    cItem,
                                                                    "value",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : cItem.fieldType === "data"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 8,
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v("字段"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 8,
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v("注释"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 8,
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v("目标"),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._l(
                                                        cItem.quotas.filter(
                                                          (e) => e.deleted == 0
                                                        ),
                                                        function (c, cIndex) {
                                                          return _c(
                                                            "el-row",
                                                            { key: cIndex },
                                                            [
                                                              _c(
                                                                "el-col",
                                                                {
                                                                  attrs: {
                                                                    span: 8,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      c.fieldName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-col",
                                                                {
                                                                  attrs: {
                                                                    span: 8,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      c.fieldExplain
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-col",
                                                                {
                                                                  attrs: {
                                                                    span: 8,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      c.target
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            padding: "5px",
                                            margin: "5px",
                                          },
                                          attrs: { span: 7 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: () =>
                                                  (_vm.currentGroup = cItem),
                                              },
                                            },
                                            [_vm._v("选中")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  cItem.fieldType ===
                                                  "dc-select",
                                                type: "primary",
                                                size: "mini",
                                              },
                                              on: {
                                                click: () => {
                                                  item.fields.splice(cIndex, 1)
                                                  if (
                                                    _vm.currentGroup &&
                                                    _vm.currentGroup.id ===
                                                      cItem.id
                                                  ) {
                                                    _vm.currentGroup = undefined
                                                  }
                                                  _vm.$forceUpdate()
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  item.fields.length <= 1,
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: () => {
                                                  item.fields.splice(cIndex, 1)
                                                  item.fields.splice(
                                                    cIndex - 1,
                                                    0,
                                                    cItem
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("上移")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                disabled:
                                                  item.fields.length <= 1,
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: () => {
                                                  item.fields.splice(cIndex, 1)
                                                  item.fields.splice(
                                                    cIndex + 1,
                                                    0,
                                                    cItem
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("下移")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-aside",
        { staticStyle: { "overflow-y": "hidden", width: "35% !important" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                top: "80px",
                width: "-webkit-fill-available",
              },
            },
            [
              _c("el-row", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#1482f0",
                      "font-size": "24px",
                      margin: "20px",
                    },
                  },
                  [_vm._v("组件配置属性")]
                ),
              ]),
              _vm.currentGroup
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "margin-bottom": "5px" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { margin: "5px 0px" },
                              attrs: { span: 4 },
                            },
                            [
                              _c("label", { staticClass: "json-class" }, [
                                _vm._v("文本名称"),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 17 } },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入文本名称",
                                },
                                model: {
                                  value: _vm.currentGroup.fieldLabel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentGroup,
                                      "fieldLabel",
                                      $$v
                                    )
                                  },
                                  expression: "currentGroup.fieldLabel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.options.noInputKeys.indexOf(
                        _vm.currentGroup.fieldType
                      ) < 0
                        ? _c(
                            "el-row",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c("label", { staticClass: "json-class" }, [
                                  _vm._v("是否必填"),
                                ]),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 17 } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.currentGroup.required,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.currentGroup,
                                            "required",
                                            $$v
                                          )
                                        },
                                        expression: "currentGroup.required",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("否")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.currentGroup.configJson, function (item, key) {
                        return _c(
                          "el-col",
                          { key: key },
                          [
                            _c(
                              "el-row",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: { margin: "5px 0px" },
                                    attrs: { span: 4 },
                                  },
                                  [
                                    _c("label", { staticClass: "json-class" }, [
                                      _vm._v(_vm._s(_vm.keyLabelMap[key])),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 17 } },
                                  [
                                    _vm.options.booKeys.indexOf(key) > -1
                                      ? _c(
                                          "el-radio-group",
                                          {
                                            attrs: {
                                              disable:
                                                _vm.currentGroup.fieldType ===
                                                "dc-select",
                                            },
                                            model: {
                                              value:
                                                _vm.currentGroup.configJson[
                                                  key
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentGroup.configJson,
                                                  key,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentGroup.configJson[key]",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: true } },
                                              [_vm._v("是")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: false } },
                                              [_vm._v("否")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.options.strJKeys.indexOf(key) > -1
                                      ? _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value:
                                              _vm.currentGroup.configJson[key],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.currentGroup.configJson,
                                                key,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentGroup.configJson[key]",
                                          },
                                        })
                                      : _vm.options.numKeys.indexOf(key) > -1
                                      ? _c("el-input-number", {
                                          attrs: {
                                            size: "mini",
                                            control: false,
                                            step: 0,
                                            precision: 0,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value:
                                              _vm.currentGroup.configJson[key],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.currentGroup.configJson,
                                                key,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentGroup.configJson[key]",
                                          },
                                        })
                                      : (key === "include" ||
                                          key === "includeAfter") &&
                                        _vm.currentGroup.fieldType.indexOf(
                                          "upload"
                                        ) > -1
                                      ? _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              size: "mini",
                                              multiple: "",
                                            },
                                            model: {
                                              value:
                                                _vm.currentGroup.configJson[
                                                  key
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.currentGroup.configJson,
                                                  key,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "currentGroup.configJson[key]",
                                            },
                                          },
                                          _vm._l(
                                            _vm.options.mediaOptions,
                                            function (o) {
                                              return _c("el-option", {
                                                key: o.value,
                                                attrs: {
                                                  label: o.label,
                                                  value: o.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                    key === "columns"
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            _vm.currentGroup.configJson[key],
                                            function (c, cIndex) {
                                              return _c(
                                                "el-row",
                                                { key: cIndex },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 9 } },
                                                    [
                                                      _c("label", [
                                                        _vm._v("文本"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          placeholder: "请输入",
                                                        },
                                                        model: {
                                                          value: c.label,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              c,
                                                              "label",
                                                              $$v
                                                            )
                                                          },
                                                          expression: "c.label",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 9 } },
                                                    [
                                                      _c("label", [
                                                        _vm._v("值"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          placeholder: "请输入",
                                                        },
                                                        model: {
                                                          value: c.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              c,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression: "c.value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "text",
                                                          plain: "",
                                                          size: "mini",
                                                          icon: "el-icon-plus",
                                                        },
                                                        on: {
                                                          click: () => {
                                                            _vm.currentGroup.configJson[
                                                              key
                                                            ].push({
                                                              value: undefined,
                                                              label: undefined,
                                                            })
                                                          },
                                                        },
                                                      }),
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "text",
                                                          plain: "",
                                                          size: "mini",
                                                          disabled:
                                                            _vm.currentGroup
                                                              .configJson[key]
                                                              .length == 1,
                                                          icon: "el-icon-delete",
                                                        },
                                                        on: {
                                                          click: () => {
                                                            _vm.currentGroup.configJson[
                                                              key
                                                            ].splice(cIndex, 1)
                                                            _vm.$forceUpdate()
                                                          },
                                                        },
                                                      }),
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "text",
                                                          plain: "",
                                                          size: "mini",
                                                          disabled: cIndex == 0,
                                                          icon: "el-icon-top",
                                                        },
                                                        on: {
                                                          click: () => {
                                                            _vm.currentGroup.configJson[
                                                              key
                                                            ].splice(cIndex, 1)
                                                            _vm.currentGroup.configJson[
                                                              key
                                                            ].splice(
                                                              cIndex - 1,
                                                              0,
                                                              c
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "text",
                                                          plain: "",
                                                          size: "mini",
                                                          disabled:
                                                            cIndex ==
                                                            _vm.currentGroup
                                                              .configJson[key]
                                                              .length -
                                                              1,
                                                          icon: "el-icon-bottom",
                                                        },
                                                        on: {
                                                          click: () => {
                                                            _vm.currentGroup.configJson[
                                                              key
                                                            ].splice(cIndex, 1)
                                                            _vm.currentGroup.configJson[
                                                              key
                                                            ].splice(
                                                              cIndex + 1,
                                                              0,
                                                              c
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.currentGroup.fieldType == "data" &&
                      _vm.currentGroup.quotas
                        ? _c(
                            "el-row",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c("label", { staticClass: "json-class" }, [
                                  _vm._v("展示字段"),
                                ]),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 17 } },
                                _vm._l(
                                  _vm.currentGroup.quotas,
                                  function (c, cIndex) {
                                    return _c(
                                      "el-row",
                                      { key: cIndex },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: { margin: "5px 0px" },
                                            attrs: { span: 7 },
                                          },
                                          [
                                            _c("label", [
                                              _vm._v(_vm._s(c.fieldName)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 7 } },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入文本注释",
                                              },
                                              model: {
                                                value: c.fieldExplain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    c,
                                                    "fieldExplain",
                                                    $$v
                                                  )
                                                },
                                                expression: "c.fieldExplain",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 3 } },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入目标",
                                              },
                                              model: {
                                                value: c.target,
                                                callback: function ($$v) {
                                                  _vm.$set(c, "target", $$v)
                                                },
                                                expression: "c.target",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 4 } },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": 0,
                                                "inactive-value": 1,
                                              },
                                              model: {
                                                value: c.deleted,
                                                callback: function ($$v) {
                                                  _vm.$set(c, "deleted", $$v)
                                                },
                                                expression: "c.deleted",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 3 } },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                type: "text",
                                                plain: "",
                                                size: "mini",
                                                disabled: cIndex == 0,
                                                icon: "el-icon-top",
                                              },
                                              on: {
                                                click: () => {
                                                  _vm.currentGroup.quotas.splice(
                                                    cIndex,
                                                    1
                                                  )
                                                  _vm.currentGroup.quotas.splice(
                                                    cIndex - 1,
                                                    0,
                                                    c
                                                  )
                                                },
                                              },
                                            }),
                                            _c("el-button", {
                                              attrs: {
                                                type: "text",
                                                plain: "",
                                                size: "mini",
                                                disabled:
                                                  cIndex ==
                                                  _vm.currentGroup.quotas
                                                    .length -
                                                    1,
                                                icon: "el-icon-bottom",
                                              },
                                              on: {
                                                click: () => {
                                                  _vm.currentGroup.quotas.splice(
                                                    cIndex,
                                                    1
                                                  )
                                                  _vm.currentGroup.quotas.splice(
                                                    cIndex + 1,
                                                    0,
                                                    c
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }