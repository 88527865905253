<template>
  <div v-if="task && task.configJson">
    <TaskBig v-if="moduleType==='big'" class-name="group" :task="task" />
    <TaskSmall v-if="moduleType==='small'" class-name="group" :task="task" />
    <TaskMini v-if="moduleType==='mini'" class-name="group" :task="task" />
    <TaskHorizontal v-if="moduleType==='horizontal' || moduleType==='group'" :task="task" />
    <TaskHighRowOne v-if="moduleType==='highRowOne'" :task="task" />
  </div>
</template>

<script>
import { parseTime, hexToRgba } from "@/utils";
import TaskBig from '../TaskBig'
import TaskSmall from '../TaskSmall'
import TaskMini from '../TaskMini'
import TaskHorizontal from '../TaskHorizontal'
import TaskHighRowOne from '../TaskHighRowOne'

export default {
  name: "TaskItem",
  components: { TaskBig, TaskMini, TaskSmall, TaskHorizontal, TaskHighRowOne },
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    moduleType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      parseShowTime: parseTime,
      hexToRgba,
    }
  },
  computed: {
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped src='@/style/appTask/groupItemV2.scss'></style>
