let time = new Date();
let currentYear = time.getFullYear();
let currentMonth = time.getMonth() + 1;

let planYear = currentYear;
let planMonth = currentMonth + 1;
if (planMonth > 12) {
  planMonth = planMonth - 12;
  planYear = planYear + 1;
}

const currentYM = currentYear + "" + (currentMonth < 10 ? "0" + currentMonth : currentMonth);
const planYM = planYear + "" + (planMonth < 10 ? "0" + planMonth: planMonth);

const arrangePlanHead = [
  {label: '年月', prop: 'plan_month', minWidth: '65px'},
  {label: '状态', prop: 'plan_status', minWidth: '50px'},
  {label: '开始制定日期', prop: 'create_time', minWidth: '100px'},
  {label: '生效日期', prop: 'effect_time', minWidth: '80px'},
  {label: '选择策略', prop: 'month_avg_type_text', minWidth: '80px'}
];
const statusTips = [
  {text: '已完成', iconName: 'icon-success', className: 'success-tips'},
  {text: '进行中', iconName: 'icon-underway', className: 'underway-tips'},
  {text: '驳回', iconName: 'icon-fail', className: 'fail-tips'},
  {text: '未开始', iconName: 'icon-notImplemented', className: 'notImplemented-tips'},
];
const tableActionLink = [
  {link_color: '#27b55a', link_txt: '进度跟踪', link_fun: 'progressTracking', isShow: false},
  {link_color: '#409EFF', link_txt: '重置开始', link_fun: 'resetStart', isShow: false},
  {link_color: '#409eff', link_txt: '查看明细', link_fun: 'detailsView', isShow: false},
  {link_color: '#f1992d', link_txt: '执行情况', link_fun: 'executeCondition', isShow: true}
];
const prodTableActionLink = [
  {link_color: '#27b55a', link_txt: '进度跟踪', link_fun: 'progressTracking', isShow: false},
  {link_color: '#409EFF', link_txt: '流程重置', link_fun: 'resetProdFlow', isShow: true},
];
const salesPhaseProject = [
  {label: '分公司客订检核', value: '1', icon: 'icon-success', className: 'success-item'},
  {label: '库存系数设定', value: '2', icon: 'icon-underway', className: 'underway-item'},
  {label: '起订量调整', value: '3', icon: 'icon-fail', className: 'fail-item'},
  {label: '请购单确认', value: '4', icon: 'icon-notImplemented', className: 'notImplemented-item'},
  {label: '方案调整', value: '5', icon: 'icon-notImplemented', className: 'notImplemented-item'},
  {label: '排产确认', value: '6', icon: 'icon-notImplemented', className: 'notImplemented-item'}
];


const moldProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '模号', prop: 'mould_code', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'}
];

const moldProdTemplateSampleHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '模号', prop: 'mould_code', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '80px'},
  {label: '日总产能', prop: 'day_prod_count', minWidth: '80px'}
];

// 工艺大类、生产单位、工作日数、日总产能、定额目标
const craftProdTemplateSampleHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '工艺大类', prop: 'craft_type', minWidth: '80px'},
  {label: '定额目标', prop: 'prod_target_count', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '80px'},
  {label: '日总产能', prop: 'day_prod_count', minWidth: '80px'}
];

const penPointProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '笔头类型', prop: 'pen_type', minWidth: '100px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能(万)', prop: 'machine_day_prod_count', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
];

const penPointTemplateSampleHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '笔头类型', prop: 'pen_type', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '80px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能', prop: 'machine_day_prod_count', minWidth: '80px'},
]

const machineryProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '机装款式', prop: 'machinery_fit_style', minWidth: '100px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能(万)', prop: 'machine_day_prod_count', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
];

const productUnitReportHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'}
];

const machineryTemplateSampleHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '机装款式', prop: 'machinery_fit_style', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '80px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能', prop: 'machine_day_prod_count', minWidth: '80px'},
];

const moldingMachineProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '注塑类型', prop: 'injection_type', minWidth: '100px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能(万)', prop: 'machine_day_prod_count', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
];

const mysteryBoxProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能(万)', prop: 'machine_day_prod_count', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
];

const locationTransferProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '机台数', prop: 'machine_count', minWidth: '80px'},
  {label: '机台日产能(万)', prop: 'machine_day_prod_count', minWidth: '80px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
];

const handmadeProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '100px'},
  {label: '工艺大类', prop: 'craft_type', minWidth: '100px'},
  {label: '定额目标（万）', prop: 'prod_target_count', minWidth: '100px'},
  {label: '产能下限', prop: 'prod_percent_down_text', minWidth: '100px'},
  {label: '产能上限', prop: 'prod_percent_up_text', minWidth: '100px'},
  {label: '月产能（万）', prop: 'month_prod_count', minWidth: '100px'},
  {label: '工作日数', prop: 'work_day_count', minWidth: '100px'},
  {label: '日总产能（万）', prop: 'day_prod_count', minWidth: '100px'}
];

const lastMonthCarryProdCapacityHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '统计工艺', prop: 'craft', minWidth: '120px'},
  // { label: '料号', prop: 'material_code', minWidth: '130px' },
  // { label: '料号名称', prop: 'material_name', minWidth: '100px' },
  // { label: '上月实际排产', prop: 'last_month_arrange_count', minWidth: '100px' },
  {label: currentYM + '月结转量(万支)', prop: 'last_month_carryover_count', minWidth: '100px'}
];

const lastMonthTemplateSampleHead = [
  {label: '年月', prop: 'check_month', minWidth: '65px'},
  {label: '生产单位', prop: 'production_unit', minWidth: '80px'},
  {label: '统计工艺', prop: 'craft', minWidth: '80px'},
  {label: currentYM + '月结转量', prop: 'last_month_carryover_count', minWidth: '80px'},
];

const minimumOrderConfirmHead = [
  {label: '年月', prop: 'plan_month', minWidth: '65px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '140px'},
  // {label: '生产类型', prop: 'prod_type', minWidth: '100px'},
  {label: '原始料号', prop: 'material_code', minWidth: '140px'},
  {label: '换算料号', prop: 'trans_material_code', minWidth: '140px'},
  {label: '产品等级', prop: 'grade', minWidth: '100px'},
  {label: currentYM + '月底库存数量', prop: 'hg_last_month_store_count', minWidth: '140px'},
  {label: '分公司客订量', prop: 'bo_kd_plu_count', minWidth: '120px'},
  {label: '理论请购量(支)', prop: 'confirm_theory_shipment_count', minWidth: '120px'},
  {label: '起订量', prop: 'min_order_count', minWidth: '100px'},
  {label: '月均销量', prop: 'month_avg_sale_count', minWidth: '80px'},

  {label: planYM + '按理论请购量预计月底库存数量', prop: 'hq_store_count_if_custom', minWidth: '240px'},
  {label: planYM + '置零后预计月底库存', prop: 'hq_store_count_if_zero_order', minWidth: '180px'},
  {label: planYM + '起订量后预计月底库存', prop: 'hq_store_count_if_min_order', minWidth: '190px'},

  {label: planYM + '按理论请购量预计月底库存系数', prop: 'hq_store_ratio_if_custom', minWidth: '240px'},
  {label: planYM + '置零后预计月底库存系数', prop: 'hg_store_ratio_if_zero_order', minWidth: '200px'},
  {label: planYM + '调整至起订量后预计月底库存系数', prop: 'hg_store_ratio_if_min_order', minWidth: '248px'},

  {label: '品名', prop: 'plu_name', minWidth: '150px'},
  {label: '赛道名称', prop: 'race_name', minWidth: '80px'},
  {label: '大类', prop: 'main_category_name', minWidth: '100px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '140px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '140px'},
  {label: '初始请购量', prop: 'theory_shipment_count', minWidth: '100px'},

  {label: '生产中心是否确认', prop: 'is_prod_confirm', minWidth: '150px'}
]

export {
  arrangePlanHead,                        // 排单计划列表 -- 表头
  tableActionLink,                        // 排单计划列表 -- 操作
  prodTableActionLink,                    // 生产中心 -- 首页 -- 排单计划列表 -- 操作
  statusTips,                             // 月单定制中进度状态说明
  salesPhaseProject,                      // 月单定制中进度各个阶段

  moldProdCapacityHead,                   // 生产中心 -- 模具产能盘点 -- 表头
  moldProdTemplateSampleHead,             // 生产中心 -- 模具产能盘点 -- 表单上传示例 -- 表头

  penPointProdCapacityHead,               // 生产中心 -- 笔头产能盘点 -- 表头
  penPointTemplateSampleHead,             // 生产中心 -- 笔头产能盘点 -- 表单上传示例 -- 表头

  machineryProdCapacityHead,              // 生产中心 -- 机装产能盘点 -- 表头
  machineryTemplateSampleHead,            // 生产中心 -- 机装产能盘点 -- 表单上传示例 -- 表头
  productUnitReportHead,                  // 生产中心 -- 机装产能盘点 -- 生产单位合计

  moldingMachineProdCapacityHead,         // 生产中心 -- 注塑机产能盘点 -- 表头
  mysteryBoxProdCapacityHead,             // 生产中心 -- 盲盒机产能盘点 -- 表头
  locationTransferProdCapacityHead,       // 生产中心 -- 定位转印产能盘点 -- 表头
  handmadeProdCapacityHead,               // 生产中心 -- 工艺大类产能盘点 -- 表头

  lastMonthCarryProdCapacityHead,         // 生产中心 -- 上月结转量产能盘点 -- 表头
  lastMonthTemplateSampleHead,            // 生产中心 -- 上月结转量产能盘点 -- 表单上传示例 -- 表头

  minimumOrderConfirmHead,                 // 生产中心 --  起订量调整-- 表头
  craftProdTemplateSampleHead,            // 生产中心 -- 工艺大类产能盘点 -- 表单上传示例 -- 表头
}
