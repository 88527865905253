 
<template>
  <el-table-column
    :label="tableColumns.label"
    :prop="tableColumns.prop"
    :align="alignType"
    :class-name="tableColumns.headerCellClassName"
  >
    <template v-for="(item, index) in tableColumns.children">
      <mg-table-column v-if="item.children" :key="index" :tableColumns="item" :minWidth="item.minWidth" />
      <el-table-column v-else :key="index" v-bind="item" align="center">
        <template #default="scoped" v-if="item.slot">
          <slot :name="item.slot" v-bind="scoped" />
        </template>
      </el-table-column>
    </template>
  </el-table-column>
</template>

<script>
  export default {
    name: 'mgTableColumn',
    props: {
      tableColumns: {
        type: Object,
      },
      // 单元格文字对齐方式
      alignType: {
        type: String,
        default: 'center', // 默认居中
      },
      minWidth: {
        type: String,
      },
    },
  }
</script>
