import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";

/**
 *
 * ----------------------------- 公共部分相关接口 -----------------------------
 */

// 字典查询 -- 产品等级
export function handleProductGrade(data) {
  return axiosPost('/common_info/dict_def_list?t=' + Math.random(), data);
}

// 大类查询
export function handleProductMaxCatagory(data) {
  return axiosPost('/common_info/max_catagory?t=' + Math.random(), data);
}

// 中类查询
export function handleProductMidCatagory(data) {
  return axiosPost('/common_info/mid_catagory?t=' + Math.random(), data);
}

// 小类查询
export function handleProductMinCatagory(data) {
  return axiosPost('/common_info/min_catagory?t=' + Math.random(), data);
}

// 生产单位列表
export function handleProductUnitList(data) {
  return axiosPost('/common_info/production_unit_list', data);
}

// 注塑机类型列表
export function handleProductDictDefList(data) {
  return axiosPost('/common_info/dict_def_list', data);
}

// 赛道名称列表
export function handleRaceNameList(data) {
  return axiosPost('/common_info/race_name_list', data);
}

// 用户信息查询
export function handleUserInfo(data) {
  return axiosPost('/user/info?t=' + Math.random(), data);
}

// 用户信息查询
export function handleRoleList(data) {
  return axiosPost('/user/all_role_list?t=' + Math.random(), data);
}

// 排单计划进度查询
export function handleProcessStatus(data) {
  return axiosPost('/pdb_plan/process_status', data);
}

// 保存表格列宽
export function handleSaveGlobalTableConfig(data) {
  return axiosPost('/data-support/style/saveOrUpdate', data, 1);
}

// 获取表格列宽
export function handleGetGlobalTableConfig(data) {
  return axiosPost('/data-support/query/style', data, 1);
}

// 当前页数据是否可用
export function handleMonthOrderDataAvailable(data) {
  return axiosGet('/month-order/data-available', data, 1);
}