<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form size="mini" label-width="auto" ref="searchRef" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <CategorySearch
          v-if="queryForm.supplyDate"
          ref="category"
          :main.sync="queryForm.c1CatCode"
          :mid.sync="queryForm.c2CatCode"
          :sub.sync="queryForm.c3CatCode"
          style="display: -webkit-inline-box"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        >
        </CategorySearch>
        <DictSelect
          ref="itemSearch"
          :value.sync="queryForm.itemCode"
          showLabel="商品信息"
          :fullLabel="true"
          placeholder="可输入编号或名称"
          type="item"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleQuery">查询</el-button>
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleResetFields">清空</el-button>
        <el-form-item style="padding-left: 5px" >小类、商品请至少选择一个条件!</el-form-item>
      </el-form>
    </div>
    <UpdateTime :time="updateTime" />
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 150px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      @sort-change="handleSortChange"
    >
      <el-table-column label="商品编号" prop="itemCode" width="120" />
      <el-table-column label="商品名称" prop="itemName" width="260" />
      <el-table-column label="大分类" prop="c1CatName" />
      <el-table-column label="中分类" prop="c2CatName" />
      <el-table-column label="小分类" prop="c3CatName" />
      <el-table-column label="大仓库存" prop="storageQty" sortable />
      <el-table-column label="预测补货数量" prop="ycBhQtyTotal" sortable />
      <el-table-column label="最终补货数量" prop="zzBhQtyTotal" sortable />
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
import DictSelect from "../components/DictSelect/index.vue";
import CategorySearch from "../components/CategorySearch/index.vue";
import { getSummaryData, getCurrentDate, getAllDate, refreshCache } from "@/api/stockToShop";
import UpdateTime from "../components/UpdateTime/index.vue";
import { parseParam, parseTime } from "@/utils";
import {Notification} from "element-ui";

export default {
  // 商品配置中心
  name: "StockToShopCollect",
  components: { Pagination, CategorySearch, DictSelect, UpdateTime },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        c1CatCode: undefined,
        c2CatCode: undefined,
        c3CatCode: undefined,
        itemCode: undefined,
        supplyDate: undefined,
      },
      loading: false,
      updateTime: undefined,
    };
  },
  computed: {},
  created() {
    this.queryForm.supplyDate = parseTime(new Date(), "{y}{m}{d}");
    this.$forceUpdate();
    refreshCache(this.queryForm.supplyDate);
  },
  mounted() {
    // this.handleFilter();
    getCurrentDate().then((res) => {
      if (res.data) {
        this.updateTime = res.data
      }
    })
  },
  methods: {
    handleDateChange(date) {
      refreshCache(date);
    },
    handleQuery() {
      if (!this.queryForm.c3CatCode && !this.queryForm.itemCode) {
        Notification.warning('小类、商品请至少选择一个条件!');
        return false;
      }
      this.loading = true;
      getSummaryData('?' + parseParam(this.queryForm)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        if (this.pageData.total > 0) {
          this.updateTime = this.pageData.rows[0].dateKey;
        }
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleResetFields() {
      this.$refs.category.clearAll();
      this.$refs.itemSearch.clearAll();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
  },
};
</script>
