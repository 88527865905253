<template>
  <div>
    <div v-if="runningData.status" style="color: red; padding: 20px">
      <div v-if="runningData.status === '创建中'">当前有1条规则结果正在生成中，待生成完成可以查看</div>
      <div v-if="runningData.status === '创建成功'">当前有1条规则结果已生成，<span style="text-decoration:underline; cursor: pointer;" @click="handleClick('view')">点击查看</span></div>
      <div v-if="runningData.status === '创建失败'">当前有1条规则结果生成失败，<span style="text-decoration:underline;cursor: pointer;" @click="handleClick('run')">点击重试</span></div>
    </div>
    <el-button class="form-btn-item" style="margin-bottom: 10px;" size="mini" type="primary" :disabled = "qwMsgSendFlag" @click="handleGroupMsgSendResult">获取企微群发数据</el-button>
    <span style="margin-left: 20px">{{ groupMsgSendNotifyContent }}</span>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="新增" name="add">
        <Add ref="add" @tab-change="handleAddBack" @show-notice="val => runningData =val" />
      </el-tab-pane>
      <el-tab-pane label="分组结果" name="current">
        <Current ref="current" />
      </el-tab-pane>
      <el-tab-pane label="分发规则" name="rule">
        <Rule ref="rule" />
      </el-tab-pane>
      <el-tab-pane label="企微后台消息" name="custom">
        <Custom ref="custom" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Current from "./components/current";
import Add from "./components/add";
import Rule from "./components/rule";
import Custom from "./components/custom";
import {
  getGroupMsgSendResult,
  getGroupMsgSendResultStatus, getGroupMsgUnSendFinish,
} from "@/api/tagPlatform";
import timer from "@/utils/timer";

export default {
  name: "Distribute",
  components: { Current, Add, Rule, Custom },
  data() {
    return {
      activeName: 'add',
      runningData: {},
      groupMsgSendNotifyContent: '',
      qwMsgSendFlag: false,
    }
  },

  created() {
    this.startGetInfoInterval();
  },
  methods: {
    handleAddBack() {
      this.activeName='current';
      this.$nextTick(() => {
        this.$refs.current.handleQuery();
      })
    },
    handleClick(type) {
      this.$nextTick(() => {
        if (type === 'view') {
          this.$refs.add.getExecuteResult(this.runningData.id);
        } else if (type === 'run') {
          this.$refs.add.submitAddDistribute();
        }
      })
    },
    showNotice(val) {
      this.runningData = val;
    },
    handleGroupMsgSendResult() {
      getGroupMsgSendResult().then((res) => {
        if (res.code === 200) {
          this.groupMsgSendNotifyContent = '数据正在获取中...'
          timer.createTimer('groupMsgSendStatus', () => {
            this.handleGroupMsgSendResultStatus()
          }, 60000)
        }
        if (res.code !== 200) {
          timer?.clearTimer('groupMsgSendStatus');
          this.$message.error(res.msg);
        }
      })
    },
    handleGroupMsgSendResultStatus(){
      getGroupMsgSendResultStatus().then((res) => {
        if (res.code === 200) {
          this.groupMsgSendNotifyContent = res.data.content;
          if(res.data.status === '2' || res.data.status === '3'){
            timer?.clearTimer('groupMsgSendStatus');
          }
        }
        if (res.code !== 200) {
          timer?.clearTimer('groupMsgSendStatus');
          this.$message.error(res.msg);
        }
      })
    },
    startGetInfoInterval() {
      this.clearGetInfoInterVal();
      this.getInfo();
      this.qwMsgSendTimer = setInterval(() => {
        this.getInfo();
      }, 60000)
    },
    clearGetInfoInterVal() {
      if (this.qwMsgSendTimer) {
        clearInterval(this.qwMsgSendTimer);
      }
    },
    getInfo() {
      getGroupMsgUnSendFinish().then(res => {
        if (res.code === 200) {
          if (res.data) {
            this.qwMsgSendFlag = true;
            this.groupMsgSendNotifyContent = '数据正在获取中...'
          }else {
            this.qwMsgSendFlag = false;
            this.groupMsgSendNotifyContent = ''
            this.clearGetInfoInterVal();
          }
        }
        if (res.code !== 200) {
          this.clearGetInfoInterVal();
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
