<template>
  <div style="padding: 20px">
    <div class="mg-search">
      <el-form size="mini" label-width="auto" ref="searchRef" :inline="true" :model="queryForm"
               @keyup.enter.native="handleQuery">
        <el-form-item label="补货日期">
          <el-select v-model="queryForm.supplyDate" @change="handleDateChange">
            <el-option v-for="item in dateOptions" :label="item" :value="item" :key="item"/>
          </el-select>
        </el-form-item>
        <CategorySearch
          v-if="queryForm.supplyDate"
          ref="category"
          :main.sync="queryForm.c1CatCode"
          :mid.sync="queryForm.c2CatCode"
          :sub.sync="queryForm.c3CatCodes"
          multiple
          style="display: -webkit-inline-box"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        >
        </CategorySearch>
        <DictSelect
          ref="shopSearch"
          :value.sync="queryForm.topGrade"
          showLabel="商品等级"
          placeholder="请选择商品等级"
          type="grade"
          :date="true"
          :remote="false"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="skuSearch"
          :value.sync="queryForm.itemCode"
          showLabel="商品信息"
          :fullLabel="true"
          placeholder="可输入编号或名称"
          type="item"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="shopSearch"
          :value.sync="queryForm.storeCode"
          showLabel="店铺信息"
          :fullLabel="true"
          placeholder="可输入编号或名称"
          type="store"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="xdrSearch"
          :value.sync="queryForm.xdrId"
          showLabel="员工信息"
          :fullLabel="true"
          placeholder="请选择员工"
          type="xdr"
          :date="true"
          :remote="false"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="activitySearch"
          :value.sync="queryForm.actName"
          showLabel="活动名称"
          placeholder="请选择活动"
          type="activity"
          :date="true"
          :remote="false"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <el-form-item label="128清单">
          <el-select v-model="queryForm.is128List" clearable>
            <el-option :value="1" label="是">是</el-option>
            <el-option :value="0" label="否">否</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺信息批量查询">
          <el-input v-model="queryForm.storeCodes" placeholder="请输入店铺编码支持多个"/>
        </el-form-item>
        <el-form-item label="商品信息批量查询">
          <el-input v-model="queryForm.itemCodes" placeholder="请输入商品编码支持多个"/>
        </el-form-item>
        <DictSelect
          ref="flagRef"
          :value.sync="queryForm.ycFlag"
          showLabel="补货提示"
          placeholder="请选择补货提示"
          type="ycFlag"
          :date="true"
          :remote="false"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="flagRef"
          :value.sync="queryForm.mandatoryIdentification"
          showLabel="强补标识"
          placeholder="请选择强补标识"
          type="mandatoryIdentification"
          :date="true"
          :remote="false"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="flagRef"
          :value.sync="queryForm.bhCatFlag"
          showLabel="总量标识"
          placeholder="请选择总量标识"
          type="bhCatFlag"
          :date="true"
          :remote="false"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <div>
          <div style="float: left">
            <el-form-item label="标记">
              <el-radio-group v-model="queryForm.status">
                <el-radio :label="true">隐藏标记</el-radio>
                <el-radio :label="undefined">显示全部</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div style="float:right; padding-top:0.5%">
            全门店配货sku数量：{{ skuCount }}；配货路线门店数量：{{ storeCount }}
          </div>
        </div>
        <div style="clear: both;">
          <el-form-item label="数据范围">
            <el-radio-group v-model="queryForm.bhDate">
              <el-radio :label="undefined">全部门店</el-radio>
              <el-radio :label=this.queryForm.supplyDate>填报补货日期{{ this.queryForm.supplyDate }}的门店</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div style="clear: both;">
          <div style="float: left; ">
            <el-button size="mini" type="primary" @click="handleFilter">查询</el-button>
            <el-button size="mini" type="primary" @click="handleResetFields">清空</el-button>
            <el-button size="mini" type="primary" @click="handleFilterColumns">列编辑</el-button>
            <el-badge :value="queryForm.conditions.length" class="item" type="warning">
              <el-button size="mini" style="margin-left: 10px; margin-right: 10px" type="primary"
                         @click="handleCondition">
                条件编辑
              </el-button>
            </el-badge>
            <el-button v-if="currentDataDate===queryForm.supplyDate" size="mini" type="primary"
                       @click="handleBatchUpdate">批量修改补货单数量
            </el-button>
            <el-button size="mini" type="primary" :loading="allExportLoading" @click="exportData">导出</el-button>
            <span style="margin-right: 10px">
              <a v-if="excelDownloadInfo.status!==undefined && excelDownloadInfo.isSuccess" target="_blank"
                 :href="excelDownloadInfo.downloadUrl">{{
                  excelDownloadInfo.fileName
                }}</a>
              <a v-else-if="excelDownloadInfo.status!==undefined && excelDownloadInfo.status === '0'" class="redColor">{{
                  '（正在导出数据，请等待！）'
                }}</a>
              <a v-else-if="excelDownloadInfo.status!==undefined && !excelDownloadInfo.isSuccess"
                 style="color:red; text-decoration: auto;cursor: auto;">导出失败,请重新生成</a>
            </span>
            <el-button v-if="currentDataDate===queryForm.supplyDate" size="mini" type="primary"
                       :loading="produceLoading" @click="produceSupply">生成配货单
            </el-button>
            <span style="margin-right: 10px">
              <a
                v-if="disDownloadInfo.status!==undefined && disDownloadInfo.isSuccess && currentDataDate===queryForm.supplyDate"
                target="_blank" :href="disDownloadInfo.downloadUrl">{{
                  disDownloadInfo.fileName
                }}</a>
              <a
                v-else-if="disDownloadInfo.status!==undefined && disDownloadInfo.status === '0' && currentDataDate===queryForm.supplyDate"
                class="redColor">{{ '（正在生成配货单，请等待！）' }}</a>
              <a
                v-else-if="disDownloadInfo.status!==undefined && !disDownloadInfo.isSuccess && currentDataDate===queryForm.supplyDate"
                style="color:red; text-decoration: auto;cursor: auto;">生成配货单失败，请重新生成</a>
            </span>
            <el-button type="primary" size="mini" @click="handleJump">填报跳转</el-button>
              <span style="margin-left: 10px; font-weight: 600;">{{ reportData.userNum }} / {{ reportData.allNum }}</span>

          </div>
          <div style="float: right">
            <UpdateTime :time.sync="updateTime"/>
          </div>
        </div>
      </el-form>
    </div>
    <div style="padding-top: 15px">
      <el-table
        ref="table"
        :data="pageData.rows"
        height="calc(100vh - 180px)"
        v-loading="loading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
        :row-class-name="rowClassName"
      >
        <el-table-column type="selection" width="40"/>
        <el-table-column type="index" width="40"/>
        <template v-for="item in checkedColumns">
          <el-table-column
            v-if="allDynamicColumns[item] && (dayType === 'holiday' && allNormalColumns.indexOf(item) < 0 || dayType === 'normal' && allHolidayColumns.indexOf(item) < 0)"
            :key="item"
            :prop="allDynamicColumns[item].prop"
            :min-width="allDynamicColumns[item].minWidth"
            :label="allDynamicColumns[item].label"
            :align="allDynamicColumns[item].align"
            :fixed="allDynamicColumns[item].fixed"
            :sortable=" allDynamicColumns[item].sortable"
          >
            <template slot-scope="{ row }">
              <a v-if="allDynamicColumns[item].prop==='storeName'" style="color: #6a6ae9;font-size: 12px"
                 @click="handleCellClick(row)">{{ row.storeName }}</a>
              <span v-else-if="allDynamicColumns[item].prop==='is128List'">{{
                  row[allDynamicColumns[item].prop] === 1 ? '是' : (row[allDynamicColumns[item].prop] === 0 ? '否' : '')
                }}</span>
              <span v-else>{{ row[allDynamicColumns[item].prop] }}</span>
            </template>
          </el-table-column>
        </template>

        <el-table-column label="标记" prop="status" width="80px">
          <template slot-scope="{ row }">
            <el-switch v-model="row.status" :disabled="completeDisabled" @change="handleCompleteChange($event, row)"/>
          </template>
        </el-table-column>
        <!--        <el-table-column label="缺货数量" prop="qhCnt" sortable min-width="100px"/>-->
        <el-table-column label="补货提示" prop="ycFlag" sortable min-width="100px"/>
        <el-table-column label="总量标识" prop="bhCatName" sortable min-width="100px"/>
        <el-table-column label="预测补货数量标识" prop="ycBhQtyFlag" min-width="100px"/>
        <el-table-column label="强补标识" prop="mandatoryIdentification" sortable min-width="100px"/>
        <el-table-column label="最终补货数量(预测辅助数据)" prop="zzBhQty" sortable min-width="120px">
          <template slot-scope="{ row }">
            <span v-if="currentDataDate!==queryForm.supplyDate">{{ row.zzBhQty }}</span>
            <el-input-number
              v-else
              v-model="row.zzBhQty"
              controls-position="right"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              :min="0"
              :disabled="currentDataDate!==queryForm.supplyDate"
              @change="handleChangeInput($event, row, 'zzBhQty')"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="配货单数量(预测辅助数据)" prop="phdQty" sortable min-width="100px"/>
        <el-table-column label="调整说明(预测辅助数据)" prop="remark" min-width="180px">
          <template slot-scope="{ row }">
            <span v-if="currentDataDate!==queryForm.supplyDate">{{ row.remark }}</span>
            <el-input
              v-else
              v-model="row.remark"
              controls-position="right"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              type="textarea"
              :disabled="currentDataDate!==queryForm.supplyDate"
              @change="handleChangeInput($event, row, 'remark')"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :pageSizes="[10, 20, 30, 50, 100, 200, 500]" :page.sync="queryForm.current"
                  :limit.sync="queryForm.size"
                  @pagination="handleQuery"/>
    </div>

    <el-dialog title="列编辑" :visible.sync="dialog.filterColumnVisible" width="700px">
      <div style="width: 25%; position: relative; float: right; right: 60%; top: -50px;">
        <el-button size="mini" type="primary" @click="handleCheckAll(false)">清空</el-button>
        <el-button size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
      </div>
      <div style="margin-bottom: 10px; color: gray;"><span>说明：页面表头指标 展示顺序同指标添加先后顺序</span></div>
      <div v-for="(item, key, index) in checkedCopy.filterColumns" :key="index" style="margin-bottom: 15px">
        <span style="margin-right: 10px; font-weight: 600">{{ item.label }}</span>
        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                     @change="handleCheckAllChange($event, key, item.label)">
          全选
        </el-checkbox>
        <div style="margin: 10px 0"></div>
        <el-checkbox-group v-model="item.checked" @change="handleCheckedChange($event, key, item.label)">
          <el-checkbox v-for="option in item.list" :label="option" :key="option"
                       :disabled="disableColumns.indexOf(option) > -1" style="width: 20%">{{
              option
            }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialog.filterColumnVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmChecked">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量修改补货单数量" :visible.sync="updateDialog.updateVisible" width="400px" style="max-height: 500px">
      <el-form ref="stockForm" :model="updateDialog.form" label-width="80px">
        <el-form-item
          label="补货数量"
          prop="supplyNum"
          :rules="[
            {
              required: true,
              message: '不能为空',
              trigger: 'change',
            },
          ]"
        >
          <el-input-number v-model="updateDialog.form.supplyNum" placeholder="请输入" :min="0" :controls="false"
                           size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="调整说明" prop="remark">
          <el-input v-model="updateDialog.form.remark" placeholder="请输入" :controls="false" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="updateDialog.updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleBatchSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="条件编辑" :visible.sync="conditionDialog.visible" width="60%">
      <div>
        <el-button size="mini" type="primary" @click="addCondition">添加条件</el-button>
      </div>
      <el-table :data="conditionDialog.tableData">
        <el-table-column type="index"/>
        <el-table-column label="字段选择" prop="compareLeftField" width="120px">
          <template slot-scope="{ row }">
            <el-select v-model="row.compareLeftField" placeholder="请选择" size="mini" filterable>
              <el-option v-for="item in compareColumns" :label="item.label" :value="item.prop" :key="item.prop"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="判断方式" prop="compareType" width="200px">
          <template slot-scope="{ row }">
            <el-radio-group v-model="row.compareType">
              <el-radio label="已有字段"></el-radio>
              <el-radio label="自定义"></el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="比较值" prop="compareExpress">
          <template slot-scope="{ row }">
            <el-select v-model="row.compareSymbol" placeholder="比较符" size="mini" style="width: 50px">
              <el-option v-for="item in compareOperation" :label="item" :value="item" :key="item"/>
            </el-select>
            <div v-if="row.compareType === '自定义'" style="display: inline-block">
              <el-input-number v-model="row.compareValue" placeholder="请输入非负整数" :controls="false" size="mini"/>
            </div>
            <div v-else style="display: inline-block">
              <el-select v-model="row.compareRightField" placeholder="请选择比较字段" size="mini" filterable>
                <el-option v-for="item in compareColumns" :label="item.label" :value="item.prop" :key="item.prop"/>
              </el-select>
              <el-select v-model="row.arithmeticSymbol" placeholder="运算符" size="mini" style="width: 50px">
                <el-option v-for="item in arithmeticOperation" :label="item" :value="item" :key="item"/>
              </el-select>
              <el-input-number v-model="row.compareValue" placeholder="请输入数字" :controls="false" :step="0.01"
                               size="mini"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="action" width="120px">
          <template slot-scope="{ row }">
            <el-button-group>
              <el-button size="mini" type="text" plain @click="handleCopyRow(row)">复制</el-button>
              <el-button size="mini" type="text" plain @click="handleDeleteRow(row.id)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="handleConfirmCondition">确 定</el-button>
        <el-button @click="conditionDialog.visible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
import {deepClone, excelExport, parseTime} from "@/utils";
import {filterColumns, allDynamicColumns, compareColumns, defaultCheckedColumns, disableColumns} from "../config";
import {Message, Notification} from "element-ui";
import CategorySearch from "../components/CategorySearch/index.vue";
import DictSelect from "../components/DictSelect/index.vue";
import {
  page,
  update,
  updateBatch,
  getDownloadRecord,
  getCurrentDate,
  distribute,
  getHoliday,
  refreshCache,
  addCompleteRecord,
  deleteCompleteRecord,
  exportBhData,
  getTbStatistics, getReportData
} from "@/api/stockToShop";
import UpdateTime from "../components/UpdateTime/index.vue";

export default {
  // 商品配置中心
  name: "StockToShopDetail",
  components: {Pagination, CategorySearch, DictSelect, UpdateTime},
  data() {
    return {
      skuCount: undefined,
      storeCount: undefined,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        c1CatCode: undefined,
        c2CatCode: undefined,
        c3CatCode: undefined,
        c3CatCodes: undefined,
        storeCode: undefined,
        storeName: undefined,
        itemCode: undefined,
        itemName: undefined,
        xdrId: undefined,
        conditions: [],
        sorts: "ycBhQty desc",
        activeName: undefined,
        is128List: undefined,
        supplyDate: undefined,
        storeCodes: undefined,
        itemCodes: undefined,
        ycFlag: undefined,
        mandatoryIdentification: undefined,
        bhCatFlag: undefined,
        bhDate: undefined
      },
      loading: false,
      allDynamicColumns,
      compareColumns,
      dialog: {
        filterColumnVisible: false,
      },
      updateDialog: {
        updateVisible: false,
        form: {
          supplyNum: undefined,
          remark: undefined,
        },
      },
      conditionDialog: {
        visible: false,
        tableData: [],
      },
      disableColumns,
      filterColumns,
      compareOperation: [">=", ">", "=", "<", "<="],
      arithmeticOperation: ["+", "-", "*", "/"],
      dayType: 'normal',
      exportLoading: false,
      produceLoading: false,
      updateTime: undefined,
      exportFixColumns: {
        prefixColumns: {
          xdrName: '下单人姓名',
          storeCode: '店铺编码',
          storeName: '店铺名称',
          itemCode: '商品编码',
          itemName: '商品名称',
          ycBeginDate: '预测开始日期',
          ycEndDate: '预测结束日期',
          ycSellDayNum: '预测销售天数'
        },
        holidayColumns: {
          holName: '预测包含节日',
          holBeginDate: '节日开始日期',
          holEndDate: '节日结束日期',
        },
        commonColumns: {
          ycSellQtyWeek1: '预测第1周销售数量',
          ycSellQtyWeek2: '预测第2周销售数'
        },
        suffixColumns: {
          ycBhQty: '预测补货数量',
          zzBhQty: '最终补货数量',
          remark: '调整说明'
        }
      },
      allExportLoading: false,
      disDownloadInfo: {
        fileName: undefined,
        downloadUrl: undefined,
        isSuccess: undefined,
        status: undefined
      },
      excelDownloadInfo: {
        fileName: undefined,
        downloadUrl: undefined,
        isSuccess: undefined,
        status: undefined
      },
      checkedCopy: {
        filterColumns: undefined,
        checked: undefined,
      },
      checkedColumns: [],
      spliceColumn: '最终补货数量',
      otherSpliceColumn: '最终补货数量',
      allHolidayColumns: [],
      allNormalColumns: [],
      dateOptions: [],
      currentDataDate: undefined,
      completeDisabled: false,
      reportData: {
        allNum: 0,
        userNum: 0,
      }
    };
  },
  watch: {
    checkedColumns: {
      handler(newVal, oldVal) {
        localStorage.setItem('checked_columns', JSON.stringify({checked: newVal}));
      },
      immediate: false
    }
  },
  created() {
    getHoliday().then(res => {
      if (res.msg && this.dayType) {
        this.filterColumns.saleCount.list = this.filterColumns.saleCount.list.concat(this.filterColumns.saleCount.holidayList);
        this.filterColumns.forecastData.list.splice(4, 0, ...this.filterColumns.forecastData.holidayList);
        this.dayType = "holiday";
      } else {
        this.filterColumns.forecastData.list.splice(4, 0, ...this.filterColumns.forecastData.normalList);
        this.dayType = "normal";
      }
      const checkedCache = localStorage.getItem('checked_columns') ? JSON.parse(localStorage.getItem('checked_columns')) : [];
      let defaultChecked = checkedCache && checkedCache.checked ? checkedCache.checked : defaultCheckedColumns;
      this.checkedColumns = defaultChecked;
      for (let key in this.filterColumns) {
        this.filterColumns[key].list.forEach(ele => {
          if (defaultChecked.indexOf(ele) > -1) {
            this.filterColumns[key].checked.push(ele);
            this.filterColumns[key].checkedBefore.push(ele);
          }
        })
      }
      this.allHolidayColumns = [].concat(this.filterColumns.saleCount.holidayList, this.filterColumns.forecastData.holidayList);
      this.allNormalColumns = [].concat(this.filterColumns.forecastData.normalList);
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    })
    this.getDistribuiteDownload();
    this.getExportDownload();

    for (let i = 0; i < 61; i++) {
      this.dateOptions.push(parseTime(new Date().getTime() - i * 24 * 60 * 60 * 1000, "{y}{m}{d}"));
    }
    this.queryForm.supplyDate = this.dateOptions[0];
    this.queryForm.bhDate = this.dateOptions[0];
    this.currentDataDate = this.dateOptions[0];
    this.$forceUpdate();
    refreshCache(this.queryForm.supplyDate);
    // getAllDate().then(res => {
    //   if (res.data && res.data.length > 0) {
    //     this.dateOptions = res.data;
    //     this.queryForm.supplyDate = res.data[0];
    //     this.currentDataDate = res.data[0];
    //     refreshCache(this.queryForm.supplyDate);
    //     this.$forceUpdate();
    //   }
    //   this.handleFilter();
    // })
    getReportData().then(res => {
      this.reportData = res.data;
    })
  },
  mounted() {
    getCurrentDate().then((res) => {
      if (res.data) {
        this.updateTime = res.data
      }
    })
    this.handleFilter();
  },
  methods: {
    getDistribuiteDownload() {
      getDownloadRecord('?type=1&create=false').then(res => {
        if (res.data.length > 0) {
          this.disDownloadInfo = res.data[0];
          if (this.disDownloadInfo.status === '0') {
            setTimeout(() => this.getDistribuiteDownload(), 10000)
          }
        }
      })
    },
    getExportDownload() {
      this.allExportLoading = true;
      getDownloadRecord('?type=2&create=true').then(res => {
        if (res.data.length > 0) {
          this.excelDownloadInfo = res.data[0];
          if (this.excelDownloadInfo.status && this.excelDownloadInfo.status === '0') {
            setTimeout(() => this.getExportDownload(), 10000)
          } else {
            this.allExportLoading = false;
          }
        } else {
          this.allExportLoading = false;
        }
      })
    },
    handleQuery() {
      // if (!this.queryForm.c3CatCode && !this.queryForm.itemCode && !this.queryForm.storeCode && !this.queryForm.xdrId) {
      //   Notification.warning('小类、商品、店铺、员工请至少选择一个条件!');
      //   return false;
      // }
      this.loading = true;
      this.pageData.rows = [];
      let query = Object.assign({}, this.queryForm);
      if (this.queryForm.c3CatCodes && this.queryForm.c3CatCodes.length > 0) {
        query.c3CatCodes = this.queryForm.c3CatCodes.join(',')
      } else {
        query.c3CatCodes = '';
      }
      page(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.table.doLayout();
        });
      });
      const bodyData = {
        bhDate: this.queryForm.supplyDate
      }
      getTbStatistics(bodyData).then(res => {
        this.skuCount = res.data.skuCount
        this.storeCount = res.data.storeCount
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleResetFields() {
      this.$refs.searchRef.resetFields();
      this.$refs.category.clearAll();
      this.$refs.shopSearch.clearAll();
      this.$refs.skuSearch.clearAll();
      this.$refs.xdrSearch.clearAll();
      this.$refs.activitySearch.clearAll();
      this.$refs.flagRef.clearAll();
      this.queryForm.storeCodes = undefined;
      this.queryForm.itemCodes = undefined;
      this.queryForm.is128List = undefined;
      this.queryForm.ycFlag = undefined;
      this.queryForm.mandatoryIdentification = undefined;
      this.queryForm.bhCatFlag = undefined;
      this.queryForm.conditions = [];
      this.conditionDialog.tableData = [];
    },
    getCheckedSpliceIndex() {
      const spliceIndex = this.checkedCopy.checkedColumns.indexOf(this.spliceColumn)
      if (spliceIndex > 2) {
        return this.checkedCopy.checkedColumns.indexOf(this.otherSpliceColumn);
      } else {
        return spliceIndex;
      }
    },
    handleCheckAllChange(val, key, label) {
      this.checkedCopy.filterColumns[key].checked = val ? this.checkedCopy.filterColumns[key].list : this.checkedCopy.filterColumns[key].defaultColumns;
      const spliceIndex = this.getCheckedSpliceIndex();
      this.checkedCopy.filterColumns[key].checkedBefore = [];
      this.checkedCopy.filterColumns[key].list.forEach(e => {
        // 全选
        if (val && this.checkedCopy.checkedColumns.indexOf(e) < 0) {
          this.checkedCopy.checkedColumns.splice(spliceIndex, 0, e);
        } else if (!val && disableColumns.indexOf(e) < 0 && this.checkedCopy.checkedColumns.indexOf(e) > -1) {
          const index = this.checkedCopy.checkedColumns.indexOf(e);
          this.checkedCopy.checkedColumns.splice(index, 1);
        }
      })
      this.checkedCopy.filterColumns[key].checkedBefore = this.checkedCopy.filterColumns[key].checked;
      this.checkedCopy.filterColumns[key].isIndeterminate = false;
    },
    handleCheckedChange(value, key, label) {
      const checkedCount = value.length;
      this.checkedCopy.filterColumns[key].checkAll = checkedCount === this.checkedCopy.filterColumns[key].list.length;
      this.checkedCopy.filterColumns[key].isIndeterminate = checkedCount > 0 && checkedCount < this.checkedCopy.filterColumns[key].list.length;
      const spliceIndex = this.getCheckedSpliceIndex();
      // 选中
      if (value.length > this.checkedCopy.filterColumns[key].checkedBefore.length) {
        this.checkedCopy.checkedColumns.splice(spliceIndex, 0, value[value.length - 1]);
      } else if (value.length < this.checkedCopy.filterColumns[key].checkedBefore.length) {
        // 取消选中
        this.checkedCopy.filterColumns[key].checkedBefore.forEach(e => {
          if (value.indexOf(e) < 0) {
            // 获取取消项
            const index = this.checkedCopy.checkedColumns.indexOf(e);
            this.checkedCopy.checkedColumns.splice(index, 1);
          }
        })
      }
      this.checkedCopy.filterColumns[key].checkedBefore = value;
    },
    handleSortChange(data) {
      const {prop, order} = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleFilterColumns() {
      this.checkedCopy = {
        filterColumns: deepClone(this.filterColumns),
        checkedColumns: Object.assign([], this.checkedColumns)
      };
      this.$forceUpdate();
      this.dialog.filterColumnVisible = true;
    },
    handleBatchUpdate() {
      if (!this.updateTime) {
        Notification.warning('数据未更新，无法修改最终补货数量及调整说明');
        return;
      }
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少勾选一条数据");
        return false;
      }
      this.updateDialog.form.supplyNum = undefined;
      this.updateDialog.updateVisible = true;
    },
    handleCondition() {
      this.conditionDialog.tableData = Object.assign([], this.queryForm.conditions);
      this.conditionDialog.visible = true;
    },
    addCondition() {
      const row = {
        id: new Date().getTime(),
        compareLeftField: undefined,
        compareType: "自定义",
        compareSymbol: undefined,
        compareRightField: undefined,
        arithmeticSymbol: undefined,
        compareValue: undefined,
      };
      this.conditionDialog.tableData.push(row);
    },
    handleDeleteRow(val) {
      // 删除条件编辑行
      let temp = [];
      this.conditionDialog.tableData.forEach(e => {
        if (val !== e.id) {
          temp.push(e);
        }
      });
      this.conditionDialog.tableData = temp;
    },
    handleCopyRow(val) {
      const row = Object.assign({}, val, {id: new Date().getTime()});
      this.conditionDialog.tableData.push(row);
    },
    handleConfirmCondition() {
      const tableData = this.conditionDialog.tableData;
      for (var i = 0; i < tableData.length; i++) {
        if (tableData[i].compareType === "自定义") {
          if (!tableData[i].compareLeftField || !tableData[i].compareSymbol || tableData[i].compareValue === undefined) {
            Notification.warning("第 " + (i + 1) + " 项存在未填写数据");
            return false;
          }
        } else if (tableData[i].compareType === "已有字段") {
          if (
            !tableData[i].compareLeftField ||
            !tableData[i].compareSymbol ||
            !tableData[i].compareRightField ||
            !tableData[i].arithmeticSymbol ||
            tableData[i].compareValue === undefined
          ) {
            Notification.warning("第 " + (i + 1) + " 项存在未填写数据");
            return false;
          }
        }
      }
      this.queryForm.conditions = Object.assign([], this.conditionDialog.tableData);
      this.conditionDialog.visible = false;
      this.handleFilter();
    },
    handleChangeInput(val, row, key) {
      if (!this.updateTime) {
        Notification.warning('数据未更新，无法修改最终补货数量及调整说明');
        return;
      }
      if (val === undefined && key === "zzBhQty") {
        Notification.warning("最终补货数量不能为空");
        return false;
      }
      row[key] = val;
      const data = {
        dateKey: row.dateKey,
        storeCode: row.storeCode,
        itemId: row.itemCode,
        zzBhQty: row.zzBhQty,
        remark: row.remark,
      };
      update(data).then(res => {
        if (res.code === 200) {
          Notification.success("修改成功");
          this.handleQuery()
        } else {
          Notification.error(res.msg);
        }
      });
    },
    handleBatchSubmit() {
      if (!this.updateTime) {
        Notification.warning('数据未更新，无法修改最终补货数量及调整说明')
      }
      let data = Object.assign([], this.pageData.selections);
      data.forEach(e => {
        e.zzBhQty = this.updateDialog.form.supplyNum;
        if (this.updateDialog.form.remark) {
          e.remark = this.updateDialog.form.remark;
        }
      });
      updateBatch({list: data}).then(res => {
        if (res.code === 200) {
          Notification.success("修改成功");
          this.updateDialog.updateVisible = false;
          this.handleQuery();
        } else {
          Notification.error(res.msg);
        }
      });
    },
    exportData() {
      if (!this.queryForm.c3CatCodes && !this.queryForm.itemCode && !this.queryForm.storeCode && !this.queryForm.xdrId && !this.queryForm.itemCodes && !this.queryForm.storeCodes) {
        Notification.warning('小类、商品、店铺、员工请至少选择一个条件!');
        return false;
      }
      this.allExportLoading = true;
      let queryParam = Object.assign(deepClone(this.queryForm), {exportSort: 'item_code,store_code'});
      exportBhData(queryParam).then(res => {
        Message.warning("数据导出中请勿关闭当前页面！");
        this.getExportDownload();
      })
    },
    handleCellClick(row) {
      this.$emit("cell-click", row, this.queryForm);
    },
    produceSupply() {
      this.produceLoading = true;
      distribute().then((res) => {
        this.produceLoading = false;
        if (res && res.code === 200) {
          Notification.success("操作成功");
        } else {
          Notification.error(res ? res.msg : '操作失败!')
        }
      })
      setTimeout(() => {
        this.getDistribuiteDownload()
      }, 3000);
    },
    handleCheckAll(checked) {
      for (let key in this.filterColumns) {
        this.checkedCopy.filterColumns[key].isIndeterminate = checked;
        this.checkedCopy.filterColumns[key].checkAll = checked;
        this.handleCheckAllChange(checked, key, this.checkedCopy.filterColumns[key].label);
      }
    },
    handleDateChange(date) {
      refreshCache(date);
      const bodyData = {
        bhDate: this.queryForm.supplyDate
      }
      getTbStatistics(bodyData).then(res => {
        this.skuCount = res.data.skuCount
        this.storeCount = res.data.storeCount
      })
    },
    handleConfirmChecked() {
      this.filterColumns = this.checkedCopy.filterColumns;
      this.checkedColumns = this.checkedCopy.checkedColumns;
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
      this.dialog.filterColumnVisible = false;
    },
    rowClassName(row) {
      if (row.row.status) {
        console.log(row)
        return "hideRow";
      } else {
        return;
      }
    },
    handleCompleteChange(val, row) {
      this.completeDisabled = true;
      if (val) {
        addCompleteRecord({storeCode: row.storeCode, itemCode: row.itemCode, status: true}).then(() => {
          Notification.success("操作成功！");
          this.completeDisabled = false;
        })
      } else {
        deleteCompleteRecord({storeCode: row.storeCode, itemCode: row.itemCode}).then(() => {
          Notification.success("操作成功！");
          this.completeDisabled = false;
        })
      }
    },
    handleJump() {
      window.open('https://bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/reports/562298294ee740c8ada3b26157eb171c', '_blank');
    }
  },
};
</script>
<style scoped>
::v-deep .hideRow {
  background-color: #e4e7ed;
}

::v-deep .el-dialog {
  max-height: 78vh;
  overflow: auto;
}

::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-badge__content.is-fixed {
  right: 25px !important;
}

::v-deep .el-badge.item {
  top: -1px;
}

.redColor {
  color: red !important;
}

a {
  color: blue;
  font-size: 15px;
  padding-left: 10px;
  text-decoration: underline;
}
</style>
