var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mg-container",
    {
      attrs: {
        isShowTips: true,
        isDisablePage: _vm.tipsConfig.isDisablePage,
        tipsConfig:
          (_vm.tipsConfig.tipsType &&
            _vm.tipsConfig.tips[_vm.tipsConfig.tipsType]) ||
          _vm.tipsConfig.tips["default"],
      },
      scopedSlots: _vm._u([
        {
          key: "headerSearch",
          fn: function () {
            return [
              _c("div", { staticClass: "monthlyOrderCustomized-head-box" }, [
                _vm.userName
                  ? _c(
                      "div",
                      { staticClass: "monthlyOrderCustomized-title" },
                      [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "monthlyOrderCustomized-name" },
                            [_vm._v(_vm._s(_vm.userName || "--"))]
                          ),
                          _vm._v(", 请选择您要使用的模块: "),
                        ]),
                        _c("mg-signboard", {
                          attrs: {
                            title:
                              _vm.branchCompanyInfos &&
                              _vm.branchCompanyInfos.name,
                            isHavBar: false,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("ul", { staticClass: "monthlyOrderCustomized-con" }, [
                  _c(
                    "li",
                    {
                      staticClass:
                        "monthlyOrderCustomized-con-item monthlyOrderCustomized-con-addOrderWarning",
                      on: {
                        click: function ($event) {
                          return _vm.handleHeadClick("addOrderWarning")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "iconfont icon-renwu-zengjia" }),
                      _c("span", { staticClass: "head-text" }, [
                        _vm._v("加单预警"),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass:
                        "monthlyOrderCustomized-con-item monthlyOrderCustomized-con-monthlyOrder",
                      on: {
                        click: function ($event) {
                          return _vm.handleHeadClick("monthlyOrder")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "iconfont icon-jihua" }),
                      _c("span", { staticClass: "head-text" }, [
                        _vm._v("月单定制"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "table",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "monthlyOrderCustomized-content" },
                [
                  _c("mg-signboard", {
                    attrs: { title: "分公司月单定制汇总" },
                  }),
                  _c(
                    "mg-table",
                    {
                      ref: "mgTable",
                      attrs: {
                        autoLoad: true,
                        columns: _vm.monthlyOrderCustomizedColumns,
                        api: _vm.handleMonthOrderLists,
                        apiParams: _vm.queryForm,
                        height: "calc(100vh - 300px)",
                        configurable: true,
                        businessType:
                          "BRANCHOFFICE_MONTHLYORDERCUSTOMIZED_LISTS",
                        "max-height": "auto",
                      },
                      on: {
                        "update:columns": function ($event) {
                          _vm.monthlyOrderCustomizedColumns = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "month",
                          fn: function ({ row }) {
                            return [
                              _vm._v(" " + _vm._s(row.month || "--") + " "),
                            ]
                          },
                        },
                        {
                          key: "flowStatus",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  style: {
                                    color:
                                      (![null, undefined].includes(
                                        row.flowStatus
                                      ) &&
                                        _vm.flowStatusColors[row.flowStatus]) ||
                                      "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (row.flowStatus &&
                                          _vm.flowStatus[row.flowStatus]) ||
                                          "--"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "customStartTime",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (row.customStartTime &&
                                      _vm.formatTime(row.customStartTime)) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "throwOrderTime",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (row.throwOrderTime &&
                                      _vm.formatTime(row.throwOrderTime)) ||
                                      "--"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "throwOrderCreator",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(row.throwOrderCreator || "--") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "操作",
                          width: "150px",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleTableAction(
                                          "continue",
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("继续定制")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleTableAction(
                                          "reset",
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("mgProgress", {
        ref: "mgProgress",
        attrs: { progressTips: _vm.progressTips },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }