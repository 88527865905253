/**
 * ----------------------------- 生产中心 -----------------------------
 */
import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";


/**
 * ----------------------------- 模具产能盘点相关接口 -----------------------------
 */

// 模具产能盘点列表
export function handlemoldProdLists(data) {
  return axiosPost('/capacity_mould/capacity_page', data);
}

// 模具产能盘点确认
export function handlemoldProdConfirm(data) {
  return axiosPost('/capacity_mould/confirm_capacity', data);
}

// 模具产能盘点保存
export function handlemoldProdSave(data) {
  return axiosPost('/capacity_mould/save_capacity', data);
}
// 模具产能盘点删除
export function handlemoldProdDelete(data) {
  return axiosPost('/capacity_mould/delete_capacity', data);
}

// 模具产能盘点Excel导入
export function handlemoldProdimportExcel(data) {
  return axiosPost('/capacity_mould/import_capacity', data);
}

// 模具产能工作天数批量修改
export function batchUpdateMouldWorkDayCount(data) {
  return axiosPost('/capacity_mould/batch_update_work_day_count', data);
}


/**
 * ----------------------------- 笔头产能盘点相关接口 -----------------------------
 */

// 笔头产能盘点列表
export function handlePenProdLists(data) {
  return axiosPost('/capacity_pen/capacity_page', data);
}

// 笔头产能盘点保存
export function handlePenProdSave(data) {
  return axiosPost('/capacity_pen/save_capacity', data);
}

// 笔头产能盘点确认
export function handlePenProdConfirm(data) {
  return axiosPost('/capacity_pen/confirm_capacity', data);
}

// 笔头产能盘点删除
export function handlePenProdDelete(data) {
  return axiosPost('/capacity_pen/delete_capacity', data);
}

// 笔头产能盘点Excel导入
export function handlePenProdImportExcel(data) {
  return axiosPost('/capacity_pen/import_capacity', data);
}

// 笔头产能工作天数批量修改
export function batchUpdatePenWorkDayCount(data) {
  return axiosPost('/capacity_pen/batch_update_work_day_count', data);
}

/**
 * ----------------------------- 机装产能盘点相关接口 -----------------------------
 */

// 机装产能盘点列表
export function handleMachineryProdLists(data) {
  return axiosPost('/capacity_machine/capacity_page', data);
}

// 机装产能生产单位合计
export function handleProductUnitReport(data) {
  return axiosPost('/capacity_machine/product_unit_report', data);
}

// 机装产能盘点保存
export function handleMachineryProdSave(data) {
  return axiosPost('/capacity_machine/save_capacity', data);
}

// 机装产能盘点确认
export function handleMachineryProdConfirm(data) {
  return axiosPost('/capacity_machine/confirm_capacity', data);
}

// 机装产能盘点Excel导入
export function handleMachineryProdImportExcel(data) {
  return axiosPost('/capacity_machine/import_capacity', data);
}

// 机装产能盘点删除
export function handleMachineryProdDelete(data) {
  return axiosPost('/capacity_machine/delete_capacity', data);
}

// 机装产能工作天数批量修改
export function batchUpdateMachineryWorkDayCount(data) {
  return axiosPost('/capacity_machine/batch_update_work_day_count', data);
}


/**
 * ----------------------------- 注塑机产能盘点相关接口 -----------------------------
 */

// 注塑机产能盘点列表
export function handleMoldingMachineProdLists(data) {
  return axiosPost('/capacity_injection/capacity_page', data);
}

// 注塑机产能盘点确认
export function handleMoldingMachineProdConfirm(data) {
  return axiosPost('/capacity_injection/confirm_capacity', data);
}

// 注塑机产能盘点保存
export function handleMoldingMachineProdSave(data) {
  return axiosPost('/capacity_injection/save_capacity', data);
}

// 注塑机产能盘点删除
export function handleMoldingMachineProdDelete(data) {
  return axiosPost('/capacity_injection/delete_capacity', data);
}

// 注塑机产能工作天数批量修改
export function batchUpdateMachineWorkDayCount(data) {
  return axiosPost('/capacity_injection/batch_update_work_day_count', data);
}


/**
 * ----------------------------- 盲盒机产能盘点相关接口 -----------------------------
 */

// 盲盒产能盘点列表
export function handleMysteryBoxProdLists(data) {
  return axiosPost('/capacity_box/capacity_page', data);
}

// 盲盒产能盘点确认
export function handleMysteryBoxProdConfirm(data) {
  return axiosPost('/capacity_box/confirm_capacity', data);
}

// 盲盒产能盘点确认
export function handleMysteryBoxProdAdd(data) {
  return axiosPost('/capacity_box/create_capacity', data);
}

// 盲盒产能盘点保存
export function handleMysteryBoxProdSave(data) {
  return axiosPost('/capacity_box/save_capacity', data);
}

// 盲盒产能盘点删除
export function handleMysteryBoxProdDelete(data) {
  return axiosPost('/capacity_box/delete_capacity', data);
}

// 盲盒产能工作天数批量修改
export function batchUpdateBoxWorkDayCount(data) {
  return axiosPost('/capacity_box/batch_update_work_day_count', data);
}


/**
 * ----------------------------- 定位转印产能盘点相关接口 -----------------------------
 */

// 定位转印产能盘点列表
export function handleLocationProdLists(data) {
  return axiosPost('/capacity_print/capacity_page', data);
}

// 定位转印产能盘点确认
export function handleLocationProdConfirm(data) {
  return axiosPost('/capacity_print/confirm_capacity', data);
}

// 定位转印产能盘点创建
export function handleLocationProdCreate(data) {
  return axiosPost('/capacity_print/create_capacity', data);
}

// 定位转印产能盘点保存
export function handleLocationProdSave(data) {
  return axiosPost('/capacity_print/save_capacity', data);
}

// 定位转印产能盘点删除
export function handleLocationProdDelete(data) {
  return axiosPost('/capacity_print/delete_capacity', data);
}

// 定位转印产能工作天数批量修改
export function batchUpdatePrintWorkDayCount(data) {
  return axiosPost('/capacity_print/batch_update_work_day_count', data);
}

/**
 * ----------------------------- 工艺产能产能盘点相关接口 -----------------------------
 */

// 工艺产能盘点列表
export function handleMadeProdLists(data) {
  return axiosPost('/capacity_craft/capacity_page', data);
}

// 工艺产能盘点确认
export function handleMadeProdConfirm(data) {
  return axiosPost('/capacity_craft/confirm_capacity', data);
}

// 工艺产能盘点创建
export function handleMadeProdCreate(data) {
  return axiosPost('/capacity_craft/create_capacity', data);
}

// 工艺产能盘点保存
export function handleMadeProdSave(data) {
  return axiosPost('/capacity_craft/save_capacity', data);
}

// 工艺产能盘点删除
export function handleMadeProdDelete(data) {
  return axiosPost('/capacity_craft/delete_capacity', data);
}

// 工艺产能盘点Excel导入
export function handleMadeProdImportExcel(data) {
  return axiosPost('/capacity_craft/import_capacity', data);
}

// 工艺产能工作天数批量修改
export function batchUpdateCraftWorkDayCount(data) {
  return axiosPost('/capacity_craft/batch_update_work_day_count', data);
}

/**
 * ----------------------------- 上月结转量产能盘点相关接口 -----------------------------
 */

// 上月结转量产能盘点列表
export function handleLastMonthProdLists(data) {
  return axiosPost('/capacity_carry/capacity_page', data);
}

// 上月结转量产能盘点确认
export function handleLastMonthProdConfirm(data) {
  return axiosPost('/capacity_carry/confirm_capacity', data);
}

// 上月结转量产能盘点保存
export function handleLastMonthProdSave(data) {
  return axiosPost('/capacity_carry/save_capacity', data);
}

// 上月结转量产能盘点删除
export function handleLastMonthProdDelete(data) {
  return axiosPost('/capacity_carry/delete_capacity', data);
}

// 上月结转量产能盘点Excel导入
export function handleLastMonthProdImportExcel(data) {
  return axiosPost('/capacity_carry/import_capacity', data);
}


/**
 * ----------------------------- 起订量调整相关接口 -----------------------------
 */

// 起订量调整列表(生产中心)
export function handleMinimumPluProdLists(data) {
  return axiosPost('/min_order_plu/min_order_plu_page_prod', data);
}

// 起订量调整生产中心确认
export function handleProdConfirmMinimumOrderPlu(data) {
  return axiosPost('/min_order_plu/prod_confirm_min_order_plu', data);
}

// 起订量调整保存
export function handleProdSaveMinimumOrderPlu(data) {
  return axiosPost('/min_order_plu/save_min_order_plu_prod', data);
}

// 起订量调整新增
export function handleProdAddMinimumOrderPlu(data) {
  return axiosPost('/capacity_injection/create_capacity', data);
}

/**
 * ----------------------------- 产能确认状态 -----------------------------
 *
 */
// 产能确认状态查询
export function queryCapacityConfirmStatus(data) {
  return axiosPost('/pdb_plan/capacity_confirm_status', data);
}

// 排单计划状态查询
export function queryPlanProcessStatus(data) {
  return axiosPost('/pdb_plan/process_status', data);
}

// 排单计划详情查询
export function queryPlanProcessList(data) {
  return axiosPost('/pdb_plan/process_list', data);
}

