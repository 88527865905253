<template>
  <div class="configCenter-page">
    <el-tabs class="configCenter-tabs" :tab-position="'top'" v-model="activeName" style="height: 200x;">
      <!-- 上月结余量_料号 -- 在线填写 -->
      <el-tab-pane label="在线填写" class="configCenter-tabs-item" name="onlineWrite">
        <div class="configCenter-content" v-if="activeName == 'onlineWrite'">
          <v-signboard :title="'上月结余量_料号列表'"/>
          <el-row>

            <el-form
              class="main-search"
              size="mini"
              label-width="auto"
              ref="configCenter-form"
              :inline="true"
              :model="form"
            >
              <el-col class="list-head">

                <el-form-item label="原始料号" size="mini">
                  <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
                </el-form-item>

                <el-button
                  size="mini"
                  @click="handleTableHeadAction('search')"
                >查 询
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="btnStatus.isSave"
                  @click="handleTableHeadAction('save')">保 存
                </el-button>
                <el-button
                  size="mini"
                  type="warning"
                  :disabled="btnStatus.isDelete"
                  @click="handleTableHeadAction('delete')">删 除
                </el-button>
                <el-button
                  size="mini"
                  @click="reverseChecked()">反 选
                </el-button>
              </el-col>
            </el-form>
          </el-row>
          <el-table
            class="table"
            stripe
            border
            size="mini"
            height="500"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="55"/>
            <el-table-column label="序号" align="center" width="50" fixed="left">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableObj.carryoverMaterialListHead">
              <!-- 可编辑列 -->
              <el-table-column v-if="['carryover_count'].includes(item.prop)"
                               v-bind="item" :key="index" align="center">
                <template slot-scope="scope">
                  <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'carryover_count'"
                            v-model="scope.row.carryover_count" size="mini" clearable/>
                </template>
              </el-table-column>
              <el-table-column
                v-else-if="['import_month', 'order_type_text', 'material_type', 'is_keep_text' ].includes(item.prop)"
                fixed="left" v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </el-tab-pane>
      <!-- 上月结余量_料号 -- 表单上传 -->
      <el-tab-pane label="表单上传" class="configCenter-tabs-item" name="formWrite">
        <div clas="form-upload-box" v-if="activeName == 'formWrite'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'"/>
              <el-row>
                <el-col class="handleConfirmUpload">
                  <el-button size="mini" type="primary">确认上传</el-button>
                </el-col>
              </el-row>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item"/>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'"/>
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams, optionsObj} from '@/mixins';
import {carryoverMaterialListHead, carryoverMaterialTemplateSampleHead} from '../config';
import {
  handleCarryoverMaterialLists,
  handleCarryoverMaterialSave,
  handleCarryoverMaterialDelete,
  handleCarryoverMaterialUpload
} from '@/api/configCenter';
import {getPlanYM} from "../../../utils";

export default {
  // 其他量导入
  name: 'configCenter',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      form:{
        order_type: '',
        race_name: '',
        material_code: '',
        trans_material_code: '',
        order_no: '',
        series: '',
      },
      btnStatus: {
        isSave: true,                   // 是否调整
        isDelete: true,                   // 是否调整
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        carryoverMaterialListHead
      },
      uploadFileObj: {
        tipsText: '必需字段: 原始料号、结转数量',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: carryoverMaterialTemplateSampleHead,
        templateSampleLists: [
          {
            carryover_month: getPlanYM(),
            material_code: 'QGP829402205E1H',
            carryover_count: '130,000'
          }
        ]
      },
      orderTypeText: {
        0: '老品',
        1: '其他量预留',
        2: '其他量已下'
      },
      selectRows: [],
      urlMonth: '',
      activeName: 'onlineWrite'
    }
  },
  watch: {
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isDelete = false;
          btnStatus.isAdjust = false;
        }
        if (n.length === 0) {
          btnStatus.isSave = true;
          btnStatus.isDelete = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    })
  },
  methods: {
    // 获取数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          material_code: form.material_code || ''
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          ...formParams
        };
        const {code, data, msg} = await handleCarryoverMaterialLists(params);
        if (data) {
          const {carryover_material_list, total} = data;
          this.modifyData(carryover_material_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----模具产能盘点列表有误');
      }
    },
    //实现反选
    reverseChecked() {
      this.lists.map(row => {
        for(let i = 0; i < this.selectRows.length; i++) {
          if(this.selectRows[i].id == row.id) {
            this.$refs['multipleTable'].toggleRowSelection(row, false);
            return;
          }
        }
        this.$refs['multipleTable'].toggleRowSelection(row, true);
      });
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            id: item.id,
            carryover_month: item.carryover_month || '--',
            material_code: item.material_code || '',
            carryover_count: item.carryover_count || 0
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作项
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {             // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              id: item.id || '',
              carryover_count: item.carryover_count || 0,
              material_code: item.material_code || '',
              carryover_month: item.carryover_month || ''
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            carryover_material_list: newItems
          };
          const {code, msg} = await handleCarryoverMaterialSave(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'delete': async () => {             // 删除
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item.id || '');
          });
          const params = {
            operator: this.urlParams.operator,
            carryover_material_id_list: newItems
          };
          const {code, msg} = await handleCarryoverMaterialDelete(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('删除成功');
          }
          if (code != '0000') this.getLists();
        },
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {            // 重置
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header

    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;
      if (uploadFileObj.tableHeader.indexOf('原始料号') === -1) return this.$message.warning('缺少原始料号字段');
      if (uploadFileObj.tableHeader.indexOf('结转数量') === -1) return this.$message.warning('缺少结转数量字段');
      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let newItem = {
          carryover_month: item['结转年月'],
          material_code: item['原始料号'],
          carryover_count: item['结转数量']
        };
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        carryover_material_list: newItems
      };
      const {code, success, msg} = await handleCarryoverMaterialUpload(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (success) return this.$message.success(msg);
      if (!success) this.activeName = 'onlineWrite';
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/configCenter/layoutMain.scss'></style>
