var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticStyle: { padding: "20px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品ID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.queryForm.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuId", $$v)
                      },
                      expression: "queryForm.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否已确认" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ifConfirm,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ifConfirm", $$v)
                        },
                        expression: "queryForm.ifConfirm",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "Y", label: "是" } }),
                      _c("el-option", { attrs: { value: "N", label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "系统匹配结果" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      value: _vm.queryForm.systemMatchResult,
                      linkageRef: "dc,shop",
                      init: true,
                      singleProps: "",
                      "init-options": _vm.options.matchResultOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "systemMatchResult",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      type: "platform",
                      value: _vm.queryForm.platforms,
                      singleProps: "",
                      multiple: "",
                      linkageRef: "shop-name",
                      init: true,
                      "init-options": _vm.options.platformsOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "platforms", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("DictSelect", {
                    ref: "shop-name",
                    attrs: {
                      type: "shop-name",
                      parentLinkageRef: "",
                      value: _vm.queryForm.shopNames,
                      singleProps: "",
                      dictFun: _vm.dictShopFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopNames", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ platforms: _vm.queryForm.platforms }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品型号" } },
                [
                  _c("DictSelect", {
                    ref: "goods",
                    attrs: {
                      type: "ec-cargo-no",
                      value: _vm.queryForm.ecCargoNos,
                      singleProps: "",
                      dictFun: _vm.dictGoodsFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "ecCargoNos", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "变动确认" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ifChanged,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ifChanged", $$v)
                        },
                        expression: "queryForm.ifChanged",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "已变化", label: "待确认" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否组装" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ifCombined,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ifCombined", $$v)
                        },
                        expression: "queryForm.ifCombined",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "是", label: "是" } }),
                      _c("el-option", { attrs: { value: "否", label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: " 是否有最终匹配" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ifFinalMatchSkuModel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ifFinalMatchSkuModel", $$v)
                        },
                        expression: "queryForm.ifFinalMatchSkuModel",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "全部", label: "全部" },
                      }),
                      _c("el-option", { attrs: { value: "1", label: "是" } }),
                      _c("el-option", { attrs: { value: "0", label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleBatchMatchConfirm },
                    },
                    [_vm._v("批量确认匹配")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row", [
            _c("p", [
              _c("strong", [_vm._v("SKU数据：")]),
              _vm._v(" 总计 " + _vm._s(_vm.countData.skuCount) + " 条, "),
              _c("strong", [_vm._v("精确匹配（1:1或人工维护）：")]),
              _vm._v(" " + _vm._s(_vm.countData.exactMatch) + " 条, "),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.countData.waitMatch)),
              ]),
              _vm._v("条待匹配"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tableRef",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _vm._v(" > "),
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "90",
              selectable: _vm.checkboxInit,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "平台", prop: "ptName", "min-width": "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "页面标题", prop: "title", "min-width": "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "SKUID", prop: "skuId", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "skuName", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#6a6ae9", "font-size": "12px" },
                        attrs: { target: "_blank", href: row.skuUrl },
                      },
                      [_vm._v(_vm._s(row.skuName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "商品图片", prop: "skuPic", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-image", {
                      staticStyle: { height: "80px", margin: "5px" },
                      attrs: {
                        "preview-src-list": [row.skuPic],
                        src: row.skuPic,
                        lazy: "",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#6a6ae9", "font-size": "12px" },
                        attrs: { target: "_blank", href: row.shopUrl },
                      },
                      [_vm._v(_vm._s(row.shopName))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "首次出现时间",
              prop: "firstSpiderTime",
              "min-width": "140",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "是否组装", prop: "ifCombined", "min-width": "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "系统匹配型号", prop: "skuModel", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "人工维护型号",
              prop: "skuModelByPerson",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "人工型号维护时间",
              prop: "skuModelModified",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "售卖价格", prop: "price", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.screenshotImageUrl
                      ? _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              width: "200",
                              trigger: "hover",
                            },
                          },
                          [
                            row.screenshotImageUrl
                              ? _c("el-image", {
                                  attrs: {
                                    "preview-src-list": [
                                      row.screenshotImageUrl,
                                    ],
                                    src: row.screenshotImageUrl,
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(row.price))]
                            ),
                          ],
                          1
                        )
                      : _c("span", [_vm._v(_vm._s(row.price))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "系统匹配售卖规格",
              prop: "skuSpecificationsBySystem",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "人工维护售卖规格",
              prop: "skuSpecificationsByPerson",
              width: "110",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "人工规格维护时间",
              prop: "skuSpecificationsModified",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "系统最后采集时间",
              prop: "lastSpiderTime",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "最终匹配", prop: "finalMatch", width: "140" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "180",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-class" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSkuModelEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                scope.row.ifShowConfirm === "N"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0px" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleMatchConfirm(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确认")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      "confirm-button-text": "编辑",
                                      "cancel-button-text": "保持原样",
                                      icon: "el-icon-info",
                                      "icon-color": "yellow",
                                      "confirm-button-type": "text",
                                      title:
                                        "当前SKU售卖商品可能存在变化，请确认；",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.handleEdit(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                      cancel: function ($event) {
                                        return _vm.submitEdit(scope.row, "2")
                                      },
                                    },
                                  },
                                  [
                                    scope.row.ifShowLinkChange === "Y"
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: {
                                              slot: "reference",
                                              size: "mini",
                                              type: "text",
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v("链接变动确认")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.page,
          limit: _vm.queryForm.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "limit", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择正确的匹配关系",
            visible: _vm.skuModelUpdateDialog.visible,
            "append-to-body": "",
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuModelUpdateDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "overflow-y": "scroll", height: "860px" } },
            [
              _c(
                "el-form",
                {
                  ref: "saveSkuModelUpdateFormRef",
                  attrs: {
                    size: "mini",
                    inline: true,
                    model: _vm.skuModelUpdateDialog.form,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { width: "1100px" } },
                        [
                          _c(
                            "el-col",
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v("识别到可能是以下商品,请选择:")]
                                ),
                              ]),
                              _vm._l(
                                _vm.skuModelUpdateOptions,
                                function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.skuModel,
                                      staticStyle: {
                                        display: "inline-block",
                                        "margin-top": "1%",
                                        "margin-left": "1%",
                                      },
                                      attrs: { label: item.skuModel },
                                    },
                                    [
                                      _c(
                                        "el-carousel",
                                        { attrs: { height: "200px" } },
                                        _vm._l(item.skuUrls, function (item1) {
                                          return _c(
                                            "el-carousel-item",
                                            { key: item1 },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  "preview-src-list": [item1],
                                                  src: item1,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "button-group" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn button-online",
                                              class: {
                                                selected:
                                                  _vm.skuModelUpdateDialog
                                                    .selectSkuModelButton ===
                                                  item.skuModel,
                                              },
                                              staticStyle: {
                                                width: "200px",
                                                "font-size": "10px",
                                                "margin-top": "1%",
                                              },
                                              attrs: {
                                                title: item.skuModelName,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handSkuModelChange(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(_vm._s(item.skuModel)),
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(
                                                  "型号品名：" +
                                                    item.skuModelName
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px dashed red",
                        "margin-top": "1%",
                        width: "1120px",
                      },
                    },
                    [
                      _c("el-form-item", {
                        staticClass: "redLabel",
                        staticStyle: {
                          "margin-top": "1%",
                          "margin-left": "2%",
                          color: "#ea2838",
                        },
                        attrs: { label: "最终型号" },
                      }),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-top": "1%" },
                          attrs: {
                            label: "请从上方选择自动带入型号或下拉直接选择:",
                          },
                        },
                        [
                          _c("DictSelect", {
                            ref: "skuModel",
                            attrs: {
                              type: "sku_model",
                              value:
                                _vm.skuModelUpdateDialog.form.skuModelByPerson,
                              dictFun: _vm.dictSkuModelsFun,
                              "init-props": {
                                label: "skuModel",
                                value: "skuModel",
                              },
                              remote: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.skuModelUpdateDialog.form,
                                  "skuModelByPerson",
                                  $event
                                )
                              },
                              change: (val) => _vm.handSkuModelInfoChange(val),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-left": "30%",
                                  "margin-top": "1%",
                                  border: "1px dashed black",
                                  height: "350px",
                                },
                              },
                              [
                                _c(
                                  "el-carousel",
                                  {
                                    staticStyle: { width: "300px" },
                                    attrs: { height: "300px" },
                                  },
                                  _vm._l(
                                    _vm.skuModelUpdateDialog
                                      .selectSkuModelOptions,
                                    function (item) {
                                      return _c(
                                        "el-carousel-item",
                                        { key: item },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              "preview-src-list": [item],
                                              src: item,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "20%",
                                      height: "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [
                                        _vm._v("你最终选择匹配的商品图片"),
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(
                                            "型号品名：" +
                                              _vm.skuModelUpdateDialog
                                                .skuModelName
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-left": "10%",
                                  "margin-top": "1%",
                                  border: "1px dashed black",
                                  height: "350px",
                                },
                              },
                              [
                                _c(
                                  "el-carousel",
                                  {
                                    staticStyle: { width: "300px" },
                                    attrs: { height: "300px" },
                                  },
                                  _vm._l(
                                    [_vm.skuModelUpdateDialog.form.skuPic],
                                    function (item) {
                                      return _c(
                                        "el-carousel-item",
                                        { key: item },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              "preview-src-list": [item],
                                              src: item,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "45%",
                                      height: "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [
                                        _vm._v("店铺商品图片"),
                                        _c("br"),
                                        _vm._v(
                                          _vm._s(
                                            "型号品名：" +
                                              _vm.skuModelUpdateDialog
                                                .shopSkuModelName
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "margin-top": "1%" } }, [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("识别到规格,请确认或修改:")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px dashed red",
                          "margin-top": "1%",
                          width: "1120px",
                        },
                      },
                      [
                        _c("el-form-item", {
                          staticClass: "redLabel",
                          staticStyle: {
                            "margin-top": "1%",
                            "margin-left": "2%",
                          },
                          attrs: { label: "最终规格" },
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "1%" },
                            attrs: {
                              label: "识别到规格:",
                              prop: "skuSpecificationsBySystem",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.skuModelUpdateDialog.form
                                    .skuSpecificationsBySystem
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "1%" },
                            attrs: {
                              label: "人工维护售卖规格",
                              prop: "skuSpecificationsByPerson",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "请输入规格",
                              },
                              model: {
                                value:
                                  _vm.skuModelUpdateDialog.form
                                    .skuSpecificationsByPerson,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.skuModelUpdateDialog.form,
                                    "skuSpecificationsByPerson",
                                    $$v
                                  )
                                },
                                expression:
                                  "skuModelUpdateDialog.form.skuSpecificationsByPerson",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "1%" },
                            attrs: { label: "是否组装", prop: "ifCombined" },
                          },
                          [
                            _c("DictSelect", {
                              ref: "ifSpu",
                              staticStyle: { width: "500px" },
                              attrs: {
                                type: "ifSpu",
                                value: _vm.skuModelUpdateDialog.form.ifCombined,
                                dictFun: _vm.dictSpuFun,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.skuModelUpdateDialog.form,
                                    "ifCombined",
                                    $event
                                  )
                                },
                                getOtherJsonParam: (callback) =>
                                  callback({
                                    platform:
                                      _vm.skuModelUpdateDialog.form.ptName,
                                  }),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    position: "absolute",
                    bottom: "5px",
                    width: "90%",
                    "background-color": "white",
                    height: "30px",
                  },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        float: "right",
                        "margin-top": "1%",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.skuModelUpdateDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.submitSkuModelEdit },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }