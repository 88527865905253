var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc,capacity,area-tag",
                      dictFun: _vm.dictFun,
                      disabled: _vm.orgDictDisabled,
                      fullLabel: "",
                      "default-first-option": "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "capacity,area-tag",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      parentLinkageRef: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组" } },
                [
                  _c("DictSelect", {
                    ref: "area-tag",
                    attrs: {
                      type: "area-tag",
                      value: _vm.queryForm.areaTag,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaTag", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺类型" } },
                [
                  _c("DictSelect", {
                    ref: "capacity",
                    attrs: {
                      type: "capacity",
                      value: _vm.queryForm.shopCapacity,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCapacity", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺编码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入店铺编码", clearable: "" },
                    model: {
                      value: _vm.queryForm.shopCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "shopCode", $$v)
                      },
                      expression: "queryForm.shopCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入店铺名称", clearable: "" },
                    model: {
                      value: _vm.queryForm.shopName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "shopName", $$v)
                      },
                      expression: "queryForm.shopName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.importDialog.visible = true
                        },
                      },
                    },
                    [_vm._v("区域分组导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("店铺分组导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.drawer.areaTargetVisible = true
                        },
                      },
                    },
                    [_vm._v("区域分组目标查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.drawer.shopTargetVisible = true
                        },
                      },
                    },
                    [_vm._v("店铺目标设定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-edit",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.drawer.dcAreaVisible = true
                        },
                      },
                    },
                    [_vm._v("管理区域分组")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 150px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
          },
          on: { "sort-change": _vm.handleSortChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司编码", prop: "orgCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司名称", prop: "orgName", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "区域分组", prop: "areaTag", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心编码", prop: "dcCode", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心名称", prop: "dcName" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺编码", prop: "shopCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺类型", prop: "shopCapacity", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { border: "0px", width: "20%" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "edit")
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle[_vm.saveTitle],
            visible: _vm.saveDialog.dialogVisible,
            width: "800px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "dialogVisible", $event)
            },
          },
        },
        [
          _c("div", { staticStyle: { margin: "10px" } }, [
            _vm._v(_vm._s(_vm.noticeMsg)),
          ]),
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.saveDialog.form,
                inline: true,
                rules: _vm.saveDialog.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺类型",
                    prop: "shopCapacity",
                    size: "mini",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled:
                        _vm.disableCapacity.indexOf(
                          _vm.currentRow.shopCapacity
                        ) > -1,
                      clearable: "",
                      placeholder: "请输入店铺类型",
                    },
                    model: {
                      value: _vm.saveDialog.form.shopCapacity,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "shopCapacity", $$v)
                      },
                      expression: "saveDialog.form.shopCapacity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组", prop: "areaTag", size: "mini" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.saveTitle === "view",
                      clearable: "",
                      placeholder: "请输入区域分组",
                    },
                    model: {
                      value: _vm.saveDialog.form.areaTag,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "areaTag", $$v)
                      },
                      expression: "saveDialog.form.areaTag",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.saveDialog.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: { importBaseParams: _vm.importDialog },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "区域分组目标查询",
            visible: _vm.drawer.areaTargetVisible,
            size: "75%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "areaTargetVisible", $event)
            },
          },
        },
        [_c("AreaGroup")],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "店铺目标设置",
            visible: _vm.drawer.shopTargetVisible,
            size: "83%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "shopTargetVisible", $event)
            },
          },
        },
        [
          _vm.taskInfo
            ? _c("TargetV2", {
                ref: "target",
                attrs: {
                  "task-info": _vm.taskInfo,
                  "auth-org-codes": _vm.authOrgs,
                },
                on: {
                  "show-commit": (val) => {
                    _vm.drawer.showCommit = val
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drawer.showCommit === "0",
                      expression: "drawer.showCommit === '0'",
                    },
                  ],
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: () => _vm.$refs.target.handleCommitUnify() },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.drawer.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "管理区域分组",
            visible: _vm.drawer.dcAreaVisible,
            size: "50%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "dcAreaVisible", $event)
            },
          },
        },
        [_c("DcArea")],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }