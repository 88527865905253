<template>
  <div class="new-box">
    <div class="mg-search" >
    <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter('hand')" @reset="resetQuery" @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item v-if="authOrgOptions.length > 1" label="分公司范围">
          <el-select v-model="queryForm.orgRange" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="queryForm.name" clearable placeholder="请输入标题关键字" />
        </el-form-item>
        <el-form-item label="核销类型">
          <el-select v-model="queryForm.type" clearable>
            <el-option v-for="item in typeOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <el-button v-if="authOrgAll" size="mini" type="primary"  style="border: 0px;" @click="handleEdit()">新建</el-button>
    <el-table
      ref="tableRef"
      :data="pageData.rows"
      size="small"
      class="customer-table"
      border
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column label="标题" prop="name"   />
      <el-table-column label="创建者" prop="createUser"  />
      <el-table-column label="核销类型" prop="type"  >
        <template #default="{ row }">
          <span>{{ typeOptions.find(e => e.value==row.type)?.label }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="200"  />
      <el-table-column label="操作" width="300" align="center">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px;" @click="handleView(row)">查看</el-button>
          <el-button v-if="authOrgAll" size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="viewDialog.titleMap[viewDialog.type]" :center="true" :visible.sync="viewDialog.visible" append-to-body width="750px" :close-on-click-modal="false" :before-close="handleBeforeClose">
      <div ref="divRef" style="overflow-y: scroll;height: 500px">
        <div slot="footer" class="dialog-footer" style="position: absolute; bottom: 5px;width: 90%;background-color: white;height: 30px">
          <div style="display: inline-block; float: right">
<!--            <el-button :disabled="buttonDisabled"  type="primary" size="mini" @click="handleSave">提交</el-button>-->
            <el-button type="primary" size="mini" @click="viewDialog.visible = false">关闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog>


    <el-dialog title="操作结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-drawer :title="currentRow.name" :visible.sync="viewDrawer.visible" size="70%" direction="rtl" append-to-body>
      <div class="mg-search" style="padding-bottom: 15px">
        <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="viewDrawer.queryForm" @keyup.enter.native="handleFilterModule">
          <el-form-item label="分公司">
            <DictSelect
              ref="ht-org"
              type="ht-org"
              :value.sync="viewDrawer.queryForm.orgCode"
              linkageRef="ht-dc"
              :dictFun="dictFun"
              fullLabel
              default-first-option
              :clearable="false"
              @changeLinkageRef="handleChangeLinkageRef"
            />
          </el-form-item>
          <el-form-item label="配送中心">
            <DictSelect
              ref="ht-dc"
              type="ht-dc"
              :value.sync="viewDrawer.queryForm.dcCode"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              @getOtherJsonParam="(callback) => callback({ orgCode: viewDrawer.queryForm.orgCode })"
            />
          </el-form-item>
<!--          <el-form-item label="关键字">-->
<!--            <el-input v-model="viewDrawer.queryForm.name" clearable placeholder="请输入标题关键字" />-->
<!--          </el-form-item>-->
<!--          <el-form-item label="核销类型">-->
<!--            <el-select v-model="viewDrawer.queryForm.type" clearable>-->
<!--              <el-option v-for="item in typeOptions" :label="item.label" :value="item.value" :key="item.value" />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="状态">
            <el-select v-model="viewDrawer.queryForm.statuss" clearable multiple>
              <el-option v-for="(item,key) in queryStatusOptions" :label="item" :value="key + ''" :key="key" />
            </el-select>
          </el-form-item>
          <div style="display: inline-block">
            <el-form-item v-for="item in viewDrawer.queryForm.queryFields" :label="item.fieldLabel" :key="item.id">
              <el-input v-if="item.fieldType==='input'" v-model="item.queryValue" clearable style="width: 180px;" />
              <el-select v-if="item.fieldType==='select'" v-model="item.queryValue" clearable>
                <el-option v-for="(e,key) in getConfigOptions(item)" :label="e.label" :value="e.value" :key="key" />
              </el-select>
              <el-date-picker
                v-if="item.fieldType==='date'"
                v-model="item.queryValue"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="开始日期"
                end-placeholder="结束日期" @change="(val) => {
                  if (val) {
                    item.queryValue1 = parseTime(val[0],  '{y}-{m}-{d}');
                    item.queryValue2 = parseTime(val[1],  '{y}-{m}-{d}')
                  } else {
                    item.queryValue1 = undefined;
                    item.queryValue2 = undefined;
                  }
                }" />
            </el-form-item>
          </div>
          <el-form-item>
            <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilterModule()">查询</el-button>
            <el-button class="form-btn-item" size="mini" type="primary" @click="handleExport(undefined)">导出</el-button>
            <DownLoadData type="QD_HX" />
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="tableRef"
        :data="viewDrawer.pageData.rows"
        height="calc(100vh - 250px)"
        v-loading="viewDrawer.loading"
        size="mini"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      >
        <el-table-column label="分公司" prop="orgName" width="120" align="center" />
        <el-table-column label="配送中心名称" prop="dcName" width="160" align="center">
          <template #default="{ row }">
            <span>{{ row.dcCode? (row.dcCode + "-" + row.dcName): row.dcCode }}</span>
          </template>
        </el-table-column>
        <template v-for="item in viewDrawer.dynamicColumns">
          <el-table-column :label="item.label" :prop="item.prop" :key="item.prop" align="center" />
        </template>
        <el-table-column label="状态" prop="status" width="140" align="center">
          <template #default="{ row }">
            <span>{{ queryStatusOptions[row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="createUser" width="140" align="center">
          <template #default="{ row }">
            <span>{{ row.createUser + '-' + row.createName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="140" align="center" />
        <el-table-column label="操作" width="150" align="center">
          <template #default="{ row }">
            <el-button  v-if="computeButtonShow(row.status, 'pass') || computeButtonShow(row.status, 'reject')" type="text" style="border: 0px;" @click="handleViewModuleDetail(row, 'audit')">审核</el-button>
            <el-button type="text" style="border: 0px;" @click="handleViewModuleDetail(row, 'view')">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="viewDrawer.pageData.total" :page.sync="viewDrawer.queryForm.current" :limit.sync="viewDrawer.queryForm.size" @pagination="handleFilterModule" />
    </el-drawer>

    <el-dialog :title="currentRow.name" :visible.sync="viewDetailDrawer.visible" size="50%" append-to-body>

      <div v-for="(item,index) in viewDetailDrawer.form.contents" :key="index" style="font-size: 14px; margin: 15px">
        <div>
          <div v-if="item.moduleType === '0'">
            <div v-for="field in item.fields" :key="field.id" style="display: inline-flex; width: 100%;padding: 5px">
              <div v-if="['text-title'].indexOf(field.fieldType) < 0" :style="field.fieldType==='data'? {}:{'display':'inline-flex'}">
                <div style="display: inline-block">
                  <label>{{ getShowFieldLabel(field, item) }}</label>
                  <span style="font-size: 10px">{{ getShowFieldDesc(field) }}</span>：
                </div>
                <div v-if="['upload'].indexOf(field.fieldType) > -1" style="display: inline-block">
                  <el-image v-for="(im,imIndx) in getModuleResource(getShowFieldValue(field, item), undefined)"
                            :preview-src-list="getModuleResource(getShowFieldValue(field, item), undefined)"
                            :key="imIndx" :src="im" lazy style="height: 80px;margin: 5px;">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div v-else-if="['upload-module'].indexOf(field.fieldType) > -1" style="display: inline-block">
                  <el-image v-for="(im,imIndx) in getModuleResource(getShowFieldValue(field, item), 'before')"
                            :preview-src-list="getModuleResource(getShowFieldValue(field, item), 'before')"
                            :key="imIndx" :src="im" lazy style="height: 80px;margin: 5px;">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-image v-for="(im,imIndx) in getModuleResource(getShowFieldValue(field, item), 'after')"
                            :preview-src-list="getModuleResource(getShowFieldValue(field, item), 'after')" :key="imIndx"
                            :src="im" lazy style="height: 80px;margin: 5px;">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div v-else-if="'group' == field.fieldType">
                  <div v-for="(childContent, cIndex) in item.childContents" :key="cIndex">
                    <div v-for="cField in childContent.fields" :key="cField.id">
                      <div v-if="['text-shop-info','text-title'].indexOf(cField.fieldType) < 0" :style="cField.fieldType==='data'? {}:{'display':'inline-flex'}">
                        <div style="display: inline-block">
                          <label>{{ getShowFieldLabel(cField, childContent) }}</label>
                          <span v-if="getShowFieldDesc(cField)" style="font-size: 10px">({{ getShowFieldDesc(cField) }})</span>：
                        </div>
                        <div v-if="['upload'].indexOf(cField.fieldType) > -1" style="display: inline-block">
                          <el-image
                            v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, childContent), undefined)"
                            :preview-src-list="getModuleResource(getShowFieldValue(cField, childContent), undefined)"
                            :key="cImIndx" :src="cim" lazy style="height: 80px;margin: 5px;">
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                        <div v-else-if="['upload-module'].indexOf(cField.fieldType) > -1" style="display: block">
                          <div style="display:inline-block;">
                            <span v-if="!getFieldConfigJson(cField).isOnlyBefore">打造前</span>
                            <!--                          {{getModuleResource(getShowFieldValue(cField, childContent), 'before')}}-->
                            <el-image
                              v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, childContent), 'before')"
                              :preview-src-list="getModuleResource(getShowFieldValue(cField, childContent), 'before')"
                              :key="cImIndx" :src="cim" style="height: 80px;margin: 5px;">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                          </div>
                          <div v-if="!getFieldConfigJson(cField).isOnlyBefore" style="display:inline-block;">
                            <span>打造后</span>
                            <el-image
                              v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, childContent), 'after')"
                              :preview-src-list="getModuleResource(getShowFieldValue(cField, childContent), 'after')"
                              :key="cImIndx" :src="cim" style="height: 80px;margin: 5px;">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                          </div>
                        </div>
                        <div v-else-if="'data' == cField.fieldType">
                          <el-table
                            :data="childContent.quotas"
                            height="120px"
                            size="mini"
                            highlight-current-row
                            :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
                          >
                            <el-table-column label="指标名" prop="fieldName" width="250" align="center" />
                            <el-table-column label="达成" prop="quotaValue" width="100" align="center">
                              <template #default="{ row }">
                                <span>{{ (row.quotaValue?row.quotaValue:'') + (row.unit && row.quotaValue? row.unit: '') }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="目标" prop="target" width="140" align="center">
                              <template #default="{ row }">
                                <span>{{ (row.target?row.target:'') + (row.unit && row.target? row.unit: '') }}</span>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                        <div v-else style="display: inline-block">{{ getShowFieldValue(cField, childContent) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="'data' == field.fieldType" style="display: block">
                  <el-table
                    :data="item.quotas"
                    height="120px"
                    size="mini"
                    highlight-current-row
                    :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
                  >
                    <el-table-column label="指标名" prop="fieldName" width="250" align="center"/>
                    <el-table-column label="达成" prop="quotaValue" width="100" align="center">
                      <template #default="{ row }">
                        <span>{{ (row.quotaValue?row.quotaValue:'') + (row.unit && row.quotaValue? row.unit: '') }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="目标" prop="target" width="140" align="center">
                      <template #default="{ row }">
                        <span>{{ (row.target?row.target:'') + (row.unit? row.unit: '') }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div v-else-if="'choose-shop' === field.fieldType" style="display: inline-block">
                  <span>{{ getShowFieldValue(field, item) }}</span>
                  <el-collapse v-model="viewDetailDrawer.activeName">
                    <el-collapse-item v-for="(childContent, cIndex) in item.childContents" :key="cIndex"
                                      :name="childContent.poiCode">
                      <template slot="title">
                        {{ childContent.shopInfo.shopName }} <span
                        :style="childContent.status==='-1'? {color: 'red'}:(childContent.status==='0'? {color: '#a0a09b'}: {color: 'green'})">{{
                          ' (' + viewDetailDrawer.shopStatusMap[childContent.status] + ')'
                        }}</span>
                      </template>
                      <div style="display: inline-block">
                        <span> {{ childContent.shopInfo.dcCode + '/' + childContent.shopInfo.shopCode }}</span>
                        <div>
                          <span>是否重点终端：</span> <span style="margin-right:10px ">{{
                            childContent.shopInfo.ifOptimumShop === '1' ? '是' : '否'
                          }}</span>
                          <span>终端容量标签：</span> <span style="margin-right:10px ">{{
                            childContent.shopInfo.shopCapacity
                          }}</span>
                          <span>包干负责人：</span> <span style="margin-right:10px">{{
                            childContent.shopInfo.userName ? (childContent.shopInfo.userName + '-' + childContent.shopInfo.nickName) : ''
                          }}</span>
                          <span>提交人：</span> <span style="margin-right:10px ">{{ childContent.createUser }}</span>
                          <span>提交时间：</span> <span>{{ childContent.createTime }}</span>
                        </div>
                      </div>
                      <div v-for="cField in childContent.fields" :key="cField.id">
                        <div v-if="['text-shop-info','text-title'].indexOf(cField.fieldType) < 0" :style="cField.fieldType==='data'? {}:{'display':'inline-flex'}">
                          <div style="display: inline-block">
                            <label>{{ getShowFieldLabel(cField, childContent) }}</label>
                            <span style="font-size: 10px">({{ getShowFieldDesc(cField) }})</span>
                          </div>
                          <div v-if="['upload'].indexOf(cField.fieldType) > -1" style="display: inline-block">
                            <el-image
                              v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, childContent), undefined)"
                              :preview-src-list="getModuleResource(getShowFieldValue(cField, childContent), undefined)"
                              :key="cImIndx" :src="cim" lazy style="height: 80px;margin: 5px;">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                          </div>
                          <div v-else-if="['upload-module'].indexOf(cField.fieldType) > -1" style="display: block">
                            <div style="display:inline-block;">
                              <span v-if="!getFieldConfigJson(cField).isOnlyBefore">打造前</span>
                              <!--                          {{getModuleResource(getShowFieldValue(cField, childContent), 'before')}}-->
                              <el-image
                                v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, childContent), 'before')"
                                :preview-src-list="getModuleResource(getShowFieldValue(cField, childContent), 'before')"
                                :key="cImIndx" :src="cim" style="height: 80px;margin: 5px;">
                                <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                              </el-image>
                            </div>
                            <div v-if="!getFieldConfigJson(cField).isOnlyBefore" style="display:inline-block;">
                              <span>打造后</span>
                              <el-image
                                v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, childContent), 'after')"
                                :preview-src-list="getModuleResource(getShowFieldValue(cField, childContent), 'after')"
                                :key="cImIndx" :src="cim" style="height: 80px;margin: 5px;">
                                <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                              </el-image>
                            </div>
                          </div>
                          <div v-else-if="'data' == cField.fieldType">
                            <el-table
                              :data="childContent.quotas"
                              height="120px"
                              size="mini"
                              highlight-current-row
                              :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
                            >
                              <el-table-column label="指标名" prop="fieldName" width="250" align="center" />
                              <el-table-column label="达成" prop="quotaValue" width="100" align="center">
                                <template #default="{ row }">
                                  <span>{{ (row.quotaValue?row.quotaValue:'') + (row.unit && row.quotaValue? row.unit: '') }}</span>
                                </template>
                              </el-table-column>
                              <el-table-column label="目标" prop="target" width="140" align="center">
                                <template #default="{ row }">
                                  <span>{{ (row.target?row.target:'') + (row.unit && row.target? row.unit: '') }}</span>
                                </template>
                              </el-table-column>
                            </el-table>
                          </div>
                          <div v-else style="display: inline-block">{{ getShowFieldValue(cField, childContent) }}</div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <span v-else>{{ getShowFieldValue(field, item) }}</span>
              </div>
            </div>
          </div>
          <div v-else>
            <template slot="title">
              {{item.shopInfo.shopName }} <span :style="item.status==='-1'? {color: 'red'}:(item.status==='0'? {color: '#a0a09b'}: {color: 'green'})">{{  ' ('+ viewDetailDrawer.shopStatusMap[item.status] +')' }}</span>
            </template>
            <div style="display: inline-block">
              <span> {{ item.shopInfo.dcCode + '/' + item.shopInfo.shopCode }}</span>
              <div>
                <span >是否重点终端：</span> <span  style="margin-right:10px ">{{ item.shopInfo.ifOptimumShop==='1'? '是': '否' }}</span>
                <span >终端容量标签：</span> <span style="margin-right:10px ">{{ item.shopInfo.shopCapacity }}</span>
                <span >包干负责人：</span> <span  style="margin-right:10px">{{ item.shopInfo.userName ? (item.shopInfo.userName + '-' + item.shopInfo.nickName) :'' }}</span>
                <span>提交人：</span> <span style="margin-right:10px ">{{ item.createUser }}</span>
                <span>提交时间：</span> <span>{{ item.createTime }}</span>
              </div>
            </div>
            <div v-for="cField in item.fields" :key="cField.id">
              <div v-if="['text-shop-info','text-title'].indexOf(cField.fieldType) < 0" style="display:inline-flex">
                <div style="display: inline-block">
                  <label>{{ getShowFieldLabel(cField, item) }}</label>
                  <span style="font-size: 10px">{{ getShowFieldDesc(cField) }}</span>
                </div>
                <div v-if="['upload'].indexOf(cField.fieldType) > -1">
                  <div >
                    <el-image v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, item), undefined)" :preview-src-list="getModuleResource(getShowFieldValue(cField, item), undefined)" :key="cImIndx" :src="cim" lazy style="height: 80px;margin: 5px;">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
                <div v-else-if="['upload-module'].indexOf(cField.fieldType) > -1" style="display: block">
                  <div style="display:inline-block;">
                    <span v-if="!getFieldConfigJson(cField).isOnlyBefore">打造前</span>
                    <!--                          {{getModuleResource(getShowFieldValue(cField, item), 'before')}}-->
                    <el-image v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, item), 'before')" :preview-src-list="getModuleResource(getShowFieldValue(cField, item), 'before')" :key="cImIndx" :src="cim" style="height: 80px;margin: 5px;">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div v-if="!getFieldConfigJson(cField).isOnlyBefore" style="display:inline-block;">
                    <span>打造后</span>
                    <el-image v-for="(cim,cImIndx) in getModuleResource(getShowFieldValue(cField, item), 'after')" :preview-src-list="getModuleResource(getShowFieldValue(cField, item), 'after')" :key="cImIndx" :src="cim" style="height: 80px;margin: 5px;">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
                <div v-else-if="'data' == cField.fieldType" >
                  <el-table
                    :data="item.quotas"
                    height="120px"
                    size="mini"
                    highlight-current-row
                    :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
                  >
                    <el-table-column label="指标名" prop="fieldName" width="250" align="center" />
                    <el-table-column label="达成" prop="quotaValue" width="100" align="center" />
                    <el-table-column label="目标" prop="target" width="140" align="center" />
                  </el-table>
                </div>
                <div v-else style="display: inline-block">{{ getShowFieldValue(cField, item) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px;">
          <label style="font-size: 16px;">审核进度</label>
        </div>
        <div style="margin-top: 5px">
          <el-table
            :data="item.operations"
            size="mini"
          >
            <el-table-column label="操作人" prop="userName" width="100">
              <template #default="{ row }">
                <span>{{ row.createUser }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流程节点" prop="action" width="120">
              <template #default="{ row }">
                <span>{{ getNodeName(row.action) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="action" width="120">
              <template #default="{ row }">
                <span>{{ statusActionMap[row.action] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="createTime" width="160" />
            <el-table-column label="备注" prop="remark" width="180" />
          </el-table>
        </div>
        <div style="margin-top: 10px">
            <label>审核说明</label><el-input clearable v-model="viewDetailDrawer.remark" placeholder="请输入" style="width: 300px;" type="textarea" />
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: right" v-if="!viewDetailDrawer.disabled">
          <el-button type="primary" size="mini" @click="viewDetailDrawer.visible = false">取消</el-button>
          <el-button v-if="viewDetailDrawer.action == 'audit' && computeButtonShow(viewDetailDrawer.form.contents[0].status, 'pass')" type="success" size="mini" @click="handleAudit(item, 0)">通过</el-button>
          <el-button v-if="viewDetailDrawer.action == 'audit' && computeButtonShow(viewDetailDrawer.form.contents[0].status, 'reject')"  type="danger" size="mini" @click="handleAudit(item, 1)">驳回</el-button>
          <el-button v-if="viewDetailDrawer.form.contents[0].ifRefuseShow == 'Y'"  type="danger" size="mini" @click="handleAudit(item, 3)">拒绝</el-button>
        </div>
      </div>
    </el-dialog>

    <el-drawer :title="configDrawer.dialogTitle[configDrawer.type]" :visible.sync="configDrawer.visible" size="100%" direction="rtl" append-to-body :close-on-click-modal="false" :wrapperClosable="false">
      <Config ref="config" :data="configDrawer.form" :timestamp="configDrawer.timestamp" />
      <div style="text-align: right; margin: 20px; position: absolute; right: 20px; bottom: 20px;">
        <el-button size="mini" :disabled="configDrawer.buttonLoading" @click="configDrawer.visible = false">取 消</el-button>
        <el-button v-if="configDrawer.type!=='view'" :disabled="configDrawer.buttonLoading" size="mini" type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  pageQdHx,
  getModuleDetail,
  listQdHxModuleConfig,
  pageQdHxModule,auditQdHx, getUserRoleKey, getQdHxStatus, getDownloadRecords, exportQdHxData, getQueryField, saveQdHxConfig, getQdHxConfigDetail
} from "@/api/appTask";
import { Notification } from "element-ui";
import {deepClone, parseTime} from "../../../utils";
import DownLoadData from "@/components/DownLoadData";
import Config from "./config";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
export default {
  name: "QDHx",
  components: { DownLoadData, Pagination, DictSelect, Config, SearchFilter },

  data() {
    return {
      deepClone,parseTime,
      dictFun: dictSelect,
      homeLoading: false,
      statusMap: {
        '1': '仅分公司审核',
        '2': '仅总部审核',
        '3': '分公司审核+总部审核',
        '4': '分公司审核+总部抽查',
      },
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        title: undefined,
        type: undefined,
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      viewDialog: {
        visible: false,
        type: undefined,
        titleMap: {
          'audit': '审核',
          'view': '查看',
        },
        form: {
          id: undefined,
          type: undefined,
          title: undefined,
          content: undefined,
          startTime: undefined,
          endTime:undefined,
          rangeTime: [],
          orgRange: [],
          random: 'N',
          randomNum: 1,
          deleted: 0,
          questions: [],
          examTimeLimit: undefined,
          examNumLimit: 'N',
          examNumLimitLabel: 'N',
        },
        rules: {
          title: [{ required: true, message: " ", trigger: "change" }],
          content: [{ required: true, message: " ", trigger: "change" }],
          rangeTime: [{ type: 'array',required: true, message: " ", trigger: "change" }],
        },
      },
      authOrgOptions: [],
      authOrg: [],
      authOrgCodes: [],
      viewDrawer: {
        visible: false,
        pageData: {
          total: 0,
          rows: [],
          selections: [],
        },
        dynamicColumns:[],
        defaultColumn: {
          label: undefined,
          prop: undefined,
        },
        queryForm: {
          current: 1,
          size: 10,
          title: undefined,
          type: undefined,
          statuss: undefined,
          orgCode: undefined,
          queryFields: []
        },
        loading: false,
      },
      viewDetailDrawer: {
        visible: false,
        disabled: false,
        form: {
          id: undefined,
          moduleType: undefined,
          orgCode: undefined,
          orgName: undefined,
          createTime: undefined,
          createUser: undefined,
          dcs: [],
        },
        remark: undefined,
        opList: [],
        action: undefined,
        activeName: undefined,
        shopStatusMap: {
          '-1': '未提交',
          '0': '草稿',
          '1': '已提交'
        }
      },
      typeOptions: [{ label: '渠道核销', value: '1'}, { label: '终端核销', value: '2'}],
      statusOptions: [{ label: '仅分公司审核', value: '1'}, { label: '仅总部审核', value: '2'}, { label: '分公司审核+总部审核', value: '1'}, { label: '分公司审核+总部抽查', value: '4'}],
      noticeDialog: {
        visible: false,
        msg: undefined
      },
      jbpShareUrl: '#/questionnaire/detail?id=',
      queryOrgOptions: [],
      buttonDisabled: false,
      currentRow: {},
      orgAuth: false,
      headAuth: false,
      queryStatusOptions: [],
      statusActionMap: {
        "save": '保存',
        "submit": '提交审批',
        "org_pass": '分公司审批通过',
        "pass": "审核通过",
        "reject": "审核驳回",
        "org_reject": '分公司审批驳回',
        "head_pass": '总部审批通过',
        "head_reject": '总部审批驳回',
        "withdraw": '撤回'
      },
      configDrawer: {
        visible: false,
        disabled: false,
        buttonLoading: false,
        dialogTitle: {
          edit: '编辑',
          view: '查看'
        },
        form: undefined,
        type: 'edit',
        timestamp: undefined
      },
    };
  },
  computed: {
  },
  watch: {
    "viewDialog.form.orgRange": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
      },
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrg = res.data;
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = this.authOrg.filter(e => e.value!=='ALL');
      if (res.data.length !== this.authOrgOptions.length) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.orgRange = this.authOrgOptions[0].value;
      }
      getUserRoleKey().then(res => {
        this.userRoleKeys = res.data;
        if (this.userRoleKeys.indexOf('jbp_channel_hx_head') > -1) {
          this.headAuth = true;
        }
        if (this.userRoleKeys.indexOf('jbp_channel_hx_org') > -1) {
          this.orgAuth = true;
        }
      })
      this.handleFilter();
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);

      pageQdHx(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery(){
        const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleQueryModule() {
      this.viewDrawer.loading = true;
      const param = deepClone(this.viewDrawer.queryForm);

      pageQdHxModule(param).then(res => {
        if (!res.data) {
          return false;
        }
        if (this.viewDrawer.dynamicColumns.length > 0) {
          res.data.records.forEach(e => {
            if (e.contents && e.contents.length> 0) {
              e.contents.forEach(c => {
                const column = this.viewDrawer.dynamicColumns.find(f => f.prop == c.fieldId);
                if (column) {
                  if (column.fieldType==='choose-shop') {
                    e[column.prop] = c.fieldValue && c.fieldValue!='null' ? JSON.parse(c.fieldValue).length : 0 + '家';
                  } else {
                    e[column.prop] = c.fieldValue;
                  }
                }
              })
            }
          })
        }
        this.viewDrawer.loading = false;
        this.viewDrawer.pageData.rows = res.data?.records;
        this.viewDrawer.pageData.total = res.data?.total;
      })
    },
    handleFilterModule() {
      this.viewDrawer.queryForm.page = 1;
      this.handleQueryModule();
    },
    async handleView(row) {
      this.currentRow = deepClone(row);
      const param = {
        hxId: row.id,
        moduleType: row.type == '1'?'0':'1'
      };
      this.viewDrawer.dynamicColumns = [];
      await listQdHxModuleConfig(param).then(res => {
        if (res.data && res.data.length > 0) {
          res.data.forEach(e => {
            if (e.configJson) {
              const configJson = JSON.parse(e.configJson);
              if (configJson.showTable) {
                this.viewDrawer.dynamicColumns.push({ label: e.fieldLabel, prop: e.id, fieldType: e.fieldType})
                if (e.fieldType==='choose-shop') {
                  this.viewDrawer.dynamicColumns.push({ label: '已提交', prop: 'submitTotal', fieldType: ''})
                }
              }
            }
          })
        }
      })
      this.viewDrawer.queryForm = {
        current: 1,
          size: 10,
          title: undefined,
          type: undefined,
          statuss: undefined,
          orgCode: undefined,
          queryFields: []
      },
      this.viewDrawer.queryForm.moduleType = param.moduleType;
      this.viewDrawer.queryForm.hxId = param.hxId;
      if (this.authOrgOptions[0].length === 0) {
        Notification.warning("未配置具体分公司权限");
        return
      }
      if (this.authOrgOptions[0].length === 1) {
        this.viewDrawer.queryForm.orgCode = this.authOrgOptions[0].value;
      }
      await this.handleQueryModule();
      getQueryField(param.hxId).then(res => {
        if (res.code === 200) {
          this.viewDrawer.queryForm.queryFields = res.data;
        }
      })

      getQdHxStatus({ hxId: param.hxId }).then(res => {
        this.queryStatusOptions = res.data;
      })
      this.viewDrawer.visible = true;
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后不会保存当前修改，请确认是否关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleDictChange(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          if (this.authOrgOptions.length === 0) {
            return;
          }
          if (this.authOrgOptions.length > 2) {
            if (oldVal && oldVal.length > 1) {
              if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.viewDialog.form.orgRange = this.viewDialog.form.orgRange.filter(e => e !== 'ALL');
              } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.viewDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.viewDialog.form.orgRange = [];
              } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.viewDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            } else {
              if (val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.viewDialog.form.orgRange = this.authOrg.map(e => e.value);
              } else if (val.indexOf('ALL') < -1 && val.length === this.authOrg.length -1) {
                this.viewDialog.form.orgRange = this.authOrg.map(e => e.value);
              }
            }
          }
        })
      }
    },
    getShowFieldLabel(field, item) {
      // const configJson = field.configJson? JSON.parse(field.configJson): field.configJson;
      switch (field.fieldType) {
        case "input":
          return field.fieldLabel;
        case "input-number":
          return field.fieldLabel;
        case "dc-select":
        case "org-select":
        case "date":
        case "date-range":
          return field.fieldLabel
        case "upload":
          return field.fieldLabel
        case "text-title":
          return ""
        case "text-shop-info":
          return field.fieldLabel;
        default:
          return field.fieldLabel;
      }
    },
    getShowFieldValue(field, item) {
      const configJson = field.configJson? JSON.parse(field.configJson): field.configJson;
      const unit = configJson && configJson.unit ? configJson.unit: ''
      switch (field.fieldType) {
        case "input":
          return field.fieldValue + unit;
        case "input-number":
          return field.fieldValue + unit;
        case "dc-select":
          let showOrg = "";
          let showDc = "";
          item.dcs.forEach(e => {
            if (!showOrg) {
              showOrg = e.orgCode + "-" + e.orgName
            }
            showDc+=e.dcCode + "-" + e.dcName+ "  "
          })
          return showOrg + "   " + showDc
        case "org-select":
          return item.orgCode + "-" + item.orgName
        case "date":
          return field.fieldValue;
        case "date-range":
          return field.fieldValue? JSON.parse(field.fieldValue).join('-'): field.fieldValue
        case "upload":
          return field.fieldValue ? JSON.parse(field.fieldValue): [];
        case "upload-module":
          return field.fieldValue ? JSON.parse(field.fieldValue): [];
        case "text-title":
          return ""
        case "text-shop-info":
          return '';
        case "choose-shop":
          return field.fieldValue ? JSON.parse(field.fieldValue).length: field.fieldValue;
        default:
          return field.fieldValue;
      }
    },
    getModuleResource(r, prop) {
      if (!prop) {
        return r ? r.filter(e => e.fileType=='image').map(e => e.path): []
      }
      return r[prop] ? r[prop].filter(e => e.fileType=='image').map(e => e.path): []
    },
    getShowFieldDesc(field) {
      // if (field.fieldType === 'choose-shop') {
      //   return field.fieldValue
      // }
      const configJson = field.configJson? JSON.parse(field.configJson): field.configJson;
      if (configJson && configJson.description) {
        return '(' + configJson.description + ')'
      }
    },
    handleViewModuleDetail(row, action) {
      this.viewDetailDrawer.activeName = undefined;
      getModuleDetail({ contentId: row.id, moduleType: row.moduleType, hxId: row.hxId}).then(res => {
        this.viewDetailDrawer.form.contents = res.data;
        this.viewDetailDrawer.opList = res.data.operations;
        this.viewDetailDrawer.action = action;
        this.viewDetailDrawer.visible = true;
        this.viewDetailDrawer.disabled = false;
      })
    },
    handleAudit(data, action) {
      this.viewDetailDrawer.disabled = true;
      auditQdHx({ contentId: data.id, hxId: data.hxId, optType: action, remark: this.viewDetailDrawer.remark }).then(res => {
        Notification.success("审核成功");
        this.viewDetailDrawer.disabled = false;
        this.viewDetailDrawer.visible = false;
        this.handleQueryModule();
      })
    },
    computeButtonShow(status, type) {
      // if (type === 'pass') {
        if (status === '99') { // 通过
          return false;
        } else if (status === '1') { // 待分公司审批
          if (this.orgAuth) {
            return true;
          } else {
            return false;
          }
        } else if (status === '2') { // 待总部审批
          if (this.headAuth) {
            return true;
          } else {
            return false;
          }
        }
      // } else if (type === 'reject') {
      //   if (status === '3' || status === '1' || status === '4') { // 驳回
      //     if (this.orgAuth || this.headAuth) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   }
      // }
    },

    getFieldConfigJson(field) {
      return field.configJson ?JSON.parse(field.configJson): {};
    },
    getNodeName(action) {
      switch (action) {
        case 'save':
          return '草稿'
        case 'withdraw':
          return '撤回'
        case 'submit':
          return '提交'
        case 'reject_end':
          return '拒绝'
        case 'org_pass':
          return '分公司审核通过'
        case 'org_reject':
          return '分公司审核驳回'
        case 'head_pass':
          return '总部审核通过'
        case 'head_reject':
          return '总部审核驳回'
        case 'reject':
          return '审核驳回'
        case 'pass':
          return '审核通过'
      }
    },
    handleExport() {
      this.viewDrawer.loading = true;
      let query = Object.assign({}, this.viewDrawer.queryForm);
      query.id = query.hxId;
      exportQdHxData(query).then(res => {
        this.viewDrawer.loading = false;
        Notification.success("数据下载中，请稍候！");
        this.downloading = true;

        if (this.timeIntevarl) {
          clearInterval(this.timeIntevarl);
        }
        this.timeIntevarl = setInterval(() => {
          if (!this.downloading) {
            if (this.timeIntevarl) {
              clearInterval(this.timeIntevarl);
            }
          }
          getDownloadRecords({ size: '1', type: 'QD_HX'}).then(res => {
            if (res.data.total > 0) {
              if (res.data.records.filter(e => e.status!='0').length > 0) {
                this.downloading = false;
                Notification.success("数据已生成，请查看导出列表！");
                if (this.timeIntevarl) {
                  clearInterval(this.timeIntevarl);
                }
              }
            }
          })
        }, 5000);
      })
    },
    getConfigOptions(item) {
      if (item && item.configJson) {
        const configJson = JSON.parse(item.configJson);
        if (configJson.columns) {
          return configJson.columns;
        }
        return [];
      }
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleEdit(row) {
      if (row) {
        this.homeLoading = true;
        getQdHxConfigDetail(row.id).then(res => {
          this.configDrawer.form = res.data;
          this.configDrawer.timestamp = new Date().getTime();
          this.configDrawer.visible = true;
          this.homeLoading = false;
        })
      } else {
        this.configDrawer.form = {};
        this.configDrawer.timestamp = new Date().getTime();
        this.configDrawer.visible = true;
      }
    },
    submitForm() {
      this.$nextTick(() => {
        this.$refs.config.$refs.configForm.validate(valid => {
          if (valid) {
            let form = deepClone(this.$refs.config.form);
            form.fields.forEach(e => {
              if (e.configJson) {
                e.configJson = JSON.stringify(e.configJson)
              }
              if (e.fields && e.fields.length > 0) {
                e.fields.forEach(f => {
                  if (f.configJson) {
                    f.configJson = JSON.stringify(f.configJson)
                  }
                })
              }
            })
            saveQdHxConfig(form).then(res => {
              if (res.code == 200) {
                Notification.success("操作成功！");
                this.configDrawer.visible = false;
                this.handleFilter();
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
