<template>
  <div>
    <el-form-item label="大类" size="mini" prop="mainCategoryName">
      <el-select clearable v-model="queryForm.mainCategoryName" @change="handleOptionChange($event, 'main')">
        <el-option v-for="item in options.productMaxCategoryOtions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="中类" size="mini" prop="midCategoryName">
      <el-select clearable v-model="queryForm.midCategoryName" @change="handleOptionChange($event, 'mid')">
        <el-option v-for="item in options.productMidCategoryOtions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="小类" size="mini" prop="subCategoryName">
      <el-select clearable v-model="queryForm.subCategoryName" :multiple="multiple" @change="handleOptionChange($event, 'sub')">
        <el-option v-for="item in options.productSubCategoryOtions"  :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import { dictSelect } from "@/api/stockToShop";

export default {
  name: "CateGorySearch",
  props: {
    main: {
      type: String,
    },
    mid: {
      type: String,
    },
    sub: {
      type: String,
    },
    date: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      queryForm: {
        mainCategoryName: this.main,
        midCategoryName: this.mid,
        subCategoryName: this.sub,
      },
      options: {
        productMaxCategoryOtions: [],
        productMidCategoryOtions: [],
        productSubCategoryOtions: [],
      },
    };
  },
  created() {
    const dateKey = this.getDateKey();
    dictSelect("main", "?dateKey=" + dateKey).then(res => {
      if (res.code === 200) {
        this.options.productMaxCategoryOtions = res.data;
      }
    });
  },
  methods: {
    handleOptionChange(val, type) {
      const dateKey = this.getDateKey();
      const funcObj = {
        main: val => {
          this.queryForm.midCategoryName = undefined;
          this.queryForm.subCategoryName = undefined;
          this.options.productMidCategoryOtions = [];
          this.options.productSubCategoryOtions = [];
          dictSelect("mid", "?query=" + val.query + (val.dateKey ? '&dateKey=' + val.dateKey : '')).then(res => {
            if (res.code === 200) {
              this.options.productMidCategoryOtions = res.data;
            }
          });
          this.$emit("update:main", val.query);
          if (!val.query) {
            this.$emit("update:mid", undefined);
            this.$emit("update:sub", undefined);
          }
        },
        mid: val => {
          this.queryForm.subCategoryName = undefined;
          this.options.productSubCategoryOtions = [];
          dictSelect("sub", "?query=" + val.query + (val.dateKey ? '&dateKey=' + val.dateKey : '')).then(res => {
            if (res.code === 200) {
              this.options.productSubCategoryOtions = res.data;
            }
          });
          this.$emit("update:mid", val.query);
          if (!val.query) {
            this.$emit("update:sub", undefined);
          }
        },
        sub: () => {
          this.$emit("update:sub", val);
        },
      };
      // const data = "?query=" + val + (dateKey ? '&dateKey=' + dateKey : '');
      const data = { query: val, dateKey: dateKey};
      funcObj[type](data);
    },
    getDateKey() {
      let dateKey;
      if (this.date) {
        this.$emit('getDate', (data) => {
          dateKey = data;
        });
      }
      return dateKey;
    },
    clearAll() {
      this.queryForm.mainCategoryName = undefined;
      this.queryForm.midCategoryName = undefined;
      this.queryForm.subCategoryName = undefined;
      this.$emit("update:main", undefined);
      this.$emit("update:mid", undefined);
      this.$emit("update:sub", undefined);
      this.options.productMidCategoryOtions = [];
      this.options.productSubCategoryOtions = [];
    },
  },
};
</script>
