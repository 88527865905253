<template>
  <div class="prodCenter-page">
    <div class="prodCenter-search">
      <v-signboard :title="'起订量调整确认列表'"/>
      <el-form
        class="main-search"
        size="mini"
        label-width="auto"
        ref="minimumOrderAdjust-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleSubmit"
      >
        <el-row>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="产品等级" size="mini">
              <el-select clearable v-model="form.productGrade" multiple>
                <el-option
                  v-for="item in options.productGradeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="大类" size="mini">
              <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
                <el-option
                  v-for="item in options.productMaxCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="中类" size="mini">
              <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                <el-option
                  v-for="item in options.productMidCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="小类" size="mini">
              <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
                <el-option
                  v-for="item in options.productMinCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="生产类型" size="mini">
              <el-select clearable v-model="form.prod_type">
                <el-option label="生产中心" value="生产中心"></el-option>
                <el-option label="OEM中心" value="OEM中心"></el-option>
                <el-option label="办公" value="办公"></el-option>
                <el-option label="生产中心/办公" value="生产中心/办公"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="是否有客订" size="mini">
              <el-select clearable v-model="form.bo_kd_plu_count_type">
                <el-option label="是" :value="1" :key="1"></el-option>
                <el-option label="否" :value="2" :key="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="原始料号" size="mini">
              <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item label="是否需要确认" size="mini">
              <el-select clearable v-model="form.prod_confirm_type">
                <el-option label="是" :value="1" :key="1"></el-option>
                <el-option label="否" :value="2" :key="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-button
            class="form-btn-item"
            size="mini"
            type="primary"
            :loading="btnStatus.isSub"
            @click="handleSubmit"
          >查 询
          </el-button>
          <el-button
            class="form-btn-item"
            size="mini"
            :loading="btnStatus.isReset"
            @click="handleReset"
          >重 置
          </el-button>

          <el-button
            size="mini"
            type="primary"
            :loading="btnStatus.isExportLoading"
            @click="handleTableHeadAction('export')"
          >导 出
          </el-button>
          <el-button
            size="mini"
            type="warning"
            @click="handleTableHeadAction('adjust')">确 认 调 整
          </el-button>

        </el-row>
      </el-form>
      <el-col class="prodCenter-tips" style="margin-top: 10px;">本月度低于起订量的产品如下表所示，请确认调整</el-col>
      <el-table
        class="table minimumOrderAdjust-table"
        stripe
        border
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <el-table-column label="序号" align="center" width="50" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.minimumOrderConfirmHead">
          <el-table-column v-if="item.prop == 'is_prod_confirm'" fixed="right" v-bind="item" :key="index"
                           align="center">
            <template slot-scope="scope">
              <div class="prodConfirm-btn">
                <template v-if="scope.row.need_prod_confirm == 1">
                  <el-button
                    v-for="(item1, confirId) in scope.row.prodConfirmBtn"
                    :key="confirId+'only'"
                    :type="item1.isSelect ? 'primary' : ''"
                    size="mini"
                    @click="handleProdConfirm(scope.$index, confirId, scope.row)"
                  >{{ item1.btnText }}
                  </el-button>
                </template>
                <el-link v-else type="info" disabled style="font-size: 13px;">无需操作</el-link>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else-if="['plan_month', 'sub_category_name', 'material_code', 'trans_material_code'].includes(item.prop)"
                           fixed="left" v-bind="item" show-overflow-tooltip :key="index"
                           align="center"></el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport, keepTwoDecimals, thousandsSeparator} from "@/utils";
import {minimumOrderConfirmHead} from '../config';
import {
  handleMinimumPluProdLists,
  handleProdConfirmMinimumOrderPlu,
  handleProdSaveMinimumOrderPlu
} from '@/api/prodCenter';

export default {
  // 起订量调整
  name: 'prodMinimumOrderAdjust',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        minimumOrderConfirmHead
      },
      selectRows: [],
      urlMonth: ''
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) btnStatus.isAdjust = false;
        if (n.length == 0) btnStatus.isAdjust = true;
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      prod_confirm_type: 1,
      bo_kd_plu_count_type: ''
    };
    this.form = formPrams;
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 获取数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          prod_type: form.prod_type || '',
          is_lower_than_min_order_plu: false,
          bo_kd_plu_count_type: form.bo_kd_plu_count_type,
          material_code: form.material_code,
          prod_confirm_type: form.prod_confirm_type
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleMinimumPluProdLists(params);
        if (data) {
          const {min_order_plu_list, total} = data;
          this.modifyData(min_order_plu_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----生产中心获取起订量列表有误');
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map((item, listIndex) => {
          let newItem = {
            plan_month: item.plan_month || '--',
            prod_type: item.prod_type || '--',
            material_code: item.material_code || '--',
            plu_id: item.plu_id || '--',
            plu_code: item.plu_code || '--',
            plu_name: item.plu_name || '--',
            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            race_name: item.race_name || '--',
            trans_material_code: item.trans_material_code || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            cargono: item.cargono || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',
            update_by: item.update_by || '--',

            grade: item.grade || '--',

            confirm_theory_shipment_count: thousandsSeparator(item.confirm_theory_shipment_count, 0) || 0,
            theory_shipment_count: thousandsSeparator(item.theory_shipment_count, 0) || 0,
            hq_store_count_if_zero_order: thousandsSeparator(item.hq_store_count_box_if_zero_order * item.bottom_spec, 0) || 0,
            hq_store_count_if_min_order: thousandsSeparator(item.hq_store_count_box_if_min_order * item.bottom_spec, 0) || 0,
            hq_store_count_if_custom: thousandsSeparator(item.hq_store_count_box_if_custom * item.bottom_spec, 0) || 0,
            hq_store_ratio_if_custom: keepTwoDecimals(item.hq_store_ratio_if_custom) || 0,
            hq_this_month_ql: thousandsSeparator(item.hq_this_month_ql_box * item.bottom_spec, 0) || 0,
            hq_old_plu_previous_put_count: thousandsSeparator(item.hq_old_plu_previous_put_count_box * item.bottom_spec, 0) || 0,
            bo_kd_plu_count: thousandsSeparator(item.bo_kd_plu_count, 0) || 0,
            hg_last_month_store_count: thousandsSeparator(item.hg_last_month_store_count, 0) || 0,
            month_avg_sale_count: thousandsSeparator(item.month_avg_sale_count, 0) || 0,
            min_order_count: thousandsSeparator(item.min_order_count, 0) || 0,
            hg_store_ratio_if_zero_order: keepTwoDecimals(item.hg_store_ratio_if_zero_order) || 0,
            hg_store_ratio_if_min_order: keepTwoDecimals(item.hg_store_ratio_if_min_order) || 0,

            is_prod_confirm: item.is_prod_confirm || 0,
            need_prod_confirm: item.need_prod_confirm,
            prodConfirmBtn: [
              {btnText: '驳回', isSelect: false, btnStatus: 2},
              {btnText: '确认', isSelect: false, btnStatus: 1}
            ]
          };
          newItems.push(newItem);
        });
        newItems.map((item, index) => {
          item.prodConfirmBtn.map((item1, index1) => {
            if (item.is_prod_confirm === item1.btnStatus) item1.isSelect = true;
            else item1.isSelect = false;
          })
          return item;
        });
        resolve(newItems);
      })
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        bo_kd_plu_count_type: '',
        material_code: '',
        prod_confirm_type: 1,
        prod_type: ''
      };
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'adjust': async () => {                     // 确认调整
          this.$confirm('您需要确认起订量调整么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const params = {
              month: this.urlMonth,
              operator: this.urlParams.operator,
            };
            const {success} = await handleProdConfirmMinimumOrderPlu(params);
            this.$refs['multipleTable'].clearSelection();
            if (success) {
              this.tableStatus.isLoading = true;
              this.getLists();
              return this.$message.success('起订量调整已成功');
            }
          }).catch(() => {
            this.$message('起订量调整未确认');
          });
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      const topHeaders = {}
      for (let i = 0; i < minimumOrderConfirmHead.length; i++) {
        topHeaders[minimumOrderConfirmHead[i].prop] = minimumOrderConfirmHead[i].label
      }

      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          prod_type: form.prod_type || '',
          is_lower_than_min_order_plu: false,
          bo_kd_plu_count_type: form.bo_kd_plu_count_type,
          material_code: form.material_code,
          prod_confirm_type: form.prod_confirm_type
        };

        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleMinimumPluProdLists(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {min_order_plu_list} = data;
          if (min_order_plu_list.length > 0) {
            for(let i = 0; i < min_order_plu_list.length; i++) {
              let item = min_order_plu_list[i];
              item.hq_store_count_if_zero_order = item.hq_store_count_box_if_zero_order * item.bottom_spec || 0;
              item.hq_store_count_if_min_order =  item.hq_store_count_box_if_min_order * item.bottom_spec || 0;
              item.hq_store_count_if_custom =  item.hq_store_count_box_if_custom * item.bottom_spec || 0;
              item.hq_this_month_ql =  item.hq_this_month_ql_box * item.bottom_spec || 0;
              item.hq_old_plu_previous_put_count = item.hq_old_plu_previous_put_count_box * item.bottom_spec|| 0;
            }


            excelExport({
              topHeaders,
              data: min_order_plu_list,
              title: '起订量调整确认列表'
            })
          }
          if (min_order_plu_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------起订量调整确认列表导出接口有误');
      }
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    handleProdConfirm(rowIndex, btnIndex, row) {
      this.lists.map((item, index) => {
        if (index === rowIndex) {
          item.prodConfirmBtn.map((btnItem, btnId) => {
            if (btnIndex === btnId) {
              btnItem.isSelect = true;
              item.is_prod_confirm = btnItem.btnStatus;
              this.handleProdSaveMinPlu(item);
            } else btnItem.isSelect = false;
          })
        }
      });
    },
    async handleProdSaveMinPlu(row) {
      const {is_prod_confirm, plan_month, plu_id} = row;
      const params = {
        operator: this.urlParams.operator,
        min_order_plu_prod_list: [
          {
            is_prod_confirm: is_prod_confirm || '',
            plan_month: plan_month || '',
            plu_id: plu_id || ''
          }
        ]
      };
      const {code, msg} = await handleProdSaveMinimumOrderPlu(params);
      this.$refs['multipleTable'].clearSelection();
      if (code == '0000') {
        this.tableStatus.isLoading = true;
        this.getLists();
        return this.$message.success('修改成功');
      }
      ;
      if (code != '0000') this.getLists();
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/prodCenter/layoutMain.scss'></style>
<style lang="scss" scoped>
  .minimumOrderAdjust-table {
    ::v-deep .el-table__empty-text {
        text-align: left;
      }
  }
</style>
