var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.updateTime ? "" : "time-show",
      staticStyle: {
        float: "right",
        "margin-bottom": "15px",
        "font-size": "15px",
      },
    },
    [_vm._v("更新时间: " + _vm._s(_vm.time))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }