var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("h2", { staticClass: "item horizontalItem", style: _vm.styles }, [
    _c(
      "span",
      {
        staticClass: "badge",
        staticStyle: { top: "4px", position: "relative" },
      },
      [
        _vm._v(
          _vm._s(
            _vm.task.taskRange === "1"
              ? "长期任务"
              : "任务时间" +
                  _vm.parseShowTime(_vm.task?.startDate, "{m}.{d}") +
                  "-" +
                  _vm.parseShowTime(_vm.task?.endDate, "{m}.{d}")
          )
        ),
      ]
    ),
    _c("div", { staticClass: "taskName" }, [
      _c("div", { staticClass: "taskNameText text-1" }, [
        _vm._v(_vm._s(_vm.task.taskName)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "taskData" },
      _vm._l(
        _vm.type === "shop"
          ? _vm.task.configJson.shop
          : _vm.task.configJson.summary,
        function (item, index) {
          return _c("div", { key: index, staticClass: "taskNum" }, [
            _c(
              "p",
              {
                staticClass: "label",
                style: "width: max-content;color:" + item.label.color,
              },
              [_vm._v(_vm._s(item.title) + " ")]
            ),
            _c("p", { staticClass: "value" }, [
              _c(
                "span",
                { style: "font-size: 13; color:" + item.value.color },
                [
                  _c("span", [_vm._v(_vm._s(item.prefix))]),
                  _vm._v(" 0 " + _vm._s(item.unit)),
                ]
              ),
            ]),
          ])
        }
      ),
      0
    ),
    _c("p", { staticClass: "moreData" }, [
      _c(
        "span",
        {
          staticClass: "more",
          style:
            "background-color:" +
            _vm.task.configJson.theme.badge.background.color,
        },
        [_vm._v(" 更多数据 21 ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }