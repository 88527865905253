<style>
.el-dialog__body .el-form-item__label{width:120px !important;}
.el-form-item__content{float: left; margin-left: 20px !important;}
</style>
<template>
  <div class="salesCenter-page">
    <div class="salesCenter-content">
      <v-signboard :title="'人员清单'"/>
      <el-row>
        <el-col class="list-head">
          <el-button v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader') "
            size="mini"
            @click="handleTableHeadAction('add')"
          >新 增
          </el-button>
        </el-col>
      </el-row>
      <el-table
        class="table"
        stripe
        border
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <template v-for="(item, index) in tableObj.userRoleHead">
          <el-table-column v-if="item.prop === '-'" fixed="right" v-bind="item" :key="index" align="center">
            <template slot-scope="scope">
              <div class="prodConfirm-btn">
                <template>
                  <el-button
                    v-for="(item1, confirId) in scope.row.userOpeBtn"
                    :key="confirId+'only'"
                    :type="item1.isSelect ? 'primary' : ''"
                    size="mini"
                    @click="handleUserOpe(scope.$index, confirId, scope.row)"
                  >{{ item1.btnText }}
                  </el-button>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
    <!-- 新增人员 -->
    <el-dialog
      class="add-dialog"
      title="新增人员"
      width="650px"
      :visible.sync="dialogStatus.isAddUser"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="80px"
          size="mini"
          class="dialog-form"
          :model="dialogFormModel"
          :rules="dialogFormModelRules"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item label="员工ID" size="mini" prop="modify_operator">
                <el-input v-model="dialogFormModel.modify_operator" placeholder="员工ID" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="姓名" size="mini" prop="real_name">
                <el-input v-model="dialogFormModel.real_name" placeholder="姓名" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="用户角色" size="mini" prop="role_code_list">
                <el-select v-model="dialogFormModel.role_code_list" multiple placeholder="请选择用户角色" clearable>
                  <el-option
                    v-for="item in options.roleOptions"
                    :key="item.role_code"
                    :label="item.role_name"
                    :value="item.role_code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="用户状态" size="mini" prop="status">
                <el-select v-model="dialogFormModel.status" placeholder="请选择用户状态" clearable>
                  <el-option v-for="(option, index) in statusOptions" :key="index" :value="option.id" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleAddSubmit">保 存</el-button>
        <el-button size="small" @click="handleAddCancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 修改用户 -->
    <el-dialog
      class="add-dialog"
      title="修改人员"
      width="650px"
      :visible.sync="dialogStatus.isUpdateUser"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="80px"
          size="mini"
          class="dialog-form"
          :model="dialogFormModel"
          :rules="dialogFormModelRules"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item label="员工ID" size="mini" prop="modify_operator">
                <el-input v-model="dialogFormModel.modify_operator" disabled placeholder="员工ID" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="姓名" size="mini" prop="real_name">
                <el-input v-model="dialogFormModel.real_name" placeholder="姓名" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="用户角色" size="mini" prop="role_code_list">
                <el-select v-model="dialogFormModel.role_code_list" multiple placeholder="请选择用户角色" clearable>
                  <el-option
                    v-for="item in options.roleOptions"
                    :key="item.role_code"
                    :label="item.role_name"
                    :value="item.role_code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="用户状态" size="mini" prop="status">
                <el-select v-model="dialogFormModel.status" placeholder="请选择用户状态" clearable>
                  <el-option v-for="option in statusOptions" :key="option.id" :value="option.id" :label="option.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleUpdateSubmit">保 存</el-button>
        <el-button size="small" @click="handleAddCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams, optionsObj} from '@/mixins';
import {userRoleHead} from '../config';
import {handleUserLists, handleDeleteUser, handleUpdateUser, handleAddUser } from '@/api/salesCenter';

export default {
  // 权限控制
  name: 'userRole',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      btnStatus: {
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        userRoleHead
      },
      selectRows: [],
      urlMonth: '',
      dialogStatus: {
        isAddUser: false,         // 是否添加用户
        isUpdateUser: false,         // 是否修改用户
      },
      dialogFormModel: {
        status: 1,
        modify_operator: '',
        real_name: '',
        role_code_list : []
      },
      dialogFormModelRules: {
        modify_operator: [{required: true, message: '请填写员工ID', trigger: 'blur'}],
        real_name: [{required: true, message: '请填写真实姓名', trigger: 'blur'}],
        role_code_list: [{required: true, message: '表选择员工角色', trigger: 'blur'}],
        status: [{required: true, message: '请选择员工状态', trigger: 'blur'}]
      },
      showEditBtn : false,
      statusText: {
        0: '删除',
        1: '正常',
        2: '禁用'
      },
      statusOptions: [
        { 'id': 1, 'name': '正常'},
        { 'id': 2, 'name': '禁用'},
      ],
    }
  },
  computed: {},
  watch: {
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isAdjust = false;
        }
        if (n.length === 0) {
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {this.getLists();});
  },
  methods: {
    // 获取数据
    async getLists() {
      try {
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          month: this.urlMonth || '',
          operator: this.urlParams.operator
        };
        const {code, data, msg} = await handleUserLists(params);
        if (data) {
          const {user_list, total} = data;
          this.modifyData(user_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----定位转印产能盘点列表有误');
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        const statusText = this.statusText;
        lists.map(item => {
          let newItem = {
            operator: item.operator || '--',
            real_name: item.real_name || '--',
            role_name_list: item.role_name_list || '--',
            role_code_list: item.role_code_list.split(",") || [],
            status: item.status || '--',
            statusText: statusText[item.status] || '--',
            update_time: item.update_time || '--',
            userOpeBtn: [
              { btnText: '编辑', isSelect: false, btnStatus: 2},
              { btnText: '删除', isSelect: false, btnStatus: 1}
            ]
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    handleUserOpe(rowIndex, btnIndex, row) {
      if(btnIndex === 0) { // 编辑
        this.dialogFormModel = row;
        this.dialogFormModel.role_code_list = row.role_code_list;
        this.dialogFormModel.modify_operator = row.operator;
        this.dialogStatus.isUpdateUser = true;
      } else if(btnIndex === 1) { // 删除
        this.handleDeleteUser(row);
      }
      console.log(btnIndex);
      console.log(rowIndex);
    },
    async handleDeleteUser(row) {
      const { operator } = row;
      const params = {
        operator: this.urlParams.operator,
        delete_operator: operator
      };
      const { code, msg } = await handleDeleteUser(params);
      this.$refs['multipleTable'].clearSelection();
      if ( code == '0000' ) {
        this.tableStatus.isLoading = true;
        this.getLists();
        return this.$message.success('删除人员成功!');
      }
      if ( code != '0000' ) this.getLists();
    },
    // 表格头部操作项
    handleTableHeadAction(type) {
      const funcObj = {
        'add': () => {                  // 保存
          this.dialogFormModel = {
            status: 1,
            modify_operator: '',
            real_name: '',
            role_code_list : ''
          };
          this.dialogStatus.isAddUser = true;
        }
      };
      funcObj[type]();
    },
    // 新增用户
    handleAddSubmit() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.dialogFormModel,
            add_operator : this.dialogFormModel.modify_operator,
            operator: this.urlParams.operator
          };
          console.log(params);
          const {code, success} = await handleAddUser(params);
          if (success) {
            this.dialogStatus.isAddUser = false;
            this.getLists();
            return this.$message.success('新增人员成功');
          }
        } catch (err) {
          this.dialogStatus.isAddUser = false;
          console.log(err, 'err------新增人员有误');
        }
      })
    },
    // 修改用户
    handleUpdateSubmit() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.dialogFormModel,
            update_operator : this.dialogFormModel.modify_operator,
            operator: this.urlParams.operator
          };
          console.log(params);
          const {code, success} = await handleUpdateUser(params);
          if (success) {
            this.dialogStatus.isUpdateUser = false;
            this.getLists();
            return this.$message.success('修改人员成功');
          }
        } catch (err) {
          this.dialogStatus.isUpdateUser = false;
          console.log(err, 'err------修改人员有误');
        }
      })
    },
    // 取消按钮
    handleAddCancel() {
      this.dialogStatus.isAddUser = false;
      this.dialogStatus.isUpdateUser = false;
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/prodCenter/layoutMain.scss'></style>
