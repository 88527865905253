var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.targetLoading,
          expression: "targetLoading",
        },
      ],
      staticStyle: { padding: "10px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "5px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                "label-position": "right",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      init: "",
                      "init-options": _vm.orgOptions,
                      disabled: _vm.orgDictDisabled,
                      version: "v2",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "area-tag,shop-code",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                      version: "v2",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组" } },
                [
                  _c("DictSelect", {
                    ref: "area-tag",
                    attrs: {
                      type: "area-tag",
                      value: _vm.queryForm.areaTag,
                      linkageRef: "shop-code",
                      dictFun: _vm.dictFun,
                      singleProps: "",
                      version: "v2",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaTag", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺编码" } },
                [
                  _c("DictSelect", {
                    ref: "shop-code",
                    attrs: {
                      value: _vm.queryForm.shopCode,
                      version: "v2",
                      remote: "",
                      fullLabel: "",
                      type: "shop-code",
                      parentLinkageRef: "",
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCode", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                    },
                  }),
                ],
                1
              ),
              _vm.queryForm.taskRange === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "年份" } },
                    [
                      _c("DictSelect", {
                        ref: "year",
                        attrs: {
                          value: _vm.queryForm.year,
                          version: "v2",
                          singleProps: "",
                          type: "year",
                          init: "",
                          "init-options": _vm.yearOptions,
                          defaultIndex: 0,
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "year", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm.queryForm.type === "1"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-download",
                            type: "primary",
                          },
                          on: { click: _vm.handleExport },
                        },
                        [_vm._v("导出")]
                      )
                    : _vm._e(),
                  _vm.queryForm.type === "1"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            type: "primary",
                          },
                          on: { click: _vm.handleImport },
                        },
                        [_vm._v("导入")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.queryForm.taskRange === "1"
        ? _c(
            "div",
            { staticClass: "div-class" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleFilter },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.dateTabs, function (item) {
                  return _c("el-tab-pane", {
                    key: item.value,
                    attrs: { label: item.label, name: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-right": "12px",
                        "font-weight": "700",
                      },
                    },
                    [_vm._v("设置方式")]
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.settingDisable,
                        size: "mini",
                        placeholder: "请选择设置方式",
                      },
                      on: { change: _vm.handleSettingChange },
                      model: {
                        value: _vm.queryForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "type", $$v)
                        },
                        expression: "queryForm.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "1", label: "单店设置目标" },
                      }),
                      _c("el-option", {
                        attrs: { value: "0", label: "统一设置目标" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.taskInfo.taskRange === "0" && _vm.taskInfo.startDate
                ? _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticStyle: { transform: "translate(-0%,30%)" } },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-right": "12px",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("任务日期：")]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.transTaskDate()))]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.queryForm.type === "1"
            ? _c(
                "el-row",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "background-color": "#e1e6e8",
                  },
                },
                [
                  _c(
                    "el-col",
                    { staticStyle: { margin: "3px" }, attrs: { span: 20 } },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "dodgerblue" } },
                        [
                          _c(
                            "strong",
                            { staticStyle: { "font-size": "20px" } },
                            [_vm._v("快捷填写")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "600",
                                title: "快捷填写说明",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-size": "12px" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("h3", [_vm._v("总目标:")]),
                                          _vm._v(" 填写后，"),
                                          _c(
                                            "font",
                                            { attrs: { color: "red" } },
                                            [
                                              _vm._v(
                                                "会用[总目标金额]减掉[己设目标的店铺]后再平分到[未设目标的店铺]"
                                              ),
                                            ]
                                          ),
                                          _vm._v("（保留两位小数）"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("h3", [
                                            _vm._v("店均上柜款数目标："),
                                          ]),
                                          _vm._v("填写后，会自动填充到每一家"),
                                          _c(
                                            "font",
                                            { attrs: { color: "red" } },
                                            [_vm._v("未设款数目标的店铺）")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.queryForm.taskRange === "0"
                                    ? _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c("h3", [
                                                _vm._v(
                                                  "店均目标新增上柜款数："
                                                ),
                                              ]),
                                              _vm._v(
                                                "填写后，会自动填充到每一家"
                                              ),
                                              _c(
                                                "font",
                                                { attrs: { color: "red" } },
                                                [
                                                  _vm._v(
                                                    "未设新增款数目标的店铺）"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("el-link", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  "margin-bottom": "10px",
                                },
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-question",
                                  type: "info",
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "12px",
                          "font-weight": "700",
                        },
                      },
                      [_vm._v("总目标金额")]
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "inline-block" } },
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            precision: 2,
                            placeholder: _vm.total.amountRefer,
                            min: 0,
                            controls: false,
                          },
                          model: {
                            value: _vm.total.totalAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.total, "totalAmount", $$v)
                            },
                            expression: "total.totalAmount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.queryForm.type === "1"
                    ? _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-right": "12px",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("店均目标上柜款式数")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                precision: 2,
                                min: 0,
                                placeholder: _vm.total.modelRefer,
                                controls: false,
                              },
                              model: {
                                value: _vm.total.avgModel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.total, "avgModel", $$v)
                                },
                                expression: "total.avgModel",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.queryForm.type === "1"
                    ? _c("el-col", { attrs: { span: 8 } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-right": "12px",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("店均目标新增上柜款式数")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                precision: 2,
                                min: 0,
                                placeholder: _vm.total.increaseRefer,
                                controls: false,
                              },
                              model: {
                                value: _vm.total.avgIncreaseModel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.total, "avgIncreaseModel", $$v)
                                },
                                expression: "total.avgIncreaseModel",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          staticStyle: {
                            "margin-left": "10px",
                            transform: "translate(-0%,10%)",
                          },
                          attrs: {
                            size: "mini",
                            icon: "el-icon-refresh",
                            type: "primary",
                          },
                          on: { click: _vm.handleCompute },
                        },
                        [_vm._v("自动计算未填写店铺")]
                      ),
                      _c("div", { staticStyle: { float: "right" } }, [
                        _c("div", { staticStyle: { display: "block" } }, [
                          _vm._v("已填写/未填写"),
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.total.handTotal) +
                              "/" +
                              _vm._s(_vm.total.autoTotal)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.queryForm.type === "1"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "dodgerblue" } },
                        [
                          _c(
                            "strong",
                            { staticStyle: { "font-size": "20px" } },
                            [_vm._v("单个店铺目标")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "600",
                                title: "单个店铺填写说明",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-size": "12px" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c("h3", [
                                            _vm._v("人工输入的店铺：:"),
                                          ]),
                                          _vm._v(
                                            " 指在本页面人工输入或表格导入，"
                                          ),
                                          _c(
                                            "font",
                                            { attrs: { color: "red" } },
                                            [
                                              _vm._v(
                                                "人工填写的目标不会受快捷填写的影响"
                                              ),
                                            ]
                                          ),
                                          _vm._v("，只能手动更改。"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c("h3", [_vm._v("快捷填写的店铺：")]),
                                        _vm._v(
                                          "未填写过的和快捷填写的店铺可随时使用上方的快捷填写来填充覆盖，此目标不会展示在App的店铺详情页面中，仅用于任务的汇总目标计算。"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("el-link", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  "margin-bottom": "10px",
                                },
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-question",
                                  type: "info",
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.queryForm.type === "1"
        ? _c(
            "div",
            { staticClass: "div-class" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.pageData.rows,
                    height: "calc(100vh - 350px)",
                    size: "mini",
                    border: "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "店铺编码/名称",
                      prop: "shop",
                      width: "250",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.shopCode === "ALL"
                                        ? "全部"
                                        : row.shopCode + "-" + row.shopName
                                    )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2681006072
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "所属配送中心", prop: "dcName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.dcCode === "ALL"
                                      ? "全部"
                                      : row.dcCode + "-" + row.dcName
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1343371504
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "目标进货金额",
                      prop: "amountTarget",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("el-input-number", {
                                class:
                                  row.amountType !== "1"
                                    ? "table-num-class"
                                    : "",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入",
                                  size: "mini",
                                  precision: 2,
                                  min: 0.01,
                                  controls: false,
                                },
                                on: {
                                  change: (cv, ov) => {
                                    if (cv !== undefined) {
                                      row.amountType = "1"
                                      if (_vm.queryForm.type === "0") {
                                        row.amountType = "2"
                                      }
                                    } else {
                                      row.amountType = undefined
                                    }
                                    _vm.handleCommit(row)
                                  },
                                },
                                model: {
                                  value: row.amountTarget,
                                  callback: function ($$v) {
                                    _vm.$set(row, "amountTarget", $$v)
                                  },
                                  expression: "row.amountTarget",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      925939295
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "目标上柜款式数",
                      prop: "modelTarget",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("el-input-number", {
                                class:
                                  row.modelType !== "1"
                                    ? "table-num-class"
                                    : "",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入",
                                  size: "mini",
                                  controls: false,
                                  precision: 2,
                                  min: 0.01,
                                },
                                on: {
                                  change: (cv, ov) => {
                                    if (cv !== undefined) {
                                      row.modelType = "1"
                                      if (_vm.queryForm.type === "0") {
                                        row.modelType = "2"
                                      }
                                    } else {
                                      row.modelType = undefined
                                    }
                                    _vm.handleCommit(row)
                                  },
                                },
                                model: {
                                  value: row.modelTarget,
                                  callback: function ($$v) {
                                    _vm.$set(row, "modelTarget", $$v)
                                  },
                                  expression: "row.modelTarget",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      260086940
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "目标新增上柜款式数",
                      prop: "increaseModelTarget",
                      width: "140",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("el-input-number", {
                                class:
                                  row.increaseType !== "1"
                                    ? "table-num-class"
                                    : "",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入",
                                  size: "mini",
                                  precision: 2,
                                  controls: false,
                                  min: 0.01,
                                },
                                on: {
                                  change: (cv, ov) => {
                                    if (cv !== undefined) {
                                      row.increaseType = "1"
                                      if (_vm.queryForm.type === "0") {
                                        row.increaseType = "2"
                                      }
                                    } else {
                                      row.increaseType = undefined
                                    }
                                    _vm.handleCommit(row)
                                  },
                                },
                                model: {
                                  value: row.increaseModelTarget,
                                  callback: function ($$v) {
                                    _vm.$set(row, "increaseModelTarget", $$v)
                                  },
                                  expression: "row.increaseModelTarget",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2186723960
                    ),
                  }),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.pageData.total,
                  page: _vm.queryForm.current,
                  limit: _vm.queryForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryForm, "size", $event)
                  },
                  pagination: _vm.handleQuery,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.queryForm.type === "0"
        ? _c(
            "div",
            { staticStyle: { margin: "20px", width: "40%" } },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "form",
                  staticClass: "common-form",
                  attrs: {
                    size: "mini",
                    "label-width": "170px",
                    inline: true,
                    model: _vm.allTargetForm,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单店目标进货额" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入整数数字，非必填",
                                  size: "mini",
                                  precision: 0,
                                  min: 0,
                                  controls: false,
                                },
                                on: {
                                  change: (cv, ov) => {
                                    if (cv !== undefined) {
                                      _vm.allTargetForm.amountType = "1"
                                      if (_vm.queryForm.type === "0") {
                                        _vm.allTargetForm.amountType = "2"
                                      }
                                    } else {
                                      _vm.allTargetForm.amountType = undefined
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.allTargetForm.amountTarget,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.allTargetForm,
                                      "amountTarget",
                                      $$v
                                    )
                                  },
                                  expression: "allTargetForm.amountTarget",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "总店铺数量" } },
                            [_c("span", [_vm._v(_vm._s(_vm.totalShops))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单店目标上柜款式数" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入整数数字，非必填",
                                  size: "mini",
                                  controls: false,
                                  precision: 0,
                                  min: 0,
                                },
                                on: {
                                  change: (cv, ov) => {
                                    if (cv !== undefined) {
                                      _vm.allTargetForm.modelType = "1"
                                      if (_vm.queryForm.type === "0") {
                                        _vm.allTargetForm.modelType = "2"
                                      }
                                    } else {
                                      _vm.allTargetForm.modelType = undefined
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.allTargetForm.modelTarget,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.allTargetForm,
                                      "modelTarget",
                                      $$v
                                    )
                                  },
                                  expression: "allTargetForm.modelTarget",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "总金额" } }, [
                            _c("span", [_vm._v(_vm._s(_vm.totalAmount))]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单店目标新增上柜款式数" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入整数数字，非必填",
                                  size: "mini",
                                  precision: 0,
                                  controls: false,
                                  min: 0,
                                },
                                on: {
                                  change: (cv, ov) => {
                                    if (cv !== undefined) {
                                      _vm.allTargetForm.increaseType = "1"
                                      if (_vm.queryForm.type === "0") {
                                        _vm.allTargetForm.increaseType = "2"
                                      }
                                    } else {
                                      _vm.allTargetForm.increaseType = undefined
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.allTargetForm.increaseModelTarget,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.allTargetForm,
                                      "increaseModelTarget",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "allTargetForm.increaseModelTarget",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "目标导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "targetUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: {
              "font-size": "16px",
              "overflow-y": "scroll",
              height: "400px",
            },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }