var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      !_vm.component
        ? _c(
            "div",
            { staticClass: "mg-search" },
            [
              _c(
                "el-card",
                { staticStyle: { margin: "10px" } },
                [
                  _c(
                    "SearchFilter",
                    {
                      on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.orgOptions.length > 1,
                              expression: "orgOptions.length > 1",
                            },
                          ],
                          attrs: { label: "分公司" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.orgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgCode", $$v)
                                },
                                expression: "queryForm.orgCode",
                              },
                            },
                            _vm._l(_vm.orgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  filterable: "",
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入商品组名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.groupName,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "groupName", $$v)
                              },
                              expression: "queryForm.groupName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            on: { change: _vm.handleDateChange },
                            model: {
                              value: _vm.createDate,
                              callback: function ($$v) {
                                _vm.createDate = $$v
                              },
                              expression: "createDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "status", $$v)
                                },
                                expression: "queryForm.status",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                { attrs: { value: 1, label: "启用" } },
                                [_vm._v("启用")]
                              ),
                              _c(
                                "el-option",
                                { attrs: { value: 0, label: "禁用" } },
                                [_vm._v("禁用")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.orgAuthAll
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "创建人" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.queryForm.createUser,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "createUser", $$v)
                                    },
                                    expression: "queryForm.createUser",
                                  },
                                },
                                _vm._l(_vm.userOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              staticStyle: { margin: "0 0 10px 0" },
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd("add")
                },
              },
            },
            [_vm._v("商品组")]
          ),
          !_vm.component
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "customer-table",
                  attrs: {
                    data: _vm.pageData.rows,
                    border: "",
                    size: "small",
                    "highlight-current-row": "",
                  },
                  on: { "sort-change": _vm.handleSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "60", label: "序号" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品组名称", prop: "groupName" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "分公司名称", prop: "orgName" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", prop: "status" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  style: row.status === 1 ? "color: blue" : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(row.status === 1 ? "启用" : "禁用")
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      743920529
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "款式总数", prop: "itemCount" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "描述", prop: "description" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创建人", prop: "createUser" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建日期",
                      prop: "createTime",
                      width: "200px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "200",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { border: "0px", width: "20%" },
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(row, "view")
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { border: "0px", width: "20%" },
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { border: "0px", width: "20%" },
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleExport(row)
                                    },
                                  },
                                },
                                [_vm._v("导出")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { border: "0px", width: "20%" },
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditStatus(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.status === 1 ? "禁用" : "启用"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    title:
                                      "请确认是否要删除商品组(" +
                                      row.groupName +
                                      ")?",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.handleDelete(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: row.status === 0,
                                          expression: "row.status === 0",
                                        },
                                      ],
                                      staticStyle: {
                                        border: "0px",
                                        width: "20%",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        size: "mini",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3270795152
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.component
            ? _c("Pagination", {
                attrs: {
                  total: _vm.pageData.total,
                  page: _vm.queryForm.current,
                  limit: _vm.queryForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryForm, "size", $event)
                  },
                  pagination: _vm.handleQuery,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle[_vm.saveTitle],
            visible: _vm.saveDialog.dialogVisible,
            width: "800px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "dialogVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.saveDialog.form,
                inline: true,
                rules: _vm.saveDialog.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "half-width",
                  attrs: { label: "分公司", size: "mini" },
                },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      disabled: _vm.saveTitle !== "add",
                      value: _vm.saveDialog.form.orgCode,
                      fullLabel: "",
                      type: "ht-org-all",
                      clearable: false,
                      dictFun: _vm.dictFun,
                      "default-index": 0,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.saveDialog.form, "orgCode", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "half-width",
                  attrs: {
                    label: "商品组名称",
                    prop: "groupName",
                    size: "mini",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.saveTitle === "view",
                      clearable: "",
                      placeholder: "请输入商品组名称",
                    },
                    model: {
                      value: _vm.saveDialog.form.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "groupName", $$v)
                      },
                      expression: "saveDialog.form.groupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "full-width",
                  attrs: { label: "描述", prop: "description", size: "mini" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.saveTitle === "view",
                      clearable: "",
                      type: "textarea",
                      placeholder: "请输入描述",
                    },
                    model: {
                      value: _vm.saveDialog.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "description", $$v)
                      },
                      expression: "saveDialog.form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "checkNumber" }, [
            _vm._v(
              "已选商品(" + _vm._s(_vm.saveDialog.form.items.length) + ")"
            ),
          ]),
          _c(
            "div",
            [
              _vm.saveTitle !== "view"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "left" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleRemoveItems },
                    },
                    [_vm._v(" 批量删除 ")]
                  )
                : _vm._e(),
              _vm.saveTitle !== "view"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.drawer.visible = true
                        },
                      },
                    },
                    [_vm._v(" 添加商品 ")]
                  )
                : _vm._e(),
              _vm.saveTitle !== "view"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.importDialog.visible = true
                        },
                      },
                    },
                    [_vm._v(" 导入 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { "margin-top": "5px" },
              attrs: {
                data: _vm.itemsPage.rows,
                height: "calc(100vh - 350px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _vm.saveTitle !== "view"
                ? _c("el-table-column", {
                    attrs: { type: "selection", width: "90" },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "商品型号", prop: "skuModel", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "款式名称", prop: "skuName" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "货号",
                  prop: "cargoNo",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "大类",
                  prop: "mainCategoryName",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "中类", prop: "midCategoryName", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "小类", prop: "subCategoryName", width: "100" },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.itemsPage.total,
              page: _vm.itemsPage.current,
              limit: _vm.itemsPage.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.itemsPage, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.itemsPage, "size", $event)
              },
              pagination: function ($event) {
                return _vm.handleQueryChecked("itemsPage")
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.saveDialog.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: { importBaseParams: _vm.importDialog },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.drawer.visible,
            size: "60%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "visible", $event)
            },
          },
        },
        [
          _c("SkuItem", {
            ref: "itemRef",
            attrs: { refresh: _vm.drawer.refresh },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawer.visible = false
              },
              getExceptModels: (callback) =>
                callback(_vm.saveDialog.form.exceptModels),
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.downloadImportResult },
                },
                [_vm._v("下载导入结果")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }