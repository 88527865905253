var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mg-search" }, [
      _c(
        "div",
        { staticClass: "head-signboard-box" },
        [
          _c("mg-signboard", { attrs: { title: "新品上市跟进明细" } }),
          _c(
            "div",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "90px", "padding-right": "10px" },
                  attrs: { size: "small", filterable: "" },
                  on: { change: _vm.handleChangeSelect },
                  model: {
                    value: _vm.queryForm.companyCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryForm, "companyCode", $$v)
                    },
                    expression: "queryForm.companyCode",
                  },
                },
                _vm._l(_vm.companies, function (item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.btnStatus.isExport,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleTableHeadAction("export")
                    },
                  },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "mg-list-box" },
      [
        _c("mg-table", {
          ref: "mgTable",
          attrs: {
            autoLoad: false,
            columns: _vm.newPublishDetailColumns,
            api: _vm.handleNewPublishListsByBranch,
            apiParams: _vm.queryForm,
            extraParams: { isEdit: false },
            height: "calc(100vh - 300px)",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }