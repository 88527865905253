<template>
  <div>
    <el-form>
      <el-form-item label="选择参考指标" class="full-width">
        <el-select v-model="referenceQuota" filterable multiple size="mini">
          <el-option v-for="e in options.tags" :key="e.tagId" :value="e.id" :label="e.tagName" :disabled="e.disabled" />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getTagList } from "@/api/tagPlatform";

export default {
  name: "StepThree",
  props: {
    formThree: {
      type: Object,
      default() {
        return {
          referenceQuota: [],
        }
      }
    },
    actionType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      referenceQuota: this.formThree.referenceQuota,
      options: {
        tags: []
      }
    }
  },
  created() {
    getTagList({ status: 1, limit: 1000 }).then(res => {
      if (res.data && res.code===200) {
        res.data.datas.forEach(e => {
          e.disabled = e.tagStatus!=='启用'? true: false;
          return e;
        })
        this.options.tags = res.data.datas;
      }
    })
  }
}
</script>

<style scoped>

</style>
