<template>
  <div class="salesCenter-page">
    <div class="salesCenter-search">
      <v-signboard :title="'起订量调整'"/>
      <el-form
        class="main-search"
        size="mini"
        label-width="auto"
        ref="minimumOrderAdjust-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleSubmit"
      >
        <el-form-item label="产品等级" size="mini">
          <el-select clearable v-model="form.productGrade" multiple>
            <el-option
              v-for="item in options.productGradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="大类" size="mini">
          <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
            <el-option
              v-for="item in options.productMaxCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中类" size="mini">
          <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
            <el-option
              v-for="item in options.productMidCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小类" size="mini">
          <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
            <el-option
              v-for="item in options.productMinCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生产类型" size="mini">
          <el-select clearable v-model="form.prod_type">
            <el-option label="生产中心" value="生产中心"></el-option>
            <el-option label="OEM中心" value="OEM中心"></el-option>
            <el-option label="办公" value="办公"></el-option>
            <el-option label="生产中心/办公" value="生产中心/办公"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品赛道" size="mini">
          <el-select clearable v-model="form.race_name" style="width:90px">
            <el-option
              v-for="item in options.raceNameLists"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原始料号" size="mini">
          <el-input v-model="form.material_code" clearable placeholder="原始料号"/>
        </el-form-item>
        <el-form-item label="是否有客订" size="mini">
          <el-select clearable v-model="form.bo_kd_plu_count_type">
            <el-option label="是" :key="1" :value="1"></el-option>
            <el-option label="否" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" size="mini">
          <el-select clearable v-model="form.data_type">
            <el-option label="全部" :key="0" :value="0"></el-option>
            <el-option label="理论出货低于起订" :key="1" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="置起订量类型" size="mini">
          <el-select clearable v-model="form.min_order_type">
            <el-option label="全部" :key="0" :value="0"></el-option>
            <el-option label="预计下月底库存系数>3" :key="1" :value="1"></el-option>
            <el-option label="预计下月底库存系数<=3" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="置零类型" size="mini">
          <el-select clearable v-model="form.zero_order_type">
            <el-option label="全部" :key="0" :value="0"></el-option>
            <el-option label="预计下月底库存系数<0" :key="1" :value="1"></el-option>
            <el-option label="预计下月底库存系数>=0" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="品名" size="mini">
          <el-input v-model="form.plu_name" clearable placeholder="请输入品名"/>
        </el-form-item>
        <el-form-item label="统计品类" size="mini">
          <el-input v-model="form.stats_category_name" clearable placeholder="请输入统计品类"/>
        </el-form-item>

        <el-form-item label="预淘汰品" size="mini">
          <el-select clearable v-model="form.dead_sku_type">
            <el-option label="全部" :key="0" :value="0"></el-option>
            <el-option label="是" :key="1" :value="1"></el-option>
            <el-option label="否" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          class="form-btn-item"
          size="mini"
          type="primary"
          :loading="btnStatus.isSub"
          @click="handleSubmit"
        >查 询
        </el-button>
        <el-button
          class="form-btn-item"
          size="mini"
          :loading="btnStatus.isReset"
          @click="handleReset"
        >重 置
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="btnStatus.isSave"
          @click="handleTableHeadAction('save')">保 存
        </el-button>

        <el-button
          size="mini"
          type="primary"
          :loading="btnStatus.isExportLoading"
          @click="handleTableHeadAction('export')"
        >导 出
        </el-button>

        <el-button
          v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader') "
          size="mini"
          type="warning"
          @click="handleTableHeadAction('adjust')">确 认 调 整
        </el-button>
      </el-form>
    </div>
    <div class="salesCenter-content">
      <el-row>
        <el-col class="salesCenter-tips">本月度低于起订量的产品如下表所示，请确认调整</el-col>
      </el-row>
      <el-table
        class="table"
        stripe
        border
        size="mini"
        height="500"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <el-table-column label="序号" align="center" width="50" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.minimumOrderAdjustHead">
          <el-table-column v-if="item.prop == 'adjust_type'" fixed="right" v-bind="item" :key="index" align="center">
            <template slot-scope="scope">
              <div class="orderAdjust-btn">
                <el-button
                  v-for="(item1, ordId) in scope.row.orderAdjustBtn"
                  :key="ordId+'only'"
                  :type="item1.isSelect ? 'primary' : ''"
                  size="mini"
                  @click="handleOrdAdj(scope.$index, ordId, item1.btnStatus, scope.row)">{{ item1.btnText }}
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.prop == 'confirm_theory_shipment_count_box'" v-bind="item" :key="index"
                           align="center">
            <template slot-scope="scope">
              <el-input class="tgqrcount-cell-ipt" type="number" :disabled="scope.row.is_edit"
                        v-model="scope.row.confirm_theory_shipment_count_box" size="mini" clearable/>
            </template>
          </el-table-column>
          <el-table-column v-else-if="['cargono','plu_name', 'price', 'min_order_count_box',
           'grade', 'hq_pre_last_six_month_avg_sale_count_box'].includes(item.prop)"
                           fixed="left" v-bind="item" show-overflow-tooltip :key="index"
                           align="center"></el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport, keepTwoDecimals, thousandsSeparator} from "@/utils";
import {minimumOrderAdjustHead} from '../config';
import {
  handleMinimumPluSaleLists,
  handleSaleConfirmMinimumOrderPlu,
  handleSaveMinimumOrderPlu
} from '@/api/salesCenter';

export default {
  // 起订量调整
  name: 'minimumOrderAdjust',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      originalForm: {},                       // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                       // 是否查询
        isDisable: true,                    // 控制查询disabled
        isReset: false,                     // 重置
        isSave: true,                       // 是否保存
        isAdjust: true                      // 是否调整
      },
      lists: [],
      tableObj: {
        minimumOrderAdjustHead
      },
      flowStatusText: {
        1: '原始数据',
        2: '被修改过',
        3: '审核过'
      },
      selectRows: [],
      urlMonth: ''
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isAdjust = false;
        }
        ;
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: '',
      data_type: 1,
      bo_kd_plu_count_type: '',
      zero_order_type : 0,
      min_order_type : 0,
    };
    this.form = formPrams;
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 获取数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          zero_order_type : form.zero_order_type,
          min_order_type : form.min_order_type,
          grade: form.productGrade || '',
          race_name: form.race_name || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          prod_type: form.prod_type || '',
          plu_code: form.plu_code || '',
          material_code: form.material_code || '',
          data_type: form.data_type,
          bo_kd_plu_count_type: form.bo_kd_plu_count_type,
          dead_sku_type : form.dead_sku_type,
          plu_name: form.plu_name,
          stats_category_name: form.stats_category_name
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleMinimumPluSaleLists(params);
        if (data) {
          const {min_order_plu_list, total} = data;
          this.modifyData(min_order_plu_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----销售中心获取起订量列表有误');
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map((item, listIndex) => {
          let orderAdjustBtn = [
            {btnText: '置零', isSelect: false, btnStatus: 2},
            {btnText: '到起订量', isSelect: false, btnStatus: 1},
            {btnText: '初始请购', isSelect: false, btnStatus: 4},
            {btnText: '自定义', isSelect: false, btnStatus: 3}
          ];
          let is_edit = item.adjust_type == 3 ? false : true;

          if(item.tsc_less_than_moc !== 1) {
            orderAdjustBtn = [];
            is_edit = true;
          }

          let newItem = {
            plan_month: item.plan_month || '--',
            prod_type: item.prod_type || '--',
            material_code: item.material_code || '--',
            plu_id: item.plu_id || '--',
            plu_code: item.plu_code || '--',
            plu_name: item.plu_name || '--',
            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            cargono: item.cargono || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',
            flow_status: this.flowStatusText[item.flow_status] || '--',
            update_by: item.update_by || '--',

            grade: item.grade || '--',
            price: item.price || '--',

            confirm_theory_shipment_count_box: item.confirm_theory_shipment_count_box || 0,
            bo_last_month_order_count: item.bo_last_month_order_count || 0,
            bo_last_month_stock_count_box: item.bo_last_month_stock_count_box || 0,
            hq_this_month_total_count_box: item.hq_this_month_total_count_box || 0,
            hq_pre_last_six_month_avg_sale_count_box: item.hq_pre_last_six_month_avg_sale_count_box || 0,
            theory_shipment_count_box: item.theory_shipment_count_box || 0,
            hq_store_count_box_if_zero_order: item.hq_store_count_box_if_zero_order || 0,
            hq_store_count_box_if_min_order: item.hq_store_count_box_if_min_order || 0,
            hq_store_count_box_if_custom: item.hq_store_count_box_if_custom || 0,
            hq_store_ratio_if_custom: item.hq_store_ratio_if_custom || 0,
            hq_this_month_ql_box: thousandsSeparator(item.hq_this_month_ql_box, 0) || 0,
            hq_old_plu_previous_put_count_box: thousandsSeparator(item.hq_old_plu_previous_put_count_box, 0) || 0,
            bo_kd_plu_count_box: thousandsSeparator(item.bo_kd_plu_count_box, 0) || 0,
            hg_last_month_store_count_box: thousandsSeparator(item.hg_last_month_store_count_box, 0) || 0,
            month_avg_sale_count_box: thousandsSeparator(item.month_avg_sale_count_box, 0) || 0,
            min_order_count_box: thousandsSeparator(item.min_order_count_box, 0) || 0,
            hg_store_ratio_if_zero_order: keepTwoDecimals(item.hg_store_ratio_if_zero_order) || 0,
            hg_store_ratio_if_min_order: keepTwoDecimals(item.hg_store_ratio_if_min_order) || 0,
            hq_store_ratio_if_custom_six_month: keepTwoDecimals(item.hq_store_ratio_if_custom_six_month) || 0,
            hq_store_ratio_if_custom_three_month: keepTwoDecimals(item.hq_store_ratio_if_custom_three_month) || 0,
            hq_last_month_store_ratio: keepTwoDecimals(item.hq_last_month_store_ratio) || 0,
            bo_last_month_stock_ratio: keepTwoDecimals(item.bo_last_month_stock_ratio) || 0,
            order_ratio: keepTwoDecimals(item.order_ratio) || 0,
            adjust_type: item.adjust_type || 2,
            is_edit: is_edit,
            orderAdjustBtn: orderAdjustBtn
          };
          newItems.push(newItem);
        });
        newItems.map((item, index) => {
          item.orderAdjustBtn.map((item1, index1) => {
            if (item.adjust_type === item1.btnStatus) item1.isSelect = true;
            else item1.isSelect = false;
          })
          return item;
        });
        resolve(newItems);
      })
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        prod_type: '',
        data_type: 1,
        bo_kd_plu_count_type: '',
        plu_name: '',
        stats_category_name: '',
        dead_sku_type: ''
      };
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {                       // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              adjust_type: item.adjust_type || 2,
              confirm_theory_shipment_count_box: item.confirm_theory_shipment_count_box || '',
              plan_month: item.plan_month || '',
              plu_id: item.plu_id || ''
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            order_month: this.urlMonth,
            min_order_plu_list: newItems
          };
          const {code, msg} = await handleSaveMinimumOrderPlu(params);
          this.$refs['multipleTable'].clearSelection();
          if (code === '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code !== '0000') this.getLists();
        },
        'adjust': async () => {                     // 确认调整
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator
          };
          const {success} = await handleSaleConfirmMinimumOrderPlu(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('起订量调整已成功');
          }
          if (!success) this.getLists();
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {

      let topHeaders = {}
      for(let i = 0; i < minimumOrderAdjustHead.length; i++) {
        let row = minimumOrderAdjustHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          race_name: form.race_name || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          prod_type: form.prod_type || '',
          plu_code: form.plu_code || '',
          material_code: form.material_code || '',
          data_type: form.data_type,
          bo_kd_plu_count_type: form.bo_kd_plu_count_type
        };

        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleMinimumPluSaleLists(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {min_order_plu_list} = data;
          if (min_order_plu_list.length > 0) {
            for (let i = 0; i < min_order_plu_list.length; i++) {
              let flow_status = min_order_plu_list[i]['flow_status'];
              min_order_plu_list[i]['flow_status'] = this.flowStatusText[flow_status];
            }
            excelExport({
              topHeaders,
              data: min_order_plu_list,
              title: '起订量调整列表'
            })
          }
          if (min_order_plu_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------起订量调整导出接口有误');
      }
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    handleOrdAdj(rowIndex, btnIndex, row) {
      this.lists.map((item, index) => {
        if (index === rowIndex) {
          item.orderAdjustBtn.map((btnItem, btnId) => {
            if (btnIndex === btnId) {
              btnItem.isSelect = true;
              item.adjust_type = btnItem.btnStatus;

              if (btnItem.btnStatus === 2) {
                item.confirm_theory_shipment_count = 0;
                item.confirm_theory_shipment_count_box = 0;
              }

              if (btnItem.btnStatus === 1) {
                item.confirm_theory_shipment_count = item.min_order_count;
                item.confirm_theory_shipment_count_box = item.min_order_count_box;
              }

              if (btnItem.btnStatus === 4) {
                item.confirm_theory_shipment_count = item.theory_shipment_count;
                item.confirm_theory_shipment_count_box = item.theory_shipment_count_box;
              }

              if (btnItem.btnStatus !== 3) item.is_edit = true;
              if (btnItem.btnStatus === 3) item.is_edit = false;
            } else btnItem.isSelect = false;
          })
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
