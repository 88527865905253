<style>
.el-input__inner {
  padding-left: 5px !important;
  max-width: 350px;
}
</style>
<template>
  <div class="app-container raceActivity-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="110px"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item label="赛道">
        <el-select v-model="queryParams.brand" placeholder="请选择" clearable>
          <el-option
            v-for="item in options.brand"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动名称">
        <el-select v-model="queryParams.activityName" placeholder="请选择" clearable filterable>
          <el-option
            v-for="item in options.activityName"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动状态">
        <el-select v-model="queryParams.activityStatus" placeholder="请选择" clearable>
          <el-option
            v-for="item in options.activityStatus"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="Search" size="mini" @click="handleQuery">查询</el-button>
        <el-button size="mini" @click="download" :loading="downloadLoading">下载</el-button>
        <el-button size="mini" @click="addActivity">添加活动</el-button>
        <el-button size="mini" @click="deleteActivity">删除活动</el-button>
      </el-form-item>
    </el-form>

    <el-table border size="mini" ref="activityTable" v-loading="loading" :data="activityList">
      <el-table-column label="id" align="center" type="index" width="80">
        <template slot-scope="scope">
          {{(queryParams.page - 1) * queryParams.limit + scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column label="活动名称" align="center" prop="activityName" />
      <el-table-column label="所属赛道" align="center" prop="brand" />
      <el-table-column label="财务核算口径" align="center" prop="financeAccountOrigin" />
      <el-table-column label="活动开始日期" align="center" prop="startTime" />
      <el-table-column label="活动结束日期" align="center" prop="endTime" />
      <el-table-column label="分公司编码" align="center" prop="orgCode" />
      <el-table-column label="分公司名称" align="center" prop="orgName" />
      <el-table-column label="产品编码" align="center" prop="skuCode" />
      <el-table-column label="产品名称" align="center" prop="skuName" />
      <el-table-column label="活动状态" align="center" prop="activityStatus" />
      <el-table-column label="更新时间" align="center" prop="createTime" />
      <el-table-column label="创建人id" align="center" prop="createBy" />
    </el-table>

    <!-- 分页器 -->
    <mg-pagination
      :total="total"
      :pageSize="queryParams.limit"
      :currentPage="queryParams.page"
      @pageChange="pagingChange"
    />

    <!-- 新增活动弹窗 -->
    <el-dialog
      class="add-dialog"
      title="新增活动"
      width="650px"
      :visible.sync="dialogObj.isAdd"
      :destroy-on-close="true"
      center
    >
      <div class="dialog-content">
        <el-form :model="dialogObj.activity" ref="addActivity_form" label-width="140px" label-position="right" :rules="rules">
          <el-form-item label="活动名称" prop="activityName">
            <el-input v-model="dialogObj.activity.activityName" placeholder="格式：年月日+赛道+活动名，如：20220525儿美儿童节"></el-input>
          </el-form-item>
          <el-form-item label="赛道" prop="brand">
            <el-select v-model="dialogObj.activity.brand" placeholder="请选择" clearable>
              <el-option
                v-for="item in options.brand"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="活动日期" prop="activityTime">
            <el-date-picker
              v-model="dialogObj.activity.activityTime"
              value-format="yyyyMMdd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="财务核算口径" prop="financeAccountOrigin">
            <el-select v-model="dialogObj.activity.financeAccountOrigin" placeholder="请选择">
              <el-option
                v-for="item in dialogObj.financeAccountOriginOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="导入文件" prop="importActivity_Org_Sku">
            <el-button type="primary" @click="upload('importActivity_Org_Sku')">上传</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
<!--        <el-button size="small" type="primary" @click="handleDialogEvent('save')">预 览</el-button>-->
<!--        <el-button size="small" @click="handleDialogEvent('cancel')">确 认</el-button>-->
      </div>
    </el-dialog>

    <!-- 导入弹窗 -->
    <mg-importDialog
      :urlParams="urlParams"
      :importBaseParams="importBaseParams(importDialog.importBaseParams)"
      :apiExtraParams="dialogObj.activity"
      @handleImportSuccess="handleImportSuccess"
    />

    <!-- 删除活动弹窗 -->
    <el-dialog
      class="add-dialog"
      title="删除活动"
      width="650px"
      :visible.sync="dialogObj.deleteDialog.isDelete"
      :destroy-on-close="true"
      center
    >
      <div class="dialog-content">
        <el-form :model="dialogObj.deleteDialog.form" ref="deleteActivity_form" label-width="140px" label-position="right">
          <el-form-item label="删除活动名称" prop="deleteNames" required>
            <el-select v-model="dialogObj.deleteDialog.form.deleteNames" placeholder="请选择" clearable multiple>
              <el-option
                v-for="item in dialogObj.deleteDialog.option.activityName"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleDeleteCancel()">取 消</el-button>
        <el-button size="small" @click="handleDeleteConfirm()" :loading="dialogObj.deleteDialog.deleteLoding">确认删除</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { urlParams } from '@/mixins';
import {handleAddActivityPost, handleRaceActivityListOptions, handleRaceActivityLists, handleRaceActivityNameList, handledeleteActivity} from "../../../api/raceActivity";
import {excelExport} from "@/utils";
export default {
  name: "index",
  mixins: [ urlParams ],
  components: {
    mgPagination: () => import("@/components/Element/Pagination"),
    mgImportDialog: () => import("@/components/ImportDialog"),
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return (importParams) => {
        return importParams.find(
          (item) => item.type === importDialog.currentImportType
        );
      };
    },
  },
  data() {
    return {
      loading: false,
      downloadLoading: false,
      queryParams: {
        page: 1,
        limit: 10,
        brand: '',
        activityName: '',
        activityStatus: ''
      },
      activityList: [],
      total: null,
      options: {
        brand: [],
        activityName: [],
        activityStatus: ["未开始", "活动中", "结束90天内", "结束超90天"],

      },
      dialogObj: {
        isAdd: false,
        activity: {
          activityName: "",
          brand: "",
          activityTime: "",
          financeAccountOrigin: "全部"
        },
        financeAccountOriginOptions: ["全部", "分公司出货", "分公司进货"],
        deleteDialog: {
          isDelete: false,
          deleteLoding: false,
          option: {
            activityName: [],
          },
          form: {
            deleteNames: []
          }
        }
      },
      importDialog: {
        currentImportType: "importActivity_Org_Sku",
        importBaseParams: [
          {
            visible: false,
            title: "分公司-产品 数据导入",
            type: "importActivity_Org_Sku",
            tipsText: "*必需字段: 分公司、产品编码、分公司名称、产品名称",
            optionalTipsText: "ps：全国性活动分公司填写*，代表全部分公司",
            templateSampleHead: [
              { label: "分公司", prop: "orgCode", minWidth: "80px" },
              { label: "产品编码", prop: "skuCode", minWidth: "80px" },
              { label: "分公司名称", prop: "orgName", minWidth: "80px" },
              { label: "产品名称", prop: "skuName", minWidth: "80px" }
            ],
            templateSampleLists: [
              {
                skuCode: "00000011",
                orgCode: "019",
                orgName: '上海',
                skuName: 'XX'
              },
            ],
            api: handleAddActivityPost,
            apiParams: "list",
            apiExtraParams: this.dialogObj?.activity
          },
        ],
      },
      rules: {
        activityName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 10, max: 26, message: '长度在 10 到 26 个字符,请参考格式：年月日+赛道+活动名', trigger: 'blur' }
        ],
        brand: [
          { required: true, message: '请选择赛道', trigger: 'blur' },
        ],
        activityTime: [
          { required: true, message: '请选择活动日期', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.handleRaceActivityListOptions();
    this.getRaceActivityList();
  },
  mounted() {
  },
  methods: {
    async getRaceActivityList() {
      try {
        this.loading = true;
        const response = await handleRaceActivityLists(this.queryParams);
        if (response.code != 200) {
          throw '错误码：' + response.code;
        }
        this.activityList = response.data.datas;
        this.total = response.data.total;
        this.loading = false;
      } catch (err) {
        this.boAddOrderList = [];
        this.total = 0;
        this.loading = false;
        console.log(err, 'err----获取赛道活动列表有误');
      }
    },

    /** 搜索框选项加载 */
    async handleRaceActivityListOptions() {
      try {
        const resCatagory = await handleRaceActivityListOptions();
        if (resCatagory.code == '200') {
          const {data} = resCatagory;
          this.options.activityName = data.activityName
          this.options.brand = data.race.filter(item => item !== "物料" && item != "" && item != null);
        }
      } catch (err) {
        console.log(err, 'err-----获取字典选项接口错误');
      }
    },

    async handleRaceActivityNameList() {
      try {
        const res = await handleRaceActivityNameList();
        if (res.code == '200') {
          const {data} = res;
          this.dialogObj.deleteDialog.option.activityName = data;
        }
      } catch (err) {
        console.log(err, 'err-----获取活动名称错误')
      }
    },

    /** 分页监听 */
    pagingChange(e) {
      if (e.current_page) this.queryParams.page = e.current_page;
      if (e.per_page) this.queryParams.limit = e.per_page;
      this.getRaceActivityList();
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getRaceActivityList();
    },

    /** 下载表格数据 */
    async download() {
      const topHeaders = {};
      const column = this.$refs.activityTable.$children.filter(obj => obj.prop);
      column.forEach(obj => { topHeaders[obj.prop] = obj.label });
      try {
        this.downloadLoading = true;

        const params = JSON.parse(JSON.stringify(this.queryParams));
        params.page = 1;
        params.limit = 1000000;

        const response = await handleRaceActivityLists(params);

        if (response.code != 200) {
          throw '错误码：' + response.code;
        }

        this.downloadLoading = false;

        const list = response.data.datas;
        if (list.length > 0) {
          excelExport({
            topHeaders,
            data: list,
            title: '赛道活动列表'
          })
        }
        if (list.length == 0) {
          return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.loading = false;
        console.log(err, 'err--------赛道活动列表导出接口有误');
      }
    },

    addActivity() {
      this.dialogObj.isAdd = true;
    },

    deleteActivity() {
      this.handleRaceActivityNameList();
      this.dialogObj.deleteDialog.isDelete = true;
    },

    handleImportSuccess(e) {
      const importDialog = this.importDialog;
      if (["importActivity_Org_Sku"].includes(e.type)) {
        const currentImportOption = importDialog.importBaseParams.find(
          (item) => item.type === e.type
        );
        importDialog.currentImportType = currentImportOption.type;
        currentImportOption.visible = false;
      }
      this.dialogObj.isAdd = false;
      this.getRaceActivityList();
      this.handleRaceActivityListOptions();
    },

    upload(type) {
      this.$refs['addActivity_form'].validate((valid) => {
        if (valid) {
          const currentImportOption = this.importDialog.importBaseParams.find(
            (item) => item.type === type
          );
          this.importDialog.currentImportType = currentImportOption.type;
          this.importDialog.importBaseParams.apiExtraParams = this.dialogObj.activity
          currentImportOption.visible = true;
        } else {
          return false;
        }
      });
    },

    handleDeleteConfirm() {
      this.$refs['deleteActivity_form'].validate(async (valid) => {
        if (valid) {
          this.dialogObj.deleteDialog.deleteLoding = true;
          try {
            const resCatagory = await handledeleteActivity(this.dialogObj.deleteDialog.form.deleteNames);
            if (resCatagory.code == '200') {
              this.dialogObj.deleteDialog.deleteLoding = false;
              this.$message.success('删除成功');
              this.dialogObj.deleteDialog.form.deleteNames = [];
              this.dialogObj.deleteDialog.isDelete = false;
              this.handleRaceActivityListOptions();
              this.handleQuery();
            }
          } catch (err) {
            this.dialogObj.deleteDialog.deleteLoding = false;
            console.log(err, 'err-----删除接口错误');
          }
        } else {
          return false;
        }
      });
    },

    handleDeleteCancel() {
      this.dialogObj.deleteDialog.form.deleteNames = [];
      this.dialogObj.deleteDialog.isDelete = false;
    }
  }
}
</script>

<style scoped>

</style>
