var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "salesCenter-page" },
    [
      _c(
        "div",
        { staticClass: "salesCenter-content" },
        [
          _c("v-signboard", { attrs: { title: "人员清单" } }),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "list-head" },
                [
                  this.user.role_code_list.includes("selfManager") ||
                  this.user.role_code_list.includes("leader")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.handleTableHeadAction("add")
                            },
                          },
                        },
                        [_vm._v("新 增 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableStatus.isLoading,
                  expression: "tableStatus.isLoading",
                },
              ],
              ref: "multipleTable",
              staticClass: "table",
              attrs: { stripe: "", border: "", size: "mini", data: _vm.lists },
              on: { "selection-change": _vm.handleselection },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40", fixed: "left" },
              }),
              _vm._l(_vm.tableObj.userRoleHead, function (item, index) {
                return [
                  item.prop === "-"
                    ? _c(
                        "el-table-column",
                        _vm._b(
                          {
                            key: index,
                            attrs: { fixed: "right", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "prodConfirm-btn" },
                                        [
                                          _vm._l(
                                            scope.row.userOpeBtn,
                                            function (item1, confirId) {
                                              return _c(
                                                "el-button",
                                                {
                                                  key: confirId + "only",
                                                  attrs: {
                                                    type: item1.isSelect
                                                      ? "primary"
                                                      : "",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleUserOpe(
                                                        scope.$index,
                                                        confirId,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item1.btnText) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          "el-table-column",
                          item,
                          false
                        )
                      )
                    : _c(
                        "el-table-column",
                        _vm._b(
                          {
                            key: index,
                            attrs: {
                              "show-overflow-tooltip": "",
                              align: "center",
                            },
                          },
                          "el-table-column",
                          item,
                          false
                        )
                      ),
                ]
              }),
            ],
            2
          ),
          _c("v-pagination", {
            attrs: {
              total: _vm.total,
              pageSize: _vm.pageSize,
              currentPage: _vm.currentPage,
            },
            on: { pageChange: _vm.pagingChange },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "新增人员",
            width: "650px",
            visible: _vm.dialogStatus.isAddUser,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogStatus, "isAddUser", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  staticClass: "dialog-form",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    size: "mini",
                    model: _vm.dialogFormModel,
                    rules: _vm.dialogFormModelRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "员工ID",
                                size: "mini",
                                prop: "modify_operator",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "员工ID", clearable: "" },
                                model: {
                                  value: _vm.dialogFormModel.modify_operator,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "modify_operator",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.modify_operator",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "姓名",
                                size: "mini",
                                prop: "real_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "姓名", clearable: "" },
                                model: {
                                  value: _vm.dialogFormModel.real_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "real_name",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.real_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item-select",
                              attrs: {
                                label: "用户角色",
                                size: "mini",
                                prop: "role_code_list",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择用户角色",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.dialogFormModel.role_code_list,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dialogFormModel,
                                        "role_code_list",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dialogFormModel.role_code_list",
                                  },
                                },
                                _vm._l(
                                  _vm.options.roleOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.role_code,
                                      attrs: {
                                        label: item.role_name,
                                        value: item.role_code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item-select",
                              attrs: {
                                label: "用户状态",
                                size: "mini",
                                prop: "status",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择用户状态",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.dialogFormModel.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dialogFormModel,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "dialogFormModel.status",
                                  },
                                },
                                _vm._l(
                                  _vm.statusOptions,
                                  function (option, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: option.id,
                                        label: option.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleAddSubmit },
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleAddCancel },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "修改人员",
            width: "650px",
            visible: _vm.dialogStatus.isUpdateUser,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogStatus, "isUpdateUser", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  staticClass: "dialog-form",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    size: "mini",
                    model: _vm.dialogFormModel,
                    rules: _vm.dialogFormModelRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "员工ID",
                                size: "mini",
                                prop: "modify_operator",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "员工ID",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.dialogFormModel.modify_operator,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "modify_operator",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.modify_operator",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "姓名",
                                size: "mini",
                                prop: "real_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "姓名", clearable: "" },
                                model: {
                                  value: _vm.dialogFormModel.real_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "real_name",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.real_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item-select",
                              attrs: {
                                label: "用户角色",
                                size: "mini",
                                prop: "role_code_list",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择用户角色",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.dialogFormModel.role_code_list,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dialogFormModel,
                                        "role_code_list",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dialogFormModel.role_code_list",
                                  },
                                },
                                _vm._l(
                                  _vm.options.roleOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.role_code,
                                      attrs: {
                                        label: item.role_name,
                                        value: item.role_code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item-select",
                              attrs: {
                                label: "用户状态",
                                size: "mini",
                                prop: "status",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择用户状态",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.dialogFormModel.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dialogFormModel,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "dialogFormModel.status",
                                  },
                                },
                                _vm._l(_vm.statusOptions, function (option) {
                                  return _c("el-option", {
                                    key: option.id,
                                    attrs: {
                                      value: option.id,
                                      label: option.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleUpdateSubmit },
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.handleAddCancel },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }