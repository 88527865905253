var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                "label-position": "right",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc,capacity,area-tag",
                      init: "",
                      initOptions: _vm.orgDictInitOptions,
                      fullLabel: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "province,capacity,area-tag",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "省份" } },
                [
                  _c("DictSelect", {
                    ref: "province",
                    attrs: {
                      type: "province",
                      value: _vm.queryForm.provinceCode,
                      linkageRef: "city",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "provinceCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市" } },
                [
                  _c("DictSelect", {
                    ref: "city",
                    attrs: {
                      type: "city",
                      value: _vm.queryForm.cityCode,
                      linkageRef: "region",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "cityCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区县" } },
                [
                  _c("DictSelect", {
                    ref: "region",
                    attrs: {
                      type: "region",
                      value: _vm.queryForm.areaCode,
                      linkageRef: "business",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商圈" } },
                [
                  _c("DictSelect", {
                    ref: "business",
                    attrs: {
                      value: _vm.queryForm.businessAreaName,
                      type: "business",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                    },
                    on: {
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "businessAreaName",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺类型" } },
                [
                  _c("DictSelect", {
                    ref: "capacity",
                    attrs: {
                      type: "capacity",
                      value: _vm.queryForm.shopCapacity,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCapacity", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组" } },
                [
                  _c("DictSelect", {
                    ref: "area-tag",
                    attrs: {
                      type: "area-tag",
                      value: _vm.queryForm.areaTag,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaTag", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      value: _vm.queryForm.shopName,
                      type: "shopName",
                      remote: "",
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopName", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺编码" } },
                [
                  _c("DictSelect", {
                    ref: "shopCode",
                    attrs: {
                      value: _vm.queryForm.shopCode,
                      remote: "",
                      type: "shopCode",
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCode", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否重点终端" } },
                [
                  _c("DictSelect", {
                    ref: "tag",
                    attrs: {
                      type: "pre-shop",
                      value: _vm.queryForm.ifOptimumShop,
                      dictFun: _vm.shopDict,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "ifOptimumShop", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合作类型" } },
                [
                  _c("DictSelect", {
                    ref: "shopBiz",
                    attrs: {
                      value: _vm.queryForm.shopBiz,
                      type: "shopBiz",
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopBiz", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm.queryForm.orgCode
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-plus",
                            type: "primary",
                          },
                          on: { click: _vm.handleAddAll },
                        },
                        [_vm._v("全部添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 350px)",
            size: "mini",
            border: "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
            "row-class-name": "rowClassName",
          },
          on: {
            "row-dblclick": _vm.handleRowDblClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "90" } }),
          _c("el-table-column", {
            attrs: { label: "店铺编码/名称", prop: "shop", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.shopCode + "-" + row.shopName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "省份/城市", prop: "provinceName", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.provinceName + "-" + row.cityName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "所属配送中心", prop: "dcName", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.dcCode + "-" + row.dcName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "所属区县", prop: "areaName", width: "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "所属商圈",
              prop: "businessAreaName",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "区域分组", prop: "areaTag", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺类型", prop: "shopCapacity", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { label: "合作类型", prop: "shopBiz", width: "60" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "是否重点终端",
              prop: "ifOptimumShop",
              width: "60",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.ifOptimumShop === "1" ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "合作类型", prop: "shopBiz", width: "80" },
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.closeDrawer } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "mini",
                type: "primary",
                disabled: _vm.pageData.selections.length === 0,
              },
              on: {
                click: function ($event) {
                  return _vm.addItems()
                },
              },
            },
            [
              _vm._v(
                " 添加已选 " + _vm._s(_vm.pageData.selections.length) + " 店铺 "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }