<template>
  <div style="float: right; margin-bottom: 15px; font-size: 15px;" :class="updateTime? '': 'time-show'">更新时间: {{ time }}</div>
</template>

<script>
import {  parseTime } from "@/utils";

export default {
  name:'UpdateTime',
  props: {
    time: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      updateTime: undefined

    }
  },
  watch: {
    // time: {
    //   handler(newVal, oldVal) {
    //     this.updateTime = this.time;
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  mounted() {
  },
  methods: {
    handleTime(data) {
      // return parseTime(new Date(data).getTime(), "{y}-{m}-{d}");
    }
  }
}
</script>

<style lang="scss" scoped>
.time-show {
  padding-right: 80px;
}
</style>
