<style>
.main-container {
  overflow: hidden !important;
}

.app-main {
  overflow: hidden !important;
}

.app-wrapper {
  overflow: hidden !important;
}

#app {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
<template>
  <div class="thirdPartyPage-page">
    <el-row>
      <el-col class="signboard" style="padding: 10px 0 10px 0;margin-bottom: 10px;" :md="8" :lg="6" :xl="4">
        排单计划确认
      </el-col>
      <el-col class="thirdPartyPage-page-head" style="padding:5px 0;" :md="8" :lg="6" :xl="4">
        <!-- 生产中心 -->
        <el-button
          size="small"
          type="warning"
          :disabled="isConfirm"
          @click="handleHeadAction('showRequisitionSku')"
        >确 认 排 单
        </el-button>
        <span style="margin-left: 10px;font-size: 14px;color: green" v-text="confirmTips" id="confirmTips">未确认排产</span>
      </el-col>
    </el-row>
    <el-tabs tab-position="top" v-model="activeName">
      <el-tab-pane v-for="(item, index) in iframeArrays" :key="index + 'iframe'" :label="item.name"
                   :name="item.tabName">
        <template>
          <div v-if="item.tabName == '3'">
            <actualProd-scheduligPlan/>
          </div>
          <div v-else-if="item.tabName == '4'">
            <ArrangePluReport/>
          </div>
          <iframe v-else :src="item.iframeSrc" frameborder="0" style="width: 100%; height: 85vh;"></iframe>
        </template>
      </el-tab-pane>
    </el-tabs>


    <!-- 请购单多包装单位SKU抛单数量确认 -->
    <el-dialog
      class="add-dialog"
      title="请购单多包装单位SKU抛单数量确认"
      width="900px"
      :visible.sync="dialogStatus.isShowRequisitionSku"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-table
          class="table"
          stripe
          border
          size="mini"
          ref="multipleTable"
          :data="lists"
          v-loading="tableStatus.isLoading"
          @selection-change="handleselection"
        >
          <el-table-column type="selection" width="55"/>
          <el-table-column label="序号" align="center" width="55" fixed="left">
            <template slot-scope="scope">
              <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
            </template>
          </el-table-column>
          <template v-for="(item, index) in tableObj.requisitionSkuHead">
            <!-- 预排产量 -- 可编辑 -->
            <el-table-column v-if="['requisition_count_box'].includes(item.prop)" v-bind="item"
                             show-overflow-tooltip :key="index" align="center">
              <template slot-scope="scope">
                <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'requisition_count_box'"
                          v-model="scope.row.requisition_count_box" size="mini" clearable/>
              </template>
            </el-table-column>
            <el-table-column v-else-if="['cargono'].includes(item.prop)" fixed="left" v-bind="item"
                             show-overflow-tooltip :key="index" align="center"></el-table-column>
            <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
          </template>

        </el-table>
        <!-- 分页器 -->
        <v-pagination
          :total="this.total"
          :pageSize="this.pageSize"
          :currentPage="this.currentPage"
          @pageChange="this.pagingChange"
        />
      </div>

      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleSave">保 存</el-button>
        <el-button size="small" type="danger" @click="handleAddSubmit">确 认 抛 单</el-button>
        <el-button size="small" @click="handleAddCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {paginationParams, urlParams} from '@/mixins';
import {handleschedulingPlanCustomAdjFinalConfirm} from '@/api/schedulingPlan';
import {handleRequisitionSkuPage, handleUpdateRequisitionSku} from "../../../api/schedulingPlan";
import {queryPlanProcessList} from "../../../api/prodCenter";

export default {
  // 排产计划
  name: 'prodCenterSchedulingPlan',
  mixins: [urlParams, paginationParams],
  components: {
    ActualProdScheduligPlan: () => import('@/views/schedulingPlan/components/ActualProdScheduligPlan'),
    ArrangePluReport: () => import('@/views/schedulingPlan/components/ArrangePluReport'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      iframeArrays: [
        {
          name: '生产中心产需平衡',
          tabName: '1',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E7%2594%259F%25E4%25BA%25A7%25E4%25B8%25AD%25E5%25BF%2583%25E4%25BA%25A7%25E9%259C%2580%25E5%25B9%25B3%25E8%25A1%25A1.cpt&ref_t=design&ref_c=dae2a225-a7dc-4f5c-a2b0-726588f23da2'
        },
        {
          name: '生产中心产线实际排产计划',
          tabName: '2',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E7%2594%259F%25E4%25BA%25A7%25E4%25B8%25AD%25E5%25BF%2583%25E4%25BA%25A7%25E7%25BA%25BF%25E5%25AE%259E%25E9%2599%2585%25E6%258E%2592%25E4%25BA%25A7%25E8%25AE%25A1%25E5%2588%2592.cpt&ref_t=design&ref_c=dae2a225-a7dc-4f5c-a2b0-726588f23da2'
        },
        {
          name: '实际排产计划',
          tabName: '3',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E5%25AE%259E%25E9%2599%2585%25E6%258E%2592%25E4%25BA%25A7%25E8%25AE%25A1%25E5%2588%2592.cpt&ref_t=design&ref_c=4149f041-76d2-48e3-ad15-501cfc51f417'
        },
        {
          name: '请购单明细(箱)',
          tabName: '4',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25AF%25B7%25E8%25B4%25AD%25E5%258D%2595%25E6%2598%258E%25E7%25BB%2586.cpt&ref_t=design&ref_c=dae2a225-a7dc-4f5c-a2b0-726588f23da2'
        },
        {
          name: '起订量问题',
          tabName: '6',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25B5%25B7%25E8%25AE%25A2%25E9%2587%258F%25E9%2597%25AE%25E9%25A2%2598.cpt&ref_t=design&ref_c=6e6965e9-bdbb-4eb4-90d1-3b7f0ff7d102'
        },
      ],
      operator: '',
      depart: '',
      isConfirm: false,
      activeName: '1',
      dialogStatus: {
        isShowRequisitionSku: false
      },
      tableStatus: {
        isLoading: false,
        initData: true,
      },
      lists: [],
      confirmTips: '尚未确认',
      tableObj: {
        requisitionSkuHead: [
          {label: '原始料号', prop: 'material_code', minWidth: '140px'},
          {label: '包装单位', prop: 'pack_unit', minWidth: '140px'},
          {label: '折算系数', prop: 'cnt', minWidth: '100px'},
          {label: '请购单总量', prop: 'total_requisition_count_box', minWidth: '100px'},
          {label: '拆分请购量', prop: 'requisition_count_box', minWidth: '100px'},
          {label: '箱规', prop: 'bottom_spec', minWidth: '100px'},
        ]
      }
    }
  },
  created() {
    this.handleUrlParams().then(res => {
      this.operator = res.operator;
      this.depart = res.depart;
      this.checkConfirmTips();
    })
  },
  methods: {
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
  async checkConfirmTips() {
    const params = {
      month: this.urlMonth || '',
      operator: this.urlParams.operator,
    };
    const {data} = await queryPlanProcessList(params);
    if (data) {
      const {process_list} = data;
      for(let i = 0; i < process_list.length; i++) {
        let process = process_list[i];
        if(process['depart_type'] === 2 && process['phase_id'] === 4) {
          this.confirmTips = '已确认排单';
          this.isConfirm = true;
        }
      }
    }
  },
    async handleSave() {
      let newItems = [];
      this.selectRows.map(item => {
        newItems.push(item);
      });
      const params = {
        operator: this.urlParams.operator,
        sku_list: newItems
      };
      const {code, msg} = await handleUpdateRequisitionSku(params);
      this.$refs['multipleTable'].clearSelection();
      if (code == '0000') {
        this.tableStatus.isLoading = true;
        this.getLists();
        return this.$message.success('保存成功！');
      }
      if (code != '0000') this.getLists();
    },
    handleAddSubmit() {
      this.handleHeadAction('confirm');
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        resolve(lists);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          operator: this.urlParams.operator,
        };
        const params = {
          init : this.tableObj.initData,
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleRequisitionSkuPage(params);
        if (data) {
          const {sku_list, total} = data;
          this.modifyData(sku_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
          this.tableStatus.initData = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取请购单多包装单位SKU抛单数量确认列表有误')
      }
    },
    // 取消新增
    handleAddCancel() {
      this.dialogStatus.isShowRequisitionSku = false;
    },
    handleHeadAction(type) {
      const funcObj = {
        'showRequisitionSku': async () => { // 展示预排请购单信息
          this.currentPage = 1;
          this.dialogStatus.isShowRequisitionSku = true;
          this.getLists();
        },
        'confirm': async () => {
          this.$confirm('需要确认最终排单么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const loading = this.$loading({
              lock: true,
              text: '处理中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });

            try {
              let newItems = [];
              if(this.selectRows) {
                this.selectRows.map(item => {
                  newItems.push(item);
                });
              }

              const params = {
                operator: this.urlParams.operator,
                sku_list: newItems
              };

              const {code, msg} = await handleschedulingPlanCustomAdjFinalConfirm(params);
              loading.close();
              if (code === '0000') {
                this.isConfirm = true;
                this.confirmTips = '已确认排单';
                this.dialogStatus.isShowRequisitionSku = false;
                return this.$message.success(msg);
              }
            } catch (err) {
              loading.close();
              this.isConfirm = false;
              console.log(err, 'err------最终确认排单接口有误');
            }
          }).catch(() => {
            this.$message('最终确认未确认');
          });
        }
      };
      funcObj[type]();
    }
  }
}
</script>

<style lang="scss" scoped>
.thirdPartyPage-page {
  height: 100vh;

  &-head {
    text-align: center;
    padding: 10px 0 20px 0;
  }

  ::v-deep .el-tabs__content {
    height: 100vh;
    overflow: scroll;
  }
}
</style>
