import { render, staticRenderFns } from "./index.vue?vue&type=template&id=174c1348&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "@/style/configCenter/layoutMain.scss?vue&type=style&index=0&id=174c1348&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174c1348",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/jenkins/workspace/pdb-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('174c1348')) {
      api.createRecord('174c1348', component.options)
    } else {
      api.reload('174c1348', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=174c1348&scoped=true", function () {
      api.rerender('174c1348', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/configCenter/capacityLimitConfig/index.vue"
export default component.exports