import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/request";

/**
 * 分组下拉创建人数据
 * @returns {*}
 */
export function getC1CatNameList() {
  return axiosGet("/xp/trace/basic/c1CatName/list", {}, 1);
}

/**
 * 上新追踪统计
 * @param data
 * @returns {*}
 */
export function getOnNewTraceStatistics(data) {
  return axiosPost("/on-new/trace/list", data, 1);
}

/**
 * 上新追踪统计下载
 * @param data
 * @returns {*}
 */
export function getOnNewTraceStatisticsExport(data) {
  return axiosPost("/on-new/trace/export", data, 1);
}

/**
 * 更新上新追踪未准时上新原因及动作
 * @param data
 * @returns {*}
 */
export function updateOnNewNotOnTimeReason(data) {
  return axiosPost("/on-new/trace/not-on-time-reason/update", data, 1);
}

/**
 * 上新问题清单列表
 * @param data
 * @returns {*}
 */
export function getOnNewProblemPageList(data) {
  return axiosPost("/on-new/problem/list", data, 1);
}

/**
 * 上新问题清单列表下载
 * @param data
 * @returns {*}
 */
export function getOnNewProblemListExport(data) {
  return axiosPost("/on-new/problem/export", data, 1);
}

/**
 * 入库追踪统计
 * @param data
 * @returns {*}
 */
export function getRkTraceStatistics(data) {
  return axiosPost("/rk-trace/list", data, 1);
}

/**
 * 入库追踪统计下载
 * @param data
 * @returns {*}
 */
export function getRkTraceStatisticsExport(data) {
  return axiosPost("/rk-trace/export", data, 1);
}

/**
 * 更新入库追踪未准时上新原因及动作
 * @param data
 * @returns {*}
 */
export function updateRkNotOnTimeReason(data) {
  return axiosPost("/rk-trace/not-on-time-reason/update", data, 1);
}

/**
 * 入库问题清单列表
 * @param data
 * @returns {*}
 */
export function getRkProblemPageList(data) {
  return axiosPost("/rk/problem/list", data, 1);
}

/**
 * 入库问题清单列表下载
 * @param data
 * @returns {*}
 */
export function getRkProblemListExport(data) {
  return axiosPost("/rk/problem/export", data, 1);
}

/**
 * 建档追踪统计
 * @param data
 * @returns {*}
 */
export function getCreateTraceStatistics(data) {
  return axiosPost("/create-trace/list", data, 1);
}

/**
 * 建档追踪统计下载
 * @param data
 * @returns {*}
 */
export function getCreateTraceStatisticsExport(data) {
  return axiosPost("/create-trace/export", data, 1);
}

/**
 * 更新建档追踪未准时上新原因及动作
 * @param data
 * @returns {*}
 */
export function updateCreatedNotOnTimeReason(data) {
  return axiosPost("/create-trace/not-on-time-reason/update", data, 1);
}

/**
 * 建档问题清单列表
 * @param data
 * @returns {*}
 */
export function getCreateProblemPageList(data) {
  return axiosPost("/create/problem/list", data, 1);
}

/**
 * 建档问题清单列表下载
 * @param data
 * @returns {*}
 */
export function getCreateProblemListExport(data) {
  return axiosPost("/create/problem/export", data, 1);
}

/**
 * YTD汇总统计
 * @param data
 * @returns {*}
 */
export function queryYtdSummaryStatistics(data) {
  return axiosPost("/on-new/trace/ytd/summary/list", data, 1);
}

/**
 * YTD汇总统计导出
 * @param data
 * @returns {*}
 */
export function ytdSummaryStatisticsExport(data) {
  return axiosPost("/on-new/trace/ytd/summary/export", data, 1);
}

/**
 * 分组下拉创建人数据
 * @returns {*}
 */
export function getDateLastUpdateTime() {
  return axiosGet("/on-new/trace/get_date_last_update_time", {}, 1);
}
