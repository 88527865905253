


const ERROR_MESSAGE_MAPS = {
    400:  '参数不正确！',
    401:  '您未登录，或者登录已经超时，请先重新登录，或未授权！',
    403:  '您没有权限操作！',
    404:  '未找到相关信息！',
    405:  '请求方法有误！',
    415:  '不支持该媒体类型！',
    500:  '服务器内部错误！',
    501:  '服务未实现！',
    502:  '网关错误！',
    503:  '服务不可用！',
    504:  '服务暂时无法访问，请稍后再试！',
    505:  'HTTP版本不受支持！',
    1000: '操作人不能为空！',
    1001: '用户ID不能为空！',
    1002: '用户不存在！',
    1003: '无法创建解算器SCIP！',
    1004: '无法满足本次要货需求！',

    1008: '分公司客订核验已确认，无须重复确认！',
    1009: '库存系统设定已确认，无须重复确认！',
    1014: '排产计划未制定！',
    1013: '产品销售事实表数据为空，无法进行客订数据初始化！',
    1041: '授权信息错误，请尝试重新登录！'
}
export {
    ERROR_MESSAGE_MAPS, // 状态码
}
