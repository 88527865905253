var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                { on: { search: _vm.handleQuery, reset: _vm.resetQuery } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", "default-first-option": "" },
                          on: {
                            change: () => {
                              _vm.queryForm.code = undefined
                              _vm.handleFilter()
                            },
                          },
                          model: {
                            value: _vm.queryForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "type", $$v)
                            },
                            expression: "queryForm.type",
                          },
                        },
                        _vm._l(_vm.options.resourceOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { value: item.value, label: item.tab },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.queryForm.type === "TASK"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "default-first-option": "",
                                clearable: "",
                              },
                              on: {
                                change: (val) =>
                                  _vm.handleOrgChange(
                                    _vm.queryForm.type,
                                    "query"
                                  ),
                              },
                              model: {
                                value: _vm.queryForm.orgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgCode", $$v)
                                },
                                expression: "queryForm.orgCode",
                              },
                            },
                            _vm._l(_vm.options.orgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.queryForm.type === "TASK"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "任务" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "default-first-option": "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "code", $$v)
                                },
                                expression: "queryForm.code",
                              },
                            },
                            _vm._l(_vm.options.taskOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.queryForm.type === "HX"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "核销名称" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "code", $$v)
                                },
                                expression: "queryForm.code",
                              },
                            },
                            _vm._l(_vm.options.hxOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.queryForm.type === "QD_HX"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "渠道核销" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "code", $$v)
                                },
                                expression: "queryForm.code",
                              },
                            },
                            _vm._l(_vm.options.qdHxOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户", prop: "userName" } },
                    [
                      _c("DictSelect", {
                        ref: "user",
                        attrs: {
                          type: "user",
                          value: _vm.queryForm.userName,
                          dictFun: _vm.dictUserFun,
                          fullLabel: "",
                          remote: "",
                          version: "v3",
                          placeholder: "请输入账号或姓名",
                          "init-props": {
                            label: "nickName",
                            value: "userName",
                          },
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "userName", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ pageSize: 50, pageNum: 1 }),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleUserAuth("add", undefined)
                },
              },
            },
            [_vm._v("新增用户绑定数据")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.resourceDrawer.visible = true
                },
              },
            },
            [_vm._v("资源管理")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", type: "danger" },
              on: { click: _vm.handleDelUserAuthBatch },
            },
            [_vm._v("批量删除")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户", prop: "createUser" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.userName + "-" + row.nickName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm.queryForm.type === "TASK"
                ? _c("el-table-column", {
                    attrs: { label: "分公司编码/名称", prop: "shop" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      358871047
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "resourceName" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUserAuth("edit", row)
                                  },
                                },
                              },
                              [_vm._v("编辑/删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [10, 20, 50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "editDialogRef",
          attrs: {
            title:
              _vm.editDialog.type === "add" ? "新增用户数据" : "修改用户数据",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "300px",
            "close-on-click-modal": false,
            tabindex: "1",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
            opened: _vm.onDialogOpened,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.editDialog.rules,
                "label-width": "120px",
                model: _vm.editDialog.form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "default-first-option": "",
                                disabled: _vm.editDialog.type !== "add",
                              },
                              on: {
                                change: () => {
                                  _vm.editDialog.form.resourceCodes = []
                                  _vm.handleFilter()
                                },
                              },
                              model: {
                                value: _vm.editDialog.form.resourceType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editDialog.form,
                                    "resourceType",
                                    $$v
                                  )
                                },
                                expression: "editDialog.form.resourceType",
                              },
                            },
                            _vm._l(
                              _vm.options.resourceOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { value: item.value, label: item.tab },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户", prop: "userName" } },
                        [
                          _c("DictSelect", {
                            ref: "user",
                            attrs: {
                              type: "user",
                              value: _vm.editDialog.form.userName,
                              dictFun: _vm.dictUserFun,
                              fullLabel: "",
                              remote: "",
                              version: "v3",
                              disabled: _vm.editDialog.type !== "add",
                              placeholder: "请输入账号或姓名",
                              "init-props": {
                                label: "nickName",
                                value: "userName",
                              },
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "userName",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback({ pageSize: 50, pageNum: 1 }),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "TASK"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "分公司" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.editDialog.type !== "add",
                                    filterable: "",
                                    "default-first-option": "",
                                    clearable: "",
                                  },
                                  on: {
                                    change: (val) =>
                                      _vm.handleOrgChange(
                                        _vm.editDialog.form.resourceType,
                                        "edit"
                                      ),
                                  },
                                  model: {
                                    value: _vm.editDialog.form.orgCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "orgCode",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.orgCode",
                                  },
                                },
                                _vm._l(_vm.options.orgOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "TASK"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "任务", prop: "resourceCodes" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.editDialog.type !== "add",
                                    filterable: "",
                                    multiple: "",
                                    "default-first-option": "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.resourceCodes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "resourceCodes",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.resourceCodes",
                                  },
                                },
                                _vm._l(
                                  _vm.options.taskOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "HX"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "核销名称",
                                prop: "resourceCodes",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.editDialog.type !== "add",
                                    filterable: "",
                                    multiple: "",
                                    "default-first-option": "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.resourceCodes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "resourceCodes",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.resourceCodes",
                                  },
                                },
                                _vm._l(_vm.options.hxOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.form.resourceType === "QD_HX"
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "渠道核销",
                                prop: "resourceCodes",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.editDialog.type !== "add",
                                    filterable: "",
                                    multiple: "",
                                    "default-first-option": "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.resourceCodes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "resourceCodes",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.resourceCodes",
                                  },
                                },
                                _vm._l(
                                  _vm.options.qdHxOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.options.resourceRoles[
                        _vm.editDialog.form.resourceType
                      ]
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "关联角色" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    "default-first-option": "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.roleIds,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "roleIds",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.roleIds",
                                  },
                                },
                                _vm._l(
                                  _vm.options.resourceRoles[
                                    _vm.editDialog.form.resourceType
                                  ],
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.roleId,
                                      attrs: {
                                        value: item.roleId,
                                        label: item.roleName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.editDialog.type === "edit"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "操作类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                  },
                                  model: {
                                    value: _vm.editDialog.form.action,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editDialog.form,
                                        "action",
                                        $$v
                                      )
                                    },
                                    expression: "editDialog.form.action",
                                  },
                                },
                                _vm._l(
                                  _vm.options.actionOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.editDialog.disabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.submitUserAuth },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "资源数据",
            visible: _vm.resourceDrawer.visible,
            size: "70%",
            direction: "ttb",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.resourceDrawer, "visible", $event)
            },
          },
        },
        [_c("Resource")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }