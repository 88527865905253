/**
 * -------------------------------- 新品上市报表 -- 开始 --------------------------------
 */
const newPublishReportColumns = {
  "活动": {
    label: "活动",
    prop: "activity",
    showOverflowTooltip: true,
    width: "100px",
    fixed: "left",
    align: "center",
  },
  "货号": {
    label: "货号",
    prop: "cargoNo",
    showOverflowTooltip: true,
    width: "100px",
    fixed: "left",
    align: "center",
  },
  "料号": {
    label: "料号",
    prop: "materialCode",
    showOverflowTooltip: true,
    width: "100px",
    fixed: "left",
    align: "center",
  },
  "品名": {
    label: "品名",
    prop: "skuName",
    showOverflowTooltip: true,
    width: "100px",
    fixed: "left",
    align: "center",
  },
  "商品负责人": {
    label: "商品负责人",
    prop: "personLiable",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "系列": {
    label: "系列",
    prop: "series",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "新品/老品": {
    label: "新品/老品",
    prop: "newOld",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "仓位组织": {
    label: "仓位组织",
    prop: "stockOrg",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "供应中心": {
    label: "供应中心",
    prop: "supplyCenter",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "是否头部": {
    label: "是否头部",
    prop: "head",
    showOverflowTooltip: false,
    width: "100px",
    align: "center",
  },

  "请购量": {
    label: "请购量",
    prop: "purchaseRequisitionCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "需补请购单量": {
    label: "需补请购单量",
    prop: "needPurchaseRequisitionCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "预计库存": {
    label: "预计库存",
    prop: "expectStock",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "预定数量": {
    label: "预定数量",
    prop: "bookCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "订货箱数": {
    label: "订货箱数",
    prop: "bookCountBox",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "订货金额": {
    label: "订货金额",
    prop: "bookTotalMoney",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "加减单确认量": {
    label: "加减单确认量",
    prop: "confirmTotal",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },

  "订单合计-RDC": {
    label: "订单合计-RDC",
    prop: "rdcTotalOrder",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "订单合计-上海仓": {
    label: "订单合计-上海仓",
    prop: "shTotalOrder",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },
  "订单合计-华南仓": {
    label: "订单合计-华南仓",
    prop: "hnTotalOrder",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },

  "库存合计": {
    label: "库存合计",
    prop: "stockTotal",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "库存-RDC": {
    label: "库存-RDC",
    prop: "rdcOnStock",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "在途-RDC": {
    label: "在途-RDC",
    prop: "rdcOnPassage",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "库存-上海仓": {
    label: "库存-上海仓",
    prop: "shOnStock",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "在途-上海仓": {
    label: "在途-上海仓",
    prop: "shOnPassage",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "库存-华南仓": {
    label: "库存-华南仓",
    prop: "hnOnStock",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "在途-华南仓": {
    label: "在途-华南仓",
    prop: "hnOnPassage",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },

  "区域欠量合计": {
    label: "区域欠量合计",
    prop: "oweTotal",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "欠量-RDC": {
    label: "欠量-RDC",
    prop: "rdcOweCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "欠量-上海仓": {
    label: "欠量-上海仓",
    prop: "shOweCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "欠量-华南仓": {
    label: "欠量-华南仓",
    prop: "hnOweCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },

  "首单已发合计": {
    label: "首单已发合计",
    prop: "deliverTotal",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "首单已发-RDC": {
    label: "首单已发-RDC",
    prop: "rdcDeliverCount",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "首单已发-上海仓": {
    label: "首单已发-上海仓",
    prop: "shDeliverCount",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },
  "首单已发-华南仓": {
    label: "首单已发-华南仓",
    prop: "hnDeliverCount",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },

  "调拨检核-RDC": {
    label: "调拨检核-RDC",
    prop: "rdcAllocationCheck",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "调拨检核-上海仓": {
    label: "调拨检核-上海仓",
    prop: "shAllocationCheck",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },
  "调拨检核-华南仓": {
    label: "调拨检核-华南仓",
    prop: "hnAllocationCheck",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },
  "可用库存总量": {
    label: "可用库存总量",
    prop: "availableStock",
    showOverflowTooltip: true,
    width: "110px",
    align: "center",
  },

  "优先区域": {
    label: "优先区域",
    prop: "firstArea",
    showOverflowTooltip: false,
    width: "100px",
    align: "center",
  },
  "系统编码": {
    label: "系统编码",
    prop: "systemCode",
    showOverflowTooltip: false,
    width: "100px",
    align: "center",
  },
  "系统包装规格": {
    label: "系统包装规格",
    prop: "systemBottomSpec",
    showOverflowTooltip: false,
    width: "100px",
    align: "center",
  },
  "最大规格": {
    label: "最大规格",
    prop: "maxBottomSpec",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "系统零售价": {
    label: "系统零售价",
    prop: "systemPrice",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "大类": {
    label: "大类",
    prop: "mainCategoryName",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "中类": {
    label: "中类",
    prop: "midCategoryName",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "小类": {
    label: "小类",
    prop: "subCategoryName",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
  "赛道": {
    label: "赛道",
    prop: "raceName",
    showOverflowTooltip: true,
    width: "100px",
    align: "center",
  },
};
/**
 * -------------------------------- 新品上市报表 -- 新品跟进明细 -- --------------------------------
 */
const newPublishDetailColumns = [
  {
    label: "活动",
    prop: "activity",
    showOverflowTooltip: false,
    minWidth: "100px",
    fixed: "left",
    align: "center",
  },
  {
    label: "分公司名称",
    prop: "companyName",
    showOverflowTooltip: false,
    minWidth: "100px",
    fixed: "left",
    align: "center",
  },
  {
    label: "料号",
    prop: "materialCode",
    showOverflowTooltip: true,
    minWidth: "100px",
    fixed: "left",
    align: "center",
  },
  {
    label: "品名",
    prop: "skuName",
    showOverflowTooltip: true,
    minWidth: "100px",
    fixed: "left",
    align: "center",
  },
  {
    label: "产品编码",
    prop: "skuCode",
    showOverflowTooltip: true,
    minWidth: "100px",
    fixed: "left",
    align: "center",
  },
  {
    label: "赛道",
    prop: "raceName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "大类",
    prop: "mainCategoryName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "中类",
    prop: "midCategoryName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "小类",
    prop: "subCategoryName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "活动订货量",
    prop: "totalBoxCount",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "累积加单确认量",
    prop: "oldConfirmTotal",
    showOverflowTooltip: true,
    minWidth: "120px",
    align: "center",
  },
  {
    label: "最新一次加减单申请量",
    prop: "latestApplyCount",
    showOverflowTooltip: true,
    minWidth: "160px",
    align: "center",
  },
  {
    label: "最新一次加减单确认量",
    prop: "latestConfirmCount",
    showOverflowTooltip: true,
    minWidth: "160px",
    align: "center",
  },
  {
    label: "合计订单",
    prop: "totalOrder",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "已配送量",
    prop: "deliverCount",
    showOverflowTooltip: false,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "系统欠量",
    prop: "oweTotal",
    showOverflowTooltip: false,
    minWidth: "100px",
    align: "center",
  },
  {
    label: "剩余未做单量",
    prop: "leftOwe",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
  },
];

const fixSortClomuns = [
  "C-广西大客户",
  "C-云南",
  "C-贵州",
  "C-广西",
  "C-峡山",
  "C-深圳",
  "C-广州",
  "C-广东文教",
  "C-东莞",
  "C-海南",
  "C-福建",
  "C-湖南",
  "C-江西",

  "B-新疆",
  "B-西藏",
  "B-兰州",
  "B-沈阳",
  "B-哈尔滨",
  "B-长春",
  "B-北京",
  "B-石家庄",
  "B-山西",
  "B-唐山",
  "B-天津",
  "B-西安",
  "B-银川",
  "B-郑州",
  "B-洛阳",
  "B-临沂",
  "B-青岛",

  "A-泰国",
  "A-重庆",
  "A-四川",
  "A-湖北",
  "A-济南",
  "A-苏北天猫",
  "A-江苏汕头",
  "A-苏北",
  "A-江苏",
  "A-安徽",
  "A-义乌",
  "A-杭州",
  "A-上海",
  "A-上海办公",
  "A-珍美",
  "A-生活馆",
  "A-科技海外",
  "A-天猫",
  "A-京东",
  "A-京东办公",
  "A-天猫办公",
  "A-电商",
  "A-杂物社",
  "A-科力普",
  "A-香港",
  "A-办公直供",
  "A-精品直供",
  "A-南京天猫",
  "A-国际",
  "A-直播业务",
];

const filterColumns = {
  column: {
    list: [
      "活动",
      "货号",
      "料号",
      "品名",
      "商品负责人",
      "系列",
      "新品/老品",
      "仓位组织",
      "供应中心",
      "是否头部",
      "请购量",
      "需补请购单量",
      "预计库存",
      "预定数量",
      "订货箱数",
      "订货金额",
      "加减单确认量",
      "订单合计-RDC",
      "订单合计-上海仓",
      "订单合计-华南仓",
      "库存合计",
      "库存-RDC",
      "在途-RDC",
      "库存-上海仓",
      "在途-上海仓",
      "库存-华南仓",
      "在途-华南仓",
      "区域欠量合计",
      "欠量-RDC",
      "欠量-上海仓",
      "欠量-华南仓",
      "首单已发合计",
      "首单已发-RDC",
      "首单已发-上海仓",
      "首单已发-华南仓",
      "调拨检核-RDC",
      "调拨检核-上海仓",
      "调拨检核-华南仓",
      "可用库存总量",
      "优先区域",
      "系统编码",
      "系统包装规格",
      "最大规格",
      "系统零售价",
      "大类",
      "中类",
      "小类",
      "赛道"
    ],
    checked: [],
    checkAll: true,
    isIndeterminate: true,
    checkedBefore: [],
    defaultColumns: [],
  }
};

export {
  newPublishReportColumns, // 新品上市报表 -- 表头
  newPublishDetailColumns, // 新品跟进明细
  fixSortClomuns, // 固定表头顺序
  filterColumns
};
