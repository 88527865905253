var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mg-container", {
    scopedSlots: _vm._u([
      {
        key: "headerSearch",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "head-signboard-box" },
              [
                _c("mg-signboard", { attrs: { title: "库存设定" } }),
                _c("mg-signboard", {
                  attrs: {
                    title:
                      _vm.branchCompanyInfos && _vm.branchCompanyInfos.name,
                    isHavBar: false,
                  },
                }),
              ],
              1
            ),
            _c("mg-search-form", {
              ref: "mgSearchFormRef",
              attrs: {
                "form-item-list": _vm.formItemList,
                row: 2,
                "label-width": "70px",
                size: "mini",
              },
              on: { getSearchFormData: _vm.handleSearch },
              scopedSlots: _vm._u([
                {
                  key: "buttonScope",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.btnStatus.isSave,
                            loading: _vm.btnStatus.isSaveLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleTableHeadAction("save")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            loading: _vm.btnStatus.isExport,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleTableHeadAction("export")
                            },
                          },
                        },
                        [_vm._v("导出")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "info",
                            disabled: _vm.btnStatus.noSelectedRow,
                            loading: _vm.btnStatus.noSelectedRowLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleTableHeadAction("setDefaultAim")
                            },
                          },
                        },
                        [_vm._v("默认库存目标")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "table",
        fn: function () {
          return [
            _c(
              "mg-table",
              {
                ref: "mgTable",
                attrs: {
                  "auto-load": false,
                  columns: _vm.inventorySettingColumns.treeColumns,
                  isHeaderMiddle: true,
                  isUseDefaultColumns: false,
                  api: _vm.handleStoreRatioGradeLists,
                  apiParams: _vm.queryForm,
                  configurable: false,
                  "business-type": "BRANCHOFFICE_INVENTORYSETTING_LISTS",
                  height: "calc(100vh - 200px)",
                  "max-height": "auto",
                  "cell-style": _vm.columnCellStyle,
                },
                on: {
                  "update:columns": function ($event) {
                    return _vm.$set(
                      _vm.inventorySettingColumns,
                      "treeColumns",
                      $event
                    )
                  },
                  "selection-change": _vm.handleSelectionChange,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "40", fixed: "left" },
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "序号",
                    type: "index",
                    align: "center",
                    width: "50",
                    fixed: "left",
                  },
                }),
                _vm._l(
                  _vm.inventorySettingColumns.columns,
                  function (item, index) {
                    return [
                      item.prop.indexOf("Category") > -1 ||
                      item.prop === "raceName"
                        ? _c(
                            "el-table-column",
                            _vm._b(
                              {
                                key: index,
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  align: "center",
                                  fixed: "left",
                                },
                              },
                              "el-table-column",
                              item,
                              false
                            )
                          )
                        : _vm._e(),
                    ]
                  }
                ),
                _c(
                  "el-table-column",
                  {
                    staticStyle: { "background-color": "#6cf40a" },
                    attrs: {
                      label: _vm.columnsLabels.currentRatioLabel,
                      align: "center",
                      "label-class-name": "current_store_ratio_title",
                    },
                  },
                  [
                    _vm._l(
                      _vm.inventorySettingColumns.columns,
                      function (item, index) {
                        return [
                          item.prop.indexOf("currentStoreRatio") > -1
                            ? _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: {
                                      "show-overflow-tooltip": "",
                                      align: "center",
                                      "label-class-name": "current_store_ratio",
                                    },
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: _vm.columnsLabels.planMonthRatioLabel,
                      align: "center",
                      "label-class-name": "plan_month_ratio_title",
                    },
                  },
                  [
                    _vm._l(
                      _vm.inventorySettingColumns.columns,
                      function (item, index) {
                        return [
                          item.prop.indexOf("planMonthStoreRatio") > -1
                            ? _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: {
                                      "show-overflow-tooltip": "",
                                      align: "center",
                                      "label-class-name":
                                        "plan_month_store_ratio",
                                    },
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: _vm.columnsLabels.storeRatioAimLabel,
                      align: "center",
                    },
                  },
                  [
                    _vm._l(
                      _vm.inventorySettingColumns.columns,
                      function (item, index) {
                        return [
                          item.prop.indexOf("AimS") > -1 ||
                          item.prop.indexOf("AimA") > -1 ||
                          item.prop.indexOf("AimB") > -1 ||
                          item.prop.indexOf("AimCPlus") > -1 ||
                          item.prop === "storeRatioAimC"
                            ? _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: { align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              item.prop === "storeRatioAimS"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row.storeRatioAimS <
                                                        0 ||
                                                      scope.row.storeRatioAimS >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimS",
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.prop === "storeRatioAimAPlus"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row
                                                        .storeRatioAimAPlus <
                                                        0 ||
                                                      scope.row
                                                        .storeRatioAimAPlus >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimAPlus,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimAPlus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimAPlus",
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.prop === "storeRatioAimA"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row.storeRatioAimA <
                                                        0 ||
                                                      scope.row.storeRatioAimA >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimA,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimA",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimA",
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.prop === "storeRatioAimBPlus"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row
                                                        .storeRatioAimBPlus <
                                                        0 ||
                                                      scope.row
                                                        .storeRatioAimBPlus >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimBPlus,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimBPlus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimBPlus",
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.prop === "storeRatioAimB"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row.storeRatioAimB <
                                                        0 ||
                                                      scope.row.storeRatioAimB >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimB,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimB",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimB",
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.prop === "storeRatioAimCPlus"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row
                                                        .storeRatioAimCPlus <
                                                        0 ||
                                                      scope.row
                                                        .storeRatioAimCPlus >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimCPlus,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimCPlus",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimCPlus",
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.prop === "storeRatioAimC"
                                                ? _c("el-input", {
                                                    class:
                                                      scope.row.storeRatioAimC <
                                                        0 ||
                                                      scope.row.storeRatioAimC >
                                                        1.5
                                                        ? "tgqrcount-cell-ipt warning"
                                                        : "tgqrcount-cell-ipt",
                                                    attrs: {
                                                      type: "number",
                                                      size: "mini",
                                                      clearable: "",
                                                      disabled:
                                                        scope.row.isEdit,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .storeRatioAimC,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "storeRatioAimC",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.storeRatioAimC",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                )
                              )
                            : item.prop.indexOf("AimCMinus") > -1 ||
                              item.prop === "storeRatioAimD"
                            ? _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: {
                                      "show-overflow-tooltip": "",
                                      align: "center",
                                    },
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                )
                              )
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }