/**
 * ----------------------------- 销售中心 -----------------------------
 */
import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";


/**
 * ----------------------------- 分公司客订检核相关接口 -----------------------------
 */
// 获取分公司客订检核列表
export function handlefilialeCustomerOrderLists(data) {
  return axiosPost('/bo_kd/bo_order_total_page', data);
}

// 分公司客订检核确认调整
export function handleConfirmOrderTotal(data) {
  return axiosPost('/bo_kd/confirm_bo_order_total', data);
}

// 分公司客订检核保存
export function handleSaveOrderTotal(data) {
  return axiosPost('/bo_kd/save_bo_order_total', data);
}

/**
 * ----------------------------- 库存系数设定相关接口 -----------------------------
 */
// 获取库存系数分类设定
export function handleClassifyConfigLists(data) {
  return axiosPost('/store_ratio_grade/store_ratio_grade_page', data);
}

// 更新库存系数分类设定
export function handleSaveClassifyConfig(data) {
  return axiosPost('/store_ratio_grade/save_store_ratio_grade', data);
}

// 导入库存系数分类设定
export function handleImportClassifyConfig(data) {
  return axiosPost('/store_ratio_grade/import_store_ratio_grade', data);
}

// 获取库存系数设定列表
export function handleStoreRatioConfigPage(data) {
  return axiosPost('/store_ratio/store_ratio_config_page', data);
}

// 库存系数设定确认调整
export function handleAdjustStoreRatio(data) {
  return axiosPost('/store_ratio/confirm_store_ratio_config', data);
}

// 库存系数设定保存
export function handleSaveStoreRatio(data) {
  return axiosPost('/store_ratio/save_store_ratio_config', data);
}

// 重新设定库存系数设定
export function handleResetStoreRatio(data) {
  return axiosPost('/store_ratio/reset_store_ratio_config', data);
}

// 库存系数设定导入数据
export function handleStoreRatioImport(data) {
  return axiosPost('/store_ratio/import_store_ratio_config', data);
}


/**
 * ----------------------------- 起订量调整相关接口 -----------------------------
 */

// 起订量调整列表(销售中心)
export function handleMinimumPluSaleLists(data) {
  return axiosPost('/min_order_plu/min_order_plu_page_sale', data);
}

// 起订量调整保存
export function handleSaveMinimumOrderPlu(data) {
  return axiosPost('/min_order_plu/save_min_order_plu', data);
}

// 起订量调整销售中心确认
export function handleSaleConfirmMinimumOrderPlu(data) {
  return axiosPost('/min_order_plu/sale_confirm_min_order_plu', data);
}


/**
 * ----------------------------- 请购单确认相关接口 -----------------------------
 */

// 请购单确认列表
export function handlePluOrderLists(data) {
  return axiosPost('/plu_order/plu_order_page', data);
}

// 请购单确认页面保存
export function handleSavePluOrder(data) {
  return axiosPost('/plu_order/save_plu_order', data);
}

// 请购单确认请购置零
export function handleProdSetZero(data) {
  return axiosPost('/plu_order/prod_set_zero', data);
}

// 请购单确认页面领导审核
export function handleConfirmPluOrder(data) {
  return axiosPost('/plu_order/confirm_plu_order', data);
}

// 导出请购单数据
export function handleDownloadPluOrder(data) {
  return axiosPost('/plu_order/download_plu_order', data);
}

// 请购单确认页面重置分组
export function handleResetGroup(data) {
  return axiosPost('/plu_order/group_reset_plu_order', data);
}

// 请购单确认页面分组审核
export function handleConfirmGroup(data) {
  return axiosPost('/plu_order/group_confirm_plu_order', data);
}

// 请购单确认页面修改目标库存系数
export function handleUpdateStoreRatio(data) {
  return axiosPost('/plu_order/update_store_ratio', data);
}

// 请购单确认导入数据
export function handlePluOrderImport(data) {
  return axiosPost('/plu_order/import_plu_list', data);
}

/**
 * ----------------------------- 人员权限控制相关接口 -----------------------------
 */

// 人员清单接口
export function handleUserLists(data) {
  return axiosPost('/user/page', data);
}

// 添加人员接口
export function handleAddUser(data) {
  return axiosPost('/user/add', data);
}

// 删除人员接口
export function handleDeleteUser(data) {
  return axiosPost('/user/delete', data);
}

// 更新人员接口
export function handleUpdateUser(data) {
  return axiosPost('/user/update', data);
}
