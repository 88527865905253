<template>
  <div>
    <div style="color:dodgerblue; text-align: center"><h1>APP界面预览</h1><p>首页任务列表</p></div>
    <GroupItem :list="list" type="summary" />
  </div>
</template>

<script>
import GroupItem from '../GroupItem';
export default {
  name: "AppWebPreview",
  components: { GroupItem },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: "summary"
    },
    title: {
      type: String,
      default: "首页任务列表"
    }
  },
  data() {
    return {
    }
  }
}

</script>
<style lang="scss" scoped src='@/style/appTask/groupItem.scss'></style>
