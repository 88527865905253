var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "明细", name: "detail" } },
            [_c("Detail", { on: { "cell-click": _vm.handleDetailCellClick } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "sku详情", name: "skuDetail" } },
            [_c("SkuDetail", { ref: "skuDetail" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }