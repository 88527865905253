import moment from "moment/moment";

let time = new Date();
let currentYear = time.getFullYear();
let currentMonth = time.getMonth() + 1;

let planYear = currentYear;
let preYear = currentYear;
let planMonth = currentMonth + 1;
let preMonth = currentMonth - 1;
if (planMonth > 12) {
  planMonth = planMonth - 12;
  planYear = planYear + 1;
}
if (preMonth <= 0) {
  preYear = preYear - 1;
  preMonth = 12;
}

const currentYM = currentYear + "" + (currentMonth < 10 ? "0" + currentMonth: currentMonth);
const planYM = planYear + "" + (planMonth < 10 ? "0" + planMonth : planMonth);
const preYm = preYear + "" + (preMonth < 10 ? "0" + preMonth : preMonth);

const currentShortYM = (currentMonth < 10 ? "0" + currentMonth: currentMonth);
const planShortYM = (planMonth < 10 ? "0" + planMonth : planMonth);
const preShortYm = (preMonth < 10 ? "0" + preMonth : preMonth);

const filialeOrdCheckHead = [
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '品名', prop: 'plu_name', minWidth: '150px'},
  {label: '等级', prop: 'grade', minWidth: '60px'},
  {label: '近6月均', prop: 'hq_pre_last_six_month_avg_sale_count_box', minWidth: '80px'},
  {label: '近3月月均', prop: 'hq_pre_last_three_month_avg_sale_count_box', minWidth: '80px'},
  {label: currentShortYM + '月底预计库存', prop: 'hq_last_month_store_count_box', minWidth: '120px'},
  {label: currentShortYM + '月底预计库存系数', prop: 'hq_last_month_store_ratio', minWidth: '140px'},
  {label: '分公司当前库存', prop: 'bo_last_month_stock_count_box', minWidth: '120px'},
  {label: '分公司当前库存系数', prop: 'bo_last_month_stock_ratio', minWidth: '130px'},

  {label: '客订量', prop: 'origin_bo_kd_plu_count_box', minWidth: '70px'},
  {label: '订单系数', prop: 'order_ratio', minWidth: '80px'},
  // {label: currentYM + '月销量', prop: 'hq_last_month_sale_count_box', minWidth: '70px'},
  {label: '调整后客订量', prop: 'confirm_bo_kd_plu_count_box', minWidth: '100px'},

  {label: '赛道', prop: 'race_name', minWidth: '60px'},
  {label: '大类', prop: 'main_category_name', minWidth: '80px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '80px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '110px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '110px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px'},
  {label: '料号', prop: 'material_code', minWidth: '140px'},
  {label: '年月', prop: 'order_month', minWidth: '65px'},
];

const classifyConfigHead = [
  {label: '赛道', prop: 'race_name', minWidth: '100px'},
  {label: '大类', prop: 'main_category_name', minWidth: '100px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '100px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '110px'},
  // 11月品类目标设定系数
  {label: 'S', prop: 'current_store_ratio_s', minWidth: '50px'},
  {label: 'A+', prop: 'current_store_ratio_a_plus', minWidth: '50px'},
  {label: 'A', prop: 'current_store_ratio_a', minWidth: '50px'},
  {label: 'B+', prop: 'current_store_ratio_b_plus', minWidth: '50px'},
  {label: 'B', prop: 'current_store_ratio_b', minWidth: '50px'},
  {label: 'C+', prop: 'current_store_ratio_c_plus', minWidth: '50px'},
  {label: 'C', prop: 'current_store_ratio_c', minWidth: '50px'},
  {label: 'C-', prop: 'current_store_ratio_c_minus', minWidth: '50px'},
  {label: 'N3', prop: 'current_store_ratio_n', minWidth: '50px'},
  {label: 'N', prop: 'current_store_ratio_n', minWidth: '50px'},
  {label: 'C2', prop: 'current_store_ratio_c2', minWidth: '50px'},
  {label: 'T', prop: 'current_store_ratio_t', minWidth: '50px'},
  {label: 'D', prop: 'current_store_ratio_d', minWidth: '50px'},
  {label: 'D1', prop: 'current_store_ratio_d1', minWidth: '50px'},
  {label: 'D2', prop: 'current_store_ratio_d2', minWidth: '50px'},
  {label: 'D3', prop: 'current_store_ratio_d3', minWidth: '50px'},
  {label: 'D4', prop: 'current_store_ratio_d4', minWidth: '50px'},
  {label: 'D5', prop: 'current_store_ratio_d5', minWidth: '50px'},
  {label: 'DL', prop: 'current_store_ratio_dl', minWidth: '50px'},
  {label: 'DT', prop: 'current_store_ratio_dt', minWidth: '50px'},
  {label: 'E', prop: 'current_store_ratio_e', minWidth: '50px'},
  {label: 'Q', prop: 'current_store_ratio_q', minWidth: '50px'},
  {label: 'W', prop: 'current_store_ratio_w', minWidth: '50px'},

  // 12月品类库存系数(根据设定值计算)
  {label: 'S', prop: 'plan_month_store_ratio_s', minWidth: '50px'},
  {label: 'A+', prop: 'plan_month_store_ratio_a_plus', minWidth: '50px'},
  {label: 'A', prop: 'plan_month_store_ratio_a', minWidth: '50px'},
  {label: 'B+', prop: 'plan_month_store_ratio_b_plus', minWidth: '50px'},
  {label: 'B', prop: 'plan_month_store_ratio_b', minWidth: '50px'},
  {label: 'C+', prop: 'plan_month_store_ratio_c_plus', minWidth: '50px'},
  {label: 'C', prop: 'plan_month_store_ratio_c', minWidth: '50px'},
  {label: 'C-', prop: 'plan_month_store_ratio_c_minus', minWidth: '50px'},
  {label: 'N3', prop: 'plan_month_store_ratio_n3', minWidth: '50px'},
  {label: 'N', prop: 'plan_month_store_ratio_n', minWidth: '50px'},
  {label: 'C2', prop: 'plan_month_store_ratio_c2', minWidth: '50px'},
  {label: 'T', prop: 'plan_month_store_ratio_t', minWidth: '50px'},
  {label: 'D', prop: 'plan_month_store_ratio_d', minWidth: '50px'},
  {label: 'D1', prop: 'plan_month_store_ratio_d1', minWidth: '50px'},
  {label: 'D2', prop: 'plan_month_store_ratio_d2', minWidth: '50px'},
  {label: 'D3', prop: 'plan_month_store_ratio_d3', minWidth: '50px'},
  {label: 'D4', prop: 'plan_month_store_ratio_d4', minWidth: '50px'},
  {label: 'D5', prop: 'plan_month_store_ratio_d5', minWidth: '50px'},
  {label: 'DL', prop: 'plan_month_store_ratio_dl', minWidth: '50px'},
  {label: 'DT', prop: 'plan_month_store_ratio_dt', minWidth: '50px'},
  {label: 'E', prop: 'plan_month_store_ratio_e', minWidth: '50px'},
  {label: 'Q', prop: 'plan_month_store_ratio_q', minWidth: '50px'},
  {label: 'W', prop: 'plan_month_store_ratio_w', minWidth: '50px'},

  // 12月品类目标设定系数
  {label: 'S', prop: 'store_ratio_aim_s', minWidth: '90px'},
  {label: 'A+', prop: 'store_ratio_aim_a_plus', minWidth: '90px'},
  {label: 'A', prop: 'store_ratio_aim_a', minWidth: '90px'},
  {label: 'B+', prop: 'store_ratio_aim_b_plus', minWidth: '90px'},
  {label: 'B', prop: 'store_ratio_aim_b', minWidth: '90px'},
  {label: 'C+', prop: 'store_ratio_aim_c_plus', minWidth: '90px'},
  {label: 'C', prop: 'store_ratio_aim_c', minWidth: '90px'},
  {label: 'C-', prop: 'store_ratio_aim_c_minus', minWidth: '50px'},
  {label: 'N3', prop: 'store_ratio_aim_n', minWidth: '50px'},
  {label: 'N', prop: 'store_ratio_aim_n', minWidth: '50px'},
  {label: 'C2', prop: 'store_ratio_aim_c2_t', minWidth: '50px'},
  {label: 'T', prop: 'store_ratio_aim_c2_t', minWidth: '50px'},
  {label: 'D', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'D1', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'D2', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'D3', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'D4', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'D5', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'DL', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'DT', prop: 'store_ratio_aim_d', minWidth: '50px'},
  {label: 'E', prop: 'store_ratio_aim_c2_t', minWidth: '50px'},
  {label: 'Q', prop: 'store_ratio_aim_c2_t', minWidth: '50px'},
  {label: 'W', prop: 'store_ratio_aim_c2_t', minWidth: '50px'},
];

const coefOfInventSettingHead = [
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '品名', prop: 'plu_name', minWidth: '150px'},
  {label: '等级', prop: 'grade', minWidth: '60px'},
  {label: '近6月月均', prop: 'hq_pre_last_six_month_avg_sale_count_box', minWidth: '80px'},
  {label: currentShortYM + '月底总部库存', prop: 'hg_last_month_store_count_box', minWidth: '120px'},
  {label: currentShortYM + '月底总部库存系数', prop: 'hg_last_month_store_ratio', minWidth: '140px'},
  {label: '分公司客订', prop: 'bo_kd_plu_sale_count_box', minWidth: '120px'},
  {label: planShortYM + '月已下量', prop: 'hg_old_plu_previous_put_count_box', minWidth: '120px'},
  {label: '库存系数上限', prop: 'hg_store_ratio_up', minWidth: '120px'},
  {label: '库存系数下限', prop: 'hg_store_ratio_down', minWidth: '120px'},
  {label: '库存系数目标', prop: 'hg_store_ratio_aim', minWidth: '120px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px'},
  {label: '料号', prop: 'material_code', minWidth: '140px'},
  {label: '生产类型', prop: 'prod_type', minWidth: '80px'},
  {label: '年月', prop: 'plan_month', minWidth: '65px'},
  {label: '是否使用系统默认值', prop: 'use_default', minWidth: '150px'},
];

const coefficientTemplateSampleHead = [
  {label: '年月', prop: 'plan_month', minWidth: '65px'},
  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '库存系数上限', prop: 'hg_store_ratio_up', minWidth: '80px'},
  {label: '库存系数下限', prop: 'hg_store_ratio_down', minWidth: '80px'},
  {label: '库存系数目标', prop: 'hg_store_ratio_aim', minWidth: '80px'}
];

const minimumOrderAdjustHead = [
  {label: '货号', prop: 'cargono', minWidth: '140px'},
  {label: '品名', prop: 'plu_name', minWidth: '150px'},
  {label: '零售价', prop: 'price', minWidth: '100px'},
  {label: '起订量', prop: 'min_order_count_box', minWidth: '100px'},
  {label: '等级', prop: 'grade', minWidth: '100px'},
  {label: '近6月月均', prop: 'hq_pre_last_six_month_avg_sale_count_box', minWidth: '100px'},
  {label: currentShortYM + '月底库存', prop: 'hg_last_month_store_count_box', minWidth: '120px'},
  {label: currentShortYM + '月底库存系数', prop: 'hq_last_month_store_ratio', minWidth: '120px'},
  {label: '分公司当前库存', prop: 'bo_last_month_stock_count_box', minWidth: '120px'},
  {label: '分公司当前库存系数', prop: 'bo_last_month_stock_ratio', minWidth: '130px'},
  {label: planShortYM + '月系统欠量', prop: 'hq_this_month_ql_box', minWidth: '130px'},
  {label: '分公司客订', prop: 'bo_kd_plu_count_box', minWidth: '130px'},
  {label: planShortYM + '月合计订单', prop: 'hq_this_month_total_count_box', minWidth: '130px'},
  {label: planShortYM + '月已下量', prop: 'hq_old_plu_previous_put_count_box', minWidth: '130px'},

  {label: '理论请购量', prop: 'confirm_theory_shipment_count_box', minWidth: '120px'},
  {label: '初始请购量', prop: 'theory_shipment_count_box', minWidth: '120px'},
  {label: planShortYM + '月底预计库存', prop: 'hq_store_count_box_if_custom', minWidth: '120px'},
  {label: planShortYM + '月底预计库存系数(近6月月均)', prop: 'hq_store_ratio_if_custom_six_month', minWidth: '200px'},
  {label: planShortYM + '月底预计库存系数(近3月月均)', prop: 'hq_store_ratio_if_custom_three_month', minWidth: '200px'},

  {label: '起订量-' + planShortYM + '月底预计库存', prop: 'hq_store_count_box_if_min_order', minWidth: '200px'},
  {label: '起订量-' + planShortYM + '月底预计库存系数', prop: 'hg_store_ratio_if_min_order', minWidth: '200px'},
  {label: '置零-' + planShortYM + '月底预计库存', prop: 'hq_store_count_box_if_zero_order', minWidth: '180px'},
  {label: '置零-' + planShortYM + '月底预计库存系数', prop: 'hg_store_ratio_if_zero_order', minWidth: '180px'},

  {label: '客订家数', prop: 'bo_last_month_order_count', minWidth: '100px'},
  {label: '订单系数', prop: 'order_ratio', minWidth: '100px'},

  {label: '状态', prop: 'flow_status', minWidth: '100px'},
  {label: '操作人', prop: 'update_by', minWidth: '100px'},
  {label: '赛道', prop: 'race_name', minWidth: '100px'},
  {label: '大类', prop: 'main_category_name', minWidth: '100px'},
  {label: '中类', prop: 'mid_category_name', minWidth: '100px'},
  {label: '小类', prop: 'sub_category_name', minWidth: '110px'},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '110px'},

  {label: '商品编码', prop: 'plu_code', minWidth: '80px'},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px'},
  {label: '料号', prop: 'material_code', minWidth: '140px'},
  {label: '生产类型', prop: 'prod_type', minWidth: '80px'},

  {label: '年月', prop: 'plan_month', minWidth: '65px'},
  {label: '理论请购量调整', prop: 'adjust_type', minWidth: '260px'},

]

const buyingReqConfirmHead = [
  {label: '序号', type: 'index', minWidth: '50px', fixed: 'left', showOverflowTooltip: true},
  {label: '货号', prop: 'cargono', minWidth: '140px', fixed: 'left', showOverflowTooltip: true},
  {label: '品名', prop: 'plu_name', minWidth: '150px', showOverflowTooltip: true},
  {label: '零售价', prop: 'price', minWidth: '60px', showOverflowTooltip: true},
  {label: '等级', prop: 'grade', minWidth: '60px', showOverflowTooltip: true},
  {label: '近6月月均', prop: 'hq_pre_last_six_month_avg_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: currentShortYM + '月底预计库存', prop: 'hg_last_month_store_count_box', minWidth: '110px', showOverflowTooltip: true},
  {label: currentShortYM + '月底预计库存系数', prop: 'hq_last_month_stock_ratio', minWidth: '140px', showOverflowTooltip: true}, // 上月底预计库存系数（10月）
  {label: '分公司当前库存', prop: 'bo_last_month_stock_count_box', minWidth: '110px', showOverflowTooltip: true}, // 分公司库存
  {label: '分公司当前库存系数', prop: 'bo_last_month_stock_ratio', minWidth: '130px', showOverflowTooltip: true}, // 分公司库存系数
  {label: planShortYM + '月系统欠量', prop: 'hq_this_month_ql_box', minWidth: '110px', showOverflowTooltip: true},
  {label: planShortYM + '月月单客订', prop: 'confirm_bo_kd_plu_count_box', minWidth: '110px', showOverflowTooltip: true},
  {label: '客订家数', prop: 'bo_last_month_order_count', minWidth: '110px', showOverflowTooltip: true}, // 分公司客订家数
  {label: planShortYM + '月合计客订', prop: 'hq_this_month_total_count_box', minWidth: '110px', showOverflowTooltip: true}, // 合计订单=分公司客订+当月欠量
  {label: planShortYM + '月已下量', prop: 'total_previous_put_count_box', minWidth: '120px', showOverflowTooltip: true},
  {label: planShortYM + '月理论请购量', prop: 'theory_shipment_count_box', minWidth: '120px', showOverflowTooltip: true},
  {label: planShortYM + '月底预计库存', prop: 'prepare_shipment_count_box', minWidth: '120px', showOverflowTooltip: false},
  {label: planShortYM + '月底预计库存系数(近6月月均)', prop: 'hq_this_month_stock_ratio_of_six_avg_sale_count', minWidth: '200px', showOverflowTooltip: true},
  {label: planShortYM + '月底预计库存系数(近3月月均)', prop: 'hq_this_month_stock_ratio_of_three_avg_sale_count', minWidth: '200px', showOverflowTooltip: true},
  {label: '请购上限', prop: 'prod_count_up_box', minWidth: '100px', showOverflowTooltip: true},
  {label: '库存上限', prop: 'store_ratio_up_count_box', minWidth: '100px', showOverflowTooltip: true}, // 库存系数上限对应库存/箱
  {label: '库存系数上限', prop: 'store_ratio_up', minWidth: '100px', showOverflowTooltip: true},
  {label: '请购下限', prop: 'prod_count_down_box', minWidth: '100px', showOverflowTooltip: true},
  {label: '库存下限', prop: 'store_ratio_down_count_box', minWidth: '100px', showOverflowTooltip: true}, // 库存系数下限对应库存/箱
  {label: '库存系数下限', prop: 'store_ratio_down', minWidth: '100px', showOverflowTooltip: true},
  {label: '库存系数目标', prop: 'store_ratio_aim', minWidth: '100px', showOverflowTooltip: true},
  {label: '库存目标', prop: 'store_ratio_aim_count_box', minWidth: '100px', showOverflowTooltip: true}, // 库存系数目标对应库存/箱
  {label: preShortYm + '月底库存', prop: 'hq_before_last_month_stock_count_box', minWidth: '100px', showOverflowTooltip: true}, // 上上月库存箱数（9月）
  {label: currentShortYM + '月已销', prop: 'hq_last_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true}, // 上月已销量（10月）
  {label: currentShortYM + '月欠量', prop: 'hq_last_month_ql_count_box', minWidth: '100px', showOverflowTooltip: true}, // 上月欠量（10月）
  {label: currentShortYM + '月结余量', prop: 'last_month_carryover_count_box', minWidth: '100px', showOverflowTooltip: true}, // 上月结余量（10月）
  {label: currentShortYM + '月排产量', prop: 'hq_last_month_pc_count_box', minWidth: '100px', showOverflowTooltip: true}, // 上月排产量（10月）
  {label: currentShortYM + '月生产未转单请购量', prop: 'hq_this_mth_no_order_zzl_box', minWidth: '180px', showOverflowTooltip: true},
  {label: planShortYM + '月生产未转单请购量', prop: 'hq_next_mth_no_order_zzl_box', minWidth: '180px', showOverflowTooltip: true},
  {label: '当前累计' + currentShortYM + '月在途', prop: 'bo_curr_zt_box', minWidth: '120px', showOverflowTooltip: true},
  {label: '当前库存', prop: 'hq_curr_stock_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: '起订量', prop: 'min_order_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: '返工库存', prop: 'hq_rework_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: '统计工艺', prop: 'pen_craft_total', minWidth: '100px', showOverflowTooltip: true},
  {label: '模号', prop: 'mould_code', minWidth: '100px', showOverflowTooltip: true},
  {label: '是否预淘汰', prop: 'dead_sku_type', minWidth: '120px', showOverflowTooltip: true},
  {label: '超期欠量', prop: 'hq_unsale_count_box', minWidth: '120px', showOverflowTooltip: true},

  {label: moment().subtract(2, 'months').format("YYYYMM") + '销量', prop: 'hq_last2_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(3, 'months').format("YYYYMM") + '销量', prop: 'hq_last3_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(4, 'months').format("YYYYMM") + '销量', prop: 'hq_last4_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(5, 'months').format("YYYYMM") + '销量', prop: 'hq_last5_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(6, 'months').format("YYYYMM") + '销量', prop: 'hq_last6_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(7, 'months').format("YYYYMM") + '销量', prop: 'hq_last7_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(8, 'months').format("YYYYMM") + '销量', prop: 'hq_last8_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(9, 'months').format("YYYYMM") + '销量', prop: 'hq_last9_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(10, 'months').format("YYYYMM") + '销量', prop: 'hq_last10_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(11, 'months').format("YYYYMM") + '销量', prop: 'hq_last11_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},
  {label: moment().subtract(12, 'months').format("YYYYMM") + '销量', prop: 'hq_last12_month_sale_count_box', minWidth: '100px', showOverflowTooltip: true},

  {label: '赛道', prop: 'race_name', minWidth: '60px', showOverflowTooltip: true},
  {label: '大类', prop: 'main_category_name', minWidth: '80px', showOverflowTooltip: true},
  {label: '中类', prop: 'mid_category_name', minWidth: '120px', showOverflowTooltip: true},
  {label: '小类', prop: 'sub_category_name', minWidth: '150px', showOverflowTooltip: true},
  {label: '统计品类', prop: 'stats_category_name', minWidth: '150px', showOverflowTooltip: true},
  {label: '商品编码', prop: 'plu_code', minWidth: '80px', showOverflowTooltip: true},
  {label: '型号', prop: 'plu_mnemonic_code', minWidth: '90px', showOverflowTooltip: true},
  {label: '料号', prop: 'material_code', minWidth: '140px', showOverflowTooltip: true},
  {label: '箱规', prop: 'bottom_spec', minWidth: '60px', showOverflowTooltip: true}, // 箱规
  {label: '生产类型', prop: 'prod_type', minWidth: '120px', showOverflowTooltip: true},
  {label: '状态', prop: 'flow_status', minWidth: '80px', showOverflowTooltip: true},
  {label: '操作人', prop: 'update_by', minWidth: '80px', showOverflowTooltip: true},
  {label: '锁状态', prop: 'lock_state', minWidth: '80px', showOverflowTooltip: true},
  {label: '年月', prop: 'order_month', minWidth: '65px', showOverflowTooltip: true},
  {label: '操作', prop: '-', minWidth: '140px', showOverflowTooltip: true},

]


const buyingReqImportSampleHead = [
  {label: '年月', prop: 'order_month', minWidth: '65px'},
  {label: '料号', prop: 'material_code', minWidth: '140px'},
  {label: '理论出货量', prop: 'theory_shipment_count_box', minWidth: '80px'}
]

const userRoleHead = [
  {label: '员工ID', prop: 'operator', minWidth: '65px'},
  {label: '姓名', prop: 'real_name', minWidth: '100px'},
  {label: '关联角色', prop: 'role_name_list', minWidth: '100px'},
  {label: '状态', prop: 'statusText', minWidth: '100px'},
  {label: '更新时间', prop: 'update_time', minWidth: '100px'},
  {label: '操作', prop: '-', minWidth: '100px'},
]

export {
  filialeOrdCheckHead,                // 销售中心 -- 分公司客订单检核 -- 表头
  classifyConfigHead,                 // 销售中心 -- 库存系数设定 -- 分类设定
  coefOfInventSettingHead,            // 销售中心 -- 库存系数设定 -- 在线填写 -- 表头
  coefficientTemplateSampleHead,      // 销售中心 -- 库存系数设定 -- 在线填写 -- 表单上传示例 -- 表头
  minimumOrderAdjustHead,             // 销售中心 -- 起订量调整 -- 表头
  buyingReqConfirmHead,               // 销售中心 -- 请购单确认 -- 表头
  buyingReqImportSampleHead,               // 销售中心 -- 请购单确认 -- 表单上传示例 -- 表头
  userRoleHead,                       // 销售中心 -- 人员清单 -- 表头
}
