<template>
  <div>
    <el-upload
      :class="!alwaysShowButton && fileList.length>=limit ? 'hide': ''"
      ref="upload"
      :before-upload="beforeUpload"
      :http-request="handleHttpRequest"
      :headers="uploadHeaders"
      :before-remove="clearFiles"
      :limit="limit"
      :disabled="disabled"
      multiple
      action=""
      :on-exceed="handleExceed"
      :file-list="fileList"
      v-bind="$attrs"
      v-on="$listeners"
      :show-file-list="showFiles"
      :on-preview="handlePictureCardPreview"
    >
      <el-button size="mini" :type="buttonType">{{ buttonName }}</el-button>
      <template slot="tip">
        <div v-if="tipMessage && (alwaysShowButton || fileList.length<limit)" class="el-upload__tip" v-html="tipMessage"></div>
        <div v-if="tips && (alwaysShowButton || fileList.length<limit)" class="el-upload__tip" v-html="tips"></div>
      </template>
    </el-upload>

    <draggable v-if="!showFiles" v-model="fileList" @update="draggableUpdate">
      <transition-group class="uploader">
        <div v-for="(item,index) in fileList" :key="item.url" class="upload-list">
          <img v-if="item.url" style="height: 90px" :src="item.url">
          <div class="icon-container">
            <span v-if="item.url" @click="handleRemove(index)">
              <i class="el-icon-delete" style="margin-right:10px"></i>
            </span>
            <span v-if="item.url" class="el-upload-list__item-preview" @click="handlePreview(index)">
                  <i class="el-icon-zoom-in"></i>
                </span>
          </div>
        </div>

      </transition-group>
    </draggable>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import Client from '@/utils/client'
import {getSTSToken} from "@/api/appTask"
import { parseTime, removeBg } from "@/utils";
import draggable from "vuedraggable";
//将渲染的内容导出
export default{
  components: { draggable },
  props: {
    autoUpload: {
      type: Boolean,
      default: true
    },
    valueTo: {
      // 赋值到哪个对象
      type: String
    },
    limit: {
      type: Number,
      default: 1
    },
    acceptType: {
      type: Array,
      default: undefined
    },
    maxFileSize: {
      type: Number,
      default: 204800
    },
    minHeight: {
      type: Boolean,
      default: false
    },
    tips: {
      type: String
    },
    tipMessageShow: {
      type: Boolean,
      default: true
    },
    alwaysShowButton: {
      type: Boolean,
      default: true
    },
    buttonName: {
      type: String,
      default: "点击上传"
    },
    buttonType: {
      type: String,
      default: "primary"
    },
    showFiles: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      region: 'oss-cn-shanghai',
      bucket: 'mg-data-oss',//这里选择OSS容器
      percentage: 0,
      dataObj: {},
      expiration: '',
      fileList: [],
      uploadHeaders: {
        authorization: '*'
      },
      defaultAcceptType: [],
      disabled: false,
      urlPrefix: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/files/' + new Date().getFullYear() + new Date().getMonth() + new Date().getDay(),
      tipMessage: undefined,
      dialogImageUrl: '',
      dialogVisible: false,
    }
  },
  watch: {
    valueTo: {
      immediate: true,
      handler: function() {
        this.fileList = [];
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
          if (this.valueTo) {
            this.valueTo.split(',').forEach(e => {
              this.fileList.push({ name: e.substring(e.lastIndexOf('/') + 1), url:e});
            })
          }
        })
      }
    },
  },
  computed: {
  },
  created(){
    if (this.tipMessageShow) {
      this.tipMessage = '只能上传' + (this.acceptType ? this.acceptType.join(' '): this.defaultAcceptType.join(' ')) + '格式的文件'
      this.tipMessage += ', 单个文件最大' + (this.maxFileSize / 1024).toFixed(2) + 'MB'
    }
  },
  mounted(){
  },
  methods: {
    getDate(){
      const date = new Date(),
        year = date.getFullYear(),
        month = date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
        day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
        hh = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`,
        mm = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
      return `${year}${month}${day}${hh}${mm}`;
    },
    getAliToken(){ //获取Token
      return new Promise((resolve, reject) => {
        getSTSToken({"supplier":"ALI"}).then(res => {
          if (res.success) {
            const {expiration, accessKeyId, accessKeySecret, securityToken} = res.data;
            this.expiration = expiration;
            this.dataObj = {
              region: this.region,
              bucket: this.bucket,
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
              stsToken: securityToken,
              useFetch: true
            };
            resolve(true);
          } else {
            reject(false);
          }
        }).catch(err => {
          console.log(err);
          reject(false);
        })
      })
    },
    beforeUpload(file){
      const type = file.name.indexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.')) : undefined
      if (this.acceptType.indexOf(type.toLowerCase()) < 0) {
        this.$message.error('文件格式不支持')
        return false
      }
      const isOver = file.size <= this.maxFileSize * 1024
      if (!isOver) {
        this.$message.error('文件超过' + (this.maxFileSize/1024) + 'M')
        return false;
      }
      return new Promise((resolve, reject) => {
        this.getAliToken().then(response => {
          if (response) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(err => {
          reject(err);
        });
      })
    },
    async handleHttpRequest(option){ //上传OSS
      try {
        this.disabled = true;
        const client = Client(this.dataObj)
        let file = option.file;

        // 分片上传文件
        console.log("开始上传");
        console.log('上传中...')
        const dateDir =  parseTime(new Date(), '{y}{m}') + "/"
        await client.put('pdb-front/' + process.env.NODE_ENV + '/' + dateDir + new Date().getTime() + '_' + file.name, file, {
          progress: async function (p) {
            let e = {};
            e.percent = p * 100;
            option.onProgress(e)
          }
        }).then(({res}) => {
          console.log(res);
          if (res.statusCode === 200) {
            this.$message.success('上传成功')

            // this.$emit("update:valueTo",  res.requestUrls[0].replace("http://", "https://"));
            let files = []
            res.requestUrls.forEach(e => {
              e.replace("http://", "https://")
              files.push({ name: e.substring(e.lastIndexOf('/') + 1), url: e})
            });

            this.fileList = this.fileList.concat(files);
            this.$emit("update:valueTo",  this.fileList.map(e => e.url).join(','));
            this.disabled = false;
            // return res.requestUrls
          } else {
            this.disabled = false;
            option.onError('上传失败');
          }
        }).catch(error => {
          this.disabled = false;
          option.onError('上传失败');
        });

      } catch (error) {
        console.error(error);
        this.disabled = false;
        option.onError('上传失败');
      }
    },
    handleExceed(files, fileList) {
      const maxLength = this.limit
      this.$message.warning(`当前限制选择 ${maxLength} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    clearFiles(file, fileList) {
      console.log(file, fileList)
      this.fileList = fileList.filter(e => e.uid!==file.uid);
      this.$emit("update:valueTo", this.fileList.map(e => e.url).join(','));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(index) {
      this.fileList.splice(index, 1);
      this.$emit("update:valueTo", this.fileList.map(e => e.url).join(','));
    },
    handlePreview(index) {
      this.dialogImageUrl = this.fileList[index].url;
      this.dialogVisible = true;
    },
    draggableUpdate() {
      this.fileList.forEach((item, index) => {
        item.sortNum = index + 1
      })
      //过滤一下url为空的数据
      this.fileList = this.fileList.filter(item => {
        return item.url != ''
      })
      this.$emit("update:valueTo", this.fileList.map(e => e.url).join(','));
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .hide .el-upload--picture-card {
  display: none;
}

.uploader {
  display: flex;
  align-items: center;

  .upload-list {
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #c0ccda;
    position: relative;

    &:hover {
      .icon-container {
        display: block;
        transition: all 0.5s;
      }
    }

    .icon-container {
      position: absolute;
      display: none;
      transition: all 0.5s;
      width: 100%;
      line-height: 146px;
      color: #fff;
      font-size: 20px;
      border-radius: 8px;
      top: 0;
      background-color: rgba(0, 0, 0, .5);

      span {
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
