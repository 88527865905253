var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            filterable: "",
            remote: _vm.remote,
            clearable: _vm.clearable,
            placeholder: _vm.placeholder,
            "remote-method": (query) => _vm.remoteMethod(query),
            loading: _vm.valueLoading,
            "allow-create": _vm.allowCreate,
            multiple: _vm.multiple,
          },
          on: {
            change: function ($event) {
              return _vm.handleChange($event, "hand")
            },
          },
          model: {
            value: _vm.queryForm.value,
            callback: function ($$v) {
              _vm.$set(_vm.queryForm, "value", $$v)
            },
            expression: "queryForm.value",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.itemOptions, function (item) {
      return _c("el-option", {
        key: _vm.singleProps
          ? item
          : _vm.valueKey
          ? item[_vm.valueKey]
          : item[_vm.initProps.value],
        attrs: {
          label: _vm.singleProps
            ? item
            : _vm.fullLabel
            ? item[_vm.initProps.value] + "-" + item[_vm.initProps.label]
            : item[_vm.initProps.label],
          value: _vm.singleProps ? item : item[_vm.initProps.value],
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }