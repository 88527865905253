var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { staticClass: "full-width", attrs: { label: "选择参考指标" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", multiple: "", size: "mini" },
                  model: {
                    value: _vm.referenceQuota,
                    callback: function ($$v) {
                      _vm.referenceQuota = $$v
                    },
                    expression: "referenceQuota",
                  },
                },
                _vm._l(_vm.options.tags, function (e) {
                  return _c("el-option", {
                    key: e.tagId,
                    attrs: {
                      value: e.id,
                      label: e.tagName,
                      disabled: e.disabled,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }