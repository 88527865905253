var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "范围" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.orgRange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgRange", $$v)
                                },
                                expression: "queryForm.orgRange",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入标题关键字",
                        },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          on: {
                            change: () =>
                              (_vm.queryForm.position = "HomeNotice"),
                          },
                          model: {
                            value: _vm.queryForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "type", $$v)
                            },
                            expression: "queryForm.type",
                          },
                        },
                        _vm._l(_vm.typeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.queryForm.type === "notice"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "位置" } },
                        [
                          _vm.queryForm.type === "notice"
                            ? _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    "allow-create": "",
                                  },
                                  model: {
                                    value: _vm.queryForm.position,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "position", $$v)
                                    },
                                    expression: "queryForm.position",
                                  },
                                },
                                _vm._l(_vm.noticeOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.queryForm.queryDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "queryDate", $$v)
                          },
                          expression: "queryForm.queryDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit("add", undefined)
                },
              },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-remove", type: "primary" },
              on: { click: _vm.handleRemove },
            },
            [_vm._v("删除")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "90", fixed: "left" },
              }),
              _c("el-table-column", {
                attrs: { label: "标题", prop: "title" },
              }),
              _c("el-table-column", {
                attrs: { label: "类型", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.typeOptions.find((e) => e.value === row.type)
                                .label
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "范围", prop: "orgName" },
              }),
              _c("el-table-column", {
                attrs: { label: "位置", prop: "position" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.noticeOptions.find(
                                (e) => e.value === row.position
                              )
                                ? _vm.noticeOptions.find(
                                    (e) => e.value === row.position
                                  ).label
                                : row.position
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "开始时间", prop: "startDate", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "结束时间", prop: "endDate", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建者", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "250", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.orgRange == _vm.queryForm.orgRange || _vm.authOrgAll
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    _vm.handleEdit("view", _vm.deepClone(row))
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        row.orgRange == _vm.queryForm.orgRange || _vm.authOrgAll
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDel(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.saveDialog.titleMap[_vm.saveDialog.type],
            center: true,
            visible: _vm.saveDialog.visible,
            "append-to-body": "",
            width: "750px",
            "close-on-click-modal": false,
            "before-close": _vm.handleBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "saveFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                rules: _vm.saveDialog.rules,
                model: _vm.saveDialog.form,
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标题", prop: "title" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入标题",
                                },
                                model: {
                                  value: _vm.saveDialog.form.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.saveDialog.form, "title", $$v)
                                  },
                                  expression: "saveDialog.form.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "类型", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  on: {
                                    change: () =>
                                      (_vm.saveDialog.form.position =
                                        "HomeNotice"),
                                  },
                                  model: {
                                    value: _vm.saveDialog.form.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.saveDialog.form, "type", $$v)
                                    },
                                    expression: "saveDialog.form.type",
                                  },
                                },
                                _vm._l(_vm.typeOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "位置",
                                prop: _vm.saveDialog.form.id
                                  ? "position"
                                  : "positions",
                              },
                            },
                            [
                              _vm.saveDialog.form.id
                                ? _c(
                                    "div",
                                    [
                                      _vm.saveDialog.form.type === "banner"
                                        ? _c(
                                            "el-select",
                                            {
                                              model: {
                                                value:
                                                  _vm.saveDialog.form.position,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saveDialog.form,
                                                    "position",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saveDialog.form.position",
                                              },
                                            },
                                            _vm._l(
                                              _vm.positionOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                filterable: "",
                                                "allow-create": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.saveDialog.form.position,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saveDialog.form,
                                                    "position",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saveDialog.form.position",
                                              },
                                            },
                                            _vm._l(
                                              _vm.noticeOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _vm.saveDialog.form.type === "banner"
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: { multiple: "" },
                                              model: {
                                                value:
                                                  _vm.saveDialog.form.positions,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saveDialog.form,
                                                    "positions",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saveDialog.form.positions",
                                              },
                                            },
                                            _vm._l(
                                              _vm.positionOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                multiple: "",
                                                filterable: "",
                                                "reserve-keyword": "",
                                                "allow-create": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.saveDialog.form.positions,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saveDialog.form,
                                                    "positions",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saveDialog.form.positions",
                                              },
                                            },
                                            _vm._l(
                                              _vm.noticeOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "时间范围", prop: "rangeTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  "picker-options": _vm.pickerOptions,
                                  type: "datetimerange",
                                  format: "yyyy-MM-dd HH:mm:ss",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                model: {
                                  value: _vm.saveDialog.form.rangeTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.saveDialog.form,
                                      "rangeTime",
                                      $$v
                                    )
                                  },
                                  expression: "saveDialog.form.rangeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "范围", prop: "orgRanges" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { multiple: "", "collapse-tags": "" },
                                  model: {
                                    value: _vm.saveDialog.form.orgRanges,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form,
                                        "orgRanges",
                                        $$v
                                      )
                                    },
                                    expression: "saveDialog.form.orgRanges",
                                  },
                                },
                                _vm._l(_vm.authOrg, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.saveDialog.form.type === "banner"
                        ? _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "图片", prop: "img" } },
                                [
                                  _c("OssUpload", {
                                    ref: "ossUpload",
                                    attrs: {
                                      "value-to": _vm.saveDialog.form.img,
                                      "list-type": "picture-card",
                                      maxFileSize: 500,
                                      tipMessageShow: false,
                                      "accept-type": [
                                        ".jpg",
                                        ".jpeg",
                                        ".gif",
                                        ".png",
                                      ],
                                    },
                                    on: {
                                      "update:valueTo": function ($event) {
                                        return _vm.$set(
                                          _vm.saveDialog.form,
                                          "img",
                                          $event
                                        )
                                      },
                                      "update:value-to": function ($event) {
                                        return _vm.$set(
                                          _vm.saveDialog.form,
                                          "img",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "链接", prop: "href" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  clearable: "",
                                  placeholder:
                                    "请输入链接地址。通知类型仅首页支持跳转",
                                },
                                model: {
                                  value: _vm.saveDialog.form.href,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.saveDialog.form, "href", $$v)
                                  },
                                  expression: "saveDialog.form.href",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                position: "absolute",
                bottom: "5px",
                width: "90%",
                "background-color": "white",
                height: "30px",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.saveDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }