<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="商品编码">
          <el-input v-model="queryForm.skuCode" size="mini" placeholder="商品编码" />
        </el-form-item>
        <el-form-item label="商品型号">
          <el-input v-model="queryForm.skuModel" size="mini" placeholder="商品型号" />
        </el-form-item>
        <el-form-item label="SPU名称">
          <el-input v-model="queryForm.spuName" size="mini" placeholder="SPU名称" />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出商品</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="openAdd">添加商品</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
    >
      <el-table-column label="商品编码" prop="skuCode" width="100" />
      <el-table-column label="SKU名称" prop="skuName" width="300" />
      <el-table-column label="商品型号" prop="skuModel" width="100" />
      <el-table-column label="SPU名称" prop="spuName" />
      <el-table-column label="操作" width="40">
        <template #default="{ row }">
          <div>
            <el-row>
              <el-col v-if="orgAllAuth || row.orgCode!=='ALL'" :span="6"><el-button size="mini" type="text" style="border: 0px;" @click="handleRemove(row)">删除</el-button></el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog title="添加商品" :visible.sync="addDialog.visible" append-to-body width="400px">
      <el-form ref="addDialog" size="mini" inline :rules="addDialog.rules" label-width="160px" :model="addDialog.form" class="common-form">
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="商品编码" prop="skuCode" label-width="200px">
              <el-input v-model="addDialog.form.skuCode" size="mini" placeholder="请输入商品编码" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="submitAddSku">确定</el-button>
        <el-button type="primary" size="mini" @click="addDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  dictSkuModels,
  deleteDcItem,
  getNoStockOrderDcItems, exportDcItem, addDcItem
} from "@/api/appTask";
import {Notification} from "element-ui";

export default {
  name: "NoStockOrderDcItems",
  components: { Pagination },
  props: {
    param: {
      type: Object,
      default() {
        return {
          orgCode: undefined,
          dcCode: undefined,
        }
      }
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
      },
      dictSelect,
      dictSkuModels,
      queryForm: {
        current: 1,
        size: 50,
        orgCode: undefined,
        dcCode: undefined,
        skuCode: undefined,
        skuModel: undefined,
        spuName: undefined
      },
      queryDate: [],
      loading: false,
      dictParam: {},
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      editDialog: {
        visible: false,
        disabled: false,
        buttonLoading: false,
        dialogTitle: {
          edit: '编辑',
          add: '新增'
        },
        id: undefined,
        type: 'edit',
        form: {
          skuModel: undefined,
          features: undefined,
        },
        rules: {
          skuModel: [{required: true, message: " ", trigger: "change"}],
          features: [{required: true, message: " ", trigger: "change"}],
        },
      },
      orgAllAuth: false,
      orgOptions: [],
      currentRow: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      addDialog: {
        visible: false,
        form: {
          orgCode: undefined,
          dcCode: undefined,
          skuCode: undefined,
        },
        rules: {
          skuCode: [{ required: true, message: " ", trigger: "change" }],
        },
      }
    };
  },
  computed: {
  },
  watch: {
    timestamp: {
      handler() {
        this.queryForm.orgCode = this.param.orgCode;
        this.queryForm.dcCode = this.param.dcCode;
        this.handleFilter();
      },
      immediate: true
    }
  },
  created() {
    this.queryForm.orgCode = this.param.orgCode;
    this.queryForm.dcCode = this.param.dcCode;
    this.handleFilter();
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      getNoStockOrderDcItems(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this[dict] = {oneCategory: this.queryForm.oneGradeCategoryName}
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleExport() {
      if (!this.queryForm.orgCode || !this.queryForm.dcCode) {
        Notification.warning("查询条件错误");
        return;
      }
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportDcItem(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', `无库存下单配送中心商品导出(${this.queryForm.orgCode}-${this.queryForm.dcCode}).xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleRemove(row) {
      this.$confirm('确定删除该商品？', {
        center: true
      }).then(() => {
        deleteDcItem(row).then(res => {
          if (res.code  == 200) {
            Notification.success("操作成功");
            this.editDialog.visible = false;
            this.handleFilter();
          }
        })
      }).catch(() => {});
    },
    openAdd() {
      this.addDialog.form.skuCode = undefined;
      this.addDialog.form.orgCode = this.queryForm.orgCode;
      this.addDialog.form.dcCode = this.queryForm.dcCode;
      this.addDialog.visible = true;
    },
    submitAddSku() {
      this.$refs.addDialog.validate(valid => {
        if (valid) {
          addDcItem(this.addDialog.form).then(res => {
            this.addDialog.visible = false;
            if (res && res.code == 200) {
              Notification.success("操作成功");
              this.handleFilter();
            }
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
.skuUrlThum {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  ::v-deep {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
