var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "mg-pagination-table",
        _vm.isFullScreen ? "mg-table--full-screen" : "",
      ],
    },
    [
      _c("div", { staticClass: "mg-table-topbar" }, [
        _c("div", { staticClass: "topbar-left" }, [_vm._t("topleft")], 2),
        _c(
          "div",
          { staticClass: "topbar-right" },
          [
            _vm._t("topright"),
            _vm.searchable
              ? [
                  !_vm.showSearchBar
                    ? _c("el-button", {
                        attrs: {
                          icon: "iconfont mg-icon_sousuo_wubiankuang",
                          type: "icon",
                        },
                        on: { click: _vm.handleShowSearch },
                      })
                    : _c("el-button", {
                        attrs: {
                          plain: "",
                          icon: "iconfont mg-icon_sousuo_wubiankuang",
                          type: "icon",
                        },
                        on: { click: _vm.handleShowSearch },
                      }),
                ]
              : _vm._e(),
            _vm.configurable
              ? _c("el-button", {
                  attrs: {
                    icon: "iconfont mg-icon_shezhi_wubiankuang",
                    type: "icon",
                  },
                  on: {
                    click: function ($event) {
                      _vm.visible = true
                    },
                  },
                })
              : _vm._e(),
            _vm.isConfigFullScreen
              ? _c("el-button", {
                  staticClass: "mg-icon-full-screen",
                  attrs: { icon: "el-icon-full-screen", type: "icon" },
                  on: { click: _vm.handleFullScreen },
                })
              : _vm._e(),
            _vm.showMiniPagination
              ? _c("mg-mini-pagination", {
                  attrs: {
                    page: _vm.page,
                    "total-page": _vm.totalPage,
                    total: _vm.total,
                  },
                  on: { "page-change": _vm.handlePageChange },
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "mgTable",
              class: [
                "mg-customer-table-container",
                { "mg-customer-table--header": _vm.showSearchBar },
                _vm.isHeaderMiddle ? "mg-customer-table-headerAlign" : "",
              ],
              attrs: {
                size: "small",
                "empty-text": "暂无数据",
                "span-method": _vm.cellMerge,
                border: "",
                data: _vm.initialData || _vm.list,
                "max-height": _vm.maxHeight,
                height: _vm.isFullScreen
                  ? "calc(100vh - 90px)"
                  : _vm.$attrs.height,
                "row-class-name": _vm.getRowClassName,
              },
              on: {
                "header-dragend": _vm.handleHeaderDragEnd,
                "row-click": _vm.handleRowClick,
              },
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._t("selection"),
          _vm.$slots.empty
            ? _c(
                "div",
                { attrs: { slot: "empty" }, slot: "empty" },
                [_vm._t("empty")],
                2
              )
            : _vm._e(),
          _vm.isUseDefaultColumns
            ? [
                _vm._l(_vm.columns, function (column) {
                  return [
                    column.children && column.children.length > 0
                      ? _c("mg-table-column", {
                          key: column.label,
                          attrs: {
                            tableColumns: column,
                            "filter-method": _vm.filterHandler,
                          },
                        })
                      : column.slot
                      ? _c("el-table-column", {
                          key: column.prop,
                          attrs: {
                            label: column.label,
                            width: column.width,
                            "min-width": column.minWidth,
                            align: column.align || "center",
                            prop: column.prop,
                            sortable: column.sortable,
                            "sort-by": column.sortBy,
                            fixed: column.fixed,
                            "show-overflow-tooltip": "",
                            "filter-method": _vm.filterHandler,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scoped) {
                                  return [
                                    _vm._t(column.slot, null, null, scoped),
                                  ]
                                },
                              },
                              column.headerSlot
                                ? {
                                    key: "header",
                                    fn: function (scoped) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "mg-customer-table-header-cell-label",
                                              column.showHeaderAsterisk
                                                ? "mg-header-asterisk"
                                                : "",
                                            ],
                                          },
                                          [
                                            column.showHeaderAsterisk
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#e60012",
                                                      "margin-top": "4px",
                                                    },
                                                  },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                            _c("span", [
                                              _vm._v(_vm._s(column.label)),
                                            ]),
                                          ]
                                        ),
                                        _vm.showSearchBar
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mg-customer-table-header-cell-body",
                                              },
                                              [
                                                _vm._t(
                                                  column.headerSlot,
                                                  null,
                                                  null,
                                                  scoped
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        })
                      : column.default
                      ? [_vm._t(column.prop)]
                      : _c(
                          "el-table-column",
                          _vm._b(
                            {
                              key: column.prop,
                              attrs: {
                                "show-overflow-tooltip": "",
                                filters: [],
                                index: _vm.getIndex,
                                "filter-method": _vm.filterHandler,
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  column.headerSlot
                                    ? {
                                        key: "header",
                                        fn: function (scoped) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mg-customer-table-header-cell-label",
                                              },
                                              [_vm._v(_vm._s(column.label))]
                                            ),
                                            _vm.showSearchBar
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mg-customer-table-header-cell-body",
                                                  },
                                                  [
                                                    _vm._t(
                                                      column.headerSlot,
                                                      null,
                                                      null,
                                                      scoped
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            },
                            "el-table-column",
                            column,
                            false
                          )
                        ),
                  ]
                }),
              ]
            : _vm._e(),
          [_vm._t("default")],
        ],
        2
      ),
      _vm.showPagination
        ? _c("el-pagination", {
            staticClass: "mg-large-pagination",
            attrs: {
              background: "",
              "current-page": _vm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.pagination.limit,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
      _c("ColumnConfig", {
        attrs: {
          visible: _vm.visible,
          columns: _vm.columns,
          appendToBody: _vm.appendToBody,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          "column-change": _vm.handleColumnChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }