var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "el-autocomplete",
      attrs: {
        "aria-haspopup": "listbox",
        role: "combobox",
        "aria-expanded": _vm.suggestionVisible,
        "aria-owns": _vm.id,
      },
    },
    [
      _c(
        "el-input",
        _vm._b(
          {
            ref: "input",
            on: {
              input: _vm.handleInput,
              change: _vm.handleChange,
              focus: _vm.handleFocus,
              blur: _vm.handleBlur,
              clear: _vm.handleClear,
            },
            nativeOn: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _vm.highlight(_vm.highlightedIndex - 1)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _vm.highlight(_vm.highlightedIndex + 1)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleKeyEnter.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  )
                    return null
                  return _vm.close.apply(null, arguments)
                },
              ],
            },
          },
          "el-input",
          [_vm.$props, _vm.$attrs],
          false
        ),
        [
          _vm.$slots.prepend
            ? _c("template", { slot: "prepend" }, [_vm._t("prepend")], 2)
            : _vm._e(),
          _vm.$slots.append
            ? _c("template", { slot: "append" }, [_vm._t("append")], 2)
            : _vm._e(),
          _vm.$slots.prefix
            ? _c("template", { slot: "prefix" }, [_vm._t("prefix")], 2)
            : _vm._e(),
          _vm.$slots.suffix
            ? _c("template", { slot: "suffix" }, [_vm._t("suffix")], 2)
            : _vm._e(),
        ],
        2
      ),
      _c(
        "mg-autocomplete-suggestions",
        {
          ref: "suggestions",
          class: [_vm.popperClass ? _vm.popperClass : ""],
          attrs: {
            "visible-arrow": "",
            "popper-options": _vm.popperOptions,
            "append-to-body": _vm.popperAppendToBody,
            placement: _vm.placement,
            id: _vm.id,
          },
        },
        [
          _c(
            "div",
            { staticClass: "mg-autocomplate-th" },
            [_vm._t("header")],
            2
          ),
          _vm.showEmptyText
            ? _c("div", { staticClass: "mg-autocomplete-suggestion__empty" }, [
                _vm._v("暂无数据"),
              ])
            : _vm._e(),
          _vm._l(_vm.suggestions, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "mg-autocomplate-td",
                class: { highlighted: _vm.highlightedIndex === index },
                attrs: {
                  id: `${_vm.id}-item-${index}`,
                  role: "option",
                  "aria-selected": _vm.highlightedIndex === index,
                },
                on: {
                  click: function ($event) {
                    return _vm.select(item)
                  },
                },
              },
              [
                _vm._t(
                  "default",
                  function () {
                    return [_vm._v(" " + _vm._s(item[_vm.valueKey]) + " ")]
                  },
                  { item: item }
                ),
              ],
              2
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }