<template>
  <div>
    <div style="text-align: center; margin-top: 20px">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="基础信息">
        </el-step>
        <el-step title="选择条件"></el-step>
        <el-step title="创建分组"></el-step>
      </el-steps>
    </div>
    <div v-show="active===0" style="width: 40%; margin: auto;">
      <StepOne ref="stepOne" :form-one="formOne" :action-type="actionType" />
    </div>
    <div v-show="active===1" style="width: 55%; margin: auto;">
      <StepTwo ref="stepTwo" :form-two="formTwo" :action-type="actionType" />
    </div>
    <div v-show="active===2" style="width: 40%; margin: auto;">
      <StepThree ref="stepThree" :form-three="formThree" :action-type="actionType" />
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" size="mini" @click="nextStep('next')">{{ active === 2 ? '确认': '下一步' }}</el-button>
      <el-button v-if="active > 0" type="primary" size="mini" @click="nextStep('upper')">取消</el-button>
    </div>
  </div>
</template>

<script>
import { deepClone, parseTime } from "@/utils";
import StepOne from "./Components/StepOne";
import StepTwo from "./Components/StepTwo";
import {Notification} from "element-ui";
import StepThree from "./Components/StepThree";
import {createGroup, getGroupDetail, updateGroup} from "@/api/tagPlatform";

export default {
  name: "LoopSelect",
  components: {StepThree, StepTwo, StepOne},
  props: {
    stepForm: {
      type: Object,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      active: 0,
      mainForm: this.stepForm.mainForm,
      formOne: this.stepForm.formOne,
      formTwo: this.stepForm.formTwo,
      formThree: this.stepForm.formThree,
      options: {
        buDomains: [],
        tagTypes: [],
        updateWay: [{ value: '1', label: '手动更新' }, { value: '2', label: '自动更新' }],
        dateType: ['周', '月'],
        weekOptions: [
          { label: '一', value: '2'},
          { label: '二', value: '3'},
          { label: '三', value: '4'},
          { label: '四', value: '5'},
          { label: '五', value: '6'},
          { label: '六', value: '7'},
          { label: '日', value: '1'}
        ],
        dayOptions: [{ value: 'first', label: '第一天'},{ value: 'any', label: '任意日期'},{ value: 'last', label: '最后一天'}]
      },
      loopDrawer: {
        formTwo: {
          conditionGroupType: '交集',
          conditionGroups: [],
          exceptGroups: [],
          checkedGroupObjs: [],
        },
        mainForm: {},
        notShowTypes: ['为空', '不为空'],
        multipleOptions: ['包含', '不包含'],
        conditionShow: {'交集': "and" , '并集': 'or', '差集': 'not in'},
      },
      createMode: undefined,
    }
  },
  created() {
  },
  methods: {
    async nextStep(type)  {
      if (type === 'next') {
        if (this.active===0) {
          this.$refs.stepOne.$refs.baseInfoForm.validate(valiad => {
            if (valiad) {
              this.baseInfoForm = this.$refs.stepOne.getForm();
              console.log("=========this.baseInfoForm.groupType",this.baseInfoForm.groupType)
              if(this.baseInfoForm.ruleGroupId){
                this.handleEdit(this.baseInfoForm.ruleGroupId, (e) => {
                  this.formTwo = this.loopDrawer.formTwo;
                });
              }else if(this.baseInfoForm.groupType !== 'edit'){
                if(this.createMode !== this.baseInfoForm.createMode){
                  this.resetLoopForm();
                  this.formTwo = this.loopDrawer.formTwo;
                }
              }
              this.createMode = this.baseInfoForm.createMode;
              if (this.active++ > 2) this.active = 0;
            }
          })
        } else if (this.active === 1) {
          const formData = this.$refs.stepTwo.form;
          let show = [];
          let index = 1;
          if (formData.conditionGroups.length === 0) {
            Notification.warning('至少需要填写一个条件组条件');
            return;
          }
          formData.conditionGroups.forEach(e => {
            let flag = false;
            if (e.conditions.length > 0) {
              e.conditions.forEach(ele => {
                if (ele.tagName && ele.compareType) {
                  if (ele.compareType === '区间' && (!ele.leftCompareSymbol || ele.leftCompareValue===undefined || !ele.rightCompareSymbol || ele.rightCompareValue===undefined)) {
                    flag = true;
                  } else if (this.$refs.stepTwo.compareMultipleOptions.indexOf(ele.compareType) > 0 && (ele.compareValue === undefined || ele.compareValue.length === 0)) {
                    flag = true;
                  } else if (this.$refs.stepTwo.notShowTypes.indexOf(ele.compareType) < 0 && ele.compareType !== '区间' && ele.compareValue === undefined) {
                    flag = true;
                  }
                } else if (!ele.compareType || !ele.tagName) {
                  flag = true;
                }
              })
            } else {
              flag = true;
            }
            if (flag) {
              show.push(index);
            }
            index++;
          })
          if (show.length > 0) {
            const msg = show.map(e => '条件组' + e).join('、');
            Notification.warning(msg + '存在未填写数据,请填写完后再进行下一步！');
          } else {
            if (this.active++ > 2) this.active = 0;
          }
        } else if (this.active === 2) {
          const formOne = this.$refs.stepOne.baseInfoForm;
          const formTwo = this.$refs.stepTwo.form;
          const formThree = this.$refs.stepThree.referenceQuota;
          let index = 0;
          const rules = formTwo.conditionGroups.map(e => {
            let rule = {};
            rule.ruleName = '条件组' + (index++);
            rule.conditionCombinationMode = e.conditionType;
            rule.ruleDetails = [];
            e.conditions.forEach(ele => {
              let detail = {
                tagId: ele.tagId,
                tagName: ele.tagName,
                tagValType: ele.tagValType,
                parentContrastOperator: ele.compareType
              }
              if (ele.compareType === '区间') {
                detail.contrastDefinitions = [{ contrastOperator: ele.leftCompareSymbol, tagVal: ele.leftCompareValue }, { contrastOperator: ele.rightCompareSymbol, tagVal: ele.rightCompareValue }];
              } else if (this.$refs.stepTwo.compareMultipleOptions.indexOf(ele.compareType) > -1) {
                if(!(ele.compareValue instanceof Array)){
                  ele.compareValue = ele.compareValue.split(",");
                }
                detail.tagVal = ele.compareValue.join(',');
              } else if (this.$refs.stepTwo.notShowTypes.indexOf(ele.compareType) < 0) {
                detail.tagVal = ele.compareValue;
              }
              rule.ruleDetails.push(detail);
            })
            return rule;
          })
          const conditions = this.$refs.stepTwo.options.conditionGroupTypes;
          const conditionType = conditions.find(e => formTwo.conditionGroupType === e.label).value;
          const form = {
            ruleGroupName: formOne.ruleGroupName,
            buDomain: formOne.buDomain,
            ruleGroupType: formOne.ruleGroupType,
            updateMode: formOne.updateMode,
            autoUpdateFrequency: formOne.autoUpdateFrequency,
            cronExpression: formOne.cronExpression,
            remark: formOne.remark,
            ruleCombinationMode: conditionType,
            rules: rules,
            includeRuleGroupIds: formTwo.exceptGroups,
            tagIds: formThree,
            topic: formOne.topic,
            createMode: formOne.createMode,
          }
          if (this.mainForm.id) {
            updateGroup({ id: this.mainForm.id, ...form}).then(res => {
              if (res.code===200) {
                Notification.success('操作成功！');
                this.$emit('submit-over');
              }
            })
          } else {
            createGroup(form).then(res => {
              if (res.code===200) {
                Notification.success('操作成功！');
                this.$emit('submit-over');
              }
            })
          }
        }
      } else if (type === 'upper') {
        if (this.active-- < 1) this.active = 0;
      }
    },
    resetLoopForm() {
      this.loopDrawer.formTwo= {
        conditionGroupType: '交集',
        conditionGroups: [],
        exceptGroups: [],
        checkedGroupObjs: [],
      };
    },
    transForm(form, data) {
      if (form === 'formTwo') {
        const conditionType = data.ruleCombinationMode;
        let conditionGroups = [];
        if (data.ruleDtos) {
          conditionGroups = data.ruleDtos.map(e => {
            let group = {};
            group.conditionName = e.ruleName;
            group.conditionType = e.conditionCombinationMode;
            if (e.ruleDetails) {
              group.conditions = e.ruleDetails.map(ele => {
                let temp = {
                  tagId: ele.tagId,
                  tagName: ele.tagName,
                  tagValType: ele.tagValType,
                  compareType: ele.parentContrastOperator,
                  compareValue: undefined,
                  leftCompareValue: undefined,
                  rightCompareValue: undefined,
                  leftCompareSymbol: undefined,
                  rightCompareSymbol: undefined,}
                if (this.loopDrawer.multipleOptions.indexOf(ele.parentContrastOperator) > -1) {
                  temp.compareValue = ele.tagVal.split(',')
                  return temp;
                } else if (ele.tagVal !== null && ele.tagVal !== undefined) {
                  temp.compareValue = ele.tagVal;
                  return temp;
                } else if (this.loopDrawer.notShowTypes.indexOf(ele.parentContrastOperator) > -1) {
                  return temp;
                }else if (ele.contrastDefinitions && ele.contrastDefinitions.length > 0) {
                  const range = {
                    leftCompareValue: ele.contrastDefinitions[0].tagVal,
                    rightCompareValue: ele.contrastDefinitions[1].tagVal,
                    leftCompareSymbol: ele.contrastDefinitions[0].contrastOperator,
                    rightCompareSymbol: ele.contrastDefinitions[1].contrastOperator,}
                  temp = Object.assign(temp, range)
                  return temp;
                }
              })
            }
            return group;
          })
        }
        return {
          conditionGroupType: conditionType,
          conditionGroups: conditionGroups,
          exceptGroups: data.excludeRuleGroupDtos ? data.excludeRuleGroupDtos.map(e => e.ruleGroupId): [],
          checkedGroupObjs: data.excludeRuleGroupDtos ? data.excludeRuleGroupDtos: [],
        }
      }
    },
    handleEdit(ruleGroupId, callback) {
      this.resetLoopForm();
      return  getGroupDetail(ruleGroupId).then(res => {
        if (res.code === 200) {
          this.loopDrawer.formTwo = this.transForm('formTwo', res.data);
          this.$forceUpdate();
          callback(this.loopDrawer.formTwo)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
