var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-aside",
        {
          staticStyle: {
            width: "420px",
            "background-color": "#d0d6db",
            padding: "15px",
            height: "700px",
            "overflow-y": "scroll",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { color: "dodgerblue", "text-align": "center" } },
            [_c("h1", [_vm._v("APP界面预览")]), _c("p", [_vm._v("任务合集页")])]
          ),
          _c("div", { staticClass: "page" }, [
            _c(
              "div",
              {
                staticClass: "content",
                style: {
                  "--bgColor": _vm.taskForm.configJson.page?.background?.color,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "contentInner",
                    style: {
                      "background-image":
                        _vm.taskForm.configJson.page?.background?.image &&
                        `url(${_vm.taskForm.configJson.page?.background?.image})`,
                      "background-repeat": "no-repeat",
                      "background-size": "100% auto",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "groupName",
                        style: {
                          color:
                            _vm.taskForm.configJson.page.taskName &&
                            _vm.taskForm.configJson.page.taskName.color
                              ? _vm.taskForm.configJson.page.taskName.color
                              : "#fff",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.taskForm.configJson.page.taskName &&
                              !_vm.taskForm.configJson.page.taskName.show
                              ? ""
                              : _vm.taskForm.configJson.page.title
                              ? _vm.taskForm.configJson.page.title
                              : _vm.taskForm.taskName
                          )
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "introduction" }, [
                      _c("p", { staticClass: "introductionTitle" }, [
                        _vm._v("活动简介"),
                      ]),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v(_vm._s(_vm.taskForm.description)),
                      ]),
                    ]),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "box-shadow": "0 2px 12px 0 rgba(0,0,0,0.73)",
                            border: "1px dashed rgba(0,0,0,0)",
                            padding: "2px 3px",
                            "margin-top": "8px",
                          },
                        },
                        [
                          _vm._l(_vm.groupTasks, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "groupBox",
                                staticStyle: {
                                  "margin-top": "5px",
                                  "background-color": "white",
                                },
                              },
                              [
                                _c(
                                  "el-container",
                                  {
                                    staticStyle: {
                                      "box-shadow":
                                        "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                                      border: "1px dashed #f5dae0",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-main",
                                      { staticStyle: { "padding-top": "0px" } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content": "flex-end",
                                              "margin-top": "-3px",
                                              height: "auto",
                                            },
                                          },
                                          [
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                                icon: "el-icon-upload2",
                                                disabled: index === 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleModuleMove(
                                                    item.title,
                                                    "up",
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                                icon: "el-icon-download",
                                                disabled:
                                                  index ===
                                                  _vm.groupTasks.length - 1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleModuleMove(
                                                    item.title,
                                                    "down",
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                            _c("el-button", {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                                icon: "el-icon-delete",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteModule(
                                                    item.title,
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("ModuleItem", {
                                          attrs: {
                                            title: item.title,
                                            list: item.list,
                                            moduleTasks: _vm.allTaskList,
                                            type: item.type,
                                            currentTask: _vm.currentTask,
                                            "show-type": "group-detail",
                                          },
                                          on: {
                                            setCurrentTask: (taskId) =>
                                              (_vm.currentTask = taskId),
                                            handleChangeTask: (
                                              type,
                                              action,
                                              insideIndex
                                            ) =>
                                              _vm.handleChangeTask(
                                                index,
                                                type,
                                                insideIndex,
                                                _vm.allTaskList,
                                                action
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "groupBox",
                              staticStyle: {
                                "margin-top": "5px",
                                "background-color": "white",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "18px",
                                    "font-weight": "700",
                                    "text-align": "center",
                                    cursor: "pointer",
                                    overflow: "unset",
                                    "background-color": "#ffeff2",
                                    "box-shadow":
                                      "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                                    border: "1px dashed #f5dae0",
                                    padding: "5px",
                                  },
                                  on: {
                                    click: () =>
                                      _vm.handleAddModule(
                                        _vm.groupTasks.length
                                      ),
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    staticStyle: { color: "red" },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v(" 添加模块")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-main",
        {
          staticStyle: {
            "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
            "margin-left": "10px",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "groupForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "120px",
                inline: true,
                model: _vm.dataForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入标题名称" },
                    model: {
                      value: _vm.dataForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "title", $$v)
                      },
                      expression: "dataForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合集简介", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      autosize: { minRows: 2, maxRows: 5 },
                      type: "textarea",
                      maxlength: "150",
                      "show-word-limit": "",
                      placeholder: "选填，请输入简介",
                    },
                    model: {
                      value: _vm.dataForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataForm, "description", $$v)
                      },
                      expression: "dataForm.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择页面模板", prop: "bgTemplate" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: (val) => {
                          _vm.dataForm.bgColor = _vm.colorMap[val]
                          if (!_vm.dataForm.customHeadImage) {
                            _vm.dataForm.bgImage = _vm.templateUrl[val]
                          }
                          _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.dataForm.bgTemplate,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "bgTemplate", $$v)
                        },
                        expression: "dataForm.bgTemplate",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: "red", value: "red" } },
                        [_vm._v("红色系模板")]
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: "orange", value: "orange" } },
                        [_vm._v("橙色系模板")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "顶部头图", prop: "template" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        input: (v) => {
                          if (!v) {
                            _vm.dataForm.bgImage =
                              _vm.templateUrl[_vm.dataForm.bgTemplate]
                          } else {
                            _vm.dataForm.bgImage = ""
                          }
                        },
                      },
                      model: {
                        value: _vm.dataForm.customHeadImage,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "customHeadImage", $$v)
                        },
                        expression: "dataForm.customHeadImage",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: false, value: false } },
                        [
                          _vm._v("模板默认头图"),
                          _c("img", {
                            staticStyle: { height: "80px", display: "block" },
                            attrs: {
                              src: _vm.templateUrl[_vm.dataForm.bgTemplate],
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: true, value: true } },
                        [
                          _vm._v("自定义 "),
                          _vm.dataForm.customHeadImage
                            ? _c("OssUpload", {
                                ref: "ossUpload",
                                staticStyle: { display: "table" },
                                attrs: {
                                  "value-to": _vm.dataForm.bgImage,
                                  maxFileSize: 1024,
                                  "list-type": "picture-card",
                                  limit: 1,
                                  "always-show-button": false,
                                  tips: "尺寸1125*726，下方225高度勿放置重要内容会被遮挡，图片建议小于1M。合集标题请做在图上一并上传",
                                  tipMessageShow: false,
                                  "accept-type": _vm.imgTypes,
                                },
                                on: {
                                  "update:valueTo": function ($event) {
                                    return _vm.$set(
                                      _vm.dataForm,
                                      "bgImage",
                                      $event
                                    )
                                  },
                                  "update:value-to": function ($event) {
                                    return _vm.$set(
                                      _vm.dataForm,
                                      "bgImage",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题是否显示", prop: "bgTemplate" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { input: (val) => {} },
                      model: {
                        value: _vm.dataForm.taskNameShow,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataForm, "taskNameShow", $$v)
                        },
                        expression: "dataForm.taskNameShow",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("是"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "颜色配置", prop: "template" } },
                [
                  _c("div", { staticStyle: { display: "inline-flex" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "span",
                          { staticStyle: { display: "inline-flex" } },
                          [_vm._v("页面背景色")]
                        ),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.dataForm.bgColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "bgColor", $$v)
                            },
                            expression: "dataForm.bgColor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "span",
                          { staticStyle: { display: "inline-flex" } },
                          [_vm._v("标题颜色")]
                        ),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.dataForm.taskNameColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataForm, "taskNameColor", $$v)
                            },
                            expression: "dataForm.taskNameColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.drawer.title,
            visible: _vm.drawer.visible,
            direction: "ltr",
            "custom-class": "drawerClass",
            size: "100%",
            "show-close": false,
            wrapperClosable: false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "visible", $event)
            },
          },
        },
        [
          _vm.drawer.templateType !== "group"
            ? _c("TaskContentV2", {
                ref: "taskContent",
                attrs: {
                  templateType: _vm.drawer.templateType,
                  form: _vm.drawer.form,
                  orgCode: _vm.orgCode,
                  tasks: _vm.drawer.tasks,
                  timestamp: _vm.drawer.timestamp,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                width: "200px",
                position: "absolute",
                bottom: "50px",
                right: "50px",
                "text-align": "right",
              },
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.confirm } },
                [_vm._v("确 认")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.drawer.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加模块",
            visible: _vm.dialog.visible,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { margin: "10px", width: "100%" },
                  on: { change: _vm.moduleTypeChange },
                  model: {
                    value: _vm.dialog.moduleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "moduleType", $$v)
                    },
                    expression: "dialog.moduleType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("一排二小"),
                  ]),
                  _vm.taskForm.taskModuleType != "PARTNER"
                    ? _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("一大二小"),
                      ])
                    : _vm._e(),
                  _vm.taskForm.taskModuleType != "PARTNER"
                    ? _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("一排二大"),
                      ])
                    : _vm._e(),
                  _vm.taskForm.taskModuleType != "PARTNER"
                    ? _c("el-radio", { attrs: { label: "4" } }, [
                        _vm._v("一排三个"),
                      ])
                    : _vm._e(),
                  _c("el-radio", { attrs: { label: "5" } }, [
                    _vm._v("一排一个"),
                  ]),
                ],
                1
              ),
              _c(
                "el-container",
                {
                  staticStyle: {
                    "box-shadow": "0 2px 12px 0 rgba(0, 0, 0, 0.1)",
                  },
                },
                [
                  _c(
                    "el-header",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-content": "center",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "17px",
                            "font-weight": "700",
                          },
                        },
                        [_vm._v(_vm._s(_vm.showCaseTitle))]
                      ),
                    ]
                  ),
                  _c(
                    "el-main",
                    [
                      _c("el-image", {
                        staticStyle: { height: "250px", width: "500px" },
                        attrs: { src: _vm.showCaseImg, fit: "contain" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { margin: "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addModule()
                        },
                      },
                    },
                    [_vm._v("添加")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCancel()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }