var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  ref: "queryFormRef",
                  attrs: { model: _vm.queryForm },
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务所配分公司", prop: "orgCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", "default-first-option": "" },
                          on: { change: _vm.handleOrgChange },
                          model: {
                            value: _vm.queryForm.orgCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "orgCode", $$v)
                            },
                            expression: "queryForm.orgCode",
                          },
                        },
                        _vm._l(_vm.options.orgOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              value: item.value,
                              label: item.value + "-" + item.label,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务", prop: "taskId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", "default-first-option": "" },
                          model: {
                            value: _vm.queryForm.taskId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "taskId", $$v)
                            },
                            expression: "queryForm.taskId",
                          },
                        },
                        _vm._l(_vm.options.taskOptions, function (item) {
                          return _c("el-option", {
                            key: item.mtTaskId,
                            attrs: {
                              value: item.mtTaskId,
                              label: "(" + item.orgName + ")" + item.taskName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "业务员所属分公司", prop: "authOrgCode" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", "default-first-option": "" },
                          on: {
                            change: (val) =>
                              _vm.handleChangeLinkageRef("dc", val, "hand"),
                          },
                          model: {
                            value: _vm.queryForm.authOrgCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "authOrgCode", $$v)
                            },
                            expression: "queryForm.authOrgCode",
                          },
                        },
                        _vm._l(_vm.options.authOrgOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              value: item.value,
                              label:
                                (item.value ? item.value + "-" : "") +
                                item.label,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心", prop: "dcCode" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          parentLinkageRef: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ orgCode: _vm.queryForm.authOrgCode }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status",
                          },
                        },
                        _vm._l(
                          _vm.options.approveOptions,
                          function (item, key) {
                            return _c("el-option", {
                              key: key,
                              attrs: { value: item.value, label: item.label },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "未审核金额", prop: "leftSend" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.leftSend,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "leftSend", $$v)
                            },
                            expression: "queryForm.leftSend",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未审核金额>0", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未审核金额<0", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户账号", prop: "userName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入用户账号/姓名",
                        },
                        model: {
                          value: _vm.queryForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "userName", $$v)
                          },
                          expression: "queryForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号状态", prop: "userStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.userStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "userStatus", $$v)
                            },
                            expression: "queryForm.userStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "正常", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "关闭", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "红包状态", prop: "acceptStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.acceptStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "acceptStatus", $$v)
                            },
                            expression: "queryForm.acceptStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "待领取", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已领取", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已作废", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _vm.queryForm.orgCode != "ALL" || _vm.orgAuthAll
                  ? _c("span", [
                      _c(
                        "span",
                        [
                          _vm._v("快捷审核"),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                width: "200",
                                trigger: "hover",
                                content: "批量审核符合筛选条件的全部未审核记录",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-question",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleApproveAll("allAgree")
                                },
                              },
                            },
                            [_vm._v("全部通过")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleApproveAll("allRefuse")
                                },
                              },
                            },
                            [_vm._v("全部不通过")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleBatchApprove("batchAgree")
                                },
                              },
                            },
                            [_vm._v("批量通过")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleBatchApprove("batchRefuse")
                                },
                              },
                            },
                            [_vm._v("批量不通过")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      size: "mini",
                      icon: "el-icon-download",
                      type: "primary",
                    },
                    on: { click: _vm.handleExport },
                  },
                  [_vm._v("按用户导出")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    attrs: {
                      size: "mini",
                      icon: "el-icon-download",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleExportShopV2("0")
                      },
                    },
                  },
                  [_vm._v("未审核店铺激励导出")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "form-btn-item",
                    attrs: {
                      size: "mini",
                      icon: "el-icon-download",
                      type: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleExportShopV2("1")
                      },
                    },
                  },
                  [_vm._v("已审核店铺激励导出")]
                ),
                _vm.authUsers.indexOf(_vm.currentUser) > -1
                  ? _c(
                      "el-button",
                      {
                        staticClass: "form-btn-item",
                        attrs: {
                          size: "mini",
                          icon: "el-icon-download",
                          type: "primary",
                        },
                        on: { click: _vm.handleExportShop },
                      },
                      [_vm._v("按店铺导出")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
              _c("span", [
                _c("strong", [_vm._v("审批金额更新时间：")]),
                _vm._v(_vm._s(_vm.summaryData.leastTime)),
              ]),
              _c("span", { staticStyle: { margin: "5px" } }, [_vm._v("统计")]),
              _c("span", { staticStyle: { margin: "5px" } }, [
                _c("strong", [_vm._v("用户：")]),
                _vm._v(" " + _vm._s(_vm.summaryData.userNum)),
              ]),
              _c("span", { staticStyle: { margin: "5px" } }, [
                _c("strong", [_vm._v("业务激励金额：")]),
                _vm._v(" " + _vm._s(_vm.summaryData.boostBonus)),
              ]),
              _c("span", { staticStyle: { margin: "5px" } }, [
                _c("strong", [_vm._v("发放金额：")]),
                _vm._v(" " + _vm._s(_vm.summaryData.packetAmount)),
              ]),
              _c("span", { staticStyle: { margin: "5px" } }, [
                _c("strong", [_vm._v("已通过金额：")]),
                _vm._v(" " + _vm._s(_vm.summaryData.approveAmount)),
              ]),
              _c("span", { staticStyle: { margin: "5px" } }, [
                _c("strong", [_vm._v("已拒绝金额：")]),
                _vm._v(" " + _vm._s(_vm.summaryData.refuseAmount)),
              ]),
              _c("span", { staticStyle: { margin: "5px" } }, [
                _c("strong", [_vm._v("未审核金额：")]),
                _vm._v(" " + _vm._s(_vm.summaryData.noApproveAmount)),
              ]),
            ]),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "50",
                  selectable: (row, index) => {
                    return (
                      row.list.filter(
                        (e) => e.status === 0 && e.packetAmount > 0
                      ).length > 0
                    )
                  },
                },
              }),
              _c("el-table-column", {
                attrs: { label: "用户账号", prop: "userName", width: "110" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户姓名", prop: "nickName", width: "110" },
              }),
              _c("el-table-column", {
                attrs: { label: "账号状态", prop: "statusText", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "用户手机号",
                  prop: "phoneNumber",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "所属分公司", prop: "orgName", width: "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "配送中心",
                  prop: "dcName",
                  "show-overflow-tooltip": "",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "业务激励金额（元）",
                  prop: "boostBonus",
                  sortable: "",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c("span", [_vm._v(_vm._s(row.boostBonus))]),
                            row.boostBonus && row.boostBonus > 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      border: "0px",
                                      "margin-left": "5px",
                                    },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleGetBoost(row)
                                      },
                                    },
                                  },
                                  [_vm._v("明细")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "待审核金额",
                  prop: "packetAmount",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.list, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c("span", [_vm._v(_vm._s(item.packetAmount))]),
                              item.packetAmount !== item.expectPacketAmount
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        "(修改前 " +
                                          _vm._s(item.expectPacketAmount) +
                                          ")"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核状态", prop: "status", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.list, function (item, index) {
                            return _c("el-col", { key: index }, [
                              _c(
                                "span",
                                {
                                  style:
                                    item.status === 0
                                      ? { color: "#EE7712" }
                                      : "",
                                },
                                [_vm._v(_vm._s(_vm.approveMap[item.status]))]
                              ),
                            ])
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "红包状态",
                  prop: "acceptStatus",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.list, function (item, index) {
                            return _c(
                              "el-col",
                              { key: index },
                              [
                                item.acceptStatus !== undefined &&
                                item.acceptStatus !== null
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          _vm.statusClass[item.acceptStatus],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statusMap[item.acceptStatus]
                                          )
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v("未发放")]),
                                item.acceptStatus !== undefined &&
                                item.acceptStatus !== null
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          width: "300",
                                          title: "详情",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "max-height": "400px !important",
                                              "overflow-y": "auto",
                                            },
                                          },
                                          _vm._l(
                                            item.packets,
                                            function (e, index) {
                                              return _c(
                                                "el-row",
                                                { key: index },
                                                [
                                                  item.packets.length > 1
                                                    ? _c("el-col", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-weight":
                                                                "600",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "红包" +
                                                                _vm._s(
                                                                  index + 1
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("el-col", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "红包金额（元）："
                                                      ),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            e.amount
                                                              ? (
                                                                  e.amount / 100
                                                                ).toFixed(2)
                                                              : ""
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c("el-col", [
                                                    _c("span", [
                                                      _vm._v("发放时间："),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(e.sendTime)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("el-col", [
                                                    _c("span", [
                                                      _vm._v("领取状态："),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.statusMap[
                                                            e.acceptStatus
                                                          ]
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    e.acceptStatus === 1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.acceptTime
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "text",
                                              size: "mini",
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v("详情")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "260",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-row",
                          _vm._l(row.list, function (item, index) {
                            return _c(
                              "el-col",
                              { key: index },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.status === 0 &&
                                          item.packetAmount > 0 &&
                                          (_vm.queryForm.orgCode != "ALL" ||
                                            _vm.orgAuthAll),
                                        expression:
                                          "item.status===0 && item.packetAmount>0 && (queryForm.orgCode!='ALL' || orgAuthAll)",
                                      },
                                    ],
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAudit(item, row, 1)
                                      },
                                    },
                                  },
                                  [_vm._v("通过")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.status === 0 &&
                                          item.packetAmount > 0 &&
                                          (_vm.queryForm.orgCode != "ALL" ||
                                            _vm.orgAuthAll),
                                        expression:
                                          "item.status===0 && item.packetAmount>0 && (queryForm.orgCode!='ALL' || orgAuthAll)",
                                      },
                                    ],
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAudit(item, row, 2)
                                      },
                                    },
                                  },
                                  [_vm._v("不通过")]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.status !== 0,
                                        expression: "item.status!==0",
                                      },
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.approveUser) +
                                        " " +
                                        _vm._s(
                                          _vm.parseTime(
                                            item.approveTime,
                                            "{y}-{m}-{d}"
                                          )
                                        )
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-popover",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          ["1", "2"].indexOf(item.status + "") >
                                          -1,
                                        expression:
                                          "['1','2'].indexOf(item.status + '') > -1",
                                      },
                                    ],
                                    attrs: {
                                      placement: "right",
                                      width: "300",
                                      title: "审批详情",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", [
                                          _c("span", [_vm._v("审批人：")]),
                                          _c("span", [
                                            _vm._v(_vm._s(item.approveUser)),
                                          ]),
                                        ]),
                                        _c("el-col", [
                                          _c("span", [_vm._v("审批时间：")]),
                                          _c("span", [
                                            _vm._v(_vm._s(item.approveTime)),
                                          ]),
                                        ]),
                                        _c("el-col", [
                                          _c("span", [_vm._v("审批金额：")]),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "font-size": "18px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.packetAmount) + "元"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          item.packetAmount !==
                                          item.expectPacketAmount
                                            ? _c("span", [
                                                _vm._v(
                                                  "修改前" +
                                                    _vm._s(
                                                      item.expectPacketAmount
                                                    ) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                        _c("el-col", [
                                          _c("span", [_vm._v("审批备注：")]),
                                          _c("span", [
                                            _vm._v(_vm._s(item.remark)),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                          size: "mini",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("详情")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "激励明细",
            visible: _vm.boostDialog.visible,
            "append-to-body": "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.boostDialog, "visible", $event)
            },
          },
        },
        [
          _vm.boostDialog.boosts && _vm.boostDialog.boosts.length > 0
            ? _c(
                "div",
                { staticStyle: { "overflow-y": "scroll", height: "500px" } },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.boostDialog.tabName,
                        callback: function ($$v) {
                          _vm.$set(_vm.boostDialog, "tabName", $$v)
                        },
                        expression: "boostDialog.tabName",
                      },
                    },
                    _vm._l(_vm.boostDialog.boosts, function (item) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: item.boostId,
                          staticStyle: {
                            "background-color": "#FCF7EE",
                            "border-radius": "5px",
                            margin: "10px",
                            padding: "5px",
                          },
                          attrs: {
                            label:
                              item.boostName +
                              "(" +
                              (item.boostBonus ? item.boostBonus : 0) +
                              ")",
                            name: item.boostId,
                          },
                        },
                        [
                          _c("div", { staticStyle: { margin: "5px" } }, [
                            _c("label", { staticStyle: { color: "#a18d49" } }, [
                              _vm._v("进货激励："),
                            ]),
                            _c("span", [_vm._v(_vm._s(item.description))]),
                          ]),
                          _c("div", { staticStyle: { margin: "10px" } }, [
                            _c("label", { staticStyle: { color: "#a18d49" } }, [
                              _vm._v("达成店铺数："),
                            ]),
                            _c("span", [_vm._v(_vm._s(item.shopsDescription))]),
                          ]),
                          _c(
                            "el-row",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "label",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      color: "#a18d49",
                                    },
                                  },
                                  [_vm._v("达成店铺数")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "label",
                                  { staticStyle: { color: "#a18d49" } },
                                  [_vm._v("预计业务奖励")]
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "font-size": "18px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.boostShopCount
                                          ? item.boostShopCount
                                          : 0
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v("家"),
                              ]),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      "font-size": "18px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.boostBonus ? item.boostBonus : 0
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v("元"),
                              ]),
                              item.shopsBoostBonus
                                ? _c("el-col", [
                                    _c("span", [
                                      _vm._v("含达标店铺数额外奖励"),
                                    ]),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.shopsBoostBonus) +
                                            "元"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.boostDialog.loading,
                              expression: "boostDialog.loading",
                            },
                          ],
                          attrs: {
                            data: _vm.boostDialog.boostDetailList,
                            size: "mini",
                            "max-height": "300",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "终端名称", prop: "shopName" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: () => {
                                              _vm.shopDetailDialog.shopName =
                                                row.shopName
                                              _vm.shopDetailDialog.visible = true
                                              const data = {
                                                poiCode: row.poiCode,
                                                boostId:
                                                  _vm.boostDialog.tabName,
                                                mtTaskId: _vm.queryForm.taskId,
                                                username: row.userName,
                                              }
                                              _vm.handleShopDetail(
                                                Object.assign(
                                                  _vm.defaultQueryObj,
                                                  data
                                                )
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(row.shopName))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1196162080
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "奖励金额",
                              prop: "boostBonus",
                              width: "100",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.boostDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "店铺激励明细",
            visible: _vm.shopDetailDialog.visible,
            "append-to-body": "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.shopDetailDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", { staticStyle: { margin: "5px" } }, [
            _c("span", [_vm._v("店铺名称： ")]),
            _c("span", [_vm._v(_vm._s(_vm.shopDetailDialog.shopName))]),
          ]),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.shopDetailDialog.tabName,
                callback: function ($$v) {
                  _vm.$set(_vm.shopDetailDialog, "tabName", $$v)
                },
                expression: "shopDetailDialog.tabName",
              },
            },
            _vm._l(_vm.shopDetailDialog.list, function (item) {
              return _c(
                "el-tab-pane",
                {
                  key: item.measure,
                  staticStyle: { "overflow-y": "scroll", height: "400px" },
                  attrs: { label: item.measureName, name: item.kss },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.shopDetailDialog.loading,
                          expression: "shopDetailDialog.loading",
                        },
                      ],
                      attrs: { data: item.data, size: "mini" },
                    },
                    _vm._l(item.columns, function (e) {
                      return _c("el-table-column", {
                        key: e,
                        attrs: { label: _vm.columnMap[e], prop: e },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.shopDetailDialog.visible = false
                    },
                  },
                },
                [_vm._v(" 关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "红包记录",
            visible: _vm.packetDialog.visible,
            "append-to-body": "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.packetDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.packetDialog.loading,
                  expression: "packetDialog.loading",
                },
              ],
              attrs: { data: _vm.packetDialog.list, size: "mini" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "红包金额（元）",
                  prop: "amount",
                  width: "250",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "发放时间", prop: "sendTime", width: "280" },
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "status", width: "280" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.statusMap[row.status]) +
                              _vm._s(row.status === 1 ? row.acceptTime : "") +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.packetDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.updateDialog.titleMap[_vm.updateDialog.title],
            center: true,
            visible: _vm.updateDialog.visible,
            "append-to-body": "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.updateDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.updateDialog.form,
              },
            },
            [
              _vm.updateDialog.title.indexOf("all") < 0 &&
              _vm.updateDialog.title.indexOf("batch") < 0
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c("el-form-item", { attrs: { label: "用户姓名" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.updateDialog.form.nickName)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c("el-form-item", { attrs: { label: "用户账号" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.updateDialog.form.userName)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c("el-form-item", { attrs: { label: "审批金额" } }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.updateDialog.form.expectPacketAmount
                                ) + "元"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm.updateDialog.form.status === 1
                        ? _c(
                            "el-col",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "修改为" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "90%" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      precision: 2,
                                      placeholder: "非必填，请谨慎操作",
                                    },
                                    model: {
                                      value: _vm.updateDialog.form.packetAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.updateDialog.form,
                                          "packetAmount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "updateDialog.form.packetAmount",
                                    },
                                  }),
                                  _vm._v("元 "),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "10px",
                                    "margin-left": "50px",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "注意修改仅针对本次金额，不会影响以后的发放（修改导致的差额之后不会补发或扣除）。"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "审批备注" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "非必填",
                                },
                                model: {
                                  value: _vm.updateDialog.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateDialog.form,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression: "updateDialog.form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-row",
                    [
                      _c("el-col", { staticStyle: { color: "#66b1ff" } }, [
                        _c("span", [
                          _vm._v(
                            "已选择" +
                              _vm._s(_vm.updateDialog.allForm.userNum) +
                              "人， 业务激励金" +
                              _vm._s(_vm.updateDialog.allForm.boostBonus) +
                              "元，未审核金额" +
                              _vm._s(_vm.updateDialog.allForm.noApproveAmount) +
                              "元"
                          ),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "审核备注" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "非必填",
                                },
                                model: {
                                  value: _vm.updateDialog.allForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.updateDialog.allForm,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression: "updateDialog.allForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.handleUpdateAmount()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.updateDialog.title.indexOf("gree") > -1
                        ? "通过"
                        : "不通过"
                    )
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.updateDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看对比明细",
            visible: _vm.diffDialog.visible,
            "append-to-body": "",
            width: "1000px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.diffDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.diffDialog.tabName,
                callback: function ($$v) {
                  _vm.$set(_vm.diffDialog, "tabName", $$v)
                },
                expression: "diffDialog.tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticStyle: { "overflow-y": "scroll", height: "400px" },
                  attrs: { label: "包干负责人", name: "resp" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.shopDetailDialog.loading,
                          expression: "shopDetailDialog.loading",
                        },
                      ],
                      attrs: {
                        data: _vm.diffDialog.form.diffReps,
                        "empty-text":
                          "仅2024年1月16号以后审核通过的数据才可进行数据对比",
                        size: "mini",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "所属配送中心",
                          prop: "dcName",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(row.dcCode + "-" + row.dcName)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "店铺编码/名称",
                          prop: "shop",
                          width: "250",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.shopCode + "-" + row.shopName)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "el-table-column",
                        { attrs: { label: "对比前", align: "center" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "日期",
                              prop: "dateKey",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "包干负责人",
                              prop: "userName",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.userName
                                            ? row.userName + "-" + row.nickName
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table-column",
                        { attrs: { label: "对比后", align: "center" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "日期",
                              prop: "afterDateKey",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "包干负责人",
                              prop: "afterUserName",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          row.afterUserName
                                            ? row.afterUserName +
                                                "-" +
                                                row.afterNickName
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  staticStyle: { "overflow-y": "scroll", height: "400px" },
                  attrs: { label: "进货数据", name: "jh" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.shopDetailDialog.loading,
                          expression: "shopDetailDialog.loading",
                        },
                      ],
                      attrs: {
                        data: _vm.diffDialog.form.diffReps,
                        "empty-text":
                          "仅2024年1月16号以后审核通过的数据才可进行数据对比",
                        size: "mini",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "所属配送中心",
                          prop: "dcName",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(row.dcCode + "-" + row.dcName)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "店铺编码/名称",
                          prop: "shop",
                          width: "250",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.shopCode + "-" + row.shopName)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "el-table-column",
                        { attrs: { label: "对比前", align: "center" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "日期",
                              prop: "dateKey",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "进货金额",
                              prop: "dqJhTotal",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "上柜款数",
                              prop: "dqJhKss",
                              width: "100",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table-column",
                        { attrs: { label: "对比后", align: "center" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "日期",
                              prop: "afterDateKey",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "进货金额",
                              prop: "afterDqJhTotal",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "上柜款数",
                              prop: "afterDqJhKss",
                              width: "100",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }