<template>
  <div>
    <el-form ref="baseInfoForm" :model="baseInfoForm" size="mini" :rules="rules" label-width="auto" >
      <el-form-item label="分组名称" prop="ruleGroupName" class="full-width" >
        <el-input :disabled="actionType==='edit'" v-model="baseInfoForm.ruleGroupName" placeholder="请输入分组名称" maxlength="15" show-word-limit />
      </el-form-item>
      <el-form-item label="业务域" prop="buDomain" class="full-width" >
        <el-select :disabled="actionType==='edit'" v-model="baseInfoForm.buDomain" size="mini">
          <el-option v-for="item in options.buDomains"  :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="分组类型" prop="ruleGroupType" class="full-width" >
        <el-select :disabled="actionType==='edit'" v-model="baseInfoForm.ruleGroupType" size="mini">
          <el-option v-for="item in options.tagTypes"  :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="主题" prop="topic" class="full-width" >
        <el-select :disabled="actionType==='edit'" v-model="baseInfoForm.topic" filterable placeholder="请选择主题/输入主题" allow-create size="mini">
          <el-option v-for="item in options.topics"  :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="actionType!=='edit'" label="新建方式" prop="createMode" class="full-width" >
        <el-select :disabled="actionType==='edit'" v-model="baseInfoForm.createMode" filterable placeholder="请选择新建方式" allow-create size="mini" @change="handleCreateModeChange">
          <el-option v-for="item in options.createModes"  :key="item" :label="item" :value="item" />
        </el-select>

        <el-select v-if="baseInfoForm.createMode==='复制已有分组'" style="margin-left: 20px;" :disabled="actionType==='edit'" v-model="baseInfoForm.ruleGroupId" filterable placeholder="请选择" allow-create size="mini">
          <el-option v-for="item in options.ruleGroupOptions"  :key="item.id" :label="item.ruleGroupName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="更新方式" prop="updateMode">
        <el-radio-group v-model="baseInfoForm.updateMode">
          <el-radio v-for="item in options.updateWay" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
        <div v-if="baseInfoForm.updateMode === '2'" style="display: inline-block; margin-left: 10px">
            按
          <el-select v-model="baseInfoForm.dateType" @change="hangleDayTypeChange" style="width: 80px !important;">
            <el-option v-for="item in options.dateType" :key="item" :label="item" :value="item" />
          </el-select>
          {{ dateShow }}
          <el-select v-if="baseInfoForm.dateType === '周'" v-model="baseInfoForm.weekDay" style="width: 80px !important;">
            <el-option v-for="item in options.weekOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-select v-if="baseInfoForm.dateType === '月'" v-model="baseInfoForm.dayType" style="width: 100px !important;">
            <el-option v-for="item in options.dayOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
          <el-input-number v-if="baseInfoForm.dateType === '月' && baseInfoForm.dayType === 'any'" v-model="baseInfoForm.day" :controls="false" :min="1" :max="28" style="width: auto" placeholder="日期不超过28号" />
          更新
        </div>
      </el-form-item>
      <el-form-item label="说明" prop="remark">
        <el-input v-model="baseInfoForm.remark" class="full-width" type="textarea" placeholder="请输入说明" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { deepClone, parseTime } from "@/utils";
import {getAllDict, getRuleGroupCreateModeList, getRuleGroupList, getRuleGroupTopicList} from "@/api/tagPlatform";

export default {
  name: "StepOne",
  props: {
    formOne: {
      type: Object,
      default() {
        return {
          ruleGroupName: undefined,
          buDomain: undefined,
          ruleGroupType: undefined,
          updateMode: '1',
          cronExpression: undefined,
          autoUpdateFrequency: '手动',
          dateType: '天',
          weekDay: '2',
          dayType: 'first',
          day: undefined,
          remark: undefined,
          topic: undefined,
          createMode: undefined,
          ruleGroupId: undefined,
          groupType: 'add',
        }
      }
    },
    actionType: {
      type: String,
      required: true,
    }
  },
  computed: {
    dateShow() {
      return '每' + this.baseInfoForm.dateType;
    }
  },
  watch: {
    "baseInfoForm.updateMode": {
      handler(newVal, oldVal) {
        if (newVal === '1') {
          this.baseInfoForm.autoUpdateFrequency = '手动';
        } else {
          this.baseInfoForm.autoUpdateFrequency = '按' + this.baseInfoForm.dateType;
        }
      },
      immediate: false,
      deep: true
    }
  },
  data() {
    return {
      rules: {
        ruleGroupName: [{ required: true, message: ' ', trigger: 'change' }],
        buDomain: [{ required: true, message: ' ', trigger: 'change' }],
        ruleGroupType: [{ required: true, message: ' ', trigger: 'change' }],
        topic: [{ required: true, message: ' ', trigger: 'change' }],
        createMode: [{ required: true, message: ' ', trigger: 'change' }],
      },
      baseInfoForm: this.formOne,
      options: {
        buDomains: [],
        tagTypes: [],
        updateWay: [{ value: '1', label: '手动更新' }, { value: '2', label: '自动更新' }],
        dateType: ['天', '周', '月'],
        weekOptions: [
          { label: '一', value: '2'},
          { label: '二', value: '3'},
          { label: '三', value: '4'},
          { label: '四', value: '5'},
          { label: '五', value: '6'},
          { label: '六', value: '7'},
          { label: '日', value: '1'}
        ],
        dayOptions: [{ value: 'first', label: '第一天'},{ value: 'any', label: '任意日期'},{ value: 'last', label: '最后一天'}],
        topics: [],
        createModes:[],
        ruleGroupOptions: [],
      },
    }
  },
  methods: {
    hangleDayTypeChange(val) {
      this.baseInfoForm.autoUpdateFrequency = '按' + val;
    },
    transCron() {
      switch (this.baseInfoForm.dateType) {
        case '天':
          return '0 0/15 6-9 * * ?';
        case "周":
          return '0 0/15 6-9 ? * ' + this.baseInfoForm.weekDay;
        case '月':
          if (this.baseInfoForm.dayType === 'first') {
            return '0 0/15 6-9 1 * ?';
          } else if (this.baseInfoForm.dayType === 'any') {
            return '0 0/15 6-9 ' + this.baseInfoForm.day + ' * ?';
          } else if (this.baseInfoForm.dayType === 'last') {
            return '0 0/15 6-9 L * ?';
          }
      }
    },
    getForm() {
      if (this.baseInfoForm.updateMode === '2') {
        this.baseInfoForm.cronExpression = this.transCron();
      }
      return this.baseInfoForm;
    },
    handleCreateModeChange(){
      if(this.baseInfoForm.createMode === '纯新增'){
        this.baseInfoForm.ruleGroupId = undefined;
      }
    },
  },
  created() {
    getAllDict().then(res => {
      this.options.buDomains = res.data.buDomains;
      this.options.tagTypes = res.data.tagTypes;
    });
    getRuleGroupTopicList().then(res => {
      this.options.topics = res.data;
    })
    getRuleGroupCreateModeList().then(res => {
      this.options.createModes = res.data;
    })
    getRuleGroupList().then(res => {
      this.options.ruleGroupOptions = res.data;
    })
  }
}
</script>

<style scoped>

</style>
