<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
              ref="org"
              type="org"
              :value.sync="queryForm.orgCode"
              linkageRef="dc,area-tag"
              :dictFun="dictFun"
              fullLabel
              default-first-option
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
              ref="dc"
              type="dc"
              :value.sync="queryForm.dcCode"
              linkageRef="area-tag"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
              ref="area-tag"
              type="area-tag"
              :value.sync="queryForm.areaTag"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="年月" label-width="40px" style="margin-left: unset">
          <DictSelect
            ref="yearMth"
            type="area-date"
            multiple
            :value.sync="queryForm.yearMths"
            :dictFun="shopDictFun"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
<!--          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="importDialog.visible = true">导入</el-button>-->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @sort-change="handleSortChange"
    >
      <el-table-column type="index" width="40" />
      <el-table-column label="分公司编码-名称" prop="orgCode">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心编码-名称" prop="dcCode">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域分组" prop="areaTag" />
      <el-table-column label="年月" prop="yearMth" />
      <el-table-column label="全部款式">
        <el-table-column label="进货金额目标(元)" prop="allJheTarget" />
        <el-table-column label="进货款数目标" prop="allKssTarget" />
        <el-table-column label="新增进货款数目标" prop="allNewKssTarget" />
      </el-table-column>
<!--      <el-table-column label="操作" width="180" align="center" fixed="right">-->
<!--        <template #default="{ row }">-->
<!--          <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleEdit(row, 'edit')">编辑</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />

    <ImportDialog :importBaseParams="importDialog" @handleImportCallBack="handleImportData" append-to-body :modal-append-to-body="false" />

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="500px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect, shopDict, exportAreaTarget } from "@/api/appTask";
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import { pageAreaTagTargetList, importAreaTagTarget } from "../../../../../api/appTask";

export default {
  name: "AreaGroup",
  components: { Pagination, ImportDialog: () => import("@/components/ImportDialog") , DictSelect},
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      },
      loading: false,
      userOptions: [],
      saveTitle: undefined,
      importDialog: {
        visible: false,
        title: "区域分组目标导入",
        type: "import",
        tipsText: "提示：导入的区域分组目标数据大概半个小时将在聚宝盆APP显示",
        templateSampleHead: [{ label: "分公司编码", prop: "orgCode", minWidth: "80px" },{ label: "配送中心编码", prop: "dcCode", minWidth: "80px" },{ label: "区域分组", prop: "areaTag", minWidth: "80px" },
          { label: "年月", prop: "yearMth", minWidth: "80px" }, { label: "进货金额目标(元)", prop: "jheTarget", minWidth: "80px" }, { label: "进货款数目标", prop: "kssTarget", minWidth: "80px" }],
        templateSampleLists: [
          {
            orgCode: "019",
            dcCode: "C000000",
            areaTag: "分区",
            yearMth: "202302",
            jheTarget: 100000,
            kssTarget: 500,
          },
        ],
        templateFileDownloadUrl: "https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/bi-admin-server/prod/%E5%8C%BA%E5%9F%9F%E5%88%86%E7%BB%84%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx" ,
        apiParams: "importList",
      },
      currentUser: {},
      dictFun: dictSelect,
      shopDictFun: shopDict,
      shopJsonParam: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
      }
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      console.log("query::::", query)
      pageAreaTagTargetList(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val) {
      if (val) {
        this.queryForm.createTimeBegin = val[0];
        this.queryForm.createTimeEnd = val[1];
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    handleImportData(val) {
      this.importDialog.visible = false;
      this.loading = true;
      const data = {
        data: Object.assign([], val["importList"])
      }
      importAreaTagTarget(data).then(res => {
        if (res.code === 200) {
          if (res.data.indexOf('很抱歉') > -1) {
            this.noticeDialog.msg = res.data;
            this.noticeDialog.visible =true;
          } else {
            Notification.success("操作成功！");
            this.handleFilter();
          }
          this.loading = false;
        } else {
          this.loading = false;
          // Message.error(res.msg);
        }
      });
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleExport() {
      if (!this.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.loading = true;
      exportAreaTarget(this.queryForm,).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '区域分组目标数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-input__inner {
  padding-left: 5px !important;
  max-width: 200px !important;
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
