<template>
  <div class="schedulingPlan-iframe-page">
    <div class="schedulingPlan-iframe-search">
      <!--            <v-signboard :title="'实际排产计划列表'" />-->
      <el-form
        class="main-search"
        size="mini"
        ref="actualProdPlan-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleSubmit"
      >
        <el-form-item label="年月" size="mini">
          <el-input v-model="form.plan_month" clearable placeholder="请输入年月"/>
        </el-form-item>
        <el-form-item label="订单类型" size="mini">
          <el-select clearable v-model="form.order_type">
            <el-option
              v-for="item in options.orderTypeOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原始料号" size="mini">
          <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
        </el-form-item>
        <el-form-item label="换算料号" size="mini">
          <el-input v-model="form.trans_material_code" clearable placeholder="请输入换算料号"/>
        </el-form-item>
        <el-form-item label="生产单位" size="mini">
          <el-input v-model="form.production_unit" clearable placeholder="请输入生产单位"/>
        </el-form-item>
        <el-form-item label="统计工艺" size="mini">
          <el-input v-model="form.craft" clearable placeholder="请输入统计工艺"/>
        </el-form-item>
        <el-form-item label="统计笔头" size="mini">
          <el-input v-model="form.tip_style" clearable placeholder="请输入统计笔头"/>
        </el-form-item>
        <el-button
          class="form-btn-item"
          size="mini"
          type="primary"
          @click="handleSearchAction('search')"
        >查 询
        </el-button>
        <el-button
          class="form-btn-item"
          size="mini"
          @click="handleSearchAction('reset')"
        >重 置
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="btnStatus.isExportLoading"
          @click="handleTableHeadAction('export')"
        >导 出
        </el-button>
      </el-form>
    </div>
    <div class="schedulingPlan-iframe-content">
      <el-table
        class="table"
        stripe
        border
        height="500"
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
      >
        <el-table-column label="序号" align="center" width="50" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.actualProdScheduligPlanHead">
          <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import {actualProdScheduligPlanHead} from '../config';
import {paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport} from "@/utils";
import {handleActualProdScheduligPlanLists} from '@/api/schedulingPlan';

export default {
  // 实际排产计划
  name: 'ActualProdScheduligPlan',
  mixins: [tableLoading, paginationParams, urlParams],
  components: {
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      form: {
        plan_month: '',
        order_type: '',
        material_code: '',
        tip_style: '',
        trans_material_code: '',
        production_unit: '',
        craft: ''
      },
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      options: {
        orderTypeOtions: [
          {label: '老品', value: 1},
          {label: '其他量预留', value: 2},
          {label: '其他量已下', value: 3},
        ]
      },
      btnStatus: {
        isDisable: true,
        isExportLoading: false,         // 导出loading
      },
      tableObj: {
        actualProdScheduligPlanHead
      },
      lists: []
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.handleUrlParams().then(res => {
      this.getLists();
    })
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 获取列表数据
    async getLists() {
      try {
        const order_type = {
          1: 0,
          2: 1,
          3: 2
        };
        const form = this.form;
        const formParams = {
          month: '',
          operator: this.urlParams.operator,
          plan_month: form.plan_month || '',
          order_type: order_type[form.order_type],
          trans_material_code: form.trans_material_code || '',
          production_unit: form.production_unit || '',
          tip_style: form.tip_style || '',
          material_code: form.material_code || '',
          craft: form.craft || ''
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        this.tableStatus.isLoading = true;
        const {code, data, msg} = await handleActualProdScheduligPlanLists(params);
        if (data) {
          const {item_list, total} = data;
          console.log(item_list, 'item_list-=-=-=-')
          this.modifyData(item_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取实际排单计划列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [],
          orderTypeText = {
            0: '老品',
            1: '其他量预留',
            2: '其他量已下'
          };
        lists.map(item => {
          let newItem = {
            plan_month: item.plan_month || '--',
            order_type: item.order_type || '--',
            model_code: item.model_code || '--',
            material_code: item.material_code || '--',
            race_name: item.race_name || item.order_type || '--',
            cargono: item.cargono || '--',
            grade: item.grade || '--',

            trans_material_code: item.trans_material_code || '--',
            specification: item.specification || '--',
            unit: item.unit || '--',
            production_unit: item.production_unit || '--',
            prod_type: item.prod_type || '--',

            craft: item.craft || '--',
            machinery_fit_style: item.machinery_fit_style || '--',
            mould_code: item.mould_code || '--',
            tip_style: item.tip_style || '--',
            order_no: item.order_no || '--',
            accept_order_date: item.accept_order_date || '--',
            deliver_date: item.deliver_date || '--',
            series: item.series || '--',

            this_mth_count: item.this_mth_count || 0,
            trans_rate: item.trans_rate || 0,
            mth_pc_count_whs: item.mth_pc_count_whs || 0,
            mth_pc_count_yhs: item.mth_pc_count_yhs || 0,
            again_pc_count: item.again_pc_count || 0,

            total_previous_put_count: item.total_previous_put_count || 0,
            plu_count_per_tray: item.plu_count_per_tray || 0,

            // 原始料号维度：上月底总部库存、理论请购量、月均销量、月底预计库存、库存系数
            hq_last_month_store_count: item.hq_last_month_store_count || 0,
            hq_expect_store_ratio: item.hq_expect_store_ratio || 0,
            hq_expect_store_count: item.hq_expect_store_count || 0,
            theory_shipment_count: item.theory_shipment_count || 0,
            hq_month_avg_sale_count: item.hq_month_avg_sale_count || 0,
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 头部筛选相关操作
    handleSearchAction(type) {
      const funcObj = {
        'search': () => {           // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {            // 重置
          this.form = {
            plan_month: '',
            order_type: '',
            trans_material_code: '',
            material_code: '',
            tip_style: '',
            production_unit: '',
            craft: ''
          };
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      const topHeaders = {}
      for (let i = 0; i < actualProdScheduligPlanHead.length; i++) {
        topHeaders[actualProdScheduligPlanHead[i].prop] = actualProdScheduligPlanHead[i].label
      }

      try {
        this.btnStatus.isExportLoading = true;
        const {code, data} = await handleActualProdScheduligPlanLists({
          operator: this.urlParams.operator,
          is_return_all: true
        });
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {item_list} = data;
          if (item_list.length > 0) {
            excelExport({
              topHeaders,
              data: item_list,
              title: '实际排单计划'
            })
          }
          ;
          if (item_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------实际排单计划导出接口有误');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.schedulingPlan-iframe {
  &-page {

  }

  &-search {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  &-content {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;

    .list-head {
      margin-bottom: 20px;
    }
    ::v-deep .el-table__empty-text {
      text-align: left;
    }
  }
}
</style>

