<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
              ref="org"
              type="org"
              :value.sync="queryForm.orgCode"
              linkageRef="dc,shop"
              :dictFun="dictFun"
              fullLabel
              default-first-option
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
              ref="dc"
              type="dc"
              :value.sync="queryForm.dcCode"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺编码">
          <el-input v-model="queryForm.shopCode" clearable placeholder="请输入店铺编码" />
        </el-form-item>
        <el-form-item label="年月" label-width="40px" style="margin-left: unset">
          <DictSelect
            ref="yearMth"
            type="shop-date"
            multiple
            :value.sync="queryForm.yearMths"
            :dictFun="shopDictFun"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="importDialog.visible = true">导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <el-button :disabled="pageData.selections.length === 0" class="form-btn-item" size="mini" icon="el-icon-remove" type="danger" @click="handleDelTargets">删除已选</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="targetTableRef"
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90" />
      <el-table-column label="分公司编码-名称" prop="orgCode">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心编码-名称" prop="dcCode">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺编码-名称" prop="shopCode">
        <template #default="{ row }">
          <span>{{ row.shopCode + (row.shopName ?'-'+ row.shopName:'') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="年月" prop="yearMth" width="60px" />
      <el-table-column label="全部款式">
        <el-table-column label="进货金额目标(元)" prop="allJheTarget" />
        <el-table-column label="总进货款数目标" prop="allKssTarget" />
        <el-table-column label="新增进货款数目标" prop="allNewKssTarget" />
      </el-table-column>
      <el-table-column label="操作" width="60" align="center" fixed="right">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px; color:red" @click="handleDelTargets(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />

    <ImportDialog :importBaseParams="importDialog" @handleImportCallBack="handleImportData" append-to-body :modal-append-to-body="false" />

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect, shopDict, deleteShopTarget, exportShopTarget } from "@/api/appTask";
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import { pageShopTargetList, importShopTarget } from "../../../../../api/appTask";

export default {
  name: "ShopTarget",
  components: { Pagination, ImportDialog: () => import("@/components/ImportDialog") , DictSelect},
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      },
      loading: false,
      userOptions: [],
      saveTitle: undefined,
      dialogTitle: {
        add: "新增商品组",
        edit: "修改商品组",
        view: "查看商品组",
      },
      saveDialog: {
        dialogVisible: false,
        form: {
          poiCode: undefined,
          dcCode: undefined,
          shopCode: undefined,
          shopCapacity: undefined,
        },
        rules: {
          shopCapacity: [{ required: true, message: "店铺不能为空", trigger: "change" }],
        },
      },
      importDialog: {
        visible: false,
        title: "店铺目标导入",
        type: "import",
        tipsText: "提示：三项目标数据至少一个不能为空</br>不存在的或近2年无进货店铺数据隔天将在聚宝盆APP显示</br>导入的店铺目标数据大概半个小时将在聚宝盆APP显示</br>聚宝盆APP中区域分组的完成率仅计算有设置目标的店铺。",
        templateSampleHead: [
          { label: "配送中心编码", prop: "dcCode", minWidth: "80px" },
          { label: "店铺编码", prop: "shopCode", minWidth: "80px" },
          { label: "周期", prop: "yearMth", minWidth: "80px" },
          { label: "全部款式-进货金额目标(元)", prop: "allJheTarget", minWidth: "160px" },
          { label: "全部款式-总进货款数目标", prop: "allKssTarget", minWidth: "160px" },
          { label: "全部款式-新增进货款数目标", prop: "allNewKssTarget", minWidth: "160px" },
        ],
        templateSampleLists: [
          {
            dcCode: "C000000",
            shopCode: "0001",
            yearMth: "202302",
            allJheTarget: 100000,
            allKssTarget: 500,
            allNewKssTarget: 200,
            nesJheTarget: 100000,
            nesKssTarget: 500,
            nesNewKssTarget: 200,
          },
        ],
        templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%BA%97%E9%93%BA%E7%9B%AE%E6%A0%87%E8%AE%BE%E5%AE%9A%E6%A8%A1%E6%9D%BFV2.xlsx',
        apiParams: "importList"
      },
      currentUser: {},
      dictFun: dictSelect,
      shopDictFun: shopDict,
      shopJsonParam: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
      }
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      console.log("query::::", query)
      pageShopTargetList(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          if (this.$refs.targetTableRef) {
            this.$refs.targetTableRef.doLayout();
          }
        })
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleImportData(val) {
      this.importDialog.visible = false;
      this.loading = true;
      const data = {
        data: Object.assign([], val["importList"])
      }
      importShopTarget(data).then(res => {
        if (res.code === 200) {
          if (res.data) {
            this.noticeDialog.msg = res.data;
            this.noticeDialog.visible= true;
          } else {
            Notification.success("操作成功！");
          }
          this.handleFilter();
        } else {
          this.loading = false;
          // Message.error(res.msg);
        }
      });
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleDelTargets(row) {
      let delParam = [];
      if (row && row.orgCode) {
        delParam.push(row);
      } else if (this.pageData.selections.length > 0) {
        delParam = Object.assign([], this.pageData.selections);
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      this.$confirm('请确认是否删除所选的数据', '提示', { type: 'warning' }).then(() => {
        deleteShopTarget({ list: delParam }).then((res) => {
          Notification.success("操作成功！");
          this.handleFilter();
        })
      }).catch(() => {
      })
    },
    handleExport() {
      if (!this.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.loading = true;
      exportShopTarget(this.queryForm,).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '店铺目标数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-input__inner {
  padding-left: 5px !important;
  max-width: 200px !important;
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}
</style>
