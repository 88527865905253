<template>
  <div style="padding: 20px">
    <el-container>
      <el-aside style="width: 420px; background-color: #d0d6db; padding: 15px;height: 700px; overflow-y: scroll;">
        <AppWebPreview :list="pageData.rows" type="summary" />
      </el-aside>
      <el-main>
        <div class="mg-search" style="padding-bottom: 15px">
          <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
            <el-form-item label="分公司">
              <el-select v-model="queryForm.orgCode" filterable clearable default-first-option>
                <el-option v-for="item in queryOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="pageData.rows"
          height="calc(100vh - 250px)"
          v-loading="loading"
          size="mini"
          highlight-current-row
          :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
        >
          <el-table-column label="任务名称" prop="taskName" width="200">
            <template slot-scope="scope">
              <i v-if="scope.row.isLeaf===0" class="el-icon-folder" style="color: dodgerblue">合集</i>&nbsp;&nbsp;<span>{{ scope.row.taskName }}</span>
              <span v-if="scope.row.isLeaf!==0 && scope.row.orgCode === 'ALL'">&nbsp;(全国)</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="right" width="330">
            <template slot-scope="scope">
              <el-row type="flex" justify="end">
                <el-col :span="5">
                  <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(scope.row, scope.row.isLeaf===0?'editHome':'editTaskHome')">{{scope.row.isLeaf===0 ?'入口样式':'编辑样式'}}</el-button>
                </el-col>
                <el-col  v-if="scope.row.isLeaf===0" :span="5">
                  <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(scope.row, 'groupContent')">合集内容</el-button>
                </el-col>
                <el-col v-if="scope.row.isLeaf===0" :span="3">
                  <el-button size="mini" type="text" style="border: 0px;" @click="handleSetTarget(scope.row)">目标<el-popover
                    placement="top-start"
                    width="300"
                    trigger="hover">
                    <i slot="reference" class="el-icon-question" />
                    <span slot>
                      提示内容：<br>
                      ①合集目标的店铺范围是合集内所有子任务的全部店铺；<br>
                      ②子任务配置的目标不会影响合集目标；<br>
                      ③目标是配置到具体店铺的，可以选择“统一设置”或“单店设置”，其中单店设置支持Excel导入。<br>
                    </span>
                  </el-popover></el-button>
                </el-col>
                <el-col v-if="scope.row.isLeaf===0" :span="3">
                  <el-button size="mini" type="text" style="border: 0px;" @click="deleteGroupTask(scope.row)">解散</el-button>
                </el-col>
                <el-col :span="3">
                  <el-button :disabled="scope.$index<=0" size="mini" type="primary" icon="el-icon-arrow-up" @click="handleMove(scope.$index, 'up')"></el-button>
                </el-col>
                <el-col :span="3">
                  <el-button :disabled="scope.$index===pageData.rows.length-1" size="mini" type="primary" icon="el-icon-arrow-down" @click="handleMove(scope.$index, 'down')"></el-button>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

        <div>
          <el-button size="mini" type="text" style="border: 0px; width: 50%; text-align: center" icon="el-icon-plus" @click="handleAdd">新建任务合集</el-button>
        </div>
        <div>
          <el-button size="mini" type="primary"  @click="refreshView('list')">刷新预览</el-button>
          <el-button size="mini" type="primary"  @click="saveSort">保存排序</el-button>
        </div>

        <el-drawer :title="groupDialog.dialogTitle[saveTitle]" :visible.sync="groupDialog.dialogVisible" direction="ltr" size="100%" custom-class="drawerClass" :wrapperClosable="false">
          <el-container style="height: 100%">
            <el-aside style="width: 420px; background-color: #d0d6db; padding: 15px;height: 700px; overflow-y: scroll;">
              <AppWebPreview v-if="saveTitle!=='groupContent'" :list.sync="groupDialog.tableTasks" type="summary" />
              <TaskGroupDetail v-if="saveTitle==='groupContent'" :task.sync="groupDialog.taskForm" :list="groupDialog.taskForm.tasks" />
            </el-aside>
            <el-main style="overflow-x: hidden;">
              <GroupHome ref="groupHomeRef" v-if="saveTitle==='add' || saveTitle === 'editHome'" :form.sync="groupDialog.form" />
              <GroupContent ref="groupContentRef" v-if="saveTitle==='groupContent'" :task.sync="groupDialog.taskForm" @task-change="(type, row, template) => handleGroupTasksChange(type, row, template)" />
              <TaskContent v-if="saveTitle==='editTaskHome'" ref="taskContentRef" :form.sync="groupDialog.taskHomeForm" :imageTemplate="undefined" :type="saveTitle" :orgCode="queryForm.orgCode" :exceptTaskIds="[]" />
              <div style="text-align: right">
                <el-button size="mini" type="primary"  @click="refreshView(refreshMap[saveTitle])">刷新预览</el-button>
                <el-button size="mini" type="primary" @click="submitForm()">{{ saveTitle ==='add'? '提交并进行下一步' :'提 交' }}</el-button>
                <el-button size="mini" :disabled="editLoading" @click="groupDialog.dialogVisible = false">取 消</el-button>
              </div>
            </el-main>
          </el-container>
        </el-drawer>

        <el-drawer :title="taskDialog.title[taskDialog.type]" :visible.sync="taskDialog.visible" direction="ltr" custom-class="drawerClass" size="100%" :wrapperClosable="false">
          <el-container style="height: 100%">
            <el-aside style="width: 420px; background-color: #d0d6db; padding: 15px;height: 700px; overflow-y: scroll;">
              <TaskGroupDetail v-if="taskDialog.type==='add' || taskDialog.type==='edit'" :type="(taskDialog.type==='add' || taskDialog.type==='edit')?'shop':'summary'" :task.sync="groupDialog.taskForm" :list="taskDialog.viewList" />
<!--              <AppWebPreview v-if="taskDialog.type==='add'" :list.sync="taskDialog.viewList" type="summary" />-->
            </el-aside>
            <el-main style="overflow-x: hidden;">
              <TaskContent ref="taskContentRef" :form.sync="taskDialog.form" :type="taskDialog.type" :orgCode="queryForm.orgCode" :imageTemplate="taskDialog.template" :exceptTaskIds="groupDialog.taskForm.tasks.map(e => e.mtTaskId)"
              @sync-config="val => $refs.groupContentRef.syncConfig(val)" />
              <div style="text-align: right">
                <el-button size="mini" type="primary"  @click="refreshView('taskContent')">刷新预览</el-button>
                <el-button size="mini" type="primary" @click="submitTaskContentForm('task_inside')">提 交</el-button>
                <el-button size="mini" :disabled="editLoading" @click="taskDialog.visible = false">取 消</el-button>
              </div>
            </el-main>
          </el-container>
        </el-drawer>
      </el-main>
    </el-container>

    <el-drawer :title="'设置目标 (' + currentRow.taskName + ')'" :visible.sync="targetDialog.visible" class="closed-header" size="90%" direction="rtl" append-to-body :close-on-click-modal="false" :wrapperClosable="false">
      <TargetV2 v-if="queryForm.orgCode" ref="target" :task-info="currentRow" :auth-org-codes="[queryForm.orgCode]"  @show-commit="(val) => {targetDialog.showCommit = val}" />
      <div class="dialog-footer" style="text-align: right;position: absolute; right: 50px;">
        <el-button v-show="targetDialog.showCommit === '0'" class="form-btn-item" size="mini" type="primary" @click="() => $refs.target.handleCommitUnify()">提交</el-button>
        <el-button size="mini" type="primary" @click="targetDialog.visible = false">关 闭</el-button>
      </div>
    </el-drawer>

    <el-dialog
      title=""
      :visible.sync="noticeDialog" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false"
      width="30%"
      center>
      <span style="    height: 80px;    font-size: 20px;    color: red;    font-weight: 700;}">任务排版全面升级，新版后台全力开发中。如需调整样式和顺序请联系聚宝盆同事进行配置</span>
    </el-dialog>

  </div>
</template>
<script>
import {
  listGroupTask,
  saveGroupTask,
  saveGroupTaskSort,
  deleteTask,
  dictSelect, getGroupTaskDetail
} from "@/api/appTask";
import {  parseTime, deepClone, hexToRgba } from "@/utils";
import { MessageBox , Notification } from "element-ui";
import OssUpload from "@/components/OssUpload";
import { homePageFields, summaryFields, shopFields, jlSummaryFields, jlShopFields, defaultTaskJson, jlTaskJson, groupTaskJson, groupTheme } from '../config';
import AppWebPreview from './components/AppWebPreview';
import GroupHome from './components/GroupHome';
import GroupContent from './components/GroupContent';
import TaskGroupDetail from './components/TaskGroupDetail';
import TaskContent from './components/TaskContent';
import TargetV2 from "../task/TargetV2";

export default {
  name: "TaskGroup",
  components: { OssUpload, AppWebPreview, GroupHome, GroupContent, TaskGroupDetail, TaskContent, TargetV2 },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "",
        1: "",
        2: "primary",
        3: "danger",
        4: "info",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        parentId: 'root',
        isLeaf: '0',
      },
      loading: false,
      queryOrgOptions: [],
      saveTitle: undefined,
      statusMap: {
        0: "草稿",
        1: "未开始",
        2: "已开始",
        3: "已结束",
        4: "已暂停",
      },
      currentRow: {
        taskId: ""
      },
      groupDialog: {
        imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        dialogVisible: false,
        dialogTitle: {
          add: "新增任务合集",
          "editHome": "修改任务合集",
          groupContent: '任务合集页面配置',
          addTask: '添加任务',
          editTaskHome: '设置任务展示字段'
        },
        tableTasks: [],
        taskHomeForm: {},
        taskForm: {
          tasks: []
        },
        form: {
          name: undefined,
          custom: '',
          template: 'blue',
          fields: ['brJhTotal', 'dqJhTotal'],
          fontColor: undefined,
          badgeFontColor: undefined,
          badgeColor: undefined,
        },
        rules: {
          name: [{required: true, message: " ", trigger: "change"}],
          template: [{required: true, message: " ", trigger: "change"}],
          fields: [{type: 'array', required: true, message: " ", trigger: "change"}],
          color: [{required: true, message: " ", trigger: "change"}],
        }
      },
      taskDialog: {
        visible: false,
        type: 'add',
        title: {
          add: '添加任务',
          edit: '修改任务字段'
        },
        template: undefined,
        form: {},
        viewList: [],
        tasks: []
      },
      homePageFields,
      summaryFields,
      shopFields,
      jlSummaryFields,
      jlShopFields,
      defaultTaskJson,
      jlTaskJson,
      groupTaskJson,
      refreshMap: {
        add: "group",
        editHome: "group",
        groupContent: 'groupContent',
        addTask: 'taskContent',
        editTaskHome: 'taskHome'
      },
      refMap: {
        add: "groupHomeRef",
        editHome: "groupHomeRef",
        groupContent: "groupContentRef",
        addTask: "taskContentRef",
        editTaskHome: "taskContentRef"
      },
      dictFun: dictSelect,
      parseShowTime: parseTime,
      editLoading: false,
      ifAllAuth: undefined,
      templateUrl: {
        blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E5%90%88%E9%9B%86-%E7%B4%AB%E8%89%B2.png',
        red: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task_group_bg.png',
        orange: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E6%A9%99%E8%89%B2%E5%B0%81%E9%9D%A2-%E8%83%8C%E6%99%AF.png'
      },
      targetDialog: {
        visible: false,
        showCommit: undefined
      },
      noticeDialog: true
    };
  },
  watch: {
  },
  computed: {},
  created() {
    dictSelect("org").then(res => {
      this.queryOrgOptions = res.data;
      this.queryForm.orgCode = res.data[0].value;
      this.handleFilter();
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      listGroupTask("?orgCode=" + this.queryForm.orgCode).then(res => {
        if (!res.data) {
          return false;
        }
        res.data.forEach(e => {
          if (e.isLeaf === 0) {
            if (!e.configJson) {
              e.configJson = groupTaskJson;
            } else {
              e.configJson= JSON.parse(e.configJson);
            }
          } else {
            if (!e.configJson) {
              if (e.boostTask) {
                e.configJson = jlTaskJson;
              } else {
                e.configJson = defaultTaskJson;
              }
            } else {
              e.configJson = JSON.parse(e.configJson);
            }
          }
        })
        this.loading = false;
        this.pageData.rows = res.data;
      });
    },
    setDefaultConfigJson(e) {
      if (e.isLeaf === 0) {
        if (!e.configJson) {
          e.configJson = groupTaskJson;
        } else {
          e.configJson= JSON.parse(e.configJson);
        }
        if (e.tasks.length > 0) {
          e.tasks.forEach(e => {
            if (!e.configJson) {
              e.configJson = defaultTaskJson;
            } else {
              e.configJson = JSON.parse(e.configJson);
            }
          })
        }
      } else {
        if (!e.configJson) {
          if (e.boostTask) {
            e.configJson = jlTaskJson;
          } else {
            e.configJson = defaultTaskJson;
          }
        } else {
          e.configJson = JSON.parse(e.configJson);
        }
      }
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleAdd() {
      this.editLoading = false;
      this.saveTitle = "add";
      this.currentRow = { taskId: undefined };
      this.resetGroupDialog();
      this.groupDialog.form = this.setDefaultGroupForm({});
    },
    resetGroupDialog() {
      this.groupDialog.tableTasks = [];
      this.groupDialog.dialogVisible = true;
      this.groupDialog.taskForm.taks = [];
      this.groupDialog.taskHomeForm = {};
      this.groupDialog.form = {
        name: undefined,
        custom: '',
        template: 'blue',
        fields: ['brJhTotal', 'dqJhTotal'],
        fontColor: undefined,
        badgeFontColor: undefined,
        badgeColor: undefined,
      }
    },
    async handleEdit(row, type) {
      this.resetGroupDialog();
      this.currentRow = deepClone(row);
      if (row.configJson) {
        if (type === 'add' || type === 'editHome') {
          this.groupDialog.tableTasks = [];
          this.groupDialog.form = {
            name: row.taskName,
            custom: row.configJson.theme.template === 'block' ?row.configJson.theme.background.image:'',
            template: row.configJson.theme.template,
            fields: row.configJson.summary.map(e => e.key),
            fontColor: row.configJson.theme.value.color,
            badgeColor: row.configJson.theme.badge.background.color,
            badgeFontColor: row.configJson.theme.badge.font.color,
          }
        } else if (type === 'groupContent') {
          await getGroupTaskDetail(row.mtTaskId).then(res => {
            this.groupDialog.taskForm.tasks = [];
            this.setDefaultConfigJson(res.data);
            this.groupDialog.taskForm = res.data;
          })
        } else if (type === 'editTaskHome') {
          this.groupDialog.taskHomeForm = deepClone(row);
        }
      } else {
        this.groupDialog.form = this.setDefaultGroupForm(row);
      }
      this.editLoading = false;
      this.saveTitle = type;
      this.groupDialog.dialogVisible = true;
    },
    setDefaultGroupForm(row) {
      return {
        name: row.taskName,
        custom: '',
        template: 'red',
        fields: ['brJhTotal', 'dqJhTotal'],
        fontColor: groupTaskJson.theme.value.color,
        badgeColor: groupTaskJson.theme.badge.background.color,
        badgeFontColor: groupTaskJson.theme.badge.font.color,
      };
    },
    submitForm() {
      let ref;
      let type;
      let form;
      if (this.saveTitle==='groupContent') {
        ref = this.$refs.groupContentRef;
        type = 'task_inside';
        form = deepClone(ref.page);
      } else if (this.saveTitle === 'editTaskHome') {
        ref = this.$refs.taskContentRef;
        type = 'task_inside';
        form = deepClone(this.groupDialog.taskHomeForm);
      } else {
        ref = this.$refs.groupHomeRef;
         type = 'task_outside'
        form = deepClone(this.groupDialog.form);
      }
      ref.formValid().then(valid => {
        if (valid) {
          this.confirmCommit(type, form);
        }
      });
    },
    submitTaskContentForm() {
      this.groupDialog.taskForm.tasks = this.filterArray(this.groupDialog.taskForm.tasks, this.taskDialog.form, 'mtTaskId');
      // this.groupDialog.taskForm.tasks.push(this.taskDialog.form);
      this.taskDialog.visible = false;
      this.$forceUpdate();
    },
    confirmCommit(type, form) {
      this.editLoading = true;
      let tmpRow = {};
      if (this.saveTitle === 'editTaskHome') {
        tmpRow = this.groupDialog.taskHomeForm;
      } else {
        tmpRow = deepClone(this.getTaskForm(type, form));
      }

      if (!tmpRow.configJson.page.title) {
        tmpRow.configJson.page.title = tmpRow.taskName;
      }
      tmpRow.configJson = JSON.stringify(tmpRow.configJson);

      saveGroupTask(tmpRow, type, this.queryForm.orgCode).then(res => {
        this.editLoading = false;
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
          this.groupDialog.dialogVisible = false;
          if (this.saveTitle === 'add') {
            this.handleEdit(res.data , 'groupContent')
          }
        }
      });
    },
    deleteGroupTask(row) {
      this.$confirm('请确认是否解散该合集！', '提示', { type: 'warning' }).then(() => {
        let delRow = deepClone(row);
        delRow.configJson = delRow.configJson? JSON.stringify(delRow.configJson): undefined;
        saveGroupTask(delRow, 'task_remove', delRow.orgCode).then(res => {
          this.editLoading = false;
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilter();
            this.groupDialog.dialogVisible = false;
          }
        });
      }).catch(() => {
      })
    },
    getTaskForm(type, form) {
      let tmpRow = deepClone(this.currentRow);
      let defaultGroupTaskJson = deepClone(tmpRow.configJson? tmpRow.configJson:groupTaskJson);
      if (typeof(defaultGroupTaskJson) === "string") {
        defaultGroupTaskJson = JSON.parse(defaultGroupTaskJson);
      }
      // 首页外
      if (type === 'task_outside') {
        defaultGroupTaskJson.page.title = form.name;
        tmpRow.taskName = form.name;
        defaultGroupTaskJson.theme.template = form.template;
        if (defaultGroupTaskJson.theme.template === 'block') {
          defaultGroupTaskJson.theme.background.image = form.custom;
        } else {
          defaultGroupTaskJson.theme.background.image = this.templateUrl[defaultGroupTaskJson.theme.template]
        }
        defaultGroupTaskJson.theme.value.color = form.fontColor;
        defaultGroupTaskJson.theme.badge.background.color = form.badgeColor;
        defaultGroupTaskJson.theme.badge.font.color = form.badgeFontColor;
        defaultGroupTaskJson.summary = homePageFields.filter(e => form.fields.indexOf(e.key) > -1);
        defaultGroupTaskJson.summary.forEach(e => {
          e.value.color = form.fontColor;
          e.label.color = hexToRgba(form.fontColor, 0.7);
        })
        defaultGroupTaskJson.shop = Object.assign([], defaultGroupTaskJson.summary);
        tmpRow.orgCode = this.queryForm.orgCode;
        tmpRow.taskRange='1';
        tmpRow.isLeaf = 0;
        tmpRow.orgName = this.queryOrgOptions.find(e => e.value == this.queryForm.orgCode).label;
        if (!defaultGroupTaskJson.page.title) {
          defaultGroupTaskJson.page.title = tmpRow.taskName;
        }
      } else {
        if (form.configJson) {
          form.configJson = defaultGroupTaskJson;
        }
        defaultGroupTaskJson.page.title = form.title;
        // 合集任务内部
        defaultGroupTaskJson.page.template = form.template;
        defaultGroupTaskJson.page.customHeadImage = form.customHeadImage;
        defaultGroupTaskJson.page = {
          title: form.title,
          template: form.template,
          customHeadImage: form.customHeadImage,
          background: {
            image: form.customHeadImage? form.customImageUrl :form.background.image,
            color: form.background.customColor
          },
        }
        tmpRow.description = form.description;
      }
      if (this.saveTitle === 'groupContent') {
        tmpRow.tasks = deepClone(this.groupDialog.taskForm.tasks);
        tmpRow.tasks.forEach(t => t.configJson = (t.configJson ?JSON.stringify(t.configJson): undefined));
      }
      tmpRow.configJson = defaultGroupTaskJson;
      return tmpRow;
    },
    handleDelete(row) {
      deleteTask(row.mtTaskId).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      });
    },
    handleMove(index, type) {
      const row = this.pageData.rows[index];
      if (type === "up") {
        this.pageData.rows.splice(index, 1)
        this.pageData.rows.splice(index - 1, 0, row)
      } else if (type === "down") {
        this.pageData.rows.splice(index, 1)
        this.pageData.rows.splice(index + 1, 0, row)
      }
    },
    refreshView(type) {
      if (type === 'group') {
        const tmpForm = this.getTaskForm('task_outside', this.groupDialog.form);
        this.groupDialog.tableTasks = Object.assign([], this.filterArray(Object.assign([], this.pageData.rows), tmpForm, 'mtTaskId'));
        this.$forceUpdate();
      } else if (type === 'taskHome') {
        this.groupDialog.tableTasks = Object.assign([], this.filterArray(Object.assign([], this.pageData.rows), this.groupDialog.taskHomeForm, 'mtTaskId'));
        this.$forceUpdate();
      } else if (type === 'groupContent') {
        // const tmpForm = this.getTaskForm('task_inside', this.groupDialog.taskForm);
        // this.taskDialog.viewList = Object.assign([], this.filterArray(Object.assign([], this.groupDialog.taskForm.tasks), this.groupDialog.taskForm, 'mtTaskId'));
        this.$forceUpdate();
      } else if (type === 'taskContent') {
        this.taskDialog.viewList = Object.assign([], this.filterArray(Object.assign([], this.groupDialog.taskForm.tasks), this.taskDialog.form, 'mtTaskId'));
        this.$forceUpdate();
      }
    },
    filterArray(list, item, key) {
      let tmpTasks = [];
      let addFlag = true;
      list.forEach(e => {
        if (e[key] !== item[key]) {
          tmpTasks.push(e);
        } else {
          tmpTasks.push(deepClone(item));
          addFlag = false;
        }
      })
      if (addFlag) {
        tmpTasks.push(deepClone(item));
      }
      return tmpTasks;
    },
    saveSort() {
      saveGroupTaskSort({ orgCode: this.queryForm.orgCode, taskIds: this.pageData.rows.map(e => e.mtTaskId)}).then((res) => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    handleSetTarget(row) {
      this.currentRow = deepClone(row);
      getGroupTaskDetail(row.mtTaskId).then(res => {
        if (res.data.tasks.length > 0) {
          this.targetDialog.visible = true;
        } else {
          Notification.warning("当前任务并未绑定相应的任务，请在编辑合集内容内绑定任务后，再开始进行目标设置！");
        }
      })
    },
    handleGroupTasksChange(type, row, template) {
      debugger
      this.taskDialog.type = type;
      this.taskDialog.viewList = [];
      if (!row) {
        row = {};
      }
      this.taskDialog.form = row;
      this.taskDialog.template = template;
      this.taskDialog.visible = true;
      setTimeout(() => {
        this.refreshView('taskContent');
      }, 500)
      this.$forceUpdate();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
::v-deep .el-divider--horizontal {
  margin: 8px 0px;
}
::v-deep .el-drawer__header {
  margin-bottom: 2px;
  padding: 2px;
  margin-top: 20px;
}
.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

::v-deep {
  .el-upload--picture-card {
    height:30px;
    width: 80px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:80px;
    width: 60px;
  }
  .drawerClass>.el-drawer__body {
    overflow-y: hidden;
  }
  .taskDrawerClass>.el-drawer__body {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .el-dialog__header {
    padding: 0px;
  }
}

</style>
<style lang="scss" scoped src='@/style/appTask/groupItem.scss'></style>
