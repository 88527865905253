
export default {
  props: {
    keyboard: Boolean,
    refEnter: {
      type: String,
      default: 'mgPaginationTable',
    },
    keyboardVxeConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 记录当前聚焦的元素
      currentFocusDom: null
    }
  },
  mounted() {
    if (this.keyboard) {
      this.$refs[this.refEnter].$el.addEventListener('keyup', this.handleKeyUp, false)
    }
  },
  beforeDestroy() {
    if (this.keyboard) {
      this.$refs[this.refEnter].$el.removeEventListener('keyup', this.handleKeyUp, false)
    }
  },
  methods: {
    /**
     * 响应键盘事件
     */
    handleKeyUp(e) {
      e.preventDefault()
      e.stopPropagation()
      
      const {
        activeRow, 
        activeCell, 
        activeRowFormItems, 
        preRowItems, 
        preCellFirstIndex, 
        nextRowItems, 
        nextCellFirstIndex 
      } = this.handleActiveAboutCell();

      const keyActions = {
        // Enter
        13: () => {
          this.setFocusCell(activeRow.nextSibling)
        },
        // ArrowLeft
        37: () => {
          if (this.keyboardVxeConfig?.isArrow) {
            // 获取当前激活单元格的位置
            const activeRowFormItemIndex = activeRowFormItems.findIndex(item => item.cellIndex === activeCell.cellIndex)
            const setFocusCell = activeRowFormItems[activeRowFormItemIndex - 1] || (preRowItems[preCellFirstIndex] || [])
            this.setFocusCell(setFocusCell)
          }
          if (e.shiftKey) {
            this.$refs.mgMiniPagination?.handlePageChange(-1)
            this.getCurrentHightLightRow()
          }
        },
        // ArrowUp
        38: () => {
          this.keyboardVxeConfig?.isArrow && this.setFocusCell(activeRow.previousSibling)
        },
        // ArrowRight
        39: () => {
          if (this.keyboardVxeConfig?.isArrow) {
             // 获取当前激活单元格的位置
            const activeRowFormItemIndex = activeRowFormItems.findIndex(item => item.cellIndex === activeCell.cellIndex)
            const setFocusCell = activeRowFormItems[activeRowFormItemIndex + 1] || (nextRowItems[nextCellFirstIndex] || [])
            this.setFocusCell(setFocusCell)
          }
          if (e.shiftKey) {
            this.$refs.mgMiniPagination?.handlePageChange(1)
            this.getCurrentHightLightRow()
          }
        },
        // ArrowDown
        40: () => {
          this.keyboardVxeConfig?.isArrow && this.setFocusCell(activeRow.nextSibling)
        }
      }
      keyActions[e.keyCode] && keyActions[e.keyCode]()
    },
    // 获取当前激活单元格相关的位置
    handleActiveAboutCell() {
      // 当前激活单元行
      const activeRow = this.getCurrentActiveRow()
      if (!activeRow) return
      // 当前激活的单元格
      const activeCell = this.getCurrentActiveCell(activeRow)

      // 获取当前行的单元格
      const activeRowCells = activeRow.cells && Array.from(activeRow.cells) || []
      // 获取当前行的表单项
      const activeRowFormItems = activeRowCells.filter(item => item.querySelector('.el-input, .el-select'))|| []

      // 获取上一行的末尾位置
      const preRowItems = activeRow.previousSibling?.cells && Array.from(activeRow.previousSibling?.cells) || []
      const preCellFirstIndex = preRowItems.findLastIndex(item => item.querySelector('.el-input, .el-select'))

      // 获取下一行的起始位置
      const nextRowItems = activeRow.nextSibling?.cells && Array.from(activeRow.nextSibling?.cells) || []
      const nextCellFirstIndex = nextRowItems.findIndex(item => item.querySelector('.el-input, .el-select'))

      return { activeRow, activeCell, activeRowCells, activeRowFormItems, preRowItems, preCellFirstIndex, nextRowItems, nextCellFirstIndex }
    },
    /**
     * 获取当前高亮行
     */
    getCurrentHightLightRow() {
      this.$nextTick(() => {
        this.getElTable && this.getElTable().setCurrentRow(this.getData[0])
        setTimeout(() => {
          const currentRow = this.$el.querySelector('.el-table__body-wrapper .el-table__row.current-row')
          this.setFocusCell(currentRow)
        }, 0)
      })
    },
    /**
     * 获取当前活动的行
     */
    getCurrentActiveRow() {
      // 获取当前活动行
      let activeRow = document.activeElement
        while (activeRow && activeRow.tagName !== 'TR') {
          activeRow = activeRow.parentElement
        }
        if (activeRow) {
          this.activeRowIndex = activeRow.rowIndex
          return activeRow
        }
        if (!activeRow) return false
    },
    /**
     * 获取当前活动的单元格
     */
    getCurrentActiveCell(activeRow) {
      return Array.from(activeRow.cells).find((el, index) => {
        this.activeColIndex = index
        return el.querySelector('input[type="text"]') === document.activeElement
      })
    },
    /**
     * 设置单元格焦点
     */
    setFocusCell(activeRow) {
      if (!activeRow || !(activeRow instanceof HTMLElement)) return
      let cell = null;
      const tagNames = {
        "TR": () => {
          this.activeRowIndex = activeRow.rowIndex;
          const activeTRCell = activeRow?.cells[this.activeColIndex]
          // TODO: 查找表单元素的方法可再优化，目前比较局限
          return  activeTRCell.querySelector('.el-input, .el-select')
        },
        "TD": () => activeRow.querySelector('.el-input, .el-select')
      }
      activeRow.click()
      cell = tagNames[activeRow.tagName] && tagNames[activeRow.tagName]()
      this.currentFocusDom = cell
      if (cell) {
        cell.__vue__.focus()
        cell.__vue__.select()
        cell.__vue__.$el.click()
      }
    },
  },
}
