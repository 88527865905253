<style>
.warning input {
  color: red;
  background-color: #f0ffbe;
}
.el-table_1_column_7, th.current_store_ratio {
  background-color: cyan !important;
}
.el-table_1_column_31, th.plan_month_store_ratio {
  background-color: blanchedalmond !important;
}
</style>
<template>
  <div>
    <el-tabs class="salesCenter-tabs" tab-position="top" v-model="classifyConfigActiveName" style="height: 200x;">
      <el-tab-pane label="手工调整" class="salesCenter-tabs-item" name="classifyConfigOnlineWrite">
        <div class="salesCenter-search">
          <v-signboard :title="'分类设定'"/>
          <el-form
            class="main-search"
            size="mini"
            label-width="auto"
            ref="classifyConfig-form"
            :inline="true"
            :model="form"
            @keyup.enter.native="handleClassifyConfigQuery"
          >

            <el-form-item label="产品赛道" size="mini">
              <el-select clearable v-model="form.race_name" style="width:90px">
                <el-option
                  v-for="item in options.raceNameLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="大类" size="mini">
              <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
                <el-option
                  v-for="item in options.productMaxCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中类" size="mini">
              <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                <el-option
                  v-for="item in options.productMidCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button
              class="form-btn-item"
              size="mini"
              type="primary"
              :loading="btnStatus.isSub"
              @click="handleSubmit"
            >查 询
            </el-button>
            <el-button
              class="form-btn-item"
              size="mini"
              :loading="btnStatus.isReset"
              @click="handleReset"
            >重 置
            </el-button>

            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isSave"
              @click="handleTableHeadAction('save')"
            >保 存
            </el-button>

            <el-button
              size="mini"
              type="primary"
              :loading="btnStatus.isExportLoading"
              @click="handleTableHeadAction('export')"
            >导 出
            </el-button>

            <el-button
              size="mini"
              type="info"
              :disabled="btnStatus.noSelectedRow"
              @click="handleTableHeadAction('set_default_aim')"
            >默 认 库 存 目 标
            </el-button>
          </el-form>
        </div>
        <div class="salesCenter-content">
          <el-table
            class="table"
            stripe
            border
            size="mini"
            ref="classifyConfigTable"
            :data="lists"
            :cell-style="columnCellStyle"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" min-width="50px" fixed="left"/>
            <el-table-column label="序号" align="center" min-width="50px" fixed="left">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>

            <template v-for="(item, index) in tableObj.classifyConfigHead">

              <el-table-column v-if="item.prop.indexOf('category') > -1 || item.prop === 'race_name'" v-bind="item" show-overflow-tooltip :key="index" align="center" fixed="left"></el-table-column>
            </template>

            <el-table-column :label="currentRatioLabel" align="center" style="background-color: #6cf40a">
              <template v-for="(item, index) in tableObj.classifyConfigHead">
                <el-table-column v-if="item.prop.indexOf('current_store_ratio') > -1 " v-bind="item" show-overflow-tooltip :key="index" align="center" label-class-name="current_store_ratio"></el-table-column>
              </template>
            </el-table-column>

            <el-table-column :label="planMonthRatioLabel" align="center">
              <template v-for="(item, index) in tableObj.classifyConfigHead" >
                <el-table-column v-if="item.prop.indexOf('plan_month_store_ratio') > -1 " v-bind="item" show-overflow-tooltip :key="index" align="center" label-class-name="plan_month_store_ratio"></el-table-column>
              </template>
            </el-table-column>

            <el-table-column :label="storeRatioAimLabel" align="center">
              <template v-for="(item, index) in tableObj.classifyConfigHead">
                <!-- 库存系数上限 / 库存系数下限 / 库存系数目标 -- 可编辑 -->
                <el-table-column
                  v-if="item.prop.indexOf('aim_s') > -1 || item.prop.indexOf('aim_a') > -1 || item.prop.indexOf('aim_b') > -1 || item.prop.indexOf('aim_c_plus') > -1 || item.prop === 'store_ratio_aim_c'"
                  v-bind="item" :key="index" align="center">
                  <template slot-scope="scope">
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_s < 0 || scope.row.store_ratio_aim_s > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_s'" v-model="scope.row.store_ratio_aim_s" size="mini"
                              clearable/>
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_a_plus < 0 || scope.row.store_ratio_aim_a_plus > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_a_plus'" v-model="scope.row.store_ratio_aim_a_plus"
                              size="mini" clearable/>
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_a < 0 || scope.row.store_ratio_aim_a > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_a'" v-model="scope.row.store_ratio_aim_a"
                              size="mini" clearable/>
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_b_plus < 0 || scope.row.store_ratio_aim_b_plus > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_b_plus'" v-model="scope.row.store_ratio_aim_b_plus"
                              size="mini" clearable/>
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_b < 0 || scope.row.store_ratio_aim_b > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_b'" v-model="scope.row.store_ratio_aim_b"
                              size="mini" clearable/>
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_c_plus < 0 || scope.row.store_ratio_aim_c_plus > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_c_plus'" v-model="scope.row.store_ratio_aim_c_plus"
                              size="mini" clearable/>
                    <el-input type="number" :disabled="scope.row.isEdit"
                              :class="scope.row.store_ratio_aim_c < 0 || scope.row.store_ratio_aim_c > 1.5 ? 'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'"
                              v-if="item.prop === 'store_ratio_aim_c'" v-model="scope.row.store_ratio_aim_c"
                              size="mini" clearable/>
                  </template>
                </el-table-column>
                <el-table-column v-else-if="item.prop.indexOf('aim') > -1 " v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane label="文件导入" class="salesCenter-tabs-item" name="classifyConfigImport">
        <div clas="form-upload-box" v-if="classifyConfigActiveName === 'classifyConfigImport'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'"/>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item"
                                 align="center"/>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'"/>
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                height="500"
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>



  </div>
</template>

<script>
import {paginationParams, tableLoading, urlParams, optionsObj} from '@/mixins';
import {excelExport} from "@/utils";
import {classifyConfigHead} from '../config';
import {handleSaveClassifyConfig} from '@/api/salesCenter';
import {handleClassifyConfigLists, handleImportClassifyConfig, handleStoreRatioImport} from "../../../api/salesCenter";
import {getCurrentShortYM, getPlanShortYM} from "../../../utils";

export default {
  // 库存系数设定
  name: 'classifyConfig',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination"),
  },
  data() {
    return {
      originalForm: {},                       // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 是否保存
        noSelectedRow: true,                   // 是否系数初始化
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        classifyConfigHead
      },
      selectRows: [],
      uploadFileObj: {
        tipsText: '必需字段: 赛道、大类、中类、S、A+、A、B+、B、C+、C',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: [
          {label: '赛道', prop: 'race_name', minWidth: '80px'},
          {label: '大类', prop: 'main_category_name', minWidth: '80px'},
          {label: '中类', prop: 'mid_category_name', minWidth: '80px'},
          {label: '统计品类', prop: 'stats_category_name', minWidth: '80px'},
          {label: 'S', prop: 'store_ratio_aim_s', minWidth: '90px'},
          {label: 'A+', prop: 'store_ratio_aim_a_plus', minWidth: '90px'},
          {label: 'A', prop: 'store_ratio_aim_a', minWidth: '90px'},
          {label: 'B+', prop: 'store_ratio_aim_b_plus', minWidth: '90px'},
          {label: 'B', prop: 'store_ratio_aim_b', minWidth: '90px'},
          {label: 'C+', prop: 'store_ratio_aim_c_plus', minWidth: '90px'},
          {label: 'C', prop: 'store_ratio_aim_c', minWidth: '90px'},
        ],
        templateSampleLists: [
          {
            race_name: '大众',
            main_category_name: '书写工具',
            mid_category_name: '中性笔',
            stats_category_name: 'KA',
            store_ratio_aim_s: 1.5,
            store_ratio_aim_a_plus: 1.5,
            store_ratio_aim_a: 1.5,
            store_ratio_aim_b_plus: 1.5,
            store_ratio_aim_b: 1.5,
            store_ratio_aim_c_plus: 1.5,
            store_ratio_aim_c: 1
          }
        ]
      },
      currentRatioLabel : getCurrentShortYM() + "月品类库存系数",
      planMonthRatioLabel : getPlanShortYM() + "月品类库存系数(根据设定值计算)",
      storeRatioAimLabel : getPlanShortYM() + "月品类目标设定系数",
      urlMonth: '',
      classifyConfigActiveName: 'classifyConfigOnlineWrite'
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length === 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] !== __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.noSelectedRow = false;
          btnStatus.isAdjust = false;
        }
        if (n.length === 0) {
          btnStatus.isSave = true;
          btnStatus.noSelectedRow = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: ''
    };
    this.form = formPrams;
    this.originalForm = Object.assign({}, this.form);
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  methods: {
    columnCellStyle(data) {
      let columnValue = data.row[data.column.property];
      if (typeof columnValue === "number" && !isNaN(columnValue)) {
        if (columnValue < 0 || columnValue > 1.5) {
          return "color:red;background-color:#f0ffbe;";
        }
      }
      return '';
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    handleClassifyConfigQuery() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        prod_type: ''
      };
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          race_name: form.race_name || '',
          data_type: form.data_type || '',
          plan_month: this.urlMonth || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          operator: this.urlParams.operator,
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleClassifyConfigLists(params);
        this.tableStatus.isLoading = false;
        if (data) {
          const {config_list, total} = data;
          this.modifyData(config_list).then(res => {
            this.lists = res;
          });
          this.total = total;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取库存系数列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          newItems.push(item);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 列表操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {        // 保存
          let newItems = [];

          let needWarning = false;

          this.selectRows.map(item => {

            if (item.store_ratio_aim_s < 0 || item.store_ratio_aim_s > 2.5 ||
              item.store_ratio_aim_a_plus < 0 || item.store_ratio_aim_a_plus > 2.5 ||
              item.store_ratio_aim_a < 0 || item.store_ratio_aim_a > 2.5 ||
              item.store_ratio_aim_b_plus < 0 || item.store_ratio_aim_b_plus > 2.5 ||
              item.store_ratio_aim_b < 0 || item.store_ratio_aim_b > 2.5 ||
              item.store_ratio_aim_c_plus < 0 || item.store_ratio_aim_c_plus > 2.5 ||
              item.store_ratio_aim_c < 0 || item.store_ratio_aim_c > 2.5 ||
              item.store_ratio_aim_c_minus < 0 || item.store_ratio_aim_c_minus > 2.5 ||
              item.store_ratio_aim_d < 0 || item.store_ratio_aim_d > 2.5 ||
              item.store_ratio_aim_n < 0 || item.store_ratio_aim_n > 2.5 ||
              item.store_ratio_aim_c2_t < 0 || item.store_ratio_aim_c2_t > 2.5) {
              console.log("needWarning");
              console.log(item);
              needWarning = true;
            }

            newItems.push(item);
          });

          if(needWarning) {
            this.$confirm('存在系数范围异常(<0 or > 2.5)，确认要保存么?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              const params = {
                operator: this.urlParams.operator,
                plan_month: this.urlMonth,
                config_list: newItems
              };
              const loading = this.$loading({
                lock: true,
                text: '处理中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });

              const {code, msg} = await handleSaveClassifyConfig(params);
              loading.close();
              this.$refs['classifyConfigTable'].clearSelection();
              if (code === '0000') {
                this.tableStatus.isLoading = true;
                this.getLists();
                return this.$message.success('保存库存系数目标成功！');
              }
              if (code !== '0000') this.getLists();
            }).catch(() => {
              this.$message('库存系数目标未保存！');
            });
          } else {
            const params = {
              operator: this.urlParams.operator,
              plan_month: this.urlMonth,
              config_list: newItems
            };
            const {code, msg} = await handleSaveClassifyConfig(params);
            this.$refs['classifyConfigTable'].clearSelection();
            if (code === '0000') {
              this.tableStatus.isLoading = true;
              this.getLists();
              return this.$message.success('保存库存系数目标成功！');
            }
            if (code !== '0000') this.getLists();
          }
        },
        'set_default_aim': () => {
          this.selectRows.map(item => {
            item.store_ratio_aim_s = 1;
            item.store_ratio_aim_a = 1;
            item.store_ratio_aim_a_plus = 1;
            item.store_ratio_aim_b_plus = 1;
            item.store_ratio_aim_b = 1;
            item.store_ratio_aim_c = 1;
            item.store_ratio_aim_c_plus = 1;
            item.store_ratio_aim_c_minus = 0;
            item.store_ratio_aim_n = 0;
            item.store_ratio_aim_d = 0;
            item.store_ratio_aim_c2_t = 0;
          });
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      let topHeaders = {}
      for (let i = 0; i < classifyConfigHead.length; i++) {
        let row = classifyConfigHead[i];

        if(row.prop.indexOf("current_store_ratio") > -1) {
          topHeaders[row.prop] = row.label + "_" + getCurrentShortYM() + "月品类库存系数";
        } else if (row.prop.indexOf("plan_month_store_ratio") > -1) {
          topHeaders[row.prop] = row.label + "_" + getPlanShortYM() + "月品类库存系数(根据设定值计算)";
        } else if(row.prop.indexOf("aim") > -1) {
          topHeaders[row.prop] = row.label + "_" + getPlanShortYM() + "月品类目标设定系数";
        } else {
          topHeaders[row.prop] = row.label;
        }
      }
      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          data_type: form.data_type || '',
          plan_month: this.urlMonth || '',
          operator: this.urlParams.operator,
        };

        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleClassifyConfigLists(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {config_list} = data;
          if (config_list.length > 0) {
            excelExport({
              topHeaders,
              data: config_list,
              title: '库存系数分类设定'
            })
          }
          if (config_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------库存系数分类设定导出接口有误');
      }
    },
    // 监听是否使用系统默认值发生change
    handleSwitchChange(index, row) {
      this.lists.map((item, i) => {
        if (i === index) {
          if (row.isEdit) {
            row.isEdit = false;
            row.use_default = 0;
            return;
          }
          if (!row.isEdit) {
            row.isEdit = true;
            row.use_default = 1;
            return;
          }
        }
      })
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header
    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;

      // {label: '赛道', prop: 'race_name', minWidth: '80px'},
      // {label: '大类', prop: 'main_category_name', minWidth: '80px'},
      // {label: '中类', prop: 'mid_category_name', minWidth: '80px'},
      // {label: '统计品类', prop: 'stats_category_name', minWidth: '80px'},
      // {label: 'S', prop: 'store_ratio_aim_s', minWidth: '90px'},
      // {label: 'A+', prop: 'store_ratio_aim_a_plus', minWidth: '90px'},
      // {label: 'A', prop: 'store_ratio_aim_a', minWidth: '90px'},
      // {label: 'B+', prop: 'store_ratio_aim_b_plus', minWidth: '90px'},
      // {label: 'B', prop: 'store_ratio_aim_b', minWidth: '90px'},
      // {label: 'C+', prop: 'store_ratio_aim_c_plus', minWidth: '90px'},
      // {label: 'C', prop: 'store_ratio_aim_c', minWidth: '90px'},

      const headList = this.uploadFileObj.templateSampleHead;
      for(let i = 0; i < headList.length; i++) {
        let item = headList[i];
        if (uploadFileObj.tableHeader.indexOf(item.label) === -1) return this.$message.warning('缺少' + item.label + '字段');
      }

      // if (uploadFileObj.tableHeader.indexOf('赛道') === -1) return this.$message.warning('缺少赛道字段');

      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let newItem = {
          race_name: item['赛道'],
          main_category_name: item['大类'],
          mid_category_name: item['中类'],
          stats_category_name: item['统计品类'],
          store_ratio_aim_s: item['S'],
          store_ratio_aim_a_plus: item['A+'],
          store_ratio_aim_a: item['A'],
          store_ratio_aim_b_plus: item['B+'],
          store_ratio_aim_b: item['B'],
          store_ratio_aim_c_plus: item['C+'],
          store_ratio_aim_c: item['C'],
        }
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        config_list: newItems
      };
      const {code, success, msg} = await handleImportClassifyConfig(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (success) return this.$message.success(msg);
      if (!success) this.activeName = 'onlineWrite';
    }
  }
}
</script>
<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
