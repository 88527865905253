var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改送货路线",
        visible: _vm.visible,
        width: "400px",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "dialogContent",
        },
        [
          _c(
            "div",
            { staticClass: "routeSelectBox" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("送货路线")]),
              _c(
                "el-select",
                {
                  attrs: {
                    "allow-create": "",
                    filterable: "",
                    placeholder: "请选择送货路线",
                  },
                  model: {
                    value: _vm.routeId,
                    callback: function ($$v) {
                      _vm.routeId = $$v
                    },
                    expression: "routeId",
                  },
                },
                _vm._l(_vm.routes, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确认修改")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }