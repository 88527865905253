var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["container", _vm.isDisablePage ? "container-disable" : ""] },
    [
      _vm.isShowTips
        ? _c(
            "div",
            { staticClass: "container-top" },
            [
              _c("mg-fixed-top", {
                attrs: { isFullWidth: true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "left",
                      fn: function () {
                        return [
                          _c("el-alert", {
                            attrs: {
                              type: _vm.tipsConfig.type,
                              title: _vm.tipsConfig.title,
                              closable: false,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1523096177
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container-header" },
        [_vm._t("headerSearch")],
        2
      ),
      _vm._t("default"),
      _c("div", { staticClass: "container-table" }, [_vm._t("table")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }