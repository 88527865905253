<template>
  <div style="padding: 15px">
    <div>
      <DictSelect ref="dept" :value.sync="queryForm.dept" allow-create size="mini" placeholder="请选择部门" :dictFun="getDeptDict" @change="handleDeptChange" />
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" style="margin: auto 10px;" @click="handleQuery">查询</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" style="margin: auto 10px; " @click="addDialog.visible = true">新增</el-button>
    </div>
    <el-table ref="tt" :data="pageData.rows" max-height="500px" v-loading="loading" size="mini" border highlight-current-row>
      <el-table-column label="平台" prop="platform" width="200" />
      <el-table-column label="部门" prop="deptName" width="140" />
      <el-table-column label="序号" prop="sort" width="90" />
      <el-table-column label="操作" width="90" text-align="center">
        <template #default="{ row }">
          <el-popconfirm
            confirm-button-text='确定'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            title="请确认是否删除该条数据"
            @confirm="handleDelete(row)"
          >
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :currentPage="queryForm.current" :pageSize="queryForm.size" @pageChange="handleFilter" />


    <el-dialog title="新增" :visible.sync="addDialog.visible" width="400px">
      <el-form ref="addForm" :rules="addDialog.rules" label-width="auto" size="mini" :model="addDialog.form">
        <el-form-item label="平台" prop="platform">
          <el-input v-model="addDialog.form.platform" placeholder="请输入平台名称" />
        </el-form-item>
        <el-form-item label="部门" prop="dept">
          <DictSelect ref="dept" :value.sync="addDialog.form.dept" allow-create placeholder="请选择部门" :dictFun="getDeptDict" @change="handleDeptChange" />
        </el-form-item>
        <el-form-item label="部门名称" prop="deptName">
          <el-input v-model="addDialog.form.deptName" placeholder="请输入部门名称" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="addDialog.form.sort" :precision="0" :controls="false" placeholder="默认升序" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="addDialog.visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { urlParams } from "@/mixins";
import { addPlatform, delPlatform, pagePlatform, getDeptDict } from "@/api/spider";
import Pagination from "../../../components/Element/Pagination.vue";
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  components: { Pagination, DictSelect },
  // 平台数据维护
  name: "Platform",
  mixins: [urlParams],
  data() {
    return {
      loading: false,
      queryForm: {
        current: 1,
        size: 10,
        dept: this.$route.query.dept? this.$route.query.dept: 'tech'
      },
      pageData: {
        total: 0,
        rows: [],
      },
      addDialog: {
        visible: false,
        form: {
          dept: undefined,
          deptName: undefined,
          platform: undefined,
          sort: undefined
        },
        rules: {
          dept: [{ required: true, message: " ", trigger: "change" }],
          deptName: [{ required: true, message: " ", trigger: "change" }],
          platform: [{ required: true, message: " ", trigger: "change" }],
          sort: [{ required: true, message: " ", trigger: "change" }],
        }
      },
      getDeptDict: getDeptDict
    };
  },
  created() {
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      this.queryForm.current = 1;
      this.handleFilter();
    },
    handleFilter() {
      this.loading = true;
      pagePlatform(this.queryForm).then(res => {
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    resetForm() {
      const form = {
        dept: undefined,
        deptName: undefined,
        platform: undefined,
        sort: undefined
      }
      this.addDialog.form = form;
    },
    handleDeptChange(val) {
      if (val) {
        const obj = this.$refs.dept.itemOptions.find(e => e.value === 'val');
        if (obj) {
          this.addDialog.form.deptName = obj.label;
        } else {
          this.addDialog.form.deptName = undefined;
        }
      }
    },
    submit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          addPlatform(this.addDialog.form).then(res => {
            if (res.code === 200) {
              Notification.success('操作成功！');
              this.addDialog.visible = false;
              this.handleQuery();
              this.resetForm();
            }
          })
        }
      })
    },
    handleDelete(row) {
      delPlatform(row).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功！');
          this.resetForm();
          this.handleQuery();
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped src="@/style/spider/index.scss"></style>
