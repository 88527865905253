var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入话题标题关键字",
                        },
                        model: {
                          value: _vm.queryForm.topicName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "topicName", $$v)
                          },
                          expression: "queryForm.topicName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "话题所属分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.authOrgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "authOrgCode", $$v)
                                },
                                expression: "queryForm.authOrgCode",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "话题状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.topicStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "topicStatus", $$v)
                            },
                            expression: "queryForm.topicStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "ALL" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未审核", value: "P" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已通过", value: "Q" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已拒绝", value: "A" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属板块" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.queryForm.topicCollectionName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm,
                                "topicCollectionName",
                                $$v
                              )
                            },
                            expression: "queryForm.topicCollectionName",
                          },
                        },
                        _vm._l(_vm.topicCollectionNameOptions, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { value: item, label: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否推荐" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.ifRecommend,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "ifRecommend", $$v)
                            },
                            expression: "queryForm.ifRecommend",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "ALL" },
                          }),
                          _c("el-option", {
                            attrs: { label: "推荐", value: "1" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleBatchTopicStatusUpdate("Q")
                },
              },
            },
            [_vm._v("批量通过")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "90",
                  selectable: (row, index) => {
                    return _vm.computeButtonShow(row.topicStatus, "已通过")
                  },
                },
              }),
              _c("el-table-column", {
                attrs: { label: "话题ID", prop: "topicId", width: "230" },
              }),
              _c("el-table-column", {
                attrs: { label: "话题标题", prop: "topicName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "话题内容",
                  prop: "topicContent",
                  width: "500",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _c("span", [_vm._v(_vm._s(row.topicContent))]),
                        ]),
                        _vm._l(row.modelNames, function (item) {
                          return _c(
                            "div",
                            {
                              key: item,
                              staticStyle: {
                                "text-align": "left",
                                "font-size": "10px",
                                color: "#C5C5C5",
                              },
                            },
                            [_c("span", [_vm._v("商品：" + _vm._s(item))])]
                          )
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "font-size": "10px",
                              color: "#C5C5C5",
                            },
                          },
                          [
                            row.taskName && row.taskName !== ""
                              ? _c("span", [
                                  _vm._v("任务：" + _vm._s(row.taskName)),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "nickName", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgAuthName", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属板块",
                  prop: "topicCollectionName",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "更新时间", prop: "createTime", width: "140" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "帖子数",
                  prop: "experienceCount",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "浏览量", prop: "viewCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "评论", prop: "answerCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "点赞", prop: "likeCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "收藏", prop: "collectCount", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "topicStatus", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { label: "类型", prop: "type", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "250",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.orgAuthCode == _vm.queryForm.authOrgCode ||
                        _vm.authOrgAll
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  size: "mini",
                                  disabled: row.topicStatus === "已通过",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTopicStatusUpdate("Q", row)
                                  },
                                },
                              },
                              [_vm._v("通过")]
                            )
                          : _vm._e(),
                        row.orgAuthCode == _vm.queryForm.authOrgCode ||
                        _vm.authOrgAll
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  size: "mini",
                                  disabled: row.topicStatus === "已拒绝",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTopicStatusUpdate("A", row)
                                  },
                                },
                              },
                              [_vm._v("不通过")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.recommendFlag === "1"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTopicRecommendUpdate(
                                      "0",
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("取消推荐")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.recommendFlag === "0"
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTopicRecommendUpdate(
                                      "1",
                                      row
                                    )
                                  },
                                },
                              },
                              [_vm._v("设为推荐")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }