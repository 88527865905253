<template>

  <el-tabs class="salesCenter-tabs" tab-position="top" v-model="activeName" style="height: 200x;">

    <el-tab-pane label="分公司客订检核" class="salesCenter-tabs-item" name="boKdCheck">

      <div class="salesCenter-page">
        <div class="salesCenter-search">
          <el-form
            class="main-search"
            size="mini"
            label-width="auto"
            ref="filialeCustome-form"
            :inline="true"
            :model="form"
            @keyup.enter.native="handleHeadAction('search')"
          >
            <el-form-item label="产品等级" size="mini">
              <el-select v-model="form.productGrade" clearable multiple style="width:90px">
                <el-option
                  v-for="item in options.productGradeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="大类" size="mini">
              <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')"
                         style="width:90px">
                <el-option
                  v-for="item in options.productMaxCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中类" size="mini">
              <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                <el-option
                  v-for="item in options.productMidCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小类" size="mini">
              <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
                <el-option
                  v-for="item in options.productMinCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品赛道" size="mini">
              <el-select clearable v-model="form.race_name" style="width:90px">
                <el-option
                  v-for="item in options.raceNameLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原始料号" size="mini">
              <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
            </el-form-item>
            <el-form-item label="数据类型" size="mini">
              <el-select clearable v-model="form.data_type" style="width:90px">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="异常" :key="1" :value="1"></el-option>
                <el-option label="有客订" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="品名" size="mini">
              <el-input v-model="form.plu_name" clearable placeholder="请输入品名"/>
            </el-form-item>
            <el-form-item label="统计品类" size="mini">
              <el-input v-model="form.stats_category_name" clearable placeholder="请输入统计品类"/>
            </el-form-item>

            <el-form-item label="预淘汰品" size="mini">
              <el-select clearable v-model="form.dead_sku_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="是" :key="1" :value="1"></el-option>
                <el-option label="否" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-button
              class="form-btn-item"
              size="mini"
              type="primary"
              :loading="btnStatus.isSearch"
              @click="handleHeadAction('search')"
            >查 询
            </el-button>
            <el-button
              class="form-btn-item"
              size="mini"
              :loading="btnStatus.isReset"
              @click="handleHeadAction('reset')"
            >重 置
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isSave"
              @click="handleTableHeadAction('save')"
            >保 存
            </el-button>

            <el-button
              size="mini"
              type="primary"
              :loading="btnStatus.isExportLoading"
              @click="handleTableHeadAction('export')"
            >导 出
            </el-button>

            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader') "
              size="mini"
              type="warning"
              @click="handleTableHeadAction('adjust')"
            >确 认 调 整
            </el-button>
          </el-form>
        </div>
        <div class="salesCenter-content">
          <el-table
            class="table"
            stripe
            border
            height="500"
            size="mini"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="40" fixed="left"/>
            <el-table-column label="序号" align="center" width="50" fixed="left">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableObj.filialeOrdCheckHead">
              <el-table-column v-if="item.prop == 'confirm_bo_kd_plu_count_box'" v-bind="item"
                               show-overflow-tooltip :key="index" align="center">
                <template slot-scope="scope">
                  <el-input
                    class="tgqrcount-cell-ipt"
                    type="number"
                    v-model="scope.row.confirm_bo_kd_plu_count_box"
                    size="mini"
                    clearable
                  />
                </template>
              </el-table-column>
              <el-table-column v-else-if="['cargono'].includes(item.prop)" :fixed="'left'" v-bind="item"
                               show-overflow-tooltip :key="index" align="center"></el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </div>

    </el-tab-pane>

    <el-tab-pane label="分公司下单情况" class="salesCenter-tabs-item" name="orderSituation">
      <iframe src="//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E5%2588%2586%25E5%2585%25AC%25E5%258F%25B8%25E4%25B8%258B%25E5%258D%2595%25E6%2583%2585%25E5%2586%25B5.cpt&ref_t=design&ref_c=73ddd091-2b97-4b12-a326-26640b29eeef" frameborder="0" style="width: 100%; height: 90vh;"></iframe>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport, keepTwoDecimals, thousandsSeparator} from "@/utils";
import {filialeOrdCheckHead} from '../config';
import {handleConfirmOrderTotal, handlefilialeCustomerOrderLists, handleSaveOrderTotal} from '@/api/salesCenter';
import {keepOneDecimals} from "../../../utils";

export default {
  // 分公司客订检核
  name: 'filialeCustomerOrderCheck',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vPagination: () => import("@/components/Element/Pagination"),
  },
  data() {
    let data = {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSearch: false,                // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 保存
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        filialeOrdCheckHead
      },
      activeName: 'boKdCheck',
      selectRows: [],
      confirm_bo_kd_plu_count_copy: '',
      urlMonth: '',                       // 月份
    }
    console.log(data)
    return data
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isAdjust = false;
        }
        ;
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      data_type: 1,
      ...this.form,
      material_code: '',
    };
    this.form = formPrams;
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 返回
    handleBack() {
      this.$router.go(-1);
    },
    handleHeadAction(type) {
      const funcObj = {
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {                // 重置
          this.form = {
            productGrade: '',
            productFirstCategorie: '',
            productFirstCategorieName: '',
            productMiddleCategorie: '',
            productMiddleCategorieName: '',
            productLittleCategorie: '',
            productLittleCategorieName: '',
            data_type: 1,
            race_name: '',
            plu_name : '',
            stats_category_name : '',
            dead_sku_type : 0,
          };
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          material_code: form.material_code,
          month: this.urlMonth || '',
          race_name: form.race_name || '',
          operator: this.urlParams.operator,
          data_type: form.data_type || 0,
          plu_name : form.plu_name,
          stats_category_name: form.stats_category_name,
          dead_sku_type : form.dead_sku_type
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handlefilialeCustomerOrderLists(params);
        if (data) {
          const {plu_order_list, total} = data;
          this.modifyData(plu_order_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取分公司客订检核列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            order_month: item.order_month || '--',
            material_code: item.material_code || '--',
            plu_code: item.plu_code || '--',
            cargono: item.cargono || '--',
            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            plu_id: item.plu_id || '--',
            plu_name: item.plu_name || '--',

            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',

            grade: item.grade || '--',

            hq_last_month_sale_count_box: thousandsSeparator(item.hq_last_month_sale_count_box, 0) || 0,
            hq_pre_last_six_month_avg_sale_count_box: thousandsSeparator(item.hq_pre_last_six_month_avg_sale_count_box, 0) || 0,
            hq_pre_last_three_month_avg_sale_count_box: thousandsSeparator(item.hq_pre_last_three_month_avg_sale_count_box, 0) || 0,
            hq_last_three_month_sale_count_box: thousandsSeparator(item.hq_last_three_month_sale_count_box, 0) || 0,
            hq_last_six_month_sale_count_box: thousandsSeparator(item.hq_last_six_month_sale_count_box, 0) || 0,

            origin_bo_kd_plu_count_box: thousandsSeparator(item.origin_bo_kd_plu_count_box, 0) || 0,
            hq_last_month_store_count_box: thousandsSeparator(item.hq_last_month_store_count_box, 0) || 0,
            bo_last_month_stock_count_box: thousandsSeparator(item.bo_last_month_stock_count_box, 0) || 0,
            order_ratio: keepOneDecimals(item.order_ratio) || '--',
            bo_last_month_stock_ratio: keepTwoDecimals(item.bo_last_month_stock_ratio) || 0,
            hq_last_month_store_ratio: keepTwoDecimals(item.hq_last_month_store_ratio) || 0,
            order_no: item.order_no || 0,
            price: item.price || 0,
            confirm_bo_kd_plu_count_box: item.confirm_bo_kd_plu_count_box || 0
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.getLists();
    },
    // 全选函数
    async handleselection(e) {
      this.selectRows = e;
    },
    // 列表操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {     // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              confirm_bo_kd_plu_count_box: item.confirm_bo_kd_plu_count_box || '',
              plu_id: item.plu_id || ''
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            order_month: this.urlMonth,
            plu_list: newItems
          };
          const {code, msg} = await handleSaveOrderTotal(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'adjust': async () => {   // 确认审核
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator
          };
          const {success} = await handleConfirmOrderTotal(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('分公司客订检核确认调整已成功');
          }
          if (!success) this.getLists();
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      let topHeaders = {}
      for (let i = 0; i < filialeOrdCheckHead.length; i++) {
        let row = filialeOrdCheckHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          material_code: form.material_code,
          month: this.urlMonth || '',
          race_name: form.race_name || '',
          operator: this.urlParams.operator,
          data_type: form.data_type || 0
        };
        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handlefilialeCustomerOrderLists(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {plu_order_list} = data;
          if (plu_order_list.length > 0) {
            excelExport({
              topHeaders,
              data: plu_order_list,
              title: '分公司客订检核列表'
            })
          }
          if (plu_order_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------分公司客订检核列表导出接口有误');
      }
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
