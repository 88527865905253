import Router from "vue-router";
// @ts-ignore
import Layout from "@/layout";
let routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    meta: { title: "首页" },
    // @ts-ignore
    component: () => import("@/views/home/index"),
  },
  {
    path: "/salesCenter",
    name: "salesCenter",
    component: Layout,
    meta: { title: "销售中心" },
    children: [
      {
        path: "filialeCustomerOrderCheck",
        name: "filialeCustomerOrderCheck",
        meta: { title: "分公司客订检核" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/filialeCustomerOrderCheck"),
      },
      {
        path: "coefficientOfInventorySetting",
        name: "coefficientOfInventorySetting",
        meta: { title: "库存系数设定" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/coefficientOfInventorySetting"),
      },
      {
        path: "minimumOrderAdjust",
        name: "minimumOrderAdjust",
        meta: { title: "起订量调整" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/minimumOrderAdjust"),
      },
      {
        path: "buyingRequisitionConfirm",
        name: "buyingRequisitionConfirm",
        meta: { title: "请购单确认" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/buyingRequisitionConfirm"),
      },
      {
        path: "billAlertReport",
        name: "billAlertReport",
        meta: { title: "加单预警报表" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/billAlertReport"),
      },
      {
        path: "pluOrderReport",
        name: "pluOrderReport",
        meta: { title: "请购单临时汇总" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/pluOrderReport"),
      },
      {
        path: "userRole",
        name: "userRole",
        meta: { title: "权限控制" },
        // @ts-ignore
        component: () => import("@/views/salesCenter/userRole"),
      },
    ],
  },
  {
    path: "/prodCenter",
    name: "prodCenter",
    component: Layout,
    meta: { title: "生产中心" },
    children: [
      {
        path: "home",
        name: "prodCenterHome",
        meta: { title: "生产中心首页" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/prodCenterHome"),
      },
      {
        path: "moldProdCapacity",
        name: "moldProdCapacity",
        meta: { title: "模具产能" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/moldProdCapacity"),
      },
      {
        path: "penPointProdCapacity",
        name: "penPointProdCapacity",
        meta: { title: "笔头产能" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/penPointProdCapacity"),
      },
      {
        path: "machineryFittingProdCapacity",
        name: "machineryFittingProdCapacity",
        meta: { title: "机装产能" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/machineryFittingProdCapacity"),
      },
      {
        path: "moldingMachineProdCapacity",
        name: "moldingMachineProdCapacity",
        meta: { title: "注塑机产能" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/moldingMachineProdCapacity"),
      },
      {
        path: "mysteryBoxProdCapacity",
        name: "mysteryBoxProdCapacity",
        meta: { title: "盲盒机产能" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/mysteryBoxProdCapacity"),
      },

      {
        path: "locationTransferPrintProdCapacity",
        name: "locationTransferPrintProdCapacity",
        meta: { title: "定位转印产能" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/locationTransferPrintProdCapacity"),
      },
      {
        path: "handmadeProdCapacity",
        name: "handmadeProdCapacity",
        meta: { title: "工艺大类产能盘点" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/handmadeProdCapacity"),
      },
      {
        path: "lastMonthCarryQuantity",
        name: "lastMonthCarryQuantity",
        meta: { title: "本月结转量(工艺)" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/lastMonthCarryQuantity"),
      },
      {
        path: "minimumOrderAdjust",
        name: "prodMinimumOrderAdjust",
        meta: { title: "起订量调整" },
        // @ts-ignore
        component: () => import("@/views/prodCenter/minimumOrderAdjust"),
      },
    ],
  },
  {
    path: "/configCenter",
    name: "configCenter",
    component: Layout,
    meta: { title: "配置中心" },
    children: [
      {
        path: "preLastMonthCarryMaterial",
        name: "preLastMonthCarryMaterial",
        meta: { title: "上月结转量(料号)" },
        // @ts-ignore
        component: () => import("@/views/configCenter/preLastMonthCarryMaterial"),
      },
      {
        path: "capacityLimitConfig",
        name: "capacityLimitConfig",
        meta: { title: "产能上下限设置" },
        // @ts-ignore
        component: () => import("@/views/configCenter/capacityLimitConfig"),
      },
      {
        path: "materialDimReport",
        name: "materialDimReport",
        meta: { title: "物料维表" },
        // @ts-ignore
        component: () => import("@/views/configCenter/materialDimReport"),
      },
      {
        path: "pluDimReport",
        name: "pluDimReport",
        meta: { title: "产品维表" },
        // @ts-ignore
        component: () => import("@/views/configCenter/pluDimReport"),
      },
    ],
  },
  {
    path: "/otherQuantity",
    name: "otherQuantity",
    // @ts-ignore
    component: () => import("@/views/otherQuantity/index"),
    meta: { title: "其他量导入" },
  },
  {
    path: "/schedulingPlan",
    name: "schedulingPlan",
    component: Layout,
    meta: { title: "排单计划" },
    children: [
      {
        path: "strategySelection",
        name: "strategySelection",
        meta: { title: "策略选择" },
        // @ts-ignore
        component: () => import("@/views/schedulingPlan/strategySelection"),
      },
      {
        path: "customAdjustment",
        name: "customAdjustment",
        meta: { title: "自定义调整" },
        // @ts-ignore
        component: () => import("@/views/schedulingPlan/customAdjustment"),
      },
      {
        path: "pluOrderReport",
        name: "schedulingPlanPluOrderReport",
        meta: { title: "请购单临时汇总" },
        // @ts-ignore
        component: () => import("@/views/schedulingPlan/pluOrderReport"),
      },
      {
        path: "productionSchedule",
        name: "productionSchedule",
        meta: { title: "排产计划确认" },
        // @ts-ignore
        component: () => import("@/views/schedulingPlan/productionSchedule"),
      },
    ],
  },
  {
    path: "/thirdPartyPage",
    name: "thirdPartyPage",
    component: Layout,
    meta: { title: "第三方页面" },
    children: [
      {
        path: "prodCenterSchedulingPlan",
        name: "prodCenterSchedulingPlan",
        meta: { title: "排单计划" },
        // @ts-ignore
        component: () => import("@/views/thirdPartyPage/prodCenterSchedulingPlan"),
      },
    ],
  },
  {
    path: "/branchOffice",
    name: "branchOffice",
    component: Layout,
    meta: { title: "分公司智能月单" },
    children: [
      {
        path: "monthlyOrderCustomized",
        name: "monthlyOrderCustomized",
        meta: { title: "分公司月单定制" },
        // @ts-ignore
        component: () => import("@/views/branchOffice/monthlyOrderCustomized"),
      },
      {
        path: "addOrderWarning",
        name: "addOrderWarning",
        meta: { title: "分公司加单预警" },
        // @ts-ignore
        component: () => import("@/views/branchOffice/addOrderWarning"),
      },
      {
        path: "inventorySetting",
        name: "inventorySetting",
        meta: { title: "库存设定" },
        // @ts-ignore
        component: () => import("@/views/branchOffice/inventorySetting"),
      },
      {
        path: "purchaseConfirmation",
        name: "purchaseConfirmation",
        meta: { title: "要货确认" },
        // @ts-ignore
        component: () => import("@/views/branchOffice/purchaseConfirmation"),
      },
      {
        path: "configurationCenter",
        name: "configurationCenter",
        meta: { title: "配置中心-商品" },
        // @ts-ignore
        component: () => import("@/views/branchOffice/configurationCenter"),
      },
    ],
  },
  {
    path: "/newPublish",
    name: "newPublish",
    component: Layout,
    meta: { title: "新品上市" },
    children: [
      {
        path: "newPublishReport",
        name: "newPublishReport",
        meta: { title: "新品上市智能化报表" },
        // @ts-ignore
        component: () => import("@/views/newPublish/newPublishReport"),
      },
      {
        path: "newPublishDetail",
        name: "newPublishDetail",
        meta: { title: "新品上市跟进明细" },
        // @ts-ignore
        component: () => import("@/views/newPublish/newPublishDetail"),
      },
    ],
  },
  {
    path: "/raceActivity",
    name: "raceActivity",
    component: Layout,
    meta: { title: "赛道活动" },
    children: [
      {
        path: "raceActivityList",
        name: "raceActivityList",
        meta: { title: "赛道活动列表" },
        // @ts-ignore
        component: () => import("@/views/raceActivity/raceActivityList"),
      },
      {
        path: "addActivity",
        name: "addActivity",
        meta: { title: "新增活动" },
        // @ts-ignore
        component: () => import("@/views/raceActivity/addActivity"),
      },
      {
        path: "raceActivityReport",
        name: "raceActivityReport",
        meta: { title: "赛道自助报表" },
        // @ts-ignore
        component: () => import("@/views/raceActivity/raceActivityReport"),
      },
    ],
  },
  {
    path: "/spider",
    name: "spider",
    component: Layout,
    meta: { title: "数据采集" },
    children: [
      {
        path: "spiderData",
        name: "spiderData",
        meta: { title: "数据采集执行状态" },
        // @ts-ignore
        component: () => import("@/views/spider/spiderData"),
      },
      {
        path: "spiderLog",
        name: "spiderLog",
        meta: { title: "采集日志" },
        // @ts-ignore
        component: () => import("@/views/spider/spiderLog"),
      },
      {
        path: "platform",
        name: "platform",
        meta: { title: "平台管理" },
        // @ts-ignore
        component: () => import("@/views/spider/platform"),
      },
      {
        path: "auth",
        name: "auth",
        meta: { title: "权限控制" },
        // @ts-ignore
        component: () => import("@/views/spider/auth"),
      },
    ],
  },
  {
    path: "/stockToShop",
    name: "stockToShop",
    component: Layout,
    meta: { title: "仓到店" },
    children: [
      {
        path: "detail",
        name: "detail",
        meta: { title: "明细" },
        component: () => import("@/views/stockToShop/detail"),
      },
      {
        path: "skuDetail",
        name: "skuDetail",
        meta: { title: "门店sku详情" },
        component: () => import("@/views/stockToShop/skuDetail"),
      },
      {
        path: "collect",
        name: "collect",
        meta: { title: "汇总" },
        component: () => import("@/views/stockToShop/collect"),
      },
      {
        path: "synthesize",
        name: "synthesize",
        meta: { title: "综合页面" },
        component: () => import("@/views/stockToShop/synthesize"),
      },
    ],
  },
  {
    path: "/appTask",
    name: "appTask",
    component: Layout,
    meta: { title: "APP任务" },
    children: [
      {
        path: "skuGroup",
        name: "skuGroup",
        meta: { title: "商品组" },
        component: () => import("@/views/appTask/skuGroup"),
      },
      {
        path: "shopGroup",
        name: "shopGroup",
        meta: { title: "店铺组" },
        component: () => import("@/views/appTask/shopGroup"),
      },
      {
        path: "task",
        name: "task",
        meta: { title: "任务" },
        component: () => import("@/views/appTask/task"),
      },
      {
        path: "shopUpdate",
        name: "shopUpdate",
        meta: { title: "店铺更新" },
        component: () => import("@/views/appTask/shopUpdate"),
      },
      {
        path: "goods",
        name: "goods",
        meta: { title: "商品管理" },
        component: () => import("@/views/appTask/goods"),
      },
      {
        path: "topShop",
        name: "topShop",
        meta: { title: "重点店铺" },
        component: () => import("@/views/appTask/topShop/topNew"),
      },
      {
        path: "course",
        name: "course",
        meta: { title: "聚宝盆教程" },
        component: () => import("@/views/appTask/course"),
      },
      {
        path: "push",
        name: "push",
        meta: { title: "聚宝盆后台推送" },
        component: () => import("@/views/appTask/push"),
      },
      {
        path: "redPacket",
        name: "redPacket",
        meta: { title: "聚宝盆红包后台" },
        component: () => import("@/views/appTask/redPacket"),
      },
      {
        path: "taskGroup",
        name: "taskGroup",
        meta: { title: "任务合集" },
        component: () => import("@/views/appTask/taskGroup"),
      },
      {
        path: "boostPacket",
        name: "boostPacket",
        meta: { title: "激励红包" },
        component: () => import("@/views/appTask/boostPacket"),
      },
      {
        path: "jl",
        name: "jl",
        meta: { title: "聚宝盆后台推送" },
        component: () => import("@/views/appTask/task/Jl"),
      },
      {
        path: "orgBranch",
        name: "orgBranch",
        meta: { title: "分公司组织" },
        component: () => import("@/views/appTask/orgBranch"),
      },
      {
        path: "survey",
        name: "survey",
        meta: { title: "问卷管理" },
        component: () => import("@/views/appTask/survey"),
      },
      {
        path: "shopAudit",
        name: "shopAudit",
        meta: { title: "店铺审核" },
        component: () => import("@/views/appTask/shopAudit"),
      },
      {
        path: "taskModule",
        name: "taskModule",
        meta: { title: "任务模块" },
        component: () => import("@/views/appTask/taskModule"),
      },
      {
        path: "hxAudit",
        name: "hxAudit",
        meta: { title: "核销审核" },
        component: () => import("@/views/appTask/hxAudit"),
      },
      {
        path: "verification",
        name: "verification",
        meta: { title: "核销配置" },
        component: () => import("@/views/appTask/hxAudit/config"),
      },
      {
        path: "auth",
        name: "auth",
        meta: { title: "用户任务授权" },
        component: () => import("@/views/appTask/auth"),
      },
      {
        path: "plate",
        name: "plate",
        meta: { title: "板块管理" },
        component: () => import("@/views/appTask/plate"),
      },
      {
        path: "topic",
        name: "topic",
        meta: { title: "话题管理" },
        component: () => import("@/views/appTask/topic"),
      },
      {
        path: "experience",
        name: "experience",
        meta: { title: "帖子管理" },
        component: () => import("@/views/appTask/experience"),
      },
      {
        path: "skuFeature",
        name: "skuFeature",
        meta: { title: "商品特性" },
        component: () => import("@/views/appTask/skuFeature"),
      },
      {
        path: "skuTag",
        name: "skuTag",
        meta: { title: "商品标签" },
        component: () => import("@/views/appTask/skuTag"),
      },
      {
        path: "skuPackage",
        name: "skuPackage",
        meta: { title: "商品包" },
        component: () => import("@/views/appTask/skuPackage"),
      },
      {
        path: "module",
        name: "module",
        meta: { title: "商品包" },
        component: () => import("@/views/appTask/module"),
      },
      {
        path: "banner",
        name: "banner",
        meta: { title: "appBanner" },
        component: () => import("@/views/appTask/banner"),
      },
      {
        path: "qdHx",
        name: "qdHx",
        meta: { title: "渠道核销" },
        component: () => import("@/views/appTask/acitivityHx"),
      },
      {
        path: "skuActivity",
        name: "skuActivity",
        meta: { title: "商品活动" },
        component: () => import("@/views/appTask/skuActivity"),
      },
      {
        path: "238Top",
        name: "238Top",
        meta: { title: "238预估补货重点商品" },
        component: () => import("@/views/appTask/238Top"),
      },
      {
        path: "shopMerge",
        name: "shopMerge",
        meta: { title: "店铺合并" },
        component: () => import("@/views/appTask/shopMerge"),
      },
      {
        path: "areaTag",
        name: "areaTag",
        meta: { title: "区域分组" },
        component: () => import("@/views/appTask/areaTag"),
      },
      {
        path: "deliveRouteSetting",
        name: "deliveRouteSetting",
        meta: { title: "送货路线设置" },
        component: () => import("@/views/appTask/deliveRouteSetting"),
      },
      {
        path: "noStockOrderConfig",
        name: "noStockOrderConfig",
        meta: { title: "无库存下单配置" },
        component: () => import("@/views/appTask/noStockOrder/config"),
      },
      {
        path: "noStockOrder",
        name: "noStockOrder",
        meta: { title: "无库存下单订单" },
        component: () => import("@/views/appTask/noStockOrder/order"),
      },
      {
        path: "jxTarget",
        name: "jxTarget",
        meta: { title: "绩效" },
        component: () => import("@/views/appTask/jxTarget"),
      }
      ,
      {
        path: "noStockOrderReport",
        name: "noStockOrderReport",
        meta: { title: "无库存下单商品报表" },
        component: () => import("@/views/appTask/noStockOrder/report"),
      }
    ],
  },
  {
    path: "/biOrg",
    name: "biOrg",
    component: Layout,
    meta: { title: "分公司相关" },
    children: [
      {
        path: "autoSupply",
        name: "autoSupply",
        meta: { title: "自动补货" },
        component: () => import("@/views/biOrg/autoSupply/config"),
      },
      {
        path: "autoSupplyLog",
        name: "autoSupplyLog",
        meta: { title: "自动补货配置日志" },
        component: () => import("@/views/biOrg/autoSupply/log"),
      }
    ],
  },
  {
    path: "/tagPlatform",
    name: "tagPlatform",
    component: Layout,
    meta: { title: "标签平台" },
    children: [
      {
        path: "tag",
        name: "tag",
        meta: { title: "标签列表" },
        component: () => import("@/views/tagPlatform/tag"),
      },
      {
        path: "loopSelect",
        name: "loopSelect",
        meta: { title: "圈选分组" },
        component: () => import("@/views/tagPlatform/loopSelect"),
      },
      {
        path: "tagGroup",
        name: "tagGroup",
        meta: { title: "分组列表" },
        component: () => import("@/views/tagPlatform/tagGroup"),
      },
      {
        path: "tagReach",
        name: "tagReach",
        meta: { title: "触达漏斗" },
        component: () => import("@/views/tagPlatform/tagReach"),
      },
      {
        path: "distribute",
        name: "distribute",
        meta: { title: "渠道分发" },
        component: () => import("@/views/tagPlatform/distribute"),
      },
      {
        path: "dataReview",
        name: "dataReview",
        meta: { title: "数据复盘" },
        component: () => import("@/views/tagPlatform/dataReview"),
      },
      {
        path: "logicTag",
        name: "logicTag",
        meta: { title: "逻辑标签" },
        component: () => import("@/views/tagPlatform/logicTag"),
      },
    ],
  },
  {
    path: "/e-commerce",
    name: "e-commerce",
    component: Layout,
    meta: { title: "电商" },
    children: [
      {
        path: "tech",
        name: "tech",
        meta: { title: "电商科技" },
        component: () => import("@/views/e-commerce/tech"),
      },
      {
        path: "jdbh",
        name: "jdbh",
        meta: { title: "京东补货" },
        component: () => import("@/views/e-commerce/jdbh"),
      },
      {
        path: "ecHasten",
        name: "ecHasten",
        meta: { title: "电商催货" },
        component: () => import("@/views/e-commerce/ecHasten"),
      },
      {
        path: "techMatch",
        name: "techMatch",
        meta: { title: "匹配关系调整(型号&规格)" },
        component: () => import("@/views/e-commerce/techMatch"),
      },
      {
        path: "priceAnalysis",
        name: "priceAnalysis",
        meta: { title: "匹配关系调整(型号&规格)" },
        component: () => import("@/views/e-commerce/priceAnalysis"),
      },
      {
        path: "techPrice",
        name: "techPrice",
        meta: { title: "匹配关系&控价分析" },
        component: () => import("@/views/e-commerce/techPrice"),
      },
      {
        path: "singlePriceControlRemind",
        name: "singlePriceControlRemind",
        meta: { title: "单品控价提醒" },
        component: () => import("@/views/e-commerce/singlePriceControlRemind"),
      },
      {
        path: "techSpuManage",
        name: "techSpuManage",
        meta: { title: "组合装控价&货品套装组成关系" },
        component: () => import("@/views/e-commerce/techSpuManage"),
      },
    ],
  },
  {
    path: "/sales",
    name: "sales",
    component: Layout,
    meta: { title: "销售相关" },
    children: [
      {
        path: "stockUp",
        name: "stockUp",
        meta: { title: "备货申请" },
        // @ts-ignore
        component: () => import("@/views/sales/stockUp"),
      },
    ],
  },
  {
    path: "/marketData",
    name: "marketData",
    component: Layout,
    meta: { title: "市场数据" },
    children: [

      {
        path: "dataBoard",
        name: "dataBoard",
        meta: { title: "市场动向" },
        component: () => import("@/views/marketData/dataBoard"),
      },
    ],
  },
  {
    path: "/rpaSpider",
    name: "rpaSpider",
    component: Layout,
    meta: { title: "rpa自动化" },
    children: [

      {
        path: "dataBoard",
        name: "dataBoard",
        meta: { title: "rpa自动化采集配置" },
        component: () => import("@/views/rpaSpider/dataBoard"),
      },
    ],
  },
  {
    path: "/import",
    name: "import",
    component: Layout,
    meta: { title: "导入销售目标" },
    children: [
        {
            path: "buttonToJump",
            name: "buttonToJump",
            meta: { title: "跳转销售目标" },
            component: () => import("@/views/import-sales-targets/button-to-jump"),
          },
        {
          path: "salesTargets",
          name: "dataBoard",
          meta: { title: "导入销售目标" },
          component: () => import("@/views/import-sales-targets/sales-targets"),
        },
      ],

  },

  {
    path: "/404",
    name: "404",
    // @ts-ignore
    component: () => import("@/views/error-page/404"),
  },
  {
    path: "/401",
    name: "401",
    // @ts-ignore
    component: () => import("@/views/error-page/401"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    path: "/xp",
    name: "xp",
    component: Layout,
    meta: { title: "新品上新" },
    children: [
      {
        path: "onNewTrace",
        name: "onNewTrace",
        meta: { title: "新品上新周追踪" },
        component: () => import("@/views/xp/onNewTrace"),
      },
    ],
  },
];

export default new Router({
  // @ts-ignore
  scrollBehavior: () => ({ y: 0 }),
  routes,
  mode: "history",
});
