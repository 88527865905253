
// @ts-ignore
import {axiosGet, axiosPost, axiosPut, axiosDelete, axiosDownload} from "@/utils/request";

/**
 * ----------------------------- 分公司智能月单 -- 分公司月单定制相关接口 -- 开始 -----------------------------
 */
// 分公司月单定制-- 列表
export function handleMonthOrderLists(data) {
    return axiosPost('/month-order/details-page', data, 1);
};
// 分公司月单定制-- 月单定制
export function handleMonthOrderCustom(data) {
    return axiosPost('/month-order/custom', data, 1);
};
// 分公司月单定制-- 月单重置
export function handleMonthOrderReset(data) {
    return axiosPost('/month-order/reset', data, 1);
};
// 分公司月单定制 -- 进度查询
export function handleMonthOrderDataStatus(data) {
    return axiosPost('/month-order/data-status', data, 1);
};
/**
 * ----------------------------- 分公司智能月单 -- 分公司月单定制相关接口 -- 结束 -----------------------------
 */

/**
 * ----------------------------- 分公司加单预警相关接口 -- 开始 -----------------------------
 */

// 分公司加单预警-- 列表
export function handleAddOrderWarningLists(data) {
    return axiosPost('/bo-add-order/details-page', data, 1);
};

// 分公司加单预警 -- 字典查询
export function handleAddOrderWarningCategory() {
    return axiosPost('/bo-add-order/category', null, 1);
};

// 分公司加单预警 -- 加单
export function handleAddOrderWarningPut(data) {
    return axiosPut('/bo-add-order/add_order', data, 1);
};

// 分公司加单预警 -- 统一加单
export function handleALLAddOrderWarningPut(data) {
    return axiosPut('/bo-add-order/throw_order', data, 1);
};

// 分公司加单预警 -- 重置加单
export function handleResetAddOrderWarningPut() {
    return axiosPut('/bo-add-order/reset', null, 1);
};

/**
 * ----------------------------- 分公司加单预警相关接口 -- 结束 -----------------------------
 */

/**
 * ----------------------------- 分公司智能月单 -- 要货确认相关接口 -- 开始 -----------------------------
 */
// 分公司要货确认 -- 列表
export function handleCargoConfirmLists(data) {
    return axiosPost('/cargo-confirm/details-page', data, 1);
};
// 分公司要货确认 -- 批量编辑
export function handleCargoConfirmEdit(data) {
    return axiosPost('/cargo-confirm/details/batch-update', data, 1);
};
// 分公司要货确认 -- 确认要货
export function handleCargoConfirmCheckSubmit(data) {
    return axiosPost('/cargo-confirm/submit', data, 1);
};
// 分公司要货确认 -- 要货检核列表
export function handleCargoConfirmCheckDetails(data) {
    return axiosPost('/cargo-confirm/check/details-page', data, 1);
};
// 分公司要货确认 -- 要货检核明细修改
export function handleCargoConfirmCheckDetailsUpdate(data) {
    return axiosPost('/cargo-confirm/check/details-update', data, 1);
};
// 分公司要货确认 -- 要货检核明细确认
export function handleCargoConfirmCheckDetailsConfirm(data) {
    return axiosPost('/cargo-confirm/check/details-confirm', data, 1);
};
// 分公司要货确认 -- 要货确认(分公司drp抛单)
export function handleCargoConfirmCheckDetailsThrowOrder(data) {
    return axiosPost('/cargo-confirm/details/throw_order', data, 1);
};
// 分公司要货确认 -- 要货检核明细批量确认
export function handleCargoConfirmCheckDetailsBatchConfirm(data) {
    return axiosPost('/cargo-confirm/check/batch-confirm', data, 1);
};
// 分公司要货确认 -- 导入
export function handleCargoConfirmCheckImport(data) {
    return axiosPost('/cargo-confirm/check/details/import', data, 1);
};
// 分公司要货确认 -- 导出
export function handleCargoConfirmCheckExport(data) {
    return axiosDownload('/cargo-confirm/check/details/export', data, 1);
};
/**
 * ----------------------------- 分公司智能月单 -- 要货确认相关接口 -- 结束 -----------------------------
 */

/**
 * ----------------------------- 分公司智能月单 -- 库存分类相关接口 -- 开始 -----------------------------
 */
// 库存系数分类设定 -- 列表
export function handleStoreRatioGradeLists(data) {
    return axiosPost('/store_ratio_grade/store_ratio_grade_page', data, 1);
};
// 库存系数分类设定 -- 保存
export function handleStoreRatioGradePut(data) {
    return axiosPut('/store_ratio_grade/save_store_ratio_grade', data, 1);
};
/**
 * ----------------------------- 分公司智能月单 -- 库存分类相关接口 -- 结束 -----------------------------
 */

/**
 * ----------------------------- 分公司智能月单 -- 配置中心--商品 相关接口 -- 开始 -----------------------------
 */
// 配置中心 -- 列表
export function handleConfigProductLists(data) {
    return axiosPost('/config-product/details-page', data, 1);
};
// 配置中心 -- 编辑单条
export function handleConfigProductEdit(data) {
    return axiosPut('/config-product/update-company-sku-month', data, 1);
};
// 配置中心 -- 批量设置产品等级
export function handleConfigProductUpdateGrade(data) {
    return axiosPut('/config-product/update-grade', data, 1);
};
// 配置中心 -- 批量设置库存系数
export function handleConfigProductUpdateRatio(data) {
    return axiosPut('/config-product/update-ratio', data, 1);
};
// 配置中心 -- 批量设置月销量
export function handleConfigProductUpdateMonth(data) {
    return axiosPost('/config-product/import-month', data, 1);
};
// 配置中心 -- 批量导入其他库存
export function handleOtherInventoryImport(data) {
    return axiosPost('/config-product/other-kc/import', data, 1);
};
// 配置中心 -- 分公司产品等级&库存系数导入
export function ratioAndGradeImport(data) {
  return axiosPost('/config-product/ratio-grade/import', data, 1);
};
/**
 * ----------------------------- 分公司智能月单 -- 配置中心--商品 相关接口 -- 结束 -----------------------------
 */

/**
 * ----------------------------- 分公司智能月单 -- 获取分公司信息 -- 开始 -----------------------------
 */
// 获取分公司信息
export function handleBranchCompanyInfos(data) {
    return axiosPost('/data-support/companyInfo', data, 1);
};
/**
 * ----------------------------- 分公司智能月单 -- 获取分公司信息 -- 开始 -----------------------------
 */
