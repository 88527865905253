var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mg-container",
    {
      attrs: {
        isShowTips: true,
        isDisablePage: _vm.tipsConfig.isDisablePage,
        tipsConfig:
          (_vm.tipsConfig.tipsType &&
            _vm.tipsConfig.tips[_vm.tipsConfig.tipsType]) ||
          _vm.tipsConfig.tips["default"],
      },
      scopedSlots: _vm._u([
        {
          key: "headerSearch",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "head-signboard-box" },
                [
                  _c("mg-signboard", { attrs: { title: "要货确认" } }),
                  _c("mg-signboard", {
                    attrs: {
                      title:
                        _vm.branchCompanyInfos && _vm.branchCompanyInfos.name,
                      isHavBar: false,
                    },
                  }),
                ],
                1
              ),
              _c("mg-search-form", {
                ref: "mgSearchFormRef",
                attrs: {
                  "form-item-list": _vm.formItemList,
                  row: 2,
                  "label-width": "110px",
                  size: "mini",
                },
                on: {
                  getSearchFormData: _vm.handleSearch,
                  isToggleForm: _vm.handleToggleForm,
                },
                scopedSlots: _vm._u([
                  {
                    key: "buttonScope",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: _vm.btnStatus.isSave,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction("save")
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction(
                                  "confirmPurchase"
                                )
                              },
                            },
                          },
                          [_vm._v("确认要货")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction(
                                  "abnormalCheck"
                                )
                              },
                            },
                          },
                          [_vm._v("异常检核")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction(
                                  "importEstimatedSales"
                                )
                              },
                            },
                          },
                          [_vm._v("导入预估销量")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.btnStatus.isExport,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableHeadAction("export")
                              },
                            },
                          },
                          [_vm._v("导出")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "table",
          fn: function () {
            return [
              _c("mg-vxe-table", {
                ref: "mgTable",
                staticClass: "mg-list-box",
                attrs: {
                  autoLoad: true,
                  columns: _vm.purchaseConfirmationColumns,
                  api: _vm.handleCargoConfirmLists,
                  apiParams: _vm.queryForm,
                  "row-config": {
                    keyField: "cargoNo",
                  },
                  isRowClick: false,
                  configurable: true,
                  businessType: "BRANCHOFFICE_PURCHASECONFIRMATION_LISTS",
                  extraParams: { isEdit: false, isSelect: false },
                  tableHeight: _vm.tableHeight,
                },
                on: {
                  "update:columns": function ($event) {
                    _vm.purchaseConfirmationColumns = $event
                  },
                  "selection-change": _vm.handleSelection,
                  select: _vm.handleSelect,
                },
                scopedSlots: _vm._u([
                  {
                    key: "checkbox",
                    fn: function () {
                      return [
                        _c("vxe-column", {
                          key: "checkbox",
                          attrs: {
                            type: "checkbox",
                            fixed: "left",
                            width: "50px",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "boNextMthConfirmOrderCount",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini", disabled: !row.isEdit },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangeRow(
                                "boNextMthConfirmOrderCount",
                                row
                              )
                            },
                          },
                          model: {
                            value: row.boNextMthConfirmOrderCount,
                            callback: function ($$v) {
                              _vm.$set(
                                row,
                                "boNextMthConfirmOrderCount",
                                _vm._n($$v)
                              )
                            },
                            expression: "row.boNextMthConfirmOrderCount",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "boNextTwoMthConfirmOrderCount",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini", disabled: !row.isEdit },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangeRow(
                                "boNextTwoMthConfirmOrderCount",
                                row
                              )
                            },
                          },
                          model: {
                            value: row.boNextTwoMthConfirmOrderCount,
                            callback: function ($$v) {
                              _vm.$set(
                                row,
                                "boNextTwoMthConfirmOrderCount",
                                _vm._n($$v)
                              )
                            },
                            expression: "row.boNextTwoMthConfirmOrderCount",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "exceptionTypes",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(row.exceptionTypes || "") + " "),
                      ]
                    },
                  },
                  {
                    key: "boNoSaleDays",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color: row.boNoSaleDays >= 30 ? "red" : "black",
                            },
                          },
                          [_vm._v(_vm._s(row.boNoSaleDays))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "operate",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: !row.isEdit ? "primary" : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTableAction("default", row)
                                  },
                                },
                              },
                              [_vm._v("默认")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: row.isEdit ? "primary" : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTableAction("edit", row)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog-box",
          attrs: {
            width: "80%",
            "close-on-click-modal": false,
            visible: _vm.dialogObj.isConfirm,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogObj, "isConfirm", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "header", attrs: { slot: "title" }, slot: "title" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("要货异常")]),
              _c("el-button", {
                staticClass: "refreshBtn",
                attrs: { size: "mini", icon: "el-icon-refresh" },
                on: { click: _vm.handleRefreshBtn },
              }),
            ],
            1
          ),
          _vm.dialogObj.isConfirm
            ? _c(
                "mg-table",
                {
                  ref: "dialogMgTable",
                  attrs: {
                    isShowTopBar: true,
                    autoLoad: true,
                    columns: _vm.dialogObj.dialogColumns,
                    api: _vm.dialogObj.api,
                    apiParams: _vm.dialogObj.queryForm,
                    extraParams: { isEdit: 1 },
                  },
                  on: { "selection-change": _vm.HandleSelectRows },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "topleft",
                        fn: function () {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  disabled:
                                    _vm.dialogObj.selectRows.length === 0,
                                },
                                on: { click: _vm.handleBatchConfirm },
                              },
                              [_vm._v("批量确认")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  loading: _vm.uploadLoading,
                                },
                                on: { click: _vm.handleUploadFile },
                              },
                              [_vm._v("导入")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  loading: _vm.downloadLoading,
                                },
                                on: { click: _vm.handleDownloadFile },
                              },
                              [_vm._v("导出")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  loading: _vm.downloadLoading,
                                },
                                on: { click: _vm.handleDownloadTemplate },
                              },
                              [_vm._v("模版下载")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "selection",
                        fn: function () {
                          return [
                            _c("el-table-column", {
                              attrs: { type: "selection", fixed: "left" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "exceptionTypes",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (row.exceptionTypes &&
                                    _vm.enumTypes.exceptionTypeLists[
                                      row.exceptionTypes
                                    ]) ||
                                    "--"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "boNextMthConfirmOrderCount",
                        fn: function ({ row }) {
                          return [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "input-number",
                                  rawName: "v-input-number",
                                },
                              ],
                              attrs: {
                                size: "mini",
                                disabled: [1, 3].includes(row.isEdit),
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleDialogRowChange(row)
                                },
                              },
                              model: {
                                value: row.boNextMthConfirmOrderCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    row,
                                    "boNextMthConfirmOrderCount",
                                    $$v
                                  )
                                },
                                expression: "row.boNextMthConfirmOrderCount",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2556568752
                  ),
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      label: "操作",
                      "min-width": "120px",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button-group",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: row.isEdit === 3 ? "primary" : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDialogRowEvent(
                                            "confirm",
                                            row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: row.isEdit === 2 ? "primary" : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDialogRowEvent(
                                            "edit",
                                            row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      46825322
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialogEvent("cancel")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    disabled: _vm.dialogObj.lists.length > 0,
                    loading: _vm.btnStatus.isConfirm,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialogEvent("confirm")
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("mg-importDialog", {
        attrs: {
          visible: _vm.importDialog.visible,
          urlParams: _vm.urlParams,
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
        },
        on: { handleImportSuccess: _vm.handleImportSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }