var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.companyRows,
            "max-height": "500px",
            "empty-text": "暂无数据",
            size: "mini",
            border: "",
            "highlight-current-row": "",
          },
        },
        _vm._l(_vm.companyColumns, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              prop: item.prop,
              width: item.width,
              label: item.label,
              align: item.align,
              fixed: item.fixed,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      (row.row === "本次确认单量" ||
                        row.row === "最新一次加减单回复量") &&
                      item.prop !== "row" &&
                      item.label != "合计"
                        ? _c("el-input-number", {
                            staticStyle: { width: "100%", color: "red" },
                            attrs: {
                              "controls-position": "right",
                              placeholder: "请输入内容",
                              size: "mini",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleRowChange($event, row.row)
                              },
                            },
                            model: {
                              value: row[item.prop],
                              callback: function ($$v) {
                                _vm.$set(row, item.prop, $$v)
                              },
                              expression: "row[item.prop]",
                            },
                          })
                        : _c(
                            "span",
                            {
                              style:
                                (row.row === "剩余未做单量" ||
                                  row.row === "累积加减单确认量" ||
                                  row.row === "最新一次加减单申请量" ||
                                  row.row === "最新一次加减单回复量") &&
                                item.prop !== "row"
                                  ? row[item.prop] < 0
                                    ? "color: red; font-size:14px !important; font-weight: 900 !important; "
                                    : row[item.prop] > 0
                                    ? "font-weight: 900 !important;font-size:14px !important;"
                                    : ""
                                  : "font-size:14px !important;",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row[item.prop] === 0 ? "-" : row[item.prop]
                                )
                              ),
                            ]
                          ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c(
        "el-button-group",
        { staticStyle: { "margin-left": "30%", "margin-top": "15px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.buttonDisabled },
              on: {
                click: function ($event) {
                  return _vm.handleSaveConfirm("save")
                },
              },
            },
            [_vm._v("保存加减单回复量")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "50px" },
              attrs: { type: "warning", disabled: _vm.buttonDisabled },
              on: {
                click: function ($event) {
                  return _vm.handleSaveConfirm("confirm")
                },
              },
            },
            [_vm._v(" 确认做单量[配送订单] (" + _vm._s(_vm.status) + ") ")]
          ),
          _vm.buttonShow
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "50px" },
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.handleReloadList("confirm")
                    },
                  },
                },
                [_vm._v(" 仅显示加减单数据 ")]
              )
            : _vm._e(),
          !_vm.buttonShow
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "50px" },
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleReloadList("all")
                    },
                  },
                },
                [_vm._v("全部显示")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }