var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      ref: "excel-upload-input",
      staticClass: "excel-upload-input",
      attrs: { type: "file", accept: ".xlsx, .xls, .csv" },
      on: { change: _vm.handleClick },
    }),
    _c(
      "div",
      {
        staticClass: "drop",
        on: {
          drop: _vm.handleDrop,
          dragover: _vm.handleDragover,
          dragenter: _vm.handleDragover,
        },
      },
      [
        _c(
          "div",
          { staticClass: "upload-area" },
          [
            _vm._v(" 拖拽上传 or "),
            _c(
              "el-button",
              {
                staticClass: "upload-btn el-icon-upload",
                staticStyle: { "margin-left": "8px" },
                attrs: { loading: _vm.loading, size: "mini", type: "primary" },
                on: { click: _vm.handleUpload },
              },
              [_vm._v("选择文件")]
            ),
            _vm.uploadDatas.length > 0
              ? _c(
                  "el-button",
                  {
                    staticClass: "upload-btn el-icon-view",
                    staticStyle: { "margin-left": "8px" },
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.handlePreview },
                  },
                  [_vm._v("点击预览")]
                )
              : _vm._e(),
            _vm.uploadDatas.length > 0
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "8px" },
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.handleConfirmUpload },
                  },
                  [_vm._v("确 认 上 传")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.uploadDatas.length === 0 && _vm.templateFileDownloadUrl
          ? _c("div", { staticClass: "warning" }, [
              _vm._v(" 仅支持Xlsx格式文件,格式可参考模板 "),
              _c(
                "em",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleDownloadTemplate.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("下载模板")]
              ),
            ])
          : _vm._e(),
        _vm.tipsText
          ? _c("div", { staticClass: "upload-tips" }, [
              _c("span", {
                staticClass: "upload-tips-text",
                domProps: { innerHTML: _vm._s(_vm.tipsText) },
              }),
              _c("br"),
              _vm.optionalTipsText
                ? _c("span", { staticClass: "upload-optional-tips-text" }, [
                    _vm._v(_vm._s(_vm.optionalTipsText)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.importType
          ? _c(
              "div",
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.importAction,
                      callback: function ($$v) {
                        _vm.importAction = $$v
                      },
                      expression: "importAction",
                    },
                  },
                  _vm._l(_vm.importType, function (item) {
                    return _c(
                      "el-radio",
                      { key: item.value, attrs: { label: item.value } },
                      [_vm._v(_vm._s(item.label))]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "red",
                      margin: "3px",
                    },
                  },
                  [
                    _vm.importActionLabel
                      ? _c("span", [_vm._v(_vm._s(_vm.importActionLabel))])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }