var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mg-container",
    {
      scopedSlots: _vm._u([
        {
          key: "headerSearch",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "head-signboard-box" },
                [
                  _c("mg-signboard", { attrs: { title: "加单预警-当前明细" } }),
                  _c("mg-signboard", {
                    attrs: {
                      title:
                        _vm.branchCompanyInfos && _vm.branchCompanyInfos.name,
                      isHavBar: false,
                    },
                  }),
                ],
                1
              ),
              _c("mg-search-form", {
                ref: "mgSearchFormRef",
                attrs: {
                  "form-item-list": _vm.formItemList,
                  row: 2,
                  "label-width": "110px",
                  size: "mini",
                },
                on: { getSearchFormData: _vm.handleSearch },
                scopedSlots: _vm._u([
                  {
                    key: "buttonScope",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              loading: _vm.status.isExport,
                            },
                            on: { click: _vm.handleExport },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.handleUnifyAddOrder },
                          },
                          [_vm._v("统一抛单")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              loading: _vm.status.isResetAddOrder,
                            },
                            on: { click: _vm.handleResetAddOrder },
                          },
                          [_vm._v("重置加单")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "table",
          fn: function () {
            return [
              _c("mg-table", {
                ref: "mgTable",
                staticClass: "mg-list-box",
                attrs: {
                  autoLoad: true,
                  columns: _vm.addOrderWarningColumns,
                  api: _vm.handleAddOrderWarningLists,
                  apiParams: _vm.queryForm,
                  configurable: true,
                  businessType: "BRANCHOFFICE_ADDORDERWARNING_LISTS",
                  height: "calc(100vh - 270px)",
                  "max-height": "auto",
                },
                on: {
                  "update:columns": function ($event) {
                    _vm.addOrderWarningColumns = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "boSupplyBoxCount",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请输入补单箱数",
                            disabled: row.kcStatus !== "库存不足",
                          },
                          on: { change: _vm.handleBoxCountEvent },
                          model: {
                            value: row.boSupplyBoxCount,
                            callback: function ($$v) {
                              _vm.$set(row, "boSupplyBoxCount", _vm._n($$v))
                            },
                            expression: "row.boSupplyBoxCount",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "boThisMthExpectKcCountAfterSupply",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.calcBoThisMthExpectKcCountAfterSupply(row)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "boThisMthExpectKcRatioAfterSupply",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.calcBoThisMthExpectKcRatioAfterSupply(row)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "boNextMthExpectKcCount",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.calcBoNextMthExpectKcCount(row)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "boNextMthExpectKcRatio",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.calcBoNextMthExpectKcRatio(row)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "boNextTwoMthExpectKcCount",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.calcBoNextTwoMthExpectKcCount(row)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "boNextTwoMthExpectKcRatio",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.calcBoNextTwoMthExpectKcRatio(row)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "operate",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              underline: false,
                              size: "mini",
                              type: "text",
                              disabled: row.kcStatus !== "库存不足",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleTableRowEvent("addOrder", row)
                              },
                            },
                          },
                          [_vm._v("加单")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "加单",
            width: "650px",
            visible: _vm.dialogObj.isAdd,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogObj, "isAdd", $event)
            },
            closed: _vm.handleDialogClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  staticClass: "dialog-form",
                  attrs: {
                    "label-position": "right",
                    "label-width": "100px",
                    size: "mini",
                    model: _vm.dialogObj.dialogFormModel,
                    rules: _vm.dialogObj.dialogFormModelRules,
                  },
                },
                [
                  !_vm.isUnifyAddOrder
                    ? [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "货号: ", size: "mini" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialogObj.dialogFormModel.cargoNo ||
                                          "--"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "品名: ", size: "mini" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialogObj.dialogFormModel.skuName
                                          ? `${
                                              _vm.dialogObj.dialogFormModel
                                                .skuCode || ""
                                            }-${
                                              _vm.dialogObj.dialogFormModel
                                                .skuName
                                            }`
                                          : "--"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "分公司: ", size: "mini" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialogObj.dialogFormModel
                                          .companyName
                                          ? `${
                                              _vm.dialogObj.dialogFormModel
                                                .companyCode || ""
                                            }-${
                                              _vm.dialogObj.dialogFormModel
                                                .companyName
                                            }`
                                          : "--"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "补单量: ", size: "mini" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dialogObj.dialogFormModel
                                          .boSupplyCount || 0
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.isUnifyAddOrder
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 14 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "交付日期: ",
                                    size: "mini",
                                    prop: "requestArrivalTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "dialog-date-picker",
                                    staticStyle: { width: "120px" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "请选择",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      pickerOptions:
                                        _vm.dialogObj.pickerOptions,
                                    },
                                    model: {
                                      value:
                                        _vm.dialogObj.dialogFormModel
                                          .requestArrivalTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dialogObj.dialogFormModel,
                                          "requestArrivalTime",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dialogObj.dialogFormModel.requestArrivalTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialogEvent("save")
                    },
                  },
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialogEvent("cancel")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }