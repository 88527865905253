<style>
.main-container {
  overflow: hidden !important;
}

.app-main {
  overflow: hidden !important;
}

.app-wrapper {
  overflow: hidden !important;
}

#app {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
<template>
  <div class="thirdPartyPage-page">
    <el-tabs tab-position="top" style="width: 100%; height: 100vh;" v-model="activeName">
      <el-tab-pane v-for="(item, index) in iframeArrays" :key="index + 'iframe'" :label="item.name"
                   :name="item.tabName">
        <template>
          <iframe :src="item.iframeSrc" frameborder="0" style="width: 100%; height: 1800px;"></iframe>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {urlParams} from '@/mixins';
export default {
  // 请购单临时汇总报表
  name: 'pluOrderReport',
  mixins: [urlParams],
  components: {
  },
  data() {
    return {
      user: {
        role_code_list:[],
        username: '',
        real_name: '',
        arrange_resource: false,
      },
      iframeArrays: [
        {
          name: '请购单临时汇总',
          tabName: '1',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25AF%25B7%25E8%25B4%25AD%25E5%258D%2595%25E6%25B1%2587%25E6%2580%25BB.cpt&ref_t=design&ref_c=4ad39279-d2e7-4ac6-8bb3-3c8730b6528e',
        },
        {
          name: '请购单确认后定额万元',
          tabName: '2',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25AF%25B7%25E8%25B4%25AD%25E5%258D%2595%25E7%25A1%25AE%25E8%25AE%25A4%25E5%2590%258E%25E5%25AE%259A%25E9%25A2%259D%25E4%25B8%2587%25E5%2585%2583.cpt&ref_t=design&ref_c=73ddd091-2b97-4b12-a326-26640b29eeef',
        },
        {
          name: '请购单产能检核',
          tabName: '3',
          iframeSrc: '//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E8%25AF%25B7%25E8%25B4%25AD%25E5%258D%2595%25E4%25BA%25A7%25E8%2583%25BD%25E6%25A3%2580%25E6%25A0%25B8.cpt&ref_t=design&ref_c=c552f6bc-699a-4933-ad3b-a9a04093f756',
        }
      ],
      operator: '',
      depart: '',
      isConfirm: false,
      activeName: '1'
    }
  },
  created() {
    this.handleUrlParams().then(res => {
      this.operator = res.operator;
      this.depart = res.depart;
    })
  },
  methods: {
    handleHeadAction(type) {
      const funcObj = {

      };
      funcObj[type]();
    }
  }
}
</script>

<style lang="scss" scoped>
.thirdPartyPage-page {
  height: 100vh;

  &-head {
    text-align: center;
    padding: 10px 0 20px 0;
  }

  ::v-deep .el-tabs__content {
    height: 100vh;
    overflow: scroll;
  }
}
</style>
