<style>
.el-dialog__body .el-form-item__label {
  width: 120px !important;
}

.el-form-item__content {
  float: left;
  margin-left: 20px !important;
}
</style>
<template>
  <div class="prodCenter-page">
    <div class="prodCenter-content">
      <v-signboard :title="'注塑机产能盘点'"/>
      <el-row>

        <el-form
          class="main-search"
          size="mini"
          label-width="auto"
          ref="moldingMachineProdCapacity-form"
          :inline="true"
          :model="form"
        >
          <el-col class="list-head">
            <el-form-item label="注塑类型" size="mini">
              <el-input v-model="form.injection_type" clearable placeholder="请输入注塑类型"/>
            </el-form-item>
            <el-form-item label="生产单位" size="mini">
              <el-input v-model="form.production_unit" clearable placeholder="请输入生产单位"/>
            </el-form-item>
            <el-button
              size="mini"
              @click="handleTableHeadAction('search')"
            >查 询
            </el-button>
            <el-button
              size="mini"
              @click="reverseChecked()">反 选
            </el-button>
            <el-button
              size="mini"
              @click="handleTableHeadAction('add')"
            >新 增
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isSave"
              @click="handleTableHeadAction('save')">保 存
            </el-button>
            <el-button
              size="mini"
              type="warning"
              :disabled="btnStatus.isDelete"
              @click="handleTableHeadAction('delete')">删 除
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleTableHeadAction('batchUpdateWorkDayCount')">批量修改工作日数
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isAdjust"
              @click="handleTableHeadAction('adjust')"
            >确 认
            </el-button>
            <span style="margin-left: 10px;font-size: 14px;color: green" v-text="confirmTips" id="confirmTips">未确认</span>
          </el-col>
        </el-form>
      </el-row>
      <el-table
        class="table"
        stripe
        border
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <el-table-column label="序号" align="center" width="50">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.moldingMachineProdCapacityHead">
          <!-- 工作日数 / 机台数 / 机台日产能(万) -- 可编辑 -->
          <el-table-column v-if="['machine_count', 'machine_day_prod_count', 'work_day_count'].includes(item.prop)"
                           v-bind="item"
                           show-overflow-tooltip :key="index" align="center">
            <template slot-scope="scope">
              <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'work_day_count'"
                        v-model="scope.row.work_day_count" size="mini" clearable/>
              <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'machine_count'"
                        v-model="scope.row.machine_count" size="mini" clearable/>
              <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'machine_day_prod_count'"
                        v-model="scope.row.machine_day_prod_count" size="mini" clearable/>
            </template>
          </el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
    <!-- 新增注塑机产能 -->
    <el-dialog
      class="add-dialog"
      title="新增注塑机产能"
      width="650px"
      :visible.sync="dialogStatus.isAddMoldingMachine"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="80px"
          size="mini"
          class="dialog-form"
          :model="dialogFormModel"
          :rules="dialogFormModelRules"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item label="年月" size="mini" prop="check_month">
                <el-input v-model="dialogFormModel.check_month" disabled placeholder="年月" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="类型" size="mini" prop="injection_type">
                <el-select v-model="dialogFormModel.injection_type" placeholder="请选择类型" clearable>
                  <el-option
                    v-for="(item, index) in options.injection_type"
                    :key="index + 'injectionType'"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="生产单位" size="mini" prop="production_unit">
                <el-input v-input-number v-model="dialogFormModel.production_unit" max="120" maxlength="3"
                          placeholder="请填写生产单位" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="产能上限" size="mini" prop="prod_percent_up">
                <el-input v-input-number v-model="dialogFormModel.prod_percent_up" max="120" maxlength="3"
                          placeholder="请填写产能上限 (%)" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="产能下限" size="mini" prop="prod_percent_down">
                <el-input v-input-number v-model="dialogFormModel.prod_percent_down" min="0" placeholder="请填写产能下限 (%)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="机台数" size="mini" prop="machine_count">
                <el-input v-input-number v-model="dialogFormModel.machine_count" min="0" placeholder="请填写机台数"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="机台日产能(万)" size="mini" prop="machine_day_prod_count">
                <el-input v-input-number v-model="dialogFormModel.machine_day_prod_count" min="0"
                          placeholder="请填写机台日产能(万)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="工作日数" size="mini" prop="work_day_count">
                <el-input
                  v-input-number
                  v-model="dialogFormModel.work_day_count"
                  min="0"
                  max="31"
                  maxlength="2"
                  placeholder="请填写工作日数"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleAddSubmit">保 存</el-button>
        <el-button size="small" @click="handleAddCancel">取 消</el-button>
      </div>
    </el-dialog>


    <!-- 批量修改工作日数 -->
    <el-dialog
      class="batch-update-work-day-count-dialog"
      title="批量修改工作日数"
      width="650px"
      :visible.sync="batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="80px"
          size="mini"
          class="dialog-form"
          :model="batchUpdateWorkDayCountDialogFormModel"
          :rules="batchUpdateWorkDayCountDialogFormModelRules"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item label="工作日数" size="mini" prop="work_day_count">
                <el-input
                  v-input-number
                  v-model="batchUpdateWorkDayCountDialogFormModel.work_day_count"
                  min="0"
                  max="31"
                  maxlength="2"
                  placeholder="请填写工作日数"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="doBatchUpdateWorkDayCount">保 存</el-button>
        <el-button size="small" @click="cancelBatchUpdateWorkDayCount">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams, unitOptions} from '@/mixins';
import {thousandsSeparator} from "@/utils";
import {moldingMachineProdCapacityHead} from '../config';
import {
  handleMoldingMachineProdLists,
  handleMoldingMachineProdConfirm,
  handleProdAddMinimumOrderPlu,
  handleMoldingMachineProdDelete,
  handleMoldingMachineProdSave
} from '@/api/prodCenter';
import {handleProductDictDefList} from '@/api/config';
import {
  batchUpdateMachineWorkDayCount,
  batchUpdateMouldWorkDayCount,
  queryCapacityConfirmStatus
} from "../../../api/prodCenter";

export default {
  // 注塑机产能
  name: 'moldingMachineProdCapacity',
  mixins: [tableLoading, paginationParams, urlParams, unitOptions],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    const validateProdPercentUp = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写产能上限"));
      } else {
        if (parseInt(value) < 100 || parseInt(value) > 120) callback(new Error("产能上限在[100, 120]"));
        callback();
      }
    };
    const validateProdPercentDown = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写产能下限"));
      } else {
        if (parseInt(value) < 0 || parseInt(value) > 100) callback(new Error("产能下限在[0, 100)"));
        callback();
      }
    };
    return {
      form: {
        injection_type: '',
        production_unit: '',
      },
      btnStatus: {
        isAdjust: true,                      // 是否调整
        isSave: true,                      // 是否保存
        isDelete: true,                   // 是否调整
      },
      lists: [],
      tableObj: {
        moldingMachineProdCapacityHead
      },
      selectRows: [],
      urlMonth: '',
      dialogStatus: {
        isAddMoldingMachine: false,         // 是否新增注塑机产能
      },
      dialogFormModel: {
        check_month: '',
        injection_type: '',
        production_unit: '',
        prod_percent_up: '120',
        prod_percent_down: '60',
        day_prod_count: 0,
        work_day_count: 0,
        machine_count: 0,
        machine_day_prod_count: 0,
        month_prod_count: 0
      },
      dialogFormModelRules: {
        injection_type: [{required: true, message: '请选择类型', trigger: 'blur'}],
        production_unit: [{required: true, message: '请选择生产单位', trigger: 'blur'}],
        prod_percent_up: [{required: true, validator: validateProdPercentUp, trigger: 'blur'}],
        prod_percent_down: [{required: true, validator: validateProdPercentDown, trigger: 'blur'}],
        machine_count: [{required: true, message: '请填写机台数', trigger: 'blur'}],
        machine_day_prod_count: [{required: true, message: '请填写机台日产能(万)', trigger: 'blur'}],
        work_day_count: [{required: true, message: '请填写工作日数', trigger: 'blur'}]
      },
      options: {
        injection_type: []
      },
      batchUpdateWorkDayCountDialogStatus: {
        isBatchUpdateWorkDayCount: false,         // 是否批量更新工作天数
      },
      batchUpdateWorkDayCountDialogFormModel: {
        check_month: '',
        work_day_count: 0
      },
      batchUpdateWorkDayCountDialogFormModelRules: {
        work_day_count: [{required: true, message: '请填写工作日数', trigger: 'blur'}]
      },
      confirmTips: "尚未确认"
    }
  },
  computed: {
    monthProdCount() {
      let monthProdCount = parseInt(this.dialogFormModel.day_prod_count) * parseInt(this.dialogFormModel.work_day_count) || 0;
      this.dialogFormModel.month_prod_count = monthProdCount;
      return monthProdCount
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isAdjust = false;
          btnStatus.isSave = false;
          btnStatus.isDelete = false;
        }
        if (n.length == 0) {
          btnStatus.isAdjust = true;
          btnStatus.isSave = true;
          btnStatus.isDelete = true;
        }
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
      this.checkConfirmTips();
    });
    this.handleInjectionOptions();
  },
  methods: {
    // 获取数据
    async getLists() {
      try {

        const form = this.form;
        const formParams = {
          injection_type: form.injection_type || '',
          production_unit: form.production_unit || '',
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          ...formParams
        };
        const {code, data, msg} = await handleMoldingMachineProdLists(params);
        if (data) {
          const {capacity_list, total} = data;
          this.modifyData(capacity_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----模具产能盘点列表有误');
      }
    },
    async checkConfirmTips() {
      const params = {
        month: this.urlMonth || '',
        operator: this.urlParams.operator,
      };
      const {data} = await queryCapacityConfirmStatus(params);
      if (data) {
        const {capacity_list} = data;
        for (let i = 0; i < capacity_list.length; i++) {
          let capacity = capacity_list[i];
          if (capacity.capacity_type == '注塑机' && capacity.confirmed) {
            this.confirmTips = '已确认';
          }
        }
      }
    },
    //实现反选
    reverseChecked() {
      this.lists.map(row => {
        for (let i = 0; i < this.selectRows.length; i++) {
          if (this.selectRows[i].id == row.id) {
            this.$refs['multipleTable'].toggleRowSelection(row, false);
            return;
          }
        }
        this.$refs['multipleTable'].toggleRowSelection(row, true);
      });
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            check_month: item.check_month || '--',
            injection_type: item.injection_type || '--',
            production_unit: item.production_unit || '--',
            machine_count: item.machine_count || 0,
            machine_day_prod_count: item.machine_day_prod_count || 0,
            prod_percent_up: item.prod_percent_up || 0,
            prod_percent_up_text: (item.prod_percent_up || 0) + "%",
            prod_percent_down: item.prod_percent_down || 0,
            prod_percent_down_text: (item.prod_percent_down || 0) + "%",
            month_prod_count: thousandsSeparator(item.month_prod_count, 0) || 0,
            work_day_count: item.work_day_count || 0,
            day_prod_count: item.day_prod_count || 0,
            id: item.id,
            product_line_type: item.product_line_type || ''
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作项
    handleTableHeadAction(type) {
      const funcObj = {
        'add': () => {                     // 保存
          this.dialogFormModel = {
            check_month: '',
            injection_type: '',
            production_unit: '',
            prod_percent_up: '120',
            prod_percent_down: '60',
            day_prod_count: '',
            work_day_count: '',
            month_prod_count: ''
          };
          this.dialogStatus.isAddMoldingMachine = true;
        },
        'delete': async () => {             // 删除
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item.id || '');
          });
          const params = {
            operator: this.urlParams.operator,
            capacity_id_list: newItems
          };
          const {code, msg} = await handleMoldingMachineProdDelete(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('删除成功');
          }
          if (code != '0000') this.getLists();
        },
        'batchUpdateWorkDayCount': () => {                     // 批量更新工作天数
          this.batchUpdateWorkDayCountDialogFormModel = {
            check_month: '',
            work_day_count: 0
          };
          this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = true;
        },
        'save': async () => {           // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              check_month: item.check_month || '',
              work_day_count: item.work_day_count || '',
              machine_count: item.machine_count || 0,
              machine_day_prod_count: item.machine_day_prod_count || 0,
              id: item.id || '',
              product_line_type: item.product_line_type || '',
              production_unit: item.production_unit || ''
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            capacity_list: newItems
          };
          const {code, msg} = await handleMoldingMachineProdSave(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'adjust': async () => {         // 确认
          this.$confirm('您需要确认注塑机产能么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            let newItems = [];
            this.selectRows.map(item => {
              let newItem = {
                check_month: item.check_month || '',
                work_day_count: item.work_day_count || '',
                machine_count: item.machine_count || 0,
                machine_day_prod_count: item.machine_day_prod_count || 0,
                id: item.id || '',
                product_line_type: item.product_line_type || '',
                production_unit: item.production_unit || ''
              };
              newItems.push(newItem);
            });
            const params = {
              month: this.urlMonth,
              operator: this.urlParams.operator,
              capacity_list: newItems
            };
            const {success} = await handleMoldingMachineProdConfirm(params);
            this.$refs['multipleTable'].clearSelection();
            if (success) {
              this.tableStatus.isLoading = true;
              this.getLists();
              this.checkConfirmTips();
              return this.$message.success('注塑机产能盘点已确认');
            }
            if (!success) this.getLists();

          }).catch(() => {
            this.$message('注塑机产能未确认');
          });

        },
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {                // 重置
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 新增注塑机产能提交
    handleAddSubmit() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.dialogFormModel,
            operator: this.urlParams.operator
          };
          const {code, success} = await handleProdAddMinimumOrderPlu(params);
          if (success) {
            this.dialogStatus.isAddMoldingMachine = false;
            this.getLists();
            return this.$message.success('新增成功');
          }
        } catch (err) {
          this.dialogStatus.isAddMoldingMachine = false;
          console.log(err, 'err------注塑机产能接口新增有误');
        }
      })
    },
    // 注塑机产能取消新增
    handleAddCancel() {
      this.dialogStatus.isAddMoldingMachine = false;
    },
    // 获取注塑机类型
    async handleInjectionOptions() {
      try {
        const params = {
          dict_type: "injection_type",
          operator: this.urlParams.operator
        };
        let newItems = [];
        const {code, data} = await handleProductDictDefList(params);
        if (code == '0000') {
          const {dict_def_dtos} = data;
          dict_def_dtos.map(item => {
            let newItem = {
              label: item.dict_label || '',
              value: item.dict_value || ''
            };
            newItems.push(newItem);
          });
          this.options.injection_type = newItems;
        }
      } catch (err) {
        this.options.injection_type = [];
        console.log(err, 'err------获取注塑机产能类型接口有误')
      }
    },
    // 批量更新工作天数
    doBatchUpdateWorkDayCount() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.batchUpdateWorkDayCountDialogFormModel,
            operator: this.urlParams.operator
          };
          const {code, success} = await batchUpdateMachineWorkDayCount(params);
          if (success) {
            this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = false;
            this.getLists();
            return this.$message.success('批量更新工作天数成功');
          }
        } catch (err) {
          this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = false;
          console.log(err, 'err------批量更新工作天数有误');
        }
      })
    },
    // 批量更新工作天数取消
    cancelBatchUpdateWorkDayCount() {
      this.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount = false;
    },
  }
}
</script>

<style lang="scss" scoped src='@/style/prodCenter/layoutMain.scss'></style>
