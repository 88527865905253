<template>
  <div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="明细" name="detail">
        <Detail @cell-click="handleDetailCellClick" />
      </el-tab-pane>
      <el-tab-pane label="sku详情" name="skuDetail">
        <SkuDetail ref="skuDetail" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Detail from "../detail";
import SkuDetail from "../skuDetail";

export default {
  name: 'Synthesize',
  components: { SkuDetail, Detail },
  data() {
    return {
      activeName: 'detail'
    }
  },
  methods: {
    handleDetailCellClick(data, query) {
      this.activeName = 'skuDetail';
      this.$nextTick(() => {
        this.$refs.skuDetail.queryForm.supplyDate = query.supplyDate;
        this.$refs.skuDetail.setDictOptions('storeSearch', [{ value: data.storeCode, label: data.storeName }]);
        this.$refs.skuDetail.setDictOptions('itemSearch', [{ value: data.itemCode, label: data.itemName }]);
        this.$refs.skuDetail.setDictData('itemSearch', data.itemCode);
        this.$refs.skuDetail.setDictData('storeSearch', data.storeCode);
        this.$refs.skuDetail.$forceUpdate();
        // 可能数据未刷新 延迟处理
        setTimeout(() => {
          this.$refs.skuDetail.handleFilter(query.supplyDate);
        }, 300)
      })
    }
  }
}
</script>
