<template>
  <div class="progress-box">
        <el-row class="progress-loading">
            <el-col
                class="signboard-title"
                :class=" titleColor ? '' : 'titleColor'"
                :style="{ color: titleColor ? titleColor : '' }"
                v-if="title"
            >{{ title }}</el-col>
            <el-col class="steps-mark">
                <span class="steps-mark-title">流程状态说明</span>
                <ul class="steps-mark-lists">
                    <li class="steps-mark-item" v-for="(item, index) in statusTips" :key="index" :class="item.className">
                        <i class="iconfont" :class="item.iconName"></i>
                        <span>{{ item.text }}</span>
                    </li>
                </ul>
            </el-col>
        </el-row>
        <div class="progress-plan-producting">
            <div class="producting-item">
                <el-row class="sales-item-title producting-item-title">
                    <el-col class="signboard-title">销售中心项目</el-col>
                    <el-col class="status">状态</el-col>
                </el-row>
                <el-steps :space="50" class="sales-steps" direction="vertical" :active="1" v-if="salesPhaseProject.length > 0">
                    <el-step
                        class="iconfont sales-steps-item step-item"
                        v-for="item in salesPhaseProject"
                        :key="item.label"
                        :title="item.label"
                        :icon="item.icon"
                        :class="[item.className, item.isSelect ? 'select-step-item' : '']"
                    ></el-step>
                </el-steps>
                <div class="empty-step sales-empty-step" v-else>
                    <span class="empty-step-item">当前无项目</span>
                    <span class="empty-step-status'">空</span>
                </div>
            </div>
            <div class="producting-item">
                <el-row class="prod-item-title producting-item-title producting-item-prod">
                    <el-col class="signboard-title">生产中心项目</el-col>
                    <el-col class="status">状态</el-col>
                </el-row>
                <el-steps :space="50" class="producting-steps" direction="vertical" :active="1" v-if="prodPhaseProject.length > 0">
                    <el-step
                        class="iconfont producting-steps-item step-item"
                        v-for="item in prodPhaseProject"
                        :key="item.label"
                        :title="item.label"
                        :icon="item.icon"
                        :class="[item.className, item.isSelect ? 'select-step-item' : '']"
                    ></el-step>
                </el-steps>
                <div class="empty-step prod-empty-step" v-else>
                    <span class="empty-step-status'">空</span>
                    <span class="empty-step-item">当前无项目</span>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import '@/assets/fonts/iconfont.css';
export default {
    props: {
        titleColor: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        salesPhaseProject: {
            type: Array,
            default: []
        },
        prodPhaseProject: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            statusTips: [
                { text: '已完成', iconName: 'icon-success', className: 'success-tips' },
                { text: '进行中', iconName: 'icon-underway', className: 'underway-tips' },
                { text: '驳回', iconName: 'icon-fail', className: 'fail-tips' },
                { text: '未开始', iconName: 'icon-notImplemented', className: 'notImplemented-tips' },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    $successColor: #6cf40a;             // 成功色值
    $underwayColor: #68aff3;            // 进行中色值
    $failColor: #e30808;                // 失败色值
    $notImplementedColor: #e0a544;      // 未开始色值
    .progress-box {
        padding-top: 30px;
        .progress-loading {
            display: flex;
            align-items: flex-start;
            padding-left: 32px;
            margin-bottom: 40px;
            .signboard-title {
                display: flex;
                align-items: center;
                color: #333;
                font-size: 16px;
                margin-bottom: 10px;
                font-weight: 600;
            }
            .signboard-before::before {
                display: block;
                content: '';
                width: 4px;
                height: 14px;
                margin-right: 6px;
                background: #409eff;
            }
            .signboard-title {
                font-size: 18px;
            }
            .steps-mark {
                padding-left: 88px;
                &-title {
                    color: #333;
                    font-size: 16px;
                    margin-bottom: 30px;
                    font-weight: 600;
                }
                &-lists {
                    display: flex;
                    padding-left: 0;
                }
                &-item {
                    .iconfont {
                        margin-right: 8px;
                    }
                }
            }
            .steps-mark-item + .steps-mark-item {
                margin-left: 20px;
            }
            .success-tips { .iconfont { color: $successColor; } }
            .underway-tips { .iconfont { color: $underwayColor; } }
            .fail-tips { .iconfont { color: $failColor; } }
            .notImplemented-tips { .iconfont { color: $notImplementedColor; } }
        }
        .progress-plan {
            &-producting {
                display: flex;
                width: 900px;
                margin: 0 auto;

                .sales-item-title {
                    .signboard-title {
                        padding-left: 20px;
                    }
                }
                .prod-item-title {}
                .producting-item {
                    flex: 0 0 50%;
                    &-title {
                        display: flex;
                        justify-content: space-between;
                    }
                    &-prod {
                        flex-flow: row-reverse;
                        .signboard-title {
                            flex: 0 0 372px;
                        }
                    }
                    .empty-step {
                        display: flex;
                        padding-left: 20px;
                        color: #bbb;
                    }
                    .sales-empty-step {
                         .empty-step-item {
                            flex: 0 0 148px;
                            text-align: right;
                            padding-right: 52px;
                        }
                    }
                    .prod-empty-step {
                        margin-left: -12px;
                        .empty-step-item {
                            flex: 0 0 143px;
                            text-align: right;
                            padding-right: 14px;
                            margin-left: 12px;
                        }
                    }
                }
                .signboard-title {
                    display: flex;
                    align-items: center;
                    color: #333;
                    font-size: 16px;
                    margin-bottom: 10px;
                    font-weight: 600;
                    padding-left: 6px;
                    flex: 0 0 162px;
                }
                .status {
                    color: #333;
                    font-size: 16px;
                    margin-bottom: 30px;
                    font-weight: 600;
                }
                .sales-steps-item {
                    flex-flow: row-reverse;
                    ::v-deep .el-step__head {
                        flex-grow: 1;
                    }
                    ::v-deep .el-step__main {
                        flex-grow: 0;
                        flex: 0 0 116px;
                        text-align: right;
                        margin-right: 48px;
                        padding-left: 0;
                    }
                    ::v-deep .is-finish, ::v-deep .is-process, ::v-deep .is-wait {
                        color: #303133;
                        font-weight: 400!important;
                    }
                }
                .producting-steps-item {
                    ::v-deep .el-step__head {

                    }
                    ::v-deep .el-step__main {
                        margin-left: 50px;
                    }
                    ::v-deep .is-finish, ::v-deep .is-process, ::v-deep .is-wait {
                        color: #303133;
                        font-weight: 400!important;
                    }
                }
                .step-item {
                    ::v-deep .el-step__icon-inner {
                        font-size: 22px;
                    }
                }
                .select-step-item {
                    ::v-deep .el-step__title {
                        color: #409EFF;
                    }
                }
                .success-item {
                    ::v-deep .el-step__icon-inner {
                        color: $successColor;
                    }
                }
                .underway-item {
                    ::v-deep .el-step__icon-inner {
                        color: $underwayColor;
                    }
                }
                .fail-item {
                    ::v-deep .el-step__icon-inner {
                        color: $failColor;
                    }
                }
                .notImplemented-item {
                    ::v-deep .el-step__icon-inner {
                        color: $notImplementedColor;
                    }
                }
            }
        }
    }
</style>
