var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "groupForm",
      staticClass: "common-form",
      attrs: {
        size: "mini",
        "label-width": "120px",
        inline: true,
        model: _vm.page,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "合集名称", prop: "title" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入合集名称" },
            model: {
              value: _vm.page.title,
              callback: function ($$v) {
                _vm.$set(_vm.page, "title", $$v)
              },
              expression: "page.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "合集简介", prop: "title" } },
        [
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: {
              autosize: { minRows: 2, maxRows: 5 },
              type: "textarea",
              maxlength: "150",
              "show-word-limit": "",
              placeholder: "选填，请输入简介",
            },
            model: {
              value: _vm.page.description,
              callback: function ($$v) {
                _vm.$set(_vm.page, "description", $$v)
              },
              expression: "page.description",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "选择页面模板", prop: "template" } },
        [
          _c(
            "el-radio-group",
            {
              on: {
                input: (val) => {
                  _vm.page.background.customColor = _vm.colorMap[val]
                  if (!_vm.page.customHeadImage) {
                    _vm.page.background.image = _vm.templateUrl[val]
                  }
                  _vm.$forceUpdate()
                },
              },
              model: {
                value: _vm.page.template,
                callback: function ($$v) {
                  _vm.$set(_vm.page, "template", $$v)
                },
                expression: "page.template",
              },
            },
            [
              _c("el-radio", { attrs: { label: "red", value: "red" } }, [
                _vm._v("红色系模板"),
              ]),
              _c("el-radio", { attrs: { label: "orange", value: "orange" } }, [
                _vm._v("橙色系模板"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "顶部头图", prop: "template" } },
        [
          _c(
            "el-radio-group",
            {
              on: {
                input: (v) => {
                  if (!v) {
                    _vm.page.background.image =
                      _vm.templateUrl[_vm.page.template]
                  } else {
                    _vm.page.background.image = _vm.page.customImageUrl
                      ? _vm.page.customImageUrl
                      : _vm.templateUrl[_vm.page.template]
                  }
                },
              },
              model: {
                value: _vm.page.customHeadImage,
                callback: function ($$v) {
                  _vm.$set(_vm.page, "customHeadImage", $$v)
                },
                expression: "page.customHeadImage",
              },
            },
            [
              _c("el-radio", { attrs: { label: false, value: false } }, [
                _vm._v("模板默认头图"),
                _c("img", {
                  staticStyle: { height: "80px", display: "block" },
                  attrs: { src: _vm.templateUrl[_vm.page.template] },
                }),
              ]),
              _c(
                "el-radio",
                { attrs: { label: true, value: true } },
                [
                  _vm._v("自定义 "),
                  _c("OssUpload", {
                    ref: "ossUpload",
                    staticStyle: { display: "table" },
                    attrs: {
                      "value-to": _vm.page.customImageUrl,
                      maxFileSize: 1024,
                      "list-type": "picture-card",
                      limit: 1,
                      "always-show-button": false,
                      tips: "尺寸1125*726，下方225高度勿放置重要内容会被遮挡，图片建议小于1M。合集标题请做在图上一并上传",
                      tipMessageShow: false,
                      "accept-type": _vm.imgTypes,
                    },
                    on: {
                      "update:valueTo": function ($event) {
                        return _vm.$set(_vm.page, "customImageUrl", $event)
                      },
                      "update:value-to": function ($event) {
                        return _vm.$set(_vm.page, "customImageUrl", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "颜色配置", prop: "template" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("span", { staticStyle: { display: "inline-flex" } }, [
              _vm._v("页面背景色"),
            ]),
            _c("el-color-picker", {
              model: {
                value: _vm.page.background.customColor,
                callback: function ($$v) {
                  _vm.$set(_vm.page.background, "customColor", $$v)
                },
                expression: "page.background.customColor",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "任务排序", prop: "fields" } },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.taskData.tasks,
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "任务名称", prop: "taskName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "300", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑样式")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: () =>
                                        _vm.taskData.tasks.splice(
                                          scope.$index,
                                          1
                                        ),
                                    },
                                  },
                                  [_vm._v("移除")]
                                ),
                                _c("el-button", {
                                  attrs: {
                                    disabled: scope.$index <= 0,
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-arrow-up",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMove(scope.$index, "up")
                                    },
                                  },
                                }),
                                _c("el-button", {
                                  attrs: {
                                    disabled:
                                      scope.$index ===
                                      _vm.taskData.tasks.length - 1,
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-arrow-down",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleMove(
                                        scope.$index,
                                        "down"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px" } },
            [
              _c("span", [_vm._v("添加任务")]),
              _c(
                "el-select",
                {
                  attrs: {
                    "value-key": "mtTaskId",
                    multiple: "",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.checkedTasks,
                    callback: function ($$v) {
                      _vm.checkedTasks = $$v
                    },
                    expression: "checkedTasks",
                  },
                },
                _vm._l(_vm.filterTaskOptions, function (item) {
                  return _c("el-option", {
                    key: item.mtTaskId,
                    attrs: { label: item.taskName, value: item },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: () => {
                      _vm.taskData.tasks = _vm.taskData.tasks.concat(
                        _vm.checkedTasks
                      )
                      _vm.checkedTasks = []
                    },
                  },
                },
                [_vm._v("确认添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }