
<template>
  <div :class="['container', isDisablePage ? 'container-disable' : '']">
    <div class="container-top" v-if="isShowTips">
      <mg-fixed-top :isFullWidth="true">
        <template #left>
            <el-alert
                :type="tipsConfig.type"
                :title="tipsConfig.title"
                :closable="false"
            />
        </template>
      </mg-fixed-top>
    </div>
    <div class="container-header">
      <slot name="headerSearch"></slot>
    </div>
    <slot></slot>
    <div class="container-table">
      <slot name="table"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 顶部提示
    tipsConfig: {
      type: [Object, Function],
      default: () => {
        return {
          type: 'error',
          title: '当前页面数据不可用'
        }
      }
    },
    // 是否显示顶部tips
    isShowTips: {
      type: Boolean,
      default: false
    },
    // 是否禁用当前页面
    isDisablePage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    mgFixedTop: () => import('@/mg-components/mg-fixed-top')
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    &-header, &-table {
      background: white;
      border-radius: 8px;
      padding: 8px;
    }
    &-header {
      margin-bottom: 8px;
    }
    &-table {
      flex: 1;
      height: 0;
    }
    &-disable {
      .container-header, .container-table {
        pointer-events: none;
        opacity: .3;
        user-select: none;
      }
    }
  }
</style>
