<template>
  <div class="mg-pagination-table">
    <div class="mg-table-topbar" v-if="isShowTopBar">
      <div class="topbar-left">
        <slot name="topleft" />
      </div>
      <div class="topbar-right">
        <slot name="topright" />
      </div>
    </div>
    <el-table
      ref="mgTable"
      size="mini"
      v-loading="loading"
      empty-text="暂无数据"
      border
      :class="[
        'mg-customer-table-container',
        { 'mg-customer-table--header': showSearchBar },
        isHeaderMiddle ? 'mg-customer-table-headerAlign' : '',
      ]"
      :data="initialData || list"
      :max-height="maxHeight"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot name="selection" />

      <div slot="empty" v-if="$slots.empty">
        <slot name="empty" />
      </div>

      <template v-for="column in columns">
        <!-- 单元格合并 树形结构数据 :filter-method="filterHandler" -->
        <mg-table-column
          v-if="column.children && column.children.length > 0"
          :key="column.label"
          :tableColumns="column"
          
        />

        <!-- 自定义的表格行 不能使用 v-bind="column" :filter-method="filterHandler" -->
        <el-table-column
          v-else-if="column.slot"
          :key="column.prop"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :align="column.align"
          :prop="column.prop"
          :sortable="column.sortable"
          :sort-by="column.sortBy"
          :fixed="column.fixed"
          :show-overflow-tooltip="column.showOverflowTooltip"
        >
          <template #default="scoped">
            <slot :name="column.slot" v-bind="scoped" />
          </template>
          <!-- 自定义表头 -->
          <template v-if="column.headerSlot" #header="scoped">
            <span class="mg-customer-table-header-cell-label">{{ column.label }}</span>
            <div v-if="showSearchBar" class="mg-customer-table-header-cell-body">
              <slot :name="column.headerSlot" v-bind="scoped" />
            </div>
          </template>
        </el-table-column>

        <!-- 使用默认表格列渲染 -->
        <template v-else-if="column.default">
          <slot :name="column.prop" />
        </template>

        <!-- 常规数值显示的表格行
          :filters="[]"
          :filter-method="filterHandler"
         -->
        <el-table-column
          v-else
          show-overflow-tooltip
          v-bind="column"
          :key="column.prop"
        >
          <template v-if="column.headerSlot" #header="scoped">
            <span class="mg-customer-table-header-cell-label">{{ column.label }}</span>
            <div v-if="showSearchBar" class="mg-customer-table-header-cell-body">
              <slot :name="column.headerSlot" v-bind="scoped" />
            </div>
          </template>
        </el-table-column>
      </template>
      <slot />
    </el-table>
    <el-pagination
      class="mg-pagination-paging"
      v-if="showPagination"
      background
      :current-page="page"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pagination.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  export default {
    props: {
      isShowTopBar: {
        type: Boolean,
        default: false
      },
      columns: {
        type: Array,
        default: () => [],
      },
      appendToBody: {
        type: Boolean,
        default: false,
      },
      // 是否启用搜索
      searchable: {
        type: Boolean,
        default: false,
      },
      // 是否需要显示分页
      showPagination: {
        type: Boolean,
        default: true,
      },
      // 是否需要mini显示分页
      showMiniPagination: {
        type: Boolean,
        default: true,
      },
      initialData: Array,
      api: Function,
      apiParams: Object,
      autoLoad: Boolean,
      // 处理列表请求后的数据
      formatData: Function,
      maxHeight: {
        type: [String, Number],
        default: '500px',
      },
      isHeaderMiddle: {
        type: Boolean,
        default: false,
      },
      // 需要查询参数对象
      filtersForm: {
        type: Object,
        default: () => {},
      },
      // 额外参数
      extraParams: {
        type: Object,
        default: null
      }
    },
    components: {
      mgTableColumn: () => import('./components/mg-table-column'),
    },
    data() {
      return {
        pagination: {
          page: this.apiParams?.page || 1,
          limit: this.apiParams?.limit || 10,
        },
        list: this.$attrs.data,
        loading: false,
        total: 0,
        showSearchBar: false,
        listsResult: null
      }
    },
    watch: {
      'apiParams.page': function (page) {
        this.pagination.page = page
      },
      initialData: {
        immediate: true,
        handler(value, old) {
          // 只需要调用一次 doLayout
          if (old?.length === 0 && value?.length > 0) {
            this.$nextTick(() => {
              this.$refs.mgTable.doLayout()
            })
          }
        },
      },
      filtersForm: {
        handler(newVal) {
          Object.keys(newVal).forEach((key) => {
            const column = this.columns
              .map((item, index) => {
                if (item.prop && item.prop === key) {
                  return index
                } else {
                  return
                }
              })
              .filter((item) => !isNaN(item))
            this.setDefaultFilter(column[0], newVal[key])
          })
        },
        deep: true,
      },
    },
    computed: {
      page() {
        return this.pagination.page
      },
      totalPage() {
        return Math.ceil(this.total / this.pagination.limit)
      },
      getRawInstance() {
        return this.$refs.mgTable
      },
    },
    mounted() {
      Promise.resolve().then(() => {
        if (this.api && this.autoLoad) {
          this.loadData()
        }
      })
    },
    methods: {
      // 重置
      resetClearFilter() {
        this.$refs.mgTable.clearFilter()
        this.filtersForm &&
          Object.keys(this.filtersForm).forEach((key) => {
            this.filtersForm[key] = ''
          })
      },
      // 表头过滤
      filterHandler(value, row, column) {
        const keys = Object.keys(this.filtersForm)
        if (row[column.property] && keys.includes(column.property)) {
          return (
            row[column.property]
              .toString()
              .toLocaleLowerCase()
              .indexOf(this.filtersForm[column.property].toString().toLocaleLowerCase()) >= 0
          )
        } else {
          return true
        }
      },
      // 设置表头过滤参数
      setDefaultFilter(col, val) {
        const column = this.$refs.mgTable.columns[col]
        column.filteredValue = [val]
        this.$refs.mgTable.store.commit('filterChange', {
          column,
          values: column.filteredValue,
          silent: true,
        })
      },
      handleCurrentChange(page) {
        this.pagination.page = page
        this.loadData()
      },
      handleSizeChange(limit) {
        this.pagination.page = 1
        this.pagination.limit = limit
        this.loadData()
      },
      handlePageChange(page) {
        this.handleCurrentChange(page)
      },
      loadData() {
        this.$emit('loading', true)
        this.loading = true
        return new Promise((resolve, reject) => {
          this.api({
            ...this.apiParams,
            ...this.pagination,
          })
            .then(async (res) => {
              if (typeof this.formatData === 'function') {
                await this.formatData(res.data?.datas)
              }
              if ( this.extraParams ) {
                this.list = res.data?.datas && res.data?.datas.map(item => {
                  return {
                    ...item,
                    ...this.extraParams
                  }
                }) || []
              }
              
              if ( !this.extraParams ) this.list = res.data?.datas || []
              this.$emit('update:initialData', [...(this.list || [])])
              this.total = res.data?.total || 0
              if (this.pagination.page > 1 && this.list.length === 0) {
                this.pagination.page = 1
                this.loadData()
              }
              this.$emit('getTableData', res.data)
              this.listsResult = res;
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              this.loading = false
              this.$emit('loading', false)
              this.$nextTick(() => {
                this.$refs.mgTable.doLayout()
              })
            })
        })
      },
      handleSingleClick(e) {
        console.log(e)
      },
      setTableTotal(num) {
        this.total = num
      },
      clear() {
        this.list = []
        this.total = 0
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mg-pagination-table {
    .mg-table-topbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .topbar-left,
      .topbar-right {
        display: flex;
        align-items: center;
      }
    }

    .mg-table--header {
      .mg-customer-table-header-cell-label {
        border-bottom: 1px solid #ebeef5;
      }
    }

    .mg-customer-table-header-cell-label {
      height: 28px;
      line-height: 28px;
      box-sizing: border-box;
      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Hiragino Sans GB,
        Microsoft Yahei, STHeiti, SimSun, sans-serifsans-serif;
      font-weight: 500;
    }
    .mg-customer-table-header-cell-body {
      padding: 0 5px;
    }
    .mg-customer-table-container {
      ::v-deep .el-table__header {
        // 奇数行 -- 奇数列
        .oddHeaderCellColspan:nth-child(odd) {
          background: #d2efc8;
        }
        // 奇数行 -- 偶数列
        .oddHeaderCellColspan:nth-child(even) {
          background: #ffe3a6;
        }
      }
      // 偶数行 -- 奇数列
      ::v-deep .eventHeaderCellColspan:nth-child(odd) {
        background: #bcd4ff;
      }
      // 偶数行 -- 偶数列
      ::v-deep .eventHeaderCellColspan:nth-child(even) {
        background: #ffc3c3;
      }
    }
    .mg-customer-table-headerAlign {
      ::v-deep .el-table__cell {
        vertical-align: middle !important;
      }
    }
    .mg-pagination-paging {
      margin-top: 20px;
    }
  }
  
  ::v-deep {
    .el-table__column-filter-trigger {
      display: none !important;
    }
    .el-table th.el-table__cell > .cell.highlight {
      color: #000000;
    }
    * {
      transition: none;
    }
  }
</style>
