const filterColumns = {
  baseInfo: {
    list: ["商品名称", "京东等级", "供价", "赛道", "大类", "中类", "小类", '箱规', "销售单位", "是否售完即止", "店铺名称", "DRP编码","库存状态"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "基础信息",
  },
  stockData: {
    list: ["京东仓库存（含采购未到货）","科技集库仓库库存"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "库存数据",
  },
  saleCount: {
    list: ["上一周期", "全国周转", "京东各仓周转", "京东各仓日均", "全国日均统计"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "销售数据",
  },
  demandData: {
    list: ["京东各仓需求量"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "需求数据",
  },
  supplyData: {
    list: ["补货提示", "单仓发全国", "补货备注"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "配货信息",
  }
};

const dynamicColumns = [
  { label: "JD码", prop: "goodsSku", show: true },
  { label: "科技SKU", prop: "ecSkuCode", show: true },
  { filterLabel: '商品名称',label: "商品名称", prop: "goodsName", show: false, width: '240px' },
  { filterLabel: '京东等级',label: "京东等级", prop: "nationalMThreeLevelSalesBand", show: false },
  { filterLabel: '供价',label: "供价", prop: "supplyPrice", show: false },
  { filterLabel: '赛道',label: "赛道", prop: "raceName", show: false },
  { filterLabel: '大类',label: "大类", prop: "mainCategoryName", show: false },
  { filterLabel: '中类',label: "中类", prop: "midCategoryName", show: false },
  { filterLabel: '小类',label: "小类", prop: "subCategoryName", show: false },
  { filterLabel: '箱规',label: "箱规", prop: "cartonSpec", show: false },
  { filterLabel: '销售单位',label: "销售单位", prop: "saleUnit", show: false },
  { filterLabel: '是否售完即止',label: "是否售完即止", prop: "soldOut", show: false },
  { filterLabel: '店铺名称',label: "店铺名称", prop: "shopName", show: false, width: '240px' },
  { filterLabel: 'DRP编码',label: "DRP编码", prop: "spuCode", show: false },
  { filterLabel: '库存状态',label: "库存状态", prop: "kcStatus", show: false },

  { filterLabel: '京东仓库存（含采购未到货）', label: "广州仓库存", prop: "nationalInventoryGzContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "上海仓库存", prop: "nationalInventoryShContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "北京仓库存", prop: "nationalInventoryBjContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "西安仓库存", prop: "nationalInventoryXaContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "成都仓库存", prop: "nationalInventoryCdContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "德州仓库存", prop: "nationalInventoryDzContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "武汉仓库存", prop: "nationalInventoryWhContain", show: false },
  { filterLabel: '京东仓库存（含采购未到货）', label: "沈阳仓库存", prop: "nationalInventorySyContain", show: false },
  { filterLabel: '科技集库仓库库存', label: "集库库存", prop: "jkKcCount", show: false },
  { filterLabel: '科技集库仓库库存', label: "华北库存", prop: "hbKcCount", show: false },
  { filterLabel: '科技集库仓库库存', label: "华南库存", prop: "hnKcCount", show: false },

  { filterLabel: '全国周转', label: "全国周转", prop: "expectedSalesNational", show: false },
  {  filterLabel: '京东各仓周转', label: "广州周转", prop: "expectedSalesDaysGz", show: false },
  {  filterLabel: '京东各仓周转', label: "上海周转", prop: "expectedSalesDaysSh", show: false },
  {  filterLabel: '京东各仓周转', label: "北京周转", prop: "expectedSalesDaysBj", show: false },
  {  filterLabel: '京东各仓周转', label: "西安周转", prop: "expectedSalesDaysXa", show: false },
  {  filterLabel: '京东各仓周转', label: "成都周转", prop: "expectedSalesDaysCd", show: false },
  {  filterLabel: '京东各仓周转', label: "德州周转", prop: "expectedSalesDaysDz", show: false },
  {  filterLabel: '京东各仓周转', label: "武汉周转", prop: "expectedSalesDaysWh", show: false },
  {  filterLabel: '京东各仓周转', label: "沈阳周转", prop: "expectedSalesDaysSy", show: false },

  {  filterLabel: '京东各仓日均', label: "全国日均合计", prop: "nationalInventoryAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "广州日均", prop: "nationalInventoryGzAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "上海日均", prop: "nationalInventoryShAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "北京日均", prop: "nationalInventoryBjAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "西安日均", prop: "nationalInventoryXaAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "成都日均", prop: "nationalInventoryCdAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "德州日均", prop: "nationalInventoryDzAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "武汉日均", prop: "nationalInventoryWhAvgNew", show: false },
  {  filterLabel: '京东各仓日均', label: "沈阳日均", prop: "nationalInventorySyAvgNew", show: false },

  { filterLabel: '京东各仓需求量', label: "北京需求", prop: "nationalInventoryBjNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "成都需求", prop: "nationalInventoryCdNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "沈阳需求", prop: "nationalInventorySyNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "德州需求", prop: "nationalInventoryDzNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "武汉需求", prop: "nationalInventoryWhNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "上海需求", prop: "nationalInventoryShNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "广州需求", prop: "nationalInventoryGzNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "西安需求", prop: "nationalInventoryXaNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "集库需求总和", prop: "jkNationalInventoryNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "华北需求总和", prop: "hbNationalInventoryNeed", show: false },
  { filterLabel: '京东各仓需求量', label: "华南需求总和", prop: "hnNationalInventoryNeed", show: false },

  // { label: "下单合计(配货信息)", prop: "totalReal", show: true },
  // { label: "补货提示(配货信息)", prop: "needBh", show: true },
  // { label: "单仓发全国(配货信息)", prop: "singleToWhole", show: true },
  // { label: "补货备注(配货信息)", prop: "remark", show: true },
  // { label: "采购场景(配货信息)", prop: "purchaseScene", show: true },
  // { label: "预计消化时间(配货信息)", prop: "estimatedDigestionTime", show: true },
];

const fixedColumns = [
  { label: "北京实际", prop: "bjReal", show: false },
  { label: "成都实际", prop: "cdReal", show: false },
  { label: "沈阳实际", prop: "syReal", show: false },
  { label: "德州实际", prop: "dzReal", show: false },

  { label: "武汉实际", prop: "whReal", show: false },
  { label: "上海实际", prop: "shReal", show: false },
  { label: "广州实际", prop: "gzReal", show: false },
  { label: "西安实际", prop: "xaReal", show: false },

  // { label: "下单合计(配货信息)", prop: "totalReal", show: true },
  // { label: "补货提示(配货信息)", prop: "needBh", show: true },
  // { label: "单仓发全国(配货信息)", prop: "singleToWhole", show: true },
  // { label: "补货备注(配货信息)", prop: "remark", show: true },
  // { label: "采购场景(配货信息)", prop: "purchaseScene", show: true },
  // { label: "预计消化时间(配货信息)", prop: "estimatedDigestionTime", show: true },
];

const hastenFilterColumns = {
  baseInfo: {
    list: ["电商等级", "销售单位", "赛道", "大类", "中类", "小类", '箱规', "进货价", "混色", "配比", "订单类型","总部等级","科技等级","上柜时间","供价"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "基础信息",
  },
  stockData: {
    list: ["京东前台库存","总部库存", "已做欠量", "未做欠量"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "库存数据",
  },
  saleCount: {
    list: ["展开近6月销售量", "28天销售", "14天销售", "7天销售"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "销售数据",
  },
  supplyData: {
    list: ["需求数量", "催货金额"],
    checked: [],
    checkedBefore: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "需求数据",
  }
};

const hastenDynamicColumns = [
  { label: "电商等级", prop: "jdLevel", show: false },
  { label: "销售单位", prop: "saleUnit", show: false },
  { label: "赛道", prop: "raceName", show: false },
  { label: "大类", prop: "mainCategoryName", show: false },
  { label: "中类", prop: "midCategoryName", show: false },
  { label: "小类", prop: "subCategoryName", show: false },
  { label: "箱规", prop: "cartonSpec", show: false },
  { label: "进货价", prop: "avgCost", show: false },
  { label: "混色", prop: "ifMixtz", show: false },
  { label: "配比", prop: "ratio", show: false },
  { label: "订单类型", prop: "orderType", show: false },
  { label: "总部等级", prop: "hqLevel", show: false },
  { label: "科技等级", prop: "ecLevel", show: false },
  { label: "上柜时间", prop: "sgDate", show: false },
  { label: "供价", prop: "supplyPrice", show: false },

  { label: "京东前台库存", prop: "nationalOrderableInventory", show: false },
  { filterLabel: '总部库存', label: "总部立库库存", prop: "qmKcCountHqLk", show: false },
  { filterLabel: '总部库存', label: "总部华南库存", prop: "qmKcCountHqHn", show: false },
  { filterLabel: '总部库存', label: "总部华北库存", prop: "qmKcCountHqHb", show: false },
  { filterLabel: '已做欠量',label: "已做欠量(华东)", prop: "qlCountYzJk", show: false },
  { filterLabel: '已做欠量',label: "已做欠量(华南)", prop: "qlCountYzHn", show: false },
  { filterLabel: '已做欠量',label: "已做欠量(华北)", prop: "qlCountYzHb", show: false },
  { filterLabel: '已做欠量',label: "已做欠量(华中)", prop: "qlCountYzHz", show: false },
  { filterLabel: '未做欠量',label: "未做欠量(华东)", prop: "qlCountWzJk", show: false },
  { filterLabel: '未做欠量',label: "未做欠量(华南)", prop: "qlCountWzHn", show: false },
  { filterLabel: '未做欠量',label: "未做欠量(华北)", prop: "qlCountWzHb", show: false },
  { filterLabel: '未做欠量',label: "未做欠量(华中)", prop: "qlCountWzHz", show: false },

  {  filterLabel: '展开近6月销售量',label: "前第6月销售量", prop: "tradeGoodsCount6mth", show: false },
  {  filterLabel: '展开近6月销售量',label: "前第5月销售量", prop: "tradeGoodsCount5mth", show: false },
  {  filterLabel: '展开近6月销售量',label: "前第4月销售量", prop: "tradeGoodsCount4mth", show: false },
  {  filterLabel: '展开近6月销售量',label: "前第3月销售量", prop: "tradeGoodsCount3mth", show: false },
  {  filterLabel: '展开近6月销售量',label: "前第2月销售量", prop: "tradeGoodsCount2mth", show: false },
  {  filterLabel: '展开近6月销售量',label: "前第1月销售量", prop: "tradeGoodsCount1mth", show: false },
  { label: "28天销售", prop: "tradeGoodsCount28dayZxdw", show: false },
  { label: "14天销售", prop: "tradeGoodsCount14dayZxdw", show: false },
  { label: "7天销售", prop: "tradeGoodsCount7dayZxdw", show: false },

  { filterLabel: '需求数量', label: "需求数量(华南)", prop: "hastenCountHn1", show: false },
  { filterLabel: '需求数量', label: "需求数量(华东)", prop: "hastenCountJk1", show: false },
  { filterLabel: '需求数量', label: "需求数量(华北)", prop: "hastenCountHb1", show: false },
  { filterLabel: '需求数量', label: "需求数量(华中)", prop: "hastenCountHz1", show: false },
  { filterLabel: '催货金额', label: "催货金额(华南)", prop: "hastenMoneyHn", show: false },
  { filterLabel: '催货金额', label: "催货金额(华东)", prop: "hastenMoneyHd", show: false },
  { filterLabel: '催货金额', label: "催货金额(华北)", prop: "hastenMoneyHb", show: false },
  { filterLabel: '催货金额', label: "催货金额(华中)", prop: "hastenMoneyHz", show: false },
];


export {
  filterColumns, // 列编辑过滤列
  fixedColumns,
  dynamicColumns,
  hastenFilterColumns,
  hastenDynamicColumns
};
