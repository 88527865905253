<template>
  <div class="configCenter-page">
    <div class="configCenter-search">
      <v-signboard :title="'物料维表'"/>
      <el-form
        class="main-search"
        size="mini"
        label-width="auto"
        ref="filialeCustome-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleHeadAction('search')"
      >
        <el-form-item label="原始料号" size="mini">
          <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
        </el-form-item>

        <el-form-item label="换算料号" size="mini">
          <el-input v-model="form.trans_material_code" clearable placeholder="请输入换算料号"/>
        </el-form-item>

        <el-form-item label="数据类型" size="mini">
          <el-select clearable v-model="form.data_type">
            <el-option label="全部" :key="1" :value="1"></el-option>
            <el-option label="正常" :key="2" :value="2"></el-option>
            <el-option label="CNT汇总!=1" :key="3" :value="3"></el-option>
            <el-option label="重复Key" :key="4" :value="4"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          class="form-btn-item"
          size="mini"
          type="primary"
          :loading="btnStatus.isSearch"
          @click="handleHeadAction('search')"
        >查 询
        </el-button>
        <el-button
          class="form-btn-item"
          size="mini"
          :loading="btnStatus.isReset"
          @click="handleHeadAction('reset')"
        >重 置
        </el-button>

        <el-button
          size="mini"
          type="primary"
          :disabled="btnStatus.isSave"
          @click="handleTableHeadAction('save')">保 存
        </el-button>

        <el-button
          size="mini"
          type="primary"
          :loading="btnStatus.isExportLoading"
          @click="handleTableHeadAction('export')"
        >导 出
        </el-button>
      </el-form>
    </div>
    <div class="configCenter-content">
      <el-table
        class="table"
        stripe
        border
        height="500"
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <el-table-column label="序号" align="center" width="55" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.materialDimReportHead">
          <!-- 折算系数 -- 可编辑 -->
          <el-table-column v-if="['cnt'].includes(item.prop)" v-bind="item"
                           show-overflow-tooltip :key="index" align="center">
            <template slot-scope="scope">
              <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'cnt'"
                        v-model="scope.row.cnt" size="mini" clearable/>
            </template>
          </el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport} from "@/utils";
import {materialDimReportHead} from '../config';
import {handleDimMaterialPage, handleDimMaterialUpdateCnt} from "../../../api/configCenter";
import {handleMadeProdSave} from "../../../api/prodCenter";

export default {
  // 物料维表
  name: 'materialDimReport',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination"),
  },
  data() {
    let data = {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSearch: false,                // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 保存
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        materialDimReportHead
      },
      selectRows: [],
      confirm_bo_kd_plu_count_copy: '',
      urlMonth: '',                       // 月份
    }
    console.log(data)
    return data
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isAdjust = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      data_type: 1,
      ...this.form,
      material_code: '',
    };
    this.form = formPrams;
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 返回
    handleBack() {
      this.$router.go(-1);
    },
    handleHeadAction(type) {
      const funcObj = {
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {                // 重置
          this.form = {
            material_code: '',
            trans_material_code: '',
            data_type: '',
          };
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          material_code: form.material_code || '',
          trans_material_code: form.trans_material_code || '',
          data_type: form.data_type || '',
          operator: this.urlParams.operator,
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleDimMaterialPage(params);
        if (data) {
          const {dim_material_list, total} = data;
          this.modifyData(dim_material_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取物料维表列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {

          // let newItem = {
          //   mould_code: item.mould_code || '--',
          //   material_code: item.material_code || '--',
          //   trans_material_code: item.trans_material_code || '--',
          // };
          item['is_blind_box_text'] = item['is_blind_box'] === 1? '是' : '否';
          newItems.push(item);
        });
        console.log(newItems);
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.getLists();
    },
    // 全选函数
    async handleselection(e) {
      this.selectRows = e;
    },
    // 列表操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {         // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              id: item.id || '',
              cnt: item.cnt || 0
            };
            newItems.push(newItem);
          });
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator,
            update_list: newItems
          };
          const {success} = await handleDimMaterialUpdateCnt(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('折算系数已保存');
          }
          if (!success) this.getLists();
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      const topHeaders = {}
      for (let i = 0; i < materialDimReportHead.length; i++) {
        topHeaders[materialDimReportHead[i].prop] = materialDimReportHead[i].label
      }

      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          trans_material_code: form.trans_material_code || '',
          material_code: form.material_code,
          operator: this.urlParams.operator
        };
        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleDimMaterialPage(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {dim_material_list} = data;
          if (dim_material_list.length > 0) {
            for(let i = 0; i < dim_material_list.length; i++) {
              let item = dim_material_list[i];
              item['is_blind_box_text'] = item['is_blind_box'] === 1? '是' : '否';

            }
            excelExport({
              topHeaders,
              data: dim_material_list,
              title: '物料维表列表'
            })
          }
          if (dim_material_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------物料维表列表导出接口有误');
      }
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/configCenter/layoutMain.scss'></style>
