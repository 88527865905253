// 获取地址栏的URL
export function getLocationUrl() {
  const LocationUrl = document.location.href;
  return LocationUrl;
}

// 获取URL参数 返回Object
export function getUrlParams(url) {
  if (!url) return {};
  let paramString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  let paramObj = {};
  for (let i = 0; i < paramString.length; i++) {
    let j = paramString[i];
    paramObj[j.substring(0, j.indexOf("="))] = j.substring(j.indexOf("=") + 1, j.length);
  }
  return paramObj;
}

export function queryParamsToString(params) {  
    // 将对象转换为数组，每个元素都是一个[key, value]数组  
    const entries = Object.entries(params);  
    const queryStrings = entries.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);  
    // 使用join('&')将数组元素连接成一个字符串  
    const queryString = queryStrings.join('&');  
    // 返回以'?'开头的字符串  
    return `?${queryString}`;  
}  

// 导出表格
export function excelExport(options) {
  const { topHeaders, title, data } = options;
  const { export_json_to_excel } = require("@/utils/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
  const headers = Object.values(topHeaders);
  const keys = Object.keys(topHeaders);
  const list = data.map(v => keys.map(j => v[j]));

  export_json_to_excel(headers, list, title); // 导出的表格名称，根据需要自己命名
}

// 千分位金额
export function thousandsSeparator(s, type) {
  if (s == null || s === "") return "0";

  let prex = "";

  if (s < 0) {
    prex = "-";
    s = 0 - s;
  }

  if (/[^0-9\.]/.test(s)) return "0";
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;

  while (re.test(s)) s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if (type == 0) {
    // 不带小数位(默认是有小数位)
    var a = s.split(".");

    if (a[1] == "00") {
      s = a[0];
    }
  }
  return prex + s;
}

// 保留两位小数
export function keepTwoDecimals(number) {
  if (typeof number != "number") {
    console.log("请传入数字！");
    return;
  }
  if (typeof number == "number") return number.toFixed(2);
}
// 保留一位位小数
export function keepOneDecimals(number) {
  if (typeof number != "number") {
    console.log("请传入数字！");
    return;
  }
  if (typeof number == "number") return number.toFixed(1);
}

export function getCurrentYM() {
  let time = new Date();
  let currentYear = time.getFullYear();
  let currentMonth = time.getMonth() + 1;
  return currentYear + "" + (currentMonth < 10 ? "0" + currentMonth : currentMonth);
}

export function getCurrentShortYM() {
  let time = new Date();
  let currentMonth = time.getMonth() + 1;
  return currentMonth < 10 ? "0" + currentMonth : currentMonth;
}

export function getPlanYM() {
  let time = new Date();
  let currentYear = time.getFullYear();
  let currentMonth = time.getMonth() + 1;

  let planYear = currentYear;
  let planMonth = currentMonth + 1;
  if (planMonth > 12) {
    planMonth = planMonth - 12;
    planYear = planYear + 1;
  }

  return planYear + "" + (planMonth < 10 ? "0" + planMonth : planMonth);
}

export function getPlanShortYM() {
  let time = new Date();
  let currentMonth = time.getMonth() + 1;

  let planMonth = currentMonth + 1;
  if (planMonth > 12) {
    planMonth = planMonth - 12;
  }

  return planMonth < 10 ? "0" + planMonth : planMonth;
}

export function getPreYM() {
  let time = new Date();
  let currentYear = time.getFullYear();
  let currentMonth = time.getMonth() + 1;

  let preYear = currentYear;
  let preMonth = currentMonth - 1;
  if (preMonth <= 0) {
    preYear = preYear - 1;
    preMonth = 12;
  }

  return preYear + "" + (preMonth < 10 ? "0" + preMonth : preMonth);
}

export function getPreShortYM() {
  let time = new Date();
  let currentMonth = time.getMonth() + 1;

  let preMonth = currentMonth - 1;
  if (preMonth <= 0) {
    preMonth = 12;
  }

  return preMonth < 10 ? "0" + preMonth : preMonth;
}

export function deleEmptyParams(params) {
  /* 剔除空参 */
  for (let i in params) {
    if (params[i] === "") delete params[i];
  }
  return params;
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */

export function deepCloneArray(arr) {
  return arr.map(item => {
    if (Array.isArray(item)) {
      return deepCloneArray(item);
    } else if (typeof item === 'object' && item !== null) {
      return deepClone(item);
    } else {
      return item;
    }
  });
}

export function deepClone(source) {
  if (typeof(source) === 'string') {
    return source + '';
  }
  if (Array.isArray(source)) {
    return deepCloneArray(source);
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] instanceof Date) {
      targetObj[keys] = source[keys];
    } else if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === "string") {
      time = time
        .replace(new RegExp(/-/gm), "/")
        .replace("T", " ")
        .replace(new RegExp(/\.[\d]{3}/gm), "");
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function parseParam(t, e) {
  var n = "";
  if ("string" == typeof t || "number" == typeof t || "boolean" == typeof t || null === t || void 0 === t)
    n += "&" + e + "=" + t;
  else if (t instanceof Array)
    $.each(t, function(i, o) {
      var r = null == e ? i : e + (t instanceof Array ? "[" + i + "]" : "." + i);
      n += "&" + parseParam(o, r)
    });
  else if (t instanceof Object)
    for (var i in t) {
      if (t[i] !== undefined && t[i] !== null) {
        var o = null == e ? i : e + (t instanceof Array ? "[" + i + "]" : "." + i);
        n += "&" + parseParam(t[i], o)
      }
    }
  return n.substr(1)
}

export function downloadByUrl(url) {
  const fileName = url.split('/')[url.split('/').length - 1];
  const a = document.createElement('a')
  a.href = url;
  //测试链接console.log(a.href)
  a.download = fileName  // 下载文件的名字
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url);
}

export function hexToRgba(hexColor, alpha) {
  if (!hexColor) {
    return ''
  }
  // 移除 # 号并提取颜色值
  var hex = hexColor.replace('#', '');
  // 将颜色值拆分成 R、G、B、A 三个部分
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);
  var a = parseInt(hex.substring(4, 6), 16);
  // 转换为 RGBA 格式并添加透明度
  var rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`
  return rgba;
}

function base64ToFile(base64) {
  const arr = base64.split(",");
  const  mime = arr[0].match(/:/)?.at(1);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // const theBlob = new Blob([u8arr], {
  //     type: mime,
  // });
  return new File([u8arr], `${new Date().getTime()}.png`, { type: mime });
}




function fetchImg(path) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (err) => reject(err);
    img.crossOrigin = "anonymous";
    img.src = path;
  })
}
export default function fileToImg (file) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()
    reader.onload = function (e) {
      img.src = e.target.result
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsDataURL(file)
    img.onload = function () {
      resolve(img)
    }
    img.onerror = function (e) {
      reject(e)
    }
  })
}

export async function removeBg(data, width, type){
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = width;

  let img;
  if (type === 'path') {
    img = await fetchImg(data);
  } else if (type === 'file') {
    img = await fileToImg(data);
  }

  ctx?.drawImage(img, 0, 0, width, width)

  const imgData = ctx.getImageData(0, 0, width, width);


  for (let index = 0; index < imgData.data.length; index++) {
    if(index % 4 === 3) {
      const [r, g, b] = [imgData.data[index - 3], imgData.data[index - 2], imgData.data[index - 1]];
      if(r === 255 && g === r && b === r) imgData.data[index] = 0;
    }
  }
  ctx?.clearRect(0, 0, width, width);
  ctx?.putImageData(imgData, 0, 0);

  // document.body.appendChild(canvas);

  return base64ToFile(canvas.toDataURL())
}
