import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/request";

// --------------数据采集页面-------------------
// 获取采集数据状态-- 列表-------
export function listSpiderData(data) {
  return axiosGet("/biSpiderStatus/page" + data, null, 1);
}
// 获取当前采集任务
export function getSingleSpider(data) {
  return axiosGet("/biSpiderStatus/" + data, null, 1);
}
// 执行采集任务
export function spider(data) {
  return axiosPost("/biSpiderStatus/spider", data, 1);
}
// 验证码登录
export function spiderLoginValidate(data) {
  return axiosPost("/biSpiderStatus/spider-validate", data, 1);
}
//  更新采集任务
export function updateSpider(data) {
  return axiosPut("/biSpiderStatus", data, 1);
}
// 新增账号
export function addSpiderUser(data) {
  return axiosPost("/biFormId", data, 1);
}

// 新增账号
export function updateSpiderUser(data) {
  return axiosPut("/biFormId", data, 1);
}

// 获取账号信息
export function getAccount(data) {
  return axiosGet("/biFormId/" + data, null, 1);
}

// 删除账号信息
export function delAccount(data) {
  return axiosDelete("/biFormId/" + data, null, 1);
}

/**
 * 获取账号信息
 * @param data
 * @returns {*}
 */
export function listAccount(data) {
  return axiosGet("/biFormId", data, 1);
}
// 获取采集数据日志-- 列表-------
export function listSpiderLog(data) {
  return axiosGet("/biFormTaskLog" + data, null, 1);
}

/**
 * 获取平台数据
 * @param data
 * @returns {*}
 */
export function getPlatformData(data) {
  return axiosGet("/biFormId/platform/" + data, null, 1);
}

// --- 各部门 平台维护 ---

// 新增平台
export function addPlatform(data) {
  return axiosPost("/platformDept", data, 1);
}

// 删除平台信息
export function delPlatform(data) {
  return axiosDelete("/platformDept", data, 1);
}
// 分页
export function pagePlatform(data) {
  return axiosGet("/platformDept", data, 1);
}
//  获取部门下拉数据
export function getDeptDict() {
  return axiosGet("/platformDept/dept", null, 1);
}

// ----  账号权限控制-----
// 新增
export function addUserAuth(data) {
  return axiosPost("/formUser", data, 1);
}

// 删除
export function delUserAuth(data) {
  return axiosDelete("/formUser", data, 1);
}
// 分页
export function pageUserAuth(data) {
  return axiosGet("/formUser", data, 1);
}
