var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "订单范围",
        visible: _vm.visible,
        width: "400px",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "dialogContent" }, [
        _c("p", [
          _vm._v(
            "查看截止今天该配送的订单的满足情况（即剔除了没到下次送货时间的订单，满足指记账）"
          ),
        ]),
        _c(
          "div",
          { staticClass: "dimensionSelectWrap" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.dimension,
                  callback: function ($$v) {
                    _vm.dimension = $$v
                  },
                  expression: "dimension",
                },
              },
              _vm._l(_vm.dimensionOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm.dimension === "week"
          ? _c(
              "div",
              _vm._l(_vm.weekForm, function (item, index) {
                return _c(
                  "div",
                  { staticClass: "routeSelectBox" },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                    _c(
                      "el-select",
                      {
                        attrs: { multiple: "", placeholder: "请选择送货路线" },
                        model: {
                          value: item.routeIds,
                          callback: function ($$v) {
                            _vm.$set(item, "routeIds", $$v)
                          },
                          expression: "item.routeIds",
                        },
                      },
                      _vm._l(_vm.routes, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                    _c("span", { staticClass: "placeRemoveIcon" }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.dimension === "day"
          ? _c(
              "div",
              [
                _vm._l(_vm.dayForm, function (item, index) {
                  return _c(
                    "div",
                    { staticClass: "routeSelectBox routeSelectBox1" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(_vm.baseDayMap[index]) +
                            "（" +
                            _vm._s(item.label) +
                            "）"
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: "请选择送货路线",
                          },
                          model: {
                            value: item.routeIds,
                            callback: function ($$v) {
                              _vm.$set(item, "routeIds", $$v)
                            },
                            expression: "item.routeIds",
                          },
                        },
                        _vm._l(_vm.routes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      index !== 0 && index === _vm.dayForm.length - 1
                        ? _c("i", {
                            staticClass: "el-icon-remove",
                            on: {
                              click: () => {
                                _vm.dayForm.splice(index, 1)
                              },
                            },
                          })
                        : _c("span", { staticClass: "placeRemoveIcon" }),
                    ],
                    1
                  )
                }),
                _c("i", {
                  staticClass: "el-icon-circle-plus",
                  on: { click: _vm.addItem },
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }