var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticClass: "form-btn-item",
          attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.handleAdd },
        },
        [_vm._v("新建任务 ")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: { model: _vm.formData, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台名" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.platform_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "platform_name", $$v)
                      },
                      expression: "formData.platform_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心编码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.dc_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "dc_code", $$v)
                      },
                      expression: "formData.dc_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.dc_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "dc_name", $$v)
                      },
                      expression: "formData.dc_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下载文件通用特征" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.file_features,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "file_features", $$v)
                      },
                      expression: "formData.file_features",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下载文件路径" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.file_path,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "file_path", $$v)
                      },
                      expression: "formData.file_path",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.formData.items, function (item, index) {
                return _c(
                  "div",
                  { key: item.id },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: `步骤编码 ${index + 1}`,
                          prop: "items." + index + ".step_coding",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: item.step_coding,
                              callback: function ($$v) {
                                _vm.$set(item, "step_coding", $$v)
                              },
                              expression: "item.step_coding",
                            },
                          },
                          _vm._l(_vm.setpDataList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: `图片信息 ${index + 1}`,
                          prop: "items." + index + ".photo_url",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: "#",
                              "on-preview": _vm.handlePreview,
                              "on-remove": (file, fileList) =>
                                _vm.handleRemove(file, fileList, index),
                              "before-remove": _vm.beforeRemove,
                              "on-change": (file, fileList) =>
                                _vm.handleFileChange(file, index),
                              multiple: "",
                              limit: 3,
                              "on-exceed": _vm.handleExceed,
                              "file-list": item.fileList,
                              "auto-upload": false,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("点击上传")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v("只能上传jpg/png文件，且不超过500kb")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addFormItem } },
                [_vm._v("增加一行")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "platform_name",
                  label: "平台名",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "dc_name", label: "配送中心", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRowData(row)
                              },
                            },
                          },
                          [_vm._v(" 删除任务 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditAccount(row)
                              },
                            },
                          },
                          [_vm._v("任务编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }