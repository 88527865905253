var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prodCenter-page" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "prodCenter-tabs",
          staticStyle: { height: "200x" },
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              staticClass: "prodCenter-tabs-item",
              attrs: { label: "在线填写", name: "onlineWrite" },
            },
            [
              _vm.activeName == "onlineWrite"
                ? _c(
                    "div",
                    { staticClass: "prodCenter-content moldProd-content" },
                    [
                      _c("v-signboard", { attrs: { title: "模具产能盘点" } }),
                      _c(
                        "el-form",
                        {
                          ref: "moldProdCapacity-form",
                          staticClass: "main-search",
                          attrs: {
                            size: "mini",
                            "label-width": "auto",
                            inline: true,
                            model: _vm.form,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { staticClass: "list-head" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "生产单位",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入生产单位",
                                        },
                                        model: {
                                          value: _vm.form.production_unit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "production_unit",
                                              $$v
                                            )
                                          },
                                          expression: "form.production_unit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "模号", size: "mini" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入模号",
                                        },
                                        model: {
                                          value: _vm.form.mould_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "mould_code",
                                              $$v
                                            )
                                          },
                                          expression: "form.mould_code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "search"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查 询 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reverseChecked()
                                        },
                                      },
                                    },
                                    [_vm._v("反 选 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        disabled: _vm.btnStatus.isSave,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "save"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("保 存 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "warning",
                                        disabled: _vm.btnStatus.isDelete,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "delete"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删 除 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "batchUpdateWorkDayCount"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("批量修改工作日数 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "confirm"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("确 认 ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        "font-size": "14px",
                                        color: "green",
                                      },
                                      attrs: { id: "confirmTips" },
                                      domProps: {
                                        textContent: _vm._s(_vm.confirmTips),
                                      },
                                    },
                                    [_vm._v("未确认")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableStatus.isLoading,
                              expression: "tableStatus.isLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticClass: "table",
                          attrs: {
                            stripe: "",
                            border: "",
                            size: "mini",
                            data: _vm.lists,
                          },
                          on: { "selection-change": _vm.handleselection },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              align: "center",
                              width: "50",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.currentPage - 1) *
                                                _vm.pageSize +
                                              1
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2762312633
                            ),
                          }),
                          _vm._l(
                            _vm.tableObj.moldProdCapacityHead,
                            function (item, index) {
                              return [
                                ["work_day_count", "day_prod_count"].includes(
                                  item.prop
                                )
                                  ? _c(
                                      "el-table-column",
                                      _vm._b(
                                        {
                                          key: index,
                                          attrs: {
                                            "show-overflow-tooltip": "",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    item.prop ==
                                                    "work_day_count"
                                                      ? _c("el-input", {
                                                          staticClass:
                                                            "tgqrcount-cell-ipt",
                                                          attrs: {
                                                            type: "number",
                                                            size: "mini",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .work_day_count,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "work_day_count",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.work_day_count",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.prop ==
                                                    "day_prod_count"
                                                      ? _c("el-input", {
                                                          staticClass:
                                                            "tgqrcount-cell-ipt",
                                                          attrs: {
                                                            type: "number",
                                                            size: "mini",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .day_prod_count,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "day_prod_count",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.day_prod_count",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        "el-table-column",
                                        item,
                                        false
                                      )
                                    )
                                  : _c(
                                      "el-table-column",
                                      _vm._b(
                                        {
                                          key: index,
                                          attrs: {
                                            "show-overflow-tooltip": "",
                                            align: "center",
                                          },
                                        },
                                        "el-table-column",
                                        item,
                                        false
                                      )
                                    ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                      _c("v-pagination", {
                        attrs: {
                          total: _vm.total,
                          pageSize: _vm.pageSize,
                          currentPage: _vm.currentPage,
                        },
                        on: { pageChange: _vm.pagingChange },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-tab-pane",
            {
              staticClass: "prodCenter-tabs-item",
              attrs: { label: "表单上传", name: "formWrite" },
            },
            [
              _vm.activeName == "formWrite"
                ? _c(
                    "div",
                    { attrs: { clas: "form-upload-box" } },
                    [
                      _c("upload-excel", {
                        ref: "uploadExcelRef",
                        attrs: {
                          uploadDatas: _vm.uploadFileObj.tableData,
                          "on-success": _vm.handleFileSuccess,
                          "before-upload": _vm.beforeFileUpload,
                          tipsText: _vm.uploadFileObj.tipsText,
                        },
                        on: {
                          handlePreview: _vm.handlePreview,
                          handleConfirmUpload: _vm.handleConfirmUpload,
                        },
                      }),
                      _vm.uploadFileObj.preTableData.length > 0
                        ? [
                            _c(
                              "div",
                              { staticClass: "previewTable" },
                              [
                                _c("v-signboard", {
                                  attrs: { title: "文件预览" },
                                }),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { staticClass: "handleConfirmUpload" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("确认上传")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.uploadFileObj.preTableData,
                                      stripe: "",
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  _vm._l(
                                    _vm.uploadFileObj.tableHeader,
                                    function (item) {
                                      return _c("el-table-column", {
                                        key: item,
                                        attrs: { prop: item, label: item },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "previewTable previewTable-sample",
                              },
                              [
                                _c("v-signboard", {
                                  attrs: { title: "文件上传模板示例" },
                                }),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.uploadFileObj
                                        .templateSampleLists,
                                      stripe: "",
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.uploadFileObj.templateSampleHead,
                                      function (item, index) {
                                        return [
                                          _c(
                                            "el-table-column",
                                            _vm._b(
                                              {
                                                key: index,
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  align: "center",
                                                },
                                              },
                                              "el-table-column",
                                              item,
                                              false
                                            )
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "batch-update-work-day-count-dialog",
          attrs: {
            title: "批量修改工作日数",
            width: "650px",
            visible:
              _vm.batchUpdateWorkDayCountDialogStatus.isBatchUpdateWorkDayCount,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.batchUpdateWorkDayCountDialogStatus,
                "isBatchUpdateWorkDayCount",
                $event
              )
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  staticClass: "dialog-form",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    size: "mini",
                    model: _vm.batchUpdateWorkDayCountDialogFormModel,
                    rules: _vm.batchUpdateWorkDayCountDialogFormModelRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "工作日数",
                                size: "mini",
                                prop: "work_day_count",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "input-number",
                                    rawName: "v-input-number",
                                  },
                                ],
                                attrs: {
                                  min: "0",
                                  max: "31",
                                  maxlength: "2",
                                  placeholder: "请填写工作日数",
                                  clearable: "",
                                },
                                model: {
                                  value:
                                    _vm.batchUpdateWorkDayCountDialogFormModel
                                      .work_day_count,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.batchUpdateWorkDayCountDialogFormModel,
                                      "work_day_count",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "batchUpdateWorkDayCountDialogFormModel.work_day_count",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.doBatchUpdateWorkDayCount },
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.cancelBatchUpdateWorkDayCount },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }