<template>
  <div class="schedulingPlan-page">
    <el-tabs class="salesCenter-tabs" tab-position="top" v-model="activeName" style="height: 200x;">
      <el-tab-pane label="SKU明细" class="salesCenter-tabs-item" name="skuDetail">

        <div class="schedulingPlan-search">
          <v-signboard :title="'自定义策略'"/>
          <el-form
            class="main-search"
            size="mini"
            label-width="auto"
            ref="filialeCustome-form"
            :inline="true"
            :model="form"
            @keyup.enter.native="handleSubmit"
          >
            <el-form-item label="产品等级" size="mini">
              <el-select clearable v-model="form.productGrade" multiple>
                <el-option
                  v-for="item in options.productGradeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="大类" size="mini">
              <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
                <el-option
                  v-for="item in options.productMaxCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中类" size="mini">
              <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                <el-option
                  v-for="item in options.productMidCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小类" size="mini">
              <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
                <el-option
                  v-for="item in options.productMinCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品赛道" size="mini">
              <el-select clearable v-model="form.race_name">
                <el-option
                  v-for="item in options.raceNameLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原始料号" size="mini">
              <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
            </el-form-item>

            <el-form-item label="客订满足率" size="mini">
              <el-select clearable v-model="form.bo_kd_fill_rate_type">
                <el-option value="1" label="100%">100%</el-option>
                <el-option value="2" label="低于100%">低于100%</el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否有客订" size="mini">
              <el-select clearable v-model="form.order_count_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="有客订" :key="1" :value="1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="预排产量分类" size="mini">
              <el-select clearable v-model="form.arrange_count_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="未下单" :key="1" :value="1"></el-option>
                <el-option label="下单超一倍,预排产量>分公司客订量*2" :key="2" :value="2"></el-option>
                <el-option label="下单低一半,预排产量<分公司客订量/2" :key="3" :value="3"></el-option>
                <el-option label="正常" :key="4" :value="4"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="下月底库存系数分类" size="mini">
              <el-select clearable v-model="form.stock_ratio_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="超过库存系数上限" :key="1" :value="1"></el-option>
                <el-option label="低于库存系数下限" :key="2" :value="2"></el-option>
                <el-option label="正常" :key="3" :value="3"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="下月底库存系数" size="mini">
              <el-select clearable v-model="form.plan_stock_ratio" multiple>
                <el-option label="x<0" :key="1" :value="1"></el-option>
                <el-option label="0≤x≤0.5" :key="2" :value="2"></el-option>
                <el-option label="0.5<x≤1" :key="3" :value="3"></el-option>
                <el-option label="1<x≤1.5" :key="4" :value="4"></el-option>
                <el-option label="1.5<x≤2" :key="5" :value="5"></el-option>
                <el-option label="2<x≤2.5" :key="6" :value="6"></el-option>
                <el-option label="2.5<x≤3" :key="7" :value="7"></el-option>
                <el-option label="3<x≤3.5" :key="8" :value="8"></el-option>
                <el-option label="x>3.5" :key="9" :value="9"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="算法是否调整" size="mini">
              <el-select clearable v-model="form.algorithm_adjust">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="是" :key="1" :value="1"></el-option>
                <el-option label="否" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="锁状态" size="mini">
              <el-select clearable v-model="form.lock_state">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="是" :key="1" :value="1"></el-option>
                <el-option label="否" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="品名" size="mini">
              <el-input v-model="form.plu_name" clearable placeholder="请输入品名"/>
            </el-form-item>
            <el-form-item label="统计品类" size="mini">
              <el-input v-model="form.stats_category_name" clearable placeholder="请输入统计品类"/>
            </el-form-item>

            <el-form-item label="模具号" size="mini">
              <el-input v-model="form.mould_code" clearable placeholder="请输入模具号"/>
            </el-form-item>

            <el-form-item label="预淘汰品" size="mini">
              <el-select clearable v-model="form.dead_sku_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="是" :key="1" :value="1"></el-option>
                <el-option label="否" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="统计工艺" size="mini">
              <el-select clearable v-model="form.craft_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="手工" :key="1" :value="1"></el-option>
                <el-option label="机装" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="预排产量是否整托" size="mini">
              <el-select clearable v-model="form.arrange_tray_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="是整托" :key="1" :value="1"></el-option>
                <el-option label="非整托" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="料号取整类型" size="mini">
              <el-select clearable v-model="form.round_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="按箱取整" :key="1" :value="1"></el-option>
                <el-option label="按托取整" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-button
              class="form-btn-item"
              size="mini"
              type="primary"
              :loading="btnStatus.isSub"
              @click="handleSubmit"
            >查询
            </el-button>
            <el-button
              class="form-btn-item"
              size="mini"
              :loading="btnStatus.isReset"
              @click="handleReset"
            >重置
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isSave"
              @click="handleTableHeadAction('save')">保存
            </el-button>

            <el-button
              size="mini"
              type="primary"
              :loading="btnStatus.isExportLoading"
              @click="handleTableHeadAction('export')"
            >导出
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleTableHeadAction('update_box_round_type_1')">按箱取整
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleTableHeadAction('update_box_round_type_2')">按托取整
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isUpdateToTheory"
              @click="handleTableHeadAction('update_to_theory')">调整到理论请购量
            </el-button>
            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader') "
              size="mini"
              type="warning"
              @click="handleTableHeadAction('adjust')">确认调整
            </el-button>
          </el-form>
        </div>
        <div class="schedulingPlan-content">
          <el-row>
            <el-col class="list-head">

            </el-col>
          </el-row>
          <el-table
            class="table"
            stripe
            border
            size="mini"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="40" fixed="left"/>
            <template v-for="(item, index) in tableObj.customAdjustHead">
              <!-- 预排产量 -- 可编辑 -->
              <el-table-column v-if="['confirm_arrange_box_count'].includes(item.prop)" v-bind="item"
                               show-overflow-tooltip :key="index" align="center">
                <template slot-scope="scope">
                  <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'confirm_arrange_box_count'"
                            v-model="scope.row.confirm_arrange_box_count" size="mini" clearable/>
                </template>
              </el-table-column>
              <!-- 是否使用系统默认值 -- 1 使用 0 不使用 -->
              <el-table-column v-else-if="item.prop == 'use_default'" v-bind="item" show-overflow-tooltip :key="index"
                               align="center">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.use_default_status"
                             @change="handleSwitchChange(scope.$index, scope.row)"/>
                </template>
              </el-table-column>
              <el-table-column v-else-if="['cargono'].includes(item.prop)" fixed="left" v-bind="item"
                               show-overflow-tooltip :key="index" align="center"></el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="大类排产执行情况" class="salesCenter-tabs-item" name="mainClassifyArrangeReport">
        <mainClassifyArrangeReport/>
      </el-tab-pane>
      <el-tab-pane label="中类排产执行情况" class="salesCenter-tabs-item" name="midClassifyArrangeReport">
        <midClassifyArrangeReport/>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams, optionsObj} from '@/mixins';
import {thousandsSeparator, keepTwoDecimals, excelExport} from "@/utils";
import {customAdjustHead} from '../config';
import {
  handleschedulingPlanCustomAdjLists,
  handleschedulingPlanCustomAdjSave,
  handleschedulingPlanCustomAdjConfirm
} from '@/api/schedulingPlan';
import {handleSchedulingUpdateToTheory, handleUpdateBoxRoundType} from "../../../api/schedulingPlan";
import {getPlanYM} from "../../../utils";
import midClassifyArrangeReport from "./midClassifyArrangeReport";
import mainClassifyArrangeReport from "./mainClassifyArrangeReport";

export default {
  // 排单计划 -- 自定义调整
  name: 'customAdjustment',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    midClassifyArrangeReport,
    mainClassifyArrangeReport,
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 是否保存
        isUpdateToTheory: true,                   // 是否调整到理论出货量
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        customAdjustHead
      },
      uploadFileObj: {
        tipsText: '必需字段: 生产类型、sku_id、库存系数上限、库存系数下限、库存系数目标',
        tableData: [],
        preTableData: [],
        tableHeader: []
      },
      selectRows: [],
      urlMonth: '',
      strategy_type: '',
      activeName: 'skuDetail'
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isUpdateToTheory = false;
        }
        ;
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isUpdateToTheory = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: '',
      grade: [],

    };
    this.form = formPrams;
    this.strategy_type = this.$route.query?.strategy_type;
    this.handleUrlParams().then(res => {
      this.getLists();
    })
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 返回
    handleBack() {
      this.$router.go(-1);
    },
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          plan_stock_ratio: form.plan_stock_ratio,
          order_count_type: form.order_count_type,
          arrange_count_type: form.arrange_count_type,
          stock_ratio_type: form.stock_ratio_type,
          algorithm_adjust: form.algorithm_adjust,
          lock_state: form.lock_state,
          grade: form.productGrade || '',
          bo_kd_fill_rate_type: form.bo_kd_fill_rate_type || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          prod_type: '',
          strategy_type: this.strategy_type || 3,
          month: this.urlMonth || '',
          race_name: form.race_name || '',
          operator: this.urlParams.operator,
          material_code: form.material_code,
          plu_name: form.plu_name,
          stats_category_name: form.stats_category_name,
          dead_sku_type : form.dead_sku_type,
          mould_code: form.mould_code,
          craft_type : form.craft_type,
          arrange_tray_type: form.arrange_tray_type,
          round_type : form.round_type,
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        this.tableStatus.isLoading = true;
        const {code, data, msg} = await handleschedulingPlanCustomAdjLists(params);
        if (data) {
          const {arrange_plu_list, total} = data;
          this.modifyData(arrange_plu_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取分公司客订检核列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            plan_month: item.plan_month || '--',
            plu_code: item.plu_code || '--',
            plu_id: item.plu_id || '--',
            plu_name: item.plu_name || '--',
            material_code: item.material_code || '--',
            plu_mnemonic_code: item.plu_mnemonic_code || '--',

            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',
            adjust_info: item.adjust_info || '--',
            cargono: item.cargono || '--',

            grade: item.grade || '--',
            pen_craft_total: item.pen_craft_total || '--',
            mould_code: item.mould_code || '--',
            dead_sku_type: item.dead_sku_type || '--',
            prod_type: item.prod_type || '--',
            min_order_count_box: thousandsSeparator(item.min_order_count_box, 0) || 0,
            confirm_arrange_box_count: item.confirm_arrange_box_count || 0,
            hq_this_month_ql_box: item.hq_this_month_ql_box || 0,
            confirm_arrange_count: item.confirm_arrange_count || 0,
            box_count_per_tray: item.box_count_per_tray || 0,
            hq_last_month_store_count_box: thousandsSeparator(item.hq_last_month_store_count_box, 0) || 0,
            bo_kd_plu_count_box: thousandsSeparator(item.bo_kd_plu_count_box, 0) || 0,
            stock_up_count_box: thousandsSeparator(item.stock_up_count_box, 0) || 0,
            hq_previous_put_count_box: thousandsSeparator(item.hq_previous_put_count_box, 0) || 0,
            theory_shipment_count_box: thousandsSeparator(item.theory_shipment_count_box, 0) || 0,
            algorithm_adjust_count_box: thousandsSeparator(item.algorithm_adjust_count_box, 0) || 0,
            hq_expect_store_count_box: thousandsSeparator(item.hq_expect_store_count_box, 0) || 0,
            hq_this_month_total_count_box: thousandsSeparator(item.hq_this_month_total_count_box, 0) || 0,
            hq_month_avg_sale_count_box: thousandsSeparator(item.hq_month_avg_sale_count_box, 0) || 0,
            bo_last_month_stock_count_box: thousandsSeparator(item.bo_last_month_stock_count_box, 0) || 0,
            hq_expect_store_ratio: keepTwoDecimals(item.hq_expect_store_ratio) || 0,
            bo_last_month_stock_ratio: keepTwoDecimals(item.bo_last_month_stock_ratio) || 0,
            hq_last_month_store_ratio: keepTwoDecimals(item.hq_last_month_store_ratio) || 0,
            bo_kd_fill_rate: item.bo_kd_fill_rate || 0,
            bo_kd_fill_rate_text: keepTwoDecimals((item.bo_kd_fill_rate || 0) * 100) + '%',
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        bo_kd_fill_rate_type: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        order_count_type: 0,
        arrange_count_type: 0,
        stock_ratio_type: 0,
        algorithm_adjust: 0,
        lock_state: 0,
        plu_name: '',
        stats_category_name: '',
        dead_sku_type: '',
        mould_code:'',
        craft_type: ''
      };
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              confirm_arrange_box_count: item.confirm_arrange_box_count || '',
              plan_month: item.plan_month || '',
              strategy_type: this.strategy_type || 3,
              plu_id: item.plu_id || ''
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            arrange_plu_list: newItems
          };
          const {code, msg} = await handleschedulingPlanCustomAdjSave(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'update_to_theory': async () => {
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item.plu_id || '');
          });
          const params = {
            operator: this.urlParams.operator,
            plan_month: this.urlMonth || getPlanYM(),
            plu_id_list: newItems
          };
          const {code, msg} = await handleSchedulingUpdateToTheory(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('调整至理论出货量成功');
          }
          if (code != '0000') this.getLists();
        },
        'update_box_round_type_1': async () => {
          await this.updateBoxRoundType(1);
        },
        'update_box_round_type_2': async () => {
          await this.updateBoxRoundType(2);
        },
        'adjust': async () => {
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator,
            strategy_type: this.strategy_type || 3
          };
          const {success} = await handleschedulingPlanCustomAdjConfirm(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('自定义排单计划确认调整已成功');
          }
          if (!success) this.getLists();
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    async updateBoxRoundType(boxRoundType) {
      let materialCodeList = [];
      this.selectRows.map(item => {
        materialCodeList.push(item.material_code || '');
      });

      const params = {
        box_round_type: boxRoundType,
        plan_month: this.urlMonth,
        operator: this.urlParams.operator,
        material_code_list : materialCodeList
      };
      const {success} = await handleUpdateBoxRoundType(params);
      this.$refs['multipleTable'].clearSelection();
      if (success) {
        this.tableStatus.isLoading = true;
        this.getLists();
        return this.$message.success('取整方式调整已成功');
      }
      if (!success) this.getLists();
    },
    // 导出数据
    async exportData() {
      let topHeaders = {}
      for (let i = 0; i < customAdjustHead.length; i++) {
        let row = customAdjustHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          prod_type: form.prod_type || '',
          is_lower_than_min_order_plu: false,
          bo_kd_plu_count_type: form.bo_kd_plu_count_type,
          material_code: form.material_code,
          prod_confirm_type: form.prod_confirm_type,
          plu_name: form.plu_name,
          stats_category_name: form.stats_category_name,
          dead_sku_type : form.dead_sku_type,
          mould_code: form.mould_code,
          craft_type : form.craft_type,
          arrange_tray_type: form.arrange_tray_type,
          round_type : form.round_type,
        };

        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        this.btnStatus.isExportLoading = false;

        const {code, data} = await handleschedulingPlanCustomAdjLists(params);

        if (code === '0000') {
          const {arrange_plu_list} = data;
          if (arrange_plu_list.length > 0) {
            excelExport({
              topHeaders,
              data: arrange_plu_list,
              title: '自定义调整列表'
            })
          }
          if (arrange_plu_list.length === 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------自定义调整列表导出接口有误');
      }
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      console.log(results, 'results--------11111')
      console.log(header, 'header--------22222')
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header
    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;

    }
  }
}
</script>

<style lang="scss" scoped src='@/style/schedulingPlan/layoutMain.scss'></style>
