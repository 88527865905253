
<template>
  <el-table-column
    :label="tableColumns.label"
    :prop="tableColumns.prop"
    :align="tableColumns.align || 'center'"
    :class-name="tableColumns.headerCellClassName"
  >
    <template v-for="(item, index) in tableColumns.children">
      <mg-table-column v-if="item.children" :key="index" :tableColumns="item" :minWidth="item.minWidth" />
      <el-table-column v-else :key="index" v-bind="item" :class-name="item.headerCellClassName" :align="item.align|| 'center'">
        <template #default="scoped" v-if="item.slot">
          <slot :name="item.slot" v-bind="scoped" />
        </template>
      </el-table-column>
    </template>
  </el-table-column>
</template>

<script>
  export default {
    name: 'mgTableColumn',
    props: {
      tableColumns: {
        type: Object,
      },
      minWidth: {
        type: String,
      },
    },
  }
</script>
