<template>
  <div class="new-box">
    <div class="mg-search">
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter('hand')"   @reset="resetQuery"  @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item v-if="authOrgOptions.length > 1" label="范围">
          <el-select v-model="queryForm.orgRange" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="queryForm.title" clearable placeholder="请输入标题关键字" />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="queryForm.type" @change="() => queryForm.position = 'HomeNotice'">
            <el-option v-for="item in typeOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type === 'notice'" label="位置">
          <el-select v-if="queryForm.type === 'notice'" v-model="queryForm.position" clearable filterable allow-create>
            <el-option v-for="item in noticeOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="queryForm.queryDate"
            type="datetime"
            placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit('add', undefined)">新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-remove" type="primary" @click="handleRemove">删除</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit('add', undefined)">新增</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-remove" type="primary" @click="handleRemove">删除</el-button>
    <el-table
     style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
      size="small"
      class="customer-table"
      border
      v-loading="loading"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90" fixed="left" />
      <el-table-column label="标题" prop="title"  />
      <el-table-column label="类型" prop="type"  >
        <template #default="{ row }">
          <span>{{ typeOptions.find(e => e.value===row.type).label }}</span>
        </template>
      </el-table-column>
      <el-table-column label="范围" prop="orgName"  />
      <el-table-column label="位置" prop="position" >
        <template #default="{ row }">
          <span>{{ noticeOptions.find(e => e.value===row.position)? noticeOptions.find(e => e.value===row.position).label: row.position }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开始时间" prop="startDate" width="200"  />
      <el-table-column label="结束时间" prop="endDate" width="200"  />
      <el-table-column label="创建者" prop="createUser" />
      <el-table-column label="创建时间" prop="createTime" width="200" />
      <el-table-column label="操作" width="250" align="center">
        <template #default="{ row }">
          <el-button size="mini" v-if="row.orgRange==queryForm.orgRange || authOrgAll" type="text" style="border: 0px;" @click="handleEdit('view', deepClone(row))">编辑</el-button>
          <el-button size="mini"  v-if="row.orgRange==queryForm.orgRange || authOrgAll" type="text" style="border: 0px;" @click="handleDel(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="saveDialog.titleMap[saveDialog.type]" :center="true" :visible.sync="saveDialog.visible" append-to-body width="750px" :close-on-click-modal="false" :before-close="handleBeforeClose">
      <el-form ref="saveFormRef" size="mini" label-width="auto" :inline="true" :rules="saveDialog.rules" :model="saveDialog.form">
        <div>
          <el-row>
            <el-col>
              <el-form-item label="标题" prop="title">
                <el-input v-model="saveDialog.form.title" style="width: 350px" clearable placeholder="请输入标题" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="类型" prop="type">
                <el-select v-model="saveDialog.form.type" @change="() => saveDialog.form.position = 'HomeNotice'">
                  <el-option v-for="item in typeOptions" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="位置" :prop="saveDialog.form.id? 'position':'positions'">
                <div v-if="saveDialog.form.id">
                  <el-select v-if="saveDialog.form.type === 'banner'" v-model="saveDialog.form.position">
                    <el-option v-for="item in positionOptions" :label="item.label" :value="item.value" :key="item.value" />
                  </el-select>
                  <el-select v-else v-model="saveDialog.form.position" clearable filterable allow-create>
                    <el-option v-for="item in noticeOptions" :label="item.label" :value="item.value" :key="item.value" />
                  </el-select>
                </div>
                <div v-else>
                  <el-select v-if="saveDialog.form.type === 'banner'" v-model="saveDialog.form.positions" multiple>
                    <el-option v-for="item in positionOptions" :label="item.label" :value="item.value" :key="item.value" />
                  </el-select>
                  <el-select v-else v-model="saveDialog.form.positions" clearable multiple filterable reserve-keyword allow-create>
                    <el-option v-for="item in noticeOptions" :label="item.label" :value="item.value" :key="item.value" />
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="时间范围" prop="rangeTime">
                <el-date-picker v-model="saveDialog.form.rangeTime" :picker-options="pickerOptions" style="width: 350px" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="范围" prop="orgRanges">
                <el-select v-model="saveDialog.form.orgRanges" multiple collapse-tags>
                  <el-option v-for="item in authOrg" :label="item.label" :value="item.value" :key="item.key" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="saveDialog.form.type==='banner'">
              <el-form-item label="图片" prop="img">
                <OssUpload ref="ossUpload" :value-to.sync="saveDialog.form.img" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="链接" prop="href">
                <el-input v-model="saveDialog.form.href" style="width:400px" clearable placeholder="请输入链接地址。通知类型仅首页支持跳转" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="position: absolute; bottom: 5px;width: 90%;background-color: white;height: 30px">
        <el-button type="primary" size="mini" @click="handleSave">提交</el-button>
        <el-button type="primary" size="mini" @click="saveDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  pageBanners,
  saveBanner,
  delBanner,batchDelBanner
} from "@/api/appTask";
import { Notification } from "element-ui";
import OssUpload from "@/components/OssUpload";
import SearchFilter from "@/components/SearchFilter/index";
import {deepClone} from "../../../utils";
export default {
  name: "BoostPacket",
  components: { Pagination, OssUpload, SearchFilter },
  data() {
    return {
      deepClone,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgRange: undefined,
        title: undefined,
        position: 'HomeNotice',
        type: 'notice'
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      pickerOptions: {
        shortcuts: [{
          text: '近1小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '近3小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000 * 3);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '近24小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      saveDialog: {
        visible: false,
        type: undefined,
        titleMap: {
          add: '新增',
          edit: '修改',
        },
        form: {
          id: undefined,
          title: undefined,
          position: 'HomeNotice',
          startDate: undefined,
          endDate:undefined,
          orgRange: undefined,
          orgRanges: [],
          orgName: undefined,
          deleted: 0,
          img:'',
          href:undefined,
          rangeTime: [],
          type: 'notice'
        },
        rules: {
          title: [{ required: true, message: " ", trigger: "change" }],
          position: [{ required: true, message: " ", trigger: "change" }],
          positions: [{ type: 'array', required: true, message: " ", trigger: "change" }],
          orgRanges: [{ type: 'array',required: true, message: " ", trigger: "change" }],
          rangeTime: [{ type: 'array',required: true, message: " ", trigger: "change" }],
          img: [{ required: true, message: " ", trigger: "change" }],
        },
      },
      authOrgOptions: [],
      authOrg: [],
      authOrgCodes: [],
      positionOptions: [{ value: 'HomeNotice', label: '首页通知'}],
      typeOptions: [ { value: 'notice', label: '通知'}, { value: 'banner', label: '横幅'}],
      noticeOptions: [
        {
          "label": "全部页面",
          "value": "ALL"
        },
        {
          "label": "首页通知",
          "value": "HomeNotice"
        },
        {
          "label": "首页-城市信息",
          "value": "/cityinfo"
        },
        {
          "label": "个人日报页",
          "value": "/dailyreport"
        },
        {
          "label": "报表-老板看板",
          "value": "/report/boss"
        },
        {
          "label": "报表-老板看板-分公司明细",
          "value": "/report/boss/branch"
        },
        {
          "label": "报表-分公司库存",
          "value": "/report/branch/instock"
        },
        {
          "label": "报表-分公司总览",
          "value": "/report/branch/overview"
        },
        {
          "label": "报表-分公司进货",
          "value": "/report/branch/restock"
        },
        {
          "label": "报表-分公司进货-本月实时",
          "value": "/report/branch/restock/live"
        },
        {
          "label": "报表-分公司销售",
          "value": "/report/branch/sales"
        },
        {
          "label": "报表-伙伴库存",
          "value": "/report/partner/instock"
        },
        {
          "label": "报表-伙伴总览",
          "value": "/report/partner/overview"
        },
        {
          "label": "报表-伙伴进货",
          "value": "/report/partner/restock"
        },
        {
          "label": "报表-伙伴销售",
          "value": "/report/partner/sales"
        },
        {
          "label": "报表-分公司任务看板",
          "value": "/report/task/branch"
        },
        {
          "label": "报表-合作伙伴任务看板",
          "value": "/report/task/partner"
        },
        {
          "label": "重点款式-款式列表",
          "value": "/important/sku"
        },
        {
          "label": "购物车",
          "value": "/shop/cart"
        },
        {
          "label": "订单页",
          "value": "/shop/order/history"
        },
        {
          "label": "店铺详情页",
          "value": "/short/shop/detail"
        },
        {
          "label": "任务补货",
          "value": "/short/shop/replenishment"
        },
        {
          "label": "任务数据页",
          "value": "/task/data"
        },
        {
          "label": "首页-终端店铺",
          "value": "/terminal"
        },
        {
          "label": "店铺列表页",
          "value": "/terminal/list"
        },
        {
          "label": "日报汇总页",
          "value": "/workreport"
        },
      ]
    };
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  watch: {
    "saveDialog.form.orgRanges": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
      },
    }
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrg = res.data;
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = this.authOrg.filter(e => e.value!=='ALL');
      if (res.data.length !== this.authOrgOptions.length) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.orgRange = this.authOrgOptions[0].value;
      }
      this.handleFilter();
    });
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);

      pageBanners(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        orgRange: undefined,
        title: undefined,
        position: 'HomeNotice',
        type: 'notice'
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleEdit(type, row) {
      this.saveDialog.type = type;
      this.saveDialog.form = {
        id: undefined,
        title: undefined,
        position: undefined,
        startDate: undefined,
        endDate:undefined,
        orgRange: undefined,
        orgRanges: [],
        orgName: undefined,
        deleted: 0,
        img:'',
        href:undefined,
        type: 'notice'
      };
      if (this.authOrgOptions.length === 1 && !row) {
        this.saveDialog.form.orgRanges = [this.authOrgOptions[0].value];
      }
      if (row) {
        row.rangeTime = [row.startDate, row.endDate];
        row.orgRanges = row.orgRange.split(',').filter(e => e);
        this.saveDialog.form = deepClone(row);
      }
      this.saveDialog.visible = true;
    },
    handleSave() {
      this.$refs.saveFormRef.validate(valid => {
        if (valid) {
          let form = deepClone(this.saveDialog.form);
          if (form.orgRanges.length === 0) {
            Notification.warning("请至少选择一个分公司！");
            return;
          }
          if (form.orgRanges.length > 1) {
            form.orgName = '全国'
          } else {
            form.orgName = this.authOrgOptions.find(e => form.orgRanges.indexOf(e.value) > -1).label;
          }

          form.orgRange = form.orgRanges.join(',')
          form.startDate = form.rangeTime[0];
          form.endDate = form.rangeTime[1];
          saveBanner(form).then(res => {
            if (res.code === 200) {
              Notification.success("操作成功!");
              this.saveDialog.visible = false;
              this.handleFilter();
            }
          })
        }
      })
    },
    handleDel(row) {
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        delBanner(row.id).then(res => {
          if (res.code===200) {
            Notification.success("删除成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    handleBeforeClose(done) {
      this.$confirm('关闭后不会保存当前修改，请确认是否关闭？')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleDictChange(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          if (this.authOrgOptions.length === 0) {
            return;
          }
          if (this.authOrgOptions.length > 2) {
            if (oldVal && oldVal.length > 1) {
              if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRanges = this.saveDialog.form.orgRanges.filter(e => e !== 'ALL');
              } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRanges = this.authOrg.map(e => e.value);
              } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRanges = [];
              } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRanges = this.authOrg.map(e => e.value);
              }
            } else {
              if (val.indexOf('ALL') > -1 && val.length < this.authOrg.length) {
                this.saveDialog.form.orgRanges = this.authOrg.map(e => e.value);
              } else if (val.indexOf('ALL') < -1 && val.length === this.authOrg.length -1) {
                this.saveDialog.form.orgRanges = this.authOrg.map(e => e.value);
              }
            }
          }
        })
      }
    },
    handleRemove(row) {
      let delParam = [];
      if (row && row.id) {
        delParam.push(row.id);
      } else if (this.pageData.selections.length > 0) {
        delParam = this.pageData.selections.map(e => e.id);
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      this.$confirm('请确认是否删除所选的数据', '提示', { type: 'warning' }).then(() => {
        batchDelBanner("?idList=" +delParam).then((res) => {
          if (res.code == 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
