var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "configCenter-page" }, [
    _c(
      "div",
      { staticClass: "configCenter-search" },
      [
        _c("v-signboard", { attrs: { title: "物料维表" } }),
        _c(
          "el-form",
          {
            ref: "filialeCustome-form",
            staticClass: "main-search",
            attrs: {
              size: "mini",
              "label-width": "auto",
              inline: true,
              model: _vm.form,
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleHeadAction("search")
              },
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "原始料号", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入原始料号" },
                  model: {
                    value: _vm.form.material_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "material_code", $$v)
                    },
                    expression: "form.material_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "换算料号", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入换算料号" },
                  model: {
                    value: _vm.form.trans_material_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "trans_material_code", $$v)
                    },
                    expression: "form.trans_material_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "数据类型", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.data_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "data_type", $$v)
                      },
                      expression: "form.data_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "全部", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "正常", value: 2 },
                    }),
                    _c("el-option", {
                      key: 3,
                      attrs: { label: "CNT汇总!=1", value: 3 },
                    }),
                    _c("el-option", {
                      key: 4,
                      attrs: { label: "重复Key", value: 4 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "form-btn-item",
                attrs: {
                  size: "mini",
                  type: "primary",
                  loading: _vm.btnStatus.isSearch,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleHeadAction("search")
                  },
                },
              },
              [_vm._v("查 询 ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "form-btn-item",
                attrs: { size: "mini", loading: _vm.btnStatus.isReset },
                on: {
                  click: function ($event) {
                    return _vm.handleHeadAction("reset")
                  },
                },
              },
              [_vm._v("重 置 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  type: "primary",
                  disabled: _vm.btnStatus.isSave,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTableHeadAction("save")
                  },
                },
              },
              [_vm._v("保 存 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  type: "primary",
                  loading: _vm.btnStatus.isExportLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTableHeadAction("export")
                  },
                },
              },
              [_vm._v("导 出 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "configCenter-content" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableStatus.isLoading,
                expression: "tableStatus.isLoading",
              },
            ],
            ref: "multipleTable",
            staticClass: "table",
            attrs: {
              stripe: "",
              border: "",
              height: "500",
              size: "mini",
              data: _vm.lists,
            },
            on: { "selection-change": _vm.handleselection },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "40", fixed: "left" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "序号",
                align: "center",
                width: "55",
                fixed: "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            scope.$index +
                              (_vm.currentPage - 1) * _vm.pageSize +
                              1
                          ) + " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._l(_vm.tableObj.materialDimReportHead, function (item, index) {
              return [
                ["cnt"].includes(item.prop)
                  ? _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    item.prop == "cnt"
                                      ? _c("el-input", {
                                          staticClass: "tgqrcount-cell-ipt",
                                          attrs: {
                                            type: "number",
                                            size: "mini",
                                            clearable: "",
                                          },
                                          model: {
                                            value: scope.row.cnt,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "cnt", $$v)
                                            },
                                            expression: "scope.row.cnt",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    )
                  : _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    ),
              ]
            }),
          ],
          2
        ),
        _c("v-pagination", {
          attrs: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            currentPage: _vm.currentPage,
          },
          on: { pageChange: _vm.pagingChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }