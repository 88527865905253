<template>

  <div>
    <el-button class="form-btn-item" size="mini" type="primary" icon="el-icon-plus" @click="handleAdd">新建任务
    </el-button>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="formData" :model="formData" label-width="80px">
        <el-form-item label="平台名">
          <el-input v-model="formData.platform_name"></el-input>
        </el-form-item>
        <el-form-item label="配送中心编码">
          <el-input v-model="formData.dc_code"></el-input>
        </el-form-item>
        <el-form-item label="配送中心名称">
          <el-input v-model="formData.dc_name"></el-input>
        </el-form-item>
        <el-form-item label="下载文件通用特征">
          <el-input v-model="formData.file_features"></el-input>
        </el-form-item>
        <el-form-item label="下载文件路径">
          <el-input v-model="formData.file_path"></el-input>
        </el-form-item>
        <div v-for="(item, index) in formData.items" :key="item.id">
          <el-form-item :label="`步骤编码 ${index + 1}`" :prop="'items.' + index + '.step_coding'">
            <el-select v-model="item.step_coding" placeholder="请选择">
              <el-option
                v-for="item in setpDataList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="`图片信息 ${index + 1}`" :prop="'items.' + index + '.photo_url'">
            <el-upload
              class="upload-demo"
              action="#"
              :on-preview="handlePreview"
              :on-remove="(file, fileList) => handleRemove(file, fileList, index)"
              :before-remove="beforeRemove"
              :on-change="(file, fileList) => handleFileChange(file,index)"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="item.fileList"
              :auto-upload="false"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
        </div>
        <el-button type="primary" @click="addFormItem">增加一行</el-button>

        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </el-form>
    </el-dialog>
    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%">
        <el-table-column
          prop="platform_name"
          label="平台名"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="dc_name"
          label="配送中心"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template #default="{ row }">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteRowData(row)"
            >
              删除任务
            </el-button>
            <el-button size="mini" type="warning" @click="handleEditAccount(row)">任务编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>


</template>

<script>

import {
  queryItemOtherPlatformsListExport,
  rpaPushUrl,
  rpaTbData,
  getStepData,
  delRpaData,
  getEditRpaData,
} from "@/api/rpaSpider";


export default {
  data() {
    return {
      tableData: [],
      formInline: {}, // 用于单个表单项的模型
      formData: {
        items: [], // 用于存储多行表单数据的数组
        platform_name: '',
        file_path: '',
        file_features: '',
        dc_code: '',
        dc_name: '',
        order_num: '',
      },
      nextFormItemId: 1, // 用于生成唯一的表单项ID
      dialogVisible: false,
      // fileList: [],
      setpDataList: [],

    };
  },
  created() {
    console.log("已经创建了这个组件");
    // this.getFirstLevel()
    // this.fetchData();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleEditAccount(row) {
      getEditRpaData(row).then(resJson => {
        this.formData = resJson.data
        this.fileList = resJson.data.items
      })
      this.dialogVisible = true;
    },
    deleteRowData(row) {
      this.$confirm('是否删除此条信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRpaData(row).then(resJson => {
          const code = resJson.code
          if (code == '200') {
            this.fetchData();
            this.$message({
              message: '删除成功',
              type: 'success'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    handleFileChange(file, index) {
      // 创建 FormData 来存储文件
      console.log(file)
      const fd = new FormData();

      fd.append('file', file.raw);
      // 调用 rpaPushUrl 函数来上传文件
      rpaPushUrl(fd).then(resJson => {
        // 处理上传成功的逻辑
        if (resJson.data.success) {
          this.$message.success('文件上传成功');
          // 更新 fileList 或执行其他操作
          this.formData.items[index].fileList.push({
            name: file.name,
            url: resJson.data.url // 假设后端返回了文件的访问URL
          });
          this.formData.items[index].photo_url = resJson.data.url
          console.log(this.fileList)
        } else {
          this.$message.error('文件上传失败');
        }
      }).catch(error => {
        // 处理上传失败的逻辑
        this.$message.error('文件上传发生错误');
        console.error(error);
      });
    },
    handleRemove(file, fileList, index) {
      // 从fileList中移除对应的文件
      const removeIndex = fileList.indexOf(file);
      if (removeIndex !== -1) {
        fileList.splice(removeIndex, 1);
      }
      // 更新formData中对应项的fileList
      this.formData.items[index].fileList = fileList;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => {
      //   });
    },
    fetchData() {
      queryItemOtherPlatformsListExport().then(resJson => {
        this.tableData = resJson;
        console.log(this.tableData)
      })
      getStepData().then(resJson => {
          this.setpDataList = resJson.data;
        }
      )
    },
    handleAdd() {
      this.currentTitle = "add";
      this.$nextTick(() => {
        this.resetForm();
      });
      this.dialogVisible = true;
      this.nextFormItemId = 1
    },
    addFormItem() {
      this.formData.items.push({
        id: this.nextFormItemId++, // 生成唯一的ID
        // step_coding: '',
        photo_url: '',
        step_coding: null,
        fileList: []
      });

    },
    submitForm() {
      this.dialogVisible = false
      this.$refs.formData.validate((valid) => {
        if (valid) {
          // alert('表单提交成功！');
          rpaTbData(this.formData).then(resJson => {
              const res = resJson.data
              console.log(res)
              this.fetchData();
              this.$message({
                message: '提交成功',
                type: 'success'
              });
            }
          )
          // 在这里处理表单提交逻辑，比如发送请求到服务器
        } else {
          console.log('表单验证失败！');
          return false;
        }

      });

    },
    resetForm() {
      this.formData = {
        items: [], // 用于存储多行表单数据的数组
        platform_name: '',
        file_features: '',
        file_path: '',
        dc_code: '',
        dc_name: '',
        order_num: ''
      }
      this.fileList = []
    },
  }
};
</script>
