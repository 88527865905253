<template>
  <div :class="['mg-virtual-table', isFullScreen ? 'mg-table--full-screen' : '']">
    <div class="mg-virtual-table-topbar" v-if="isShowTopBar">
      <div class="topbar-left">
        <slot name="topleft" />
      </div>
      <div class="topbar-right">
        <slot name="topright" />
        <template v-if="multipleSort">
          <el-tooltip effect="dark" content="多级排序" placement="top">
            <el-button
              :style="[multipleSortBtnStyle]"
              style="padding: 7px 8px; margin-left: 10px;"
              type="icon"
              @click="multiple = !multiple"
            >
              多
            </el-button>
          </el-tooltip>
        </template>
        <!-- 搜索按钮 -->
        <template v-if="searchable">
          <el-button
            v-if="!showSearchBar"
            icon="iconfont mg-icon_sousuo_wubiankuang"
            size="mini"
            @click="handleShowSearch"
            type="icon"
          />
          <el-button
            v-else
            plain
            icon="iconfont mg-icon_sousuo_wubiankuang"
            size="mini"
            @click="handleShowSearch"
            type="icon"
          />
        </template>
        <!-- 列宽配置按钮 -->
        <el-button
          icon="iconfont mg-icon_shezhi_wubiankuang"
          type="icon"
          @click="visible = true"
          v-if="configurable"
        />
        <!-- 是否全屏按钮 -->
        <el-button
          class="mg-icon-full-screen"
          icon="el-icon-full-screen"
          type="icon"
          @click="handleFullScreen"
          v-if="isConfigFullScreen"
        />
        <!-- 小分页器 -->
        <mg-mini-pagination
          v-if="showMiniPagination"
          :page="page"
          :total="total"
          :total-page="totalPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>
    <!-- chronological 只对 multiple 有效，是否按照先后触发顺序进行排序 -->
    <div
      style="width: 100%; overflow: hidden;"
      :style="{ height: isFullScreen ? 'calc(100vh - 90px)' : (isNaN(tableHeight) ? tableHeight : `${tableHeight}px`) }"
    >
      <vxe-table
        class="vxe-table-box"
        v-loading="loading"
        border
        show-overflow
        highlight-hover-row
        ref="mgTable"
        auto-resize
        height="auto"
        size="mini"
        show-header-overflow="title"
        :data="initialData || list"
        :scroll-y="{ mode: 'wheel', gt: 25 }"
        :filter-config="{ showIcon: false }"
        :sort-config="{
          iconAsc: 'el-icon-caret-top',
          iconDesc: 'el-icon-caret-bottom',
          remote: sortRemote,
          multiple: multiple,
          chronological: true,
        }"
        :seq-config="{ seqMethod: seqMethod }"
        :resizable-config="{ minWidth: 50 }"
        :footer-method="footerMethod"
        :row-class-name="handleRowClassName"
        @cell-click="handleCell"
        @checkbox-change="selectChangeEvent"
        @checkbox-all="selectAllEvent"
        @resizable-change="resizableChange"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot name="checkbox"></slot>
        <!-- 自定义表格内容 -->
        <!--
          外面再包一层template是因为：
          columns添加可配置后，其依赖接口取值，
          而本地又有一份静态columns，导致缓存列的存在，
          对于下面默认插槽的渲染会走进该模板的渲染，导致dom渲染异常
        -->
        <template v-if="columns.length > 0">
          <template v-for="column in columns">
            <vxe-column
              v-if="column.slot"
              :key="column.prop"
              :title="column.label"
              :min-width="column.minWidth"
              :width="column.width"
              :align="column.align"
              :field="column.prop"
              :sortable="column.sortable"
              :sort-by="column.sortBy"
              :fixed="column.fixed"
              :params="column.params"
              :show-overflow-tooltip="column.showOverflowTooltip"
              resizable
              :filters="[{ data: '' }]"
              :filter-method="(config) => filterMethod(config, column.prop)"
            >
              <template #default="scoped">
                <slot :name="column.slot" v-bind="{ ...scoped, $index: scoped.$rowIndex }" />
              </template>
              <!-- 自定义表头 -->
              <template v-if="column.headerSlot" #header="scoped">
                <div
                  :class="[
                    'mg-customer-table-header-cell-label',
                    column.showHeaderAsterisk ? 'mg-header-asterisk' : '',
                  ]"
                >
                  <span style="color: #e60012; margin-top: 4px" v-if="column.showHeaderAsterisk">*</span>
                  <span>{{ column.label }}</span>
                </div>
                <div
                  v-if="showSearchBar"
                  :class="[
                    'mg-customer-table-header-cell-body',
                    showSearchBar ? 'mg-customer-table-header-cell-noBorderTop' : '',
                  ]"
                >
                  <slot :name="column.headerSlot" v-bind="scoped" />
                </div>
              </template>
            </vxe-column>
            <!-- 使用默认表格列渲染 -->
            <template v-else-if="column.default">
              <slot :name="column.prop" v-bind="column" />
            </template>
            <!-- 常规数值显示的表格行 -->
            <vxe-column
              v-else
              show-overflow-tooltip
              :key="column.prop"
              :title="column.label"
              :min-width="column.minWidth"
              :width="column.width"
              :align="column.align"
              :field="column.prop"
              :sortable="column.sortable"
              :sort-by="column.sortBy"
              :fixed="column.fixed"
              :type="column.type"
              :params="column.params"
              resizable
              :filters="[{ data: '' }]"
              :filter-method="(config) => filterMethod(config, column.prop)"
            >
              <template v-if="column.headerSlot" #header="scoped">
                <div
                  :class="[
                    'mg-customer-table-header-cell-label',
                    column.showHeaderAsterisk ? 'mg-header-asterisk' : '',
                  ]"
                >
                  <span style="color: #e60012; margin-top: 4px" v-if="column.showHeaderAsterisk">*</span>
                  <span>{{ column.label }}</span>
                </div>
                <div
                  v-if="showSearchBar"
                  :class="[
                    'mg-customer-table-header-cell-body',
                    showSearchBar ? 'mg-customer-table-header-cell-noBorderTop' : '',
                  ]"
                >
                  <slot :name="column.headerSlot" v-bind="scoped" />
                </div>
              </template>
            </vxe-column>
          </template>
        </template>
        <slot></slot>
      </vxe-table>
    </div>
    <el-pagination
      v-if="showPagination"
      background
      :current-page="page"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pagination.limit"
      :page-sizes="pagination.sizes"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <ColumnConfig
      :visible.sync="visible"
      :columns="columns"
      :appendToBody="appendToBody"
      @column-change="handleColumnChange"
    />
  </div>
</template>

<script>
  import ConfigColumn from '@/mg-components/mg-table/mixins/column-config'
  import KeyupEnter from '@/mg-components/mg-table/mixins/keyup-enter'
  export default {
    mixins: [ConfigColumn, KeyupEnter],
    name: 'paginationTable',
    props: {
      columns: {
        type: Array,
        default: () => [],
      },
      // 是否启用多字段组合排序
      multipleSort: {
        type: Boolean,
        default: false,
      },
      // 是否启用服务器排序
      sortRemote: {
        type: Boolean,
        default: true,
      },
      // 是否启用搜索
      searchable: {
        type: Boolean,
        default: false,
      },
      // 是否启用搜索
      showSummary: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Array,
        default: () => [],
      },
      // 处理列表请求后的数据
      formatData: Function,
      initialData: Array,
      summarySums: Array,
      api: Function,
      apiParams: Object,
      autoLoad: Boolean,
      // 需要查询参数对象
      filtersForm: {
        type: Object,
        default: () => {},
      },
      // 是否需要显示分页
      showPagination: {
        type: Boolean,
        default: true,
      },
      // 点击当前列不选中行
      checkboxColumnOff: {
        type: Array,
        default: () => [],
      },
      // 合计金额字段
      amountColumns: {
        type: Array,
        default: () => [],
      },
      // 数量金额字段
      quantityColumns: {
        type: Array,
        default: () => [],
      },
      // 列表高度
      tableHeight: {
        type: [Number, String],
        default: 600,
      },
      // 列表行是否可点
      isRowClick: {
        type: Boolean,
        default: true,
      },
      // 是否显示顶部bar
      isShowTopBar: {
        type: Boolean,
        default: true,
      },
      // 添加行样式
      rowClassValues: {
        type: [String, Object],
        default: null,
      },
      // 是否需要mini显示分页
      showMiniPagination: {
        type: Boolean,
        default: true,
      },
      // 是否需要表格loading
      isNeedLoading: {
        type: Boolean,
        default: true,
      },
      // 全量合计数据
      sumColumnsData: {
        type: Object,
        default: null,
      },
      // 额外参数
      extraParams: {
        type: Object,
        default: null
      },
      // 列宽弹窗是否插入body
      appendToBody: {
        type: Boolean,
        default: true,
      },
      // 是否全屏显示
      isConfigFullScreen: {
        type: Boolean,
        default: true
      }
    },
    components: {
      mgMiniPagination: () => import('@/mg-components/mg-table/components/mg-mini-pagination.vue'),
    },
    data() {
      return {
        multiple: false,
        activeColIndex: 0,
        activeRowIndex: 0,
        showSearchBar: false,
        selectList: [],
        pagination: {
          page: this.apiParams?.page || 1,
          limit: this.apiParams?.limit || 20,
          sizes: (this.apiParams?.sizes && [10, 20, 30, 40, 50, 100].concat(this.apiParams?.sizes)) || [
            10, 20, 30, 40, 50, 100,
          ],
        },
        list: this.$attrs.data,
        loading: false,
        total: 0,
        autoHeight: 0,
        selectedRows: [],
        listsResult: null,
        isFullScreen: false
      }
    },
    computed: {
      multipleSortBtnStyle() {
        return this.multiple
          ? { color: '#e60012', borderColor: '#f8b3b8', backgroundColor: '#fde6e7' }
          : { color: '#252a3d', borderColor: '#dcdfe6', backgroundColor: '#ffffff' }
      },
      page() {
        return this.pagination.page
      },
      totalPage() {
        return Math.ceil(this.total / this.pagination.limit)
      },
    },
    watch: {
      selectList: function (val) {
        this.$emit('selection-change', val)
      },
      filtersForm: {
        handler(newVal) {
          Object.keys(newVal).forEach((key) => {
            const tempKeys = this.columns.map((item) => item.prop)
            if (!tempKeys.includes(key)) return
            const column = this.$refs.mgTable.getColumnByField(key)
            this.filterChange(column, newVal[key])
          })
        },
        deep: true,
      }
    },
    created() {
      if (this.api && this.autoLoad) {
        this.loadData()
      }
    },
    mounted() {
      this.$el.addEventListener('keyup', this.handleKeyup, false)
    },
    beforeDestroy() {
      this.$el.removeEventListener('keyup', this.handleKeyup, false)
    },
    methods: {
      seqMethod({ row, rowIndex, column, columnIndex }) {
        return rowIndex + 1 + (this.page - 1) * this.pagination.limit
      },
      // 重置columns
      handleResetColumns(columns) {
        this.handleColumnChange(columns)
      },
      loadData() {
        this.$emit('loading', true)
        // 为了局部场景不需要表格loading
        if (this.isNeedLoading) this.loading = true
        return new Promise((resolve, reject) => {
          const apiParams = this.$lodash.omit(this.apiParams, ['sizes'])
          const pagination = this.$lodash.omit(this.pagination, ['sizes'])
          this.api({
            ...apiParams,
            ...pagination,
          })
            .then(res => {
              const dataKeys = res?.data && Object.keys(res.data) || []
              dataKeys.forEach(async item => {
                if (this.$lodash.isArray(res.data[item])) {
                  if (this.extraParams) {
                    this.list = res.data?.datas && res.data?.datas.map(item => {
                      return {
                        ...item,
                        ...this.extraParams
                      }
                    }) || res.data[item]?.map(item => {
                      return {
                        ...item,
                        ...this.extraParams
                      }
                    }) || []
                  }
                  if ( !this.extraParams ) this.list = res.data?.datas || res.data[item] || []
                  if (typeof this.formatData === 'function') await this.formatData(res.data?.items)
                  this.$emit('update:initialData', [...(this.list || [])])
                  this.total = res.data?.total || 0
                  if (this.pagination.page > 1 && this.list.length === 0) {
                    this.pagination.page = 1
                    this.loadData()
                  }
                  this.$emit('getTableData', res)
                  this.selectedRows = []
                  this.listsResult = res
                  resolve(res)
                }
              })
            })
            .catch((err) => {
              reject(err)
            })
            .finally(() => {
              this.loading = false
              this.$emit('loading', false)
              if (this.$attrs['show-summary']) {
                this.$nextTick(() => {
                  this.$refs.mgTable.updateFooter()
                })
              }
            })
        })
      },
      handleCurrentChange(page) {
        this.pagination.page = page
        this.loadData()
      },
      handleSizeChange(limit) {
        this.pagination.page = 1
        this.pagination.limit = limit
        this.loadData()
      },
      handlePageChange(page) {
        this.handleCurrentChange(page)
      },
      handleShowSearch() {
        this.showSearchBar = !this.showSearchBar
        this.$nextTick(() => {
          this.$refs.mgTable.recalculate(true)
        })
        this.clearFilter()
        this.$emit('searchable-change', this.showSearchBar)
      },
      // 合计
      footerMethod({ columns, data }) {
        if (this.showSummary) {
          this.$emit('summary-method', { columns, data })
          return this.$parent?.sums ? [this.$parent.sums] : [this.$parent.$parent?.sums]
        } else {
          return [
            columns.map((column, columnIndex) => {
              if (columnIndex === 0) {
                return '合计'
              }
              // 金额列合计
              if (this.amountColumns.includes(column.property)) {
                const values = data.map((item) => item[column.property] || 0)
                const total = values.reduce((sum, value) => (sum = this.np.plus(sum, value)), 0)
                return (this.sumColumnsData && this.sumColumnsData[column.property]) || this.$format.amount(total)
              }
              // 数量列合计
              if (this.quantityColumns.includes(column.property)) {
                const values = data.map((item) => item[column.property] || 0)
                const total = values.reduce((sum, value) => (sum = this.np.plus(sum, value)), 0)
                return (this.sumColumnsData && this.sumColumnsData[column.property]) || total
              }
              return null
            }),
          ]
        }
      },
      // 选中所有
      selectAllEvent({ records }) {
        this.selectList = records
      },
      // 选择当行
      selectChangeEvent({ records }) {
        this.selectList = records
      },
      // 单机行
      handleCell({ row, column }) {
        if (!this.isRowClick) return false
        if (!this.checkboxColumnOff.includes(column.property) && column.type !== 'checkbox' && column.property) {
          this.$refs.mgTable.toggleCheckboxRow(row)
          this.selectList = this.$refs.mgTable.getCheckboxRecords()
        }
      },
      // 删除行
      remove(rows) {
        if (rows) {
          this.$refs.mgTable.remove(rows)
        }
      },
      // 统一过滤方法
      filterMethod(config, column) {
        if (!config.cellValue && !this.filtersForm[column]) return true
        return (
          config.row[column]
            .toString()
            .toLocaleLowerCase()
            .indexOf(this.filtersForm[column].toString().toLocaleLowerCase()) >= 0
        )
      },
      /**
       * 过滤数据
       */
      filterChange(column, values) {
        const xTable = this.$refs.mgTable
        const columns = column?.property && xTable.getColumnByField(column.property)
        if (columns) {
          // 修改第二个选项为勾选状态
          const option = columns.filters[0]
          option.data = values
          option.checked = true
          // 修改条件之后，需要手动调用 updateData 处理表格数据
          xTable.updateData()
          xTable.updateFooter()
          xTable.scrollTo(0, 0)
        }
      },
      /**
       * 清除过滤条件
       */
      clearFilter() {
        this.$refs.mgTable.clearFilter()
        this.filtersForm &&
          Object.keys(this.filtersForm).forEach((key) => {
            this.filtersForm[key] = ''
          })
      },
      // 添加行样式
      handleRowClassName({ row, rowIndex, $rowIndex }) {
        const rowClassValues = this.rowClassValues
        // 关系集合
        const relationObj = {
          '大于': () => {
            return Number(row[rowClassValues?.relation?.left]) > Number(row[rowClassValues?.relation?.right])
          },
          '小于': () => {
            return Number(row[rowClassValues?.relation?.left]) < Number(row[rowClassValues?.relation?.right])
          }
        }
        // 背景色集合
        const backgroundColorObj = {
          'red': 'vxe-column-red-bg'
        }
        // 单一条件(所应用场景：dst-web的【应付】->【应付明细台账】)
        const singleConditionCompare = rowClassValues?.fieldKey && rowClassValues?.fieldValue && rowClassValues?.fieldValue.includes(row[rowClassValues?.fieldKey])
        // 复合条件, 主要是两两字段成比较关系(所应用场景：ee-web的【活动】->【活动管理首页】)
        const recombinationConditionCompare = (rowClassValues?.relation?.type && rowClassValues?.relation?.left && rowClassValues?.relation?.right) && 
        relationObj[rowClassValues?.relation?.type] && relationObj[rowClassValues?.relation?.type]()
        if (singleConditionCompare || recombinationConditionCompare) return rowClassValues?.backgroundColor && backgroundColorObj[rowClassValues?.backgroundColor] || 'vxe-column-bg'
      },
      updateFooter() {
        this.$refs.mgTable.updateFooter()
      },
      // 全屏展示
      handleFullScreen() {
        this.isFullScreen = !this.isFullScreen
      }
    },
  }
</script>

<style lang="scss" scoped>
  .mg-customer-table-header-cell-noBorderTop {
    border-top: none;
  }
  .mg-table--full-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow: auto;
    margin: 0;
    height: calc(100vh - 10px);
    background: #fff;
  }
  .topbar-right {
     .mg-icon-full-screen {
      ::v-deep {
        .el-icon-full-screen:before {
            display: block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 16px;
        }
      }
     }
  }
  @import './index.scss';
</style>
