var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticClass: "new-box",
    },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "ora-org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "ora-dc",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          "default-first-option": "",
                          "default-index": 0,
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "ora-dc",
                        attrs: {
                          type: "ora-dc",
                          value: _vm.queryForm.dcCode,
                          linkageRef: "shop-route",
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          "default-index": 0,
                          clearable: false,
                          alwaysChange: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.shopJsonParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货路线" } },
                    [
                      _c("DictSelect", {
                        ref: "shop-route",
                        attrs: {
                          type: "shop-route",
                          value: _vm.queryForm.routeId,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          alwaysChange: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "routeId", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.shopJsonParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleImport },
            },
            [_vm._v("导入送货路线")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出送货路线")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-setting", type: "warning" },
              on: { click: _vm.setRoute },
            },
            [_vm._v("设置送货路线时间表")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-setting", type: "warning" },
              on: { click: _vm.manageRoute },
            },
            [_vm._v("管理送货路线")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "配送中心编码", prop: "dcCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心", prop: "dcName" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺编码", prop: "shopCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺名称", prop: "shopName" },
              }),
              _c("el-table-column", {
                attrs: { label: "送货路线", prop: "routeName" },
              }),
              _c("el-table-column", {
                attrs: { label: "显示名（中文名）", prop: "routeCnName" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "text",
                            },
                            on: {
                              click: () => {
                                _vm.currentItem = row
                                _vm.showUpdateRouteDialog = true
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v("修改送货路线")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _vm.showRouteSettingDialog
        ? _c("RouteSettingDialog", {
            attrs: {
              orgCode: _vm.queryForm.orgCode,
              dcCode: _vm.queryForm.dcCode,
            },
            on: {
              closeRouteSettingDialog: _vm.closeRouteSettingDialog,
              handleFilter: _vm.handleFilter,
            },
          })
        : _vm._e(),
      _vm.showRouteManageDialog
        ? _c("RouteManageDialog", {
            attrs: {
              orgCode: _vm.queryForm.orgCode,
              dcCode: _vm.queryForm.dcCode,
            },
            on: {
              closeRouteManageDialog: _vm.closeRouteManageDialog,
              handleFilter: _vm.handleFilter,
            },
          })
        : _vm._e(),
      _vm.showUpdateRouteDialog
        ? _c("UpdateRouteDialog", {
            attrs: {
              orgCode: _vm.queryForm.orgCode,
              dcCode: _vm.queryForm.dcCode,
              currentItem: _vm.currentItem,
            },
            on: {
              closeUpdateRouteDialog: _vm.closeUpdateRouteDialog,
              handleFilter: _vm.handleFilter,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }