<template>
  <div>
    <div style="padding: 20px">
      <label class="demonstration" style="font-size: 15px; padding-right: 1%;">周：</label>
      <el-date-picker
        v-model="yearWk"
        type="week"
        :picker-options="pickerOptions"
        @change="newDateWeekHandle"
        format="yyyyWW"
        placeholder="选择周">
      </el-date-picker>
      <label style="font-size: 15px; padding-left: 1%">大类：</label>
      <el-select v-model="c1CatName" style="font-size: 15px; padding-right: 1%; width:26%" clearable filterable
                 placeholder="请选择"
                 id="fz-select"
                 size="mini">
        <el-option
          v-for="item in c1CatNameRadio"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <el-button type="primary" size="mini" style="font-size: 15px;" @click="getOnNewTraceData">查询</el-button>
      <div style="float: right; margin-bottom: 15px; font-size: 15px;">更新时间: {{ updateTime }}</div>
    </div>

    <h1 style="font-size: 20px"> 所属周上新追踪</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleOnNewTraceStatisticsDownload">下载所属周上新追踪</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="onNewTraceList"
        border
        :header-cell-style="tableHeaderColor"
        style="width: 100%; ">
        <el-table-column fixed prop="c1CatName" label="八大类" align="center"></el-table-column>
        <el-table-column fixed prop="yearWk" label="所属周" align="center"></el-table-column>
        <el-table-column prop="createdYearWk" label="建档周" align="center"></el-table-column>
        <el-table-column prop="planCreatedSkuCnt" label="周规划建档sku数" align="center"></el-table-column>
        <el-table-column prop="createdSkuCnt" label="截至当前建档数量" align="center"></el-table-column>
        <el-table-column prop="createdOnTimeSkuCnt" label="准时建档数量建档" align="center"></el-table-column>
        <el-table-column prop="createdOnTimeSkuRate" label="准时建档率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.createdOnTimeSkuRate !=='-' && scope.row.createdOnTimeSkuRate.substring(0, scope.row.createdOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.createdOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.createdOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdNotOnTimeReason" label="未准时建档原因及动作" align="center" width="230px"></el-table-column>
        <el-table-column prop="rkYearWk" label="入仓周" align="center"></el-table-column>
        <el-table-column prop="planRkSkuCnt" label="周规划入仓SKU数量" align="center"></el-table-column>
        <el-table-column prop="rkOnTimeSkuCnt" label="准确入仓数量" align="center"></el-table-column>
        <el-table-column prop="rkOnTimeSkuRate" label="准确入仓率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.rkOnTimeSkuRate !=='-' && scope.row.rkOnTimeSkuRate.substring(0, scope.row.rkOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.rkOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.rkOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rkNotOnTimeReason" label="未准时入仓原因及动作" align="center" width="230px"></el-table-column>
        <el-table-column prop="onNewYearWk" label="上新周" align="center"></el-table-column>
        <el-table-column prop="planOnNewSkuCnt" label="周规划上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuCnt" label="准时上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuRate" label="上新准确率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.onNewOnTimeSkuRate !=='-' && scope.row.onNewOnTimeSkuRate.substring(0, scope.row.onNewOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.onNewOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.onNewOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onNewNotOnTimeReason" label="未准时上新原因及动作" align="center" width="230px">
          <template slot-scope="{ row }">
            <span v-if="row.yearWk !== lastWeek || row.c1CatName === '总计'">{{ row.onNewNotOnTimeReason }}</span>
            <el-input
              v-else
              v-model="row.onNewNotOnTimeReason"
              controls-position="right"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              type="textarea"
              :disabled="row.yearWk !== lastWeek"
              @change="handleChangeInput($event, row, 'onNew')"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div>
    </div>

    <h1 style="font-size: 18px"> 上新问题清单</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleOnNewProblemListDownload">下载上新问题清单</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="onNewProblemList"
        border
        style="width: 100%; ">
        <el-table-column fixed prop="yearWk" label="所属周" align="center"></el-table-column>
        <el-table-column fixed prop="itemId" label="商品编码" align="center"></el-table-column>
        <el-table-column prop="itemName" label="商品名称" width="300" align="center"></el-table-column>
        <el-table-column prop="c1CatName" label="大类" align="center"></el-table-column>
        <el-table-column prop="c2CatName" label="中类" align="center"></el-table-column>
        <el-table-column prop="c3CatName" label="小类" align="center"></el-table-column>
        <el-table-column prop="isCreatedOnTime" label="是否准时建档" align="center"></el-table-column>
        <el-table-column prop="isRkOnTime" label="是否准确入仓" align="center"></el-table-column>
        <el-table-column prop="isOnNewOnTime" label="是否准时上新" align="center"></el-table-column>
        <el-table-column prop="isCg" label="是否晨光" align="center"></el-table-column>
        <el-table-column prop="isManghe" label="是否盲盒" align="center"></el-table-column>
        <el-table-column prop="isJsFormFlag" label="商品形态-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsGradeFlag" label="商品等级-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsJzFlag" label="架装-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsTbFlag" label="是否属于不计算明细" align="center"></el-table-column>
        <el-table-column prop="isJs" label="是否计算" align="center"></el-table-column>
        <el-table-column prop="createdDate" label="建档日期" align="center"></el-table-column>
        <el-table-column prop="createdYearWk" label="建档所属周" align="center"></el-table-column>
        <el-table-column prop="expectedNewDate" label="预计上新日" align="center"></el-table-column>
        <el-table-column prop="expectedNewYearWk" label="预计上新所属周" align="center"></el-table-column>
        <el-table-column prop="onNewDate" label="实际上新时间" align="center"></el-table-column>
        <el-table-column prop="onNewDateYearWk" label="实际上新所属周" align="center"></el-table-column>
        <el-table-column prop="yrkDate" label="应入仓日期" align="center"></el-table-column>
        <el-table-column prop="yrkQty" label="应入仓量" align="center"></el-table-column>
        <el-table-column prop="realRkQty" label="应入仓日期前实际入仓数量" width="120" align="center"></el-table-column>
        <el-table-column prop="llPhStoreCnt" label="理论铺货店铺" align="center"></el-table-column>
        <el-table-column prop="realPhStoreCnt" label="实际铺货店铺" align="center"></el-table-column>
        <el-table-column prop="displayNum" label="最小陈列量" align="center"></el-table-column>
        <el-table-column prop="minDisNum" label="最小配货量" align="center"></el-table-column>
      </el-table>
      <el-pagination
        style="padding-top: 2%;"
        background
        :current-page="onNewProblemPage"
        :page-size="onNewProblemPageSize"
        :total="onNewProblemListTotal"
        layout="prev, pager, next"
        @current-change="onNewProblemHandlePageChange"
      >
      </el-pagination>
    </div>

    <h1 style="font-size: 20px"> 所属周入仓追踪</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleRkTraceStatisticsDownload">下载所属周入仓追踪</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="rkTraceList"
        border
        :header-cell-style="tableHeaderColor"
        style="width: 100%; ">
        <el-table-column fixed prop="c1CatName" label="八大类" align="center"></el-table-column>
        <el-table-column fixed prop="yearWk" label="所属周" align="center"></el-table-column>
        <el-table-column prop="createdYearWk" label="建档周" align="center"></el-table-column>
        <el-table-column prop="planCreatedSkuCnt" label="周规划建档sku数" align="center"></el-table-column>
        <el-table-column prop="createdSkuCnt" label="截至当前建档数量" align="center"></el-table-column>
        <el-table-column prop="createdOnTimeSkuCnt" label="准时建档数量建档" align="center"></el-table-column>
        <el-table-column prop="createdOnTimeSkuRate" label="准时建档率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.createdOnTimeSkuRate !=='-' && scope.row.createdOnTimeSkuRate.substring(0, scope.row.createdOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.createdOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.createdOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdNotOnTimeReason" label="未准时建档原因及动作" align="center" width="230px"></el-table-column>
        <el-table-column prop="rkYearWk" label="入仓周" align="center"></el-table-column>
        <el-table-column prop="planRkSkuCnt" label="周规划入仓SKU数量" align="center"></el-table-column>
        <el-table-column prop="rkOnTimeSkuCnt" label="准确入仓数量" align="center"></el-table-column>
        <el-table-column prop="rkOnTimeSkuRate" label="准确入仓率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.rkOnTimeSkuRate !=='-' && scope.row.rkOnTimeSkuRate.substring(0, scope.row.rkOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.rkOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.rkOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rkNotOnTimeReason" label="未准时入仓原因及动作" align="center" width="230px">
          <template slot-scope="{ row }">
            <span v-if="row.yearWk !== lastWeek || row.c1CatName === '总计'">{{ row.rkNotOnTimeReason }}</span>
            <el-input
              v-else
              v-model="row.rkNotOnTimeReason"
              controls-position="right"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              type="textarea"
              :disabled="row.yearWk !== lastWeek"
              @change="handleChangeInput($event, row, 'rk')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="onNewYearWk" label="上新周" align="center"></el-table-column>
        <el-table-column prop="planOnNewSkuCnt" label="周规划上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuCnt" label="准时上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuRate" label="上新准确率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.onNewOnTimeSkuRate !=='-' && scope.row.onNewOnTimeSkuRate.substring(0, scope.row.onNewOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.onNewOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.onNewOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onNewNotOnTimeReason" label="未准时上新原因及动作" align="center" width="230px"></el-table-column>
      </el-table>
    </div>


    <h1 style="font-size: 18px"> 入仓问题清单</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleRkProblemListDownload">下载入仓问题清单</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="rkProblemList"
        border
        style="width: 100%; ">
        <el-table-column fixed prop="yearWk" label="所属周" align="center"></el-table-column>
        <el-table-column fixed prop="itemId" label="商品编码" align="center"></el-table-column>
        <el-table-column prop="itemName" label="商品名称" width="300" align="center"></el-table-column>
        <el-table-column prop="c1CatName" label="大类" align="center"></el-table-column>
        <el-table-column prop="c2CatName" label="中类" align="center"></el-table-column>
        <el-table-column prop="c3CatName" label="小类" align="center"></el-table-column>
        <el-table-column prop="isCreatedOnTime" label="是否准时建档" align="center"></el-table-column>
        <el-table-column prop="isRkOnTime" label="是否准确入仓" align="center"></el-table-column>
        <el-table-column prop="isOnNewOnTime" label="是否准时上新" align="center"></el-table-column>
        <el-table-column prop="isCg" label="是否晨光" align="center"></el-table-column>
        <el-table-column prop="isManghe" label="是否盲盒" align="center"></el-table-column>
        <el-table-column prop="isJsFormFlag" label="商品形态-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsGradeFlag" label="商品等级-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsJzFlag" label="架装-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsTbFlag" label="是否属于不计算明细" align="center"></el-table-column>
        <el-table-column prop="isJs" label="是否计算" align="center"></el-table-column>
        <el-table-column prop="createdDate" label="建档日期" align="center"></el-table-column>
        <el-table-column prop="createdYearWk" label="建档所属周" align="center"></el-table-column>
        <el-table-column prop="expectedNewDate" label="预计上新日" align="center"></el-table-column>
        <el-table-column prop="expectedNewYearWk" label="预计上新所属周" align="center"></el-table-column>
        <el-table-column prop="onNewDate" label="实际上新时间" align="center"></el-table-column>
        <el-table-column prop="onNewDateYearWk" label="实际上新所属周" align="center"></el-table-column>
        <el-table-column prop="yrkDate" label="应入仓日期" align="center"></el-table-column>
        <el-table-column prop="yrkQty" label="应入仓量" align="center"></el-table-column>
        <el-table-column prop="realRkQty" label="应入仓日期前实际入仓数量" width="120" align="center"></el-table-column>
        <el-table-column prop="llPhStoreCnt" label="理论铺货店铺" align="center"></el-table-column>
        <el-table-column prop="realPhStoreCnt" label="实际铺货店铺" align="center"></el-table-column>
        <el-table-column prop="displayNum" label="最小陈列量" align="center"></el-table-column>
        <el-table-column prop="minDisNum" label="最小配货量" align="center"></el-table-column>
      </el-table>
      <el-pagination
        style="padding-top: 2%;"
        background
        :current-page="rkProblemPage"
        :page-size="rkProblemPageSize"
        :total="rkProblemListTotal"
        layout="prev, pager, next"
        @current-change="rkProblemHandlePageChange"
      >
      </el-pagination>
    </div>

    <h1 style="font-size: 20px"> 所属周建档追踪</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleCreateraceStatisticsDownload">下载所属周建档追踪</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="createTraceList"
        border
        :header-cell-style="tableHeaderColor"
        style="width: 100%; ">
        <el-table-column fixed prop="c1CatName" label="八大类" align="center"></el-table-column>
        <el-table-column fixed prop="yearWk" label="所属周" align="center"></el-table-column>
        <el-table-column prop="createdYearWk" label="建档周" align="center"></el-table-column>
        <el-table-column prop="planCreatedSkuCnt" label="周规划建档sku数" align="center"></el-table-column>
        <el-table-column prop="createdSkuCnt" label="截至当前建档数量" align="center"></el-table-column>
        <el-table-column prop="createdOnTimeSkuCnt" label="准时建档数量建档" align="center"></el-table-column>
        <el-table-column prop="createdOnTimeSkuRate" label="准时建档率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.createdOnTimeSkuRate !=='-' && scope.row.createdOnTimeSkuRate.substring(0, scope.row.createdOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.createdOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.createdOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdNotOnTimeReason" label="未准时建档原因及动作" align="center" width="230px">
          <template slot-scope="{ row }">
            <span v-if="row.yearWk !== lastWeek || row.c1CatName === '总计'">{{ row.createdNotOnTimeReason }}</span>
            <el-input
              v-else
              v-model="row.createdNotOnTimeReason"
              controls-position="right"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              type="textarea"
              :disabled="row.yearWk !== lastWeek"
              @change="handleChangeInput($event, row, 'created')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="rkYearWk" label="入仓周" align="center"></el-table-column>
        <el-table-column prop="planRkSkuCnt" label="周规划入仓SKU数量" align="center"></el-table-column>
        <el-table-column prop="rkOnTimeSkuCnt" label="准确入仓数量" align="center"></el-table-column>
        <el-table-column prop="rkOnTimeSkuRate" label="准确入仓率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.rkOnTimeSkuRate !=='-' && scope.row.rkOnTimeSkuRate.substring(0, scope.row.rkOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.rkOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.rkOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rkNotOnTimeReason" label="未准时入仓原因及动作" align="center" width="230px"></el-table-column>
        <el-table-column prop="onNewYearWk" label="上新周" align="center"></el-table-column>
        <el-table-column prop="planOnNewSkuCnt" label="周规划上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuCnt" label="准时上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuRate" label="上新准确率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.onNewOnTimeSkuRate !=='-' && scope.row.onNewOnTimeSkuRate.substring(0, scope.row.onNewOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.onNewOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.onNewOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onNewNotOnTimeReason" label="未准时上新原因及动作" align="center" width="230px"></el-table-column>
      </el-table>
    </div>

<!--    <h1 style="font-size: 18px"> 建档问题清单</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleCreateProblemListDownload">下载建档问题清单</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="createProblemList"
        border
        style="width: 100%; ">
        <el-table-column prop="yearWk" label="所属周" align="center" fixed></el-table-column>
        <el-table-column prop="itemId" label="商品编码" align="center"></el-table-column>
        <el-table-column prop="itemName" label="商品名称" width="300" align="center"></el-table-column>
        <el-table-column prop="c1CatName" label="大类" align="center"></el-table-column>
        <el-table-column prop="c2CatName" label="中类" align="center"></el-table-column>
        <el-table-column prop="c3CatName" label="小类" align="center"></el-table-column>
        <el-table-column prop="isCreatedOnTime" label="是否准时建档" align="center"></el-table-column>
        <el-table-column prop="isRkOnTime" label="是否准确入仓" align="center"></el-table-column>
        <el-table-column prop="isOnNewOnTime" label="是否准时上新" align="center"></el-table-column>
        <el-table-column prop="isCg" label="是否晨光" align="center"></el-table-column>
        <el-table-column prop="isManghe" label="是否盲盒" align="center"></el-table-column>
        <el-table-column prop="isJsFormFlag" label="商品形态-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsGradeFlag" label="商品等级-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsJzFlag" label="架装-是否计算" align="center"></el-table-column>
        <el-table-column prop="isJsTbFlag" label="是否属于不计算明细" align="center"></el-table-column>
        <el-table-column prop="isJs" label="是否计算" align="center"></el-table-column>
        <el-table-column prop="createdDate" label="建档日期" align="center"></el-table-column>
        <el-table-column prop="createdYearWk" label="建档所属周" align="center"></el-table-column>
        <el-table-column prop="expectedNewDate" label="预计上新日" align="center"></el-table-column>
        <el-table-column prop="expectedNewYearWk" label="预计上新所属周" align="center"></el-table-column>
        <el-table-column prop="onNewDate" label="实际上新时间" align="center"></el-table-column>
        <el-table-column prop="onNewDateYearWk" label="实际上新所属周" align="center"></el-table-column>
        <el-table-column prop="yrkDate" label="应入仓日期" align="center"></el-table-column>
        <el-table-column prop="yrkQty" label="应入仓量" align="center"></el-table-column>
        <el-table-column prop="realRkQty" label="应入仓日期前实际入仓数量" width="120" align="center"></el-table-column>
        <el-table-column prop="llPhStoreCnt" label="理论铺货店铺" align="center"></el-table-column>
        <el-table-column prop="realPhStoreCnt" label="实际铺货店铺" align="center"></el-table-column>
        <el-table-column prop="displayNum" label="最小陈列量" align="center"></el-table-column>
        <el-table-column prop="minDisNum" label="最小配货量" align="center"></el-table-column>
      </el-table>
      <el-pagination
        style="padding-top: 2%;"
        background
        :current-page="createProblemPage"
        :page-size="createProblemPageSize"
        :total="createProblemListTotal"
        layout="prev, pager, next"
        @current-change="createProblemHandlePageChange"
      >
      </el-pagination>
    </div>-->

    <h1 style="font-size: 20px"> YTD汇总</h1>
    <div class="top-title">
      <div class="title-right">
        <el-button type="primary" size="mini" icon="el-icon-download" @click="handleYtdSummaryStatisticsDownload">下载YTD汇总</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="ytdSummaryList"
        border
        style="width: 100%; ">
        <el-table-column prop="c1CatName" label="大类" align="center" fixed></el-table-column>
        <el-table-column prop="planOnNewSkuCnt" label="规划上新sku数 (不含晨光)" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuCnt" label="准时上新SKU数" align="center"></el-table-column>
        <el-table-column prop="onNewOnTimeSkuRate" label="准时上新率" align="center">
          <template slot-scope="scope" >
            <span v-if="scope.row.onNewOnTimeSkuRate !=='-' && scope.row.onNewOnTimeSkuRate.substring(0, scope.row.onNewOnTimeSkuRate.length-1) < 100" style="color:red">{{ scope.row.onNewOnTimeSkuRate }}</span>
            <span v-else >{{ scope.row.onNewOnTimeSkuRate }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getC1CatNameList,
  getOnNewProblemPageList,
  getOnNewTraceStatistics,
  getRkTraceStatistics,
  getRkProblemPageList,
  getCreateTraceStatistics,
  getCreateProblemPageList,
  getOnNewTraceStatisticsExport,
  getOnNewProblemListExport,
  getRkTraceStatisticsExport,
  getRkProblemListExport,
  getCreateTraceStatisticsExport,
  getCreateProblemListExport,
  updateOnNewNotOnTimeReason,
  updateRkNotOnTimeReason,
  updateCreatedNotOnTimeReason,
  queryYtdSummaryStatistics, ytdSummaryStatisticsExport, getDateLastUpdateTime
} from "@/api/xpTrace";
import moment from 'moment';
import {Notification} from "element-ui";

export default {
  data() {
    return {
      c1CatName: '',
      c1CatNameRadio: [],
      yearWk: '',
      onNewProblemList: [],
      rkProblemList: [],
      createProblemList: [],
      onNewTraceList: [],
      rkTraceList: [],
      createTraceList: [],
      startDate: '',
      endDate: '',
      onNewProblemPage: 1,
      onNewProblemPageSize: 10,
      onNewProblemListTotal: 1,
      rkProblemPage: 1,
      rkProblemPageSize: 10,
      rkProblemListTotal: 1,
      createProblemPage: 1,
      createProblemPageSize: 10,
      createProblemListTotal: 1,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > (moment(moment().week(moment().week() - 1).endOf('week').add(2, 'days').valueOf())) - 8.64e7;
        }
      },
      lastWeek: '',
      ytdSummaryList: [],
      updateTime: undefined
    }
  },
  created() {
    getC1CatNameList().then(res => {
      this.c1CatNameRadio = res.data;
    })
    var startTime =  moment(moment(moment().week(moment().week()).startOf('week').add(1, 'days').valueOf())).add(-6, 'day').format("YYYY-MM-DD HH:mm:ss");
    console.log("默认选择时间,starttime:" + startTime);
    this.yearWk = startTime;
    this.lastWeek = moment(this.yearWk).format('yyyyWW');
    this.newDateWeekHandle();
    this.getOnNewTraceData();
    getDateLastUpdateTime().then(res => {
      this.updateTime = res.data;
    })
  },
  methods: {
    newDateWeekHandle(){
      console.log("选择的周,yearWk:" + this.yearWk)
      const now = new Date(this.yearWk);
      const nowTime = now.getTime();
      const day = now.getDay();
      const oneDayTime = 24*60*60*1000;
      const mondayTime = nowTime - (day-1)*oneDayTime;
      const sundayTime = nowTime + (7-day)*oneDayTime;
      this.startDate = moment(mondayTime).format('YYYYMMDD');
      this.endDate = moment(sundayTime).format('YYYYMMDD');
    },

    getOnNewTraceData() {
      if(moment(this.endDate, 'YYYYMMDD').toDate().getTime() > new Date().getTime()){
        Notification.warning('最晚周为上周');
      }else {
        this.reset();
        this.getOnNewTraceStatistics();
        this.getOnNewProblemPageList();

        this.getRkTraceStatistics();
        this.getRkProblemPageList();

        this.getCreateTraceStatistics();
        this.getCreateProblemPageList();

        this.getYtdSummaryStatistics();
      }
    },

    async getOnNewTraceStatistics() {
      console.log('getOnNewTraceStatistics method execute...')
      try {
        var parameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate
        }
        const res = await getOnNewTraceStatistics(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.onNewTraceList = resJson.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getOnNewProblemPageList() {
      console.log('getOnNewProblemPageList method execute...')
      try {
        var parameters = {
          page: this.onNewProblemPage,
          limit: this.onNewProblemPageSize,
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate
        }
        const res = await getOnNewProblemPageList(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.onNewProblemList = resJson.data.datas;
        this.onNewProblemListTotal = resJson.data.total;
      } catch (error) {
        console.error(error);
      }
    },
    async getRkTraceStatistics() {
      console.log('getRkTraceStatistics method execute...')
      try {
        var parameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate
        }
        const res = await getRkTraceStatistics(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.rkTraceList = resJson.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getRkProblemPageList() {
      console.log('getRkProblemPageList method execute...')
      try {
        var parameters = {
          page: this.rkProblemPage,
          limit: this.rkProblemPageSize,
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate
        }
        const res = await getRkProblemPageList(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.rkProblemList = resJson.data.datas;
        this.rkProblemListTotal = resJson.data.total;
      } catch (error) {
        console.error(error);
      }
    },
    async getCreateTraceStatistics() {
      console.log('getCreateTraceStatistics method execute...')
      try {
        var parameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate
        }
        const res = await getCreateTraceStatistics(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.createTraceList = resJson.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCreateProblemPageList() {
      console.log('getCreateProblemPageList method execute...')
      try {
        var parameters = {
          page: this.createProblemPage,
          limit: this.createProblemPageSize,
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate
        }
        const res = await getCreateProblemPageList(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.createProblemList = resJson.data.datas;
        this.createProblemListTotal = resJson.data.total;
      } catch (error) {
        console.error(error);
      }
    },
    async handleOnNewTraceStatisticsDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
        startDate: this.startDate,
        endDate: this.endDate
      }
      const res = await getOnNewTraceStatisticsExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
    async handleOnNewProblemListDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
        startDate: this.startDate,
        endDate: this.endDate
      }
      const res = await getOnNewProblemListExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
    async handleRkTraceStatisticsDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
        startDate: this.startDate,
        endDate: this.endDate
      }
      const res = await getRkTraceStatisticsExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
    async handleRkProblemListDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
        startDate: this.startDate,
        endDate: this.endDate
      }
      const res = await getRkProblemListExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
    async handleCreateraceStatisticsDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
        startDate: this.startDate,
        endDate: this.endDate
      }
      const res = await getCreateTraceStatisticsExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
    async handleCreateProblemListDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
        startDate: this.startDate,
        endDate: this.endDate
      }
      const res = await getCreateProblemListExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
    reset(){
      this.onNewProblemPage = 1;
      this.onNewProblemPageSize = 10;
      this.onNewProblemListTotal= 1;
      this.rkProblemPage= 1;
      this.rkProblemPageSize= 10;
      this.rkProblemListTotal= 1;
      this.createProblemPage= 1;
      this.createProblemPageSize= 10;
      this.createProblemListTotal= 1;
      this.onNewProblemList = [];
      this.rkProblemList= [];
      this.createProblemList= [];
      this.onNewTraceList= [];
      this.rkTraceList= [];
      this.createTraceList= []
    },
    handleChangeInput(val, row, key) {
      if("onNew" === key){
        var onNewParameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: row.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate,
          reason: row.onNewNotOnTimeReason
        };
        updateOnNewNotOnTimeReason(onNewParameters).then(res => {
          if (res.code === 200) {
            Notification.success("修改成功");
            this.handleQuery()
          } else {
            Notification.error(res.msg);
          }
        });
      }else if("rk" === key){
        var rkParameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: row.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate,
          reason: row.rkNotOnTimeReason
        };
        updateRkNotOnTimeReason(rkParameters).then(res => {
          if (res.code === 200) {
            Notification.success("修改成功");
            this.handleQuery()
          } else {
            Notification.error(res.msg);
          }
        });
      }else if("created" === key){
        var createdParameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: row.c1CatName,
          startDate: this.startDate,
          endDate: this.endDate,
          reason: row.createdNotOnTimeReason
        };
        updateCreatedNotOnTimeReason(createdParameters).then(res => {
          if (res.code === 200) {
            Notification.success("修改成功");
            this.handleQuery()
          } else {
            Notification.error(res.msg);
          }
        });
      }
    },
    onNewProblemHandlePageChange(newPage) {
      this.onNewProblemPage = newPage;
      this.getOnNewProblemPageList()
    },

    rkProblemHandlePageChange(newPage) {
      this.rkProblemPage = newPage;
      this.getRkProblemPageList()
    },
    createProblemHandlePageChange(newPage) {
      this.createProblemPage = newPage;
      this.getRkProblemPageList()
    },
    //设置表头的颜色
    tableHeaderColor({ rowIndex, columnIndex }) {
      if (rowIndex === 0 && (columnIndex === 2 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 6)) {
        return 'background-color: #afccfd;color:#000000;'; //蓝色
      } else if (rowIndex === 0 && (columnIndex === 8 || columnIndex === 9 || columnIndex === 10 || columnIndex === 11)) {
        return 'background-color: rgb(50 194 177 /0.5);color:#000000;';//绿色
      } else if (rowIndex === 0 && (columnIndex === 13 || columnIndex === 14 || columnIndex === 15 || columnIndex === 16)) {
        return 'background-color: #fbc57b;color:#000000;';//橙色
      } else {
        return 'color:#000000;background: #ffffff;';
      }
    },

    async getYtdSummaryStatistics() {
      console.log('getYtdSummaryStatistics method execute...')
      try {
        var parameters = {
          yearWk: moment(this.yearWk).format('yyyyWW'),
          c1CatName: this.c1CatName,
        }
        const res = await queryYtdSummaryStatistics(parameters)
        const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
        this.ytdSummaryList = resJson.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleYtdSummaryStatisticsDownload() {
      var parameters = {
        yearWk: moment(this.yearWk).format('yyyyWW'),
        c1CatName: this.c1CatName,
      }
      const res = await ytdSummaryStatisticsExport(parameters)
      const resJson = JSON.parse(JSON.stringify(res));  // 将 JSON 字符串解析成对象或数组
      const downloadLink = resJson.msg;
      const code = resJson.code;
      if (code === 200) {
        // 触发文件下载
        window.location.href = downloadLink
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .row-class-1 {
  background-color: #f0f9eb;;
}

::v-deep {
  .row-class-2 {
    background-color: #75886e;
  }
}

.rea_ {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  height: 100%;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
}

.rea_ > * {
  flex-grow: 1;
}

.progress-container {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #e5e9f2;
  margin-top: 2%;
  /* 设置在小屏幕下占用12列的宽度 */
  //@media (min-width: 768px) {
  //  width: 50%;
  //}
}

// 进度条百分比的文字
.progress-summary {
  margin-top: 2%;
  width: 100%;
  font-size: 14px;
}


.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #33b5e5;
}

.progress-bar2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #5cb87a;
}

.progress-bar3 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #5cb87a;
}

.progress-bar4 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1989fa;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
}

.progress-value {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.progress-total {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.percentage {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}


.top-title {
  margin-top: 1%;
  //margin-bottom: 1%;
  display: flex;
  justify-content: space-between; //justify-content: space-between; 属性将左右两个部分放到两端
  align-items: center;
  height: 100%;
  padding: 0 20px;
  color: #F56C6C;
}

.title-left {
  flex: 1;
  display: flex;
  align-items: center;

}

.title-right {
  display: flex;
  justify-content: flex-end; //justify-content: flex-end; 属性将其内容右对齐
  align-items: center;
  flex: 1;
}

.chart-container {
  position: relative;
  height: auto;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 3%;
  padding-top: 1%;
  padding-left: 5%;
  padding-right: 5%;

}


//.chart-container canvas {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//}

/* 文本放进度条右侧*/
.progress-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//标题
.header {
  display: flex;
  align-items: center;
  padding-bottom: 1%;
  padding-top: 1%;
}

// 标题选项间设置
.child-header {
  font-size: 15px;
  padding-left: 1%;
  //padding-right: 5%;
}

//选项设置
.header-option {
  font-size: 10px;
  color: #150a0a;
  margin-right: 20px;
  //background: #f5f5f5;
}

.child-header-check {
  color: #333;
  font-size: 16px;
}

.child-header-one {
  font-size: 15px;
  padding-left: 1%;
  color: #333;
  //padding-right: 5%;
}

.store-polycontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.store-polytable-wrapper {
  flex: 1;
  overflow-y: auto;
}

.store-polypagination-wrapper {
  padding-top: 0.5%;
  padding-bottom: 1%;
}

// 表根内进度条
.progress-container-table {
  width: 100%;
  height: 100%;
  border-radius: 0;

}

.end-page {
  padding-bottom: 5%;
  padding-top: 10%;
}


</style>
