<template>
    <el-button type="primary" size="small" @click="jumpToASalesTarget">导入销售目标</el-button>
</template>

<script>
import { queryParamsToString } from "@/utils/index";
export default {
  data() {
    return {
    }
  },

  created() {
    console.log(this.$route.query);
  },
  methods: {
    jumpToASalesTarget(){
        const queryString =`?APP=${this.$route.query.datartToken}&sessionId=${this.$route.query.sessionId}` ;
        window.open(`/import/salesTargets${queryString}`, '_blank');
    }
  }
}
</script>

<style lang="scss" >
.app-wrapper{
    min-width: 0 !important;
}
.app-main{
    padding: 0 !important;
}
</style>