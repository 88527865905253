<template>
  <el-container>
    <el-aside style="width: 420px; background-color: #d0d6db; padding: 15px;height: 700px; overflow-y: scroll;">
      <div style="color:dodgerblue; text-align: center"><h1>APP界面预览</h1><p>任务合集页</p></div>
      <div class="page">
        <div class="content" :style="{'--bgColor': taskForm.configJson.page?.background?.color}">
          <div class="contentInner" :style="{'background-image': taskForm.configJson.page?.background?.image && `url(${taskForm.configJson.page?.background?.image})`,
          'background-repeat': 'no-repeat',
          'background-size': '100% auto' }">
            <div class="groupName" :style="{ color: (taskForm.configJson.page.taskName && taskForm.configJson.page.taskName.color) ? taskForm.configJson.page.taskName.color: '#fff' }">{{ (taskForm.configJson.page.taskName && !taskForm.configJson.page.taskName.show)? '': (taskForm.configJson.page.title? taskForm.configJson.page.title: taskForm.taskName)}}</div>
            <div class="introduction">
              <p class="introductionTitle">活动简介</p>
              <div class="desc">{{taskForm.description}}</div>
            </div>
            <div>
              <div style="box-shadow: 0 2px 12px 0 rgba(0,0,0,0.73); border: 1px dashed rgba(0,0,0,0); padding: 2px 3px; margin-top: 8px">
                <div v-for="(item,index) in groupTasks" class="groupBox" style="margin-top: 5px; background-color: white" :key="index">
                  <el-container style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); border: 1px dashed #f5dae0">
                    <el-main style="padding-top: 0px">
                      <div style="display: flex; justify-content: flex-end; margin-top: -3px; height: auto">
                        <el-button size="mini" type="text" icon="el-icon-upload2" :disabled="index===0" @click="handleModuleMove(item.title, 'up', index)"></el-button>
                        <el-button size="mini" type="text" icon="el-icon-download" :disabled="index===groupTasks.length-1" @click="handleModuleMove(item.title, 'down', index)"></el-button>
                        <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteModule(item.title, index)"></el-button>
                      </div>
                      <ModuleItem :title="item.title" :list="item.list" :moduleTasks="allTaskList" :type="item.type" :currentTask="currentTask" @setCurrentTask="(taskId) => currentTask = taskId"
                                  @handleChangeTask="(type, action,insideIndex) => handleChangeTask(index, type, insideIndex, allTaskList, action)" show-type="group-detail" />
                    </el-main>
                  </el-container>
                </div>

                <div class="groupBox" style="margin-top: 5px; background-color: white">
                  <div style="font-size: 18px; font-weight: 700; text-align: center; cursor: pointer; overflow: unset;
                      background-color: #ffeff2;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); border: 1px dashed #f5dae0;padding: 5px;"
                    @click="() => handleAddModule(groupTasks.length)">
                    <i class="el-icon-plus" style="color: red"></i>
                    <span style="color: red">  添加模块</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-aside>
    <el-main style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); margin-left: 10px">
      <el-form ref="groupForm" size="mini" label-width="120px" :inline="true" :model="dataForm" class="common-form">
        <el-form-item label="标题名称" prop="title">
          <el-input v-model="dataForm.title" placeholder="请输入标题名称" />
        </el-form-item>
        <el-form-item label="合集简介" prop="title">
          <el-input v-model="dataForm.description" style="width: 400px" :autosize="{ minRows: 2, maxRows: 5}" type="textarea" maxlength="150" show-word-limit placeholder="选填，请输入简介" />
        </el-form-item>
        <el-form-item label="选择页面模板" prop="bgTemplate">
          <el-radio-group v-model="dataForm.bgTemplate" @input="(val) => {
              dataForm.bgColor=colorMap[val];
              if (!dataForm.customHeadImage) {
                dataForm.bgImage = templateUrl[val];
              }
              $forceUpdate();
            }">
            <el-radio label="red" value="red">红色系模板</el-radio>
            <el-radio label="orange" value="orange">橙色系模板</el-radio>
            <!--        <el-radio label="yellow" value="yellow">黄色系模板</el-radio>-->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="顶部头图" prop="template">
          <el-radio-group v-model="dataForm.customHeadImage" @input="(v) => {
        if (!v) {
          dataForm.bgImage = templateUrl[dataForm.bgTemplate];
        } else {
          dataForm.bgImage= '';
        }
      }">
            <el-radio :label="false" :value="false">模板默认头图<img :src="templateUrl[dataForm.bgTemplate]" style="height: 80px; display: block;" /></el-radio>
            <el-radio :label="true" :value="true">自定义
              <OssUpload v-if="dataForm.customHeadImage" ref="ossUpload" :value-to.sync="dataForm.bgImage" :maxFileSize="1024" list-type="picture-card" style="display: table"
                         :limit="1" :always-show-button="false" tips="尺寸1125*726，下方225高度勿放置重要内容会被遮挡，图片建议小于1M。合集标题请做在图上一并上传" :tipMessageShow="false"
                         :accept-type="imgTypes" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题是否显示" prop="bgTemplate">
          <el-radio-group v-model="dataForm.taskNameShow" @input="(val) => {
            }">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="颜色配置" prop="template">
          <div style="display: inline-flex">
            <div style="display: flex">
              <span style="display: inline-flex">页面背景色</span><el-color-picker v-model="dataForm.bgColor" />
            </div>
            <div style="display: flex">
              <span style="display: inline-flex">标题颜色</span><el-color-picker v-model="dataForm.taskNameColor" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-main>

    <el-drawer :title="drawer.title" :visible.sync="drawer.visible" direction="ltr" custom-class="drawerClass" size="100%" :show-close="false" :wrapperClosable="false"
               append-to-body>
      <TaskContentV2 v-if="drawer.templateType!=='group'" ref="taskContent" :templateType="drawer.templateType" :form="drawer.form" :orgCode="orgCode" :tasks="drawer.tasks" :timestamp="drawer.timestamp" />
      <div style="width: 200px; position: absolute; bottom: 50px; right: 50px; text-align: right;">
        <el-button size="mini" @click="confirm">确 认</el-button>
        <el-button size="mini" @click="drawer.visible = false">取 消</el-button>
      </div>
    </el-drawer>

    <el-dialog title="添加模块" :visible.sync="dialog.visible" width="700px" append-to-body>
      <div>
        <el-radio-group v-model="dialog.moduleType" @change="moduleTypeChange" style="margin: 10px; width: 100%">
          <el-radio :label="'1'">一排二小</el-radio>
          <el-radio v-if="taskForm.taskModuleType!='PARTNER'" :label="'2'">一大二小</el-radio>
          <el-radio v-if="taskForm.taskModuleType!='PARTNER'" :label="'3'">一排二大</el-radio>
          <el-radio v-if="taskForm.taskModuleType!='PARTNER'" :label="'4'">一排三个</el-radio>
          <el-radio :label="'5'">一排一个</el-radio>
        </el-radio-group>
        <el-container style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">
          <el-header style="display: flex; align-content: center">
            <p style="font-size: 17px; font-weight: 700">{{showCaseTitle}}</p>
          </el-header>
          <el-main>
            <el-image
              :src="showCaseImg"
              :fit="'contain'" style="height: 250px; width: 500px"></el-image>
          </el-main>
        </el-container>
        <el-row style="margin: 10px">
          <el-button size="small" type="primary" @click="addModule()">添加</el-button>
          <el-button size="small" @click="handleCancel()">取消</el-button>
        </el-row>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import {
} from "@/api/appTask";
import { v4 as uuidv4 } from "uuid";
import ModuleItem from "../ModuleItem";
import TaskContentV2 from "../taskContentV2";
import { deepClone } from "@/utils";
import {Notification} from "element-ui";
import OssUpload from "@/components/OssUpload";

export default {
  name: "taskGroupDetailV2",
  components: { ModuleItem, TaskContentV2, OssUpload },
  props: {
    orgCode: {
      type: String,
      required: true
    },
    taskList: {
      type:Array,
      default() {
        return []
      }
    },
    childTasks: {
      type:Array,
      default() {
        return []
      }
    },
    form: {
      type: Object,
    }
  },
  watch: {
    childTasks: {
      handler(nV, oV) {
        console.log(nV, 'watch')
        this.groupTasks = deepClone(nV);
      },
      deep: true,
      immediate: true
    },
    taskList: {
      handler(nV, oV) {
        this.allTaskList = deepClone(nV);
      },
      deep: true,
      immediate: true
    },
    form: {
      handler(nV, oV) {
        this.taskForm = deepClone(nV);
        const configJson = nV.configJson;
        const template = configJson.page.template;
        const customHeadImage = configJson.page.customHeadImage? true: false;
        const taskName = configJson.page.taskName? configJson.page.taskName: {
          color: '#fff',
          show: true
        }
        this.dataForm = {
          customHeadImage: customHeadImage,
          description: nV.description,
          bgColor: configJson.page.background.color,
          bgImage: customHeadImage ? configJson.page.background.image : this.templateUrl[template],
          bgTemplate: template,
          taskNameColor: taskName.color,
          taskNameShow: taskName.show,
          title: configJson.page.title
        };
      },
      deep: true,
      immediate: true
    },
    dataForm: {
      handler(nV, oV) {
        let configJson = this.taskForm.configJson;

        configJson.page.template = nV.bgTemplate;
        configJson.page.background.color = nV.bgColor;
        configJson.page.background.image = nV.bgImage;
        configJson.page.customHeadImage = nV.customHeadImage;
        configJson.page.title = nV.title;

        const taskName = {
          color: nV.taskNameColor,
          show: nV.taskNameShow
        }
        configJson.page.taskName = deepClone(taskName);
        this.taskForm.configJson = configJson;
        this.taskForm.description = nV.description;
      },
      deep: true,
      immediate: true
    },
    groupTasks: {
      handler(nV, oV) {
        if (nV) {
          let imageSet = false;
          nV.forEach(e => {
            e.list.forEach(l => {
              if (l) {
                const task = this.allTaskList.find(t => t.mtTaskId=== l);
                if (task && task.skuUrl) {
                  if (this.taskForm.configJson.theme.cover) {
                    this.taskForm.configJson.theme.cover.image = task.skuUrl;
                  } else {
                    const cover = {
                      custom: false,
                      template: 'blue',
                      skuImageType: '',
                      background: {
                        image: task.skuUrl,
                        originSkuImage: '',
                        removeBg: true
                      }
                    }
                    this.taskForm.configJson.theme.cover = cover;
                  }
                  imageSet = true;
                  return;
                }
              }
            })
            if (imageSet) {
              return;
            }
          })
        } else {
          this.taskForm.configJson.theme.cover.image = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      groupTasks: [],
      allTaskList: [],
      taskForm: {},
      loading: false,
      showCaseImg: undefined,
      showCaseTitle: undefined,
      currentTask: undefined,
      colorMap : {
        red: '#FA6D5E',
        orange: '#FF905E'
      },
      dataForm:{
        title: undefined,
        bgColor: undefined,
        bgImage: undefined,
        bgTemplate: undefined,
        taskNameColor: undefined,
        taskNameShow: undefined,
      },
      imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
      templateUrl: {
        red:process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E7%BA%A2%E8%89%B2%E6%A8%A1%E6%9D%BF-%E5%A4%B4%E5%9B%BE%E8%83%8C%E6%99%AF.png',
        orange:process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/%E6%A9%99%E8%89%B2%E5%A4%B4%E5%9B%BE%E8%83%8C%E6%99%AF.png',
      },
      showCaseImgList: {
        oneRowTwoSmall: {
          title: '示例：一排二小',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row2_2%402x.png'
        },
        oneBigTwoSmall: {
          title: '示例：一大二小',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1big2small%402x.png'
        },
        oneRowTwoBig: {
          title: '示例：一排二大',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row2%402x.png'
        },
        oneRowThree: {
          title: '示例：一排三个',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row3%402x.png'
        },
        oneRowOne: {
          title: '示例：一排一个',
          img: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/show_case/1row1%402x.png'
        },
      },
      taskModuleData: [],
      drawer: {
        visible: false,
        title: '',
        task: undefined,
        templateType: undefined,
        taskId: undefined,
        form: undefined,
        index: undefined,
        moduleIdx: undefined,
        insideIndex: undefined,
        tasks: [],
        timestamp: undefined,
      },
      dialog: {
        visible: false,
        moduleType: '1'
      }
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
    addModuleShow(moduleIndex) {
      this.dialog.visible = true;
      this.moduleIndex = moduleIndex;
      this.moduleTypeChange('1');
      this.splitTitle = '';
    },
    addModule() {
      this.addTaskModule(this.dialog.moduleType, this.moduleIndex);
      this.dialog.visible = false;
    },
    handleCancel() {
      this.dialog.visible = false;
    },
    moduleTypeChange(type) {
      switch (type) {
        case '1':
          this.showCaseImg = this.showCaseImgList.oneRowTwoSmall.img;
          this.showCaseTitle = this.showCaseImgList.oneRowTwoSmall.title;
          break;
        case '2':
          this.showCaseImg = this.showCaseImgList.oneBigTwoSmall.img;
          this.showCaseTitle = this.showCaseImgList.oneBigTwoSmall.title;
          break;
        case '3':
          this.showCaseImg = this.showCaseImgList.oneRowTwoBig.img;
          this.showCaseTitle = this.showCaseImgList.oneRowTwoBig.title;
          break;
        case '4':
          this.showCaseImg = this.showCaseImgList.oneRowThree.img;
          this.showCaseTitle = this.showCaseImgList.oneRowThree.title;
          break;
        case '5':
          this.showCaseImg = this.showCaseImgList.oneRowOne.img;
          this.showCaseTitle = this.showCaseImgList.oneRowOne.title;
          break;
        default:
          break;
      }
    },
    handleModuleMove(moduleId, type, index) {
      const row = this.groupTasks[index];
      if (type === "up") {
        this.groupTasks[index].sort = index -1;
        this.groupTasks[index-1].sort = index;
        this.groupTasks.splice(index, 1)
        this.groupTasks.splice(index - 1, 0, row)
      } else if (type === "down") {
        this.groupTasks[index].sort = index + 1;
        this.groupTasks[index+1].sort = index;
        this.groupTasks.splice(index, 1)
        this.groupTasks.splice(index + 1, 0, row)
      }
    },
    deleteModule(moduleId) {
      const removeTaskIds =this.groupTasks.find(e => e.title === moduleId).list.filter(e => e);
      this.allTaskList.forEach(e => {
        if (removeTaskIds.indexOf(e.mtTaskId) > -1) {
          e.parentId = 'root';
        }
      })
      this.groupTasks = this.groupTasks.filter(e => e.title !== moduleId)
      console.log(this.groupTasks);
    },
    handleAddModule(moduleIndex) {
      this.addModuleShow(moduleIndex);
    },
    addTaskModule(type, moduleIndex) {
      switch (type) {
        case "1":
          this.groupTasks.push({ type, title: uuidv4(), list: ['', ''], sort: moduleIndex + 1 });
          break;
        case "2":
          this.groupTasks.push({ type, title: uuidv4(), list: ['', '', ''], sort: moduleIndex + 1 });
          break;
        case "3":
          this.groupTasks.push({ type, title: uuidv4(), list: ['', ''], sort: moduleIndex + 1 });
          break;
        case "4":
          this.groupTasks.push({ type, title: uuidv4(), list: ['', '', ''], sort: moduleIndex + 1 });
          break;
        case "5":
          this.groupTasks.push({ type, title: uuidv4(), list: [''], sort: moduleIndex + 1 });
          break;
        case "6":
          this.groupTasks.push({ type, title: uuidv4(), list: [''], sort: moduleIndex + 1 });
          break;
      }
    },
    handleChangeTask(moduleIndex, type, insideIndex, tasks, action) {
      this.drawer.templateType = type;
      this.drawer.moduleIdx = moduleIndex;
      this.drawer.insideIndex = insideIndex;
      this.drawer.taskId = this.groupTasks[moduleIndex].list[insideIndex];
      this.drawer.action = action;
      this.drawer.timestamp = new Date().getTime();
      const allTaskIds = this.getAllTaskIds();
      if (this.drawer.action === 'remove') {
        let tmpList = this.groupTasks[moduleIndex].list;
        tmpList[insideIndex] = "";
        if (type === 'group') {
          // 将合集子任务恢复可选择状态
          this.allTaskList.forEach(e => {
            if (allTaskIds.indexOf(e.mtTaskId) > -1) {
              e.parentId = 'root';
            }
          })
        }
        this.groupTasks[moduleIndex].list = tmpList;
        console.log(this.groupTasks, 'handleChangeTask');
        this.currentTask = undefined;
        this.$forceUpdate();
        return;
      }
      const taskList = this.allTaskList;
      let tmpForm = {};
      tmpForm = taskList.find(e => e && e.mtTaskId === this.drawer.taskId);
      this.drawer.form = tmpForm ? deepClone(tmpForm): {timestamp: new Date().getTime()};
      this.drawer.tasks = tasks.filter(e => this.getAllTaskIds().indexOf(e.mtTaskId) < 0 || e.mtTaskId === this.drawer.taskId);
      if (this.drawer.tasks.length === 0 && type !== 'group') {
        Notification.warning("已无可添加的任务！");
        return;
      }
      this.drawer.visible = true;
    },
    getAllTaskIds() {
      let array = [];
      this.groupTasks.forEach(e => {
        array = array.concat(e.list.filter(e => e))
      })
      return array;
    },
    confirm() {
      if (this.$refs.taskContent) {
        if (!this.$refs.taskContent.taskForm || !this.$refs.taskContent.taskForm.mtTaskId) {
          Notification.warning("请选择相应的任务！");
          return;
        }
        this.groupTasks[this.drawer.moduleIdx].list.splice(this.drawer.insideIndex, 1, this.$refs.taskContent.taskForm.mtTaskId);
        console.log(this.groupTasks, 'confirm');
        this.allTaskList = this.allTaskList.filter(e => e.mtTaskId !== this.$refs.taskContent.taskForm.mtTaskId);
        let taskForm = deepClone(this.$refs.taskContent.taskForm);
        taskForm.isLeaf = 1;
        taskForm.parentId= this.taskForm.mtTaskId;
        if (!taskForm.configJson.theme.cover.background.image) {
          taskForm.configJson.theme.cover.background.image = taskForm.skuUrl;
        }
        this.allTaskList.push(taskForm);
      }
      this.drawer.visible = false;
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-main {
    padding: 5px 5px 0px 5px;
  }

  .el-drawer__header {
    margin: 0px;
    height: 0px;
    padding: 0px;
  }
  .groupBox {
    background-color: unset !important;
  }
}
</style>

<style lang="scss" src='@/style/appTask/groupDetail.scss'></style>
