var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "goodsCategoryInput",
      attrs: {
        clearable: "",
        placeholder: "请选择",
        "popper-class": "virtualSelect",
        fixed: "",
      },
      on: { clear: _vm.clearSkuCategory },
      model: {
        value: _vm.selectedCategory.categoryCode,
        callback: function ($$v) {
          _vm.$set(_vm.selectedCategory, "categoryCode", $$v)
        },
        expression: "selectedCategory.categoryCode",
      },
    },
    [
      _vm.options.goodsCategory.data.length !== 0
        ? _c("el-input", {
            staticClass: "sel-input",
            attrs: { placeholder: "输入关键字搜索" },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.options.goodsCategory.filterText,
              callback: function ($$v) {
                _vm.$set(_vm.options.goodsCategory, "filterText", $$v)
              },
              expression: "options.goodsCategory.filterText",
            },
          })
        : _vm._e(),
      _c(
        "el-option",
        {
          staticClass: "sel-option",
          attrs: {
            value: _vm.selectedCategory.categoryCode,
            label: _vm.selectedCategory.categoryName,
          },
        },
        [
          _c("el-tree", {
            ref: "goodsCategory",
            attrs: {
              data: _vm.options.goodsCategory.data,
              props: _vm.options.goodsCategory.dataProps,
              "node-key": _vm.selectedCategory.categoryCode,
              accordion: true,
              "filter-node-method": _vm.filterNode,
              "popper-append-to-body": false,
            },
            on: { "node-click": _vm.handleNodeClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }