var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thirdPartyPage-page" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "signboard",
              staticStyle: {
                padding: "10px 0 10px 0",
                "margin-bottom": "10px",
              },
              attrs: { md: 8, lg: 6, xl: 4 },
            },
            [_vm._v(" 排单计划确认 ")]
          ),
          _c(
            "el-col",
            {
              staticClass: "thirdPartyPage-page-head",
              staticStyle: { padding: "5px 0" },
              attrs: { md: 8, lg: 6, xl: 4 },
            },
            [
              _vm.user.role_code_list.includes("selfManager") ||
              _vm.user.role_code_list.includes("leader")
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleHeadAction("resetSelect")
                        },
                      },
                    },
                    [_vm._v("重 新 选 择 ")]
                  )
                : _vm._e(),
              _vm._v("   "),
              _vm.user.role_code_list.includes("selfManager") ||
              _vm.user.role_code_list.includes("leader")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "danger",
                        disabled: _vm.billStatus !== 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleHeadAction("sendRequisition")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.billStatus === 0 ? "抛 请 购 单" : "已 抛 单"
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticStyle: { width: "100%", height: "100vh", overflow: "scroll" },
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.iframeArrays, function (item, index) {
          return _c(
            "el-tab-pane",
            {
              key: index + "iframe",
              attrs: { label: item.name, name: item.tabName },
            },
            [
              [
                item.tabName === "5"
                  ? _c("div", [_c("actualProd-scheduligPlan")], 1)
                  : item.tabName === "2"
                  ? _c("div", [_c("ArrangePluReport")], 1)
                  : _c("iframe", {
                      staticStyle: { width: "100%", height: "90vh" },
                      attrs: { src: item.iframeSrc, frameborder: "0" },
                    }),
              ],
            ],
            2
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            title: "抛 请 购 单",
            width: "650px",
            visible: this.showDialog,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(this, "showDialog", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "dialogForm",
                  staticClass: "dialog-form",
                  attrs: {
                    "label-position": "right",
                    "label-width": "80px",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "到货日期", prop: "wantDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择到货日期",
                                },
                                model: {
                                  value: _vm.wantDate,
                                  callback: function ($$v) {
                                    _vm.wantDate = $$v
                                  },
                                  expression: "wantDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: this.sendRequisitionBtnLoading,
                  },
                  on: { click: _vm.handleSubmitRequisition },
                },
                [_vm._v("抛 单")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }