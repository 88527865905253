
import axios from 'axios';
import { Message, Loading } from 'element-ui';
import { ERROR_MESSAGE_MAPS } from "@/config";
import {getLocationUrl, getUrlParams, deleEmptyParams} from "./index";

axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
const pendingMap = new Map();
const LoadingInstance = {
  _target: null,
  _count: 0
};
/**
 * 请求方法体
 * @param { Object } axiosConfig
 * @param { Object } customOptions
 * @param { Object } loadingOptions
*/
function request (axiosConfig, customOptions, loadingOptions) {
    // 自定义配置
    let custom_options = Object.assign({
      repeat_request_cancel: false, // 是否开启取消重复请求, 默认为 true
      loading: false, // 是否开启loading层效果, 默认为false
      reduct_data_format: true, // 是否开启简洁的数据结构响应, 默认为true
      error_message_show: true, // 是否开启接口错误信息展示,默认为true
      code_message_show: false, // 是否开启code不为0时的信息提示, 默认为false
      default_message_show: true, // 是否开启默认提示消息方式, 默认为true
    }, customOptions);
    const service = axios.create({
        timeout: 180000, // 设置统一的超时时长
    });

    // 请求拦截
    service.interceptors.request.use(
        config => {
            removePending(config);
            custom_options.repeat_request_cancel && addPending(config);
            // 创建loading实例
            if (custom_options.loading) {
                LoadingInstance._count++;
                if(LoadingInstance._count === 1) {
                    LoadingInstance._target = Loading.service(loadingOptions);
                }
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    // 响应拦截
    service.interceptors.response.use(
        response => {
            const { code, msg } = response.data;
            removePending(response.config);
            custom_options.loading && closeLoading(custom_options); // 关闭loading
            if(custom_options.code_message_show && response.data && response.data.code !== 0 && custom_options.default_message_show) {
                Message({
                    type: 'error',
                    message: response.data.msg,
                    dangerouslyUseHTMLString: true,
                    duration: 20000
                })
                return Promise.reject(response.data); // code不等于0, 页面具体逻辑就不执行了
            }
            if (code && Number(code) >= 400 && custom_options.default_message_show) {
                Message({
                    type: 'error',
                    message: msg || ERROR_MESSAGE_MAPS[code],
                    dangerouslyUseHTMLString: true,
                    duration: 5000
                })
            }
            return custom_options.reduct_data_format ? response.data : response;

        },
        error => {
            error.config && removePending(error.config);
            custom_options.loading && closeLoading(custom_options); // 关闭loading
            custom_options.error_message_show && handleHttpErrorStatus(error); // 处理错误状态码
            return Promise.reject(error); // 错误继续返回给到具体页面
        }
    );
    return service(axiosConfig);
}
/**
 * 处理异常
 * @param { Object } error
*/
function handleHttpErrorStatus(error) {
    // 处理被取消的请求
    // if(axios.isCancel(error)) return console.error('该接口重复请求：' + error.message);
    let message = '';
    if (error && error.response) {
        const error_messages = Object.keys(ERROR_MESSAGE_MAPS);
        for (let i in error_messages) {
          if (error.response.data && error.response.data.code == 400 && error.response.data.msg.indexOf('时间戳') > -1) {
            message = error.response.data.msg;
            break;
          }
          if (error.response.data && error.response.data.code + "" === error_messages[i]) {
            message = ERROR_MESSAGE_MAPS[error_messages[i]];
            break;
          }
          if ( error.response.code == error_messages[i]) message = error.response.msg || ERROR_MESSAGE_MAPS[error_messages[i]];
          if ( error.response.code != error_messages[i]) message = error.response.msg || '操作失败，请联系管理员！';
        }
    }
    if (error.message.includes('timeout')) message = '网络请求超时！';
    if (error.message.includes('Network')) message = window.navigator.onLine ? '服务端异常！' : '网络连接已断开, 请稍候再试！';

    Message({
        type: 'error',
        message,
        duration: 5000
    })
}
/**
 * 关闭Loading层实例
 * @param { Object } _options
*/
function closeLoading(_options) {
    if(_options.loading && LoadingInstance._count > 0) LoadingInstance._count--;
    if(LoadingInstance._count === 0) {
        LoadingInstance._target.close();
        LoadingInstance._target = null;
    }
}
/**
 * 储存每个请求的唯一cancel回调, 以此为标识
 * @param { Object } config
*/
function addPending(config) {
    const pendingKey = getPendingKey(config);
    config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
        if (!pendingMap.has(pendingKey)) {
            pendingMap.set(pendingKey, cancel);
        }
    });
}
/**
 * 删除重复的请求
 * @param { Object } config
*/
function removePending(config) {
    const pendingKey = getPendingKey(config);
    if (pendingMap.has(pendingKey)) {
        const cancelToken = pendingMap.get(pendingKey);
        cancelToken(pendingKey);
        pendingMap.delete(pendingKey);
    }
}
/**
 * 生成唯一的每个请求的唯一key
 * @param { Object } config
*/
function getPendingKey(config) {
    let {url, method, params, data} = config;
    if(typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
    return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

// 为每个请求定制token
export function buildHeaders(data) {
  const URL = getLocationUrl();
  let params = getUrlParams(getLocationUrl())
  let xToken = params['x-token']
  let datartToken = params['datartToken']
  let APP = params['APP']
  let headers = {}
  const sessionId = params['sessionId']
  if (sessionId) {
    // datart中App应用
    const staffUrl = process.env.VUE_APP_SESSION_URL.split(',');
    const sessionUrl = staffUrl.filter(e => URL.indexOf(e) !== -1);
    if (sessionUrl.length > 0) {
      headers["Authorization"] = "JUBAOPEN_ADMIN " + sessionId;
      headers["timestamp"] = new Date().getTime();
      return headers;
    }
  }
  if(datartToken) {
    // 与DATART的协议
    headers["Authorization"] = "DATART " + datartToken
  }else if(APP){
    // APP接口
    headers["Authorization"] = "JUBAOPEN_ADMIN " + sessionId
    headers["timestamp"] = new Date().getTime();
  } else if(xToken === undefined || xToken === '') {
    // 旧订单服务/MES系统的方式
    headers["Authorization"] = "PDB " + data['operator']
  } else {
    // 新DRP的协议
    headers["Authorization"] = "DRP " + decodeURIComponent(xToken)
  }

  return headers;
}

export function axiosUlr (index = 0) {
    let env = process.env.VUE_APP_BASE_URL.split(',');
    return env[index];
}
/**
 * get方法请求
 * @param { String } url
 * @param { Object } data
*/
export function axiosGet(url, data, index = 0) {
    deleEmptyParams(data);
    let headers = buildHeaders(data);
    const baseURL = axiosUlr(index);
    return request({
        baseURL,
        url,
        method: 'get',
        headers : headers,
        params: data
    })
}
/**
 * post方法请求
 * @param { String } url
 * @param { Object } data
*/
export function axiosPost(url, data, index = 0) {
    deleEmptyParams(data);
    let headers = buildHeaders(data);
    const baseURL = axiosUlr(index);
    return request({
        baseURL,
        url,
        method: 'post',
        headers : headers,
        data
    })
}

/**
 * post方法请求
 * @param { String } url
 * @param { Object } data
 */
export function axiosPostWithoutMessage(url, data, index = 0) {
  deleEmptyParams(data);
  let headers = buildHeaders(data);
  const baseURL = axiosUlr(index);
  return request({
    baseURL,
    url,
    method: 'post',
    headers : headers,
    data
  }, {default_message_show: false})
}

/**
 * put方法请求
 * @param { String } url
 * @param { Object } data
*/
export function axiosPut(url, data, index = 0) {
    deleEmptyParams(data);
    let headers = buildHeaders(data);
    const baseURL = axiosUlr(index);
    return request({
        baseURL,
        url,
        method: 'put',
        headers: headers,
        data
    })
}
/**
 * delete方法请求
 * @param { String } url
 * @param { Object } data
*/
export function axiosDelete(url, data, index = 0) {
    deleEmptyParams(data);
    let headers = buildHeaders(data);
    const baseURL = axiosUlr(index);
    return request({
        baseURL,
        url,
        method: 'delete',
        headers: headers,
        data
    })
}
export function axiosDeleteWithoutMessage(url, data, index = 0) {
  deleEmptyParams(data);
  let headers = buildHeaders(data);
  const baseURL = axiosUlr(index);
  return request({
    baseURL,
    url,
    method: 'delete',
    headers: headers,
    data
  }, {default_message_show: false})
}
/**
 * Download方法请求
 * @param { String } url
 * @param { Object } data
*/
export function axiosDownload(url, data, index = 0) {
    deleEmptyParams(data);
    let headers = buildHeaders(data);
    const baseURL = axiosUlr(index);
    return request({
        baseURL,
        url,
        method: 'post',
        headers : headers,
        data,
        responseType: 'blob',
    })
}
