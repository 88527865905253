var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc,area-tag",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      "default-first-option": "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "area-tag",
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组" } },
                [
                  _c("DictSelect", {
                    ref: "area-tag",
                    attrs: {
                      type: "area-tag",
                      value: _vm.queryForm.areaTag,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaTag", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.shopJsonParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "unset" },
                  attrs: { label: "年月", "label-width": "40px" },
                },
                [
                  _c("DictSelect", {
                    ref: "yearMth",
                    attrs: {
                      type: "area-date",
                      multiple: "",
                      value: _vm.queryForm.yearMths,
                      dictFun: _vm.shopDictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "yearMths", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 350px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
          on: { "sort-change": _vm.handleSortChange },
        },
        [
          _c("el-table-column", { attrs: { type: "index", width: "40" } }),
          _c("el-table-column", {
            attrs: { label: "分公司编码-名称", prop: "orgCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心编码-名称", prop: "dcCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.dcCode + "-" + row.dcName))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "区域分组", prop: "areaTag" },
          }),
          _c("el-table-column", { attrs: { label: "年月", prop: "yearMth" } }),
          _c(
            "el-table-column",
            { attrs: { label: "全部款式" } },
            [
              _c("el-table-column", {
                attrs: { label: "进货金额目标(元)", prop: "allJheTarget" },
              }),
              _c("el-table-column", {
                attrs: { label: "进货款数目标", prop: "allKssTarget" },
              }),
              _c("el-table-column", {
                attrs: { label: "新增进货款数目标", prop: "allNewKssTarget" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.page,
          limit: _vm.queryForm.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "limit", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importDialog,
          "append-to-body": "",
          "modal-append-to-body": false,
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }