<template>
  <div class="new-box">
    <div class="mg-search" >
    <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery"  @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->

<!--        <el-form-item label="标签">-->
<!--          <DictSelect-->
<!--            type="tag"-->
<!--            :value.sync="queryForm.tag"-->
<!--            :dictFun="dictFun"-->
<!--            singleProps-->
<!--            default-first-option-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item label="关键词">
          <el-input v-model="queryForm.title"  clearable placeholder="请输入关键词" />
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="queryForm.category" filterable clearable placeholder="请选择分类">
            <el-option v-for="item in options.category" :label="item.dictLabel" :value="item.dictKey" :key="item.dictKey" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
        </el-form-item> -->

        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-edit" type="primary" @click="handleEdit(undefined, 'category')">编辑分类</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit(undefined, 'courseAdd')">创建新教程</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-edit" type="primary" @click="handleEdit(undefined, 'category')">编辑分类</el-button>
    <el-table
      :data="pageData.rows"
      style="margin-top: 10px;"
      v-loading="loading"
           size="small"
           class="customer-table"
           border
      ref="table"
      highlight-current-row
      @sort-change="handleSortChange"
    >
      <el-table-column label="ID" prop="id" width="180" show-overflow-tooltip/>
      <el-table-column label="标题" prop="title" width="280" />
      <el-table-column label="权重值" prop="sort" />
      <el-table-column label="创建者" prop="createUser" />
      <el-table-column label="分类" prop="categoryName"  />
      <el-table-column label="编辑时间" prop="updateTime" width="200"/>
      <el-table-column label="开始时间" prop="showStartTime" width="200"/>
      <el-table-column label="结束时间" prop="showEndTime" width="200"/>
      <el-table-column label="操作"  align="center" fixed="right">
        <template #default="{ row }">
          <div>
            <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row, 'courseEdit')">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="editDialog.titleMap[editDialog.type]" v-if="editDialog.visible" :visible.sync="editDialog.visible" append-to-body :width="editDialog.widthMap[editDialog.type]" :close-on-click-modal="false">
      <el-form v-if="editDialog.type && editDialog.type.indexOf('course') > -1" ref="form" size="mini" inline :rules="editDialog.rules" label-width="160px" :model="editDialog.form" class="common-form">
        <el-row class="common-form-row">
          <div style="border: solid 1px rgb(78 72 72 / 10%); padding: 10px">
            <el-col>
              <span style="color: #1482f0; font-size: 24px;">基本信息</span>
            </el-col>
            <el-col :span="16">
              <el-form-item label="教程标题" prop="title">
                <el-input v-model="editDialog.form.title" clearable placeholder="建议13字以内" maxlength="100" show-word-limit/>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="分类" prop="category">
                <el-radio-group v-model="editDialog.form.category">
                  <el-radio v-for="item in options.category" :label="item.dictKey" :key="item.dictKey">{{ item.dictLabel }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="自定义标签" prop="tag">
                <el-input v-model="editDialog.form.tag" clearable placeholder="2-5字，仅展示，如：“新功能” “新手必看”" maxlength="20" show-word-limit/>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="展示时间" prop="showStartTime">
                <el-date-picker
                  v-model="editDialog.showDate"
                  type="daterange"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  @change="val => handleDateChange(val)"
                  format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="排序权重" prop="sort">
                <el-input-number v-model="editDialog.form.sort" clearable :controls="false" placeholder="数字越小，排序越靠前" />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="上传头图" prop="coverImg">
                <OssUpload ref="ossUpload" :value-to.sync="editDialog.form.coverImg" list-type="picture-card" :maxFileSize="1024" tips="尺寸330*120，1MB以下" :tipMessageShow="false" :accept-type="editDialog.imgTypes" />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="关联的教程文章">
                <el-select v-model="editDialog.form.relateCourses" filterable multiple clearable>
                  <el-option v-for="item in options.relate" :label="item.title" :limit="1" :value="item.id" :key="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="24">
            <div style="border: solid 1px rgb(78 72 72 / 10%); padding: 10px; margin-top: 10px">
              <strong style="color: #1482f0; font-size: 24px;">教程详情</strong>
              <WangEditor ref="editor" :value.sync="editDialog.form.content" />
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div v-else>
        <el-table
          :data="editDialog.pageData.rows"
          height="calc(100vh - 250px)"
          v-loading="editDialog.loading"
          size="mini"
          ref="table"
          highlight-current-row
          @sort-change="handleSortChange"
        >
          <el-table-column type="index" width="50" />
          <el-table-column label="分类名" prop="dictLabel">
            <template #default="{ row }">
              <el-input v-if="editDialog.currentRow.dictLabel === row.dictLabel" v-model="row.dictLabelChange" size="mini" clearable />
              <span v-else>{{ row.dictLabel }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250" align="center">
            <template slot-scope="scope">
              <div>
                <el-button size="mini" type="primary" @click="openEditCategory(scope.row)">编辑</el-button>
                <el-button size="mini" type="primary" icon="el-icon-arrow-down" :disabled="scope.$index === editDialog.pageData.rows.length -1 || editDialog.currentRow.dictKey !== undefined" @click="handleMove(scope.row, 'down')" />
                <el-button size="mini" type="primary" icon="el-icon-arrow-up" :disabled="scope.$index === 0 || editDialog.currentRow.dictKey !== undefined" @click="handleMove(scope.row, 'up')" />
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="handleDeleteCategory(scope.row)" />
                <el-button size="mini" type="primary" @click="handleCopy(scope.row)">复制链接</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-button v-if="addShow" size="mini" type="primary" icon="el-icon-plus" @click="openEditCategory(undefined)">新建分类</el-button>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="editDialog.visible = false"> {{editDialog.type !== 'category' ? '取消': '关闭'}}</el-button>
        <el-button v-if="editDialog.type !== 'category'" type="primary" size="mini" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑分组" :visible.sync="editCategory.visible" width="500px" center>
      <el-form ref="categoryForm" size="mini" :model="editCategory.form" :rules="editCategory.rules" label-width="100px">
        <el-form-item label="分组名" prop="dictLabel">
          <el-input v-model="editCategory.form.dictLabel" clearable placeholder="请输入分类名" style="width: 280px" />
        </el-form-item>
        <el-form-item label="分组权限">
          <el-radio-group v-model="editCategory.form.authType">
            <el-radio :label="0">不限制</el-radio>
            <el-radio :label="1" ref="radio">
              指定角色可见
              <el-select size="mini" v-model="editCategory.form.roleKeys" clearable filterable multiple	placeholder="请选择角色" @change="handleRoleChange">
              <el-option
                v-for="item in options.roles"
                :key="item.roleKey"
                :label="item.roleName"
                :value="item.roleKey">
              </el-option>
            </el-select>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="saveCategory">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  appDict,
  getCoursePage,
  saveCourse,
  updateAppDict,
  moveAppDict,
  getCourseCategoryList, getBizRoleList, saveCourseCategory, removeCourseCategory
} from "@/api/appTask";
import { parseParam, parseTime } from "@/utils"
import { Notification } from "element-ui";
import OssUpload from "@/components/OssUpload";
import SearchFilter from "@/components/SearchFilter/index";
import WangEditor from "@/components/WangEditor";
export default {
  name: "Course",
  components: { Pagination, OssUpload, WangEditor, SearchFilter },
  data() {
    return {
      aa: undefined,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        title: undefined,
        category: undefined,
        tag: undefined,
      },
      lrDay: undefined,
      loading: false,
      dictFun: appDict,
      dictParam: {},
      formDictParam: {},
      editDialog: {
        visible: false,
        loading: false,
        type: undefined,
        currentRow: {},
        titleMap: {
          category: '编辑分类',
          courseAdd: '新建教程',
          courseEdit: '编辑教程'
        },
        widthMap: {
          category: '600px',
          course: '800px'
        },
        showDate: [],
        imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        form: {
          id: undefined,
          title: undefined,
          category: undefined,
          content: '',
          tag: undefined,
          coverImg: '',
          showStartTime: undefined,
          showEndTime: undefined,
          sort: 0,
          createTime: undefined,
          createUser: undefined,
          updateTime: undefined,
          updateUser: undefined,
          relateCourses: undefined,
        },
        pageData: {
          rows: [],
        },
        rules: {
          title: [{ required: true, message: " ", trigger: "change" }],
          category: [{ required: true, message: " ", trigger: "change" }],
          tag: [{ required: true, message: " ", trigger: "change" }],
          showStartTime: [{ required: true, message: " ", trigger: "change" }],
          coverImg: [{ required: false, message: " ", trigger: "change" }],
          sort: [{ required: true, message: " ", trigger: "change" }],
        }
      },
      jumpVal: undefined,
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      currentRow: {},
      options: {
        category: [],
        defaultRelate: [],
        relate: [],
        roles: []
      },
      editCategory: {
        visible: false,
        form: {
          dictKey: undefined,
          dictLabel: undefined,
          authType: 0,
          roleKeys: [],
        }
      }
    };
  },
  computed: {
    addShow() {
      return this.editDialog.pageData.rows.filter(e => e.dictKey === '').length === 0;
    }
  },
  watch: {
  },
  created() {
    this.getCategoryRoleList();
    this.getCategoryList();
  },
  mounted() {
    this.handleFilter();
    this.handleGetRelateTitle();
  },
  methods: {
    handleCopy(row) {
      let url = '/tutorial/list?active=' + row.dictKey;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      Notification.success("复制链接成功！");
      oInput.remove()
    },
    saveCategory() {
      saveCourseCategory(this.editCategory.form).then(res => {
        if(res && res.code === 200) {
          Notification.success("操作成功！");
          this.getCategoryList();
          this.handleQuery();
        }
        this.editCategory.visible = false;
      })
    },
    handleRoleChange() {
      this.editCategory.form.authType = 1;
    },
    openEditCategory(row) {
      if (row) {
        this.editCategory.form = row;
      } else {
        this.editCategory.form = {
          dictKey: undefined,
          dictLabel: undefined,
          authType: 0,
          roleKeys: [],
        }
      }
      this.editCategory.visible = true;
    },
    handleDateChange(val) {
      if (val) {
        this.editDialog.form.showStartTime = parseTime(val[0], "{y}-{m}-{d} {h}:{i}:{s}");
        this.editDialog.form.showEndTime = parseTime(val[1], "{y}-{m}-{d} {h}:{i}:{s}");
      } else {
        this.editDialog.form.showStartTime = undefined;
        this.editDialog.form.showEndTime = undefined;
      }
    },
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      getCoursePage(parseParam(query)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              this[dict] = Object.assign({}, form)
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleImageUrls(row) {
      return row.skuUrls && row.skuUrls.length > 0? row.skuUrls.split(','): [];
    },

    async handleEdit(row, type) {
      if (type.indexOf('course') > -1) {
        this.options.relate = this.options.defaultRelate.filter(e => !row || e.id !== row.id);
        if (row) {
          this.editDialog.form = Object.assign({}, row);
          if (row.showStartTime && row.showEndTime) {
            this.editDialog.showDate = [this.editDialog.form.showStartTime, this.editDialog.form.showEndTime];
          }
          this.editDialog.form.relateCourses = this.editDialog.form.relateCourses ? this.editDialog.form.relateCourses.split(","): []
        } else {
          this.editDialog.form = {
            id: undefined,
            title: undefined,
            category: undefined,
            content: '',
            tag: undefined,
            coverImg: '',
            showStartTime: undefined,
            showEndTime: undefined,
            sort: 0,
            createTime: undefined,
            createUser: undefined,
            updateTime: undefined,
            updateUser: undefined,
            deleted: 0,
            relateCourses: undefined,
          }
          this.editDialog.showDate = [];
        }
      } else {
        this.getCategoryList();
      }
      this.editDialog.type = type;
      this.editDialog.visible = true;
    },
    getCategoryRoleList() {
      getBizRoleList({}).then(res => {
        if (!res.data) {
          return false;
        }
        this.options.roles = res.data;
      });
    },
    getCategoryList() {
      getCourseCategoryList().then(res => {
        if (res.code === 200) {
          res.data.forEach(e => {
            e.dictLabelChange = e.dictLabel;
            e.dictTheme = 'COURSE_TYPE';
          })
          this.editDialog.pageData.rows = res.data;
        }
        this.options.category = res.data;
      })
    },
    submitForm() {
      if (!this.editDialog.form.content) {
        Notification.info("教程内容不能为空!");
        return;
      }
      // if (!this.editDialog.form.coverImg) {
      //   Notification.info("头图不能为空!");
      //   return;
      // }
      this.$refs.form.validate(valiad => {
        if (valiad) {
          this.loading = true;
          this.editDialog.form.relateCourses = this.editDialog.form.relateCourses? this.editDialog.form.relateCourses.join(','): undefined;
          saveCourse(this.editDialog.form).then(res => {
            if (res.code === 200) {
              this.loading = false;
              Notification.success("操作成功!");
              this.editDialog.visible = false;
              this.handleFilter();
            }
          })
        }
      })
    },
    handleUpdateCategory(row) {
      if (!row.dictLabelChange || row.dictLabelChange === '') {
        Notification.info("分类名不能为空!");
        return;
      }
      row.dictLabel = row.dictLabelChange;
      updateAppDict(row).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.getCategoryList();
          this.editDialog.currentRow = {};
        }
      })
    },
    handleMove(row, type) {
      moveAppDict(type, row).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.getCategoryList();
        }
      })
    },
    handleDeleteCategory(row) {
      this.$confirm('请确认是否删除该分类。如删除该分类，则该分类对应的文章会归入“未分类”', '提示', { type: 'warning' }).then(() => {
        removeCourseCategory(row.dictKey).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功!");
            this.getCategoryList();
          }
        })
      }).catch(() => {
      })
    },
    handleGetRelateTitle() {
      getCoursePage('current=1&size=50').then(res => {
        this.options.defaultRelate = res.data.records;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
::v-deep .el-radio {
  display: block;
  line-height: 23px;
  white-space: normal;
  margin-right: 0;
}
</style>
