<template>
  <el-collapse v-model="activeNames">
    <el-collapse-item :disabled="options.qdNames.length > 0" title="催货配置" name="1">
      <div style="width: 50%; margin: 0 25%;">
        <el-form ref="configForm" :rules="configForm.rules" size="mini" :inline="true" label-width="160px" :model="configForm.form" class="common-form">
          <el-row>
            <el-form-item label="检查计算所需的数据源">
              <ul class="custom-list">
                <li class="green-li" v-if="checkData.jdDataUpdateTime"> 京东商智出库数据 今日更新时间 {{ checkData.jdDataUpdateTime }}</li>
                <li class="red-li" v-else>京东商智出库数据 今日数据尚未获取</li>
                <li class="green-li" v-if="checkData.jdqtDataUpdateTime"> 京东前台库存数据 今日更新时间 {{ checkData.jdqtDataUpdateTime }}</li>
                <li class="red-li" v-else>京东前台库存数据 今日数据尚未获取</li>
                <li class="green-li" v-if="checkData.kjDataUpdateTime"> 科技仓库库存数据 今日更新时间 {{ checkData.kjDataUpdateTime }}</li>
                <li class="red-li" v-else>科技仓库库存数据 今日数据尚未获取</li>
              </ul>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="目标系数是否覆盖所有商品">
              <ul>
                <li class="yellow-li" v-if="checkData.noRatioSkuCount && checkData.noRatioSkuCount>0"> 当前有 {{ checkData.noRatioSkuCount }} 个商品未维护目标系数，您可以在计算环节再次配置或调整已有目标系数</li>
                <li class="green-li" v-else>成功配置所有商品的目标系数</li>
              </ul>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="12"><el-form-item label="渠道" prop="qdName">
              <el-select v-model="configForm.form.qdName" @change="handleCheckData">
                <el-option value="天猫" label="天猫" />
                <el-option value="京东" label="京东" />
              </el-select>
            </el-form-item></el-col>
            <el-col v-if="configForm.form.qdName === '京东'" :span="12">
              <el-form-item label="京东待导入单据" label-width="140px">
                <el-upload
                  ref="goodsUpload"
                  class="upload-demo"
                  :limit="1"
                  action="#"
                  :auto-upload="false"
                  :file-list="configForm.file.jd.fileList"
                  :on-change="(file, fileList) => handleFileChange(file, 'jd', fileList, 'change')"
                  :on-remove="(file, fileList) => handleFileChange(file, 'jd', fileList, 'remove')"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div class="el-upload__tip" slot="tip">
                    <span>仅允许导入xls、xlsx格式文件。</span>
                    <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="configForm.file.jd.template">下载模板</el-link>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="仓库" prop="ckNames">
              <el-select v-model="configForm.form.ckNames" multiple @change="handleCheckData">
                <el-option v-for="item in options.ckNames.filter(e => configForm.form.qdName=='天猫' || e !=='华中集库')" :label="item" :value="item" :key="item" />
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="预计销量" prop="expectNum">近6月月均销量<el-input-number v-model="configForm.form.expectNum" style="width: 50px" :min="0" :controls="false" :step="0" />倍</el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="覆盖目标系数">
              <el-upload
                ref="goodsUpload"
                class="upload-demo"
                :limit="1"
                action="#"
                :auto-upload="false"
                :file-list="configForm.file.ratio.fileList"
                :on-change="(file, fileList) => handleFileChange(file, 'ratio', fileList, 'change')"
                :on-remove="(file, fileList) => handleFileChange(file, 'ratio', fileList, 'remove')"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  <span>仅允许导入xls、xlsx格式文件。</span>
                  <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" @click="handleExportCurrent('ratio')">下载当前系数模板</el-link>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="重点品催单">
              <el-upload
                ref="goodsUpload"
                class="upload-demo"
                :limit="1"
                action="#"
                :auto-upload="false"
                :file-list="configForm.file.require.fileList"
                :on-change="(file, fileList) => handleFileChange(file, 'require', fileList, 'change')"
                :on-remove="(file, fileList) => handleFileChange(file, 'require', fileList, 'remove')"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  <span>仅允许导入xls、xlsx格式文件。</span>
                  <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" @click="handleExportCurrent('require')">下载当前数据模板</el-link>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="新品催单">
              <el-upload
                ref="goodsUpload"
                class="upload-demo"
                :limit="1"
                action="#"
                :auto-upload="false"
                :file-list="configForm.file.news.fileList"
                :on-change="(file, fileList) => handleFileChange(file, 'news', fileList, 'change')"
                :on-remove="(file, fileList) => handleFileChange(file, 'news', fileList, 'remove')"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  <span>仅允许导入xls、xlsx格式文件。</span>
                  <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" @click="handleExportCurrent('news')">下载当前数据模板</el-link>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="人工月均销量">
              <el-upload
                ref="goodsUpload"
                class="upload-demo"
                :limit="1"
                action="#"
                :auto-upload="false"
                :file-list="configForm.file.sale.fileList"
                :on-change="(file, fileList) => handleFileChange(file, 'sale', fileList, 'change')"
                :on-remove="(file, fileList) => handleFileChange(file, 'sale', fileList, 'remove')"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  <span>仅允许导入xls、xlsx格式文件。</span>
                  <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" @click="handleExportCurrent('sale')">下载当前数据模板</el-link>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="导入催货标签">
              <el-upload
                ref="goodsUpload"
                class="upload-demo"
                :limit="1"
                action="#"
                :auto-upload="false"
                :file-list="configForm.file.label.fileList"
                :on-change="(file, fileList) => handleFileChange(file, 'label', fileList, 'change')"
                :on-remove="(file, fileList) => handleFileChange(file, 'label', fileList, 'remove')"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  <span>仅允许导入xls、xlsx格式文件。</span>
                  <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" @click="handleExportCurrent('label')">下载当前催货标签模板</el-link>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button size="mini" @click="editDialog.visible = false">取 消</el-button>
          <el-button size="mini" type="primary" @click="handleImport">确 定</el-button>
        </div>
      </div>
    </el-collapse-item>
    <el-collapse-item :disabled="options.qdNames.length == 0" title="计算催货数据系统计算催货数据，品类管理部订单员可以根据结果数据进行批注及调整" name="2">
      <div style="padding: 0px 20px">
        <div class="mg-search" style="padding-bottom: 15px">
          <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
            <el-form-item v-show="options.qdNames.length > 1" label="渠道">
              <DictSelect :value.sync="queryForm.qdName" singleProps init :defaultIndex="0" :init-options="options.qdNames"/>
            </el-form-item>
            <el-form-item v-if="queryForm.qdName==='京东'" label="电商平台编码">
              <el-input v-model="queryForm.jdSkuCode" clearable />
            </el-form-item>
            <el-form-item label="DRP编码">
              <el-input v-model="queryForm.skuCode" clearable />
            </el-form-item>
            <el-form-item label="SKU货号">
              <el-input v-model="queryForm.ecSkuCode" clearable />
            </el-form-item>
            <el-form-item label="料号">
              <el-input v-model="queryForm.materialCode" clearable />
            </el-form-item>
            <el-form-item label="电商等级">
              <DictSelect ref="md-type" :value.sync="queryForm.jdLevel" singleProps init :init-options="options.jdLevels"/>
            </el-form-item>
            <el-form-item label="科技等级">
              <DictSelect ref="shop-type" :value.sync="queryForm.ecLevel" singleProps init :init-options="options.ecLevels"/>
            </el-form-item>
            <el-form-item label="总部等级">
              <DictSelect ref="md-type" :value.sync="queryForm.hqLevel" singleProps init :init-options="options.hqLevels"/>
            </el-form-item>
            <el-form-item label="赛道">
              <DictSelect ref="md-type" :value.sync="queryForm.raceName" singleProps init :init-options="options.races"/>
            </el-form-item>
            <el-form-item label="大类">
              <el-select v-model="queryForm.mainCategoryName" @change="(val) => handleChangeDict('mid', val)" clearable>
                <el-option v-for="item in options.main" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="中类">
              <el-select v-model="queryForm.midCategoryName" @change="(val) => handleChangeDict('sub', val)" clearable>
                <el-option v-for="item in options.mid" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="小类">
              <el-select v-model="queryForm.subCategoryName" clearable>
                <el-option v-for="item in options.sub" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
            <el-form-item label="是否混色">
              <el-select v-model="queryForm.ifMixtz" clearable>
                <el-option label="是" value="1" />
                <el-option label="否" value="0" />
              </el-select>
            </el-form-item>
            <el-form-item label="重点品">
              <el-select v-model="queryForm.ifRequire" clearable>
                <el-option label="是" value="是" />
                <el-option label="否" value="否" />
              </el-select>
            </el-form-item>
            <el-form-item label="催货标记">
              <el-select v-model="queryForm.hastenLabel" clearable>
                <el-option label="华北不催" value="华北不催" />
                <el-option label="华南不催" value="华南不催" />
                <el-option label="华东不催" value="华东不催" />
              </el-select>
            </el-form-item>
            <el-form-item label="过滤无需催货">
              <el-select v-model="queryForm.filterChTag" clearable>
                <el-option label="是" value="是" />
              </el-select>
            </el-form-item>
            <el-form-item label="是否新品">
              <el-select v-model="queryForm.isNewProduct" clearable>
                <el-option label="是" value="是" />
                <el-option label="否" value="否" />
              </el-select>
            </el-form-item>
            <el-form-item style="width: 100%">
              <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
              <el-button size="mini" type="primary" @click="handleFilterColumns">列编辑</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-edit" type="primary" @click="handleBatchDialog">批量调整</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleCompleteExport">确认完成计算</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="handleReset">重新开始</el-button>
              <span style="margin-left: 20px">当前正在进行 <strong>{{ options.qdNames.join('、') }}</strong> 渠道，<strong>{{ options.currentCkNames.join('、') }}</strong>的催货操作，月均系数为 <strong>{{ currentInfo.expectNum }}</strong> 倍，任务启动时间为 <strong>{{ currentInfo.latestTime }}</strong> 分</span>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="pageData.rows"
          height="calc(100vh - 250px)"
          v-loading="loading"
          size="mini"
          border
          ref="tableRef"
          highlight-current-row
          :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
          align="center"
        >
          <el-table-column type="selection" width="90" />
          <el-table-column label="电商平台编码" prop="jdSkuCode" width="100" align="center" fixed="left" />
          <el-table-column label="DRP编码" prop="skuCode" width="100" align="center" fixed="left" />
          <el-table-column label="料号" prop="materialCode" width="100" align="center" fixed="left" />
          <el-table-column label="SKU货号" prop="ecSkuCode" width="100" align="center" fixed="left" />
          <el-table-column label="商品名称" prop="skuName" width="180" align="center" fixed="left" />
          <el-table-column label="新品" prop="isNewProduct" width="100" align="center" />
          <template v-for="item in hastenDynamicColumns">
            <el-table-column
              v-if="item.show || checkedColumns.indexOf(item.filterLabel) > -1 || checkedColumns.indexOf(item.label) > -1"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :width="item.width? item.width: '100px'"
              show-overflow-tooltip
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row[item.prop] }}</span>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="近6月合计月均" prop="tradeGoodsCountAvgTotal" width="100" align="center" />
          <el-table-column prop="provinceName" width="160" align="center">
            <template slot="header">
              科技库存<br>(可发+在途+待导入+待调拨)<br>目标系数
            </template>
            <template #default="{ row }">
              <span>{{ row.kcTotal }}</span><br>
              <span>({{ row.kfTotal }} + {{ row.ztTotal }} + {{  row.prepareLoadTotal}} + {{ row.prepareTransferOutJk + row.prepareTransferOutHn + row.prepareTransferOutHb }})</span><br>
              <span>{{ row.kjRatio?row.kjRatio:0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="provinceName" width="160" align="center">
            <template slot="header">
              <div style="background-color: #e67488">华东库存<br>(可发+在途+待导入+待调拨)<br>目标系数</div>
            </template>
            <template #default="{ row }">
              <span>{{ row.kcJk }}</span><br>
              <span>({{ row.kfJk }} + {{ row.ztJk }} + {{  row.prepareLoadJk}} + {{ row.prepareTransferOutJk }})</span><br>
              <span>{{ row.hdRatio?row.hdRatio:0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="provinceName" width="160" align="center">
            <template slot="header">
              <div style="background-color: #e67488">华南库存<br>(可发+在途+待导入+待调拨)<br>目标系数</div>
            </template>
            <template #default="{ row }">
              <span>{{ row.kcHn }}</span><br>
              <span>({{ row.kfHn }} + {{ row.ztHn }} + {{  row.prepareLoadHn }} + {{ row.prepareTransferOutHn }})</span><br>
              <span>{{ row.hnRatio?row.hnRatio:0 }}</span><br>
            </template>
          </el-table-column>
          <el-table-column prop="provinceName" width="160" align="center">
            <template slot="header">
              <div style="background-color: #e67488">华北库存<br>(可发+在途+待导入+待调拨)<br>目标系数</div>
            </template>
            <template #default="{ row }">
              <span>{{ row.kcHb }}</span><br>
              <span>({{ row.kfHb }} + {{ row.ztHb }} + {{  row.prepareLoadHb}} + {{ row.prepareTransferOutHb }})</span><br>
              <span>{{ row.hbRatio?row.hbRatio:0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="provinceName" width="160" align="center">
            <template slot="header">
              <div style="background-color: #e67488">华中库存<br>(可发+在途+待导入+待调拨)<br>目标系数</div>
            </template>
            <template #default="{ row }">
              <span>{{ row.kcHz }}</span><br>
              <span>({{ row.kfHz }} + {{ row.ztHz }} + {{  row.prepareLoadHz}} + {{ row.prepareTransferOutHz }})</span><br>
              <span>{{ row.hzRatio?row.hzRatio:0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="hastenCountJk1" width="140" align="center">
            <template slot="header">
              <div :style="options.currentCkNames.indexOf('华东集库') < 0?{'background-color': 'gray'}: {}">&nbsp;<br>华东催货箱数<br>&nbsp;</div>
            </template>
            <template #default="{ row }">
              <span v-if="row.tags.indexOf('华东不催') > -1">{{ row.hastenCountJk1 }}</span>
              <el-input-number v-else v-model="row.hastenCountJk1" style="width: 100px" :controls="false" :min="0" :disabled="options.currentCkNames.indexOf('华东集库') < 0" size="mini" @change="handleChangeRow(row)" />
            </template>
          </el-table-column>
          <el-table-column prop="hastenCountJk1" width="140" align="center">
            <template slot="header">
              <div :style="options.currentCkNames.indexOf('华南集库') < 0?{'background-color': 'gray'}: {}">&nbsp;<br>华南催货箱数<br>&nbsp;</div>
            </template>
            <template #default="{ row }">
              <span v-if="row.tags.indexOf('华南不催') > -1">{{ row.hastenCountHn1 }}</span>
              <el-input-number v-else v-model="row.hastenCountHn1" style="width: 100px" :controls="false" :min="0" :disabled="options.currentCkNames.indexOf('华南集库') < 0" size="mini" @change="handleChangeRow(row)" />
            </template>
          </el-table-column>
          <el-table-column prop="hastenCountJk1" width="140" align="center">
            <template slot="header">
              <div :style="options.currentCkNames.indexOf('华北集库') < 0?{'background-color': 'gray'}: {}">&nbsp;<br>华北催货箱数<br>&nbsp;</div>
            </template>
            <template #default="{ row }">
              <span v-if="row.tags.indexOf('华北不催') > -1">{{ row.hastenCountHb1 }}</span>
              <el-input-number v-else v-model="row.hastenCountHb1" style="width: 100px" :controls="false" :min="0" :disabled="options.currentCkNames.indexOf('华北集库') < 0" size="mini" @change="handleChangeRow(row)" />
            </template>
          </el-table-column>
          <el-table-column prop="hastenCountJk1" width="140" align="center">
            <template slot="header">
              <div :style="options.currentCkNames.indexOf('华中集库') < 0?{'background-color': 'gray'}: {}">&nbsp;<br>华中催货箱数<br>&nbsp;</div>
            </template>
            <template #default="{ row }">
              <span v-if="row.tags.indexOf('华中不催') > -1">{{ row.hastenCountHz1 }}</span>
              <el-input-number v-else v-model="row.hastenCountHz1" style="width: 100px" :controls="false" :min="0" :disabled="options.currentCkNames.indexOf('华中集库') < 0" size="mini" @change="handleChangeRow(row)" />
            </template>
          </el-table-column>
          <el-table-column label="催货打标" prop="hastenLabel" width="120" align="center">
            <template #default="{ row }">
              <el-select v-model="row.tags" multiple size="mini" @change="handleChangeRow(row)">
                <el-option v-for="item in options.tags.filter(e => queryForm.qdName=='天猫' || e.value !=='华中不催')" :key="item.value" :value="item.value" :label="item.label" :disabled="item.disabled" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" width="180" align="center">
            <template #default="{ row }">
              <el-input v-model="row.remark" type="textarea" />
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

        <el-dialog title="操作提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
          <div v-html="noticeDialog.msg" style="font-size: 16px;overflow-y: scroll;height: 400px">
          </div>
          <div slot="footer" class="dialog-footer" style="text-align: right">
            <el-button v-if="noticeDialog.data && noticeDialog.data.length >0" type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
            <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="列编辑" :visible.sync="dialog.filterColumnVisible" width="700px">
          <div style="width: 25%; position: relative; float: right; right: 60%; top: -50px;">
            <el-button size="mini" type="primary" @click="handleCheckAll(false)">清空</el-button>
            <el-button size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
          </div>
          <div style="margin-bottom: 10px; color: gray;"><span>说明：页面表头指标 展示顺序同指标添加先后顺序</span></div>
          <div v-for="(item, key, index) in checkedCopy.hastenFilterColumns" :key="index" style="margin-bottom: 15px">
            <span style="margin-right: 10px; font-weight: 600">{{ item.label }}</span>
            <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                         @change="handleCheckAllChange($event, key, item.label)">
              全选
            </el-checkbox>
            <div style="margin: 10px 0"></div>
            <el-checkbox-group v-model="item.checked" @change="handleCheckedChange($event, key, item.label)">
              <el-checkbox v-for="option in item.list" :label="option" :key="option" :style="item.label==='库存数据'? 'width: 40% ': 'width: 20% '">{{option}}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button size="mini" @click="dialog.filterColumnVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="handleConfirmChecked">确 定</el-button>
          </div>
        </el-dialog>

        <el-dialog title="批量调整" :visible.sync="editDialog.visible" width="700px">
          <el-form ref="form" size="mini" :inline="true" :model="editDialog.form" class="common-form" label-width="120px">
            <el-row>
              <el-row>催货目标系数调整</el-row>
              <el-col :span="8"><el-form-item label="华东"><el-input-number v-model="editDialog.form.hdRatio" :max="1" :min="0" :controls="false" style="width: 100px" /></el-form-item></el-col>
              <el-col :span="8"><el-form-item label="华南"><el-input-number v-model="editDialog.form.hnRatio" :max="1" :min="0" :controls="false" style="width: 100px" /></el-form-item></el-col>
              <el-col :span="8"><el-form-item label="华北"><el-input-number v-model="editDialog.form.hbRatio" :max="1" :min="0" :controls="false" style="width: 100px" /></el-form-item></el-col>
              <el-col :span="8"><el-form-item label="华中"><el-input-number v-model="editDialog.form.hzRatio" :max="1" :min="0" :controls="false" style="width: 100px" /></el-form-item></el-col>

            </el-row>
            <el-row>
              <el-row>催货箱数调整</el-row>
              <el-row>
                <el-col :span="8"><el-form-item label="华东"><el-input-number v-model="editDialog.form.hastenBoxHd" :controls="false" :step="0" :disabled="options.currentCkNames.indexOf('华东集库') < 0" style="width: 100px" /></el-form-item></el-col>
                <el-col :span="8"><el-form-item label="华南"><el-input-number v-model="editDialog.form.hastenBoxHn" :controls="false" :step="0" :disabled="options.currentCkNames.indexOf('华南集库') < 0" style="width: 100px" /></el-form-item></el-col>
                <el-col :span="8"><el-form-item label="华北"><el-input-number v-model="editDialog.form.hastenBoxHb" :controls="false" :step="0" :disabled="options.currentCkNames.indexOf('华北集库') < 0" style="width: 100px" /></el-form-item></el-col>
                <el-col :span="8"><el-form-item label="华中"><el-input-number v-model="editDialog.form.hastenBoxHz" :controls="false" :step="0" :disabled="options.currentCkNames.indexOf('华中集库') < 0" style="width: 100px" /></el-form-item></el-col>
              </el-row>
            </el-row>
            <el-row>
              <el-row>标记及备注调整</el-row>
              <el-row>
                <el-form-item label="催单打标">
                  <el-select v-model="editDialog.form.tags" clearable multiple style="width: 200px">
                    <el-option v-for="item in options.tags.filter(e => editDialog.form.qdName=='天猫' || e.value !=='华中不催')" :label="item.label" :value="item.value" :key="item.value" :disabled="item.disabled" />
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="备注"><el-input v-model="editDialog.form.remark" type="textarea" /></el-form-item>
              </el-row>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button @click="editDialog.visible = false">取 消</el-button>
            <el-button type="primary" @click="handleBathUpdate">确 定</el-button>
          </div>
        </el-dialog>

      </div>
    </el-collapse-item>
  </el-collapse>

</template>
<script>
import Pagination from "@/components/Pagination";
import { pageHasten, getBaseOptions, getCategoryOptions, importHasten, confirmHandleHasten, batchHandleHasten, exportHasten, exportCompleteHasten, checkData, runDayData, exportCurrent, resetData, saveSingle } from "@/api/eCommerce";
import { parseTime, excelExport, deepClone } from "@/utils"
import { hastenFilterColumns, hastenDynamicColumns} from "../config";
import {MessageBox, Notification} from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "TopShop",
  components: { Pagination, DictSelect},
  props: {
    queryTop: {
      type: String,
    }
  },
  data() {
    return {
      activeNames: [],
      tabName: 'after',
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        qdName: undefined,
        jdSkuCode: undefined,
        skuCode: undefined,
        ecSkuCode: undefined,
        materialCode: undefined,
        jdLevel: undefined,
        hqLevel: undefined,
        ecLevel: undefined,
        raceName: undefined,
        mainCategoryName: undefined,
        midCategoryName: undefined,
        subCategoryName: undefined,
        ifMixtz: undefined,
        ifRequire: undefined,
        hastenLabel: undefined,
        filterChTag: '是',
        isNewProduct: undefined,
      },
      loading: false,
      dictParam: {},
      formDictParam: {},
      checkData: {
        jdDataUpdateTime: undefined,
        kjDataUpdateTime: undefined,
        jdqtDataUpdateTime: undefined,
        noRatioSkuCount: undefined
      },
      configForm: {
        rules:{
          qdName: [{ required: true, message: ' ', trigger: 'change' },],
          ckNames: [{ type: 'array', required: true, message: ' ', trigger: 'change' },],
          expectNum: [{ required: true, message: ' ', trigger: 'change' },],
        },
        form: {
          qdName: undefined,
          ckNames: undefined,
          expectNum: undefined,
        },
        file: {
          jd: {
            fileList: [],
            template:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E7%A7%91%E6%8A%80%E5%82%AC%E8%B4%A7-%E4%BA%AC%E4%B8%9C%E5%BE%85%E5%AF%BC%E5%85%A5%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx',
            accept: ['.xls', '.xlsx'],
          },
          label: {
            fileList: [],
            template: '',
            accept: ['.xls', '.xlsx'],
          },
          require: {
            fileList: [],
            template: '',
            accept: ['.xls', '.xlsx'],
          },
          ratio: {
            fileList: [],
            template: '',
            accept: ['.xls', '.xlsx'],
          },
          news: {
            fileList: [],
            template: '',
            accept: ['.xls', '.xlsx'],
          },
          sale: {
            fileList: [],
            template: '',
            accept: ['.xls', '.xlsx'],
          },
        },
        checkData: {
          jdDataUpdateTime: undefined,
          kjDataUpdateTime: undefined,
          noRatioSkuCount: undefined
        }
      },
      editDialog: {
        visible: false,
        form: {
          qdName: undefined,
          ecSkuCodes: [],
          hdRatio: undefined,
          hbRatio: undefined,
          hnRatio: undefined,
          hzRatio: undefined,
          hastenBoxHd: undefined,
          hastenBoxHn: undefined,
          hastenBoxHb: undefined,
          hastenBoxHz: undefined,
          tags: undefined,
          remark: undefined
        }
      },
      dialog: {
        filterColumnVisible: false,
      },
      checkedCopy: {
        hastenFilterColumns: undefined,
        checked: undefined,
      },
      checkedColumns: [],
      hastenFilterColumns,hastenDynamicColumns,
      editFormDictParam: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
        data: undefined
      },
      currentRow: {},
      currentInfo: {
        expectNum: undefined,
        latestTime: undefined
      },
      options: {
        qdNames: [],
        races: [],
        ecLevels: [],
        jdLevels: [],
        hqLevels: [],
        main: [],
        mid: [],
        sub: [],
        tags: [{value:'华东不催', label: '华东不催'},{value:'华南不催', label: '华南不催'},{value:'华北不催', label: '华北不催'},{value:'华中不催', label: '华中不催'}],
        ckNames: ['华东集库', '华南集库', '华北集库', '华中集库'],
        currentCkNames: []
      },
      exportHeaders: {
        jd: {
          ckName: "仓库名称",
          ecSkuCode: "科技商品编码",
          prepareLoadQty: '换算后数量',
          errorMsg: '校验信息'
        },
        ratio: {
          qdName: "渠道名称",
          ckName: "仓库名称",
          ecSkuCode: "科技商品编码",
          cdCoefficient: '目标系数',
          errorMsg: '校验信息'
        },
        label: {
          qdName: "渠道名称",
          ckName: "仓库名称",
          ecSkuCode: "科技商品编码",
          hastenLabel: '催货标签',
          errorMsg: '校验信息'
        },
        require: {
          qdName: "渠道名称",
          ecSkuCode: "科技商品编码",
          importantSkuRequirement: '供货需求量',
          errorMsg: '校验信息'
        },
        news: {
          qdName: "渠道名称",
          ecSkuCode: "科技商品编码",
          newSkuRequirement: '新品需求量',
          errorMsg: '校验信息'
        },
        sales: {
          qdName: "渠道名称",
          ecSkuCode: "科技商品编码",
          avgSaleCountByPerson: '人工月均销量',
          errorMsg: '校验信息'
        }
      }
    };
  },
  watch: {
  },
  created() {
    this.handleBase();
    this.handleCheckData();
    this.handleChangeDict('main');
  },
  mounted() {

  },
  methods: {
    handleCheckData() {
      // if (!this.configForm.qdName || !this.configForm.ckNames) {
      //   return;
      // }
      checkData(this.configForm).then(res => {
        this.checkData = res.data;
      })
    },
    handleBase() {
      getBaseOptions().then(res => {
        this.options.qdNames = res.data.qdNames;
        this.options.currentCkNames = res.data.ckNames;
        this.options.races = res.data.races;
        this.options.ecLevels = res.data.ecLevels;
        this.options.jdLevels = res.data.jdLevels;
        this.options.hqLevels = res.data.hqLevels;

        this.currentInfo.latestTime = res.data.latestTime;
        this.currentInfo.expectNum = res.data.expectNum;
        this.filterTags();
        if (this.options.qdNames.length > 0) {
          this.queryForm.qdName = this.options.qdNames[0];
          this.activeNames = ['2'];
          this.$forceUpdate();
          this.handleFilter();
        } else {
          this.activeNames = ['1'];
        }
      })
    },
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      pageHasten(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        res.data.records.forEach(r => {
          r.tags = r.hastenLabel.split(',').filter(d => d)
        })
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.tableRef.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        jdSkuCode: undefined,
        skuCode: undefined,
        ecSkuCode: undefined,
        materialCode: undefined,
        jdLevel: undefined,
        hqLevel: undefined,
        ecLevel: undefined,
        raceName: undefined,
        mainCategoryName: undefined,
        middleCategoryName: undefined,
        subCategoryName: undefined,
        ifMixtz: undefined,
        ifRequire: undefined,
        hastenLabel: undefined,
        filterChTag: '是',
        qdName: this.queryForm.qdName,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleChangeDict(type) {
      if (type =='main') {
        this.queryForm.midCategoryName = undefined;
        this.queryForm.subCategoryName = undefined;
      } else if (type == 'mid') {
        this.queryForm.subCategoryName = undefined;
      }
      getCategoryOptions(type, this.queryForm).then(res => {
        this.options[type] = res.data;
      })
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleFilterColumns() {
      this.checkedCopy = {
        hastenFilterColumns: deepClone(this.hastenFilterColumns),
        checkedColumns: Object.assign([], this.checkedColumns)
      };
      this.$forceUpdate();
      this.dialog.filterColumnVisible = true;
    },
    handleConfirmChecked() {
      this.hastenFilterColumns = this.checkedCopy.hastenFilterColumns;
      this.checkedColumns = this.checkedCopy.checkedColumns;
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
      this.dialog.filterColumnVisible = false;
    },
    handleCheckAllChange(val, key, label) {
      this.checkedCopy.hastenFilterColumns[key].checked = val ? this.checkedCopy.hastenFilterColumns[key].list : [];
      const spliceIndex = -1;
      this.checkedCopy.hastenFilterColumns[key].checkedBefore = [];
      this.checkedCopy.hastenFilterColumns[key].list.forEach(e => {
        // 全选
        if (val && this.checkedCopy.checkedColumns.indexOf(e) < 0) {
          this.checkedCopy.checkedColumns.splice(spliceIndex, 0, e);
        } else if (!val && this.checkedCopy.checkedColumns.indexOf(e) > -1) {
          const index = this.checkedCopy.checkedColumns.indexOf(e);
          this.checkedCopy.checkedColumns.splice(index, 1);
        }
      })
      this.checkedCopy.hastenFilterColumns[key].checkedBefore =  this.checkedCopy.hastenFilterColumns[key].checked;
      this.checkedCopy.hastenFilterColumns[key].isIndeterminate = false;
    },
    handleCheckedChange(value, key, label) {
      const checkedCount = value.length;
      this.checkedCopy.hastenFilterColumns[key].checkAll = checkedCount === this.checkedCopy.hastenFilterColumns[key].list.length;
      this.checkedCopy.hastenFilterColumns[key].isIndeterminate = checkedCount > 0 && checkedCount < this.checkedCopy.hastenFilterColumns[key].list.length;
      const spliceIndex = -1;
      // 选中
      if (value.length > this.checkedCopy.hastenFilterColumns[key].checkedBefore.length) {
        this.checkedCopy.checkedColumns.splice(spliceIndex, 0, value[value.length - 1]);
      } else if (value.length < this.checkedCopy.hastenFilterColumns[key].checkedBefore.length) {
        // 取消选中
        this.checkedCopy.hastenFilterColumns[key].checkedBefore.forEach(e => {
          if (value.indexOf(e) < 0) {
            // 获取取消项
            const index = this.checkedCopy.checkedColumns.indexOf(e);
            this.checkedCopy.checkedColumns.splice(index, 1);
          }
        })
      }
      this.checkedCopy.hastenFilterColumns[key].checkedBefore = value;
    },
    handleCheckAll(checked) {
      for (let key in this.checkedCopy.hastenFilterColumns) {
        this.checkedCopy.hastenFilterColumns[key].isIndeterminate = checked;
        this.checkedCopy.hastenFilterColumns[key].checkAll = checked;
        this.handleCheckAllChange(checked, key, this.checkedCopy.hastenFilterColumns[key].label);
      }
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImport() {
      this.$refs.configForm.validate((valid) => {
        if (valid) {
          let fd = new FormData();
          fd.append("qdName", this.configForm.form.qdName);
          fd.append("ckNames", this.configForm.form.ckNames);
          fd.append("expectNum", this.configForm.form.expectNum);
          for (let fileKey in this.configForm.file) {
            this.configForm.file[fileKey].fileList.forEach(item=>{
              //文件信息中raw才是真的文件
              fd.append(fileKey + "File",item.raw);
            })
          }
          importHasten(fd).then(async res => {
            if (res.code === 200) {
              if (res.data) {
                let msg = []
                if (res.data.hastenPrepareLoads && res.data.hastenPrepareLoads.length > 0) {
                  let exportFlag = false;
                  res.data.hastenPrepareLoads.forEach(e => {
                    if (e.errorMsg) {
                      exportFlag = true;
                    }
                  })
                  if (exportFlag) {
                    msg.push("京东待导入单据");
                    excelExport({
                      topHeaders: this.exportHeaders.jd,
                      data: res.data.hastenPrepareLoads,
                      title: "京东待导入单据数据错误",
                    });
                  }
                }
                if (res.data.hastenCoefficients && res.data.hastenCoefficients.length > 0) {
                  let exportFlag = false;
                  res.data.hastenCoefficients.forEach(e => {
                    if (e.errorMsg) {
                      exportFlag = true;
                    }
                  })
                  if (exportFlag) {
                    msg.push("目标系数");
                    excelExport({
                      topHeaders: this.exportHeaders.ratio,
                      data: res.data.hastenCoefficients,
                      title: "目标系数数据错误",
                    });
                  }
                }
                if (res.data.hastenImportantRequirements && res.data.hastenImportantRequirements.length > 0) {
                  let exportFlag = false;
                  res.data.hastenImportantRequirements.forEach(e => {
                    if (e.errorMsg) {
                      exportFlag = true;
                    }
                  })
                  if (exportFlag) {
                    msg.push("重点品");
                    excelExport({
                      topHeaders: this.exportHeaders.require,
                      data: res.data.hastenImportantRequirements,
                      title: "重点品数据错误",
                    });
                  }
                }
                if (res.data.hastenLabels && res.data.hastenLabels.length > 0) {
                  let exportFlag = false;
                  res.data.hastenLabels.forEach(e => {
                    if (e.errorMsg) {
                      exportFlag = true;
                    }
                  })
                  if (exportFlag) {
                    msg.push("催货标签");
                    excelExport({
                      topHeaders: this.exportHeaders.label,
                      data: res.data.hastenLabels,
                      title: "催货标签导入数据错误",
                    });
                  }
                }
                if (res.data.hastenNewRequirements && res.data.hastenNewRequirements.length > 0) {
                  let exportFlag = false;
                  res.data.hastenNewRequirements.forEach(e => {
                    if (e.errorMsg) {
                      exportFlag = true;
                    }
                  })
                  if (exportFlag) {
                    msg.push("新品需求量");
                    excelExport({
                      topHeaders: this.exportHeaders.news,
                      data: res.data.hastenNewRequirements,
                      title: "新品需求量导入数据错误",
                    });
                  }
                }
                if (res.data.sales && res.data.sales.length > 0) {
                  let exportFlag = false;
                  res.data.sales.forEach(e => {
                    if (e.errorMsg) {
                      exportFlag = true;
                    }
                  })
                  if (exportFlag) {
                    msg.push("人工月均销量");
                    excelExport({
                      topHeaders: this.exportHeaders.sales,
                      data: res.data.sales,
                      title: "人工月均销量导入数据错误",
                    });
                  }
                }
                if (msg.length > 0) {
                  MessageBox.alert(msg.join("、") + "导入失败已自动导出，请修改后重新导入", '提示', {
                    dangerouslyUseHTMLString: true
                  });
                  return;
                }

                if (res.data.msg) {
                  Notification.warning(res.data.msg);
                  return;
                }

                if (res.data && res.data.confirmMsg) {
                  this.$confirm(res.data.confirmMsg, '提示', {
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                  }).then(() => {
                    res.data.confirm = true;
                    confirmHandleHasten(res.data).then(async (res) => {
                      if (res.code == 200) {
                        Notification.success("操作成功！");
                        await this.handleBase();
                        this.handleCheckData();
                        this.activeNames = ['2'];
                      }
                    })
                  }).catch(() => {
                  })
                }
              } else {
                if (res.code == 200) {
                  Notification.success("操作成功！");
                  await this.handleBase();
                  this.handleCheckData();
                  this.activeNames = ['2'];
                }
              }
            }
          })
        }
      })
    },
    handleBatchDialog() {
      if (this.pageData.selections.length === 0) {
        Notification.info("请至少选择一条数据！");
        return;
      }
      this.editDialog.form = {
        qdName: undefined,
        ecSkuCodes: [],
        hdRatio: undefined,
        hbRatio: undefined,
        hnRatio: undefined,
        hastenBoxHd: undefined,
        hastenBoxHn: undefined,
        hastenBoxHb: undefined,
        tags: undefined,
        remark: undefined
      };
      this.editDialog.form.ecSkuCodes = this.pageData.selections.map(e => e.ecSkuCode);
      this.editDialog.form.qdName = this.pageData.selections[0].qdName;
      this.editDialog.visible = true;
    },
    handleBathUpdate() {
      batchHandleHasten(this.editDialog.form).then((res) => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.handleFilter();
          this.editDialog.visible = false;
        }
      })
    },
    handleFileChange(file, type, fileList, actionType) {
      if (actionType === 'remove') {
        this.configForm.file[type].fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.configForm.file[type].accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.configForm.file[type].fileList = [];
      } else {
        this.configForm.file[type].fileList = fileList;
      }
    },
    handleExport() {
      this.loading = true;
      let param = Object.assign({}, this.queryForm, {size: 100000});
      exportHasten(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '科技催货导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCompleteExport() {
      this.$prompt('请对催货单计算结果进行命名', '确认完成计算（命名格式建议为：渠道_仓库_日期_催货）', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.queryForm.qdName + '_' + this.options.currentCkNames.join('&') + '_' + parseTime(new Date().getTime(), '{y}{m}{d}') + '_催货' ,
        center: true
      }).then(({ value }) => {
        this.loading = true;
        exportCompleteHasten(this.queryForm).then(res => {
          this.loading = false;
          if (!res) {
            return
          }
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = window.URL.createObjectURL(new Blob([res]))
          link.setAttribute('download', value + '.xlsx')
          document.body.appendChild(link)
          link.click()
          link.remove();
        })
      }).catch(() => {
      });
    },
    handleExportCurrent(type) {
      let fileName = '';
      switch (type) {
        case 'ratio':
          fileName = '当前目标系数数据'
          break;
        case 'label':
          fileName = '当前催货标签数据'
          break;
        case 'require':
          fileName = '当前重点品数据'
          break;
        case 'news':
          fileName = '当前新品数据'
          break;
        case 'sale':
          fileName = '当前人工月均销量数据'
          break;
      }
      exportCurrent({qdName: this.configForm.form.qdName, ckNames: this.configForm.form.ckNames, type: type }).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', fileName + '.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleReset() {
      resetData(this.queryForm.qdName).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.handleFilter();
          this.activeNames = ['1'];
          this.options.qdNames = [];
        }
      })
    },
    handleChangeRow(row) {
      saveSingle({ ecSkuCode: row.ecSkuCode, qdName: row.qdName, hastenCountJk1: row.hastenCountJk1, hastenCountHn1: row.hastenCountHn1, hastenCountHb1: row.hastenCountHb1,tags:row.tags, remark:row.remark })
        .then(res => {
          if (res.code === 200) {
            Notification.success("操作成功!");
            this.handleFilter();
          }
      })
    },
    filterTags() {
      this.options.tags.forEach(e => {
        e.disabled = false;
        if (e.value.indexOf('华东') > -1 && this.options.currentCkNames.indexOf("华东集库") < 0) {
          e.disabled = true;
        }
        if (e.value.indexOf('华南') > -1 && this.options.currentCkNames.indexOf("华南集库") < 0) {
          e.disabled = true;
        }
        if (e.value.indexOf('华北') > -1 && this.options.currentCkNames.indexOf("华北集库") < 0) {
          e.disabled = true;
        }
        if (e.value.indexOf('华中') > -1 && this.options.currentCkNames.indexOf("华中集库") < 0) {
          e.disabled = true;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-input__inner {
  padding-left: 5px !important;
  //max-width: 200px !important;
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}

::v-deep {
  .icon-check-class {
    color: blue;
    cursor: pointer;
    font-size: 18px !important;
  }
  .icon-close-class {
    color: gray;
    cursor: pointer;
    font-size: 18px !important;
  }
}

</style>

<style lang="scss" scoped >
::v-deep ul {
  list-style-type: disc !important; /* 移除默认的列表项符号 */
  padding: 0px 0px 0px 10px;
}

::v-deep ul.custom-list li::before {
  content: ""; /* 使用伪元素添加自定义列表项符号 */
  display: inline-block; /* 设置为内联块级元素 */
  width: 15px; /* 设置符号的宽度 */
  height: 15px; /* 设置符号的高度 */
  margin-left: -15px; /* 设置与列表项文本的对齐 */
  border-radius: 50%; /* 设置圆形 */
  margin: 0px 10px;
  //.red-li {
  //  background: red; /* 设置背景颜色 */
  //}
  //.green-li {
  //  background: green;
  //}
}

::v-deep li.red-li::marker {
    color: red;
}
::v-deep li.yellow-li::marker {
  color: yellow;
}
::v-deep li.green-li::marker {
  color: green;
}
</style>

<!--<style lang="scss" scoped src='@/style/common/index.scss'></style>-->
