var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "salesCenter-page" }, [
    _c(
      "div",
      { staticClass: "salesCenter-search" },
      [
        _c("v-signboard", { attrs: { title: "起订量调整" } }),
        _c(
          "el-form",
          {
            ref: "minimumOrderAdjust-form",
            staticClass: "main-search",
            attrs: {
              size: "mini",
              "label-width": "auto",
              inline: true,
              model: _vm.form,
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "产品等级", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", multiple: "" },
                    model: {
                      value: _vm.form.productGrade,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productGrade", $$v)
                      },
                      expression: "form.productGrade",
                    },
                  },
                  _vm._l(_vm.options.productGradeOptions, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "大类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleOptionChange($event, "max")
                      },
                    },
                    model: {
                      value: _vm.form.productFirstCategorie,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productFirstCategorie", $$v)
                      },
                      expression: "form.productFirstCategorie",
                    },
                  },
                  _vm._l(
                    _vm.options.productMaxCategorieOtions,
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "中类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleOptionChange($event, "mid")
                      },
                    },
                    model: {
                      value: _vm.form.productMiddleCategorie,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productMiddleCategorie", $$v)
                      },
                      expression: "form.productMiddleCategorie",
                    },
                  },
                  _vm._l(
                    _vm.options.productMidCategorieOtions,
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "小类", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handleOptionChange($event, "min")
                      },
                    },
                    model: {
                      value: _vm.form.productLittleCategorie,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "productLittleCategorie", $$v)
                      },
                      expression: "form.productLittleCategorie",
                    },
                  },
                  _vm._l(
                    _vm.options.productMinCategorieOtions,
                    function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }
                  ),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "生产类型", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.prod_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "prod_type", $$v)
                      },
                      expression: "form.prod_type",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "生产中心", value: "生产中心" },
                    }),
                    _c("el-option", {
                      attrs: { label: "OEM中心", value: "OEM中心" },
                    }),
                    _c("el-option", {
                      attrs: { label: "办公", value: "办公" },
                    }),
                    _c("el-option", {
                      attrs: { label: "生产中心/办公", value: "生产中心/办公" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "产品赛道", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "90px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.race_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "race_name", $$v)
                      },
                      expression: "form.race_name",
                    },
                  },
                  _vm._l(_vm.options.raceNameLists, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "原始料号", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "原始料号" },
                  model: {
                    value: _vm.form.material_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "material_code", $$v)
                    },
                    expression: "form.material_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "是否有客订", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.bo_kd_plu_count_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bo_kd_plu_count_type", $$v)
                      },
                      expression: "form.bo_kd_plu_count_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "是", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "否", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "数据类型", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.data_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "data_type", $$v)
                      },
                      expression: "form.data_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 0,
                      attrs: { label: "全部", value: 0 },
                    }),
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "理论出货低于起订", value: 1 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "置起订量类型", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.min_order_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "min_order_type", $$v)
                      },
                      expression: "form.min_order_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 0,
                      attrs: { label: "全部", value: 0 },
                    }),
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "预计下月底库存系数>3", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "预计下月底库存系数<=3", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "置零类型", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.zero_order_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "zero_order_type", $$v)
                      },
                      expression: "form.zero_order_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 0,
                      attrs: { label: "全部", value: 0 },
                    }),
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "预计下月底库存系数<0", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "预计下月底库存系数>=0", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "品名", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入品名" },
                  model: {
                    value: _vm.form.plu_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "plu_name", $$v)
                    },
                    expression: "form.plu_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "统计品类", size: "mini" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", placeholder: "请输入统计品类" },
                  model: {
                    value: _vm.form.stats_category_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "stats_category_name", $$v)
                    },
                    expression: "form.stats_category_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "预淘汰品", size: "mini" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.dead_sku_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dead_sku_type", $$v)
                      },
                      expression: "form.dead_sku_type",
                    },
                  },
                  [
                    _c("el-option", {
                      key: 0,
                      attrs: { label: "全部", value: 0 },
                    }),
                    _c("el-option", {
                      key: 1,
                      attrs: { label: "是", value: 1 },
                    }),
                    _c("el-option", {
                      key: 2,
                      attrs: { label: "否", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "form-btn-item",
                attrs: {
                  size: "mini",
                  type: "primary",
                  loading: _vm.btnStatus.isSub,
                },
                on: { click: _vm.handleSubmit },
              },
              [_vm._v("查 询 ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "form-btn-item",
                attrs: { size: "mini", loading: _vm.btnStatus.isReset },
                on: { click: _vm.handleReset },
              },
              [_vm._v("重 置 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  type: "primary",
                  disabled: _vm.btnStatus.isSave,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTableHeadAction("save")
                  },
                },
              },
              [_vm._v("保 存 ")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  type: "primary",
                  loading: _vm.btnStatus.isExportLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleTableHeadAction("export")
                  },
                },
              },
              [_vm._v("导 出 ")]
            ),
            this.user.role_code_list.includes("selfManager") ||
            this.user.role_code_list.includes("leader")
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "warning" },
                    on: {
                      click: function ($event) {
                        return _vm.handleTableHeadAction("adjust")
                      },
                    },
                  },
                  [_vm._v("确 认 调 整 ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "salesCenter-content" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { staticClass: "salesCenter-tips" }, [
              _vm._v("本月度低于起订量的产品如下表所示，请确认调整"),
            ]),
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableStatus.isLoading,
                expression: "tableStatus.isLoading",
              },
            ],
            ref: "multipleTable",
            staticClass: "table",
            attrs: {
              stripe: "",
              border: "",
              size: "mini",
              height: "500",
              data: _vm.lists,
            },
            on: { "selection-change": _vm.handleselection },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "40", fixed: "left" },
            }),
            _c("el-table-column", {
              attrs: {
                label: "序号",
                align: "center",
                width: "50",
                fixed: "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            scope.$index +
                              (_vm.currentPage - 1) * _vm.pageSize +
                              1
                          ) + " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._l(_vm.tableObj.minimumOrderAdjustHead, function (item, index) {
              return [
                item.prop == "adjust_type"
                  ? _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: { fixed: "right", align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "orderAdjust-btn" },
                                      _vm._l(
                                        scope.row.orderAdjustBtn,
                                        function (item1, ordId) {
                                          return _c(
                                            "el-button",
                                            {
                                              key: ordId + "only",
                                              attrs: {
                                                type: item1.isSelect
                                                  ? "primary"
                                                  : "",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleOrdAdj(
                                                    scope.$index,
                                                    ordId,
                                                    item1.btnStatus,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item1.btnText) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    )
                  : item.prop == "confirm_theory_shipment_count_box"
                  ? _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: { align: "center" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "tgqrcount-cell-ipt",
                                      attrs: {
                                        type: "number",
                                        disabled: scope.row.is_edit,
                                        size: "mini",
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          scope.row
                                            .confirm_theory_shipment_count_box,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "confirm_theory_shipment_count_box",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.confirm_theory_shipment_count_box",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    )
                  : [
                      "cargono",
                      "plu_name",
                      "price",
                      "min_order_count_box",
                      "grade",
                      "hq_pre_last_six_month_avg_sale_count_box",
                    ].includes(item.prop)
                  ? _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            fixed: "left",
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    )
                  : _c(
                      "el-table-column",
                      _vm._b(
                        {
                          key: index,
                          attrs: {
                            "show-overflow-tooltip": "",
                            align: "center",
                          },
                        },
                        "el-table-column",
                        item,
                        false
                      )
                    ),
              ]
            }),
          ],
          2
        ),
        _c("v-pagination", {
          attrs: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            currentPage: _vm.currentPage,
          },
          on: { pageChange: _vm.pagingChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }