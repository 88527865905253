var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              staticClass: "dialog-box",
              attrs: {
                width: "1000px",
                "close-on-click-modal": false,
                visible: _vm.importBaseParams.visible,
                "destroy-on-close": true,
                title: _vm.importBaseParams.title,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.importBaseParams, "visible", $event)
                },
                close: _vm.handleDialogClose,
              },
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.importBaseParams.visible
            ? _c(
                "div",
                [
                  _c("mg-upload-excel", {
                    ref: "uploadExcelRef",
                    attrs: {
                      uploadDatas: _vm.uploadFileObj.tableData,
                      "on-success": _vm.handleFileSuccess,
                      "before-upload": _vm.beforeFileUpload,
                      templateFileDownloadUrl:
                        _vm.importBaseParams.templateFileDownloadUrl,
                      tipsText: _vm.importBaseParams.tipsText,
                      optionalTipsText: _vm.importBaseParams.optionalTipsText,
                      importType: _vm.importType,
                    },
                    on: {
                      handlePreview: _vm.handlePreview,
                      handleConfirmUpload: _vm.handleConfirmUpload,
                    },
                  }),
                  _vm.uploadFileObj.preTableData.length > 0
                    ? [
                        _c(
                          "div",
                          { staticClass: "previewTable" },
                          [
                            _c("mg-signboard", {
                              attrs: { title: "文件预览" },
                            }),
                            _c(
                              "el-table",
                              {
                                staticClass: "previewTable-lists",
                                attrs: {
                                  data: _vm.uploadFileObj.preTableData,
                                  stripe: "",
                                  border: "",
                                  size: "mini",
                                  height: "calc(100vh - 500px)",
                                  "max-height": "auto",
                                },
                              },
                              _vm._l(
                                _vm.uploadFileObj.tableHeader,
                                function (item) {
                                  return _c("el-table-column", {
                                    key: item,
                                    attrs: {
                                      prop: item,
                                      label: item,
                                      align: "center",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "previewTable previewTable-sample" },
                          [
                            _c("mg-signboard", {
                              attrs: { title: "文件上传模板示例" },
                            }),
                            _c(
                              "el-table",
                              {
                                staticClass: "previewTable-lists",
                                attrs: {
                                  data: _vm.importBaseParams
                                    .templateSampleLists,
                                  stripe: "",
                                  border: "",
                                  size: "mini",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.importBaseParams.templateSampleHead,
                                  function (item, index) {
                                    return [
                                      _c(
                                        "el-table-column",
                                        _vm._b(
                                          {
                                            key: index,
                                            attrs: {
                                              "show-overflow-tooltip": "",
                                              align: "center",
                                            },
                                          },
                                          "el-table-column",
                                          item,
                                          false
                                        )
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }