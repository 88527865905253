<template>
  <!-- 分页器 -->
  <el-pagination
    background
    :layout="layout"
    :page-sizes="pageSizesArr"
    :current-page.sync="paging.current_page"
    :page-size.sync="paging.per_page"
    :total="paging.total"
    class="table-pages table-paging-box"
    @current-change="handleCurrentChange"
    @size-change="handleSizeChange"
  ></el-pagination>
</template>

<script>
export default {
  props: {
    // 总页数
    total: {
      type: Number,
      default: 0,
    },
    // 当前页
    currentPage: {
      type: Number,
      default: 1,
    },
    // 每页显示条数
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizesArr: {
      type: Array,
      default() {
        return [10, 15, 20, 25, 50, 100];
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
  },
  data() {
    return {
      paging: {
        current_page: this.currentPage,
        per_page: this.pageSize,
        total: this.total,
      },
    };
  },
  watch: {
    total(n, o) {
      if (n != o) this.$set(this.paging, "total", n);
    },
    currentPage(n, o) {
      if (n != o) this.$set(this.paging, "current_page", n);
    },
    per_page(n, o) {
      if (n != o) this.$set(this.per_page, "pageSize");
    },
  },
  methods: {
    // 翻页
    handleCurrentChange(current) {
      this.$set(this.paging, "current_page", current);
      this.$emit("pageChange", this.paging);
    },
    // 直达第几页
    handleSizeChange(current) {
      this.$set(this.paging, "current_page", 1);
      this.$set(this.paging, "per_page", current);
      this.$emit("pageChange", this.paging);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-paging-box {
  margin-top: 20px;
}
</style>
