<template>
  <div class="prodCenter-page">
    <el-tabs class="prodCenter-tabs" :tab-position="'top'" v-model="activeName" style="height: 200x;">
      <!-- 上月结转量 -- 在线填写 -->
      <el-tab-pane label="在线填写" class="prodCenter-tabs-item" name="onlineWrite">
        <div class="prodCenter-content lastMonth-content" v-if="activeName == 'onlineWrite'">
          <v-signboard :title="'本月结转量(工艺)盘点'"/>
          <el-row>

            <el-form
              class="main-search"
              size="mini"
              label-width="auto"
              ref="lastMonthCarrryQuantity-form"
              :inline="true"
              :model="form"
            >
              <el-col class="list-head">

                <el-form-item label="生产单位" size="mini">
                  <el-input v-model="form.production_unit" clearable placeholder="请输入生产单位"/>
                </el-form-item>
                <el-form-item label="统计工艺" size="mini">
                  <el-input v-model="form.craft" clearable placeholder="请输入统计工艺"/>
                </el-form-item>

                <el-button
                  size="mini"
                  @click="handleTableHeadAction('search')"
                >查 询
                </el-button>
                <el-button
                  size="mini"
                  @click="reverseChecked()">反 选
                </el-button>
                <el-button
                  size="mini"
                  :disabled="btnStatus.isSave"
                  @click="handleTableHeadAction('save')">保 存
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleTableHeadAction('adjust')">确 认
                </el-button>
                <span style="margin-left: 10px;font-size: 14px;color: green" v-text="confirmTips" id="confirmTips">未确认</span>
              </el-col>
            </el-form>
          </el-row>
          <el-table
            class="table"
            stripe
            border
            size="mini"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="55"/>
            <el-table-column label="序号" align="center" width="50">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableObj.lastMonthCarryProdCapacityHead">
              <!-- 工作日数 / 日总产能（万） -- 可编辑 -->
              <el-table-column v-if="['material_code', 'last_month_carryover_count'].includes(item.prop)" v-bind="item"
                               :key="index" align="center">
                <template slot-scope="scope">
                  <el-input
                    class="tgqrcount-cell-ipt"
                    v-if=" item.prop == 'material_code'"
                    v-model="scope.row.material_code"
                    size="mini"
                    clearable
                  />
                  <div v-if=" item.prop == 'last_month_carryover_count'">
                    <el-input
                      class="tgqrcount-cell-ipt"
                      type="number"
                      v-model="scope.row.last_month_carryover_count"
                      size="mini"
                      clearable
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </el-tab-pane>
      <!-- 上月结转量 -- 表单上传 -->
      <el-tab-pane label="表单上传" class="prodCenter-tabs-item" name="formWrite">
        <div clas="form-upload-box" v-if="activeName == 'formWrite'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'"/>
              <el-row>
                <el-col class="handleConfirmUpload">
                  <el-button size="mini" type="primary">确认上传</el-button>
                </el-col>
              </el-row>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item"/>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'"/>
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams} from '@/mixins';
import {lastMonthCarryProdCapacityHead, lastMonthTemplateSampleHead} from '../config';
import {
  handleLastMonthProdLists,
  handleLastMonthProdConfirm,
  handleLastMonthProdSave,
  handleLastMonthProdImportExcel
} from '@/api/prodCenter';
import {queryCapacityConfirmStatus} from "../../../api/prodCenter";

export default {
  // 上月结转量
  name: 'lastMonthCarryQuantity',
  mixins: [tableLoading, paginationParams, urlParams],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {

      form: {
        craft: '',
        production_unit: '',
      },
      btnStatus: {
        isSave: true,                   // 是否保存
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        lastMonthCarryProdCapacityHead
      },
      uploadFileObj: {
        tipsText: '必需字段: 生产单位、统计工艺、上月结转量',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: lastMonthTemplateSampleHead,
        templateSampleLists: [
          {
            check_month: '',
            production_unit: '一分厂',
            craft: '笔头',
            last_month_carryover_count: '13'
          }
        ]
      },
      selectRows: [],
      urlMonth: '',
      activeName: 'onlineWrite',
      confirmTips: "尚未确认"
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isAdjust = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();

      this.checkConfirmTips();
    });
  },
  methods: {
    // 获取数据
    async getLists() {
      try {

        const form = this.form;
        const formParams = {
          craft: form.craft || '',
          production_unit: form.production_unit || '',
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          ...formParams
        };
        const {code, data, msg} = await handleLastMonthProdLists(params);
        if (data) {
          const {capacity_list, total} = data;
          this.modifyData(capacity_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----模具产能盘点列表有误');
      }
    },
    async checkConfirmTips() {
      const params = {
        month: this.urlMonth || '',
        operator: this.urlParams.operator,
      };
      const {data} = await queryCapacityConfirmStatus(params);
      if (data) {
        const {capacity_list} = data;
        for(let i = 0; i < capacity_list.length; i++) {
          let capacity = capacity_list[i];
          if(capacity.capacity_type == '上月结转量' && capacity.confirmed) {
            this.confirmTips = '已确认';
          }
        }
      }
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    //实现反选
    reverseChecked() {
      this.lists.map(row => {
        for (let i = 0; i < this.selectRows.length; i++) {
          if (this.selectRows[i].id == row.id) {
            this.$refs['multipleTable'].toggleRowSelection(row, false);
            return;
          }
        }
        this.$refs['multipleTable'].toggleRowSelection(row, true);
      });
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            check_month: item.check_month || '--',
            production_unit: item.production_unit || '--',
            craft: item.craft || '--',
            last_month_carryover_count: item.last_month_carryover_count || 0,
            id: item.id
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作项
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {               // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              check_month: item.check_month || '',
              last_month_carryover_count: item.last_month_carryover_count || 0,
              material_code: item.material_code || '',
              id: item.id || '',
            };
            newItems.push(newItem);
          });
          const parmas = {
            operator: this.urlParams.operator,
            capacity_list: newItems
          };
          const {code, msg} = await handleLastMonthProdSave(parmas);
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'reset': () => {                    // 重置
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'adjust': async () => {             // 确认调整
          this.$confirm('您需要确认结转量(工艺)么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const params = {
              month: this.urlMonth,
              operator: this.urlParams.operator
            };
            const {success} = await handleLastMonthProdConfirm(params);
            this.$refs['multipleTable'].clearSelection();
            if (success) {
              this.tableStatus.isLoading = true;
              this.getLists();
              this.checkConfirmTips();
              return this.$message.success('结转量(工艺)已确认');
            }
          }).catch(() => {
            this.$message('产能未确认');
          });
        }
      };
      funcObj[type]();
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header
    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;
      if (uploadFileObj.tableHeader.indexOf('生产单位') === -1) return this.$message.warning('缺少生产单位字段');
      if (uploadFileObj.tableHeader.indexOf('统计工艺') === -1) return this.$message.warning('缺少统计工艺字段');
      if (uploadFileObj.tableHeader.indexOf('上月结转量') === -1) return this.$message.warning('缺少上月结转量字段');
      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let newItem = {
          check_month: item['年月'],
          production_unit: item['生产单位'],
          craft: item['统计工艺'],
          last_month_carryover_count: item['上月结转量']
        }
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        capacity_list: newItems
      };
      const {code, success, msg} = await handleLastMonthProdImportExcel(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (success) return this.$message.success(msg);
      if (!success) this.activeName = 'onlineWrite';
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/prodCenter/layoutMain.scss'></style>
<style lang="scss" scoped>
.lastMonth-content {
  margin-top: 0;
}
</style>
