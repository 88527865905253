<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form size="mini" label-width="auto" ref="searchRef" :inline="true" :model="queryForm">
        <el-form-item label="补货日期">
          <el-select v-model="queryForm.supplyDate" @change="handleDateChange">
            <el-option v-for="item in dateOptions" :label="item" :value="item" :key="item"/>
          </el-select>
        </el-form-item>
        <DictSelect
          ref="storeSearch"
          :title="queryForm.storeCode"
          :value.sync="queryForm.storeCode"
          showLabel="店铺信息"
          :fullLabel="true"
          placeholder="可输入编号或名称"
          type="store"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <DictSelect
          ref="itemSearch"
          :value.sync="queryForm.itemCode"
          showLabel="商品信息"
          :fullLabel="true"
          placeholder="可输入编号或名称"
          type="item"
          :date="true"
          @getDate="(callback) => callback(queryForm.supplyDate)"
        />
        <el-form-item label="">
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleQuery">查询</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleResetFields">清空</el-button>
        </el-form-item>
        <el-form-item>至少选择一个店铺或一个商品!</el-form-item>
      </el-form>
    </div>
    <div style="margin-bottom: 10px">
      <UpdateTime :time.sync="updateTime"/>
      <div style="margin-left: 10px"><h3 style="margin: 0px">概览</h3></div>
      <el-table
        :data="pageData.rows"
        max-height="500px"
        v-loading="loading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
        @sort-change="handleSortChange"
      >
        <el-table-column label="店铺编号" prop="storeCode" width="100" fixed="left"/>
        <el-table-column label="店铺名称" prop="storeName" width="160" fixed="left"/>
        <el-table-column label="商品编号" prop="itemCode" width="100" fixed="left"/>
        <el-table-column label="商品名称" prop="itemName" width="180" fixed="left"/>
        <el-table-column label="大仓库存" prop="storageQty" sortable width="100"/>
        <el-table-column label="门店库存" prop="storeQty" sortable width="100"/>
        <el-table-column label="最小起订量" prop="minDisNum" sortable width="120"/>
        <el-table-column label="中包装" prop="middleBoxGauge" sortable width="100"/>
        <el-table-column label="标准陈列量" prop="displayNum" sortable width="120"/>
        <el-table-column label="预测补货数量" prop="ycBhQty" sortable width="120"/>
        <el-table-column label="最终补货数量" prop="zzBhQty" sortable width="120"/>
        <el-table-column label="预测周转" prop="ycZz" sortable width="100"/>
        <el-table-column label="最终补货周转" prop="ycBhZz" width="100">
          <template slot-scope="{ row }">
            <span>{{ row.sellQtyLast1 ? ((row.zzBhQty + row.storeQty) / row.sellQtyLast1).toFixed(2) : 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="调整说明" prop="remark" width="120"/>
        <el-table-column v-if="showColumns['modelName']" label="模型" prop="modelName" width="100"/>
        <el-table-column v-if="showColumns['a']" label="a" prop="a" width="100"/>
        <el-table-column v-if="showColumns['b']" label="b" prop="b" width="100"/>
        <el-table-column v-if="showColumns['r2']" label="r2" prop="r2" width="100"/>
        <el-table-column v-if="showColumns['sse']" label="sse" prop="sse" width="100"/>
        <el-table-column v-if="showColumns['mse']" label="nse" prop="mse" width="100"/>
        <el-table-column v-if="showColumns['smoothingLevel']" label="smoothing_level" prop="smoothingLevel"
                         width="100"/>
        <el-table-column v-if="showColumns['smoothingTrend']" label="smoothing_trend" prop="smoothingTrend"
                         width="100"/>
        <el-table-column v-if="showColumns['smoothingSeasonal']" label="smoothing_seasonal" prop="smoothingSeasonal"
                         width="100"/>
        <el-table-column v-if="showColumns['dampingTrend']" label="damping_trend" prop="dampingTrend" width="100"/>
        <el-table-column v-if="showColumns['initialLevel']" label="initial_level" prop="initialLevel" width="100"/>
        <el-table-column v-if="showColumns['initialTrend']" label="initial_trend" prop="initialTrend" width="100"/>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size"
                  @pagination="handleQuery"/>
    </div>
    <div v-show="dayType" style="margin-top: 10px">
      <div style="float: right; z-index: 99999999999999">
        <el-radio-group v-model="holidayChartType" size="mini" @input="handleChartTypeChange">
          <el-radio-button label="周日均"></el-radio-button>
          <el-radio-button label="同期"></el-radio-button>
        </el-radio-group>
      </div>
      <div style="font-size: 12px; margin-bottom: 5px">
        下一个节日:
        <span style="color: #e67488">{{ dayType }} ({{ holidayDateRange }})</span>
      </div>
      <div style="font-size: 12px; margin-bottom: 5px">
        距离节日开始
        <span style="color: #e67488">{{ beginDay > 0 ? beginDay : 0 }}</span>
        天,距离节日结束
        <span style="color: #e67488">{{ endDay }}</span>
        天
      </div>
      <div style="margin: 10px"><h3 style="margin: 0px">销售趋势</h3></div>
    </div>
    <div v-show="!dayType">
      <div style="margin-left: 10px"><h3 style="margin: 10px">销售数据</h3></div>
    </div>
    <div
      v-show="dayType &&holidayChartType === '同期'"
      ref="history"
      id="1"
      style="width: 800px; height: 350px; border: 0.5px solid #e3d6d6; margin: 0 auto"
    ></div>
    <div v-show="dayType && holidayChartType === '周日均'" ref="holiday"
         style="width: 100%; height: 350px; border: 0.5px solid #e3d6d6"></div>
    <div v-show="!dayType" ref="common" style="width: 100%; height: 350px; border: 0.5px solid #e3d6d6"></div>
    <div v-show="!dayType || holidayChartType === '周日均'" class="block" style="margin-top: 10px">
      <el-date-picker
        v-model="dayDate"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        :default-value="defaultDayDate"
        style="min-width: 400px;"
        size="mini"
        :default-time="['00:00:00', '00:00:00']">
      </el-date-picker>
      <el-button type="primary" size="mini" style="margin-left: 10px" @click="handleConfirmDate">确认</el-button>
      <span style="margin-bottom: 10px; margin-left: 10px; color: gray;">说明：阴影部分为期初库存为0的日期</span>
      <div ref="dayChart" style="width: 100%; height: 350px; border: 0.5px solid #e3d6d6; margin-top: 10px;"></div>
    </div>
    <div v-show="dayType && holidayChartType === '同期'">
      <el-select v-model="historyHolidayDate" placeholder="选择同期年份" size="mini" value-key="year" @change="handleHistoryHolidayData">
        <el-option v-for="item in holidayDateOptions" :key="item.year" :value="item"
                   :label="item.year"/>
      </el-select>
      <div ref="historyDayChart"
           style="width: 100%; height: 350px; border: 0.5px solid #e3d6d6; margin-top: 10px;"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Pagination from "../../../components/Pagination";
import DictSelect from "../components/DictSelect/index.vue";
import {
  page,
  getChartData,
  getCurrentDate,
  refreshCache,
  getAllDate,
  getDayChartData,
  getHolidayData
} from "@/api/stockToShop";
import {parseTime} from "@/utils";
import UpdateTime from "../components/UpdateTime/index.vue";
import {Message, Notification} from "element-ui";

export default {
  name: "SkuDetail",
  components: {Pagination, DictSelect, UpdateTime},
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        storeCode: undefined,
        itemCode: undefined,
        supplyDate: undefined,
      },
      loading: false,
      queryByItem: false,
      dayType: undefined,
      saleDay: undefined,
      holidayDateRange: undefined,
      beginDay: undefined,
      endDay: undefined,
      showColumns: {
        a: false,
        b: false,
        r2: false,
        sse: false,
        mse: false,
        smoothingLevel: false,
        smoothingTrend: false,
        smoothingSeasonal: false,
        dampingTrend: false,
        initialLevel: false,
        initialTrend: false,
      },
      holidayChartType: '周日均',
      firstRow: undefined,
      updateTime: undefined,
      dateOptions: [],
      dayDate: undefined,
      defaultDayDate: undefined,
      pickerOptions: {
        disabledDate(date) {
          console.log(window.skuDetailCurrDate)
          const time = window.skuDetailCurrDate.getTime() - date.getTime();
          return time >= 35 * 7 * 24 * 60 * 60 * 1000 || time <= 24 * 60 * 60 * 1000;

        },
        onPick({maxDate, minDate}) {
          if (maxDate && minDate && maxDate.getTime() - minDate.getTime() > 35 * 24 * 60 * 60 * 1000) {
            Message.warning("所选择日期范围需在35天之内!");
            return false;
          }
        }
      },
      historyHolidayDate: undefined,
      holidayDateOptions: [{year: 2022, startDate: '20221001', endDate: '20221007'}],
      dayChartData: [],
      dateChangeQuery: false
    };
  },
  created() {
    const start = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    const end = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    this.dayDate = [start, end];

    getHolidayData().then(res => {
      if (res.data && res.data.length > 0) {
        this.holidayDateOptions = res.data.filter(e => e.year !== new Date().getFullYear()).sort((a,b) => a.year -b.year);
      }
    })
  },
  mounted() {
    // this.handleFilter();
    for (let i = 0; i < 15; i++) {
      this.dateOptions.push(parseTime(new Date().getTime() - i * 24 * 60 * 60 * 1000, "{y}{m}{d}"));
    }
    this.queryForm.supplyDate = this.dateOptions[0];
    this.currentDataDate = this.dateOptions[0];
    window.skuDetailCurrDate = this.handleStrTime(this.queryForm.supplyDate);
    this.$forceUpdate();
    refreshCache(this.queryForm.supplyDate);
    getCurrentDate().then((res) => {
      if (res.data) {
        this.updateTime = res.data
      }
    })
  },
  methods: {
    handleQuery(jump) {
      if (!this.queryForm.storeCode && !this.queryForm.itemCode) {
        Notification.warning('至少选择一个店铺或一个商品!');
        return false;
      }
      this.loading = true;
      if (this.queryForm.itemCode) {
        this.queryByItem = true;
      } else {
        this.queryByItem = false;
      }
      page(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.resetShowColumns();
        if (this.pageData.total > 0) {
          const row = this.pageData.rows[0];
          this.firstRow = row;
          this.dayType = row.holName;
          this.holidayDateRange = row.holBeginDate + "至" + row.holEndDate;
          this.beginDay = Math.trunc((new Date(row.holBeginDate).getTime() - new Date(this.handleTime(this.updateTime)).getTime()) / (1000 * 60 * 60 * 24));
          this.endDay = Math.trunc((new Date(row.holEndDate).getTime() - new Date(this.handleTime(this.updateTime)).getTime()) / (1000 * 60 * 60 * 24));
          if (this.queryByItem) {
            this.saleDay = row.czDayNum;
          }
          if (row.modelName) {
            this.showColumns["modelName"] = true;
          }
          if (row["a"] !== null) {
            this.showColumns["a"] = true;
          }
          if (row["r2"] !== null) {
            this.showColumns["r2"] = true;
          }
          if (row["b"] !== null) {
            this.showColumns["b"] = true;
          }
          if (row["sse"] !== null) {
            this.showColumns["sse"] = true;
          }
          if (row["mse"] !== null) {
            this.showColumns["mse"] = true;
          }
          if (row["smoothingLevel"] !== null) {
            this.showColumns["smoothingLevel"] = true;
          }
          if (row["smoothingTrend"] !== null) {
            this.showColumns["smoothingTrend"] = true;
          }
          if (row["smoothingSeasonal"] !== null) {
            this.showColumns["smoothingSeasonal"] = true;
          }
          if (row["dampingTrend"] !== null) {
            this.showColumns["dampingTrend"] = true;
          }
          if (row["initialLevel"] !== null) {
            this.showColumns["initialLevel"] = true;
          }
          if (row["initialTrend"] !== null) {
            this.showColumns["initialTrend"] = true;
          }
          let param =
            "?storeCode=" +
            (this.queryForm.storeCode ? this.queryForm.storeCode : "") +
            "&itemCode=" +
            (this.queryForm.itemCode ? this.queryForm.itemCode : "") + "&dateKey=" + this.queryForm.supplyDate;
          getChartData(param).then(res => {
            if (res.data && res.data.length > 0) {
              if (this.dayType) {
                this.handleHistoryChart(res.data[0]);
              }
              this.handleChartData(this.dayType ? "holiday" : "common", res.data[0]);
            }
          });
        } else {
          if (this.$refs[this.dayType ? "holiday" : "common"]) {
            echarts.dispose(this.$refs[this.dayType ? "holiday" : "common"])
          }
        }
      });
      if (this.dateChangeQuery || jump) {
        console.log(this.dateChangeQuery, jump);
        const date = jump && typeof jump === 'number'? this.handleStrTime(jump).getTime() : window.skuDetailCurrDate.getTime();
        const start = new Date(date - 7 * 24 * 60 * 60 * 1000);
        const end = new Date(date - 24 * 60 * 60 * 1000);
        this.dayDate = [start, end];
        this.dateChangeQuery = false;
        this.$forceUpdate();
      }
      this.getDayChartData();

    },
    handleStrTime(data) {
      const dateKey = data + "";
      const y = dateKey.slice(0, 4);
      const m = dateKey.slice(4, 6);
      const d = dateKey.slice(6, 8);
      const dateStr = y + "-" + m + "-" + d;
      return new Date(dateStr);
    },
    handleTime(data) {
      const dateKey = data + "";
      const y = dateKey.slice(0, 4);
      const m = dateKey.slice(4, 6);
      const d = dateKey.slice(6, 8);
      const dateStr = y + "-" + m + "-" + d;
      return parseTime(new Date(dateStr).getTime() + 24 * 60 * 60 * 1000, "{y}-{m}-{d}");
    },
    resetShowColumns() {
      this.showColumns = {
        a: false,
        b: false,
        r2: false,
        sse: false,
        mse: false,
        smoothingLevel: false,
        smoothingTrend: false,
        smoothingSeasonal: false,
        dampingTrend: false,
        initialLevel: false,
        initialTrend: false,
      };
    },
    handleFilter(date) {
      this.queryForm.current = 1;
      this.handleQuery(date);
    },
    handleResetFields() {
      this.$refs.storeSearch.clearAll();
      this.$refs.itemSearch.clearAll();
    },
    handleDateChange(date) {
      refreshCache(date);
      window.skuDetailCurrDate = this.handleStrTime(date);
      this.dateChangeQuery = true;
    },
    setDictOptions(component, options) {
      this.$refs[component].itemOptions = options;
    },
    setDictData(component, data) {
      this.$refs[component].queryForm.value = data;
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const {prop, order} = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    parseShowTime(num, format) {
      return parseTime(new Date().getTime() + num * 24 * 60 * 60 * 1000, format ? format : "{m}{d}");
    },
    parseShowTimeByDate(date, num, format) {
      return parseTime(new Date(date).getTime() + num * 24 * 60 * 60 * 1000, format ? format : "{m}{d}");
    },
    handleHistoryDate(data) {
      const beginDate = data.split("--")[0];
      const y = beginDate.slice(0, 4);
      const bm = beginDate.slice(5, 7);
      const bd = beginDate.slice(8, 10);
      const beginMonth = bm + "-" + bd;
      const endDate = data.split("--")[1];
      const em = endDate.slice(5, 7);
      const ed = endDate.slice(8, 10);
      const endMonth = em + "-" + ed;
      return y + "同期\n(" + beginMonth + "至\n" + endMonth + ")";
    },
    handleHistoryChart(data) {
      const current =
        parseTime(new Date(this.firstRow.ycBeginDate).getTime(), "{y}") +
        "预测\n(" +
        parseTime(new Date(this.firstRow.ycBeginDate).getTime(), "{m}-{d}") +
        "至\n" +
        parseTime(new Date(this.firstRow.ycEndDate).getTime(), "{m}-{d}") +
        ")";
      const xData = [
        this.handleHistoryDate(data.dateFlag4),
        this.handleHistoryDate(data.dateFlag3),
        this.handleHistoryDate(data.dateFlag2),
        this.handleHistoryDate(data.dateFlag1),
        current,
      ];
      const yData = [
        data.holSellQtyLy4Total,
        data.holSellQtyLy3Total,
        data.holSellQtyLy2Total,
        data.holSellQtyLy1Total,
        data.ycSellQtyTotal,
      ];
      const legend = "周销售数量";
      const rangeData = [
        {
          name: "预测周",
          xAxis: this.handleHistoryDate(data.dateFlag1),
        },
        {
          xAxis: current,
        },
      ];
      let pieces = [];
      pieces.push({
        lte: 2,
        color: '#409EFF'
      })
      pieces.push({
        gte: 2,
        lte: 3,
        color: '#409EFF'
      })
      pieces.push({
        gt: 3,
        color: '#FFC77A'
      })
      this.getEchartData("history", xData, yData, legend, [rangeData], pieces);
    },
    handleChartData(chart, data) {
      let xData = [];
      let yData = [];
      let kcYdata = []
      let legend = [];
      let divisor = 1;
      if (this.dayType) {
        legend.push("周日均销售数量");
        divisor = 7;
      } else {
        legend.push("周销售数量");
      }
      let rangeData = [];

      let pieces = [];
      // 非节假日
      // 单品
      if (this.queryByItem) {
        const week = this.saleDay / 7;
        if (week > 0) {
          const rangeWeekBegin = {
            name: "预测参考周",
            xAxis: "前" + 1 + "周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-7) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-1) + ")",
          };
          const rangeWeekEnd = {
            xAxis: "前" + (week + 1) + "周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-7 * week -7) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-1 - 7 * week) + ")",
          };
          rangeData.push([rangeWeekBegin, rangeWeekEnd]);
        }
        pieces.push({
          gt: 29 - week,
          lt: 29,
          color: 'blue'
        })
        pieces.push({
          lt: 29 - week,
          color: '#409EFF'
        })
      } else {
        pieces.push({
          gte: 0,
          lt: 29,
          color: '#409EFF'
        })
      }
      if (!this.dayType) {
        yData.push(data.ycSellQtyWeek2Total);
        yData.push(data.ycSellQtyWeek1Total);
        xData.push("后2周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),7) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),13) + ")");
        xData.push("后1周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),0) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),6) + ")");
        rangeData.push([
          {name: "预测周", xAxis: "前1周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-7) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-1) + ")"},
          {xAxis: "后2周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),7) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),13) + ")"},
        ]);

        pieces.push({
          gte: 29,
          color: 'yellow'
        })
      } else {
        const ycX =
          "预测周\n(" +
          parseTime(new Date(this.firstRow.ycBeginDate).getTime(), "{m}{d}") +
          "-\n" +
          parseTime(new Date(this.firstRow.ycEndDate).getTime(), "{m}{d}") +
          ")";
        if (this.firstRow.ycBeginDate) {
          yData.push(Math.trunc(data.ycSellQtyTotal / this.firstRow.ycSellDayNum));
          xData.push(ycX);
          rangeData.push([
            {
              name: "预测周",
              xAxis: "前1周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-7) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-1) + ")",
            },
            {
              xAxis: ycX,
            },
          ]);
        }
        pieces.push({
          lte: 28,
          color: 'blue'
        })
        pieces.push({
          lte: 29,
          gte: 28,
          color: '#409EFF'
        })
        pieces.push({
          gt: 29,
          color: '#409EFF'
        })
      }
      for (let i = 1; i <= 30; i++) {
        yData.push(Math.ceil(data["sellQtyLast" + i + "Total"] / divisor));
        xData.push("前" + i + "周\n(" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-7 * i) + "-\n" + this.parseShowTimeByDate(this.getDateFormat(this.queryForm.supplyDate),-1 - 7 * (i - 1)) + ")");
        if (this.queryForm.storeCode && this.queryForm.itemCode) {
          kcYdata.push(Math.ceil(data["qhCntLast" + i + "Total"]));
        }
      }
      xData.reverse();
      yData.reverse();
      kcYdata.reverse();
      let series = [];
      let series1 = {
        name: legend[0],
        type: "line",
        data: yData,
        smooth: true,
        markArea: {
          itemStyle: {
            color: "#FFE4bE",
            borderWidth: "0.1",
          },
          data: rangeData,
        },
      };
      let yAxis = [];
      let visualMap = [];
      let visual1 = {
        show: false,
        dimension: 0,
        pieces: pieces,
        seriesIndex: 0,
      };
      if (this.queryForm.storeCode && this.queryForm.itemCode) {
        legend.push('库存为0天数')
        series1.yAxisIndex = 0;
        let series2 = {
          name: legend[1],
          type: "line",
          data: kcYdata,
          smooth: true,
          yAxisIndex: 1,
        };
        series.push(series1);
        series.push(series2);
        const interval1 = this.getChartInterval(yData);
        yAxis.push({type: "value", name: '销售数量', min: 0, interval: interval1, splitNumber: 10, max: interval1 * 10});
        visualMap.push(visual1);
        let interval = this.getChartInterval(kcYdata);
        yAxis.push({
          type: "value",
          name: '天数',
          splitNumber: 10,
          min: 0,
          max: interval * 10,
          interval: interval
        });
      } else {
        yAxis.push({type: "value", name: '销售数量'});
        series.push(series1);
        visualMap.push(visual1);
      }

      this.getManyEchartData(chart, xData, yAxis, legend, series, visualMap);
    },
    getEchartData(chart, xData, yData, legend, rangeData, pieces) {
      let series = [{
        name: legend,
        type: "line",
        data: yData,
        smooth: true,
        markArea: {
          itemStyle: {
            color: "#FFE4bE",
            borderWidth: "0.1",
          },
          data: rangeData,
        },
      }];
      let visualMap = [{
        show: false,
        dimension: 0,
        pieces: pieces,
      }]
      this.getManyEchartData(chart, xData, {type: "value"}, [legend], series, visualMap);
    },
    getChartInterval(data) {
      const maxItem = Math.max(...data);
      const temp = maxItem / 10;
      if (temp === 0) {
        return 1;
      } else if (temp < 10) {
        return Math.ceil(maxItem/7);
      } else if (temp >= 10 && temp <= 100) {
        return (Math.ceil(temp/5) * 5);
      } else if (temp > 100 && temp <= 1000) {
        return (Math.ceil(temp/50)) * 50;
      } else if (temp > 1000) {
        return Math.ceil(temp/500) * 500;
      }
    },
    getManyEchartData(chart, xData, yAxis, legend, series, visualMap, tooltip) {
      const chart1 = this.$refs[chart];
      const tip = tooltip ? tooltip : {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      };
      if (chart1) {
        const myChart = echarts.init(chart1);
        const option = {
          tooltip: tip,
          legend: {
            data: legend,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: xData,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              //interval设置成 0 强制显示所有标签
              interval: 0,
            },
          },
          yAxis: yAxis,
          visualMap: visualMap,
          series: series,
        };
        console.log(option, chart)
        myChart.clear();
        setTimeout(() => {
          myChart.setOption(option);
          window.addEventListener("resize", function () {
            myChart.resize();
          });
          this.$on("hook:destroyed", () => {
            window.removeEventListener("resize", function () {
              myChart.resize();
            });
          });
        }, 500)
      }
    },
    getDayChartData() {
      if (this.dayDate && this.dayDate.length > 0 && this.queryForm.storeCode && this.queryForm.itemCode) {
        const startDate = parseTime(this.dayDate[0], "{y}-{m}-{d}");
        const endDate = parseTime(this.dayDate[1], "{y}-{m}-{d}");
        const param = "?startDate=" + startDate + "&endDate=" + endDate + "&storeCode=" + this.queryForm.storeCode + "&itemCode=" + this.queryForm.itemCode;
        getDayChartData(param).then(res => {
          this.dayChartData = res.data;
          this.initDayChart('dayChart', res.data);
        })
      }
    },
    handleHistoryHolidayData(val) {
      if (val && this.queryForm.storeCode && this.queryForm.itemCode) {
        const startDate = this.getDateFormat(this.historyHolidayDate.startDate);
        const endDate = this.getDateFormat(this.historyHolidayDate.endDate);
        const param = "?startDate=" + startDate + "&endDate=" + endDate + "&storeCode=" + this.queryForm.storeCode + "&itemCode=" + this.queryForm.itemCode;
        getDayChartData(param).then(res => {
          this.initDayChart('historyDayChart', res.data);
        })
      }
    },
    initDayChart(chart, data) {
      let xData = [];
      let allYData = {
        y1Data: [],
        y2Data: [],
        y3Data: [],
        y4Data: [],
        y5Data: [],
      };
      let legend = ['销售数量', '销售订单量', '期初库存', '在途库存', '通知库存'];
      let emptyData = [];
      data.forEach(e => {
        const x = e.dateKey + '';
        if (data.length > 20) {
          xData.push(x.slice(0, 4) + '\n' + x.slice(4));
        } else {
          xData.push(x);
        }
        allYData.y1Data.push(e.salesQtyTotal);
        allYData.y2Data.push(e.skuOrderCntTotal);
        allYData.y3Data.push(e.qcStockQtyTotal);
        allYData.y4Data.push(e.onwayQtyTotal);
        allYData.y5Data.push(e.noticeQtyTotal);
      });
      const max = this.getChartInterval([].concat(allYData.y3Data).concat(allYData.y4Data).concat(allYData.y5Data)) * 10;
      allYData.y3Data.forEach(e => {
        if (e === 0) {
          emptyData.push(max === 0 ? 10 : max);
        } else {
          emptyData.push(0)
        }
      })
      let series = [];
      const interval1 = this.getChartInterval(allYData.y1Data);
      const interval2 = this.getChartInterval(allYData.y2Data);
      const interval3 = this.getChartInterval(allYData.y3Data.concat(allYData.y4Data).concat(allYData.y5Data));
      let yAxis = [
        {
          "type": "value",
          name: '销售数量',
          alignTicks: true,
          offset: 60,
          axisLine: {
            show: true,
          },
          min: 0,
          interval: interval1,
          splitNumber: 10,
          max: interval1 * 10
        },
        {
          "type": "value",
          name: '销售订单量',
          position: 'left',
          alignTicks: true,
          axisLine: {
            show: true,
          },
          min: 0,
          interval: interval2,
          splitNumber: 10,
          max: interval2 * 10
        },
        {
          "type": "value",
          name: '库存',
          position: 'right',
          alignTicks: true,
          axisLine: {
            show: true,
          },
          min: 0,
          interval: interval3,
          splitNumber: 10,
          max: interval3 * 10
        }
      ];
      const yAxisData = {
        1: 0,
        2: 1,
        3: 2,
        4: 2,
        5: 2,
      }
      for (let i = 1; i < 6; i++) {
        series.push({
          name: legend[i - 1],
          type: "line",
          data: allYData['y' + i + 'Data'],
          smooth: true,
          yAxisIndex: yAxisData[i],
        });
      }
      series.push({
        type: "bar",
        data: emptyData,
        barMinWidth:'99.9%',
        itemStyle: {
          color: "#FFE4bE",
        },
        smooth: true,
        yAxisIndex: 2,
      })
      const tooltip = {
        "trigger": "axis",
        "axisPointer": {
          "type": "cross"
        },
        formatter: params => {
          let str = params[0].name + '</br>';
          for (let i = 0; i < params.length-1; i++) {
            str += '<div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">' + params[i].marker + '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' + params[i].seriesName + '</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + params[i].data + '</span><div style="clear:both"></div></div><div style="clear:both"></div></div>';
          }
          return str;
        }
      };
      this.getManyEchartData(chart, xData, yAxis, legend, series, [], tooltip);
    },
    getDateFormat(data) {
      const dateKey = data + "";
      const y = dateKey.slice(0, 4);
      const m = dateKey.slice(4, 6);
      const d = dateKey.slice(6, 8);
      return y + "-" + m + "-" + d;
    },
    handleConfirmDate() {
      this.getDayChartData();
    },
    handleChartTypeChange(val) {
      if (val === '同期' && !this.historyHolidayDate) {
        this.historyHolidayDate = this.holidayDateOptions[this.holidayDateOptions.length - 1];
        this.handleHistoryHolidayData(this.historyHolidayDate);
      }
    }
  },
};
</script>
