var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "mg-virtual-table",
        _vm.isFullScreen ? "mg-table--full-screen" : "",
      ],
    },
    [
      _vm.isShowTopBar
        ? _c("div", { staticClass: "mg-virtual-table-topbar" }, [
            _c("div", { staticClass: "topbar-left" }, [_vm._t("topleft")], 2),
            _c(
              "div",
              { staticClass: "topbar-right" },
              [
                _vm._t("topright"),
                _vm.multipleSort
                  ? [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "多级排序",
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                padding: "7px 8px",
                                "margin-left": "10px",
                              },
                              style: [_vm.multipleSortBtnStyle],
                              attrs: { type: "icon" },
                              on: {
                                click: function ($event) {
                                  _vm.multiple = !_vm.multiple
                                },
                              },
                            },
                            [_vm._v(" 多 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.searchable
                  ? [
                      !_vm.showSearchBar
                        ? _c("el-button", {
                            attrs: {
                              icon: "iconfont mg-icon_sousuo_wubiankuang",
                              size: "mini",
                              type: "icon",
                            },
                            on: { click: _vm.handleShowSearch },
                          })
                        : _c("el-button", {
                            attrs: {
                              plain: "",
                              icon: "iconfont mg-icon_sousuo_wubiankuang",
                              size: "mini",
                              type: "icon",
                            },
                            on: { click: _vm.handleShowSearch },
                          }),
                    ]
                  : _vm._e(),
                _vm.configurable
                  ? _c("el-button", {
                      attrs: {
                        icon: "iconfont mg-icon_shezhi_wubiankuang",
                        type: "icon",
                      },
                      on: {
                        click: function ($event) {
                          _vm.visible = true
                        },
                      },
                    })
                  : _vm._e(),
                _vm.isConfigFullScreen
                  ? _c("el-button", {
                      staticClass: "mg-icon-full-screen",
                      attrs: { icon: "el-icon-full-screen", type: "icon" },
                      on: { click: _vm.handleFullScreen },
                    })
                  : _vm._e(),
                _vm.showMiniPagination
                  ? _c("mg-mini-pagination", {
                      attrs: {
                        page: _vm.page,
                        total: _vm.total,
                        "total-page": _vm.totalPage,
                      },
                      on: { "page-change": _vm.handlePageChange },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: { width: "100%", overflow: "hidden" },
          style: {
            height: _vm.isFullScreen
              ? "calc(100vh - 90px)"
              : isNaN(_vm.tableHeight)
              ? _vm.tableHeight
              : `${_vm.tableHeight}px`,
          },
        },
        [
          _c(
            "vxe-table",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "mgTable",
                  staticClass: "vxe-table-box",
                  attrs: {
                    border: "",
                    "show-overflow": "",
                    "highlight-hover-row": "",
                    "auto-resize": "",
                    height: "auto",
                    size: "mini",
                    "show-header-overflow": "title",
                    data: _vm.initialData || _vm.list,
                    "scroll-y": { mode: "wheel", gt: 25 },
                    "filter-config": { showIcon: false },
                    "sort-config": {
                      iconAsc: "el-icon-caret-top",
                      iconDesc: "el-icon-caret-bottom",
                      remote: _vm.sortRemote,
                      multiple: _vm.multiple,
                      chronological: true,
                    },
                    "seq-config": { seqMethod: _vm.seqMethod },
                    "resizable-config": { minWidth: 50 },
                    "footer-method": _vm.footerMethod,
                    "row-class-name": _vm.handleRowClassName,
                  },
                  on: {
                    "cell-click": _vm.handleCell,
                    "checkbox-change": _vm.selectChangeEvent,
                    "checkbox-all": _vm.selectAllEvent,
                    "resizable-change": _vm.resizableChange,
                  },
                },
                "vxe-table",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _vm._t("checkbox"),
              _vm.columns.length > 0
                ? [
                    _vm._l(_vm.columns, function (column) {
                      return [
                        column.slot
                          ? _c("vxe-column", {
                              key: column.prop,
                              attrs: {
                                title: column.label,
                                "min-width": column.minWidth,
                                width: column.width,
                                align: column.align,
                                field: column.prop,
                                sortable: column.sortable,
                                "sort-by": column.sortBy,
                                fixed: column.fixed,
                                params: column.params,
                                "show-overflow-tooltip":
                                  column.showOverflowTooltip,
                                resizable: "",
                                filters: [{ data: "" }],
                                "filter-method": (config) =>
                                  _vm.filterMethod(config, column.prop),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scoped) {
                                      return [
                                        _vm._t(column.slot, null, null, {
                                          ...scoped,
                                          $index: scoped.$rowIndex,
                                        }),
                                      ]
                                    },
                                  },
                                  column.headerSlot
                                    ? {
                                        key: "header",
                                        fn: function (scoped) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "mg-customer-table-header-cell-label",
                                                  column.showHeaderAsterisk
                                                    ? "mg-header-asterisk"
                                                    : "",
                                                ],
                                              },
                                              [
                                                column.showHeaderAsterisk
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#e60012",
                                                          "margin-top": "4px",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(_vm._s(column.label)),
                                                ]),
                                              ]
                                            ),
                                            _vm.showSearchBar
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "mg-customer-table-header-cell-body",
                                                      _vm.showSearchBar
                                                        ? "mg-customer-table-header-cell-noBorderTop"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    _vm._t(
                                                      column.headerSlot,
                                                      null,
                                                      null,
                                                      scoped
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            })
                          : column.default
                          ? [_vm._t(column.prop, null, null, column)]
                          : _c("vxe-column", {
                              key: column.prop,
                              attrs: {
                                "show-overflow-tooltip": "",
                                title: column.label,
                                "min-width": column.minWidth,
                                width: column.width,
                                align: column.align,
                                field: column.prop,
                                sortable: column.sortable,
                                "sort-by": column.sortBy,
                                fixed: column.fixed,
                                type: column.type,
                                params: column.params,
                                resizable: "",
                                filters: [{ data: "" }],
                                "filter-method": (config) =>
                                  _vm.filterMethod(config, column.prop),
                              },
                              scopedSlots: _vm._u(
                                [
                                  column.headerSlot
                                    ? {
                                        key: "header",
                                        fn: function (scoped) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                class: [
                                                  "mg-customer-table-header-cell-label",
                                                  column.showHeaderAsterisk
                                                    ? "mg-header-asterisk"
                                                    : "",
                                                ],
                                              },
                                              [
                                                column.showHeaderAsterisk
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#e60012",
                                                          "margin-top": "4px",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(_vm._s(column.label)),
                                                ]),
                                              ]
                                            ),
                                            _vm.showSearchBar
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "mg-customer-table-header-cell-body",
                                                      _vm.showSearchBar
                                                        ? "mg-customer-table-header-cell-noBorderTop"
                                                        : "",
                                                    ],
                                                  },
                                                  [
                                                    _vm._t(
                                                      column.headerSlot,
                                                      null,
                                                      null,
                                                      scoped
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            }),
                      ]
                    }),
                  ]
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          ),
        ],
        1
      ),
      _vm.showPagination
        ? _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-size": _vm.pagination.limit,
              "page-sizes": _vm.pagination.sizes,
              total: _vm.total,
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          })
        : _vm._e(),
      _c("ColumnConfig", {
        attrs: {
          visible: _vm.visible,
          columns: _vm.columns,
          appendToBody: _vm.appendToBody,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          "column-change": _vm.handleColumnChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }