<template>
    <el-dialog
        title="修改送货路线"
        :visible.sync="visible"
        width="400px"
        center
        @close="closeDialog"
        >
        <div class="dialogContent" v-loading="loading">
          <div class="routeSelectBox">
              <span class="label">送货路线</span>
              <el-select v-model="routeId" allow-create filterable placeholder="请选择送货路线">
                <el-option
                  v-for="item in routes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="confirm">确认修改</el-button>
        </span>
    </el-dialog>
</template>

<script>
  import { getRouteSelectList, saveRoute } from "@/api/appTask";
  import { Notification } from "element-ui";
  export default {
    props: ['orgCode', 'dcCode', 'currentItem'],
    data() {
      return {
        visible: false,
        routeId: '',
        routes: [],
        loading:false
      };
    },
    created() {
      this.routeId = this.currentItem.routeId;
      this.visible = true;
      this.loading=true;
      getRouteSelectList(`?size=100&orgCode=${this.orgCode}&dcCode=${this.dcCode}&page=1`).then(res => {
        this.routes = res.data;
        this.loading=false;
      })
    },
    methods: {
      closeDialog() {
        this.visible = false;
        this.$emit("closeUpdateRouteDialog");
      },
      confirm() {
        // 判断当前的routeId是否在初始化列表里，如果不在就routeId为null,并且传给后端
        let params = {
          orgCode: this.orgCode,
          dcCode: this.dcCode,
          shopCode: this.currentItem.shopCode,
          routeId: this.routes.findIndex(i => i.value === this.routeId) > -1 ? this.routeId : null,
          routeName: this.routes.find(i => i.value === this.routeId)?.label ?? this.routeId
        }
        saveRoute(params).then(res => {
          Notification.success('保存成功')
          this.closeDialog();
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
.routeSelectBox {
  display: flex;
  align-items: center;
  margin: 10px 0 20px;
  .label {
    width: 5em;
  }
  ::v-deep(.el-select) {
    flex: 1;
    .el-input__inner {
      max-width: unset!important;
    }
  }
}
</style>
