var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { float: "right", margin: "5px" } },
        [
          _vm.compType === "sku"
            ? _c(
                "el-input",
                {
                  staticStyle: {
                    width: "140px !important",
                    "margin-right": "10px",
                  },
                  attrs: {
                    clearable: "",
                    size: "mini",
                    placeholder: _vm.scope === "0" ? "商品型号" : "商品编码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery("skus", "skuPage")
                    },
                  },
                  model: {
                    value: _vm.querySkuKeyWord,
                    callback: function ($$v) {
                      _vm.querySkuKeyWord = $$v
                    },
                    expression: "querySkuKeyWord",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        return _vm.handleQuery("skus", "skuPage")
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              )
            : _vm._e(),
          _vm.compType === "shop"
            ? _c(
                "el-input",
                {
                  staticStyle: { width: "120px !important" },
                  attrs: {
                    size: "mini",
                    clearable: "",
                    placeholder: "配送中心编码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery("shops", "shopPage")
                    },
                  },
                  model: {
                    value: _vm.queryDcCode,
                    callback: function ($$v) {
                      _vm.queryDcCode = $$v
                    },
                    expression: "queryDcCode",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        return _vm.handleQuery("shops", "shopPage")
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              )
            : _vm._e(),
          _vm.compType === "shop"
            ? _c(
                "el-input",
                {
                  staticStyle: {
                    width: "120px !important",
                    "margin-left": "5px",
                    "margin-right": "10px",
                  },
                  attrs: {
                    clearable: "",
                    size: "mini",
                    placeholder: "店铺编码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery("shops", "shopPage")
                    },
                  },
                  model: {
                    value: _vm.queryShopCode,
                    callback: function ($$v) {
                      _vm.queryShopCode = $$v
                    },
                    expression: "queryShopCode",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "suffix" },
                    on: {
                      click: function ($event) {
                        return _vm.handleQuery("shops", "shopPage")
                      },
                    },
                    slot: "suffix",
                  }),
                ]
              )
            : _vm._e(),
          _vm.type !== "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: { click: _vm.handleImport },
                },
                [
                  _vm._v(
                    _vm._s(_vm.compType === "sku" ? "导入商品" : "导入店铺") +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.compType === "sku" && _vm.type !== "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      _vm.drawerVisible = true
                    },
                  },
                },
                [_vm._v("添加商品 ")]
              )
            : _vm._e(),
          _vm.compType === "shop" && _vm.type !== "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      _vm.drawerVisible = true
                    },
                  },
                },
                [_vm._v("添加店铺 ")]
              )
            : _vm._e(),
          _vm.type !== "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-remove",
                    type: "danger",
                  },
                  on: { click: _vm.handleRemoveAll },
                },
                [_vm._v("全部移除 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.compType === "sku"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "skuTableRef",
                  staticStyle: { "margin-top": "5px" },
                  attrs: {
                    data: _vm.skuPage.rows,
                    height: "calc(100vh - 400px)",
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                },
                [
                  _vm.scope === "1"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          prop: "skuCode",
                          width: "100",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品型号",
                      prop: "skuModel",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "款式名称", prop: "skuName" },
                  }),
                  _vm.scope === "1"
                    ? _c("el-table-column", {
                        attrs: { label: "规格", prop: "spec", width: "80" },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "货号",
                      prop: "cargoNo",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "大类",
                      prop: "mainCategoryName",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "中类",
                      prop: "midCategoryName",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "小类",
                      prop: "subCategoryName",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "标签", prop: "label", width: "100" },
                  }),
                  _vm.type !== "view"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "70",
                          align: "center",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        border: "0px",
                                        color: "red",
                                      },
                                      attrs: { type: "text", size: "small" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleRemoveRow(
                                            scope.row,
                                            1,
                                            "skus",
                                            "skuPage"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2253429640
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.skuPage.total,
                  page: _vm.skuPage.current,
                  limit: _vm.skuPage.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.skuPage, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.skuPage, "size", $event)
                  },
                  pagination: function ($event) {
                    return _vm.handleQuery("skus", "skuPage")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.compType === "shop"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "shopTableRef",
                  class:
                    !_vm.formLoading && _vm.shops.length === 0
                      ? "table-light-class"
                      : "",
                  staticStyle: { "margin-top": "5px" },
                  attrs: {
                    data: _vm.shopPage.rows,
                    height: "calc(100vh - 300px)",
                    size: "mini",
                    border: "",
                    "empty-text":
                      !_vm.formLoading && _vm.shops.length === 0
                        ? "不添加数据,则视为默认添加当前所选分公司全部店铺"
                        : "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "分公司", prop: "orgName", width: "100" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属配送中心",
                      prop: "dcName",
                      width: "170",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (row.dcCode ? row.dcCode : "") +
                                      (row.dcName ? "-" + row.dcName : "")
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2338758765
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "省份/城市",
                      prop: "provinceName",
                      width: "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (row.provinceName ? row.provinceName : "") +
                                      "-" +
                                      (row.cityName ? "-" + row.cityName : "")
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1325750251
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属区县",
                      prop: "areaName",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "店铺编码/名称",
                      prop: "shop",
                      width: "250",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    (row.shopCode ? row.shopCode : "") +
                                      (row.shopName ? "-" + row.shopName : "")
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      139777549
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属商圈",
                      prop: "businessAreaName",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "区域分组", prop: "areaTag", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "店铺类型",
                      prop: "shopCapacity",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "合作类型", prop: "shopBiz", width: "70" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "是否重点终端",
                      prop: "ifOptimumShop",
                      width: "80",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.ifOptimumShop === "1" ? "是" : "否"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1943928152
                    ),
                  }),
                  _vm.type !== "view"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "70",
                          align: "center",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        border: "0px",
                                        color: "red",
                                      },
                                      attrs: { type: "text", size: "small" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleRemoveRow(
                                            scope.row,
                                            1,
                                            "shops",
                                            "shopPage"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          402664936
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.shopPage.total,
                  page: _vm.shopPage.current,
                  limit: _vm.shopPage.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.shopPage, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.shopPage, "size", $event)
                  },
                  pagination: function ($event) {
                    return _vm.handleQuery("shops", "shopPage")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.compType === "sku" ? "选择商品" : "选择店铺",
            visible: _vm.drawerVisible,
            size: "70%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
            close: () => {
              _vm.skuGroups.refresh = false
              _vm.shopGroups.refresh = false
            },
            open: () => {
              _vm.skuGroups.refresh = true
              _vm.shopGroups.refresh = true
            },
          },
        },
        [
          _vm.compType === "sku" && _vm.scope === "0"
            ? _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo",
                  attrs: {
                    "default-active": _vm.skuGroups.activeIndex,
                    mode: "horizontal",
                  },
                  on: {
                    select: (val, path) =>
                      _vm.handleMenuSelect(val, path, "skuGroups"),
                  },
                },
                [
                  _vm._l(_vm.skuGroups.fixed, function (item) {
                    return _c(
                      "el-menu-item",
                      { key: item.id, attrs: { index: item.id } },
                      [_vm._v(" " + _vm._s(item.groupName) + " ")]
                    )
                  }),
                  _vm.skuGroups.dynamic.length > 0
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "other" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(_vm._s(_vm.skuOtherName)),
                          ]),
                          _vm._l(_vm.skuGroups.dynamic, function (item) {
                            return _c(
                              "el-menu-item",
                              { key: item.id, attrs: { index: item.id } },
                              [_vm._v(" " + _vm._s(item.groupName) + " ")]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.compType === "shop"
            ? _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo",
                  attrs: {
                    "default-active": _vm.shopGroups.activeIndex,
                    mode: "horizontal",
                  },
                  on: {
                    select: (val, path) =>
                      _vm.handleMenuSelect(val, path, "shopGroups"),
                  },
                },
                [
                  _vm._l(_vm.shopGroups.fixed, function (item) {
                    return _c(
                      "el-menu-item",
                      { key: item.id, attrs: { index: item.id } },
                      [_vm._v(" " + _vm._s(item.groupName) + " ")]
                    )
                  }),
                  _vm.shopGroups.dynamic.length > 0
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "other" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(_vm._s(_vm.shopOtherName)),
                          ]),
                          _vm._l(_vm.shopGroups.dynamic, function (item) {
                            return _c(
                              "el-menu-item",
                              { key: item.id, attrs: { index: item.id } },
                              [_vm._v(" " + _vm._s(item.groupName) + " ")]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c("SkuItem", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.compType === "sku",
                expression: "compType==='sku'",
              },
            ],
            ref: "shopItemRef",
            attrs: {
              "group-id": _vm.skuGroups.activeIndex,
              refresh: _vm.skuGroups.refresh,
              scope: _vm.scope,
              "task-id": _vm.taskId,
              pageSkus: _vm.pageSku,
              boost: _vm.boost,
              "show-action": true,
            },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawerVisible = false
              },
              getExceptModels: (callback) => callback(_vm.form.exceptModels),
              getExceptSkuCodes: (callback) =>
                callback(_vm.form.exceptSkuCodes),
            },
          }),
          _c("ShopItem", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.compType === "shop",
                expression: "compType==='shop'",
              },
            ],
            ref: "skuItemRef",
            attrs: {
              "org-code": this.form.checkedOrgCodes,
              "group-id": _vm.shopGroups.activeIndex,
              refresh: _vm.shopGroups.refresh,
              "show-action": true,
            },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawerVisible = false
              },
              getExceptCodes: (callback) => callback(_vm.form.exceptCodes),
            },
          }),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }