const filterColumns = {
  baseInfo: {
    list: ["下单人姓名", "店铺编码", "商品编号", "店铺名称", "商品名称", "大分类", "中分类", "小分类", "商品等级", "吊牌价", "标准陈列量", "最低起订量", "门店性质", "128清单", "活动", "首次到店天数"],
    checked: [],
    checkedBefore: [],
    defaultColumns: ["店铺名称", "商品名称"],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "基础信息",
  },
  stockData: {
    list: ["门店库存", '门店库存成本', "大仓库存", "大仓剩余库存", "大仓最近一次入库时间", "预测周转"],
    checked: [],
    checkedBefore: [],
    defaultColumns: ["大仓最近一次入库时间"],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "库存数据",
  },
  saleCount: {
    list: [
      "前12周",
      "前11周",
      "前10周",
      "前9周",
      "前8周",
      "前7周",
      "前6周",
      "前5周",
      "前4周",
      "前3周",
      "前2周",
      "前1周",
      "过去第1周订单数",
      "过去第2周订单数",
      "过去第3周订单数",
      "30周销量中位数",
    ],
    holidayList: ["前4年同期", "前3年同期", "前2年同期", "前1年同期"],
    checked: [],
    checkedBefore: [],
    defaultColumns: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "销售数据",
  },
  forecastData: {
    list: ["参照历史销售天数", "预测开始日期", "预测结束日期", "预测销售天数", "预测销售数量", "预测补货数量"],
    holidayList: ["预测包含节日", "节日开始日期", "节日结束日期"],
    normalList: ["预测第1周销售数量", "预测第2周销售数量"],
    checked: [],
    checkedBefore: [],
    defaultColumns: [],
    checkAll: false,
    isIndeterminate: false,
    columnLabels: [],
    label: "预测辅助数据",
  },
  supplyData: {
    list: ["总量标识", "最终补货数量", "强补标识", "配货单数量", "补货提示", "调整说明"],
    checked: [],
    checkedBefore: [],
    defaultColumns: ["总量标识", "最终补货数量", "强补标识", "配货单数量", "补货提示", "调整说明"],
    checkAll: true,
    isIndeterminate: false,
    columnLabels: [],
    label: "配货信息",
  }
};

const defaultCheckedColumns = ["店铺名称", "商品名称", "预测销售数量", "预测补货数量", "总量标识", "最终补货数量", "强补标识", "配货单数量", "补货提示", "调整说明", "大仓最近一次入库时间", "大仓剩余库存"];
const disableColumns = ["店铺名称", "商品名称", "强补标识", "总量标识", "最终补货数量", "配货单数量", "补货提示", "调整说明"]; // 列编辑隐藏列表

const allDynamicColumns = {
  "下单人姓名": {
    label: "下单人姓名(基础信息)",
    prop: "xdrName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "店铺编码": {
    label: "店铺编码(基础信息)",
    prop: "storeCode",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "商品编号": {
    label: "商品编号(基础信息)",
    prop: "itemCode",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "店铺名称": {
    label: "店铺名称(基础信息)",
    prop: "storeName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "商品名称": {
    label: "商品名称(基础信息)",
    prop: "itemName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "大分类": {
    label: "大分类(基础信息)",
    prop: "c1CatName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "中分类": {
    label: "中分类(基础信息)",
    prop: "c2CatName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "小分类": {
    label: "小分类(基础信息)",
    prop: "c3CatName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "商品等级": {
    label: "商品等级(基础信息)",
    prop: "topGrade",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "吊牌价": {
    label: "吊牌价(基础信息)",
    prop: "tagPrice",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "采购成本": {
    label: "采购成本(基础信息)",
    prop: "purchaseCost",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "标准陈列量": {
    label: "标准陈列量(基础信息)",
    prop: "displayNum",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "最低起订量": {
    label: "最低起订量(基础信息)",
    prop: "minDisNum",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "中包装": {
    label: "中包装(基础信息)",
    prop: "middleBoxGauge",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "门店性质": {
    label: "门店性质(基础信息)",
    prop: "mdxz",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "128清单": {
    label: "128清单(基础信息)",
    prop: "is128List",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "活动": {
    label: "活动(基础信息)",
    prop: "actName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "首次到店天数": {
    label: "首次到店天数(基础信息)",
    prop: "onNewDays",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },

  "门店库存": {
    label: "门店库存(库存数据)",
    prop: "storeQty",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "门店库存成本": {
    label: "门店库存成本(库存数据)",
    prop: "stockCost",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "大仓库存": {
    label: "大仓库存(库存数据)",
    prop: "storageQty",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "大仓剩余库存": {
    label: "大仓剩余库存(库存数据)",
    prop: "storageSurplusQty",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "库位": {
    label: "库位(库存数据)",
    prop: "kw",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "大仓最近一次入库时间": {
    label: "大仓最近一次入库时间(库存数据)",
    prop: "recentlyRkDate",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测周转": {
    label: "预测周转(库存数据)",
    prop: "ycZz",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测周转上限": {
    label: "预测周转上限(库存数据)",
    prop: "ycZzUpper",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测周转下限": {
    label: "预测周转下限(库存数据)",
    prop: "ycZzLower",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测月转": {
    label: "预测月转(库存数据)",
    prop: "ycYz",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测月转上限": {
    label: "预测月转上限(库存数据)",
    prop: "ycYzUpper",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测月转下限": {
    label: "预测月转下限(库存数据)",
    prop: "ycYzLower",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },

  "前12周": {
    label: "前12周(销售数据)",
    prop: "sellQtyLast12",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前11周": {
    label: "前11周(销售数据)",
    prop: "sellQtyLast11",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前10周": {
    label: "前10周(销售数据)",
    prop: "sellQtyLast10",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前9周": {
    label: "前9周(销售数据)",
    prop: "sellQtyLast9",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前8周": {
    label: "前8周(销售数据)",
    prop: "sellQtyLast8",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前7周": {
    label: "前7周(销售数据)",
    prop: "sellQtyLast7",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前6周": {
    label: "前6周(销售数据)",
    prop: "sellQtyLast6",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前5周": {
    label: "前5周(销售数据)",
    prop: "sellQtyLast5",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前4周": {
    label: "前4周(销售数据)",
    prop: "sellQtyLast4",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前3周": {
    label: "前3周(销售数据)",
    prop: "sellQtyLast3",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前2周": {
    label: "前2周(销售数据)",
    prop: "sellQtyLast2",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前1周": {
    label: "前1周(销售数据)",
    prop: "sellQtyLast1",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "过去第1周订单数": {
    label: "过去第1周订单数(销售数据)",
    prop: "orderCntLast1",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "过去第2周订单数": {
    label: "过去第2周订单数(销售数据)",
    prop: "orderCntLast2",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "过去第3周订单数": {
    label: "过去第3周订单数(销售数据)",
    prop: "orderCntLast3",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "30周销量中位数": {
    label: "30周销量中位数(销售数据)",
    prop: "sellQtyMedian",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前4年同期": {
    label: "前4年同期(销售数据)",
    prop: "holSellQtyLy4",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前3年同期": {
    label: "前3年同期(销售数据)",
    prop: "holSellQtyLy3",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前2年同期": {
    label: "前2年同期(销售数据)",
    prop: "holSellQtyLy2",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "前1年同期": {
    label: "前1年同期(销售数据)",
    prop: "holSellQtyLy1",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },

  "参照历史销售天数": {
    label: "参照历史销售天数(预测辅助数据)",
    prop: "czDayNum",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测开始日期": {
    label: "预测开始日期(预测辅助数据)",
    prop: "ycBeginDate",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测结束日期": {
    label: "预测结束日期(预测辅助数据)",
    prop: "ycEndDate",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测销售天数": {
    label: "预测销售天数(预测辅助数据)",
    prop: "ycSellDayNum",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测包含节日": {
    label: "预测包含节日(预测辅助数据)",
    prop: "holName",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "节日开始日期": {
    label: "节日开始日期(预测辅助数据)",
    prop: "holBeginDate",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "节日结束日期": {
    label: "节日结束日期(预测辅助数据)",
    prop: "holEndDate",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测第1周销售数量": {
    label: "预测第1周销售数量(预测辅助数据)",
    prop: "ycSellQtyWeek1",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测第2周销售数量": {
    label: "预测第2周销售数量(预测辅助数据)",
    prop: "ycSellQtyWeek2",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测销售数量": {
    label: "预测销售数量(预测辅助数据)",
    prop: "ycSellQty",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  "预测补货数量": {
    label: "预测补货数量(预测辅助数据)",
    prop: "ycBhQty",
    showOverflowTooltip: true,
    minWidth: "100px",
    align: "center",
    sortable: true
  },
  // "最终补货数量": {
  //   label: "最终补货数量(预测辅助数据)",
  //   prop: "zzBhQty",
  //   showOverflowTooltip: true,
  //   minWidth: "100px",
  //   align: "center",
  //   sortable: true
  // },
  // "配货单数量": {
  //   label: "配货单数量(预测辅助数据)",
  //   prop: "phdQty",
  //   showOverflowTooltip: true,
  //   minWidth: "100px",
  //   align: "center",
  //   sortable: true
  // },
  // "调整说明": {
  //   label: "调整说明(预测辅助数据)",
  //   prop: "remark",
  //   showOverflowTooltip: true,
  //   minWidth: "100px",
  //   align: "center",
  //   sortable: true
  // },
};

const compareColumns = [
  {label: "吊牌价(基础信息)", prop: "tag_price"},
  {label: "标准陈列量(基础信息)", prop: "display_num"},
  {label: "最低起订量(基础信息)", prop: "min_dis_num"},
  {label: "中包装(基础信息)", prop: "middle_box_gauge"},
  {label: "采购成本(基础信息)", prop: "purchase_cost"},
  {label: "首次到店天数(基础信息)", prop: "on_new_days"},

  {label: "门店库存(库存数据)", prop: "store_qty"},
  {label: "门店库存成本(库存数据)", prop: "stock_cost"},
  {label: "大仓库存(库存数据)", prop: "storage_qty"},
  {label: "大仓剩余库存(库存数据)", prop: "storage_surplus_qty"},
  {label: "库位(库存数据)", prop: "kw"},
  {label: "预测周转(库存数据)", prop: "yc_zz"},
  {label: "预测周转上限(库存数据)", prop: "yc_zz_upper"},
  {label: "预测周转下限(库存数据)", prop: "yc_zz_lower"},
  {label: "预测月转(库存数据)", prop: "yc_yz"},
  {label: "预测月转上限(库存数据)", prop: "yc_yz_upper"},
  {label: "预测月转下限(库存数据)", prop: "yc_yz_lower"},

  {label: "过去第1周订单数(销售数据)", prop: "order_cnt_last_1"},
  {label: "过去第2周订单数(销售数据)", prop: "order_cnt_last_2"},
  {label: "过去第3周订单数(销售数据)", prop: "order_cnt_last_3"},
  {label: "30周销量中位数(销售数据)", prop: "sell_qty_median"},
  {label: "前1周(销售数据)", prop: "sell_qty_last_1"},
  {label: "前2周(销售数据)", prop: "sell_qty_last_2"},
  {label: "前3周(销售数据)", prop: "sell_qty_last_3"},
  {label: "前4周(销售数据)", prop: "sell_qty_last_4"},
  {label: "前5周(销售数据)", prop: "sell_qty_last_5"},
  {label: "前6周(销售数据)", prop: "sell_qty_last_6"},
  {label: "前7周(销售数据)", prop: "sell_qty_last_7"},
  {label: "前8周(销售数据)", prop: "sell_qty_last_8"},
  {label: "前9周(销售数据)", prop: "sell_qty_last_9"},
  {label: "前10周(销售数据)", prop: "sell_qty_last_10"},
  {label: "前11周(销售数据)", prop: "sell_qty_last_11"},
  {label: "前12周(销售数据)", prop: "sell_qty_last_12"},
  // { label: "前13周(销售数据)", prop: "sell_qty_last_13" },
  // { label: "前14周(销售数据)", prop: "sell_qty_last_14" },
  // { label: "前15周(销售数据)", prop: "sell_qty_last_15" },
  // { label: "前16周(销售数据)", prop: "sell_qty_last_16" },
  // { label: "前17周(销售数据)", prop: "sell_qty_last_17" },
  // { label: "前18周(销售数据)", prop: "sell_qty_last_18" },
  // { label: "前19周(销售数据)", prop: "sell_qty_last_19" },
  // { label: "前20周(销售数据)", prop: "sell_qty_last_20" },
  // { label: "前21周(销售数据)", prop: "sell_qty_last_21" },
  // { label: "前22周(销售数据)", prop: "sell_qty_last_22" },
  // { label: "前23周(销售数据)", prop: "sell_qty_last_23" },
  // { label: "前24周(销售数据)", prop: "sell_qty_last_24" },
  // { label: "前25周(销售数据)", prop: "sell_qty_last_25" },
  // { label: "前26周(销售数据)", prop: "sell_qty_last_26" },
  // { label: "前27周(销售数据)", prop: "sell_qty_last_27" },
  // { label: "前28周(销售数据)", prop: "sell_qty_last_28" },
  // { label: "前29周(销售数据)", prop: "sell_qty_last_29" },
  // { label: "前30周(销售数据)", prop: "sell_qty_last_30" },

  {label: "前1年同期(销售数据)", prop: "hol_sell_qty_ly_1"},
  {label: "前2年同期(销售数据)", prop: "hol_sell_qty_ly_2"},
  {label: "前3年同期(销售数据)", prop: "hol_sell_qty_ly_3"},
  {label: "前4年同期(销售数据)", prop: "hol_sell_qty_ly_4"},

  {
    label: "参照历史销售天数(预测辅助数据)",
    prop: "cz_day_num",
  },
  {label: "预测第1周销售数量", prop: "yc_sell_qty_week_1"},
  {label: "预测第2周销售数量", prop: "yc_sell_qty_week_2"},
  {label: "预测销售数量", prop: "yc_sell_qty"},
  {label: "预测补货数量", prop: "yc_bh_qty"},
  {label: "最终补货数量", prop: "zz_bh_qty"},
];

export {
  filterColumns, // 列编辑过滤列
  allDynamicColumns, // 动态列
  compareColumns,
  defaultCheckedColumns,
  disableColumns
};
