var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.className }, [
    _c(
      "h4",
      {
        staticClass: "item default small3",
        style: _vm.computeStyles(_vm.task),
      },
      [
        _c("div", { staticClass: "taskInfo" }, [
          _c("div", { staticClass: "taskInfoL" }, [
            _c(
              "p",
              {
                staticClass: "taskName text-1 closed-p",
                style: { color: _vm.task.configJson.theme?.taskName?.color },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.task.configJson.theme.taskName.text
                      ? _vm.task.configJson.theme.taskName.text
                      : _vm.task.taskName
                  )
                ),
              ]
            ),
            _c("p", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.task.isLeaf === 0,
                  expression: "task.isLeaf===0",
                },
              ],
              staticClass: "taskRange closed-p",
              style: "width: fit-content;",
              domProps: { innerHTML: _vm._s("&nbsp;") },
            }),
            _vm.task.isLeaf !== 0
              ? _c(
                  "p",
                  {
                    staticClass: "taskRange closed-p",
                    style: "width: fit-content;",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.task.taskRange === "1"
                          ? "长期任务"
                          : +_vm.parseShowTime(_vm.task?.startDate, "{m}.{d}") +
                              "-" +
                              _vm.parseShowTime(_vm.task?.endDate, "{m}.{d}")
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "cover" }, [
            _c("img", {
              attrs: {
                src:
                  _vm.task.configJson.theme.cover &&
                  _vm.task.configJson.theme.cover.background
                    ? _vm.task.configJson.theme.cover.background.image
                    : _vm.task.skuUrl,
                alt: "",
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "dataBox" },
            _vm._l(_vm.task.configJson.summary, function (item, index) {
              return _c("div", { key: index, staticClass: "numBox" }, [
                _c(
                  "p",
                  {
                    staticClass: "label closed-p",
                    style:
                      "width: max-content;color:" +
                      _vm.hexToRgba(item.value.color, 0.7),
                  },
                  [_vm._v(_vm._s(item.title) + " ")]
                ),
                _c("p", { staticClass: "value closed-p" }, [
                  _c(
                    "span",
                    { style: "font-size: 13; color:" + item.value.color },
                    [
                      _c("span", [_vm._v(_vm._s(item.prefix))]),
                      _vm._v(" 0 " + _vm._s(item.unit)),
                    ]
                  ),
                ]),
              ])
            }),
            0
          ),
          _c("div", { staticClass: "more" }, [
            _c(
              "span",
              {
                style:
                  "color:" + _vm.task.configJson.theme?.moreDataText?.color,
              },
              [
                _vm._v(_vm._s(_vm.task.isLeaf !== 0 ? "更多数据" : "任务合集")),
                _vm.task.isLeaf !== 0
                  ? _c("i", { staticClass: "el-icon-arrow-down" })
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }