<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc"
            :dictFun="dictFun"
            fullLabel
            :clearable="orgAllAuth"
            :init="true"
            placeholder="全部"
            :init-options="this.options.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            collapse-tags
            :clearable="true"
            placeholder="全部"
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @change="dictParam = Object.assign({}, queryForm)"
          />
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input v-model="queryForm.skuCode"  placeholder="商品编码" />
        </el-form-item>
        <el-form-item label="商品型号">
          <el-input v-model="queryForm.skuModel" placeholder="商品型号" />
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="queryForm.timeRange"
            type="datetimerange"
            style="width: 350px !important;"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
    <el-table
     style="margin-top: 10px;"
      :data="pageData.rows"
      v-loading="loading"
      class="customer-table"
      border
            size="small"
      ref="table"
      highlight-current-row
      show-summary
      :summary-method="handleSummary"
      @sort-change="handleSortChange"

    >
      <el-table-column label="分公司编码" prop="orgCode"   width="140"/>
      <el-table-column label="分公司名称" prop="orgName"  width="140"/>
      <el-table-column label="配送中心编码" prop="dcCode"  width="140"/>
      <el-table-column label="配送中心名称" prop="dcName"  width="140"/>
      <el-table-column label="商品编码" prop="skuCode"  width="140"/>
      <el-table-column label="商品属性" prop="attributeName"   width="140"/>
      <el-table-column label="商品名称" prop="skuName"  width="200"/>
      <el-table-column label="商品型号" prop="skuModel"  width="140"/>
      <el-table-column label="下单金额" prop="orderMoney" sortable="custom"  width="140" />
      <el-table-column label="下单数量" prop="orderNum" sortable="custom"  width="140"/>
      <el-table-column label="发货金额" prop="fhMoney" sortable="custom" width="140"/>
      <el-table-column label="发货数量" prop="fhNum" sortable="custom" width="140" />
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index.vue";
import {
  topShopDict,
  getNoStockOrderDetailReport,
  exportNoStockOrderDetailReport,
  getNoStockOrderDetailReportOverview,
  dictSelect,
} from "@/api/appTask";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect/index.vue";

export default {
  name: "noStockOrder",
  components: {DictSelect, Pagination, SearchFilter },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 100,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        orgCode: undefined,
        dcCode: undefined,
        skuCode: undefined,
        skuModel: undefined,
        timeRange: [],
        sorts: undefined,
      },
      loading: false,
      dictParam: {},
      dictFun: topShopDict,
      orgAllAuth: false,
      totalMap: {},
      options: {
        orgOptions: []
      }
    };
  },
  computed: {
    timeDefault () {
      let date = new Date()
      // 通过时间戳计算
      let defaultStartTime = date.getTime() - 7 * 24 * 3600 * 1000 // 转化为时间戳
      let defaultEndTime = date.getTime()
      let startDateNs = new Date(defaultStartTime)
      let endDateNs = new Date(defaultEndTime)
      // 月，日 不够10补0
      defaultStartTime = startDateNs.getFullYear() + '-' + ((startDateNs.getMonth() + 1) >= 10 ? (startDateNs.getMonth() + 1) : '0' + (startDateNs.getMonth() + 1)) + '-' + (startDateNs.getDate() >= 10 ? startDateNs.getDate() : '0' + startDateNs.getDate())
      defaultEndTime = endDateNs.getFullYear() + '-' + ((endDateNs.getMonth() + 1) >= 10 ? (endDateNs.getMonth() + 1) : '0' + (endDateNs.getMonth() + 1)) + '-' + (endDateNs.getDate() >= 10 ? endDateNs.getDate() : '0' + endDateNs.getDate())
      defaultStartTime = defaultStartTime + ' 00:00:00'
      defaultEndTime = defaultEndTime + ' 23:59:59'
      return [defaultStartTime, defaultEndTime]
    }
  },

  watch: {
  },
  created() {
    this.queryForm.timeRange = this.timeDefault
  },
  mounted() {
    dictSelect('ht-org-all').then(res => {
        this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
        this.options.orgOptions = res.data.filter(e => e.value !== 'ALL');
        if (!this.orgAllAuth) {
          this.queryForm.orgCode = this.options.orgOptions[0].value;
        }
    })
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      if (this.queryForm.timeRange) {
        if (this.queryForm.timeRange.length > 0) {
          this.queryForm.createTimeBegin = this.queryForm.timeRange[0];
        }
        if (this.queryForm.timeRange.length > 1) {
          this.queryForm.createTimeEnd = this.queryForm.timeRange[1];
        }
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
      getNoStockOrderDetailReportOverview(this.queryForm).then(res => {
        if (!res.data) {
          return;
        }
        this.totalMap = res.data
      });
      getNoStockOrderDetailReport(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        setTimeout(() => this.loading = false, 500)
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 100,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleExport() {
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportNoStockOrderDetailReport(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', `无库存下单商品报表.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleSummary({ columns }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';//可更改合计行的名称
          return;
        }
        if (this.totalMap[column.property] !== undefined) {
          sums[index] = this.totalMap[column.property];
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    handleSortChange(data) {
      const {prop, order} = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for (const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}

.select-radio {
  display: block;
  line-height: 23px;
  white-space: normal;
  margin-right: 0;
}
//  /deep/ 为深度操作符，可以穿透到子组件
::v-deep .el-table {
  display: flex;
  flex-direction: column;
}
//  order默认值为0，只需将表体order置为1即可移到最后，这样合计行就上移到表体上方
::v-deep .el-table__body-wrapper {
  order: 1;
}

</style>
