<template>
  <div class="salesCenter-page">
    <el-tabs class="salesCenter-tabs" tab-position="top" v-model="activeName" style="height: 200x;">

      <el-tab-pane label="定额情况(万元)" class="salesCenter-tabs-item" name="wanSituation">
        <iframe src="//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E5%25AE%259A%25E9%25A2%259D%25E4%25B8%2587%25E5%2585%2583.cpt&ref_t=design&ref_c=eb0ff519-d716-479d-87dc-f5174f363200" frameborder="0" style="width: 100%; height: 90vh;"></iframe>
      </el-tab-pane>
      <el-tab-pane label="定额情况(箱)" class="salesCenter-tabs-item" name="boxSituation">
        <iframe src="//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E5%25AE%259A%25E9%25A2%259D%25E7%25AE%25B1.cpt&ref_t=design&ref_c=eb0ff519-d716-479d-87dc-f5174f363200" frameborder="0" style="width: 100%; height: 90vh;"></iframe>
      </el-tab-pane>
      <el-tab-pane label="整体下单情况" class="salesCenter-tabs-item" name="orderSituation">
        <iframe src="//finreport.bi.mgmos.com.cn/decision/view/report?viewlet=MGReport%252FDW%252F%25E8%25AE%25A2%25E5%258D%2595%25E9%2583%25A8%252F%25E6%2595%25B4%25E4%25BD%2593%25E4%25B8%258B%25E5%258D%2595%25E6%2583%2585%25E5%2586%25B5.cpt&ref_t=design&ref_c=7ef9bea4-759e-44ce-ac8d-04b2a6c37d27" frameborder="0" style="width: 100%; height: 90vh;"></iframe>
      </el-tab-pane>
      <el-tab-pane label="分类设定" class="salesCenter-tabs-item" name="classifyConfig">
        <classify-config />
      </el-tab-pane>
      <!-- 库存系数设定 -- 个别调整 -->
      <el-tab-pane label="个别调整" class="salesCenter-tabs-item" name="onlineWrite">
        <div class="salesCenter-onlineWrite" v-if="activeName == 'onlineWrite'">
          <div class="salesCenter-search">
            <v-signboard :title="'库存系数设定'"/>
            <el-form
              class="main-search"
              size="mini"
              label-width="auto"
              ref="filialeCustome-form"
              :inline="true"
              :model="form"
              @keyup.enter.native="handleSubmit"
            >
              <el-form-item label="产品等级" size="mini">
                <el-select clearable v-model="form.productGrade" multiple style="width:90px">
                  <el-option
                    v-for="item in options.productGradeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="大类" size="mini">
                <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
                  <el-option
                    v-for="item in options.productMaxCategorieOtions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="中类" size="mini">
                <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                  <el-option
                    v-for="item in options.productMidCategorieOtions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="小类" size="mini">
                <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
                  <el-option
                    v-for="item in options.productMinCategorieOtions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="生产类型" size="mini">
                <el-select clearable v-model="form.prod_type" style="width:90px">
                  <el-option label="生产中心" value="生产中心"></el-option>
                  <el-option label="OEM中心" value="OEM中心"></el-option>
                  <el-option label="办公" value="办公"></el-option>
                  <el-option label="生产中心/办公" value="生产中心/办公"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="产品赛道" size="mini">
                <el-select clearable v-model="form.race_name" style="width:90px">
                  <el-option
                    v-for="item in options.raceNameLists"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="原始料号" size="mini">
                <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
              </el-form-item>

              <el-form-item label="库存系数类型" size="mini">
                <el-select clearable v-model="form.data_type">
                  <el-option label="全部" :key="0" :value="0"></el-option>
                  <el-option label="库存超量" :key="2" :value="2"></el-option>
                  <el-option label="库存告警" :key="3" :value="3"></el-option>
                  <el-option label="上下限错误" :key="4" :value="4"></el-option>
                  <el-option label="库存正常" :key="1" :value="1"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="品名" size="mini">
                <el-input v-model="form.plu_name" clearable placeholder="请输入品名"/>
              </el-form-item>
              <el-form-item label="统计品类" size="mini">
                <el-input v-model="form.stats_category_name" clearable placeholder="请输入统计品类"/>
              </el-form-item>

              <el-form-item label="预淘汰品" size="mini">
                <el-select clearable v-model="form.dead_sku_type">
                  <el-option label="全部" :key="0" :value="0"></el-option>
                  <el-option label="是" :key="1" :value="1"></el-option>
                  <el-option label="否" :key="2" :value="2"></el-option>
                </el-select>
              </el-form-item>

              <el-button
                class="form-btn-item"
                size="mini"
                type="primary"
                :loading="btnStatus.isSub"
                @click="handleSubmit"
              >查 询
              </el-button>
              <el-button
                class="form-btn-item"
                size="mini"
                :loading="btnStatus.isReset"
                @click="handleReset"
              >重 置
              </el-button>
              <el-button
                size="mini"
                type="primary"
                :disabled="btnStatus.isSave"
                @click="handleTableHeadAction('save')"
              >保 存
              </el-button>

              <el-button
                size="mini"
                type="primary"
                :loading="btnStatus.isExportLoading"
                @click="handleTableHeadAction('export')"
              >导 出
              </el-button>

              <el-button
                v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader') "
                size="mini"
                type="warning"
                @click="handleTableHeadAction('adjust')"
              >确 认 调 整
              </el-button>
            </el-form>
          </div>
          <div class="salesCenter-content">
            <el-table
              class="table"
              stripe
              border
              size="mini"
              ref="multipleTable"
              :data="lists"
              v-loading="tableStatus.isLoading"
              @selection-change="handleselection"
            >
              <el-table-column type="selection" width="40" fixed="left"/>
              <el-table-column label="序号" align="center" width="50" fixed="left">
                <template slot-scope="scope">
                  <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
                </template>
              </el-table-column>
              <template v-for="(item, index) in tableObj.coefOfInventSettingHead">
                <!-- 库存系数上限 / 库存系数下限 / 库存系数目标 -- 可编辑 -->
                <el-table-column
                  v-if="[ 'hg_store_ratio_up', 'hg_store_ratio_down', 'hg_store_ratio_aim'].includes(item.prop)"
                  v-bind="item" :key="index" align="center">
                  <template slot-scope="scope">
                    <el-input class="tgqrcount-cell-ipt" type="number" :disabled="scope.row.isEdit"
                              v-if=" item.prop == 'hg_store_ratio_up'" v-model="scope.row.hg_store_ratio_up" size="mini"
                              clearable/>
                    <el-input class="tgqrcount-cell-ipt" type="number" :disabled="scope.row.isEdit"
                              v-if=" item.prop == 'hg_store_ratio_down'" v-model="scope.row.hg_store_ratio_down"
                              size="mini" clearable/>
                    <el-input class="tgqrcount-cell-ipt" type="number" :disabled="scope.row.isEdit"
                              v-if=" item.prop == 'hg_store_ratio_aim'" v-model="scope.row.hg_store_ratio_aim"
                              size="mini" clearable/>
                  </template>
                </el-table-column>
                <!-- 是否使用系统默认值 -- 1 使用 0 不使用 -->
                <el-table-column v-else-if="item.prop == 'use_default'" fixed="right" v-bind="item"
                                 show-overflow-tooltip :key="index" align="center">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.use_default_status"
                               @change="handleSwitchChange(scope.$index, scope.row)"/>
                  </template>
                </el-table-column>
                <el-table-column v-else-if="['cargono'].includes(item.prop)"
                                 :fixed="['cargono'].includes(item.prop) ? 'left' : ''"
                                 v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
                <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index"
                                 align="center"></el-table-column>
              </template>
            </el-table>
            <!-- 分页器 -->
            <v-pagination
              :total="total"
              :pageSize="pageSize"
              :currentPage="currentPage"
              @pageChange="pagingChange"
            />
          </div>
        </div>
      </el-tab-pane>
      <!-- 库存系数设定 -- 表单上传 -->
      <el-tab-pane label="表单上传" class="salesCenter-tabs-item" name="formWrite">
        <div clas="form-upload-box" v-if="activeName == 'formWrite'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'"/>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item"
                                 align="center"/>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'"/>
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                height="500"
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport, keepTwoDecimals, thousandsSeparator} from "@/utils";
import {coefficientTemplateSampleHead, coefOfInventSettingHead} from '../config';
import {
  handleAdjustStoreRatio,
  handleStoreRatioConfigPage,
  handleSaveStoreRatio,
  handleStoreRatioImport
} from '@/api/salesCenter';
import ClassifyConfig from "./classifyConfig";

export default {
  // 库存系数设定
  name: 'coefficientOfInventorySetting',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    ClassifyConfig,
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination"),
  },
  data() {
    return {
      originalForm: {},                       // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 是否保存
        isAdjust: true                  // 是否调整
      },
      lists: [],
      classifyConfigLists: [],
      tableObj: {
        coefOfInventSettingHead
      },
      selectRows: [],
      uploadFileObj: {
        tipsText: '必需字段: 商品编码、库存系数上限、库存系数下限、库存系数目标',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: coefficientTemplateSampleHead,
        templateSampleLists: [
          {
            plan_month: '',
            plu_code: '00004136',
            plu_name: '晨光中性笔考试必备KGP1821A-09黑0.5',
            hg_store_ratio_up: '1.5',
            hg_store_ratio_down: '0.5',
            hg_store_ratio_aim: 1
          }
        ]
      },
      urlMonth: '',
      activeName: 'wanSituation'
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isAdjust = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: ''
    };
    this.form = formPrams;
    this.originalForm = Object.assign({}, this.form);
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  methods: {
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    handleClassifyConfigQuery() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getClassifyConfigLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        prod_type: '',
        plu_name : '',
        stats_category_name : '',
        dead_sku_type : 0,
      };
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          race_name: form.race_name || '',
          prod_type: form.prod_type || '',
          data_type: form.data_type || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          material_code: form.material_code,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          plu_name : form.plu_name,
          stats_category_name: form.stats_category_name,
          dead_sku_type : form.dead_sku_type
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleStoreRatioConfigPage(params);
        this.tableStatus.isLoading = false;
        if (data) {
          const {store_ratio_list, total} = data;
          this.modifyData(store_ratio_list).then(res => {
            this.lists = res;
          });
          this.total = total;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取库存系数列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            plan_month: item.plan_month || '--',
            prod_type: item.prod_type || '--',
            plu_code: item.plu_code || '--',
            material_code: item.material_code || '--',
            plu_id: item.plu_id || '--',
            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            plu_name: item.plu_name || '--',
            cargono: item.cargono || '--',

            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',

            grade: item.grade || '--',

            hg_last_month_store_ratio: keepTwoDecimals(item.hg_last_month_store_ratio) || 0,
            hg_last_month_store_count_box: thousandsSeparator(item.hg_last_month_store_count_box, 0) || 0,
            hq_pre_last_six_month_avg_sale_count_box: thousandsSeparator(item.hq_pre_last_six_month_avg_sale_count_box, 0) || 0,
            bo_kd_plu_sale_count_box: thousandsSeparator(item.bo_kd_plu_sale_count_box, 0) || 0,
            hg_old_plu_previous_put_count_box: thousandsSeparator(item.hg_old_plu_previous_put_count_box, 0) || 0,
            hg_month_avg_sale_box: thousandsSeparator(item.hg_month_avg_sale_box, 0) || 0,

            hg_store_ratio_up: item.use_default == 1 ? item.hg_default_store_ratio_up : item.hg_store_ratio_up || 2,
            hg_store_ratio_down: item.use_default == 1 ? item.hg_default_store_ratio_down : item.hg_store_ratio_down || 0,
            hg_store_ratio_aim: item.use_default == 1 ? item.hg_default_store_ratio_aim : item.hg_store_ratio_aim || 1,

            hg_default_store_ratio_aim: item.hg_default_store_ratio_aim,
            hg_default_store_ratio_down: item.hg_default_store_ratio_down,
            hg_default_store_ratio_up: item.hg_default_store_ratio_up,

            use_default: item.use_default || 0,
            use_default_status: item.use_default == 0 ? false : true,
            isEdit: item.use_default == 1 ? true : false,
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 列表操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {        // 保存
          let newItems = [];
          console.log(this.selectRows);
          this.selectRows.map(item => {
            let newItem = {
              hg_store_ratio_aim: item.use_default == 1 ? item.hg_default_store_ratio_aim : item.hg_store_ratio_aim || 0,
              hg_store_ratio_down: item.use_default == 1 ? item.hg_default_store_ratio_down : item.hg_store_ratio_down || 0,
              hg_store_ratio_up: item.use_default == 1 ? item.hg_default_store_ratio_up : item.hg_store_ratio_up || 0,
              plan_month: item.plan_month || '',
              plu_code: item.plu_code || '',
              use_default: item.use_default
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            order_month: this.urlMonth,
            store_ratio_list: newItems
          };
          const {code, msg} = await handleSaveStoreRatio(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'adjust': async () => {     // 确认审核
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator
          };
          const {success} = await handleAdjustStoreRatio(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('分公司客订检核确认调整已成功');
          }
          if (!success) this.getLists();
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      let topHeaders = {}
      for(let i = 0; i < coefOfInventSettingHead.length; i++) {
        let row = coefOfInventSettingHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          race_name: form.race_name || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          material_code: form.material_code,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
        };

        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleStoreRatioConfigPage(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {store_ratio_list} = data;
          if (store_ratio_list.length > 0) {
            excelExport({
              topHeaders,
              data: store_ratio_list,
              title: '库存系数设定列表'
            })
          }
          if (store_ratio_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------库存系数设定列表导出接口有误');
      }
    },
    // 监听是否使用系统默认值发生change
    handleSwitchChange(index, row) {
      this.lists.map((item, i) => {
        if (i === index) {
          if (row.isEdit) {
            row.isEdit = false;
            row.use_default = 0;
            return;
          }
          if (!row.isEdit) {
            row.isEdit = true;
            row.use_default = 1;
            return;
          }
        }
      })
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header
    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;
      if (uploadFileObj.tableHeader.indexOf('商品编码') === -1) return this.$message.warning('缺少商品编码字段');
      if (uploadFileObj.tableHeader.indexOf('库存系数上限') === -1) return this.$message.warning('缺少库存系数上限字段');
      if (uploadFileObj.tableHeader.indexOf('库存系数下限') === -1) return this.$message.warning('缺少库存系数下限字段');
      if (uploadFileObj.tableHeader.indexOf('库存系数目标') === -1) return this.$message.warning('缺少库存系数目标字段');
      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let newItem = {
          plan_month: item['年月'],
          plu_code: item['商品编码'],
          hg_store_ratio_up: item['库存系数上限'],
          hg_store_ratio_down: item['库存系数下限'],
          hg_store_ratio_aim: item['库存系数目标']
        }
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        store_ratio_list: newItems
      };
      const {code, success, msg} = await handleStoreRatioImport(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (success) return this.$message.success(msg);
      if (!success) this.activeName = 'onlineWrite';
    }
  }
}
</script>
<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
