<template>
  <div>
    <div style="padding: 10px">
      <el-button class="form-btn-item" style="margin-bottom: 10px;" size="mini" type="primary" @click="handleAddTag">新建逻辑标签</el-button>
      <el-button type="primary" size="mini" :loading="uploadLoading" @click="handleQwTagPushImport" >企微标签推送</el-button>
      <span style="margin-left: 20px">{{ qwTagPushNotifyContent }}</span>
    </div>
    <el-dialog title="" :visible.sync="addDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="addForm" size="mini" label-width="100px" :model="addDialog.form" :rules="addDialog.rules">
        <el-form-item label="新建方式" prop="addWay">
          <el-radio-group v-model="addDialog.form.addWay" size="mini">
            <el-radio label="单独新建" />
            <el-radio label="批量上传" />
          </el-radio-group>
        </el-form-item>
        <div v-show="addDialog.form.addWay === '批量上传'" >
          <el-form-item label="模板下载">
            <el-link type="primary" :underline="false" style="font-size:14px;vertical-align: baseline;" target="_blank" :href="addDialog.templateFileDownloadUrl">逻辑标签批量新建模板</el-link>
          </el-form-item>
          <el-form-item label="上传">
            <el-upload
              ref="addUpload"
              class="upload-demo"
              :limit="1"
              action="#"
              :auto-upload="false"
              :on-change="(file, fileList) => handleFileChange(file, fileList, 'change', 'addDialog')"
              :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove', 'addDialog')"
              :file-list="addDialog.upload.fileList">
              <el-button v-show="addDialog.upload.fileList.length === 0" size="mini" type="primary">点击上传标签</el-button>
            </el-upload>
          </el-form-item>
        </div>
        <div v-show="addDialog.form.addWay === '单独新建'">
          <el-form-item label="业务域" prop="buDomain">
            <el-select v-model="addDialog.form.buDomain" size="mini" class="half-width">
              <el-option v-for="item in addDialog.options.buDomains" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="标签类型" prop="tagType">
            <el-select v-model="addDialog.form.tagType" size="mini" class="half-width">
              <el-option v-for="item in addDialog.options.tagTypes" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="一级分类" prop="c1TagName">
            <el-select v-model="addDialog.form.c1TagName" filterable default-first-option size="mini" allow-create class="half-width" @input="val => handleC1Change(val, 'add')">
              <el-option v-for="item in addDialog.options.c1TagNames" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="二级分类" prop="c2TagName">
            <el-select v-model="addDialog.form.c2TagName" filterable default-first-option size="mini" allow-create class="half-width">
              <el-option v-for="item in addDialog.options.c2TagNames" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="标签名称" prop="tagName">
            <el-input v-model="addDialog.form.tagName" size="mini" class="half-width" placeholder="请输入标签名称" />
          </el-form-item>
          <el-form-item label="表字段名称" prop="tagColName">
            <el-select v-model="addDialog.form.tagColName" size="mini" filterable class="half-width">
              <el-option v-for="item in addDialog.options.tagColNames" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="有效期" prop="validityDate">
            <el-radio-group v-model="addDialog.form.available" size="mini" @input="handleAvailable">
              <el-radio label="永久" />
              <el-radio label="失效日期" />
            </el-radio-group>
            <el-date-picker v-if="addDialog.form.available==='失效日期'" v-model="addDialog.form.validityDate" placeholder="请选择" style="margin-left: 5px" type="date" format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item label="枚举值穷举">
            <el-radio-group v-model="addDialog.form.define" size="mini">
              <el-radio label="是" />
              <el-radio label="否" />
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="addDialog.form.define === '是'" label="是否推送企微">
            <el-radio-group v-model="addDialog.form.ifPushQw" @input="handleTagDefinitions" size="mini">
              <el-radio label="Y">是</el-radio>
              <el-radio label="N">否</el-radio>
              <el-radio label="P">部分</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签定义" prop="tagDefinitions">
            <el-input v-if="addDialog.form.define === '否'" v-model="addDialog.form.tagDefine" size="mini" class="half-width" placeholder="请输入标签名称" @change="handleAddDefineChange" />
            <el-button v-if="addDialog.form.define === '是'" size="mini" @click="handleAddDefineRows">新增</el-button>
            <el-table
              v-if="addDialog.form.define === '是'"
              :data="addDialog.form.tagDefinitions"
              size="mini"
              border
              highlight-current-row
              :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            >
              <el-table-column label="标签值" prop="tagVal">
                <template slot-scope="{ row }">
                  <el-input v-model="row.tagVal" type="textarea" placeholder="请输入标签值" @input="val => handleWxTagVar(val, row)" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="定义" prop="tagDefine">
                <template slot-scope="{ row }">
                  <el-input v-model="row.tagDefine" type="textarea" placeholder="请输入标签定义" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="是否推送企微" prop="ifPushQw">
                <template slot-scope="{ row }">
                  <el-select v-model="row.ifPushQw" size="mini" placeholder="下拉选择是否推送企微" :disabled="addDialog.form.ifPushQw === 'Y' || addDialog.form.ifPushQw === 'N'" @input="handlePartPushQwWxTagVar">
                    <el-option value="Y" label="是">是</el-option>
                    <el-option value="N" label="否">否</el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="企微标签名称" prop="wxTagVal">
                <template slot-scope="{ row }">
                  <el-input v-model="row.wxTagVal" type="textarea" placeholder="请输入标签定义" :disabled="addDialog.form.ifPushQw === 'Y' || addDialog.form.ifPushQw === 'N'" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template #default="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="deleteAddTagConditionLine(scope.$index, scope.row)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="标签说明" prop="tagExplain">
            <el-input v-model="addDialog.form.tagExplain" type="textarea" size="mini" class="half-width" placeholder="请输入标签说明" />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="addDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAddForm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="企微标签推送导入" :visible.sync="qwTagPushImportDialog.visible" append-to-body width="400px" :close-on-click-modal="false">
      <el-upload
        ref="qwTagPushUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="qwTagPushImportDialog.fileList"
        :on-change="(file, fileList) => handleQwTagPushFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleQwTagPushFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="qwTagPushImportDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="qwTagPushImportDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitQwTagPushImport">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllDict,
  getC2Dict,
  getTagList,
  importTagUserData,
  importSingleTagData,
  getTagDetail,
  updateTagDefine,
  updateTagExplain,
  enableTags,
  disableTags,
  getLogicTagColNameList, addLogicTag, importLogicTags, importQwTags, getQwTagValImportStatus
} from "@/api/tagPlatform";
import { deepClone, parseTime } from "@/utils";
import Pagination from "@/components/Pagination";
import { Notification } from "element-ui";
import timer from "@/utils/timer";

export default {
  name: "LogicTag",
  components: { Pagination },
  data() {
    return {
      loading: false,

      moreConditionKey: ['tagForm', 'available', 'tagStatus'],
      options: {
        buDomains: [],
        tagTypes: [],
        c1TagNames: [],
        allC2Tags: [],
        c2TagNames: [],
        tagStatus: [],
        tagForm: ['逻辑标签', '手工标签'],
        tagFormObj: [{ label: '逻辑标签', value: '2'}, { label: '手工标签', value: '1'}],
      },
      addDialog: {
        visible: false,
        templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E9%80%BB%E8%BE%91%E6%A0%87%E7%AD%BE%E6%89%B9%E9%87%8F%E6%96%B0%E5%BB%BA%E6%A8%A1%E6%9D%BF.xlsx',

        uploadUrl: process.env.VUE_APP_BASE_URL.split(',')[3] + '/import',
        uploadFileType:'',
        upload: {
          fileList: [],
          accept: ['.xls', '.xlsx']
        },
        options: {
          buDomains: [],
          tagTypes: [],
          c1TagNames: [],
          c2TagNames: [],
          tagColNames: [],
        },
        form: {
          addWay: '单独新建',
          buDomain: undefined,
          tagType: undefined,
          c1TagName: undefined,
          c2TagName: undefined,
          tagName: undefined,
          tagColName: undefined,
          available: '永久',
          validityDate: undefined,
          define: '否',
          ifPushQw: 'N',
          tagDefine: undefined,
          tagDefinitions: [],
          tagExplain: undefined,
        },
        rules: {
          buDomain: [{required: true, message: ' ', trigger: 'change'}],
          tagType: [{required: true, message: ' ', trigger: 'change'}],
          c1TagName: [{required: true, message: ' ', trigger: 'change'}],
          c2TagName: [{required: true, message: ' ', trigger: 'change'}],
          tagName: [{required: true, message: ' ', trigger: 'change'}],
          available: [{required: true, message: ' ', trigger: 'change'}],
          validityDate: [{required: true, message: ' ', trigger: 'change'}],
          tagDefine: [{required: true, message: ' ', trigger: 'change'}],
          tagDefinitions: [{required: true, message: ' ', trigger: 'change'}],
          tagExplain: [{required: true, message: ' ', trigger: 'change'}],
        }
      },
      detailDrawer: {
        visible: false,
        tableEditable: false,
        explainEditable: false,
        form: {
          id: undefined,
          tagName: undefined,
          tagId: undefined,
          c1TagName: undefined,
          c2TagName: undefined,
          useCount: undefined,
          tagDefinitions: [],
          tagExplain: undefined,
        },
        originTagDefinitions: [],
        originTagExplain: undefined,
      },
      userDataDialog: {
        visible: false,
        type: 'single',
        upload: {
          fileList: [],
          accept: ['.xls', '.xlsx']
        },
        title: {
          'single': '标签数据上传',
          'batch': '批量上传数据'
        },
        form: {
          tagId: undefined,
          tagName: undefined,
        },
        templateFileDownloadUrl:  undefined,
        batchTemplateUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E6%89%B9%E9%87%8F%E4%B8%8A%E4%BC%A0%E6%A0%87%E7%AD%BE%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx',
        singleTemplateUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%8D%95%E4%B8%AA%E6%A0%87%E7%AD%BE%E6%95%B0%E6%8D%AE%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx',
      },
      currentRow: {},
      qwTagPushImportDialog: {
        visible: false,
          fileList: [],
          accept: ['.xls', '.xlsx'],
          template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E6%8E%A8%E9%80%81%E4%BC%81%E5%BE%AE%E6%A0%87%E7%AD%BE%E5%80%BC%E6%B8%85%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx',
      },
      qwTagPushNotifyContent: '',
    }
  },
  computed: {
    moreConditionLength() {
      return this.moreConditionKey.filter(e => this.queryForm[e] !== '全部').length;
    },
  },
  created() {
    getAllDict().then(res => {
      this.addDialog.options.buDomains = deepClone(res.data.buDomains);
      this.addDialog.options.tagTypes = deepClone(res.data.tagTypes);
      this.addDialog.options.c1TagNames = deepClone(res.data.c1TagNames);
    })
    getLogicTagColNameList().then(res => {
      this.addDialog.options.tagColNames = deepClone(res.data);
    })
  },
  methods: {
    handleFilter() {
      this.loading =true;
      let param = deepClone(this.queryForm);
      for (const key in param) {
        if (param[key] === '全部') {
          param[key] = undefined
        } else if (key === 'available') {
          if (param[key] === '永久') {
            param.validityDate = '永久';
          }
        } else if (key === 'tagStatus') {
          param[key] = this.options.tagStatus.find(e => e.name === param[key]).code;
        }
      }
      if (param.tagForm) {
        param.tagForm = this.options.tagFormObj.find(e => e.label === param.tagForm).value;
      }
      getTagList(param).then(res => {
        this.pageData.rows = res.data.datas;
        this.pageData.total = res.data.total;
        this.loading = false;
      })
    },
    handleQuery() {
      this.queryForm.page = 1;
      this.handleFilter();
    },
    handleC1Change(val, type) {
      let query = {}
      if (val && val !== '全部') {
        query = { c1TagName: val };
        getC2Dict(query).then(res => {
          if (type === 'query') {
            this.options.c2TagNames = ['全部'].concat(res.data);
            this.queryForm.c2TagName = '全部';
          } else if (type === 'add') {
            this.addDialog.options.c2TagNames = res.data;
          }
        })
      } else {
        if (type === 'query') {
          this.options.c2TagNames = deepClone(this.options.allC2Tags);
        } else if (type === 'add') {
          this.addDialog.options.c2TagNames = this.options.c2TagNames = deepClone(this.options.allC2Tags);
        }
      }
    },
    handleQueryMore() {
      this.options.form = {
        tagForm: this.queryForm.tagForm,
        available: this.queryForm.available,
        validityDate: this.queryForm.validityDate,
        tagStatus: this.queryForm.tagStatus
      };
      this.queryDialog.visible = true;
    },
    confirmMoreQuery() {
      this.queryForm = deepClone(Object.assign(this.queryForm, this.queryDialog.form));
      this.queryDialog.visible = false;
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleQuery();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleBatchUpdate(type) {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少勾选一条数据");
        return false;
      }
      const ids = this.pageData.selections.filter(e => (type.indexOf('enable') > -1 && e.tagStatus === '停用') || (type.indexOf('disable') > -1 && e.tagStatus !== '停用')).map( e =>  e.id);
      if (type === 'enableDialog') {
        if (ids.length < this.pageData.selections.length) {
          Notification.warning("生成中数据及启用数据无法启用,请取消相应数据勾选!");
          return false;
        }
        this.statusDialog.validityDate = undefined;
        this.statusDialog.visible = true;
      } else if (type === 'enable') {
        if (!this.statusDialog.validityDate) {
          return false;
        }
        const date = this.statusDialog.available === '截止'? this.statusDialog.validityDate: this.statusDialog.available;
        enableTags({ ids: ids, validityDate: date }).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.handleQuery();
            this.statusDialog.visible = false;
          }
        })
      } else {
        if (ids.length < this.pageData.selections.length) {
          Notification.warning("停用数据无法再次停用,请取消相应数据勾选!");
          return false;
        }
        disableTags({ ids: ids }).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.handleQuery();
          }
        })
      }
    },
    handleAddTag() {
      this.addDialog.form = {
        addWay: '单独新建',
        buDomain: undefined,
        tagType: undefined,
        c1TagName: undefined,
        c2TagName: undefined,
        tagName: undefined,
        available: '永久',
        validityDate: new Date(),
        define: '否',
        ifPushQw: 'N',
        tagDefine: undefined,
        tagDefinitions: [],
        tagExplain: undefined,
      }

      this.addDialog.visible = true;
      this.clearUploadFiles('addUpload', 'addDialog');
    },
    handleAddDefineChange(val) {
      this.addDialog.form.tagDefinitions = [].concat({ tagVal: '', tagDefine: val});
      this.$forceUpdate();
    },
    submitAddForm() {
      if (this.addDialog.form.addWay === '单独新建') {
        this.$refs.addForm.validate(valid => {
          if (valid) {
            let form = deepClone(this.addDialog.form);
            if (form.available === '永久') {
              form.validityDate = '永久';
            } else {
              form.validityDate = parseTime(form.validityDate, '{y}-{m}-{d}');
            }
            form.tagDefinitions = form.tagDefinitions.filter(e => !(e.tagVal && e.tagVal==='' && e.tagDefine==='' && e.tagDefine));
            addLogicTag(form).then(res => {
              if (res && res.code === 200) {
                Notification.success('操作成功！');
                this.addDialog.visible = false;
              }
            })
          }
        })
      } else {
        if (this.addDialog.upload.fileList.length === 0) {
          Notification.warning('请先上传文件！');
          return false;
        }
        let fd = new FormData();
        this.addDialog.upload.fileList.forEach(item=>{
          //文件信息中raw才是真的文件
          fd.append("file",item.raw);
        })
        importLogicTags(fd).then(res => {
          if (res && res.code === 200) {
            this.clearUploadFiles('addUpload', 'addDialog');
            Notification.success('操作成功！');
            this.addDialog.visible = false;
          }
        });
      }
    },
    handleAddDefineRows() {
      if(this.addDialog.form.ifPushQw === 'Y'){
        this.addDialog.form.tagDefinitions.push({ tagVal: '', tagDefine: '', ifPushQw: 'Y', wxTagVal: '' })
      }else {
        this.addDialog.form.tagDefinitions.push({ tagVal: '', tagDefine: '', ifPushQw: 'N', wxTagVal: '' })
      }
      this.$forceUpdate();
    },
    handleFileChange(file, fileList, type, dialog) {
      if (type === 'remove') {
        this[dialog].upload.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this[dialog].upload.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this[dialog].upload.fileList = [];
      } else {
        this[dialog].upload.fileList = fileList;
      }
    },
    handleViewDetail(row) {
      this.detailDrawer.form = {
        id: undefined,
        tagName: undefined,
        tagId: undefined,
        c1TagName: undefined,
        c2TagName: undefined,
        useCount: undefined,
        tagDefinitions: [],
        tagExplain: undefined
      };
      this.currentRow = row;
      getTagDetail(row.id).then(res => {
        if (res && res.code === 200) {
          this.detailDrawer.form = deepClone(res.data);
          this.detailDrawer.originTagExplain = res.data.tagExplain;
          this.detailDrawer.originTagDefinitions = res.data.tagDefinitions;
          this.detailDrawer.visible = true;
        }
      })
    },
    updateTagDefines(type) {
      if (type === 'define') {
        const param = { tagDefinitions: this.detailDrawer.form.tagDefinitions };
        updateTagDefine(this.currentRow.id, param).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.detailDrawer.tableEditable = false;
          }
        })
      } else if (type === 'explain') {
        const param = { tagExplain: this.detailDrawer.form.tagExplain };
        updateTagExplain(this.currentRow.id, param).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.detailDrawer.explainEditable = false;
          }
        })
      } else if (type === 'cancel') {
        this.detailDrawer.form.tagExplain = this.detailDrawer.originTagExplain + '';
        this.detailDrawer.form.tagDefinitions = deepClone(this.detailDrawer.originTagDefinitions);
        this.detailDrawer.explainEditable = false;
        this.detailDrawer.tableEditable = false;
      }
    },
    handleImportUserData(row) {
      this.currentRow = row;
      if (row.id) {
        this.userDataDialog.type = 'single';
        this.userDataDialog.templateFileDownloadUrl = this.userDataDialog.singleTemplateUrl;
      } else {
        this.userDataDialog.type = 'batch';
        this.userDataDialog.templateFileDownloadUrl = this.userDataDialog.batchTemplateUrl;
      }
      this.userDataDialog.visible = true;

      this.clearUploadFiles('userUpload', 'userDataDialog');
    },
    clearUploadFiles(ref, dialog) {
      this.$nextTick(() => {
        this.$refs[ref].clearFiles();
        this[dialog].upload.fileList = [];
      })
    },
    submitImportUserData() {
      if (this.userDataDialog.upload.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.userDataDialog.upload.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })

      if (this.userDataDialog.type == 'single') {
        fd.append("id", this.currentRow.id);
        importSingleTagData(fd).then((res) => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.clearUploadFiles('userUpload', 'userDataDialog');
            this.userDataDialog.visible = false;
            this.handleQuery();
          }
        })
      } else if (this.userDataDialog.type === 'batch') {
        importTagUserData(fd).then((res) => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.clearUploadFiles('userUpload', 'userDataDialog');
            this.userDataDialog.visible = false;
            this.handleQuery();
          }
        })
      }
    },
    handleTagDefinitions(value) {
      if(value === 'Y'){
        this.addDialog.form.tagDefinitions.forEach(item => {
          item.ifPushQw = 'Y';
          item.wxTagVal = this.addDialog.form.tagName + '-' + item.tagVal;
        })
      }else {
        this.addDialog.form.tagDefinitions.forEach(item => {
          item.ifPushQw = 'N';
          item.wxTagVal = '';
        })
      }
      this.$forceUpdate();
    },
    handleWxTagVar(value, row){
      if(this.addDialog.form.ifPushQw === 'Y'){
        row.wxTagVal = this.addDialog.form.tagName + '-' + value;
      }else {
        row.wxTagVal = '';
      }
      this.$forceUpdate();
    },
    handlePartPushQwWxTagVar(){
      if(this.addDialog.form.ifPushQw === 'P'){
        this.addDialog.form.tagDefinitions.forEach(item => {
          if(item.ifPushQw === 'Y'){
            item.wxTagVal = this.addDialog.form.tagName + '-' + item.tagVal;
          }
        })
      }
      this.$forceUpdate();
    },
    handleQwTagPushImport() {
      this.qwTagPushImportDialog.visible = true;
    },
    handleQwTagPushFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.qwTagPushImportDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.qwTagPushImportDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.qwTagPushImportDialog.fileList = [];
      } else {
        this.qwTagPushImportDialog.fileList = fileList;
      }
    },
    submitQwTagPushImport() {
      if (this.qwTagPushImportDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.qwTagPushImportDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      importQwTags(fd).then((res) => {
        if (res && res.code !== 200) {
          this.$message.success(res.data);
        } else {
          this.$message.success("文件上传成功！");
          this.qwTagPushImportDialog.visible = false;
          this.qwTagPushNotifyContent = '企微标签推送正在处理中...'
          timer.createTimer('qwTagPushStatus', () => {
            this.handleQwTagPushStatus(res.data)
          }, 5000)
        }
        this.loading = false;
        this.$refs.qwTagPushUpload.clearFiles();
      })
    },
    deleteAddTagConditionLine(index){
      this.addDialog.form.tagDefinitions.splice(index, 1)
    },
    handleQwTagPushStatus(fileId){
      getQwTagValImportStatus(fileId).then((res) => {
        if (res.code === 200) {
          this.qwTagPushNotifyContent = res.data;
          if(res.data === '企微标签推送导入成功!' || res.data === '企微标签推送导入失败!'){
            timer?.clearTimer('qwTagPushStatus');
          }
        }
        if (res.code !== 200) {
          timer?.clearTimer('qwTagPushStatus');
          this.$message.error(res.msg);
        }
      })
    },
    handleAvailable(value) {
      if(value === '失效日期'){
        this.addDialog.form.validityDate = new Date();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.keyClass {
  ::v-deep .el-input__inner {
    min-width: 250px !important;
  }
}

::v-deep .el-date-editor .el-input__inner {
  text-align: center;
}

.half-width {
  width: 45%;
  ::v-deep .el-input .el-input__inner {
     max-width: 100% !important;
   }
  ::v-deep .el-input__inner {
    max-width: 100% !important;
  }
  ::v-deep .el-date-editor .el-input__inner {
    max-width: 100% !important;
  }
}

::v-deep .el-descriptions-row .el-descriptions-item__label {
  font-weight: 600;
}

::v-deep .el-descriptions-item__container .labelClass {
  width: 80px;
}

::v-deep .el-badge.item {
  top: -1px;
}
::v-deep .el-badge__content.is-fixed {
  right: 25px !important;
}

.buttonClass {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
}
</style>
