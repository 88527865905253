<template>
    <mg-container
        :isShowTips="true"
        :isDisablePage="tipsConfig.isDisablePage"
        :tipsConfig="(tipsConfig.tipsType && tipsConfig.tips[tipsConfig.tipsType]) || tipsConfig.tips['default']"
    >
        <template #headerSearch>
            <div class="monthlyOrderCustomized-head-box">
                <div class="monthlyOrderCustomized-title" v-if="userName">
                    <div>
                        <span class="monthlyOrderCustomized-name">{{ userName || '--' }}</span>, 请选择您要使用的模块:
                    </div>
                    <mg-signboard :title="branchCompanyInfos && branchCompanyInfos.name" :isHavBar="false" />
                </div>
                <ul class="monthlyOrderCustomized-con">
                        <li class="monthlyOrderCustomized-con-item monthlyOrderCustomized-con-addOrderWarning" @click="handleHeadClick('addOrderWarning')">
                            <i class="iconfont icon-renwu-zengjia"></i>
                            <span class="head-text">加单预警</span>
                        </li>
                        <li class="monthlyOrderCustomized-con-item monthlyOrderCustomized-con-monthlyOrder" @click="handleHeadClick('monthlyOrder')">
                            <i class="iconfont icon-jihua"></i>
                            <span class="head-text">月单定制</span>
                        </li>
                </ul>
            </div>
        </template>
        <!-- 列表主体 -->
        <template #table>
            <div class="monthlyOrderCustomized-content">
                <mg-signboard :title="'分公司月单定制汇总'" />
                <mg-table 
                    :autoLoad="true"
                    :columns.sync="monthlyOrderCustomizedColumns"
                    :api="handleMonthOrderLists"
                    :apiParams="queryForm"
                    ref="mgTable"
                    height="calc(100vh - 300px)"
                    :configurable="true"
                    businessType="BRANCHOFFICE_MONTHLYORDERCUSTOMIZED_LISTS"
                    max-height="auto"
                >
                    <template #month="{ row }">
                        {{ row.month || '--' }}
                    </template>
                    <template #flowStatus="{ row }">
                        <span :style="{ color: ![null, undefined].includes(row.flowStatus) && flowStatusColors[row.flowStatus] || '' }">
                            {{ row.flowStatus && flowStatus[row.flowStatus] || '--' }}
                        </span>
                    </template>
                    <template #customStartTime="{ row }">
                        {{ row.customStartTime && formatTime(row.customStartTime) || '--' }}
                    </template>
                    <template #throwOrderTime="{ row }">
                        {{ row.throwOrderTime && formatTime(row.throwOrderTime) || '--' }}
                    </template>
                    <template #throwOrderCreator="{ row }">
                        {{ row.throwOrderCreator || '--' }}
                    </template>
                    <el-table-column show-overflow-tooltip label="操作" width="150px" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                @click="handleTableAction('continue', row)"
                            >继续定制</el-button>
                            <el-button
                                size="mini"
                                type="text"
                                @click="handleTableAction('reset', row)"
                            >重置</el-button>
                        </template>
                    </el-table-column>
                </mg-table>
            </div>
        </template>
        <mgProgress ref="mgProgress" :progressTips="progressTips" />
    </mg-container>
</template>

<script>
import '@/assets/fonts/iconfont.css';
import { monthlyOrderCustomizedColumns } from '../config';
import {
    handleMonthOrderLists,
    handleMonthOrderCustom,
    handleMonthOrderReset,
    handleMonthOrderDataStatus
} from '@/api/branchOffice';
import { urlParams, branchCompanyInfos } from '@/mixins';
import { deleEmptyParams } from '@/utils';
import { pageDataAvailable } from '../mixins'
import timer from '@/utils/timer';
export default {
    // 分公司月单定制 -- 首页
    name: 'monthlyOrderCustomized',
    components: {
        mgContainer: () => import('@/mg-components/mg-container'),
        mgSignboard: () => import('@/components/Signboard'),
        mgTable: () => import('@/mg-components/mg-table'),
        mgProgress: () => import('@/mg-components/mg-progress')
    },
    computed: {
      // 时间格式化
      formatTime() {
        return (time) => {
          return this.$dayjs(time).format('YYYY-MM-DD HH:mm:ss')
        }
      },
    },
    mixins: [ urlParams, branchCompanyInfos, pageDataAvailable ],
    data() {
        return {
            monthlyOrderCustomizedColumns,
            handleMonthOrderLists,
            queryForm: {
                page: 1,
                limit: 20,
            },
            flowStatus: {
                0: '未定制',
                1: '定制中',
                3: '要货确认',
                4: 'DRP已抛单',
            },
            flowStatusColors: {
                0: '#faad14',
                1: '#fd781d',
                3: '#1890ff',
                4: '#45b60d',
            },
            queryParams: {},
            userName: '',
            progressTips: ''
        }
    },
    created() {
        const queryForm = this.queryForm;
        this.handleUrlParams().then(res => {
            this.queryParams = res
            if ( !(res && res['x-token']) ) {
                this.queryForm = {
                    ...res,
                    ...queryForm
                }
            }
        })
    },
    mounted() {
        setTimeout(() => {
            this.userName = this.$refs['mgTable']?.listsResult?.data?.operation || ''
        }, 500)
    },
    methods: {
        // 调用组件内部方法 加载列表
        handleLoadData() {
            this.$nextTick(() => {
                this.$refs['mgTable']?.loadData();
            })
        },
        // 顶部事件
        handleHeadClick(type) {
            const funcObj = {
                addOrderWarning: () => {
                    deleEmptyParams(this.queryParams);
                    this.$router.push({ 
                        path: '/branchOffice/addOrderWarning',
                        query: { ...this.queryParams }
                    })
                },
                monthlyOrder: async () => {
                    try {
                        this.progressTips = '月单正在定制中，请耐心等待'
                        this.$refs['mgProgress'].show()
                        const { code, data, msg } = await handleMonthOrderCustom({});
                        if (code === 200) {
                            timer.createTimer('processStatus', () => {
                                this.handleMonthOrderProcessStatus()
                            }, 5000)
                        }
                        if (code !== 200) {
                            this.$refs['mgProgress'].finish()
                            timer?.clearTimer('processStatus');
                            return this.$message.error(msg)
                        }
                    } catch(err) {
                        throw new Error(err)
                    }
                },
            };
            funcObj[type]();
        },
        // 列表操作列
        handleTableAction(type, row) {
            const funcObj = {
                continue: () => {
                    deleEmptyParams(this.queryParams);
                    this.$router.push({
                        path: '/branchOffice/purchaseConfirmation',
                        query: { ...this.queryParams }
                    })
                },
                reset: async () => {
                    try {
                        const { code, data, msg } = await handleMonthOrderReset({})
                        if (code === 200) {
                            this.handleLoadData();
                            return this.$message.success('月单定制重置已成功')
                        }
                    } catch(err) {
                        throw new Error(err)
                    }
                }
            };
            funcObj[type]()
        },
        // 月单定制进度查询
        async handleMonthOrderProcessStatus() {
            try {
                const statusObj = {
                    '1': '月单定制中',
                    '2': '月单定制成功',
                    '3': '月单定制失败'
                }
                const { code, data, msg } = await handleMonthOrderDataStatus({ month: this.$dayjs().format('YYYYMM')  })
                this.progressTips = `${ data?.status && statusObj[data?.status] || '数据异常' }`
                this.handleLoadData();
            } catch (error) {
                throw new Error(error)
            } finally {
                this.$refs['mgProgress'].finish()
                timer.clearTimer('processStatus');
            }
        }
    }
}
</script>

<style lang="scss" scoped src='@/style/branchOffice/monthlyOrderCustomized.scss'></style>