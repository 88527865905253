var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键词" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入关键词" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择分类",
                          },
                          model: {
                            value: _vm.queryForm.category,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "category", $$v)
                            },
                            expression: "queryForm.category",
                          },
                        },
                        _vm._l(_vm.options.category, function (item) {
                          return _c("el-option", {
                            key: item.dictKey,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictKey,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit(undefined, "courseAdd")
                },
              },
            },
            [_vm._v("创建新教程")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-edit", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit(undefined, "category")
                },
              },
            },
            [_vm._v("编辑分类")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "ID",
                  prop: "id",
                  width: "180",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "标题", prop: "title", width: "280" },
              }),
              _c("el-table-column", {
                attrs: { label: "权重值", prop: "sort" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建者", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "分类", prop: "categoryName" },
              }),
              _c("el-table-column", {
                attrs: { label: "编辑时间", prop: "updateTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "开始时间",
                  prop: "showStartTime",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "结束时间", prop: "showEndTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row, "courseEdit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _vm.editDialog.visible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.editDialog.titleMap[_vm.editDialog.type],
                visible: _vm.editDialog.visible,
                "append-to-body": "",
                width: _vm.editDialog.widthMap[_vm.editDialog.type],
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.editDialog, "visible", $event)
                },
              },
            },
            [
              _vm.editDialog.type && _vm.editDialog.type.indexOf("course") > -1
                ? _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "common-form",
                      attrs: {
                        size: "mini",
                        inline: "",
                        rules: _vm.editDialog.rules,
                        "label-width": "160px",
                        model: _vm.editDialog.form,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "common-form-row" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                border: "solid 1px rgb(78 72 72 / 10%)",
                                padding: "10px",
                              },
                            },
                            [
                              _c("el-col", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#1482f0",
                                      "font-size": "24px",
                                    },
                                  },
                                  [_vm._v("基本信息")]
                                ),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "教程标题",
                                        prop: "title",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "建议13字以内",
                                          maxlength: "100",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _vm.editDialog.form.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "title",
                                              $$v
                                            )
                                          },
                                          expression: "editDialog.form.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "分类",
                                        prop: "category",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.editDialog.form.category,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editDialog.form,
                                                "category",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editDialog.form.category",
                                          },
                                        },
                                        _vm._l(
                                          _vm.options.category,
                                          function (item) {
                                            return _c(
                                              "el-radio",
                                              {
                                                key: item.dictKey,
                                                attrs: { label: item.dictKey },
                                              },
                                              [_vm._v(_vm._s(item.dictLabel))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "自定义标签",
                                        prop: "tag",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder:
                                            "2-5字，仅展示，如：“新功能” “新手必看”",
                                          maxlength: "20",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: _vm.editDialog.form.tag,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "tag",
                                              $$v
                                            )
                                          },
                                          expression: "editDialog.form.tag",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "展示时间",
                                        prop: "showStartTime",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "start-placeholder": "开始时间",
                                          "end-placeholder": "结束时间",
                                          format: "yyyy-MM-dd",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        on: {
                                          change: (val) =>
                                            _vm.handleDateChange(val),
                                        },
                                        model: {
                                          value: _vm.editDialog.showDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog,
                                              "showDate",
                                              $$v
                                            )
                                          },
                                          expression: "editDialog.showDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "排序权重",
                                        prop: "sort",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          clearable: "",
                                          controls: false,
                                          placeholder: "数字越小，排序越靠前",
                                        },
                                        model: {
                                          value: _vm.editDialog.form.sort,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "sort",
                                              $$v
                                            )
                                          },
                                          expression: "editDialog.form.sort",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "上传头图",
                                        prop: "coverImg",
                                      },
                                    },
                                    [
                                      _c("OssUpload", {
                                        ref: "ossUpload",
                                        attrs: {
                                          "value-to":
                                            _vm.editDialog.form.coverImg,
                                          "list-type": "picture-card",
                                          maxFileSize: 1024,
                                          tips: "尺寸330*120，1MB以下",
                                          tipMessageShow: false,
                                          "accept-type":
                                            _vm.editDialog.imgTypes,
                                        },
                                        on: {
                                          "update:valueTo": function ($event) {
                                            return _vm.$set(
                                              _vm.editDialog.form,
                                              "coverImg",
                                              $event
                                            )
                                          },
                                          "update:value-to": function ($event) {
                                            return _vm.$set(
                                              _vm.editDialog.form,
                                              "coverImg",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "关联的教程文章" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            multiple: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.editDialog.form.relateCourses,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editDialog.form,
                                                "relateCourses",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editDialog.form.relateCourses",
                                          },
                                        },
                                        _vm._l(
                                          _vm.options.relate,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.title,
                                                limit: 1,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  border: "solid 1px rgb(78 72 72 / 10%)",
                                  padding: "10px",
                                  "margin-top": "10px",
                                },
                              },
                              [
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      color: "#1482f0",
                                      "font-size": "24px",
                                    },
                                  },
                                  [_vm._v("教程详情")]
                                ),
                                _c("WangEditor", {
                                  ref: "editor",
                                  attrs: { value: _vm.editDialog.form.content },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.editDialog.form,
                                        "content",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.editDialog.loading,
                              expression: "editDialog.loading",
                            },
                          ],
                          ref: "table",
                          attrs: {
                            data: _vm.editDialog.pageData.rows,
                            height: "calc(100vh - 250px)",
                            size: "mini",
                            "highlight-current-row": "",
                          },
                          on: { "sort-change": _vm.handleSortChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", width: "50" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "分类名", prop: "dictLabel" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _vm.editDialog.currentRow.dictLabel ===
                                      row.dictLabel
                                        ? _c("el-input", {
                                            attrs: {
                                              size: "mini",
                                              clearable: "",
                                            },
                                            model: {
                                              value: row.dictLabelChange,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "dictLabelChange",
                                                  $$v
                                                )
                                              },
                                              expression: "row.dictLabelChange",
                                            },
                                          })
                                        : _c("span", [
                                            _vm._v(_vm._s(row.dictLabel)),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              409890356
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "250",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openEditCategory(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _c("el-button", {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              icon: "el-icon-arrow-down",
                                              disabled:
                                                scope.$index ===
                                                  _vm.editDialog.pageData.rows
                                                    .length -
                                                    1 ||
                                                _vm.editDialog.currentRow
                                                  .dictKey !== undefined,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleMove(
                                                  scope.row,
                                                  "down"
                                                )
                                              },
                                            },
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              icon: "el-icon-arrow-up",
                                              disabled:
                                                scope.$index === 0 ||
                                                _vm.editDialog.currentRow
                                                  .dictKey !== undefined,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleMove(
                                                  scope.row,
                                                  "up"
                                                )
                                              },
                                            },
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDeleteCategory(
                                                  scope.row
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleCopy(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("复制链接")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2107246854
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm.addShow
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-plus",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openEditCategory(undefined)
                                },
                              },
                            },
                            [_vm._v("新建分类")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.editDialog.type !== "category" ? "取消" : "关闭"
                          )
                      ),
                    ]
                  ),
                  _vm.editDialog.type !== "category"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("确定")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑分组",
            visible: _vm.editCategory.visible,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editCategory, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "categoryForm",
              attrs: {
                size: "mini",
                model: _vm.editCategory.form,
                rules: _vm.editCategory.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组名", prop: "dictLabel" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { clearable: "", placeholder: "请输入分类名" },
                    model: {
                      value: _vm.editCategory.form.dictLabel,
                      callback: function ($$v) {
                        _vm.$set(_vm.editCategory.form, "dictLabel", $$v)
                      },
                      expression: "editCategory.form.dictLabel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分组权限" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.editCategory.form.authType,
                        callback: function ($$v) {
                          _vm.$set(_vm.editCategory.form, "authType", $$v)
                        },
                        expression: "editCategory.form.authType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不限制"),
                      ]),
                      _c(
                        "el-radio",
                        { ref: "radio", attrs: { label: 1 } },
                        [
                          _vm._v(" 指定角色可见 "),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "mini",
                                clearable: "",
                                filterable: "",
                                multiple: "",
                                placeholder: "请选择角色",
                              },
                              on: { change: _vm.handleRoleChange },
                              model: {
                                value: _vm.editCategory.form.roleKeys,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editCategory.form,
                                    "roleKeys",
                                    $$v
                                  )
                                },
                                expression: "editCategory.form.roleKeys",
                              },
                            },
                            _vm._l(_vm.options.roles, function (item) {
                              return _c("el-option", {
                                key: item.roleKey,
                                attrs: {
                                  label: item.roleName,
                                  value: item.roleKey,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.saveCategory },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }