/**
 * ----------------------------- 其他量导入 -----------------------------
 */
import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";


// 其他量导入列表
export function handleOtherQuantityLists(data) {
  return axiosPost('/other_material/other_material_page', data);
}

// 其他量导入确认
export function handleOtherQuantityConfirm(data) {
  return axiosPost('/other_material/confirm_other_material', data);
}

// 其他量导入保存
export function handleOtherQuantitySave(data) {
  return axiosPost('/other_material/save_other_material', data);
}

// 其他量导入删除
export function handleOtherQuantityDelete(data) {
  return axiosPost('/other_material/delete_other_material', data);
}

// 其他量导入上传
export function handleOtherQuantityUpload(data) {
  return axiosPost('/other_material/import_other_material', data);
}





