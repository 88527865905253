<template>
  <div class="new-box">
    <div v-if="!component" class="mg-search" >
        <el-card style="margin: 10px;">
            <SearchFilter   @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="80px" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item v-show="orgOptions.length > 1" label="分公司">
          <el-select v-model="queryForm.orgCode" filterable clearable default-first-option>
            <el-option v-for="item in orgOptions" :key="item.value" filterable :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="queryForm.groupName" placeholder="请输入店铺组名称" clearable />
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="createDate"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="handleDateChange"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryForm.status" clearable>
            <el-option :value="1" label="启用">启用</el-option>
            <el-option :value="0" label="禁用">禁用</el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="orgAuthAll" label="创建人">
          <el-select v-model="queryForm.createUser" clearable>
            <el-option v-for="item in userOptions" :key="item.value" :value="item.value" :label="item.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('add')">店铺组</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button  style="margin: 0 0 10px 0;" class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('add')">店铺组</el-button>
    <el-table
      v-if="!component"
      :data="pageData.rows"
      class="customer-table"
      border
         size="small"
      v-loading="loading"
      highlight-current-row
      @sort-change="handleSortChange"
    >
      <el-table-column type="index" width="90" label="序号" />
      <el-table-column label="店铺组名称" prop="groupName" />
      <el-table-column label="分公司名称" prop="orgName"  />
      <el-table-column label="状态" prop="status" >
        <template #default="{ row }">
          <span :style="row.status === 1 ? 'color: blue' : ''">{{ row.status === 1 ? "启用" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="款式总数" prop="itemCount"  />
      <el-table-column label="描述" prop="description" />
      <el-table-column label="创建人" prop="createUser" />
      <el-table-column label="创建日期" prop="createTime" width="200px" />
      <el-table-column label="操作" width="200" align="center" fixed="right">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleEdit(row, 'view')">查看</el-button>
          <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleEdit(row, 'edit')">编辑</el-button>
          <el-button size="mini" style="border: 0px; width: 20%" type="text" @click="handleExport(row)">导出</el-button>
          <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleEditStatus(row)">
            {{ row.status === 1 ? "禁用" : "启用" }}
          </el-button>
          <el-popconfirm
            :title="'请确认是否要删除店铺组(' + row.groupName + ')?'"
            @confirm="handleDelete(row)"
          >
            <el-button v-show="row.status === 0" slot="reference" size="mini" type="text" style="border: 0px; width: 20%">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <Pagination v-if="!component" :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="dialogTitle[saveTitle]" :visible.sync="saveDialog.dialogVisible" width="900px" append-to-body :close-on-click-modal="false">
      <el-form ref="addForm" :model="saveDialog.form" :inline="true" :rules="saveDialog.rules" label-width="auto">
        <el-form-item label="分公司" class="half-width" size="mini">
          <DictSelect :disabled="saveTitle!=='add'" ref="org" :value.sync="saveDialog.form.orgCode" fullLabel type="ht-org-all" :clearable="false" :dictFun="dictFun" :default-index="0" @change="(val, type) => handleOrgChange(val, type)" />
        </el-form-item>
        <el-form-item label="店铺组名称" prop="groupName" class="half-width" size="mini">
          <el-input
            :disabled="saveTitle === 'view'"
            v-model="saveDialog.form.groupName"
            clearable
            placeholder="请输入店铺组名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description" size="mini" class="full-width">
          <el-input
            :disabled="saveTitle === 'view'"
            v-model="saveDialog.form.description"
            clearable
            type="textarea"
            placeholder="请输入描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="checkNumber">已选店铺({{ saveDialog.form.items.length }})</div>
      <div>
        <el-button size="mini" type="primary" v-if="saveTitle !== 'view'" style="float: left" @click="handleRemoveItems">
          批量删除
        </el-button>
        <el-button size="mini" type="primary" v-if="saveTitle !== 'view'" style="float: right" @click="drawer.visible = true">
          添加店铺
        </el-button>
        <el-button size="mini" type="primary" v-if="saveTitle !== 'view'" style="float: right" @click="importDialog.visible = true">
          导入
        </el-button>
      </div>
      <el-table
        :data="itemsPage.rows"
        height="calc(100vh - 350px)"
        v-loading="loading"
        size="mini"
        border
        style="margin-top: 5px"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="saveTitle !== 'view'" type="selection" width="90" />
        <el-table-column label="所属配送中心" prop="dcName" width="170">
          <template #default="{ row }">
            <span>{{ (row.dcCode?row.dcCode:'') + (row.dcName?"-" + row.dcName:'') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="省份/城市" prop="provinceName" width="150">
          <template #default="{ row }">
            <span>{{ (row.provinceName?row.provinceName:'') + "-" + (row.cityName? "-" +row.cityName:'') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属区县" prop="areaName" width="100" />
        <el-table-column label="店铺编码/名称" prop="shop" width="250">
          <template #default="{ row }">
            <span>{{ (row.shopCode?row.shopCode:'') + (row.shopName ?"-" + row.shopName: '') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属商圈" prop="businessAreaName" width="100" />
        <el-table-column label="区域分组" prop="areaTag" width="80" />
        <el-table-column label="店铺类型" prop="shopCapacity" width="80" />
        <el-table-column label="合作类型" prop="shopBiz" width="70" />
        <el-table-column label="是否重点终端" prop="ifOptimumShop" width="80">
          <template #default="{ row }">
            <span>{{ row.ifOptimumShop ==='1'? '是': '否' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="itemsPage.total" :page.sync="itemsPage.current" :limit.sync="itemsPage.size" @pagination="handleQueryChecked('itemsPage')"/>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="saveDialog.dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    <ImportDialog :importBaseParams="importDialog" @handleImportCallBack="handleImportData" />

    <el-drawer title="选择店铺" :visible.sync="drawer.visible" size="75%" direction="rtl" append-to-body>
      <ShopItem
        :refresh="drawer.refresh"
        :org-code="saveDialog.form.orgCode"
        @add-items="addItems"
        @close-drawer="drawer.visible = false"
        ref="itemRef"
        @getExceptCodes="callback => callback(saveDialog.form.exceptCodes)"
      />
    </el-drawer>

    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageShopGroup, getShopGroupInfo, saveShopGroup, updateShopGroup, deleteShopGroup, dictSelect, getCurrentUser, checkShops, exportShopGroups } from "@/api/appTask";
import { parseParam, parseTime, excelExport } from "@/utils";
import {Message, MessageBox, Notification} from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import ShopItem from "./ShopItem";
import DictSelect from "@/components/DictSelect";

export default {
  name: "ShopGroup",
  components: { ShopItem, Pagination, ImportDialog: () => import("@/components/ImportDialog"), DictSelect, SearchFilter},
  props: {
    component: {
      type: String,
    }
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      itemsPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      createDate: undefined,
      queryForm: {
        current: 1,
        size: 10,
        groupName: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        status: undefined,
        createUser: undefined,
      },
      itemQueryForm: {
        groupName: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        createDate: undefined,
        status: undefined,
        createUser: undefined,
      },
      loading: false,
      userOptions: [],
      orgOptions: [],
      saveTitle: undefined,
      dialogTitle: {
        add: "新增店铺组",
        edit: "修改店铺组",
        view: "查看店铺组",
      },
      saveDialog: {
        dialogVisible: false,
        form: {
          groupName: undefined,
          groupId: undefined,
          description: undefined,
          status: 1,
          itemCount: undefined,
          createUser: undefined,
          creteTime: undefined,
          items: [],
          tmpItems: [],
          exceptCodes: [],
          removeSelections: [],
        },
        rules: {
          groupName: [{ required: true, message: "   ", trigger: "change" }],
          description: [{ required: true, message: " ", trigger: "change" }],
        },
      },
      drawer: {
        visible: false,
        refresh: false,
      },
      importDialog: {
        visible: false,
        title: "店铺组-店铺数据导入",
        type: "importAddReduce",
        tipsText: "必需字段: 配送中心编码、店铺编码",
        templateSampleHead: [{label: "配送中心编码", prop: "dcCode", minWidth: "80px"},
          {
            label: "店铺编码",
            prop: "shopCode",
            minWidth: "80px"
          }],
        templateSampleLists: [
          {
            dcCode: "C03017",
            shopCode: "0190",
          },
        ],
        apiParams: "importList",
        previewDataLength: 10,
        templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/店铺数据导入模板.xls',
      },
      currentUser: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      dictFun: dictSelect,
      exportHeaders: {
        oldDcCode: '配送中心编码',
        oldShopCode: '店铺编码',
        dcName: '配送中心名称',
        shopName: '店铺名称',
        errorMsg: '校验结果'
      },
      orgAuthAll: false
    };
  },
  watch: {
    "saveDialog.dialogVisible": {
      handler(newVal) {
        this.drawer.refresh = newVal;
        if (!newVal) {
          this.$emit('dialog-close', 'shop');
        }
      },
    },
    itemsPage: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true,
    },
    component: {
      handler(newVal) {
        if (newVal && newVal==='shop') {
          this.handleAdd();
        }
      }
    },
  },
  created() {
    dictSelect("group_shop_creater").then(res => {
      this.userOptions = res.data;
    });
    dictSelect("ht-org-all").then(res => {
      if (res.data.length === 1) {
        this.queryForm.orgCode = res.data[0].value;
      }
      this.orgOptions = res.data;
      if (res.data.filter(e => e.value == 'ALL').length > 0) {
        this.orgAuthAll = true
      }
      this.handleFilter();
    });
    getCurrentUser().then(res => this.currentUser = res.data);

  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let param = "";
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Date) {
            param+='&' + key + "=" + parseTime(this.queryForm[key]);
          } else if (!(this.queryForm[key] instanceof Array)) {
            param+='&' + key + "=" + this.queryForm[key];
          }
        }
      }
      pageShopGroup("?" + param.substring(1)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        groupName: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        createDate: undefined,
        status: undefined,
        createUser: undefined,
        orgCode: undefined,
      };
      this.createDate = undefined;
      this.queryForm = Object.assign({}, queryForm);
      if (this.$refs.org && this.$refs.org.itemOptions.length > 0) {
        this.queryForm.orgCode = this.$refs.org.itemOptions[0].value;
      }
      this.$forceUpdate();
    },
    handleResetFields() {
      const form = {
        groupName: undefined,
        createTimeBegin: undefined,
        createTimeEnd: undefined,
        createDate: undefined,
        status: 1,
        createUser: undefined,
        items: [],
        exceptCodes: [],
        removeSelections: [],
        orgCode: undefined,
        orgName: undefined,
        tmpItems: [],
      };
      this.saveDialog.form = Object.assign({}, form);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val) {
      if (val) {
        this.queryForm.createTimeBegin = val[0];
        this.queryForm.createTimeEnd = val[1];
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    handleAdd() {
      this.saveDialog.dialogVisible = true;
      this.saveTitle = "add";
      this.handleResetFields();
      this.$nextTick(() => {
        if (this.$refs.org && this.$refs.org.itemOptions.length > 0) {
          this.saveDialog.form.orgCode = this.$refs.org.itemOptions[0].value;
          this.saveDialog.form.orgName = this.$refs.org.itemOptions[0].label;
        }
      })
      this.itemsPage = {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      };
    },
    handleEdit(row, type) {
      getShopGroupInfo(row.id).then(res => {
        this.saveTitle = type;
        this.handleResetFields();
        debugger
        const form = Object.assign({}, res.data);
        this.saveDialog.form = form;
        this.saveDialog.form.exceptCodes = form.items.map(e => e.dcCode + "-" + e.shopCode);
        this.$forceUpdate();
        this.saveDialog.dialogVisible = true;
        this.itemsPage.total = this.saveDialog.form.items.length;
        this.addItems(form.items);
      });
    },
    submitForm() {
      if (this.saveTitle === "view") {
        this.saveDialog.dialogVisible = false;
      } else {
        this.$refs["addForm"].validate(valid => {
          if (valid) {
            if (this.$refs.org && this.$refs.org.itemOptions.length > 0) {
              if (!this.saveDialog.form.orgName) {
                this.saveDialog.form.orgName = this.$refs.org.itemOptions[0].label;
              } else {
                this.saveDialog.form.orgName = this.$refs.org.itemOptions.find(e => e.value === this.saveDialog.form.orgCode).label;
              }
            }
            const data = Object.assign({}, this.saveDialog.form);
            saveShopGroup(data).then(res => {
              if (res.code === 200) {
                if (res.data) {
                  MessageBox.alert(res.data, '提示', {
                    dangerouslyUseHTMLString: true
                  });
                } else {
                  this.saveDialog.dialogVisible = false;
                  Notification.success("操作成功！");
                  this.handleQuery();
                }
              }
            });
          }
        });
      }
    },
    handleEditStatus(row) {
      let data = Object.assign({}, row);
      if (row.status === 1) {
        data = Object.assign(data, { status: 0 });
      } else {
        data = Object.assign(data, { status: 1 });
      }
      updateShopGroup(data).then(res => {
        Notification.success("操作成功！");
        this.handleFilter();
      });
    },
    handleDelete(row) {
      deleteShopGroup(row.id).then(res => {
        Notification.success("操作成功！");
        this.handleFilter();
      });
    },
    addItems(items, action) {
      const newItems = items.filter(e => {
        if (this.saveDialog.form.exceptCodes.indexOf(e.dcCode + "-" + e.shopCode) < 0) {
          return true;
        }
      });
      this.saveDialog.form.items = this.saveDialog.form.items.concat(newItems);
      this.saveDialog.form.exceptCodes = this.saveDialog.form.exceptCodes.concat(newItems.map(e => e.dcCode + "-" + e.shopCode));
      this.itemsPage.total = this.saveDialog.form.items.length;
      this.handleQueryChecked('itemsPage')
      if (action === 'close') {
        this.drawer.visible = false;
      }
    },
    handleSelectionChange(selections) {
      this.saveDialog.form.removeSelections = selections;
    },
    handleRemoveItems() {
      const removeSelections = this.saveDialog.form.removeSelections;
      this.saveDialog.form.items = this.saveDialog.form.items.filter(e => {
        let flag = true;
        for (const i in removeSelections) {
          if (removeSelections[i].dcCode + "-" + removeSelections[i].shopCode == e.dcCode + "-" + e.shopCode) {
            flag = false;
            break;
          }
        }
        return flag;
      });
      this.saveDialog.form.exceptCodes = this.saveDialog.form.items.map(e => e.dcCode + "-" + e.shopCode);
      this.saveDialog.form.removeSelections = [];
      this.itemsPage.total = this.saveDialog.form.items.length;
      this.handleQueryChecked('itemsPage')
    },
    async handleImportData(val) {
      let checkedArray = Object.assign([], this.saveDialog.form.items).filter(e => e.dcCode != 'ALL');
      checkedArray.forEach(e => e.dataType = '0');
      let tempArray = val["importList"];
      let errorData = [];
      tempArray.forEach(e => {
        if (!e.dcCode || !e.shopCode) {
          errorData.push(1)
        }
        e.dataType = '1';
      })
      if (errorData.length > 0) {
        MessageBox.alert(" 导入失败，请重新上传，存在" + errorData.length + " 条数据，配送中心编码或店铺编码为空", '提示', {
          dangerouslyUseHTMLString: true
        });
        return;
      }
      const param = tempArray.concat(checkedArray);
      await checkShops({shops: param}).then((res) => {
        if (res.data && res.data.length) {
          let successNum = 0;
          let errorNum = 0;
          let errorData = []
          let errorDc = []
          let virtualDcData = [];

          let totalRepeatNum = 0;
          let importCount = 0;
          let importRepeatNum = 0;
          let existRepeatNUm = 0;
          res.data.filter(e => {
            if (e.dataType === '1') {
              importCount++;
              if (e.checkResult.indexOf("3") <0 && e.checkResult.indexOf("2") < 0) {
                successNum++;
              } else if (e.checkResult.indexOf("0") > -1) {
                errorDc.push(e.oldDcCode);
              }

              if (e.checkResult.indexOf('5') > -1) {
                if (e.checkResult.indexOf('4') > -1) {
                  errorData.push("配送中心编码：" + e.oldDcCode + "(原) -> " + e.dcCode + "(新)；   店铺编码:" + e.oldShopCode + "(原) -> " + e.shopCode + "(新)");
                } else {
                  errorData.push('配送中心编码：' + e.dcCode + "&nbsp;&nbsp;&nbsp;店铺编码: " + e.shopCode);
                }
              }
              if (e.checkResult.indexOf('4') > -1) {
                virtualDcData.push("配送中心编码：" + e.oldDcCode + "(原) -> " + e.dcCode + "(新)；   店铺编码:" + e.oldShopCode + "(原) -> " + e.shopCode + "(新)");
              }

              if (e.checkResult.indexOf('2') > -1 || e.checkResult.indexOf('3') > -1) {
                totalRepeatNum++;
                if (e.checkResult.indexOf('2') > -1) {
                  importRepeatNum++;
                }
                if (e.checkResult.indexOf('3') > -1) {
                  existRepeatNUm++;
                }
              }
            }
          });
          this.importDialog.visible = false;
          if (errorDc.length > 0) {
            this.noticeDialog.msg = "以下配送中心无权限或不存在，请确认后重新导入，列表如下：</br>" + Array.from(new Set(errorDc)).join('、');
            this.noticeDialog.visible = true;
            return;
          }
          this.noticeDialog.msg = "共 " + importCount + " 条，成功 " + successNum + " 条，重复 " + totalRepeatNum + " 条，" +
            "无法匹配 " + (errorNum) + " 条,</br>";
          if (virtualDcData.length > 0) {
            this.noticeDialog.msg += "</br></br>以下店铺(以'_'分割）存在多个配送中心进货，实际为同一店铺，为在聚宝盆中合并统计，配送中心及店铺编码合并为：</br>" + virtualDcData.join('</br>');
          }
          if (errorData.length > 0) {
            this.noticeDialog.msg += '</br></br>无效店铺列表如下（原因：不存在该店铺或近2年无进货,将在30分钟内自动处理）：</br>' + errorData.join('</br>')
          }
          this.noticeDialog.visible = true;
          this.saveDialog.form.tmpItems =  res.data.filter(e => e.dataType === '1');
          this.saveDialog.form.items = res.data.filter(e => e.dataType === '0' || (e.dataType === '1' && e.checkResult.indexOf('2') < 0 && e.checkResult.indexOf('3') < 0));

          this.saveDialog.form.exceptCodes = this.saveDialog.form.items.map(e => e.dcCode + "-" + e.shopCode);
          this.itemsPage.total = this.saveDialog.form.items.length;
          this.handleQueryChecked('itemsPage');
        }
      })
    },
    handleQueryChecked(page) {
      const total = this[page].current * this[page].size;
      this[page].rows = this.saveDialog.form.items.slice(this[page].size * (this[page].current - 1), total >= this.saveDialog.form.items.length ? this.saveDialog.form.items.length : this[page].current * this[page].size);
    },
    handleOrgChange(val, type) {
      if (val) {
        this.$nextTick(() => {
          const orgInfo = this.$refs.org.itemOptions.filter(e => e.value === val);
          if (orgInfo && orgInfo.length > 0) {
            this.saveDialog.form.orgName = orgInfo[0].label;
          }
          // 店铺对应的分公司和任务对应的分公司不一致则清空数据
          if (type === 'hand' && this.saveDialog.form.items.length > 0 && this.saveDialog.form.items[0].orgCode !== this.saveDialog.form.orgCode && val !== 'ALL') {
            this.saveDialog.form.items = [];
            this.itemsPage = {
              total: 0,
              current: 1,
              rows: [],
              size: 10,
            };
            this.$forceUpdate();
          }
        })
      } else {
        this.saveDialog.form.orgName = undefined;
      }
    },
    handleExport(row) {
      this.loading = true;
      exportShopGroups(row.id).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '店铺组店铺数据导出（' + row.groupName + '）.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    downloadImportResult() {
      let headers = {};
      let data = [];
      let title = ''
      headers = this.exportHeaders;
      data = this.saveDialog.form.tmpItems.filter(e => e.dataType === '1');
      title = '店铺组店铺导入结果';
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   padding-top: 10px;
//   padding-bottom: 5px;
// }

// ::v-deep .el-input__inner {
//   padding-left: 5px !important;
//   max-width: 200px !important;
// }
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

</style>
