var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "otherQuantity-page" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "otherQuantity-tabs",
          staticStyle: { height: "200x" },
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              staticClass: "otherQuantity-tabs-item",
              attrs: { label: "在线填写", name: "onlineWrite" },
            },
            [
              _vm.activeName == "onlineWrite"
                ? _c(
                    "div",
                    { staticClass: "otherQuantity-content" },
                    [
                      _c("v-signboard", { attrs: { title: "其他量导入列表" } }),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "otherQuantity-form",
                              staticClass: "main-search",
                              attrs: {
                                size: "mini",
                                "label-width": "auto",
                                inline: true,
                                model: _vm.form,
                              },
                            },
                            [
                              _c(
                                "el-col",
                                { staticClass: "list-head" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "订单类型",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { clearable: "" },
                                          model: {
                                            value: _vm.form.order_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "order_type",
                                                $$v
                                              )
                                            },
                                            expression: "form.order_type",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "0",
                                                label: "老品",
                                              },
                                            },
                                            [_vm._v("老品")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "1",
                                                label: "其他量预留",
                                              },
                                            },
                                            [_vm._v("其他量预留")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "2",
                                                label: "其他量已下",
                                              },
                                            },
                                            [_vm._v("其他量已下")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "订单性质",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { clearable: "" },
                                          model: {
                                            value: _vm.form.race_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "race_name",
                                                $$v
                                              )
                                            },
                                            expression: "form.race_name",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "新品",
                                                label: "新品",
                                              },
                                            },
                                            [_vm._v("新品")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "大学汛新品",
                                                label: "大学汛新品",
                                              },
                                            },
                                            [_vm._v("大学汛新品")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "考试季新品",
                                                label: "考试季新品",
                                              },
                                            },
                                            [_vm._v("考试季新品")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "外单",
                                                label: "外单",
                                              },
                                            },
                                            [_vm._v("外单")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "OEM单",
                                                label: "OEM单",
                                              },
                                            },
                                            [_vm._v("OEM单")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "新品预留",
                                                label: "新品预留",
                                              },
                                            },
                                            [_vm._v("新品预留")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "订做预留",
                                                label: "订做预留",
                                              },
                                            },
                                            [_vm._v("订做预留")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "外单预留",
                                                label: "外单预留",
                                              },
                                            },
                                            [_vm._v("外单预留")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "原始料号",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入原始料号",
                                        },
                                        model: {
                                          value: _vm.form.material_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "material_code",
                                              $$v
                                            )
                                          },
                                          expression: "form.material_code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "换算料号",
                                        size: "mini",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入换算料号",
                                        },
                                        model: {
                                          value: _vm.form.trans_material_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "trans_material_code",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.trans_material_code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "单号", size: "mini" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入单号",
                                        },
                                        model: {
                                          value: _vm.form.order_no,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "order_no", $$v)
                                          },
                                          expression: "form.order_no",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "系列", size: "mini" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入系列",
                                        },
                                        model: {
                                          value: _vm.form.series,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "series", $$v)
                                          },
                                          expression: "form.series",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "search"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("查 询 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        disabled: _vm.btnStatus.isSave,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "save"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("保 存 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "warning",
                                        disabled: _vm.btnStatus.isDelete,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "delete"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删 除 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reverseChecked()
                                        },
                                      },
                                    },
                                    [_vm._v("反 选 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleTableHeadAction(
                                            "adjust"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("确 认 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableStatus.isLoading,
                              expression: "tableStatus.isLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticClass: "table",
                          attrs: {
                            stripe: "",
                            border: "",
                            size: "mini",
                            height: "500",
                            data: _vm.lists,
                          },
                          on: { "selection-change": _vm.handleselection },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              align: "center",
                              width: "50",
                              fixed: "left",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.currentPage - 1) *
                                                _vm.pageSize +
                                              1
                                          ) + " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2762312633
                            ),
                          }),
                          _vm._l(
                            _vm.tableObj.otherQuantityHead,
                            function (item, index) {
                              return [
                                [
                                  "material_code",
                                  "trans_material_code",
                                  "material_count",
                                ].includes(item.prop)
                                  ? _c(
                                      "el-table-column",
                                      _vm._b(
                                        {
                                          key: index,
                                          attrs: { align: "center" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    item.prop == "material_code"
                                                      ? _c("el-input", {
                                                          staticClass:
                                                            "tgqrcount-cell-ipt",
                                                          attrs: {
                                                            size: "mini",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .material_code,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "material_code",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.material_code",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.prop ==
                                                    "trans_material_code"
                                                      ? _c("el-input", {
                                                          staticClass:
                                                            "tgqrcount-cell-ipt",
                                                          attrs: {
                                                            size: "mini",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .trans_material_code,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "trans_material_code",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.trans_material_code",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.prop ==
                                                    "material_count"
                                                      ? _c("el-input", {
                                                          staticClass:
                                                            "tgqrcount-cell-ipt",
                                                          attrs: {
                                                            type: "number",
                                                            size: "mini",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .material_count,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "material_count",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.material_count",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        "el-table-column",
                                        item,
                                        false
                                      )
                                    )
                                  : [
                                      "import_month",
                                      "order_type_text",
                                      "material_type",
                                      "is_keep_text",
                                    ].includes(item.prop)
                                  ? _c(
                                      "el-table-column",
                                      _vm._b(
                                        {
                                          key: index,
                                          attrs: {
                                            fixed: "left",
                                            "show-overflow-tooltip": "",
                                            align: "center",
                                          },
                                        },
                                        "el-table-column",
                                        item,
                                        false
                                      )
                                    )
                                  : _c(
                                      "el-table-column",
                                      _vm._b(
                                        {
                                          key: index,
                                          attrs: {
                                            "show-overflow-tooltip": "",
                                            align: "center",
                                          },
                                        },
                                        "el-table-column",
                                        item,
                                        false
                                      )
                                    ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                      _c("v-pagination", {
                        attrs: {
                          total: _vm.total,
                          pageSize: _vm.pageSize,
                          currentPage: _vm.currentPage,
                        },
                        on: { pageChange: _vm.pagingChange },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "el-tab-pane",
            {
              staticClass: "otherQuantity-tabs-item",
              attrs: { label: "表单上传", name: "formWrite" },
            },
            [
              _vm.activeName == "formWrite"
                ? _c(
                    "div",
                    { attrs: { clas: "form-upload-box" } },
                    [
                      _c("upload-excel", {
                        ref: "uploadExcelRef",
                        attrs: {
                          uploadDatas: _vm.uploadFileObj.tableData,
                          "on-success": _vm.handleFileSuccess,
                          "before-upload": _vm.beforeFileUpload,
                          tipsText: _vm.uploadFileObj.tipsText,
                        },
                        on: {
                          handlePreview: _vm.handlePreview,
                          handleConfirmUpload: _vm.handleConfirmUpload,
                        },
                      }),
                      _vm.uploadFileObj.preTableData.length > 0
                        ? [
                            _c(
                              "div",
                              { staticClass: "previewTable" },
                              [
                                _c("v-signboard", {
                                  attrs: { title: "文件预览" },
                                }),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { staticClass: "handleConfirmUpload" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("确认上传")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.uploadFileObj.preTableData,
                                      stripe: "",
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  _vm._l(
                                    _vm.uploadFileObj.tableHeader,
                                    function (item) {
                                      return _c("el-table-column", {
                                        key: item,
                                        attrs: { prop: item, label: item },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "previewTable previewTable-sample",
                              },
                              [
                                _c("v-signboard", {
                                  attrs: { title: "文件上传模板示例" },
                                }),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.uploadFileObj
                                        .templateSampleLists,
                                      stripe: "",
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.uploadFileObj.templateSampleHead,
                                      function (item, index) {
                                        return [
                                          _c(
                                            "el-table-column",
                                            _vm._b(
                                              {
                                                key: index,
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  align: "center",
                                                },
                                              },
                                              "el-table-column",
                                              item,
                                              false
                                            )
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }