<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery"  @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,shop"
            :dictFun="dictFun"
            fullLabel
            :clearable="orgAllAuth"
            :init="true"
            :init-options="this.options.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCodes"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            multiple
            collapse-tags
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @change="dictParam = Object.assign({}, queryForm)"
          />
        </el-form-item>
        <el-form-item label="是否支持无库存下单">
          <el-select v-model="queryForm.supportNoStock" clearable placeholder="全部">
            <el-option v-for="item in options.statusOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="商品范围">
          <el-select v-model="queryForm.itemRange" clearable placeholder="全部">
            <el-option v-for="item in options.itemRangeOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openImportConfig">开关配置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openImportItems">导入商品</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openBatchConfig">批量设置</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openImportConfig">开关配置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openImportItems">导入商品</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="openBatchConfig">批量设置</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
      v-loading="loading"
      class="customer-table"
      border
           size="small"
      ref="table"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="60"/>
      <el-table-column label="配送中心编码" prop="dcCode"  width="140">
        <template #default="{ row }">
          <span>{{ row.dcCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心名称" prop="dcName" width="200">
        <template #default="{ row }">
          <span>{{ row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属分公司编码" prop="orgCode"  width="140">
        <template #default="{ row }">
          <span>{{ row.orgCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属分公司名称" prop="orgName" width="200">
        <template #default="{ row }">
          <span>{{ row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否支持无库存下单" prop="supportNoStock"  width="180" >
        <template #default="{ row }">
          <el-tag :type="row.supportNoStock === 1 ? 'success' : 'info'">
            {{ row.supportNoStock === 1 ? '支持' : '不支持' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="商品范围" prop="itemRange"   width="180">
        <template #default="{ row }">
            {{ row.supportNoStock === 1 ? row.itemRange == 0 ? '全部无库存商品' : '清单中的无库存商品' : ''}}
        </template>
      </el-table-column>
      <el-table-column label="SKU数量" prop="skuCount"  >
        <template #default="{ row }">
          <span>{{ row.skuCount??0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template #default="{ row }">
          <div>
            <el-button size="mini" type="text" @click="handleEdit(row)">设置</el-button>
            <el-button size="mini" type="text" @click="handleDcItems(row)">查看商品</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="设置" :visible.sync="editDialog.visible" append-to-body width="600px">
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="160px" :model="editDialog.form" class="common-form">
        <el-row class="common-form-row">
            <el-col>
              <el-form-item label="分公司" prop="orgCode" label-width="200px">
                <span>{{ editDialog.form.orgCode+ '-' + editDialog.form.orgName}}</span>
              </el-form-item>
            </el-col>
        </el-row>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="配送中心" prop="dcCode" label-width="200px">
              <span>{{ editDialog.form.dcCode + '-' + editDialog.form.dcName}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="是否支持无库存下单" label-width="200px">
              <el-radio-group v-model="editDialog.form.supportNoStock">
                <el-radio :label="item.value" :key="item.value" v-for="item in options.statusOptionsWithoutAll">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="common-form-row" v-if="editDialog.form.supportNoStock === 1">
          <el-col>
            <el-form-item label="商品范围" label-width="200px">
              <el-radio-group v-model="editDialog.form.itemRange">
                <el-radio :label="item.value" :key="item.value" v-for="item in options.itemRangeOptionsWithoutAll">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="submitEdit">确定</el-button>
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量设置" :visible.sync="batchConfig.visible" append-to-body width="600px">
      <el-form ref="form" size="mini" inline label-width="160px" :model="batchConfig.form" class="common-form">
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="分公司" prop="orgCode" label-width="200px">
              <span>{{ batchConfig.form.orgCode + '-' + batchConfig.form.orgName}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="配送中心" prop="dcCode" label-width="200px" max-height="200px">
              <span>{{ batchConfig.form.dcs.map(item => item.dcCode + '-' + item.dcName).join('，') }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="是否支持无库存下单" label-width="200px">
              <el-radio-group v-model="batchConfig.form.supportNoStock">
                <el-radio :label="item.value" :key="item.value" v-for="item in options.statusOptionsWithoutAll">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="common-form-row" v-if="batchConfig.form.supportNoStock === 1">
          <el-col>
            <el-form-item label="商品范围" label-width="200px">
              <el-radio-group v-model="batchConfig.form.itemRange">
                <el-radio :label="item.value" :key="item.value" v-for="item in options.itemRangeOptionsWithoutAll">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="submitBatchEdit">确定</el-button>
        <el-button type="primary" size="mini" @click="batchConfig.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="importDc.importBaseParams.title" :visible.sync="importDc.importBaseParams.visible" append-to-body width="1000px">
        <el-row>
          <el-col :span="5" align="right" style="font-weight: bold">文件：</el-col>
          <el-col :span="10">
            <el-upload
              ref="dcConfigUpload"
              class="upload-demo"
              drag
              :limit="1"
              action="#"
              :auto-upload="false"
              :file-list="importDc.importBaseParams.fileList"
              :on-change="(file, fileList) => handleDcConfigFileChange(file, fileList, 'change')"
              :on-remove="(file, fileList) => handleDcConfigFileChange(file, fileList, 'remove')"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">
                <span>仅允许导入xls、xlsx格式文件。</span>
                <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDc.importBaseParams.templateFileDownloadUrl">下载模板</el-link>
              </div>
            </el-upload>
          </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="15" :offset="5" align="left">
          <div class="previewTable previewTable-sample">
            <mg-signboard title="文件上传模板示例"/>
            <el-table
              class="previewTable-lists"
              :data="importDc.importBaseParams.templateSampleLists"
              stripe
              border
              size="mini"
            >
              <template v-for="(item, index) in importDc.importBaseParams.templateSampleHead">
                <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
              </template>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px; margin-bottom: 20px">
        <el-col :span="10" :offset="5" align="left">
          <el-button size="mini" type="success" @click="handleImportDc">导入</el-button>
          <el-button size="mini" type="info" @click="importDc.importBaseParams.visible = false">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog :title="importItems.importBaseParams.title" :visible.sync="importItems.importBaseParams.visible" append-to-body width="1000px">
      <el-row>
        <el-col :span="5" align="right" style="font-weight: bold">文件：</el-col>
        <el-col :span="10">
          <el-upload
            ref="dcItemUpload"
            class="upload-demo"
            drag
            :limit="1"
            action="#"
            :auto-upload="false"
            :file-list="importItems.importBaseParams.fileList"
            :on-change="(file, fileList) => handleDcItemFileChange(file, fileList, 'change')"
            :on-remove="(file, fileList) => handleDcItemFileChange(file, fileList, 'remove')"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              <span>仅允许导入xls、xlsx格式文件。</span>
              <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importItems.importBaseParams.templateFileDownloadUrl">下载模板</el-link>
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="15" :offset="5" align="left">
          <div class="previewTable previewTable-sample">
            <mg-signboard title="文件上传模板示例"/>
            <el-table
              class="previewTable-lists"
              :data="importItems.importBaseParams.templateSampleLists"
              stripe
              border
              size="mini"
            >
              <template v-for="(item, index) in importItems.importBaseParams.templateSampleHead">
                <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
              </template>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="5" align="right" style="font-weight: bold">导入方式：</el-col>
        <el-col :span="10">
          <el-radio-group v-model="importItems.importBaseParams.importType">
            <el-radio :label="1">增量</el-radio>
            <el-radio :label="0">覆盖</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px; margin-bottom: 20px">
        <el-col :span="10" :offset="5" align="left">
          <el-button size="mini" type="success" @click="handleImportItems">导入</el-button>
          <el-button size="mini" type="info" @click="importItems.importBaseParams.visible = false">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-drawer title="商品管理" :visible.sync="itemsDrawer.visible" size="75%" direction="rtl" append-to-body :modal-append-to-body="false" :destroy-on-close="true">
      <NoStockOrderDcItems ref="shopDetail" :param="itemsDrawer.param" :timestamp="itemsDrawer.timestamp" />
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index.vue";
import {
  topShopDict,
  dictSelect,
  getNoStockOrderDcConfig,
  updateDcConfig,
  importDcConfig,
  importDcItem, batchUpdateDcConfig,
} from "@/api/appTask";
import { deepClone } from "@/utils"
import { Notification } from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect/index.vue";
import NoStockOrderDcItems from "@/views/appTask/noStockOrder/items";

export default {
  name: "noStockOrderDcConfig",
  components: {NoStockOrderDcItems, Pagination, DictSelect, SearchFilter },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 100,
        orgCode: undefined,
        dcCodes: undefined,
        supportNoStock: undefined,
        itemRange: undefined,
      },
      loading: false,
      dictParam: {},
      dictFun: topShopDict,
      orgAllAuth: false,
      editDialog: {
        visible: false,
        form: {
          orgCode: undefined,
          orgName: undefined,
          dcCode: undefined,
          dcName: undefined,
          supportNoStock: undefined,
          itemRange: undefined,
        },
        rules: {
        },
      },
      options: {
        statusOptions: [{value: undefined, label: '全部'},{value: 0, label: '不支持'},{value: 1, label: '支持'}],
        itemRangeOptions: [{value: undefined, label: '全部'},{value: 0, label: '全部无库存商品'},{value: 1, label: '清单中的无库存商品'}],
        statusOptionsWithoutAll: [{value: 0, label: '不支持'},{value: 1, label: '支持'}],
        itemRangeOptionsWithoutAll: [{value: 0, label: '全部无库存商品'},{value: 1, label: '清单中的无库存商品'}],

      },
      importDc: {
        currentImportType: "",
        importBaseParams: {
          visible: false,
          title: "无库存下单开关配置",
          type: "dcConfig",
          apiParams: "importList",
          fileList: [],
          accept: ['.xls', '.xlsx'],
          previewDataLength: 10,
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E6%97%A0%E5%BA%93%E5%AD%98%E4%B8%8B%E5%8D%95%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E9%85%8D%E9%80%81%E4%B8%AD%E5%BF%83%E9%85%8D%E7%BD%AE.xlsx',
          templateSampleHead: [
            { label: "配送中心编码", prop: "dcCode", minWidth: "80px" },
            { label: "是否支持无库存下单", prop: "supportNoStock", minWidth: "80px" },
            { label: "商品范围", prop: "itemRange", minWidth: "80px" },
          ],
          templateSampleLists: [
            {
              dcCode: '001',
              supportNoStock: '支持',
              itemRange: '全部无库存商品',
            },
            {
              dcCode: '002',
              supportNoStock: '不支持',
              itemRange: '清单中的无库存商品',
            },
          ],
        },
      },
      batchConfig: {
        visible: false,
        form: {
          orgCode: undefined,
          orgName: undefined,
          dcs: [],
          supportNoStock: 0,
          itemRange: 0,
        },
      },
      importItems: {
        currentImportType: "",
        importBaseParams: {
          visible: false,
          title: "批量导入商品",
          type: "dcConfig",
          apiParams: "importList",
          fileList: [],
          accept: ['.xls', '.xlsx'],
          importType: 0,
          previewDataLength: 10,
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E6%97%A0%E5%BA%93%E5%AD%98%E4%B8%8B%E5%8D%95%E5%AF%BC%E5%85%A5%E5%95%86%E5%93%81.xlsx',
          templateSampleHead: [
            { label: "配送中心编码", prop: "dcCode", minWidth: "80px" },
            { label: "商品编码", prop: "skuCode", minWidth: "80px" },
          ],
          templateSampleLists: [
            {
              dcCode: '001',
              skuCode: 'C00001',
            },
            {
              dcCode: '002',
              skuCode: 'C00002',
            },
          ],
        },
      },
      itemsDrawer: {
        visible: false,
        timestamp: undefined,
        param: {
          orgCode: undefined,
          dcCode: undefined,
        }
      }
    };
  },
  computed: {
  },
  watch: {
    'itemsDrawer.visible'(val) {
      if (!val) {
        this.handleFilter();
      }
    }
  },
  mounted() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.options.orgOptions = res.data.filter(e => e.value !== 'ALL');
      if (!this.orgAllAuth) {
        this.queryForm.orgCode = this.options.orgOptions[0].value;
      }
      // 存在分公司则加载第一个分公司数据
      if (this.options.orgOptions.length > 0) {
        this.queryForm.orgCode = this.options.orgOptions[0].value;
        this.handleFilter();
      }
    })
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      this.setQuery(query);
      getNoStockOrderDcConfig(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    openImportConfig() {
      this.importDc.importBaseParams.visible = true;
    },
    openImportItems() {
      this.importItems.importBaseParams.visible = true;
    },

    openBatchConfig() {
      if (!this.pageData.selections || this.pageData.selections.length <= 0) {
        Notification.warning('请先选中要批量配置的配送中心！');
        return;
      }
      this.batchConfig.form.orgCode = this.queryForm.orgCode;
      this.batchConfig.form.orgName = this.pageData.selections[0].orgName;
      this.batchConfig.form.dcs = [];
      this.pageData.selections.forEach(selection => {
        if (selection.dcCode && selection.dcName) {
          this.batchConfig.form.dcs.push({
            dcCode: selection.dcCode,
            dcName: selection.dcName
          });
        }
      });
      this.batchConfig.visible = true;
    },
    handleImportDc() {
      if (this.importDc.importBaseParams.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDc.importBaseParams.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.loading = true;
      importDcConfig(fd).then((res) => {
        this.loading = false;
        if (!res) {
          return
        }
        try {
          const resData = JSON.parse(res) // this.result为FileReader获取blob数据转换为json后的数据，即后台返回的原始数据
          console.log(resData)
        } catch (err) {
          console.log("------start")
          console.log(err)
          console.log("------end")

        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '无库存下单开关配置失败记录.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.$refs.dcConfigUpload.clearFiles()
      })
      setTimeout(() => {this.handleFilter();}, 1000);
      this.importDc.importBaseParams.visible = false;
    },
    handleImportItems() {
      if (this.importItems.importBaseParams.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importItems.importBaseParams.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.loading = true;
      importDcItem(fd, this.importItems.importBaseParams.importType).then((res) => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '无库存下单开关配置失败记录.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.$refs.dcItemUpload.clearFiles()
      })
      this.importItems.importBaseParams.visible = false;
      this.han
    },
    handleDcConfigFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDc.importBaseParams.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDc.importBaseParams.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDc.importBaseParams.fileList = [];
      } else {
        this.importDc.importBaseParams.fileList = fileList;
      }
    },
    handleDcItemFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importItems.importBaseParams.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importItems.importBaseParams.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importItems.importBaseParams.fileList = [];
      } else {
        this.importItems.importBaseParams.fileList = fileList;
      }
    },
    setQuery(query) {
      if (this.queryForm.orgCode) {
        query.orgCode = this.queryForm.orgCode
      }
      if (this.queryForm.dcCode) {
        query.dcCode = this.queryForm.dcCode.join(',')
      }
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 100,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleEdit(row) {
      this.editDialog.form = deepClone(row);
      this.editDialog.visible = true;
    },
    handleDcItems(row) {
      this.itemsDrawer.visible = true;
      this.itemsDrawer.param.orgCode = row.orgCode;
      this.itemsDrawer.param.dcCode = row.dcCode;
    },
    submitBatchEdit() {
      const data = this.batchConfig.form.dcs.map(e => {
        return {
          orgCode: this.batchConfig.form.orgCode,
          dcCode: e.dcCode,
          supportNoStock: this.batchConfig.form.supportNoStock,
          itemRange: this.batchConfig.form.itemRange,
        }
      })
      batchUpdateDcConfig(data).then(res => {
        this.batchConfig.visible = false;
        if(res && res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    submitEdit() {
      updateDcConfig(this.editDialog.form).then(res => {
        this.editDialog.visible = false;
        if(res && res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
</style>
