<template>
  <div style="display: inline-block">
    <el-popover
      placement="right"
      width="750"
      trigger="click"
      @show="handlePageDownload">
      <div>
        <el-row>
          <el-col :span="8">
            <el-select v-model="queryForm.status" clearable placeholder="请选择">
              <el-option v-for="(item, key) in downloadStatusMap" :label="item" :value="key" :key="key" />
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handlePageDownload">刷新</el-button>
          </el-col>
        </el-row>
        <el-table :data="rows" v-loading="loading" size="mini">
          <el-table-column property="fileName" label="文件名"></el-table-column>
          <el-table-column width="140" property="createTime" label="时间"></el-table-column>
          <el-table-column width="80" property="status" label="状态">
            <template #default="{ row }">
              <el-tag :type="$options.filters.downloadStatusFilter(row.status)">
                {{downloadStatusMap[row.status] }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60">
            <template #default="{ row }">
              <el-link v-if="row.status === '1'" type="primary" target="_blank" :href="row.downloadUrl">下载</el-link>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="total" :page.sync="queryForm.current" :pageSizes="[3, 5, 10]" :limit.sync="queryForm.size" @pagination="handlePageDownload" />
      </div>
      <el-button class="form-btn-item" size="mini" slot="reference" type="primary" style="margin-left: 10px">查看导出列表</el-button>
    </el-popover>
  </div>
</template>

<script>
import { getDownloadRecords } from "@/api/appTask";
import Pagination from "@/components/Pagination";
import Config from "../../views/appTask/acitivityHx/config";
export default {
  name: "DownLoadData",
  components: { Pagination },

  props: {
    type: {
      type: String,
      required: true,
    }
  },
  filters: {
    downloadStatusFilter(status) {
      const statusMap = {
        "0": "primary",
        "1": "success",
        "2": "error",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      total: 0,
      loading: false,
      rows: [],
      queryForm: {
        current: 1,
        size: 3,
        type: this.type,
      },
      downloadStatusMap: {
        "1": "生成成功",
        "0": "生成中",
        "2": "生成失败"
      }
    }
  },
  methods: {
    handlePageDownload() {
      getDownloadRecords(this.queryForm).then(res => {
        this.rows = res.data.records;
        this.total = res.data.total;
      })
    },
  }
}
</script>

<style scoped>

</style>
