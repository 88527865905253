<template>
  <div style="margin-left: 20px" :loading="loading" >
    <el-form ref="addForm" :model="form" :rules="rules">
      <el-form-item label="选择分组" prop="groupObj">
        <el-select v-model="form.groupObj" remote clearable filterable value-key="id" placeholder="请输入分组名称" :remote-method="remoteMethod" size="mini" @change="handleGroupChange" @keyup.enter.native="handleQuery">
          <el-option v-for="item in options.groups" :key="item.id" :value="item" :label="item.ruleGroupName" />
        </el-select>
      </el-form-item>
      <el-form-item label="选择版本" prop="version">
        <el-select v-model="form.version" filterable placeholder="请选择版本" size="mini">
          <el-option v-for="item in options.versions" :key="item" :value="item" :label="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="触达方式" prop="reachMode">
        <template>
          <el-radio v-model="form.reachMode" label="manual" @input = "handleIfLimitDisabled">手动触达</el-radio>
          <el-radio :disabled="autoDisabled" v-model="form.reachMode" label="auto" @input = "handleIfLimitDisabled">自动触达</el-radio>
        </template>
      </el-form-item>
      <el-form-item v-if="form.reachMode==='auto'" label="触达频次">
        <span>{{this.form.reachFrequency}}</span>
      </el-form-item>
      <el-form-item label="规则编辑">
        <template>
          <el-radio :disabled="ifLimitDisabled" v-model="form.ifLimit" label="Y" @input = "handleRuleDetails">限制额度</el-radio>
          <el-radio v-model="form.ifLimit" label="N" @input = "handleRuleDetails">不限制额度</el-radio>
          <el-radio v-model="form.ifLimit" label="C" @input = "handleRuleDetails">直推crm</el-radio>
        </template>
        <div v-if="form.ifLimit !== 'C'" style="width: 32%">
          <el-table
            :data="form.channelRuleDetails"
            size="mini"
            border
            highlight-current-row
            :row-style="{height:0+'px'}"
            :cell-style="{padding:0+'px'}"
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
          >
            <el-table-column label="优先级" type="index" width="60" />
            <el-table-column label="渠道" prop="channel" width="100">
              <template slot-scope="scope">
                <el-select v-model="scope.row.channel" size="mini" :disabled="runDisabled" @change="val => handleChannelChange(val, scope.row)">
                  <el-option v-for="item in channels['channel' + scope.$index]" :key="item" :value="item" :label="item" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="触达比例" prop="reachRatio" width="130">
              <template slot-scope="{ row }">
                <el-input-number
                  v-model="row.reachRatio"
                  :disabled="row.channel === '电话' || runDisabled"
                  style="width: 100px"
                  :controls="false"
                  placeholder="请输入比例"
                  size="mini"
                  :min="1"
                  :max="100"
                ></el-input-number><i slot="suffix">%</i>
              </template>
            </el-table-column>
            <el-table-column align="center">
              <template slot-scope="scope">
                <el-button size="mini" :disabled="!scope.row.channel" type="plain" @click="handleAddTagCondition(scope.row)">条件编辑</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="text" style="border: 0px;" :disabled="runDisabled" @click="form.channelRuleDetails.splice(scope.$index , 1)">删除</el-button>
              </template>
            </el-table-column>
            <el-table-column align="center" >
              <template slot-scope="{ row }">
                <span v-for="item in row.tagConditions" :key="item.tagName">{{ item.tagName + '-' + (item.order==='asc'?'升序':item.order==='desc'?'降序':'') }}</span><br/>
              </template>
            </el-table-column>
          </el-table>
          <el-button v-if="form.channelRuleDetails.length < 3" :disabled="runDisabled" type="text" plain size="mini" @click="form.channelRuleDetails.push({})">新增渠道</el-button>
        </div>
        <div v-if="form.ifLimit === 'C'" style="width: 32%">
          <el-table
            :data="form.channelRuleDetails"
            size="mini"
            border
            highlight-current-row
            :row-style="{height:0+'px'}"
            :cell-style="{padding:0+'px'}"
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
          >
            <el-table-column label="优先级" type="index" />
            <el-table-column label="渠道" prop="channel" />
            <el-table-column label="触达比例" prop="reachRatio" >
              <template slot-scope="{ row }">
                <el-input-number
                  v-model="row.reachRatio"
                  :disabled="row.channel === 'crm' || runDisabled"
                  style="width: 100px"
                  :controls="false"
                  placeholder="请输入比例"
                  size="mini"
                  :min="1"
                  :max="100"
                ></el-input-number><i slot="suffix">%</i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <el-form-item>
        <div v-if="form.channelRuleDetails.length > 0" style="text-align: center; width: 32%">
          <el-button type="primary" size="mini" :disabled="runDisabled" @click="handleViewResult"> {{ currentRunning.status? buttonShowMap[currentRunning.status]: '生成运算结果' }}</el-button>
          <el-table
            :data="viewData"
            size="mini"
            border
            highlight-current-row
            :row-style="{height:0+'px'}"
            :cell-style="{padding:0+'px'}"
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
          >
            <el-table-column label="渠道" prop="channel" />
            <el-table-column label="可触达总数" prop="totalReachCount" />
            <el-table-column label="触达比例" prop="reachRatio" />
            <el-table-column label="实际触达" prop="actualReachCount" />
            <el-table-column label="未触达" prop="noReachCount" />
          </el-table>
        </div>
      </el-form-item>
    </el-form>
    <div v-if="viewData.length > 0" slot="footer" class="dialog-footer" style="text-align: center; width: 32%">
      <el-button v-if="currentRunning.status === '创建成功'" :disabled="buttonDisabled==='confirm'" size="mini" type="primary" @click="submitAddDistribute">确定</el-button>
      <el-button size="mini" @click="cancel">取消</el-button>
    </div>
    <!-- 新增条件 -->
    <el-dialog :title="'分发渠道补充条件（' + currentRow.channel + ')'" :visible.sync="addTagConditionOpen" width="500px">
      <el-table :data="addTagConditionData" size="mini">
        <el-table-column prop="tagName" label="标签名称" width="200">
          <template #default="scope">
            <el-select v-model="scope.row.tagId" size="mini" filterable placeholder="支持搜索/下拉选择" @change="val => {
              const item = numTagOptions.find(e => e.tagId === val);
              scope.row.tagName = item.tagName;
              scope.row.tagColName = item.tagColName;
            }">
              <el-option
                v-for="item in numTagOptions"
                value-key = "item.tagId"
                :key="item.tagId"
                :label="item.tagName"
                :value="item.tagId">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="order" label="排序">
          <template #default="scope">
            <el-select v-model="scope.row.order" size="mini" placeholder="下拉选择升序/降序">
              <el-option value="asc" label="升序">升序</el-option>
              <el-option value="desc" label="降序">降序</el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="deleteAddTagConditionLine(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="text"
            icon="Plus"
            size="mini"
            @click="addTagConditionLine()"
          >新增条件</el-button>
        </el-col>
      </el-row>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="mini" type="primary" @click="submitAddTagConditionData()">保 存</el-button>
          <el-button size="mini" @click="addTagConditionCancel()">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getGroupList, getVersionByGroup, viewExecuteResult, addDistribute, getExecuteResultList, getExecuteDistributeDetail, getExecuteResult, getNumTagList } from "@/api/tagPlatform";
import { Notification } from "element-ui";

export default {
  name: "Add",
  data() {
    return {
      loading: false,
      form: {
        groupObj: undefined,
        ruleGroupName: undefined,
        ruleGroupId: undefined,
        version: undefined,
        channelRuleDetails: [],
        ifLimit: 'Y',
        reachMode: 'manual',
        dateType: '天',
        weekDay: '2',
        dayType: 'first',
        day: undefined,
        reachFrequency: undefined,
      },
      rules: {
        groupObj: [{ required: true, message: ' ', trigger: 'change' },],
        version: [{ required: true, message: ' ', trigger: 'change' },]
      },
      options: {
        groups: [],
        versions: [],
        defaultChannels: ['电话', '企微', '短信', 'crm(企微)'],
      },
      currentRunning: {},
      buttonShowMap: {
        '创建中': '创建中',
        '创建成功': '创建成功',
        '创建失败': '重新生成',
      },
      viewData: [],
      buttonDisabled: undefined,
      // 分组分发试算新增是否显示新增条件
      addTagConditionOpen: false,
      // 分组分发试算新增是否显示新增条件数据
      addTagConditionData:[{
        tagId: undefined,
        tagName: undefined,
        tagColName: undefined,
        order: undefined
      }],
      // 分组分发试算新增条件弹框标题
      title: "",
      currentRow: {
        tagConditions:[]
      },
      numTagOptions: [],
      autoDisabled: true,
      ifLimitDisabled: false,
    }
  },
  computed: {
    channel0() {
      const channels = this.options.defaultChannels.filter(e => {
        return this.form.channelRuleDetails.filter((i,index) => index !==0).map(ele => ele.channel).indexOf(e) === -1;
      })

      const chooseChannels = this.form.channelRuleDetails.filter((i,index) => i.channel === 'crm(企微)' && index !==0).map(e => e.channel);
      if(chooseChannels.length > 0){
        return channels.filter(e => e !== '企微')
      }
      const chooseWechatChannel = this.form.channelRuleDetails.filter((i,index) => i.channel === '企微' && index !==0).map(e => e.chaannel);
      if(chooseWechatChannel.length > 0){
        return channels.filter(e => e !== 'crm(企微)')
      }
      return channels;
    },
    channel1() {
      const channels = this.options.defaultChannels.filter(e => {
        return this.form.channelRuleDetails.filter((i,index) => index !==1).map(ele => ele.channel).indexOf(e) === -1;
      })
      const chooseChannels = this.form.channelRuleDetails.filter((i,index) => i.channel === 'crm(企微)' && index !==1).map(e => e.channel);
      if(chooseChannels.length > 0){
        return channels.filter(e => e !== '企微')
      }
      const chooseWechatChannel = this.form.channelRuleDetails.filter((i,index) => i.channel === '企微' && index !==1).map(e => e.chaannel);
      if(chooseWechatChannel.length > 0){
        return channels.filter(e => e !== 'crm(企微)')
      }
      return channels;
    },
    channel2() {
      const channels = this.options.defaultChannels.filter(e => {
        return this.form.channelRuleDetails.filter((i,index) => index !==2).map(ele => ele.channel).indexOf(e) === -1;
      })
      const chooseChannels = this.form.channelRuleDetails.filter((i,index) => i.channel === 'crm(企微)' && index !==2).map(e => e.channel);
      if(chooseChannels.length > 0){
        return channels.filter(e => e !== '企微')
      }
      const chooseWechatChannel = this.form.channelRuleDetails.filter((i,index) => i.channel === '企微' && index !==2).map(e => e.chaannel);
      if(chooseWechatChannel.length > 0){
        return channels.filter(e => e !== 'crm(企微)')
      }
      return channels;
    },
    channels() {
      return { channel0: this.channel0, channel1: this.channel1, channel2: this.channel2}
    },
    runDisabled() {
      return this.currentRunning.status && this.currentRunning.status==='创建成功';
    },
  },
  created() {
    this.startGetInfoInterval();
    getNumTagList().then(response => {
      this.numTagOptions = response.data;
    })
  },
  beforeDestroy() {
    this.clearGetInfoInterVal();
  },
  methods: {
    getInfo() {
      this.currentRunning = {};
      getExecuteResultList().then(res => {
        if (res.data && res.data.length > 0) {
          const data = res.data[0];
          this.currentRunning = data;
          this.$emit('show-notice', data);
          this.getExecuteResult(data.id);
          if (data.status === '创建成功') {
            getExecuteResult(data.id).then(resp => {
              this.viewData = resp.data;
            })
            this.clearGetInfoInterVal();
          }
        }
      })
    },
    getExecuteResult(id) {
      getExecuteDistributeDetail(id).then(r => {
        if (r.data) {
          this.options.groups = [{ id: r.data.id, ruleGroupName: r.data.ruleGroupName, ruleGroupId: r.data.ruleGroupId }];
          this.form.groupObj = this.options.groups[0];
          this.form.ruleGroupName = r.data.ruleGroupName;
          this.form.ruleGroupId = r.data.ruleGroupId;
          this.handleLastGroupChange(this.form.groupObj);
          this.form.version = r.data.version;
          this.form.ifLimit = r.data.ruleType;
          this.form.reachMode = r.data.reachMode;
          if(this.form.reachMode === 'auto'){
            this.autoDisabled = false;
            getGroupList({ ruleGroupName: r.data.ruleGroupName }).then(res => {
              this.form.reachFrequency = res.data[0].reachFrequency;
            })
          }
          this.form.channelRuleDetails = r.data.channelRuleDetails.map(e => {
            e.reachRatio = e.reachRatio.replace('%', '')
            return e;
          });
        } else {
          Notification.error("获取运算结果失败！")
        }
      })
    },
    handleGroupChange(val) {
      this.form.version = undefined;
      if (val) {
        this.form.ruleGroupName = val.ruleGroupName;
        this.form.ruleGroupId = val.ruleGroupId;
        this.options.versions = [];
        if(val.updateMode === '2' && val.active === 1){
          this.autoDisabled = false;
          this.form.reachFrequency = val.reachFrequency;
        }else {
          this.autoDisabled = true;
          this.form.reachMode = 'manual';
          this.form.reachFrequency = undefined;
        }
        getVersionByGroup(val.ruleGroupId).then(res => {
          this.form.version = res.data[0];
          this.options.versions = res.data;
        })
      } else {
        this.form.ruleGroupName = undefined;
        this.form.ruleGroupId = undefined;
      }
    },
    handleLastGroupChange(val) {
      this.form.version = undefined;
      if (val) {
        this.form.ruleGroupName = val.ruleGroupName;
        this.form.ruleGroupId = val.ruleGroupId;
        this.options.versions = [];
        getVersionByGroup(val.ruleGroupId).then(res => {
          this.form.version = res.data[0];
          this.options.versions = res.data;
        })
      } else {
        this.form.ruleGroupName = undefined;
        this.form.ruleGroupId = undefined;
      }
    },
    remoteMethod(val) {
      if (val) {
        getGroupList({ ruleGroupName: val }).then(res => {
          this.options.groups = res.data;
        })
      }
    },
    handleChannelChange(val, row) {
      if (val === '电话') {
        row.reachRatio = 100;
      } else {
        row.reachRatio = 1;
      }
    },
    handleViewResult() {
      this.$refs.addForm.validate(valaid => {
        if (valaid) {
          const nullArray = this.form.channelRuleDetails.filter(e => !e.channel || !e.reachRatio);
          if (nullArray.length > 0) {
            Notification.warning('渠道和触达比例不能为空');
            return;
          }
          const param = this.getParam();
          this.loading = true;
          this.viewData = [];
          viewExecuteResult(param).then(res => {
            if (res.code === 200) {
              this.loading = false;
              Notification.success('操作成功！');
              this.startGetInfoInterval();
            }
          })
        }
      })
    },
    getParam() {
      return {
        ruleGroupId: this.form.ruleGroupId,
        ruleGroupName: this.form.ruleGroupName,
        version: this.form.version,
        channelRuleDetails: this.form.channelRuleDetails.map((e,index) => { e.priority = index + 1; return e; }),
        ifLimit: this.form.ifLimit,
        reachMode: this.form.reachMode,
      }
    },
    submitAddDistribute() {
      this.buttonDisabled = 'confirm';
      addDistribute(this.currentRunning.id).then(res => {
        this.buttonDisabled = undefined;
        if (res.code === 200) {
          this.form = {
            groupObj: undefined,
              ruleGroupName: undefined,
              ruleGroupId: undefined,
              version: undefined,
              channelRuleDetails: []
          };
          Notification.success('操作成功！');
          this.getInfo();
          this.$emit('tab-change');
        } else {
          Notification.error('提交失败！');
        }
      })
    },
    cancel() {
      this.clearGetInfoInterVal();
      this.currentRunning = {}
    },
    startGetInfoInterval() {
      this.clearGetInfoInterVal();
      this.getInfo();
      this.timer = setInterval(() => {
        this.getInfo();
      }, 10000)
    },
    clearGetInfoInterVal() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    handleAddTagCondition(row) {
      this.addTagConditionData = [];
      if (!row.tagConditions) {
        row.tagConditions = [];
      }
      this.addTagConditionData = Object.assign([],row.tagConditions);
      this.addTagConditionOpen = true;
      this.currentRow = row;
    },
    addTagConditionLine(){
        this.addTagConditionData.push({
          tagId: undefined,
          tagName: undefined,
          tagColName: undefined,
          order: undefined
        });
    },
    deleteAddTagConditionLine(index){
      this.addTagConditionData.splice(index, 1)
    },
    addTagConditionCancel() {
      this.addTagConditionOpen = false;
      this.addTagConditionData = [];
    },
    submitAddTagConditionData() {
      this.addTagConditionOpen = false;
      for(const i in this.addTagConditionData) {
        if (!(this.addTagConditionData[i].order && this.addTagConditionData[i].tagName)) {
          Notification.warning('有未选择数据，请选择后重新提交！');
          return;
        }
      }
      this.currentRow.tagConditions = Object.assign([],this.addTagConditionData);
      this.addTagConditionData = []
    },

    handleRuleDetails(){
      this.form.channelRuleDetails = [];
      if(this.form.ifLimit === 'C'){
        this.form.channelRuleDetails.push({index:1, channel:'crm', reachRatio:'100'})
      }
    },
    handleIfLimitDisabled(){
      if(this.form.reachMode === 'manual'){
        this.ifLimitDisabled = false;
      }else if(this.form.reachMode === 'auto'){
        this.ifLimitDisabled = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
