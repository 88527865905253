var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticClass: "new-box",
    },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          fullLabel: "",
                          "init-options": _vm.options.orgOptions,
                          init: "",
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          change: _vm.handleOrgChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示时间", prop: "startTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          format: "yyyy-MM",
                          "default-time": ["00:00:00", "23:59:59"],
                          clearable: false,
                        },
                        on: {
                          change: (val) => _vm.handleDateChange(val, "query"),
                        },
                        model: {
                          value: _vm.queryForm.queryMth,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "queryMth", $$v)
                          },
                          expression: "queryForm.queryMth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleEdit()
                },
              },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                disabled: _vm.pageData.rows.length === 0,
                size: "mini",
                icon: "el-icon-check",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.handleTargetSort()
                },
              },
            },
            [_vm._v("保存排序")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", type: "warning" },
              on: {
                click: () => {
                  _vm.userTagDrawer.visible = true
                },
              },
            },
            [_vm._v("人员角色配置")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { border: "0px" },
              attrs: { size: "mini", type: "primary" },
              on: {
                click: () => {
                  _vm.mthSummaryDrawer.visible = true
                },
              },
            },
            [_vm._v("查看月度成绩")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _c("el-table-column", {
                attrs: { label: "分公司编码-名称", prop: "orgCode" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "名称", prop: "backgroundName" },
              }),
              _c("el-table-column", {
                attrs: { label: "月份", prop: "startTime" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(new Date(row.startTime).getMonth() + 1)
                          ),
                        ]),
                        _vm._v("月 "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "250", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            new Date(scope.row.endTime).getTime() >=
                            new Date().getTime()
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope.row, "edit")
                                      },
                                    },
                                  },
                                  [_vm._v("编辑配置")]
                                )
                              : _vm._e(),
                            new Date(scope.row.endTime).getTime() <
                            new Date().getTime()
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope.row, "view")
                                      },
                                    },
                                  },
                                  [_vm._v("查看配置")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleViewScope(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看成绩")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-upload2",
                                disabled: scope.$index === 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMove("up", scope.$index)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-download",
                                disabled:
                                  scope.$index === _vm.pageData.rows.length - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMove("down", scope.$index)
                                },
                              },
                            }),
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCopyTargetDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "绩效设置",
            visible: _vm.targetDrawer.visible,
            size: "65%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.targetDrawer, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "jxFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.targetDrawer.form,
                rules: _vm.targetDrawer.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "分公司", size: "mini", prop: "orgCode" },
                    },
                    [
                      _c("DictSelect", {
                        ref: "orgUpdate",
                        attrs: {
                          value: _vm.targetDrawer.form.orgCode,
                          fullLabel: "",
                          type: "org",
                          clearable: false,
                          "init-options": _vm.options.orgOptions,
                          init: "",
                          disabled: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.targetDrawer.form,
                              "orgCode",
                              $event
                            )
                          },
                          change: _vm.handleOrgChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "得分类型", size: "mini" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.targetDrawer.form.scoreType,
                            callback: function ($$v) {
                              _vm.$set(_vm.targetDrawer.form, "scoreType", $$v)
                            },
                            expression: "targetDrawer.form.scoreType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 2 } }, [
                            _vm._v("金额"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("分数"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "计算类型", size: "mini" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            input: () =>
                              (_vm.targetDrawer.form.condition = "1"),
                          },
                          model: {
                            value: _vm.targetDrawer.form.computeType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.targetDrawer.form,
                                "computeType",
                                $$v
                              )
                            },
                            expression: "targetDrawer.form.computeType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: 1 } }, [
                            _vm._v("梯度"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 2 } }, [
                            _vm._v("百分比"),
                          ]),
                          _c("el-radio-button", { attrs: { label: 4 } }, [
                            _vm._v("负向奖励"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "计算条件", size: "mini" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.handleComputeChange },
                          model: {
                            value: _vm.targetDrawer.form.condition,
                            callback: function ($$v) {
                              _vm.$set(_vm.targetDrawer.form, "condition", $$v)
                            },
                            expression: "targetDrawer.form.condition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "1" } }, [
                            _vm._v("单项计算"),
                          ]),
                          _c(
                            "el-radio-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.targetDrawer.form.computeType === 4,
                                label: "2",
                              },
                            },
                            [_vm._v("混合指标-且")]
                          ),
                          _c(
                            "el-radio-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.targetDrawer.form.computeType === 4,
                                label: "3",
                              },
                            },
                            [_vm._v("混合指标-或")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "App显示名称",
                        size: "mini",
                        prop: "targetName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "聚宝盆App上显示",
                        },
                        model: {
                          value: _vm.targetDrawer.form.targetName,
                          callback: function ($$v) {
                            _vm.$set(_vm.targetDrawer.form, "targetName", $$v)
                          },
                          expression: "targetDrawer.form.targetName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "后台名称",
                        size: "mini",
                        prop: "backgroundName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "仅供后台区分" },
                        model: {
                          value: _vm.targetDrawer.form.backgroundName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.targetDrawer.form,
                              "backgroundName",
                              $$v
                            )
                          },
                          expression: "targetDrawer.form.backgroundName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示时间", prop: "startTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          format: "yyyy-MM",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        on: { change: (val) => _vm.handleDateChange(val) },
                        model: {
                          value: _vm.targetDrawer.form.showDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.targetDrawer.form, "showDate", $$v)
                          },
                          expression: "targetDrawer.form.showDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "指标数据",
                            size: "mini",
                            prop: "quotaIds",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-plus",
                                type: "primary",
                              },
                              on: { click: _vm.handleAddQuota },
                            },
                            [_vm._v("添加")]
                          ),
                          _vm._l(
                            _vm.targetDrawer.form.quotaIds,
                            function (item, index) {
                              return _c(
                                "el-row",
                                { key: index, staticStyle: { width: "500px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 10 } },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: _vm.getQuotaTagType(
                                              _vm.targetDrawer.form.quotaIds,
                                              item
                                            ),
                                            size: "small",
                                            closable: "",
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.handleQuotaChange(
                                                item,
                                                "remove",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getQuotaInfo(item).quotaName
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "form-btn-item",
                                          attrs: {
                                            disabled:
                                              _vm.getQuotaInfo(item).limits
                                                .length === 0,
                                            size: "mini",
                                            type: "text",
                                            plain: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleSetQuotaLimit(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("设置")]
                                      ),
                                      _c("el-button", {
                                        staticStyle: { border: "0px" },
                                        attrs: {
                                          disabled:
                                            index === 0 || _vm.quotaMoving,
                                          type: "text",
                                          icon: "el-icon-arrow-up",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleMoveQuota(
                                              item,
                                              index,
                                              "up"
                                            )
                                          },
                                        },
                                      }),
                                      _c("el-button", {
                                        staticStyle: { border: "0px" },
                                        attrs: {
                                          disabled:
                                            index ==
                                              _vm.targetDrawer.form.quotaIds
                                                .length -
                                                1 || _vm.quotaMoving,
                                          type: "text",
                                          icon: "el-icon-arrow-down",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleMoveQuota(
                                              item,
                                              index,
                                              "down"
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.validateQuota("任务") || _vm.validateQuota("238盘点")
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "任务",
                                size: "mini",
                                prop: "mtTaskId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "default-first-option": "",
                                    clearable: "",
                                  },
                                  on: { change: _vm.handleTaskChange },
                                  model: {
                                    value: _vm.targetDrawer.form.mtTaskId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.targetDrawer.form,
                                        "mtTaskId",
                                        $$v
                                      )
                                    },
                                    expression: "targetDrawer.form.mtTaskId",
                                  },
                                },
                                _vm._l(
                                  _vm.options.taskOptions,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.mtTaskId,
                                      attrs: {
                                        value: item.mtTaskId,
                                        label:
                                          "(" +
                                          item.orgName +
                                          ")" +
                                          item.taskName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.targetDrawer.form.quotaIds &&
                  _vm.targetDrawer.form.quotaIds.length > 0
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-plus",
                                type: "primary",
                              },
                              on: { click: _vm.handleAddQuotaUser },
                            },
                            [_vm._v("添加用户")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-upload",
                                type: "primary",
                              },
                              on: { click: _vm.handleImport },
                            },
                            [_vm._v("导入用户")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.targetDrawer.form.quotaIds &&
                  _vm.targetDrawer.form.quotaIds.length > 0
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { lable: "用户列表" } },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "userTableRef",
                                  attrs: {
                                    data: _vm.targetDrawer.form.userData,
                                    "max-height": "500px",
                                    size: "mini",
                                    "highlight-current-row": "",
                                    "header-cell-style": {
                                      background: "rgb(235 235 235)",
                                      color: "black",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    },
                                    "span-method": _vm.objectSpanMethod,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "账号",
                                      prop: "userName",
                                      width: "100",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "姓名",
                                      prop: "nickName",
                                      width: "100",
                                      align: "center",
                                    },
                                  }),
                                  _vm.validateDcQuota()
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "配送中心",
                                          prop: "dcCode",
                                          width: "300",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100px",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        disabled:
                                                          !_vm.validateDcQuota(),
                                                        placeholder: "请选择",
                                                      },
                                                      on: {
                                                        change: (val) =>
                                                          _vm.handleShopRangeChange(
                                                            val,
                                                            scope.row,
                                                            scope.$index
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.shopRange,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "shopRange",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.shopRange",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.options
                                                        .shopRangeOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  scope.row.shopRange === "2"
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                          attrs: {
                                                            filterable: "",
                                                            placeholder:
                                                              "请选择配送中心",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.dcCode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "dcCode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.dcCode",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.options.dcOptions,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.value,
                                                                attrs: {
                                                                  label:
                                                                    item.value +
                                                                    "-" +
                                                                    item.label,
                                                                  value:
                                                                    item.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3408674681
                                        ),
                                      })
                                    : _vm._e(),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "指标",
                                      prop: "quotaName",
                                      width: "350",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getQuotaInfo(
                                                      row.jxQuotaId
                                                    ).quotaName
                                                  )
                                                ),
                                              ]),
                                              _vm.options.boostQuotas.indexOf(
                                                _vm.getQuotaInfo(row.jxQuotaId)
                                                  .quotaCode
                                              ) > -1
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "120px",
                                                      },
                                                      attrs: {
                                                        "collapse-tags": "",
                                                        multiple: "",
                                                        filterable: "",
                                                        "default-first-option":
                                                          "",
                                                        placeholder:
                                                          "关联激励 必填",
                                                      },
                                                      model: {
                                                        value: row.boostId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "boostId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.boostId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.options.boosts,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.id,
                                                          attrs: {
                                                            value: item.id,
                                                            label: item.name,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.options.boostQuotas.indexOf(
                                                _vm.getQuotaInfo(row.jxQuotaId)
                                                  .quotaCode
                                              ) > -1
                                                ? _c("el-input", {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      placeholder: "简称 ",
                                                    },
                                                    model: {
                                                      value: row.boostShortName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "boostShortName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.boostShortName",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4201634154
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "目标",
                                      prop: "target",
                                      width: "200",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return _vm._l(
                                              row.rules,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _vm.targetDrawer.form
                                                      .computeType === 1
                                                      ? _c("span", [
                                                          _vm._v(" ≥"),
                                                        ])
                                                      : _vm._e(),
                                                    _c("el-input-number", {
                                                      staticStyle: {
                                                        width: "100px",
                                                      },
                                                      attrs: {
                                                        controls: false,
                                                        size: "mini",
                                                        placeholder:
                                                          "目标 必填",
                                                      },
                                                      model: {
                                                        value: item.numA,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "numA",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.numA",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getQuotaInfo(
                                                            item.jxQuotaId
                                                          ).unit
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1700080389
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label:
                                        _vm.targetDrawer.form.scoreType === 1
                                          ? "得分"
                                          : "金额",
                                      prop: "boost",
                                      width: "300",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _vm.targetDrawer.form
                                                .computeType === 4
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      row.rules,
                                                      function (item, index) {
                                                        return _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "el-row",
                                                              [
                                                                _vm._v(
                                                                  " 达成奖励 "
                                                                ),
                                                                _vm.targetDrawer
                                                                  .form
                                                                  .scoreType ===
                                                                1
                                                                  ? _c(
                                                                      "el-input-number",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100px",
                                                                          },
                                                                        attrs: {
                                                                          controls: false,
                                                                          size: "mini",
                                                                          placeholder:
                                                                            "得分 必填",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.score,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "score",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.score",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.targetDrawer
                                                                  .form
                                                                  .scoreType ===
                                                                2
                                                                  ? _c(
                                                                      "el-input-number",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100px",
                                                                          },
                                                                        attrs: {
                                                                          controls: false,
                                                                          size: "mini",
                                                                          placeholder:
                                                                            "金额 必填",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.money,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "money",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "item.money",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .targetDrawer
                                                                          .form
                                                                          .scoreType ===
                                                                          2
                                                                          ? "元"
                                                                          : "分"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-row",
                                                              [
                                                                _vm._v(
                                                                  " 每不达成 "
                                                                ),
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "80px",
                                                                      },
                                                                    attrs: {
                                                                      controls: false,
                                                                      size: "mini",
                                                                      placeholder:
                                                                        "得分 必填",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.perNum,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "perNum",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.perNum",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getQuotaInfo(
                                                                          item.jxQuotaId
                                                                        ).unit
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" 扣"),
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "80px",
                                                                      },
                                                                    attrs: {
                                                                      controls: false,
                                                                      size: "mini",
                                                                      placeholder:
                                                                        "得分 必填",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.perBoostNum,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "perBoostNum",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.perBoostNum",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .targetDrawer
                                                                          .form
                                                                          .scoreType ===
                                                                          2
                                                                          ? "元"
                                                                          : "分"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-row",
                                                              [
                                                                _vm._v(
                                                                  " 最多扣减 "
                                                                ),
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100px",
                                                                      },
                                                                    attrs: {
                                                                      controls: false,
                                                                      size: "mini",
                                                                      placeholder:
                                                                        "得分 必填",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.maxNum,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "maxNum",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.maxNum",
                                                                    },
                                                                  }
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .targetDrawer
                                                                          .form
                                                                          .scoreType ===
                                                                          2
                                                                          ? "元"
                                                                          : "分"
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm.targetDrawer.form
                                                    .condition !== "1"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _vm.targetDrawer.form
                                                        .scoreType === 1
                                                        ? _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                width: "100px",
                                                              },
                                                              attrs: {
                                                                controls: false,
                                                                size: "mini",
                                                                placeholder:
                                                                  "得分 必填",
                                                              },
                                                              model: {
                                                                value:
                                                                  row.boostNum,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "boostNum",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.boostNum",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _vm.targetDrawer.form
                                                        .scoreType === 2
                                                        ? _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                width: "100px",
                                                              },
                                                              attrs: {
                                                                controls: false,
                                                                size: "mini",
                                                                placeholder:
                                                                  "金额 必填",
                                                              },
                                                              model: {
                                                                value:
                                                                  row.boostNum,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "boostNum",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.boostNum",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.targetDrawer
                                                                .form
                                                                .scoreType === 2
                                                                ? "元"
                                                                : "分"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    _vm._l(
                                                      row.rules,
                                                      function (item, index) {
                                                        return _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            _vm.targetDrawer
                                                              .form
                                                              .scoreType === 1
                                                              ? _c(
                                                                  "el-input-number",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100px",
                                                                      },
                                                                    attrs: {
                                                                      controls: false,
                                                                      size: "mini",
                                                                      placeholder:
                                                                        "得分 必填",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.score,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "score",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.score",
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _vm.targetDrawer
                                                              .form
                                                              .scoreType === 2
                                                              ? _c(
                                                                  "el-input-number",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100px",
                                                                      },
                                                                    attrs: {
                                                                      controls: false,
                                                                      size: "mini",
                                                                      placeholder:
                                                                        "金额 必填",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.money,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "money",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.money",
                                                                    },
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .targetDrawer
                                                                      .form
                                                                      .scoreType ===
                                                                      2
                                                                      ? "元"
                                                                      : "分"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("el-button", {
                                                              attrs: {
                                                                disabled:
                                                                  _vm
                                                                    .targetDrawer
                                                                    .form
                                                                    .computeType ===
                                                                  2,
                                                                size: "mini",
                                                                type: "text",
                                                                plain: "",
                                                                icon: "el-icon-circle-plus-outline",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  let cloneRule =
                                                                    _vm.deepClone(
                                                                      _vm.defaultRule
                                                                    )
                                                                  cloneRule.jxQuotaId =
                                                                    item.jxQuotaId
                                                                  row.rules.push(
                                                                    cloneRule
                                                                  )
                                                                },
                                                              },
                                                            }),
                                                            _c("el-button", {
                                                              attrs: {
                                                                disabled:
                                                                  row.rules
                                                                    .length ==
                                                                  1,
                                                                size: "mini",
                                                                type: "text",
                                                                plain: "",
                                                                icon: "el-icon-remove-outline",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  row.rules.splice(
                                                                    index,
                                                                    1
                                                                  )
                                                                },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1521477976
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      width: "200",
                                      prop: "action",
                                      align: "center",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 2 } },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                          plain: "",
                                                          icon: "el-icon-remove-outline",
                                                        },
                                                        on: {
                                                          click: () => {
                                                            _vm.targetDrawer.form.userData.splice(
                                                              scope.$index,
                                                              1
                                                            )
                                                            _vm.resetPageData()
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: { span: 16 },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            type: "text",
                                                            plain: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleUserCopy(
                                                                scope.row,
                                                                scope.$index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "一键同步至其他用户"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2464590093
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                bottom: "50px",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.targetDrawer.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.targetDrawer.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.targetDrawer.type !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.targetDrawer.buttonLoading,
                        size: "mini",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加用户",
            visible: _vm.userDialog.visible,
            "append-to-body": "",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "500px" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入关键字进行过滤",
                  size: "mini",
                  clearable: "",
                },
                on: { input: _vm.handleFilterChange },
                model: {
                  value: _vm.userDialog.filterText,
                  callback: function ($$v) {
                    _vm.$set(_vm.userDialog, "filterText", $$v)
                  },
                  expression: "userDialog.filterText",
                },
              }),
              _c("el-tree", {
                ref: "userTagTreeRef",
                staticStyle: { height: "500px", "overflow-y": "scroll" },
                attrs: {
                  data: _vm.userTagTree,
                  "show-checkbox": "",
                  "node-key": "id",
                  "filter-node-method": _vm.filterUserTagNode,
                  props: { children: "users", label: "name", value: "id" },
                },
                on: { check: _vm.handleUserTagChange },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.userDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitAddUser },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加指标",
            visible: _vm.quotaDialog.visible,
            "append-to-body": "",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quotaDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "userForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "110px",
                inline: true,
                model: _vm.quotaDialog,
                rules: _vm.quotaDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "指标数据", prop: "dcCode" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", multiple: "" },
                      model: {
                        value: _vm.quotaDialog.quotaIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.quotaDialog, "quotaIds", $$v)
                        },
                        expression: "quotaDialog.quotaIds",
                      },
                    },
                    _vm._l(_vm.quotaDialog.quotaOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          value: item.id,
                          label: item.quotaType + "-" + item.quotaName,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.quotaDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleAddQuotas },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "限制条件",
            visible: _vm.quotaLimitDialog.visible,
            "append-to-body": "",
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.quotaLimitDialog, "visible", $event)
            },
          },
        },
        [
          _vm.targetDrawer.form.quotaData[_vm.quotaLimitDialog.quotaId]
            ? _c(
                "el-checkbox-group",
                {
                  on: { change: () => _vm.$forceUpdate() },
                  model: {
                    value: _vm.quotaLimitDialog.limitCheckedObj,
                    callback: function ($$v) {
                      _vm.$set(_vm.quotaLimitDialog, "limitCheckedObj", $$v)
                    },
                    expression: "quotaLimitDialog.limitCheckedObj",
                  },
                },
                _vm._l(_vm.quotaLimitDialog.limits, function (item) {
                  return _c(
                    "el-row",
                    { key: item.id },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: item.limitCode,
                            disabled: item.ifRequired == 1,
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.limitParamCode1
                                  ? item.limitParamName1.split(
                                      "${" + item.limitParamCode1 + "}"
                                    )[0]
                                  : item.limitParamName1
                              )
                            ),
                          ]),
                          item.limitParamCode1
                            ? _c("el-input", {
                                staticStyle: { width: "60px" },
                                attrs: {
                                  size: "mini",
                                  disabled: item.ifRequired == 1,
                                },
                                model: {
                                  value: item.limitParamValue1,
                                  callback: function ($$v) {
                                    _vm.$set(item, "limitParamValue1", $$v)
                                  },
                                  expression: "item.limitParamValue1",
                                },
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.limitParamCode1
                                  ? item.limitParamName1.split(
                                      "${" + item.limitParamCode1 + "}"
                                    )[1]
                                  : ""
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.quotaLimitDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitSetLimits },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "一键同步用户",
            visible: _vm.copyUserDialog.visible,
            "append-to-body": "",
            width: "300px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.copyUserDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "copyUserFormRef",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "110px",
                inline: true,
                model: _vm.copyUserDialog,
                rules: _vm.copyUserDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户", prop: "userNames" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", multiple: "" },
                      model: {
                        value: _vm.copyUserDialog.userNames,
                        callback: function ($$v) {
                          _vm.$set(_vm.copyUserDialog, "userNames", $$v)
                        },
                        expression: "copyUserDialog.userNames",
                      },
                    },
                    _vm._l(_vm.copyUserDialog.userOptions, function (item) {
                      return _c("el-option", {
                        key: item.userName,
                        attrs: { value: item.userName, label: item.nickName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(true)
                    },
                  },
                },
                [_vm._v("全选")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(false)
                    },
                  },
                },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.copyUserDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitUserCopy },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "一键复制",
            visible: _vm.copyTargetDialog.visible,
            "append-to-body": "",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.copyTargetDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "copyTargetFormRef",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "110px",
                inline: true,
                model: _vm.copyTargetDialog,
                rules: _vm.copyTargetDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "展示时间", prop: "startTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "month",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      format: "yyyy-MM",
                      "picker-options": _vm.copyTargetDialog.pickerOptions,
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    on: {
                      change: (val) => _vm.handleDateChange(val, "copyTarget"),
                    },
                    model: {
                      value: _vm.copyTargetDialog.showDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.copyTargetDialog, "showDate", $$v)
                      },
                      expression: "copyTargetDialog.showDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.copyTargetDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitTargetCopy },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "goodsUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template[_vm.importDialog.type],
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "人员角色配置",
            visible: _vm.userTagDrawer.visible,
            size: "75%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userTagDrawer, "visible", $event)
            },
          },
        },
        [
          _c("UserTag", { attrs: { orgCode: _vm.queryForm.orgCode } }),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                bottom: "50px",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.userTagDrawer.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "汇总",
            visible: _vm.summaryDrawer.visible,
            size: "85%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.summaryDrawer, "visible", $event)
            },
          },
        },
        [
          _c("Summary", {
            attrs: {
              id: _vm.currentRow.id,
              condition: _vm.currentRow.condition,
              "target-name": _vm.currentRow.targetName,
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                bottom: "50px",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.summaryDrawer.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.summaryDrawer.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "月度汇总",
            visible: _vm.mthSummaryDrawer.visible,
            size: "85%",
            direction: "rtl",
            "append-to-body": "",
            "modal-append-to-body": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.mthSummaryDrawer, "visible", $event)
            },
          },
        },
        [
          _c("UserSummary", {
            attrs: {
              "org-code": _vm.queryForm.orgCode,
              "start-time": _vm.queryForm.startTime,
              "end-time": _vm.queryForm.endTime,
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                bottom: "50px",
                right: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.mthSummaryDrawer.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.mthSummaryDrawer.visible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }