var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          clearable: _vm.orgAllAuth,
                          init: true,
                          placeholder: "全部",
                          "init-options": this.options.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          "collapse-tags": "",
                          clearable: true,
                          placeholder: "全部",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          change: function ($event) {
                            _vm.dictParam = Object.assign({}, _vm.queryForm)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商品编码" },
                        model: {
                          value: _vm.queryForm.skuCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "skuCode", $$v)
                          },
                          expression: "queryForm.skuCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品型号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商品型号" },
                        model: {
                          value: _vm.queryForm.skuModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "skuModel", $$v)
                          },
                          expression: "queryForm.skuModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px !important" },
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.queryForm.timeRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "timeRange", $$v)
                          },
                          expression: "queryForm.timeRange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
                "show-summary": "",
                "summary-method": _vm.handleSummary,
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "分公司编码", prop: "orgCode", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司名称", prop: "orgName", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心编码", prop: "dcCode", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心名称", prop: "dcName", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品编码", prop: "skuCode", width: "140" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品属性",
                  prop: "attributeName",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "商品名称", prop: "skuName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品型号", prop: "skuModel", width: "140" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "下单金额",
                  prop: "orderMoney",
                  sortable: "custom",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "下单数量",
                  prop: "orderNum",
                  sortable: "custom",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货金额",
                  prop: "fhMoney",
                  sortable: "custom",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货数量",
                  prop: "fhNum",
                  sortable: "custom",
                  width: "140",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }