import {axiosGet, axiosPost, axiosPut, axiosDelete} from "@/utils/request";

/**
 * 市场数据  市场动向 一级分类
 * @param
 * @returns {*}
 */
export function getCDGroupData() {
  console.log('enter getCDGroupData')
  return axiosGet("/stationery-classify/one_category/list", {}, 1);
}


/**
 * 市场数据  市场动向 二级分类
 * @param data
 * @returns {*}
 */
export function getCDGroupDataMidCategory(data) {
  return axiosGet("/stationery-classify/mid_category/list", data, 1);
}


/**
 * 市场数据  市场动向 二级分类
 * @param data
 * @returns {*}
 */
export function getCDGroupDataThirdCategory(data) {
  return axiosGet("/stationery-classify/third_category/list", data, 1);
}

/**
 * 市场数据  市场动向 热点词 电商平台
 * @param data
 * @returns {*}
 */
export function getRankingChangeClass(data) {
  return axiosPost("/keywords/rank/list", data, 1);
}


/**
 * 市场数据  市场动向 抖音热门话题
 * @param data
 * @returns {*}
 */
export function getyDHotTopicClass(data) {
  return axiosPost("/dy/hot-topic/list", data, 1);
}

/**
 * 市场数据  市场动向 抖音热门话题
 * @param data
 * @returns {*}
 */
export function getyDIncreaseClass(data) {
  return axiosPost("/dy/increase-topic/list", data, 1);
}

/**
 * 市场数据  市场动向 抖音热门话题
 * @param data
 * @returns {*}
 */
export function getXhsHotTopicClass(data) {
  return axiosPost("/xhs/hot-topic/list", data, 1);
}


/**
 * 市场数据  市场动向 其他平台商品清单 快手、拼多多、抖音
 * @param data
 * @returns {*}
 */
export function getItemOtherPlatformsClass(data) {
  return axiosPost("/item/other-platforms/list", data, 1);
}

/**
 * 市场数据  市场动向 其他平台商品清单 生意参谋
 * @param data
 * @returns {*}
 */
export function getItemSycmClass(data) {
  return axiosPost("/item/sycm/list", data, 1);
}


/**
 * 市场数据  市场动向 其他平台商品清单 京东商智
 * @param data
 * @returns {*}
 */
export function getItemJdszClass(data) {
  return axiosPost("/item/jdsz/list", data, 1);
}

/**
 * 市场数据  市场动向 爬虫关键词
 * @param data
 * @returns {*}
 */
export function getStationeryClassifyKeywordsListClass(data) {
  return axiosPost("/stationery-classify/keywords/list", data, 1);
}


/**
 * 市场数据  市场动向 分类 树形图
 * @param
 * @returns {*}
 */
export function getStationeryClassifyTreeDataList() {
  return axiosGet("/stationery-classify/treeData/list", {}, 1);
}

/**
 * 市场数据  市场动向 抖音话题趋势
 * @param
 * @returns {*}
 */
export function getDyTopicTrendListDataList(data) {
  return axiosPost("/dy/topic-trend/list", data, 1);
}

/**
 * 市场数据  关键词排名趋势
 * @param data
 * @returns {*}
 */
export function queryKeywordsRankTrendList(data) {
  return axiosPost("/keywords/rank-trend/list", data, 1);
}

/**
 * 市场数据  抖音话题列表
 * @param data
 * @returns {*}
 */
export function queryDyTopicList(data) {
  return axiosPost("/dy/topic/list", data, 1);
}

/**
 * 市场数据  热度周趋势
 * @param data
 * @returns {*}
 */
export function queryKeywordsHeatRankTrendList(data) {
  return axiosPost("/keywords/heat-rank-trend/list", data, 1);
}

/**
 * 市场数据  热度周趋势
 * @param data
 * @returns {*}
 */
export function queryZjfbTopic(data) {
  return axiosPost("/xhs/zjfb-topic/list", data, 1);
}

/**
 * 市场数据  生意参谋价格趋势
 * @param data
 * @returns {*}
 */
export function querySycmPriceTrendList(data) {
  return axiosPost("/item/sycm/price-trend/list", data, 1);
}

/**
 * 市场数据  生意参谋月销趋势
 * @param data
 * @returns {*}
 */
export function querySycmSellCountTrendList(data) {
  return axiosPost("/item/sycm/sell-count/trend/list", data, 1);
}

/**
 * 市场数据  小红书点赞趋势
 * @param data
 * @returns {*}
 */
export function queryXhsLikesTrendList(data) {
  return axiosPost("/xhs/likes-trend/list", data, 1);
}

/**
 * 市场数据  小红书分享趋势
 * @param data
 * @returns {*}
 */
export function queryXhsShareTrendList(data) {
  return axiosPost("/xhs/share-trend/list", data, 1);
}

/**
 * 市场数据  小红书评论趋势
 * @param data
 * @returns {*}
 */
export function queryXhsCommentTrendList(data) {
  return axiosPost("/xhs/comment-trend/list", data, 1);
}

/**
 * 市场数据  小红书收藏趋势
 * @param data
 * @returns {*}
 */
export function queryXhsCollectTrendList(data) {
  return axiosPost("/xhs/collect-trend/list", data, 1);
}

/**
 * 市场数据  热点词关联信息 其他平台列表 抖音商品
 * @param data
 * @returns {*}
 */
export function queryKeywordsOtherPlatformsList(data) {
  return axiosPost("/keywords/other-platforms/list", data, 1);
}

/**
 * 市场数据  热点词关联信息 天猫商品列表
 * @param data
 * @returns {*}
 */
export function queryKeywordsSycmList(data) {
  return axiosPost("/keywords/sycm/list", data, 1);
}

/**
 * 市场数据  热点词关联信息 京东商品列表
 * @param data
 * @returns {*}
 */
export function queryKeywordsJdszList(data) {
  return axiosPost("/keywords/jdsz/list", data, 1);
}

/**
 * 市场数据  热点词关联信息 小红书 帖子
 * @param data
 * @returns {*}
 */
export function queryKeywordsXhsTopicList(data) {
  return axiosPost("/keywords/xhs-topic/list", data, 1);
}


/**
 * 市场数据  热点词关联信息 抖音话题
 * @param data
 * @returns {*}
 */
export function queryKeywordsDyTopicList(data) {
  return axiosPost("/keywords/dy-topic/list", data, 1);
}

/**
 * 市场数据  热点词关联信息 其他平台导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsOtherPlatformsListExport(data) {
  return axiosPost("/keywords/other-platforms/list/export", data, 1);
}


/**
 * 市场数据  热点词关联信息 天猫导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsSycmListExport(data) {
  return axiosPost("/keywords/sycm/list/export", data, 1);
}
/**
 * 市场数据  热点词关联信息 京东导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsJdszListExport(data) {
  return axiosPost("/keywords/jdsz/list/export", data, 1);
}

/**
 * 市场数据  热点词关联信息 小红书帖子导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsXhsTopicListExport(data) {
  return axiosPost("/keywords/xhs-topic/list/export", data, 1);
}

/**
 * 市场数据  热点词关联信息 抖音话题导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsDyTopicListExport(data) {
  return axiosPost("/keywords/dy-topic/list/export", data, 1);
}

/**
 * 市场数据  电商平台 生意参谋导出
 * @param data
 * @returns {*}
 */
export function queryItemSycmListExport(data) {
  return axiosPost("/item/sycm/list/export", data, 1);
}

/**
 * 市场数据  电商平台  京东商智
 * @param data
 * @returns {*}
 */
export function queryItemJdszListExport(data) {
  return axiosPost("/item/jdsz/list/export", data, 1);
}

/**
 * 市场数据  电商平台  其他
 * @param data
 * @returns {*}
 */
export function queryItemOtherPlatformsListExport(data) {
  return axiosPost("/item/other-platforms/list/export", data, 1);
}

/**
 * 市场数据  晨光相关商品销售占比趋势
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgItemSellTrendList(data) {
  return axiosPost("/keywords/mg-item-sell/trend/list", data, 1);
}

/**
 * 市场数据  晨光相关商品清单统计
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgItemStatistics(data) {
  return axiosPost("/keywords/mg-item/statistics", data, 1);
}


/**
 * 市场数据  晨光相关商品列表
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgItemList(data) {
  return axiosPost("/keywords/mg-item/list", data, 1);
}


/**
 * 市场数据  晨光相关商品清单导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgItemListExport(data) {
  return axiosPost("/keywords/mg-item/list/export", data, 1);
}


/**
 * 市场数据  只保留二级分类
 * @param
 * @returns {*}
 */
export function queryStationeryClassifySpecialTreeDataList() {
  return axiosGet("/stationery-classify/special/treeData/list", {}, 1);
}

/**
 * 市场数据  市场动向 其他平台商品清单 生意参谋
 * @param data
 * @returns {*}
 */
export function getItemTmClass(data) {
  return axiosPost("/item/tm/list", data, 1);
}

/**
 * 市场数据  电商平台 生意参谋导出
 * @param data
 * @returns {*}
 */
export function queryTmItemListExport(data) {
  return axiosPost("/item/tm/list/export", data, 1);
}

/**
 * 市场数据  晨光品牌商品销售占比趋势
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgBrandItemTrendList(data) {
  return axiosPost("/keywords/mg-brand/item/sell/trend/list", data, 1);
}

/**
 * 市场数据  晨光品牌商品统计
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgBrandItemStatistics(data) {
  return axiosPost("/keywords/mg-brand/item/statistics", data, 1);
}

/**
 * 市场数据  晨光品牌商品列表
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgBrandItemList(data) {
  return axiosPost("/keywords/mg-brand/item/list", data, 1);
}

/**
 * 市场数据  晨光品牌商品清单导出
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgBrandItemListExport(data) {
  return axiosPost("/keywords/mg-brand/item/list/export", data, 1);
}


/**
 * 市场数据  晨光品牌商品数量统计
 * @param data
 * @returns {*}
 */
export function queryKeywordsMgBrandSkucountStatistics(data) {
  return axiosPost("/keywords/mg-brand/sku_count/statistics", data, 1);
}


/**
 * 市场数据  品牌关键词排名列表
 * @param data
 * @returns {*}
 */
export function queryKeywordsBrandRankListStatistics(data) {
  return axiosPost("/keywords/brand/rank/list", data, 1);
}
