<template>
  <div style="padding: 20px">
    <div class="mg-search">
      <div>
        <el-button size="mini" type="primary" @click="handleInit">初始化</el-button> <span>初始化：点击设置目标周转，开始计算补货数据</span>
      </div>
      <el-form size="mini" label-width="auto" ref="searchRef" :inline="true" :model="queryForm"
               @keyup.enter.native="handleQuery">
        <el-form-item label="补货日期">
          <el-select v-model="queryForm.dateKey">
            <el-option v-for="item in dateOptions" :label="item" :value="item" :key="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="库存状态">
          <el-select v-model="queryForm.kcStatus" clearable>
            <el-option v-for="item in options.ksOptions" :label="item" :value="item" :key="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="赛道">
          <DictSelect
            v-if="this.options.category.length > 0"
            ref="race"
            type="race"
            :value.sync="queryForm.raceName"
            linkageRef="main"
            :dictFun="dictFun"
            singleProps
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="大类">
          <DictSelect
            ref="main"
            type="main"
            :value.sync="queryForm.mainCategoryName"
            :dictFun="dictFun"
            linkageRef="mid"
            parentLinkageRef
            singleProps
            @getOtherJsonParam="(callback) => callback(queryForm)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="中类">
          <DictSelect
            ref="mid"
            type="mid"
            :value.sync="queryForm.midCategoryName"
            :dictFun="dictFun"
            parentLinkageRef
            singleProps
            linkageRef="sub"
            @getOtherJsonParam="(callback) => callback(queryForm)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="小类">
          <DictSelect
            ref="sub"
            type="sub"
            :value.sync="queryForm.subCategoryName"
            :dictFun="dictFun"
            parentLinkageRef
            singleProps
            @getOtherJsonParam="(callback) => callback(queryForm)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="JD编码">
          <el-input v-model="queryForm.goodsSku" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="科技SKU">
          <el-input v-model="queryForm.ecSkuCode" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="DRP编码">
          <el-input v-model="queryForm.spuCode" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="补货提示">
          <el-select v-model="queryForm.needBh" clearable>
            <el-option v-for="item in options.notices" :label="item" :value="item" :key="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="需求为0的SKU">
          <el-select v-model="queryForm.needZero" clearable>
            <el-option label="为零" :value="true" />
          </el-select>
        </el-form-item>
        <el-form-item label="单仓发全国">
          <el-select v-model="queryForm.singleToWhole" clearable>
            <el-option v-for="item in options.singleToWholeOptions" :label="item" :value="item" :key="item" />
          </el-select>
        </el-form-item>
        <div>
          <div style="float: left; margin-bottom: 5px">
            <el-button size="mini" type="primary" @click="handleFilter">查询</el-button>
            <el-button size="mini" type="primary" @click="handleFilterColumns">列编辑</el-button>
            <el-button v-if="currentDataDate===queryForm.dateKey" size="mini" type="primary" @click="handleBatchUpdate">批量修改补货单数量</el-button>
            <el-button size="mini" type="primary" @click="exportData">导出</el-button>
            <el-button v-if="currentDataDate===queryForm.dateKey" size="mini" type="primary" @click="produceSupply">生成配货单</el-button>
            <el-button v-if="currentDataDate===queryForm.dateKey" size="mini" type="primary" @click="handleSyncWholeALl">一键单仓发全国</el-button>
            <el-link href="https://bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/reports/d275f9dcf9464d1cb67a70411d1012fd" target="_blank" type="primary">补货填报跳转</el-link>
            <el-popover
              placement="right"
              width="600"
              trigger="click">
              <div style="height: calc(100vh - 150px); overflow-y: scroll;">
                <label>操作说明：</label><br>
                操作前，请确保【京东DRP商品编码对应关系】维护完整；<br>
                观察筛选框下方 采集时间，需要当天数据采集完成后才可以进行补货操作；约定库存更新时间为每天11点；<br>

                1.初始化操作步骤：<br>
                1）点击【补货填报跳转】，录入当天需要补货的京东码信息（按需补货）<br>
                京东补货依照为补货清单中的京东码进行补货；<br>
                2）如当天有自动单，需要录入【京东仓自动单录入】，最后计算出来的补货数量，会扣减自动单的采购数量；<br>
                3）点击上方【初始化】按钮，并填写目标周转、采购原因、日均计算系数；<br>
                目标周转：京东八仓按多少天的销售天数来进行补货<br>
                日均计算系数：默认可按照 1,1,1填写，用于计算全国日均，可根据需求填写系数的值<br>
                全国日均=（近7天出库/7 * A+近14天出库/14 * B+近28天出库/28 *C）/ 3<br>
                4）点击【确定】 按钮<br>
                5）点击【查询】，查看计算后的补货数值<br>
                2.补货功能说明：<br>
                1）用户可以点击【列编辑】来选择需要展示的数据列；<br>
                2）用户可以在页面上调整 XX实际 的数值来调整实际补货的数量<br>
                3）可以参考 【补货提示】，会告知以SKU维度的各仓库存是否够进行补货操作，如果不够，会提示哪个科技仓库缺货，可以哪个单仓发全国<br>
                4）【单仓发全国】，当默认仓库缺货时，满足科技单仓发京东八仓的情况，会出现下拉选择；用户可以选择从哪个仓发全国；<br>
                当选择否时，按照实际可发库存发货；当选择XX发全国时，按照需求量发货；<br>
                5）【一键单仓发全国】 功能：<br>
                影响全局   单仓发全国  字段， 选择 XX仓发全国，并调整京东八仓实际补货量；<br>
                6）【导出】，导出全字段数据<br>
                7）【生成配货单】，生成京东VC后台的采购单格式文件，可以直接上传到VC后台进行补货；<br><br>

                <label>补货逻辑说明：</label><br>
                1.各仓需求量计算逻辑：<br>
                ①计算铺底库存<br>
                如果某商品同时满足七日销量为0且可用库存为0，则仓库为北上广的时候补10个，为其他仓库的时候补5个<br>
                ②计算是否需要补全负卖单<br>
                如果可用库存为负数，则补可用库存的绝对值<br>
                ③计算各仓日均<br>
                （近7天出库/7  * A+近14天出库/14 * B+近28天出库/28 *C）/ 3<br>
                若单仓日均为0，则取往前推30天商智数据计算同期值。<br>
                若该仓同期计算后都为0北上广都为0，则这三仓日均销售0；成都武汉沈阳西安德州都为日均销售0；--代表该品是新品<br>
                若该仓同期计算后仍为0（非全部），则北上广取有值的最大值，例如北京0，上海4，广州6，则北京6；成都武汉沈阳西安德州，任意为0，取其他仓最大值<br>
                ④计算待补货天数<br>
                待补货天数=（目标周转天数- 各仓周转）    若负数则0<br>
                ⑤计算各仓需求量 （ 若负数则0）<br>
                待补货天数*日均销售-采购未到货-京东自动单+负卖补单+铺底库存<br>
                2.科技仓库对应京东八仓关系<br>
                集库需求总和：上海+西安+武汉<br>
                华北需求总和：成都+北京+德州+沈阳<br>
                华南需求总和：广州<br>
                其中，集库、华北可发全国，华南不可发全国<br>
              </div>
              <el-link icon="el-icon-info" type="info" slot="reference" style="margin-left: 10px">使用说明</el-link>
            </el-popover>
          </div>
        </div>
        <div style="float: right; margin-bottom: 15px; font-size: 15px;">京东商智数据采集时间: {{ updateDate.timeStamp }}</div>
      </el-form>
    </div>
    <div style="padding-top: 15px">
      <el-table
        ref="table"
        :data="pageData.rows"
        height="calc(100vh - 220px)"
        v-loading="loading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="40"/>
        <el-table-column type="index" width="40"/>
        <template v-for="item in dynamicColumns">
          <el-table-column
            v-if="item.show || checkedColumns.indexOf(item.filterLabel) > -1"
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            :width="item.width? item.width: '100px'"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <span>{{ row[item.prop] }}</span>
            </template>
          </el-table-column>
        </template>

        <template v-for="item in fixedColumns">
          <el-table-column
            :key="item.prop"
            :prop="item.prop"
            :label="item.label"
            width="120"
          >
            <template slot-scope="{ row }">
              <el-input-number
                v-model="row[item.prop]"
                controls-position="right"
                placeholder="请输入内容"
                style="width: 100%"
                size="mini"
                :min="0"
                :precision="0"
                :disabled="lastDataDate!==row.dateKey"
                @change="(cV, oV) => handleChangeInput(cV, oV, row, item.prop)"
              />
            </template>
          </el-table-column>
        </template>
        <el-table-column label="下单合计" prop="totalReal" width="80px"/>
        <el-table-column label="补货提示" prop="needBh" width="100px"/>
        <el-table-column label="单仓发全国" prop="singleToWhole" width="100px">
          <template slot-scope="{ row }">
            <el-select v-model="row.singleToWhole" size="mini" :disabled="lastDataDate!==row.dateKey" @change="val => handleChangeSend(val, row)">
              <el-option v-for="item in row.sendWholeOptions" :key="item" :value="item" :label="item" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="补货备注"  prop="remark" width="180px">
          <template slot-scope="{ row }">
            <el-input
              v-model="row.remark"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              type="textarea"
              :disabled="lastDataDate!==row.dateKey"
              @change="(cV, oV) => handleChangeInput(cV, oV, row, 'remark')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="采购场景" prop="purchaseScene" width="120px">
          <template slot-scope="{ row }">
            <el-select :disabled="lastDataDate!==row.dateKey" v-model="row.purchaseScene" size="mini" @change="(cV, oV) => handleChangeInput(cV, oV, row, 'remark')">
              <el-option v-for="item in options.purchase" :key="item" :value="item" :label="item" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="预计消化时间" prop="estimatedDigestionTime" width="140px">
          <template slot-scope="{ row }">
            <el-date-picker
              v-model="row.estimatedDigestionTime"
              type="date"
              size="mini"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              style="width: 120px"
              :disabled="lastDataDate!==row.dateKey"
              @change="(cV, oV) => handleChangeInput(cV, oV, row, 'estimatedDigestionTime')" />
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :pageSizes="[10, 20, 30, 50, 100, 200, 500]" :page.sync="queryForm.current" :limit.sync="queryForm.size"
                  @pagination="handleQuery"/>
    </div>

    <el-dialog title="列编辑" :visible.sync="dialog.filterColumnVisible" width="700px">
      <div style="width: 25%; position: relative; float: right; right: 60%; top: -50px;">
        <el-button size="mini" type="primary" @click="handleCheckAll(false)">清空</el-button>
        <el-button size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
      </div>
      <div style="margin-bottom: 10px; color: gray;"><span>说明：页面表头指标 展示顺序同指标添加先后顺序</span></div>
      <div v-for="(item, key, index) in checkedCopy.filterColumns" :key="index" style="margin-bottom: 15px">
        <span style="margin-right: 10px; font-weight: 600">{{ item.label }}</span>
        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                     @change="handleCheckAllChange($event, key, item.label)">
          全选
        </el-checkbox>
        <div style="margin: 10px 0"></div>
        <el-checkbox-group v-model="item.checked" @change="handleCheckedChange($event, key, item.label)">
          <el-checkbox v-for="option in item.list" :label="option" :key="option" :style="item.label==='库存数据'? 'width: 40% ': 'width: 20% '">{{option}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialog.filterColumnVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmChecked">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量修改补货单数量" :visible.sync="updateDialog.updateVisible" width="600px">
      <el-form ref="stockForm" :model="updateDialog.form" class="common-form">
        <el-row class="common-form-row">
          <el-col v-for="item in fixedColumns" :key="item.prop" :span="12">
            <el-form-item :label="item.label" :prop="item.prop">
              <el-input-number v-model="updateDialog.form[item.prop]" placeholder="请输入" :min="0" :precision="0" :controls="false" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="补货备注" prop="remark">
              <el-input v-model="updateDialog.form.remark" placeholder="请输入" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购场景" prop="purchaseScene">
              <el-select v-model="updateDialog.form.purchaseScene" size="mini" @change="(cV, oV) => handleChangeInput(cV, oV, row, 'remark')">
                <el-option v-for="item in options.purchase" :key="item" :value="item" :label="item" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计消化时间" prop="estimatedDigestionTime">
              <el-date-picker
                v-model="updateDialog.form.estimatedDigestionTime"
                type="date"
                size="mini"
                placeholder="选择日期"
                value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="mini" @click="updateDialog.updateVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleBatchSubmit">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="初始化" :visible.sync="initDialog.visible" width="400px">
      <el-form ref="initForm" :model="initDialog.form" :rules="initDialog.rules" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="22">
            <el-form-item label="目标周转" prop="turnover">
              <el-input-number v-model="initDialog.form.turnover" placeholder="请输入" :precision="0" size="small"><template slot="append">天</template></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="采购原因" prop="purchaseReason">
              <el-input v-model="initDialog.form.purchaseReason" placeholder="请输入" size="small"></el-input>
            </el-form-item>
          </el-col>
          <div>全部日均计算系数</div>
          <el-col :span="22">
            <el-form-item label="仅7天出库占比（A）" prop="r7d">
              <el-input-number v-model="initDialog.form.r7d" placeholder="请输入" size="small">
                <template slot="append">倍</template>
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="仅14天出库占比（B）" prop="r14d">
              <el-input-number v-model="initDialog.form.r14d" placeholder="请输入" size="small"><template slot="append">倍</template></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="仅28天出库占比（C）" prop="r28d">
              <el-input-number v-model="initDialog.form.r28d" placeholder="请输入" size="small"><template slot="append">倍</template></el-input-number>
            </el-form-item>
          </el-col>
          <div>即全国日均=（近7天出库/7  * A+近14天出库/14 * B+近28天出库/28 *C）/ 3</div>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="mini" @click="initDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitInit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
import {deepClone, parseTime} from "@/utils";
import { fixedColumns, dynamicColumns, filterColumns} from "../config";
import {Message, Notification} from "element-ui";
import {
  pageJdBhData,
  initJdBhData,
  saveJdBhData,
  exportJdBhData,
  exportJdBhDsData,
  getCategories, getUpdateDate, syncWholeToAll
} from "@/api/eCommerce";
import DictSelect from "@/components/DictSelect";

export default {
  name: "EcJdBh",
  components: { Pagination, DictSelect },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        dateKey: undefined,
        ksStatus: undefined,
        spuCode: undefined,
        goodsSku: undefined,
        raceName: undefined,
        mainCategoryName: undefined,
        middleCategoryName: undefined,
        subCategoryName: undefined,
        needBh: undefined,
        needZero: undefined,
        ecSkuCode: undefined,
        singleToWhole: undefined,
      },
      loading: false,
      dialog: {
        filterColumnVisible: false,
      },
      updateDialog: {
        updateVisible: false,
        form: {
          shReal: undefined,
          gzReal: undefined,
          whReal: undefined,
          cdReal: undefined,
          bjReal: undefined,
          xaReal: undefined,
          dzReal: undefined,
          syReal: undefined,
          remark: undefined,
          purchaseScene: undefined,
          estimatedDigestionTime: undefined,
        },
      },
      initDialog: {
        visible: false,
        form: {
          dateKey: undefined,
          r7d: undefined,
          r14d: undefined,
          r28d: undefined,
          turnover: undefined,
          purchaseReason: undefined
        },
        rules: {
          r7d: [{required: true, message: " ", trigger: "change"}],
          r14d: [{required: true, message: " ", trigger: "change"}],
          r28d: [{required: true, message: " ", trigger: "change"}],
          turnover: [{required: true, message: " ", trigger: "change"}],
          purchaseReason: [{required: true, message: " ", trigger: "change"}],
        },
        tableData: [],
      },
      filterColumns,
      fixedColumns,
      dynamicColumns,
      exportLoading: false,
      produceLoading: false,
      updateTime: undefined,
      allExportLoading: false,
      disDownloadInfo: {
        fileName: undefined,
        downloadUrl: undefined,
        isSuccess: undefined,
        status: undefined
      },
      excelDownloadInfo: {
        fileName: undefined,
        downloadUrl: undefined,
        isSuccess: undefined,
        status: undefined
      },
      checkedCopy: {
        filterColumns: undefined,
        checked: undefined,
      },
      checkedColumns: [],
      dateOptions: [],
      currentDataDate: undefined,
      lastDataDate: undefined,
      options: {
        category: [],
        ksOptions: ['全仓有货', '单仓缺货'],
        singleToWholeOptions: ['分仓发货', '集库发全国', '华北发全国'],
        notices: ['华北缺货', '集库缺货', '华南缺货','华北缺货，集库可发全国', '集库缺货，华北可发全国','华南缺货，集库可发全国', '华南缺货，华北可发全国','华南华北缺货，集库可发全国'],
        purchase: ["断货补货","日常备货","促销备货","大促备货","囤货","新品首采","定需采购","库存差异调整"],
      },
      updateDate: {}
    };
  },
  watch: {
    checkedColumns: {
      handler(newVal, oldVal) {
        localStorage.setItem('checked_columns', JSON.stringify( { checked: newVal}));
      },
      immediate: false
    }
  },
  created() {
    for (let i = 0; i < 15; i++) {
      this.dateOptions.push(parseTime(new Date().getTime() - i * 24 * 60 * 60 * 1000, "{y}{m}{d}"));
    }
    this.queryForm.dateKey = this.dateOptions[0];
    this.lastDataDate = parseInt(this.dateOptions[1]);
    this.currentDataDate = this.dateOptions[0];
    getCategories().then(res => {
      this.options.category = res.data;
    })
    getUpdateDate().then(res => {
      this.updateDate = res.data;
    })
    this.$forceUpdate();
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    dictFun(data, form) {
      return new Promise((resolve, reject) => {
        let res = { data: [], code: 200};
        let respData = [];
        if (data.indexOf('race')  === 0) {
          respData = this.options.category.filter(e => e.raceName).map(e => e.raceName);
        } else if (data.indexOf('main') === 0) {
          respData = this.options.category.filter(e => e.raceName === form.raceName).map(e => e.mainCategoryName);
        } else if (data.indexOf('mid') === 0) {
          respData =this.options.category.filter(e => e.raceName === form.raceName && e.mainCategoryName=== form.mainCategoryName).map(e => e.midCategoryName);
        } else if (data.indexOf('sub') === 0) {
          respData =this.options.category.filter(e => e.raceName === form.raceName && e.mainCategoryName=== form.mainCategoryName && e.midCategoryName=== form.midCategoryName).map(e => e.subCategoryName);
        }
        resolve(Object.assign(res, { data: Array.from(new Set(respData))} ));
      })
    },
    handleQuery() {
      this.loading = true;
      this.pageData.rows = [];
      let query = Object.assign({}, this.queryForm);
      pageJdBhData(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.rows.forEach(e => {
          e.singleToWhole = e.singleToWhole? e.singleToWhole: '否';
          e.sendWholeOptions = ['否'];
          if (e.needBh.indexOf('集库可发全国') > -1) {
            e.sendWholeOptions.push('集库发全国')
          }  else if (e.needBh.indexOf('华北可发全国') > -1) {
            e.sendWholeOptions.push('华北发全国')
          }
        })
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.table.doLayout();
        });
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleResetFields() {
      this.$refs.searchRef.resetFields();
      this.queryForm.storeCodes = undefined;
      this.queryForm.itemCodes = undefined;
      this.queryForm.is128List = undefined;
      this.queryForm.ycFlag = undefined;
      this.queryForm.conditions = [];
      this.conditionDialog.tableData = [];
    },
    handleCheckAllChange(val, key, label) {
      this.checkedCopy.filterColumns[key].checked = val ? this.checkedCopy.filterColumns[key].list : [];
      const spliceIndex = -1;
      this.checkedCopy.filterColumns[key].checkedBefore = [];
      this.checkedCopy.filterColumns[key].list.forEach(e => {
        // 全选
        if (val && this.checkedCopy.checkedColumns.indexOf(e) < 0) {
          this.checkedCopy.checkedColumns.splice(spliceIndex, 0, e);
        } else if (!val && this.checkedCopy.checkedColumns.indexOf(e) > -1) {
          const index = this.checkedCopy.checkedColumns.indexOf(e);
          this.checkedCopy.checkedColumns.splice(index, 1);
        }
      })
      this.checkedCopy.filterColumns[key].checkedBefore =  this.checkedCopy.filterColumns[key].checked;
      this.checkedCopy.filterColumns[key].isIndeterminate = false;
    },
    handleCheckedChange(value, key, label) {
      const checkedCount = value.length;
      this.checkedCopy.filterColumns[key].checkAll = checkedCount === this.checkedCopy.filterColumns[key].list.length;
      this.checkedCopy.filterColumns[key].isIndeterminate = checkedCount > 0 && checkedCount < this.checkedCopy.filterColumns[key].list.length;
      const spliceIndex = -1;
      // 选中
      if (value.length > this.checkedCopy.filterColumns[key].checkedBefore.length) {
        this.checkedCopy.checkedColumns.splice(spliceIndex, 0, value[value.length - 1]);
      } else if (value.length < this.checkedCopy.filterColumns[key].checkedBefore.length) {
        // 取消选中
        this.checkedCopy.filterColumns[key].checkedBefore.forEach(e => {
          if (value.indexOf(e) < 0) {
            // 获取取消项
            const index = this.checkedCopy.checkedColumns.indexOf(e);
            this.checkedCopy.checkedColumns.splice(index, 1);
          }
        })
      }
      this.checkedCopy.filterColumns[key].checkedBefore = value;
    },
    handleSortChange(data) {
      const {prop, order} = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleFilterColumns() {
      this.checkedCopy = {
        filterColumns: deepClone(this.filterColumns),
        checkedColumns: Object.assign([], this.checkedColumns)
      };
      this.$forceUpdate();
      this.dialog.filterColumnVisible = true;
    },
    handleBatchUpdate() {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少勾选一条数据");
        return false;
      }
      this.updateDialog.form = {
        shReal: undefined,
        gzReal: undefined,
        whReal: undefined,
        cdReal: undefined,
        bjReal: undefined,
        xaReal: undefined,
        dzReal: undefined,
        syReal: undefined,
        remark: undefined,
        purchaseScene: undefined,
        estimatedDigestionTime: undefined,
      }
      this.updateDialog.updateVisible = true;
    },
    handleInit() {
      this.initDialog.form = {
        dateKey: undefined,
        r7d: undefined,
        r14d: undefined,
        r28d: undefined,
        turnover: undefined,
        purchaseReason: undefined
      }
      this.initDialog.visible = true;
    },
    submitInit() {
      this.loading = true;
      initJdBhData(this.initDialog.form).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.initDialog.visible = false;
          Notification.success("初始化成功");
          this.handleQuery();
          getCategories().then(res => {
            this.options.category = res.data;
          })
        }
      })
    },
    handleChangeInput(cV, oV, row, key) {
      const jkReal = row.shReal + row.xaReal + row.whReal;
      const hbReal = row.cdReal + row.bjReal + row.dzReal + row.syReal;
      const hnReal = row.gzReal;
      let flag = true;
      if (row.singleToWhole === '否') {
        if (jkReal > row.jkKcCount) {
          Notification.warning("集库库存不足以分配！");
          flag = false;
        } else if (hbReal > row.hbKcCount) {
          Notification.warning("华北库存不足以分配！");
          flag = false;
        } else if (hnReal > row.hnKcCount) {
          Notification.warning("华南库存不足以分配！");
          flag = false;
        }
      } else if (row.singleToWhole.indexOf('集库可发全国') > -1 && (hnReal + jkReal +  hbReal) > row.jkKcCount) {
        Notification.warning("集库库存不足以分配！");
        flag = false;
      } else if (row.singleToWhole.indexOf('华北可发全国') > -1 && (hnReal + jkReal +  hbReal) > row.hbKcCount) {
        Notification.warning("华北库存不足以分配！");
        flag = false;
      }
      if (!flag) {
        this.$nextTick(() => {
          row[key] = oV;
          this.$forceUpdate();
        })
        return true;
      }
      if (row.estimatedDigestionTime) {
        row.estimatedDigestionTime = parseTime(new Date(row.estimatedDigestionTime).getTime(), "{y}-{m}-{d}") + ' 23:59:59';
      }
      saveJdBhData({list: [row]}).then(res => {
        if (res.code === 200) {
          Notification.success("修改成功");
          this.handleQuery();
        } else {
          Notification.error(res.msg);
        }
      });
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleBatchSubmit() {
      let data = Object.assign([], this.pageData.selections);
      data.forEach(e => {
        e = Object.assign(e, this.updateDialog.form);
        if (e.estimatedDigestionTime) {
          e.estimatedDigestionTime = parseTime(new Date(e.estimatedDigestionTime).getTime(), "{y}-{m}-{d}") + ' 23:59:59';
        }
        return e;
      });
      saveJdBhData({list: data}).then(res => {
        if (res.code === 200) {
          Notification.success("修改成功");
          this.updateDialog.updateVisible = false;
          this.handleQuery();
        } else {
          Notification.error(res.msg);
        }
      });
    },
    exportData() {
      this.loading = true;
      let queryParam = Object.assign(deepClone(this.queryForm));
      exportJdBhData(queryParam).then(res => {
        Message.warning("数据处理中请勿关闭当前页面！");
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '京东补货导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCellClick(row) {
      this.$emit("cell-click", row, this.queryForm);
    },
    produceSupply() {
      exportJdBhDsData(Object.assign(deepClone(this.queryForm))).then((res) => {
        Message.warning("数据处理中请勿关闭当前页面！");
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '京东配货单导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCheckAll(checked) {
      for (let key in this.filterColumns) {
        this.checkedCopy.filterColumns[key].isIndeterminate = checked;
        this.checkedCopy.filterColumns[key].checkAll = checked;
        this.handleCheckAllChange(checked, key, this.checkedCopy.filterColumns[key].label);
      }
    },
    handleConfirmChecked() {
      this.filterColumns = this.checkedCopy.filterColumns;
      this.checkedColumns = this.checkedCopy.checkedColumns;
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
      this.dialog.filterColumnVisible = false;
    },
    handleChangeSend(val, row) {
      this.$nextTick(() => {
        if (val.indexOf('发全国') > -1) {
          row.bjReal = row.nationalInventoryBjNeed;
          row.cdReal = row.nationalInventoryCdNeed;
          row.dzReal = row.nationalInventoryDzNeed;
          row.syReal = row.nationalInventorySyNeed;
          row.shReal = row.nationalInventoryShNeed;
          row.gzReal = row.nationalInventoryGzNeed;
          row.whReal = row.nationalInventoryWhNeed;
          row.xaReal = row.nationalInventoryXaNeed;
        } else {
          row.bjReal = row.nationalInventoryBjReal;
          row.cdReal = row.nationalInventoryCdReal;
          row.dzReal = row.nationalInventoryDzReal;
          row.syReal = row.nationalInventorySyReal;
          row.shReal = row.nationalInventoryShReal;
          row.gzReal = row.nationalInventoryGzReal;
          row.whReal = row.nationalInventoryWhReal;
          row.xaReal = row.nationalInventoryXaReal;
        }
        saveJdBhData({list: [row]}).then(res => {
          if (res.code === 200) {
            Notification.success("修改成功");
            this.handleQuery();
          }
        });
      })
    },
    handleSyncWholeALl() {
      syncWholeToAll().then(res => {
        if (res.code === 200) {
          Notification.success("修改成功");
          this.handleQuery();
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .hideRow {
  background-color: #e4e7ed;
}

::v-deep .el-dialog {
  max-height: 78vh;
  overflow: auto;
}

::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-badge__content.is-fixed {
  right: 25px !important;
}

::v-deep .el-badge.item {
  top: -1px;
}

.redColor {
  color: red !important;
}

a {
  color: blue;
  font-size: 15px;
  padding-left: 10px;
  text-decoration: underline;
}

.common-form {
  &-row {
     display: flex !important;
     flex-wrap: wrap;
   }
  div .el-form-item {
    display: flex !important;
    margin-bottom: 10px;
      ::v-deep {
        .el-form-item__label {
          min-width: 140px !important;
        }
        .el-form-item__content {
          width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
</style>
