<template>
  <div class="mg-progress-container" v-if="isShowProgress">
    <div class="mg-progress-box">
      <div class="mg-progress-loading" :style="progressStyle">
        <el-progress v-if="isShowProgress" :percentage="progress"></el-progress>
        <div class="mg-progress-tips">
          <span>{{ progressTips }}</span>
          <span v-for="(item, index) in progressTipsDot" :key="index">.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // 自定义样式
      progressStyle: {
        type: Object,
      },
      // 进度条tips
      progressTips: {
        type: String,
        default: '正在提交中, 请耐心等待',
      },
    },
    data() {
      return {
        isShowProgress: false,
        progress: 0,
        timer: null,
        progressTipsDot: 1,
      }
    },
    methods: {
      // 显示进度
      show() {
        this.progress = 0
        this.timer = null
        this.isShowProgress = true
        if (this.isShowProgress) {
          this.timer = setInterval(() => {
            ++this.progressTipsDot
            if (this.progressTipsDot >= 4) {
              this.progressTipsDot = 1
            }
            if (this.progress >= 90) {
              this.progressTipsDot = 3
              clearTimeout(this.timer)
              return false
            }
            this.progress += 2
          }, 1000)
        }
      },
      // 接口加载完成时调用
      finish() {
        new Promise((resolve) => {
          this.progress = 100
          resolve()
        }).then(() => {
          clearTimeout(this.timer)
          setTimeout(() => {
            this.isShowProgress = false
          }, 600)
        })
      },
      // 关闭进度
      close() {
        this.progress = 0
        this.isShowProgress = false
        clearTimeout(this.timer)
      },
    },
    // 清除定时器
    destroyed() {
      this.close()
    },
  }
</script>

<style lang="scss" scoped>
  .mg-progress-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    opacity: 0.87;
    z-index: 99999;
    .mg-progress-box {
      position: relative;
      height: 100%;
      .mg-progress-loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 300px;
        ::v-deep .el-progress__text {
          color: #409EFF;
        }
      }
      .mg-progress-tips {
        font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Hiragino Sans GB,
          Microsoft Yahei, STHeiti, SimSun, sans-serifsans-serif;
        font-size: 13px;
        font-weight: 400;
        color: #252a3d;
      }
    }
  }
</style>
