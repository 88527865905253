var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "15px" } },
    [
      _c(
        "div",
        [
          _c("DictSelect", {
            ref: "dept",
            attrs: {
              value: _vm.queryForm.dept,
              "allow-create": "",
              size: "mini",
              placeholder: "请选择部门",
              dictFun: _vm.getDeptDict,
            },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.queryForm, "dept", $event)
              },
              change: _vm.handleDeptChange,
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "auto 10px" },
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleQuery },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "auto 10px" },
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  _vm.addDialog.visible = true
                },
              },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tt",
          attrs: {
            data: _vm.pageData.rows,
            "max-height": "500px",
            size: "mini",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "平台", prop: "platform", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "部门", prop: "deptName", width: "140" },
          }),
          _c("el-table-column", {
            attrs: { label: "序号", prop: "sort", width: "90" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "90", "text-align": "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "取消",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "请确认是否删除该条数据",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger",
                            },
                            slot: "reference",
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          currentPage: _vm.queryForm.current,
          pageSize: _vm.queryForm.size,
        },
        on: { pageChange: _vm.handleFilter },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增",
            visible: _vm.addDialog.visible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                rules: _vm.addDialog.rules,
                "label-width": "auto",
                size: "mini",
                model: _vm.addDialog.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "platform" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入平台名称" },
                    model: {
                      value: _vm.addDialog.form.platform,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDialog.form, "platform", $$v)
                      },
                      expression: "addDialog.form.platform",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "部门", prop: "dept" } },
                [
                  _c("DictSelect", {
                    ref: "dept",
                    attrs: {
                      value: _vm.addDialog.form.dept,
                      "allow-create": "",
                      placeholder: "请选择部门",
                      dictFun: _vm.getDeptDict,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.addDialog.form, "dept", $event)
                      },
                      change: _vm.handleDeptChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "部门名称", prop: "deptName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入部门名称" },
                    model: {
                      value: _vm.addDialog.form.deptName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDialog.form, "deptName", $$v)
                      },
                      expression: "addDialog.form.deptName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      precision: 0,
                      controls: false,
                      placeholder: "默认升序",
                    },
                    model: {
                      value: _vm.addDialog.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDialog.form, "sort", $$v)
                      },
                      expression: "addDialog.form.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }