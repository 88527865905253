<template>
  <div>
    <div style="float: right;margin: 5px;">
      <el-input v-if="compType === 'sku'" style="width: 140px !important; margin-right: 10px" clearable size="mini" :placeholder="scope === '0'?'商品型号': '商品编码'" v-model="querySkuKeyWord" @keyup.enter.native="handleQuery('skus', 'skuPage')">
        <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('skus', 'skuPage')" /></el-input>
      <el-input v-if="compType === 'shop'" style="width: 120px !important;" size="mini" clearable placeholder="配送中心编码" v-model="queryDcCode" @keyup.enter.native="handleQuery('shops', 'shopPage')">
        <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('shops', 'shopPage')" /></el-input>
      <el-input v-if="compType === 'shop'" style="width: 120px !important;margin-left: 5px; margin-right: 10px" clearable size="mini" placeholder="店铺编码" v-model="queryShopCode" @keyup.enter.native="handleQuery('shops', 'shopPage')">
        <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('shops', 'shopPage')" /></el-input>
      <el-button v-if="type!=='view'" size="mini" icon="el-icon-plus" type="primary"
                 @click="handleImport">{{compType === "sku"?'导入商品': '导入店铺'}}
      </el-button>
      <el-button v-if="compType === 'sku' && type !=='view'" size="mini" icon="el-icon-plus" type="primary"
                 @click="drawerVisible = true">添加商品
      </el-button>
      <el-button v-if="compType === 'shop' && type !=='view'" size="mini" icon="el-icon-plus" type="primary"
                 @click="drawerVisible = true">添加店铺
      </el-button>
      <el-button v-if="type!=='view'" size="mini" icon="el-icon-remove" type="danger"
                 @click="handleRemoveAll">全部移除
      </el-button>
    </div>
    <div v-if="compType === 'sku'">
      <el-table
        ref="skuTableRef"
        :data="skuPage.rows"
        height="calc(100vh - 400px)"
        v-loading="loading"
        size="mini"
        border
        style="margin-top: 5px"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column v-if="scope === '1'" label="商品编码" prop="skuCode" width="100"/>
        <el-table-column label="商品型号" prop="skuModel" width="100"/>
        <el-table-column label="款式名称" prop="skuName"/>
        <el-table-column v-if="scope === '1'" label="规格" prop="spec" width="80"/>
        <el-table-column label="货号" prop="cargoNo" show-overflow-tooltip />
        <el-table-column label="大类" prop="mainCategoryName" width="100"/>
        <el-table-column label="中类" prop="midCategoryName" width="100"/>
        <el-table-column label="小类" prop="subCategoryName" width="100"/>
        <el-table-column label="标签" prop="label" width="100"/>
        <el-table-column v-if="type!=='view'" label="操作" width="70" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              style="border: 0px; color: red"
              @click.native.prevent="handleRemoveRow(scope.row, 1, 'skus', 'skuPage')"
              type="text"
              size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="skuPage.total" :page.sync="skuPage.current" :limit.sync="skuPage.size"
                  @pagination="handleQuery('skus', 'skuPage')"/>
    </div>
    <div v-if="compType === 'shop'">
      <el-table
        ref="shopTableRef"
        :data="shopPage.rows"
        height="calc(100vh - 300px)"
        v-loading="loading"
        size="mini"
        border
        style="margin-top: 5px"
        :empty-text="(!formLoading && shops.length === 0) ?'不添加数据,则视为默认添加当前所选分公司全部店铺': ''"
        highlight-current-row
        :class="(!formLoading && shops.length === 0) ? 'table-light-class': ''"
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="分公司" prop="orgName" width="100" />
        <el-table-column label="所属配送中心" prop="dcName" width="170">
          <template #default="{ row }">
            <span>{{ (row.dcCode?row.dcCode:'') + (row.dcName?"-" + row.dcName:'') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="省份/城市" prop="provinceName" width="150">
          <template #default="{ row }">
            <span>{{ (row.provinceName?row.provinceName:'') + "-" + (row.cityName?  "-" +row.cityName:'') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属区县" prop="areaName" width="100" />
        <el-table-column label="店铺编码/名称" prop="shop" width="250">
          <template #default="{ row }">
            <span>{{ (row.shopCode?row.shopCode:'') + (row.shopName ? "-" + row.shopName: '') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属商圈" prop="businessAreaName" width="100" />
        <el-table-column label="区域分组" prop="areaTag" width="80" />
        <el-table-column label="店铺类型" prop="shopCapacity" width="80" />
        <el-table-column label="合作类型" prop="shopBiz" width="70" />
        <el-table-column label="是否重点终端" prop="ifOptimumShop" width="80">
          <template #default="{ row }">
            <span>{{ row.ifOptimumShop ==='1'? '是': '否' }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="type!=='view'" label="操作" width="70" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button
              style="border: 0px; color: red"
              @click.native.prevent="handleRemoveRow(scope.row, 1, 'shops', 'shopPage')"
              type="text"
              size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="shopPage.total" :page.sync="shopPage.current" :limit.sync="shopPage.size"
                  @pagination="handleQuery('shops', 'shopPage')"/>
    </div>
    <el-drawer :title="compType==='sku'?'选择商品':'选择店铺'" :visible.sync="drawerVisible" size="70%" direction="rtl" append-to-body @close="() => {
      skuGroups.refresh = false;
      shopGroups.refresh = false;
    }" @open="() => {
      skuGroups.refresh = true;
      shopGroups.refresh = true;
    }">
      <el-menu v-if="compType==='sku' && scope === '0'" :default-active="skuGroups.activeIndex" mode="horizontal" class="el-menu-demo"
               @select="(val, path) => handleMenuSelect(val, path,'skuGroups')">
        <el-menu-item v-for="item in skuGroups.fixed" :key="item.id" :index="item.id"> {{
            item.groupName
          }}
        </el-menu-item>
        <el-submenu v-if="skuGroups.dynamic.length > 0" index="other">
          <template slot="title">{{ skuOtherName }}</template>
          <el-menu-item v-for="item in skuGroups.dynamic" :key="item.id" :index="item.id"> {{
              item.groupName
            }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <el-menu v-if="compType==='shop'" :default-active="shopGroups.activeIndex" mode="horizontal" class="el-menu-demo"
               @select="(val, path) => handleMenuSelect(val, path,'shopGroups')">
        <el-menu-item v-for="item in shopGroups.fixed" :key="item.id" :index="item.id"> {{
            item.groupName
          }}
        </el-menu-item>
        <el-submenu v-if="shopGroups.dynamic.length > 0" index="other">
          <template slot="title">{{ shopOtherName }}</template>
          <el-menu-item v-for="item in shopGroups.dynamic" :key="item.id" :index="item.id"> {{
              item.groupName
            }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
  <!--    <el-button style="float: right;top: -35px;position: relative;" size="mini" icon="el-icon-plus" type="primary"-->
  <!--               @click="handleAddGrp">{{ compType === 'sku' ? '新建商品组' : '新建店铺组' }}-->
  <!--    </el-button>-->
      <SkuItem
        v-show="compType==='sku'"
        ref="shopItemRef"
        :group-id="skuGroups.activeIndex"
        :refresh="skuGroups.refresh"
        :scope="scope"
        :task-id="taskId"
        :pageSkus="pageSku"
        :boost="boost"
        @add-items="addItems"
        @close-drawer="drawerVisible= false"
        :show-action="true"
        @getExceptModels="callback => callback(form.exceptModels)"
        @getExceptSkuCodes="callback => callback(form.exceptSkuCodes)"
      />
      <ShopItem
        v-show="compType==='shop'"
        ref="skuItemRef"
        :org-code="this.form.checkedOrgCodes"
        :group-id="shopGroups.activeIndex"
        :refresh="shopGroups.refresh"
        @add-items="addItems"
        @close-drawer="drawerVisible= false"
        :show-action="true"
        @getExceptCodes="callback => callback(form.exceptCodes)"
      />
    </el-drawer>
    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
                  @handleImportCallBack="handleImportData" append-to-body/>
    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SkuItem from "@/views/appTask/skuGroup/SkuItem";
import ShopItem from "@/views/appTask/shopGroup/ShopItem";
import {pageShopGroup, pageSkuModelGroup, checkShops, checkSkus} from "@/api/appTask";
import {MessageBox, Notification} from "element-ui";
import Pagination from "@/components/Pagination";

export default {
  name: "Items",
  components: {SkuItem, ShopItem, Pagination,ImportDialog: () => import("@/components/ImportDialog")},
  props: {
    compType: {
      type: String,
      default: 'sku'
    },
    scope: {
      type: String,
      default: '0'
    },
    skus: {
      type: Array,
      default() {
        return [];
      }
    },
    shops: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: 'edit'
    },
    showGroup: {
      type: Boolean,
      default: false
    },
    pageSku: {
      type: Function,
      default: undefined,
    },
    taskId: {
      type: String,
    },
    boost: {
      type: Boolean,
      default: false
    },
  },
  watch:{
    skus: {
      handler(nV, oV) {
        this.form.skus = nV;
        this.handleQuery('skus', 'skuPage');
        if (this.scope === '0') {
          this.form.exceptModels = nV.map(e => e.skuModel);
        } else {
          this.form.exceptSkuCodes = nV.map(e => e.skuCode);
        }
        if (this.compType === 'sku' && this.$refs.skuItemRef) {
          this.$refs.skuItemRef.handleFilter();
        }
      },
      deep: true,
      immediate: true
    },
    shops: {
      handler(nV, oV) {
        this.form.shops = nV;
        this.handleQuery('shops', 'shopPage');
        this.form.exceptCodes = nV.map(e => e.dcCode + "-" + e.shopCode);
        if (this.compType === 'shop' && this.$refs.shopItemRef) {
          this.$refs.shopItemRef.handleFilter();
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      loading: false,
      drawerVisible: false,
      form: {
        shops: [],
        skus: [],
        exceptCodes: [],
        exceptSkuCodes: [],
        exceptModels: [],
      },
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      shopPage: {
        total: 0,
        current: 1,
        rows: [],
        size: 10,
      },
      formLoading: false,
      shopGroups: {
        fixed: [],
        dynamic: [],
        activeIndex: 'ALL',
        activeIndexPath: '更多',
        refresh: false,
      },
      skuGroups: {
        fixed: [],
        dynamic: [],
        activeIndex: 'ALL',
        activeIndexPath: '更多',
        refresh: false,
      },
      fixIndex: 6,
      allArray: [{id: 'ALL', groupName: '全部'}],
      querySkuKeyWord: undefined,
      queryShopCode: undefined,
      queryDcCode: undefined,
      importDialog: {
        currentImportType: "sku",
        importBaseParams: [
          {
            visible: false,
            title: "任务-商品数据导入",
            type: "sku",
            tipsText: "提示：商品型号与货号至少一个不能为空,同时存在时以商品型号为主",
            templateSampleHead: [{label: "商品型号", prop: "skuModel", minWidth: "80px"}, {
              label: "货号",
              prop: "cargoNo",
              minWidth: "80px"
            }],
            templateSampleLists: [
              {
                skuModel: "1002E4G",
                cargoNo: "1002E4G",
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%95%86%E5%93%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          },
          {
            visible: false,
            title: "任务-商品数据导入",
            type: "skuCode",
            tipsText: "提示：商品编码不能为空",
            templateSampleHead: [{label: "商品编码", prop: "skuCode", minWidth: "80px"}],
            templateSampleLists: [
              {
                skuCode: "609023",
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%95%86%E5%93%81%E7%BC%96%E7%A0%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          },
          {
            visible: false,
            title: "任务-店铺数据导入",
            type: "shop",
            tipsText: "必需字段: 配送中心编码、店铺编码</br>不存在的或近2年无进货店铺数据隔天将在聚宝盆APP显示",
            templateSampleHead: [{label: "配送中心编码", prop: "dcCode", minWidth: "80px"},
              {
                label: "店铺编码",
                prop: "shopCode",
                minWidth: "80px"
              }],
            templateSampleLists: [
              {
                dcCode: "C03017",
                shopCode: "0190",
              },
            ],
            previewDataLength: 10,
            apiParams: "importList",
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%BA%97%E9%93%BA%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          }
        ],
      },
      noticeDialog: {
        visible: false,
        msg: undefined
      }
    }
  },
  computed: {
    skuOtherName: function () {
      return this.skuGroups.activeIndexPath;
    },
    shopOtherName: function () {
      return this.shopGroups.activeIndexPath;
    },
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  methods: {
    reset() {
      this.form = {
        shops: [],
        skus: [],
        exceptCodes: [],
        exceptSkuCodes: [],
        exceptModels: [],
      }
      this.handleQuery('shops', 'shopPage');
      this.handleQuery('skus', 'skuPage');
    },
    handleMenuSelect(val, path, type) {
      this[type].activeIndex = val;
      if (path.length > 1) {
        this[type].activeIndexPath = '更多(' + this[type].dynamic.find(e => e.id === val).groupName + ')';
      } else {
        this[type].activeIndexPath = '更多'
      }
    },
    getMenuIndex(type) {
      if (this.compType === 'sku' && (type === 'all' || type === 'sku')) {
        pageSkuModelGroup('?status=1').then(res => {
          if (res.data.records.length > this.fixIndex) {
            this.skuGroups.fixed = this.allArray.concat(res.data.records.slice(0, this.fixIndex));
            this.skuGroups.dynamic = res.data.records.slice(this.fixIndex);
          } else {
            this.skuGroups.fixed = this.allArray.concat(res.data.records);
          }
          if (this.skuGroups.activeIndex !== 'ALL') {
            const fixSelect = this.skuGroups.fixed.find(e => e.groupId === this.skuGroups.activeIndex);
            if (!fixSelect) {
              const dynamicSelect = this.skuGroups.dynamic.find(e => e.groupId === this.skuGroups.activeIndex);
              if (!dynamicSelect) {
                this.skuGroups.activeIndex = 'ALL';
              }
            }
          }
        })
      }
      if (this.compType === 'shop' && (type === 'all' || type === 'shop')) {
        pageShopGroup('?status=1').then(res => {
          if (res.data.records.length > this.fixIndex) {
            this.shopGroups.fixed = this.allArray.concat(res.data.records.slice(0, this.fixIndex));
            this.shopGroups.dynamic = res.data.records.slice(this.fixIndex);
          } else {
            this.shopGroups.fixed = this.allArray.concat(res.data.records);
          }

          if (this.shopGroups.activeIndex !== 'ALL') {
            const fixSelect = this.shopGroups.fixed.find(e => e.groupId === this.shopGroups.activeIndex);
            if (!fixSelect) {
              const dynamicSelect = this.shopGroups.dynamic.find(e => e.groupId === this.shopGroups.activeIndex);
              if (!dynamicSelect) {
                this.shopGroups.activeIndex = 'ALL';
              }
            }
          }
        })
      }
    },
    addItems(items, type) {
      if (type === "sku") {
        const newItems = items.filter(e => {
          if (this.form.exceptModels.indexOf(e.skuModel) < 0 && this.scope === '0') {
            return true;
          } else if (this.form.exceptSkuCodes.indexOf(e.skuCode) < 0 && this.scope === '1') {
            return true;
          }
        });
        this.form.skus = this.form.skus.concat(newItems);
        if (this.scope === '0') {
          this.form.exceptModels = Array.from(new Set(this.form.exceptModels.concat(newItems.map(e => e.skuModel))));
        } else if (this.scope === '1') {
          this.form.exceptSkuCodes = Array.from(new Set(this.form.exceptSkuCodes.concat(newItems.map(e => e.skuCode))));
        }
        this.skuPage.total = this.form.skus.length;
        this.handleQuery('skus', 'skuPage');
      } else if (type === "shop") {
        const newItems = items.filter(e => {
          if (this.form.exceptSkuCodes.indexOf(e.dcCode + "-" + e.shopCode) < 0) {
            return true;
          }
        });
        const addOrgCodes = Array.from(new Set(items.map(e => e.orgCode)));
        const orgAll = Array.from(new Set(this.form.shops.filter(e => addOrgCodes.indexOf(e.orgCode) > -1 && e.dcCode === 'ALL')));
        if (orgAll.length > 0) {
          Notification.warning('本次提交会覆盖' + orgAll.map(e => e.orgName).join('、') + '分公司上次配置，以本次所选店铺为准')
        }
        this.form.shops = this.form.shops.filter(e => addOrgCodes.indexOf(e.orgCode) < 0 || (addOrgCodes.indexOf(e.orgCode) > -1 && e.dcCode != 'ALL')).concat(newItems);
        this.form.exceptCodes = this.form.exceptCodes.concat(newItems.map(e => e.dcCode + "-" + e.shopCode));
        this.shopPage.total = this.form.shops.length;
        this.handleQuery('shops', 'shopPage');
      }
      this.$emit('set-size', this.form.skus.length, this.form.shops.length);
    },
    handleQuery(type, page) {
      const filterData = this.form[type].filter(e => {
        if (type === 'skus') {
          if (this.scope === '0') {
            return !this.querySkuKeyWord || e.skuModel.indexOf(this.querySkuKeyWord) > -1;
          } else if (this.scope === '1') {
            return !this.querySkuKeyWord || e.skuCode.indexOf(this.querySkuKeyWord) > -1;
          }
        } else if (type === 'shops') {
          let filterFlag = true;
          if (this.queryDcCode) {
            filterFlag = e.dcCode.indexOf(this.queryDcCode) > -1;
          }
          if (filterFlag && this.queryShopCode) {
            filterFlag = e.shopCode.indexOf(this.queryShopCode) > -1;
          }
          return filterFlag;
        }
        return false;
      })
      const total = this[page].current * this[page].size;
      this[page].total = filterData.length;
      this[page].rows = filterData.slice(this[page].size * (this[page].current - 1), total >= filterData.length ? filterData.length : this[page].current * this[page].size);
      const tableRef = type === 'skus'? 'skuTableRef' : 'shopTableRef';
      this.$nextTick(() => {
        if(this.$refs[tableRef]) {
          this.$refs[tableRef].doLayout();
        }
      })
    },
    handleRemoveRow(row, deleteCount, type, page) {
      if (type === 'skus') {
        if (this.scope === '0') {
          this.form[type] = this.form[type].filter(e => e.skuModel !== row.skuModel);
        } else if (this.scope === '1') {
          this.form[type] = this.form[type].filter(e => e.skuCode !== row.skuCode);
        }
      } else if (type === 'shops') {
        this.form[type] = this.form[type].filter(e => e.dcCode !== row.dcCode || e.shopCode !== row.shopCode);
      }
      this[page].total = this.form[type].length;
      this.handleQuery(type, page);
      this.$emit('set-size', this.form.skus.length, this.form.shops.length);
      if (this.scope === '0') {
        this.form.exceptModels = this.form.skus.map(e => e && e.skuModel ? e.skuModel : '');
      } else if (this.scope === '1') {
        this.form.exceptSkuCodes = this.form.skus.map(e => e && e.skuCode ? e.skuCode : '');
      }
      this.form.exceptCodes = this.form.shops.map(e => e.dcCode + "-" + e.shopCode);
    },
    handleRemoveAll() {
      if (this.compType === 'sku') {
        this.form.skus = [];
        this.form.exceptModels = [];
        this.form.exceptSkuCodes = [];
        this.handleQuery('skus', 'skuPage');
      } else if (this.compType === 'shop') {
        this.form.shops = [];
        this.form.exceptCodes = [];
        this.handleQuery('shops', 'shopPage');
      }
      this.$emit('set-size', this.form.skus.length, this.form.shops.length);
    },
    handleImport() {
      const type = this.compType === 'shop' ? 'shop' : (this.scope === '0' && this.compType === 'sku'? 'sku': 'skuCode')
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === type);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    async handleImportData(val) {
      this.noticeDialog.msg =""
      if (this.compType === 'sku') {
        const checkedArray = Object.assign([], this.form.skus);
        let tempArray = val["importList"];
        let errorData = [];
        tempArray.forEach(e => {

          if (this.scope === '0' && !e.skuModel && !e.cargoNo) {
            errorData.push(1)
          } else if (this.scope === '1' && !e.skuCode) {
            errorData.push(1)
          }

        })
        let msg = undefined;
        if (this.scope === '1' && errorData.length === tempArray.length) {
          msg = " 导入失败，导入的商品编码不能为空，请填写后重新导入！"
        } else if (this.scope === '0' && errorData.length > 0) {
          msg = " 导入失败，请重新上传，存在" + errorData.length+ " 条数据，商品型号和货号均为空！";
        }
        if (msg) {
          MessageBox.alert(msg, '提示', {
            dangerouslyUseHTMLString: true
          });
          return;
        }
        const param = tempArray.concat(checkedArray);
        await checkSkus(this.scope, { skus: param }).then((res) => {
          if (res.data && res.data.errorMsg) {
            this.noticeDialog.msg = " 导入失败，请重新上传" + res.data.errorMsg;
            this.noticeDialog.visible = true;
            return;
          } else {
            this.noticeDialog.msg = "导入成功，共成功" + (res.data.skus.length - checkedArray.length) + "条，重复" + (param.length - res.data.skus.length) + "条数据";
            this.noticeDialog.visible = true;
          }
          this.form.skus = res.data.skus;
        })
        if (this.scope === '0') {
          this.form.exceptModels = this.form.skus.map(e => e && e.skuModel ? e.skuModel : '');
        } else if (this.scope === '1') {
          this.form.exceptSkuCodes = this.form.skus.map(e => e && e.skuCode ? e.skuCode : '');
        }
        this.skuPage.total = this.form.skus.length;
      } else if (this.compType === 'shop') {
        const checkedArray = Object.assign([], this.form.shops).filter(e => e.dcCode != 'ALL');
        let tempArray = val["importList"];
        let errorData = [];
        tempArray.forEach(e => {
          if (!e.dcCode || !e.shopCode) {
            errorData.push(1)
          }
        })
        if (errorData.length > 0) {
          this.noticeDialog.msg = " 导入失败，请重新上传，存在" + errorData.length + " 条数据，配送中心编码或店铺编码为空";
          this.noticeDialog.visible = true;
          return;
        }
        const param = tempArray.concat(checkedArray);
        await checkShops({shops: param}).then((res) => {
          if (res.data && res.data.length) {
            let successNum = 0;
            let errorNum = 0;
            let errorData = []
            let errorDc = []
            let virtualDcData = [];
            res.data.filter(e => {
              if (e.orgCode && e.orgCode !== '-') {
                successNum++;
                if (e.checkResult === 3) {
                  virtualDcData.push("配送中心编码：" + e.oldDcCode + "(原) -> " + e.dcCode + "(新)；   店铺编码:" + e.oldShopCode + "(原) -> " + e.shopCode + "(新)");
                }
              } else if (e.checkResult === 2 || e.checkResult === 4) {
                errorNum++;
                errorData.push('配送中心编码：' + e.dcCode + "&nbsp;&nbsp;&nbsp;店铺编码: " + e.shopCode);
                if (e.checkResult === 4) {
                  virtualDcData.push("配送中心编码：" + e.oldDcCode + "(原) -> " + e.dcCode + "(新)；   店铺编码:" + e.oldShopCode + "(原) -> " + e.shopCode + "(新)");
                }
              } else if (!e.orgCode) {
                if (errorDc.indexOf(e.dcCode) < 0) {
                  errorDc.push(e.dcCode);
                }
              }
            });

            if (errorDc.length > 0) {
              this.noticeDialog.msg = "以下配送中心无权限或不存在，请确认后重新导入，列表如下：</br>" + errorDc.join('、');
              this.noticeDialog.visible = true;
              return;
            }
            const oldSuccessNum = this.form.shops.filter(e => e.orgCode && e.orgCode !== '-')
            this.noticeDialog.msg = "共 " + param.length + " 条，成功 " + (successNum - oldSuccessNum.length) + " 条，重复 " + (param.length - successNum - errorNum) + " 条，" +
              "无法匹配 " + (errorNum) + " 条,</br>";
            if (virtualDcData.length > 0) {
              this.noticeDialog.msg += "</br></br>以下店铺(以'_'分割）存在多个配送中心进货，实际为同一店铺，为在聚宝盆中合并统计，配送中心及店铺编码合并为：</br>" + virtualDcData.join('</br>');
            }
            if (errorNum > 0) {
              this.noticeDialog.msg += '</br></br>无效店铺列表如下（原因：不存在该店铺或近2年无进货,将在30分钟内自动处理）：</br>' + errorData.join('</br>')
            }
            this.noticeDialog.visible = true;
            this.form.shops = res.data;
          }
        })
        this.shopPage.total = this.form.shops.length;
        this.form.exceptCodes = this.form.shops.map(e => e.dcCode + "-" + e.shopCode);
      }
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === this.compType);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = false;
      this.$forceUpdate();
      this.$emit('set-size', this.form.skus.length, this.form.shops.length);
      this.handleQuery('skus', 'skuPage');
      this.handleQuery('shops', 'shopPage');
    },
  }
}
</script>

<style scoped>

</style>
