var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.targetLoading,
          expression: "targetLoading",
        },
      ],
      staticStyle: { padding: "10px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "5px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                "label-position": "right",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc,capacity,area-tag",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      "default-first-option": "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      linkageRef: "capacity,area-tag,ht-shop",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      parentLinkageRef: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "区域分组" } },
                [
                  _c("DictSelect", {
                    ref: "area-tag",
                    attrs: {
                      type: "area-tag",
                      value: _vm.queryForm.areaTag,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      alwaysChange: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "areaTag", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "年份" } },
                [
                  _c("DictSelect", {
                    ref: "year",
                    attrs: {
                      value: _vm.queryForm.year,
                      version: "v2",
                      singleProps: "",
                      type: "year",
                      init: "",
                      "init-options": _vm.yearOptions,
                      defaultIndex: 0,
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "year", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        disabled: !(
                          _vm.queryForm.orgCode &&
                          _vm.queryForm.dcCode &&
                          _vm.queryForm.areaTag
                        ),
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "12px",
                                "font-weight": "700",
                              },
                            },
                            [_vm._v("设置方式")]
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                size: "mini",
                                placeholder: "请选择设置方式",
                              },
                              on: { change: _vm.handleSettingChange },
                              model: {
                                value: _vm.queryForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "type", $$v)
                                },
                                expression: "queryForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  value: "1",
                                  label: "设置区域整体目标",
                                },
                              }),
                              _c("el-option", {
                                attrs: { value: "2", label: "设置单店目标" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _vm.queryForm.type === "2"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-upload",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleImport("shop")
                                    },
                                  },
                                },
                                [_vm._v("导入单店目标")]
                              )
                            : _vm._e(),
                          _vm.queryForm.type === "2"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-download",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleExport("shop")
                                    },
                                  },
                                },
                                [_vm._v("导出单店目标")]
                              )
                            : _vm._e(),
                          _vm.queryForm.type === "1"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-upload",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleImport("area")
                                    },
                                  },
                                },
                                [_vm._v("导入区域目标")]
                              )
                            : _vm._e(),
                          _vm.queryForm.type === "1"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-download",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleExport("area")
                                    },
                                  },
                                },
                                [_vm._v("导出区域目标")]
                              )
                            : _vm._e(),
                          _vm.queryForm.type === "2"
                            ? _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: { label: "店铺编码" },
                                },
                                [
                                  _c("DictSelect", {
                                    ref: "shop",
                                    attrs: {
                                      type: "ht-shop",
                                      value: _vm.queryForm.shopCode,
                                      dictFun: _vm.dictFun,
                                      fullLabel: "",
                                      alwaysChange: "",
                                      remote: "",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.queryForm,
                                          "shopCode",
                                          $event
                                        )
                                      },
                                      getOtherJsonParam: (callback) =>
                                        callback(_vm.dictParam),
                                      changeLinkageRef:
                                        _vm.handleChangeLinkageRef,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.queryForm.type === "2"
        ? _c(
            "div",
            { staticClass: "div-class" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: {
                    "tab-click": (val) => {
                      if (val.name == "x") {
                        this.queryForm.yearMth = this.queryForm.year
                      } else {
                        this.queryForm.yearMth = this.queryForm.year + val.name
                      }
                      _vm.handleFilter()
                    },
                  },
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.dateTabs, function (item) {
                  return _c("el-tab-pane", {
                    key: item.value,
                    attrs: { label: item.label, name: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.queryForm.type === "2"
        ? _c(
            "div",
            { staticClass: "div-class" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.pageData.rows,
                    height: "calc(100vh - 350px)",
                    size: "mini",
                    border: "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "店铺编码/名称",
                      prop: "shop",
                      width: "300",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.shopCode === "ALL"
                                        ? "全部"
                                        : row.shopCode + "-" + row.shopName
                                    )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2681006072
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "所属配送中心", prop: "dcName" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.dcCode === "ALL"
                                      ? "全部"
                                      : row.dcCode + "-" + row.dcName
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1343371504
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "目标进货金额",
                      prop: "amountTarget",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("el-input-number", {
                                class:
                                  row.amountType !== "1"
                                    ? "table-num-class"
                                    : "",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入",
                                  size: "mini",
                                  precision: 1,
                                  min: 0,
                                  controls: false,
                                },
                                on: { change: (cv, ov) => {} },
                                model: {
                                  value: row.amountTarget,
                                  callback: function ($$v) {
                                    _vm.$set(row, "amountTarget", $$v)
                                  },
                                  expression: "row.amountTarget",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4277474189
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { border: "0px", width: "20%" },
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCommit(row)
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2919234747
                    ),
                  }),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.pageData.total,
                  page: _vm.queryForm.current,
                  limit: _vm.queryForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryForm, "size", $event)
                  },
                  pagination: _vm.handleQuery,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.queryForm.type === "1"
        ? _c(
            "div",
            { staticStyle: { margin: "20px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.pageData.list,
                    height: "420px",
                    size: "mini",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "时间", prop: "mth", width: "100" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "目标",
                      prop: "amountTarget",
                      width: "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v("进货金额 ")]),
                              _c("el-input-number", {
                                staticStyle: { width: "70px" },
                                attrs: {
                                  placeholder: "请输入",
                                  size: "mini",
                                  precision: 1,
                                  min: 0,
                                  controls: false,
                                },
                                model: {
                                  value: row.amountTarget,
                                  callback: function ($$v) {
                                    _vm.$set(row, "amountTarget", $$v)
                                  },
                                  expression: "row.amountTarget",
                                },
                              }),
                              _c("span", [_vm._v("元")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2992416477
                    ),
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { border: "0px", width: "20%" },
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSaveAreaTargets()
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { border: "0px", width: "20%" },
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.importDialog.title[_vm.importDialog.type],
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "targetUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template[_vm.importDialog.type],
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: {
              "font-size": "16px",
              "overflow-y": "scroll",
              height: "400px",
            },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }