<template>
  <el-tabs v-model="tabName" @tab-click="(val) => handleTabClick(val.name)">
    <el-tab-pane name="spuSpecifications" label="包装（套装）转化关系维护">
      <TechSpuSpecifications ref="spuSpecifications" @handleJumpQuery="handleJumpQuery" />
    </el-tab-pane>
    <el-tab-pane name="spuPriceControl" label="组合装控价">
      <TechSpuPriceControl ref="spuPriceControl" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>


import TechSpuPriceControl from "@/views/e-commerce/techSpuPriceControl";
import TechSpuSpecifications from "@/views/e-commerce/techSpuSpecifications";

export default {
  name: "TechSpuManage",
  components: {TechSpuPriceControl,TechSpuSpecifications},
  data() {
    return {
      tabName: 'spuSpecifications'
    }
  },
  methods: {
    handleJumpQuery() {
      this.tabName = 'spuSpecifications';
      this.$nextTick(() => {
        this.$refs.spuSpecifications.handleJumpQuery();
      })
    },
    handleTabClick(val) {
      this.$nextTick(() => {
        this.$refs[val].handleFilter();
      })
    }
  }
}
</script>
