<template>
  <div>
    <div style="padding-bottom: 15px">
      <el-form ref="form" :rules="rules" size="mini" :inline="true" :model="form" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="12">
            <el-form-item label="商品包名称" prop="spName" label-width="120px">
              <el-input v-model="form.spName" placeholder="请输入" clearable/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark" label-width="120px">
              <el-input v-model="form.remark" class="descWidth" placeholder="请输入" clearable/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="一级品类" prop="oneGradeCategoryName" label-width="120px">
              <DictSelect
                ref="one_other"
                type="one_other"
                :value.sync="form.oneGradeCategoryName"
                linkageRef="two_other"
                :dictFun="dictFun"
                default-first-option
                @changeLinkageRef="(ref, val, type) => handleChangeLinkageRef(ref, val, type, form, 'formDictParam')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="二级品类" prop="twoGradeCategoryName" label-width="120px">
              <DictSelect
                ref="two_other"
                type="two_other"
                :value.sync="form.twoGradeCategoryName"
                :dictFun="dictFun"
                parentLinkageRef
                @getOtherJsonParam="(callback) => callback(formDictParam)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联盟商品包编码" label-width="160px">
              <el-input v-model="form.lmSpCode" class="descWidth" placeholder="请输入" clearable/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="商品包头图" label-width="120px">
              <OssUpload ref="ossUpload" :value-to.sync="form.imageUrl" list-type="picture-card" :showFiles="false" :maxFileSize="10240" tips="图片要求正方形" :tipMessageShow="false" :limit="10" :accept-type="imgTypes" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="商品详情图" prop="groupImageUrl" label-width="120px">
              <OssUpload ref="ossUpload" :value-to.sync="form.groupImageUrl" list-type="picture-card" :showFiles="false" :maxFileSize="10240" tips="图片要求正方形" :tipMessageShow="false" :limit="20" :accept-type="imgTypes" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-tabs v-model="tabName" @tab-click="(val) => {
        if (val.name !== 'sku') {
          this.handleSetTreeChecked(form);
        }
      }">
        <el-tab-pane label="商品范围" name="sku" />
        <el-tab-pane label="配送中心" name="dc" />
      </el-tabs>
      <div>
        <div style="position: relative; z-index: 999;width: 100%;">
          <div style="float: right;margin: 5px;">
            <el-input v-if="tabName === 'sku'" style="width: 120px !important; margin-right: 10px" clearable size="mini" :placeholder="'商品编码'" v-model="querySkuKeyWord" @keyup.enter.native="handleQuery('skus', 'skuPage')">
              <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('skus', 'skuPage')" /></el-input>
            <el-button v-if="type!=='view' && ((form.shopCheckType==='1' && tabName === 'shop') || tabName === 'sku')" size="mini" icon="el-icon-upload" type="primary"
                       @click="handleImport">导入商品
            </el-button>
            <el-button v-if="tabName === 'sku' && form.spCode" size="mini" icon="el-icon-download" type="primary"
                       @click="handleExport">导出商品
            </el-button>
            <el-button v-if="tabName === 'sku' && type !=='view'" size="mini" icon="el-icon-plus" type="primary"
                       @click="drawer.visible = true">添加商品
            </el-button>
            <el-button v-if="type!=='view' && tabName === 'sku'" size="mini" icon="el-icon-remove" type="danger"
                       @click="handleRemoveAll">全部移除
            </el-button>
          </div>
        </div>
        <div v-show="tabName === 'sku'">
          <el-table
            ref="skuTableRef"
            :data="skuPage.rows"
            height="calc(100vh - 400px)"
            v-loading="loading"
            size="mini"
            border
            style="margin-top: 5px"
            highlight-current-row
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="商品编码" prop="skuCode" width="100"/>
            <el-table-column label="商品型号" prop="skuModel" width="100"/>
            <el-table-column label="款式名称" prop="skuName" show-overflow-tooltip>
              <template slot-scope="scope">
                <span >{{ scope.row.skuName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="商品数量" prop="skuCount" width="120">
              <template slot-scope="scope">
                <span v-show="type==='view'" >{{ scope.row.skuCount }}</span>
                <div v-show="type==='edit' || type==='add'">
                  <el-input-number v-model="scope.row.skuCount" size="mini" clearable :controls="false" :min="1" placeholder="请输入数量" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="规格" prop="spec" width="80"/>
            <el-table-column label="货号" prop="cargoNo" show-overflow-tooltip />
            <el-table-column label="大类" prop="mainCategoryName" width="100"/>
            <el-table-column label="中类" prop="midCategoryName" width="100"/>
            <el-table-column label="小类" prop="subCategoryName" width="100"/>
            <el-table-column v-if="type!=='view'" label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  style="border: 0px; color: red"
                  @click.native.prevent="handleRemoveRow(scope.row, 1, 'skus', 'skuPage')"
                  type="text"
                  size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="skuPage.total" :page.sync="skuPage.current" :limit.sync="skuPage.size"
                      @pagination="handleQuery('skus', 'skuPage')"/>
        </div>
        <div v-show="tabName === 'dc'">
          <div style="display: inline-flex;">
            <div style="width: 100px;font-size: 14px; padding: 10px; font-weight: 500">
              所选区域
            </div>
            <div style="height: 300px;overflow-y: scroll;width: 400px;margin: 10px;">
              <el-input placeholder="输入关键字进行过滤" v-model="filterTaskDcText" size="mini" style="z-index: 99; width: 200px;" clearable @input="handleFilterChange" />
              <el-button v-if="orgDcTree.length > 1" size="mini" icon="el-icon-check" type="primary" @click="handleCheckAll(true)">全选</el-button>
              <el-button v-if="orgDcTree.length > 1" size="mini" icon="el-icon-remove" type="primary" @click="handleCheckAll(false)">取消全选</el-button>
              <el-tree
                ref="orgDcTreeRef"
                :data="orgDcTree"
                show-checkbox
                node-key="fullCode"
                :accordion="true"
                :filter-node-method="filterOrgDcNode"
                @check="(node, data) => handleOrgDcChange(node, data, 'dcs')"
                style="margin-top: 10px"
                :props="{ children: 'children', label: 'label', value: 'value' }">
              </el-tree>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer :title="'选择商品'" :visible.sync="drawer.visible" size="70%" direction="rtl"
               append-to-body>
      <SkuItem
        v-show="tabName==='sku'"
        :group-id="skuGroups.activeIndex"
        :refresh="skuGroups.refresh"
        scope="1"
        @add-items="addItems"
        @close-drawer="drawer.visible = false"
        :show-action="true"
        @getExceptModels="callback => callback([])"
        @getExceptSkuCodes="callback => callback(form.exceptSkuCodes)"
      />
    </el-drawer>
    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
                  @handleImportCallBack="handleImportData" append-to-body/>

    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getPackageDetail, checkSkus, exportSkuPackage, getOrgDcTree, goodsDict} from "@/api/appTask";
import Pagination from "@/components/Pagination";
import SkuItem from "@/views/appTask/skuGroup/SkuItem";
import DictSelect from "@/components/DictSelect";
import {parseTime, deepClone, excelExport} from "@/utils";
import {Message, MessageBox, Notification} from "element-ui";
import OssUpload from "@/components/OssUpload";

export default {
  name: "Edit",
  components: {
    SkuItem,
    DictSelect,
    Pagination,
    OssUpload,
    ImportDialog: () => import("@/components/ImportDialog")
  },
  props: {
    spCode: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      dictFun: goodsDict,
      filterDcText: undefined,
      filterTaskDcText: undefined,
      formLoading: false,
      form: {
        dcs: [],
        skus: [],
        spCode: undefined,
        lmSpCode: undefined,
        spName: undefined,
        imageUrl: '',
        groupImageUrl: '',
        oneGradeCategoryName: undefined,
        twoGradeCategoryName: undefined,
        skuCount: 0,
        status: 0,
        remark: undefined,
        rid: undefined,
        createUser: undefined,
        createTime: undefined,
        updateUser: undefined,
        updateTime: undefined,
        exceptSkuCodes: []
      },
      querySkuKeyWord: undefined,
      queryDcCode: undefined,
      rules: {
        spName: [{required: true, message: " ", trigger: "change"}],
        oneGradeCategoryName: [{required: true, message: " ", trigger: "change"}],
        twoGradeCategoryName: [{required: true, message: " ", trigger: "change"}],
        imageUrl: [{required: true, message: " ", trigger: "blur"}],
      },
      loading: false,
      tabName: "sku",
      drawer: {
        visible: false,
      },
      skuGroups: {
        fixed: [],
        dynamic: [],
        activeIndex: 'ALL',
        activeIndexPath: '更多',
        refresh: false,
      },
      fixIndex: 6,
      formDictParam: {},
      allArray: [{id: 'ALL', groupName: '全部'}],
      imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
      importDialog: {
        currentImportType: "sku",
        importBaseParams: [
          {
            visible: false,
            title: "商品包-商品数据导入",
            type: "sku",
            tipsText: "提示：商品编码不能为空,商品数量不能为空",
            templateSampleHead: [{label: "商品编码", prop: "skuCode", minWidth: "80px"},{label: "商品数量", prop: "skuCount", minWidth: "80px"}],
            templateSampleLists: [
              {
                skuCode: "609023",
                skuCount: 3,
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%95%86%E5%93%81%E5%8C%85-%E5%95%86%E5%93%81%E7%BC%96%E7%A0%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          },
        ],
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      ifComponent: undefined,
      initOrgOptions: [],
      defaultQueryOptions: [],
      queryConfigRows: [],
      orgFilterMap: {},
      allOrgMap: {},
      exportHeaders: {
        skuCode: {
          skuCode: '商品编码',
          skuCount: '商品数量',
          errorMsg: '校验结果',
        },
      },
      orgDcTree: [],
      parseTime: undefined,
    };
  },
  computed: {
    skuOtherName: function () {
      return this.skuGroups.activeIndexPath;
    },
    "skuPage.total": function () {
      return this.form.skus.length;
    },
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
    orgCompareShow() {
      if (this.form.compareOrgCode) {
        if (this.orgFilterMap[this.form.compareOrgCode].ksCompareChoose === '1' && this.orgFilterMap[this.form.compareOrgCode].ksCompareDate &&  this.orgFilterMap[this.form.compareOrgCode].ksCompareDate.length > 0) {
          return parseTime(this.orgFilterMap[this.form.compareOrgCode].ksCompareDate[0], '{y}-{m}-{d}') + '至' + parseTime(this.orgFilterMap[this.form.compareOrgCode].ksCompareDate[1], '{y}-{m}-{d}');
        } else if (this.orgFilterMap[this.form.compareOrgCode].ksCompareChoose === '0') {
          return "前180天"
        }
      }
      return;
    },
  },
  watch: {
    spCode: {
      handler(newVal) {
        this.getDetail(newVal);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    getOrgDcTree().then(res => {
      this.orgDcTree = res.data;
    })
  },
  mounted() {
  },
  methods: {
    getDetail(val) {
      this.reset();
      if (val !== undefined) {
        this.formLoading = true;
        getPackageDetail(val).then(res => {
          if ((res.data.checkedOrgCodes instanceof Array) && res.data.checkedOrgCodes.length === 1) {
            res.data.checkedOrgCodes = res.data.checkedOrgCodes[0];
          }
          let tmpForm = deepClone(this.form)
          tmpForm= Object.assign(tmpForm, res.data);

          this.handleSetTreeChecked(tmpForm)

          this.skuPage.total = tmpForm.skus.length;

          this.form = tmpForm;
          this.handleQuery('skus', 'skuPage');
          // this.$emit('set-size', tmpForm.skus.length, tmpForm.shops.length);
          this.$forceUpdate();
          this.formLoading = false;
        })
        this.resetTree();

      } else {
        this.$emit('set-size', 0, 0);
        this.form.shopCheckType = '0'
        this.resetTree();
      }
    },
    reset() {
      let form = {
        dcs: [],
        skus: [],
        spCode: undefined,
        lmSpCode: undefined,
        spName: undefined,
        imageUrl: '',
        groupImageUrl: '',
        oneGradeCategoryName: undefined,
        twoGradeCategoryName: undefined,
        skuCount: 0,
        status: 0,
        remark: undefined,
        rid: undefined,
        createUser: undefined,
        createTime: undefined,
        updateUser: undefined,
        updateTime: undefined,

        exceptSkuCodes: []
      };
      this.skuPage = {
        total: 0,
        current: 1,
        rows: [],
        size: 10
      };
      this.tabName ='sku';
      this.form = Object.assign({}, form);
      this.querySkuKeyWord = undefined;
      this.queryDcCode = undefined;
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    async handleImportData(val) {
      this.noticeDialog.msg =""
      if (this.tabName === 'sku') {
        const checkedArray = Object.assign([], this.form.skus);
        checkedArray.forEach(e => e.dataType = '0');
        let tempArray = val["importList"];
        let errorData = [];
        tempArray.forEach(e => {
          if (!e.skuCode) {
            errorData.push(1)
            e.errorMsg = '商品商品编码不能为空'
          }
          e.dataType = '1';
        })
        let msg = undefined;
        if (errorData.length === tempArray.length) {
          msg = " 导入失败，导入的商品编码不能为空，请填写后重新导入！"
        }
        if (msg) {
          MessageBox.alert(msg, '提示', {
            dangerouslyUseHTMLString: true
          });
          return;
        }
        const param = tempArray.concat(checkedArray);
        await checkSkus(3, { skus: param }).then((res) => {
          const errorCount = res.data.skus.filter(e => e.checkResult === '3' && e.dataType === '1').length;
          this.form.tmpSkus = [].concat(res.data.skus.filter(e => e.dataType === '1'));
          if (errorCount > 0) {
            this.noticeDialog.msg = " 导入失败，请重新上传" + res.data.errorMsg;
            this.noticeDialog.visible = true;
            return;
          } else {
            const repeatCount = res.data.skus.filter(e => e.checkResult === '2' && e.dataType === '1').length;
            const successCount = res.data.skus.filter(e => e.checkResult === '1' && e.dataType === '1').length;
            this.noticeDialog.msg = "导入成功， 共 " + tempArray.length + " 条，成功 " + successCount + " 条，重复 " + repeatCount + " 条数据";
            this.noticeDialog.visible = true;
            this.form.skus = res.data.skus.filter(e => e.dataType === '0' || ( e.dataType === '1' && e.checkResult === '1'));
          }
        })
        this.form.exceptSkuCodes = this.form.skus.map(e => e && e.skuCode ? e.skuCode : '');
        this.skuPage.total = this.form.skus.length;
      }
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === this.tabName);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = false;
      this.$forceUpdate();
      this.handleQuery('skus', 'skuPage');
    },
    addItems(items, type) {
      const newItems = items.filter(e => {
        if (this.form.exceptSkuCodes.indexOf(e.skuCode) < 0) {
          return true;
        }
      });
      this.form.skus = this.form.skus.concat(newItems);
      this.form.exceptSkuCodes = Array.from(new Set(this.form.exceptSkuCodes.concat(newItems.map(e => e.skuCode))));
      this.skuPage.total = this.form.skus.length;
      this.handleQuery('skus', 'skuPage');
    },
    handleAddGrp() {
      this.ifComponent = this.tabName
    },
    handleQuery(type, page) {
      const filterData = this.form[type].filter(e => {
        if (type === 'skus') {
          return !this.querySkuKeyWord || e.skuCode.indexOf(this.querySkuKeyWord) > -1;
        }
        return false;
      })
      const total = this[page].current * this[page].size;
      this[page].total = filterData.length;
      this[page].rows = filterData.slice(this[page].size * (this[page].current - 1), total >= filterData.length ? filterData.length : this[page].current * this[page].size);
      this.$nextTick(() => {
        if(this.$refs['skuTableRef']) {
          this.$refs['skuTableRef'].doLayout();
        }
      })
    },
    handleImport() {
      const type = 'sku'
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === type);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    handleExport() {
      this.loading = true;
      exportSkuPackage(this.form.spCode).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '商品包商品数据导出（' + this.form.spName + '）.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleRemoveRow(row, deleteCount, type, page) {
      if (type === 'skus') {
        this.form[type] = this.form[type].filter(e => e.skuCode !== row.skuCode);
      }
      this[page].total = this.form[type].length;
      this.handleQuery(type, page);
      this.form.exceptSkuCodes = this.form.skus.map(e => e && e.skuCode ? e.skuCode : '');
    },
    handleRemoveAll() {
      if (this.tabName === 'sku') {
        this.form.skus = [];
        this.form.exceptModels = [];
        this.form.exceptSkuCodes = [];
        this.handleQuery('skus', 'skuPage');
      }
      this.form.runFlow = true;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this[dict] = {oneCategory: this.form.oneGradeCategoryName}
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDialogCloseEvent(type) {
      this.ifComponent = undefined;
    },
    downloadImportResult() {
      let headers = {};
      let data = [];
      let title = ''
      if (this.tabName === 'sku') {
        data = this.form.tmpSkus.filter(e => e.dataType === '1');
        console.log(data);
        title = '商品包绑定商品导入结果';
        headers = this.exportHeaders.skuCode;
      }
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
    handleFilterChange(val) {
      if (this.$refs.orgDcTreeRef) {
        this.$refs.orgDcTreeRef.filter(val);
        if (!val) {
          this.expandTree(false);
        }
      }
    },
    expandTree(val) {
      var nodes = this.$refs.orgDcTreeRef.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = val;
      }
    },
    resetTree() {
      this.filterDcText = undefined;
      this.handleFilterChange(undefined);
    },
    filterOrgDcNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleOrgDcChange(node, data, key) {
      let checkedDcCodes = [];
      let checkOrgCodes = [];
      data.checkedNodes.forEach(e => {
        const orgCode = e.fullCode.split('-')[0];
        if (e.fullCode.split('-').length > 1) {
          const dcCode =e.fullCode.split('-')[1];
          if (checkOrgCodes.indexOf(orgCode) < 0) {
            checkOrgCodes.push(orgCode);
          }
          checkedDcCodes.push({...{orgCode: orgCode, dcCode: dcCode } });
        } else {
          checkedDcCodes.push({...{orgCode: orgCode, dcCode: 'ALL' } });
        }
      })
      this.form[key] = [].concat(checkedDcCodes);
    },
    handleSetTreeChecked(form) {
      if (form.dcs.length > 0) {
        let checkedAllOrgs = form.dcs.filter(e => e.dcCode === 'ALL').map(e => e.orgCode);
        let otherDcs = form.dcs.filter(e => e.dcCode !== 'ALL').map(e => e.orgCode + '-' + e.dcCode);
        let checkedKeys = [];
        this.orgDcTree.forEach(e => {
          if (checkedAllOrgs.indexOf(e.value) > -1) {
            e.children.forEach(c => {
              checkedKeys.push(c.fullCode);
            })
          }
        });
        checkedKeys = checkedKeys.concat(otherDcs);
        this.$nextTick(() => {
          if (this.$refs.orgDcTreeRef) {
            this.$refs.orgDcTreeRef.setCheckedKeys(checkedKeys, true);
          }
        })
      }
    },
    handleCheckAll(action) {
      let checkedDcCodes = [];
      this.orgDcTree.forEach(e => {
        if (action) {
          checkedDcCodes.push({...{orgCode: e.value, dcCode: 'ALL' } });
        }
      });
      this.form.dcs = [].concat(checkedDcCodes);
      this.$nextTick(() => {
        if (this.$refs.orgDcTreeRef) {
          this.$refs.orgDcTreeRef.setCheckedKeys(checkedDcCodes.map(e => e.orgCode), false);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  //.table-light-class .el-table__empty-block {
  //  font-size: xxx-large;
  //  justify-content: unset;
  //  .el-table__empty-text {
  //    line-height: 60px;
  //    width: 50%;
  //    color: red !important;
  //  }
  //}
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .el-drawer__header {
    margin-bottom: 0px;
  }

  .descWidth.el-input__inner {
    padding-left: 5px !important;
    max-width: unset !important;
  }
  .el-upload--picture-card {
    height:30px;
    width: 80px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:80px;
    width: 60px;
  }
  label.el-radio {
    font-weight: 400;
  }
}

</style>
<style lang="scss" scoped src='@/style/common/index.scss'></style>
