var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mg-fixed-top", style: { height: _vm.height } },
    [
      _c("div", { ref: "mgFixedTopBody", staticClass: "mg-fixed-top--body" }, [
        _c(
          "div",
          {
            style: {
              display: "flex",
              "align-items": "center",
              width: _vm.isFullWidth ? "100%" : "",
            },
          },
          [_vm._t("left")],
          2
        ),
        _c(
          "div",
          {
            staticClass: "mg-fixed-top-right",
            staticStyle: { flex: "1", "text-align": "right" },
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }