<template>
  <el-card
    :class="['mg-search-form', isShowFormBorder ? '' : 'mg-search-formBorder']"
    shadow="never"
  >
    <el-form ref="form" :model="form" v-bind="$attrs" v-on="$listeners" @keydown.enter.native="handleSearch">
      <el-row>
        <template v-for="(item, index) in formItemList">
          <el-col
            v-if="!item.hidden"
            v-show="index <= firstIndex || expandType"
            :key="index"
            :span="(item.type === 'date' && item.attrs.type === 'daterange') || !item.span ? 6 : item.span"
          >
            <el-form-item v-if="item.turnLabel" v-bind="item">
              <template v-slot:label>
                <el-dropdown @command="(event) => handleCommand(event, item)">
                  <span
                    class="dropdown-label"
                    style="
                      font-size: 12px;
                      cursor: pointer;
                      font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial,
                        Hiragino Sans GB, Microsoft Yahei, STHeiti, SimSun, sans-serifsans-serif;
                    "
                  >
                    {{ item.label }}
                    <i class="el-icon-caret-bottom el-icon--right dropdown-icon" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(dropdown, dropIndex) in item.attrs.options"
                      :key="dropIndex"
                      :command="dropdown.model"
                    >
                      {{ dropdown.label }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <el-input
                v-model.trim="form[item.model]"
                :placeholder="item.placeholder"
                clearable
                @clear="selectClear(item)"
              />
            </el-form-item>
            <el-form-item
              v-else
              :prop="item.model"
              :class="[item.attrs && item.attrs.type === 'daterange' ? 'mg_date_picker' : '']"
              v-bind="item"
            >
              <!-- 单选 -->
              <el-radio-group
                v-if="item.type === 'radio'"
                v-model="form[item.model]"
                :placeholder="item.placeholder || '请输入'"
              >
                <el-radio v-for="radioItem in item.options" :label="radioItem.value" :key="radioItem.value">
                  {{ radioItem.label }}
                </el-radio>
              </el-radio-group>
              <!--  搜索input  -->
              <mg-autocomplete
                v-if="item.type === 'autocomplete'"
                popper-class="my-autocomplete"
                class="width_100"
                v-model="form[item.model]"
                :fetch-suggestions="(queryString, cb) => querySearch(queryString, cb, item)"
                placeholder="请输入内容"
                @select="(value) => handleSelect(value, item)"
                :highlight-first-item="true"
              >
                <template #header>
                  <slot name="header">
                    <template v-for="column in item.columns">
                      <span :style="{ width: column.width }" :key="column.label">{{ column.label }}</span>
                    </template>
                  </slot>
                </template>
                <template slot-scope="{ item: items }">
                  <slot :item="items">
                    <template v-for="column in item.columns">
                      <span :style="{ width: column.width }" :key="column.label">
                        <mg-tooltip :content="items[column.prop]" placement="top">
                          <span>
                            {{ items[column.prop] }}
                          </span>
                        </mg-tooltip>
                      </span>
                    </template>
                  </slot>
                </template>
              </mg-autocomplete>
              <!-- input 文本类型-->
              <el-input
                v-if="item.type === 'input'"
                v-model.trim="form[item.model]"
                :placeholder="item.placeholder || '请输入'"
                clearable
                @clear="selectClear(item)"
              />
              <!-- number 类型 -->
              <el-input
                v-if="item.type === 'number'"
                v-input-number
                maxlength="10"
                v-model.trim="form[item.model]"
                :placeholder="item.placeholder || '请输入'"
                clearable
                @clear="selectClear(item)"
              />
              <!-- 搜索类型 -->
              <el-input
                v-if="item.type === 'inputSearch'"
                v-model.trim="form[item.model]"
                :placeholder="item.placeholder || '请输入'"
                clearable
                disabled
                @clear="selectClear(item)"
              >
                <el-button
                  slot="append"
                  :icon="form[item.model] ? 'el-icon-sort' : 'el-icon-plus'"
                  @click="item.select(item)"
                ></el-button>
              </el-input>
              <!-- select 是否多选根据initialValue属性是否为数组判断；需要传进来options属性为select选择属性 -->
              <el-select
                :popper-append-to-body="false"
                v-if="item.type === 'select'"
                class="width_100"
                v-loadmore:loadMore="item"
                v-model="form[item.model]"
                :multiple="Array.isArray(item.initialValue || '')"
                collapse-tags
                clearable
                filterable
                reserve-keyword
                :disabled="item.disabled"
                :placeholder="item.placeholder || '请选择'"
                @change="(event) => selectChange(event, item)"
                @clear="selectClear(item)"
              >
                <el-option
                  v-for="itemSub in item.options"
                  :key="itemSub.value"
                  :label="itemSub.label"
                  :value="itemSub.value"
                ></el-option>
              </el-select>
              <!-- select 树形option选项 -->
              <mg-select-tree
                ref="treeOption"
                v-if="item.type === 'selectTree'"
                :default-expand-all="item.options.defaultExpandAll"
                :treeData="item.options.treeData"
                :checkedNodes="treeCheckedNodes(item.model)"
                :show-checkbox="Array.isArray(item.initialValue) ? true : false"
                :node-key="item.options.nodeKey"
                :props="item.options.defaultProps || {}"
                @change="
                  ({ checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }) => {
                    checkChangeTree(checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys, item)
                  }
                "
              />
              <el-date-picker
                v-if="item.type === 'date'"
                v-model="form[item.model]"
                style="width: 100%"
                :type="item.attrs.type || 'date'"
                :format="item.attrs.format || 'yyyy-MM-dd'"
                :value-format="item.attrs.valueFormat || 'timestamp'"
                :placeholder="item.placeholder || '请选择'"
                :range-separator="item.attrs.rangeSeparator || '至'"
                :picker-options="item.attrs.pickerOptions"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :disabled="item.disabled || false"
                @change="(event) => dateChange(event, item)"
                :popper-class="item.attrs.popperClass || ''"
              ></el-date-picker>
              <div v-if="item.type === 'date' && item.attrs.type === 'daterange'" class="customize_date">
                <a v-for="time in dateList" size="small" :key="time.value" @click="createDate(time, item)">
                  {{ time.name }}
                </a>
              </div>
              <div v-if="item.type === 'range'">
                <el-input-number
                  v-model.trim="form[item.model]"
                  class="input_number"
                  :placeholder="item.placeholder"
                  :controls="false"
                  @blur="rangeBlur('min', item)"
                />
                <span style="padding: 0 5px; font-size: 12px">{{ item.separator }}</span>
                <el-input-number
                  v-model.trim="form[item.maxModel]"
                  class="input_number"
                  :placeholder="item.maxPlaceholder"
                  :controls="false"
                  @blur="rangeBlur('max', item)"
                />
              </div>
              <div v-if="item.type === 'monthrange'" class="monthrange">
                <el-date-picker
                  v-model="form[item.model]"
                  :type="item.pickerType || 'month'"
                  class="month_range"
                  :format="item.format || 'yyyyMM'"
                  :value-format="item.valueFormat || 'timestamp'"
                  :placeholder="item.placeholder || '请选择'"
                  :picker-options="item.minPickerOptions"
                  @change="(event) => dateChange(event, item, 'min')"
                ></el-date-picker>
                <span style="padding: 0 5px; font-size: 12px">{{ item.separator || '-' }}</span>
                <el-date-picker
                  v-model="form[item.maxModel]"
                  :type="item.pickerType || 'month'"
                  class="month_range"
                  :format="item.format || 'yyyyMM'"
                  :value-format="item.valueFormat || 'timestamp'"
                  :placeholder="item.maxPlaceholder || '请选择'"
                  :picker-options="item.maxPickerOptions"
                  @change="(event) => dateChange(event, item, 'max')"
                ></el-date-picker>
              </div>
              <div v-if="item.type === 'yearAndmonth'" class="monthrange">
                <el-col :span="11">
                  <el-date-picker
                    v-model="form[item.model]"
                    :type="item.pickerType || 'year'"
                    class="month_range"
                    :format="item.format || 'yyyy'"
                    :value-format="item.valueFormat || 'yyyy'"
                    :placeholder="item.placeholder || '请选择'"
                    :picker-options="item.minPickerOptions"
                    @change="(event) => dateChange(event, item, 'min')"
                    style="width: 100%"
                  ></el-date-picker>
                </el-col>
                <el-col :span="2" style="text-align: center">{{ item.separator || '-' }}</el-col>
                <el-col :span="11">
                  <el-select
                    :popper-append-to-body="false"
                    class="width_100"
                    v-loadmore:loadMore="item"
                    v-model="form[item.selectmodel]"
                    :multiple="Array.isArray(item.initialValue || '')"
                    collapse-tags
                    clearable
                    filterable
                    :disabled="item.disabled"
                    :placeholder="item.placeholder || '请选择'"
                    @change="(event) => selectChange(event, item)"
                    @clear="selectClear(item)"
                  >
                    <el-option
                      v-for="itemSub in item.options"
                      :key="itemSub.value"
                      :label="itemSub.label"
                      :value="itemSub.value"
                    ></el-option>
                  </el-select>
                </el-col>
              </div>
              <!-- 复合选择框 -->
              <div v-if="item.type === 'recombination'" class="recombination">
                <el-col :span="11">
                  <el-select
                    :popper-append-to-body="false"
                    class="width_100"
                    v-loadmore:loadMore="item"
                    v-model="form[item.selectmodel]"
                    collapse-tags
                    clearable
                    filterable
                    :disabled="item.disabled"
                    :placeholder="item.placeholder || '请选择'"
                    @change="(event) => selectChange(event, item)"
                    @clear="selectClear(item)"
                  >
                    <el-option
                      v-for="itemSub in item.options"
                      :key="itemSub.value"
                      :label="itemSub.label"
                      :value="itemSub.value"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col :span="2" style="text-align: center">{{ item.separator || '-' }}</el-col>
                <el-col :span="11">
                  <el-input
                    v-model.trim="form[item.model]"
                    :placeholder="item.placeholder || '请输入'"
                    clearable
                    @clear="selectClear(item)"
                  />
                </el-col>
              </div>
            </el-form-item>
          </el-col>
        </template>

        <el-col class="button_box" :span="buttonScopeSpan" v-if="isShowSearchBtn">
          <el-form-item label-width="0" class="button_position">
            <el-button
              size="mini"
              type="primary"
              @click="handleSearch"
            >查询</el-button>
            <el-button
              size="mini"
              @click="handleReset"
            >重置</el-button>
            <slot name="buttonScope"></slot>
            <el-button
              v-show="spanLength >= 24 * row"
              type="text"
              @click="openForm"
            >
              <span v-if="expandType">收起</span>
              <span v-else>更多</span>
              <i v-if="expandType" class="el-icon-arrow-up"></i>
              <i v-else class="el-icon-arrow-down"></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template v-if="isShowFooterTag">
      <form-tag
        :expand-type="expandType"
        :hide-form-list="hideFormList"
        @handleReset="handleReset"
        @closeTag="closeTag"
      />
    </template>

    <!--  formItem 配置  -->
    <set-form-item ref="setFormItem" :form-items.sync="setFormList" />
  </el-card>
</template>

<script>
  import mixins from './mixins/mixins'
  import dayjs from 'dayjs'
  import FormTag from '@/mg-components/mg-search-form/components/FormTag'
  import SetFormItem from '@/mg-components/mg-search-form/components/SetFormItem'
  import MgAutocomplete from '@/mg-components/mg-autocomplete'
  import mgTooltip from '@/mg-components/mg-tooltip-text'
  export default {
    props: {
      formItemList: {
        // 表单基本配置数据
        type: Array,
        default: () => [],
      },
      row: {
        // 默认展示行数
        type: Number,
        default: 1,
      },
      // 是否需要底部tag
      isShowFooterTag: {
        type: Boolean,
        default: true,
      },
      isAdvanced: {
        type: Boolean,
        default: false,
      },
      // 是否为高级筛选
      searchType: {
        type: String,
        default: 'normal',
      },
      // 是否显示查询/重置按钮
      isShowSearchBtn: {
        type: Boolean,
        default: true
      },
      // 是否显示查询模块边框
      isShowFormBorder: {
        type: Boolean,
        default: false
      },
      // 日期选择框值发生变化时, 是否直接查询
      isDateChangeSearch: {
        type: Boolean,
        default: true
      },
      // 搜索区域宽度
      buttonScopeSpan: {
        type: Number,
        default: 6
      }
    },
    components: {
      SetFormItem,
      FormTag,
      MgAutocomplete,
      mgTooltip,
      mgSelectTree: () => import('./components/mg-select-tree'),
    },
    mixins: [mixins],
    computed: {
      // 为了改变顺序时不直接修改prop里面的值导致控制台报错
      setFormList: {
        get() {
          return this.formItemList
        },
        set(val) {
          this.$emit('update:formItemList', val)
        },
      },
    },
    watch: {
      formItemList: {
        handler(newVal) {
          this.spanLength = 0
          // 是否展开，是否展开标签
          newVal.forEach((item, index) => {
            if (!item.hidden) {
              this.spanLength += !item.span || item.type === 'date' ? 6 : item.span
            }
            if (this.spanLength < 24 * this.row) {
              this.firstIndex = index
            }
          })
          this.getHideData()
        },
        deep: true,
      },
    },

    data() {
      return {
        dayjs: dayjs,
        form: {},
        expandType: false,
        itemList: [],
        firstIndex: 0, // 记录第一行展示的下标
        spanLength: 0,
        hideFormList: [],
        dateList: [
          {
            name: '今',
            type: 'today',
          },
          {
            name: '昨',
            type: 'yesterday',
          },
          // {
          //   name: '近7天',
          //   type: 'week',
          // },
          // {
          //   name: '近30天',
          //   type: 'month',
          // },
        ],
        formItemListAssgin: [],
        searchMoreForm: {},
      }
    },
    mounted() {
      // console.log(this.$attrs)
      this.formItemListAssgin = this.$lodash.cloneDeep(this.formItemList)
      // 根据span的总数判断第一行所展示的个数
      this.spanLength = 0
      // 获取初始化this.form数据
      const formKey = {}
      this.formItemList.forEach((item, index) => {
        this.spanLength += !item.span || item.type === 'date' ? 6 : item.span
        if (['range', 'monthrange'].includes(item.type)) {
          formKey[item.model] = item.initialValue || undefined
          formKey[item.maxModel] = item.maxInitialValue || undefined
        } else if (item.type === 'inputSearch') {
          formKey[item.model] = item.initialValue || null
          formKey[item.attrs.model] = item.attrs.initialValue || null
        } else {
          formKey[item.model] = item.initialValue || null
        }
        if (this.spanLength < 24 * this.row) {
          this.firstIndex = index
        }
        if (item?.isLoadMore) this.handleSelectOptions(item)
      })
      this.form = formKey
      this.getHideData()
    },
    methods: {
      // input 搜索
      async querySearch(queryString, cb, item) {
        if (queryString.trim().length < 3) return
        try {
          const { data } = await item.api({ searchKey: queryString })
          cb(data)
          console.log(data)
        } catch (e) {
          console.log(6)
          cb([])
        }
      },
      // 选择
      handleSelect(value, item) {
        this.form[item.model] = value.skuCode
      },
      handleSet() {
        this.$refs.setFormItem.show()
      },
      syncFormItemList() {
        this.spanLength = 0
        // 获取初始化this.form数据
        const formKey = {}
        this.formItemList.forEach((item, index) => {
          this.spanLength += !item.span || item.type === 'date' ? 6 : item.span
          if (['range', 'monthrange'].includes(item.type)) {
            formKey[item.model] = item.initialValue || undefined
            formKey[item.maxModel] = item.maxInitialValue || undefined
          } else if (item.type === 'inputSearch') {
            formKey[item.model] = item.initialValue || null
            formKey[item.attrs.model] = item.attrs.initialValue || null
          } else {
            formKey[item.model] = item.initialValue || null
          }
          if (this.spanLength < 24 * this.row) {
            this.firstIndex = index
          }
        })
        this.form = formKey
        this.getHideData()
      },
      treeCheckedNodes(model) {
        return this.formItemListAssgin?.find((itemAssign) => itemAssign.model === model)?.options?.checkedNodes || []
      },
      selectClear(data) {
        const { model, label, type, options } = data
        this.form[model] = null
        const tag = {
          key: model,
          label,
          type,
          value: options,
        }
        this.closeTag(tag)
      },
      handleSearch() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.form?.btnType && delete this.form.btnType
            this.spreadData(this.form)
            if (this.isShowFooterTag) this.expandType = false
            this.getHideData()
          }
        })
      },
      // 时间快捷选择
      createDate(time, item) {
        const date = new Date()
        if (time.type === 'today') {
          this.form[item.model] = [
            dayjs(dayjs(date.getTime()).format('YYYY-MM-DD') + '00:00:00').valueOf(),
            dayjs(dayjs(date.getTime()).format('YYYY-MM-DD') + '00:00:00').valueOf(),
          ]
        } else if (time.type === 'yesterday') {
          this.form[item.model] = [
            dayjs(dayjs(date.getTime() - 3600 * 1000 * 24).format('YYYY-MM-DD') + '00:00:00').valueOf(),
            dayjs(dayjs(date.getTime() - 3600 * 1000 * 24).format('YYYY-MM-DD') + '00:00:00').valueOf(),
          ]
        } else if (time.type === 'week') {
          this.form[item.model] = [
            dayjs(dayjs(date.getTime() - 7 * 3600 * 1000 * 24).format('YYYY-MM-DD') + '00:00:00').valueOf(),
            dayjs(dayjs(date.getTime()).format('YYYY-MM-DD') + '00:00:00').valueOf(),
          ]
        } else if (time.type === 'month') {
          this.form[item.model] = [
            dayjs(dayjs(date.getTime() - 30 * 3600 * 1000 * 24).format('YYYY-MM-DD') + '00:00:00').valueOf(),
            dayjs(dayjs(date.getTime()).format('YYYY-MM-DD') + '00:00:00').valueOf(),
          ]
        }
      },
      // select 选择后触发
      selectChange(value, item) {
        this.form?.btnType && delete this.form.btnType
        if (item.change) {
          item.change(value, item)
        }
      },
      // 日期范围选择器change方法触发
      dateChange(date, item, range) {
        this.form?.btnType && delete this.form.btnType
        const { model, label, type } = item
        if (!date && !range) {
          const tag = {
            key: model,
            label,
            type,
          }
          this.closeTag(tag)
        }
        if (!range && item.change) item.change(date, item)
        if (range && item.change) item.change({ date, range }, item)
        /**
         * 当外部没有绑定事件时, 内部调用查询, 反之, 则走外部调用
         * isDateChangeSearch： 是否date框变化时, 直接查询, 默认: 是
         */
        if (this.isDateChangeSearch && !item?.change) this.handleSearch()
      },
      // 切换label
      handleCommand(data, row) {
        this.formItemList.forEach((item, index) => {
          if (item.dropdownType === row.dropdownType) {
            row.attrs.options.forEach((itemRow) => {
              if (itemRow.model === data) {
                this.$set(this.formItemList, index, {
                  ...row,
                  ...itemRow,
                })
              } else {
                this.form[itemRow.model] = null
              }
            })
          }
        })
        this.getHideData()
      },
      // 清除单条隐藏数据
      closeTag(tag) {
        this.hideFormList = this.hideFormList.filter((item) => item.key !== tag.key)
        if (this.searchType === 'normal') {
          if (tag.value) {
            this.resetForm(tag)
          }
        } else {
          this.searchMoreForm.mainList.forEach((item, index) => {
            if (item.field === tag.key) {
              this.searchMoreForm.mainList.splice(index, 1)
            }
          })
          this.searchMoreForm.childList.forEach((item, index) => {
            if (item.field === tag.key) {
              this.searchMoreForm.mainList.splice(index, 1)
            }
          })
          this.$emit('handleSearchMore', { ...this.searchMoreForm, page: 1 })
        }
      },
      // 单条清除数据后重置form数据
      resetForm(tag) {
        Object.keys(this.form).forEach((item) => {
          if (item === tag.key) {
            if (typeof this.form[item] === 'string') {
              this.$set(this.form, item, null)
            } else if (this.form[item] instanceof Array) {
              this.$set(this.form, item, [])
            } else {
              this.$set(this.form, item, null)
            }
            if (tag.type === 'inputSearch') {
              this.$set(this.form, item, null)
              this.$set(this.form, tag.attrsModel, null)
            }
            if (tag.type === 'selectTree') {
              this.formItemListAssgin.find((el) => el.model === item).options.checkedNodes = []
              this.$set(this.form, item, null)
              this.$refs['treeOption'][0].$refs['tree'].setCheckedNodes([])
            }
          }
        })
        this.spreadData(this.form)
      },
      // 更多筛选点击事件,根据记录的下标去截取和拼接itemList
      openForm() {
        this.expandType = !this.expandType
        this.$emit('isToggleForm', this.expandType)
        // this.getHideData()
      },
      // 重置表单数据
      handleReset() {
        if (this.searchType === 'normal') {
          const formKey = {}
          // 需特别处理的字段
          const specialType = ['range', 'inputSearch', 'selectTree', 'yearAndmonth']
          this.formItemList.forEach((item) => {
            if (specialType.includes(item.type)) {
              const typeObj = {
                range: () => {
                  formKey[item.model] = item.initialValue || undefined
                  formKey[item.maxModel] = item.maxInitialValue || undefined
                },
                monthrange: () => {
                  formKey[item.model] = item.initialValue || undefined
                  formKey[item.maxModel] = item.maxInitialValue || undefined
                },
                inputSearch: () => {
                  formKey[item.model] = item.initialValue || null
                  formKey[item.attrs.model] = item.attrs.initialValue || null
                },
                selectTree: () => {
                  formKey[item.model] = item.initialValue || null
                  this.formItemListAssgin.find((el) => el.model === item.model).options.checkedNodes = []
                  this.$refs['treeOption'][0].$refs['tree'].setCheckedNodes([])
                },
                yearAndmonth: () => {
                  formKey[item.selectmodel] = item.initialValue || null
                  formKey[item.model] = item.initialValue || null
                },
              }
              typeObj[item.type]()
            } else {
              formKey[item.model] = item.initialValue || null
            }
          })
          this.hideFormList = []
          this.form = formKey
          // 重置选项值
          for (let item in this.form) {
            if (typeof this.form[item] === 'string') {
              this.$set(this.form, item, null)
            } else if (this.form[item] instanceof Array) {
              this.$set(this.form, item, [])
            } else {
              this.$set(this.form, item, null)
            }
          }
          this.form = {
            ...this.form,
            btnType: 'reset',
          }
          this.spreadData(this.form)
        } else {
          this.hideFormList = []
          this.$emit('getSearchFormData', { mainList: [], childList: [], page: 1, size: 20 })
        }
      },
      rangeBlur(type, item) {
        if (type === 'min') {
          if (this.form[item.maxModel] && this.form[item.model] > this.form[item.maxModel]) {
            this.$message.warning(`${item.label} 最小值不能大于最大值`)
          }
        } else {
          if (this.form[item.model] && this.form[item.model] > this.form[item.maxModel]) {
            this.$message.warning(`${item.label} 最大值不能小于最小值`)
          }
        }
      },
      // 向父组件传递处理好的数据
      spreadData(data) {
        return new Promise((resolve, reject) => {
          const dataObj = JSON.parse(JSON.stringify(data))
          this.formItemList.forEach((item) => {
            Object.keys(dataObj).forEach((key) => {
              if (item.turnKey && item.model === key) {
                if (item.type === 'date' && item.attrs.valueFormat === 'timestamp') {
                  dataObj[item.attrs.begin] = (dataObj[key] && dataObj[key][0]) || null
                  dataObj[item.attrs.end] =
                    dataObj[key] && dataObj[key][1]
                      ? this.dayjs(this.dayjs(dataObj[key][1]).format('YYYY-MM-DD') + ' 23:59:59').valueOf()
                      : null
                } else if (item.type === 'date' && item.attrs.valueFormat === 'yyyy-MM-dd') {
                  dataObj[item.attrs.begin] = (dataObj[key] && dataObj[key][0]) || null
                  dataObj[item.attrs.end] = dataObj[key] && dataObj[key][1] ? dataObj[key][1] + '23:59:59' : null
                } else if (item.type === 'date' && item.attrs.valueFormat === 'yyyy-MM-dd HH:mm:ss') {
                  dataObj[item.attrs.begin] = (dataObj[key] && dataObj[key][0]) || null
                  dataObj[item.attrs.end] = (dataObj[key] && dataObj[key][1]) || null
                }
                delete dataObj[key]
              } else if (item.turnKey && item.model === key && dataObj[key].length === 0) {
                delete dataObj[key]
              } else if (item.turnLabel && item.model === key) {
                item.attrs.options.forEach((drop) => {
                  if (drop.model !== item.model) {
                    dataObj[drop.model] = null
                    // delete dataObj[drop.model]
                  }
                })
              }
            })
          })
          this.$refs.form.validate((valid) => {
            if (valid) {
              resolve(dataObj)
              this.$emit('getSearchFormData', dataObj)
            }
          })
        })
      },
      // 树形选项
      filterTreeNode(value, data) {
        if (!value) return true
        return data.name.indexOf(value) !== -1
      },
      // 树形选项change监听
      checkChangeTree(checkedNodesParam, checkedKeysParam, halfCheckedNodes, halfCheckedKeys, itemRow) {
        if (!checkedKeysParam?.checkedKeys) {
          this.form[itemRow.model] = null
          this.formItemListAssgin.find((item) => item.model === itemRow.model).options.checkedNodes = []
          this.$refs['treeOption'][0].$refs['tree'].setCheckedNodes([])
          // 点击清除icon去走一遍查询方法
          if (checkedKeysParam === null) this.handleSearch()
          return
        }
        const { checkedNodes } = checkedKeysParam
        this.handleCombinationVal(checkedNodes, itemRow).then((res) => {
          this.formItemListAssgin.find((item) => {
            if (item.model === itemRow.model) {
              item.options.checkedNodes = res.map((item) => item.name)
            }
          })
          /**
           * needLevelType: 树形数据取值层级【取值范围: LEVEL1; LEVEL2; LEVEL3; LEVEL4】
           * 可在原来的options进行配置:
           * options：{
           *  ...
           *  needLevelType: 'LEVEL4',
           *  ...
           * }
           */
          if (itemRow?.options?.needLevelType) {
            const needLevelTypeData = res.map((item) => item.id)
            this.$set(this.form, itemRow.model, needLevelTypeData)
          } else this.$set(this.form, itemRow.model, checkedKeysParam.checkedKeys)
        })
      },
      // 递归数据 组合code-name
      handleCombinationVal(data, itemRow) {
        return new Promise((resolve, reject) => {
          const combinationObj = []
          // if (data?.length > 0) {
          data.map((item) => {
            if (itemRow?.options?.needLevelType === item.categoryLevel) combinationObj.push(item)
            if (!itemRow?.options?.needLevelType && (!item.children || item.categoryLevel === 'LEVEL1'))
              combinationObj.push(item)
            if (item?.children?.length > 0) this.handleCombinationVal(item.children, itemRow)
          })
          resolve(combinationObj)
          // }
        })
      },
      // 树形select选项RemoveTag
      handleRemoveTag(event, item) {
        if (item.type === 'selectTree') {
          this.form[item.model] = null
          this.$refs['treeOption'][0].$refs['tree'].setCheckedNodes([])
        }
      },
      // select下拉加载更多
      loadMore(e) {
        const formItemList = this.formItemList
        const currentSelectItem = formItemList.find((item) => item.model === e.model)
        if (!currentSelectItem?.attrs) return false
        if (currentSelectItem?.attrs?.finish) return false
        ++currentSelectItem.attrs.queryParams.page
        this.handleSelectOptions(e)
      },
      // select加载更多
      async handleSelectOptions(e, type) {
        const formItemList = this.formItemList
        const currentSelectItem = formItemList.find((item) => item.model === e.model)
        try {
          const paramsObj = {
            ...currentSelectItem.attrs.queryParams,
          }
          const { code, data, msg } = await currentSelectItem.attrs.api(paramsObj)
          if (code === 200) {
            const { items, total, page } = data
            const newItems = items.map((item) => {
              return {
                ...item,
                label: currentSelectItem.attrs.label
                  ? `${item[currentSelectItem.attrs.code]}-${item[currentSelectItem.attrs.label]}`
                  : `${item.shopCode}-${item.shopName}`,
                value: currentSelectItem.attrs.label ? `${item[currentSelectItem.attrs.code]}` : `${item.shopCode}`,
              }
            })
            if (page <= 1) {
              currentSelectItem.options = newItems
            } else {
              currentSelectItem.options = currentSelectItem.options.concat(newItems)
            }
            if (currentSelectItem.options.length === total) currentSelectItem.attrs.finish = true
          }
          if (code !== 200) return this.$baseMessage(msg, 'error')
        } catch (error) {
          throw new Error(error)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  ::v-deep {
    .advanced-btn {
      color: #252a3d;
      i {
        font-size: 12px;
      }
    }
  }
  .mg-search-form {
    overflow: unset !important;
    ::v-deep {
      .el-card__body {
        padding: 0;
      }
    }
  }
  .mg-search-formBorder {
    border: none;
  }
  .mg_date_picker {
    ::v-deep {
      .el-range-editor.el-input__inner {
        padding: 3px 30px 3px 10px;
      }
      .el-form-item__content {
        position: relative;
      }
    }
  }
  .customize_date {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    display: inline-block;
    width: 28px;
    height: 28px;
    overflow: hidden;
    line-height: 28px;
    background: #ffffff;
    border: 1px #1a252a3d solid;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    &:hover {
      width: 59px;
    }

    a {
      display: inline-block;
      width: 28px;
      height: 28px;
      color: #000000;
      text-align: center;
      &:nth-child(1) {
        float: right;
      }
      &:nth-child(2) {
        border-right: 1px #1a252a3d solid;
      }
      &:link {
        color: rgba(230, 0, 18, 1);
      }
      &:visited {
        color: rgba(230, 0, 18, 1);
      }
      &:hover {
        color: rgba(230, 0, 18, 1) !important;
      }
      &:active {
        color: rgba(230, 0, 18, 1);
      }
    }
  }
  .formSelectTreeOption {
    height: 100%;
    background: #fff;
    padding: 10px 0 !important;
  }
  ::v-deep {
    .advanced_button {
      border: none;
    }
    .el-tag.el-tag--info {
      color: rgba(#252a3d, 0.75);
      background-color: #ffffff;
    }
    .el-card__body {
      padding: 18px 12px 0px;
    }
    .input_number {
      width: 78px;
      .el-input--mini {
        width: 81px;
      }
    }
    .monthrange {
      display: flex;
      flex-wrap: nowrap;
    }
    .month_range {
      width: 132px;
      .el-input--mini {
        width: 81px;
      }
    }
    .el-row {
      display: flex;
      flex-wrap: wrap;
    }
    .el-input-group__append,
    .el-input-group__prepend {
      padding: 0 15px;
      text-align: center;
      i {
        transform: rotate(90deg);
      }
    }
    .el-button--small {
      min-width: auto;
    }
    .el-input-group__append,
    .el-input-group__prepend {
      font-size: 14px;
      font-weight: bold;
      color: #ea2838;
      background-color: #ffffff;
    }
    .el-form-item--mini.el-form-item {
      margin-bottom: 8px;
    }
    .el-range-separator {
      z-index: 1;
    }
  }

  .button_box {
    flex: 1;
    text-align: right;
    .button_position {
      display: flex;
      justify-content: flex-end;
      margin-left: 10px;
      ::v-deep {
        .el-button + .el-button {
          margin-left: 8px;
        }
      }
    }
  }
  .ul-more {
    padding: 0px;
    margin: 0;
    .el-dropdown-menu__item {
      font-size: 12px;
      color: rgba(37, 42, 61, 1);
    }
  }
  .width_150 {
    width: 250px;
  }

  .tag_box {
    display: flex;
    align-items: center;
    float: left;
    margin-left: 10px;
    margin-bottom: 4px;
    .selectTreeCon {
      max-width: 200px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .float_left {
    float: left;
  }
  .width_100 {
    width: 100% !important;
  }

  .mg-select-tree {
    width: 200px;
    display: inline-block;
    transition: 0.5s;
    padding: 20px;
    .select-param-input {
      width: 100%;
      cursor: pointer;
    }
    .mg-drop-down {
      border-radius: 3px;
      max-height: 200px;
      min-width: 200px;
      padding: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.2);
    }
    .mg-drop-down .drop-down-tree {
      overflow: auto;
    }
    .triangle {
      width: 0;
      height: 0;
      border-width: 0 8px 7px;
      border-style: solid;
      border-color: transparent transparent rgb(220, 223, 230);
      margin-left: 60px;
      margin-top: 3px;
      position: relative;
    }
    .triangle span {
      display: block;
      width: 0;
      height: 0;
      border-width: 0 7px 6px;
      border-style: solid;
      border-color: transparent transparent rgb(255, 255, 255);
      position: absolute;
      top: 1px;
      left: -7px;
    }
  }
</style>
