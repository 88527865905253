<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm">
        <el-form-item label="类型">
          <el-select v-model="queryForm.type" filterable default-first-option @change="() => {
            queryForm.code= undefined;
            handleFilter();
          }">
            <el-option v-for="item in options.resourceOptions" :key="item.value" :value="item.value" :label="item.tab" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='TASK'" label="分公司">
          <el-select v-model="queryForm.orgCode" filterable default-first-option clearable @change="(val) => handleResourceOrgChange(queryForm.type, 'query')">
            <el-option v-for="item in options.orgOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='TASK'" label="任务">
          <el-select v-model="queryForm.code" filterable default-first-option clearable>
            <el-option v-for="item in options.taskOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='HX'" label="核销名称">
          <el-select v-model="queryForm.code" filterable default-first-option>
            <el-option v-for="item in options.hxOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='QD_HX'" label="渠道核销">
          <el-select v-model="queryForm.code" filterable default-first-option>
            <el-option v-for="item in options.qdHxOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEditResource()">新增资源</el-button>
          <el-button class="form-btn-item" size="mini" type="danger" @click="handleDelAuthBatch">批量删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 450px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90" align="center"  />
      <el-table-column v-if="queryForm.type === 'TASK'" label="分公司编码/名称" prop="shop" width="100">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="resourceName" width="250" />
      <el-table-column label="是否授权" prop="auth" width="80">
        <template #default="{ row }">
          <el-switch v-model="row.auth" @change="(val) => {
                       submitAuth(row);
                     }"/>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" align="center">
        <template #default="{ row }">
          <div>
            <el-button size="mini" type="text" @click="handleAddUserResource(row)">添加用户</el-button>
            <el-button size="mini" type="text" @click="handleDelAuth(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog title="新增资源数据"  :visible.sync="editDialog.visible" append-to-body width="300px" :close-on-click-modal="false" @opened="onDialogOpened" ref="editDialogRef" tabindex="1" >
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="120px" :model="editDialog.form" class="common-form" @submit.native.prevent>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="类型">
              <el-select v-model="editDialog.form.resourceType" filterable default-first-option @change="() => {
                  editDialog.form.resourceCode= undefined;
                }">
                <el-option v-for="item in options.resourceOptions" :key="item.value" :value="item.value" :label="item.tab" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="是否授权" prop="auth">
              <el-radio-group v-model="editDialog.form.auth">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='TASK'" label="分公司">
              <el-select v-model="editDialog.form.orgCode" filterable default-first-option clearable @change="(val) => handleResourceOrgChange(editDialog.form.resourceType, 'edit')">
                <el-option v-for="item in options.orgOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='TASK'" label="任务" prop="resourceCode">
              <el-select v-model="editDialog.form.resourceCode" filterable default-first-option clearable>
                <el-option v-for="item in options.taskOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='HX'" label="核销名称" prop="resourceCode">
              <el-select v-model="editDialog.form.resourceCode" filterable default-first-option>
                <el-option v-for="item in options.hxOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='QD_HX'" label="渠道核销" prop="resourceCode">
              <el-select v-model="editDialog.form.resourceCode" filterable default-first-option>
                <el-option v-for="item in options.qdHxOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right" v-if="!editDialog.disabled">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="submitAuth(editDialog.form)">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增资源数据"  :visible.sync="userDialog.visible" append-to-body width="300px" :close-on-click-modal="false" @opened="onDialogOpened" ref="editDialogRef" tabindex="1" >
      <el-form ref="form" size="mini" inline :rules="userDialog.rules" label-width="120px" :model="userDialog.form" class="common-form" @submit.native.prevent>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="用户数据">
              <el-input v-model="userDialog.form.userName" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right" v-if="!editDialog.disabled">
        <el-button type="primary" size="mini" @click="userDialog.visible = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="submitBatchAuth(userDialog.form)">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="操作提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px;overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect,getAuthResourceOptions,pageResource,saveResource,delResource,userSelect, importUserAuth } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "AuthResource",
  components: { Pagination, DictSelect },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
        4: "primary",
        5: "primary",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      dictUserFun: userSelect,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        type: 'HX',
        orgCode: undefined,
        code: undefined,
      },
      deepClone: deepClone,
      queryDate: [],
      loading: false,
      dictParam: {},
      editDialog: {
        visible: false,
        disabled: false,
        type: 'edit',
        form: {
          resourceType: undefined,
          resourceCode: undefined,
          orgCode : undefined,
          auth: true,
        },
        options: {
          orgOptions: [],
          taskOptions: [],
          hxOptions: [],
          qdHxOptions: []
        },
        rules: {
          resourceCode: [{ required: true, message: "   ", trigger: "change" }],
        },
      },
      userDialog: {
        visible: false,
        row: {},
        form: {
          userName: undefined
        }
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      resourceDrawer: {
        visible: false,
      },
      options: {
        orgOptions: [],
        resourceOptions: [{ value: 'HX', label: '核销名称', tab: '核销'}, { value: 'TASK',label: '任务名称', tab: '任务'}, { value: 'QD_HX',label: '渠道核销', tab: '渠道核销'}],
        taskOptions: [],
        hxOptions: [],
        qdHxOptions: []
      },
      userRoleKeys: [],
      orgAuth: false,
      headAuth: false,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.options.orgOptions = res.data;
    })
    getAuthResourceOptions({ type: 'HX_ALL'}).then(res => {
      this.options.hxOptions = res.data
    })
    getAuthResourceOptions({ type: 'QD_HX_ALL'}).then(res => {
      this.options.qdHxOptions = res.data
    })
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      pageResource(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleResourceOrgChange(val, action) {
      this.editDialog.options.taskOptions = [];
      if (val && val === 'TASK') {
        let query = {};
        if (action === 'query') {
          query = deepClone(this.queryForm);
          query.type = query.type + '_ALL'
        } else {
          query = {
            type: this.editDialog.form.resourceType +'_ALL',
            orgCode: this.editDialog.form.orgCode
          }
        }
        getAuthResourceOptions(query).then(res => {
          this.options.taskOptions = res.data
        })
      }
    },
    handleEditResource(row) {
      if (row) {
        this.editDialog.form = deepClone(row);
        this.editDialog.type ='add';
      } else {
        this.editDialog.form = {
          resourceType: this.queryForm.type,
          resourceCode: undefined,
          orgCode: undefined,
          auth: true
        };
        this.editDialog.type ='edit';
      }
      this.editDialog.visible = true;
    },
    submitAuth(val) {
      if (!this.$refs.form) {
        this.$confirm('请确认是否' + (val.auth? '授权': '取消授权'))
          .then(() => {
            saveResource(val).then(res => {
              if (res.code === 200) {
                Notification.success("操作成功！");
                this.handleQuery();
                this.editDialog.visible = false;
              }
            })
          }).catch(() => {
            val.auth = !val.auth;
        })

      } else {
        this.$refs.form.validate(valid => {
          if (valid) {
            saveResource(val).then(res => {
              if (res.code === 200) {
                Notification.success("操作成功！");
                this.handleQuery();
                this.editDialog.visible = false;
              }
            })
          }
        })
      }
    },
    handleDelAuth(row) {
      this.$confirm('请确认是否删除该记录')
        .then(() => {
          delResource({list: [row]}).then(res => {
            this.editDialog.disabled = false;
            if(res && res.code == 200) {
              Notification.success("操作成功！");
              this.editDialog.visible = false;
              this.handleFilter();
            }
          })
        })
        .catch(() => {});

    },
    handleDelAuthBatch() {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少选择一条记录！");
        return;
      }
      let data = deepClone(this.pageData.selections);
      this.$confirm('请确认是否删除勾选的数据')
        .then(() => {
        this.editDialog.disabled = true;
        delResource({ list: data }).then(res => {
          this.editDialog.disabled = false;
          if(res && res.code == 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      });
    },
    onDialogOpened() {
      this.$nextTick(() =>{
        this.$refs.editDialogRef.$el.focus()
      })
    },
    handleAddUserResource(row) {
      this.userDialog.row = deepClone(row);
      this.userDialog.form.userName = undefined;
      this.userDialog.visible = true;
    },
    submitBatchAuth() {
      if (!this.userDialog.form.userName) {
        Notification.warning("添加的用户不能为空！");
        return;
      }
      const userNames = this.userDialog.form.userName.replaceAll('\r\n', ',').replaceAll('\n', ',').replaceAll('\r', ',').replaceAll(" ", ',').replaceAll('，', ',').split(',').filter(e => e);
      const data = {
        resourceType: this.userDialog.row.resourceType,
        resourceCode: this.userDialog.row.resourceCode,
        userNames: userNames
      }
      importUserAuth(data).then(res => {
        if(res && res.code == 200) {
          if (res.data) {
            this.noticeDialog.msg = res.data;
            this.noticeDialog.visible = true;
          } else {
            Notification.success("操作成功！");
            this.handleFilter();
            this.userDialog.visible = false;
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
::v-deep .checked-class .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: red;
}
</style>
