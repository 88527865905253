var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品编码" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "商品编码" },
                    model: {
                      value: _vm.queryForm.skuCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuCode", $$v)
                      },
                      expression: "queryForm.skuCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品型号" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "商品型号" },
                    model: {
                      value: _vm.queryForm.skuModel,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuModel", $$v)
                      },
                      expression: "queryForm.skuModel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SPU名称" } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", placeholder: "SPU名称" },
                    model: {
                      value: _vm.queryForm.spuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "spuName", $$v)
                      },
                      expression: "queryForm.spuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出商品")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.openAdd },
                    },
                    [_vm._v("添加商品")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "商品编码", prop: "skuCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "SKU名称", prop: "skuName", width: "300" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品型号", prop: "skuModel", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "SPU名称", prop: "spuName" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          [
                            _vm.orgAllAuth || row.orgCode !== "ALL"
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0px" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          pageSizes: [10, 20, 50, 100, 200],
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加商品",
            visible: _vm.addDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addDialog",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.addDialog.rules,
                "label-width": "160px",
                model: _vm.addDialog.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品编码",
                            prop: "skuCode",
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              placeholder: "请输入商品编码",
                            },
                            model: {
                              value: _vm.addDialog.form.skuCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.addDialog.form, "skuCode", $$v)
                              },
                              expression: "addDialog.form.skuCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitAddSku },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.addDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }