import dayjs from 'dayjs';
import NP from 'number-precision'
import { getCurrentShortYM, getPlanShortYM } from "@/utils";

/**
 * -------------------------------- 分公司智能月单 -- 月单定制- 首页 -- 开始 --------------------------------
 */
const monthlyOrderCustomizedColumns = [
    { label: '序号', type: 'index', showOverflowTooltip: true, minWidth: '60px', fixed: 'left', align: 'center' },
    { label: '账期', prop: 'month', slot: 'month', showOverflowTooltip: true, minWidth: '60px', align: 'center' },
    { label: '状态', prop: 'flowStatus', slot: 'flowStatus', showOverflowTooltip: true, minWidth: '60px', align: 'center' },
    { label: '定制开始日期', prop: 'customStartTime', slot: 'customStartTime', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '抛单日期', prop: 'throwOrderTime', slot: 'throwOrderTime', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '抛单操作人', prop: 'throwOrderCreator', slot: 'throwOrderCreator', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
];


/**
 * -------------------------------- 分公司智能月单 -- 月单定制- 首页 -- 结束 --------------------------------
 */



/**
 * -------------------------------- 分公司智能月单 -- 加单预警 -- 开始 --------------------------------
 */

 const currentMonth = NP.plus(dayjs().month(), 1)
 const nextOneMonth = NP.plus(dayjs().month(), 2)
 const nextTwoMonth = NP.plus(dayjs().month(), 3)
const addOrderWarningColumns = [
    { label: '序号', type: 'index', showOverflowTooltip: true, minWidth: '50px', align: 'center', fixed: 'left' },
    { label: '分公司名称', showOverflowTooltip: true, prop: 'companyName', minWidth: '120px', fixed: 'left' },
    { label: '库存状态', showOverflowTooltip: true, prop: 'kcStatus', minWidth: '90px', fixed: 'left' },
    { label: '加单状态', showOverflowTooltip: true, prop: 'addOrderStatus', minWidth: '90px', fixed: 'left' },
    { label: '品名', showOverflowTooltip: true, prop: 'skuName', minWidth: '240px', fixed: 'left' },
    { label: '货号', showOverflowTooltip: true, prop: 'cargoNo', minWidth: '120px' },
    { label: '进货单价', showOverflowTooltip: true, prop: 'purchasePrice', minWidth: '120px' },
    { label: '总部等级', showOverflowTooltip: true, prop: 'grade', minWidth: '120px' },
    { label: '分公司等级', showOverflowTooltip: true, prop: 'boGrade', minWidth: '120px' },
    { label: `上6月月均销量`, showOverflowTooltip: true, prop: 'boLastSixMthSaleCountAvg', minWidth: '150px' },
    { label: '分公司当前库存', showOverflowTooltip: true, prop: 'boCurrKcCount', minWidth: '120px' },
    { label: '当天在途', showOverflowTooltip: true, prop: 'boCurrZtCount', minWidth: '120px' },
    { label: '已配送未记账', showOverflowTooltip: true, prop: 'boThisMthPsNojz', minWidth: '120px' },
    { label: `${ currentMonth }月欠量未发`, prop: 'boThisMthQlCount', minWidth: '120px' },
    { label: `${ currentMonth }月已销量`, prop: 'boCurrSaleCount', minWidth: '120px' },
    { label: `${ currentMonth }月待销量`, prop: 'boCurrPendingSaleCount', minWidth: '120px' },
    { label: `${ currentMonth }月预计库存`, prop: 'boThisMthExpectKcCount', minWidth: '120px' },
    { label: `${ currentMonth }月预计库存系数`, prop: 'boThisMthExpectKcRatio', minWidth: '130px' },
    { label: '可提前欠量', prop: 'boQlInAdvance', minWidth: '120px' },
    { label: '补单量', prop: 'boSupplyCount', minWidth: '120px' },
    { label: '补单箱数', prop: 'boSupplyBoxCount', minWidth: '120px' },
    { label: '安全库存系数', prop: 'safeStockRatio', minWidth: '120px' },
    { label: '自动补单系数', prop: 'autoSupplyRatio', minWidth: '120px' },
    { label: `${ currentMonth }月底预计库存`, prop: 'boThisMthExpectKcCountAfterSupply', minWidth: '120px' },
    { label: `${ currentMonth }月底预计库存系数`, prop: 'boThisMthExpectKcRatioAfterSupply', minWidth: '150px' },

    { label: `${ nextOneMonth }月欠量未发`, prop: 'boNextMthQlCount', minWidth: '120px' },
    { label: `${ nextOneMonth }月预估销量`, prop: 'boNextMthExceptSaleCount', minWidth: '120px' },
    { label: `${ nextOneMonth }月底预计库存`, prop: 'boNextMthExpectKcCount', minWidth: '120px' },
    { label: `${ nextOneMonth }月底预计库存系数`, prop: 'boNextMthExpectKcRatio', minWidth: '150px' },

    { label: `${ nextTwoMonth }月欠量未发`, prop: 'boNextTwoMthQlCount', minWidth: '120px' },
    { label: `${ nextTwoMonth }月预估销量`, prop: 'boNextTwoMthExceptSaleCount', minWidth: '120px' },
    { label: `${ nextTwoMonth }月底预计库存`, prop: 'boNextTwoMthExpectKcCount', minWidth: '120px' },
    { label: `${ nextTwoMonth }月底预计库存系数`, prop: 'boNextTwoMthExpectKcRatio', minWidth: '150px' },
    { label: `操作`, prop: 'operate', slot: 'operate', fixed: 'right', minWidth: '120px' },
    /**
     * 在迭代: http://192.168.10.155:1086/task-view-2297.html
     * 中, 附带去掉一下几项
     */
    // { label: '赛道编码', prop: 'raceCode', minWidth: '120px' },
    { label: '赛道名称', prop: 'raceName', minWidth: '120px' },
    // { label: '大类编码', prop: 'mainCategoryCode', minWidth: '120px' },
    { label: '大类名称', prop: 'mainCategoryName', minWidth: '120px' },
    // { label: '中类编码', prop: 'midCategoryCode', minWidth: '120px' },
    { label: '中类名称', prop: 'midCategoryName', minWidth: '120px' },
    // { label: '小类编码', prop: 'subCategoryCode', minWidth: '120px' },
    { label: '小类名称', prop: 'subCategoryName', minWidth: '120px' },
    { label: '统计品类', prop: 'statsCategory', minWidth: '120px' },
    { label: '商品编码', prop: 'skuCode', minWidth: '120px' },
    { label: '型号', prop: 'skuMnemonicCode', minWidth: '120px' },
    { label: '料号', prop: 'materialCode', minWidth: '120px' },
    { label: '箱规', prop: 'bottomSpec', minWidth: '120px' },
    { label: '生产类型', prop: 'supplyCenter', minWidth: '120px' },
];
/**
 * -------------------------------- 分公司智能月单 -- 加单预警 -- 结束 --------------------------------
 */

/**
 * -------------------------------- 分公司智能月单 -- 库存设定 -- 开始 --------------------------------
 */
const inventorySettingColumns = {
    treeColumns: [
        { label: '序号', type: 'index', showOverflowTooltip: true, minWidth: '65px', fixed: 'left' },
        { label: '赛道', prop: 'raceName', showOverflowTooltip: true, minWidth: '80px' },
        { label: '大类', prop: 'mainCategoryName', showOverflowTooltip: true, minWidth: '80px' },
        { label: '中类', prop: 'midCategoryName', showOverflowTooltip: true, minWidth: '80px' },
        { label: '统计品类', prop: 'statsCategoryName', showOverflowTooltip: true, minWidth: '110px' },
        {
            label: `${ getCurrentShortYM() }月品类库存系数`,
            headerCellClassName: 'oddHeaderCellColspan',
            children: [
                // 当月品类库存系数
                { label: 'S', prop: 'currentStoreRatioS', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'A+', prop: 'currentStoreRatioAPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'A', prop: 'currentStoreRatioA', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'B+', prop: 'currentStoreRatioBPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'B', prop: 'currentStoreRatioB', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C+', prop: 'currentStoreRatioCPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C', prop: 'currentStoreRatioC', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C-', prop: 'currentStoreRatioCMinus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'D', prop: 'currentStoreRatioD', showOverflowTooltip: true, minWidth: '50px' },
            ]
        },
        {
            label: `${ getPlanShortYM() }月品类库存系数(根据设定值计算)`,
            headerCellClassName: 'oddHeaderCellColspan',
            children: [
                // 次月(计划月)品类库存系数(根据设定值计算)
                { label: 'S', prop: 'planMonthStoreRatioS', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'A+', prop: 'planMonthStoreRatioAPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'A', prop: 'planMonthStoreRatioA', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'B+', prop: 'planMonthStoreRatioBPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'B', prop: 'planMonthStoreRatioB', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C+', prop: 'planMonthStoreRatioCPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C', prop: 'planMonthStoreRatioC', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C-', prop: 'planMonthStoreRatioCMinus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'D', prop: 'planMonthStoreRatioD', showOverflowTooltip: true, minWidth: '50px' },
            ]
        },
        {
            label: `${ getPlanShortYM() }月品类目标设定系数`,
            children: [
                // 次月(计划月)品类目标设定系数
                { label: 'S', prop: 'storeRatioAimS', slot: 'storeRatioAimS', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'A+', prop: 'storeRatioAimAPlus', slot: 'storeRatioAimAPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'A', prop: 'storeRatioAimA', slot: 'storeRatioAimA', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'B+', prop: 'storeRatioAimBPlus', slot: 'storeRatioAimBPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'B', prop: 'storeRatioAimB', slot: 'storeRatioAimB', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C+', prop: 'storeRatioAimCPlus', slot: 'storeRatioAimCPlus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C', prop: 'storeRatioAimC', slot: 'storeRatioAimC', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'C-', prop: 'storeRatioAimCMinus', showOverflowTooltip: true, minWidth: '50px' },
                { label: 'D', prop: 'storeRatioAimD', showOverflowTooltip: true, minWidth: '50px' },
            ]
        }
    ],
    columns: [
        { label: '赛道', prop: 'raceName', showOverflowTooltip: true, minWidth: '80px' },
        { label: '大类', prop: 'mainCategoryName', showOverflowTooltip: true, minWidth: '80px' },
        { label: '中类', prop: 'midCategoryName', showOverflowTooltip: true, minWidth: '80px' },
        { label: '统计品类', prop: 'statsCategoryName', showOverflowTooltip: true, minWidth: '110px' },
        // 当月品类库存系数
        { label: 'S', prop: 'currentStoreRatioS', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'A+', prop: 'currentStoreRatioAPlus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'A', prop: 'currentStoreRatioA', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'B+', prop: 'currentStoreRatioBPlus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'B', prop: 'currentStoreRatioB', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'C+', prop: 'currentStoreRatioCPlus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'C', prop: 'currentStoreRatioC', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'C-', prop: 'currentStoreRatioCMinus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'D', prop: 'currentStoreRatioD', showOverflowTooltip: true, minWidth: '50px' },
        // 次月(计划月)品类库存系数(根据设定值计算)
        { label: 'S', prop: 'planMonthStoreRatioS', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'A+', prop: 'planMonthStoreRatioAPlus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'A', prop: 'planMonthStoreRatioA', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'B+', prop: 'planMonthStoreRatioBPlus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'B', prop: 'planMonthStoreRatioB', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'C+', prop: 'planMonthStoreRatioCPlus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'C', prop: 'planMonthStoreRatioC', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'C-', prop: 'planMonthStoreRatioCMinus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'D', prop: 'planMonthStoreRatioD', showOverflowTooltip: true, minWidth: '50px' },
        // 次月(计划月)品类目标设定系数
        { label: 'S', prop: 'storeRatioAimS', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'A+', prop: 'storeRatioAimAPlus', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'A', prop: 'storeRatioAimA', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'B+', prop: 'storeRatioAimBPlus', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'B', prop: 'storeRatioAimB', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'C+', prop: 'storeRatioAimCPlus', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'C', prop: 'storeRatioAimC', showOverflowTooltip: true, minWidth: '90px' },
        { label: 'C-', prop: 'storeRatioAimCMinus', showOverflowTooltip: true, minWidth: '50px' },
        { label: 'D', prop: 'storeRatioAimD', showOverflowTooltip: true, minWidth: '50px' },
    
    ]
}
/**
 * -------------------------------- 分公司智能月单 -- 库存设定 -- 结束 --------------------------------
 */

/**
 * -------------------------------- 分公司智能月单 -- 要货确认 -- 开始 --------------------------------
 */
 let purchasePreMonths = [];
 const currentDay = dayjs().format('DD');
 for (let i = 1; i < 14; i++) {
     let month = '';
     // 每月一号, 往前多减一个月
     if (currentDay === '01') month = dayjs().subtract(NP.plus(i, 1), 'month').format('YYYY年MM月')
     else month = dayjs().subtract(i, 'month').format('YY.MM')
     purchasePreMonths.push(month)
 }
const purchaseConfirmationColumns = [
    { label: '序号', type: 'seq', width: 50, align: 'center', fixed: 'left' },
    { label: '货号', prop: 'cargoNo', showOverflowTooltip: true, minWidth: '100px', align: 'center', fixed: 'left' },
    
    // 一期 暂时不做 { label: '状态', prop: '', showOverflowTooltip: true, minWidth: '100px' },

    { label: '品名', prop: 'skuName', showOverflowTooltip: true, minWidth: '100px', align: 'left' },
    { label: '进货单价', prop: 'price', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '是否当季商品', prop: 'isSingleSeasonSku', showOverflowTooltip: true, minWidth: '100px', align: 'center' },


    { label: '近3月伙伴进货家数', prop: 'boThreeMthPartnerCount', showOverflowTooltip: true, minWidth: '130px', align: 'center' },
    { label: '近3月订单执行率', prop: 'boThreeMthOrderRate', showOverflowTooltip: true, minWidth: '120px', align: 'center' },
    { label: `${ currentMonth }月订单执行率`, prop: 'boThisMthOrderRate', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: '未动销天数', prop: 'boNoSaleDays', slot: 'boNoSaleDays', showOverflowTooltip: true, minWidth: '100px', align: 'center' },

    { label: '总部等级', prop: 'grade', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '分公司等级', prop: 'companyGrade', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '近6月月均销量', prop: 'boLastSixMthSaleCountAvg', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: '分公司当前库存', prop: 'boCurrKcCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: '当天在途', prop: 'boCurrZtCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },

    { label: '已配送未记账', prop: 'boThisMthPsNojz', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    
    { label: `${ currentMonth }月欠量未发`, prop: 'boThisMthQlCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ currentMonth }月已销量`, prop: 'boCurrSaleCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ currentMonth }月待销量`, prop: 'boForSaleCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ currentMonth }月底预计库存`, prop: 'boThisMthExpectKcCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ currentMonth }月底预计库存系数`, prop: 'boThisMthExpectKcRatio', showOverflowTooltip: true, minWidth: '130px', align: 'center' },

    { label: `${ nextOneMonth }月预估销量`, prop: 'boNextMthExceptSaleCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `去年${ nextOneMonth }月销量`, prop: 'boLastYearSamePeriodSaleCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月已有欠量`, prop: 'boNextMthQlCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月理论要货量`, prop: 'boNextMthTheoryOrderCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    
    { label: `${ nextOneMonth }月确认要货量`, prop: 'boNextMthConfirmOrderCount', slot: 'boNextMthConfirmOrderCount', showOverflowTooltip: false, minWidth: '110px', align: 'center' },

    { label: `${ nextOneMonth }月底预计库存`, prop: 'boNextMthExpectKcCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ nextOneMonth }月底预计库存系数`, prop: 'boNextMthExpectKcRatio', showOverflowTooltip: true, minWidth: '130px', align: 'center' },
    { label: `${ nextTwoMonth }月预估销量`, prop: 'boNextTwoMthExceptSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `去年${ nextTwoMonth }月销量`, prop: 'boLastYearNextSamePeriodSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ nextTwoMonth }月已有欠量`, prop: 'boNextTwoMthQlCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ nextTwoMonth }月理论要货量`, prop: 'boNextTwoMthTheoryOrderCount', showOverflowTooltip: true, minWidth: '120px', align: 'center' },
    
    { label: `${ nextTwoMonth }月确认要货量`, prop: 'boNextTwoMthConfirmOrderCount', slot: 'boNextTwoMthConfirmOrderCount', showOverflowTooltip: false, minWidth: '120px', align: 'center' },
    { label: `${ nextTwoMonth }月底预计库存`, prop: 'boNextTwoMthExpectKcCount', showOverflowTooltip: true, minWidth: '120px', align: 'center' },
    { label: `${ nextTwoMonth }月底预计库存系数`, prop: 'boNextTwoMthExpectKcRatio', showOverflowTooltip: true, minWidth: '145px', align: 'center' },
    { label: '库存系数目标', prop: 'boKcRratioTarget', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '库存目标', prop: 'boKcTarget', showOverflowTooltip: true, minWidth: '100px', align: 'center' },

    { label: '活动标签', prop: 'activityLabel', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '活动开始时间', prop: 'activityStartTime', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '活动结束时间', prop: 'activityEndTime', showOverflowTooltip: true, minWidth: '100px', align: 'center' },

    { label: `${ purchasePreMonths[0] }销量`, prop: 'boLastMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[1] }销量`, prop: 'boLastTwoMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[2] }销量`, prop: 'boLastThreeMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[3] }销量`, prop: 'boLastFourMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[4] }销量`, prop: 'boLastFiveMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[5] }销量`, prop: 'boLastSixMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[6] }销量`, prop: 'boLastSevenMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[7] }销量`, prop: 'boLastEightMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[8] }销量`, prop: 'boLastNineMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[9] }销量`, prop: 'boLastTenMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[10] }销量`, prop: 'boLastElevenMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[11] }销量`, prop: 'boLastTwelveMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ purchasePreMonths[12] }销量`, prop: 'boLastThirteenMthSaleCount', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    
    { label: '赛道', prop: 'raceName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '大类', prop: 'mainCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '中类', prop: 'midCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '小类', prop: 'subCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '统计品类', prop: 'statsCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '商品编码', prop: 'skuCode', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '型号', prop: 'skuMnemonicCode', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '料号', prop: 'materialCode', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '箱规', prop: 'bottomSpec', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    // 一期 暂时不做 { label: '操作人', prop: '', showOverflowTooltip: true, minWidth: '100px' },
    { label: '要货年月', prop: 'month', showOverflowTooltip: true, minWidth: '100px', align: 'center' },

    { label: '近3个月进货终端数', showOverflowTooltip: true, prop: 'boThreeMthJhTmCount', minWidth: '120px' },
    { label: '直发欠量', showOverflowTooltip: true, prop: 'directQlCount', minWidth: '100px' },
    { label: '其他库存', showOverflowTooltip: true, prop: 'otherKcCount', minWidth: '100px' },
    { label: '检核异常类型', showOverflowTooltip: true, prop: 'exceptionTypes', slot: 'exceptionTypes', minWidth: '100px' },
    { label: '操作', prop: 'operate', slot: 'operate', minWidth: '100px', width: 100, fixed: 'right' }
]
const dialogColumns = [
    { label: '序号', type: 'index', showOverflowTooltip: true, minWidth: '60px', fixed: 'left', align: 'center' },
    { label: '货号', prop: 'cargoNo', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '品名', prop: 'skuName', showOverflowTooltip: true, minWidth: '180px', align: 'center' },
    { label: '分公司产品等级', prop: 'companyGrade', showOverflowTooltip: true, minWidth: '110px', align: 'center' },
    { label: `${ currentMonth }月底库存`, prop: 'boThisMthExpectKcCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月预估销量`, prop: 'boNextMthExceptSaleCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月已有欠量`, prop: 'boNextMthQlCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月要货量`, prop: 'boNextMthConfirmOrderCount', slot: 'boNextMthConfirmOrderCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月底库存`, prop: 'boNextMthExpectKcCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月底库存系数`, prop: 'boNextMthExpectKcRatio', showOverflowTooltip: true, minWidth: '120px', align: 'center' },
    { label: '异常类型', prop: 'exceptionTypes', slot: 'exceptionTypes', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
]
/**
 * -------------------------------- 分公司智能月单 -- 要货确认 -- 结束 --------------------------------
 */

/**
 * -------------------------------- 分公司智能月单 -- 商品配置中心 -- 开始 --------------------------------
 */
const configurationCenterColumns = [
    { label: '序号', type: 'index', showOverflowTooltip: true, minWidth: '60px', fixed: 'left', align: 'center' },
    { label: '分公司', prop: 'companyName', showOverflowTooltip: true, minWidth: '100px', fixed: 'left', align: 'center' },
    { label: '货号', prop: 'cargoNo', showOverflowTooltip: true, minWidth: '100px', fixed: 'left', align: 'center' },
    { label: '品名', prop: 'skuName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '总部产品等级', prop: 'headGrade', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '分公司产品等级', prop: 'branchGrade', slot: 'branchGrade', showOverflowTooltip: false, minWidth: '110px', align: 'center' },
    { label: '赛道', prop: 'raceName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '大类', prop: 'mainCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '中类', prop: 'midCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '小类', prop: 'subCategoryName', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '统计品类', prop: 'statsCategory', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '箱规', prop: 'bottomSpec', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '零售价', prop: 'price', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '商品编码', prop: 'skuCode', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '料号', prop: 'materialCode', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '其他库存', prop: 'otherKcCount', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: '安全库存系数', prop: 'safeStockRatio', slot: 'safeStockRatio', showOverflowTooltip: false, minWidth: '100px', align: 'center' },
    { label: '库存系数目标', prop: 'stockRatioAim', slot: 'stockRatioAim', showOverflowTooltip: false, minWidth: '100px', align: 'center' },
    { label: '库存系数上限', prop: 'stockRatioUp', slot: 'stockRatioUp', showOverflowTooltip: false, minWidth: '100px', align: 'center' },
    { label: '库存系数下限', prop: 'stockRatioDown', slot: 'stockRatioDown', showOverflowTooltip: false, minWidth: '100px', align: 'center' },
    { label: '年月', prop: 'thisMonth', showOverflowTooltip: true, minWidth: '100px', align: 'center' },
    { label: `${ currentMonth }月预计销售`, prop: 'thisMthExpectSaleCount', slot: 'thisMthExpectSaleCount', showOverflowTooltip: false, minWidth: '100px', align: 'center' },
    { label: `${ nextOneMonth }月预计销售`, prop: 'nextMthExpectSaleCount', slot: 'nextMthExpectSaleCount', showOverflowTooltip: false, minWidth: '100px', align: 'center' },
]
/**
 * -------------------------------- 分公司智能月单 -- 商品配置中心 -- 结束 --------------------------------
 */


export {
    monthlyOrderCustomizedColumns,  // 月单定制首页列表 -- 表头
    addOrderWarningColumns,         // 加单预警 -- 表头
    inventorySettingColumns,        // 库存设定 -- 表头
    purchaseConfirmationColumns,    // 要货确认 -- 表头
    dialogColumns,                  // 要货确认 -- 异常检核清单 -- 表头
    configurationCenterColumns,     // 商品配置中心 -- 表头
}