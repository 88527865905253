<template>
    <div>
 <el-card style="margin: 10px;">
  <el-tabs v-model="tabName" type="card">
    <el-tab-pane :lazy="true" name="after" label="合并后">
      <TopShop />
    </el-tab-pane>
    <el-tab-pane :lazy="true" name="before" label="合并前">
      <TopShop query-top="1" />
    </el-tab-pane>
  </el-tabs>
  </el-card>
</div>
</template>

<script>
import TopShop from '../topShop/index';

export default {
  name: "topNew",
  components: {TopShop},
  data() {
    return {
      tabName: 'after'
    }
  }
}
</script>

<style scoped>

</style>
