<template>
  <div class="configCenter-page">
    <div class="configCenter-search">
      <v-signboard :title="'产能上下限设置'"/>
      <el-form
        class="main-search"
        size="mini"
        label-width="auto"
        ref="filialeCustome-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleHeadAction('search')"
      >
        <el-form-item label="产线类型" size="mini">
          <el-select clearable v-model="form.product_line_type">
            <el-option label="机装" value="机装"></el-option>
            <el-option label="工艺大类" value="工艺大类"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="具体产线" size="mini">
          <el-input v-model="form.product_line" clearable placeholder="请输入具体产线"/>
        </el-form-item>

        <el-button
          class="form-btn-item"
          size="mini"
          type="primary"
          :loading="btnStatus.isSearch"
          @click="handleHeadAction('search')"
        >查 询
        </el-button>

        <el-button
          class="form-btn-item"
          size="mini"
          :loading="btnStatus.isReset"
          @click="handleHeadAction('reset')"
        >重 置
        </el-button>

        <el-button
          size="mini"
          type="primary"
          :loading="btnStatus.isExportLoading"
          @click="handleTableHeadAction('export')"
        >导 出
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="btnStatus.isSave"
          @click="handleHeadAction('save')">保 存
        </el-button>
        <el-button
          size="mini"
          @click="handleHeadAction('add')"
        >新 增
        </el-button>
        <el-button
          size="mini"
          type="warning"
          :disabled="btnStatus.isDelete"
          @click="handleHeadAction('delete')">删 除
        </el-button>
      </el-form>
    </div>
    <div class="configCenter-content">
      <el-table
        class="table"
        stripe
        border
        height="500"
        size="mini"
        ref="multipleTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <el-table-column label="序号" align="center" width="55" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.configCapacityHead">
          <!-- 工作日数 / 日总产能（万） -- 可编辑 -->
          <el-table-column v-if="['prod_percent_up', 'prod_percent_down'].includes(item.prop)" v-bind="item" :key="index" align="center">
            <template slot-scope="scope">
              <el-input
                class="tgqrcount-cell-ipt"
                v-if=" item.prop == 'prod_percent_up'"
                v-model="scope.row.prod_percent_up"
                size="mini"
                clearable
              />
              <div v-if=" item.prop == 'prod_percent_down'">
                <el-input
                  class="tgqrcount-cell-ipt"
                  type="number"
                  v-model="scope.row.prod_percent_down"
                  size="mini"
                  clearable
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>


    <!-- 新增产能上下限设置 -->
    <el-dialog
      class="add-dialog"
      title="新增产能上下限设置"
      width="650px"
      :visible.sync="dialogStatus.isAddConfig"
      :destroy-on-close="true"
    >
      <div class="dialog-content">
        <el-form
          ref="dialogForm"
          label-position="right"
          label-width="100px"
          size="mini"
          class="dialog-form"
          :model="dialogFormModel"
          :rules="dialogFormModelRules"
        >
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="具体产线" size="mini" prop="product_line_type">
                <el-select clearable v-model="dialogFormModel.product_line_type">
                  <el-option label="机装" value="机装"></el-option>
                  <el-option label="工艺大类" value="工艺大类"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item class="form-item-select" label="具体产线" size="mini" prop="product_line">
                <el-input v-model="dialogFormModel.product_line" placeholder="请填写具体产线" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="产能上限" size="mini" prop="prod_percent_up">
                <el-input v-input-number v-model="dialogFormModel.prod_percent_up" max="120" maxlength="3"
                          placeholder="请填写产能上限 (%)" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-form-item label="产能下限" size="mini" prop="prod_percent_down">
                <el-input v-input-number v-model="dialogFormModel.prod_percent_down" min="0" placeholder="请填写产能下限 (%)"
                          clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 操作按钮 -->
      <div class="footer" slot="footer">
        <el-button size="small" type="primary" @click="handleAddSubmit">保 存</el-button>
        <el-button size="small" @click="handleAddCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {excelExport} from "@/utils";
import {configCapacityHead} from '../config';
import {
  handleConfigCapacityCreate,
  handleConfigCapacityDelete,
  handleConfigCapacityPage,
  handleConfigCapacityUpdate
} from "../../../api/configCenter";

export default {
  // 产能上下限设置
  name: 'materialDimReport',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination"),
  },
  data() {
    const validateProdPercentUp = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写产能上限"));
      } else {
        if (parseInt(value) < 100 || parseInt(value) > 120) callback(new Error("产能上限在[100, 120]"));
        callback();
      }
    };
    const validateProdPercentDown = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写产能下限"));
      } else {
        if (parseInt(value) < 0 || parseInt(value) > 100) callback(new Error("产能下限在[0, 100)"));
        callback();
      }
    };

    let data = {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSearch: false,                // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 保存
        isDelete: true,                   // 删除
        isAdjust: true                  // 是否调整
      },
      dialogStatus: {
        isAddConfig: false,         // 是否新增产能上下限配置
      },
      lists: [],
      tableObj: {
        configCapacityHead
      },
      selectRows: [],
      urlMonth: '',                       // 月份,
      dialogFormModel: {
        product_line_type: '',
        product_line: '',
        prod_percent_up: '120',
        prod_percent_down: '60'
      },
      dialogFormModelRules: {
        product_line_type: [{required: true, message: '请选择产线类型', trigger: 'blur'}],
        prod_percent_up: [{required: true, validator: validateProdPercentUp, trigger: 'blur'}],
        prod_percent_down: [{required: true, validator: validateProdPercentDown, trigger: 'blur'}],
        product_line: [{required: true, message: '请填写具体产线', trigger: 'blur'}],
      }
    }
    console.log(data)
    return data
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
              __that.btnStatus.isDelete = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              __that.btnStatus.isDelete = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                __that.btnStatus.isDelete = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                __that.btnStatus.isDelete = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
              __that.btnStatus.isDelete = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isDelete = false;
          btnStatus.isAdjust = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isDelete = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      data_type: 1,
      ...this.form,
      product_line_type: '',
      product_line: '',
    };
    this.form = formPrams;
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  mounted() {
    this.originalForm = Object.assign({}, this.form);
  },
  methods: {
    // 返回
    handleBack() {
      this.$router.go(-1);
    },
    handleHeadAction(type) {
      const funcObj = {
        'add': () => {                  // 添加
          this.dialogFormModel = {
            check_month: '',
            production_unit: '',
            prod_percent_up: '120',
            prod_percent_down: '60',
            day_prod_count: '',
            work_day_count: '',
            month_prod_count: ''
          };
          this.dialogStatus.isAddConfig = true;
        },
        'save': async () => {            // 保存
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item);
          });
          const params = {
            operator: this.urlParams.operator,
            config_list: newItems
          };
          const {code, msg} = await handleConfigCapacityUpdate(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'delete': async () => {             // 删除
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item.id || '');
          });
          const params = {
            operator: this.urlParams.operator,
            config_id_list: newItems
          };
          const {code, msg} = await handleConfigCapacityDelete(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('删除成功');
          }
          if (code != '0000') this.getLists();
        },
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {                // 重置
          this.form = {
            product_line_type: '',
            product_line: '',
          };
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          product_line_type: form.product_line_type || '',
          product_line: form.product_line || '',
          operator: this.urlParams.operator,
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleConfigCapacityPage(params);
        if (data) {
          const {config_list, total} = data;
          this.modifyData(config_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取产能上下限设置列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          newItems.push(item);
        });
        console.log(newItems);
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.getLists();
    },
    // 全选函数
    async handleselection(e) {
      this.selectRows = e;
    },
    // 列表操作
    handleTableHeadAction(type) {
      const funcObj = {
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 新增注塑机产能提交
    handleAddSubmit() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.dialogFormModel,
            operator: this.urlParams.operator
          };
          const {code, success} = await handleConfigCapacityCreate(params);
          if (success) {
            this.dialogStatus.isAddConfig = false;
            this.getLists();
            return this.$message.success('新增成功');
          }
        } catch (err) {
          this.dialogStatus.isAddConfig = false;
          console.log(err, 'err------产能上下限配置接口新增有误');
        }
      })
    },
    // 取消新增
    handleAddCancel() {
      this.dialogStatus.isAddConfig = false;
    },
    // 导出数据
    async exportData() {
      const topHeaders = {}
      for (let i = 0; i < configCapacityHead.length; i++) {
        topHeaders[configCapacityHead[i].prop] = configCapacityHead[i].label
      }

      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          product_line : form.product_line || '',
          product_line_type : form.product_line_type,
          operator: this.urlParams.operator
        };
        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleConfigCapacityPage(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {config_list} = data;
          if (config_list.length > 0) {
            // for(let i = 0; i < config_list.length; i++) {
            //   let item = config_list[i];
            //   item['prod_percent_down_text'] = item['prod_percent_down'] + '%';
            //   item['prod_percent_up_text'] = item['prod_percent_up'] + '%';
            // }
            excelExport({
              topHeaders,
              data: config_list,
              title: '产能上下限设置列表'
            })
          }
          if (config_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------产能上下限设置列表导出接口有误');
      }
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/configCenter/layoutMain.scss'></style>
