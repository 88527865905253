var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticStyle: { margin: "10px" } },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { lazy: true, name: "after", label: "合并后" } },
                [_c("TopShop")],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { lazy: true, name: "before", label: "合并前" } },
                [_c("TopShop", { attrs: { "query-top": "1" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }