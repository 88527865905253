<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,capacity,area-tag"
            :dictFun="dictFun"
            :disabled="orgDictDisabled"
            fullLabel
            default-first-option
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            linkageRef="capacity,area-tag"
            :dictFun="dictFun"
            fullLabel
            @getOtherJsonParam="(callback) => callback(shopJsonParam)"
            parentLinkageRef
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
<!--        <el-form-item label="配送中心编码">-->
<!--          <el-input v-model="queryForm.dcCode" placeholder="请输入配送中心编码" clearable />-->
<!--        </el-form-item>-->
        <el-form-item label="区域分组">
          <DictSelect
              ref="area-tag"
              type="area-tag"
              :value.sync="queryForm.areaTag"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺类型">
          <DictSelect
              ref="capacity"
              type="capacity"
              :value.sync="queryForm.shopCapacity"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="店铺编码">
          <el-input v-model="queryForm.shopCode" placeholder="请输入店铺编码" clearable />
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="queryForm.shopName" placeholder="请输入店铺名称" clearable />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="importDialog.visible = true">区域分组导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">店铺分组导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="drawer.areaTargetVisible = true">区域分组目标查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="drawer.shopTargetVisible = true">店铺目标设定</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-edit" type="primary" @click="drawer.dcAreaVisible = true">管理区域分组</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 150px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      @sort-change="handleSortChange"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="分公司编码" prop="orgCode" width="100" />
      <el-table-column label="分公司名称" prop="orgName" width="100" />
      <el-table-column label="区域分组" prop="areaTag" width="100" />
      <el-table-column label="配送中心编码" prop="dcCode" width="120" />
      <el-table-column label="配送中心名称" prop="dcName" />
      <el-table-column label="店铺编码" prop="shopCode"  width="100" />
      <el-table-column label="店铺名称" prop="shopName" />
      <el-table-column label="店铺类型" prop="shopCapacity"  width="100" />
      <el-table-column label="操作" width="80" align="center">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleEdit(row, 'edit')">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog :title="dialogTitle[saveTitle]" :visible.sync="saveDialog.dialogVisible" width="800px" append-to-body :close-on-click-modal="false">
      <div style="margin: 10px">{{ noticeMsg }}</div>
      <el-form ref="addForm" :model="saveDialog.form" :inline="true" :rules="saveDialog.rules" label-width="100px">
        <el-form-item label="店铺类型" prop="shopCapacity" size="mini">
          <el-input
            :disabled="disableCapacity.indexOf(currentRow.shopCapacity) > -1"
            v-model="saveDialog.form.shopCapacity"
            clearable
            placeholder="请输入店铺类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="区域分组" prop="areaTag" size="mini">
          <el-input
            :disabled="saveTitle === 'view'"
            v-model="saveDialog.form.areaTag"
            clearable
            placeholder="请输入区域分组"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="saveDialog.dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    <ImportDialog :importBaseParams="importDialog" @handleImportCallBack="handleImportData" />

    <el-drawer title="区域分组目标查询" :visible.sync="drawer.areaTargetVisible" size="75%" direction="rtl" append-to-body :modal-append-to-body="false">
      <AreaGroup />
    </el-drawer>

    <el-drawer title="店铺目标设置" :visible.sync="drawer.shopTargetVisible" size="83%" direction="rtl" append-to-body :modal-append-to-body="false">
      <TargetV2 ref="target" v-if="taskInfo" :task-info="taskInfo" :auth-org-codes="authOrgs" @show-commit="(val) => {drawer.showCommit = val}" />
      <div class="dialog-footer" style="text-align: right;position: absolute; right: 50px;">
        <el-button v-show="drawer.showCommit === '0'" class="form-btn-item" size="mini" type="primary" @click="() => $refs.target.handleCommitUnify()">提交</el-button>
        <el-button size="mini" type="primary" @click="drawer.visible = false">关 闭</el-button>
      </div>
    </el-drawer>
    <el-drawer title="管理区域分组" :visible.sync="drawer.dcAreaVisible" size="50%" direction="rtl" append-to-body :modal-append-to-body="false">
      <DcArea />
    </el-drawer>

    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  pageShopCapaticyList,
  updateShopCapaticy,
  importShopCapaticy,
  getCurrentUser,
  dictSelect,
  shopDict,
  exportArea, getTaskInfo
} from "@/api/appTask";
import { parseParam } from "@/utils";
import {Message, MessageBox, Notification} from "element-ui";
import DictSelect from "@/components/DictSelect";
import AreaGroup from "./components/AreaGroup";
import ShopTarget from "./components/ShopTarget";
import DcArea from "./components/DcArea";
import TargetV2 from "../task/TargetV2";

export default {
  name: "shopCapacity",
  components: { AreaGroup, Pagination, ShopTarget, ImportDialog: () => import("@/components/ImportDialog"), DictSelect, DcArea, TargetV2 },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      itemsPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      queryForm: {
        current: 1,
        size: 10,
        shopName: undefined,
        shopCapacity: undefined,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        areaTag: undefined
      },
      loading: false,
      userOptions: [],
      saveTitle: undefined,
      dialogTitle: {
        add: "新增",
        edit: "修改",
        view: "查看",
      },
      saveDialog: {
        dialogVisible: false,
        form: {
          poiCode: undefined,
          dcCode: undefined,
          shopCode: undefined,
          shopCapacity: undefined,
          areaTag: undefined
        },
        rules: {
          shopCapacity: [{ required: true, message: "店铺类型不能为空", trigger: "change" }],
          areaTag: [{ required: true, message: "区域分组不能为空", trigger: "change" }],
        },
      },
      drawer: {
        areaTargetVisible: false,
        shopTargetVisible: false,
        dcAreaVisible: false,
        showCommit: undefined
      },
      importDialog: {
        visible: false,
        title: "店铺数据导入",
        type: "import",
        tipsText: "提示：区域分组模块数据隔天生效</br>店铺类型为TOP、一档、二档、三档、四档的店铺不可修改为其他类型</br>不可将店铺类型修改为TOP、一档、二档、三档、四档",
        templateSampleHead: [{ label: "配送中心编码", prop: "dcCode", minWidth: "80px" },{ label: "店铺编码", prop: "shopCode", minWidth: "80px" },{ label: "店铺类型", prop: "shopCapacity", minWidth: "80px" },{ label: "区域分组", prop: "areaTag", minWidth: "80px" }],
        templateSampleLists: [
          {
            dcCode: "C00000",
            shopCode: "0002",
            shopCapacity: "其他",
            areaTag: "1区"
          },
        ],
        apiParams: "importList",
        templateFileDownloadUrl:  process.env.VUE_APP_OSS_TEMPLATE_PATH +'/店铺信息修改模板.xlsx',
      },
      currentUser: {},
      dictFun: dictSelect,
      shopDict: shopDict,
      disableCapacity: ['二档', '三档', 'TOP', '一档', '四档'],
      currentRow: {},
      noticeMsg: '提示：区域分组模块数据隔天生效',
      orgDictDisabled: false,
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      authOrgs: [],
      taskInfo: undefined
    };
  },
  watch: {
    orgCode: {
      handler(newVal) {
        if (newVal!== undefined && newVal !== 'ALL') {
          this.queryForm.orgCode = newVal;
          this.orgDictDisabled = true;
        } else if (newVal) {
          this.queryForm.orgCode = undefined;
          this.orgDictDisabled = false;
        }
      },
      immediate: true
    },
  },
  created() {
    getCurrentUser().then(res => this.currentUser = res.data);
    dictSelect("ht-org-all").then(res => {
      // if (res.data.length === 1) {
      //   this.queryForm.orgCode = res.data[0].value;
      // }
      this.authOrgs = res.data.map(e => e.value)
    });
    getTaskInfo('0').then(res => {
      this.taskInfo = res.data
    })

  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      pageShopCapaticyList("?" + parseParam(this.queryForm)).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        shopName: undefined,
        shopCapacity: undefined,
        dcCode: undefined,
        shopCode: undefined,
        areaTag: undefined
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$refs.capacity.clearAll();
      this.$refs.tag.clearAll();
      this.$forceUpdate();
    },
    handleResetFields() {
      const form = {
        poiCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        shopCapacity: undefined,
        areaTag: undefined
      };
      this.saveDialog.form = Object.assign({}, form);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val) {
      if (val) {
        this.queryForm.createTimeBegin = val[0];
        this.queryForm.createTimeEnd = val[1];
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    handleEdit(row, type) {
      this.saveDialog.dialogVisible = true;
      this.saveTitle = type;
      this.currentRow = row;
      this.handleResetFields();
      const form = Object.assign({}, row);
      this.saveDialog.form = form;
    },
    submitForm() {
      this.$refs["addForm"].validate(valid => {
        if (valid) {
          const data = Object.assign({}, this.saveDialog.form);
          updateShopCapaticy(data).then(res => {
            if (res.code === 200) {
              this.saveDialog.dialogVisible = false;
              Notification.success("操作成功！");
              this.$nextTick(() => {
                this.$refs.capacity.remoteMethod('', true);
                this.$refs['area-tag'].remoteMethod('', true);
              })
              this.handleQuery();
            }
          });
        }
      });
    },
    handleImportData(val) {
      this.importDialog.visible = false;
      this.loading = true;
      if (val["importList"].length === 0) {
        Notification.warning("导入的数据量不能小于1个");
        return;
      }

      const data = {
        data: Object.assign([], val["importList"])
      }

      importShopCapaticy(data).then(res => {
        this.loading = false;
        if (res.code === 200) {
          if (res.data) {
            this.noticeDialog.msg = res.data;
            this.noticeDialog.visible = true;
          } else {
            Notification.success("操作成功！");
            this.$nextTick(() => {
              this.$refs.capacity.remoteMethod('', true);
              this.$refs.tag.remoteMethod('', true);
            })
            this.handleQuery();
          }
        }
      });
    },

    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleExport() {
      if (!this.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportArea(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '分区分组数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }
  },
};
</script>

<style lang="scss">

::v-deep {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .el-input__inner {
    padding-left: 5px !important;
    max-width: 200px !important;
  }
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}

</style>
