var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { padding: "20px" } },
      [
        _c(
          "label",
          {
            staticClass: "demonstration",
            staticStyle: { "font-size": "15px", "padding-right": "1%" },
          },
          [_vm._v("周：")]
        ),
        _c("el-date-picker", {
          attrs: {
            type: "week",
            "picker-options": _vm.pickerOptions,
            format: "yyyyWW",
            placeholder: "选择周",
          },
          on: { change: _vm.newDateWeekHandle },
          model: {
            value: _vm.yearWk,
            callback: function ($$v) {
              _vm.yearWk = $$v
            },
            expression: "yearWk",
          },
        }),
        _c(
          "label",
          { staticStyle: { "font-size": "15px", "padding-left": "1%" } },
          [_vm._v("大类：")]
        ),
        _c(
          "el-select",
          {
            staticStyle: {
              "font-size": "15px",
              "padding-right": "1%",
              width: "26%",
            },
            attrs: {
              clearable: "",
              filterable: "",
              placeholder: "请选择",
              id: "fz-select",
              size: "mini",
            },
            model: {
              value: _vm.c1CatName,
              callback: function ($$v) {
                _vm.c1CatName = $$v
              },
              expression: "c1CatName",
            },
          },
          _vm._l(_vm.c1CatNameRadio, function (item) {
            return _c("el-option", {
              key: item,
              attrs: { label: item, value: item },
            })
          }),
          1
        ),
        _c(
          "el-button",
          {
            staticStyle: { "font-size": "15px" },
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.getOnNewTraceData },
          },
          [_vm._v("查询")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              float: "right",
              "margin-bottom": "15px",
              "font-size": "15px",
            },
          },
          [_vm._v("更新时间: " + _vm._s(_vm.updateTime))]
        ),
      ],
      1
    ),
    _c("h1", { staticStyle: { "font-size": "20px" } }, [
      _vm._v(" 所属周上新追踪"),
    ]),
    _c("div", { staticClass: "top-title" }, [
      _c(
        "div",
        { staticClass: "title-right" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleOnNewTraceStatisticsDownload },
            },
            [_vm._v("下载所属周上新追踪")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.onNewTraceList,
              border: "",
              "header-cell-style": _vm.tableHeaderColor,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "c1CatName",
                label: "八大类",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "yearWk",
                label: "所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdYearWk",
                label: "建档周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planCreatedSkuCnt",
                label: "周规划建档sku数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdSkuCnt",
                label: "截至当前建档数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdOnTimeSkuCnt",
                label: "准时建档数量建档",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdOnTimeSkuRate",
                label: "准时建档率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.createdOnTimeSkuRate !== "-" &&
                      scope.row.createdOnTimeSkuRate.substring(
                        0,
                        scope.row.createdOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.createdOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.createdOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdNotOnTimeReason",
                label: "未准时建档原因及动作",
                align: "center",
                width: "230px",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "rkYearWk", label: "入仓周", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planRkSkuCnt",
                label: "周规划入仓SKU数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkOnTimeSkuCnt",
                label: "准确入仓数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkOnTimeSkuRate",
                label: "准确入仓率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.rkOnTimeSkuRate !== "-" &&
                      scope.row.rkOnTimeSkuRate.substring(
                        0,
                        scope.row.rkOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.rkOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.rkOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkNotOnTimeReason",
                label: "未准时入仓原因及动作",
                align: "center",
                width: "230px",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "onNewYearWk", label: "上新周", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planOnNewSkuCnt",
                label: "周规划上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuCnt",
                label: "准时上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuRate",
                label: "上新准确率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.onNewOnTimeSkuRate !== "-" &&
                      scope.row.onNewOnTimeSkuRate.substring(
                        0,
                        scope.row.onNewOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewNotOnTimeReason",
                label: "未准时上新原因及动作",
                align: "center",
                width: "230px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.yearWk !== _vm.lastWeek || row.c1CatName === "总计"
                        ? _c("span", [_vm._v(_vm._s(row.onNewNotOnTimeReason))])
                        : _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "controls-position": "right",
                              placeholder: "请输入内容",
                              size: "mini",
                              type: "textarea",
                              disabled: row.yearWk !== _vm.lastWeek,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeInput(
                                  $event,
                                  row,
                                  "onNew"
                                )
                              },
                            },
                            model: {
                              value: row.onNewNotOnTimeReason,
                              callback: function ($$v) {
                                _vm.$set(row, "onNewNotOnTimeReason", $$v)
                              },
                              expression: "row.onNewNotOnTimeReason",
                            },
                          }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div"),
    _c("h1", { staticStyle: { "font-size": "18px" } }, [
      _vm._v(" 上新问题清单"),
    ]),
    _c("div", { staticClass: "top-title" }, [
      _c(
        "div",
        { staticClass: "title-right" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleOnNewProblemListDownload },
            },
            [_vm._v("下载上新问题清单")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.onNewProblemList, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "yearWk",
                label: "所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "itemId",
                label: "商品编码",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "itemName",
                label: "商品名称",
                width: "300",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "c1CatName", label: "大类", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "c2CatName", label: "中类", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "c3CatName", label: "小类", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isCreatedOnTime",
                label: "是否准时建档",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isRkOnTime",
                label: "是否准确入仓",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isOnNewOnTime",
                label: "是否准时上新",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "isCg", label: "是否晨光", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "isManghe", label: "是否盲盒", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsFormFlag",
                label: "商品形态-是否计算",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsGradeFlag",
                label: "商品等级-是否计算",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsJzFlag",
                label: "架装-是否计算",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsTbFlag",
                label: "是否属于不计算明细",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "isJs", label: "是否计算", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdDate",
                label: "建档日期",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdYearWk",
                label: "建档所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "expectedNewDate",
                label: "预计上新日",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "expectedNewYearWk",
                label: "预计上新所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewDate",
                label: "实际上新时间",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewDateYearWk",
                label: "实际上新所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "yrkDate", label: "应入仓日期", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "yrkQty", label: "应入仓量", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "realRkQty",
                label: "应入仓日期前实际入仓数量",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "llPhStoreCnt",
                label: "理论铺货店铺",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "realPhStoreCnt",
                label: "实际铺货店铺",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "displayNum",
                label: "最小陈列量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "minDisNum",
                label: "最小配货量",
                align: "center",
              },
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticStyle: { "padding-top": "2%" },
          attrs: {
            background: "",
            "current-page": _vm.onNewProblemPage,
            "page-size": _vm.onNewProblemPageSize,
            total: _vm.onNewProblemListTotal,
            layout: "prev, pager, next",
          },
          on: { "current-change": _vm.onNewProblemHandlePageChange },
        }),
      ],
      1
    ),
    _c("h1", { staticStyle: { "font-size": "20px" } }, [
      _vm._v(" 所属周入仓追踪"),
    ]),
    _c("div", { staticClass: "top-title" }, [
      _c(
        "div",
        { staticClass: "title-right" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleRkTraceStatisticsDownload },
            },
            [_vm._v("下载所属周入仓追踪")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.rkTraceList,
              border: "",
              "header-cell-style": _vm.tableHeaderColor,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "c1CatName",
                label: "八大类",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "yearWk",
                label: "所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdYearWk",
                label: "建档周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planCreatedSkuCnt",
                label: "周规划建档sku数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdSkuCnt",
                label: "截至当前建档数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdOnTimeSkuCnt",
                label: "准时建档数量建档",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdOnTimeSkuRate",
                label: "准时建档率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.createdOnTimeSkuRate !== "-" &&
                      scope.row.createdOnTimeSkuRate.substring(
                        0,
                        scope.row.createdOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.createdOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.createdOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdNotOnTimeReason",
                label: "未准时建档原因及动作",
                align: "center",
                width: "230px",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "rkYearWk", label: "入仓周", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planRkSkuCnt",
                label: "周规划入仓SKU数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkOnTimeSkuCnt",
                label: "准确入仓数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkOnTimeSkuRate",
                label: "准确入仓率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.rkOnTimeSkuRate !== "-" &&
                      scope.row.rkOnTimeSkuRate.substring(
                        0,
                        scope.row.rkOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.rkOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.rkOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkNotOnTimeReason",
                label: "未准时入仓原因及动作",
                align: "center",
                width: "230px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.yearWk !== _vm.lastWeek || row.c1CatName === "总计"
                        ? _c("span", [_vm._v(_vm._s(row.rkNotOnTimeReason))])
                        : _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "controls-position": "right",
                              placeholder: "请输入内容",
                              size: "mini",
                              type: "textarea",
                              disabled: row.yearWk !== _vm.lastWeek,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeInput($event, row, "rk")
                              },
                            },
                            model: {
                              value: row.rkNotOnTimeReason,
                              callback: function ($$v) {
                                _vm.$set(row, "rkNotOnTimeReason", $$v)
                              },
                              expression: "row.rkNotOnTimeReason",
                            },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "onNewYearWk", label: "上新周", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planOnNewSkuCnt",
                label: "周规划上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuCnt",
                label: "准时上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuRate",
                label: "上新准确率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.onNewOnTimeSkuRate !== "-" &&
                      scope.row.onNewOnTimeSkuRate.substring(
                        0,
                        scope.row.onNewOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewNotOnTimeReason",
                label: "未准时上新原因及动作",
                align: "center",
                width: "230px",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("h1", { staticStyle: { "font-size": "18px" } }, [
      _vm._v(" 入仓问题清单"),
    ]),
    _c("div", { staticClass: "top-title" }, [
      _c(
        "div",
        { staticClass: "title-right" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleRkProblemListDownload },
            },
            [_vm._v("下载入仓问题清单")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.rkProblemList, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "yearWk",
                label: "所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "itemId",
                label: "商品编码",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "itemName",
                label: "商品名称",
                width: "300",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "c1CatName", label: "大类", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "c2CatName", label: "中类", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "c3CatName", label: "小类", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isCreatedOnTime",
                label: "是否准时建档",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isRkOnTime",
                label: "是否准确入仓",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isOnNewOnTime",
                label: "是否准时上新",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "isCg", label: "是否晨光", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "isManghe", label: "是否盲盒", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsFormFlag",
                label: "商品形态-是否计算",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsGradeFlag",
                label: "商品等级-是否计算",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsJzFlag",
                label: "架装-是否计算",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isJsTbFlag",
                label: "是否属于不计算明细",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "isJs", label: "是否计算", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdDate",
                label: "建档日期",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdYearWk",
                label: "建档所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "expectedNewDate",
                label: "预计上新日",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "expectedNewYearWk",
                label: "预计上新所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewDate",
                label: "实际上新时间",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewDateYearWk",
                label: "实际上新所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "yrkDate", label: "应入仓日期", align: "center" },
            }),
            _c("el-table-column", {
              attrs: { prop: "yrkQty", label: "应入仓量", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "realRkQty",
                label: "应入仓日期前实际入仓数量",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "llPhStoreCnt",
                label: "理论铺货店铺",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "realPhStoreCnt",
                label: "实际铺货店铺",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "displayNum",
                label: "最小陈列量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "minDisNum",
                label: "最小配货量",
                align: "center",
              },
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticStyle: { "padding-top": "2%" },
          attrs: {
            background: "",
            "current-page": _vm.rkProblemPage,
            "page-size": _vm.rkProblemPageSize,
            total: _vm.rkProblemListTotal,
            layout: "prev, pager, next",
          },
          on: { "current-change": _vm.rkProblemHandlePageChange },
        }),
      ],
      1
    ),
    _c("h1", { staticStyle: { "font-size": "20px" } }, [
      _vm._v(" 所属周建档追踪"),
    ]),
    _c("div", { staticClass: "top-title" }, [
      _c(
        "div",
        { staticClass: "title-right" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleCreateraceStatisticsDownload },
            },
            [_vm._v("下载所属周建档追踪")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.createTraceList,
              border: "",
              "header-cell-style": _vm.tableHeaderColor,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "c1CatName",
                label: "八大类",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "",
                prop: "yearWk",
                label: "所属周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdYearWk",
                label: "建档周",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planCreatedSkuCnt",
                label: "周规划建档sku数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdSkuCnt",
                label: "截至当前建档数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdOnTimeSkuCnt",
                label: "准时建档数量建档",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdOnTimeSkuRate",
                label: "准时建档率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.createdOnTimeSkuRate !== "-" &&
                      scope.row.createdOnTimeSkuRate.substring(
                        0,
                        scope.row.createdOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.createdOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.createdOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createdNotOnTimeReason",
                label: "未准时建档原因及动作",
                align: "center",
                width: "230px",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.yearWk !== _vm.lastWeek || row.c1CatName === "总计"
                        ? _c("span", [
                            _vm._v(_vm._s(row.createdNotOnTimeReason)),
                          ])
                        : _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "controls-position": "right",
                              placeholder: "请输入内容",
                              size: "mini",
                              type: "textarea",
                              disabled: row.yearWk !== _vm.lastWeek,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeInput(
                                  $event,
                                  row,
                                  "created"
                                )
                              },
                            },
                            model: {
                              value: row.createdNotOnTimeReason,
                              callback: function ($$v) {
                                _vm.$set(row, "createdNotOnTimeReason", $$v)
                              },
                              expression: "row.createdNotOnTimeReason",
                            },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "rkYearWk", label: "入仓周", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planRkSkuCnt",
                label: "周规划入仓SKU数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkOnTimeSkuCnt",
                label: "准确入仓数量",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkOnTimeSkuRate",
                label: "准确入仓率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.rkOnTimeSkuRate !== "-" &&
                      scope.row.rkOnTimeSkuRate.substring(
                        0,
                        scope.row.rkOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.rkOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.rkOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "rkNotOnTimeReason",
                label: "未准时入仓原因及动作",
                align: "center",
                width: "230px",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "onNewYearWk", label: "上新周", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planOnNewSkuCnt",
                label: "周规划上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuCnt",
                label: "准时上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuRate",
                label: "上新准确率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.onNewOnTimeSkuRate !== "-" &&
                      scope.row.onNewOnTimeSkuRate.substring(
                        0,
                        scope.row.onNewOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewNotOnTimeReason",
                label: "未准时上新原因及动作",
                align: "center",
                width: "230px",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("h1", { staticStyle: { "font-size": "20px" } }, [_vm._v(" YTD汇总")]),
    _c("div", { staticClass: "top-title" }, [
      _c(
        "div",
        { staticClass: "title-right" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-download",
              },
              on: { click: _vm.handleYtdSummaryStatisticsDownload },
            },
            [_vm._v("下载YTD汇总")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.ytdSummaryList, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "c1CatName",
                label: "大类",
                align: "center",
                fixed: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "planOnNewSkuCnt",
                label: "规划上新sku数 (不含晨光)",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuCnt",
                label: "准时上新SKU数",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "onNewOnTimeSkuRate",
                label: "准时上新率",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.onNewOnTimeSkuRate !== "-" &&
                      scope.row.onNewOnTimeSkuRate.substring(
                        0,
                        scope.row.onNewOnTimeSkuRate.length - 1
                      ) < 100
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.onNewOnTimeSkuRate)),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }