
// input -- 数字
const inputNumber = {
    bind:function(el) {
        el.handler = function() {
          el.childNodes[1].value = el.childNodes[1].value.replace(/\D+/g, '');
        }
        el.addEventListener('keyup', el.handler);
        el.childNodes[1].addEventListener('blur', el.handler);
    },
    unbind: function(el) {
        el.removeEventListener('keyup', el.handler);
        el.childNodes[1].addEventListener('blur', el.handler);
    }
}
// 下拉框触底加载
const loadmore = {
    bind(el, binding, vnode) {
        const that = vnode.context
        const selectWrap_dom = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        selectWrap_dom.addEventListener('scroll', function() {
            // 判断是否滚动到底部
            const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
            if (condition) {
                /**
                 * binding.value 是指令的绑定值，该值可能是字符串，数字、函数
                 * binding.value() 表示执行 v-loadmore 绑定的函数
                 */
                that[binding.arg](binding.value)
            }
        })
    }
}
const directives = {
    inputNumber,
    loadmore
  }
  
  export default {
    install(Vue) {
      Object.keys(directives).forEach((key) => {
        Vue.directive(key, directives[key])
      })
    },
  }