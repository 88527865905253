<template>
  <div class="salesCenter-page">

    <el-tabs class="salesCenter-tabs" :tab-position="'top'" v-model="activeName" style="height: 200x;">
      <!-- 请购单确认列表 -->
      <el-tab-pane label="确认列表" class="prodCenter-tabs-item" name="onlineWrite">
        <div class="salesCenter-search">
          <v-signboard :title="'请购单确认列表'"/>
          <el-form
            class="main-search"
            size="mini"
            label-width="auto"
            ref="buyingReq-form"
            :inline="true"
            :model="form"
            @keyup.enter.native="handleSubmit"
          >
            <el-form-item label="产品等级" size="mini" >
              <el-select clearable v-model="form.productGrade" multiple>
                <el-option
                  v-for="item in options.productGradeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="大类" size="mini">
              <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
                <el-option
                  v-for="item in options.productMaxCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中类" size="mini">
              <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                <el-option
                  v-for="item in options.productMidCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小类" size="mini">
              <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
                <el-option
                  v-for="item in options.productMinCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生产类型" size="mini">
              <el-select clearable v-model="form.prod_type">
                <el-option label="生产中心" value="生产中心"></el-option>
                <el-option label="OEM中心" value="OEM中心"></el-option>
                <el-option label="生产中心/办公" value="生产中心/办公"></el-option>
                <el-option label="办公" value="办公"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="锁状态" size="mini">
              <el-select clearable v-model="form.lock_state">
                <el-option label="全部" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品赛道" size="mini">
              <el-select clearable v-model="form.race_name" style="width:90px">
                <el-option
                  v-for="item in options.raceNameLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原始料号" size="mini">
              <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
            </el-form-item>
            <el-form-item label="品名" size="mini">
              <el-input v-model="form.plu_name" clearable placeholder="请输入品名"/>
            </el-form-item>
            <el-form-item label="统计品类" size="mini">
              <el-input v-model="form.stats_category_name" clearable placeholder="请输入统计品类"/>
            </el-form-item>

            <el-form-item label="模具号" size="mini">
              <el-input v-model="form.mould_code" clearable placeholder="请输入模具号"/>
            </el-form-item>

            <el-form-item label="预淘汰品" size="mini">
              <el-select clearable v-model="form.dead_sku_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="是" :key="1" :value="1"></el-option>
                <el-option label="否" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="统计工艺" size="mini">
              <el-select clearable v-model="form.craft_type">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="手工" :key="1" :value="1"></el-option>
                <el-option label="机装" :key="2" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否有客订" size="mini">
              <el-select clearable v-model="form.order_count_type" style="width:90px">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="有客订" :key="1" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="理论请购量分类" size="mini">
              <el-select clearable v-model="form.theory_shipment_count_type" style="width:90px">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="为零" :key="1" :value="1"></el-option>
                <el-option label="超一倍(理论请购量超过分公司客订量*2)" :key="2" :value="2"></el-option>
                <el-option label="低一半(理论请购量小于分公司客订量/2)" :key="3" :value="3"></el-option>
                <el-option label="正常" :key="4" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-button
              class="form-btn-item"
              size="mini"
              type="primary"
              :loading="btnStatus.isSub"
              @click="handleSubmit"
            >查 询
            </el-button>
            <el-button
              class="form-btn-item"
              size="mini"
              :loading="btnStatus.isReset"
              @click="handleReset"
            >重 置
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="btnStatus.isSave"
              @click="handleTableHeadAction('save')">保 存
            </el-button>
            <el-button
              size="mini"
              :disabled="btnStatus.isExport"
              :loading="btnStatus.isExportLoading"
              @click="handleTableHeadAction('export')">导 出
            </el-button>
            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader')"
              size="mini"
              type="primary"
              @click="handleTableHeadAction('setting')">重 新 设 定 库 存 系 数
            </el-button>
            <el-button v-if="this.user.role_code_list.includes('leader')"
                       size="mini"
                       type="warning"
                       @click="handleTableHeadAction('leaderConfirm')">领 导 审 核
            </el-button>

            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader')"
              size="mini"
              type="primary"
              @click="handleTableHeadAction('resetOffice')">重 置 办 公
            </el-button>
            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader')"
              size="mini"
              type="primary"
              @click="handleTableHeadAction('resetOem')">重 置 OEM
            </el-button>
            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader')"
              size="mini"
              type="primary"
              @click="handleTableHeadAction('resetSelf')">重 置 书 写
            </el-button>

            <el-button v-if="this.user.role_code_list.includes('officeManager')"
                       size="mini"
                       type="warning"
                       @click="handleTableHeadAction('confirmOffice')">审 核 办 公
            </el-button>
            <el-button v-if="this.user.role_code_list.includes('oemManager')"
                       size="mini"
                       type="warning"
                       @click="handleTableHeadAction('confirmOem')">审 核 OEM
            </el-button>
            <el-button v-if="this.user.role_code_list.includes('selfManager')"
                       size="mini"
                       type="warning"
                       @click="handleTableHeadAction('confirmSelf')">审 核 书 写
            </el-button>

            <el-button
              v-if="this.user.role_code_list.includes('selfManager') || this.user.role_code_list.includes('leader')"
              size="mini"
              type="primary"
              :disabled="btnStatus.isProdSetZero"
              @click="handleTableHeadAction('prod_set_zero')">请 购 置 零
            </el-button>
          </el-form>
        </div>
        <div class="salesCenter-content">
          <el-row>
            <el-col class="salesCenter-tips">根据您设置的库存系数目标，经数据中心计算，得到请购单如下：</el-col>
          </el-row>
          <el-table
            class="table"
            stripe
            border
            height="500"
            size="mini"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="40" fixed="left"/>
            <template v-for="(item, index) in tableObj.buyingReqConfirmHead">
              <el-table-column v-if="item.prop === '-'" fixed="right" v-bind="item" :key="index" align="center">
                <template slot-scope="scope">
                  <div class="prodConfirm-btn">
                    <template>
                      <el-button
                        v-for="(item1, confirId) in scope.row.userOpeBtn"
                        :key="confirId+'only'"
                        :type="item1.isSelect ? 'primary' : ''"
                        size="mini"
                        @click="handleUserOpe(scope.$index, confirId, scope.row)"
                      >{{ item1.btnText }}
                      </el-button>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                v-else-if="item.prop == 'prepare_shipment_count_box'"
                fixed="right"
                v-bind="item"
                show-overflow-tooltip
                :key="index"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input
                    class="tgqrcount-cell-ipt"
                    type="number"
                    v-model="scope.row.prepare_shipment_count_box"
                    size="mini"
                    clearable
                  />
                </template>
              </el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"/>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>


        <!-- 修改库存目标系数 -->
        <el-dialog
          class="add-dialog"
          title="修改库存目标系数"
          width="650px"
          :visible.sync="dialogStatus.isUpdateStoreRatio"
          :destroy-on-close="true"
        >
          <div class="dialog-content">
            <el-form
              ref="dialogForm"
              label-position="right"
              label-width="140px"
              :inline="true"
              size="medium"
              class="dialog-form"
              :model="dialogFormModel"
              :rules="dialogFormModelRules"
            >
                    <el-form-item label="原始料号" prop="material_code">
                      <el-input v-model="dialogFormModel.material_code" placeholder="原始料号" readonly="readonly" disabled></el-input>
                    </el-form-item>

                  <el-form-item label="修改类型" prop="update_type">
                    <el-select clearable v-model="dialogFormModel.update_type" style="width:90px">
                      <el-option label="当前项库存系数调整" value="1" :key="1"></el-option>
                      <el-option label="小类&级别库存系数调整" value="2" :key="2"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="库存系数上限" prop="store_ratio_up">
                    <el-input type="number" v-model="dialogFormModel.store_ratio_up" placeholder="库存系数上限"></el-input>
                  </el-form-item>

                  <el-form-item label="库存系数目标" prop="store_ratio_aim">
                    <el-input type="number" v-model="dialogFormModel.store_ratio_aim" placeholder="库存系数目标"></el-input>
                  </el-form-item>

                  <el-form-item label="库存系数下限" prop="store_ratio_down">
                    <el-input type="number" v-model="dialogFormModel.store_ratio_down" placeholder="库存系数下限"></el-input>
                  </el-form-item>

                  <el-form-item label="大类" prop="main_category_name">
                    <el-input v-model="dialogFormModel.main_category_name" placeholder="大类" readonly="readonly" disabled></el-input>
                  </el-form-item>

                  <el-form-item label="中类" prop="mid_category_name">
                    <el-input v-model="dialogFormModel.mid_category_name" placeholder="中类" readonly="readonly" disabled></el-input>
                  </el-form-item>

                  <el-form-item label="小类" prop="sub_category_name">
                    <el-input v-model="dialogFormModel.sub_category_name" placeholder="小类" readonly="readonly" disabled></el-input>
                  </el-form-item>

                  <el-form-item label="等级" prop="grade">
                    <el-input v-model="dialogFormModel.grade" placeholder="等级" readonly="readonly" disabled></el-input>
                  </el-form-item>
            </el-form>
          </div>
          <!-- 操作按钮 -->
          <div class="footer" slot="footer">
            <el-button size="small" type="primary" @click="handleUpdateStoreRatio">保 存</el-button>
            <el-button size="small" @click="handleCancelUpdateStoreRatio">取 消</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <!-- 请购单确认列表 -- 表单上传 -->
      <el-tab-pane label="表单上传" class="prodCenter-tabs-item" name="formWrite">
        <div clas="form-upload-box" v-if="activeName == 'formWrite'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'" />
              <el-row>
                <el-col class="handleConfirmUpload">
                  <el-button size="mini" type="primary">确认上传</el-button>
                </el-col>
              </el-row>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item" />
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'" />
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center" />
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams, optionsObj} from '@/mixins';
import {buyingReqConfirmHead, buyingReqImportSampleHead, minimumOrderAdjustHead} from '../config';
import {excelExport, thousandsSeparator, keepTwoDecimals, getPreYM, getCurrentYM, getPlanYM} from "@/utils";
import {
  handlePluOrderLists,
  handleSavePluOrder,
  handleConfirmPluOrder,
  handleDownloadPluOrder,
  handleResetGroup,
  handleConfirmGroup,
  handleProdSetZero
} from '@/api/salesCenter';
import {handleResetStoreRatio} from '@/api/salesCenter';
import {handlePluOrderImport, handleUpdateStoreRatio} from "../../../api/salesCenter";

export default {
  // 请购单页面
  name: 'buyingRequisitionConfirm',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 是否保存
        isProdSetZero: true,                   // 是否请购单置零
        isExport: false,                // 是否导出
        isConfirm: true,                // 是否确认
        isResetSetting: true,           // 是否重新设定库存系数
        isExportLoading: false,         // 导出loading
      },
      flowStatusText: {
        1: '原始数据',
        2: '被修改过',
        3: '审核过'
      },
      lists: [],
      tableObj: {
        buyingReqConfirmHead
      },
      dialogStatus: {
        isUpdateStoreRatio: false         // 是否修改库存目标系数
      },
      dialogFormModel: {
        update_type: 1,
        material_code: '',
        grade: '',
        main_category_name: '',
        mid_category_name: '',
        sub_category_name: '',
        store_ratio_up: 2,
        store_ratio_down: 0.5,
        store_ratio_aim: 1,
      },
      dialogFormModelRules: {
        update_type: [{required: true, message: '请选择修改类型', trigger: 'blur'}]
      },
      uploadFileObj: {
        tipsText: '必需字段: 原始料号、理论请购量。理论出货量请填写箱数',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: buyingReqImportSampleHead,
        templateSampleLists: [
          {
            order_month: '202111',
            material_code: 'AGPK3501110500H',
            theory_shipment_count_box: '13'
          }
        ]
      },
      activeName: 'onlineWrite',
      selectRows: [],
      urlMonth: ''
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isConfirm = false;
          btnStatus.isProdSetZero = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isConfirm = true;
          btnStatus.isProdSetZero = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: '',
      order_count_type: 0,
      theory_shipment_count_type: 0,
    }
    this.form = formPrams;
    this.originalForm = Object.assign({}, this.form);
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  methods: {
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          order_count_type: form.order_count_type,
          theory_shipment_count_type: form.theory_shipment_count_type || '',
          grade: form.productGrade || '',
          lock_state: form.lock_state || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          prod_type: form.prod_type || '',
          race_name: form.race_name || '',
          material_code: form.material_code,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          plu_name: form.plu_name,
          stats_category_name: form.stats_category_name,
          dead_sku_type : form.dead_sku_type,
          mould_code: form.mould_code,
          craft_type : form.craft_type
        }
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        }
        const {code, data, msg} = await handlePluOrderLists(params);
        if (data) {
          const {plu_order_list, total} = data;
          this.modifyData(plu_order_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-------请购单确认列表接口有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            order_month: item.order_month || '--',
            prod_type: item.prod_type || '--',
            plu_code: item.plu_code || '--',
            plu_id: item.plu_id || '--',
            plu_name: item.plu_name || '--',
            material_code: item.material_code || '--',
            cargono: item.cargono || '--',
            flow_status: this.flowStatusText[item.flow_status] || '--',

            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',
            price: item.price || '--',
            lock_state: item.lock_state || '--',

            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            grade: item.grade || '--',
            pen_craft_total: item.pen_craft_total || '--',
            mould_code: item.mould_code || '--',
            dead_sku_type: item.dead_sku_type || '--',
            min_order_count_box: thousandsSeparator(item.min_order_count_box, 0) || 0,
            hq_rework_count_box: thousandsSeparator(item.hq_rework_count_box, 0) || 0,

            hq_before_last_month_stock_count_box: thousandsSeparator(item.hq_before_last_month_stock_count_box, 0) || 0,
            hq_last_month_sale_count_box: thousandsSeparator(item.hq_last_month_sale_count_box, 0) || 0,
            hq_last_month_ql_count_box: thousandsSeparator(item.hq_last_month_ql_count_box, 0) || 0,
            last_month_carryover_count_box: thousandsSeparator(item.last_month_carryover_count_box, 0) || 0,
            hq_last_month_pc_count_box: thousandsSeparator(item.hq_last_month_pc_count_box, 0) || 0,
            hq_this_month_ql_box: thousandsSeparator(item.hq_this_month_ql_box, 0) || 0,
            hq_last_month_stock_ratio: keepTwoDecimals(item.hq_last_month_stock_ratio) || 0,
            bo_last_month_stock_count_box: thousandsSeparator(item.bo_last_month_stock_count_box, 0) || 0,
            bo_last_month_stock_ratio: keepTwoDecimals(item.bo_last_month_stock_ratio) || 0,
            hq_this_month_stock_ratio: keepTwoDecimals(item.hq_this_month_stock_ratio) || 0,
            bo_last_month_order_count: thousandsSeparator(item.bo_last_month_order_count, 0) || 0,
            store_ratio_aim_count_box: thousandsSeparator(item.store_ratio_aim_count_box, 0) || 0,
            store_ratio_up_count_box: thousandsSeparator(item.store_ratio_up_count_box, 0) || 0,
            store_ratio_down_count_box: thousandsSeparator(item.store_ratio_down_count_box, 0) || 0,

            hq_last2_month_sale_count_box: thousandsSeparator(item.hq_last2_month_sale_count_box, 0) || 0,
            hq_last3_month_sale_count_box: thousandsSeparator(item.hq_last3_month_sale_count_box, 0) || 0,
            hq_last4_month_sale_count_box: thousandsSeparator(item.hq_last4_month_sale_count_box, 0) || 0,
            hq_last5_month_sale_count_box: thousandsSeparator(item.hq_last5_month_sale_count_box, 0) || 0,
            hq_last6_month_sale_count_box: thousandsSeparator(item.hq_last6_month_sale_count_box, 0) || 0,
            hq_last7_month_sale_count_box: thousandsSeparator(item.hq_last7_month_sale_count_box, 0) || 0,
            hq_last8_month_sale_count_box: thousandsSeparator(item.hq_last8_month_sale_count_box, 0) || 0,
            hq_last9_month_sale_count_box: thousandsSeparator(item.hq_last9_month_sale_count_box, 0) || 0,
            hq_last10_month_sale_count_box: thousandsSeparator(item.hq_last10_month_sale_count_box, 0) || 0,
            hq_last11_month_sale_count_box: thousandsSeparator(item.hq_last11_month_sale_count_box, 0) || 0,
            hq_last12_month_sale_count_box: thousandsSeparator(item.hq_last12_month_sale_count_box, 0) || 0,
            hq_unsale_count_box: thousandsSeparator(item.hq_unsale_count_box, 0) || 0,

            hq_this_mth_no_order_zzl_box: thousandsSeparator(item.hq_this_mth_no_order_zzl_box, 0) || 0,
            hq_next_mth_no_order_zzl_box: thousandsSeparator(item.hq_next_mth_no_order_zzl_box, 0) || 0,
            bo_curr_zt_box: thousandsSeparator(item.bo_curr_zt_box, 0) || 0,
            hq_curr_stock_count_box: thousandsSeparator(item.hq_curr_stock_count_box, 0) || 0,

            hg_last_month_store_count_box: thousandsSeparator(item.hg_last_month_store_count_box, 0) || 0,
            hq_pre_last_six_month_avg_sale_count_box: thousandsSeparator(item.hq_pre_last_six_month_avg_sale_count_box, 0) || 0,
            hg_month_avg_sale_count_box: thousandsSeparator(item.hg_month_avg_sale_count_box, 0) || 0,
            prod_count_up_box: thousandsSeparator(item.prod_count_up_box, 0) || 0,
            prod_count_down_box: thousandsSeparator(item.prod_count_down_box, 0) || 0,
            store_ratio_aim: keepTwoDecimals(item.store_ratio_aim) || 0,
            store_ratio_up: keepTwoDecimals(item.store_ratio_up) || 0,
            store_ratio_down: keepTwoDecimals(item.store_ratio_down) || 0,
            hq_this_month_stock_ratio_of_six_avg_sale_count: keepTwoDecimals(item.hq_this_month_stock_ratio_of_six_avg_sale_count) || 0,
            hq_this_month_stock_ratio_of_three_avg_sale_count: keepTwoDecimals(item.hq_this_month_stock_ratio_of_three_avg_sale_count) || 0,
            total_previous_put_count_box: thousandsSeparator(item.total_previous_put_count_box, 0) || 0,
            confirm_bo_kd_plu_count_box: thousandsSeparator(item.confirm_bo_kd_plu_count_box, 0) || 0,
            prepare_shipment_count_box: item.prepare_shipment_count_box || 0,
            hq_this_month_total_count_box: item.hq_this_month_total_count_box || 0,
            theory_shipment_count_box: thousandsSeparator(item.theory_shipment_count_box, 0) || 0,
            update_by: item.update_by || '--',
            update_time: item.update_time || '--',
            bottom_spec: item.bottom_spec || '1',
            userOpeBtn: [
              { btnText: '修改库存系数', isSelect: false, btnStatus: 1},
            ]
          }
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 导出数据
    async exportData() {

      let topHeaders = {}
      for(let i = 0; i < buyingReqConfirmHead.length; i++) {
        let row = buyingReqConfirmHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;
        const {code, data} = await handleDownloadPluOrder({operator: this.urlParams.operator});
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {plu_order_list} = data;
          if (plu_order_list.length > 0) {
            excelExport({
              topHeaders,
              data: plu_order_list,
              title: '请购单确认'
            })
          }
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------请购单确认列表导出接口有误');
      }
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        lock_state: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        prod_type: '',
        plu_name: '',
        stats_category_name: '',
        dead_sku_type: '',
        mould_code:'',
        craft_type: ''
      }
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'export': () => {                     // 导出数据
          this.exportData();
        },
        'save': async () => {                 // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              order_month: item.order_month || '',
              plu_id: item.plu_id || '',
              prepare_shipment_count_box: item.prepare_shipment_count_box || 0,
              update_by: item.update_by || '',
              update_time: item.update_time || ''
            }
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            order_month: this.urlMonth,
            plu_order_list: newItems
          }
          const {code, msg} = await handleSavePluOrder(params);
          this.$refs['multipleTable'].clearSelection();
          if (code === '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code !== '0000') this.getLists();
        },
        'prod_set_zero': async () => {                 // 请购置零
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              plu_id: item.plu_id || ''
            }
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            order_month: this.urlMonth,
            zero_list: newItems
          }
          const {code, msg} = await handleProdSetZero(params);
          this.$refs['multipleTable'].clearSelection();
          if (code === '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('请购置零成功');
          }
          if (code !== '0000') this.getLists();
        },
        'leaderConfirm': async () => {            // 领导审核
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator
          }
          const {success} = await handleConfirmPluOrder(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('请购单确认已成功');
          }
          if (!success) this.getLists();
        },
        'resetOffice': async () => {            // 重置办公
          const params = {
            month: this.urlMonth,
            type: 'office',
            operator: this.urlParams.operator
          }
          const {success} = await handleResetGroup(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('重置办公已成功');
          }
          if (!success) this.getLists();
        },
        'resetOem': async () => {            // 重置OEM
          const params = {
            month: this.urlMonth,
            type: 'oem',
            operator: this.urlParams.operator
          }
          const {success} = await handleResetGroup(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('重置OEM已成功');
          }
          if (!success) this.getLists();
        },
        'resetSelf': async () => {            // 重置书写
          const params = {
            month: this.urlMonth,
            type: 'self',
            operator: this.urlParams.operator
          }
          const {success} = await handleResetGroup(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('重置书写已成功');
          }
          if (!success) this.getLists();
        },
        'confirmOffice': async () => {            // 审核办公
          const params = {
            month: this.urlMonth,
            type: 'office',
            operator: this.urlParams.operator
          }
          const {success} = await handleConfirmGroup(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('审核办公已成功');
          }
          if (!success) this.getLists();
        },
        'confirmOem': async () => {            // 审核OEM
          const params = {
            month: this.urlMonth,
            type: 'oem',
            operator: this.urlParams.operator
          }
          const {success} = await handleConfirmGroup(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('审核OEM已成功');
          }
          if (!success) this.getLists();
        },
        'confirmSelf': async () => {            // 审核书写
          const params = {
            month: this.urlMonth,
            type: 'self',
            operator: this.urlParams.operator
          }
          const {success} = await handleConfirmGroup(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('审核书写已成功');
          }
          if (!success) this.getLists();
        },
        'setting': async () => {            // 重置设定系数
          const params = {
            month: this.urlMonth || '',
            operator: this.urlParams.operator
          }
          const {success} = await handleResetStoreRatio(params);
          if (success) return this.$message.success('重新设定库存系数已成功');
        }
      }
      funcObj[type]();
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 修改库存目标系数
    handleUpdateStoreRatio() {
      this.$refs['dialogForm'].validate(async foo => {
        if (!foo) return;
        try {
          const params = {
            ...this.dialogFormModel,
            operator: this.urlParams.operator
          };
          console.log(params);
          const {code, success} = await handleUpdateStoreRatio(params);
          if (success) {
            this.dialogStatus.isUpdateStoreRatio = false;
            this.getLists();
            return this.$message.success('修改库存目标系数成功');
          }
        } catch (err) {
          this.dialogStatus.isUpdateStoreRatio = false;
          console.log(err, 'err------修改库存目标系数有误');
        }
      })
    },
    // 取消按钮
    handleCancelUpdateStoreRatio() {
      this.dialogStatus.isUpdateStoreRatio = false;
    },
    handleUserOpe(rowIndex, btnIndex, row) {
      if(btnIndex === 0) { // 修改库存目标系数
        this.dialogFormModel = row;
        this.dialogStatus.isUpdateStoreRatio = true;
      }
      console.log(btnIndex);
      console.log(rowIndex);
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if ( !this.$refs['uploadExcelRef'].isExcel(file) ) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({ results, header }) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header
    },
    // 预览上传文件
    handlePreview({ data, isPreview }) {
      this.uploadFileObj.preTableData = data;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;
      if ( uploadFileObj.tableHeader.indexOf('原始料号') === -1 ) return this.$message.warning('缺少原始料号字段');
      if ( uploadFileObj.tableHeader.indexOf('理论请购量') === -1 ) return this.$message.warning('缺少理论请购量字段');
      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let newItem = {
          order_month: item['年月'],
          theory_shipment_count_box: item['理论请购量'],
          material_code: item['原始料号']
        }
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        plu_order_list: newItems
      };
      const { code, success ,msg } = await handlePluOrderImport(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if ( success ) return this.$message.success(msg);
      if ( !success ) this.activeName = 'onlineWrite';
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
<style lang="scss" scoped>
.form-pluCode-item {
  ::v-deep .el-form-item__content {
    width: 200px;
  }
}
.salesCenter-content {
  ::v-deep .el-table__empty-text {
      width: 79%;
    }
}
</style>
