var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      ref: "mgTooltip",
      attrs: {
        disabled: _vm.tagDisabled,
        content: _vm.content,
        placement: "top",
        "popper-class": "mg-tooltip",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }