var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "item taskGroupItem",
        style: {
          ..._vm.styles,
          backgroundImage: `url(${_vm.task.configJson?.theme?.background?.image})`,
        },
      },
      [
        _c("span", { staticClass: "badge" }, [_vm._v("任务合集")]),
        _c("div", { staticClass: "taskName text-2" }, [
          _vm._v(_vm._s(_vm.task.taskName)),
        ]),
        _c(
          "div",
          { staticClass: "taskData" },
          _vm._l(
            _vm.type === "shop"
              ? _vm.task.configJson.shop
              : _vm.task.configJson.summary,
            function (item, index) {
              return _c("div", { key: index, staticClass: "taskNum" }, [
                _c(
                  "p",
                  {
                    staticClass: "label",
                    style:
                      "width: max-content;color:" +
                      _vm.hexToRgba(item.value.color, 0.7),
                  },
                  [_vm._v(_vm._s(item.title) + " ")]
                ),
                _c("p", { staticClass: "value" }, [
                  _c(
                    "span",
                    { style: "font-size: 13; color:" + item.value.color },
                    [
                      _c("span", [_vm._v(_vm._s(item.prefix))]),
                      _vm._v(" 0 " + _vm._s(item.unit)),
                    ]
                  ),
                ]),
              ])
            }
          ),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }