<template>
    <div>
        <el-card style="margin: 20px;">
            <div style="width: 350px; margin: auto;">
            <el-upload class="upload-demo" drag accept=".xls,.xlsx" action="" multiple :auto-upload="false"
                :on-change="onChange">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    <el-link href="https://mg-app-oss.oss-cn-shanghai.aliyuncs.com/upload/%E9%85%8D%E9%80%81%E4%B8%AD%E5%BF%83%E9%94%80%E5%94%AE%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx" type="primary" target="_blank"
                        style="margin-top: -4px;">下载文件导入模板</el-link>

                </div>
            </el-upload>
            <div style="text-align: center; margin: 20px;">
                <el-button type="primary" size="small" @click="submit">提交</el-button>
            </div>
        </div>
        </el-card>
    </div>
</template>

<script>
import { importSalesTargets } from "@/api/importSalesTargets/index";
import { urlParams } from '@/mixins';
export default {
    mixins: [urlParams],
    components: {},

    data() {
        return {
            form: {
                file: null,
            }
        }
    },

    created() {
        console.log(this.$route.query);
    },
    methods: {
        onChange(file) {
            this.form.file = file.raw;
        },
        async submit() {
            if (!this.form.file) {
                this.$message.error("文件不能为空！");
                return;
            }
            var formData = new FormData();
            formData.append("file", this.form.file);
            const response = await importSalesTargets(formData)
            if(response.code != 200){
                this.$message.error(response.msg);
                return;
            }
            this.$message.success("操作成功");
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-demo {
    text-align: center;
    margin: 20px 0;
}
</style>