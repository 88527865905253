var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                rules: _vm.rules,
                size: "mini",
                inline: true,
                model: _vm.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "标签名称",
                            prop: "tagName",
                            "label-width": "120px",
                          },
                        },
                        [
                          _vm.type !== "view"
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value: _vm.form.tagName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "tagName", $$v)
                                  },
                                  expression: "form.tagName",
                                },
                              })
                            : _vm._e(),
                          _vm.type === "view"
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.form.tagName)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分公司",
                            "label-width": "100px",
                            prop: "orgs",
                          },
                        },
                        [
                          _vm.type !== "view"
                            ? _c("DictSelect", {
                                ref: "org",
                                attrs: {
                                  value: _vm.form.orgs,
                                  fullLabel: "",
                                  multiple: "",
                                  init: "",
                                  "init-options": _vm.initOrgOptions,
                                  "collapse-tags": "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(_vm.form, "orgs", $event)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.type === "view"
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.form.orgName)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "权重",
                            prop: "weight",
                            "label-width": "120px",
                          },
                        },
                        [
                          _vm.type !== "view"
                            ? _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  min: 0,
                                  step: 0,
                                  placeholder: "值越大优先级越高",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.weight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "weight", $$v)
                                  },
                                  expression: "form.weight",
                                },
                              })
                            : _vm._e(),
                          _vm.type === "view"
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.form.weight)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "时间范围",
                            prop: "rangeTime",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              type: "datetimerange",
                              format: "yyyy-MM-dd HH:mm:ss",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            model: {
                              value: _vm.form.rangeTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "rangeTime", $$v)
                              },
                              expression: "form.rangeTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                "z-index": "999",
                width: "100%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    float: "left",
                    "font-weight": "600",
                    margin: "15px",
                  },
                },
                [_vm._v("商品列表")]
              ),
              _c(
                "div",
                { staticStyle: { float: "right", margin: "5px" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: {
                        width: "120px !important",
                        "margin-right": "10px",
                      },
                      attrs: {
                        clearable: "",
                        size: "mini",
                        placeholder: "商品型号",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery("models", "skuPage")
                        },
                      },
                      model: {
                        value: _vm.querySkuKeyWord,
                        callback: function ($$v) {
                          _vm.querySkuKeyWord = $$v
                        },
                        expression: "querySkuKeyWord",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        staticStyle: { cursor: "pointer" },
                        attrs: { slot: "suffix" },
                        on: {
                          click: function ($event) {
                            return _vm.handleQuery("models", "skuPage")
                          },
                        },
                        slot: "suffix",
                      }),
                    ]
                  ),
                  _vm.type !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            type: "primary",
                          },
                          on: { click: _vm.handleImport },
                        },
                        [_vm._v("导入商品 ")]
                      )
                    : _vm._e(),
                  _vm.type !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-plus",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.drawer.visible = true
                            },
                          },
                        },
                        [_vm._v("添加商品 ")]
                      )
                    : _vm._e(),
                  _vm.type !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-remove",
                            type: "danger",
                          },
                          on: { click: _vm.handleRemoveAll },
                        },
                        [_vm._v("全部移除 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "skuTableRef",
                  staticStyle: { "margin-top": "5px" },
                  attrs: {
                    data: _vm.skuPage.rows,
                    height: "calc(100vh - 400px)",
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(227 224 224)",
                      color: "black",
                    },
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "商品型号",
                      prop: "skuModel",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "款式名称",
                      prop: "skuName",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.skuName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.type !== "view"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "70",
                          align: "center",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        border: "0px",
                                        color: "red",
                                      },
                                      attrs: { type: "text", size: "small" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleRemoveRow(
                                            scope.row,
                                            1,
                                            "models",
                                            "skuPage"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 移除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3216214570
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.skuPage.total,
                  page: _vm.skuPage.current,
                  limit: _vm.skuPage.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.skuPage, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.skuPage, "size", $event)
                  },
                  pagination: function ($event) {
                    return _vm.handleQuery("models", "skuPage")
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.drawer.visible,
            size: "70%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "visible", $event)
            },
          },
        },
        [
          _c("SkuItem", {
            attrs: {
              "group-id": _vm.skuGroups.activeIndex,
              refresh: _vm.skuGroups.refresh,
              scope: "0",
              "show-action": true,
            },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawer.visible = false
              },
              getExceptModels: (callback) => callback(_vm.form.exceptSkuModels),
              getExceptSkuCodes: (callback) => callback([]),
            },
          }),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.downloadImportResult },
                },
                [_vm._v("下载导入结果")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }