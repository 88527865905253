var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": (val) => _vm.handleTabClick(val.name) },
      model: {
        value: _vm.tabName,
        callback: function ($$v) {
          _vm.tabName = $$v
        },
        expression: "tabName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { name: "match", label: "匹配关系" } },
        [_c("TechMatch", { ref: "match" })],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "price", label: "控价分析" } },
        [
          _c("PriceAnalysis", {
            ref: "price",
            on: { handleJumpQuery: _vm.handleJumpQuery },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }