import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/request";

// --- 标签列表 ---------------
/**
 * 下拉数据接口
 * @param data
 * @returns {*}
 */
export function getAllDict(data) {
  return axiosGet("/basic/data/tag-select/data", data, 3);
}

/**
 * 根据一级分类获取二级分类
 * @param data
 * @returns {*}
 */
export function getC2Dict(data) {
  return axiosGet("/basic/data/tag/c2-tag-name/list", data, 3);
}

/**
 * 标签列表分页
 * @param data
 * @returns {*}
 */
export function getTagList(data) {
  return axiosPost("/tag/list", data, 3);
}

/**
 * 新增手工标签
 * @param data
 * @returns {*}
 */
export function addManualTag(data) {
  return axiosPost("/tag/create", data, 3);
}

/**
 * 标签导入
 * @param data
 * @returns {*}
 */
export function importTags(data) {
  return axiosPost("/tag/import", data, 3);
}

/**
 * 用户标签数据导入
 * @param data
 * @returns {*}
 */
export function importTagUserData(data) {
  return axiosPost("/tag/user/bind-tag/import", data, 3);
}

/**
 * 单个标签数据导入
 * @param data
 * @returns {*}
 */
export function importSingleTagData(data) {
  return axiosPost("/tag/single-tag/user-data/import", data, 3);
}

/**
 * 标签详情
 * @param data
 * @returns {*}
 */
export function getTagDetail(data) {
  return axiosGet("/tag/" + data, null, 3);
}

/**
 * 标签详情
 * @param data
 * @returns {*}
 */
export function getSpecialTagDetail(data) {
  return axiosGet("/tag/special/" + data, null, 3);
}

/**
 * 更新标签定义
 * @param id
 * @param data
 * @returns {*}
 */
export function updateTagDefine(id, data) {
  return axiosPost("/tag/" + id+ "/tag-define/update", data, 3);
}

/**
 * 更新标签说明
 * @param id
 * @param data
 * @returns {*}
 */
export function updateTagExplain(id, data) {
  return axiosPost("/tag/" + id+ "/tag-explain/update", data, 3);
}

/**
 * 批量启用
 * @param id
 * @param data
 * @returns {*}
 */
export function enableTags(data) {
  return axiosPost("/tag/batch-enable", data, 3);
}

/**
 * 批量停用
 * @param id
 * @param data
 * @returns {*}
 */
export function disableTags(data) {
  return axiosPost("/tag/batch-disable", data, 3);
}

/**
 * 新增逻辑标签
 * @param data
 * @returns {*}
 */
export function addLogicTag(data) {
  return axiosPost("/logic-tag/create", data, 3);
}

/**
 * 逻辑标签导入
 * @param data
 * @returns {*}
 */
export function importLogicTags(data) {
  return axiosPost("/logic-tag/import", data, 3);
}

/**
 * 更新对应字段
 * @param id
 * @param data
 * @returns {*}
 */
export function updateTagColName(id, data) {
  return axiosPost("/tag/" + id+ "/tag-col-name/update", data, 3);
}

/**
 * 企微标签导入
 * @param data
 * @returns {*}
 */
export function importQwTags(data) {
  return axiosPost("/logic-tag/push/import", data, 3);
}

/**
 * 获取企微标签值推送导入处理进度
 * @param id
 * @param data
 * @returns {*}
 */
export function getQwTagValImportStatus(fileId) {
  return axiosGet("/logic-tag/push/import/" + fileId+ "/status", {}, 3);
}

/**
 * 获取标签数据导入处理进度
 * @param id
 * @param data
 * @returns {*}
 */
export function queryFileUploadStatus(fileId) {
  return axiosGet("/tag/import/" + fileId+ "/status", {}, 3);
}

// ----   分组列表--------------
/**
 * 获取分组列表
 * @param data
 * @returns {*}
 */
export function getGroupPageList(data) {
  return axiosPost("/rule-group/page/list", data, 3);
}

/**
 * 获取分组列表
 * @param data
 * @returns {*}
 */
export function getGroupList(data) {
  return axiosPost("/rule-group/list", data, 3);
}

/**
 * 分组下拉创建人数据
 * @returns {*}
 */
export function getGroupCreater() {
  return axiosGet("/basic/data/rule-group/creator/list", {}, 3);
}

/**
 * 新增圈选分组
 * @param data
 * @returns {*}
 */
export function createGroup(data) {
  return axiosPost("/rule-group/create", data, 3);
}

/**
 * 获取分组详情
 * @param data
 * @returns {*}
 */
export function getGroupDetail(data) {
  return axiosGet("/rule-group/detail/" + data, {}, 3);
}

/**
 * 更新分组
 * @param data
 * @returns {*}
 */
export function updateGroup(data) {
  return axiosPost("/rule-group/update", data, 3);
}

/**
 * 删除分组
 * @param data
 * @returns {*}
 */
export function deleteGroup(data) {
  return axiosDelete("/rule-group/" + data, {}, 3);
}

/**
 * 手动运行
 * @param data
 * @returns {*}
 */
export function executeGroup(data) {
  return axiosPost("/rule-group/execute/" + data, {}, 3);
}

/**
 * 分组运行日志
 * @param data
 * @returns {*}
 */
export function getGroupExecuteLog(data) {
  return axiosPost("/rule-group/update-record/list", data, 3);
}

/**
 * 下载运行日志
 * @param data
 * @returns {*}
 */
export function downloadGroupExecuteLog(data) {
  return axiosPost("/rule-group/download", data, 3);
}

/**
 * 分组运行日志明细
 * @param data
 * @returns {*}
 */
export function getGroupExecuteLogDetail(data) {
  return axiosPost("/rule-group/execute-result", data, 3);
}

// -----  渠道分发 ----------
/**
 * 渠道分发查询
 * @param data
 * @returns {*}
 */
export function queryDistribute(data) {
  return axiosPost("/channel/distribute/page/list", data, 3);
}

/**
 * 查看运算结果
 * @param data
 * @returns {*}
 */
export function viewExecuteResult(data) {
  return axiosPost("/channel/distribute/execute", data, 3);
}

/**
 * 新增渠道分发
 * @param data
 * @returns {*}
 */
export function addDistribute(data) {
  return axiosGet("/channel/distribute/confirm/" + data, null, 3);
}

/**
 * 下载结果
 * @param data
 * @returns {*}
 */
export function downloadResult(data) {
  return axiosGet("/channel/distribute/download/" + data, {}, 3);
}
/**
 * 查看运算结果详情
 * @param data
 * @returns {*}
 */
export function getExecuteResult(data) {
  return axiosGet("/channel/distribute/run/result/" + data, {}, 3);
}

/**
 * 删除分发规则
 * @param data
 * @returns {*}
 */
export function deleteDistribute(data) {
  return axiosDelete("/channel/distribute/" + data, {}, 3);
}

/**
 * 根据分组获取版本列表
 * @param data
 * @returns {*}
 */
export function getVersionByGroup(data) {
  return axiosGet("/basic/data/rule-group/version/list?ruleGroupId=" + data, {}, 3);
}

/**
 * 是否存在运行中分发记录
 * @returns {*}
 */
export function getIfRunning() {
  return axiosGet("/channel/distribute/run/record", null, 3);
}

/**
 * 分发详情
 * @param data
 * @returns {*}
 */
export function getExecuteDistributeDetail(data) {
  return axiosGet("/channel/distribute/run/detail/" + data, null, 3);
}

/**
 * 分发生成记录列表
 * @returns {*}
 */
export function getExecuteResultList() {
  return axiosGet("/channel/distribute/run/list", null, 3);
}

/**
 * 推送
 * @param data
 * @returns {*}
 */
export function pushChannel(data) {
  return axiosPost("/channel/distribute/push", data, 3);
}

/**
 * 获取企微群发数据
 * @returns {*}
 */
export function getGroupMsgSendResult() {
  return axiosGet("/channel/distribute/get_groupmsg_send_result", {}, 3);
}

/**
 * 获取企微群发数据拉取状态
 * @returns {*}
 */
export function getGroupMsgSendResultStatus() {
  return axiosGet("/channel/distribute/get_groupmsg_send_result/status", {}, 3);
}

/**
 * 获取企微群发数据未拉取完成的
 * @returns {*}
 */
export function getGroupMsgUnSendFinish() {
  return axiosGet("/channel/distribute/get_groupmsg_unsend_finish", {}, 3);
}

/**
 * 添加企微群发消息内容
 * @param data
 * @returns {*}
 */
export function addQwGroupMsgContent(data) {
  return axiosPost("/qw/group/msg/add", data, 3);
}

/**
 * 获取企微群发消息内容
 * @param data
 * @returns {*}
 */
export function getQwGroupMsgContent(data) {
  return axiosGet("/qw/group/msg/get/" + data, {}, 3);
}
// ---------------  数据复盘
/**
 * 获取分组数据
 * @param data
 * @returns {*}
 */
export function getGroupData(data) {
  return axiosGet("/basic/data/data-replay/group/list?name=" + data, {}, 3);
}

/**
 * 分组数据对比
 * @param data
 * @returns {*}
 */
export function getCompareGroupData(data) {
  return axiosPost("/data/replay/group-data/contrast", data, 3);
}

/**
 * 标签数据对比
 * @param data
 * @returns {*}
 */
export function getCompareTagData(data) {
  return axiosPost("/data/replay/tag-data/contrast", data, 3);
}

/**
 * 触达分析获取分组数据 触达漏斗
 * @param data
 * @returns {*}
 */
export function getCDGroupData(data) {
  return axiosGet("/basic/data/reach-group/list", data, 3);
}

/**
 * 触达消费接口 触达漏斗
 * @param data
 * @returns {*}
 */
export function getCDconsumeStatistics(data) {
  return axiosPost("/reach/consume-statistics", data, 3);
}


/**
 * 触达 短信触达失败统计 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsFail(data) {
  return axiosPost("/reach/sms-fail/statistics", data, 3);
}

/**
 * 触达 企微触达失败统计 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsFailQW(data) {
  return axiosPost("/reach/qw-fail/statistics", data, 3);
}

/**
 * 触达 触达人群消费到天指标统计 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsTodayStatistics(data) {
  return axiosPost("/reach/consume/today-statistics", data, 3);
}


/**
 * 触达 触达人群消费门店维度聚合指标统计 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsStorePolyStatistics(data) {
  return axiosPost("/reach/consume/store-poly-statistics", data, 3);
}

/**
 * 触达 触达人群消费聚合指标统计 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsPolyStatistics(data) {
  return axiosPost("/reach/consume/poly-statistics", data, 3);
}


/**
 * 触达 触达人群消费分组对比聚合指标统计 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsGroupCompareStatistics(data) {
  return axiosPost("/reach/consume/group-compare-statistics", data, 3);
}


/**
 * 触达 短信触达失败会员明细导出 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsSmsFailUserDetails(data) {
  return axiosPost("/reach/sms-fail/user-details/export", data, 3);
}

/**
 * 触达 企微触达失败会员明细导出 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsQWFailUserDetails(data) {
  return axiosPost("/reach/qw-fail/user-details/export", data, 3);
}

/**
 * 触达 企微触达失败门店统计明细导出 触达漏斗
 * @param data
 * @returns {*}
 */
export function getSmsQWFailStoreDetails(data) {
  return axiosPost("/reach/qw-fail/store-statistics/export", data, 3);
}

/**
 * 逻辑标签字段列表
 * @param data
 * @returns {*}
 */
export function getLogicTagColNameList() {
  return axiosGet("/basic/data/logic-tag/column-name/list", null, 3);
}



/**
 * 未触达任务统计 失败总人数 触达漏斗
 * @param data
 * @returns {*}
 */
export function getReachFailStatistics(data) {
  return axiosPost("/reach/fail/statistics", data, 3);
}

/**
 * 实际触达人群消费数据 时间-到天下载 触达漏斗
 * @param data
 * @returns {*}
 */
export function getConsumeTodayStatisticsExport(data) {
  return axiosPost("/reach/consume/today-statistics/export", data, 3);
}

/**
 * 实际触达人群消费数据 时间-聚合 全部门店下载 触达漏斗
 * @param data
 * @returns {*}
 */
export function getConsumePolyStatisticsExport(data) {
  return axiosPost("/reach/consume/poly-statistics/export", data, 3);
}

/**
 * 实际触达人群消费数据 时间-聚合 门店维度下载 触达漏斗
 * @param data
 * @returns {*}
 */
export function getConsumeStorePolyStatisticsExport(data) {
  return axiosPost("/reach/consume/store-poly-statistics/export", data, 3);
}

/**
 * 实际触达人群消费数据 分组下载 触达漏斗
 * @param data
 * @returns {*}
 */
export function getConsumeGroupCompareStatisticsExport(data) {
  return axiosPost("/reach/consume/group-compare-statistics/export", data, 3);
}



/**
 * 触达漏斗  分组详情
 * @param data
 * @returns {*}
 */
export function getConsumeRuleGroupDetails(data) {
  return axiosPost("/reach/rule-group/details", data, 3);
}

/**
 * 停止任务
 * @param data
 * @returns {*}
 */
export function stopTask(data) {
  return axiosPost("/rule-group/stop/task/" + data, {}, 3);
}

/**
 * 停止任务
 * @param data
 * @returns {*}
 */
export function startTask(data) {
  return axiosPost("/rule-group/start/task/" + data, {}, 3);
}

/**
 * 数值标签下拉列表
 * @param data
 * @returns {*}
 */
export function getNumTagList() {
  return axiosGet("/basic/data/num-tag/list", null, 3);
}

/**
 * 分发规则列表查询
 * @param data
 * @returns {*}
 */
export function queryGroupDistributeRulePageList(data) {
  return axiosPost("/group/distribute/rule/page/list", data, 3);
}

/**
 * 分组分发规则创建人列表
 * @returns {*}
 */
export function getGroupDistributeRuleCreatorList() {
  return axiosGet("/basic/data/group/distribute/rule/creator/list", {}, 3);
}

/**
 * 分组分发规则分组名称列表
 * @returns {*}
 */
export function getGroupDistributeRuleNameList() {
  return axiosGet("/basic/data/group/distribute/rule/name/list", {}, 3);
}

/**
 * 分组分发规则分组更新方式列表
 * @returns {*}
 */
export function getGroupDistributeRuleUpdateModeList() {
  return axiosGet("/basic/data/group/distribute/rule/update_mode/list", {}, 3);
}

/**
 * 分组分发规则详情
 * @returns {*}
 */
export function getGroupDistributeRuleDetail(data) {
  return axiosGet("/group/distribute/rule/" + data, {}, 3);
}

/**
 * 分组主题列表接口
 * @param data
 * @returns {*}
 */
export function getRuleGroupTopicList(data) {
  return axiosGet("/basic/data/rule-group/topic/list", data, 3);
}

/**
 * 分组新建方式列表接口
 * @param data
 * @returns {*}
 */
export function getRuleGroupCreateModeList(data) {
  return axiosGet("/basic/data/rule-group/create_mode/list", data, 3);
}

/**
 * 分组新建方式列表接口
 * @param data
 * @returns {*}
 */
export function getRuleGroupList(data) {
  return axiosGet("/basic/data/group/rule/list", data, 3);
}

/**
 * 分组更新方式列表
 * @param data
 * @returns {*}
 */
export function getRuleGroupTAutoUpdateFrequencyList(data) {
  return axiosGet("/basic/data/rule-group/auto_update_frequency/list", data, 3);
}

/**
 * 添加自定义企微群发消息内容
 * @param data
 * @returns {*}
 */
export function addCustomQwGroupMsgContent(data) {
  return axiosPost("/qw/group/msg/add/custom", data, 3);
}

/**
 * 获取自定义企微群发消息列表
 * @param data
 * @returns {*}
 */
export function queryCustomQwGroupMsgPageList(data) {
  return axiosPost("/qw/group/msg/custom/list", data, 3);
}

/**
 * 自定企微群发消息标题列表
 * @returns {*}
 */
export function getCustomQwGroupMsgMsgTopicList() {
  return axiosGet("/basic/data/custom/qw_group_msg/msg_topic/list", {}, 3);
}

/**
 * 获取自定义企微群发消息内容
 * @param data
 * @returns {*}
 */
export function getCustomQwGroupMsg(data) {
  return axiosGet("/qw/group/msg/get/custom/" + data, {}, 3);
}

/**
 * 标签示例
 * @param data
 * @returns {*}
 */
export function getTagExample(data) {
  return axiosGet("/tag/tag_example/" + data, null, 3);
}


/**
 * 触达人群消费分组对比到天指标统计
 * @param data
 * @returns {*}
 */
export function getGroupToDayStatistics(data) {
  return axiosPost("/reach/consume/group-today-statistics", data, 3);
}

/**
 * 触达人群消费分组对比到天指标统计 下载
 * @param data
 * @returns {*}
 */
export function getGroupToDayStatisticsExport(data) {
  return axiosPost("/reach/consume/group-today-statistics/export", data, 3);
}


/**
 * 触达 门店触达任务统计
 * @param data
 * @returns {*}
 */
export function getReachConsumeStoreTaskStatistics(data) {
  return axiosPost("/reach/consume/store-task/statistics", data, 3);
}

/**
 * 门店触达任务统计导出
 * @param data
 * @returns {*}
 */
export function getReachConsumeStoreTaskStatisticsExport(data) {
  return axiosPost("/reach/consume/store-task/statistics/export", data, 3);
}
