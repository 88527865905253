var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "问卷范围" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.orgRange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgRange", $$v)
                                },
                                expression: "queryForm.orgRange",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入问卷标题关键字",
                        },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "问卷类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.queryForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "type", $$v)
                            },
                            expression: "queryForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "考试", value: "KS" },
                          }),
                          _c("el-option", {
                            attrs: { label: "问卷", value: "WJ" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd("WJ")
                },
              },
            },
            [_vm._v("创建调研问卷")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd("KS")
                },
              },
            },
            [_vm._v("创建考试")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "问卷标题", prop: "title" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建者", prop: "createBy" },
              }),
              _c("el-table-column", {
                attrs: { label: "问卷类型", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.type === "KS" ? "考试" : "问卷")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "340", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.orgRange == _vm.queryForm.orgRange ||
                        _vm.authOrgAll ||
                        _vm.showButton
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row, "view")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        row.orgRange == _vm.queryForm.orgRange ||
                        _vm.authOrgAll ||
                        _vm.showButton
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDel(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleExport(row)
                              },
                            },
                          },
                          [_vm._v("导出问卷结果")]
                        ),
                        row.orgRange == _vm.queryForm.orgRange ||
                        _vm.authOrgAll ||
                        _vm.showButton
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCopy(row)
                                  },
                                },
                              },
                              [_vm._v("复制链接")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: {
                              size: "mini",
                              type: "text",
                              disabled: row.type !== "KS",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleQuestionExport(row)
                              },
                            },
                          },
                          [_vm._v("下载试题")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.saveDialog.titleMap[_vm.saveDialog.type],
            center: true,
            visible: _vm.saveDialog.visible,
            "append-to-body": "",
            width: "750px",
            "close-on-click-modal": false,
            "before-close": _vm.handleBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "divRef",
              staticStyle: { "overflow-y": "scroll", height: "500px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "saveFormRef",
                  attrs: {
                    size: "mini",
                    "label-width": "auto",
                    inline: true,
                    rules: _vm.saveDialog.rules,
                    model: _vm.saveDialog.form,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#1482f0",
                                  "font-size": "24px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.saveDialog.titleMap[_vm.saveDialog.type]
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "问卷标题", prop: "title" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入标题",
                                    },
                                    model: {
                                      value: _vm.saveDialog.form.title,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.saveDialog.form,
                                          "title",
                                          $$v
                                        )
                                      },
                                      expression: "saveDialog.form.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "分享文案", prop: "content" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder:
                                        "最多20字，例如“⻓线⼀盘货考试开始了，点击开始作答”",
                                    },
                                    model: {
                                      value: _vm.saveDialog.form.content,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.saveDialog.form,
                                          "content",
                                          $$v
                                        )
                                      },
                                      expression: "saveDialog.form.content",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "问卷时间",
                                    prop: "rangeTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "default-time": ["00:00:00", "23:59:59"],
                                    },
                                    model: {
                                      value: _vm.saveDialog.form.rangeTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.saveDialog.form,
                                          "rangeTime",
                                          $$v
                                        )
                                      },
                                      expression: "saveDialog.form.rangeTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.saveDialog.form.type === "KS"
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "是否随机" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.saveDialog.form.random,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.saveDialog.form,
                                                "random",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "saveDialog.form.random",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "N" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("不随机，展示全部题⽬")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { label: "random" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("随机抽取")]
                                              ),
                                              _c("el-input-number", {
                                                staticStyle: { width: "60px" },
                                                attrs: {
                                                  min: 0,
                                                  controls: false,
                                                  precision: 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.saveDialog.form
                                                      .randomNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.saveDialog.form,
                                                      "randomNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "saveDialog.form.randomNum",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "个题⽬，题⽬和选项都会随机展示"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "问卷范围",
                                    prop: "orgRange",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        "collapse-tags": "",
                                      },
                                      model: {
                                        value: _vm.saveDialog.form.orgRange,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.saveDialog.form,
                                            "orgRange",
                                            $$v
                                          )
                                        },
                                        expression: "saveDialog.form.orgRange",
                                      },
                                    },
                                    _vm._l(_vm.authOrg, function (item) {
                                      return _c("el-option", {
                                        key: item.key,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm.showButton
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "form-btn-item",
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckAll(true)
                                            },
                                          },
                                        },
                                        [_vm._v("全选")]
                                      )
                                    : _vm._e(),
                                  _vm.showButton
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "form-btn-item",
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckAll(false)
                                            },
                                          },
                                        },
                                        [_vm._v("取消全选")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.saveDialog.form.type === "KS"
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "考试时间限制",
                                        prop: "examTimeLimit",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "60px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder:
                                            "请输入考试时间限制,数字,单位分钟",
                                        },
                                        model: {
                                          value:
                                            _vm.saveDialog.form.examTimeLimit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.saveDialog.form,
                                              "examTimeLimit",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "saveDialog.form.examTimeLimit",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [_vm._v(" 分钟")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.saveDialog.form.type === "KS"
                            ? _c(
                                "el-col",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "考试次数限制" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value:
                                              _vm.saveDialog.form
                                                .examNumLimitLabel,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.saveDialog.form,
                                                "examNumLimitLabel",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "saveDialog.form.examNumLimitLabel",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "N" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("不限")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-radio",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                              },
                                              attrs: { label: "Y" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("限制")]
                                              ),
                                              _vm.saveDialog.form
                                                .examNumLimitLabel === "Y"
                                                ? _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "60px",
                                                    },
                                                    attrs: {
                                                      min: 0,
                                                      controls: false,
                                                      precision: 0,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.saveDialog.form
                                                          .examNumLimit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.saveDialog.form,
                                                          "examNumLimit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "saveDialog.form.examNumLimit",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("次")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-bottom": "30px",
                        "margin-top": "25px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#1482f0",
                                  "font-size": "24px",
                                },
                              },
                              [_vm._v("题目设置")]
                            ),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      margin: "auto 5px",
                                      position: "relative",
                                      bottom: "4px",
                                    },
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleImport()
                                      },
                                    },
                                  },
                                  [_vm._v("导入题目")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(
                            _vm.saveDialog.form.questions,
                            function (item, index) {
                              return _c(
                                "el-col",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "20px" },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-weight": "600" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(index + 1) +
                                              "." +
                                              _vm._s(
                                                _vm.questionMap[item.type]
                                              ) +
                                              "  "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: item.require,
                                            callback: function ($$v) {
                                              _vm.$set(item, "require", $$v)
                                            },
                                            expression: "item.require",
                                          },
                                        },
                                        [_vm._v("必答")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.saveDialog.form.type === "WJ"
                                              ? ""
                                              : "题目分数:"
                                          )
                                      ),
                                      _vm.saveDialog.form.type === "KS"
                                        ? _c("el-input-number", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              size: "small",
                                              controls: false,
                                              min: 0,
                                              precision: 0,
                                            },
                                            model: {
                                              value: item.score,
                                              callback: function ($$v) {
                                                _vm.$set(item, "score", $$v)
                                              },
                                              expression: "item.score",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "background-color":
                                                  "rgb(243 243 243)",
                                                "border-radius": "3px",
                                              },
                                              attrs: { span: 21 },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    margin: "5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [_vm._v("题目")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "180px",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      placeholder: "请输入题目",
                                                    },
                                                    model: {
                                                      value: item.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.title",
                                                    },
                                                  }),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticClass:
                                                        "form-btn-item",
                                                      attrs: {
                                                        size: "mini",
                                                        type: "text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSelectPhoto(
                                                            index,
                                                            undefined,
                                                            "question"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选择图片")]
                                                  ),
                                                  _c("OssUpload", {
                                                    ref: "ossUpload",
                                                    refInFor: true,
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      position:
                                                        "relative !important",
                                                      bottom: "7px",
                                                    },
                                                    attrs: {
                                                      "value-to": item.imgUrl,
                                                      "list-type":
                                                        "picture-card",
                                                      maxFileSize: 10240,
                                                      tipMessageShow: false,
                                                      buttonName: "上传图片",
                                                      buttonType: "text",
                                                      "accept-type": [
                                                        ".jpg",
                                                        ".jpeg",
                                                        ".gif",
                                                        ".png",
                                                      ],
                                                    },
                                                    on: {
                                                      "update:valueTo":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "imgUrl",
                                                            $event
                                                          )
                                                        },
                                                      "update:value-to":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "imgUrl",
                                                            $event
                                                          )
                                                        },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              item.type !== "blank"
                                                ? _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "30px",
                                                        "margin-bottom": "2px",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.chooses,
                                                      function (ele, i) {
                                                        return _c(
                                                          "el-col",
                                                          { key: i },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "font-weight":
                                                                    "600",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "选项" +
                                                                    _vm._s(
                                                                      i + 1
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "180px",
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                                placeholder:
                                                                  "请输入选项内容",
                                                              },
                                                              model: {
                                                                value:
                                                                  ele.title,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      ele,
                                                                      "title",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "ele.title",
                                                              },
                                                            }),
                                                            _c("el-button", {
                                                              staticStyle: {
                                                                margin: "0px",
                                                              },
                                                              attrs: {
                                                                icon: "el-icon-plus",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  item.chooses.splice(
                                                                    i + 1,
                                                                    0,
                                                                    _vm.deepClone(
                                                                      _vm
                                                                        .saveDialog
                                                                        .defaultChoose
                                                                    )
                                                                  )
                                                                },
                                                              },
                                                            }),
                                                            _c("el-button", {
                                                              staticStyle: {
                                                                margin: "0px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  item.chooses
                                                                    .length ===
                                                                  1,
                                                                icon: "el-icon-minus",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  item.chooses.splice(
                                                                    i,
                                                                    1
                                                                  )
                                                                },
                                                              },
                                                            }),
                                                            _c("el-button", {
                                                              staticStyle: {
                                                                margin: "0px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  i === 0,
                                                                icon: "el-icon-top",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  item.chooses.splice(
                                                                    i,
                                                                    1
                                                                  )
                                                                  item.chooses.splice(
                                                                    i - 1,
                                                                    0,
                                                                    ele
                                                                  )
                                                                },
                                                              },
                                                            }),
                                                            _c("el-button", {
                                                              staticStyle: {
                                                                margin: "0px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  i ===
                                                                  item.chooses
                                                                    .length -
                                                                    1,
                                                                icon: "el-icon-bottom",
                                                                size: "mini",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  item.chooses.splice(
                                                                    i,
                                                                    1
                                                                  )
                                                                  item.chooses.splice(
                                                                    i + 1,
                                                                    0,
                                                                    ele
                                                                  )
                                                                },
                                                              },
                                                            }),
                                                            _vm.saveDialog.form
                                                              .type === "KS"
                                                              ? _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "10px",
                                                                      },
                                                                    model: {
                                                                      value:
                                                                        ele.answer,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            ele,
                                                                            "answer",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "ele.answer",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "正确答案"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "form-btn-item",
                                                                attrs: {
                                                                  size: "mini",
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleSelectPhoto(
                                                                        index,
                                                                        i,
                                                                        "choose",
                                                                        ele.title
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "选择图片"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("OssUpload", {
                                                              ref: "ossUpload",
                                                              refInFor: true,
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                position:
                                                                  "relative !important",
                                                                bottom: "7px",
                                                              },
                                                              attrs: {
                                                                "value-to":
                                                                  ele.imgUrl,
                                                                "list-type":
                                                                  "picture-card",
                                                                maxFileSize: 10240,
                                                                tipMessageShow: false,
                                                                "accept-type": [
                                                                  ".jpg",
                                                                  ".jpeg",
                                                                  ".gif",
                                                                  ".png",
                                                                ],
                                                                buttonName:
                                                                  "上传图片",
                                                                buttonType:
                                                                  "text",
                                                              },
                                                              on: {
                                                                "update:valueTo":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      ele,
                                                                      "imgUrl",
                                                                      $event
                                                                    )
                                                                  },
                                                                "update:value-to":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      ele,
                                                                      "imgUrl",
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: () =>
                                                              _vm.handleRemoveRow(
                                                                index
                                                              ),
                                                          },
                                                        },
                                                        [_vm._v("移除")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              index === 0,
                                                            size: "mini",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: () => {
                                                              _vm.saveDialog.form.questions.splice(
                                                                index,
                                                                1
                                                              )
                                                              _vm.saveDialog.form.questions.splice(
                                                                index - 1,
                                                                0,
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("上移")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              index ===
                                                              _vm.saveDialog
                                                                .form.questions
                                                                .length -
                                                                1,
                                                            size: "mini",
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: () => {
                                                              _vm.saveDialog.form.questions.splice(
                                                                index,
                                                                1
                                                              )
                                                              _vm.saveDialog.form.questions.splice(
                                                                index + 1,
                                                                0,
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("下移")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c("el-row", {
                        staticStyle: { position: "absolute", bottom: "10px" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    position: "absolute",
                    bottom: "5px",
                    width: "90%",
                    "background-color": "white",
                    height: "30px",
                  },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "text-align": "left",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddQuestion("single")
                            },
                          },
                        },
                        [_vm._v("添加单选题")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddQuestion("multiple")
                            },
                          },
                        },
                        [_vm._v("添加多选题")]
                      ),
                      _vm.saveDialog.type === "WJ"
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddQuestion("blank")
                                },
                              },
                            },
                            [_vm._v("添加填空题")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "inline-block", float: "right" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.buttonDisabled,
                            type: "primary",
                            size: "mini",
                          },
                          on: { click: _vm.handleSave },
                        },
                        [_vm._v("提交")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.saveDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品图片(双击选择的图片)",
            visible: _vm.photoDialog.visible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.photoDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                rules: _vm.photoDialog.rules,
                size: "mini",
                inline: true,
                model: _vm.photoDialog.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品型号",
                            prop: "skuModel",
                            "label-width": "110px",
                          },
                        },
                        [
                          _c("DictSelect", {
                            ref: "modelDict",
                            attrs: {
                              type: "",
                              value: _vm.photoDialog.form.skuModel,
                              dictFun: _vm.dictSkuModels,
                              fullLabel: "",
                              remote: "",
                              "init-props": {
                                value: "skuModel",
                                label: "skuName",
                              },
                              version: "v3",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.photoDialog.form,
                                  "skuModel",
                                  $event
                                )
                              },
                              change: (val) => _vm.handleChangeModel(val),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "商品图片", "label-width": "110px" },
                        },
                        [
                          _c(
                            "el-row",
                            _vm._l(
                              _vm.photoDialog.form.skuUrls,
                              function (item) {
                                return _c(
                                  "el-col",
                                  {
                                    key: item,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { span: 12 },
                                  },
                                  [
                                    _c("el-image", {
                                      attrs: { src: item },
                                      on: {
                                        dblclick: function ($event) {
                                          return _vm.handleDbClickModel(item)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                          _vm.photoDialog.form.skuUrls.length === 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    margin: "10% 0",
                                  },
                                },
                                [_vm._v(" 暂无图片 ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.photoDialog.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.photoDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }