<template>
  <section class="app-main" v-if="isShowApp">
      <transition name="fade-transform" mode="out-in">
          <router-view />
      </transition>
  </section>
</template>

<script>
export default {
    name: 'AppMain',
    data() {
        return {
            isShowApp: false
        }
    },
    created() {
        this.isShowApp = true;
    },
    methods: {

    }
}
</script>

<style lang="scss">
    .app-main {
        min-height: calc(100vh - 50px);
        width: 100%;
        position: relative;
        overflow: hidden;
        padding: 5px;
        ::v-deep .el-tabs__nav {
            .el-tabs__item {
                padding-left: 0;
            }
        }
    }
</style>
