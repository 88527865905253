<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="order-org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc"
            :dictFun="dictFun"
            fullLabel
            default-first-option
            :default-index="0"
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="order-dc"
            placeholder="全部"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            :clearable="true"
            alwaysChange
            @getOtherJsonParam="(callback) => callback({orgCode:queryForm.orgCode})"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
    <el-table
      :data="pageData.rows"
      size="small"
       class="customer-table"
      border
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column label="分公司编码" prop="orgCode"  width="150"/>
      <el-table-column label="分公司名" prop="orgName"  width="150"/>
      <el-table-column label="配送中心编码" prop="dcCode"  width="150"/>
      <el-table-column label="配送中心名称" prop="dcName" width="200"  />
      <el-table-column label="清单商品数量" prop="skuCount"  width="150"/>
      <el-table-column label="补货周期" >
        <template #default="{ row }">
          <span v-if="row.supplyTimeType === 'week'">每周{{replaceWeekDay(row.supplyTimeDayWeek)}}</span>
          <span v-if="row.supplyTimeType === 'mth'">每月{{row.supplyTimeDay}}号</span>
          <span v-if="row.supplyTimeType === 'half-mth'">每半月</span>
        </template>
      </el-table-column>
      <el-table-column label="补货规格" prop="supplySpec"  >
        <template #default="{ row }">
          <span>{{options.supplySpecOptions.filter((m) => m.value === row.supplySpec)[0]?.label }}</span>
        </template>
      </el-table-column>
      <el-table-column label="起订量" prop="supplyThreshold"  width="180">
        <template #default="{ row }">
          <span v-if="row.supplySpec === 'bottom'">&lt;{{row.supplyThreshold}}箱{{row.supplyBoxTransMid === 1 ? '按中包装补货' : '不补货' }}<br>&ge;{{row.supplyThreshold}}箱补一箱</span>
          <span v-if="row.supplySpec === 'mid'">1中包装</span>
        </template>
      </el-table-column>
      <el-table-column label="补货模式" prop="supplyDays"  >
        <template #default="{ row }">
          <span>{{row.modeType === '1' ? '自动补货' : '手动补货'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="最后操作人" prop="updateUser" width="200">
        <template #default="{ row }">
          <span>{{row.updateUser}}<br>{{row.updateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280px" align="center" fixed="right">
        <template #default="{ row }">
          <el-button size="mini" type="text" @click="openSkuDetail(row)">商品清单</el-button>
          <el-button size="mini" type="text" @click="openConfig(row)">补货设置</el-button>
          <el-button size="mini" type="text" @click="openSuggest(row)">补货商品推荐</el-button>
          <el-button size="mini" type="text" @click="openSupplyConfirm(row)">补货确认单</el-button>
          <el-button size="mini" type="text" @click="openLog(row)">操作日志</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="商品清单" :visible.sync="skuDetail.visible" append-to-body width="1200px" height="600px" :close-on-click-modal="false">
      <el-form ref="skuDetail" size="mini" label-width="auto" :inline="true" :model="skuDetail.queryForm" @keyup.enter.native="handleQuerySkuDetail">
        <el-form-item label="分公司">
          <DictSelect
            ref="skuDetailOrg"
            type="order-org"
            :value.sync="skuDetail.queryForm.orgCode"
            linkageRef="skuDetailDc"
            :dictFun="dictFun"
            fullLabel
            default-first-option
            :default-index="0"
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="skuDetailDc"
            type="order-dc"
            placeholder="全部"
            :value.sync="skuDetail.queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            :default-index="0"
            :clearable="true"
            alwaysChange
            @getOtherJsonParam="(callback) => callback({orgCode:skuDetail.queryForm.orgCode})"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="档位">
          <el-select v-model="skuDetail.queryForm.skuLevel" clearable placeholder="全部">
            <el-option v-for="item in options.skuLevelOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类">
          <GoodCategory @selected="querySkuTreeNodeClick"></GoodCategory>
        </el-form-item>
        <el-form-item label="是否238">
          <el-select v-model="skuDetail.queryForm.if238" clearable placeholder="全部">
            <el-option v-for="item in options.if238Options" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="新老品">
          <el-select v-model="skuDetail.queryForm.isNewSku" clearable placeholder="全部">
            <el-option v-for="item in options.isNewSkuOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="搜索商品">
          <el-input v-model="skuDetail.queryForm.searchKey" size="mini" clearable placeholder="输入商品名、商品编码" />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilterSkuDetail">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="skuDetail.importDialog.visible = true">导入商品</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportSkuDetail">导出商品</el-button>
          <el-button class="form-btn-item edit" size="mini" icon="el-icon-s-operation" type="warning" @click="openBatchUpdateSku">批量编辑</el-button>
          <el-button class="form-btn-item edit" size="mini" icon="el-icon-delete" type="warning" @click="handleBatchRemoveSku">批量删除</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="skuDetail.pageData.rows"
        height="calc(100vh - 250px)"
        v-loading="skuDetail.loading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
        @sort-change="handleSortChange"
        max-height="340px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="30"/>
        <el-table-column label="分公司编码" prop="orgCode" width="80" align="center" />
        <el-table-column label="分公司名" prop="orgName" width="80" align="center" />
        <el-table-column label="配送中心编码" prop="dcCode" width="90" align="center" />
        <el-table-column label="配送中心名称" prop="dcName" width="90" align="center" />
        <el-table-column label="商品编码" prop="skuCode" width="80" align="center" />
        <el-table-column label="商品名" prop="skuName" width="300" align="center" />
        <el-table-column label="档位" prop="skuLevel" width="40" align="center" />
        <el-table-column label="近半年销量" prop="last180XsCount" width="100" align="center">
          <template slot="header">
            <el-tooltip class="item"
                        effect="dark"
                        content="不含大仓出货数据"
                        placement="top-start">
              <span>近半年销量<i class="el-icon-question"></i></span>
            </el-tooltip>
          </template>
          <template #default="{ row }">
            <span>{{row.last180XsCountBox.toFixed(2)}}箱({{row.last180XsShopCount}}客户) </span>
          </template>
        </el-table-column>
        <!--      <el-table-column label="配送中心库存" prop="dcKcCount" width="200" align="center">-->
        <!--        <template #default="{ row }">-->
        <!--          <span>{{row.dcKcCountBox.toFixed(2)}}箱(够卖{{row.kcCanSaleDay}}天) </span>-->
        <!--        </template>-->
        <!--      </el-table-column>-->
        <el-table-column label="配送中心库存" prop="dcKcCountBox" sortable="custom" width="120" align="center">
          <template #default="{ row }">
            <span>{{row.dcKcCountBox.toFixed(2)}}箱 </span>
          </template>
        </el-table-column>
        <el-table-column label="库存够卖天数" prop="kcCanSaleDay" sortable="custom" width="120" align="center">
          <template #default="{ row }">
            <span>{{row.kcCanSaleDay}}天 </span>
          </template>
        </el-table-column>
        <el-table-column label="日常补货天数" prop="supplyDays" width="100" align="center" />
        <el-table-column label="学汛补货天数" prop="xxSupplyDays" width="100" align="center" />
        <el-table-column label="修改人" prop="updateUser" width="100" align="center" />
        <el-table-column label="修改时间" prop="updateTime" width="150" align="center" />
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template #default="{ row }">
            <el-button size="mini" type="text" style="border: 0px; width: 20%;margin-right: 15px" @click="handleEditSku(row)">编辑</el-button>
            <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleRemoveSku(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="skuDetail.pageData.total" :page.sync="skuDetail.queryForm.page" :limit.sync="skuDetail.queryForm.limit" @pagination="handleQuerySkuDetail" />
    </el-dialog>

    <el-dialog title="编辑商品" :visible.sync="skuDetail.saveDialog.visible" append-to-body width="400px">
      <el-form ref="editSku" size="mini" inline :rules="skuDetail.saveDialog.rules" label-width="160px" :model="skuDetail.saveDialog.form" class="common-form">
        <el-form-item label="档位">
          <el-select v-model="skuDetail.saveDialog.form.skuLevel">
            <el-option v-for="item in options.skuLevelOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="日常补货" prop="supplyDays">
          <el-col :span="10">
            <el-input type="number" v-model="skuDetail.saveDialog.form.supplyDays" @input.native="skuDetail.saveDialog.form.supplyDays = skuDetail.saveDialog.form.supplyDays.replace(/^(0+)|[^\d]+/g,'')"></el-input>
          </el-col>
          <el-col :span="5">天库存</el-col>
        </el-form-item>
        <el-form-item label="学汛补货" prop="xxSupplyDays">
          <el-col :span="10">
            <el-input type="number" v-model="skuDetail.saveDialog.form.xxSupplyDays" @input.native="skuDetail.saveDialog.form.xxSupplyDays = skuDetail.saveDialog.form.xxSupplyDays.replace(/^(0+)|[^\d]+/g,'')"></el-input>
          </el-col>
          <el-col :span="5">天库存</el-col>
        </el-form-item>
        <el-form-item label="修改人" prop="updateUser">
          <span>{{skuDetail.saveDialog.form.updateUser}}</span>
        </el-form-item>
        <el-form-item label="修改时间" prop="updateTime">
          <span>{{skuDetail.saveDialog.form.updateTime}}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="submitEditSku">确定</el-button>
        <el-button type="primary" size="mini" @click="skuDetail.saveDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量编辑商品" :visible.sync="skuDetail.batchUpdate.visible" append-to-body width="400px">
      <el-form ref="batchUpdateSku" size="mini" inline :rules="skuDetail.batchUpdate.rules" label-width="160px" :model="skuDetail.batchUpdate.form" class="common-form">
        <el-form-item label="档位">
          <el-select v-model="skuDetail.batchUpdate.form.skuLevel">
            <el-option v-for="item in options.skuLevelOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="日常补货" prop="supplyDays">
          <el-col :span="10">
            <el-input type="number" v-model="skuDetail.batchUpdate.form.supplyDays" @input.native="skuDetail.batchUpdate.form.supplyDays = skuDetail.batchUpdate.form.supplyDays.replace(/^(0+)|[^\d]+/g,'')"></el-input>
          </el-col>
          <el-col :span="5">天库存</el-col>
        </el-form-item>
        <el-form-item label="学汛补货" prop="xxSupplyDays">
          <el-col :span="10">
            <el-input type="number" v-model="skuDetail.batchUpdate.form.xxSupplyDays" @input.native="skuDetail.batchUpdate.form.xxSupplyDays = skuDetail.batchUpdate.form.xxSupplyDays.replace(/^(0+)|[^\d]+/g,'')"></el-input>
          </el-col>
          <el-col :span="5">天库存</el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="submitBatchUpdateSku">确定</el-button>
        <el-button type="primary" size="mini" @click="skuDetail.batchUpdate.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="skuDetail.importDialog.title" :visible.sync="skuDetail.importDialog.visible" append-to-body width="1000px" height="800px">
      <el-row>
        <el-col :span="5" align="right" style="font-weight: bold">文件：</el-col>
        <el-col :span="10">
          <el-upload
            ref="goodsUpload"
            class="upload-demo"
            drag
            :limit="1"
            action="#"
            :auto-upload="false"
            :file-list="skuDetail.importDialog.fileList"
            :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
            :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              <span>仅允许导入xls、xlsx格式文件。</span>
              <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="skuDetail.importDialog.templateFileDownloadUrl">下载模板</el-link>
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="15" :offset="5" align="left">
          <div class="previewTable previewTable-sample">
            <mg-signboard title="文件上传模板示例"/>
            <el-table
              class="previewTable-lists"
              :data="skuDetail.importDialog.templateSampleLists"
              stripe
              border
              size="mini"
            >
              <template v-for="(item, index) in skuDetail.importDialog.templateSampleHead">
                <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center">
                  <template slot="header">
                    <el-tooltip v-if="item.prop === 'skuLevel'"
                                class="item"
                                effect="dark"
                                content="A档(长线品，不经常调整)，B档(经常调整的商品，需定期维护)"
                                placement="top-start"
                    >
                      <span>{{item.label}}<i class="el-icon-question"></i></span>
                    </el-tooltip>
                    <span v-else>{{item.label}}</span>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col :span="5" align="right" style="font-weight: bold">导入方式：</el-col>
        <el-col :span="10">
          <el-radio-group v-model="skuDetail.importDialog.importType">
            <el-radio :label="1">新增</el-radio>
            <el-radio :label="2">覆盖</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px; margin-bottom: 20px">
        <el-col :span="10" :offset="5" align="left">
          <el-button size="mini" type="success" @click="handleImport">导入</el-button>
          <el-button size="mini" type="info" @click="skuDetail.importDialog.visible = false">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="补货设置" :visible.sync="supplyConfig.visible" width="400px" append-to-body :close-on-click-modal="false">
      <el-form ref="supplyConfig" class="common-form" inline :model="supplyConfig.form" label-width="100px" :rules="supplyConfig.rules">
        <el-row class="common-form-row">
          <el-col :span="24" :offset="8">
            <el-form-item label="" prop="modeType" size="mini">
              <el-radio-group v-model="supplyConfig.form.modeType" text-color="white" fill="red">
                <el-radio-button label="1">自动补货</el-radio-button>
                <el-radio-button label="2">手动补货</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="common-form-row" v-if="this.supplyConfig.form.modeType === '2'">
          <el-col :span="20" :offset="4" style="font-weight: bold; color: red; margin-bottom: 20px;margin-top: 20px">请在聚宝盆App上手动确认发起补货单</el-col>
        </el-row>
        <!--        <el-row class="common-form-row" v-if="this.supplyConfig.form.modeType === '1'">-->
        <!--          <el-col :span="24" :offset="8">-->
        <!--            <el-form-item label="" prop="autoSwitch" size="mini">-->
        <!--                <p v-if="this.supplyConfig.form.autoSwitch === 0">{{  this.supplyConfig.text.closeText }} <el-button type="text" @click="supplyConfig.form.autoSwitch = 1">开启</el-button></p>-->
        <!--                <p v-else>{{  this.supplyConfig.text.openText }} <el-button type="text" @click="supplyConfig.form.autoSwitch = 0">停止</el-button></p>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-row class="bh-row">
          <el-col :span="10" align="right" class="bh-label">补货周期：</el-col>
          <el-col :span="4">
            <el-select v-model="supplyConfig.form.supplyTimeType" size="mini" >
              <el-option v-for="item in options.supplyTimeTypeOptions" :label="item.label" :value="item.value" :key="item.value" />
            </el-select>
          </el-col>
          <el-col :span="6" key="supplyTimeDayListForWeek" v-if="supplyConfig.form.supplyTimeType === 'week'">
            <el-select multiple v-model="supplyConfig.form.supplyTimeDayListForWeek" size="mini">
              <el-option v-for="item in options.supplyTimeDayOptions" :label="item.label" :value="item.value" :key="item.value" />
            </el-select>
          </el-col>
          <el-col :span="6" key="supplyTimeDay" v-if="supplyConfig.form.supplyTimeType === 'mth'" >
            <el-select v-model="supplyConfig.form.supplyTimeDay" size="mini">
              <el-option v-for="item in options.supplyTimeMonthDayOptions" :label="item.label" :value="item.value" :key="item.value" />
            </el-select>
          </el-col>
        </el-row>
        <el-row class="bh-row" >
          <el-col :span="10" align="right" class="bh-label">补货规格：</el-col>
          <el-col :span="5">
            <el-select v-model="supplyConfig.form.supplySpec" size="mini" @change="selectTrigger(supplyConfig.form.supplySpec)">
              <el-option v-for="item in options.supplySpecOptions" :label="item.label" :value="item.value" :key="item.value" />
            </el-select>
          </el-col>
        </el-row>
        <el-row class="bh-row" v-if="supplyConfig.form.supplySpec === 'bottom'">
          <el-col :span="10" align="right" class="bh-label">阈值：</el-col>
          <el-col :span="1.5" align="right" style="margin-top: 5px">&lt;</el-col>
          <el-col :span="4">
            <el-form-item prop="supplyThreshold" size="mini">
              <el-input type="number" v-model="supplyConfig.form.supplyThreshold"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="margin-top: 5px">箱</el-col>
          <el-col :span="8">
            <el-select v-model="supplyConfig.form.supplyBoxTransMid" size="mini">
              <el-option v-for="item in options.supplySpecThresholdOptions" :label="item.label" :value="item.value" :key="item.value" />
            </el-select>
          </el-col>
        </el-row>
        <el-row class="bh-row" v-if="supplyConfig.form.supplySpec === 'bottom'">
          <el-col :offset="10" :span="1.5" align="right" style="margin-top: 5px">>=</el-col>
          <el-col :span="4">
            <el-form-item prop="supplyThreshold" size="mini">
              <el-input type="number" v-model="supplyConfig.form.supplyThreshold"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="supplyConfig.form.supplySpec === 'bottom'" :span="6" style="margin-top: 5px">箱则补1箱</el-col>
          <el-col v-else :span="4" style="margin-top: 5px">
            {{ options.supplySpecOptions.filter((m) => m.value === supplyConfig.form.supplySpec)[0].label }}
          </el-col>
        </el-row>
        <el-row class="bh-row" v-else>
          <el-col :span="10" align="right" class="bh-label">起订量：</el-col>
          <el-col :span="4">
            <el-form-item prop="supplyThreshold" size="mini">
              <el-input type="number" v-model="supplyConfig.form.supplyThreshold"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="supplyConfig.form.supplySpec === 'bottom'" :span="6" style="margin-top: 5px">箱则补1箱</el-col>
          <el-col :span="4" style="margin-top: 5px">
            {{ options.supplySpecOptions.filter((m) => m.value === supplyConfig.form.supplySpec)[0].label }}
          </el-col>
        </el-row>
        <el-row class="bh-row">
          <el-col :span="10" align="right" class="bh-label">修改人：</el-col>
          <el-col :span="5" style="margin-top: 5px">
            <span style="margin-top: 5px">{{supplyConfig.form.updateUser === null ? supplyConfig.form.createUser : supplyConfig.form.updateUser}}</span>
          </el-col>
        </el-row>
        <el-row class="bh-row">
          <el-col :span="10" align="right" class="bh-label">修改时间：</el-col>
          <el-col :span="12" style="margin-top: 5px">
            <span>{{supplyConfig.form.updateTime === null ? supplyConfig.form.createTime : supplyConfig.form.updateTime}}</span>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="supplyConfig.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitSupplyConfig">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="supplySuggest.visible" custom-class="previewDialog" append-to-body width="1000px" :close-on-click-modal="false" max-height="400px">
      <el-tabs type="border-card" value="first" :stretch="true">
        <el-tab-pane label="238及其他重点" name="first">
          <el-form size="mini" label-width="auto" :inline="true" :model="supplySuggest.queryForm1" @keyup.enter.native="querySuggest1">
            <el-row class="common-form-row">
              <el-col :span="24" class="bh-tip">总部推荐清单，含238商品及其他重点产品，可供参考挑选需自动补货的商品</el-col>
            </el-row>
            <el-form-item label="商品分类">
              <GoodCategory @selected="supplySuggestTreeNodeClick1"></GoodCategory>
            </el-form-item>
            <el-form-item label="是否238">
              <el-select v-model="supplySuggest.queryForm1.if238" clearable placeholder="全部">
                <el-option v-for="item in options.if238Options" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="新老品">
              <el-select v-model="supplySuggest.queryForm1.isNewSku" clearable placeholder="全部">
                <el-option v-for="item in options.isNewSkuOptions" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="搜索商品">
              <el-input v-model="supplySuggest.queryForm1.searchKey" size="mini" clearable placeholder="输入商品名、商品编码" />
            </el-form-item>
            <el-form-item>
              <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="querySuggest1">查询</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportSuggest1">导出</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="supplySuggest.data1" :v-loading="supplySuggest.loading" size="mini" height="300px">
            <el-table-column label="排序" prop="sort" width="50" align="center"/>
            <el-table-column label="商品编码" prop="skuCode" width="80" align="center"/>
            <el-table-column label="所属型号" prop="jbpSpuCode" width="80" align="center"/>
            <el-table-column label="商品名" prop="skuName" width="200" align="center"/>
            <el-table-column width="100" align="center">
              <template slot="header">
                <el-tooltip class="item"
                            effect="dark"
                            content="不含大仓出货数据"
                            placement="top-start">
                  <span>近半年销量<i class="el-icon-question"></i></span>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                <span>{{(row.l180XsCount)}}<br>({{(row.l180XsCountBox).toFixed(2)}}箱)</span>
              </template>
            </el-table-column>
            <el-table-column label="近半年销售店铺数" prop="l180XsShopCount" width="120" align="center" />
            <el-table-column label="月均销量" prop="monthlyXsCountBox" width="100" align="center">
              <template #default="{ row }">
                <span>{{(row.monthlyXsCountBox).toFixed(2)}}箱</span>
              </template>
            </el-table-column>
            <el-table-column label="配送中心库存" prop="dcKcCountBox" width="100" align="center">
              <template #default="{ row }">
                <span>{{(row.dcKcCountBox).toFixed(2)}}箱</span>
              </template>
            </el-table-column>
            <el-table-column label="分公司库存" prop="orgKcStr" width="80" align="center"/>
            <el-table-column label="新老品" prop="isNew" width="80" align="center"/>
            <el-table-column label="是否238" prop="is238" width="80" align="center"/>
          </el-table>
          <Pagination :total="supplySuggest.pageData1.total" :page.sync="supplySuggest.queryForm1.page" :limit.sync="supplySuggest.queryForm1.limit" @pagination="querySuggest1" />
        </el-tab-pane>
        <el-tab-pane label="其他商品" name="second">
          <el-form size="mini" label-width="auto" :inline="true" :model="supplySuggest.queryForm" @keyup.enter.native="querySuggest">
            <el-row class="common-form-row">
              <el-col :span="24" class="bh-tip">本配送中心近半年(180天)销量前1000名的商品清单(SKU)，可供参考挑选需自动补货的商品</el-col>
            </el-row>
            <el-form-item label="商品分类">
              <GoodCategory @selected="supplySuggestTreeNodeClick"></GoodCategory>
            </el-form-item>
            <el-form-item label="是否238">
              <el-select v-model="supplySuggest.queryForm.if238" clearable placeholder="全部">
                <el-option v-for="item in options.if238Options" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="新老品">
              <el-select v-model="supplySuggest.queryForm.isNewSku" clearable placeholder="全部">
                <el-option v-for="item in options.isNewSkuOptions" :label="item.label" :value="item.value" :key="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="搜索商品">
              <el-input v-model="supplySuggest.queryForm.searchKey" size="mini" clearable placeholder="输入商品名、商品编码" />
            </el-form-item>
            <el-form-item>
              <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="querySuggest">查询</el-button>
              <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportSuggest">导出</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="supplySuggest.data" :v-loading="supplySuggest.loading" size="mini" height="300px">
            <el-table-column label="排序" prop="sort" width="50" align="center"/>
            <el-table-column label="商品编码" prop="skuCode" width="80" align="center"/>
            <el-table-column label="所属型号" prop="jbpSpuCode" width="80" align="center"/>
            <el-table-column label="商品名" prop="skuName" width="200" align="center"/>
            <el-table-column label="近半年销量" prop="l180XsCountBox" width="100" align="center">
              <template slot="header">
                <el-tooltip class="item"
                            effect="dark"
                            content="不含大仓出货数据"
                            placement="top-start">
                  <span>近半年销量<i class="el-icon-question"></i></span>
                </el-tooltip>
              </template>
              <template #default="{ row }">
                <span>{{(row.l180XsCount)}}<br>({{(row.l180XsCountBox).toFixed(2)}}箱)</span>
              </template>
            </el-table-column>
            <el-table-column label="近半年销售店铺数" prop="l180XsShopCount" width="120" align="center" />
            <el-table-column label="月均销量" prop="monthlyXsCountBox" width="100" align="center">
              <template #default="{ row }">
                <span>{{(row.monthlyXsCountBox).toFixed(2)}}箱</span>
              </template>
            </el-table-column>
            <el-table-column label="配送中心库存" prop="dcKcCountBox" width="100" align="center">
              <template #default="{ row }">
                <span>{{(row.dcKcCountBox).toFixed(2)}}箱</span>
              </template>
            </el-table-column>
            <el-table-column label="分公司库存" prop="orgKcStr" width="80" align="center"/>
            <el-table-column label="新老品" prop="isNew" width="80" align="center"/>
            <el-table-column label="是否238" prop="is238" width="80" align="center"/>
          </el-table>
          <Pagination :total="supplySuggest.pageData.total" :page.sync="supplySuggest.queryForm.page" :limit.sync="supplySuggest.queryForm.limit" @pagination="querySuggest" />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog title="补货确认单" :visible.sync="supplyConfirm.visible" append-to-body width="1000px" :close-on-click-modal="false" max-height="400px">
      <el-form ref="supplyConfirm" size="mini" label-width="auto" :inline="true" :model="supplyConfirm.queryForm" @keyup.enter.native="handleQueryRestocking">
        <el-row class="common-form-row">
          <el-col :span="24" class="bh-tip">如补货模式为手动补货，请在聚宝盆App上确认发起补货单（自动补货则无需确认）</el-col>
        </el-row>
        <el-form-item label="档位">
          <el-select v-model="supplyConfirm.queryForm.skuLevel" clearable placeholder="全部">
            <el-option v-for="item in options.skuLevelOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类">
          <GoodCategory @selected="supplyConfirmTreeNodeClick"></GoodCategory>
        </el-form-item>
        <el-form-item label="是否238">
          <el-select v-model="supplyConfirm.queryForm.if238" clearable placeholder="全部">
            <el-option v-for="item in options.if238Options" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="新老品">
          <el-select v-model="supplyConfirm.queryForm.isNewSku" clearable placeholder="全部">
            <el-option v-for="item in options.isNewSkuOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="搜索商品">
          <el-input v-model="supplyConfirm.queryForm.searchKey" size="mini" clearable placeholder="输入商品名、商品编码" />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQueryRestocking">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportRestocking">导出</el-button>
        </el-form-item>
      </el-form>
      <span style="font-size: 16px; float: right; color: red">汇总：{{ supplyConfirm.skuCount }}个sku, 共计￥{{ supplyConfirm.saleTotal }}</span>
      <el-table :data="supplyConfirm.data" v-loading="supplyConfirm.loading" size="mini" height="300px">
        <el-table-column label="商品编码" prop="skuCode" width="100" align="center" />
        <el-table-column label="商品名" prop="skuName" align="center" />
        <el-table-column label="本次预计补货数量(PCS)" prop="status" width="140" align="center">
          <template #default="{ row }">
            <span style="color: red">{{row.skuNum}}<br>({{parseInt(row.unFixedSupplyNum / row.bottomSpec) > 0 ? (parseInt(row.unFixedSupplyNum / row.bottomSpec)+'箱') : ''}}{{(row.unFixedSupplyNum % row.bottomSpec/row.midSpec).toFixed(2)>0? (Math.ceil(row.unFixedSupplyNum % row.bottomSpec/row.midSpec)+'中包装'):''}})</span>
          </template>
        </el-table-column>
        <el-table-column label="本次预计补货金额" prop="status" width="120" align="center">
          <template #default="{ row }">
            <span style="color: red">￥{{(row.skuNum * row.price).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="档位" prop="skuLevel" width="40" align="center">
          <template #default="{ row }">
            <span>{{row.skuLevel}}档</span>
          </template>
        </el-table-column>
        <el-table-column :label="currentSupplyType" width="100" align="center">
          <template #default="{ row }">
            <span>{{row.currentSupplyDays}}天</span>
          </template>
        </el-table-column>
        <el-table-column label="近半年日均销量(PCS)" prop="dailyAvgXsCount" width="140" align="center">
          <template #default="{ row }">
            <span>{{row.dailyAvgXsCount.toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="我未发给终端" prop="wfCntBox" width="100" align="center">
          <template #default="{ row }">
            <span>{{row.wfCntBox?.toFixed(2)}}箱</span>
          </template>
        </el-table-column>
        <el-table-column label="配送中心库存" prop="dcKcCount" width="100" align="center">
          <template #default="{ row }">
            <span>{{row.dcKcCountBox?.toFixed(2)}}箱<br>({{row.dcKcCount}})</span>
          </template>
        </el-table-column>
        <el-table-column label="够卖天数" prop="dcKcCount" width="100" align="center">
          <template #default="{ row }">
            <span>够卖{{row.kcCanSaleDay}}天</span>
          </template>
        </el-table-column>
        <el-table-column label="分公司未发给我(PCS)" prop="orgWfCountBox" width="140" align="center">
          <template #default="{ row }">
            <span>{{row.orgWfCountBox?.toFixed(0)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="分公司在途(PCS)" prop="cgZtCnt" width="120" align="center">
          <template #default="{ row }">
            <span>{{row.cgZtCnt?.toFixed(0)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="分公司库存" prop="orgKcStr" width="100" align="center"/>
        <el-table-column label="规格" prop="spec" width="100" align="center"/>
      </el-table>
      <Pagination :total="supplyConfirm.pageData.total" :page.sync="supplyConfirm.queryForm.page" :limit.sync="supplyConfirm.queryForm.limit" @pagination="handleQueryRestocking" />
    </el-dialog>

    <el-drawer title="配置日志" :visible.sync="openLogParam.visible" append-to-body size="70%" destroy-on-close>
      <AutoSupplyConfigLog :param="{orgCode: this.openLogParam.orgCode, dcCode: this.openLogParam.dcCode}"/>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import DictSelect from "@/components/DictSelect";
import {
  batchRemoveSku,
  batchUpdateSku,
  exportHeadSuggestExcel,
  exportRestockingExcel, exportRestockingSuggestExcel,
  exportSkuExcel,
  getSupplyConfig,
  getSupplyConfigList,
  getSupplyHeadSuggestList,
  getSupplyRestockingList, getSupplyRestockingSummary, getSupplySkuList,
  getSupplySuggestList, importSkuExcel, removeSku, updateSku, updateSupplyConfig,
} from "@/api/biOrg";
import {dictSelect} from "@/api/appTask";
import AutoSupplyConfigLog from "@/views/biOrg/autoSupply/log/index.vue";
import GoodCategory from "@/views/biOrg/autoSupply/config/GoodCategory.vue";
import SearchFilter from "@/components/SearchFilter/index";
import {Notification} from "element-ui";

export default {
  name: "AutoSupplyOrg",
  components: {GoodCategory, AutoSupplyConfigLog, Pagination, DictSelect, SearchFilter },
  data() {
    return {
      dictFun: dictSelect,
      currentSupplyType: [1,7,8,12].includes(new Date().getMonth() + 1)? '学讯补货天数' : '日常补货天数',
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        dcCode: undefined,
      },
      openLogParam: {
        visible: false,
        orgCode: undefined,
        dcCode: undefined,
      },
      loading: false,
      options: {
        supplySpecOptions: [{value: 'mid', label: '中包装'}, {value: 'bottom', label: '箱'}],
        supplyTimeTypeOptions: [{value: 'week', label: '每周'}, {value: 'mth', label: '每月'}, {value: 'half-mth', label: '每半月'}],
        skuLevelOptions: [{value: 'A', label: 'A档'},{value: 'B', label: 'B档'}],
        isNewSkuOptions: [{value: 1, label: '是'}, {value: 0, label: '否'}],
        if238Options: [{value: true, label: '是'}, {value: false, label: '否'}],
        supplyTimeDayOptions: [{value: 1, label: '一'}, {value: 2, label: '二'}, {value: 3, label: '三'}, {value: 4, label: '四'}, {value: 5, label: '五'}, {value: 6, label: '六'}, {value: 7, label: '日'}],
        supplyTimeMonthDayOptions: Array(31).fill(0).map((i, idx) => ({ label: idx + 1 + "日", value: idx + 1 })),
        supplySpecThresholdOptions: [{value: 1, label: '按中包装补货(向上取整)'}, {value: 0, label: '不补货'}],
      },
      skuDetail: {
        visible: false,
        loading: false,
        pageData: {
          total: 0,
          rows: [],
          selections: [],
        },
        queryForm: {
          page: 1,
          limit: 10,
          orgCode: undefined,
          dcCode: undefined,
          skuLevel: undefined,
          mainCategoryCode: undefined,
          midCategoryCode: undefined,
          subCategoryCode: undefined,
          if238: undefined,
          isNewSku: undefined,
          searchKey: undefined,
          sorts: undefined,
        },
        importDialog: {
          visible: false,
          type: 'common',
          apiParams: "importList",
          fileList: [],
          accept: ['.xls', '.xlsx'],
          title: '导入商品',
          importType: 2,
          previewDataLength: 10,
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E8%87%AA%E5%8A%A8%E8%A1%A5%E8%B4%A7%E5%AF%BC%E5%85%A5%E5%95%86%E5%93%81.xlsx',
          templateSampleHead: [
            { label: "商品编码*", prop: "skuCode", minWidth: "20px" },
            { label: "档位(A或B)*", prop: "skuLevel", minWidth: "20px" },
            { label: "日常补货天数*", prop: "supplyDays", minWidth: "20px" },
            { label: "学汛补货天数", prop: "xxSupplyDays", minWidth: "20px" },
          ],
          templateSampleLists: [
            {
              skuCode: '001',
              skuLevel: 'A',
              supplyDays: 20,
              xxSupplyDays: 40,
            },
            {
              skuCode: '002',
              skuLevel: 'B',
              supplyDays: 20,
              xxSupplyDays: '',
            },
          ],
        },
        saveDialog: {
          visible: false,
          form: {
            orgCode: undefined,
            dcCode: undefined,
            skuCode: undefined,
            supplyDays: undefined,
            xxSupplyDays: undefined,
            skuLevel: undefined,
          },
          rules: {
            supplyDays: [{ required: true, message: " ", trigger: "change" }],
            xxSupplyDays: [{ required: true, message: " ", trigger: "change" }],
          },
        },
        batchUpdate: {
          visible: false,
          form: {
            skuLevel: 'A',
            supplyDays: 20,
            xxSupplyDays: 40,
            skuList: []
          },
          rules: {
            supplyDays: [{ required: true, message: " ", trigger: "change" }],
            xxSupplyDays: [{ required: true, message: " ", trigger: "change" }],
          },
        }
      },
      supplyConfig: {
        visible: false,
        text: {
          openText: `已开启自动补货`,
          closeText: `已停止自动补货`,
        },
        form: {
          orgCode: undefined,
          dcCode: undefined,
          modeType: '1',
          supplyTimeType: 'week',
          supplyTimeDay: 1,
          supplyTimeDayListForWeek: [],
          supplySpec: 'mid',
          supplyThreshold: 1.0,
          supplyBoxTransMid: 1,
          updateUser: undefined,
          updateTime: undefined,
          createUser: undefined,
          createTime: undefined,
        },
        rules: {
          supplyThreshold: [{ required: true, message: " ", trigger: "change" }],
        },
        originSupplyThreshold: 1.0,
      },
      supplySuggest: {
        visible: false,
        loading: false,
        queryForm: {
          page: 1,
          limit: 10,
          orgCode: undefined,
          dcCode: undefined,
          isNewSku: undefined,
          searchKey: undefined,
          mainCategoryCode: undefined,
          midCategoryCode: undefined,
          subCategoryCode: undefined,
        },
        data: [],
        pageData: {
          total: 0,
          rows: [],
          selections: [],
        },
        queryForm1: {
          page: 1,
          limit: 10,
          orgCode: undefined,
          dcCode: undefined,
          isNewSku: undefined,
          searchKey: undefined,
          mainCategoryCode: undefined,
          midCategoryCode: undefined,
          subCategoryCode: undefined,
        },
        data1: [],
        pageData1: {
          total: 0,
          rows: [],
          selections: [],
        },
      },
      supplyConfirm: {
        visible: false,
        loading: false,
        queryForm: {
          page: 1,
          limit: 10,
          orgCode: undefined,
          dcCode: undefined,
          skuLevel: undefined,
          is238: undefined,
          isNewSku: undefined,
          searchKey: undefined,
          mainCategoryCode: undefined,
          midCategoryCode: undefined,
          subCategoryCode: undefined,
        },
        data: [],
        pageData: {
          total: 0,
          rows: [],
          selections: [],
        },
        saleTotal: undefined,
        skuCount: undefined
      },
    };
  },
  watch: {
    'queryForm.orgCode' () {
      if (this.queryForm.orgCode) {
        this.handleFilter();
      }
    }
  },
  methods: {
    selectTrigger(value) {
      if (value === 'mid') {
        this.supplyConfig.originSupplyThreshold = this.supplyConfig.form.supplyThreshold;
        this.supplyConfig.form.supplyThreshold = 1;
      } else {
        this.supplyConfig.form.supplyThreshold = this.supplyConfig.originSupplyThreshold;
      }
    },
    replaceWeekDay(val) {
      return val.replace(/(\d)/g, function (match, number) {
        const chineseNumberMap = {
          '1': '一',
          '2': '二',
          '3': '三',
          '4': '四',
          '5': '五',
          '6': '六',
          '7': '日'
          // 可以继续添加更多的映射关系
        };
        return chineseNumberMap[number] || match;
      });
    },
    handleQuery() {
      this.loading = true;
      getSupplyConfigList(this.queryForm).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.pageData.rows = res.data?.records;
          this.pageData.total = res.data?.total;
        }
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery(){
        const queryForm = {
          page: 1,
          limit: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    openSkuDetail(row) {
      this.skuDetail.queryForm.orgCode = row.orgCode;
      this.skuDetail.queryForm.dcCode = row.dcCode;
      this.handleFilterSkuDetail();
      this.skuDetail.visible = true;
    },
    openLog(row) {
      this.openLogParam.orgCode = row.orgCode;
      this.openLogParam.dcCode = row.dcCode;
      this.openLogParam.visible = true;
    },
    openConfig(row) {
      this.supplyConfig.form.orgCode = row.orgCode;
      this.supplyConfig.form.dcCode = row.dcCode;
      const data = {
        orgCode: this.supplyConfig.form.orgCode,
        dcCode: this.supplyConfig.form.dcCode,
      }
      getSupplyConfig(data).then(res => {
        if (res.code === 200 && res.data.config) {
          this.supplyConfig.form = res.data.config;
        }
        this.supplyConfig.visible = true;
      })
    },
    openSuggest(row) {
      this.supplySuggest.queryForm.orgCode = row.orgCode;
      this.supplySuggest.queryForm.dcCode = row.dcCode;
      this.supplySuggest.queryForm1.orgCode = row.orgCode;
      this.supplySuggest.queryForm1.dcCode = row.dcCode;
      this.querySuggest1();
      this.querySuggest();
      this.supplySuggest.visible = true;
    },
    querySuggest() {
      this.supplySuggest.loading = true;
      getSupplySuggestList(this.supplySuggest.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.supplySuggest.data = res.data.list;
        this.supplySuggest.loading = false;
        this.supplySuggest.pageData.rows = res.data?.list;
        this.supplySuggest.pageData.total = res.data?.total;
      });
    },
    querySuggest1() {
      this.supplySuggest.loading = true;
      getSupplyHeadSuggestList(this.supplySuggest.queryForm1).then(res => {
        if (!res.data) {
          return false;
        }
        this.supplySuggest.data1 = res.data.list;
        this.supplySuggest.loading = false;
        this.supplySuggest.pageData1.rows = res.data?.list;
        this.supplySuggest.pageData1.total = res.data?.total;
      });
    },
    querySkuTreeNodeClick(data) {
      this.resetSkuCategory()
      this.skuDetail.queryForm.mainCategoryCode = data.mainCategoryCode;
      this.skuDetail.queryForm.midCategoryCode = data.midCategoryCode;
      this.skuDetail.queryForm.subCategoryCode = data.subCategoryCode;
    },
    resetSkuCategory() {
      this.skuDetail.queryForm.mainCategoryCode = undefined;
      this.skuDetail.queryForm.midCategoryCode = undefined;
      this.skuDetail.queryForm.subCategoryCode = undefined;
    },
    supplyConfirmTreeNodeClick(data) {
      this.resetSupplyConfirmCategory();
      this.supplyConfirm.queryForm.mainCategoryCode = data.mainCategoryCode;
      this.supplyConfirm.queryForm.midCategoryCode = data.midCategoryCode;
      this.supplyConfirm.queryForm.subCategoryCode = data.subCategoryCode;
    },
    supplySuggestTreeNodeClick(data) {
      this.resetSupplySuggestCategory();
      this.supplySuggest.queryForm.mainCategoryCode = data.mainCategoryCode;
      this.supplySuggest.queryForm.midCategoryCode = data.midCategoryCode;
      this.supplySuggest.queryForm.subCategoryCode = data.subCategoryCode;
    },
    supplySuggestTreeNodeClick1(data) {
      this.resetSupplySuggestCategory1();
      this.supplySuggest.queryForm1.mainCategoryCode = data.mainCategoryCode;
      this.supplySuggest.queryForm1.midCategoryCode = data.midCategoryCode;
      this.supplySuggest.queryForm1.subCategoryCode = data.subCategoryCode;
    },
    resetSupplySuggestCategory() {
      this.supplyConfirm.queryForm.mainCategoryCode = undefined;
      this.supplyConfirm.queryForm.midCategoryCode = undefined;
      this.supplyConfirm.queryForm.subCategoryCode = undefined;
    },
    resetSupplySuggestCategory1() {
      this.supplyConfirm.queryForm1.mainCategoryCode = undefined;
      this.supplyConfirm.queryForm1.midCategoryCode = undefined;
      this.supplyConfirm.queryForm1.subCategoryCode = undefined;
    },
    openSupplyConfirm(row) {
      this.supplyConfirm.queryForm.orgCode = row.orgCode;
      this.supplyConfirm.queryForm.dcCode = row.dcCode;
      this.handleQueryRestocking();
      this.supplyConfirm.visible = true;
    },
    submitSupplyConfig() {
      this.$refs.supplyConfig.validate(valid => {
        if (valid) {
          updateSupplyConfig(this.supplyConfig.form).then(res => {
            if (res.code === 200) {
              this.supplyConfig.visible = false;
              Notification.success("操作成功！");
              this.handleFilter();
            }
          })
        }
      })

    },
    handleQueryRestocking() {
      this.supplyConfirm.loading = true;
      getSupplyRestockingList(this.supplyConfirm.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.supplyConfirm.data = res.data.list;
        this.supplyConfirm.loading = false;
        this.supplyConfirm.pageData.rows = res.data?.list;
        this.supplyConfirm.pageData.total = res.data?.total;
      });
      getSupplyRestockingSummary(this.supplyConfirm.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.supplyConfirm.skuCount = res.data.skuCount;
        this.supplyConfirm.saleTotal = res.data.orderTotal;
      });
    },
    handleExportSkuDetail() {
      if (!this.skuDetail.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.skuDetail.loading = true;
      const param = Object.assign({}, this.skuDetail.queryForm, {size: 100000});
      exportSkuExcel(param).then(res => {
        this.skuDetail.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '自动补货商品数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleExportRestocking() {
      if (!this.supplyConfirm.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.supplyConfirm.loading = true;
      const param = Object.assign({}, this.supplyConfirm.queryForm, {limit: 100000});
      exportRestockingExcel(param).then(res => {
        this.supplyConfirm.visible = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '补货确认单数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleExportSuggest() {
      if (!this.supplySuggest.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.supplySuggest.loading = true;
      const param = Object.assign({}, this.supplySuggest.queryForm, {size: 100000});
      exportRestockingSuggestExcel(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '销售前1000推荐商品数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleExportSuggest1() {
      if (!this.supplySuggest.queryForm1.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.supplySuggest.loading = true;
      const param = Object.assign({}, this.supplySuggest.queryForm1, {size: 100000});
      exportHeadSuggestExcel(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '238及其他重点商品数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleQuerySkuDetail() {
      this.skuDetail.loading = true;
      getSupplySkuList(this.skuDetail.queryForm).then(res => {
        this.skuDetail.loading = false;
        if (res.code === 200) {
          this.skuDetail.pageData.rows = res.data?.list;
          this.skuDetail.pageData.total = res.data?.total;
        }
      });
    },
    handleFilterSkuDetail() {
      this.skuDetail.queryForm.page = 1;
      this.handleQuerySkuDetail();
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.skuDetail.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.skuDetail.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.skuDetail.importDialog.fileList = [];
      } else {
        this.skuDetail.importDialog.fileList = fileList;
      }
    },
    handleImport() {
      if (this.skuDetail.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      if (!this.skuDetail.queryForm.dcCode) {
        Notification.warning("请先选择配送中心！");
        return;
      }
      let fd = new FormData();
      this.skuDetail.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.loading = true;
      const data = {
        orgCode: this.skuDetail.queryForm.orgCode,
        dcCode: this.skuDetail.queryForm.dcCode,
        mode: this.skuDetail.importDialog.importType
      }
      importSkuExcel(fd, data).then((res) => {
        this.loading = false;
        if (!res) {
          return
        }
        if (res.data) {
          Notification.success("导入商品成功！");
        } else {
          Notification.error("导入商品失败！");
        }
        this.$refs.goodsUpload.clearFiles()
      })
      this.handleFilterSkuDetail();
      this.skuDetail.importDialog.visible = false;
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.skuDetail.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.skuDetail.queryForm.sorts = undefined;
      }
      this.handleFilterSkuDetail();
    },
    handleEditSku(row) {
      this.handleResetFields();
      this.skuDetail.saveDialog.form = {
        orgCode: this.skuDetail.queryForm.orgCode,
        dcCode: this.skuDetail.queryForm.dcCode,
        skuCode: row.skuCode,
        supplyDays: row.supplyDays,
        xxSupplyDays: row.xxSupplyDays,
        skuLevel: row.skuLevel,
        skuList: [],
        updateUser: row.updateUser,
        updateTime: row.updateTime,
      };
      this.skuDetail.saveDialog.visible = true;
    },
    submitEditSku() {
      this.$refs.editSku.validate(valid => {
        if (valid) {
          let item = this.skuDetail.saveDialog.form
          const data = {
            orgCode: item.orgCode,
            dcCode: item.dcCode,
            skuList: [
              {
                skuCode: item.skuCode,
                supplyDays: item.supplyDays,
                xxSupplyDays: item.xxSupplyDays,
                skuLevel: item.skuLevel
              }
            ]
          }
          updateSku(data).then(res => {
            if (res.code === 200) {
              this.skuDetail.saveDialog.visible = false;
              Notification.success("操作成功！");
              this.handleQuery();
            }
          });
        }
      })
    },
    handleRemoveSku(row) {
      this.$confirm('确定删除该商品？', {
        center: true
      }).then(() => {
        const data = {
          orgCode: this.skuDetail.queryForm.orgCode,
          dcCode: this.skuDetail.queryForm.dcCode,
          skuCodeList: [row.skuCode]
        }
        removeSku(data).then(res => {
          if (res.code  == 200) {
            Notification.success("操作成功");
            this.handleFilterSkuDetail();
          }
        })
      }).catch(() => {});
    },
    handleResetFields() {
      const form = {
        orgCode: undefined,
        dcCode: undefined,
        skuList: []
      };
      this.skuDetail.saveDialog.form = Object.assign({}, form);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.skuDetail.pageData.selections = selections;
    },
    openBatchUpdateSku() {
      if (!this.skuDetail.pageData.selections || this.skuDetail.pageData.selections.length <= 0) {
        Notification.warning('请先选中要批量配置的商品！');
        return;
      }
      this.skuDetail.pageData.selections.forEach(selection => {
        if (selection.orgCode && selection.dcCode && selection.skuCode) {
          this.skuDetail.batchUpdate.form.skuList.push({
            orgCode: selection.orgCode,
            dcCode: selection.dcCode,
            skuCode: selection.skuCode
          });
        }
      });
      this.skuDetail.batchUpdate.visible = true;
    },
    handleBatchRemoveSku() {
      if (!this.skuDetail.pageData.selections || this.skuDetail.pageData.selections.length <= 0) {
        Notification.warning('请先选中要批量删除的商品！');
        return;
      }
      this.$confirm('确定批量删除选中商品？', {
        center: true
      }).then(() => {
        let data = [];
        this.skuDetail.pageData.selections.forEach(selection => {
          if (selection.orgCode && selection.dcCode && selection.skuCode) {
            data.push({
              orgCode: selection.orgCode,
              dcCode: selection.dcCode,
              skuCode: selection.skuCode
            });
          }
        });
        batchRemoveSku(data).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功");
            this.handleQuerySkuDetail();
          }
        })
      }).catch(() => {});
    },
    submitBatchUpdateSku() {
      this.$refs.batchUpdateSku.validate(valid => {
        if (valid) {
          batchUpdateSku(this.skuDetail.batchUpdate.form).then(res => {
            if (res.code === 200) {
              this.skuDetail.batchUpdate.visible = false;
              Notification.success("操作成功！");
              this.handleQuerySkuDetail();
            }
          });
        }
      })
    },
  },
};
</script>

<style lang="scss">
::v-deep {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .el-input__inner {
    padding-left: 5px !important;
    max-width: 200px !important;
  }
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
.bh-row {
  margin-bottom: 10px;
}
.bh-label {
  margin-top: 5px;
  font-weight: bold
}
.bh-tip {
  font-weight: bold;
  color: red;
  text-align: center;
  margin-bottom: 20px;
}
.edit {
  color: #fff;
  background-color: #F8A04D;
  border-color: #F8A04D;
}
.edit:hover,
.edit:focus {
  background: #f48f2f;
  border-color: #f48f2f;
  color: #fff;
}
//.previewDialog.el-dialog {
//  .el-dialog__header {
//    display: none;
//  }
//  .dj-dialog-content {
//    padding: 0;
//    overflow: unset;
//  }
//}
</style>
