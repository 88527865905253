var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "80px",
                "label-position": "right",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品型号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.queryForm.skuModel,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuModel", $$v)
                      },
                      expression: "queryForm.skuModel",
                    },
                  }),
                ],
                1
              ),
              _vm.scope === "1" && !_vm.showUrl
                ? _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.queryForm.skuCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "skuCode", $$v)
                          },
                          expression: "queryForm.skuCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.queryForm.skuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuName", $$v)
                      },
                      expression: "queryForm.skuName",
                    },
                  }),
                ],
                1
              ),
              _c("DimCateGorySearch", {
                ref: "category",
                staticStyle: { display: "inline" },
                attrs: {
                  main: _vm.queryForm.mainCategoryCode,
                  mid: _vm.queryForm.midCategoryCode,
                  sub: _vm.queryForm.subCategoryCode,
                },
                on: {
                  "update:main": function ($event) {
                    return _vm.$set(_vm.queryForm, "mainCategoryCode", $event)
                  },
                  "update:mid": function ($event) {
                    return _vm.$set(_vm.queryForm, "midCategoryCode", $event)
                  },
                  "update:sub": function ($event) {
                    return _vm.$set(_vm.queryForm, "subCategoryCode", $event)
                  },
                },
              }),
              _c(
                "el-form-item",
                { attrs: { label: "标签" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.queryForm.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "label", $$v)
                      },
                      expression: "queryForm.label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  !_vm.showUrl
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-plus",
                            type: "primary",
                          },
                          on: { click: _vm.handleAddAll },
                        },
                        [_vm._v("全部添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 300px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
            "row-class-name": "rowClassName",
          },
          on: {
            "row-dblclick": _vm.handleRowDblClick,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "90" } }),
          _vm.scope === "1" && !_vm.showUrl
            ? _c("el-table-column", {
                attrs: { label: "商品编码", prop: "skuCode", width: "80" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "商品型号", prop: "skuModel", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "商品名称", prop: "skuName" },
          }),
          _vm.scope === "1" && !_vm.showUrl
            ? _c("el-table-column", {
                attrs: { label: "规格", prop: "spec", width: "80" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "大类", prop: "mainCategoryName", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "中类", prop: "midCategoryName", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "小类", prop: "subCategoryName", width: "100" },
          }),
          _vm.boost
            ? _c("el-table-column", {
                attrs: { label: "标签", prop: "label", width: "100" },
              })
            : _vm._e(),
          _vm.showUrl
            ? _c("el-table-column", {
                attrs: { label: "图片", prop: "skuUrl", width: "120" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-image",
                            {
                              attrs: {
                                src: row.skuUrl,
                                "preview-src-list": [row.skuUrl],
                                lazy: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  547142063
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      !_vm.showUrl
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.closeDrawer } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: _vm.pageData.selections.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addItems()
                    },
                  },
                },
                [
                  _vm._v(
                    "添加已选 " +
                      _vm._s(_vm.pageData.selections.length) +
                      " 商品"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }