var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "salesCenter-page" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "salesCenter-tabs",
          staticStyle: { height: "200x" },
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              staticClass: "prodCenter-tabs-item",
              attrs: { label: "确认列表", name: "onlineWrite" },
            },
            [
              _c(
                "div",
                { staticClass: "salesCenter-search" },
                [
                  _c("v-signboard", { attrs: { title: "请购单确认列表" } }),
                  _c(
                    "el-form",
                    {
                      ref: "buyingReq-form",
                      staticClass: "main-search",
                      attrs: {
                        size: "mini",
                        "label-width": "auto",
                        inline: true,
                        model: _vm.form,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品等级", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", multiple: "" },
                              model: {
                                value: _vm.form.productGrade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "productGrade", $$v)
                                },
                                expression: "form.productGrade",
                              },
                            },
                            _vm._l(
                              _vm.options.productGradeOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "大类", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleOptionChange($event, "max")
                                },
                              },
                              model: {
                                value: _vm.form.productFirstCategorie,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "productFirstCategorie",
                                    $$v
                                  )
                                },
                                expression: "form.productFirstCategorie",
                              },
                            },
                            _vm._l(
                              _vm.options.productMaxCategorieOtions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "中类", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleOptionChange($event, "mid")
                                },
                              },
                              model: {
                                value: _vm.form.productMiddleCategorie,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "productMiddleCategorie",
                                    $$v
                                  )
                                },
                                expression: "form.productMiddleCategorie",
                              },
                            },
                            _vm._l(
                              _vm.options.productMidCategorieOtions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "小类", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleOptionChange($event, "min")
                                },
                              },
                              model: {
                                value: _vm.form.productLittleCategorie,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "productLittleCategorie",
                                    $$v
                                  )
                                },
                                expression: "form.productLittleCategorie",
                              },
                            },
                            _vm._l(
                              _vm.options.productMinCategorieOtions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产类型", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.prod_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "prod_type", $$v)
                                },
                                expression: "form.prod_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "生产中心", value: "生产中心" },
                              }),
                              _c("el-option", {
                                attrs: { label: "OEM中心", value: "OEM中心" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "生产中心/办公",
                                  value: "生产中心/办公",
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "办公", value: "办公" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "锁状态", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.lock_state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "lock_state", $$v)
                                },
                                expression: "form.lock_state",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "0" },
                              }),
                              _c("el-option", {
                                attrs: { label: "是", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品赛道", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90px" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.race_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "race_name", $$v)
                                },
                                expression: "form.race_name",
                              },
                            },
                            _vm._l(_vm.options.raceNameLists, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "原始料号", size: "mini" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入原始料号",
                            },
                            model: {
                              value: _vm.form.material_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "material_code", $$v)
                              },
                              expression: "form.material_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "品名", size: "mini" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入品名" },
                            model: {
                              value: _vm.form.plu_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "plu_name", $$v)
                              },
                              expression: "form.plu_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "统计品类", size: "mini" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入统计品类",
                            },
                            model: {
                              value: _vm.form.stats_category_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "stats_category_name", $$v)
                              },
                              expression: "form.stats_category_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "模具号", size: "mini" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入模具号",
                            },
                            model: {
                              value: _vm.form.mould_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mould_code", $$v)
                              },
                              expression: "form.mould_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预淘汰品", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.dead_sku_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dead_sku_type", $$v)
                                },
                                expression: "form.dead_sku_type",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 0,
                                attrs: { label: "全部", value: 0 },
                              }),
                              _c("el-option", {
                                key: 1,
                                attrs: { label: "是", value: 1 },
                              }),
                              _c("el-option", {
                                key: 2,
                                attrs: { label: "否", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "统计工艺", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.craft_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "craft_type", $$v)
                                },
                                expression: "form.craft_type",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 0,
                                attrs: { label: "全部", value: 0 },
                              }),
                              _c("el-option", {
                                key: 1,
                                attrs: { label: "手工", value: 1 },
                              }),
                              _c("el-option", {
                                key: 2,
                                attrs: { label: "机装", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否有客订", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90px" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.order_count_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "order_count_type", $$v)
                                },
                                expression: "form.order_count_type",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 0,
                                attrs: { label: "全部", value: 0 },
                              }),
                              _c("el-option", {
                                key: 1,
                                attrs: { label: "有客订", value: 1 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "理论请购量分类", size: "mini" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90px" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.theory_shipment_count_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "theory_shipment_count_type",
                                    $$v
                                  )
                                },
                                expression: "form.theory_shipment_count_type",
                              },
                            },
                            [
                              _c("el-option", {
                                key: 0,
                                attrs: { label: "全部", value: 0 },
                              }),
                              _c("el-option", {
                                key: 1,
                                attrs: { label: "为零", value: 1 },
                              }),
                              _c("el-option", {
                                key: 2,
                                attrs: {
                                  label: "超一倍(理论请购量超过分公司客订量*2)",
                                  value: 2,
                                },
                              }),
                              _c("el-option", {
                                key: 3,
                                attrs: {
                                  label: "低一半(理论请购量小于分公司客订量/2)",
                                  value: 3,
                                },
                              }),
                              _c("el-option", {
                                key: 4,
                                attrs: { label: "正常", value: 4 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            type: "primary",
                            loading: _vm.btnStatus.isSub,
                          },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("查 询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            loading: _vm.btnStatus.isReset,
                          },
                          on: { click: _vm.handleReset },
                        },
                        [_vm._v("重 置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.btnStatus.isSave,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleTableHeadAction("save")
                            },
                          },
                        },
                        [_vm._v("保 存 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm.btnStatus.isExport,
                            loading: _vm.btnStatus.isExportLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleTableHeadAction("export")
                            },
                          },
                        },
                        [_vm._v("导 出 ")]
                      ),
                      this.user.role_code_list.includes("selfManager") ||
                      this.user.role_code_list.includes("leader")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction("setting")
                                },
                              },
                            },
                            [_vm._v("重 新 设 定 库 存 系 数 ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("leader")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction(
                                    "leaderConfirm"
                                  )
                                },
                              },
                            },
                            [_vm._v("领 导 审 核 ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("selfManager") ||
                      this.user.role_code_list.includes("leader")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction(
                                    "resetOffice"
                                  )
                                },
                              },
                            },
                            [_vm._v("重 置 办 公 ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("selfManager") ||
                      this.user.role_code_list.includes("leader")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction("resetOem")
                                },
                              },
                            },
                            [_vm._v("重 置 OEM ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("selfManager") ||
                      this.user.role_code_list.includes("leader")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction("resetSelf")
                                },
                              },
                            },
                            [_vm._v("重 置 书 写 ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("officeManager")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction(
                                    "confirmOffice"
                                  )
                                },
                              },
                            },
                            [_vm._v("审 核 办 公 ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("oemManager")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction("confirmOem")
                                },
                              },
                            },
                            [_vm._v("审 核 OEM ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("selfManager")
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction(
                                    "confirmSelf"
                                  )
                                },
                              },
                            },
                            [_vm._v("审 核 书 写 ")]
                          )
                        : _vm._e(),
                      this.user.role_code_list.includes("selfManager") ||
                      this.user.role_code_list.includes("leader")
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                disabled: _vm.btnStatus.isProdSetZero,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleTableHeadAction(
                                    "prod_set_zero"
                                  )
                                },
                              },
                            },
                            [_vm._v("请 购 置 零 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "salesCenter-content" },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { staticClass: "salesCenter-tips" }, [
                        _vm._v(
                          "根据您设置的库存系数目标，经数据中心计算，得到请购单如下："
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableStatus.isLoading,
                          expression: "tableStatus.isLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticClass: "table",
                      attrs: {
                        stripe: "",
                        border: "",
                        height: "500",
                        size: "mini",
                        data: _vm.lists,
                      },
                      on: { "selection-change": _vm.handleselection },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "40",
                          fixed: "left",
                        },
                      }),
                      _vm._l(
                        _vm.tableObj.buyingReqConfirmHead,
                        function (item, index) {
                          return [
                            item.prop === "-"
                              ? _c(
                                  "el-table-column",
                                  _vm._b(
                                    {
                                      key: index,
                                      attrs: {
                                        fixed: "right",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "prodConfirm-btn",
                                                  },
                                                  [
                                                    _vm._l(
                                                      scope.row.userOpeBtn,
                                                      function (
                                                        item1,
                                                        confirId
                                                      ) {
                                                        return _c(
                                                          "el-button",
                                                          {
                                                            key:
                                                              confirId + "only",
                                                            attrs: {
                                                              type: item1.isSelect
                                                                ? "primary"
                                                                : "",
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleUserOpe(
                                                                  scope.$index,
                                                                  confirId,
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item1.btnText
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    "el-table-column",
                                    item,
                                    false
                                  )
                                )
                              : item.prop == "prepare_shipment_count_box"
                              ? _c(
                                  "el-table-column",
                                  _vm._b(
                                    {
                                      key: index,
                                      attrs: {
                                        fixed: "right",
                                        "show-overflow-tooltip": "",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass:
                                                    "tgqrcount-cell-ipt",
                                                  attrs: {
                                                    type: "number",
                                                    size: "mini",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .prepare_shipment_count_box,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "prepare_shipment_count_box",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.prepare_shipment_count_box",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    "el-table-column",
                                    item,
                                    false
                                  )
                                )
                              : _c(
                                  "el-table-column",
                                  _vm._b(
                                    {
                                      key: index,
                                      attrs: {
                                        "show-overflow-tooltip": "",
                                        align: "center",
                                      },
                                    },
                                    "el-table-column",
                                    item,
                                    false
                                  )
                                ),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                  _c("v-pagination", {
                    attrs: {
                      total: _vm.total,
                      pageSize: _vm.pageSize,
                      currentPage: _vm.currentPage,
                    },
                    on: { pageChange: _vm.pagingChange },
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  staticClass: "add-dialog",
                  attrs: {
                    title: "修改库存目标系数",
                    width: "650px",
                    visible: _vm.dialogStatus.isUpdateStoreRatio,
                    "destroy-on-close": true,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(
                        _vm.dialogStatus,
                        "isUpdateStoreRatio",
                        $event
                      )
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "dialog-content" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dialogForm",
                          staticClass: "dialog-form",
                          attrs: {
                            "label-position": "right",
                            "label-width": "140px",
                            inline: true,
                            size: "medium",
                            model: _vm.dialogFormModel,
                            rules: _vm.dialogFormModelRules,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "原始料号",
                                prop: "material_code",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "原始料号",
                                  readonly: "readonly",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dialogFormModel.material_code,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "material_code",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.material_code",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "修改类型", prop: "update_type" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "90px" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.dialogFormModel.update_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dialogFormModel,
                                        "update_type",
                                        $$v
                                      )
                                    },
                                    expression: "dialogFormModel.update_type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: 1,
                                    attrs: {
                                      label: "当前项库存系数调整",
                                      value: "1",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: 2,
                                    attrs: {
                                      label: "小类&级别库存系数调整",
                                      value: "2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "库存系数上限",
                                prop: "store_ratio_up",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "库存系数上限",
                                },
                                model: {
                                  value: _vm.dialogFormModel.store_ratio_up,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "store_ratio_up",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.store_ratio_up",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "库存系数目标",
                                prop: "store_ratio_aim",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "库存系数目标",
                                },
                                model: {
                                  value: _vm.dialogFormModel.store_ratio_aim,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "store_ratio_aim",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormModel.store_ratio_aim",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "库存系数下限",
                                prop: "store_ratio_down",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "number",
                                  placeholder: "库存系数下限",
                                },
                                model: {
                                  value: _vm.dialogFormModel.store_ratio_down,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "store_ratio_down",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dialogFormModel.store_ratio_down",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "大类",
                                prop: "main_category_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "大类",
                                  readonly: "readonly",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dialogFormModel.main_category_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "main_category_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dialogFormModel.main_category_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "中类",
                                prop: "mid_category_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "中类",
                                  readonly: "readonly",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dialogFormModel.mid_category_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "mid_category_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dialogFormModel.mid_category_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "小类",
                                prop: "sub_category_name",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "小类",
                                  readonly: "readonly",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dialogFormModel.sub_category_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogFormModel,
                                      "sub_category_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dialogFormModel.sub_category_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "等级", prop: "grade" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "等级",
                                  readonly: "readonly",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dialogFormModel.grade,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dialogFormModel, "grade", $$v)
                                  },
                                  expression: "dialogFormModel.grade",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleUpdateStoreRatio },
                        },
                        [_vm._v("保 存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleCancelUpdateStoreRatio },
                        },
                        [_vm._v("取 消")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              staticClass: "prodCenter-tabs-item",
              attrs: { label: "表单上传", name: "formWrite" },
            },
            [
              _vm.activeName == "formWrite"
                ? _c(
                    "div",
                    { attrs: { clas: "form-upload-box" } },
                    [
                      _c("upload-excel", {
                        ref: "uploadExcelRef",
                        attrs: {
                          uploadDatas: _vm.uploadFileObj.tableData,
                          "on-success": _vm.handleFileSuccess,
                          "before-upload": _vm.beforeFileUpload,
                          tipsText: _vm.uploadFileObj.tipsText,
                        },
                        on: {
                          handlePreview: _vm.handlePreview,
                          handleConfirmUpload: _vm.handleConfirmUpload,
                        },
                      }),
                      _vm.uploadFileObj.preTableData.length > 0
                        ? [
                            _c(
                              "div",
                              { staticClass: "previewTable" },
                              [
                                _c("v-signboard", {
                                  attrs: { title: "文件预览" },
                                }),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { staticClass: "handleConfirmUpload" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                            },
                                          },
                                          [_vm._v("确认上传")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.uploadFileObj.preTableData,
                                      stripe: "",
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  _vm._l(
                                    _vm.uploadFileObj.tableHeader,
                                    function (item) {
                                      return _c("el-table-column", {
                                        key: item,
                                        attrs: { prop: item, label: item },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "previewTable previewTable-sample",
                              },
                              [
                                _c("v-signboard", {
                                  attrs: { title: "文件上传模板示例" },
                                }),
                                _c(
                                  "el-table",
                                  {
                                    attrs: {
                                      data: _vm.uploadFileObj
                                        .templateSampleLists,
                                      stripe: "",
                                      border: "",
                                      size: "mini",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.uploadFileObj.templateSampleHead,
                                      function (item, index) {
                                        return [
                                          _c(
                                            "el-table-column",
                                            _vm._b(
                                              {
                                                key: index,
                                                attrs: {
                                                  "show-overflow-tooltip": "",
                                                  align: "center",
                                                },
                                              },
                                              "el-table-column",
                                              item,
                                              false
                                            )
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }