<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="合并后配送中心编码" prop="dcCode">
          <DictSelect
            size="mini"
            ref="dc"
            type="merge-dc"
            :value.sync="queryForm.mdcCode"
            linkageRef="shop"
            :dictFun="dictSelect"
            remote
            fullLabel
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="合并后店铺编码" prop="shopCode">
          <DictSelect
            size="mini"
            ref="shop"
            type="merge-shop"
            :value.sync="queryForm.mshopCode"
            :dictFun="dictSelect"
            remote
            fullLabel
            @getOtherJsonParam="(callback) => callback({dcCode: queryForm.mdcCode})"
          />
        </el-form-item>
        <el-form-item label="合并前配送中心编码" prop="dcCode">
          <DictSelect
            size="mini"
            ref="before-dc"
            type="merge-before-dc"
            :value.sync="queryForm.dcCode"
            linkageRef="before-shop"
            :dictFun="dictSelect"
            remote
            fullLabel
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="合并前店铺编码" prop="shopCode">
          <DictSelect
            size="mini"
            ref="before-shop"
            type="merge-before-shop"
            :value.sync="queryForm.shopCode"
            :dictFun="dictSelect"
            remote
            fullLabel
            @getOtherJsonParam="(callback) => callback({dcCode: queryForm.dcCode})"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      cell-class-name="table-cell-class"
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="合并后" prop="mdcCode" width="200">
        <el-table-column label="配送中心" prop="mdcCode" width="200">
          <template #default="{ row }">
            <span>{{ row.mdcCode + "-" + row.mdcName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="店铺" prop="mshopCode">
          <template #default="{ row }">
            <span>{{ row.mshopCode + "-" + row.mshopName }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="合并前" prop="shopCode" width="200">
        <el-table-column label="配送中心" prop="shopCode" width="200">
          <template #default="{ row }">
            <el-row>
              <el-col v-for="(item,index) in row.shops" :key="index">
                <span>{{ item.dcCode + "-" + item.dcName }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="店铺" prop="shopName">
          <template #default="{ row }">
            <el-row>
              <el-col v-for="(item,index) in row.shops" :key="index">
                <span>{{ item.shopCode + "-" + item.shopName }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="是否主店铺" prop="ifMain" width="100">
          <template #default="{ row }">
            <el-row>
              <el-col v-for="(item,index) in row.shops" :key="index">
                <span>{{ item.ifMain === 1? '是': '否' }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="createUser" width="100">
          <template #default="{ row }">
            <el-row>
              <el-col v-for="(item,index) in row.shops" :key="index">
                <span>{{ item.opUser }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="操作时间" prop="createTime" width="140">
          <template #default="{ row }">
            <el-row>
              <el-col v-for="(item,index) in row.shops" :key="index">
                <span>{{ item.opTime }}</span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template #default="{ row }">
          <el-row>
            <el-button :disabled="row.mshopCode.indexOf('_')> -1" size="mini" type="text" style="border: 0px; margin-right: 10px" @click="handleEdit(row, 'edit')">编辑</el-button>
            <el-popconfirm
              :title="'请确认是否要删除当前合并店铺数据?'"
              @confirm="handleDelete(row)"
            >
              <el-button :disabled="row.mshopCode.indexOf('_')> -1" slot="reference" size="mini" type="text" style="border: 0px;">删除</el-button>
            </el-popconfirm>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog :title="editDialog.dialogTitle[editDialog.type]" :visible.sync="editDialog.visible" width="50%" :close-on-click-modal="false">
      <el-form ref="form" size="mini" label-width="140px" :inline="true" :rules="editDialog.rules" :model="editDialog.form" class="common-form">
        <el-row :gutter="12" class="common-form-row">
          <el-col :span="12">
            <el-form-item label="主配送中心编码">
              <el-input disabled v-model="editDialog.form.mdcCode" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主配送中心名称" prop="mdcName">
              <el-input v-model="editDialog.form.mdcName" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主店铺编码">
              <el-input disabled v-model="editDialog.form.mshopCode" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主店铺名称" prop="mshopName">
              <el-input v-model="editDialog.form.mshopName" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-table
          :data="editDialog.form.shops"
          height="250px"
          size="mini"
          border
          ref="tmTable"
          highlight-current-row
          :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        >
          <el-table-column label="配送中心编码" prop="dcCode" width="220">
            <template #default="{ row }">
              <span>{{ row.dcCode + "-" + row.dcName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="店铺编码" prop="shopCode" width="280">
            <template #default="{ row }">
              <span>{{ row.shopCode + "-" + row.shopName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否主店铺" prop="ifMain" width="100">
            <template #default="{ row }">
              <span>{{ row.ifMain === 1? '是': '否' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="opUser"  width="80" />
          <el-table-column label="创建日期" prop="opTime" width="140" />
          <el-table-column label="操作" width="160" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="() => editDialog.form.shops.splice(scope.$index, 1)">移除</el-button>
              <el-button :disabled="scope.row.ifMain ===1" size="mini" type="primary" @click="changeMain(scope.row)">切换主店铺</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; border: 1px skyblue dashed;">
          <el-button size="mini" type="text" style="width: 100%" @click="handleAddRow">新增</el-button>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" :disabled="editDialog.buttonLoading" @click="editDialog.visible = false">取 消</el-button>
        <el-button v-if="editDialog.type!=='view'" :disabled="editDialog.buttonLoading" size="mini" type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择店铺" :visible.sync="shopDialog.visible" append-to-body>
      <el-form ref="shopForm" size="mini" label-width="140px" :inline="true" :rules="shopDialog.rules" :model="shopDialog.form" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="12">
            <el-form-item label="配送中心编码" prop="dcCode">
              <DictSelect
                size="mini"
                ref="mergeDc"
                type="merge-dc"
                :value.sync="shopDialog.form.dcCode"
                linkageRef="merge-shop"
                :dictFun="dictSelect"
                remote
                @change="(val) => handleFormChange(val, 'mergeDc')"
                fullLabel
                @changeLinkageRef="handleChangeLinkageRef"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店铺编码" prop="shopCOde">
              <DictSelect
                size="mini"
                ref="mergeShop"
                type="merge-shop"
                :value.sync="shopDialog.form.shopCode"
                linkageRef="regionSave"
                :dictFun="dictSelect"
                remote
                @change="(val) => handleFormChange(val, 'mergeShop')"
                fullLabel
                @getOtherJsonParam="(callback) => callback({dcCode: shopDialog.form.dcCode})"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="shopDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitShopForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageShopMerge, getSkuTagDetail, saveShopMerge, removeSingleMerge, dictSelect, removeShopMerge } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "SkuTag",
  components: { Pagination, DictSelect },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      dictSelect,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        tagName: undefined,
        mdcCode: undefined,
        mshopCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
      },
      queryDate: [],
      loading: false,
      dictParam: {},
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      editDialog: {
        visible: false,
        disabled: false,
        buttonLoading: false,
        dialogTitle: {
          edit: '编辑',
          view: '查看'
        },
        id: undefined,
        type: 'edit',
        form: {
          mdcCode: undefined,
          mdcName: undefined,
          mshopCode: undefined,
          mshopName: undefined,
          shops: [],
          oldShops: []
        },
        rules: {
          mdcName: [{ required: true, message: ' ', trigger: 'change' }],
          mshopName: [{ required: true, message: ' ', trigger: 'change' }],
        },
      },
      orgAllAuth: false,
      orgOptions: [],
      shopDialog: {
        visible: false,
        rules: {
          dcCode: [{ required: true, message: ' ', trigger: 'change' }],
          shopCode: [{ required: true, message: ' ', trigger: 'change' }],
        },
        form: {
          dcCode: undefined,
          dcName: undefined,
          shopName: undefined,
          shopCode: undefined
        }
      }
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.orgOptions = res.data;
      this.handleFilter();
    })
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      pageShopMerge(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        dcCode: undefined,
        shopCode: undefined
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImageUrls(urls) {
      return urls && urls.length > 0? urls.split(','): [];
    },
    handleEdit(row, type) {
      if (row) {
        this.editDialog.form = deepClone(row);
        this.editDialog.form.oldShops = deepClone(row.shops);
      } else {
        this.editDialog.form = {
          mdcCode: undefined,
          mdcName: undefined,
          mshopCode: undefined,
          mshopName: undefined,
          shops: [],
          oldShops: []
        }
      }
      this.$forceUpdate();
      this.editDialog.visible = true;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDelete(row) {
      removeShopMerge(row.id).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      });
    },
    submitForm() {
      this.$refs.form.validate(valiad => {
        if (valiad) {
          let form = deepClone(this.editDialog.form);
          if (form.shops.length<2) {
            Notification.warning("店铺数据至少为2条数据！");
            return;
          }
          saveShopMerge(form).then(res => {
            if (res.code  == 200) {
              Notification.success("操作成功");
              this.editDialog.visible = false;
              this.handleFilter();
            }
          })
        }
      })
    },
    handleAddRow() {
      this.shopDialog.form = {
        dcCode: undefined,
        dcName: undefined,
        shopName: undefined,
        shopCode: undefined
      }
      this.shopDialog.visible = true;
    },
    handleFormChange(val, ref) {
      if (this.$refs[ref] && this.$refs[ref].itemOptions) {
        let obj = this.$refs[ref].itemOptions.find(e => e.value == val)
        if (obj) {
          if (ref.indexOf('Dc')>-1) {
            this.shopDialog.form.dcName = obj.label;
          } else {
            this.shopDialog.form.shopName = obj.label;
          }
        }
      }
    },
    submitShopForm() {
      this.$refs.shopForm.validate(valid => {
        if (valid) {
          let obj = deepClone(this.shopDialog.form);
          if (this.editDialog.form.shops.length === 0) {
            obj.ifMain = 1;
          } else {
            obj.ifMain = 0;
          }
          this.editDialog.form.shops.push(obj);
          this.shopDialog.visible = false;
        }
      });
    },
    changeMain(row) {
      this.editDialog.form.shops.forEach(e => {
        e.ifMain = 0
      })
      this.editDialog.form.mdcCode = row.dcCode;
      this.editDialog.form.mdcName = row.dcName;
      this.editDialog.form.mshopCode = row.shopCode;
      this.editDialog.form.mshopName = row.shopName;
      row.ifMain = 1
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
.skuUrlThum {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  ::v-deep {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
::v-deep .table-cell-class {
  border-right: 0px;
}
</style>
