var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticClass: "new-box",
    },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "ora-org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "ora-dc-all",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          "default-first-option": "",
                          "default-index": 0,
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "ora-dc-all",
                        attrs: {
                          type: "ora-dc-all",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          "default-index": 0,
                          clearable: false,
                          alwaysChange: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.shopJsonParam),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleImport },
            },
            [_vm._v("导入")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出")]
          ),
          _vm.queryForm.dcCode === "ALL"
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleCopy },
                },
                [_vm._v("一键复制到分公司下配送中心")]
              )
            : _vm._e(),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v(
              "配置后，在聚宝盆App→订单管理tab→238补货预估中，可以重点查看这些商品"
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "商品编码", prop: "skuCode" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品名称", prop: "skuName" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品型号", prop: "skuModel" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              "confirm-button-text": "确认",
                              "cancel-button-text": "取消",
                              icon: "el-icon-info",
                              "icon-color": "red",
                              title: "确认移除该商品吗？",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.handleDel(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  type: "text",
                                },
                                slot: "reference",
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            [
              _c("span", [_c("strong", [_vm._v("导入类型")])]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.importDialog.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.importDialog, "type", $$v)
                    },
                    expression: "importDialog.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "add" } }, [_vm._v("新增")]),
                  _c("el-radio", { attrs: { label: "override" } }, [
                    _vm._v("覆盖"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "复用分公司",
            visible: _vm.copyDialog.visible,
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.copyDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "copyForm",
              attrs: {
                model: _vm.copyDialog.form,
                inline: true,
                rules: _vm.copyDialog.rules,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "复制到以下配送中心", prop: "targetDcCodes" },
                },
                [
                  _c("DictSelect", {
                    ref: "sq-type",
                    attrs: {
                      type: "pre-shop",
                      value: _vm.copyDialog.form.targetDcCodes,
                      "collapse-tags": "",
                      fullLabel: "",
                      multiple: "",
                      init: "",
                      "init-options": _vm.copyDialog.dcOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.copyDialog.form,
                          "targetDcCodes",
                          $event
                        )
                      },
                      "update:initOptions": function ($event) {
                        return _vm.$set(_vm.copyDialog, "dcOptions", $event)
                      },
                      "update:init-options": function ($event) {
                        return _vm.$set(_vm.copyDialog, "dcOptions", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(true)
                    },
                  },
                },
                [_vm._v("全选")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(false)
                    },
                  },
                },
                [_vm._v("取消全选")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.copyDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitCopy },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }