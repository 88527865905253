<template>
  <div>
    <div style="padding: 10px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :model="queryForm"  @submit.native.prevent @keyup.enter.native="handleQuery">
        <el-form-item label="业务域">
          <el-radio-group v-model="queryForm.buDomain" size="mini">
            <el-radio-button v-for="item in options.buDomains" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标签类型">
          <el-radio-group v-model="queryForm.tagType" size="mini">
            <el-radio-button v-for="item in options.tagTypes" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="一级分类">
          <el-radio-group v-model="queryForm.c1TagName" size="mini" @input="val => handleC1Change(val, 'query')">
            <el-radio-button v-for="item in options.c1TagNames" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="二级分类">
          <el-radio-group v-model="queryForm.c2TagName" size="mini">
            <el-radio-button v-for="item in options.c2TagNames" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item class="keyClass" label="关键词">
          <div style="display: inline-flex">
            <el-input v-model="queryForm.tagName" placeholder="请输入关键词" clearable>
              <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
            </el-input>
            <el-badge :value="moreConditionLength" class="item" type="warning">
              <el-button class="form-btn-item" size="mini" icon="el-icon-plus" style="margin-left: 10px; margin-right: 10px;" type="primary" @click="handleQueryMore">更多筛选</el-button>
            </el-badge>

          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleBatchUpdate('enableDialog')">批量启用</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleBatchUpdate('disable')">批量停用</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleAddTag">新建手工标签</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleImportUserData">批量上传数据</el-button>
          <span style="margin-left: 20px; color:#F56C6C">{{ singleTagFileUploadResult }}</span>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      ref="tagTable"
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"/>
      <el-table-column type="index" width="40" />
      <el-table-column label="标签ID" prop="tagId" sortable width="100" />
      <el-table-column label="业务域" prop="buDomain" sortable />
      <el-table-column label="标签类型" prop="tagType" sortable />
      <el-table-column label="标签形式" prop="tagForm" sortable />
      <el-table-column label="标签名称" prop="tagName" sortable />
      <el-table-column label="一级分类名称" prop="c1TagName" sortable />
      <el-table-column label="二级分类名称" prop="c2TagName" sortable />
      <el-table-column label="有效期" prop="validityDate" sortable />
      <el-table-column label="状态" prop="tagStatus" sortable>
        <template #default="{ row }">
          <el-tag :type="tagStatusMap[row.tagStatus]">
            {{ row.tagStatus }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220" align="center" fixed="right">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px;" @click="handleViewDetail(row)">查看详情</el-button>
          <el-button v-if="row.tagForm === '手工标签'" size="mini" type="text" style="border: 0px;" @click="handleImportUserData(row)">上传数据</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleFilter" />

    <el-dialog title="" :visible.sync="queryDialog.visible" width="600px">
      <el-form ref="queryFormRef" size="mini" label-width="100px" :model="queryDialog.form" @keyup.enter.native="handleQuery">
        <el-form-item label="标签形式">
          <el-radio-group v-model="queryDialog.form.tagForm" size="mini">
            <el-radio-button label="全部" />
            <el-radio-button v-for="item in options.tagForm" :key="item" :label="item" />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="有效期">
          <el-radio-group v-model="queryDialog.form.available" size="mini" @input="queryDialog.form.validityDate=undefined">
            <el-radio label="全部">全部</el-radio>
            <el-radio label="永久">永久</el-radio>
            <el-radio label="截止">截止
            </el-radio>
          </el-radio-group>
          <el-date-picker v-if="queryDialog.form.available==='截止'" v-model="queryDialog.form.validityDate" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="标签状态">
          <el-radio-group v-model="queryDialog.form.tagStatus" size="mini">
            <el-radio-button v-for="item in options.tagStatus" :key="item.code" :label="item.name"></el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="queryDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="confirmMoreQuery">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="statusDialog.visible" width="400px">
      <el-form :model="statusDialog">
        <el-form-item label="有效期" prop="validityDate" :rules="[{ required: true, message: '有效期不能为空', trigger: 'change' }]">
          <el-radio-group v-model="statusDialog.available" size="mini" @input="statusDialog.validityDate= statusDialog.available==='永久'? '1': undefined">
            <el-radio label="永久">永久</el-radio>
            <el-radio label="截止">截止  <template>
              <el-date-picker v-if="statusDialog.available==='截止'" v-model="statusDialog.validityDate" style="width: auto" size="mini" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd" />
            </template></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="statusDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="handleBatchUpdate('enable')">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="addDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="addForm" size="mini" label-width="100px" :model="addDialog.form" :rules="addDialog.rules">
        <el-form-item label="新建方式" prop="addWay">
          <el-radio-group v-model="addDialog.form.addWay" size="mini">
            <el-radio label="单独新建" />
            <el-radio label="批量上传" />
          </el-radio-group>
        </el-form-item>
        <div v-show="addDialog.form.addWay === '批量上传'" >
          <el-form-item label="模板下载">
            <el-link type="primary" :underline="false" style="font-size:14px;vertical-align: baseline;" target="_blank" :href="addDialog.templateFileDownloadUrl">下载模板</el-link>
          </el-form-item>
          <el-form-item label="上传">
            <el-upload
              ref="addUpload"
              class="upload-demo"
              :limit="1"
              action="#"
              :auto-upload="false"
              :on-change="(file, fileList) => handleFileChange(file, fileList, 'change', 'addDialog')"
              :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove', 'addDialog')"
              :file-list="addDialog.upload.fileList">
              <el-button v-show="addDialog.upload.fileList.length === 0" size="mini" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </div>
        <div v-show="addDialog.form.addWay === '单独新建'">
          <el-form-item label="业务域" prop="buDomain">
            <el-select v-model="addDialog.form.buDomain" size="mini" class="half-width">
              <el-option v-for="item in addDialog.options.buDomains" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="标签类型" prop="tagType">
            <el-select v-model="addDialog.form.tagType" size="mini" class="half-width">
              <el-option v-for="item in addDialog.options.tagTypes" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="一级分类" prop="c1TagName">
            <el-select v-model="addDialog.form.c1TagName" filterable default-first-option size="mini" allow-create class="half-width" @input="val => handleC1Change(val, 'add')">
              <el-option v-for="item in addDialog.options.c1TagNames" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="二级分类" prop="c2TagName">
            <el-select v-model="addDialog.form.c2TagName" filterable default-first-option size="mini" allow-create class="half-width">
              <el-option v-for="item in addDialog.options.c2TagNames" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item label="标签名称" prop="tagName">
            <el-input v-model="addDialog.form.tagName" size="mini" class="half-width" placeholder="请输入标签名称" />
          </el-form-item>
          <el-form-item label="有效期" prop="validityDate">
            <el-radio-group v-model="addDialog.form.available" size="mini" @input="addDialog.form.validityDate='1'">
              <el-radio label="永久" />
              <el-radio label="失效日期" />
            </el-radio-group>
            <el-date-picker v-if="addDialog.form.available==='失效日期'" v-model="addDialog.form.validityDate" placeholder="请选择" style="margin-left: 5px" type="date" format="yyyy-MM-dd" />
          </el-form-item>
          <el-form-item label="枚举值穷举">
            <el-radio-group v-model="addDialog.form.define" size="mini">
              <el-radio label="是" />
              <el-radio label="否" />
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签定义" prop="tagDefinitions">
            <el-input v-if="addDialog.form.define === '否'" v-model="addDialog.form.tagDefine" size="mini" class="half-width" placeholder="请输入标签名称" @change="handleAddDefineChange" />
            <el-button v-if="addDialog.form.define === '是'" size="mini" @click="handleAddDefineRows">新增</el-button>
            <el-table
              v-if="addDialog.form.define === '是'"
              :data="addDialog.form.tagDefinitions"
              size="mini"
              border
              highlight-current-row
              :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            >
              <el-table-column label="标签值" prop="tagVal">
                <template slot-scope="{ row }">
                  <el-input v-model="row.tagVal" type="textarea" placeholder="请输入标签值" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="定义" prop="tagDefine">
                <template slot-scope="{ row }">
                  <el-input v-model="row.tagDefine" type="textarea" placeholder="请输入标签定义" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template #default="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="deleteAddTagConditionLine(scope.$index, scope.row)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="标签说明" prop="tagExplain">
            <el-input v-model="addDialog.form.tagExplain" type="textarea" size="mini" class="half-width" placeholder="请输入标签说明" />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="addDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAddForm">确定</el-button>
      </div>
    </el-dialog>
    <el-drawer title="标签详情" :visible.sync="detailDrawer.visible" direction="rtl" size="50%">
      <el-descriptions title="" :column="1" style="padding: 15px;">
        <el-descriptions-item label="标签名称">{{ detailDrawer.form.tagName }}</el-descriptions-item>
        <el-descriptions-item label="标签ID">{{ detailDrawer.form.tagId }}</el-descriptions-item>
        <el-descriptions-item label="一级分类">{{ detailDrawer.form.c1TagName }}</el-descriptions-item>
        <el-descriptions-item label="二级分类">{{ detailDrawer.form.c2TagName }}</el-descriptions-item>
        <el-descriptions-item v-if="detailDrawer.form.tagForm === '2'" label="对应字段">
          <span v-if="!detailDrawer.tagColNameEditable" style="width: 80%" >{{ detailDrawer.form.tagColName }}</span>
          <el-select v-else v-model="detailDrawer.form.tagColName" size="mini" filterable class="half-width">
            <el-option v-for="item in options.tagColNames" :key="item" :label="item" :value="item" />
          </el-select>
          <div style="float: right; ">
            <el-button v-if="!detailDrawer.tagColNameEditable" class="buttonClass" type="text" size="small" @click="detailDrawer.tagColNameEditable = true">编辑</el-button>
            <el-button v-if="detailDrawer.tagColNameEditable" class="buttonClass" type="text" size="small" @click="updateTagDefines('tagColName')">保存</el-button>
            <el-button v-if="detailDrawer.tagColNameEditable" class="buttonClass" type="text" style="color: gray" size="small" @click="updateTagDefines('cancel')">取消</el-button>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="使用次数">{{ detailDrawer.form.useCount }}</el-descriptions-item>
        <el-descriptions-item label="标签定义">
          <div style="float:right;">
            <el-button v-if="!detailDrawer.tableEditable" class="buttonClass" type="text" size="mini" @click="detailDrawer.tableEditable = true">编辑</el-button>
            <el-button v-if="detailDrawer.tableEditable" class="buttonClass" type="text" size="mini" @click="updateTagDefines('define')">保存</el-button>
            <el-button v-if="detailDrawer.tableEditable" class="buttonClass" type="text" style="color: gray" size="mini" @click="updateTagDefines('cancel')">取消</el-button>
          </div>
          <div>
            <el-table
              :data="detailDrawer.form.tagDefinitions"
              size="mini"
              border
              highlight-current-row
              :row-style="{height:0+'px'}"
              :cell-style="{padding:0+'px'}"
              :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            >
              <el-table-column label="标签值" prop="tagVal" width="150px">
                <template slot-scope="{ row }">
                  <span v-if="!detailDrawer.tableEditable">{{ row.tagVal }}</span>
                  <el-input v-else v-model="row.tagVal" type="textarea" placeholder="请输入标签值" size="mini" @input="handlePushQwWxTagVar"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="定义" prop="tagDefine" width="200px">
                <template slot-scope="{ row }">
                  <span v-if="!detailDrawer.tableEditable">{{ row.tagDefine }}</span>
                  <el-input v-else v-model="row.tagDefine" type="textarea" placeholder="请输入标签定义" size="mini"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="覆盖人数" prop="coverUserCount" width="80" />
              <el-table-column label="是否推送企微" prop="ifPushQw" width="110px">
                <template slot-scope="{ row }">
                  <span v-if="!detailDrawer.tableEditable">{{ row.ifPushQw }}</span>
                  <el-select v-else v-model="row.ifPushQw" size="mini" placeholder="下拉选择是否推送企微" :disabled="detailDrawer.form.tagForm === '1'" @input="handlePushQwWxTagVar">
                    <el-option value="Y" label="是">是</el-option>
                    <el-option value="N" label="否">否</el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="企微标签名称" prop="wxTagVal" width="200px">
                <template slot-scope="{ row }">
                  <span>{{ row.wxTagVal }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="标签说明">
          <span v-if="!detailDrawer.explainEditable" style="width: 80%" >{{ detailDrawer.form.tagExplain }}</span>
          <el-input v-else v-model="detailDrawer.form.tagExplain" style="width: 80%" type="textarea" size="mini" class="half-width" placeholder="请输入标签说明" />
          <div style="float: right; ">
            <el-button v-if="!detailDrawer.explainEditable" class="buttonClass" type="text" size="small" @click="detailDrawer.explainEditable = true">编辑</el-button>
            <el-button v-if="detailDrawer.explainEditable" class="buttonClass" type="text" size="small" @click="updateTagDefines('explain')">保存</el-button>
            <el-button v-if="detailDrawer.explainEditable" class="buttonClass" type="text" style="color: gray" size="small" @click="updateTagDefines('cancel')">取消</el-button>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="创建人">{{ detailDrawer.form.createBy }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{ detailDrawer.form.createTime }}</el-descriptions-item>
      </el-descriptions>
      <el-button style="float: right; margin-right: 50px;" type="primary" size="mini" @click="detailDrawer.visible = false">确定</el-button>
    </el-drawer>

    <el-dialog :title="userDataDialog.title[userDataDialog.type]" :visible.sync="userDataDialog.visible" width="400px">
      <el-descriptions title="" :column="1" style="padding: 15px;" labelClassName="labelClass">
        <el-descriptions-item v-if="userDataDialog.type === 'single'" label="标签名称">{{ currentRow.tagName }}</el-descriptions-item>
        <el-descriptions-item v-if="userDataDialog.type === 'single'" label="标签ID">{{ currentRow.tagId }}</el-descriptions-item>
        <el-descriptions-item label="模板下载">
          <el-link type="primary" :underline="false" style="font-size:14px;vertical-align: baseline;" target="_blank" :href="userDataDialog.templateFileDownloadUrl">下载模板</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="上传">
          <el-upload
            class="upload-demo"
            :limit="1"
            action="#"
            ref="userUpload"
            :auto-upload="false"
            :on-change="(file, fileList) => handleFileChange(file, fileList, 'change', 'userDataDialog')"
            :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove', 'userDataDialog')"
            :file-list="userDataDialog.upload.fileList">
            <el-button v-show="userDataDialog.upload.fileList.length === 0" size="mini" type="primary">点击上传</el-button>
          </el-upload>
        </el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="userDataDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitImportUserData">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllDict,
  getC2Dict,
  getTagList,
  addManualTag,
  importTags,
  importTagUserData,
  importSingleTagData,
  getTagDetail,
  updateTagDefine,
  updateTagExplain,
  enableTags,
  disableTags,
  updateTagColName,
  getLogicTagColNameList,queryFileUploadStatus
} from "@/api/tagPlatform";
import { deepClone, parseTime } from "@/utils";
import Pagination from "@/components/Pagination";
import { Notification } from "element-ui";
import timer from "@/utils/timer";

export default {
  name: "Tag",
  components: { Pagination },
  data() {
    return {
      loading: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        tagName: undefined,
        buDomain: '全部',
        tagType: '全部',
        c1TagName: '全部',
        c2TagName: '全部',
        available: '全部',
        validityDate: undefined,
        tagStatus: '全部',
        tagForm: '全部',
        order: {
          column: undefined,
          operator: undefined
        }
      },
      moreConditionKey: ['tagForm', 'available', 'tagStatus'],
      options: {
        buDomains: [],
        tagTypes: [],
        c1TagNames: [],
        allC2Tags: [],
        c2TagNames: [],
        tagStatus: [],
        tagForm: ['逻辑标签', '手工标签'],
        tagFormObj: [{ label: '逻辑标签', value: '2'}, { label: '手工标签', value: '1'}],
        tagColNames: [],
      },
      tagStatusMap: {
        '生成中': 'primary',
        '启用': 'success',
        '停用': 'danger',
      },
      queryDialog: {
        visible: false,
        form: {
          tagForm: '全部',
          available: '全部',
          validityDate: undefined,
          tagStatus: '全部'
        }
      },
      statusDialog: {
        visible: false,
        validityDate: undefined,
        available: '截止'
      },
      addDialog: {
        visible: false,
        templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E6%89%8B%E5%B7%A5%E6%A0%87%E7%AD%BE%E6%89%B9%E9%87%8F%E6%96%B0%E5%BB%BA%E6%A8%A1%E6%9D%BF.xlsx',
        uploadUrl: process.env.VUE_APP_BASE_URL.split(',')[3] + '/import',
        upload: {
          fileList: [],
          accept: ['.xls', '.xlsx']
        },
        options: {
          buDomains: [],
          tagTypes: [],
          c1TagNames: [],
          c2TagNames: [],
        },
        form: {
          addWay: '单独新建',
          buDomain: undefined,
          tagType: undefined,
          c1TagName: undefined,
          c2TagName: undefined,
          tagName: undefined,
          available: '失效日期',
          validityDate: undefined,
          define: '否',
          tagDefine: undefined,
          tagDefinitions: [],
          tagExplain: undefined,
        },
        rules: {
          buDomain: [{required: true, message: ' ', trigger: 'change'}],
          tagType: [{required: true, message: ' ', trigger: 'change'}],
          c1TagName: [{required: true, message: ' ', trigger: 'change'}],
          c2TagName: [{required: true, message: ' ', trigger: 'change'}],
          tagName: [{required: true, message: ' ', trigger: 'change'}],
          available: [{required: true, message: ' ', trigger: 'change'}],
          validityDate: [{required: true, message: ' ', trigger: 'change'}],
          tagDefine: [{required: true, message: ' ', trigger: 'change'}],
          tagDefinitions: [{required: true, message: ' ', trigger: 'change'}],
          tagExplain: [{required: true, message: ' ', trigger: 'change'}],
        }
      },
      detailDrawer: {
        visible: false,
        tableEditable: false,
        explainEditable: false,
        tagColNameEditable: false,
        form: {
          id: undefined,
          tagName: undefined,
          tagId: undefined,
          c1TagName: undefined,
          c2TagName: undefined,
          tagColName: undefined,
          useCount: undefined,
          tagDefinitions: [],
          tagExplain: undefined,
          tagForm: undefined,
        },
        originTagDefinitions: [],
        originTagExplain: undefined,
        originTagColName: undefined,
      },
      userDataDialog: {
        visible: false,
        type: 'single',
        upload: {
          fileList: [],
          accept: ['.xls', '.xlsx']
        },
        title: {
          'single': '标签数据上传',
          'batch': '批量上传数据'
        },
        form: {
          tagId: undefined,
          tagName: undefined,
        },
        templateFileDownloadUrl:  undefined,
        batchTemplateUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E6%89%B9%E9%87%8F%E4%B8%8A%E4%BC%A0%E6%A0%87%E7%AD%BE%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx',
        singleTemplateUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%8D%95%E4%B8%AA%E6%A0%87%E7%AD%BE%E6%95%B0%E6%8D%AE%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx',
      },
      currentRow: {},
      singleTagFileUploadResult: '',
    }
  },
  computed: {
    moreConditionLength() {
      return this.moreConditionKey.filter(e => this.queryForm[e] !== '全部').length;
    },
  },
  created() {
    getAllDict().then(res => {
      this.addDialog.options.buDomains = deepClone(res.data.buDomains);
      this.addDialog.options.tagTypes = deepClone(res.data.tagTypes);
      this.addDialog.options.c1TagNames = deepClone(res.data.c1TagNames);

      this.options.buDomains = ['全部'].concat(res.data.buDomains);
      this.options.tagTypes = ['全部'].concat(res.data.tagTypes);
      this.options.c1TagNames = ['全部'].concat(res.data.c1TagNames);
      this.options.allC2Tags = ['全部'].concat(res.data.c2TagNames);
      this.options.c2TagNames = deepClone(this.options.allC2Tags);
      this.options.tagStatus = [{ name: '全部', code: '全部'}].concat(res.data.tagStatus);
    })
    getLogicTagColNameList().then(res => {
      this.options.tagColNames = deepClone(res.data);
    })
    this.handleQuery();
  },
  methods: {
    handleFilter() {
      this.loading =true;
      let param = deepClone(this.queryForm);
      for (const key in param) {
        if (param[key] === '全部') {
          param[key] = undefined
        } else if (key === 'available') {
          if (param[key] === '永久') {
            param.validityDate = '永久';
          }
        } else if (key === 'tagStatus') {
          param[key] = this.options.tagStatus.find(e => e.name === param[key]).code;
        }
      }
      if (param.tagForm) {
        param.tagForm = this.options.tagFormObj.find(e => e.label === param.tagForm).value;
      }
      getTagList(param).then(res => {
        this.pageData.rows = res.data.datas;
        this.pageData.total = res.data.total;
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.tagTable.doLayout()
        })
      })
    },
    handleQuery() {
      this.queryForm.page = 1;
      this.handleFilter();
    },
    handleC1Change(val, type) {
      let query = {}
      if (val && val !== '全部') {
        query = { c1TagName: val };
        getC2Dict(query).then(res => {
          if (type === 'query') {
            this.options.c2TagNames = ['全部'].concat(res.data);
            this.queryForm.c2TagName = '全部';
          } else if (type === 'add') {
            this.addDialog.options.c2TagNames = res.data;
          }
        })
      } else {
        if (type === 'query') {
          this.options.c2TagNames = deepClone(this.options.allC2Tags);
        } else if (type === 'add') {
          this.addDialog.options.c2TagNames = this.options.c2TagNames = deepClone(this.options.allC2Tags);
        }
      }
    },
    handleQueryMore() {
      this.options.form = {
        tagForm: this.queryForm.tagForm,
        available: this.queryForm.available,
        validityDate: this.queryForm.validityDate,
        tagStatus: this.queryForm.tagStatus
      };
      this.queryDialog.visible = true;
    },
    confirmMoreQuery() {
      this.queryForm = deepClone(Object.assign(this.queryForm, this.queryDialog.form));
      this.queryDialog.visible = false;
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleQuery();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleBatchUpdate(type) {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少勾选一条数据");
        return false;
      }
      const ids = this.pageData.selections.filter(e => (type.indexOf('enable') > -1 && e.tagStatus === '停用') || (type.indexOf('disable') > -1 && e.tagStatus !== '停用')).map( e =>  e.id);
      if (type === 'enableDialog') {
        if (ids.length < this.pageData.selections.length) {
          Notification.warning("生成中数据及启用数据无法启用,请取消相应数据勾选!");
          return false;
        }
        this.statusDialog.validityDate = undefined;
        this.statusDialog.visible = true;
      } else if (type === 'enable') {
        if (!this.statusDialog.validityDate) {
          return false;
        }
        const date = this.statusDialog.available === '截止'? this.statusDialog.validityDate: this.statusDialog.available;
        enableTags({ ids: ids, validityDate: date }).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.handleQuery();
            this.statusDialog.visible = false;
          }
        })
      } else {
        if (ids.length < this.pageData.selections.length) {
          Notification.warning("停用数据无法再次停用,请取消相应数据勾选!");
          return false;
        }
        disableTags({ ids: ids }).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.handleQuery();
          }
        })
      }
    },
    handleAddTag() {
      this.addDialog.form = {
        addWay: '单独新建',
        buDomain: undefined,
        tagType: undefined,
        c1TagName: undefined,
        c2TagName: undefined,
        tagName: undefined,
        available: '失效日期',
        validityDate: undefined,
        define: '否',
        tagDefine: undefined,
        tagDefinitions: [],
        tagExplain: undefined,
      }

      this.addDialog.visible = true;
      this.clearUploadFiles('addUpload', 'addDialog');
    },
    handleAddDefineChange(val) {
      this.addDialog.form.tagDefinitions = [].concat({ tagVal: '', tagDefine: val});
      this.$forceUpdate();
    },
    submitAddForm() {
      if (this.addDialog.form.addWay === '单独新建') {
        this.$refs.addForm.validate(valid => {
          if (valid) {
            let form = deepClone(this.addDialog.form);
            if (form.available === '永久') {
              form.validityDate = '永久';
            } else {
              form.validityDate = parseTime(form.validityDate, '{y}-{m}-{d}');
            }
            form.tagDefinitions = form.tagDefinitions.filter(e => !(e.tagVal && e.tagVal==='' && e.tagDefine==='' && e.tagDefine));
            addManualTag(form).then(res => {
              if (res && res.code === 200) {
                Notification.success('操作成功！');
                this.handleQuery();
                this.addDialog.visible = false;
              }
            })
          }
        })
      } else {
        if (this.addDialog.upload.fileList.length === 0) {
          Notification.warning('请先上传文件！');
          return false;
        }
        let fd = new FormData();
        this.addDialog.upload.fileList.forEach(item=>{
          //文件信息中raw才是真的文件
          fd.append("file",item.raw);
        })
        importTags(fd).then(res => {
          if (res && res.code === 200) {
            this.clearUploadFiles('addUpload', 'addDialog');
            Notification.success('操作成功！');
            this.handleQuery();
            this.addDialog.visible = false;
          }
        });
      }
    },
    handleAddDefineRows() {
      this.addDialog.form.tagDefinitions.push({ tagVal: '', tagDefine: '' })
    },
    handleFileChange(file, fileList, type, dialog) {
      if (type === 'remove') {
        this[dialog].upload.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this[dialog].upload.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this[dialog].upload.fileList = [];
      } else {
        this[dialog].upload.fileList = fileList;
      }
    },
    handleViewDetail(row) {
      this.detailDrawer.tagColNameEditable = false;
      this.detailDrawer.tableEditable = false;
      this.detailDrawer.explainEditable = false;
      this.detailDrawer.form = {
        id: undefined,
        tagName: undefined,
        tagId: undefined,
        c1TagName: undefined,
        c2TagName: undefined,
        useCount: undefined,
        tagDefinitions: [],
        tagExplain: undefined
      };
      this.currentRow = row;
      getTagDetail(row.id).then(res => {
        if (res && res.code === 200) {
          this.detailDrawer.form = deepClone(res.data);
          this.detailDrawer.originTagExplain = res.data.tagExplain;
          this.detailDrawer.originTagDefinitions = res.data.tagDefinitions;
          this.detailDrawer.originTagColName = res.data.tagColName;
          this.detailDrawer.visible = true;
        }
      })
    },
    updateTagDefines(type) {
      if (type === 'define') {
        const param = { tagDefinitions: this.detailDrawer.form.tagDefinitions };
        updateTagDefine(this.currentRow.id, param).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.detailDrawer.tableEditable = false;
          }
        })
      } else if (type === 'explain') {
        const param = { tagExplain: this.detailDrawer.form.tagExplain };
        updateTagExplain(this.currentRow.id, param).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.detailDrawer.explainEditable = false;
          }
        })
      } else if (type === 'tagColName') {
        const param = { tagColName: this.detailDrawer.form.tagColName };
        updateTagColName(this.currentRow.id, param).then(res => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.detailDrawer.tagColNameEditable = false;
          }else {
            Notification.error(res.msg);
            this.detailDrawer.form.tagColName = this.detailDrawer.originTagColName + '';
            this.detailDrawer.tagColNameEditable = false;
          }
        })
      }else if (type === 'cancel') {
        this.detailDrawer.form.tagExplain = this.detailDrawer.originTagExplain + '';
        this.detailDrawer.form.tagDefinitions = deepClone(this.detailDrawer.originTagDefinitions);
        this.detailDrawer.form.tagColName = this.detailDrawer.originTagColName + '';
        this.detailDrawer.explainEditable = false;
        this.detailDrawer.tableEditable = false;
        this.detailDrawer.tagColNameEditable = false;
      }
    },
    handleImportUserData(row) {
      this.currentRow = row;
      if (row.id) {
        this.userDataDialog.type = 'single';
        this.userDataDialog.templateFileDownloadUrl = this.userDataDialog.singleTemplateUrl;
      } else {
        this.userDataDialog.type = 'batch';
        this.userDataDialog.templateFileDownloadUrl = this.userDataDialog.batchTemplateUrl;
      }
      this.userDataDialog.visible = true;

      this.clearUploadFiles('userUpload', 'userDataDialog');
    },
    clearUploadFiles(ref, dialog) {
      this.$nextTick(() => {
        this.$refs[ref].clearFiles();
        this[dialog].upload.fileList = [];
      })
    },
    submitImportUserData() {
      if (this.userDataDialog.upload.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.userDataDialog.upload.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })

      if (this.userDataDialog.type == 'single') {
        fd.append("id", this.currentRow.id);
        importSingleTagData(fd).then((res) => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.clearUploadFiles('userUpload', 'userDataDialog');
            this.userDataDialog.visible = false;
            this.handleQuery();
            this.singleTagFileUploadResult = '单个标签数据文件上传正在处理中...'
            timer.createTimer('singleTagFileUploadStatus', () => {
              this.handleSingleTagFileUpload(res.data)
            }, 5000)
          }else {
            Notification.error("文件上传失败！");
          }
        })
      } else if (this.userDataDialog.type === 'batch') {
        importTagUserData(fd).then((res) => {
          if (res && res.code === 200) {
            Notification.success('操作成功！');
            this.clearUploadFiles('userUpload', 'userDataDialog');
            this.userDataDialog.visible = false;
            this.handleQuery();
          }
        })
      }
    },
    handlePushQwWxTagVar(){
      this.detailDrawer.form.tagDefinitions.forEach(item => {
        if(item.ifPushQw === 'Y'){
          item.wxTagVal = this.detailDrawer.form.tagName + '-' + item.tagVal;
        }else {
          item.wxTagVal = '';
        }
      })
      this.$forceUpdate();
    },
    deleteAddTagConditionLine(index){
      this.addDialog.form.tagDefinitions.splice(index, 1)
    },
    handleSingleTagFileUpload(fileId){
      queryFileUploadStatus(fileId).then((res) => {
        if (res.code === 200) {
          this.singleTagFileUploadResult = res.data;
          if(res.data.indexOf("上传成功") > 0 || res.data.indexOf("上传失败") > 0){
            this.handleQuery();
            timer?.clearTimer('singleTagFileUploadStatus');
          }
        }
        if (res.code !== 200) {
          timer?.clearTimer('singleTagFileUploadStatus');
          this.$message.error(res.msg);
          this.handleQuery();
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.keyClass {
  ::v-deep .el-input__inner {
    min-width: 250px !important;
  }
}

::v-deep .el-date-editor .el-input__inner {
  text-align: center;
}

.half-width {
  width: 45%;
  ::v-deep .el-input .el-input__inner {
     max-width: 100% !important;
   }
  ::v-deep .el-input__inner {
    max-width: 100% !important;
  }
  ::v-deep .el-date-editor .el-input__inner {
    max-width: 100% !important;
  }
}

::v-deep .el-descriptions-row .el-descriptions-item__label {
  font-weight: 600;
}

::v-deep .el-descriptions-item__container .labelClass {
  width: 80px;
}

::v-deep .el-badge.item {
  top: -1px;
}
::v-deep .el-badge__content.is-fixed {
  right: 25px !important;
}

.buttonClass {
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
}
</style>
