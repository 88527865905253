var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分公司范围" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.orgRange,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgRange", $$v)
                                },
                                expression: "queryForm.orgRange",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入标题关键字",
                        },
                        model: {
                          value: _vm.queryForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "name", $$v)
                          },
                          expression: "queryForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "核销类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.queryForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "type", $$v)
                            },
                            expression: "queryForm.type",
                          },
                        },
                        _vm._l(_vm.typeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _vm.authOrgAll
            ? _c(
                "el-button",
                {
                  staticStyle: { border: "0px" },
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit()
                    },
                  },
                },
                [_vm._v("新建")]
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", { attrs: { label: "标题", prop: "name" } }),
              _c("el-table-column", {
                attrs: { label: "创建者", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "核销类型", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.typeOptions.find((e) => e.value == row.type)
                                ?.label
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "300", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleView(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _vm.authOrgAll
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.viewDialog.titleMap[_vm.viewDialog.type],
            center: true,
            visible: _vm.viewDialog.visible,
            "append-to-body": "",
            width: "750px",
            "close-on-click-modal": false,
            "before-close": _vm.handleBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.viewDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "divRef",
              staticStyle: { "overflow-y": "scroll", height: "500px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    position: "absolute",
                    bottom: "5px",
                    width: "90%",
                    "background-color": "white",
                    height: "30px",
                  },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "inline-block", float: "right" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.viewDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.currentRow.name,
            visible: _vm.viewDrawer.visible,
            size: "70%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.viewDrawer, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mg-search",
              staticStyle: { "padding-bottom": "15px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "queryFormRef",
                  attrs: {
                    size: "mini",
                    "label-width": "auto",
                    inline: true,
                    model: _vm.viewDrawer.queryForm,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilterModule.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "ht-org",
                        attrs: {
                          type: "ht-org",
                          value: _vm.viewDrawer.queryForm.orgCode,
                          linkageRef: "ht-dc",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          "default-first-option": "",
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.viewDrawer.queryForm,
                              "orgCode",
                              $event
                            )
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "ht-dc",
                        attrs: {
                          type: "ht-dc",
                          value: _vm.viewDrawer.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.viewDrawer.queryForm,
                              "dcCode",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({
                              orgCode: _vm.viewDrawer.queryForm.orgCode,
                            }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", multiple: "" },
                          model: {
                            value: _vm.viewDrawer.queryForm.statuss,
                            callback: function ($$v) {
                              _vm.$set(_vm.viewDrawer.queryForm, "statuss", $$v)
                            },
                            expression: "viewDrawer.queryForm.statuss",
                          },
                        },
                        _vm._l(_vm.queryStatusOptions, function (item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item, value: key + "" },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "inline-block" } },
                    _vm._l(
                      _vm.viewDrawer.queryForm.queryFields,
                      function (item) {
                        return _c(
                          "el-form-item",
                          { key: item.id, attrs: { label: item.fieldLabel } },
                          [
                            item.fieldType === "input"
                              ? _c("el-input", {
                                  staticStyle: { width: "180px" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: item.queryValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "queryValue", $$v)
                                    },
                                    expression: "item.queryValue",
                                  },
                                })
                              : _vm._e(),
                            item.fieldType === "select"
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: item.queryValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "queryValue", $$v)
                                      },
                                      expression: "item.queryValue",
                                    },
                                  },
                                  _vm._l(
                                    _vm.getConfigOptions(item),
                                    function (e, key) {
                                      return _c("el-option", {
                                        key: key,
                                        attrs: {
                                          label: e.label,
                                          value: e.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            item.fieldType === "date"
                              ? _c("el-date-picker", {
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "至",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                  },
                                  on: {
                                    change: (val) => {
                                      if (val) {
                                        item.queryValue1 = _vm.parseTime(
                                          val[0],
                                          "{y}-{m}-{d}"
                                        )
                                        item.queryValue2 = _vm.parseTime(
                                          val[1],
                                          "{y}-{m}-{d}"
                                        )
                                      } else {
                                        item.queryValue1 = undefined
                                        item.queryValue2 = undefined
                                      }
                                    },
                                  },
                                  model: {
                                    value: item.queryValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "queryValue", $$v)
                                    },
                                    expression: "item.queryValue",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-search",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleFilterModule()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleExport(undefined)
                            },
                          },
                        },
                        [_vm._v("导出")]
                      ),
                      _c("DownLoadData", { attrs: { type: "QD_HX" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.viewDrawer.loading,
                  expression: "viewDrawer.loading",
                },
              ],
              ref: "tableRef",
              attrs: {
                data: _vm.viewDrawer.pageData.rows,
                height: "calc(100vh - 250px)",
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "分公司",
                  prop: "orgName",
                  width: "120",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "配送中心名称",
                  prop: "dcName",
                  width: "160",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.dcCode
                                ? row.dcCode + "-" + row.dcName
                                : row.dcCode
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.viewDrawer.dynamicColumns, function (item) {
                return [
                  _c("el-table-column", {
                    key: item.prop,
                    attrs: {
                      label: item.label,
                      prop: item.prop,
                      align: "center",
                    },
                  }),
                ]
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  prop: "status",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.queryStatusOptions[row.status])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  prop: "createUser",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.createUser + "-" + row.createName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  prop: "createTime",
                  width: "140",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.computeButtonShow(row.status, "pass") ||
                        _vm.computeButtonShow(row.status, "reject")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleViewModuleDetail(
                                      row,
                                      "audit"
                                    )
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViewModuleDetail(row, "view")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.viewDrawer.pageData.total,
              page: _vm.viewDrawer.queryForm.current,
              limit: _vm.viewDrawer.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.viewDrawer.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.viewDrawer.queryForm, "size", $event)
              },
              pagination: _vm.handleFilterModule,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.currentRow.name,
            visible: _vm.viewDetailDrawer.visible,
            size: "50%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.viewDetailDrawer, "visible", $event)
            },
          },
        },
        _vm._l(_vm.viewDetailDrawer.form.contents, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticStyle: { "font-size": "14px", margin: "15px" },
            },
            [
              _c("div", [
                item.moduleType === "0"
                  ? _c(
                      "div",
                      _vm._l(item.fields, function (field) {
                        return _c(
                          "div",
                          {
                            key: field.id,
                            staticStyle: {
                              display: "inline-flex",
                              width: "100%",
                              padding: "5px",
                            },
                          },
                          [
                            ["text-title"].indexOf(field.fieldType) < 0
                              ? _c(
                                  "div",
                                  {
                                    style:
                                      field.fieldType === "data"
                                        ? {}
                                        : { display: "inline-flex" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getShowFieldLabel(field, item)
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getShowFieldDesc(field)
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v("： "),
                                      ]
                                    ),
                                    ["upload"].indexOf(field.fieldType) > -1
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          _vm._l(
                                            _vm.getModuleResource(
                                              _vm.getShowFieldValue(
                                                field,
                                                item
                                              ),
                                              undefined
                                            ),
                                            function (im, imIndx) {
                                              return _c(
                                                "el-image",
                                                {
                                                  key: imIndx,
                                                  staticStyle: {
                                                    height: "80px",
                                                    margin: "5px",
                                                  },
                                                  attrs: {
                                                    "preview-src-list":
                                                      _vm.getModuleResource(
                                                        _vm.getShowFieldValue(
                                                          field,
                                                          item
                                                        ),
                                                        undefined
                                                      ),
                                                    src: im,
                                                    lazy: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-picture-outline",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : ["upload-module"].indexOf(
                                          field.fieldType
                                        ) > -1
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              _vm.getModuleResource(
                                                _vm.getShowFieldValue(
                                                  field,
                                                  item
                                                ),
                                                "before"
                                              ),
                                              function (im, imIndx) {
                                                return _c(
                                                  "el-image",
                                                  {
                                                    key: imIndx,
                                                    staticStyle: {
                                                      height: "80px",
                                                      margin: "5px",
                                                    },
                                                    attrs: {
                                                      "preview-src-list":
                                                        _vm.getModuleResource(
                                                          _vm.getShowFieldValue(
                                                            field,
                                                            item
                                                          ),
                                                          "before"
                                                        ),
                                                      src: im,
                                                      lazy: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "image-slot",
                                                        attrs: {
                                                          slot: "error",
                                                        },
                                                        slot: "error",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-picture-outline",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._l(
                                              _vm.getModuleResource(
                                                _vm.getShowFieldValue(
                                                  field,
                                                  item
                                                ),
                                                "after"
                                              ),
                                              function (im, imIndx) {
                                                return _c(
                                                  "el-image",
                                                  {
                                                    key: imIndx,
                                                    staticStyle: {
                                                      height: "80px",
                                                      margin: "5px",
                                                    },
                                                    attrs: {
                                                      "preview-src-list":
                                                        _vm.getModuleResource(
                                                          _vm.getShowFieldValue(
                                                            field,
                                                            item
                                                          ),
                                                          "after"
                                                        ),
                                                      src: im,
                                                      lazy: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "image-slot",
                                                        attrs: {
                                                          slot: "error",
                                                        },
                                                        slot: "error",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-picture-outline",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : "group" == field.fieldType
                                      ? _c(
                                          "div",
                                          _vm._l(
                                            item.childContents,
                                            function (childContent, cIndex) {
                                              return _c(
                                                "div",
                                                { key: cIndex },
                                                _vm._l(
                                                  childContent.fields,
                                                  function (cField) {
                                                    return _c(
                                                      "div",
                                                      { key: cField.id },
                                                      [
                                                        [
                                                          "text-shop-info",
                                                          "text-title",
                                                        ].indexOf(
                                                          cField.fieldType
                                                        ) < 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                style:
                                                                  cField.fieldType ===
                                                                  "data"
                                                                    ? {}
                                                                    : {
                                                                        display:
                                                                          "inline-flex",
                                                                      },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline-block",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getShowFieldLabel(
                                                                              cField,
                                                                              childContent
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.getShowFieldDesc(
                                                                      cField
                                                                    )
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "10px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "(" +
                                                                                _vm._s(
                                                                                  _vm.getShowFieldDesc(
                                                                                    cField
                                                                                  )
                                                                                ) +
                                                                                ")"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      "： "
                                                                    ),
                                                                  ]
                                                                ),
                                                                [
                                                                  "upload",
                                                                ].indexOf(
                                                                  cField.fieldType
                                                                ) > -1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "inline-block",
                                                                          },
                                                                      },
                                                                      _vm._l(
                                                                        _vm.getModuleResource(
                                                                          _vm.getShowFieldValue(
                                                                            cField,
                                                                            childContent
                                                                          ),
                                                                          undefined
                                                                        ),
                                                                        function (
                                                                          cim,
                                                                          cImIndx
                                                                        ) {
                                                                          return _c(
                                                                            "el-image",
                                                                            {
                                                                              key: cImIndx,
                                                                              staticStyle:
                                                                                {
                                                                                  height:
                                                                                    "80px",
                                                                                  margin:
                                                                                    "5px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  "preview-src-list":
                                                                                    _vm.getModuleResource(
                                                                                      _vm.getShowFieldValue(
                                                                                        cField,
                                                                                        childContent
                                                                                      ),
                                                                                      undefined
                                                                                    ),
                                                                                  src: cim,
                                                                                  lazy: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "image-slot",
                                                                                  attrs:
                                                                                    {
                                                                                      slot: "error",
                                                                                    },
                                                                                  slot: "error",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-icon-picture-outline",
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  : [
                                                                      "upload-module",
                                                                    ].indexOf(
                                                                      cField.fieldType
                                                                    ) > -1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "block",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                display:
                                                                                  "inline-block",
                                                                              },
                                                                          },
                                                                          [
                                                                            !_vm.getFieldConfigJson(
                                                                              cField
                                                                            )
                                                                              .isOnlyBefore
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "打造前"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._l(
                                                                              _vm.getModuleResource(
                                                                                _vm.getShowFieldValue(
                                                                                  cField,
                                                                                  childContent
                                                                                ),
                                                                                "before"
                                                                              ),
                                                                              function (
                                                                                cim,
                                                                                cImIndx
                                                                              ) {
                                                                                return _c(
                                                                                  "el-image",
                                                                                  {
                                                                                    key: cImIndx,
                                                                                    staticStyle:
                                                                                      {
                                                                                        height:
                                                                                          "80px",
                                                                                        margin:
                                                                                          "5px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        "preview-src-list":
                                                                                          _vm.getModuleResource(
                                                                                            _vm.getShowFieldValue(
                                                                                              cField,
                                                                                              childContent
                                                                                            ),
                                                                                            "before"
                                                                                          ),
                                                                                        src: cim,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "image-slot",
                                                                                        attrs:
                                                                                          {
                                                                                            slot: "error",
                                                                                          },
                                                                                        slot: "error",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-icon-picture-outline",
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                          ],
                                                                          2
                                                                        ),
                                                                        !_vm.getFieldConfigJson(
                                                                          cField
                                                                        )
                                                                          .isOnlyBefore
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    display:
                                                                                      "inline-block",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "打造后"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._l(
                                                                                  _vm.getModuleResource(
                                                                                    _vm.getShowFieldValue(
                                                                                      cField,
                                                                                      childContent
                                                                                    ),
                                                                                    "after"
                                                                                  ),
                                                                                  function (
                                                                                    cim,
                                                                                    cImIndx
                                                                                  ) {
                                                                                    return _c(
                                                                                      "el-image",
                                                                                      {
                                                                                        key: cImIndx,
                                                                                        staticStyle:
                                                                                          {
                                                                                            height:
                                                                                              "80px",
                                                                                            margin:
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            "preview-src-list":
                                                                                              _vm.getModuleResource(
                                                                                                _vm.getShowFieldValue(
                                                                                                  cField,
                                                                                                  childContent
                                                                                                ),
                                                                                                "after"
                                                                                              ),
                                                                                            src: cim,
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "image-slot",
                                                                                            attrs:
                                                                                              {
                                                                                                slot: "error",
                                                                                              },
                                                                                            slot: "error",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "i",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "el-icon-picture-outline",
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              2
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  : "data" ==
                                                                    cField.fieldType
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "el-table",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                data: childContent.quotas,
                                                                                height:
                                                                                  "120px",
                                                                                size: "mini",
                                                                                "highlight-current-row":
                                                                                  "",
                                                                                "header-cell-style":
                                                                                  {
                                                                                    background:
                                                                                      "rgb(235 235 235)",
                                                                                    color:
                                                                                      "black",
                                                                                    textAlign:
                                                                                      "center",
                                                                                    verticalAlign:
                                                                                      "middle",
                                                                                  },
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-table-column",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "指标名",
                                                                                    prop: "fieldName",
                                                                                    width:
                                                                                      "250",
                                                                                    align:
                                                                                      "center",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "el-table-column",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "达成",
                                                                                    prop: "quotaValue",
                                                                                    width:
                                                                                      "100",
                                                                                    align:
                                                                                      "center",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "default",
                                                                                        fn: function ({
                                                                                          row,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    (row.quotaValue
                                                                                                      ? row.quotaValue
                                                                                                      : "") +
                                                                                                      (row.unit &&
                                                                                                      row.quotaValue
                                                                                                        ? row.unit
                                                                                                        : "")
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  ),
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "el-table-column",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "目标",
                                                                                    prop: "target",
                                                                                    width:
                                                                                      "140",
                                                                                    align:
                                                                                      "center",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "default",
                                                                                        fn: function ({
                                                                                          row,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    (row.target
                                                                                                      ? row.target
                                                                                                      : "") +
                                                                                                      (row.unit &&
                                                                                                      row.target
                                                                                                        ? row.unit
                                                                                                        : "")
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "inline-block",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getShowFieldValue(
                                                                              cField,
                                                                              childContent
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : "data" == field.fieldType
                                      ? _c(
                                          "div",
                                          { staticStyle: { display: "block" } },
                                          [
                                            _c(
                                              "el-table",
                                              {
                                                attrs: {
                                                  data: item.quotas,
                                                  height: "120px",
                                                  size: "mini",
                                                  "highlight-current-row": "",
                                                  "header-cell-style": {
                                                    background:
                                                      "rgb(235 235 235)",
                                                    color: "black",
                                                    textAlign: "center",
                                                    verticalAlign: "middle",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "指标名",
                                                    prop: "fieldName",
                                                    width: "250",
                                                    align: "center",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "达成",
                                                    prop: "quotaValue",
                                                    width: "100",
                                                    align: "center",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({ row }) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (row.quotaValue
                                                                    ? row.quotaValue
                                                                    : "") +
                                                                    (row.unit &&
                                                                    row.quotaValue
                                                                      ? row.unit
                                                                      : "")
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "目标",
                                                    prop: "target",
                                                    width: "140",
                                                    align: "center",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({ row }) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (row.target
                                                                    ? row.target
                                                                    : "") +
                                                                    (row.unit
                                                                      ? row.unit
                                                                      : "")
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : "choose-shop" === field.fieldType
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getShowFieldValue(
                                                    field,
                                                    item
                                                  )
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "el-collapse",
                                              {
                                                model: {
                                                  value:
                                                    _vm.viewDetailDrawer
                                                      .activeName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.viewDetailDrawer,
                                                      "activeName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "viewDetailDrawer.activeName",
                                                },
                                              },
                                              _vm._l(
                                                item.childContents,
                                                function (
                                                  childContent,
                                                  cIndex
                                                ) {
                                                  return _c(
                                                    "el-collapse-item",
                                                    {
                                                      key: cIndex,
                                                      attrs: {
                                                        name: childContent.poiCode,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                childContent
                                                                  .shopInfo
                                                                  .shopName
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              style:
                                                                childContent.status ===
                                                                "-1"
                                                                  ? {
                                                                      color:
                                                                        "red",
                                                                    }
                                                                  : childContent.status ===
                                                                    "0"
                                                                  ? {
                                                                      color:
                                                                        "#a0a09b",
                                                                    }
                                                                  : {
                                                                      color:
                                                                        "green",
                                                                    },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  " (" +
                                                                    _vm
                                                                      .viewDetailDrawer
                                                                      .shopStatusMap[
                                                                      childContent
                                                                        .status
                                                                    ] +
                                                                    ")"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  childContent
                                                                    .shopInfo
                                                                    .dcCode +
                                                                    "/" +
                                                                    childContent
                                                                      .shopInfo
                                                                      .shopCode
                                                                )
                                                            ),
                                                          ]),
                                                          _c("div", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "是否重点终端："
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    childContent
                                                                      .shopInfo
                                                                      .ifOptimumShop ===
                                                                      "1"
                                                                      ? "是"
                                                                      : "否"
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "终端容量标签："
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    childContent
                                                                      .shopInfo
                                                                      .shopCapacity
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "包干负责人："
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    childContent
                                                                      .shopInfo
                                                                      .userName
                                                                      ? childContent
                                                                          .shopInfo
                                                                          .userName +
                                                                          "-" +
                                                                          childContent
                                                                            .shopInfo
                                                                            .nickName
                                                                      : ""
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "提交人："
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    childContent.createUser
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "提交时间："
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  childContent.createTime
                                                                )
                                                              ),
                                                            ]),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        childContent.fields,
                                                        function (cField) {
                                                          return _c(
                                                            "div",
                                                            { key: cField.id },
                                                            [
                                                              [
                                                                "text-shop-info",
                                                                "text-title",
                                                              ].indexOf(
                                                                cField.fieldType
                                                              ) < 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      style:
                                                                        cField.fieldType ===
                                                                        "data"
                                                                          ? {}
                                                                          : {
                                                                              display:
                                                                                "inline-flex",
                                                                            },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "inline-block",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getShowFieldLabel(
                                                                                    cField,
                                                                                    childContent
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "(" +
                                                                                  _vm._s(
                                                                                    _vm.getShowFieldDesc(
                                                                                      cField
                                                                                    )
                                                                                  ) +
                                                                                  ")"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      [
                                                                        "upload",
                                                                      ].indexOf(
                                                                        cField.fieldType
                                                                      ) > -1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "inline-block",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.getModuleResource(
                                                                                _vm.getShowFieldValue(
                                                                                  cField,
                                                                                  childContent
                                                                                ),
                                                                                undefined
                                                                              ),
                                                                              function (
                                                                                cim,
                                                                                cImIndx
                                                                              ) {
                                                                                return _c(
                                                                                  "el-image",
                                                                                  {
                                                                                    key: cImIndx,
                                                                                    staticStyle:
                                                                                      {
                                                                                        height:
                                                                                          "80px",
                                                                                        margin:
                                                                                          "5px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        "preview-src-list":
                                                                                          _vm.getModuleResource(
                                                                                            _vm.getShowFieldValue(
                                                                                              cField,
                                                                                              childContent
                                                                                            ),
                                                                                            undefined
                                                                                          ),
                                                                                        src: cim,
                                                                                        lazy: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "image-slot",
                                                                                        attrs:
                                                                                          {
                                                                                            slot: "error",
                                                                                          },
                                                                                        slot: "error",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "i",
                                                                                          {
                                                                                            staticClass:
                                                                                              "el-icon-picture-outline",
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          )
                                                                        : [
                                                                            "upload-module",
                                                                          ].indexOf(
                                                                            cField.fieldType
                                                                          ) > -1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "block",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      display:
                                                                                        "inline-block",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  !_vm.getFieldConfigJson(
                                                                                    cField
                                                                                  )
                                                                                    .isOnlyBefore
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "打造前"
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm._l(
                                                                                    _vm.getModuleResource(
                                                                                      _vm.getShowFieldValue(
                                                                                        cField,
                                                                                        childContent
                                                                                      ),
                                                                                      "before"
                                                                                    ),
                                                                                    function (
                                                                                      cim,
                                                                                      cImIndx
                                                                                    ) {
                                                                                      return _c(
                                                                                        "el-image",
                                                                                        {
                                                                                          key: cImIndx,
                                                                                          staticStyle:
                                                                                            {
                                                                                              height:
                                                                                                "80px",
                                                                                              margin:
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              "preview-src-list":
                                                                                                _vm.getModuleResource(
                                                                                                  _vm.getShowFieldValue(
                                                                                                    cField,
                                                                                                    childContent
                                                                                                  ),
                                                                                                  "before"
                                                                                                ),
                                                                                              src: cim,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "image-slot",
                                                                                              attrs:
                                                                                                {
                                                                                                  slot: "error",
                                                                                                },
                                                                                              slot: "error",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "i",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "el-icon-picture-outline",
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                2
                                                                              ),
                                                                              !_vm.getFieldConfigJson(
                                                                                cField
                                                                              )
                                                                                .isOnlyBefore
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          display:
                                                                                            "inline-block",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "打造后"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.getModuleResource(
                                                                                          _vm.getShowFieldValue(
                                                                                            cField,
                                                                                            childContent
                                                                                          ),
                                                                                          "after"
                                                                                        ),
                                                                                        function (
                                                                                          cim,
                                                                                          cImIndx
                                                                                        ) {
                                                                                          return _c(
                                                                                            "el-image",
                                                                                            {
                                                                                              key: cImIndx,
                                                                                              staticStyle:
                                                                                                {
                                                                                                  height:
                                                                                                    "80px",
                                                                                                  margin:
                                                                                                    "5px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  "preview-src-list":
                                                                                                    _vm.getModuleResource(
                                                                                                      _vm.getShowFieldValue(
                                                                                                        cField,
                                                                                                        childContent
                                                                                                      ),
                                                                                                      "after"
                                                                                                    ),
                                                                                                  src: cim,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "image-slot",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      slot: "error",
                                                                                                    },
                                                                                                  slot: "error",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "i",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "el-icon-picture-outline",
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : "data" ==
                                                                          cField.fieldType
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "el-table",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      data: childContent.quotas,
                                                                                      height:
                                                                                        "120px",
                                                                                      size: "mini",
                                                                                      "highlight-current-row":
                                                                                        "",
                                                                                      "header-cell-style":
                                                                                        {
                                                                                          background:
                                                                                            "rgb(235 235 235)",
                                                                                          color:
                                                                                            "black",
                                                                                          textAlign:
                                                                                            "center",
                                                                                          verticalAlign:
                                                                                            "middle",
                                                                                        },
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-table-column",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "指标名",
                                                                                          prop: "fieldName",
                                                                                          width:
                                                                                            "250",
                                                                                          align:
                                                                                            "center",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "el-table-column",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "达成",
                                                                                          prop: "quotaValue",
                                                                                          width:
                                                                                            "100",
                                                                                          align:
                                                                                            "center",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "default",
                                                                                              fn: function ({
                                                                                                row,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          (row.quotaValue
                                                                                                            ? row.quotaValue
                                                                                                            : "") +
                                                                                                            (row.unit &&
                                                                                                            row.quotaValue
                                                                                                              ? row.unit
                                                                                                              : "")
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "el-table-column",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "目标",
                                                                                          prop: "target",
                                                                                          width:
                                                                                            "140",
                                                                                          align:
                                                                                            "center",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "default",
                                                                                              fn: function ({
                                                                                                row,
                                                                                              }) {
                                                                                                return [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          (row.target
                                                                                                            ? row.target
                                                                                                            : "") +
                                                                                                            (row.unit &&
                                                                                                            row.target
                                                                                                              ? row.unit
                                                                                                              : "")
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              },
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "inline-block",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getShowFieldValue(
                                                                                    cField,
                                                                                    childContent
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getShowFieldValue(field, item)
                                            )
                                          ),
                                        ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" " + _vm._s(item.shopInfo.shopName) + " "),
                          _c(
                            "span",
                            {
                              style:
                                item.status === "-1"
                                  ? { color: "red" }
                                  : item.status === "0"
                                  ? { color: "#a0a09b" }
                                  : { color: "green" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  " (" +
                                    _vm.viewDetailDrawer.shopStatusMap[
                                      item.status
                                    ] +
                                    ")"
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.shopInfo.dcCode +
                                      "/" +
                                      item.shopInfo.shopCode
                                  )
                              ),
                            ]),
                            _c("div", [
                              _c("span", [_vm._v("是否重点终端：")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.shopInfo.ifOptimumShop === "1"
                                        ? "是"
                                        : "否"
                                    )
                                  ),
                                ]
                              ),
                              _c("span", [_vm._v("终端容量标签：")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(item.shopInfo.shopCapacity))]
                              ),
                              _c("span", [_vm._v("包干负责人：")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.shopInfo.userName
                                        ? item.shopInfo.userName +
                                            "-" +
                                            item.shopInfo.nickName
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              _c("span", [_vm._v("提交人：")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(item.createUser))]
                              ),
                              _c("span", [_vm._v("提交时间：")]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(item.createTime))]),
                            ]),
                          ]
                        ),
                        _vm._l(item.fields, function (cField) {
                          return _c("div", { key: cField.id }, [
                            ["text-shop-info", "text-title"].indexOf(
                              cField.fieldType
                            ) < 0
                              ? _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getShowFieldLabel(
                                                cField,
                                                item
                                              )
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getShowFieldDesc(cField)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    ["upload"].indexOf(cField.fieldType) > -1
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            _vm._l(
                                              _vm.getModuleResource(
                                                _vm.getShowFieldValue(
                                                  cField,
                                                  item
                                                ),
                                                undefined
                                              ),
                                              function (cim, cImIndx) {
                                                return _c(
                                                  "el-image",
                                                  {
                                                    key: cImIndx,
                                                    staticStyle: {
                                                      height: "80px",
                                                      margin: "5px",
                                                    },
                                                    attrs: {
                                                      "preview-src-list":
                                                        _vm.getModuleResource(
                                                          _vm.getShowFieldValue(
                                                            cField,
                                                            item
                                                          ),
                                                          undefined
                                                        ),
                                                      src: cim,
                                                      lazy: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "image-slot",
                                                        attrs: {
                                                          slot: "error",
                                                        },
                                                        slot: "error",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-picture-outline",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ])
                                      : ["upload-module"].indexOf(
                                          cField.fieldType
                                        ) > -1
                                      ? _c(
                                          "div",
                                          { staticStyle: { display: "block" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                !_vm.getFieldConfigJson(cField)
                                                  .isOnlyBefore
                                                  ? _c("span", [
                                                      _vm._v("打造前"),
                                                    ])
                                                  : _vm._e(),
                                                _vm._l(
                                                  _vm.getModuleResource(
                                                    _vm.getShowFieldValue(
                                                      cField,
                                                      item
                                                    ),
                                                    "before"
                                                  ),
                                                  function (cim, cImIndx) {
                                                    return _c(
                                                      "el-image",
                                                      {
                                                        key: cImIndx,
                                                        staticStyle: {
                                                          height: "80px",
                                                          margin: "5px",
                                                        },
                                                        attrs: {
                                                          "preview-src-list":
                                                            _vm.getModuleResource(
                                                              _vm.getShowFieldValue(
                                                                cField,
                                                                item
                                                              ),
                                                              "before"
                                                            ),
                                                          src: cim,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "image-slot",
                                                            attrs: {
                                                              slot: "error",
                                                            },
                                                            slot: "error",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-picture-outline",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            !_vm.getFieldConfigJson(cField)
                                              .isOnlyBefore
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("打造后"),
                                                    ]),
                                                    _vm._l(
                                                      _vm.getModuleResource(
                                                        _vm.getShowFieldValue(
                                                          cField,
                                                          item
                                                        ),
                                                        "after"
                                                      ),
                                                      function (cim, cImIndx) {
                                                        return _c(
                                                          "el-image",
                                                          {
                                                            key: cImIndx,
                                                            staticStyle: {
                                                              height: "80px",
                                                              margin: "5px",
                                                            },
                                                            attrs: {
                                                              "preview-src-list":
                                                                _vm.getModuleResource(
                                                                  _vm.getShowFieldValue(
                                                                    cField,
                                                                    item
                                                                  ),
                                                                  "after"
                                                                ),
                                                              src: cim,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "image-slot",
                                                                attrs: {
                                                                  slot: "error",
                                                                },
                                                                slot: "error",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-picture-outline",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : "data" == cField.fieldType
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-table",
                                              {
                                                attrs: {
                                                  data: item.quotas,
                                                  height: "120px",
                                                  size: "mini",
                                                  "highlight-current-row": "",
                                                  "header-cell-style": {
                                                    background:
                                                      "rgb(235 235 235)",
                                                    color: "black",
                                                    textAlign: "center",
                                                    verticalAlign: "middle",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "指标名",
                                                    prop: "fieldName",
                                                    width: "250",
                                                    align: "center",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "达成",
                                                    prop: "quotaValue",
                                                    width: "100",
                                                    align: "center",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "目标",
                                                    prop: "target",
                                                    width: "140",
                                                    align: "center",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getShowFieldValue(
                                                  cField,
                                                  item
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
              ]),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c("label", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v("审核进度"),
                ]),
              ]),
              _c(
                "div",
                { staticStyle: { "margin-top": "5px" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: item.operations, size: "mini" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "操作人",
                          prop: "userName",
                          width: "100",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [_vm._v(_vm._s(row.createUser))]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "流程节点",
                          prop: "action",
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.getNodeName(row.action))),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", prop: "action", width: "120" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.statusActionMap[row.action])
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "时间",
                          prop: "createTime",
                          width: "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "备注", prop: "remark", width: "180" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("label", [_vm._v("审核说明")]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      clearable: "",
                      placeholder: "请输入",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.viewDetailDrawer.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.viewDetailDrawer, "remark", $$v)
                      },
                      expression: "viewDetailDrawer.remark",
                    },
                  }),
                ],
                1
              ),
              !_vm.viewDetailDrawer.disabled
                ? _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.viewDetailDrawer.visible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm.viewDetailDrawer.action == "audit" &&
                      _vm.computeButtonShow(
                        _vm.viewDetailDrawer.form.contents[0].status,
                        "pass"
                      )
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAudit(item, 0)
                                },
                              },
                            },
                            [_vm._v("通过")]
                          )
                        : _vm._e(),
                      _vm.viewDetailDrawer.action == "audit" &&
                      _vm.computeButtonShow(
                        _vm.viewDetailDrawer.form.contents[0].status,
                        "reject"
                      )
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAudit(item, 1)
                                },
                              },
                            },
                            [_vm._v("驳回")]
                          )
                        : _vm._e(),
                      _vm.viewDetailDrawer.form.contents[0].ifRefuseShow == "Y"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAudit(item, 3)
                                },
                              },
                            },
                            [_vm._v("拒绝")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.configDrawer.dialogTitle[_vm.configDrawer.type],
            visible: _vm.configDrawer.visible,
            size: "100%",
            direction: "rtl",
            "append-to-body": "",
            "close-on-click-modal": false,
            wrapperClosable: false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.configDrawer, "visible", $event)
            },
          },
        },
        [
          _c("Config", {
            ref: "config",
            attrs: {
              data: _vm.configDrawer.form,
              timestamp: _vm.configDrawer.timestamp,
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "right",
                margin: "20px",
                position: "absolute",
                right: "20px",
                bottom: "20px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: _vm.configDrawer.buttonLoading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.configDrawer.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.configDrawer.type !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.configDrawer.buttonLoading,
                        size: "mini",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }