import Vue from 'vue';
import App from './App.vue';
import { Message } from 'element-ui';

import 'normalize.css/normalize.css';
import ElementUI from 'element-ui';
ElementUI.Dialog.props.lockScroll.default = false;
import 'element-ui/lib/theme-chalk/index.css';
import './style/common/element-variables.scss'
import './style/common/index.scss';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n
import XEUtils from 'xe-utils'
import { VXETable, Table, Column, Footer, Keyboard, Edit, Tooltip } from 'vxe-table'
import './style/common/vxe-table-variables.scss'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
// dayjs
import dayjs from 'dayjs';
// lodash
import lodash from 'lodash';
// 四则运算
import NP from 'number-precision'

import Router from 'vue-router';
import router from './routes';

import directives from './directive'

Vue.use(ElementUI, { locale });
Vue.use(Router);
Vue.use(directives);

VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})
// 按需加载 vxe-table 组件
Vue.use(Column).use(Footer).use(Keyboard).use(Edit).use(Tooltip).use(Table)

Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$lodash = lodash;

NP.enableBoundaryChecking(false)
Vue.prototype.$np = NP;
Vue.prototype.$format = {
  /**
   * 列表的头部搜索时处理下拉框数据
   * @param { Array } formItemList 表单项
   * @param { Array } data 接口返回的lists数据
   * @param { Object } field 表单项的字段
   * {
   * key:   表单项model名 (当多个表单项的下拉数据一样时, 可传入数组, 即可同时设置多个选项值)
   * label: 接口返回的***name
   * value: 接口返回的***id
   * code:  接口返回的***code
   * }
   * @param { Object } type 表单项类型 默认普通select; 可选: select/selectTree
   * @returns [{ label: '',value: '', ...option }]
   */
   handleSetOptions(formItemList = [], data = [], field = null, type = 'select') {
    if (!formItemList?.length || !data?.length || !field) return []
    // 聚合相关逻辑赋值处理
    const assignOptions = (item, data, field) => {
      const optionTypes = {
        'select': () => {
          item.options = data.map((optionItem) => {
            return {
              ...optionItem,
              label: field?.code ? optionItem[field.code] + '-' + optionItem[field.label] : optionItem[field.label],
              value: optionItem[field.value],
            }
          })
        },
        'selectTree': () => {
          item.options.treeData = data
        }
      }
      optionTypes[type] && optionTypes[type]()
    }
    formItemList.forEach((item) => {
      if (Object.prototype.toString.call(field.key) === '[object Array]') {
        field.key.map((fieldKeyItem) => {
          if (item.model === fieldKeyItem) assignOptions(item, data, field)
        })
      } else {
        if (item.model === field.key) assignOptions(item, data, field)
      }
    })
  }
}


const app = new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
