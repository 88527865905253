
<template>
  <div class="mg-monthOrder-container">
        <!-- 头部制定计划 -->
        <div class="mg-monthOrder-container-head">
            <div class="head-box" @click="handleDrawPlan">
                <i class="iconfont icon-jihua"></i>
                <span class="head-text">下一月度计划制定</span>
            </div>
        </div>
        <div class="mg-monthOrder-container-body">
            <!-- 月单计划列表 -->
            <div class="monthOrder-plan-lists">
                <v-signboard :title="'现有月度排单计划列表'" />
                <el-table
                    stripe
                    border
                    v-loading="tableStatus.isLoading"
                    :data="lists"
                    size="mini"
                >
                    <template v-for="(item, index) in tableObj.arrangePlanHead">
                        <el-table-column v-if=" item.prop == 'plan_status' " v-bind="item" show-overflow-tooltip :key="index" align="center">
                            <template slot-scope="scope">
                                <span :style="{ color: scope.row.plan_status == 0 ? '' : '#06f418' }">{{ scope.row.plan_status == 0 ? '制定中' : '已生效' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-else  v-bind="item" show-overflow-tooltip :key="index" align="center" />
                    </template>
                    <el-table-column label="操作" align="center" width="160" fixed="right">
                        <template slot-scope="scope">
                                <div v-if="scope.row.plan_status == 0">
<!--                                    <el-link-->
<!--                                        class="action-link"-->
<!--                                        :style="{ color: btnObj.prodTableActionLink[0].link_color }"-->
<!--                                        :underline="false"-->
<!--                                        @click="handleDialog(btnObj.prodTableActionLink[0].link_fun, scope.row)"-->
<!--                                    >&nbsp;{{ btnObj.prodTableActionLink[0].link_txt }}</el-link>-->
                                    <el-link
                                        class="action-link"
                                        :style="{ color: btnObj.prodTableActionLink[1].link_color }"
                                        :underline="false"
                                        @click="handleDialog(btnObj.prodTableActionLink[1].link_fun, scope.row)"
                                    >&nbsp;{{ btnObj.prodTableActionLink[1].link_txt }}</el-link>
                                </div>
                                <div v-else>
<!--                                    <el-link-->
<!--                                        class="action-link"-->
<!--                                        :style="{ color: btnObj.prodTableActionLink[0].link_color }"-->
<!--                                        :underline="false"-->
<!--                                        @click="handleDialog(btnObj.prodTableActionLink[0].link_fun, scope.row)"-->
<!--                                    >&nbsp;{{ btnObj.prodTableActionLink[0].link_txt }}</el-link>-->
                                </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页器 -->
                <v-pagination
                    :total="total"
                    :pageSize="pageSize"
                    :currentPage="currentPage"
                    @pageChange="pagingChange"
                />
            </div>
        </div>
        <!-- 制定计划弹窗 -->
        <el-dialog
            class="select-plan"
            title="请选择月均销量依据"
            :visible.sync="isShowPlan"
            width="600px"
        >
            <ul class="select-plan-lists">
                <li class="select-plan-item" @click="updateMonthData(1)">上月月均销量</li>
                <li class="select-plan-item" @click="updateMonthData(3)">近3月月均销量</li>
                <li class="select-plan-item" @click="updateMonthData(6)">近半年月均销量</li>
            </ul>
        </el-dialog>
        <!-- 进度跟踪 -->
        <el-drawer
            :with-header="false"
            :visible.sync="progressTracking"
            :destroy-on-close="true"
            direction="rtl"
            class="process-drawer"
            size="1000px"
            ref="clearDrawer"
        >
            <div class="process-drawer-box">
                <el-tabs class="sonDict-box-pane" type="border-card" v-model="activeName">
                    <el-tab-pane label="进度跟踪" name="1">
                        <v-progress
                            :title="progressObj.title"
                            :salesPhaseProject="progressObj.salesPhaseProject"
                            :prodPhaseProject="progressObj.prodPhaseProject"
                        />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-drawer>
  </div>
</template>

<script>
import {paginationParams, tableLoading, urlParams} from '@/mixins';
import {arrangePlanHead, prodTableActionLink, statusTips} from '../config';
import {handleArrangePlanLists, handleMonthAvgType, handleProcessStatus} from '@/api/home';
import {handleResetProdFlow} from "../../../api/home";

export default {
    // 生产中心首页
    name: 'prodCenterHome',
    mixins: [ tableLoading, paginationParams, urlParams ],
    components: {
        vPagination: () => import("@/components/Element/Pagination"),
        vSignboard: () => import('@/components/Signboard'),
        vProgress: () => import('@/components/Progress/index')
    },
    data() {
        return {
            isShowPlan: false,
            showPlan_type: '',
            lists: [],
            tableObj: {
                arrangePlanHead
            },
            progressObj: {
                plan_month: '',
                title: '',
                salesPhaseProject: [],
                prodPhaseProject: []
            },
            statusTips,  // 月单定制中进度状态说明
            btnObj: {
                prodTableActionLink
            },
            monthAvgTypeText: {
                1: '上月月均销量',
                3: '近3月月均销量',
                6: '近半年月均销量'
            },
            progressTracking: false,
            activeName: '1',
            makePlan: {
                is_select_month_type: false,
                month: ''
            },
             resetStartParams: {
                is_reset_plan: '',
                month: ''
            }

        }
    },
    created() {
        this.handleUrlParams().then(res => {
            this.getLists();
        })
    },
    methods: {
        // 获取月度排单计划列表
        async getLists() {
            try {
                const params = {
                    page: this.currentPage,
                    limit: this.pageSize,
                    operator: this.urlParams.operator
                };
                const { code, data, msg } = await handleArrangePlanLists(params);
                if ( data ) {
                    const { total, pan_list } = data;
                    let newItems = [];
                    pan_list.map(item => {
                        let newItem = {
                            plan_month: item.plan_month || '--',
                            plan_status: item.plan_status || 0,
                            create_time: item.create_time || '--',
                            effect_time: item.effect_time || '--',
                            month_avg_type: item.month_avg_type || '--',
                            month_avg_type_text: this.monthAvgTypeText[item.month_avg_type] || '--',
                        };
                        newItems.push(newItem);
                    })
                    this.lists = newItems;
                    this.total = total;
                }
            } catch (err) {
                this.lists = [];
                this.total = 0;
                console.log(err, 'err-----获取月度排单计划接口有误')
            }
        },
        // 头部制定计划
        handleDrawPlan() {
            const makePlan = this.makePlan;
            if( makePlan.is_select_month_type ) {
                this.progressTracking = true;
                this.handleLookProcess();
            }
            if( !makePlan.is_select_month_type ) {
                this.showPlan_type = 'drawPlan';
                this.isShowPlan = true;
            }
        },
        // 列表操作
        handleDialog(fun, row) {
            const funcObj = {
                'progressTracking': () => {         // 进度跟踪
                    this.progressTracking = true;
                    this.handleLookProcess();
                },
                'resetStart': () => {               // 重置开始
                    this.isShowPlan = true;
                    this.showPlan_type = 'reset';
                    this.resetStartParams = {
                        is_reset_plan: true,
                        month: row.plan_month
                    };
                },
                'resetProdFlow': () => {              // 查看明细
                    this.resetProdFlow();
                },
                'executeCondition': () => {         // 执行情况
                    console.log('执行情况')
                }
            };
            funcObj[fun]();
        },
        // 分页监听函数
        pagingChange(e) {
            if (!!e.current_page) this.currentPage = e.current_page;
            if (!!e.current_page) this.pageSize = e.per_page;
            this.getLists();
        },
        // 查询进度状态
        async handleLookProcess() {
            const params = {
                operator: this.urlParams.operator,
                month: ''
            };
            const { code, data, success } = await handleProcessStatus(params);
            if ( data ) {
                const progressObj = this.progressObj;
                const makePlan = this.makePlan;
                const { is_select_month_avg_type, process_status, prod_phase_list, sale_phase_list } = data;
                const { plan_month, sale_phase_id, prod_phase_id, sale_phase_status, prod_phase_status } = process_status;
                makePlan.is_select_month_type = is_select_month_avg_type;
                makePlan.month = plan_month;
                progressObj.plan_month = this.$dayjs(process_status.plan_month).format('YYYY年MM月');
                progressObj.title = `${progressObj.plan_month}月单正在定制ing...`;
                // 销售中心项目
                if ( sale_phase_list.length > 0 ) {
                    this.processingdData(sale_phase_list, sale_phase_id, sale_phase_status).then(res => {
                        progressObj.salesPhaseProject = res;
                   })
                };
                // 生产中心项目
                if ( prod_phase_list.length > 0 ) {
                   this.processingdData(prod_phase_list, prod_phase_id, prod_phase_status).then(res => {
                        progressObj.prodPhaseProject = res;
                   })
                };
            }
        },
        // 处理数据
         processingdData(lists, selectId, status) {
            const iconObj = {
                0: 'icon-notImplemented',   // 未开始
                1: 'icon-underway',         // 进行中
                3: 'icon-fail',             // 已驳回
                4: 'icon-success'           // 已完成
            };
            const classNameObj = {
                0: 'notImplemented-item',   // 未开始
                1: 'underway-item',         // 进行中
                3: 'fail-item',             // 已驳回
                4: 'success-item'           // 已完成
            };
            return new Promise( (resolve, reject) => {
                    let newItems = [];
                    lists.map((item, index) => {
                        let newItem = {};
                        newItem = {
                            label: item.dict_label || '--',
                            icon:  'icon-notImplemented',
                            className: 'notImplemented-item',
                            isSelect: false
                        };
                        if ( Number(item.dict_value) ==  selectId) {
                            newItem = {
                                label: item.dict_label || '--',
                                icon: iconObj[status],
                                className: classNameObj[status],
                                isSelect: true
                            }
                        };
                        if ( Number(item.dict_value) < selectId) {
                            newItem = {
                                label: item.dict_label || '--',
                                icon: iconObj[4],
                                className: classNameObj[4],
                                isSelect: false
                            }
                        };
                        newItems.push(newItem);
                    });
                    resolve(newItems);
            });
         },
         // 下一月度计划制定
         async updateMonthData(monthType) {
             const params = {
                 month_avg_type: monthType,
                 operator: this.urlParams.operator,
                 ...this.resetStartParams
             };
             const funcObj = {
                 'drawPlan': async () => {
                    const { success } = await handleMonthAvgType(params);
                    if ( !success ) return;
                    this.isShowPlan = false;
                    this.$router.push({
                        path: '/salesCenter/filialeCustomerOrderCheck',
                        query: { month: this.makePlan.month, operator: this.urlParams.operator }
                    });
                 },
                'reset': () => {
                     this.handleResetSchedulingPlan(params);
                 }
             };
             funcObj[this.showPlan_type]();
            //  this.getLists();
         },
        // 这里重置就是更新 -- 更新排单计划
        async handleResetSchedulingPlan() {
            try {
                const paramsObj = {
                    ...params
                };
                const { success, msg } = await handleMonthAvgType(paramsObj);
                if ( !success ) return;
                this.$message.success('更新排单计划已成功');
                this.isShowPlan = false;
                this.$router.push({
                    path: '/salesCenter/filialeCustomerOrderCheck',
                    query: { month: this.makePlan.month, operator: this.urlParams.operator }
                });
                this.getLists();
            } catch (err) {
                console.log(err, 'err------更新排产计划接口有误')
            }
        },
        // 生产流程重置
        async resetProdFlow() {
          const params = {
            operator: this.urlParams.operator,
            plan_month: ''
          };

          this.tableStatus.isLoading = true;
          const {code, msg} = await handleResetProdFlow(params);
          this.tableStatus.isLoading = false;
          if (code == '0000') {
            this.getLists();
            return this.$message.success('流程重置成功');
          }
          if (code != '0000') this.getLists();
        }
    }
}

</script>
<style lang="scss" scoped src='@/style/home/index.scss'></style>
