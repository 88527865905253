var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleInit },
                },
                [_vm._v("初始化")]
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v("初始化：点击设置目标周转，开始计算补货数据"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "searchRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "补货日期" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.queryForm.dateKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "dateKey", $$v)
                        },
                        expression: "queryForm.dateKey",
                      },
                    },
                    _vm._l(_vm.dateOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库存状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.kcStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "kcStatus", $$v)
                        },
                        expression: "queryForm.kcStatus",
                      },
                    },
                    _vm._l(_vm.options.ksOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赛道" } },
                [
                  this.options.category.length > 0
                    ? _c("DictSelect", {
                        ref: "race",
                        attrs: {
                          type: "race",
                          value: _vm.queryForm.raceName,
                          linkageRef: "main",
                          dictFun: _vm.dictFun,
                          singleProps: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "raceName", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "大类" } },
                [
                  _c("DictSelect", {
                    ref: "main",
                    attrs: {
                      type: "main",
                      value: _vm.queryForm.mainCategoryName,
                      dictFun: _vm.dictFun,
                      linkageRef: "mid",
                      parentLinkageRef: "",
                      singleProps: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "mainCategoryName",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) => callback(_vm.queryForm),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "中类" } },
                [
                  _c("DictSelect", {
                    ref: "mid",
                    attrs: {
                      type: "mid",
                      value: _vm.queryForm.midCategoryName,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      singleProps: "",
                      linkageRef: "sub",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "midCategoryName",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) => callback(_vm.queryForm),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小类" } },
                [
                  _c("DictSelect", {
                    ref: "sub",
                    attrs: {
                      type: "sub",
                      value: _vm.queryForm.subCategoryName,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      singleProps: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "subCategoryName",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) => callback(_vm.queryForm),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "JD编码" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.goodsSku,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "goodsSku", $$v)
                      },
                      expression: "queryForm.goodsSku",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "科技SKU" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.ecSkuCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "ecSkuCode", $$v)
                      },
                      expression: "queryForm.ecSkuCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "DRP编码" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.spuCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "spuCode", $$v)
                      },
                      expression: "queryForm.spuCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "补货提示" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.needBh,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "needBh", $$v)
                        },
                        expression: "queryForm.needBh",
                      },
                    },
                    _vm._l(_vm.options.notices, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "需求为0的SKU" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.needZero,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "needZero", $$v)
                        },
                        expression: "queryForm.needZero",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "为零", value: true },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单仓发全国" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.singleToWhole,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "singleToWhole", $$v)
                        },
                        expression: "queryForm.singleToWhole",
                      },
                    },
                    _vm._l(_vm.options.singleToWholeOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", [
                _c(
                  "div",
                  { staticStyle: { float: "left", "margin-bottom": "5px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleFilter },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleFilterColumns },
                      },
                      [_vm._v("列编辑")]
                    ),
                    _vm.currentDataDate === _vm.queryForm.dateKey
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleBatchUpdate },
                          },
                          [_vm._v("批量修改补货单数量")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.exportData },
                      },
                      [_vm._v("导出")]
                    ),
                    _vm.currentDataDate === _vm.queryForm.dateKey
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.produceSupply },
                          },
                          [_vm._v("生成配货单")]
                        )
                      : _vm._e(),
                    _vm.currentDataDate === _vm.queryForm.dateKey
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleSyncWholeALl },
                          },
                          [_vm._v("一键单仓发全国")]
                        )
                      : _vm._e(),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          href: "https://bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/reports/d275f9dcf9464d1cb67a70411d1012fd",
                          target: "_blank",
                          type: "primary",
                        },
                      },
                      [_vm._v("补货填报跳转")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "600",
                          trigger: "click",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "calc(100vh - 150px)",
                              "overflow-y": "scroll",
                            },
                          },
                          [
                            _c("label", [_vm._v("操作说明：")]),
                            _c("br"),
                            _vm._v(
                              " 操作前，请确保【京东DRP商品编码对应关系】维护完整；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 观察筛选框下方 采集时间，需要当天数据采集完成后才可以进行补货操作；约定库存更新时间为每天11点；"
                            ),
                            _c("br"),
                            _vm._v(" 1.初始化操作步骤："),
                            _c("br"),
                            _vm._v(
                              " 1）点击【补货填报跳转】，录入当天需要补货的京东码信息（按需补货）"
                            ),
                            _c("br"),
                            _vm._v(
                              " 京东补货依照为补货清单中的京东码进行补货；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 2）如当天有自动单，需要录入【京东仓自动单录入】，最后计算出来的补货数量，会扣减自动单的采购数量；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 3）点击上方【初始化】按钮，并填写目标周转、采购原因、日均计算系数；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 目标周转：京东八仓按多少天的销售天数来进行补货"
                            ),
                            _c("br"),
                            _vm._v(
                              " 日均计算系数：默认可按照 1,1,1填写，用于计算全国日均，可根据需求填写系数的值"
                            ),
                            _c("br"),
                            _vm._v(
                              " 全国日均=（近7天出库/7 * A+近14天出库/14 * B+近28天出库/28 *C）/ 3"
                            ),
                            _c("br"),
                            _vm._v(" 4）点击【确定】 按钮"),
                            _c("br"),
                            _vm._v(" 5）点击【查询】，查看计算后的补货数值"),
                            _c("br"),
                            _vm._v(" 2.补货功能说明："),
                            _c("br"),
                            _vm._v(
                              " 1）用户可以点击【列编辑】来选择需要展示的数据列；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 2）用户可以在页面上调整 XX实际 的数值来调整实际补货的数量"
                            ),
                            _c("br"),
                            _vm._v(
                              " 3）可以参考 【补货提示】，会告知以SKU维度的各仓库存是否够进行补货操作，如果不够，会提示哪个科技仓库缺货，可以哪个单仓发全国"
                            ),
                            _c("br"),
                            _vm._v(
                              " 4）【单仓发全国】，当默认仓库缺货时，满足科技单仓发京东八仓的情况，会出现下拉选择；用户可以选择从哪个仓发全国；"
                            ),
                            _c("br"),
                            _vm._v(
                              " 当选择否时，按照实际可发库存发货；当选择XX发全国时，按照需求量发货；"
                            ),
                            _c("br"),
                            _vm._v(" 5）【一键单仓发全国】 功能："),
                            _c("br"),
                            _vm._v(
                              " 影响全局 单仓发全国 字段， 选择 XX仓发全国，并调整京东八仓实际补货量；"
                            ),
                            _c("br"),
                            _vm._v(" 6）【导出】，导出全字段数据"),
                            _c("br"),
                            _vm._v(
                              " 7）【生成配货单】，生成京东VC后台的采购单格式文件，可以直接上传到VC后台进行补货；"
                            ),
                            _c("br"),
                            _c("br"),
                            _c("label", [_vm._v("补货逻辑说明：")]),
                            _c("br"),
                            _vm._v(" 1.各仓需求量计算逻辑："),
                            _c("br"),
                            _vm._v(" ①计算铺底库存"),
                            _c("br"),
                            _vm._v(
                              " 如果某商品同时满足七日销量为0且可用库存为0，则仓库为北上广的时候补10个，为其他仓库的时候补5个"
                            ),
                            _c("br"),
                            _vm._v(" ②计算是否需要补全负卖单"),
                            _c("br"),
                            _vm._v(" 如果可用库存为负数，则补可用库存的绝对值"),
                            _c("br"),
                            _vm._v(" ③计算各仓日均"),
                            _c("br"),
                            _vm._v(
                              " （近7天出库/7 * A+近14天出库/14 * B+近28天出库/28 *C）/ 3"
                            ),
                            _c("br"),
                            _vm._v(
                              " 若单仓日均为0，则取往前推30天商智数据计算同期值。"
                            ),
                            _c("br"),
                            _vm._v(
                              " 若该仓同期计算后都为0北上广都为0，则这三仓日均销售0；成都武汉沈阳西安德州都为日均销售0；--代表该品是新品"
                            ),
                            _c("br"),
                            _vm._v(
                              " 若该仓同期计算后仍为0（非全部），则北上广取有值的最大值，例如北京0，上海4，广州6，则北京6；成都武汉沈阳西安德州，任意为0，取其他仓最大值"
                            ),
                            _c("br"),
                            _vm._v(" ④计算待补货天数"),
                            _c("br"),
                            _vm._v(
                              " 待补货天数=（目标周转天数- 各仓周转） 若负数则0"
                            ),
                            _c("br"),
                            _vm._v(" ⑤计算各仓需求量 （ 若负数则0）"),
                            _c("br"),
                            _vm._v(
                              " 待补货天数*日均销售-采购未到货-京东自动单+负卖补单+铺底库存"
                            ),
                            _c("br"),
                            _vm._v(" 2.科技仓库对应京东八仓关系"),
                            _c("br"),
                            _vm._v(" 集库需求总和：上海+西安+武汉"),
                            _c("br"),
                            _vm._v(" 华北需求总和：成都+北京+德州+沈阳"),
                            _c("br"),
                            _vm._v(" 华南需求总和：广州"),
                            _c("br"),
                            _vm._v(" 其中，集库、华北可发全国，华南不可发全国"),
                            _c("br"),
                          ]
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              slot: "reference",
                              icon: "el-icon-info",
                              type: "info",
                            },
                            slot: "reference",
                          },
                          [_vm._v("使用说明")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    float: "right",
                    "margin-bottom": "15px",
                    "font-size": "15px",
                  },
                },
                [
                  _vm._v(
                    "京东商智数据采集时间: " + _vm._s(_vm.updateDate.timeStamp)
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "15px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.pageData.rows,
                height: "calc(100vh - 220px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40" },
              }),
              _c("el-table-column", { attrs: { type: "index", width: "40" } }),
              _vm._l(_vm.dynamicColumns, function (item) {
                return [
                  item.show || _vm.checkedColumns.indexOf(item.filterLabel) > -1
                    ? _c("el-table-column", {
                        key: item.prop,
                        attrs: {
                          prop: item.prop,
                          label: item.label,
                          width: item.width ? item.width : "100px",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              }),
              _vm._l(_vm.fixedColumns, function (item) {
                return [
                  _c("el-table-column", {
                    key: item.prop,
                    attrs: { prop: item.prop, label: item.label, width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: "请输入内容",
                                  size: "mini",
                                  min: 0,
                                  precision: 0,
                                  disabled: _vm.lastDataDate !== row.dateKey,
                                },
                                on: {
                                  change: (cV, oV) =>
                                    _vm.handleChangeInput(
                                      cV,
                                      oV,
                                      row,
                                      item.prop
                                    ),
                                },
                                model: {
                                  value: row[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(row, item.prop, $$v)
                                  },
                                  expression: "row[item.prop]",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              }),
              _c("el-table-column", {
                attrs: { label: "下单合计", prop: "totalReal", width: "80px" },
              }),
              _c("el-table-column", {
                attrs: { label: "补货提示", prop: "needBh", width: "100px" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单仓发全国",
                  prop: "singleToWhole",
                  width: "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm.lastDataDate !== row.dateKey,
                            },
                            on: {
                              change: (val) => _vm.handleChangeSend(val, row),
                            },
                            model: {
                              value: row.singleToWhole,
                              callback: function ($$v) {
                                _vm.$set(row, "singleToWhole", $$v)
                              },
                              expression: "row.singleToWhole",
                            },
                          },
                          _vm._l(row.sendWholeOptions, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { value: item, label: item },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "补货备注", prop: "remark", width: "180px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请输入内容",
                            size: "mini",
                            type: "textarea",
                            disabled: _vm.lastDataDate !== row.dateKey,
                          },
                          on: {
                            change: (cV, oV) =>
                              _vm.handleChangeInput(cV, oV, row, "remark"),
                          },
                          model: {
                            value: row.remark,
                            callback: function ($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购场景",
                  prop: "purchaseScene",
                  width: "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.lastDataDate !== row.dateKey,
                              size: "mini",
                            },
                            on: {
                              change: (cV, oV) =>
                                _vm.handleChangeInput(cV, oV, row, "remark"),
                            },
                            model: {
                              value: row.purchaseScene,
                              callback: function ($$v) {
                                _vm.$set(row, "purchaseScene", $$v)
                              },
                              expression: "row.purchaseScene",
                            },
                          },
                          _vm._l(_vm.options.purchase, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { value: item, label: item },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "预计消化时间",
                  prop: "estimatedDigestionTime",
                  width: "140px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-date-picker", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            type: "date",
                            size: "mini",
                            placeholder: "选择日期",
                            "value-format": "yyyy-MM-dd",
                            disabled: _vm.lastDataDate !== row.dateKey,
                          },
                          on: {
                            change: (cV, oV) =>
                              _vm.handleChangeInput(
                                cV,
                                oV,
                                row,
                                "estimatedDigestionTime"
                              ),
                          },
                          model: {
                            value: row.estimatedDigestionTime,
                            callback: function ($$v) {
                              _vm.$set(row, "estimatedDigestionTime", $$v)
                            },
                            expression: "row.estimatedDigestionTime",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              pageSizes: [10, 20, 30, 50, 100, 200, 500],
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "列编辑",
            visible: _vm.dialog.filterColumnVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "filterColumnVisible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "25%",
                position: "relative",
                float: "right",
                right: "60%",
                top: "-50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(false)
                    },
                  },
                },
                [_vm._v("清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(true)
                    },
                  },
                },
                [_vm._v("全选")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", color: "gray" } },
            [
              _c("span", [
                _vm._v("说明：页面表头指标 展示顺序同指标添加先后顺序"),
              ]),
            ]
          ),
          _vm._l(_vm.checkedCopy.filterColumns, function (item, key, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "margin-bottom": "15px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                ),
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: item.isIndeterminate },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckAllChange($event, key, item.label)
                      },
                    },
                    model: {
                      value: item.checkAll,
                      callback: function ($$v) {
                        _vm.$set(item, "checkAll", $$v)
                      },
                      expression: "item.checkAll",
                    },
                  },
                  [_vm._v(" 全选 ")]
                ),
                _c("div", { staticStyle: { margin: "10px 0" } }),
                _c(
                  "el-checkbox-group",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckedChange($event, key, item.label)
                      },
                    },
                    model: {
                      value: item.checked,
                      callback: function ($$v) {
                        _vm.$set(item, "checked", $$v)
                      },
                      expression: "item.checked",
                    },
                  },
                  _vm._l(item.list, function (option) {
                    return _c(
                      "el-checkbox",
                      {
                        key: option,
                        style:
                          item.label === "库存数据"
                            ? "width: 40% "
                            : "width: 20% ",
                        attrs: { label: option },
                      },
                      [_vm._v(_vm._s(option))]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.filterColumnVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmChecked },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量修改补货单数量",
            visible: _vm.updateDialog.updateVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.updateDialog, "updateVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "stockForm",
              staticClass: "common-form",
              attrs: { model: _vm.updateDialog.form },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _vm._l(_vm.fixedColumns, function (item) {
                    return _c(
                      "el-col",
                      { key: item.prop, attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: item.label, prop: item.prop } },
                          [
                            _c("el-input-number", {
                              attrs: {
                                placeholder: "请输入",
                                min: 0,
                                precision: 0,
                                controls: false,
                                size: "small",
                              },
                              model: {
                                value: _vm.updateDialog.form[item.prop],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateDialog.form,
                                    item.prop,
                                    $$v
                                  )
                                },
                                expression: "updateDialog.form[item.prop]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "补货备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", size: "small" },
                            model: {
                              value: _vm.updateDialog.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.updateDialog.form, "remark", $$v)
                              },
                              expression: "updateDialog.form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "采购场景", prop: "purchaseScene" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "mini" },
                              on: {
                                change: (cV, oV) =>
                                  _vm.handleChangeInput(
                                    cV,
                                    oV,
                                    _vm.row,
                                    "remark"
                                  ),
                              },
                              model: {
                                value: _vm.updateDialog.form.purchaseScene,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.updateDialog.form,
                                    "purchaseScene",
                                    $$v
                                  )
                                },
                                expression: "updateDialog.form.purchaseScene",
                              },
                            },
                            _vm._l(_vm.options.purchase, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { value: item, label: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预计消化时间",
                            prop: "estimatedDigestionTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              size: "mini",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value:
                                _vm.updateDialog.form.estimatedDigestionTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.updateDialog.form,
                                  "estimatedDigestionTime",
                                  $$v
                                )
                              },
                              expression:
                                "updateDialog.form.estimatedDigestionTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.updateDialog.updateVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleBatchSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "初始化",
            visible: _vm.initDialog.visible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.initDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "initForm",
              staticClass: "common-form",
              attrs: {
                model: _vm.initDialog.form,
                rules: _vm.initDialog.rules,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标周转", prop: "turnover" } },
                        [
                          _c(
                            "el-input-number",
                            {
                              attrs: {
                                placeholder: "请输入",
                                precision: 0,
                                size: "small",
                              },
                              model: {
                                value: _vm.initDialog.form.turnover,
                                callback: function ($$v) {
                                  _vm.$set(_vm.initDialog.form, "turnover", $$v)
                                },
                                expression: "initDialog.form.turnover",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("天"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "采购原因", prop: "purchaseReason" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", size: "small" },
                            model: {
                              value: _vm.initDialog.form.purchaseReason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.initDialog.form,
                                  "purchaseReason",
                                  $$v
                                )
                              },
                              expression: "initDialog.form.purchaseReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [_vm._v("全部日均计算系数")]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仅7天出库占比（A）", prop: "r7d" } },
                        [
                          _c(
                            "el-input-number",
                            {
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.initDialog.form.r7d,
                                callback: function ($$v) {
                                  _vm.$set(_vm.initDialog.form, "r7d", $$v)
                                },
                                expression: "initDialog.form.r7d",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("倍"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "仅14天出库占比（B）", prop: "r14d" },
                        },
                        [
                          _c(
                            "el-input-number",
                            {
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.initDialog.form.r14d,
                                callback: function ($$v) {
                                  _vm.$set(_vm.initDialog.form, "r14d", $$v)
                                },
                                expression: "initDialog.form.r14d",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("倍"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "仅28天出库占比（C）", prop: "r28d" },
                        },
                        [
                          _c(
                            "el-input-number",
                            {
                              attrs: { placeholder: "请输入", size: "small" },
                              model: {
                                value: _vm.initDialog.form.r28d,
                                callback: function ($$v) {
                                  _vm.$set(_vm.initDialog.form, "r28d", $$v)
                                },
                                expression: "initDialog.form.r28d",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("倍"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "即全国日均=（近7天出库/7 * A+近14天出库/14 * B+近28天出库/28 *C）/ 3"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.initDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitInit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }