


const otherQuantityHead = [
    { label: '年月', prop: 'import_month', minWidth: '100px' },
    { label: '订单类型', prop: 'order_type_text', minWidth: '100px' },
    // { label: '是否预留', prop: 'is_keep_text', minWidth: '100px' },
    { label: '订单性质', prop: 'race_name', minWidth: '100px' },
    { label: '原始料号', prop: 'material_code', minWidth: '200px' },
    { label: '换算料号', prop: 'trans_material_code', minWidth: '200px' },
    { label: '数量(支)', prop: 'material_count', minWidth: '120px' },
    { label: '单号', prop: 'order_no', minWidth: '80px' },
    { label: '接单日期', prop: 'accept_order_date', minWidth: '80px' },
    { label: '成品交期', prop: 'deliver_date', minWidth: '80px' },
    { label: '系列', prop: 'series', minWidth: '80px' },
];

const otherQuantityTemplateSampleHead = [
    { label: '年月', prop: 'import_month', minWidth: '80px' },
    { label: '订单类型', prop: 'order_type', minWidth: '80px' },
    { label: '订单性质', prop: 'race_name', minWidth: '80px' },
    { label: '原始料号', prop: 'material_code', minWidth: '80px' },
    { label: '换算料号', prop: 'trans_material_code', minWidth: '80px' },
    { label: '料号数量', prop: 'material_count', minWidth: '80px' },
    { label: '单号', prop: 'order_no', minWidth: '80px' },
    { label: '接单日期', prop: 'accept_order_date', minWidth: '80px' },
    { label: '成品交期', prop: 'deliver_date', minWidth: '80px' },
    { label: '系列', prop: 'series', minWidth: '80px' },
];

export {
    otherQuantityHead,                          // 其他量导入 -- 表头
    otherQuantityTemplateSampleHead,            // 其他量导入 -- 表头
}
