

const arrangePlanHead = [
    { label: '年月', prop: 'plan_month', minWidth: '50px' },
    { label: '状态', prop: 'plan_status', minWidth: '50px' },
    { label: '开始制定日期', prop: 'create_time', minWidth: '100px' },
    { label: '生效日期', prop: 'effect_time', minWidth: '80px' },
    { label: '选择策略', prop: 'month_avg_type_text', minWidth: '80px' }
];
const statusTips = [
    { text: '已完成', iconName: 'icon-success', className: 'success-tips' },
    { text: '进行中', iconName: 'icon-underway', className: 'underway-tips' },
    { text: '驳回', iconName: 'icon-fail', className: 'fail-tips' },
    { text: '未开始', iconName: 'icon-notImplemented', className: 'notImplemented-tips' },
];
const tableActionLink = [
    { link_color: '#27b55a', link_txt: '进度跟踪', link_fun: 'progressTracking', isShow: false },
    { link_color: '#409EFF', link_txt: '重置开始', link_fun: 'resetStart', isShow: false },
    { link_color: '#409eff', link_txt: '查看明细', link_fun: 'detailsView', isShow: false },
    { link_color: '#f1992d', link_txt: '执行情况', link_fun: 'executeCondition', isShow: true }
];
const salesPhaseProject = [
    { label: '分公司客订检核', value: '1', icon: 'icon-success', className: 'success-item' },
    { label: '库存系数设定', value: '2', icon: 'icon-underway', className: 'underway-item' },
    { label: '起订量调整', value: '3', icon: 'icon-fail', className: 'fail-item' },
    { label: '请购单确认', value: '4', icon: 'icon-notImplemented', className: 'notImplemented-item' },
    { label: '方案调整', value: '5', icon: 'icon-notImplemented', className: 'notImplemented-item' },
    { label: '排产确认', value: '6', icon: 'icon-notImplemented', className: 'notImplemented-item' }
];
export {
    arrangePlanHead,        // 排单计划列表 -- 表头
    tableActionLink,        // 排单计划列表 -- 操作
    statusTips,             // 月单定制中进度状态说明
    salesPhaseProject,      // 月单定制中进度各个阶段
}

