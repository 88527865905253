<template>
  <el-row :gutter="10" class="">
    <el-col :span="16" :offset="4">
      <el-form :model="activity" ref="addActivity_form" size="medium" :inline="false" label-width="140px" :label-position="labelPosition">
        <el-form-item label="活动名称" prop="activityName" size="medium" required>
          <el-input v-model="activity.activityName"></el-input>
        </el-form-item>
        <el-form-item label="赛道" prop="brand" size="medium" required>
          <el-input v-model="activity.brand" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="活动日期" prop="activityTime" required>
          <el-date-picker
            v-model="activity.activityTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="财务核算口径" prop="financeAccountOrigin" size="medium" required>
          <el-select v-model="activity.financeAccountOrigin" placeholder="请选择">
            <el-option
              v-for="item in financeAccountOriginOptions"
              :key="item.guid"
              :label="item.name"
              :value="item.guid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="导入文件" prop="activityName" size="medium" required>
          <el-input v-model="activity.activityName"></el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      // 标签位置
      labelPosition: "left",
      activity: {
        activityName: "",
        brand: "",
        activityTime: "",
        financeAccountOrigin: ""
      },
      financeAccountOriginOptions: [],
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
