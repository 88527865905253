<template>
  <div  v-loading="homeLoading" class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter"  @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
              ref="org"
              type="org"
              :value.sync="queryForm.orgCode"
              linkageRef="dc,area-tag"
              :dictFun="dictFun"
              fullLabel
              default-first-option
              :default-index="0"
              :clearable="false"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
              ref="dc"
              type="dc"
              :value.sync="queryForm.dcCode"
              linkageRef="area-tag"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
              ref="area-tag"
              type="area-tag"
              :value.sync="queryForm.areaTag"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="() => {
            importDialog.visible = true;
            submitDisabled = false;
          }">导入分组店铺</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出分组店铺</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="() => {
            importDialog.visible = true;
            submitDisabled = false;
          }">导入分组店铺</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出分组店铺</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
      v-loading="loading"
      size="small"
      class="customer-table"
      border
      highlight-current-row
      @sort-change="handleSortChange"
    >
      <el-table-column label="分公司编码-名称" prop="orgCode" >
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心编码-名称" prop="dcCode">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域分组" prop="areaTag"  />
      <el-table-column label="终端数" prop="total" />
      <el-table-column label="操作" width="220" align="center">
        <template #default="{ row }">
          <el-row>
            <el-col :span="6">
              <el-button size="mini" type="text" style="border: 0px; " @click="handleViewShopDetail(row)">店铺明细</el-button>
            </el-col>
            <el-col :span="7">
              <el-button size="mini" type="text" style="border: 0px; " @click="handleEdit(row)">编辑分组名</el-button>
            </el-col>
            <el-col :span="5">
              <el-button size="mini" type="text" style="border: 0px; width: 20%" @click="handleViewTarget(row)">目标</el-button>
            </el-col>
            <el-col :span="5">
              <el-popconfirm
                confirm-button-text='确认'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                :title="'确认删除区域分组“'+ row.areaTag+'”? 删除后该区域分组下的店铺会归入“其它“分组'"
                @confirm="handleDel(row)"
              >
                <el-button slot="reference" size="mini" type="text" style="border: 0px; ">删除</el-button>
              </el-popconfirm>
            </el-col>
          </el-row>

        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="" :visible.sync="editDialog.visible" width="200px" append-to-body :close-on-click-modal="false">
      <el-form ref="form" :model="editDialog.form" :inline="true" :rules="editDialog.rules" label-width="130px">
        <el-form-item label="修改区域分组名称" prop="areaTag" size="mini">
          <el-input
            v-model="editDialog.form.areaTag"
            clearable
            placeholder="请输入区域分组"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="editDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitEditForm">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="区域分组导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="goodsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog  title="分组目标" :visible.sync="targetDialog.visible" append-to-body width="400px">
      <el-select v-model="targetDialog.year" size="mini" @change="() => handleViewTarget()">
        <el-option v-for="item in yearOptions" :key="item" :label="item" :value="item" />
      </el-select>
      <el-table
        :data="targetDialog.list"
        height="400px"
        v-loading="targetDialog.loading"
        size="mini"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="时间" prop="mth" align="center" />
        <el-table-column label="目标" prop="amountTarget" align="center">
          <template #default="{ row }">
            <span>进货金额   {{ row.amountTarget ? row.amountTarget: 0 }}   元</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-drawer title="店铺明细" :visible.sync="shopDrawer.visible" size="75%" direction="rtl" append-to-body :modal-append-to-body="false">
      <ShopDetail ref="shopDetail" :param="shopDrawer.currentRow" :timestamp="shopDrawer.timestamp" />
    </el-drawer>

    <el-drawer title="区域目标设置" :visible.sync="targetDrawer.visible" size="75%" direction="rtl" append-to-body :modal-append-to-body="false">
      <Target ref="target" :param="targetDrawer.currentRow" :timestamp="targetDrawer.timestamp" />
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect, shopDict, pageAreaTags, changeAreaTag, removeAreaTag, importShopAreaTag, exportShopAreaTag, getAreaTargetDetail } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
import DictSelect from "@/components/DictSelect";
import ShopDetail from "./ShopDetail";
import SearchFilter from "@/components/SearchFilter/index";
import Target from "./Target";

export default {
  name: "AreaTag",
  components: { Target, ShopDetail, Pagination, DictSelect, SearchFilter},
  data() {
    return {
      homeLoading: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      },
      loading: false,
      currentUser: {},
      dictFun: dictSelect,
      shopJsonParam: {},
      editDialog: {
        visible: false,
        form: {
          orgCode: undefined,
          dcCode: undefined,
          oldAreaTag: undefined,
          areaTag: undefined,
        },
        rules: {
          areaTag: [{ required: true, message: "区域分组不能为空", trigger: "change" }],
        }
      },
      importDialog: {
        visible: false,
        type: 'common',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '分组店铺导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%88%86%E7%BB%84%E5%BA%97%E9%93%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
      exportHeaders: {
        dcCode: '配送中心编码',
        shopCode: '店铺编码',
        areaTag: '区域分组',
        errorMsg: '校验结果',
      },
      targetDialog: {
        visible: false,
        loading: false,
        year: undefined,
        list: []
      },
      submitDisabled: false,
      shopDrawer: {
        visible: false,
        currentRow: undefined,
        timestamp: undefined,
      },
      targetDrawer: {
        visible: false,
        currentRow: undefined,
        timestamp: undefined,
      },
      yearOptions: []
    };
  },
  watch: {
  },
  created() {
    const currentY = new Date().getFullYear();
    this.yearOptions = [(currentY-1)+ '', currentY+ '', (currentY + 1) + ""];
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageAreaTags(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val) {
      if (val) {
        this.queryForm.createTimeBegin = val[0];
        this.queryForm.createTimeEnd = val[1];
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleViewTarget(row) {
      this.targetDrawer.currentRow = deepClone(row);
      this.targetDrawer.currentRow.year = new Date().getFullYear() + '';
      this.targetDrawer.timestamp = new Date().getTime();
      this.targetDrawer.visible = true;
    },
    handleEdit(row) {
      this.editDialog.form = {
        orgCode: row.orgCode,
        dcCode: row.dcCode,
        oldAreaTag: row.areaTag,
        areaTag: row.areaTag,
      }
      this.editDialog.visible = true;
    },
    handleViewShopDetail(row) {
      this.shopDrawer.currentRow = deepClone(row)
      this.shopDrawer.currentRow.year = new Date().getFullYear() + '';
      this.shopDrawer.timestamp = new Date().getTime();
      this.shopDrawer.visible = true;
    },
    submitEditForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          changeAreaTag(this.editDialog.form).then(res => {
            this.editDialog.visible = false;
            Notification.success("操作成功！");
            this.handleFilter()
          });
        }
      })
    },
    handleDel(row) {
      let data = {
        orgCode: row.orgCode,
        dcCode: row.dcCode,
        oldAreaTag: row.areaTag,
        areaTag: '其它',
      }
      this.callUpdateDcAreaTag(data);
    },
    callUpdateDcAreaTag(data) {
      return new Promise(resolve => {
        this.homeLoading = true;
        removeAreaTag(data).then(res => {
          if (res.code === 200) {
            this.$refs['area-tag'].handleRefChange(undefined, 'hand');
            this.homeLoading = false;
            Notification.success("操作成功！");
            this.handleFilter()
            resolve();
          }
        })
      })
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.submitDisabled = true;
      importShopAreaTag(fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.submitDisabled = false;
        this.loading = false;
        this.$refs.goodsUpload.clearFiles();
      })
    },
    downloadImportResult(data) {
      excelExport({
        topHeaders: this.exportHeaders,
        data: data,
        title: '分组店铺导入校验结果',
      });
    },
    handleExport() {
      if (!this.queryForm.orgCode) {
        Notification.warning("请选择分公司！");
        return;
      }
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportShopAreaTag(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '分区分组数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   padding-top: 10px;
//   padding-bottom: 5px;
// }

// ::v-deep .el-input__inner {
//   padding-left: 5px !important;
//   max-width: 200px !important;
// }
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
