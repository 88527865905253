<template>
  <div style="padding: 20px">
    <label>{{ targetName }}</label>
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      :span-method="objectSpanMethod"
    >
      <el-table-column label="人员" prop="nickName" width="150">
        <template #default="{ row }">
          <span v-if="row.userName">{{ row.userName }}-</span>
          <span>{{ row.nickName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="指标" prop="quotaName" width="180" />
      <el-table-column v-if="showDc" label="配送中心" prop="dcName" width="180" />
      <el-table-column v-if="showBoost" label="阵地" prop="boostShortName" width="180" />
      <el-table-column label="目标" prop="desc" width="300" show-overflow-tooltip />
      <el-table-column label="满分/金额" prop="maxNum" width="100"/>
      <el-table-column label="实际得分/金额" prop="boostNum" width="280" />
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { dictSelect, userSelect, pageSummaryDetail, exportSummaryDetail } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
export default {
  name: "UserTag",
  components: {  Pagination},
  props: {
    // 导入弹窗基础参数
    id: {
      type: Number,
      required: true
    },
    condition: {
      type: String,
      required: true
    },
    targetName: {
      type: String,
      required: true
    },
    userName: {
      type: String,
    },
  },
  data() {
    return {
      dictFun: dictSelect,
      dictUserFun: userSelect,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        userName: undefined,
        tagId: undefined,
        orgCode: undefined,
      },
      loading: false,
      currentRow: {},
      colFields: ['userName'],
      spanArr: [],
      showDc: false,
      showBoost: false
    };
  },
  watch: {
    id: {
      handler(nV, oV) {
        if (nV) {
          this.handleFilter();
        }
      },
      deep: true,
      immediate: true
    },
    userName: {
      handler(nV, oV) {
        this.handleFilter();
      },
      deep: true,
      immediate: true
    },
  },
  created() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageSummaryDetail(this.id, this.userName, query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.showDc = res.data.records.filter(e => e.dcCode && e.dcCode!='').length > 0;
        this.showBoost = res.data.records.filter(e => e.boostShortName && e.boostShortName!='').length > 0;
        this.$refs.table.doLayout();
        this.getSpanArr(res.data.records);
        this.pageData.rows = res.data.records;
        this.pageData.total = res.data.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    getSpanArr(list) {
      for (let i = 0; i < list.length; i++) {
        let row = i;
        // let col = i % this.colCount;
        if (row === 0) {
          // i 表示行 j表示列
          for (let j = 0; j < this.colFields.length; j++) {
            this.spanArr[i * this.colFields.length + j] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        } else {
          for (let j = 0; j < this.colFields.length; j++) {
            // 当前和上一次的一样
            // 1. 合并所有列的相同数据单元格
            if (
              list[row][this.colFields[j]] ===
              list[row - 1][this.colFields[j]]
            ) {
              let beforeItem = this.spanArr[(row - 1) * this.colFields.length + j];
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1 + beforeItem.rowspan, // 合并几行
                colspan: 1, // 合并几列,我这里只是跨行合并,不跨列合并,所以用的1
              };
              beforeItem.rowspan = 0;
              beforeItem.colspan = 0;
            } else {
              // rowspan 和 colspan 都为1表格此单元格不合并
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1,
                colspan: 1,
              };
            }
          }
        }
      }
      // 对数据进行倒序
      let stack = [];
      for (let i = 0; i < this.colFields.length; i++) {
        for (let j = 0; j < list.length; j++) {
          // console.log("i=" + i + " j=" + j);
          // i 表示列 j表示行
          if (j === 0) {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            }
          } else {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            } else {
              stack.push(this.spanArr[j * this.colFields.length + i]);
              while (stack.length > 0) {
                let pop = stack.pop();
                let len = stack.length;
                this.spanArr[(j - len) * this.colFields.length + i] = pop;
              }
            }
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.colFields.indexOf(column.property) > -1 || (this.condition !=='1' && (column.property === 'maxNum' || column.property === 'boostNum'))) {
        return this.spanArr[rowIndex * this.colFields.length]
      }
    },
    handleExport() {
      this.loading = true;
      exportSummaryDetail(this.id, this.userName, this.queryForm).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', this.targetName + '绩效汇总导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
  }
}
</script>

<style scoped>

</style>
