<template>
  <div>
    <div class="mg-search">
      <div class="head-signboard-box">
        <mg-signboard title="新品上市智能化报表" />
      </div>
      <el-form size="mini" label-width="auto" ref="mgSearchRef" :inline="true" :model="queryForm" @keyup.enter.native="handleSubmit">
        <!-- <el-form-item label="赛道" size="mini">
            <el-select clearable v-model="queryForm.raceName" style="width:90px">
                <el-option
                v-for="item in options.raceNameLists"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                ></el-option>
            </el-select>
        </el-form-item>-->

        <el-form-item label="大类" size="mini">
          <el-select clearable v-model="queryForm.mainCategoryName" @change="handleOptionChange($event, 'max')">
            <el-option
              v-for="item in options.productMaxCategorieOtions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中类" size="mini">
          <el-select clearable v-model="queryForm.midCategoryName" @change="handleOptionChange($event, 'mid')">
            <el-option
              v-for="item in options.productMidCategorieOtions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小类" size="mini">
          <el-select clearable v-model="queryForm.subCategoryName">
            <el-option
              v-for="item in options.productSubCategorieOtions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品负责人" size="mini">
          <el-select clearable v-model="queryForm.personLiable">
            <el-option v-for="item in personLiableOptions" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动" size="mini">
          <el-select clearable v-model="queryForm.activity">
            <el-option v-for="item in activityOptions" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货号" size="mini">
          <el-input size="mini" v-model="queryForm.cargoNo" placeholder="请输入货号" clearable />
        </el-form-item>
        <el-form-item label="品名" size="mini">
          <el-input size="mini" v-model="queryForm.skuName" placeholder="请输入品名" clearable />
        </el-form-item>
        <el-form-item label="料号" size="mini">
          <el-input size="mini" v-model="queryForm.materialCode" placeholder="请输入品名" clearable />
        </el-form-item>
        <el-form-item label="可用库存类型" size="mini">
          <el-select clearable v-model="queryForm.ifAvailableStock">
            <el-option v-for="item in avaliableStockType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓位组织" prop="stockOrg" size="mini">
          <el-select v-model="queryForm.stockOrg" clearable placeholder="请选择">
            <el-option v-for="item in stockOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="系列" prop="series" size="mini">
          <el-select v-model="queryForm.series" clearable placeholder="请选择">
            <el-option v-for="item in seriesOptions" :key="item.label" :label="item.label" :value="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="加减单回复类型" prop="callBackType" size="mini">
          <el-select v-model="queryForm.callBackType" clearable placeholder="请选择">
            <el-option v-for="item in callBackTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleSubmit">查询</el-button>
        <el-button class="form-btn-item" size="mini" @click="handleTableHeadAction('reset')">重置</el-button>
        <el-button size="mini" type="primary" @click="handleTableHeadAction('importAddReduce')">导入加减单数据</el-button>
        <el-button size="mini" type="primary" :loading="btnStatus.isExport" @click="handleTableHeadAction('export')">导出</el-button>
        <el-button v-if="authUsers.indexOf(currentUser)>-1" size="mini" type="primary" :loading="btnStatus.isDeliveryExport" @click="handleStockDialog('抛单DRP')">
          抛单DRP
        </el-button>
        <el-button size="mini" type="primary" :loading="btnStatus.isResetStatus" @click="handleStockDialog('重置流程')">重置流程</el-button>
        <el-button size="mini" type="primary" style="float:right" @click="handleFilterColumns">列编辑</el-button>
      </el-form>
    </div>
    <div class="mg-list-box">
      <el-table
        ref="table"
        :data="pageData.rows"
        height="calc(100vh - 180px)"
        v-loading="loading"
        size="mini"
        border
        highlight-current-row
        @header-dragend="handleDrag"
      >
        <el-table-column
          label="序号"
          type="index"
          fixed="left"
          width="50">
        </el-table-column>
        <template v-for="item in checkedColumns">
          <el-table-column
            v-if="newPublishReportColumns[item]"
            :key="item"
            :prop="newPublishReportColumns[item].prop"
            :min-width="newPublishReportColumns[item].width"
            :label="newPublishReportColumns[item].label"
            :align="newPublishReportColumns[item].align"
            :fixed="newPublishReportColumns[item].fixed"
            :sortable="newPublishReportColumns[item].sortable"
            :show-overflow-tooltip="newPublishReportColumns[item].showOverflowTooltip"
          >
            <template slot-scope="{ row }">
              <span>{{ row[newPublishReportColumns[item].prop] }}</span>
            </template>
          </el-table-column>
        </template>
        <el-table-column show-overflow-tooltip label="操作" min-width="120px" fixed="right" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button size="mini" type="primary" @click="handleTableRowEvent(scope.row, scope.$index)">分公司详情</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit"
                  @pagination="handleLists"/>
    </div>
    <!-- 导入弹窗 -->
    <mg-importDialog
      :urlParams="urlParams"
      :importBaseParams="importBaseParams(importDialog.importBaseParams)"
      @handleImportSuccess="handleImportSuccess"
    />
    <el-dialog title="分公司详情弹窗" :visible.sync="dialogVisible" width="80%" center>
      <el-tag effect="dark" style="width: 80%; margin-bottom: 15px; margin-left: 10%; text-align: center">
        <div style="display: flex">
          <span style="font-size: 15px; flex: 1;">料号：{{ currentRow.materialCode }}</span>
          <span style="font-size: 15px; flex: 1;">可用库存总量：{{ currentRow.availableStock?currentRow.availableStock: 0 }} (RDC：{{ (currentRow.rdcOnStock -currentRow.rdcOweCount)?(currentRow.rdcOnStock -currentRow.rdcOweCount): 0 }} 华南：{{ (currentRow.hnOnStock -currentRow.hnOweCount)? (currentRow.hnOnStock -currentRow.hnOweCount):0 }} 上海：{{ (currentRow.shOnStock-currentRow.shOweCount)?(currentRow.shOnStock-currentRow.shOweCount):0 }})</span>
          <span style="font-size: 15px; flex: 1;">库位：{{ currentRow.stockOrg }}</span>
          <span style="font-size: 15px; flex: 1;">预计库存：{{ currentRow.expectStock }}</span>
          <span style="font-size: 15px; flex: 1;">优先区域：{{ currentRow.firstArea }}</span>
        </div>
      </el-tag>
      <el-container>
        <el-aside width="auto" style="margin: auto">
          <el-button size="mini" :disabled="nextUpperDisabled || (queryForm.page === 1 && currentIndex < 1)" icon="el-icon-arrow-left" @click="handleNextSku('upper')"
          /></el-aside>
        <el-main>
          <new-publish-sku-detail ref="mgDialog" :skuCode="currentRow.skuCode" @next-upper="nextUpperDisabled=false" />
        </el-main>
        <el-aside width="auto" style="margin: auto">
          <el-button  size="mini" :disabled="nextUpperDisabled || (queryForm.page >= queryForm.maxPage && currentIndex >= queryForm.limit - 1)" icon="el-icon-arrow-right" @click="handleNextSku('next')" /></el-aside>
      </el-container>
    </el-dialog>
    <el-dialog :title="stockDialog.title" :visible.sync="stockDialog.visible" width="400px">
      <el-form ref="stockForm" :model="stockDialog.form" label-width="80px">
        <el-form-item label="活动" prop="activity" :rules="[
            {
              required: true,
              message: '不能为空',
              trigger: 'change',
            },
          ]">
          <el-select clearable v-model="stockDialog.form.activity">
            <el-option v-for="item in activityOptions" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item-->
<!--          label="仓位组织"-->
<!--          prop="stockOrg"-->
<!--          :rules="[-->
<!--            {-->
<!--              required: true,-->
<!--              message: '不能为空',-->
<!--              trigger: 'change',-->
<!--            },-->
<!--          ]"-->
<!--        >-->
<!--          <el-select v-model="stockDialog.form.stockOrg" placeholder="请选择">-->
<!--            <el-option v-for="item in stockOptions" :key="item" :label="item" :value="item"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="收货单位">-->
<!--          <el-input v-model="stockDialog.form.acceptOrgCode" placeholder="请输入收货单位编码" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="要货单位">-->
<!--          <el-input v-model="stockDialog.form.requireOrgCode" placeholder="请输入要货单位编码" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="商品编码">-->
<!--          <el-input v-model="stockDialog.form.skuCode" placeholder="请输入商品编码" />-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="mini" type="primary" @click="handleTableHeadAction('deliveryExport')">配送导出（仅导出数据）</el-button>
        <el-button size="mini" @click="stockDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleDialogAction">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="列编辑" :visible.sync="columnsDialog.visible" width="600px">
      <div style="width: 25%; position: relative; float: right; right: 60%; top: -70px;">
        <el-button size="mini" type="primary" @click="handleCheckAll(false)">清空</el-button>
        <el-button size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
      </div>
        <span style="width:80%;position: relative; top: -20px;">{{ checkForm.checkedColumns.join(", ")}}</span>
      <div v-for="(item, key, index) in checkForm.filterColumns" :key="index" style="margin-bottom: 15px;display: inline-block;">
<!--        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"-->
<!--                     @change="handleCheckAllChange($event, key)">-->
<!--          全选-->
<!--        </el-checkbox>-->
<!--        <div style="margin: 10px 0"></div>-->
        <el-checkbox-group v-model="item.checked" @change="handleCheckedChange($event, key)">
          <el-checkbox v-for="option in item.list" :label="option" :key="option" style="width: 22%">{{
              option
            }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="dialog.visible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmChecked">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { urlParams } from "@/mixins";
import Pagination from "../../../components/Pagination";
import { newPublishReportColumns, filterColumns } from "../config";
import {
  handleNewPublishLists,
  //   handleGetNewpublishDetailBySku,
  handleImportAddReduce,
  getActivitySelect,
  getPersonSelect,
  getDictSelect, sendDrp
  // handleConfirmOrder
} from "@/api/newPublish";
import { getCurrentUser } from "@/api/eCommerce";
import { handleAddOrderWarningCategory } from "@/api/branchOffice";
import { excelExport } from "@/utils";
import { getDeliveryExportData, resetStatus } from "../../../api/newPublish";
import {Message, MessageBox} from "element-ui";
import {deepClone} from "../../../utils";
export default {
  // 商品配置中心
  name: "newPublishReport",
  mixins: [urlParams],
  components: {
    mgSignboard: () => import("@/components/Signboard"),
    mgImportDialog: () => import("@/components/ImportDialog"),
    newPublishSkuDetail: () => import("../newPublishSkuDetail"),
    Pagination
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  data() {
    return {
      btnStatus: {
        isExport: false,
        isDeliveryExport: false,
        isResetStatus: false,
      },
      newPublishReportColumns,
      handleAddOrderWarningCategory,
      handleNewPublishLists,
      filterColumns,
      checkForm: {filterColumns: {}, checkedColumns: []},
      checkedColumns: [],
      importDialog: {
        currentImportType: "importAddReduce",
        importBaseParams: [
          {
            visible: false,
            title: "加减单数据导入",
            type: "importAddReduce",
            tipsText: "必需字段: 收货单位编码、产品编码、加减单申请量",
            templateSampleHead: [
              { label: "收货单位编码", prop: "companyCode", minWidth: "80px" },
              { label: "产品编码", prop: "skuCode", minWidth: "80px" },
              { label: "加减单申请量", prop: "applyCount", minWidth: "80px" },
            ],
            templateSampleLists: [
              {
                skuCode: "00000011",
                companyCode: "004",
                applyCount: 15,
              },
            ],
            api: handleImportAddReduce,
            apiParams: "importList",
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E6%96%B0%E5%93%81-%E5%8A%A0%E5%87%8F%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          },
        ],
      },
      queryForm: {
        maxPage: 1,
        page: 1,
        limit: 10,
        raceName: "",
        mainCategoryName: "",
        midCategoryName: "",
        subCategoryName: "",
        branchGrade: "",
        cargoNo: "",
        skuName: "",
        materialCode: undefined,
        activityItem: undefined,
        activity: undefined,
        personLiable: undefined,
        stockOrg: undefined,
        callBackType: undefined
      },
      pageData: {
        total: 0,
        rows: [],
      },
      loading: false,
      disabledEdit: true,
      dialogVisible: false,
      currentRow: {},
      currentIndex: 0,
      avaliableStockType: [
        {
          value: "available",
          label: "有可用库存",
        },
        {
          value: "owe",
          label: "可用库存&有未做单量",
        },
        {
          value: "confirm",
          label: "有未做单量",
        },
        {
          value: "empty",
          label: "无可用库存",
        },
      ],
      options: {
        productMaxCategorieOtions: [],
        productMidCategorieOtions: [],
        productSubCategorieOtions: [],
      },
      originOptions: [],
      activityOptions: [],
      personLiableOptions: [],
      stockDialog: {
        title: "",
        visible: false,
        form: {
          stockOrg: undefined,
          activity: undefined,
          acceptOrgCode: undefined,
          requireOrgCode: undefined,
          skuCode: undefined,
        },
      },
      stockOptions: ["办公库", "礼品库", "股份库"],
      seriesOptions: [],
      columnsDialog: {
        visible: false,
      },
      callBackTypeOptions: [{value: "1", label: "有未回复"}, {value: "2", label: "全部回复"}],
      nextUpperDisabled: false,
      currentUser: undefined,
      authUsers: ['fangmengqi','wangsiyong']
    };
  },
  watch: {
    checkedColumns: {
      handler(newVal, oldVal) {
        localStorage.setItem('xp_checked_columns', JSON.stringify( { checked: newVal}));
      },
      immediate: false
    }
  },
  created() {
    getDictSelect('main', '').then(res => {
      if (res.code === 200) {
        this.options.productMaxCategorieOtions = res.data;
      }
    });
    getActivitySelect().then(res => {
      if (res.code === 200) {
        this.activityOptions = res.data;
      }
    });
    getPersonSelect().then(res => {
      if (res.code === 200) {
        this.personLiableOptions = res.data;
      }
    });
    getDictSelect('series', '') .then(res => {
      this.seriesOptions= res.data;
    })
    const checkedCache = localStorage.getItem('xp_checked_columns') ? JSON.parse(localStorage.getItem('xp_checked_columns')): [];
    let defaultChecked = checkedCache && checkedCache.checked? checkedCache.checked: this.filterColumns.column.list;
    this.checkedColumns = defaultChecked;
    for (let key in this.filterColumns) {
      this.filterColumns[key].list.forEach(ele => {
        if (defaultChecked.indexOf(ele) > -1) {
          this.filterColumns[key].checked.push(ele);
          this.filterColumns[key].checkedBefore.push(ele);
        }
      })
    }
    getCurrentUser().then(res => {
      if (res.code == 200) {
        this.currentUser = res.data;
      }
    })
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
    this.handleSubmit();
  },
  methods: {
    handleDrag() {
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    // 获取列表
    handleLists(detail) {
      this.loading = true;
      this.handleNewPublishLists(this.queryForm).then(res => {
        this.pageData.total = res.data.total;
        this.pageData.rows = res.data.datas;
        this.queryForm.maxPage = Math.ceil(res.data.total/this.queryForm.limit);
        if (detail) {
          this.handleTableRowEvent(this.pageData.rows[this.currentIndex], this.currentIndex)
        }
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.table.doLayout();
        })
      })
    },
    // 顶部搜索
    handleSubmit() {
      this.queryForm.page = 1;
      this.handleLists();
    },
    // 下拉选项change联动
    handleOptionChange(val, type) {
      const funcObj = {
        max: (val) => {
          this.queryForm.midCategoryName = undefined;
          this.queryForm.subCategoryName = undefined;
          this.options.productMidCategorieOtions = [];
          this.options.productSubCategorieOtions = [];
          if (val) {
            getDictSelect('mid', '?query=' + val).then(res => {
              this.options.productMidCategorieOtions = res.data;
            })
          }
        },
        mid: (val) => {
          this.queryForm.subCategoryName = undefined;
          this.options.productSubCategorieOtions = [];
          if (val) {
            getDictSelect('sub', '?query=' + val).then(res => {
              this.options.productSubCategorieOtions = res.data;
            })
          }
        },
      };
      funcObj[type](val);
    },
    // 表单顶部事件
    handleTableHeadAction(type) {
      const importDialog = this.importDialog;
      /**
       * reset                    -- 重置
       * importProductGrade       -- 导入产品等级
       * importInventoryFactor    -- 导入库存系数
       * importEstimatedSales     -- 导入预估销量
       * export                   -- 导出
       */
      if (["importAddReduce"].includes(type)) {
        const currentImportOption = importDialog.importBaseParams.find(item => item.type === type);
        importDialog.currentImportType = currentImportOption.type;
        currentImportOption.visible = true;
        return false;
      }
      const funcObj = {
        reset: () => {
          this.handleResetFields();
          this.handleLists();
        },
        export: async () => {
          const btnStatus = this.btnStatus;
          const queryForm = deepClone(this.queryForm);
          this.handleResetFields();
          const topHeaders = {};
          let filternewPublishReportColumns = [];
          for (const key in this.newPublishReportColumns) {
            filternewPublishReportColumns.push(this.newPublishReportColumns[key])
          }
          filternewPublishReportColumns.map(item => {
            return (topHeaders[item.prop] = item.label);
          });
          try {
            btnStatus.isExport = true;
            queryForm.page = 1;
            queryForm.limit = 1000000;

            const { code, data } = await handleNewPublishLists(queryForm);
            if (code === 200 && data?.datas.length > 0) {
              const newItems =
                (data.datas &&
                  data.datas.map(item => {
                    return {
                      ...item,
                    };
                  })) ||
                [];
              excelExport({
                topHeaders,
                data: newItems,
                title: "新品上市报表",
              });
            }
          } catch (err) {
            throw new Error(err);
          } finally {
            btnStatus.isExport = false;
          }
        },
        sendDrp: async () => {
          try {
            this.btnStatus.isDeliveryExport = true;
            sendDrp(deepClone(this.stockDialog.form)).then(res => {
              if (res.code == 200) {
                MessageBox.alert('抛单成功！', '提示');
              }
            })
          } catch (err) {
            throw new Error(err);
          } finally {
            this.btnStatus.isDeliveryExport = false;
            this.stockDialog.visible = false;
          }
        },
        deliveryExport: async () => {
          try {
            this.btnStatus.isDeliveryExport = true;
            const topHeaders = {
              skuCode: "产品编码",
              materialCode: "料号",
              requireOrgCode: "分公司编码【要货单位】",
              acceptOrgCode: "配送中心编码【收货单位】",
              confirmDoCount: "配送箱数【本次确认单量】",
              confirmDoCountItem: "配送数量【本次确认单量】",
              stockOrg: "仓位组织",
            };
            const { code, data } = await getDeliveryExportData(deepClone(this.stockDialog.form));
            if (code === 200 && data.length > 0) {
              const newItems =
                (data &&
                  data.map(item => {
                    return {
                      ...item,
                    };
                  })) ||
                [];
              excelExport({
                topHeaders,
                data: newItems,
                title: "配送导出",
              });
            } else if (data.length === 0) {
              Message.info("无可导出的数据");
            }
          } catch (err) {
            throw new Error(err);
          } finally {
            this.btnStatus.isDeliveryExport = false;
            this.stockDialog.visible = false;
          }
        },
      };
      funcObj[type]();
    },
    // 重置流程按钮
    async handleResetStatus() {
      try {
        this.btnStatus.isResetStatus = true;
        const { code, msg } = await resetStatus(deepClone(this.stockDialog.form));
        if (code === 200) {
          // this.handleResetFields();
          this.handleLists();
          return Message.success("重置成功");
        }
        if (code != 200) return Message.error(msg);
      } catch (err) {
        throw new Error(err);
      } finally {
        this.btnStatus.isResetStatus = false;
        this.stockDialog.visible = false;
      }
    },
    // 重置表单字段
    handleResetFields() {
      const queryForm = this.queryForm;
      queryForm.page = 1;
      queryForm.limit = 10;
      queryForm.raceName = "";
      queryForm.mainCategoryName = "";
      queryForm.midCategoryName = "";
      queryForm.subCategoryName = "";
      queryForm.branchGrade = "";
      queryForm.cargoNo = "";
      queryForm.skuName = "";
      queryForm.materialCode = undefined;
      queryForm.ifAvailableStock = undefined;
      queryForm.stockOrg = undefined;
      queryForm.callBackType = undefined;
    },
    // 表格行操作事件
    handleTableRowEvent(row, index) {
      this.currentRow = row;
      this.currentIndex = index;
      this.dialogVisible = true;
      this.$nextTick(() => {
        const rdc = (this.currentRow.rdcOnStock -this.currentRow.rdcOweCount)? this.currentRow.rdcOnStock -this.currentRow.rdcOweCount: 0;
        const hn = (this.currentRow.hnOnStock -this.currentRow.hnOweCount)? (this.currentRow.hnOnStock -this.currentRow.hnOweCount):0;
        const sh = (this.currentRow.shOnStock-this.currentRow.shOweCount)?(this.currentRow.shOnStock-this.currentRow.shOweCount):0;
        let area = undefined;
        if (rdc >= hn && rdc >= sh) {
          area ='B';
        } else if (hn>=rdc && hn>=sh) {
          area ='C';
        } else if (sh>=hn && sh>=rdc) {
          area ='A';
        }
        const firstArea = row.firstArea === 'ABC'  && this.currentRow.availableStock > 0? area : row.firstArea;
        this.$refs.mgDialog?.handleLists({
          skuCode: row.skuCode,
          availableStock: row.availableStock ? row.availableStock : 0,
          firstArea: firstArea,
          activity: this.currentRow.activity
        });
      });
    },
    // 导入成功回调
    handleImportSuccess(e) {
      const importDialog = this.importDialog;
      if (["importAddReduce"].includes(e.type)) {
        const currentImportOption = importDialog.importBaseParams.find(item => item.type === e.type);
        importDialog.currentImportType = currentImportOption.type;
        currentImportOption.visible = false;
      }
      this.handleLists();
    },
    handleStockDialog(val) {
      this.stockDialog.form.stockOrg = undefined;
      this.stockDialog.form.activity = undefined;
      this.stockDialog.title = val;
      this.stockDialog.visible = true;
    },
    handleDialogAction() {
      this.$refs["stockForm"].validate(valid => {
        if (valid) {
          if (this.stockDialog.title === "抛单DRP") {
            this.handleTableHeadAction("sendDrp");
          } else if (this.stockDialog.title === "重置流程") {
            this.handleResetStatus();
          }
        }
      });
    },
    handleFilterColumns() {
      this.checkForm = {
        filterColumns: deepClone(this.filterColumns),
        checkedColumns: Object.assign([], this.checkedColumns)
      };
      this.$forceUpdate();
      this.columnsDialog.visible = true;
    },
    handleCheckAllChange(val, key, label) {
      this.checkForm.filterColumns[key].checked = val ? this.checkForm.filterColumns[key].list : this.checkForm.filterColumns[key].defaultColumns;
      const spliceIndex = val.length;
      this.checkForm.filterColumns[key].list.forEach(e => {
        // 全选
        if (val && this.checkForm.checkedColumns.indexOf(e) < 0) {
          this.checkForm.checkedColumns.splice(spliceIndex, 0, e);
        } else if (!val && this.checkForm.checkedColumns.indexOf(e) > -1) {
          const index = this.checkForm.checkedColumns.indexOf(e);
          this.checkForm.checkedColumns.splice(index, 1);
        }
      })
      this.checkForm.filterColumns[key].checkedBefore = Object.assign([], this.checkForm.filterColumns[key].checked);
      this.checkForm.filterColumns[key].isIndeterminate = false;
    },
    handleCheckedChange(value, key, label) {
      const checkedCount = value.length;
      this.checkForm.filterColumns[key].checkAll = checkedCount === this.checkForm.filterColumns[key].list.length;
      this.checkForm.filterColumns[key].isIndeterminate = checkedCount > 0 && checkedCount < this.checkForm.filterColumns[key].list.length;
      const spliceIndex = value.length;
      // 选中
      if (value.length > this.checkForm.filterColumns[key].checkedBefore.length) {
        this.checkForm.checkedColumns.splice(spliceIndex, 0, value[value.length - 1]);
      } else if (value.length < this.checkForm.filterColumns[key].checkedBefore.length) {
        // 取消选中
        this.checkForm.filterColumns[key].checkedBefore.forEach(e => {
          if (value.indexOf(e) < 0) {
            // 获取取消项
            const index = this.checkForm.checkedColumns.indexOf(e);
            this.checkForm.checkedColumns.splice(index, 1);
          }
        })
      }
      this.checkForm.filterColumns[key].checkedBefore = value;
    },
    handleCheckAll(checked) {
      for (let key in this.filterColumns) {
        this.checkForm.filterColumns[key].isIndeterminate = checked;
        this.checkForm.filterColumns[key].checkAll = checked;
        this.handleCheckAllChange(checked, key, this.checkForm.filterColumns[key].label);
      }
    },
    handleConfirmChecked() {
      this.filterColumns = this.checkForm.filterColumns;
      this.checkedColumns = this.checkForm.checkedColumns;
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
      this.columnsDialog.visible = false;
    },
    async handleNextSku(type) {
      this.nextUpperDisabled = true;
      if (this.currentIndex === 0 && type === 'upper') {
        this.queryForm.page--;
        this.currentIndex = this.queryForm.limit - 1;
        this.handleLists('upper');
      } else if (type === 'next' && this.currentIndex >= this.queryForm.limit -1) {
        this.queryForm.page++;
        this.currentIndex = 0;
        this.handleLists('next');
      } else {
        if (type === 'next') {
          this.currentIndex++;
        } else if (type === 'upper') {
          this.currentIndex--;
        }
        this.handleTableRowEvent(this.pageData.rows[this.currentIndex], this.currentIndex)
      }
    }
  },
};
</script>

<style lang="scss" scoped src="@/style/branchOffice/common.scss"></style>
