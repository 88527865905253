var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "page" }, [
      _c(
        "div",
        {
          staticClass: "content",
          style: { "--bgColor": _vm.task.configJson.page?.background?.color },
        },
        [
          _c(
            "div",
            {
              staticClass: "contentInner",
              style: {
                "background-image":
                  _vm.task.configJson.page?.background?.image &&
                  `url(${_vm.task.configJson.page?.background?.image})`,
              },
            },
            [
              _c("div", { staticClass: "groupName" }, [
                _vm._v(
                  _vm._s(
                    _vm.task.configJson.page.customHeadImage
                      ? ""
                      : _vm.task.configJson.page.title
                  )
                ),
              ]),
              _c("div", { staticClass: "introduction" }, [
                _c("p", { staticClass: "introductionTitle" }, [
                  _vm._v("活动简介"),
                ]),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(_vm._s(_vm.task.description)),
                ]),
              ]),
              _c(
                "div",
                [
                  _c("GroupItem", {
                    style: {
                      "background-color":
                        _vm.task.configJson.page?.background?.color,
                      "border-radius": "unset",
                    },
                    attrs: { list: _vm.list, type: "summary" },
                    on: {
                      "update:list": function ($event) {
                        _vm.list = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { color: "dodgerblue", "text-align": "center" } },
      [_c("h1", [_vm._v("APP界面预览")]), _c("p", [_vm._v("任务合集页")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }