var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请序号" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "id", $$v)
                      },
                      expression: "queryForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "省市" } },
                [
                  _vm.options.cities.length > 0
                    ? _c("DictSelect", {
                        ref: "province",
                        attrs: {
                          type: "province",
                          value: _vm.queryForm.region,
                          linkageRef: "region",
                          dictFun: _vm.dictQueryFun,
                          singleProps: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "region", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签约类型" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      value: _vm.queryForm.signType,
                      init: "",
                      singleProps: "",
                      "init-options": _vm.options.signTypeOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "signType", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      type: "channel",
                      value: _vm.queryForm.channel,
                      linkageRef: "shopType",
                      init: "",
                      singleProps: "",
                      "init-options": _vm.options.channels,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "channel", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺类型" } },
                [
                  _c("DictSelect", {
                    ref: "shopType",
                    attrs: {
                      type: "shopType",
                      value: _vm.queryForm.shopType,
                      singleProps: "",
                      dictFun: _vm.dictQueryFun,
                      parentLinkageRef: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopType", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分公司名称" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.org,
                      dictFun: _vm.dictQueryFun,
                      parentLinkageRef: "",
                      singleProps: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "org", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "保证金状态" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      value: _vm.queryForm.depositStatus,
                      init: "",
                      singleProps: "",
                      "init-options": _vm.options.depositOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "depositStatus", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合同截止日期范围" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleDateChange($event, "contact")
                      },
                    },
                    model: {
                      value: _vm.queryForm.contractDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "contractDate", $$v)
                      },
                      expression: "queryForm.contractDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "DRP编码" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.drpTerminalCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "drpTerminalCode", $$v)
                      },
                      expression: "queryForm.drpTerminalCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "原申请序号" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.authCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "authCode", $$v)
                      },
                      expression: "queryForm.authCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.shopName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "shopName", $$v)
                      },
                      expression: "queryForm.shopName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleDateChange($event, "sign")
                      },
                    },
                    model: {
                      value: _vm.queryForm.signDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "signDate", $$v)
                      },
                      expression: "queryForm.signDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "拟使用单位" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.intendedCompany,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "intendedCompany", $$v)
                      },
                      expression: "queryForm.intendedCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收据编号" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.queryForm.receiptNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "receiptNum", $$v)
                      },
                      expression: "queryForm.receiptNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否关店" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.businessStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "businessStatus", $$v)
                        },
                        expression: "queryForm.businessStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "是", label: "是" } }),
                      _c("el-option", { attrs: { value: "否", label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "授权状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.authStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "authStatus", $$v)
                        },
                        expression: "queryForm.authStatus",
                      },
                    },
                    _vm._l(_vm.options.authStatuss, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { value: item, label: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "终端审核" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.auditStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "auditStatus", $$v)
                        },
                        expression: "queryForm.auditStatus",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { value: "0", label: "待审核" },
                      }),
                      _c("el-option", {
                        attrs: { value: "1", label: "已通过" },
                      }),
                      _c("el-option", {
                        attrs: { value: "2", label: "已驳回" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit()
                        },
                      },
                    },
                    [_vm._v("单行新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleImport("main")
                        },
                      },
                    },
                    [_vm._v("授权导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleImport("tm")
                        },
                      },
                    },
                    [_vm._v("终端导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleImport("shop")
                        },
                      },
                    },
                    [_vm._v("店铺导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleExport("0")
                        },
                      },
                    },
                    [_vm._v("授权导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleExport("1")
                        },
                      },
                    },
                    [_vm._v("终端导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleExport("2")
                        },
                      },
                    },
                    [_vm._v("店铺导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        disabled: _vm.pageData.selections.length === 0,
                        size: "mini",
                        icon: "el-icon-remove",
                        type: "danger",
                      },
                      on: { click: _vm.handleDel },
                    },
                    [_vm._v("删除已选")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tableRef",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
          on: {
            "sort-change": _vm.handleSortChange,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "90", fixed: "left" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "申请序号",
              prop: "id",
              width: "80",
              fixed: "left",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "申请时间",
              prop: "signTime",
              width: "100",
              fixed: "left",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "区域",
              prop: "region",
              width: "100",
              fixed: "left",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "是否绑定终端", prop: "ifBind", width: "160" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "授权单位",
              prop: "intendedCompany",
              width: "180",
              fixed: "left",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "授权状态", prop: "authStatus", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "新/续签", prop: "signType", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "合同期限", prop: "shop", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row?.contractDateStart
                            ? _vm.parseTime(
                                row.contractDateStart,
                                "{y}.{m}.{d}"
                              ) +
                                "-" +
                                _vm.parseTime(
                                  row?.contractDateEnd,
                                  "{y}.{m}.{d}"
                                )
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "法人", prop: "legalPerson", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "渠道", prop: "channel", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺类型", prop: "shopType", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "是否关店", prop: "businessStatus", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "应收保证金", prop: "depositAmount", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { label: "联系电话", prop: "phoneNumber", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司名称", prop: "org", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { label: "保证金状态", prop: "depositStatus", width: "80" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "保证金收取时间",
              prop: "depositCollectDate",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "实收金额", prop: "payAmount", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { label: "收据编号", prop: "receiptNum", width: "80" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "保证金退还时间",
              prop: "depositReturnDate",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "保证金实退",
              prop: "depositBackAmount",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "保证金结余",
              prop: "depositLeftAmount",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "保证金备注", prop: "depositRemark", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "原申请序号", prop: "authCode", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { label: "其它备注", prop: "remark", width: "160" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "80",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-class" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCopy(row)
                                      },
                                    },
                                  },
                                  [_vm._v("新增")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleViewLog(row)
                                      },
                                    },
                                  },
                                  [_vm._v("日志")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      border: "0px",
                                      color: "red",
                                    },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDel(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importDialog[_vm.importDialog.type],
          "append-to-body": "",
          "modal-append-to-body": false,
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增/编辑",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "120px",
                inline: true,
                rules: _vm.editDialog.rules,
                model: _vm.editDialog.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row", attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "form-title" }, [
                      _vm._v("签约信息"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申请时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.editDialog.form.signTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "signTime", $$v)
                              },
                              expression: "editDialog.form.signTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同起始时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDateChange($event, "save")
                              },
                            },
                            model: {
                              value: _vm.editDialog.contractDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog, "contractDate", $$v)
                              },
                              expression: "editDialog.contractDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "签约类型", prop: "signType" } },
                        [
                          _c("DictSelect", {
                            attrs: {
                              value: _vm.editDialog.form.signType,
                              init: "",
                              singleProps: "",
                              "init-options": _vm.options.signTypeOptions,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "signType",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.editDialog.form.id
                    ? _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "申请序号" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.editDialog.form.id)),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "保证金状态" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.editDialog.form.depositStatus,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "depositStatus",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.depositStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "保证金备注" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.depositRemark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "depositRemark",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.depositRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "拟使用单位" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.intendedCompany,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "intendedCompany",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.intendedCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "应收保证金" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 0,
                              controls: false,
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.depositAmount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "depositAmount",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.depositAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收取时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.editDialog.form.depositCollectDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "depositCollectDate",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.depositCollectDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "退还时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.editDialog.form.depositReturnDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "depositReturnDate",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.depositReturnDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "授权下发时间" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd ",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.editDialog.form.authSendDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "authSendDate",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.authSendDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实收金额", prop: "payAmount" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              controls: false,
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.payAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "payAmount", $$v)
                              },
                              expression: "editDialog.form.payAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "保证金实退",
                            prop: "depositBackAmount",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              controls: false,
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.depositBackAmount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "depositBackAmount",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.depositBackAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "收据编号", prop: "receiptNum" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.receiptNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "receiptNum", $$v)
                              },
                              expression: "editDialog.form.receiptNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "原申请序号" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.currentRow.createTime !== undefined,
                              clearable: "",
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.editDialog.form.authCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "authCode", $$v)
                              },
                              expression: "editDialog.form.authCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "其它备注" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.editDialog.form, "remark", $$v)
                              },
                              expression: "editDialog.form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "授权状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.editDialog.form.authStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editDialog.form,
                                    "authStatus",
                                    $$v
                                  )
                                },
                                expression: "editDialog.form.authStatus",
                              },
                            },
                            _vm._l(_vm.options.authStatuss, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { value: item, label: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分公司名称" } },
                        [
                          _c("DictSelect", {
                            ref: "orgSave",
                            attrs: {
                              type: "orgSave",
                              value: _vm.editDialog.form.org,
                              dictFun: _vm.dictSaveFun,
                              parentLinkageRef: "",
                              singleProps: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "org",
                                  $event
                                )
                              },
                              changeLinkageRef: _vm.handleChangeLinkageRef,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "form-title" }, [
                      _vm._v("店铺信息"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "区域", prop: "region" } },
                        [
                          _vm.options.cities.length > 0
                            ? _c("DictSelect", {
                                ref: "province",
                                attrs: {
                                  type: "province",
                                  value: _vm.editDialog.form.region,
                                  linkageRef: "orgSave",
                                  dictFun: _vm.dictSaveFun,
                                  singleProps: "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.editDialog.form,
                                      "region",
                                      $event
                                    )
                                  },
                                  changeLinkageRef: _vm.handleChangeLinkageRef,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "渠道", prop: "channel" } },
                        [
                          _c("DictSelect", {
                            attrs: {
                              type: "channel",
                              value: _vm.editDialog.form.channel,
                              linkageRef: "shopTypeSave",
                              init: "",
                              singleProps: "",
                              "init-options": _vm.options.channels,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "channel",
                                  $event
                                )
                              },
                              changeLinkageRef: _vm.handleChangeLinkageRef,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺类型", prop: "shopType" } },
                        [
                          _c("DictSelect", {
                            ref: "shopTypeSave",
                            attrs: {
                              type: "shopTypeSave",
                              value: _vm.editDialog.form.shopType,
                              singleProps: "",
                              dictFun: _vm.dictSaveFun,
                              parentLinkageRef: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.editDialog.form,
                                  "shopType",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库地址" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.stockAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "stockAddress",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.stockAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "phoneNumber",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.phoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "法人" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.legalPerson,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "legalPerson",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.legalPerson",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实控人" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.editDialog.form.realControlPerson,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editDialog.form,
                                  "realControlPerson",
                                  $$v
                                )
                              },
                              expression: "editDialog.form.realControlPerson",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.editDialog.form.customType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editDialog.form,
                                    "customType",
                                    $$v
                                  )
                                },
                                expression: "editDialog.form.customType",
                              },
                            },
                            _vm._l(_vm.options.customTypes, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { value: item, label: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否关店" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.editDialog.form.businessStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editDialog.form,
                                    "businessStatus",
                                    $$v
                                  )
                                },
                                expression: "editDialog.form.businessStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { value: "是", label: "是" },
                              }),
                              _c("el-option", {
                                attrs: { value: "否", label: "否" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tabName,
                    callback: function ($$v) {
                      _vm.tabName = $$v
                    },
                    expression: "tabName",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "终端", name: "tm" } }),
                  _c("el-tab-pane", { attrs: { label: "店铺", name: "shop" } }),
                ],
                1
              ),
              _vm.tabName === "tm"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "tmTable",
                          attrs: {
                            data: _vm.editDialog.form.tms,
                            height: "250px",
                            size: "mini",
                            border: "",
                            "highlight-current-row": "",
                            "header-cell-style": {
                              background: "rgb(235 235 235)",
                              color: "black",
                              textAlign: "center",
                              verticalAlign: "middle",
                            },
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "主配送中心编码",
                              prop: "mDcCode",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.mainDcCode +
                                              (row.mainDcName
                                                ? "-" + row.mainDcName
                                                : "")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3935249932
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "主终端编码",
                              prop: "mainDrpTerminalCode",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.mainDrpTerminalCode +
                                              (row.mainDrpTerminalName
                                                ? "-" + row.mainDrpTerminalName
                                                : "")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3835451241
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "副配送中心编码",
                              prop: "dcCode",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.dcCode +
                                              (row.dcName
                                                ? "-" + row.dcName
                                                : "")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2422512103
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "副终端编码",
                              prop: "drpTerminalCode",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row.drpTerminalCode +
                                              (row.drpTerminalName
                                                ? "-" + row.drpTerminalName
                                                : "")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3487000290
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "审核状态",
                              prop: "status",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.auditStatusMap[row.status])
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2359950003
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "创建人",
                              prop: "createUser",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "创建日期",
                              prop: "createTime",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "220",
                              align: "center",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEditRow(
                                                "currentTmIndex",
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm.auditUsers.indexOf(_vm.currentUser) >
                                        -1 && scope.row.status !== "1"
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                disabled: !scope.row.createTime,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleAuditTm(
                                                    scope.row,
                                                    "1"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("通过")]
                                          )
                                        : _vm._e(),
                                      _vm.auditUsers.indexOf(_vm.currentUser) >
                                        -1 && scope.row.status !== "1"
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                disabled: !scope.row.createTime,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleAuditTm(
                                                    scope.row,
                                                    "2"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("驳回")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: () =>
                                              _vm.editDialog.form.tms.splice(
                                                scope.$index,
                                                1
                                              ),
                                          },
                                        },
                                        [_vm._v("移除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4263576061
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            border: "1px skyblue dashed",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "mini", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddRow("tms")
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabName === "shop"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "shopTable",
                          attrs: {
                            data: _vm.editDialog.form.shops,
                            height: "250px",
                            size: "mini",
                            border: "",
                            "highlight-current-row": "",
                            "header-cell-style": {
                              background: "rgb(235 235 235)",
                              color: "black",
                              textAlign: "center",
                              verticalAlign: "middle",
                            },
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "店铺Id",
                              prop: "shopId",
                              width: "120",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "店铺名称",
                              prop: "shopName",
                              width: "220",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "创建人",
                              prop: "createUser",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "创建日期",
                              prop: "createTime",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              width: "140",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditRow(
                                                    "currentShopIndex",
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: () =>
                                                  _vm.editDialog.form.shops.splice(
                                                    scope.$index,
                                                    1
                                                  ),
                                              },
                                            },
                                            [_vm._v("移除")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1320687530
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            border: "1px skyblue dashed",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "mini", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddRow("shops")
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.tmDialog.visible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.tmDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "tmForm",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "140px",
                inline: true,
                rules: _vm.tmDialog.rules,
                model:
                  _vm.tmDialog.type.indexOf("tm") > -1
                    ? _vm.tmDialog.tmForm
                    : _vm.tmDialog.shopForm,
              },
            },
            [
              _vm.tmDialog.type.indexOf("tm") > -1
                ? _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "主配送中心编码",
                                prop: "mainDcCode",
                              },
                            },
                            [
                              _c("DictSelect", {
                                ref: "mDc",
                                attrs: {
                                  size: "mini",
                                  type: "dc",
                                  value: _vm.tmDialog.tmForm.mainDcCode,
                                  linkageRef: "mTm",
                                  dictFun: _vm.getTechDict,
                                  "init-props": {
                                    value: "code",
                                    label: "name",
                                  },
                                  remote: "",
                                  fullLabel: "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.tmDialog.tmForm,
                                      "mainDcCode",
                                      $event
                                    )
                                  },
                                  change: _vm.handleFormChange,
                                  changeLinkageRef: _vm.handleChangeLinkageRef,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "主终端编码",
                                prop: "mainDrpTerminalCode",
                              },
                            },
                            [
                              _c("DictSelect", {
                                ref: "mTm",
                                attrs: {
                                  size: "mini",
                                  type: "tm",
                                  value:
                                    _vm.tmDialog.tmForm.mainDrpTerminalCode,
                                  linkageRef: "regionSave",
                                  dictFun: _vm.getTechDict,
                                  "init-props": {
                                    value: "code",
                                    label: "name",
                                  },
                                  remote: "",
                                  fullLabel: "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.tmDialog.tmForm,
                                      "mainDrpTerminalCode",
                                      $event
                                    )
                                  },
                                  change: _vm.handleFormChange,
                                  getOtherJsonParam: (callback) =>
                                    callback({
                                      dcCode: _vm.tmDialog.tmForm.mainDcCode,
                                    }),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "副配送中心编码",
                                prop: "dcCode",
                              },
                            },
                            [
                              _c("DictSelect", {
                                ref: "dc",
                                attrs: {
                                  size: "mini",
                                  type: "dc",
                                  value: _vm.tmDialog.tmForm.dcCode,
                                  linkageRef: "tm",
                                  dictFun: _vm.getTechDict,
                                  "init-props": {
                                    value: "code",
                                    label: "name",
                                  },
                                  remote: "",
                                  fullLabel: "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.tmDialog.tmForm,
                                      "dcCode",
                                      $event
                                    )
                                  },
                                  change: function ($event) {
                                    _vm.tmDialog.tmFormstatus = "0"
                                  },
                                  changeLinkageRef: _vm.handleChangeLinkageRef,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "副终端编码",
                                prop: "drpTerminalCode",
                              },
                            },
                            [
                              _c("DictSelect", {
                                ref: "tm",
                                attrs: {
                                  size: "mini",
                                  type: "tm",
                                  value: _vm.tmDialog.tmForm.drpTerminalCode,
                                  dictFun: _vm.getTechDict,
                                  "init-props": {
                                    value: "code",
                                    label: "name",
                                  },
                                  remote: "",
                                  fullLabel: "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.tmDialog.tmForm,
                                      "drpTerminalCode",
                                      $event
                                    )
                                  },
                                  change: function ($event) {
                                    _vm.tmDialog.tmFormstatus = "0"
                                  },
                                  getOtherJsonParam: (callback) =>
                                    callback({
                                      dcCode: _vm.tmDialog.tmForm.dcCode,
                                    }),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "店铺ID", prop: "shopId" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tmDialog.shopForm.shopId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tmDialog.shopForm,
                                      "shopId",
                                      $$v
                                    )
                                  },
                                  expression: " tmDialog.shopForm.shopId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "店铺名称", prop: "shopName" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.tmDialog.shopForm.shopName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tmDialog.shopForm,
                                      "shopName",
                                      $$v
                                    )
                                  },
                                  expression: " tmDialog.shopForm.shopName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.tmDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: {
              "font-size": "16px",
              "overflow-y": "scroll",
              "max-height": "400px",
            },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "操作日志",
            visible: _vm.logDrawer.visible,
            size: "50%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.logDrawer, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.logDrawer.loading,
                      expression: "logDrawer.loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    data: _vm.logDrawer.logs,
                    height: "calc(100vh - 250px)",
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(235 235 235)",
                      color: "black",
                      textAlign: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "明细", prop: "content" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作时间", prop: "opTime", width: "140" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作人", prop: "opUser", width: "100" },
                  }),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.logDrawer.total,
                  page: _vm.logDrawer.current,
                  limit: _vm.logDrawer.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.logDrawer, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.logDrawer, "size", $event)
                  },
                  pagination: _vm.handleViewLog,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }