<template>
  <el-container>
    <el-aside style="width: 400px; height: 100vh; border-right: 1px solid #e3e0e0;background-color: #d0d6db;">
      <div>
        <div style="color:dodgerblue; text-align: center"><h1>APP界面预览</h1></div>
        <div v-if="taskForm.configJson" class="group col2">
          <TaskBig v-if="templateType==='big'" class-name="group col2" :task="taskForm" />
          <TaskSmall v-if="templateType==='small'" class-name="group col2" :task="taskForm" />
          <TaskMini v-if="templateType==='mini'" class-name="group col3" :task="taskForm" />
          <TaskHorizontal v-if="templateType==='horizontal'" :task="taskForm" />
          <TaskHighRowOne v-if="templateType === 'highRowOne'" :task="taskForm" />
        </div>
      </div>
    </el-aside>
    <el-main>
      <el-form ref="groupForm" size="mini" label-width="120px" :inline="true" :model="form" class="common-form" label-position="left">
        <el-form-item v-if="!isGroup" label="选择任务" prop="name">
          <el-select v-model="dataForm.mtTaskId" :disabled="(form && form.mtTaskId!==undefined) || uploading" placeholder="请选择任务" filterable @change="handleTaskChange">
            <el-option v-for="item in tasks" :key="item.mtTaskId" :label="item.isLeaf===0?item.taskName+'(合集)': item.taskName" :value="item.mtTaskId" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="isGroup" label="合集名称" prop="name">
          <el-input v-model="dataForm.taskName" clearable placeholder="请输入合集名称" />
        </el-form-item>
        <el-form-item label="显示名（选填，如输入空格则不显示名称）" prop="name">
          <el-input v-model="dataForm.taskText" clearable />
        </el-form-item>
        <el-form-item label="扣除白色背景" prop="name">
          <el-radio-group v-model="dataForm.removeSkuBg" :disabled="uploading" @input="(val) => {
             handleRemoveSkuBg(val, dataForm.skuImage).then(res => {
              dataForm.skuImage = res;
            });
          }">
            <el-radio :label="true" :value="true">是</el-radio>
            <el-radio :label="false" :value="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="任务样式" prop="template">
          <el-radio-group v-model="dataForm.backGroundCustom" @input="handleImageTemplateChange">
            <el-radio :label="false" :value="false">使用模板
              <el-form-item v-if="templateType!=='horizontal' && templateType!=='highRowOne'" label="商品图" prop="name">
                <el-radio-group v-model="dataForm.skuCustom" :disabled="uploading" @input="val => {
                  if (!dataForm.skuCustom && taskForm && taskForm.skuUrl) {
                    dataForm.skuImage = taskForm.skuUrl;
                  }
                  // if (dataForm.skuCustom) {
                  //   dataForm.taskNameColor = colorMap.auto.taskNameColor;
                  //   dataForm.bgColor = colorMap.auto.bgColor;
                  // }
                }">
                  <el-radio :label="false" :value="false">自动</el-radio>
                  <el-radio :label="true" :value="true"><span>自定义</span>
                    <OssUpload v-if="dataForm.skuCustom" ref="ossUpload" :value-to.sync="dataForm.skuImage" :maxFileSize="1024" class="custom-picture-card special-tips" list-type="picture-card" style="display: table"
                               :limit="1" :always-show-button="false" tips="尺寸5116*750" :tipMessageShow="false"
                               :accept-type="imgTypes" />
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="!dataForm.backGroundCustom" label="模板背景图" prop="name">
                <el-radio-group v-model="dataForm.backTemplate" @input="val => {
                  if (!dataForm.backGroundCustom && bgImg[templateType]) {
                    dataForm.backGroundImage = bgImg[templateType][val];
                    dataForm.bgTemplate = dataForm.backTemplate;
                  } else if (!dataForm.backGroundCustom) {
                    dataForm.backGroundImage = ''
                  }

                  if (templateType === 'mini' || templateType === 'small') {
                    dataForm.taskNameColor = colorMap[dataForm.backTemplate].taskNameColor;
                    dataForm.bgColor = colorMap[dataForm.backTemplate].bgColor;
                    dataForm.moreDataBg = colorMap[dataForm.backTemplate].moreDataBg;
                    if (taskForm.isLeaf === 0) {
                      dataForm.moreDataText = colorMap[dataForm.backTemplate].taskNameColor;
                    }
                  } else if (templateType==='horizontal' || templateType==='highRowOne') {
                    dataForm.taskNameColor = '#ffffff';
                    dataForm.fontColor ='#ffffff';
                  } else if (templateType==='big') {
                    dataForm.taskNameColor = colorMap[templateType + '_' + dataForm.backTemplate].taskNameColor;
                  }
                }">
                  <el-row>
                    <el-col v-for="(item, key) in template[templateType]" :key="key" :span="8">
                      <el-radio :label="key" :value="key" style="min-width: 150px"><img :src="item" :style="(templateType === 'horizontal' || templateType === 'highRowOne') ? {height: '60px', display: 'block', 'max-width': '200px'} : {height: '80px', display: 'block'}" /></el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-radio>
            <el-radio v-if="templateType !== 'small' && templateType !== 'mini'"  :label="true" :value="true" style="display: block;">自定义
              <OssUpload v-if="dataForm.backGroundCustom" ref="ossUpload" :value-to.sync="dataForm.backGroundImage" :maxFileSize="1024" class="custom-picture-card special-tips" list-type="picture-card" style="display: table"
                         :limit="1" :always-show-button="false" :tips="tipsMap[templateType]" :tipMessageShow="false"
                         :accept-type="imgTypes" />
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="templateType === 'small' || templateType === 'mini'" label="颜色配置" prop="template">
          <el-row style="display: table">
            <el-col v-for="(item,index) in options.blockColor" :key="index"  :span="6">
              <span style="vertical-align: text-bottom;">{{item.label}}</span><el-color-picker v-model="dataForm[item.value]" :show-alpha="item.value === 'contentDataBg'" />
              <OssUpload v-if="item.value==='bgColor'" ref="ossUpload" :value-to.sync="dataForm.backGroundImage" :maxFileSize="1024" class="custom-picture-card" list-type="picture-card" style="display: table"
                         :limit="1" :always-show-button="false" :tips="tipsMap[templateType]" :tipMessageShow="false"
                         :accept-type="imgTypes" />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item v-if="templateType === 'big' || templateType==='horizontal' || templateType === 'highRowOne'" label="数据颜色" prop="color">
          <div style="display: inline-flex">
            <div style="display: flex">
              <span style="display: inline-flex">数据文字颜色</span><el-color-picker v-model="dataForm.fontColor" />
            </div>
            <div style="display: flex">
              <span style="display: inline-flex">标题颜色</span><el-color-picker v-model="dataForm.taskNameColor" />
            </div>
            <div style="display: flex">
              <span style="display: inline-flex">{{ taskForm.isLeaf!==0? '更多数据底色': '任务合集底色' }}</span><el-color-picker v-model="dataForm.moreDataBg" />
            </div>
            <div style="display: flex">
              <span style="display: inline-flex">{{ taskForm.isLeaf!==0? '更多数据颜色': '任务合集颜色' }}</span><el-color-picker v-model="dataForm.moreDataText" />
            </div>
          </div>
        </el-form-item>
        <div v-if="isNoGroup">
          <label>设置展示字段</label><el-button type="text" style="margin-left: 10px" @click="showCustomFields=!showCustomFields">{{ showCustomFields? '隐藏':'展开' }}</el-button>
        </div>
        <div v-show="showCustomFields && isNoGroup">
          <el-form-item label="选择任务汇总的展示字段" prop="summaryFields" style="width: 800px">
            <div class="text-light">最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据</div>
            <el-checkbox-group v-model="dataForm.summaryFields" style="width: 700px" :max="2">
              <div v-for="(item, key) in summaryFieldMap" :key="key">
                <el-row>
                  <el-col :span="3">
                    <span class="text-light">{{ key }}</span>
                  </el-col>
                  <el-col v-for="e in item" :key="e.title" :span="5">
                    <el-checkbox :value="e.key" :label="e.key">{{e.title}}</el-checkbox>
                  </el-col>
                </el-row>
              </div>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="选择店铺页的任务展示字段" prop="shopFields" style="width: 800px">
            <div class="text-light">最多可选2个，其他会在“更多数据”中展示，阶段任务是任务期间数据，⻓期任务是本月数据</div>
            <el-checkbox-group v-model="dataForm.shopFields" style="width: 700px" :max="2">
              <div v-for="(item, key) in shopFieldMap" :key="key">
                <el-row>
                  <el-col :span="3">
                    <span class="text-light">{{ key }}</span>
                  </el-col>
                  <el-col v-for="e in item" :key="e.title" :span="5">
                    <el-checkbox :value="e.key" :label="e.key">{{e.title}}</el-checkbox>
                  </el-col>
                </el-row>
              </div>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import { homePageFields, defaultTaskJson, jlTaskJson, summaryFieldMap, shopFieldMap, horizontalTheme,
  blockTheme,shopFields, summaryFields, jlHorizontalTaskJson, horizontalTaskJson, orangeTheme } from '../../../config';
import OssUpload from "@/components/OssUpload";
import { getSTSToken } from "@/api/appTask";
import { hexToRgba, deepClone, removeBg } from "@/utils";
import { Notification } from "element-ui";
import TaskBig from "../TaskBig";
import TaskSmall from "../TaskSmall";
import TaskMini from "../TaskMini";
import TaskHorizontal from "../TaskHorizontal";
import TaskHighRowOne from "../TaskHighRowOne";
import Client from '@/utils/client'

export default {
  name: "TaskContentV2",
  components: {TaskBig, OssUpload, TaskSmall, TaskHorizontal, TaskMini, TaskHighRowOne },
  props: {
    type: {
      type: String,
      default: "add"
    },
    templateType: { // big/horizontal/small/mini
      type: String,
      // required: true,
      default: "big"
    },
    taskId: {
      type: String,
    },
    form: {
      type: Object,
      // required: true
    },
    orgCode: {
      type: String,
      // required: true,
      default: "002"
    },
    tasks: {
      type: Array,
      default() {
        return []
      }
    },
    timestamp: {
      type: Number,
      default() {
        return new Date().getTime()
      }
    },
    isGroup: {
      type:Boolean,
      default: false
    }
  },
  watch: {
    form: {
      handler(nV, oV) {
        if (nV && nV.mtTaskId) {
          // this.getTaskOptions(nV.orgCode, nV);
          this.taskForm = nV;
          this.handleTemplateChange(this.templateType, 'auto');
          this.refreshTaskForm(this.dataForm);
        } else {
          if (!this.dataObj.accessKeyId) {
            this.getAliToken().then(() => {
              this.handTaskChangeWatch(nV, 'new');
            })
          } else {
            this.handTaskChangeWatch(nV, 'new');
          }
        }
      },
      deep: true,
      immediate: true
    },
    orgCode: {
      handler(nV, oV) {
        // if (nV) {
        //   this.getTaskOptions(nV, undefined);
        // }
      },
      deep: true,
      immediate: true
    },
    dataForm: {
      handler(nV, oV) {
        if (nV) {
          this.refreshTaskForm(nV);
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      deepClone,
      taskForm: {},
      dataForm: {
        backGroundCustom: false,
        backTemplate: 'blue',
        skuImage: '',
        skuCustom: false,
        mtTaskId: undefined,
        taskText: undefined,
        taskName: undefined,
        backGroundImage: '',
        taskNameColor: undefined,
        moreDataBg: undefined,
        removeSkuBg: true,
        originSkuImage: '',
        contentDataBg: '',

        template: 'horizontal',
        bgColor: undefined,
        badgeBackColor:undefined,
        fontColor: undefined,
        badgeColor: undefined,
        taskTimeColor: undefined,
        value: undefined,
        moreDataText: undefined,
        jlColor: undefined,
        summaryFields: [],
        shopFields:[],
        customImageUrl: ''
      },
      colorMap: {
        blue: {
          taskNameColor: '#304E82',
          bgColor: '#EAF2FE',
          moreDataBg: '#EAF2FE',
        },
        red: {
          taskNameColor: '#9E323B',
          bgColor: '#FEEDF0',
          moreDataBg: '#FEEDF0',
        },
        purple: {
          taskNameColor: '#654D89',
          bgColor: '#F3EEFB',
          moreDataBg: '#F3EEFB',
        },
        yellow: {
          taskNameColor: '#CC682E',
          bgColor: '#FFF3EC',
          moreDataBg: '#FFF3EC',
        },
        auto: {
          taskNameColor: '#9E323B',
          bgColor: '#FEEDF0',
          moreDataBg: '#FEEDF0',
        },
        big_blue: {
          taskNameColor: '#1D6BB5',
        },
        big_yellow: {
          taskNameColor: '#AB3E00',
        }
      },
      template: {
        big: {
          blue:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/big-blue.png',
          yellow: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/big-yellow.png'
        },
        horizontal: {
          blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/row-blue.png',
          purple:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/row-purple.png',
          yellow: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/row-yellow.png',
          blue2: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/row-blue2.png',
          red:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/row-red.png',
          orange: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/row-orange.png',
        },
        small: {
          blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/small-blue.png',
          yellow:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/small-yellow.png',
          purple: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/small-purple.png',
          red: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/small-red.png'
        },
        mini: {
          blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/mini-blue.png',
          yellow:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/mini-yellow.png',
          red: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/mini-red.png'
        },
        highRowOne: {
          blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/high_row_blue.png',
          purple:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/high_row_purple.png',
          yellow: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/high_row_yellow.png',
        }
      },
      bgImg: {
        big: {
          blue:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/task_bg_big_blue.png',
          yellow: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/task_bg_big_yellow.png'
        },
        horizontal: {
          purple:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_row_purple.png',
          blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_row_blue.png',
          yellow: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_row_yellow.png',
          red:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_row_red.png',
          blue2: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_row_blue2.png',
          orange: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_row_orange.png',
        },
        highRowOne: {
          blue: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_high_row_blue.png',
          purple:  process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_high_row_purple.png',
          yellow: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/img/task-groupV2/bg_high_row_yellow.png',
        }
      },
      tipsMap: {
        horizontal: '尺寸1065*240',
        big: '尺寸516*750',
        small: '尺寸516*360',
        mini: '尺寸516*330'
      },
      homePageFields,defaultTaskJson, jlTaskJson,summaryFieldMap, shopFieldMap,
      imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
      taskDialog: {
        visible: false,
      },
      submitLoading: false,
      field: {
        jlBlockSummary:['jhTotal', 'jhKss'],
        jlBlockShop:['jhTotal', 'jhKss'],
        defaultBlockSummary: ['jhTotal', 'jhKss'],
        defaultBlockShop: ['jhKss', 'jhKssDiff',],
        jlHrizontalSummary: ['jhTotal', 'boostBonus'],
        jlHrizontalShop:['jhTotal', 'boostBonus'],
        defaultHrizontalSummary:['jhTotal', 'jhKss'],
        defaultHrizontalShop:['jhTotal', 'jhKss']
      },
      options: {
        taskOptions: [],
        blockColor: [ { value: 'bgColor', label: '底色' },{ value: 'taskNameColor', label: '任务名颜色' },{ value: 'jlColor', label: '业务激励颜色' },{ value: 'taskTimeColor', label: '任务时间颜色' },
          { value: 'fontColor', label: '数据颜色' },{ value: 'moreDataText', label: '”更多数据“颜色' },{ value: 'moreDataBg', label: '”更多数据“底色' },{ value: 'contentDataBg', label: '”数据“底色' }],
        horizontalColor: [ { value: 'bgColor', label: '底色' },{ value: 'taskNameColor', label: '任务名颜色' },{ value: 'badgeBackColor', label: '角标标签底色' },{ value: 'badgeColor', label: '角标标签文字' }
          ,{ value: 'fontColor', label: '数据颜色' },{ value: 'moreDataText', label: '”更多数据“颜色' },{ value: 'moreDataBg', label: '”更多数据“底色' }]
      },
      rules: {
        shopFields: [{type: 'array',  required: true, message:' ', trigger: 'blur'}],
        summaryFields: [{type: 'array',  required: true, message:' ', trigger: 'blur'}],
      },
      showCustomFields: false,
      region: 'oss-cn-shanghai',
      bucket: 'mg-data-oss',//这里选择OSS容器
      dataObj: {},
      uploading: false,
    }
  },
  computed: {
    isNoGroup() {
      if (this.dataForm.mtTaskId) {
        const task = this.tasks.find(t => t.mtTaskId == this.dataForm.mtTaskId);
        if (task && task.isLeaf === 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  created() {
    this.getAliToken();
  },
  methods: {
    formValid() {
      return new Promise((resolve) => {
        this.$refs.groupForm.validate(validate => {
          resolve(validate);
        })
      })
    },
    getDefaultData(form) {
      if (form) {
        if (form.configJson) {
          return JSON.parse(form.configJson);
        } else {
          if (!form.boostTask) {
            return defaultTaskJson;
          }
          return jlTaskJson;
        }
      }
    },
    transJsonToForm(form, json) {
      const cover = {
        custom: false,
          template: 'blue',
          skuImageType: '',
          background: {
            image: '',
            originSkuImage: '',
            removeBg: true
          }
      }
      const template = form.configJson.theme.cover && form.configJson.theme.cover.template ? form.configJson.theme.cover.template :cover.template;
      const removeBg = form.configJson.theme.cover && form.configJson.theme.cover.background && form.configJson.theme.cover.background.removeBg ? form.configJson.theme.cover.background.removeBg: true
      return {
        taskName: form.taskName,
        backGroundCustom: form.configJson.theme.background.custom,
        backTemplate: template,
        skuCustom: (this.templateType==='horizontal' || this.templateType === 'highRowOne') ? false :(form.configJson.theme.cover? form.configJson.theme.cover.custom: cover.custom),
        skuImage:  form.configJson.theme.cover && form.configJson.theme.cover.background && form.configJson.theme.cover.background.image? form.configJson.theme.cover.background.image: form.skuUrl,
        originSkuImage: form.configJson.theme.cover && form.configJson.theme.cover.background && form.configJson.theme.cover.background.image? form.configJson.theme.cover.background.image: form.skuUrl,
        backGroundImage: form.configJson.theme.background.image ? form.configJson.theme.background.image: '',
        taskText: form.configJson.theme.taskName.text,
        mtTaskId: form? form.mtTaskId:undefined,
        bgTemplate: form.configJson.theme.background.template?form.configJson.theme.background.template:template,
        fontColor: form.configJson.theme.value.color,
        removeSkuBg: removeBg,
        contentDataBg: form.configJson.theme.contentDataBg ? form.configJson.theme.contentDataBg.color: 'rgba(255, 255, 255, 0.606)',

        template: form.configJson.theme.mode? form.configJson.theme.mode:'horizontal',
        bgColor: form.configJson.theme.background.color,
        taskNameColor: form.configJson.theme.taskName.color,
        badgeBackColor:form.configJson.theme.badge.background.color,
        badgeColor: form.configJson.theme.badge.font.color,
        taskTimeColor: form.configJson.theme.taskTime.color,
        moreDataText: form.configJson.theme.moreDataText.color,
        moreDataBg: form.configJson.theme.moreDataBg && form.configJson.theme.moreDataBg.color? form.configJson.theme.moreDataBg.color: blockTheme.moreDataBg.color,
        jlColor: summaryFields.find(e => e.key==='boostBonus').label.color,
        summaryFields:json.summary.map(e => e.key),
        shopFields:json.shop.map(e => e.key),
        customImageUrl: ''
      }
    },
    handleTaskChange(val) {
      let task = this.tasks.find(e => e.mtTaskId === val);
      this.taskForm = deepClone(task);
      this.handleTemplateChange(this.templateType, 'hand');
      if (this.templateType === 'mini' || this.templateType === 'small') {
        this.dataForm.taskNameColor = this.colorMap[this.dataForm.backTemplate].taskNameColor;
        this.dataForm.bgColor = this.colorMap[this.dataForm.backTemplate].bgColor;
      }
      this.refreshTaskForm(this.dataForm);
    },
    async handleTemplateChange(val, type) {
      let tmpForm = {};
      if (val === 'big') {
        if ( this.taskForm && this.taskForm.boostTask) {
          tmpForm = this.transJsonToForm(this.taskForm, this.taskForm.configJson?this.taskForm.configJson:jlHorizontalTaskJson);
          if (this.isNoGroup && !this.taskForm.configJson.summary) {
            tmpForm.summaryFields= this.field.jlHrizontalSummary;
            tmpForm.shopFields= this.field.jlHrizontalShop;
          }
        } else {
          tmpForm = this.transJsonToForm(this.taskForm, this.taskForm.configJson?this.taskForm.configJson:horizontalTaskJson);
          if (this.isNoGroup && !this.taskForm.configJson.summary) {
            tmpForm.summaryFields= this.field.defaultHrizontalSummary;
            tmpForm.shopFields= this.field.defaultHrizontalShop;
          }
        }
        if (!tmpForm.backGroundImage && !tmpForm.backGroundCustom) {
          tmpForm.backGroundImage = this.bgImg.big.blue;
        }
      } else {
        if (this.taskForm && this.taskForm.boostTask) {
          tmpForm = this.transJsonToForm(this.taskForm, this.taskForm.configJson?this.taskForm.configJson:jlTaskJson);
          if (this.isNoGroup && !this.taskForm.configJson.summary) {
            tmpForm.summaryFields= this.field.jlBlockSummary;
            tmpForm.shopFields= this.field.jlBlockShop;
          }
        } else {
          tmpForm = this.transJsonToForm(this.taskForm, this.taskForm.configJson?this.taskForm.configJson: defaultTaskJson);
          if (this.isNoGroup && !this.taskForm.configJson.summary) {
            tmpForm.summaryFields= this.field.defaultBlockSummary;
            tmpForm.shopFields= this.field.defaultBlockShop;
          }
        }
      }
      if (!tmpForm.backGroundCustom && !tmpForm.skuCustom) {
        tmpForm.originSkuImage = this.taskForm.skuUrl;
        if (type === 'hand' || type === 'new') {
          tmpForm.skuImage = await this.handleRemoveSkuBg(this.dataForm.removeSkuBg, tmpForm.originSkuImage);
        } else {
          tmpForm.skuImage = this.taskForm.skuUrl;
        }
      }
      this.dataForm = tmpForm;
    },
    refreshTaskForm(nV) {
      if (!this.taskForm || !this.taskForm.mtTaskId || !this.taskForm.configJson) {
        return;
      }
      let tmpTask = deepClone(this.taskForm);
      let configJson = tmpTask.configJson;

      configJson.theme.taskName.text = nV.taskText;
      if (this.isNoGroup) {
        configJson.summary = summaryFields.filter(e => nV.summaryFields.indexOf(e.key) > -1);
        configJson.shop = shopFields.filter(e => nV.shopFields.indexOf(e.key) > -1);
        configJson.summary.forEach(e => {
          if (e.key !== 'boostBonus') {
            e.value.color = nV.fontColor;
            e.label.color = nV.fontColor;
          }
        })
        configJson.shop.forEach(e => {
          if (e.key !== 'boostBonus') {
            e.value.color = nV.fontColor;
            e.label.color = nV.fontColor;
          }
        })
      }
      configJson.theme.background.color = nV.bgColor;

      configJson.theme.background.color = nV.bgColor;
      configJson.theme.taskName.color = nV.taskNameColor;
      configJson.theme.badge.background.color = nV.badgeBackColor;
      configJson.theme.badge.font.color = nV.badgeColor;
      configJson.theme.taskTime.color = nV.taskTimeColor;
      configJson.theme.value.color = nV.fontColor;
      configJson.theme.label.color = nV.fontColor;
      configJson.theme.moreDataText.color = nV.moreDataText;
      configJson.theme.background.template = nV.bgTemplate;
      configJson.theme.moreDataBg = { color: ''}
      configJson.theme.moreDataBg.color = nV.moreDataBg;
      configJson.summary.forEach(e => {
        if (e.key === 'boostBonus') {
          e.value.color = nV.jlColor;
          e.label.color = hexToRgba(nV.jlColor, 0.7);
        } else {
          e.value.color = nV.fontColor;
          e.label.color = hexToRgba(nV.fontColor, 0.7);
        }
      })

      configJson.shop.forEach(e => {
        if (e.key === 'boostBonus') {
          e.label.color = nV.jlColor;
          e.value.color = nV.jlColor;
        } else {
          e.value.color = nV.fontColor;
          e.label.color = hexToRgba(nV.fontColor, 0.7);
        }
      })
      if (nV.template === 'horizontal') {
        if (this.dataForm.customImageUrl) {
          configJson.theme.background.image = this.dataForm.customImageUrl;
        } else {
          configJson.theme.background.image = defaultTaskJson.theme.background.image;
        }
      }
      configJson.theme.background.custom = nV.backGroundCustom;
      configJson.theme.background.image = nV.backGroundImage;
      if (!configJson.theme.cover) {
        configJson.theme.cover = {
          custom: false,
            template: 'blue',
            skuImageType: '',
            background: {
              image: '',
              originSkuImage: '',
              removeBg: true
          }
        }
      }
      if (!configJson.theme.contentDataBg) {
        configJson.theme.contentDataBg = { color: ''}
      }
      configJson.theme.contentDataBg.color = nV.contentDataBg;
      configJson.theme.cover.template = nV.backTemplate;
      configJson.theme.cover.custom = nV.skuCustom;
      configJson.theme.cover.background.image = nV.skuImage;
      configJson.theme.cover.background.removeBg = nV.removeSkuBg;

      tmpTask.configJson = configJson;
      tmpTask.taskName = nV.taskName;

      tmpTask.timestamp = new Date().getTime();
      this.$emit('update:form', tmpTask);
      this.taskForm = tmpTask;
      this.$forceUpdate();
    },
    syncConfigToOtherTask() {
      this.$emit('sync-config', deepClone(this.dataForm));
    },
    async handleRemoveSkuBg(removeSkuBg, skuImage) {
      if (removeSkuBg && skuImage) {
        const file = await removeBg(skuImage, 200, 'path');
        if (this.dataObj.accessKeyId) {
          return await this.handleUpload(file);
        } else {
          let num = 0;
          let skuImage = ''
          this.timer = setInterval(async () => {
            if (num > 11) {
              clearInterval(this.timer);
              return this.dataForm.originSkuImage ? this.dataForm.originSkuImage: skuImage;
            }
            if (this.dataObj.accessKeyId) {
              clearInterval(this.timer);
              skuImage = await this.handleUpload(file);
            }
            num++;
          }, 500)
        }
      } else {
        return this.dataForm.originSkuImage ? this.dataForm.originSkuImage: skuImage;
      }
    },
    getAliToken(){ //获取Token
      return new Promise((resolve, reject) => {
        getSTSToken({"supplier":"ALI"}).then(res => {
          if (res.success) {
            const {expiration, accessKeyId, accessKeySecret, securityToken} = res.data;
            this.expiration = expiration;
            this.dataObj = {
              region: this.region,
              bucket: this.bucket,
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
              stsToken: securityToken,
              useFetch: true
            };
            resolve(true);
          } else {
            reject(false);
          }
        }).catch(err => {
          console.log(err);
          reject(false);
        })
      })
    },
    async handleUpload(file){ //上传OSS
      try {
        let imageUrl = ''
        this.uploading = true;
        const client = Client(this.dataObj);
        // 分片上传文件
        this.$message.info('抠图中...')
        const dateDir =  new Date().getFullYear() + new Date().getMonth() + "/"
        await client.put('pdb-front/' + process.env.NODE_ENV + '/' + dateDir + new Date().getTime() + '_' + file.name, file, {
          progress: async function (p) {
            let e = {};
            e.percent = p * 100;
          }
        }).then(({res}) => {
          console.log(res);
          if (res.statusCode === 200) {
            this.$message.success('抠图成功')
            imageUrl = res.requestUrls[0]
          } else {
            Notification.error('上传失败');
          }
          this.uploading = false;
        }).catch(error => {
          Notification.error('上传失败');
          this.uploading = false;
        });
        return imageUrl;
      } catch (error) {
        console.error(error);
        Notification.error('上传失败');
        this.uploading = false;
      }
    },
    handleImageTemplateChange(val) {
      if (!this.dataForm.backGroundCustom && this.bgImg[this.templateType] && this.bgImg[this.templateType][this.dataForm.backTemplate]) {
        this.dataForm.backGroundImage = this.bgImg[this.templateType][this.dataForm.backTemplate];
        this.dataForm.bgTemplate = this.dataForm.backTemplate;
      } else if (this.templateType === 'mini' || this.templateType === 'small') {
        this.dataForm.taskNameColor = this.colorMap[this.dataForm.backTemplate].taskNameColor;
        this.dataForm.bgColor = this.colorMap[this.dataForm.backTemplate].bgColor;
        this.dataForm.moreDataBg = this.colorMap[this.dataForm.backTemplate].moreDataBg;
        if (this.taskForm.isLeaf===0) {
          this.dataForm.moreDataText = this.colorMap[this.dataForm.backTemplate].taskNameColor;
        }
      }
      this.$forceUpdate();
    },
    handTaskChangeWatch(nV, type) {
      this.taskForm = nV.isLeaf===0 ? nV: this.tasks[0];
      this.dataForm.mtTaskId = this.taskForm.mtTaskId;
      this.handleTemplateChange(this.templateType, type);
      if (!this.dataForm.backGroundCustom && this.bgImg[this.templateType]) {
        this.dataForm.backGroundImage = this.bgImg[this.templateType][this.dataForm.backTemplate];
        this.dataForm.bgTemplate = this.dataForm.backTemplate;
      } else if (this.templateType === 'mini' || this.templateType === 'small') {
        this.dataForm.taskNameColor = this.colorMap[this.dataForm.backTemplate].taskNameColor;
        this.dataForm.bgColor = this.colorMap[this.dataForm.backTemplate].bgColor;
      }
      this.refreshTaskForm(this.dataForm);
    }
  }
}
</script>

<style lang="scss" scoped>
.text-light {
  font-size: 11px;
  color: gray
}

::v-deep .custom-picture-card {
  display: inline-table !important;
  top: -11px !important;
  position: relative;
}
::v-deep .el-upload__tip {
  display: unset;
}

::v-deep {
  .el-upload--picture-card {
    height:30px;
    width: 80px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }

  .el-upload-list__item {
    height:80px;
    width: 60px;
  }

  .special-tips .el-upload__tip{
    top: 8px;
    position: relative;
  }
}
</style>
