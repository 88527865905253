<template>
  <div
    class="el-autocomplete"
    v-clickoutside="close"
    aria-haspopup="listbox"
    role="combobox"
    :aria-expanded="suggestionVisible"
    :aria-owns="id"
  >
    <el-input
      ref="input"
      v-bind="[$props, $attrs]"
      @input="handleInput"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @clear="handleClear"
      @keydown.up.native.prevent="highlight(highlightedIndex - 1)"
      @keydown.down.native.prevent="highlight(highlightedIndex + 1)"
      @keydown.enter.native="handleKeyEnter"
      @keydown.native.tab="close"
    >
      <template slot="prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </template>
      <template slot="append" v-if="$slots.append">
        <slot name="append"></slot>
      </template>
      <template slot="prefix" v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </template>
      <template slot="suffix" v-if="$slots.suffix">
        <slot name="suffix"></slot>
      </template>
    </el-input>
    <mg-autocomplete-suggestions
      visible-arrow
      :class="[popperClass ? popperClass : '']"
      :popper-options="popperOptions"
      :append-to-body="popperAppendToBody"
      ref="suggestions"
      :placement="placement"
      :id="id"
    >
      <div class="mg-autocomplate-th">
        <slot name="header"></slot>
      </div>
      <!-- 无数据时显示空 -->
      <div v-if="showEmptyText" class="mg-autocomplete-suggestion__empty">暂无数据</div>
      <li
        v-for="(item, index) in suggestions"
        :key="index"
        class="mg-autocomplate-td"
        :class="{ highlighted: highlightedIndex === index }"
        @click="select(item)"
        :id="`${id}-item-${index}`"
        role="option"
        :aria-selected="highlightedIndex === index"
      >
        <slot :item="item">
          {{ item[valueKey] }}
        </slot>
      </li>
    </mg-autocomplete-suggestions>
  </div>
</template>

<script>
  import { Autocomplete } from 'element-ui'
  import MgAutocompleteSuggestions from './mg-autocomplete-suggestions.vue'
  export default {
    extends: Autocomplete,
    components: {
      MgAutocompleteSuggestions,
    },
    data() {
      return {
        showEmptyText: false,
      }
    },
    computed: {
      suggestionVisible() {
        const suggestions = this.suggestions
        let isValidData = Array.isArray(suggestions) && suggestions.length > 0
        if (this.showEmptyText) return true
        return (isValidData || this.loading) && this.activated
      },
    },
    methods: {
      closeClear() {
        this.showEmptyText = false
        this.loading = false
        this.suggestionDisabled = true
        this.suggestions = []
        this.highlightedIndex = -1
      },
      getData(queryString) {
        if (this.suggestionDisabled) {
          return
        }
        this.loading = true
        this.fetchSuggestions(queryString, (suggestions) => {
          this.loading = false
          if (this.suggestionDisabled) {
            return
          }
          if (Array.isArray(suggestions)) {
            this.showEmptyText = suggestions.length === 0
            this.suggestions = suggestions
            this.highlightedIndex = this.highlightFirstItem ? 0 : -1
          } else {
            console.error('[Element Error][Autocomplete]autocomplete suggestions must be an array')
          }
        })
      },
      close(e) {
        this.showEmptyText = false
        this.activated = false
      },
    },
  }
</script>
<style lang="scss">
  .el-autocomplete-suggestion li {
    padding: 0;
  }
</style>
<style lang="scss">
  $border-color: rgba(37, 42, 61, 0.1);
  .mg-autocomplate-td,
  .mg-autocomplate-th {
    display: flex;
    align-items: center;
    background: rgba(37, 42, 61, 0.05);
    span {
      width: 100px;
      padding: 0 8px;
      line-height: 28px;
      border-right: 1px solid $border-color;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &:last-child {
        border-right: none;
      }
    }
  }
  .mg-autocomplate-td {
    background: #fff;
    border-bottom: 1px solid $border-color;
    span {
      font-weight: normal;
    }
  }
  .mg-autocomplete-suggestion__empty {
    min-height: 220;
    text-align: center;
    line-height: 200px;
  }
</style>
