<template>
  <div class="new-box">
    <div class="mg-search" >
    <el-card style="margin: 10px;">
        <SearchFilter ref="queryFormRef" :model="queryForm"  @search="handleQuery" @reset="resetQuery"  @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司" prop="orgCode">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            :dictFun="dictFun"
            fullLabel
            :defaultIndex="0"
            :clearable="false"
            :init="true"
            :init-options="this.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="商品包编码" prop="spCode">
          <el-input v-model="queryForm.spCode" clearable placeholder="商品包编码" />
        </el-form-item>
        <el-form-item label="联盟商品包编码" prop="lmSpCode">
          <el-input v-model="queryForm.lmSpCode"  clearable placeholder="商品包编码" />
        </el-form-item>
        <el-form-item label="商品包名称" prop="spName">
          <el-input v-model="queryForm.spName"  clearable placeholder="商品包名称" />
        </el-form-item>
        <el-form-item label="一级品类" prop="oneGradeCategoryName">
          <DictSelect
            ref="one_other"
            type="one_other"
            :value.sync="queryForm.oneGradeCategoryName"
            linkageRef="two_other"
            :dictFun="dictFun"
            default-first-option
            @changeLinkageRef="(ref, val, type) => handleChangeLinkageRef(ref, val, type, queryForm, 'dictParam')"
          />
        </el-form-item>
        <el-form-item label="二级品类" prop="twoGradeCategoryName">
          <DictSelect
            ref="two_other"
            type="two_other"
            :value.sync="queryForm.twoGradeCategoryName"
            :dictFun="dictFun"
            parentLinkageRef
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="全国范围" prop="orgAll">
          <el-select clearable v-model="queryForm.orgAll">
            <el-option :value="true" label="是" />
            <el-option :value="false" label="否" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit(undefined, 'add')">新增</el-button>
        </el-form-item>
      </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button style="margin: 0 0 10px 0;" class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit(undefined, 'add')">新增</el-button>
    <el-table
      :data="pageData.rows"
      v-loading="loading"
      class="customer-table"
      border
            size="small"
      ref="table"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="商品包编码" prop="spCode" width="220" />
      <el-table-column label="名称" prop="spName"  width="220"/>
      <el-table-column label="一级品类" prop="oneGradeCategoryName"  width="160"/>
      <el-table-column label="二级品类" prop="twoGradeCategoryName"  width="160"/>
      <el-table-column label="头图" prop="imageUrl" width="110">
        <template #default="{ row }">
          <el-image :src="row.imageUrl ? row.imageUrl.split(',')[0]: ''" :preview-src-list="handleImageUrls(row.imageUrl)" lazy>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="商品详情图" prop="groupImageUrl" width="110">
        <template #default="{ row }">
          <el-image :src="row.groupImageUrl ? row.groupImageUrl.split(',')[0]: ''" :preview-src-list="handleImageUrls(row.groupImageUrl)" lazy>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="全国范围" prop="orgAll" width="110">
        <template #default="{ row }">
          <span>{{ row.orgAll?'是': '否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联盟商品包编码" prop="lmSpCode"  width="160px" />
      <el-table-column label="创建人" prop="createUser"   width="160"/>
      <el-table-column label="创建日期" prop="createTime" width="200" />
      <el-table-column label="操作" width="140" align="center" fixed="right">
        <template #default="{ row }">
        <el-button v-if="orgAllAuth || !row.orgAll"  size="mini" type="text"  @click="handleEdit(row, 'edit')">编辑</el-button>
       <el-button size="mini" type="text"  @click="handleEdit(row, 'view')">查看</el-button>
                <el-popconfirm
                  v-if="orgAllAuth || !row.orgAll"
                  style="margin-left: 5px;"
                  :title="'请确认是否要删除商品包(' + row.spName + ')?'"
                  @confirm="handleDelete(row)"
                >
                  <el-button slot="reference" size="mini" type="text" style="border: 0px;">删除</el-button>
                </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog v-if="editDialog.visible" :title="editDialog.dialogTitle[editDialog.type]" :visible.sync="editDialog.visible" width="70%" :close-on-click-modal="false">
      <Edit ref="edit" :type="editDialog.type" :spCode.sync="editDialog.spCode" />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" :disabled="editDialog.buttonLoading" @click="editDialog.visible = false">取 消</el-button>
        <el-button v-if="editDialog.type!=='view'" :disabled="editDialog.buttonLoading" size="mini" type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="操作提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pagePackage, savePackage, removePackage, goodsDict, dictSelect } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import Edit from "./compoments/Edit";
import DictSelect from "@/components/DictSelect";

export default {
  name: "SkuPackage",
  components: { Pagination, Edit, DictSelect, SearchFilter },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        oneGradeCategoryName: undefined,
        twoGradeCategoryName: undefined,
        spCode: undefined,
        spName: undefined,
        lmSpCode: undefined
      },
      dictFun: goodsDict,
      queryDate: [],
      loading: false,
      dictParam: {},
      editDialog: {
        visible: false,
        disabled: false,
        buttonLoading: false,
        dialogTitle: {
          edit: '编辑',
          view: '查看'
        },
        spCode: undefined,
        type: 'edit',
        form: {
          dcs: [],
          skus: [],
        },
        rules: {
        },
      },
      orgAllAuth: false,
      orgOptions: [],
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.orgOptions = res.data.filter(e => e.value !== 'ALL');
      if (this.orgOptions.length > 0) {
        this.queryForm.orgCode= this.orgOptions[0].value
      }
      this.handleFilter();
    })
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      pagePackage(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImageUrls(urls) {
      return urls && urls.length > 0? urls.split(','): [];
    },
    handleEdit(row, type) {
      this.editDialog.spCode = row? row.spCode : undefined;
      this.editDialog.type = type;
      this.editDialog.visible = true;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
              this[dict] = {oneCategory: this.queryForm.oneGradeCategoryName}
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDelete(row) {
      removePackage(row.spCode).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      });
    },
    submitForm() {

      this.$refs.edit.$refs.form.validate(valiad => {
        if (valiad) {
          let form = deepClone(this.$refs.edit.form);
          if (form.skus.length  == 0) {
            Notification.warning("商品包的商品数据不能为空！");
            return;
          } else if (form.dcs.length == 0) {
            Notification.warning("商品包的适用的配送中心范围不能为空！");
            return;
          } else if (!form.imageUrl) {
            Notification.warning("商品包的头图不能为空！");
            return;
          }
          let checkedAllOrgs = form.dcs.filter(e => e.dcCode==='ALL').map(e => e.orgCode);
          let dcs = [];
          form.dcs.forEach(e => {
            if (checkedAllOrgs.indexOf(e.orgCode) < 0 || e.dcCode==='ALL') {
              dcs.push(e);
            }

          })
          form.dcs = dcs;
          form.skus.forEach(e => {
            if (!e.skuCount) {
              e.skuCount = 1;
            }
          })
          savePackage(form).then(res => {
            if (res.code  == 200) {
              Notification.success("操作成功");
              this.editDialog.visible = false;
              if (res.data) {
                this.noticeDialog.msg = res.data;
                this.noticeDialog.visible = true;
              }
              this.handleFilter();
            }
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
.skuUrlThum {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  ::v-deep {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
