<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm"> -->
        <el-form-item label="类型">
          <el-select v-model="queryForm.type" filterable default-first-option @change="() => {
            queryForm.code= undefined;
            handleFilter();
          }">
            <el-option v-for="item in options.resourceOptions" :key="item.value" :value="item.value" :label="item.tab" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='TASK'" label="分公司">
          <el-select v-model="queryForm.orgCode" filterable default-first-option clearable @change="(val) => handleOrgChange(queryForm.type, 'query')">
            <el-option v-for="item in options.orgOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='TASK'" label="任务">
          <el-select v-model="queryForm.code" filterable default-first-option clearable>
            <el-option v-for="item in options.taskOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='HX'" label="核销名称">
          <el-select v-model="queryForm.code" filterable clearable default-first-option>
            <el-option v-for="item in options.hxOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="queryForm.type==='QD_HX'" label="渠道核销">
          <el-select v-model="queryForm.code" filterable clearable default-first-option>
            <el-option v-for="item in options.qdHxOptions" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户" prop="userName">
          <DictSelect
            ref="user"
            type="user"
            :value.sync="queryForm.userName"
            :dictFun="dictUserFun"
            fullLabel
            remote
            version="v3"
            placeholder="请输入账号或姓名"
            :init-props="{ label: 'nickName', value: 'userName'}"
            @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
          />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleUserAuth('add', undefined)">新增用户绑定数据</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="resourceDrawer.visible = true">资源管理</el-button>
          <el-button class="form-btn-item" size="mini" type="danger" @click="handleDelUserAuthBatch">批量删除</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleUserAuth('add', undefined)">新增用户绑定数据</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="resourceDrawer.visible = true">资源管理</el-button>
          <el-button class="form-btn-item" size="mini" type="danger" @click="handleDelUserAuthBatch">批量删除</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
      size="small"
      class="customer-table"
      border
      v-loading="loading"
      ref="table"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="90"  />
      <el-table-column label="用户" prop="createUser" >
        <template #default="{ row }">
          <span>{{ row.userName + '-' + row.nickName }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="queryForm.type === 'TASK'" label="分公司编码/名称" prop="shop" >
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="resourceName" />
      <el-table-column label="操作" width="120" align="center">
        <template #default="{ row }">
          <div>
            <el-button size="mini" type="text" @click="handleUserAuth('edit', row)">编辑/删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="editDialog.type === 'add' ? '新增用户数据': '修改用户数据'"  :visible.sync="editDialog.visible" append-to-body width="300px" :close-on-click-modal="false" @opened="onDialogOpened" ref="editDialogRef" tabindex="1" >
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="120px" :model="editDialog.form" class="common-form" @submit.native.prevent>
        <el-row class="common-form-row">
          <el-col>
            <el-form-item label="类型">
              <el-select v-model="editDialog.form.resourceType" filterable default-first-option :disabled="editDialog.type !== 'add'" @change="() => {
                editDialog.form.resourceCodes= [];
                handleFilter();
              }">
                <el-option v-for="item in options.resourceOptions" :key="item.value" :value="item.value" :label="item.tab" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="用户" prop="userName">
              <DictSelect
                ref="user"
                type="user"
                :value.sync="editDialog.form.userName"
                :dictFun="dictUserFun"
                fullLabel
                remote
                version="v3"
                :disabled="editDialog.type !== 'add'"
                placeholder="请输入账号或姓名"
                :init-props="{ label: 'nickName', value: 'userName'}"
                @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='TASK'" label="分公司">
              <el-select v-model="editDialog.form.orgCode" :disabled="editDialog.type !== 'add'" filterable default-first-option clearable @change="(val) => handleOrgChange(editDialog.form.resourceType, 'edit')">
                <el-option v-for="item in options.orgOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='TASK'" label="任务" prop="resourceCodes">
              <el-select v-model="editDialog.form.resourceCodes" :disabled="editDialog.type !== 'add'"  filterable multiple default-first-option clearable>
                <el-option v-for="item in options.taskOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='HX'" label="核销名称" prop="resourceCodes">
              <el-select v-model="editDialog.form.resourceCodes" :disabled="editDialog.type !== 'add'" filterable multiple default-first-option>
                <el-option v-for="item in options.hxOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.form.resourceType==='QD_HX'" label="渠道核销" prop="resourceCodes">
              <el-select v-model="editDialog.form.resourceCodes" :disabled="editDialog.type !== 'add'" filterable multiple default-first-option>
                <el-option v-for="item in options.qdHxOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="options.resourceRoles[editDialog.form.resourceType]" label="关联角色">
              <el-select v-model="editDialog.form.roleIds" filterable multiple default-first-option>
                <el-option v-for="item in options.resourceRoles[editDialog.form.resourceType]" :key="item.roleId" :value="item.roleId" :label="item.roleName" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item v-if="editDialog.type === 'edit'" label="操作类型">
              <el-select v-model="editDialog.form.action" filterable default-first-option>
                <el-option v-for="item in options.actionOptions" :key="item.value" :value="item.value" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right" v-if="!editDialog.disabled">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="submitUserAuth">确 定</el-button>
      </div>
    </el-dialog>


    <el-drawer title="资源数据" :visible.sync="resourceDrawer.visible" size="70%" direction="ttb" append-to-body>
      <Resource />
    </el-drawer>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect,pageUserAuth,saveUserAuthBatch,saveUserAuth,delUserAuth,delUserAuthBatch,getAuthResourceOptions,userSelect, getUserResourceRoles } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import Resource from './compoments/resource'

export default {
  name: "Auth",
  components: { Pagination, DictSelect, Resource, SearchFilter },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
        4: "primary",
        5: "primary",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      dictUserFun: userSelect,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        type: 'HX',
        orgCode: undefined,
        code: undefined,
      },
      queryDate: [],
      loading: false,
      dictParam: {},
      editDialog: {
        visible: false,
        disabled: false,
        type: 'add',
        form: {
          userName: undefined,
          resourceType: undefined,
          resourceCodes: [],
          roleIds: [],
          roles: []
        },
        taskOptions: [],
        rules: {
          userName: [{ required: true, message: "   ", trigger: "change" }],
          resourceCodes: [{ type: 'array', required: true, message: "   ", trigger: "change" }],
        },
      },
      resourceDrawer: {
        visible: false,
      },
      options: {
        orgOptions: [],
        resourceOptions: [{ value: 'HX', label: '核销名称', tab: '核销'}, { value: 'TASK',label: '任务名称', tab: '任务'}, { value: 'QD_HX',label: '渠道核销', tab: '渠道核销'},],
        taskOptions: [],
        hxOptions: [],
        qdHxOptions: [],
        resourceRoles: {},
        actionOptions: [{ value: 'edit', label: '更新'},   { value: 'del',label: '删除'}]
      },
      userRoleKeys: [],
      headAuth: false,
    };
  },
  computed: {
    formNew() {
      return JSON.parse(JSON.stringify(this.editDialog.form));
    },
  },
  watch: {
    formNew: {
      handler(nV, oV) {
        if (!oV || nV.userName!==oV.userName || nV.resourceType!==oV.resourceType) {
          this.handleAuthChange(nV);
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.options.orgOptions = res.data;
      this.handleFilter();
    })
    getAuthResourceOptions({ type: 'HX'}).then(res => {
      this.options.hxOptions = res.data
    })
    getAuthResourceOptions({ type: 'QD_HX'}).then(res => {
      this.options.qdHxOptions = res.data
    })
    this.options.resourceOptions.forEach(e => {
      getUserResourceRoles(e.value, "0").then(res => {
        this.options.resourceRoles[e.value] = res.data;
      })
    })
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      pageUserAuth(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        type: 'HX',
        orgCode: undefined,
        code: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleOrgChange(val, action) {
      this.options.taskOptions = [];
      this.editDialog.form.roleIds = [];
      if (val && val === 'TASK') {
        let query = {};
        if (action === 'query') {
          query = deepClone(this.queryForm);
        } else {
          query = {
            type: this.editDialog.form.resourceType,
            orgCode: this.editDialog.form.orgCode
          }
        }
        getAuthResourceOptions(query).then(res => {
          this.options.taskOptions = res.data
        })
      }
    },
    async handleUserAuth(type, row) {
      if (type === 'add') {
        this.editDialog.form = {
          userName: undefined,
          resourceType: this.queryForm.type,
          resourceCodes: [],
          action: 'edit'
        }
      } else {
        await getUserResourceRoles(row.resourceType, row.userName).then(res => {
          this.editDialog.form = {
            userName: row.userName,
            resourceType: row.resourceType,
            resourceCodes: [row.resourceCode],
            roleIds: res.data.map(e => e.roleId),
            roles: res.data,
            action: 'edit'
          }
        })
      }
      this.editDialog.type =type;
      this.editDialog.visible = true;
    },
    submitUserAuth() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.editDialog.type === 'add') {
            saveUserAuthBatch(this.editDialog.form).then(res => {
              if (res.code === 200) {
                Notification.success("操作成功！");
                this.handleQuery();
                this.editDialog.visible = false;
              }
            })
          } else {
            const data = {
              userName: this.editDialog.form.userName,
              resourceType: this.editDialog.form.resourceType,
              resourceCode: this.editDialog.form.resourceCodes.join(''),
              roleIds: this.editDialog.form.roleIds,
              action: this.editDialog.form.action
            }
            saveUserAuth(data).then(res => {
              if (res.code === 200) {
                Notification.success("操作成功！");
                this.handleQuery();
                this.editDialog.visible = false;
              }
            })
          }
        }
      })
    },
    handleDelUserAuth(row) {
      this.$confirm('请确认是否删除该记录')
        .then(() => {
          delUserAuth(row).then(res => {
            this.editDialog.disabled = false;
            if(res && res.code == 200) {
              Notification.success("操作成功！");
              this.editDialog.visible = false;
              this.handleFilter();
            }
          })
        })
        .catch(() => {});
    },
    handleDelUserAuthBatch() {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少选择一条正确的记录！");
        return;
      }
      let data = deepClone(this.pageData.selections);
      this.$confirm('请确认是否删除勾选的数据')
        .then(() => {
        this.editDialog.disabled = true;
        delUserAuthBatch({ list: data }).then(res => {
          this.editDialog.disabled = false;
          if(res && res.code == 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      });
    },
    onDialogOpened() {
      this.$nextTick(() =>{
        this.$refs.editDialogRef.$el.focus()
      })
    },
    handleAuthChange(form) {
      if (form.resourceType && form.userName && this.editDialog.type === 'add') {
        getUserResourceRoles(form.resourceType, form.userName).then(res => {
          this.editDialog.form.roleIds = res.data.map(e => e.roleId);
          this.editDialog.form.roles = res.data;
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
::v-deep .checked-class .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: red;
}
</style>
