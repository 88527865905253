import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/request";

/**
 * ----------------------------- 新品上市报表 -- 新品上市报表页面接口 -- 开始 -----------------------------
 */
// 新品上市报表页面-- 列表
export function handleNewPublishLists(data) {
  return axiosPost("/new-publish-dimension", data, 1);
}
// 新品上市报表页面-- 分公司详情查看
export function handleGetNewpublishDetailBySku(data) {
  return axiosGet("/new-publish-dimension/" + data.skuCode + "/" + data.availableStock + "/" + data.firstArea + "/" + data.activity, data, 1);
}
// 新品上市报表页面-- 加减单数据导入
export function handleImportAddReduce(data) {
  return axiosPost("/new-publish-dimension/import-add-aeduce", data, 1);
}

// 新品上市报表页面-- 保存回复单量
export function handleSaveConfirmOrder(data) {
  return axiosPost("/new-publish-dimension/save-confirm-order", data, 1);
}

// 新品上市报表页面-- 确认加单量
export function handleConfirmOrder(data) {
  return axiosPost("/new-publish-dimension/confirm-order", data, 1);
}

// 新品上市报表页面-- 配送导出数据
export function getDeliveryExportData(data) {
  return axiosPost("/new-publish-dimension/delivery-export", data, 1);
}

export function sendDrp(data) {
  return axiosPost("/new-publish-dimension/sendDrp", data, 1);
}

// 新品上市报表页面-- 重置流程
export function resetStatus(data) {
  return axiosPost("/new-publish-dimension/reset", data, 1);
}
// 商品负责人下拉
export function getPersonSelect() {
  return axiosGet("/new-publish-dimension/person-select", null, 1);
}
// 商品活动下拉
export function getActivitySelect() {
  return axiosGet("/new-publish-dimension/activity-select", null, 1);
}

export function getDictSelect(type, data) {
  return axiosGet("/new-publish-dimension/dict/" + type + data, null, 1);
}

/**
 * ----------------------------- 新品上市报表 -- 新品上市跟进明细接口 -- 开始 -----------------------------
 */
// 新品上市报表跟进明细-- 列表
export function handleNewPublishListsByBranch(data) {
  return axiosPost("/new-publish-dimension/branch", data, 1);
}
// 分公司列表-- 下拉列表
export function getCompanies() {
  return axiosGet("/config-product/companies", null, 1);
}

// 获取收货列表 -- 下拉列表
export function getAcceptOrg() {
  return axiosGet("/config-product/accept-org", null, 1);
}
