var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      attrs: { type: "card" },
      model: {
        value: _vm.tabName,
        callback: function ($$v) {
          _vm.tabName = $$v
        },
        expression: "tabName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { name: "module", label: "分公司重点打造阵地" } },
        [_c("OrgDcModule", { staticStyle: { "margin-top": "8px" } })],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "target", label: "店铺打造目标" } },
        [_c("ShopTarget", { staticStyle: { "margin-top": "8px" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }