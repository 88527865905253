<template>
  <el-form-item :label="showLabel" size="mini" prop="value">
    <el-select
      v-model="queryForm.value"
      filterable
      :remote="remote"
      clearable
      :placeholder="placeholder"
      :remote-method="query => remoteMethod(query)"
      :loading="valueLoading"
      :allow-create="allowCreate"
      :multiple="multiple"
    >
      <el-option
        v-for="item in itemOptions"
        :key="item.value"
        :label="fullLabel ? item.value + '-' + item.label : item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </el-form-item>
</template>
<script>
import { dictSelect } from "@/api/stockToShop";

export default {
  name: "RemoteSelect",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      require: true,
    },
    showLabel: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    fullLabel: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    remote: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      itemOptions: [],
      valueLoading: false,
      nameLoading: false,
      queryForm: {
        value: this.value,
        label: this.label,
      },
    };
  },
  watch: {
    queryForm: {
      handler(newVal, oldVal) {
        this.$emit("update:value", newVal.value);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (!this.remote) {
      this.remoteMethod('')
    }
  },
  methods: {
    remoteMethod(query) {
      if (query || !this.remote) {
        this.valueLoading = true;
        let dateKey;
        if (this.date) {
          this.$emit('getDate', (data) => {
            dateKey = data;
          });
        }
        const data = "?query=" + query + (dateKey ? '&dateKey=' + dateKey : '');
        dictSelect(this.type, data).then(res => {
          this.valueLoading = false;
          if (res.code === 200) {
            this.itemOptions = res.data;
          }
        });
      }
    },
    clearAll() {
      this.queryForm.value = undefined;
      this.$emit("update:value", undefined);
    },
  },
};
</script>
