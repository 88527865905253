<template>
  <div class="schedulingPlan-page strategySelection-page" v-loading="calcStatus.calcLoading || tableStatus.isLoading">
    <div class="schedulingPlan-content" v-if="calcStatus.isStartCalcBefore">
      <div class="schedulingPlan-start">
        <el-button
          v-if="calcStatus.isStartCalc"
          class="schedulingPlan-toStart"
          size="mini"
          type="primary"
          style="padding:24px 60px !important;"
          @click="handleStartAdjust"
        >开始产需平衡调整
        </el-button>
        <el-row v-if="calcStatus.calcLoading">
          <el-col class="schedulingPlan-loading">
            产需平衡算法正在执行中，可能会花费一些时间，请稍候...
          </el-col>
        </el-row>
      </div>
      <div class="schedulingPlan-content" v-if="calcStatus.isCalcComplete">
        <v-progress
          :title="progressObj.title"
          :titleColor="'#e30808'"
          :salesPhaseProject="progressObj.salesPhaseProject"
          :prodPhaseProject="progressObj.prodPhaseProject"
        />
      </div>
    </div>
    <!-- 产需平衡计算完成 显示列表数据 -->
    <template v-else>
      <div class="schedulingPlan-search">
        <v-signboard :title="'策略选择'"/>
        <el-form
          class="main-search"
          size="mini"
          label-width="auto"
          ref="filialeCustome-form"
          :inline="true"
          :model="form"
        >
          <el-row>
            <el-col :md="8" :lg="6" :xl="4">
              <el-form-item label="调整策略" size="mini">
                <el-select clearable v-model="form.tactics" @change="handleTacticsAdj">
                  <el-option
                    v-for="item in options.tacticsOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-button
                  size="mini"
                  type="primary" style="margin-left:10px;"
                  @click="handleTableHeadAction('adjust')">确 认 选 择
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="schedulingPlan-content">

        <el-tabs class="prodCenter-tabs" :tab-position="'top'" v-model="activeName" style="height: 800px;">
          <!-- 预排产能图表 -- 在线填写 -->
          <el-tab-pane label="预排产能图表" class="prodCenter-tabs-item" name="barDiagramTab">
            <!-- 预排产能图表 -->
            <div id="barDiagram" style="height: 350px;"></div>
          </el-tab-pane><!-- 预排产能图表 -- 在线填写 -->
          <el-tab-pane label="预排产品列表" class="prodCenter-tabs-item" name="productTab">
            <div class="schedulingPlan-con">
              <el-table
                class="table"
                stripe
                border
                size="mini"
                ref="multipleTable"
                :data="lists"
                v-loading="tableStatus.isLoading"
                @selection-change="handleselection"
              >
                <!-- <el-table-column type="selection" width="55" /> -->
                <template v-for="(item, index) in tableObj.stacticsHead">
                  <el-table-column
                    v-if="['cargono'].includes(item.prop)" fixed="left"
                    v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                  <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
              <!-- 分页器 -->
              <v-pagination
                :total="total"
                :pageSize="pageSize"
                :currentPage="currentPage"
                @pageChange="pagingChange"
              />
            </div>
          </el-tab-pane>
          <!-- 问题产线列表 -- 在线填写 -->
          <el-tab-pane label="问题产线列表" class="prodCenter-tabs-item" name="aae03Tab">
            <div class="schedulingPlan-search">
              <el-button
                size="mini"
                type="primary"
                :loading="btnStatus.isExportLoading"
                @click="handleTableHeadAction('export')"
              >导 出
              </el-button>
            </div>
            <el-table
              class="table"
              stripe
              max-height="700px"
              border
              size="mini"
              ref="aae03Table"
              :data="aae03List"
              v-loading="tableStatus.isLoading"
            >
              <template v-for="(item, index) in tableObj.aae03Head">
                <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
              </template>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import {BarChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import {keepTwoDecimals, excelExport} from "@/utils";

import {tableLoading, paginationParams, urlParams} from '@/mixins';
import {stacticsHead, aae03Head} from '../config';

import {
  handleschedulingPlanSeeStatus,
  handleschedulingPlanCustomAdjLists,
  handleschedulingPlanCustomAdjConfirm,
  handleschedulingPlanStartCompute
} from '@/api/schedulingPlan';
import {handleProcessStatus} from '@/api/home';

export default {
  // 策略选择
  name: 'strategySelection',
  mixins: [tableLoading, paginationParams, urlParams],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vProgress: () => import('@/components/Progress'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      activeName: 'barDiagramTab',
      form: {
        tactics: ''
      },
      originalForm: {},                       // 拷贝一份form表单数据, 用于监听校验表单值的变化
      options: {
        tacticsOptions: [
          {label: '安全库存优先策略', value: 1},
          //    { label: '高效产能优先策略', value: '2' },
          {label: '自定义', value: 3}
        ]
      },
      btnStatus: {
        isSub: false,                       // 是否查询
        isDisable: true,                    // 控制查询disabled
        isReset: false,                     // 重置
        isAdjust: true                      // 是否调整
      },
      progressObj: {
        plan_month: '',
        title: '计算未能开始，请跟进以下未完成的项目',
        salesPhaseProject: [],
        prodPhaseProject: []
      },
      lists: [],
      aae03List: [],
      tableObj: {
        stacticsHead,
        aae03Head
      },
      selectRows: [],
      calcStatus: {                           // 计算状态
        isStartCalcBefore: true,            // 是否是计算前，显示对用容器
        isStartCalc: true,                  // 控制显示开始计算按钮 -- 默认显示
        isCalcComplete: false,              // 计算是否完成
        calcLoading: false                  // 计算loading
      },
      urlMonth: '',                           // 路由的url参数
      safeStrategyProdPercentYLabel: ['模具', '笔头', '机装', '注塑机', '盲盒机', '定位转印', '工艺大类'],
      safeStrategyProdPercentYVal: [],
      makePlan: {
        is_select_month_type: false,
        month: ''
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
      this.handleLookProcess();
    })
  },
  mounted() {
    //    this.initEchart()
  },
  methods: {
    // 查询进度状态
    async handleLookProcess() {
      const params = {
        operator: this.urlParams.operator,
        month: ''
      };
      const {code, data, success} = await handleProcessStatus(params);
      if (data) {
        const progressObj = this.progressObj;
        const makePlan = this.makePlan;
        const {is_select_month_avg_type, process_status, prod_phase_list, sale_phase_list} = data;
        const {plan_month, sale_phase_id, prod_phase_id, sale_phase_status, prod_phase_status} = process_status;
        makePlan.is_select_month_type = is_select_month_avg_type;
        makePlan.month = plan_month;
        // 销售中心项目
        if (sale_phase_list.length > 0) {
          this.processingdData(sale_phase_list, sale_phase_id, sale_phase_status).then(res => {
            progressObj.salesPhaseProject = res;
          })
        }
        // 生产中心项目
        if (prod_phase_list.length > 0) {
          this.processingdData(prod_phase_list, prod_phase_id, prod_phase_status).then(res => {
            progressObj.prodPhaseProject = res;
          })
        }
      }
    },
    // 处理数据
    processingdData(lists, selectId, status) {
      const iconObj = {
        0: 'icon-notImplemented',   // 未开始
        1: 'icon-underway',         // 进行中
        3: 'icon-fail',             // 已驳回
        4: 'icon-success'           // 已完成
      };
      const classNameObj = {
        0: 'notImplemented-item',   // 未开始
        1: 'underway-item',         // 进行中
        3: 'fail-item',             // 已驳回
        4: 'success-item'           // 已完成
      };
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map((item, index) => {
          let newItem = {};
          newItem = {
            label: item.dict_label || '--',
            icon: 'icon-notImplemented',
            className: 'notImplemented-item',
            isSelect: false
          }
          if (Number(item.dict_value) == selectId) {
            newItem = {
              label: item.dict_label || '--',
              icon: iconObj[status],
              className: classNameObj[status],
              isSelect: true
            }
          }
          if (Number(item.dict_value) < selectId) {
            newItem = {
              label: item.dict_label || '--',
              icon: iconObj[4],
              className: classNameObj[4],
              isSelect: false
            }
          }
          newItems.push(newItem);
        });
        resolve(newItems);
      });
    },
    // 监听策略调整change变化
    handleTacticsAdj(e) {
      if (e === 3) {
        this.$router.push({
          path: '/schedulingPlan/customAdjustment',
          query: {strategy_type: 3, operator: this.urlParams.operator}
        });
        return false;
      }
      ;
      this.form.tactics = e;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 开始计算产需平衡调整
    async handleStartAdjust() {
      const params = {
        month: this.urlMonth,
        operator: this.urlParams.operator
      };
      const calcStatus = this.calcStatus;
      calcStatus.isStartCalc = false;
      calcStatus.calcLoading = true;
      const {code, data, success} = await handleschedulingPlanSeeStatus(params);
      if (code == '0000') {
        const {compute_status, safe_strategy_product_line, selected_strategy_type} = data;
        this.form.tactics = selected_strategy_type;

        console.log("data1", data);
        if (safe_strategy_product_line) {
          const {
            mould_prod_percent,
            pen_prod_percent,
            machine_prod_percent,
            injection_prod_percent,
            box_prod_percent,
            print_prod_percent,
            craft_prod_percent
          } = safe_strategy_product_line;
          const YValObj = {
            '模具': mould_prod_percent,
            '笔头': pen_prod_percent,
            '机装': machine_prod_percent,
            '注塑机': injection_prod_percent,
            '盲盒机': box_prod_percent,
            '定位转印': print_prod_percent,
            '工艺大类': craft_prod_percent
          };
          let newItems = [];
          const safeStrategyProdPercentYLabel = this.safeStrategyProdPercentYLabel;
          safeStrategyProdPercentYLabel.map(item => {
            for (let item1 in YValObj) {
              if (item1 == item) {
                let newItem = YValObj[item1];
                newItems.push(newItem);
              }
            }
          });
          console.log("newItems", newItems);
          this.safeStrategyProdPercentYVal = newItems;
        }
        /**
         * compute_status:
         *  0: '计算前置未完成';
         *  1: '计算前置已完成';
         *  2: '计算中';
         *  3: '计算完成待选择策略';
         *  4: '已选择策略'
         */
        if (compute_status >= 1) {
          try {
            const res = await handleschedulingPlanStartCompute({operator: this.urlParams.operator});
            console.log(res, '开始产需平衡调整---=-=计算完成')
            const funcObj = {
              0: () => {      // 计算未完成，显示无法计算结果
                calcStatus.isStartCalcBefore = true;
                calcStatus.isCalcComplete = true;
              },
              1: () => {      // 计算前置已完成
                calcStatus.isStartCalcBefore = false;
                setTimeout(() => {
                  this.initEchart();
                }, 50);
              },
              2: () => {      // 计算中
                return this.$message.warning('计算超时，请刷新页面重试');
              },
              3: () => {      // 计算完成待选择策略
                calcStatus.isStartCalcBefore = false;
                setTimeout(() => {
                  this.initEchart(true);
                }, 50);
              },
              4: () => {      // 已选择策略
                calcStatus.isStartCalcBefore = false;
                setTimeout(() => {
                  this.initEchart(true);
                }, 50);
              }
            };
            funcObj[compute_status]();

            calcStatus.calcLoading = false;
          } catch (err) {
            calcStatus.calcLoading = false;
            calcStatus.isStartCalc = true;
            console.log(err, '开始产需平衡调整---计算接口有误')
          }
        } else if (compute_status === 0) {
          calcStatus.isStartCalc = true;
          calcStatus.calcLoading = false;
          return this.$message.warning('˙前置未完成:请购单确认/产能盘点/其他量导入/起订量调整确认！');
        }
      }
    },
    // 初始化echart
    async initEchart(refreshData) {
      if (refreshData) {
        const params = {
          month: this.urlMonth,
          operator: this.urlParams.operator
        };
        const {code, data, success} = await handleschedulingPlanSeeStatus(params);
        if (code == '0000') {
          const {compute_status, safe_strategy_product_line, selected_strategy_type, aae03_list} = data;
          this.form.tactics = selected_strategy_type;
          this.aae03List = aae03_list;

          console.log("data2", data);
          if (safe_strategy_product_line) {
            const {
              mould_prod_percent,
              pen_prod_percent,
              machine_prod_percent,
              injection_prod_percent,
              box_prod_percent,
              print_prod_percent,
              craft_prod_percent
            } = safe_strategy_product_line;
            const YValObj = {
              '模具': mould_prod_percent,
              '笔头': pen_prod_percent,
              '机装': machine_prod_percent,
              '注塑机': injection_prod_percent,
              '盲盒机': box_prod_percent,
              '定位转印': print_prod_percent,
              '工艺大类': craft_prod_percent
            };
            let newItems = [];
            const safeStrategyProdPercentYLabel = this.safeStrategyProdPercentYLabel;
            safeStrategyProdPercentYLabel.map(item => {
              for (let item1 in YValObj) {
                if (item1 == item) {
                  let newItem = YValObj[item1];
                  newItems.push(newItem);
                }
              }
            });
            console.log("newItems2", newItems);
            this.safeStrategyProdPercentYVal = newItems;
          }
        }
      }

      console.log("safeStrategyProdPercentYVal", this.safeStrategyProdPercentYVal);

      echarts.use(
        [TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, CanvasRenderer]
      );
      var chartDom = document.getElementById('barDiagram'),
        myChart = echarts.init(chartDom),
        option;
      option = {
        color: [
          '#409EFF',
          '#2b9ddf',
          '#c60c0c',
          '#91c7ae',
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '3%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: this.safeStrategyProdPercentYLabel,
        },
        series: [
          {
            type: 'bar',
            data: this.safeStrategyProdPercentYVal
          }
        ]
      };
      option && myChart.setOption(option);
    },
    // 获取列表数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorie || '',
          mid_category_name: form.productMiddleCategorie || '',
          sub_category_name: form.productLittleCategorie || '',
          prod_type: '',
          strategy_type: this.form.tactics,
          month: this.urlMonth || '',
          operator: this.urlParams.operator
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleschedulingPlanCustomAdjLists(params);
        if (data) {
          const {arrange_plu_list, total} = data;
          this.modifyData(arrange_plu_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取分公司客订检核列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            plu_code: item.plu_code || '--',
            plu_id: item.plu_id || '--',
            plu_name: item.plu_name || '--',
            cargono: item.cargono || '--',

            race_name: item.race_name || '--',
            material_code: item.material_code || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',
            grade: item.grade || '--',

            confirm_arrange_box_count: item.confirm_arrange_box_count || 0,
            hq_expect_store_ratio: item.hq_expect_store_ratio || 0,
            bo_kd_plu_count: item.bo_kd_plu_count || 0,
            bo_kd_plu_count_box: item.bo_kd_plu_count_box || 0,
            prod_count_up_box: item.prod_count_up_box || 0,
            prod_count_down_box: item.prod_count_down_box || 0,
            bo_kd_fill_rate: item.bo_kd_fill_rate || 0,
            bo_kd_fill_rate_text: keepTwoDecimals((item.bo_kd_fill_rate || 0) * 100) + '%',
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'adjust': async () => {
          if (this.form.tactics == '') return this.$message.error('请先选择一种策略类型');
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator,
            strategy_type: this.form.tactics
          };
          const {success} = await handleschedulingPlanCustomAdjConfirm(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.form.tactics = '';
            // this.tableStatus.isLoading = true;
            // this.getLists();
            return this.$message.success('策略选择已成功');
          }
          if (!success) this.getLists();
        },
          'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
  // 导出数据
  async exportData() {
    const topHeaders = {
      product_line_type: '产线类型',
      unique_code: '计算标识',
      reason: '原因',
      relevance_plu_old: '老品商品编码',
      relevance_plu_other: '新品商品编码',
      order_subtotal_count: '接单小计',
      total_prod_count_up: '生产上限汇总',
      total_prod_count_down: '生产下限汇总',
      adjustable_order_subtotal_count: '可调整接单小计',
      adjustable_prod_count_up: '可调整生产上限汇总',
      adjustable_prod_count_down: '可调整生产下限汇总',
      create_time: '创建时间',
    };
    try {
      if (this.aae03List.length > 0) {
        excelExport({
          topHeaders,
          data: this.aae03List,
          title: '问题产线列表'
        })
      }
      if (this.aae03List.length == 0) return this.$message.warning('数据为空');
    } catch (err) {
      this.btnStatus.isExportLoading = false;
      console.log(err, 'err--------问题产线列表导出接口有误');
    }
  },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 返回
    handleBack() {
      this.calcStatus.isStartCalcBefore = true;
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/schedulingPlan/layoutMain.scss'></style>
