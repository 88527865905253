var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "baseInfoForm",
          attrs: {
            model: _vm.baseInfoForm,
            size: "mini",
            rules: _vm.rules,
            "label-width": "auto",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "full-width",
              attrs: { label: "分组名称", prop: "ruleGroupName" },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.actionType === "edit",
                  placeholder: "请输入分组名称",
                  maxlength: "15",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.baseInfoForm.ruleGroupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.baseInfoForm, "ruleGroupName", $$v)
                  },
                  expression: "baseInfoForm.ruleGroupName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "full-width",
              attrs: { label: "业务域", prop: "buDomain" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: _vm.actionType === "edit", size: "mini" },
                  model: {
                    value: _vm.baseInfoForm.buDomain,
                    callback: function ($$v) {
                      _vm.$set(_vm.baseInfoForm, "buDomain", $$v)
                    },
                    expression: "baseInfoForm.buDomain",
                  },
                },
                _vm._l(_vm.options.buDomains, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "full-width",
              attrs: { label: "分组类型", prop: "ruleGroupType" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: _vm.actionType === "edit", size: "mini" },
                  model: {
                    value: _vm.baseInfoForm.ruleGroupType,
                    callback: function ($$v) {
                      _vm.$set(_vm.baseInfoForm, "ruleGroupType", $$v)
                    },
                    expression: "baseInfoForm.ruleGroupType",
                  },
                },
                _vm._l(_vm.options.tagTypes, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "full-width",
              attrs: { label: "主题", prop: "topic" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.actionType === "edit",
                    filterable: "",
                    placeholder: "请选择主题/输入主题",
                    "allow-create": "",
                    size: "mini",
                  },
                  model: {
                    value: _vm.baseInfoForm.topic,
                    callback: function ($$v) {
                      _vm.$set(_vm.baseInfoForm, "topic", $$v)
                    },
                    expression: "baseInfoForm.topic",
                  },
                },
                _vm._l(_vm.options.topics, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.actionType !== "edit"
            ? _c(
                "el-form-item",
                {
                  staticClass: "full-width",
                  attrs: { label: "新建方式", prop: "createMode" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.actionType === "edit",
                        filterable: "",
                        placeholder: "请选择新建方式",
                        "allow-create": "",
                        size: "mini",
                      },
                      on: { change: _vm.handleCreateModeChange },
                      model: {
                        value: _vm.baseInfoForm.createMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.baseInfoForm, "createMode", $$v)
                        },
                        expression: "baseInfoForm.createMode",
                      },
                    },
                    _vm._l(_vm.options.createModes, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                  _vm.baseInfoForm.createMode === "复制已有分组"
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: {
                            disabled: _vm.actionType === "edit",
                            filterable: "",
                            placeholder: "请选择",
                            "allow-create": "",
                            size: "mini",
                          },
                          model: {
                            value: _vm.baseInfoForm.ruleGroupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseInfoForm, "ruleGroupId", $$v)
                            },
                            expression: "baseInfoForm.ruleGroupId",
                          },
                        },
                        _vm._l(_vm.options.ruleGroupOptions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.ruleGroupName,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "更新方式", prop: "updateMode" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.baseInfoForm.updateMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.baseInfoForm, "updateMode", $$v)
                    },
                    expression: "baseInfoForm.updateMode",
                  },
                },
                _vm._l(_vm.options.updateWay, function (item) {
                  return _c(
                    "el-radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
              _vm.baseInfoForm.updateMode === "2"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "margin-left": "10px",
                      },
                    },
                    [
                      _vm._v(" 按 "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px !important" },
                          on: { change: _vm.hangleDayTypeChange },
                          model: {
                            value: _vm.baseInfoForm.dateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.baseInfoForm, "dateType", $$v)
                            },
                            expression: "baseInfoForm.dateType",
                          },
                        },
                        _vm._l(_vm.options.dateType, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                      _vm._v(" " + _vm._s(_vm.dateShow) + " "),
                      _vm.baseInfoForm.dateType === "周"
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "80px !important" },
                              model: {
                                value: _vm.baseInfoForm.weekDay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseInfoForm, "weekDay", $$v)
                                },
                                expression: "baseInfoForm.weekDay",
                              },
                            },
                            _vm._l(_vm.options.weekOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.baseInfoForm.dateType === "月"
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px !important" },
                              model: {
                                value: _vm.baseInfoForm.dayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.baseInfoForm, "dayType", $$v)
                                },
                                expression: "baseInfoForm.dayType",
                              },
                            },
                            _vm._l(_vm.options.dayOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { value: item.value, label: item.label },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.baseInfoForm.dateType === "月" &&
                      _vm.baseInfoForm.dayType === "any"
                        ? _c("el-input-number", {
                            staticStyle: { width: "auto" },
                            attrs: {
                              controls: false,
                              min: 1,
                              max: 28,
                              placeholder: "日期不超过28号",
                            },
                            model: {
                              value: _vm.baseInfoForm.day,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfoForm, "day", $$v)
                              },
                              expression: "baseInfoForm.day",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" 更新 "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "说明", prop: "remark" } },
            [
              _c("el-input", {
                staticClass: "full-width",
                attrs: { type: "textarea", placeholder: "请输入说明" },
                model: {
                  value: _vm.baseInfoForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.baseInfoForm, "remark", $$v)
                  },
                  expression: "baseInfoForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }