<template>
    <div class="new-box">
        <div class="mg-search" >
            <el-card style="margin: 10px;">
                <SearchFilter @search="handleQuery"  @reset="resetQuery" @keyup.enter.native="handleQuery">
                    <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
                    <el-form-item label="商品型号">
                        <el-input v-model="queryForm.skuModel"  clearable placeholder="商品型号" />
                    </el-form-item>
                    <el-form-item label="商品名称">
                        <el-input v-model="queryForm.skuName"  clearable placeholder="商品名称" />
                    </el-form-item>
                    <el-form-item label="特性">
                        <el-input v-model="queryForm.features"  clearable placeholder="特性" />
                    </el-form-item>
                    <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit({}, 'add')">新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport">导入</el-button>
        </el-form-item> -->
                    <!-- </el-form> -->
                </SearchFilter>
            </el-card>
        </div>
        <el-card style="margin: 10px;" class="box-card">
            <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary"
                @click="handleEdit({}, 'add')">新增</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary"
                @click="handleImport">导入</el-button>
            <el-table style="margin-top: 10px;" :data="pageData.rows"  v-loading="loading"
             ref="table" highlight-current-row         class="customer-table"
             border     size="small"
                @selection-change="handleSelectionChange">
                <el-table-column label="商品型号" prop="skuModel"/>
                <el-table-column label="商品名称" prop="skuName"  />
                <el-table-column label="产品特点" prop="features" >
                    <template #default="{ row }">
                        <ul v-if="row.features" style="list-style-type: circle;margin: auto">
                            <li v-for="item in getFeatures(row.features)" :key="item">{{ item }}</li>
                        </ul>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template #default="{ row }">
                        <div>
                            <el-row>
                                <el-col v-if="orgAllAuth || row.orgCode !== 'ALL'" :span="6"><el-button size="mini"
                                        type="text" style="border: 0px;"
                                        @click="handleEdit(row, 'edit')">编辑</el-button></el-col>
                                <el-col v-if="orgAllAuth || row.orgCode !== 'ALL'" :span="6"><el-button size="mini"
                                        type="text" style="border: 0px;"
                                        @click="handleRemove(row)">删除</el-button></el-col>
                            </el-row>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]"
                :limit.sync="queryForm.size" @pagination="handleQuery" />
        </el-card>
        <el-dialog :title="editDialog.dialogTitle[editDialog.type]" :visible.sync="editDialog.visible" width="50%"
            :close-on-click-modal="false">
            <el-form ref="form" :rules="editDialog.rules" size="mini" :inline="true" :model="editDialog.form"
                class="common-form">
                <el-row class="common-form-row">
                    <el-col :span="16">
                        <el-form-item label="商品型号" prop="skuModel" label-width="110px">
                            <DictSelect ref="modelDict" :type="''" :value.sync="editDialog.form.skuModel"
                                :dictFun="dictSkuModels" fullLabel remote
                                :init-props="{ value: 'skuModel', label: 'skuName' }" version="v3" init
                                :disabled="currentRow.skuModel && currentRow.skuModel === editDialog.form.skuModel" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="产品特点" label-width="110px" prop="features">
                            <el-input v-model="editDialog.form.features" type="textarea" placeholder="请输入" clearable
                                :autosize="{ minRows: 6, maxRows: 6 }" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button size="mini" :disabled="editDialog.buttonLoading" @click="editDialog.visible = false">取
                    消</el-button>
                <el-button v-if="editDialog.type !== 'view'" :disabled="editDialog.buttonLoading" size="mini"
                    type="primary" @click="submitForm()">确 定</el-button>
            </div>
        </el-dialog>

        <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
            @handleImportCallBack="handleImportData" append-to-body />

        <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
            <div v-html="noticeDialog.msg" style="font-size: 16px">
            </div>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
                <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageFeatures, importFeatures, saveFeatures, dictSelect, checkSkus, dictSkuModels, getSingle, delFeature } from "@/api/appTask";
import { parseTime, deepClone, excelExport } from "@/utils"
import { MessageBox, Notification } from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect";

export default {
    name: "SkuTag",
    components: { Pagination, DictSelect, ImportDialog: () => import("@/components/ImportDialog"), SearchFilter },
    filters: {
        statusFilter(status) {
            const statusMap = {
                0: "info",
                1: "primary",
                2: "success",
                3: "warning",
            };
            return statusMap[status];
        },
    },
    data() {
        return {
            pageData: {
                total: 0,
                rows: [],
                selections: [],
            },
            dictSelect,
            dictSkuModels,
            queryForm: {
                current: 1,
                size: 10,
                skuModel: undefined,
                skuName: undefined,
                features: undefined
            },
            queryDate: [],
            loading: false,
            dictParam: {},
            skuPage: {
                total: 0,
                rows: [],
                current: 1,
                size: 10,
            },
            editDialog: {
                visible: false,
                disabled: false,
                buttonLoading: false,
                dialogTitle: {
                    edit: '编辑',
                    add: '新增'
                },
                id: undefined,
                type: 'edit',
                form: {
                    skuModel: undefined,
                    features: undefined,
                },
                rules: {
                    skuModel: [{ required: true, message: " ", trigger: "change" }],
                    features: [{ required: true, message: " ", trigger: "change" }],
                },
            },
            orgAllAuth: false,
            orgOptions: [],
            importDialog: {
                currentImportType: "skuModel",
                importBaseParams: [
                    {
                        visible: false,
                        title: "商品特性数据导入",
                        type: "skuModel",
                        tipsText: "提示：商品型号不能为空",
                        templateSampleHead: [{ label: "商品型号", prop: "skuModel", minWidth: "80px" }, { label: "产品特点", prop: "feature", minWidth: "80px" }],
                        templateSampleLists: [
                            {
                                skuModel: "609023",
                                feature: '好用'
                            },
                        ],
                        apiParams: "importList",
                        previewDataLength: 10,
                        templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E4%BA%A7%E5%93%81%E7%89%B9%E7%82%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
                    },
                ],
            },
            currentRow: {},
            noticeDialog: {
                visible: false,
                msg: undefined,
            },
        };
    },
    computed: {
        importBaseParams() {
            const importDialog = this.importDialog;
            return importParams => {
                return importParams.find(item => item.type === importDialog.currentImportType);
            };
        },
    },
    watch: {
        "editDialog.form.skuModel": {
            handler(nV, oV) {
                if (nV && oV !== nV && !this.editDialog.form.id) {
                    this.handleSkuModelChange(nV);
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        dictSelect('allOrg').then(res => {
            this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true : false;
            this.orgOptions = res.data.filter(e => e.value !== 'ALL');
            this.handleFilter();
        })
    },
    mounted() {
    },
    methods: {
        handleQuery() {
            this.loading = true;
            let query = Object.assign({}, this.queryForm);
            pageFeatures(query).then(res => {
                this.loading = false;
                if (!res.data) {
                    return false;
                }
                this.pageData.rows = res.data?.records;
                this.pageData.total = res.data?.total;
                this.$refs.table.doLayout();
            });
        },
        handleFilter() {
            this.queryForm.current = 1;
            this.handleQuery();
        },
        getFeatures(val) {
            if (val) {
                try {
                    let array = JSON.parse(val);
                    return array
                } catch (e) {
                    return [val];
                }
            }
            return null
        },
        resetQuery() {
            const queryForm = {
                current: 1,
                size: 10,
            };
            this.queryForm = Object.assign({}, queryForm);
            this.$forceUpdate();
        },
        handleSelectionChange(selections) {
            this.pageData.selections = selections;
        },
        handleImageUrls(urls) {
            return urls && urls.length > 0 ? urls.split(',') : [];
        },
        handleEdit(row, type) {

            this.currentRow = deepClone(row);
            this.editDialog.form = deepClone(this.currentRow);
            this.editDialog.type = type;
            this.editDialog.visible = true;
            if (row.skuModel) {
                this.editDialog.form.features = this.transferStr(row.features);
                if (this.$refs.modelDict) {
                    this.$refs.modelDict.remoteMethod(row.skuModel, true);
                }
            }
        },
        handleChangeLinkageRef(ref, val, type, form, dict) {
            this.$nextTick(() => {
                ref.split(',').forEach(e => {
                    if (this.$refs[e]) {
                        this[dict] = { oneCategory: this.queryForm.oneGradeCategoryName }
                        setTimeout(() => {
                            this.$refs[e].handleRefChange(val, type);
                        }, 100)
                        this.$forceUpdate();
                    }
                })
            });
        },
        handleImport() {
            const type = 'skuModel'
            const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === type);
            this.importDialog.currentImportType = currentImportOption.type;
            currentImportOption.visible = true;
            return false;
        },
        async handleImportData(val) {
            this.noticeDialog.msg = ""
            let tempArray = val["importList"];
            let errorData = [];
            tempArray.forEach(e => {
                if (!e.skuModel) {
                    errorData.push(1)
                    e.errorMsg = '商品型号不能为空'
                }
                e.field = e.feature;
                e.dataType = '1';
            })
            let msg = undefined;
            if (errorData.length === tempArray.length) {
                msg = " 导入失败，导入的商品型号不能为空，请填写后重新导入！"
            }
            if (msg) {
                MessageBox.alert(msg, '提示', {
                    dangerouslyUseHTMLString: true
                });
                return;
            }
            await checkSkus(0, { skus: tempArray }).then((res) => {
                res.data.skus.forEach(e => {
                    e.features = e.field;
                })
                const errorCount = res.data.skus.filter(e => e.checkResult === '3' && e.dataType === '1').length;
                this.editDialog.form.tmpSkus = [].concat(res.data.skus.filter(e => e.dataType === '1'));
                if (errorCount > 0) {
                    this.noticeDialog.msg = " 导入失败，请重新上传" + res.data.errorMsg;
                    this.noticeDialog.visible = true;
                    return;
                } else {
                    importFeatures({ list: res.data.skus }).then(r => {
                        const repeatCount = res.data.skus.filter(e => e.checkResult === '2' && e.dataType === '1').length;
                        const successCount = res.data.skus.filter(e => e.checkResult === '1' && e.dataType === '1').length;
                        this.noticeDialog.msg = "导入成功， 共 " + tempArray.length + " 条，成功 " + successCount + " 条，重复 " + repeatCount + " 条数据";
                        this.noticeDialog.visible = true;
                        const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === 'skuModel');
                        this.importDialog.currentImportType = currentImportOption.type;
                        currentImportOption.visible = false;
                    })
                }
            })
            this.$forceUpdate();
        },
        submitForm() {
            this.$refs.form.validate(valiad => {
                if (valiad) {
                    let form = deepClone(this.editDialog.form);
                    saveFeatures(form).then(res => {
                        if (res.code == 200) {
                            Notification.success("操作成功");
                            this.editDialog.visible = false;
                            this.handleFilter();
                        }
                    })
                }
            })
        },
        handleQuerySku(type, page) {
            const filterData = this.form[type].filter(e => {
                return !this.querySkuKeyWord || e.skuModel.indexOf(this.querySkuKeyWord) > -1;
            })
            const total = this[page].current * this[page].size;
            this[page].total = filterData.length;
            this[page].rows = filterData.slice(this[page].size * (this[page].current - 1), total >= filterData.length ? filterData.length : this[page].current * this[page].size);
            const tableRef = 'skuTableRef';
            this.$nextTick(() => {
                if (this.$refs[tableRef]) {
                    this.$refs[tableRef].doLayout();
                }
            })
        },
        downloadImportResult() {
            let headers = {};
            let data = [];
            let title = ''
            data = this.editDialog.form.tmpSkus.filter(e => e.dataType === '1');
            title = '商品标签绑定商品导入结果';
            headers = this.exportHeaders.skuModel;
            excelExport({
                topHeaders: headers,
                data: data,
                title: title,
            });
        },
        handleSkuModelChange(val) {
            if (val) {
                getSingle(val).then(res => {
                    if (res.data && res.data.features) {
                        this.$set(this.editDialog.form, 'features', this.transferStr(res.data.features))
                    }
                    this.$forceUpdate();
                })
            }
        },
        transferStr(val) {
            if (val) {
                try {
                    const array = JSON.parse(val);
                    return array.join('\n');
                } catch (e) {
                    return val;
                }
            } else {
                return undefined;
            }
        },
        handleRemove(row) {
            this.$confirm('请确认是删除型号 ' + (row.skuModel) + ' 的产品特性？')
                .then(() => {
                    delFeature(row.skuModel).then(res => {
                        if (res.code == 200) {
                            Notification.success("操作成功");
                            this.editDialog.visible = false;
                            this.handleFilter();
                        }
                    })
                })
                .catch(() => { });

        }
    }
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//     padding-top: 10px;
//     padding-bottom: 5px;
// }

.common-form {
    &-row {
        display: flex !important;
        flex-wrap: wrap;
    }

    div .el-form-item {
        display: flex !important;

        ::v-deep {
            .el-form-item__content {
                width: 100%;

                .el-input {
                    width: 100%;
                }

                .el-input-number {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }

                .el-date-editor {
                    width: 100%;
                }
            }
        }
    }
}

.link-text-class {
    margin-left: 20px;
    color: gray;
}

::v-deep .el-form-item__error {
    top: 70%
}

.form-label-class {
    font-size: 16px;
    font-weight: 500;
    color: #f57d13;
}

.skuUrlThum {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 70%;

    ::v-deep {
        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
