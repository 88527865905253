<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
      <el-form ref="queryFormRef" size="mini" label-width="auto" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,shop"
            :dictFun="dictFun"
            fullLabel
            :clearable="false"
            :init="true"
            :init-options="this.options.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @change="dictParam = Object.assign({}, queryForm)"
          />
        </el-form-item>
        <el-form-item label="店铺">
          <DictSelect
            ref="shop"
            type="shop"
            :value.sync="queryForm.shopCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            remote
            :init-props="{ label: 'label', value: 'fullCode'}"
            @getOtherJsonParam="(callback) => callback(dictParam)"
          />
        </el-form-item>

        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport()">导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    </div>
    <el-card style="margin: 10px;">
    <el-tabs v-model="activeTab" type="card" @tab-click="() => {}">
      <el-tab-pane v-for="item in dateTabs" :label="item.label" :name="item.value" :key="item.value" />
    </el-tabs>

    <el-table
      :data="pageData.rows"
      v-loading="loading"
      size="small"
      class="customer-table"
      border
      ref="detailTable"
      highlight-current-row
    >
      <el-table-column label="分公司" prop="shop" width="120">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属配送中心" prop="dcName" width="180">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺" prop="shop" width="240">
        <template #default="{ row }">
          <span>{{ row.shopCode + "-" + row.shopName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="近90天上柜款数目标" prop="modelTarget" width="180">
        <template #default="{ row }">
          <el-input-number :disabled="!currentRow.poiCode ||currentRow.poiCode!=row.poiCode" v-model="row.modelTarget" clearable :controls="false" size="mini" :min="0" placeholder="" />
        </template>
      </el-table-column>
      <el-table-column label="终端客情" prop="shopKq" width="100">
        <template #default="{ row }">
          <el-select :disabled="!currentRow.poiCode ||currentRow.poiCode!=row.poiCode" clearable size="mini" v-model="row.shopKq">
            <el-option v-for="item in options.kqOptions" :key="item" :label="item" :value="item" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="提升方式" prop="improveMethod" width="100">
        <template #default="{ row }">
          <el-select :disabled="!currentRow.poiCode ||currentRow.poiCode!=row.poiCode" clearable size="mini" v-model="row.improveMethod">
            <el-option v-for="item in options.methodOptions" :key="item" :label="item" :value="item" />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="提升时间" prop="improveWeekDesc" width="100">
        <template #default="{ row }">
          <el-select :disabled="!currentRow.poiCode ||currentRow.poiCode!=row.poiCode" clearable size="mini" v-model="row.improveWeekDesc">
            <el-option v-for="item in options.weekOptions" :key="item" :label="item" :value="item" />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="meeboki阵地" prop="mbModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.mbModule" />
        </template>
      </el-table-column>
      <el-table-column label="书写阵地" prop="sxModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode ||currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.sxModule" />
        </template>
      </el-table-column>
      <el-table-column label="修正阵地" prop="xzModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.xzModule" />
        </template>
      </el-table-column>
      <el-table-column label="橡皮阵地" prop="xpModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.xpModule" />
        </template>
      </el-table-column>
      <el-table-column label="尺规阵地" prop="cgModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.cgModule" />
        </template>
      </el-table-column>
      <el-table-column label="优事贴阵地" prop="ystModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.ystModule" />
        </template>
      </el-table-column>
      <el-table-column label="木铅削笔阵地" prop="mxqbModule" width="140" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.mqxbModule" />
        </template>
      </el-table-column>
      <el-table-column label="活动铅笔/芯阵地" prop="hdqbModule" width="140" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.hdqbModule" />
        </template>
      </el-table-column>
      <el-table-column label="儿美阵地" prop="emModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.emModule" />
        </template>
      </el-table-column>
      <el-table-column label="本册阵地" prop="bcModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.bcModule" />
        </template>
      </el-table-column>
      <el-table-column label="桌面办公阵地" prop="zmbgModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.zmbgModule" />
        </template>
      </el-table-column>
      <el-table-column label="彩色荧光阵地" prop="csygModule" width="120" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.csygModule" />
        </template>
      </el-table-column>
      <el-table-column label="钢笔阵地" prop="gbModule" width="100" align="center">
        <template #default="{ row }">
          <el-checkbox :disabled="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" class="checked-class" v-model="row.gbModule" />
        </template>
      </el-table-column>
      <el-table-column label="终端问题/障碍" prop="shopProblem" width="160" show-overflow-tooltip />
      <el-table-column label="操作" width="140" align="center" fixed="right">
        <template #default="{ row }">
          <el-button v-if="!currentRow.poiCode || currentRow.poiCode!=row.poiCode" size="mini" type="text" style="border: 0px;" @click="() => {currentRow = deepClone(row);}">编辑</el-button>
          <el-button v-if="currentRow.poiCode==row.poiCode" size="mini" type="text" style="border: 0px;" @click="() => {currentRow = {}}">取消</el-button>
          <el-button v-if="currentRow.poiCode==row.poiCode" size="mini" type="text" style="border: 0px;" @click="handleConfirmSubmit(row)">确认</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="店铺打造目标导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <div>
            <span>仅允许导入xls、xlsx格式文件。</span>
            <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
          </div>
          <div style="color: red">本次导入将覆盖之前导入对应月份的数据</div>
          <div>
            <el-radio-group v-model="importDialog.importAction">
              <el-radio label="success" >仅成功导入</el-radio>
              <el-radio label="error"> 失败亦导入</el-radio>
            </el-radio-group>
            <div style="font-size: 15px;color: red; margin: 5px;">
              <span v-if="importDialog.importAction==='success'">数据全部校验成功后才可导入</span>
              <span v-else>数据全部校验存在错误数据后，仅导入成功数据</span>
            </div>
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="操作提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px;overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dictSelect,pageModuleTargets, importModuleTargets, branchDict, get238Options, topShopDict, updateTargets, exportTargets } from "@/api/appTask";
import Pagination from "@/components/Pagination";
import {Notification} from "element-ui";
import { deepClone, excelExport } from "@/utils";
import DictSelect from "@/components/DictSelect";

export default {
  name: "ShopTarget",
  components: { Pagination, DictSelect },
  data() {
    return {
      deepClone,
      activeTab: undefined,
      dateTabs: [],
      orgDictInitOptions: [],
      dcOptions: [],
      orgAllAuth: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      loading: false,
      dictFun: topShopDict,
      queryForm: {
        orgCode: undefined,
        dcCode: undefined,
        size: 10,
        current: 1
      },
      dictParam: {},
      exportHeaders: {
        yearMth: '年月',
        orgCode: '分公司编码',
        dcCode: '配送中心编码',
        shopCode: '店铺编码',
        modelTarget: '近90天上柜款数目标',
        shopKq: '终端客情',
        improveMethod: '提升方式',
        improveWeekDesc: '提升时间',
        mbModule: 'meeboki阵地',
        sxModule: '书写阵地',
        xzModule: '修正阵地',
        xpModule: '橡皮阵地',
        cgModule: '尺规阵地',
        ystModule: '优事贴阵地',
        mqxbModule: '木铅削笔阵地',
        hdqbModule: '活动铅笔阵地',
        emModule: '儿美阵地',
        bcModule: '本册阵地',
        zmbgModule: '桌面办公阵地',
        csygModule: '彩色荧光阵地',
        gbModule: '钢笔阵地',
        shopProblem: '终端问题/障碍',
        errorMsg: '校验信息',
      },
      currentRow: {},
      options: {
        orgOptions: [],
        kqOptions: ['好','中' ,'差'],
        methodOptions: ['单店提升','维护抄单'],
        weekOptions: ['第1周','第2周','第3周','第4周',]
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
        data: undefined
      },
      moduleFields: [ 'sxModule','xzModule','xpModule','cgModule','ystModule','mqxbModule','hdqbModule','emModule','bcModule','zmbgModule','csygModule','gbModule', 'mbModule'],
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        importAction: 'success',
        title: '店铺目标导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/module/%E9%87%8D%E7%82%B9%E7%BB%88%E7%AB%AF%E6%89%93%E9%80%A0%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV3.xlsx',
      },
    }
  },
  created() {
    if (this.dateTabs.length === 0) {
      for (let i = 1; i < 13; i++) {
        this.dateTabs.push({ value: i> 9 ? i + '': '0' + i, label: i+ '月'})
      }
    }
    const mth = new Date().getMonth() + 1;
    this.activeTab = mth> 9 ? mth + '': '0' + mth;
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.options.orgOptions = res.data.filter(e => e.value !== 'ALL');
      this.queryForm.orgCode = this.options.orgOptions[0].value;
      this.handleFilter();
    })
  },
  computed: {
    yearMth() {
      if (this.activeTab) {
        return new Date().getFullYear() + this.activeTab;
      } else {
        return null;
      }
    },
  },
  watch: {
    yearMth: {
      handler(nV, oV) {
        if (nV && this.queryForm.orgCode) {
          this.handleFilter();
        }
      }
    }
  },
  methods: {
    handleQuery() {
      this.currentRow = {};
      this.loading = true;
      this.queryForm.yearMth = this.yearMth + "";
      let query = Object.assign({}, this.queryForm);
      if (this.queryForm.shopCode) {
        const shop = this.$refs.shop.itemOptions.find(e => e.fullCode === this.queryForm.shopCode);
        query.shopCode = undefined;
        if (shop) {
          query.shopCode = shop.value;
          query.dcCode = shop.fullCode.replace("_" +query.shopCode, "");
        }
      }
      pageModuleTargets(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleImport() {
      this.importDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      fd.append("action", this.importDialog.importAction)
      this.loading = true;
      importModuleTargets(fd, this.importDialog.importAction).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (this.importDialog.importAction!=='success') {
          this.noticeDialog.data = errList;
        } else {
          this.noticeDialog.data = res.data;
        }
        if (errList.length > 0) {
          let msg = "很抱歉操作失败,共" + res.data.length+"条,失败" + errList.length+ "条,具体如下：</br>"
          errList.forEach(e => msg+= "配送中心编码:" + e.dcCode + " ,门店编码:" + e.shopCode + ": " + e.errorMsg + "</br>")
          this.noticeDialog.msg = msg;
        } else {
          let msg = "操作成功,共" + res.data.length+"条!"
          this.noticeDialog.msg = msg;
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.noticeDialog.visible = true;
        this.loading = false;
        this.$refs.upload.clearFiles();
      })
    },
    downloadImportResult() {
      if (this.noticeDialog.data.length === 0) {
        this.$message.warning("无可导出的错误数据！");
        return;
      }
      let headers = this.exportHeaders;
      let title = '238-重点终端打造导入明细';
      excelExport({
        topHeaders: headers,
        data: this.noticeDialog.data,
        title: title,
      });
    },
    handleConfirmSubmit(row) {
      let param = deepClone(row);
      this.loading = true;
      param.yearMth = this.yearMth;
      this.moduleFields.forEach(e => {
        if (param[e]) {
          param[e] = 1
        } else {
          param[e] = 0
        }
      })
      updateTargets({ list: [param] }).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.$message.success("操作成功!");
          this.currentRow = {};
          this.handleFilter();
        }
      })
    },
    handleExport() {
      this.loading = true;
      this.queryForm.yearMth = this.yearMth + "";
      const query = Object.assign({}, this.queryForm);
      exportTargets(query).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '店铺打造目标导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
  }
}
</script>

<style scoped>
::v-deep .checked-class .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1D9AEE;
}
</style>
