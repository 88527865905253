<template>
  <div>
    <div class="salesCenter-search">
      <v-signboard :title="'中类排产执行情况'"/>
      <el-form
        class="main-search"
        size="mini"
        label-width="auto"
        ref="classifyConfig-form"
        :inline="true"
        :model="form"
        @keyup.enter.native="handleClassifyConfigQuery"
      >
        <el-form-item label="产品等级" size="mini">
          <el-select clearable v-model="form.productGrade" multiple>
            <el-option
              v-for="item in options.productGradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="大类" size="mini">
          <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
            <el-option
              v-for="item in options.productMaxCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="中类" size="mini">
          <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
            <el-option
              v-for="item in options.productMidCategorieOtions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button
          class="form-btn-item"
          size="mini"
          type="primary"
          :loading="btnStatus.isSub"
          @click="handleSubmit"
        >查 询
        </el-button>
        <el-button
          class="form-btn-item"
          size="mini"
          :loading="btnStatus.isReset"
          @click="handleReset"
        >重 置
        </el-button>

        <el-button
          size="mini"
          type="primary"
          :loading="btnStatus.isExportLoading"
          @click="handleTableHeadAction('export')"
        >导 出
        </el-button>
      </el-form>
    </div>
    <div class="salesCenter-content">
      <el-table
        class="table"
        stripe
        border
        size="mini"
        ref="classifyConfigTable"
        :data="lists"
        v-loading="tableStatus.isLoading"
        @selection-change="handleselection"
      >
        <el-table-column type="selection" width="40" fixed="left"/>
        <el-table-column label="序号" align="center" width="50" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <template v-for="(item, index) in tableObj.midClassifyArrangeReportHead">
          <!-- 库存系数上限 / 库存系数下限 / 库存系数目标 -- 可编辑 -->
          <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
        </template>
      </el-table>
      <!-- 分页器 -->
      <v-pagination
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pageChange="pagingChange"
      />
    </div>
  </div>
</template>

<script>
import {paginationParams, tableLoading, urlParams, optionsObj} from '@/mixins';
import {excelExport} from "@/utils";
import {midClassifyArrangeReportHead} from '../config';
import {handleSaveClassifyConfig} from '@/api/salesCenter';
import {handleMidClassifyArrangeReportPage} from "../../../api/schedulingPlan";

export default {
  // 库存系数设定
  name: 'midClassifyArrangeReport',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    vPagination: () => import("@/components/Element/Pagination"),
  },
  data() {
    return {
      originalForm: {},                       // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 是否保存
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        midClassifyArrangeReportHead
      },
      selectRows: [],
      urlMonth: '',
      activeName: 'classifyConfig'
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isAdjust = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: ''
    };
    this.form = formPrams;
    this.originalForm = Object.assign({}, this.form);
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  methods: {
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    handleClassifyConfigQuery() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getClassifyConfigLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        prod_type: ''
      };
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          grade: form.productGrade || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          stats_category_name: form.stats_category_name || '',
          data_type: form.data_type || '',
          plan_month: this.urlMonth || '',
          operator: this.urlParams.operator,
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        };
        const {code, data, msg} = await handleMidClassifyArrangeReportPage(params);
        this.tableStatus.isLoading = false;
        if (data) {
          const {report_list, total} = data;
          this.modifyData(report_list).then(res => {
            this.lists = res;
          });
          this.total = total;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err----获取分类排产执行情况列表有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          newItems.push(item);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 列表操作
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {        // 保存
          let newItems = [];
          console.log(this.selectRows);
          this.selectRows.map(item => {
            newItems.push(item);
          });
          const params = {
            operator: this.urlParams.operator,
            plan_month: this.urlMonth,
            config_list: newItems
          };
          const {code, msg} = await handleSaveClassifyConfig(params);
          this.$refs['classifyConfigTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'export': () => {                     // 导出数据
          this.exportData();
        }
      };
      funcObj[type]();
    },
    // 导出数据
    async exportData() {
      let topHeaders = {}
      for(let i = 0; i < midClassifyArrangeReportHead.length; i++) {
        let row = midClassifyArrangeReportHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;

        const form = this.form;
        const formParams = {
          data_type: form.data_type || '',
          plan_month: this.urlMonth || '',
          operator: this.urlParams.operator,
        };

        const params = {
          page: 1,
          limit: 1000000,
          ...formParams
        };

        const {code, data} = await handleMidClassifyArrangeReportPage(params);
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {report_list} = data;
          if (report_list.length > 0) {
            excelExport({
              topHeaders,
              data: report_list,
              title: '库存系数分类设定'
            })
          }
          if (report_list.length == 0) return this.$message.warning('数据为空');
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------分类排产执行情况导出接口有误');
      }
    },
    // 监听是否使用系统默认值发生change
    handleSwitchChange(index, row) {
      this.lists.map((item, i) => {
        if (i === index) {
          if (row.isEdit) {
            row.isEdit = false;
            row.use_default = 0;
            return;
          }
          if (!row.isEdit) {
            row.isEdit = true;
            row.use_default = 1;
            return;
          }
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
