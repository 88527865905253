import { handleSaveGlobalTableConfig, handleGetGlobalTableConfig } from '@/api/config'
export default {
  props: {
    // 列是否可配置
    configurable: Boolean,
    // 业务类型
    businessType: String,
  },
  components: {
    ColumnConfig: () => import('../components/column-config/index.vue'),
  },
  data() {
    return {
      visible: false,
    }
  },
  created() {
    this.handleInitGlobalTableConfig()
  },
  methods: {
    handleInitGlobalTableConfig() {
      if (this.businessType) {
        handleGetGlobalTableConfig({ businessType: this.businessType }).then((res) => {
          if (res.data?.columnData) {
            const localColumns = this.columns
            this.$emit('update:columns', [])
            this.$nextTick(() => {
              const remoteColumns = JSON.parse(res.data.columnData)

              const sortColumns = localColumns.sort((a, b) => {
                const Aindex = remoteColumns.findIndex((item) => item.label === a.label)
                if (Aindex == -1) return 0
                const Bindex = remoteColumns.findIndex((item) => item.label === b.label)
                if (Bindex == -1) return 1
                return Aindex - Bindex
              })

              // 列宽已服务器保存的列宽为主
              sortColumns.forEach((item) => {
                const column = remoteColumns.find((col) => item.label === col.label)
                if (column) {
                  item.width = column.width || item.width
                  item.minWidth = column.minWidth || item.minWidth
                }
              })

              this.$emit('update:columns', sortColumns)
              handleSaveGlobalTableConfig({
                businessType: this.businessType,
                columnData: JSON.stringify(sortColumns)
              })
            })
          }
        })
      }
    },
    resizableChange({ $rowIndex, column, columnIndex, $columnIndex, $event }) {
      this.columns.forEach((item) => {
        if (item.prop === column.property) {
          item.width = column.renderWidth
          item.minWidth = item.minWidth || 60
        }
      })
      if (this.businessType) {
        handleSaveGlobalTableConfig({
          businessType: this.businessType,
          columnData: JSON.stringify(this.columns)
        })
      }
    },
    /**
     * 调整列宽
     */
    handleHeaderDragEnd(newWidth, oldWidth, column, event) {
      if (typeof this.$attrs['header-dragend'] === 'function') {
        this.$attrs['header-dragend'](newWidth, oldWidth, column, event)
      }
      const col = this.columns.find((item) => item.prop === column.property)
      if (Math.abs(newWidth - oldWidth) <= 5) return
      if (col) col.width = Math.ceil(newWidth)
      if (this.businessType) {
        handleSaveGlobalTableConfig({
          businessType: this.businessType,
          columnData: JSON.stringify(this.columns)
        })
      }
    },
    /**
     * 响应列改变得事件
     */
    handleColumnChange(columns) {
      if (this.businessType) {
        handleSaveGlobalTableConfig({
          businessType: this.businessType,
          columnData: JSON.stringify(columns)
        })
          .then((res) => {
            if (res.success) {
              this.$emit('update:columns', [])
              this.$nextTick(() => {
                this.$emit('update:columns', columns)
              })
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.$refs['mgTable']?.doLayout && this.$refs['mgTable']?.doLayout()
              this.$refs['mgTable']?.recalculate && this.$refs['mgTable']?.recalculate()
            }, 0)
          })
      }
    },
  },
}
