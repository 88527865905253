var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "thirdPartyPage-page" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { width: "100%", height: "100vh" },
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.iframeArrays, function (item, index) {
          return _c(
            "el-tab-pane",
            {
              key: index + "iframe",
              attrs: { label: item.name, name: item.tabName },
            },
            [
              [
                item.tabName == "1"
                  ? _c("div", [_c("plu-order-detail")], 1)
                  : _c("iframe", {
                      staticStyle: { width: "100%", height: "1800px" },
                      attrs: { src: item.iframeSrc, frameborder: "0" },
                    }),
              ],
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }