var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mainLoading,
          expression: "mainLoading",
        },
      ],
      staticStyle: { padding: "15px" },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "10px" },
              attrs: { clearable: "", size: "mini", placeholder: "请选择平台" },
              model: {
                value: _vm.queryForm.dataPlatform,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "dataPlatform", $$v)
                },
                expression: "queryForm.dataPlatform",
              },
            },
            _vm._l(_vm.platformOptions, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "10px" },
              attrs: { clearable: "", size: "mini", placeholder: "请选择状态" },
              model: {
                value: _vm.queryForm.spiderState,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "spiderState", $$v)
                },
                expression: "queryForm.spiderState",
              },
            },
            _vm._l(_vm.statusOptions, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { "margin-right": "10px" },
              attrs: {
                clearable: "",
                size: "mini",
                placeholder: "请选择店铺经营类型",
              },
              model: {
                value: _vm.queryForm.storeType,
                callback: function ($$v) {
                  _vm.$set(_vm.queryForm, "storeType", $$v)
                },
                expression: "queryForm.storeType",
              },
            },
            _vm._l(_vm.storeOptions, function (item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleQuery },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新建账号")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            "max-height": "500px",
            size: "mini",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "平台", prop: "dataPlatform" },
          }),
          _c("el-table-column", {
            attrs: { label: "经营类型", prop: "storeType" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName" },
          }),
          _c("el-table-column", { attrs: { label: "手机号", prop: "phone" } }),
          _c("el-table-column", {
            attrs: { label: "采集状态", prop: "spiderState" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.spiderState !== "等待输入验证码"
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm._f("stateFilter")(row.spiderState),
                            },
                          },
                          [_vm._v(" " + _vm._s(row.spiderState) + " ")]
                        )
                      : _c(
                          "el-tag",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              type: _vm._f("stateFilter")(row.spiderState),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSpiderValidate(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.spiderState) + " ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "账户状态", prop: "accountState" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("accountStateFilter")(row.accountState),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.accountStateMap[row.accountState]) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "执行人", prop: "executor" },
          }),
          _c("el-table-column", {
            attrs: { label: "上次执行采集时间", prop: "lastSpiderTime" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "180", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled:
                            row.spiderState === "采集中" ||
                            row.accountState === "0",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleSpider(row)
                          },
                        },
                      },
                      [_vm._v(" 执行采集 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "warning" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEditAccount(row)
                          },
                        },
                      },
                      [_vm._v("账号编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          currentPage: _vm.queryForm.current,
          pageSize: _vm.queryForm.size,
        },
        on: { pageChange: _vm.handleFilter },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle[_vm.currentTitle],
            visible: _vm.dialog.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "dialogVisible", $event)
            },
            close: function ($event) {
              return _vm.resetForm()
            },
          },
        },
        [
          _c("div", [
            _vm.currentTitle === "add"
              ? _c(
                  "ul",
                  {
                    staticStyle: {
                      "padding-left": "10px",
                      "margin-top": "0px !important",
                    },
                  },
                  [
                    _vm._v(" 说明： "),
                    _c(
                      "li",
                      { staticStyle: { "margin-bottom": "5px" } },
                      [
                        _vm._v(" 1.请确保 "),
                        _c("font", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v("[平台]"),
                        ]),
                        _vm._v(" 、 "),
                        _c("font", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v("[店铺名称]"),
                        ]),
                        _vm._v(
                          " 务必维护正确，为保证采集数据的准确性，这两个字段 "
                        ),
                        _c("font", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v("不允许修改"),
                        ]),
                        _vm._v(" ； "),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _vm._v(" 2. "),
                        _c("font", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v("阿里、京东、拼多多"),
                        ]),
                        _vm._v(" 平台新建账户需要填写 "),
                        _c("font", { staticStyle: { "font-weight": "600" } }, [
                          _vm._v("[账号]、[密码]"),
                        ]),
                        _vm._v(" ，其余平台只需要填写手机号； "),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.currentTitle === "edit"
              ? _c(
                  "ul",
                  {
                    staticStyle: {
                      "padding-left": "10px",
                      "margin-top": "0px !important",
                    },
                  },
                  [
                    _vm._v(" 说明： "),
                    _c("li", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(
                        "1.当前允许修改账户[账号]、[密码]、[手机号]，修改后立即生效"
                      ),
                    ]),
                    _c("li", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(
                        "2.账户状态[停用]表示 当前账户短期不需要采集，列表中仍可见，当日状态为[停用]"
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "3.[账户删除]，表示店铺不再运营，列表中不可见该账户（谨慎操作）"
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.dialog.form,
                rules: _vm.dialog.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "item-width",
                  attrs: { label: "选择平台", prop: "platform", size: "mini" },
                },
                [
                  _vm.currentTitle === "add"
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "72%" },
                          attrs: { placeholder: "请选择平台" },
                          model: {
                            value: _vm.dialog.form.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog.form, "platform", $$v)
                            },
                            expression: "dialog.form.platform",
                          },
                        },
                        _vm._l(_vm.platformOptions, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.currentTitle === "edit"
                    ? _c("span", [_vm._v(_vm._s(_vm.dialog.form.platform))])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "item-width",
                  attrs: { label: "店铺名称", prop: "shopName", size: "mini" },
                },
                [
                  _vm.currentTitle === "add"
                    ? _c("el-input", {
                        attrs: { placeholder: "请输入店铺名称" },
                        model: {
                          value: _vm.dialog.form.shopName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog.form, "shopName", $$v)
                          },
                          expression: "dialog.form.shopName",
                        },
                      })
                    : _vm._e(),
                  _vm.currentTitle === "edit"
                    ? _c("span", [_vm._v(_vm._s(_vm.dialog.form.shopName))])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "item-width",
                  attrs: { label: "店铺类型", prop: "storeType", size: "mini" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "72%" },
                      attrs: { clearable: "", placeholder: "请选择店铺类型" },
                      model: {
                        value: _vm.dialog.form.storeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.dialog.form, "storeType", $$v)
                        },
                        expression: "dialog.form.storeType",
                      },
                    },
                    _vm._l(_vm.storeOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "item-width",
                  attrs: { label: "账号", size: "mini" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入账号" },
                    model: {
                      value: _vm.dialog.form.accountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog.form, "accountName", $$v)
                      },
                      expression: "dialog.form.accountName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "item-width",
                  attrs: { label: "密码", size: "mini" },
                },
                [
                  _vm.currentTitle === "add"
                    ? _c("el-input", {
                        attrs: { placeholder: "请输入密码" },
                        model: {
                          value: _vm.dialog.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog.form, "password", $$v)
                          },
                          expression: "dialog.form.password",
                        },
                      })
                    : _c("el-input", {
                        attrs: {
                          placeholder:
                            _vm.currentTitle === "edit" &&
                            _vm.dialog.form.password
                              ? "*******"
                              : "请输入密码",
                        },
                        model: {
                          value: _vm.dialog.form.editPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.dialog.form, "editPassword", $$v)
                          },
                          expression: "dialog.form.editPassword",
                        },
                      }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "item-width",
                  attrs: { label: "手机号", prop: "phone", size: "mini" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.dialog.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.dialog.form, "phone", $$v)
                      },
                      expression: "dialog.form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm.currentTitle === "edit"
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "item-width",
                      attrs: { label: "状态", size: "mini" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.dialog.form.accountState,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialog.form, "accountState", $$v)
                            },
                            expression: "dialog.form.accountState",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: "1" } },
                            [
                              _c("el-tag", { attrs: { type: "success" } }, [
                                _vm._v("正常"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-radio",
                            { attrs: { label: "0" } },
                            [
                              _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v("停用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitAccount },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-popconfirm",
                {
                  attrs: { title: "请确认是否永久删除该账号" },
                  on: { confirm: _vm.handleDelAccount },
                },
                [
                  _vm.currentTitle === "edit"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { slot: "reference", type: "danger" },
                          slot: "reference",
                        },
                        [_vm._v("永久删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请输入验证码",
            visible: _vm.spiderDialog.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.spiderDialog, "dialogVisible", $event)
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
            _vm._v("平台: " + _vm._s(_vm.spiderDialog.platform)),
          ]),
          _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
            _vm._v("店铺: " + _vm._s(_vm.spiderDialog.shopName)),
          ]),
          _c("span", [
            _vm._v(
              "登录验证，请查看手机" +
                _vm._s(_vm.spiderDialog.phone) +
                "的验证码。"
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px", display: "inline-block" } },
            [
              _c("el-input", {
                staticStyle: {
                  width: "120px",
                  display: "inline",
                  "margin-right": "10px",
                },
                attrs: { size: "mini", placeholder: "请输入验证码" },
                model: {
                  value: _vm.spiderDialog.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.spiderDialog, "code", $$v)
                  },
                  expression: "spiderDialog.code",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { display: "inline" },
                  attrs: {
                    round: "",
                    disabled: _vm.showText !== "获取验证码",
                    size: "mini",
                  },
                  on: { click: _vm.getVerifyCode },
                },
                [_vm._v(" " + _vm._s(_vm.showText) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.spiderDialog.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSpiderLoginValidate },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }