var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                rules: _vm.rules,
                size: "mini",
                inline: true,
                model: _vm.form,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品包名称",
                            prop: "spName",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.form.spName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "spName", $$v)
                              },
                              expression: "form.spName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "备注",
                            prop: "remark",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "descWidth",
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "一级品类",
                            prop: "oneGradeCategoryName",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("DictSelect", {
                            ref: "one_other",
                            attrs: {
                              type: "one_other",
                              value: _vm.form.oneGradeCategoryName,
                              linkageRef: "two_other",
                              dictFun: _vm.dictFun,
                              "default-first-option": "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "oneGradeCategoryName",
                                  $event
                                )
                              },
                              changeLinkageRef: (ref, val, type) =>
                                _vm.handleChangeLinkageRef(
                                  ref,
                                  val,
                                  type,
                                  _vm.form,
                                  "formDictParam"
                                ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "二级品类",
                            prop: "twoGradeCategoryName",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("DictSelect", {
                            ref: "two_other",
                            attrs: {
                              type: "two_other",
                              value: _vm.form.twoGradeCategoryName,
                              dictFun: _vm.dictFun,
                              parentLinkageRef: "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "twoGradeCategoryName",
                                  $event
                                )
                              },
                              getOtherJsonParam: (callback) =>
                                callback(_vm.formDictParam),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联盟商品包编码",
                            "label-width": "160px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "descWidth",
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.form.lmSpCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "lmSpCode", $$v)
                              },
                              expression: "form.lmSpCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品包头图",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("OssUpload", {
                            ref: "ossUpload",
                            attrs: {
                              "value-to": _vm.form.imageUrl,
                              "list-type": "picture-card",
                              showFiles: false,
                              maxFileSize: 10240,
                              tips: "图片要求正方形",
                              tipMessageShow: false,
                              limit: 10,
                              "accept-type": _vm.imgTypes,
                            },
                            on: {
                              "update:valueTo": function ($event) {
                                return _vm.$set(_vm.form, "imageUrl", $event)
                              },
                              "update:value-to": function ($event) {
                                return _vm.$set(_vm.form, "imageUrl", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品详情图",
                            prop: "groupImageUrl",
                            "label-width": "120px",
                          },
                        },
                        [
                          _c("OssUpload", {
                            ref: "ossUpload",
                            attrs: {
                              "value-to": _vm.form.groupImageUrl,
                              "list-type": "picture-card",
                              showFiles: false,
                              maxFileSize: 10240,
                              tips: "图片要求正方形",
                              tipMessageShow: false,
                              limit: 20,
                              "accept-type": _vm.imgTypes,
                            },
                            on: {
                              "update:valueTo": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "groupImageUrl",
                                  $event
                                )
                              },
                              "update:value-to": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "groupImageUrl",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              on: {
                "tab-click": (val) => {
                  if (val.name !== "sku") {
                    this.handleSetTreeChecked(_vm.form)
                  }
                },
              },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "商品范围", name: "sku" } }),
              _c("el-tab-pane", { attrs: { label: "配送中心", name: "dc" } }),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  position: "relative",
                  "z-index": "999",
                  width: "100%",
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "5px" } },
                  [
                    _vm.tabName === "sku"
                      ? _c(
                          "el-input",
                          {
                            staticStyle: {
                              width: "120px !important",
                              "margin-right": "10px",
                            },
                            attrs: {
                              clearable: "",
                              size: "mini",
                              placeholder: "商品编码",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleQuery("skus", "skuPage")
                              },
                            },
                            model: {
                              value: _vm.querySkuKeyWord,
                              callback: function ($$v) {
                                _vm.querySkuKeyWord = $$v
                              },
                              expression: "querySkuKeyWord",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-input__icon el-icon-search",
                              staticStyle: { cursor: "pointer" },
                              attrs: { slot: "suffix" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuery("skus", "skuPage")
                                },
                              },
                              slot: "suffix",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.type !== "view" &&
                    ((_vm.form.shopCheckType === "1" &&
                      _vm.tabName === "shop") ||
                      _vm.tabName === "sku")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-upload",
                              type: "primary",
                            },
                            on: { click: _vm.handleImport },
                          },
                          [_vm._v("导入商品 ")]
                        )
                      : _vm._e(),
                    _vm.tabName === "sku" && _vm.form.spCode
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-download",
                              type: "primary",
                            },
                            on: { click: _vm.handleExport },
                          },
                          [_vm._v("导出商品 ")]
                        )
                      : _vm._e(),
                    _vm.tabName === "sku" && _vm.type !== "view"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-plus",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.drawer.visible = true
                              },
                            },
                          },
                          [_vm._v("添加商品 ")]
                        )
                      : _vm._e(),
                    _vm.type !== "view" && _vm.tabName === "sku"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-remove",
                              type: "danger",
                            },
                            on: { click: _vm.handleRemoveAll },
                          },
                          [_vm._v("全部移除 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabName === "sku",
                    expression: "tabName === 'sku'",
                  },
                ],
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "skuTableRef",
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      data: _vm.skuPage.rows,
                      height: "calc(100vh - 400px)",
                      size: "mini",
                      border: "",
                      "highlight-current-row": "",
                      "header-cell-style": {
                        background: "rgb(227 224 224)",
                        color: "black",
                      },
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "商品编码",
                        prop: "skuCode",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品型号",
                        prop: "skuModel",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "款式名称",
                        prop: "skuName",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.skuName))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品数量",
                        prop: "skuCount",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.type === "view",
                                      expression: "type==='view'",
                                    },
                                  ],
                                },
                                [_vm._v(_vm._s(scope.row.skuCount))]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.type === "edit" ||
                                        _vm.type === "add",
                                      expression:
                                        "type==='edit' || type==='add'",
                                    },
                                  ],
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      size: "mini",
                                      clearable: "",
                                      controls: false,
                                      min: 1,
                                      placeholder: "请输入数量",
                                    },
                                    model: {
                                      value: scope.row.skuCount,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "skuCount", $$v)
                                      },
                                      expression: "scope.row.skuCount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "规格", prop: "spec", width: "80" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "货号",
                        prop: "cargoNo",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "大类",
                        prop: "mainCategoryName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "中类",
                        prop: "midCategoryName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "小类",
                        prop: "subCategoryName",
                        width: "100",
                      },
                    }),
                    _vm.type !== "view"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "70",
                            align: "center",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          border: "0px",
                                          color: "red",
                                        },
                                        attrs: { type: "text", size: "small" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleRemoveRow(
                                              scope.row,
                                              1,
                                              "skus",
                                              "skuPage"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 移除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2253429640
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("Pagination", {
                  attrs: {
                    total: _vm.skuPage.total,
                    page: _vm.skuPage.current,
                    limit: _vm.skuPage.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.skuPage, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.skuPage, "size", $event)
                    },
                    pagination: function ($event) {
                      return _vm.handleQuery("skus", "skuPage")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabName === "dc",
                    expression: "tabName === 'dc'",
                  },
                ],
              },
              [
                _c("div", { staticStyle: { display: "inline-flex" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100px",
                        "font-size": "14px",
                        padding: "10px",
                        "font-weight": "500",
                      },
                    },
                    [_vm._v(" 所选区域 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "300px",
                        "overflow-y": "scroll",
                        width: "400px",
                        margin: "10px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "z-index": "99", width: "200px" },
                        attrs: {
                          placeholder: "输入关键字进行过滤",
                          size: "mini",
                          clearable: "",
                        },
                        on: { input: _vm.handleFilterChange },
                        model: {
                          value: _vm.filterTaskDcText,
                          callback: function ($$v) {
                            _vm.filterTaskDcText = $$v
                          },
                          expression: "filterTaskDcText",
                        },
                      }),
                      _vm.orgDcTree.length > 1
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-check",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheckAll(true)
                                },
                              },
                            },
                            [_vm._v("全选")]
                          )
                        : _vm._e(),
                      _vm.orgDcTree.length > 1
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-remove",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheckAll(false)
                                },
                              },
                            },
                            [_vm._v("取消全选")]
                          )
                        : _vm._e(),
                      _c("el-tree", {
                        ref: "orgDcTreeRef",
                        staticStyle: { "margin-top": "10px" },
                        attrs: {
                          data: _vm.orgDcTree,
                          "show-checkbox": "",
                          "node-key": "fullCode",
                          accordion: true,
                          "filter-node-method": _vm.filterOrgDcNode,
                          props: {
                            children: "children",
                            label: "label",
                            value: "value",
                          },
                        },
                        on: {
                          check: (node, data) =>
                            _vm.handleOrgDcChange(node, data, "dcs"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "选择商品",
            visible: _vm.drawer.visible,
            size: "70%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "visible", $event)
            },
          },
        },
        [
          _c("SkuItem", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabName === "sku",
                expression: "tabName==='sku'",
              },
            ],
            attrs: {
              "group-id": _vm.skuGroups.activeIndex,
              refresh: _vm.skuGroups.refresh,
              scope: "1",
              "show-action": true,
            },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawer.visible = false
              },
              getExceptModels: (callback) => callback([]),
              getExceptSkuCodes: (callback) =>
                callback(_vm.form.exceptSkuCodes),
            },
          }),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.downloadImportResult },
                },
                [_vm._v("下载导入结果")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }