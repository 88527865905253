var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-collapse",
    {
      model: {
        value: _vm.activeNames,
        callback: function ($$v) {
          _vm.activeNames = $$v
        },
        expression: "activeNames",
      },
    },
    [
      _c(
        "el-collapse-item",
        {
          attrs: {
            disabled: _vm.options.qdNames.length > 0,
            title: "催货配置",
            name: "1",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "50%", margin: "0 25%" } },
            [
              _c(
                "el-form",
                {
                  ref: "configForm",
                  staticClass: "common-form",
                  attrs: {
                    rules: _vm.configForm.rules,
                    size: "mini",
                    inline: true,
                    "label-width": "160px",
                    model: _vm.configForm.form,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "检查计算所需的数据源" } },
                        [
                          _c("ul", { staticClass: "custom-list" }, [
                            _vm.checkData.jdDataUpdateTime
                              ? _c("li", { staticClass: "green-li" }, [
                                  _vm._v(
                                    " 京东商智出库数据 今日更新时间 " +
                                      _vm._s(_vm.checkData.jdDataUpdateTime)
                                  ),
                                ])
                              : _c("li", { staticClass: "red-li" }, [
                                  _vm._v("京东商智出库数据 今日数据尚未获取"),
                                ]),
                            _vm.checkData.jdqtDataUpdateTime
                              ? _c("li", { staticClass: "green-li" }, [
                                  _vm._v(
                                    " 京东前台库存数据 今日更新时间 " +
                                      _vm._s(_vm.checkData.jdqtDataUpdateTime)
                                  ),
                                ])
                              : _c("li", { staticClass: "red-li" }, [
                                  _vm._v("京东前台库存数据 今日数据尚未获取"),
                                ]),
                            _vm.checkData.kjDataUpdateTime
                              ? _c("li", { staticClass: "green-li" }, [
                                  _vm._v(
                                    " 科技仓库库存数据 今日更新时间 " +
                                      _vm._s(_vm.checkData.kjDataUpdateTime)
                                  ),
                                ])
                              : _c("li", { staticClass: "red-li" }, [
                                  _vm._v("科技仓库库存数据 今日数据尚未获取"),
                                ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "目标系数是否覆盖所有商品" } },
                        [
                          _c("ul", [
                            _vm.checkData.noRatioSkuCount &&
                            _vm.checkData.noRatioSkuCount > 0
                              ? _c("li", { staticClass: "yellow-li" }, [
                                  _vm._v(
                                    " 当前有 " +
                                      _vm._s(_vm.checkData.noRatioSkuCount) +
                                      " 个商品未维护目标系数，您可以在计算环节再次配置或调整已有目标系数"
                                  ),
                                ])
                              : _c("li", { staticClass: "green-li" }, [
                                  _vm._v("成功配置所有商品的目标系数"),
                                ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "渠道", prop: "qdName" } },
                            [
                              _c(
                                "el-select",
                                {
                                  on: { change: _vm.handleCheckData },
                                  model: {
                                    value: _vm.configForm.form.qdName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.configForm.form,
                                        "qdName",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.form.qdName",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: "天猫", label: "天猫" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: "京东", label: "京东" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.configForm.form.qdName === "京东"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "京东待导入单据",
                                    "label-width": "140px",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "goodsUpload",
                                      staticClass: "upload-demo",
                                      attrs: {
                                        limit: 1,
                                        action: "#",
                                        "auto-upload": false,
                                        "file-list":
                                          _vm.configForm.file.jd.fileList,
                                        "on-change": (file, fileList) =>
                                          _vm.handleFileChange(
                                            file,
                                            "jd",
                                            fileList,
                                            "change"
                                          ),
                                        "on-remove": (file, fileList) =>
                                          _vm.handleFileChange(
                                            file,
                                            "jd",
                                            fileList,
                                            "remove"
                                          ),
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "仅允许导入xls、xlsx格式文件。"
                                            ),
                                          ]),
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                "vertical-align": "baseline",
                                              },
                                              attrs: {
                                                type: "primary",
                                                underline: false,
                                                target: "_blank",
                                                href: _vm.configForm.file.jd
                                                  .template,
                                              },
                                            },
                                            [_vm._v("下载模板")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库", prop: "ckNames" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { multiple: "" },
                              on: { change: _vm.handleCheckData },
                              model: {
                                value: _vm.configForm.form.ckNames,
                                callback: function ($$v) {
                                  _vm.$set(_vm.configForm.form, "ckNames", $$v)
                                },
                                expression: "configForm.form.ckNames",
                              },
                            },
                            _vm._l(
                              _vm.options.ckNames.filter(
                                (e) =>
                                  _vm.configForm.form.qdName == "天猫" ||
                                  e !== "华中集库"
                              ),
                              function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "预计销量", prop: "expectNum" } },
                        [
                          _vm._v("近6月月均销量"),
                          _c("el-input-number", {
                            staticStyle: { width: "50px" },
                            attrs: { min: 0, controls: false, step: 0 },
                            model: {
                              value: _vm.configForm.form.expectNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.configForm.form, "expectNum", $$v)
                              },
                              expression: "configForm.form.expectNum",
                            },
                          }),
                          _vm._v("倍"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "覆盖目标系数" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "goodsUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                action: "#",
                                "auto-upload": false,
                                "file-list": _vm.configForm.file.ratio.fileList,
                                "on-change": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "ratio",
                                    fileList,
                                    "change"
                                  ),
                                "on-remove": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "ratio",
                                    fileList,
                                    "remove"
                                  ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _c("span", [
                                    _vm._v("仅允许导入xls、xlsx格式文件。"),
                                  ]),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "vertical-align": "baseline",
                                      },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleExportCurrent(
                                            "ratio"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下载当前系数模板")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "重点品催单" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "goodsUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                action: "#",
                                "auto-upload": false,
                                "file-list":
                                  _vm.configForm.file.require.fileList,
                                "on-change": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "require",
                                    fileList,
                                    "change"
                                  ),
                                "on-remove": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "require",
                                    fileList,
                                    "remove"
                                  ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _c("span", [
                                    _vm._v("仅允许导入xls、xlsx格式文件。"),
                                  ]),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "vertical-align": "baseline",
                                      },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleExportCurrent(
                                            "require"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下载当前数据模板")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "新品催单" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "goodsUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                action: "#",
                                "auto-upload": false,
                                "file-list": _vm.configForm.file.news.fileList,
                                "on-change": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "news",
                                    fileList,
                                    "change"
                                  ),
                                "on-remove": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "news",
                                    fileList,
                                    "remove"
                                  ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _c("span", [
                                    _vm._v("仅允许导入xls、xlsx格式文件。"),
                                  ]),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "vertical-align": "baseline",
                                      },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleExportCurrent("news")
                                        },
                                      },
                                    },
                                    [_vm._v("下载当前数据模板")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "人工月均销量" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "goodsUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                action: "#",
                                "auto-upload": false,
                                "file-list": _vm.configForm.file.sale.fileList,
                                "on-change": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "sale",
                                    fileList,
                                    "change"
                                  ),
                                "on-remove": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "sale",
                                    fileList,
                                    "remove"
                                  ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _c("span", [
                                    _vm._v("仅允许导入xls、xlsx格式文件。"),
                                  ]),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "vertical-align": "baseline",
                                      },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleExportCurrent("sale")
                                        },
                                      },
                                    },
                                    [_vm._v("下载当前数据模板")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "导入催货标签" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "goodsUpload",
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                action: "#",
                                "auto-upload": false,
                                "file-list": _vm.configForm.file.label.fileList,
                                "on-change": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "label",
                                    fileList,
                                    "change"
                                  ),
                                "on-remove": (file, fileList) =>
                                  _vm.handleFileChange(
                                    file,
                                    "label",
                                    fileList,
                                    "remove"
                                  ),
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _c("span", [
                                    _vm._v("仅允许导入xls、xlsx格式文件。"),
                                  ]),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "vertical-align": "baseline",
                                      },
                                      attrs: {
                                        type: "primary",
                                        underline: false,
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleExportCurrent(
                                            "label"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下载当前催货标签模板")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleImport },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-collapse-item",
        {
          attrs: {
            disabled: _vm.options.qdNames.length == 0,
            title:
              "计算催货数据系统计算催货数据，品类管理部订单员可以根据结果数据进行批注及调整",
            name: "2",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0px 20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "mg-search",
                  staticStyle: { "padding-bottom": "15px" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryFormRef",
                      attrs: {
                        size: "mini",
                        "label-width": "auto",
                        inline: true,
                        model: _vm.queryForm,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.options.qdNames.length > 1,
                              expression: "options.qdNames.length > 1",
                            },
                          ],
                          attrs: { label: "渠道" },
                        },
                        [
                          _c("DictSelect", {
                            attrs: {
                              value: _vm.queryForm.qdName,
                              singleProps: "",
                              init: "",
                              defaultIndex: 0,
                              "init-options": _vm.options.qdNames,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.queryForm, "qdName", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.queryForm.qdName === "京东"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "电商平台编码" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.queryForm.jdSkuCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "jdSkuCode", $$v)
                                  },
                                  expression: "queryForm.jdSkuCode",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "DRP编码" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.queryForm.skuCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "skuCode", $$v)
                              },
                              expression: "queryForm.skuCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "SKU货号" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.queryForm.ecSkuCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "ecSkuCode", $$v)
                              },
                              expression: "queryForm.ecSkuCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "料号" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.queryForm.materialCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "materialCode", $$v)
                              },
                              expression: "queryForm.materialCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电商等级" } },
                        [
                          _c("DictSelect", {
                            ref: "md-type",
                            attrs: {
                              value: _vm.queryForm.jdLevel,
                              singleProps: "",
                              init: "",
                              "init-options": _vm.options.jdLevels,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "jdLevel",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "科技等级" } },
                        [
                          _c("DictSelect", {
                            ref: "shop-type",
                            attrs: {
                              value: _vm.queryForm.ecLevel,
                              singleProps: "",
                              init: "",
                              "init-options": _vm.options.ecLevels,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "ecLevel",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "总部等级" } },
                        [
                          _c("DictSelect", {
                            ref: "md-type",
                            attrs: {
                              value: _vm.queryForm.hqLevel,
                              singleProps: "",
                              init: "",
                              "init-options": _vm.options.hqLevels,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "hqLevel",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "赛道" } },
                        [
                          _c("DictSelect", {
                            ref: "md-type",
                            attrs: {
                              value: _vm.queryForm.raceName,
                              singleProps: "",
                              init: "",
                              "init-options": _vm.options.races,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "raceName",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "大类" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              on: {
                                change: (val) =>
                                  _vm.handleChangeDict("mid", val),
                              },
                              model: {
                                value: _vm.queryForm.mainCategoryName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "mainCategoryName",
                                    $$v
                                  )
                                },
                                expression: "queryForm.mainCategoryName",
                              },
                            },
                            _vm._l(_vm.options.main, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "中类" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              on: {
                                change: (val) =>
                                  _vm.handleChangeDict("sub", val),
                              },
                              model: {
                                value: _vm.queryForm.midCategoryName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "midCategoryName",
                                    $$v
                                  )
                                },
                                expression: "queryForm.midCategoryName",
                              },
                            },
                            _vm._l(_vm.options.mid, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "小类" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.subCategoryName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "subCategoryName",
                                    $$v
                                  )
                                },
                                expression: "queryForm.subCategoryName",
                              },
                            },
                            _vm._l(_vm.options.sub, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否混色" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.ifMixtz,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "ifMixtz", $$v)
                                },
                                expression: "queryForm.ifMixtz",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: "0" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "重点品" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.ifRequire,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "ifRequire", $$v)
                                },
                                expression: "queryForm.ifRequire",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "是" },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: "否" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "催货标记" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.hastenLabel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "hastenLabel", $$v)
                                },
                                expression: "queryForm.hastenLabel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "华北不催", value: "华北不催" },
                              }),
                              _c("el-option", {
                                attrs: { label: "华南不催", value: "华南不催" },
                              }),
                              _c("el-option", {
                                attrs: { label: "华东不催", value: "华东不催" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "过滤无需催货" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.filterChTag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "filterChTag", $$v)
                                },
                                expression: "queryForm.filterChTag",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "是" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否新品" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.queryForm.isNewProduct,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "isNewProduct", $$v)
                                },
                                expression: "queryForm.isNewProduct",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: "是" },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: "否" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.handleFilter },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-refresh",
                                type: "primary",
                              },
                              on: { click: _vm.resetQuery },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.handleFilterColumns },
                            },
                            [_vm._v("列编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-edit",
                                type: "primary",
                              },
                              on: { click: _vm.handleBatchDialog },
                            },
                            [_vm._v("批量调整")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-download",
                                type: "primary",
                              },
                              on: { click: _vm.handleExport },
                            },
                            [_vm._v("导出")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-download",
                                type: "primary",
                              },
                              on: { click: _vm.handleCompleteExport },
                            },
                            [_vm._v("确认完成计算")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-refresh",
                                type: "primary",
                              },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重新开始")]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [
                              _vm._v("当前正在进行 "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.options.qdNames.join("、"))),
                              ]),
                              _vm._v(" 渠道，"),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.options.currentCkNames.join("、"))
                                ),
                              ]),
                              _vm._v("的催货操作，月均系数为 "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.currentInfo.expectNum)),
                              ]),
                              _vm._v(" 倍，任务启动时间为 "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.currentInfo.latestTime)),
                              ]),
                              _vm._v(" 分"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tableRef",
                  attrs: {
                    data: _vm.pageData.rows,
                    height: "calc(100vh - 250px)",
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(235 235 235)",
                      color: "black",
                      textAlign: "center",
                      verticalAlign: "middle",
                    },
                    align: "center",
                  },
                  on: {
                    "sort-change": _vm.handleSortChange,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "90" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "电商平台编码",
                      prop: "jdSkuCode",
                      width: "100",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "DRP编码",
                      prop: "skuCode",
                      width: "100",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "料号",
                      prop: "materialCode",
                      width: "100",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "SKU货号",
                      prop: "ecSkuCode",
                      width: "100",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      prop: "skuName",
                      width: "180",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "新品",
                      prop: "isNewProduct",
                      width: "100",
                      align: "center",
                    },
                  }),
                  _vm._l(_vm.hastenDynamicColumns, function (item) {
                    return [
                      item.show ||
                      _vm.checkedColumns.indexOf(item.filterLabel) > -1 ||
                      _vm.checkedColumns.indexOf(item.label) > -1
                        ? _c("el-table-column", {
                            key: item.prop,
                            attrs: {
                              prop: item.prop,
                              label: item.label,
                              width: item.width ? item.width : "100px",
                              "show-overflow-tooltip": "",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(row[item.prop])),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ]
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "近6月合计月均",
                      prop: "tradeGoodsCountAvgTotal",
                      width: "100",
                      align: "center",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "provinceName",
                        width: "160",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v(_vm._s(row.kcTotal))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(row.kfTotal) +
                                    " + " +
                                    _vm._s(row.ztTotal) +
                                    " + " +
                                    _vm._s(row.prepareLoadTotal) +
                                    " + " +
                                    _vm._s(
                                      row.prepareTransferOutJk +
                                        row.prepareTransferOutHn +
                                        row.prepareTransferOutHb
                                    ) +
                                    ")"
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(row.kjRatio ? row.kjRatio : 0)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _vm._v(" 科技库存"),
                        _c("br"),
                        _vm._v("(可发+在途+待导入+待调拨)"),
                        _c("br"),
                        _vm._v("目标系数 "),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "provinceName",
                        width: "160",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v(_vm._s(row.kcJk))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(row.kfJk) +
                                    " + " +
                                    _vm._s(row.ztJk) +
                                    " + " +
                                    _vm._s(row.prepareLoadJk) +
                                    " + " +
                                    _vm._s(row.prepareTransferOutJk) +
                                    ")"
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(row.hdRatio ? row.hdRatio : 0)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticStyle: { "background-color": "#e67488" } },
                          [
                            _vm._v("华东库存"),
                            _c("br"),
                            _vm._v("(可发+在途+待导入+待调拨)"),
                            _c("br"),
                            _vm._v("目标系数"),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "provinceName",
                        width: "160",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v(_vm._s(row.kcHn))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(row.kfHn) +
                                    " + " +
                                    _vm._s(row.ztHn) +
                                    " + " +
                                    _vm._s(row.prepareLoadHn) +
                                    " + " +
                                    _vm._s(row.prepareTransferOutHn) +
                                    ")"
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(row.hnRatio ? row.hnRatio : 0)),
                              ]),
                              _c("br"),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticStyle: { "background-color": "#e67488" } },
                          [
                            _vm._v("华南库存"),
                            _c("br"),
                            _vm._v("(可发+在途+待导入+待调拨)"),
                            _c("br"),
                            _vm._v("目标系数"),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "provinceName",
                        width: "160",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v(_vm._s(row.kcHb))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(row.kfHb) +
                                    " + " +
                                    _vm._s(row.ztHb) +
                                    " + " +
                                    _vm._s(row.prepareLoadHb) +
                                    " + " +
                                    _vm._s(row.prepareTransferOutHb) +
                                    ")"
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(row.hbRatio ? row.hbRatio : 0)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticStyle: { "background-color": "#e67488" } },
                          [
                            _vm._v("华北库存"),
                            _c("br"),
                            _vm._v("(可发+在途+待导入+待调拨)"),
                            _c("br"),
                            _vm._v("目标系数"),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "provinceName",
                        width: "160",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v(_vm._s(row.kcHz))]),
                              _c("br"),
                              _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(row.kfHz) +
                                    " + " +
                                    _vm._s(row.ztHz) +
                                    " + " +
                                    _vm._s(row.prepareLoadHz) +
                                    " + " +
                                    _vm._s(row.prepareTransferOutHz) +
                                    ")"
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(row.hzRatio ? row.hzRatio : 0)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticStyle: { "background-color": "#e67488" } },
                          [
                            _vm._v("华中库存"),
                            _c("br"),
                            _vm._v("(可发+在途+待导入+待调拨)"),
                            _c("br"),
                            _vm._v("目标系数"),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "hastenCountJk1",
                        width: "140",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.tags.indexOf("华东不催") > -1
                                ? _c("span", [
                                    _vm._v(_vm._s(row.hastenCountJk1)),
                                  ])
                                : _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      disabled:
                                        _vm.options.currentCkNames.indexOf(
                                          "华东集库"
                                        ) < 0,
                                      size: "mini",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleChangeRow(row)
                                      },
                                    },
                                    model: {
                                      value: row.hastenCountJk1,
                                      callback: function ($$v) {
                                        _vm.$set(row, "hastenCountJk1", $$v)
                                      },
                                      expression: "row.hastenCountJk1",
                                    },
                                  }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            style:
                              _vm.options.currentCkNames.indexOf("华东集库") < 0
                                ? { "background-color": "gray" }
                                : {},
                          },
                          [
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("华东催货箱数"),
                            _c("br"),
                            _vm._v(" "),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "hastenCountJk1",
                        width: "140",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.tags.indexOf("华南不催") > -1
                                ? _c("span", [
                                    _vm._v(_vm._s(row.hastenCountHn1)),
                                  ])
                                : _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      disabled:
                                        _vm.options.currentCkNames.indexOf(
                                          "华南集库"
                                        ) < 0,
                                      size: "mini",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleChangeRow(row)
                                      },
                                    },
                                    model: {
                                      value: row.hastenCountHn1,
                                      callback: function ($$v) {
                                        _vm.$set(row, "hastenCountHn1", $$v)
                                      },
                                      expression: "row.hastenCountHn1",
                                    },
                                  }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            style:
                              _vm.options.currentCkNames.indexOf("华南集库") < 0
                                ? { "background-color": "gray" }
                                : {},
                          },
                          [
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("华南催货箱数"),
                            _c("br"),
                            _vm._v(" "),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "hastenCountJk1",
                        width: "140",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.tags.indexOf("华北不催") > -1
                                ? _c("span", [
                                    _vm._v(_vm._s(row.hastenCountHb1)),
                                  ])
                                : _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      disabled:
                                        _vm.options.currentCkNames.indexOf(
                                          "华北集库"
                                        ) < 0,
                                      size: "mini",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleChangeRow(row)
                                      },
                                    },
                                    model: {
                                      value: row.hastenCountHb1,
                                      callback: function ($$v) {
                                        _vm.$set(row, "hastenCountHb1", $$v)
                                      },
                                      expression: "row.hastenCountHb1",
                                    },
                                  }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            style:
                              _vm.options.currentCkNames.indexOf("华北集库") < 0
                                ? { "background-color": "gray" }
                                : {},
                          },
                          [
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("华北催货箱数"),
                            _c("br"),
                            _vm._v(" "),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "hastenCountJk1",
                        width: "140",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.tags.indexOf("华中不催") > -1
                                ? _c("span", [
                                    _vm._v(_vm._s(row.hastenCountHz1)),
                                  ])
                                : _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      controls: false,
                                      min: 0,
                                      disabled:
                                        _vm.options.currentCkNames.indexOf(
                                          "华中集库"
                                        ) < 0,
                                      size: "mini",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleChangeRow(row)
                                      },
                                    },
                                    model: {
                                      value: row.hastenCountHz1,
                                      callback: function ($$v) {
                                        _vm.$set(row, "hastenCountHz1", $$v)
                                      },
                                      expression: "row.hastenCountHz1",
                                    },
                                  }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            style:
                              _vm.options.currentCkNames.indexOf("华中集库") < 0
                                ? { "background-color": "gray" }
                                : {},
                          },
                          [
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("华中催货箱数"),
                            _c("br"),
                            _vm._v(" "),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: "催货打标",
                      prop: "hastenLabel",
                      width: "120",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: { multiple: "", size: "mini" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChangeRow(row)
                                  },
                                },
                                model: {
                                  value: row.tags,
                                  callback: function ($$v) {
                                    _vm.$set(row, "tags", $$v)
                                  },
                                  expression: "row.tags",
                                },
                              },
                              _vm._l(
                                _vm.options.tags.filter(
                                  (e) =>
                                    _vm.queryForm.qdName == "天猫" ||
                                    e.value !== "华中不催"
                                ),
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                      disabled: item.disabled,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "备注",
                      prop: "remark",
                      width: "180",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              model: {
                                value: row.remark,
                                callback: function ($$v) {
                                  _vm.$set(row, "remark", $$v)
                                },
                                expression: "row.remark",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.pageData.total,
                  page: _vm.queryForm.current,
                  limit: _vm.queryForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryForm, "size", $event)
                  },
                  pagination: _vm.handleQuery,
                },
              }),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "操作提示",
                    visible: _vm.noticeDialog.visible,
                    "append-to-body": "",
                    width: "700px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.noticeDialog, "visible", $event)
                    },
                  },
                },
                [
                  _c("div", {
                    staticStyle: {
                      "font-size": "16px",
                      "overflow-y": "scroll",
                      height: "400px",
                    },
                    domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "right" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _vm.noticeDialog.data && _vm.noticeDialog.data.length > 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.downloadImportResult },
                            },
                            [_vm._v("下载导入结果")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.noticeDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "列编辑",
                    visible: _vm.dialog.filterColumnVisible,
                    width: "700px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.dialog, "filterColumnVisible", $event)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "25%",
                        position: "relative",
                        float: "right",
                        right: "60%",
                        top: "-50px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCheckAll(false)
                            },
                          },
                        },
                        [_vm._v("清空")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCheckAll(true)
                            },
                          },
                        },
                        [_vm._v("全选")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px", color: "gray" } },
                    [
                      _c("span", [
                        _vm._v("说明：页面表头指标 展示顺序同指标添加先后顺序"),
                      ]),
                    ]
                  ),
                  _vm._l(
                    _vm.checkedCopy.hastenFilterColumns,
                    function (item, key, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                "font-weight": "600",
                              },
                            },
                            [_vm._v(_vm._s(item.label))]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              attrs: { indeterminate: item.isIndeterminate },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckAllChange(
                                    $event,
                                    key,
                                    item.label
                                  )
                                },
                              },
                              model: {
                                value: item.checkAll,
                                callback: function ($$v) {
                                  _vm.$set(item, "checkAll", $$v)
                                },
                                expression: "item.checkAll",
                              },
                            },
                            [_vm._v(" 全选 ")]
                          ),
                          _c("div", { staticStyle: { margin: "10px 0" } }),
                          _c(
                            "el-checkbox-group",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckedChange(
                                    $event,
                                    key,
                                    item.label
                                  )
                                },
                              },
                              model: {
                                value: item.checked,
                                callback: function ($$v) {
                                  _vm.$set(item, "checked", $$v)
                                },
                                expression: "item.checked",
                              },
                            },
                            _vm._l(item.list, function (option) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: option,
                                  style:
                                    item.label === "库存数据"
                                      ? "width: 40% "
                                      : "width: 20% ",
                                  attrs: { label: option },
                                },
                                [_vm._v(_vm._s(option))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.dialog.filterColumnVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.handleConfirmChecked },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "批量调整",
                    visible: _vm.editDialog.visible,
                    width: "700px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.editDialog, "visible", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      staticClass: "common-form",
                      attrs: {
                        size: "mini",
                        inline: true,
                        model: _vm.editDialog.form,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-row", [_vm._v("催货目标系数调整")]),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "华东" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: { max: 1, min: 0, controls: false },
                                    model: {
                                      value: _vm.editDialog.form.hdRatio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editDialog.form,
                                          "hdRatio",
                                          $$v
                                        )
                                      },
                                      expression: "editDialog.form.hdRatio",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "华南" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: { max: 1, min: 0, controls: false },
                                    model: {
                                      value: _vm.editDialog.form.hnRatio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editDialog.form,
                                          "hnRatio",
                                          $$v
                                        )
                                      },
                                      expression: "editDialog.form.hnRatio",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "华北" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: { max: 1, min: 0, controls: false },
                                    model: {
                                      value: _vm.editDialog.form.hbRatio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editDialog.form,
                                          "hbRatio",
                                          $$v
                                        )
                                      },
                                      expression: "editDialog.form.hbRatio",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "华中" } },
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "100px" },
                                    attrs: { max: 1, min: 0, controls: false },
                                    model: {
                                      value: _vm.editDialog.form.hzRatio,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editDialog.form,
                                          "hzRatio",
                                          $$v
                                        )
                                      },
                                      expression: "editDialog.form.hzRatio",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-row", [_vm._v("催货箱数调整")]),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "华东" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          controls: false,
                                          step: 0,
                                          disabled:
                                            _vm.options.currentCkNames.indexOf(
                                              "华东集库"
                                            ) < 0,
                                        },
                                        model: {
                                          value:
                                            _vm.editDialog.form.hastenBoxHd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "hastenBoxHd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editDialog.form.hastenBoxHd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "华南" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          controls: false,
                                          step: 0,
                                          disabled:
                                            _vm.options.currentCkNames.indexOf(
                                              "华南集库"
                                            ) < 0,
                                        },
                                        model: {
                                          value:
                                            _vm.editDialog.form.hastenBoxHn,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "hastenBoxHn",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editDialog.form.hastenBoxHn",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "华北" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          controls: false,
                                          step: 0,
                                          disabled:
                                            _vm.options.currentCkNames.indexOf(
                                              "华北集库"
                                            ) < 0,
                                        },
                                        model: {
                                          value:
                                            _vm.editDialog.form.hastenBoxHb,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "hastenBoxHb",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editDialog.form.hastenBoxHb",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "华中" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          controls: false,
                                          step: 0,
                                          disabled:
                                            _vm.options.currentCkNames.indexOf(
                                              "华中集库"
                                            ) < 0,
                                        },
                                        model: {
                                          value:
                                            _vm.editDialog.form.hastenBoxHz,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog.form,
                                              "hastenBoxHz",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editDialog.form.hastenBoxHz",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-row", [_vm._v("标记及备注调整")]),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "催单打标" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: { clearable: "", multiple: "" },
                                      model: {
                                        value: _vm.editDialog.form.tags,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editDialog.form,
                                            "tags",
                                            $$v
                                          )
                                        },
                                        expression: "editDialog.form.tags",
                                      },
                                    },
                                    _vm._l(
                                      _vm.options.tags.filter(
                                        (e) =>
                                          _vm.editDialog.form.qdName ==
                                            "天猫" || e.value !== "华中不催"
                                      ),
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                            disabled: item.disabled,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注" } },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.editDialog.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editDialog.form,
                                          "remark",
                                          $$v
                                        )
                                      },
                                      expression: "editDialog.form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleBathUpdate },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }