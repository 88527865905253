<template>
  <div style="padding: 20px">
    <div class="mg-search">
      <el-form size="mini" label-width="auto" ref="searchRef" :inline="true" :model="queryForm"
               @keyup.enter.native="handleQuery">
        <el-form-item label="类型">
          <el-select v-model="queryForm.type" @change="handleFilter">
            <el-option label="箱数" value="Count" />
            <el-option label="金额" value="Total" />
          </el-select>
        </el-form-item>
        <el-form-item label="大类">
          <DictSelect ref="main" type="main" :dictFun="dictFun" :value.sync="queryForm.mainCategoryCode" linkageRef="mid" multiple collapse-tags clearable @getOtherJsonParam="(callback) => callback(dictParam)" @changeLinkageRef="handleChangeLinkageRef" />
        </el-form-item>
        <el-form-item label="中类">
          <DictSelect ref="mid" type="mid" :dictFun="dictFun" :value.sync="queryForm.midCategoryCode" linkageRef="sub" multiple collapse-tags parentLinkageRef clearable @getOtherJsonParam="(callback) => callback(dictParam)" @changeLinkageRef="handleChangeLinkageRef" />
        </el-form-item>
        <el-form-item label="小类">
          <DictSelect ref="sub" type="sub" :dictFun="dictFun" :value.sync="queryForm.subCategoryCode" multiple collapse-tags @getOtherJsonParam="(callback) => callback(dictParam)" parentLinkageRefclearable />
        </el-form-item>
        <el-form-item label="系列">
          <DictSelect ref="series" type="series" :dictFun="dictFun" singleProps :value.sync="queryForm.series" multiple collapse-tags clearable />
        </el-form-item>
        <el-form-item label="赛道">
          <DictSelect ref="race" type="race" :dictFun="dictFun" :value.sync="queryForm.raceCode" multiple collapse-tags clearable />
        </el-form-item>
        <el-form-item label="料号">
          <el-input v-model="queryForm.materialCode" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="型号">
          <el-input v-model="queryForm.skuModel" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="品名">
          <el-input v-model="queryForm.skuName" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="级别">
          <DictSelect ref="grade" type="grade" :dictFun="dictFun" singleProps :value.sync="queryForm.grade" multiple collapse-tags clearable />
        </el-form-item>
        <el-form-item label="负责人">
          <DictSelect ref="user" type="user" :dictFun="dictFun" :value.sync="queryForm.responsible" multiple collapse-tags fullLabel clearable />
        </el-form-item>
        <el-form-item label="本次下单需求">
          <el-select v-model="queryForm.ordered" clearable>
            <el-option label="有值" value="1" />
            <el-option label="无值" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否238">
          <el-select v-model="queryForm.is238" clearable>
            <el-option label="是" :value="true" />
            <el-option label="否" :value="false" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="handleFilter">查询</el-button>
          <el-button size="mini" type="primary" @click="handleDownload" title="下载当前查询的商品箱数或金额相关字段数据">下载</el-button>
          <el-button size="mini" type="primary" @click="handleExport" title="导出当前查询的商品下单数量、下单金额和消化时间">导出</el-button>
          <el-button size="mini" type="primary" @click="handleImport">导入</el-button>
          <span style="margin-left: 10px">单位： {{  currentQueryType ==='Count'?'箱':'万元' }}</span>
        </el-form-item>
      </el-form>
    </div>
    <div style="padding-top: 15px">
      <el-table
        ref="table"
        :data="pageData.rows"
        height="calc(100vh - 220px)"
        v-loading="loading"
        size="mini"
        border
        highlight-current-row
        header-align="center"
        :header-cell-style="{ background: '#F0F0F2', color: 'black', 'text-align': 'center' }"
        @sort-change="handleSortChange"
      >
        <el-table-column type="index" width="40" fixed="left" />
<!--        <el-table-column label="负责人" prop="reposible" />-->
        <el-table-column label="型号" prop="skuModel" fixed="left" show-overflow-tooltip align="center" sortable/>
        <el-table-column label="料号" prop="materialCode" width="120" show-overflow-tooltip fixed="left" align="center" sortable/>
        <el-table-column label="品名" prop="skuName" width="220" fixed="left" show-overflow-tooltip align="left" sortable/>
        <el-table-column label="级别" prop="grade" width="60" fixed="left" align="center" show-overflow-tooltip  sortable/>
        <el-table-column label="去年同期月均" prop="tqAvgMthAmount" width="120" align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <span :title="row.tqAvgMthAmount"> {{ fidData(row.tqAvgMthAmount,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="近半年销售情况" prop="a" align="center" show-overflow-tooltip >
          <el-table-column label="月均" prop="avgMthAmount" align="center" sortable show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.avgMthAmount"> {{ fidData(row.avgMthAmount,2) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="recentFieldMap['recent1']" prop="recent1" align="center" sortable width="100" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.recent1"> {{ fidData(row.recent1,2) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="recentFieldMap.recent2" prop="recent2" align="center" sortable width="100" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.recent2"> {{ fidData(row.recent2,2) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="recentFieldMap.recent3" prop="recent3" align="center" sortable width="100" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.recent3"> {{ fidData(row.recent3,2) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="recentFieldMap.recent4" prop="recent4" align="center" sortable width="80" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.recent4"> {{ fidData(row.recent4,2) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="recentFieldMap.recent5" prop="recent5" align="center" sortable width="80" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.recent5"> {{ fidData(row.recent5,2) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="recentFieldMap.recent6" prop="recent6" align="center" sortable width="80" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span :title="row.recent6"> {{ fidData(row.recent6,2) }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="当月销售" prop="byXs" align="center" width="120" show-overflow-tooltip sortable>
          <template slot-scope="{ row }">
            <span :title="row.byXs"> {{ fidData(row.byXs,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="总部当前库存" prop="qmKc" align="center" width="120" show-overflow-tooltip sortable>
          <template slot-scope="{ row }">
            <span :title="row.qmKc"> {{ fidData(row.qmKc,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="库存系数" prop="stockRatio" align="center" show-overflow-tooltip width="90">
          <template slot-scope="{ row }">
            <span :title="row.stockRatio"> {{ fidData(row.stockRatio,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="采购未交" prop="cgwj" align="center" show-overflow-tooltip sortable width="90">
          <template slot-scope="{ row }">
            <span :title="row.cgwj"> {{ fidData(row.cgwj,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="欠量未发" prop="allQl" align="center" show-overflow-tooltip sortable width="90">
          <template slot-scope="{ row }">
            <span :title="row.allQl"> {{ fidData(row.allQl,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="采购未转单" prop="cgwzd" align="center" show-overflow-tooltip sortable width="100">
          <template slot-scope="{ row }">
            <span :title="row.cgwzd"> {{ fidData(row.cgwzd,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="currentQueryType ==='Count'?'下单箱数':'下单金额'" width="140" prop="orderNeed" align="center">
          <template slot-scope="{ row }">
            <el-input-number
              v-model="row.orderNeed"
              controls-position="right"
              placeholder="请输入内容"
              style="width: 100%"
              size="mini"
              :min="0"
              @change="val => handleSave(row, val, 'orderNeed')"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="剩余备货库存" prop="lefBackStock" width="120" show-overflow-tooltip align="center">
          <template slot-scope="{ row }">
            <span :title="row.lefBackStock"> {{ fidData(row.lefBackStock,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备货库存系数" prop="stockBackRatio" width="120" show-overflow-tooltip align="center">
          <template slot-scope="{ row }">
            <span :title="row.stockBackRatio"> {{ fidData(row.stockBackRatio,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="计划消化时间" prop="expectUsedDate" width="120" align="center">
          <template slot-scope="{ row }">
            <el-date-picker
              size="mini"
              v-model="row.expectUsedDate"
              type="date"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              default-time="23:59:59"
              style="width: 100px"
              @change="val => handleSave(row, val, 'expectUsedDate')"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="赛道" prop="raceName" show-overflow-tooltip sortable/>
        <el-table-column label="大类" prop="mainCategoryName" show-overflow-tooltip sortable/>
        <el-table-column label="中类" prop="midCategoryName" show-overflow-tooltip sortable/>
        <el-table-column label="小类" prop="subCategoryName" show-overflow-tooltip sortable/>
        <el-table-column label="系列" prop="goodsSeriesName" show-overflow-tooltip sortable/>
        <el-table-column label="是否238" prop="is238" show-overflow-tooltip sortable/>
        <el-table-column label="零售价" prop="price" show-overflow-tooltip sortable/>
        <el-table-column label="箱规" prop="bottomSpec" show-overflow-tooltip sortable/>
      </el-table>
      <Pagination :total="pageData.total" :pageSizes="[10, 20, 30, 50, 100, 200, 500]" :page.sync="queryForm.current" :limit.sync="queryForm.size"
                  @pagination="handleQuery"/>
    </div>

    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
                  @handleImportCallBack="handleImportData" append-to-body/>
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
import {deepClone, parseTime, excelExport} from "@/utils";
import {Message, Notification} from "element-ui";
import {pageStockUpData, getSixMthFields, getDictData, saveStockUp, exportData, importData} from "@/api/sales";
import DictSelect from "@/components/DictSelect";

export default {
  name: "StockUp",
  components: { Pagination, DictSelect, ImportDialog: () => import("@/components/ImportDialog") },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      dictFun: getDictData,
      dictParam: {},
      queryForm: {
        current: 1,
        size: 10,
        type: 'Count',
        materialCode: undefined,
        skuModel: undefined,
        grade: [],
        ordered: undefined,
        responsible: [],
        skuName: undefined,
        mainCategoryCode: [],
        middleCategoryCode: [],
        subCategoryCode: [],
        series: [],
        raceCode: [],
        is238: undefined,
        order: {
          column: undefined,
          operator: undefined
        }
      },
      loading: false,
      currentQueryType: undefined,
      recentFieldMap: {},
      importDialog: {
        currentImportType: "import",
        importBaseParams: [
          {
            visible: false,
            title: "备货申请下单数据导入",
            type: "import",
            tipsText: "必需字段: 料号",
            templateSampleHead: [{label: "料号", prop: "materialCode", minWidth: "80px"},
              {label: "下单金额", prop: "orderTotal", minWidth: "80px"},
              {label: "计划消化时间（下单金额）", prop: "expectTotalUsedDate", minWidth: "80px"},
              {label: "下单数量", prop: "orderCount", minWidth: "80px"},
              {label: "计划消化时间（下单数量）", prop: "expectCountUsedDate", minWidth: "80px"},
            ],
            previewDataLength: 10,
            apiParams: "importList",
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%A4%87%E8%B4%A7%E7%94%B3%E8%AF%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          }
        ],
      },
      exportHeaders: {
        skuModel: '型号',
        materialCode: '料号',
        skuName: '品名',
        grade: '级别',
        tqAvgMthAmount: '去年同期月均',
        avgMthAmount: '月均',
        recent1: '',
        recent2: '',
        recent3: '',
        recent4: '',
        recent5: '',
        recent6: '',
        byXs: '当月销售',
        qmKc: '总部当前库存',
        stockRatio: '库存系数',
        cgwj: '采购未交',
        allQl: '欠量未发',
        cgwzd: '采购未转单',
        orderNeed: '下单数量',
        lefBackStock: '剩余备货库存',
        stockBackRatio: '备货库存系数',
        expectUsedDate: '计划消化时间',
        raceName: '赛道名称',
        mainCategoryName: '大类',
        midCategoryName: '中类',
        subCategoryName: '小类',
        goodsSeriesName: '系列',
        is238: '是否238',
        price: '零售价',
        bottomSpec: '箱规'
      },
      errorHeader: {
        materialCode: '料号',
        orderCount: '下单数量',
        expectUseCountDate: '计划消化时间（下单数量）',
        orderTotal: '下单金额',
        expectUseTotalDate: '计划消化时间（下单金额）',
        importResult: '校验结果'
      }
    };
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  watch: {
  },
  created() {
    getSixMthFields().then(res => {
      if (res.code === 200) {
          res.data.forEach(e => {
            if (this.exportHeaders[e.field] !== undefined) {
              this.exportHeaders[e.field] = e.name;
            }
            this.recentFieldMap[e.field] = e.name;
          }
        )
      }
    })
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      this.pageData.rows = [];
      let query = Object.assign({}, this.queryForm);
      pageStockUpData(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.currentQueryType = this.queryForm.type;
        if (query.type === 'Total') {
          this.exportHeaders.orderNeed = '下单金额'
        } else {
          this.exportHeaders.orderNeed = '下单箱数'
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleResetFields() {
      this.$refs.searchRef.resetFields();
      this.queryForm.storeCodes = undefined;
      this.queryForm.itemCodes = undefined;
      this.queryForm.is128List = undefined;
      this.queryForm.ycFlag = undefined;
      this.queryForm.conditions = [];
    },
    handleSave(row, val ,field) {
      row[field] = val;
      saveStockUp(row).then(res => {
        if (res.code==200) {
          Notification.success("操作成功！");
        }
      })
    },
    async handleDownload() {
      let title = ''
      if (this.currentQueryType === 'Total') {
        title = '下单金额导出';
      } else if (this.currentQueryType === 'Count') {
        title = '下单数量导出';
      }
      let query = deepClone(this.queryForm);
      const size = 2000;
      const totalPage = Math.ceil(this.pageData.total / size);
      this.loading = true;
      let allExportData = [];
      for (let i = 1; i <= totalPage; i++) {
        const data = Object.assign(deepClone(query), {current: i, size: size});
        await pageStockUpData(data).then(res => {
          allExportData.push(...res.data.records);
        });
      }
      excelExport({
        topHeaders: this.exportHeaders,
        data: allExportData,
        title: title,
      });
      this.loading = false;
    },
    handleExport() {
      this.loading = true;
      let queryParam = deepClone(this.queryForm);
      exportData(queryParam).then(res => {
        Message.warning("数据处理中请勿关闭当前页面！");
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '下单数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleImport() {
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === 'import');
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    handleImportData(val) {
      let tempArray = val["importList"];
      importData(tempArray).then(res => {
        if (res.code === 200) {
          if (res.data.length > 0) {
            Notification.info("存在错误信息正在下载！");
            excelExport({
              topHeaders: this.errorHeader,
              data: res.data,
              title: '备货申请导入记录',
            });
          } else {
            const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === 'import');
            this.importDialog.currentImportType = currentImportOption.type;
            currentImportOption.visible = false;
            Notification.success("导入成功！");
          }
        }
      })
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 200)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    fidData(data, num) {
      return data;
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
