<template>
  <div>
    <div style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" inline label-width="auto" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="渠道" style="display: block;margin-top: 20px;">
          <el-radio-group v-model="queryForm.channel" class="full-width" size="small">
            <el-radio-button label="全部">全部</el-radio-button>
            <el-radio-button label="电话">电话</el-radio-button>
            <el-radio-button label="企微">企微</el-radio-button>
            <el-radio-button label="短信">短信</el-radio-button>
            <el-radio-button label="crm">crm</el-radio-button>
            <el-radio-button label="crm(企微)">crm(企微)</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="创建人">
          <el-input v-model="queryForm.createBy" size="mini" clearable placeholder="请输入创建人">
            <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer" @click="handleQuery" />
          </el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="queryForm.createTime"
            size="mini"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="原分组名称">
          <el-input v-model="queryForm.sourceRuleGroupName" size="mini" clearable placeholder="请输入原分组名称">
            <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer" @click="handleQuery" />
          </el-input>
        </el-form-item>
        <el-form-item label="分组名称">
          <el-input v-model="queryForm.name" clearable size="mini" placeholder="请输入分组名称">
            <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer" @click="handleQuery" />
          </el-input>
        </el-form-item>
        <el-form-item label="触达时间">
          <el-date-picker
            v-model="queryForm.reachDate"
            size="mini"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配置时间">
          <el-date-picker
            v-model="queryForm.msgConfigTime"
            size="mini"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配置状态">
          <el-select v-model="queryForm.msgConfigStatus" placeholder="请选择" clearable>
            <el-option value="0" label="未配置">未配置</el-option>
            <el-option value="1" label="已配置">已配置</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="消息匹配状态">
          <el-select v-model="queryForm.matchStatus" placeholder="请选择" clearable>
            <el-option value="0" label="待匹配">待匹配</el-option>
            <el-option value="1" label="已匹配">已匹配</el-option>
            <el-option value="2" label="匹配失败">匹配失败</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      @sort-change="handleSortChange"
    >
      <el-table-column type="index" width="40" />
      <el-table-column label="分组名称" prop="name" width="220" />
      <el-table-column label="原分组" prop="sourceRuleGroupName" width="220" />
      <el-table-column label="渠道" prop="channel" sortable width="80" />
      <el-table-column label="范围" prop="reachResult" sortable width="80" />
      <el-table-column label="创建日期" prop="createTime" sortable width="130" />
<!--      <el-table-column label="优先级" prop="priority" sortable width="100" />-->
      <el-table-column label="触达比例" prop="reachRatio" sortable width="90">
        <template #default="{ row }">
          <span v-show="row.reachRatio">{{ row.reachRatio }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="创建状态" prop="status" sortable width="100">
        <template #default="{ row }">
          <el-tag :type="statusMap[row.status]">
            {{ row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作人" prop="createBy" sortable width="120" />
      <el-table-column label="操作" width="200" align="center" >
        <template #default="{ row }">
          <el-button size="mini" type="text" class="table-button" style="width: 30%" @click="handleDownloadResult(row)">导出</el-button>
<!--          <el-popconfirm title="是否删除改分组？" @confirm="handleDeleteGroup(row)">
            <el-button slot="reference" size="mini" style="width: 20%" type="text" class="table-button">删除</el-button>
          </el-popconfirm>-->
          <el-button size="mini" v-if="(row.name.indexOf('未触达') < 0 && row.channel ==='企微')" type="text" class="table-button" style="width: 50%" @click="handleQwGroupMsgContentConfig(row)">配置企微内容</el-button>
        </template>
      </el-table-column>
      <el-table-column label="触达操作" width="100" align="center">
        <template #default="{ row }">
          <span v-if="!(row.reachTime==='' || row.reachTime!='推送失败')"></span>
          <el-button size="mini" v-if="row.reachFlag === 'Y'" :disabled="((row.reachTime!=='' && row.reachTime!=='推送失败') && row.channel ==='企微') || ((row.channel==='短信' || row.channel==='crm' || row.channel==='crm(企微)') && row.reachTime=='推送中')" type="text" class="table-button" @click="handlePush(row, pushOptions[row.channel].value)">{{ pushOptions[row.channel].label }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="触达方式" prop="reachMode" sortable width="90" />
<!--      <el-table-column label="自动更新状态" sortable width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.reachMode !== '手动触达'">{{ scope.row.active === 0 ? '已停止自动更新' : '已开启自动更新' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="自动更新操作" sortable width="120" align="center">
        <template #default="{ row }">
          <el-button v-if="row.reachMode !== '手动触达' && row.active === 1" size="mini" type="text" class="table-button" @click="handleStopTask(row)">停止更新</el-button>
          <el-button v-else-if="row.reachMode !== '手动触达' && row.active === 0" size="mini" type="text" class="table-button" @click="handleStartTask(row)">开启更新</el-button>
        </template>
      </el-table-column>-->
      <el-table-column label="触达时间" prop="reachTime" sortable width="130" />
      <el-table-column label="配置时间" prop="msgConfigTime" sortable width="130" />
      <el-table-column label="配置状态" prop="msgConfigStatus" sortable width="90" align="center">
        <template #default="{ row }">
          <el-tag v-if="(row.name.indexOf('未触达') < 0 && row.channel ==='企微')" :type="msgConfigStatusMap[row.msgConfigStatus]">
            {{ row.msgConfigStatus }}
          </el-tag>
          <span v-else></span>
        </template>
      </el-table-column>
      <el-table-column label="消息匹配状态" prop="matchStatus" sortable width="120" align="center">
        <template #default="{ row }">
          <el-tag v-if="(row.name.indexOf('未触达') < 0 && row.channel ==='企微')" :type="matchStatusMap[row.matchStatus]">
            {{ row.matchStatus }}
          </el-tag>
          <span v-else></span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleFilter" />

    <!-- 配置企微内容 -->
    <el-dialog title="配置企微内容" :visible.sync="noticeDialog.visible" append-to-body width="500px">
       <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="消息内容" prop="content">
             <el-input v-model="form.content" type="textarea" :autosize="{ minRows: 10, maxRows: 30 }" placeholder="请输入消息内容" />
          </el-form-item>
          <el-form-item label="消息创建时间" prop="msgCreateTime"  >
            <el-date-picker
              v-model="form.msgCreateTime"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
       </el-form>
       <div slot="footer" class="dialog-footer" style="text-align: right">
         <el-button size="mini" @click="cancel">取消</el-button>
         <el-button size="mini" type="primary" @click="submitForm">确定</el-button>
       </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone, downloadByUrl, parseTime } from "@/utils";
import {
  queryDistribute,
  downloadResult,
  deleteDistribute,
  pushChannel,
  getQwGroupMsgContent,
  addQwGroupMsgContent,
} from "@/api/tagPlatform";
import {Notification} from "element-ui";
import Pagination from '@/components/Pagination';

export default {
  name: "Current",
  components: { Pagination },
  data() {
    return {
      loading: false,
      pageData: {
        total: 0,
        rows: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        channel: '全部',
        createBy: undefined,
        name: undefined,
        sourceRuleGroupName: undefined,
        startDate: undefined,
        endDate: undefined,
        reachStartDate: undefined,
        reachEndDate: undefined,
        reachDate: undefined,
        crateTime: undefined,
        msgConfigStartDate: undefined,
        msgConfigEndDate: undefined,
        msgConfigTime: undefined,
        msgConfigStatus: undefined,
        matchStatus: undefined,
        order: {
          column: undefined,
          operator: undefined
        }
      },
      statusMap: {
        '创建中': 'primary',
        '创建成功': 'success',
        '创建失败': 'danger',
      },
      pushOptions: { '企微': { label: '推送企微', value: '1' }, '短信': {label: '推送CRM', value: '2'}, 'crm': {label: '推送CRM', value: '2'}, '电话': {label: '电话', value: '3'}, 'crm(企微)': {label: '推送crm(企微)', value: '5'}},
      form: {
        ruleGroupChannelId: undefined,
        content: undefined,
        msgCreateTime: undefined,
      },
      noticeDialog:{
        visible: false,
      },
      // 表单校验
      rules: {
        content: [{ required: true, message: "消息内容不能为空", trigger: "change" }],
        msgCreateTime: [{ required: true, message: "消息创建时间不能为空", trigger: "change" }],
      },
      msgConfigStatusMap: {
        '已配置': 'success',
        '未配置': 'danger',
      },
      matchStatusMap: {
        '待匹配': 'primary',
        '已匹配': 'success',
        '匹配失败': 'danger',
      }
    }
  },
  created() {
    this.handleQuery();
  },
  methods: {
    handleFilter() {
      this.loading =true;
      let param = {};
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Date) {
            param[key] = parseTime(this.queryForm[key]);
          } else if (this.queryForm[key] instanceof Array) {
            if (key === 'createTime') {
              param['startDate'] = this.queryForm[key][0];
              param['endDate'] = this.queryForm[key][1];
            } else if (key === 'reachDate') {
              param['reachStartDate'] = this.queryForm[key][0];
              param['reachEndDate'] = this.queryForm[key][1];
            } else if (key === 'msgConfigTime') {
              param['msgConfigStartDate'] = this.queryForm[key][0];
              param['msgConfigEndDate'] = this.queryForm[key][1];
            }
          } else {
            if (!(key === 'channel' && this.queryForm[key]==='全部')) {
              param[key] = this.queryForm[key];
            }
          }
        }
      }
      queryDistribute(param).then(res => {
        this.pageData.rows = res.data.datas;
        this.pageData.total = res.data.total;
        this.loading = false;
      })
    },
    handleQuery() {
      this.queryForm.page = 1;
      this.handleFilter();
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleQuery();
    },
    handleDownloadResult(row) {
      this.loading = true;
      downloadResult(row.id).then((res) => {
        if (res.code === 200 && res.data) {
          downloadByUrl(res.data);
          this.loading = false;
        } else {
          Notification.warning('无可下载的数据');
          this.loading = false;
        }
      })
    },
    handleDeleteGroup(row) {
      deleteDistribute(row.id).then((res) => {
        if (res.code === 200) {
          this.handleQuery();
          Notification.success('删除成功！');
        } else {
          Notification.warning('删除失败！');
        }
      })
    },
    handlePush(row, type) {
      pushChannel({ id: row.id, type: type}).then((res) => {
        if (res.code === 200) {
          this.handleQuery();
          Notification.success('推送成功！');
        }
      })
    },
    /** 配置企微内容 */
    handleQwGroupMsgContentConfig(row) {
      this.form = {
        ruleGroupChannelId: undefined,
        content: undefined,
        msgCreateTime: undefined,
      },
      getQwGroupMsgContent(row.id).then(response => {
        if(response.data !==null){
          this.form = response.data;
        }
        this.form.ruleGroupChannelId = row.id;
        this.noticeDialog.visible = true;
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addQwGroupMsgContent(this.form).then((res) => {
            if (res.code === 200) {
              Notification.success('配置企微内容成功！');
              this.noticeDialog.visible = false;
              this.handleQuery();
            }else {
              this.noticeDialog.visible = false;
              this.handleQuery();
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 取消按钮
    cancel() {
      this.noticeDialog.visible = false;
      this.form = {
        ruleGroupChannelId: undefined,
        content: undefined,
        msgCreateTime: undefined,
      }
    },
}
}
</script>

<style scoped>

</style>
