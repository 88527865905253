<template>
  <div>
    <div style="padding-bottom: 15px">
      <el-form ref="form" :rules="rules" size="mini" :inline="true" :model="form" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="12">
            <el-form-item label="标签名称" prop="tagName" label-width="120px">
              <el-input  v-if="type !== 'view'" v-model="form.tagName" placeholder="请输入" clearable/>
              <span v-if="type === 'view'"> {{ form.tagName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分公司" label-width="100px" prop="orgs">
              <DictSelect
                v-if="type !== 'view'"
                ref="org"
                :value.sync="form.orgs"
                fullLabel
                multiple
                init
                :init-options="initOrgOptions"
                collapse-tags
              />
              <span v-if="type === 'view'"> {{ form.orgName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="权重" prop="weight" label-width="120px">
              <el-input-number  v-if="type !== 'view'" v-model="form.weight" :controls="false" :min="0" :step="0" placeholder="值越大优先级越高" clearable/>
              <span v-if="type === 'view'"> {{ form.weight }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="时间范围" prop="rangeTime" label-width="120px">
              <el-date-picker v-model="form.rangeTime" :picker-options="pickerOptions" style="width: 350px" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <div>
        <div style="position: relative; z-index: 999;width: 100%;">
          <div style="float: left; font-weight: 600; margin: 15px;">商品列表</div>
          <div style="float: right;margin: 5px;">
            <el-input style="width: 120px !important; margin-right: 10px" clearable size="mini" :placeholder="'商品型号'" v-model="querySkuKeyWord" @keyup.enter.native="handleQuery('models', 'skuPage')">
              <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('models', 'skuPage')" /></el-input>
            <el-button v-if="type!=='view'" size="mini" icon="el-icon-upload" type="primary"
                       @click="handleImport">导入商品
            </el-button>
<!--            <el-button v-if="form.id" size="mini" icon="el-icon-download" type="primary"-->
<!--                       @click="handleExport">导出商品-->
<!--            </el-button>-->
            <el-button v-if="type !=='view'" size="mini" icon="el-icon-plus" type="primary"
                       @click="drawer.visible = true">添加商品
            </el-button>
            <el-button v-if="type!=='view'" size="mini" icon="el-icon-remove" type="danger"
                       @click="handleRemoveAll">全部移除
            </el-button>
          </div>
        </div>
        <div>
          <el-table
            ref="skuTableRef"
            :data="skuPage.rows"
            height="calc(100vh - 400px)"
            v-loading="loading"
            size="mini"
            border
            style="margin-top: 5px"
            highlight-current-row
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="商品型号" prop="skuModel" width="100"/>
            <el-table-column label="款式名称" prop="skuName" show-overflow-tooltip>
              <template slot-scope="scope">
                <span >{{ scope.row.skuName }}</span>
              </template>
            </el-table-column>
<!--            <el-table-column label="规格" prop="spec" width="80"/>-->
<!--            <el-table-column label="货号" prop="cargoNo" show-overflow-tooltip />-->
<!--            <el-table-column label="大类" prop="mainCategoryName" width="100"/>-->
<!--            <el-table-column label="中类" prop="midCategoryName" width="100"/>-->
<!--            <el-table-column label="小类" prop="subCategoryName" width="100"/>-->
            <el-table-column v-if="type!=='view'" label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  style="border: 0px; color: red"
                  @click.native.prevent="handleRemoveRow(scope.row, 1, 'models', 'skuPage')"
                  type="text"
                  size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="skuPage.total" :page.sync="skuPage.current" :limit.sync="skuPage.size"
                      @pagination="handleQuery('models', 'skuPage')"/>
        </div>
      </div>
    </div>

    <el-drawer :title="'选择商品'" :visible.sync="drawer.visible" size="70%" direction="rtl"
               append-to-body>
      <SkuItem
        :group-id="skuGroups.activeIndex"
        :refresh="skuGroups.refresh"
        scope="0"
        @add-items="addItems"
        @close-drawer="drawer.visible = false"
        :show-action="true"
        @getExceptModels="callback => callback(form.exceptSkuModels)"
        @getExceptSkuCodes="callback => callback([])"
      />
    </el-drawer>
    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
                  @handleImportCallBack="handleImportData" append-to-body/>

    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getSkuTagDetail, checkSkus, dictSelect } from "@/api/appTask";
import Pagination from "@/components/Pagination";
import SkuItem from "@/views/appTask/skuGroup/SkuItem";
import DictSelect from "@/components/DictSelect";
import {parseTime, deepClone, excelExport} from "@/utils";
import {Message, MessageBox, Notification} from "element-ui";

export default {
  name: "Edit",
  components: {
    SkuItem,
    DictSelect,
    Pagination,
    ImportDialog: () => import("@/components/ImportDialog")
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      dictSelect,
      filterDcText: undefined,
      filterTaskDcText: undefined,
      formLoading: false,
      form: {
        orgs: [],
        models: [],
        weight: undefined,
        id: undefined,
        tagName: undefined,
        imageUrl: '',
        groupImageUrl: '',
        oneGradeCategoryName: undefined,
        twoGradeCategoryName: undefined,
        skuCount: 0,
        status: 0,
        remark: undefined,
        rid: undefined,
        createUser: undefined,
        createTime: undefined,
        updateUser: undefined,
        updateTime: undefined,
        exceptSkuModels: [],
        rangeTime: [],
      },
      querySkuKeyWord: undefined,
      queryDcCode: undefined,
      rules: {
        tagName: [{required: true, message: " ", trigger: "change"}],
        orgs: [{ type:'array', required: true, message: " ", trigger: "change"}],
        rangeTime: [{ type: 'array',required: true, message: " ", trigger: "change" }],
      },
      loading: false,
      drawer: {
        visible: false,
      },
      skuGroups: {
        fixed: [],
        dynamic: [],
        activeIndex: 'ALL',
        activeIndexPath: '更多',
        refresh: false,
      },
      importDialog: {
        currentImportType: "skuModel",
        importBaseParams: [
          {
            visible: false,
            title: "商品包-商品数据导入",
            type: "skuModel",
            tipsText: "提示：商品型号不能为空",
            templateSampleHead: [{label: "商品型号", prop: "skuModel", minWidth: "80px"}],
            templateSampleLists: [
              {
                skuModel: "609023",
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%85%AC%E5%85%B1%E6%A0%87%E7%AD%BE%E5%95%86%E5%93%81%E5%9E%8B%E5%8F%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          },
        ],
      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      ifComponent: undefined,
      initOrgOptions: [],
      defaultQueryOptions: [],
      queryConfigRows: [],
      orgFilterMap: {},
      allOrgMap: {},
      exportHeaders: {
        skuModel: {
          skuModel: '商品型号',
          errorMsg: '校验结果',
        },
      },
      orgDcTree: [],
      parseTime: undefined,
      orgAllAuth: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  computed: {
    skuOtherName: function () {
      return this.skuGroups.activeIndexPath;
    },
    "skuPage.total": function () {
      return this.form.models.length;
    },
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
    orgCompareShow() {
      if (this.form.compareOrgCode) {
        if (this.orgFilterMap[this.form.compareOrgCode].ksCompareChoose === '1' && this.orgFilterMap[this.form.compareOrgCode].ksCompareDate &&  this.orgFilterMap[this.form.compareOrgCode].ksCompareDate.length > 0) {
          return parseTime(this.orgFilterMap[this.form.compareOrgCode].ksCompareDate[0], '{y}-{m}-{d}') + '至' + parseTime(this.orgFilterMap[this.form.compareOrgCode].ksCompareDate[1], '{y}-{m}-{d}');
        } else if (this.orgFilterMap[this.form.compareOrgCode].ksCompareChoose === '0') {
          return "前180天"
        }
      }
      return;
    },
  },
  watch: {
    id: {
      handler(newVal) {
        this.getDetail(newVal);
      },
      deep: true,
      immediate: true
    },
    "form.orgs": {
      handler(nV, oV) {
        if (nV && nV.length > 0 && oV.length !== nV.length) {
          if (nV.indexOf('ALL') > -1 && oV.indexOf('ALL') > -1) {
            this.form.orgs = nV.filter(e => e !== 'ALL')
          } else if (nV.indexOf('ALL') > -1 && oV.indexOf('ALL') < 0) {
            this.form.orgs = this.initOrgOptions.map(e => e.value)
          } else if (nV.indexOf('ALL') < 0 && nV.length === this.initOrgOptions.length -1) {
            this.form.orgs = this.initOrgOptions.map(e => e.value)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.initOrgOptions = res.data;
    })
  },
  mounted() {
  },
  methods: {
    getDetail(val) {
      this.reset();
      if (val !== undefined) {
        this.formLoading = true;
        getSkuTagDetail(val).then(res => {
          res.data.orgs = res.data.orgs.map(e => e.orgCode)
          let tmpForm = deepClone(this.form)

          tmpForm= Object.assign(tmpForm, res.data);
          if (tmpForm.startDate) {
            tmpForm.rangeTime = [tmpForm.startDate, tmpForm.endDate];
          }
          this.skuPage.total = tmpForm.models.length;

          this.form = tmpForm;
          this.handleQuery('models', 'skuPage');
          // this.$emit('set-size', tmpForm.models.length, tmpForm.shops.length);
          this.$forceUpdate();
          this.formLoading = false;
        })
      } else {
        this.$emit('set-size', 0, 0);
      }
    },
    reset() {
      let form = {
        orgs: [],
        models: [],
        weight: undefined,
        id: undefined,
        tagName: undefined,
        createUser: undefined,
        createTime: undefined,
        updateUser: undefined,
        updateTime: undefined,
        exceptSkuModels: [],
        rangeTime: undefined
      };
      this.skuPage = {
        total: 0,
        current: 1,
        rows: [],
        size: 10
      };
      this.form = Object.assign({}, form);
      this.querySkuKeyWord = undefined;
      this.queryDcCode = undefined;
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    async handleImportData(val) {
      this.noticeDialog.msg =""
      const checkedArray = Object.assign([], this.form.models);
      checkedArray.forEach(e => e.dataType = '0');
      let tempArray = val["importList"];
      let errorData = [];
      tempArray.forEach(e => {
        if (!e.skuModel) {
          errorData.push(1)
          e.errorMsg = '商品型号不能为空'
        }
        e.dataType = '1';
      })
      let msg = undefined;
      if (errorData.length === tempArray.length) {
        msg = " 导入失败，导入的商品型号不能为空，请填写后重新导入！"
      }
      if (msg) {
        MessageBox.alert(msg, '提示', {
          dangerouslyUseHTMLString: true
        });
        return;
      }
      const param = tempArray.concat(checkedArray);
      await checkSkus(0, { skus: param }).then((res) => {
        const errorCount = res.data.skus.filter(e => e.checkResult === '3' && e.dataType === '1').length;
        this.form.tmpSkus = [].concat(res.data.skus.filter(e => e.dataType === '1'));
        if (errorCount > 0) {
          this.noticeDialog.msg = " 导入失败，请重新上传" + res.data.errorMsg;
          this.noticeDialog.visible = true;
          return;
        } else {
          const repeatCount = res.data.skus.filter(e => e.checkResult === '2' && e.dataType === '1').length;
          const successCount = res.data.skus.filter(e => e.checkResult === '1' && e.dataType === '1').length;
          this.noticeDialog.msg = "导入成功， 共 " + tempArray.length + " 条，成功 " + successCount + " 条，重复 " + repeatCount + " 条数据";
          this.noticeDialog.visible = true;
          this.form.models = res.data.skus.filter(e => e.dataType === '0' || ( e.dataType === '1' && e.checkResult === '1'));
        }
      })
      this.form.exceptSkuModels = this.form.models.map(e => e && e.skuModel ? e.skuModel : '');
      this.skuPage.total = this.form.models.length;
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === 'skuModel');
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = false;
      this.$forceUpdate();
      this.handleQuery('models', 'skuPage');
    },
    addItems(items, type) {
      if (type === "sku") {
        const newItems = items.filter(e => {
          if (this.form.exceptSkuModels.indexOf(e.skuModel) < 0) {
            return true;
          }
        });
        this.form.models = this.form.models.concat(newItems);
        this.form.exceptSkuModels = Array.from(new Set(this.form.exceptSkuModels.concat(newItems.map(e => e.skuModel))));
        this.skuPage.total = this.form.models.length;
        this.handleQuery('models', 'skuPage');
      }
    },
    handleQuery(type, page) {
      const filterData = this.form[type].filter(e => {
        if (type === 'models') {
          return !this.querySkuKeyWord || e.skuModel.indexOf(this.querySkuKeyWord) > -1;
        }
        return false;
      })
      const total = this[page].current * this[page].size;
      this[page].total = filterData.length;
      this[page].rows = filterData.slice(this[page].size * (this[page].current - 1), total >= filterData.length ? filterData.length : this[page].current * this[page].size);
      this.$nextTick(() => {
        if(this.$refs['skuTableRef']) {
          this.$refs['skuTableRef'].doLayout();
        }
      })
    },
    handleImport() {
      const type = 'skuModel'
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === type);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    // handleExport() {
    //   this.loading = true;
    //   exportTaskSku(this.form.mtTaskId).then(res => {
    //     this.loading = false;
    //     if (!res) {
    //       return
    //     }
    //     const link = document.createElement('a')
    //     link.style.display = 'none'
    //     link.href = window.URL.createObjectURL(new Blob([res]))
    //     link.setAttribute('download', '商品包商品数据导出（' + this.form.tagName + '）.xlsx')
    //     document.body.appendChild(link)
    //     link.click()
    //     link.remove()
    //   })
    // },
    handleRemoveRow(row, deleteCount, type, page) {
      if (type === 'models') {
        this.form[type] = this.form[type].filter(e => e.skuModel !== row.skuModel);
      }
      this[page].total = this.form[type].length;
      this.handleQuery(type, page);
      this.form.exceptSkuModels = this.form.models.map(e => e && e.skuModel ? e.skuModel : '');
    },
    handleRemoveAll() {
      this.form.models = [];
      this.form.exceptModels = [];
      this.form.exceptSkuModels = [];
      this.handleQuery('models', 'skuPage');
      this.form.runFlow = true;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this[dict] = {oneCategory: this.form.oneGradeCategoryName}
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDialogCloseEvent(type) {
      this.ifComponent = undefined;
    },
    downloadImportResult() {
      let headers = {};
      let data = [];
      let title = ''
      data = this.form.tmpSkus.filter(e => e.dataType === '1');
      title = '商品标签绑定商品导入结果';
      headers = this.exportHeaders.skuModel;
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  //.table-light-class .el-table__empty-block {
  //  font-size: xxx-large;
  //  justify-content: unset;
  //  .el-table__empty-text {
  //    line-height: 60px;
  //    width: 50%;
  //    color: red !important;
  //  }
  //}
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .el-drawer__header {
    margin-bottom: 0px;
  }

  .descWidth.el-input__inner {
    padding-left: 5px !important;
    max-width: unset !important;
  }
  .el-upload--picture-card {
    height:30px;
    width: 80px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:80px;
    width: 60px;
  }
  label.el-radio {
    font-weight: 400;
  }
}

</style>
<style lang="scss" scoped src='@/style/common/index.scss'></style>
