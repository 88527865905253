var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-form",
            {
              ref: "searchRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "补货日期" } },
                [
                  _c(
                    "el-select",
                    {
                      on: { change: _vm.handleDateChange },
                      model: {
                        value: _vm.queryForm.supplyDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "supplyDate", $$v)
                        },
                        expression: "queryForm.supplyDate",
                      },
                    },
                    _vm._l(_vm.dateOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.queryForm.supplyDate
                ? _c("CategorySearch", {
                    ref: "category",
                    staticStyle: { display: "-webkit-inline-box" },
                    attrs: {
                      main: _vm.queryForm.c1CatCode,
                      mid: _vm.queryForm.c2CatCode,
                      sub: _vm.queryForm.c3CatCodes,
                      multiple: "",
                      date: true,
                    },
                    on: {
                      "update:main": function ($event) {
                        return _vm.$set(_vm.queryForm, "c1CatCode", $event)
                      },
                      "update:mid": function ($event) {
                        return _vm.$set(_vm.queryForm, "c2CatCode", $event)
                      },
                      "update:sub": function ($event) {
                        return _vm.$set(_vm.queryForm, "c3CatCodes", $event)
                      },
                      getDate: (callback) => callback(_vm.queryForm.supplyDate),
                    },
                  })
                : _vm._e(),
              _c("DictSelect", {
                ref: "shopSearch",
                attrs: {
                  value: _vm.queryForm.topGrade,
                  showLabel: "商品等级",
                  placeholder: "请选择商品等级",
                  type: "grade",
                  date: true,
                  remote: false,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "topGrade", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("DictSelect", {
                ref: "skuSearch",
                attrs: {
                  value: _vm.queryForm.itemCode,
                  showLabel: "商品信息",
                  fullLabel: true,
                  placeholder: "可输入编号或名称",
                  type: "item",
                  date: true,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "itemCode", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("DictSelect", {
                ref: "shopSearch",
                attrs: {
                  value: _vm.queryForm.storeCode,
                  showLabel: "店铺信息",
                  fullLabel: true,
                  placeholder: "可输入编号或名称",
                  type: "store",
                  date: true,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "storeCode", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("DictSelect", {
                ref: "xdrSearch",
                attrs: {
                  value: _vm.queryForm.xdrId,
                  showLabel: "员工信息",
                  fullLabel: true,
                  placeholder: "请选择员工",
                  type: "xdr",
                  date: true,
                  remote: false,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "xdrId", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("DictSelect", {
                ref: "activitySearch",
                attrs: {
                  value: _vm.queryForm.actName,
                  showLabel: "活动名称",
                  placeholder: "请选择活动",
                  type: "activity",
                  date: true,
                  remote: false,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "actName", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c(
                "el-form-item",
                { attrs: { label: "128清单" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.is128List,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "is128List", $$v)
                        },
                        expression: "queryForm.is128List",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 1, label: "是" } }, [
                        _vm._v("是"),
                      ]),
                      _c("el-option", { attrs: { value: 0, label: "否" } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺信息批量查询" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入店铺编码支持多个" },
                    model: {
                      value: _vm.queryForm.storeCodes,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "storeCodes", $$v)
                      },
                      expression: "queryForm.storeCodes",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品信息批量查询" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品编码支持多个" },
                    model: {
                      value: _vm.queryForm.itemCodes,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "itemCodes", $$v)
                      },
                      expression: "queryForm.itemCodes",
                    },
                  }),
                ],
                1
              ),
              _c("DictSelect", {
                ref: "flagRef",
                attrs: {
                  value: _vm.queryForm.ycFlag,
                  showLabel: "补货提示",
                  placeholder: "请选择补货提示",
                  type: "ycFlag",
                  date: true,
                  remote: false,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "ycFlag", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("DictSelect", {
                ref: "flagRef",
                attrs: {
                  value: _vm.queryForm.mandatoryIdentification,
                  showLabel: "强补标识",
                  placeholder: "请选择强补标识",
                  type: "mandatoryIdentification",
                  date: true,
                  remote: false,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.queryForm,
                      "mandatoryIdentification",
                      $event
                    )
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("DictSelect", {
                ref: "flagRef",
                attrs: {
                  value: _vm.queryForm.bhCatFlag,
                  showLabel: "总量标识",
                  placeholder: "请选择总量标识",
                  type: "bhCatFlag",
                  date: true,
                  remote: false,
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.queryForm, "bhCatFlag", $event)
                  },
                  getDate: (callback) => callback(_vm.queryForm.supplyDate),
                },
              }),
              _c("div", [
                _c(
                  "div",
                  { staticStyle: { float: "left" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "标记" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.queryForm.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "status", $$v)
                              },
                              expression: "queryForm.status",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: true } }, [
                              _vm._v("隐藏标记"),
                            ]),
                            _c("el-radio", { attrs: { label: undefined } }, [
                              _vm._v("显示全部"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right", "padding-top": "0.5%" } },
                  [
                    _vm._v(
                      " 全门店配货sku数量：" +
                        _vm._s(_vm.skuCount) +
                        "；配货路线门店数量：" +
                        _vm._s(_vm.storeCount) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticStyle: { clear: "both" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据范围" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.queryForm.bhDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "bhDate", $$v)
                            },
                            expression: "queryForm.bhDate",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: undefined } }, [
                            _vm._v("全部门店"),
                          ]),
                          _c(
                            "el-radio",
                            { attrs: { label: this.queryForm.supplyDate } },
                            [
                              _vm._v(
                                "填报补货日期" +
                                  _vm._s(this.queryForm.supplyDate) +
                                  "的门店"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } }, [
                _c(
                  "div",
                  { staticStyle: { float: "left" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleFilter },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleResetFields },
                      },
                      [_vm._v("清空")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleFilterColumns },
                      },
                      [_vm._v("列编辑")]
                    ),
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          value: _vm.queryForm.conditions.length,
                          type: "warning",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-right": "10px",
                            },
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleCondition },
                          },
                          [_vm._v(" 条件编辑 ")]
                        ),
                      ],
                      1
                    ),
                    _vm.currentDataDate === _vm.queryForm.supplyDate
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleBatchUpdate },
                          },
                          [_vm._v("批量修改补货单数量 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          loading: _vm.allExportLoading,
                        },
                        on: { click: _vm.exportData },
                      },
                      [_vm._v("导出")]
                    ),
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm.excelDownloadInfo.status !== undefined &&
                      _vm.excelDownloadInfo.isSuccess
                        ? _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.excelDownloadInfo.downloadUrl,
                              },
                            },
                            [_vm._v(_vm._s(_vm.excelDownloadInfo.fileName))]
                          )
                        : _vm.excelDownloadInfo.status !== undefined &&
                          _vm.excelDownloadInfo.status === "0"
                        ? _c("a", { staticClass: "redColor" }, [
                            _vm._v(_vm._s("（正在导出数据，请等待！）")),
                          ])
                        : _vm.excelDownloadInfo.status !== undefined &&
                          !_vm.excelDownloadInfo.isSuccess
                        ? _c(
                            "a",
                            {
                              staticStyle: {
                                color: "red",
                                "text-decoration": "auto",
                                cursor: "auto",
                              },
                            },
                            [_vm._v("导出失败,请重新生成")]
                          )
                        : _vm._e(),
                    ]),
                    _vm.currentDataDate === _vm.queryForm.supplyDate
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              loading: _vm.produceLoading,
                            },
                            on: { click: _vm.produceSupply },
                          },
                          [_vm._v("生成配货单 ")]
                        )
                      : _vm._e(),
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm.disDownloadInfo.status !== undefined &&
                      _vm.disDownloadInfo.isSuccess &&
                      _vm.currentDataDate === _vm.queryForm.supplyDate
                        ? _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.disDownloadInfo.downloadUrl,
                              },
                            },
                            [_vm._v(_vm._s(_vm.disDownloadInfo.fileName))]
                          )
                        : _vm.disDownloadInfo.status !== undefined &&
                          _vm.disDownloadInfo.status === "0" &&
                          _vm.currentDataDate === _vm.queryForm.supplyDate
                        ? _c("a", { staticClass: "redColor" }, [
                            _vm._v(_vm._s("（正在生成配货单，请等待！）")),
                          ])
                        : _vm.disDownloadInfo.status !== undefined &&
                          !_vm.disDownloadInfo.isSuccess &&
                          _vm.currentDataDate === _vm.queryForm.supplyDate
                        ? _c(
                            "a",
                            {
                              staticStyle: {
                                color: "red",
                                "text-decoration": "auto",
                                cursor: "auto",
                              },
                            },
                            [_vm._v("生成配货单失败，请重新生成")]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.handleJump },
                      },
                      [_vm._v("填报跳转")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                          "font-weight": "600",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.reportData.userNum) +
                            " / " +
                            _vm._s(_vm.reportData.allNum)
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _c("UpdateTime", {
                      attrs: { time: _vm.updateTime },
                      on: {
                        "update:time": function ($event) {
                          _vm.updateTime = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "padding-top": "15px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.pageData.rows,
                height: "calc(100vh - 180px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
                "row-class-name": _vm.rowClassName,
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40" },
              }),
              _c("el-table-column", { attrs: { type: "index", width: "40" } }),
              _vm._l(_vm.checkedColumns, function (item) {
                return [
                  _vm.allDynamicColumns[item] &&
                  ((_vm.dayType === "holiday" &&
                    _vm.allNormalColumns.indexOf(item) < 0) ||
                    (_vm.dayType === "normal" &&
                      _vm.allHolidayColumns.indexOf(item) < 0))
                    ? _c("el-table-column", {
                        key: item,
                        attrs: {
                          prop: _vm.allDynamicColumns[item].prop,
                          "min-width": _vm.allDynamicColumns[item].minWidth,
                          label: _vm.allDynamicColumns[item].label,
                          align: _vm.allDynamicColumns[item].align,
                          fixed: _vm.allDynamicColumns[item].fixed,
                          sortable: _vm.allDynamicColumns[item].sortable,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _vm.allDynamicColumns[item].prop ===
                                  "storeName"
                                    ? _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            color: "#6a6ae9",
                                            "font-size": "12px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCellClick(row)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(row.storeName))]
                                      )
                                    : _vm.allDynamicColumns[item].prop ===
                                      "is128List"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row[
                                              _vm.allDynamicColumns[item].prop
                                            ] === 1
                                              ? "是"
                                              : row[
                                                  _vm.allDynamicColumns[item]
                                                    .prop
                                                ] === 0
                                              ? "否"
                                              : ""
                                          )
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            row[
                                              _vm.allDynamicColumns[item].prop
                                            ]
                                          )
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ]
              }),
              _c("el-table-column", {
                attrs: { label: "标记", prop: "status", width: "80px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-switch", {
                          attrs: { disabled: _vm.completeDisabled },
                          on: {
                            change: function ($event) {
                              return _vm.handleCompleteChange($event, row)
                            },
                          },
                          model: {
                            value: row.status,
                            callback: function ($$v) {
                              _vm.$set(row, "status", $$v)
                            },
                            expression: "row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "补货提示",
                  prop: "ycFlag",
                  sortable: "",
                  "min-width": "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "总量标识",
                  prop: "bhCatName",
                  sortable: "",
                  "min-width": "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "预测补货数量标识",
                  prop: "ycBhQtyFlag",
                  "min-width": "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "强补标识",
                  prop: "mandatoryIdentification",
                  sortable: "",
                  "min-width": "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最终补货数量(预测辅助数据)",
                  prop: "zzBhQty",
                  sortable: "",
                  "min-width": "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.currentDataDate !== _vm.queryForm.supplyDate
                          ? _c("span", [_vm._v(_vm._s(row.zzBhQty))])
                          : _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "controls-position": "right",
                                placeholder: "请输入内容",
                                size: "mini",
                                min: 0,
                                disabled:
                                  _vm.currentDataDate !==
                                  _vm.queryForm.supplyDate,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeInput(
                                    $event,
                                    row,
                                    "zzBhQty"
                                  )
                                },
                              },
                              model: {
                                value: row.zzBhQty,
                                callback: function ($$v) {
                                  _vm.$set(row, "zzBhQty", $$v)
                                },
                                expression: "row.zzBhQty",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "配货单数量(预测辅助数据)",
                  prop: "phdQty",
                  sortable: "",
                  "min-width": "100px",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "调整说明(预测辅助数据)",
                  prop: "remark",
                  "min-width": "180px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.currentDataDate !== _vm.queryForm.supplyDate
                          ? _c("span", [_vm._v(_vm._s(row.remark))])
                          : _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "controls-position": "right",
                                placeholder: "请输入内容",
                                size: "mini",
                                type: "textarea",
                                disabled:
                                  _vm.currentDataDate !==
                                  _vm.queryForm.supplyDate,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeInput(
                                    $event,
                                    row,
                                    "remark"
                                  )
                                },
                              },
                              model: {
                                value: row.remark,
                                callback: function ($$v) {
                                  _vm.$set(row, "remark", $$v)
                                },
                                expression: "row.remark",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              pageSizes: [10, 20, 30, 50, 100, 200, 500],
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "列编辑",
            visible: _vm.dialog.filterColumnVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "filterColumnVisible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "25%",
                position: "relative",
                float: "right",
                right: "60%",
                top: "-50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(false)
                    },
                  },
                },
                [_vm._v("清空")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCheckAll(true)
                    },
                  },
                },
                [_vm._v("全选")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", color: "gray" } },
            [
              _c("span", [
                _vm._v("说明：页面表头指标 展示顺序同指标添加先后顺序"),
              ]),
            ]
          ),
          _vm._l(_vm.checkedCopy.filterColumns, function (item, key, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "margin-bottom": "15px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v(_vm._s(item.label))]
                ),
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: item.isIndeterminate },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckAllChange($event, key, item.label)
                      },
                    },
                    model: {
                      value: item.checkAll,
                      callback: function ($$v) {
                        _vm.$set(item, "checkAll", $$v)
                      },
                      expression: "item.checkAll",
                    },
                  },
                  [_vm._v(" 全选 ")]
                ),
                _c("div", { staticStyle: { margin: "10px 0" } }),
                _c(
                  "el-checkbox-group",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckedChange($event, key, item.label)
                      },
                    },
                    model: {
                      value: item.checked,
                      callback: function ($$v) {
                        _vm.$set(item, "checked", $$v)
                      },
                      expression: "item.checked",
                    },
                  },
                  _vm._l(item.list, function (option) {
                    return _c(
                      "el-checkbox",
                      {
                        key: option,
                        staticStyle: { width: "20%" },
                        attrs: {
                          label: option,
                          disabled: _vm.disableColumns.indexOf(option) > -1,
                        },
                      },
                      [_vm._v(_vm._s(option) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog.filterColumnVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmChecked },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "max-height": "500px" },
          attrs: {
            title: "批量修改补货单数量",
            visible: _vm.updateDialog.updateVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.updateDialog, "updateVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "stockForm",
              attrs: { model: _vm.updateDialog.form, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "补货数量",
                    prop: "supplyNum",
                    rules: [
                      {
                        required: true,
                        message: "不能为空",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入",
                      min: 0,
                      controls: false,
                      size: "small",
                    },
                    model: {
                      value: _vm.updateDialog.form.supplyNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateDialog.form, "supplyNum", $$v)
                      },
                      expression: "updateDialog.form.supplyNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "调整说明", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入",
                      controls: false,
                      size: "small",
                    },
                    model: {
                      value: _vm.updateDialog.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateDialog.form, "remark", $$v)
                      },
                      expression: "updateDialog.form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.updateDialog.updateVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleBatchSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "条件编辑",
            visible: _vm.conditionDialog.visible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.conditionDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addCondition },
                },
                [_vm._v("添加条件")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.conditionDialog.tableData } },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _c("el-table-column", {
                attrs: {
                  label: "字段选择",
                  prop: "compareLeftField",
                  width: "120px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              size: "mini",
                              filterable: "",
                            },
                            model: {
                              value: row.compareLeftField,
                              callback: function ($$v) {
                                _vm.$set(row, "compareLeftField", $$v)
                              },
                              expression: "row.compareLeftField",
                            },
                          },
                          _vm._l(_vm.compareColumns, function (item) {
                            return _c("el-option", {
                              key: item.prop,
                              attrs: { label: item.label, value: item.prop },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "判断方式",
                  prop: "compareType",
                  width: "200px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: row.compareType,
                              callback: function ($$v) {
                                _vm.$set(row, "compareType", $$v)
                              },
                              expression: "row.compareType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "已有字段" } }),
                            _c("el-radio", { attrs: { label: "自定义" } }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "比较值", prop: "compareExpress" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "50px" },
                            attrs: { placeholder: "比较符", size: "mini" },
                            model: {
                              value: row.compareSymbol,
                              callback: function ($$v) {
                                _vm.$set(row, "compareSymbol", $$v)
                              },
                              expression: "row.compareSymbol",
                            },
                          },
                          _vm._l(_vm.compareOperation, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                        row.compareType === "自定义"
                          ? _c(
                              "div",
                              { staticStyle: { display: "inline-block" } },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    placeholder: "请输入非负整数",
                                    controls: false,
                                    size: "mini",
                                  },
                                  model: {
                                    value: row.compareValue,
                                    callback: function ($$v) {
                                      _vm.$set(row, "compareValue", $$v)
                                    },
                                    expression: "row.compareValue",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticStyle: { display: "inline-block" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择比较字段",
                                      size: "mini",
                                      filterable: "",
                                    },
                                    model: {
                                      value: row.compareRightField,
                                      callback: function ($$v) {
                                        _vm.$set(row, "compareRightField", $$v)
                                      },
                                      expression: "row.compareRightField",
                                    },
                                  },
                                  _vm._l(_vm.compareColumns, function (item) {
                                    return _c("el-option", {
                                      key: item.prop,
                                      attrs: {
                                        label: item.label,
                                        value: item.prop,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "50px" },
                                    attrs: {
                                      placeholder: "运算符",
                                      size: "mini",
                                    },
                                    model: {
                                      value: row.arithmeticSymbol,
                                      callback: function ($$v) {
                                        _vm.$set(row, "arithmeticSymbol", $$v)
                                      },
                                      expression: "row.arithmeticSymbol",
                                    },
                                  },
                                  _vm._l(
                                    _vm.arithmeticOperation,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: { label: item, value: item },
                                      })
                                    }
                                  ),
                                  1
                                ),
                                _c("el-input-number", {
                                  attrs: {
                                    placeholder: "请输入数字",
                                    controls: false,
                                    step: 0.01,
                                    size: "mini",
                                  },
                                  model: {
                                    value: row.compareValue,
                                    callback: function ($$v) {
                                      _vm.$set(row, "compareValue", $$v)
                                    },
                                    expression: "row.compareValue",
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "action", width: "120px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button-group",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCopyRow(row)
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteRow(row.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmCondition },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.conditionDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }