<template>
  <div class="new-box">
    <div class="mg-search" >
    <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="关键词">
          <el-input v-model="queryForm.pushDesc" clearable placeholder="请输入关键词" />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
        </el-form-item> -->
    </SearchFilter>
      <!-- </el-form> -->
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit">创建新推送</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
      class="customer-table"
      border
            size="small"
      v-loading="loading"
      ref="table"
      highlight-current-row
      @sort-change="handleSortChange"
    >
      <el-table-column label="推送描述" prop="pushDesc" width="280" />
      <el-table-column label="创建者" prop="createBy" width="90"/>
      <el-table-column label="推送状态" prop="pushStatus" width="90">
        <template #default="{ row }">
          <el-tag :type="$options.filters.statusFilter(row.pushStatus)">
            {{ statusMap[row.pushStatus] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" width="200" />
      <el-table-column label="预计推送时间" prop="expectPushTime" width="200" />
      <el-table-column label="实际推送时间" prop="pushTime" width="200" />

      <el-table-column label="预计推送人数" prop="expectPushNumbers"  width="140" />
      <el-table-column label="实际推送人数" prop="actualPushNumbers" width="140"/>
      <el-table-column label="已到达数" prop="arriveNumbers"  width="140"/>
      <el-table-column label="到达率" prop="arriveRate" >
        <template #default="{ row }">
          {{ row.arriveRate ? (row.arriveRate * 100).toFixed(1) + '%': '' }}
        </template>
      </el-table-column>
      <el-table-column label="点击数" prop="clickNumbers"  />
      <el-table-column label="点击率" prop="clickRate" >
        <template #default="{ row }">
          {{ row.clickRate ? (row.clickRate * 100).toFixed(1) + '%': '' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80" align="center">
        <template #default="{ row }">
          <div>
            <el-button size="mini" v-if="row.pushStatus===1" type="text" style="border: 0px;" @click="handleCancelPush(row.recordId)">取消推送</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog title="创建新推送"  :visible.sync="editDialog.visible" append-to-body width="800px" :close-on-click-modal="false">
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="160px" :model="editDialog.form" class="common-form">
        <el-row class="common-form-row">
          <div style="border: solid 1px rgb(78 72 72 / 10%); padding: 10px">
            <el-col>
              <span style="color: #1482f0; font-size: 24px;">基本信息</span>
            </el-col>
            <el-col :span="16">
              <el-form-item label="推送描述" prop="pushDesc">
                <el-input v-model="editDialog.form.pushDesc" clearable placeholder="建议13字以内" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="推送方式" prop="pushType">
                <el-checkbox-group v-model="editDialog.form.pushType">
                  <el-checkbox v-for="item in options.pushTypeOptions" :label="item.value" :key="item.value">{{ item.label }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="推送用户" prop="userType">
                <el-radio-group v-model="editDialog.form.userType" @input="val => handleRadioChange(val, 'user')">
                  <el-radio v-for="item in options.userTypeOptions" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <div v-show="editDialog.form.userType===2" style="background-color: #e5e9ec;display: inline-flex; ">
                  <div style="width: 150px;font-size: 14px; padding: 10px">
                    请选择分公司/配送中心(可多选)
                  </div>
                  <div style="max-height: 200px;overflow-y: scroll;width: 400px;margin: 10px;">
                    <el-input placeholder="输入关键字进行过滤" v-model="editDialog.filterDcText" size="mini" clearable @input="handleFilterChange" />
                    <el-tree
                      ref="orgDcTreeRef"
                      :data="options.orgDcTree"
                      show-checkbox
                      node-key="fullCode"
                      :accordion="true"
                      :filter-node-method="filterOrgDcNode"
                      @check="handleOrgDcChange"
                      :props="{ children: 'children', label: 'label', value: 'value' }">
                    </el-tree>
                  </div>
                </div>
                <div v-show="editDialog.form.userType===3" style="background-color: #e5e9ec;display: inline-flex; width: 80%">
                  <div style="width: 150px;font-size: 14px; padding: 10px">
                    请选择角色(可多选)
                  </div>
                  <div style="line-height: 50px;">
                    <el-select v-model="editDialog.form.userRoles" multiple style="width: 200px" filterable @change="val => {
                      if (val) {
                        editDialog.form.userContent = editDialog.form.userRoles.join(',')
                      } else {
                        editDialog.form.userContent = undefined;
                      }
                    }">
                      <el-option v-for="item in options.roles" :key="item.roleId" :value="item.roleId" :label="item.roleName" />
                    </el-select>
                  </div>
                </div>
                <div v-show="editDialog.form.userType===4" style="background-color: #e5e9ec;display: inline-flex; width: 80%">
                  <div style="width: 150px;font-size: 14px; padding: 10px">
                    请输入用户账号
                  </div>
                  <div style="line-height: 50px;">
                    <el-input v-model="editDialog.form.userContent" type="textarea" :span="4" :rows="3" />
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="推送落地页" prop="userType">
                <el-radio-group v-model="editDialog.form.contentType" @input="editDialog.form.content = undefined">
                  <el-radio v-for="item in options.contentTypeOptions" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
                </el-radio-group>
                <div  v-show="editDialog.form.contentType!==3" style="background-color: #e5e9ec;padding-top: 5px;padding-left: 20px;">
                  <div v-show="editDialog.form.contentType===1">
                    <div>
                      <span>请输入跳转链接</span><el-input v-model="editDialog.form.content" style="width:250px;margin-left: 15px" placeholder="请输入链接" size="mini" />
                    </div>
                    <div>
                      <span style="font-weight: 600;font-size: 14px;">常用链接</span>
                      <div v-for="e in options.commonlyUsedOptions" :key="e" class="link-text-class">{{ e }}</div>
                    </div>
                  </div>
                  <div v-show="editDialog.form.contentType===2">
                    <div>
                      <div style="width: 120px;display: inline-block;">请选择分公司(单选)</div>
                      <DictSelect
                        ref="org"
                        type="ht-org-all"
                        :value.sync="editDialog.form.taskObj.orgCode"
                        linkageRef="task"
                        :dictFun="dictOrgFun"
                        default-first-option
                        :defaultIndex="0"
                        style="width: 150px"
                        @changeLinkageRef="handleChangeLinkageRef"
                      />
                    </div>
                    <div>
                      <div style="width: 120px;display: inline-block;">请选择任务(单选)</div>
                      <DictSelect
                        ref="task"
                        type=""
                        :value.sync="editDialog.form.taskObj.taskId"
                        :dictFun="dictTaskFun"
                        parentLinkageRef
                        default-first-option
                        :defaultIndex="0"
                        style="width: 150px"
                        :init-props="{ label: 'taskName', value: 'mtTaskId'}"
                        @change="val => {
                          if (val) {
                            editDialog.form.content = val + '&taskRange=' + $refs.task.itemOptions.find(e => e.mtTaskId === val).taskRange;
                          } else {
                            editDialog.form.content = undefined;
                          }
                        }"
                      />
                    </div>
                  </div>
                  <div v-show="editDialog.form.contentType===4" style="display: inline-flex;">
                    <div style="width: 150px;font-size: 14px; padding: 10px">
                      请选择教程文章
                    </div>
                    <div style="line-height: 50px;">
                      <el-select v-model="editDialog.form.content" style="width: 200px" filterable>
                        <el-option v-for="item in options.courseOptions" :key="item.id" :value="item.id" :label="item.title" />
                      </el-select>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col>
              <span style="color: #1482f0; font-size: 24px;">推送内容</span>
            </el-col>
            <el-col :span="20">
              <el-form-item label="推送时间" prop="rangeType">
                <el-radio-group v-model="editDialog.form.rangeType">
                  <el-radio v-for="item in options.rangeOptions" :label="item.value" :key="item.value">
                    <div style="display: inline-block; width: 60px" v-html="item.label"></div>
                    <div style="display: inline-block;" v-show="item.value === 1">
                      <el-date-picker
                        v-model="editDialog.form.cronObj.fixDate"
                        type="datetime"
                        style="width: 150px"
                        placeholder="选择日期时间"
                        @change="handleFixDateChange" />
                    </div>

                    <div style="display: inline-block;" v-show="item.value === 2">
                      <el-select v-model="editDialog.form.cronObj.weekDays" multiple style="width: 150px">
                        <el-option v-for="e in options.weekOptions" :label="e.label" :value="e.value" :key="e.value" />
                      </el-select>
                      <el-time-picker
                        v-model="editDialog.form.cronObj.weekTime"
                        type="time"
                        style="width: 110px"
                        placeholder="选择时间" />
                    </div>
                    <div style="display: inline-block;" v-show="item.value === 3">
                      <el-select v-model="editDialog.form.cronObj.monthType" style="width: 110px">
                        <el-option v-for="e in options.monthOptions" :label="e.label" :value="e.value" :key="e.value" />
                      </el-select>
                      <el-input v-if="editDialog.form.cronObj.monthType === 'any'" v-model="editDialog.form.cronObj.monthDay" style="width: 150px" placeholder="请输入具体日，多个,分割" />
                      <el-time-picker
                        v-model="editDialog.form.cronObj.monthTime"
                        type="time"
                        style="width: 110px"
                        placeholder="选择时间" />
                    </div>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="推送标题" prop="title">
                <el-input v-model="editDialog.form.title" clearable placeholder="建议15字以内" />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="推送副标题" prop="subtitle">
                <el-input v-model="editDialog.form.subtitle" clearable placeholder="建议30字以内" />
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="推送图片" prop="imgUrl">
                <OssUpload ref="ossUpload" :value-to.sync="editDialog.form.imgUrl" list-type="picture-card" :maxFileSize="500" tips="尺寸1:1，500KB以下" :tipMessageShow="false" :accept-type="editDialog.imgTypes" />
              </el-form-item>
            </el-col>
<!--            <el-col :span="16">-->
<!--              <el-form-item label="上传头图" prop="coverImg">-->
<!--                <OssUpload ref="ossUpload" :value-to.sync="editDialog.form.coverImg" list-type="picture-card" :maxFileSize="1024" tips="尺寸330*120，1MB以下" :tipMessageShow="false" :accept-type="editDialog.imgTypes" />-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col>
              <span style="color: #1482f0; font-size: 24px;">测试</span><span style="color: #1482f0; font-size: 16px;">请务必先测试推送，无误后再正式推送</span>
            </el-col>
            <el-col :span="24">
              <el-form-item label="测试id" prop="testUsers">
                <span>{{ editDialog.defaultTestUsers.join('、') }}</span>
                <el-input v-model="editDialog.form.testUsers" style="width: 250px" clearable placeholder="如增加id请填写，多个用逗号分割" />
                <el-button type="primary" size="mini" @click="submitForm('test')">发送测试推送</el-button>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button :disabled="editDialog.form.test" type="primary" size="mini" @click="submitForm(undefined)">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageAppPushRecords, saveAppPushTask, cancelPushTask, getAppPushOptions, dictSelect, getTaskByOrg, getCoursePage } from "@/api/appTask";
import { parseParam, parseTime } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import OssUpload from "@/components/OssUpload";

export default {
  name: "AppPush",
  components: { Pagination, DictSelect, OssUpload, SearchFilter },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "primary",
        2: "warning",
        3: "success",
        4: "danger",
        5: "info",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        pushDesc: undefined,
      },
      lrDay: undefined,
      loading: false,
      dictParam: {},
      formDictParam: {},
      editDialog: {
        visible: false,
        loading: false,
        type: undefined,
        currentRow: {},
        imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        form: {
          taskId: undefined,
          pushDesc: undefined,
          pushType: [],
          userType: 1,
          userContent: undefined,
          userRoles: undefined,
          contentType: 1,
          pushContent: undefined,
          title: undefined,
          subtitle: undefined,
          rangeType: 1,
          rangeCron: undefined,
          cronObj: {
            dayType: undefined,
            fixDate: undefined,
            weekDays: undefined,
            weekTime: undefined,
            monthType: undefined,
            monthDay: undefined,
            monthTime: undefined,
          },
          taskObj: {
            orgCode: undefined,
            taskId: undefined,
          },
          imgUrl: '',
          testUsers: undefined,
          test: true
        },
        rules: {
          pushDesc: [{ required: true, message: " ", trigger: "change" }],
          pushType: [{ type: 'array', required: true, message: "请选择具体的推送方式 ", trigger: "change" }],
          userType: [{ required: true, message: " ", trigger: "change" }],
          userContent: [{ required: true, message: " ", trigger: "change" }],
          contentType: [{ required: true, message: " ", trigger: "change" }],
          pushContent: [{ required: true, message: " ", trigger: "change" }],
          title: [{ required: true, message: " ", trigger: "change" }],
          subtitle: [{ required: true, message: " ", trigger: "change" }],
        },
        defaultTestUsers: [], //
        otherTestUsers: undefined,
        filterDcText: undefined
      },
      jumpVal: undefined,
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      currentRow: {},
      options: {
        pushTypeOptions: [{label: 'App推送', value: 1}, { label: '站内信', value: 2}],
        userTypeOptions: [{label: '全部用户', value: 1}, { label: '按分公司/配送中心', value: 2}, { label: '按角色', value: 3}, { label: '按用户账号', value: 4}],
        contentTypeOptions: [{label: '自定义链接', value: 1}, { label: '店铺任务目标完成列表', value: 2}, { label: '版本升级提醒', value: 3}, { label: '教程', value: 4}],
        rangeOptions: [{label: '一次性&nbsp;&nbsp;&nbsp;', value: 1}, { label: '每周重复', value: 2}, { label: '每月重复', value: 3}],
        weekOptions: [
          { label: '周一', value: '2'},
          { label: '周二', value: '3'},
          { label: '周三', value: '4'},
          { label: '周四', value: '5'},
          { label: '周五', value: '6'},
          { label: '周六', value: '7'},
          { label: '周日', value: '1'}
        ],
        monthOptions: [{ value: 'first', label: '第一天'},{ value: 'any', label: '任意日期'},{ value: 'last', label: '最后一天'}],
        orgDcTree: [],
        orgs: [],
        roles: [],
        commonlyUsedOptions: ["个人日报 /my/report?reportType=daily", "个人月报 /my/report?reportType=month", "汇总日报 /workreport?_mode=switch&_type=day", "汇总月报 /workreport?_mode=switch&_type=month"],
        courseOptions: []
      },
      pickerOptions: {
        disabledDate(date) {
          const time = date.getTime() - new Date().getTime();
          return time < 5 * 60 * 1000;
        },
      },
      dictOrgFun: dictSelect,
      dictTaskFun: getTaskByOrg,
      fixUrl: {
        task: '/task/messageNotice?mtTaskId=',
        version: process.env.VUE_APP_JBP_DOWNLOAD,
        course: '/tutorial/detail?id='
      },
      statusMap: {
        1: "等待推送",
        2: "推送中",
        3: "推送成功",
        4: "推送失败",
        5: "已取消",
      },
      defaultUserObj: {
        development: ['wangsiyong'],
        test: ['wangsiyong','tongmeng'],
        uat: ['tongmeng'],
        production: ['xiajinlong','tongmeng']
      }
    };
  },
  computed: {
    cronExpression() {
      if (this.editDialog.form.rangeType === 1 && this.editDialog.form.cronObj.fixDate) {
        const time = parseTime(this.editDialog.form.cronObj.fixDate, "{y} {m} {d} {h} {i} {s}").replaceAll(' 0', ' ').split(' ');
        return time[5] + ' ' + time[4] + ' ' + time[3] + ' ' + time[2] + ' ' + time[1] + ' ? '  + time[0];
      } else if (this.editDialog.form.rangeType === 2 && this.editDialog.form.cronObj.weekDays && this.editDialog.form.cronObj.weekTime) {
        const time = parseTime(this.editDialog.form.cronObj.weekTime, "{h} {i} {s}").replaceAll(' 0', ' ').split(' ');
        return time[2] + ' ' + time[1] + ' ' + time[0] + ' ? * ' + this.editDialog.form.cronObj.weekDays.join(',');
      } else if (this.editDialog.form.rangeType === 3 && this.editDialog.form.cronObj.monthType && this.editDialog.form.cronObj.monthTime) {
        const time = parseTime(this.editDialog.form.cronObj.monthTime, "{h} {i} {s}").replaceAll(' 0', ' ').split(' ');
        const preCron = time[2] + ' ' + time[1] + ' ' + time[0] + ' ';
        if (this.editDialog.form.cronObj.monthType === 'any' && this.editDialog.form.cronObj.monthDay) {
          return preCron + this.editDialog.form.cronObj.monthDay.replaceAll('，', ',') + ' * ?'
        } else if (this.editDialog.form.cronObj.monthType === 'first') {
          return preCron + '1 * ?'
        } else if (this.editDialog.form.cronObj.monthType === 'last') {
          return preCron + 'L * ?'
        }
      }
      return undefined;
    },
    pushContent() {
      if (this.editDialog.form.contentType === 1) {
        if (!this.editDialog.form.content) {
          return undefined;
        }
        return JSON.stringify({ type: 'h5', path: this.editDialog.form.content})
      } else if (this.editDialog.form.contentType === 2) {
        if (!this.editDialog.form.content) {
          return undefined;
        }
        return JSON.stringify({ type: 'h5', path: this.fixUrl.task + this.editDialog.form.content})
      } else if (this.editDialog.form.contentType === 3) {
        return JSON.stringify({ type: 'browser', path: this.fixUrl.version})
      } else if (this.editDialog.form.contentType === 4) {
        if (!this.editDialog.form.content) {
          return undefined;
        }
        return JSON.stringify({ type: 'h5', path: this.fixUrl.course + this.editDialog.form.content})
      }
      return undefined;
    }
  },
  watch: {
  },
  created() {
    getAppPushOptions().then(res => {
      this.options.orgDcTree = res.data.orgDcTree;
      this.options.orgs = res.data.orgs;
      this.options.roles = res.data.roles;
    })
    getCoursePage(parseParam({size: 200, current: 1})).then(res => {
      this.options.courseOptions = res.data?.records;
    });
    this.editDialog.defaultTestUsers = this.defaultUserObj[process.env.NODE_ENV];
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageAppPushRecords(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleEdit() {
      this.editDialog.form = {
        taskId: undefined,
        pushDesc: undefined,
        pushType: [],
        userType: 1,
        userContent: undefined,
        userRoles: undefined,
        contentType: 1,
        pushContent: undefined,
        content: undefined,
        title: undefined,
        subtitle: undefined,
        rangeType: 1,
        rangeCron: undefined,
        cronObj: {
          dayType: undefined,
          fixDate: undefined,
          weekDays: undefined,
          weekTime: undefined,
          monthType: undefined,
          monthDay: undefined,
          monthTime: undefined,
        },
        taskObj: {
          orgCode: undefined,
          taskId: undefined,
        },
        imgUrl: '',
        test: true
      }
      this.editDialog.showDate = [];
      this.editDialog.visible = true;
    },
    handleFixDateChange(val) {
      if (val) {
        if ((val.getTime() - new Date().getTime()) < 10 * 60 * 1000) {
          Notification.info("选择的时间至少要大于当前时间10分钟");
          this.editDialog.form.cronObj.fixDate = undefined;
        }
      }
    },
    handleFilterChange(val) {
      this.$refs.orgDcTreeRef.filter(val);
    },
    filterOrgDcNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleOrgDcChange(node, data, c) {
      let checkedOrgCodes = [];
      let checkedDcCodes = [];
      console.log(data, node, c);
      data.checkedNodes.forEach(e => {
        // 分公司
        if (e.fullCode.split('-').length === 1) {
          checkedOrgCodes.push(e.value);
        } else if (data.halfCheckedKeys.indexOf(e.parentCode) < 0) {
          checkedOrgCodes.push(e.parentCode);
        } else {
          checkedDcCodes.push(e.fullCode.split("-")[1]);
        }
      })
      this.editDialog.form.userContent = JSON.stringify({ orgCodes: Array.from(new Set(checkedOrgCodes)), dcCodes: checkedDcCodes});
    },
    handleRadioChange(val, radio) {
      if (radio === 'user') {
        this.editDialog.form.userContent = undefined;
        this.editDialog.form.userRoles = undefined;
        this.$nextTick(() => {
          if (this.$refs.orgDcTreeRef) {
            this.$refs.orgDcTreeRef.setCheckedKeys([], true);
          }
        })
      } else if (radio === 'range') {
        this.editDialog.form.rangeContent = undefined;
      } else if (radio === 'content') {
        this.editDialog.form.pushContent = undefined;
        this.editDialog.form.content = undefined;
      }
    },
    submitForm(action) {
      // if (!this.editDialog.form.coverImg) {
      //   Notification.info("头图不能为空!");
      //   return;
      // }
      this.$refs.form.validate(valiad => {
        if (valiad) {
          if (!this.editDialog.form.pushType || this.editDialog.form.pushType.length === 0) {
            Notification.info("推送方式不能为空！");
            return;
          }
          if (!this.editDialog.form.userContent) {
            if (this.editDialog.form.userType === 2) {
              Notification.info("请勾选相应的分公司和配送中心！");
              return;
            } else if (this.editDialog.form.userType === 3) {
              Notification.info("请选择相应的角色！");
              return;
            } else if (this.editDialog.form.userType === 4) {
              Notification.info("请输入具体的用户账号！");
              return;
            }
          }
          if (!this.pushContent) {
            if (this.editDialog.form.contentType === 1) {
              Notification.info("请输入具体的跳转链接！");
              return;
            } else if (this.editDialog.form.contentType === 2) {
              Notification.info("请选择具体的任务！");
              return;
            } else if (this.editDialog.form.contentType === 4) {
              Notification.info("请选择具体的教程！");
              return;
            }
          }
          this.editDialog.form.pushContent = this.pushContent;
          if (!this.cronExpression) {
            Notification.info("请选择具体的日期时间！");
            return;
          }
          this.editDialog.form.rangeCron = this.cronExpression;
          let data = Object.assign({}, this.editDialog.form)
          if (action) {
            data.rangeType = 1;
            data.rangeCron = this.getFixTimeCron(new Date().getTime() + 20 * 1000);
            data.userType = 4;
            data.userContent = this.editDialog.defaultTestUsers.concat(this.editDialog.form.testUsers ? this.editDialog.form.testUsers.replaceAll('，', ',').split(','): []).join(',')
            data.title='[测试]' + data.title;
            data.pushDesc='[测试]' + data.pushDesc;
            data.test = true;
          } else if (data.userType === 1) {
            data.userContent = 'ALL';
          }
          data.pushType = data.pushType.join(',')
          this.loading = true;
          saveAppPushTask(data).then(res => {
            if (res.code === 200) {
              this.loading = false;
              Notification.success("操作成功!");
              if (action) {
                this.editDialog.form.test = false;
                this.handleFilter();
              } else {
                this.editDialog.visible = false;
                this.handleFilter();
              }
            }
          })
        }
      })
    },
    handleTestPush(row) {
      if (!row.dictLabelChange || row.dictLabelChange === '') {
        Notification.info("分类名不能为空!");
        return;
      }
      row.dictLabel = row.dictLabelChange;
      saveAppPushTask(row).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.editDialog.currentRow = {};
        }
      })
    },
    handleCancelPush(recordId) {
      this.$confirm('请确认是否取消推送，该操作不可逆！', '提示', { type: 'warning' }).then(() => {
        cancelPushTask(recordId).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功!");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    getFixTimeCron(date) {
      const time = parseTime(date, "{y} {m} {d} {h} {i} {s}").replaceAll(' 0', ' ').split(' ');
      return time[5] + ' ' + time[4] + ' ' + time[3] + ' ' + time[2] + ' ' + time[1] + ' ? '  + time[0];
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}
</style>
