<template>
  <el-container>
    <el-aside style="width: 20%">
      <div style="position: absolute; top: 80px;    width: inherit;">
        <div style="margin: 20px; font-size: 20px">组件</div>
        <el-row>
          <el-col :span="12" v-for="item in options.groups" :key="item.type" style="margin-bottom: 5px">
            <el-button type="info" style="width: 120px; color: black" plain :disabled="disableGroup(item.type)" @click="handleAddGroup(item)">{{ item.name }}</el-button>
          </el-col>
        </el-row>
        <div style="margin: 20px; font-size: 20px">数据</div>
        <el-row>
          <el-col :span="12" v-for="item in options.data" :key="item.type" style="margin-bottom: 5px">
            <el-button type="info" style="width: 120px; color: black" plain @click="handleAddGroup(item)">{{ item.name }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-aside>
    <el-container style="max-width: 45%">
      <el-main>
        <el-form ref="configForm" size="mini" label-width="auto" :inline="true" label-position="left" :model="form" :rules="rules" style="max-height: 100%">
          <el-row>
            <el-col>
              <span style="color: #1482f0; font-size: 24px;">基础设置<span style="font-size: 20px">（创建者须填写）</span></span>
            </el-col>
            <el-col>
              <el-form-item label="活动名称" prop="name">
                <el-input v-model="form.name" max-length="13" show-word-limit placeholder="建议13字以内" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="核销类型" prop="type">
                <el-radio-group v-model="form.type" @input="handleTypeChange">
                  <el-radio label="1">活动核销</el-radio>
                  <el-radio label="2">终端核销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="审核流程" prop="flowType">
                <el-select v-model="form.flowType">
                  <el-option v-for="item in options.flowTypeOptions" :key="item.value" :value="item.value" :label="item.label" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="展示时间" prop="startDate">
                <el-date-picker
                  v-model="form.showDate"
                  type="daterange"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  @change="val => handleDateChange(val)"
                  format="yyyy-MM-dd"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="分公司范围" prop="orgCodes">
                <DictSelect
                  ref="org"
                  type="org"
                  :value.sync="form.orgCodes"
                  linkageRef="dc"
                  fullLabel
                  multiple
                  :dictFun="dictSelect"
                  clearable collapse-tags
                  placeholder="请选择分公司"
                  @change="handleOrgChange"
                />
                <el-button class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
                <el-button class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(false)">取消全选</el-button>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="显示图标">
                <OssUpload ref="ossUpload" :value-to.sync="form.shareIcon" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" tips="尺寸120*120,正方形图标" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="所属分组">
                <DictSelect
                  ref="tag"
                  type="tag"
                  :value.sync="form.tagName"
                  init
                  :init-options="options.tagNames"
                  clearable
                  singleProps
                  allow-create
                  placeholder="请选择分公司"
                />
              </el-form-item>
            </el-col>
            <el-col>
              <span style="color: #1482f0; font-size: 24px;">活动资料表单配置<span style="font-size: 20px">（用户填写项，此处为展示）</span></span>
            </el-col>
            <el-col>
              <el-row v-for="(item,index) in form.fields" :key="index">
                <el-col :span="12" style="background-color: #f8f8f8; margin: 5px; padding: 5px; border-radius: 1px;" :class="currentGroup && currentGroup.id==item.id? 'select-group': ''">
                  <el-form-item>
                    <template #label>
                      <span>{{ item.fieldLabel? item.fieldLabel: '文本' }}</span><span v-if="item.required" style="color: red">*</span>

                    </template>
                    <div v-if="item.fieldType === 'upload-module'">
                      <div v-if="!item.configJson.isOnlyBefore">
                        <span>上传照片</span><span v-if="item.required" style="color: red">*</span>
                        <span>{{ '(最少' + item.configJson.min + '张,最多' + item.configJson.max + '张)' }}</span>
                        <el-row>
                          <el-col :span="12"><span>打造前</span><span v-if="item.required" style="color: red">*</span><span>{{ '(最少' + item.configJson.min + '张,最多' + item.configJson.max + '张)' }}</span></el-col>
                          <el-col :span="12"><span>打造后</span><span v-if="item.required" style="color: red">*</span><span>{{ '(最少' + item.configJson.afterMin + '张,最多' + item.configJson.afterMax + '张)' }}</span></el-col>
                        </el-row>
                      </div>
                    </div>
                    <span v-if="item.fieldType === 'select'">{{ item.configJson.multiple? '(多选)': '(单选)' }}</span>
                    <span v-else-if="item.fieldType === 'upload'">{{ '(最少' + item.configJson.min + '张,最多' + item.configJson.max + '张)' }}</span>
                    <div v-if="item.fieldType === 'upload'">{{ item.configJson.description }}</div>
                    <div v-else-if="item.fieldType ==='data'">{{ item.configJson.description }}</div>


                    <el-input v-if="item.fieldType === 'input'" v-model="item.value" readonly :placeholder="item.configJson.placeholder? item.configJson.placeholder: '请输入'"/>
                    <el-input v-else-if="item.fieldType === 'input-number'" v-model="item.value" readonly :placeholder="item.configJson.placeholder? item.configJson.placeholder: '请输入'"/>
<!--                    <el-input v-else-if="item.fieldType === 'text-title'" v-model="item.value" readonly :placeholder="item.configJson.placeholder? item.configJson.placeholder: '请输入标题'"/>-->
                    <span v-else-if="item.fieldType === 'text-shop-info'" >xxxx店铺 C11111/0001</span>
                    <el-select v-else-if="item.fieldType === 'select'" v-model="item.value" disabled :placeholder="item.configJson.placeholder? item.configJson.placeholder: '请选择'">
                    </el-select>
                    <el-select v-else-if="item.fieldType === 'dc-select'" v-model="item.value" disabled :placeholder="item.configJson.placeholder? item.configJson.placeholder: '请选择'">
                    </el-select>
                    <el-date-picker v-else-if="item.fieldType === 'date'" v-model="item.value" type="date" readonly :placeholder="item.configJson.placeholder? item.configJson.placeholder: '请选择日期'">
                    </el-date-picker>
                    <el-date-picker v-else-if="item.fieldType === 'date-range'" v-model="item.value" type="daterange" readonly range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                    <OssUpload v-else-if="item.fieldType === 'upload'" :ref="'ossUpload' + index" :value-to.sync="item.value" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                    <el-row v-else-if="item.fieldType === 'upload-module'" >
                      <el-col :span="12">
                        <OssUpload :ref="'ossUploadBefore' + index" :value-to.sync="item.value" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                      </el-col>
                      <el-col :span="12">
                        <OssUpload :ref="'ossUploadAfter' + index" :value-to.sync="item.value" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                      </el-col>
                    </el-row>
                    <div v-else-if="item.fieldType === 'data'">
                      <el-row>
                        <el-col :span="8"><label>字段</label></el-col>
                        <el-col :span="8"><label>注释</label></el-col>
                        <el-col :span="8"><label>目标</label></el-col>
                      </el-row>
                      <el-row v-for="(c, cIndex) in item.quotas.filter(e => e.deleted==0)" :key="cIndex">
                        <el-col :span="8">{{ c.fieldName }}</el-col>
                        <el-col :span="8">{{ c.fieldExplain }}</el-col>
                        <el-col :span="8">{{ c.target }}</el-col>
                      </el-row>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="7" style="padding: 5px; margin: 5px;">
                  <el-button type="primary" size="mini"  @click="() => currentGroup = item">选中</el-button>
                  <el-button :disabled="item.fieldType==='dc-select'" type="primary" size="mini" @click="() => { form.fields.splice(index, 1); $forceUpdate();
                    if (currentGroup && currentGroup.id === item.id) {
                      currentGroup = undefined
                    }
                  }">删除</el-button>
                  <el-button :disabled="form.fields.length<= 1" size="mini" type="primary" @click="() => {form.fields.splice(index, 1); form.fields.splice(index - 1, 0, item)}">上移</el-button>
                  <el-button :disabled="form.fields.length<= 1" size="mini" type="primary" @click="() => {form.fields.splice(index, 1); form.fields.splice(index + 1, 0, item)}">下移</el-button>
                </el-col>

                <el-col v-if="options.groupKeys.indexOf(item.fieldType) > -1"><span style="color: #1482f0; font-size: 24px;">子组件配置</span></el-col>
                <el-col style="width: 95%; float: right">
                  <el-row v-for="(cItem,cIndex) in item.fields" :key="cIndex" style="margin-left: 20px">
                    <el-col :span="12" style="background-color: #f8f8f8; margin: 5px; padding: 5px; border-radius: 1px;" :class="currentGroup && currentGroup.id==cItem.id? 'select-group': ''">
                      <el-form-item>
                        <template #label>
                          <span>{{ cItem.fieldLabel? cItem.fieldLabel: '文本' }}</span><span v-if="cItem.required" style="color: red">*</span>
                        </template>
                        <div v-if="cItem.fieldType === 'upload-module'">
                          <div v-if="!cItem.configJson.isOnlyBefore">
                            <span>上传照片</span><span v-if="cItem.required" style="color: red">*</span>
                            <el-row>
                              <el-col :span="12"><span>打造前</span><span v-if="cItem.required" style="color: red">*</span><span>{{ '(最少' + cItem.configJson.min + '张,最多' + cItem.configJson.max + '张)' }}</span></el-col>
                              <el-col :span="12"><span>打造后</span><span v-if="cItem.required" style="color: red">*</span><span>{{ '(最少' + cItem.configJson.afterMin + '张,最多' + cItem.configJson.afterMax + '张)' }}</span></el-col>
                            </el-row>
                          </div>
                        </div>
                        <span v-if="cItem.fieldType === 'select'">{{ cItem.multiple? '(多选)': '(单选)' }}</span>
                        <span v-else-if="cItem.fieldType === 'upload'">{{ '(最少' + cItem.configJson.min + '张,最多' + cItem.configJson.max + '张)' }}</span>

                        <div v-if="cItem.fieldType === 'upload'">{{ cItem.configJson.description }}</div>
                        <div v-else-if="cItem.fieldType ==='data'">({{ cItem.configJson.description }})</div>

                        <el-input v-if="cItem.fieldType === 'input'" v-model="cItem.value" readonly :placeholder="cItem.configJson.placeholder? cItem.configJson.placeholder: '请输入'"/>
                        <el-input v-else-if="cItem.fieldType === 'input-number'" v-model="cItem.value" readonly :placeholder="cItem.configJson.placeholder? cItem.configJson.placeholder: '请输入'"/>
<!--                        <el-input v-else-if="cItem.fieldType === 'text-title'" v-model="cItem.value" readonly :placeholder="cItem.configJson.placeholder? cItem.configJson.placeholder: '请输入标题'"/>-->
                        <span v-else-if="cItem.fieldType === 'text-shop-info'" >xxxx店铺 C11111/0001</span>
                        <el-select v-else-if="cItem.fieldType === 'select'" v-model="cItem.value" disabled :placeholder="cItem.configJson.placeholder? cItem.configJson.placeholder: '请选择'">
                        </el-select>
                        <el-select v-else-if="cItem.fieldType === 'dc-select'" v-model="cItem.value" disabled :placeholder="cItem.configJson.placeholder? cItem.configJson.placeholder: '请选择'">
                        </el-select>
                        <el-date-picker v-else-if="cItem.fieldType === 'date'" v-model="cItem.value" type="date" readonly :placeholder="cItem.configJson.placeholder? cItem.configJson.placeholder: '请选择日期'">
                        </el-date-picker>
                        <el-date-picker v-else-if="cItem.fieldType === 'date-range'" v-model="cItem.value" type="daterange" readonly range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                        <OssUpload v-else-if="cItem.fieldType === 'upload'" :ref="'ossUpload' + cIndex" :value-to.sync="cItem.value" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                        <el-row v-else-if="cItem.fieldType === 'upload-module'" >
                          <el-col :span="12">
                            <OssUpload :ref="'ossUploadBefore' + cIndex" :value-to.sync="cItem.value" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                          </el-col>
                          <el-col :span="12">
                            <OssUpload :ref="'ossUploadAfter' + cIndex" :value-to.sync="cItem.value" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                          </el-col>
                        </el-row>
                        <div v-else-if="cItem.fieldType === 'data'">
                          <el-row>
                            <el-col :span="8"><label>字段</label></el-col>
                            <el-col :span="8"><label>注释</label></el-col>
                            <el-col :span="8"><label>目标</label></el-col>
                          </el-row>
                          <el-row v-for="(c, cIndex) in cItem.quotas.filter(e => e.deleted==0)" :key="cIndex">
                            <el-col :span="8">{{ c.fieldName }}</el-col>
                            <el-col :span="8">{{ c.fieldExplain }}</el-col>
                            <el-col :span="8">{{ c.target }}</el-col>
                          </el-row>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="7" style="padding: 5px; margin: 5px;">
                      <el-button type="primary" size="mini"  @click="() => currentGroup = cItem">选中</el-button>
                      <el-button :disabled="cItem.fieldType==='dc-select'" type="primary" size="mini" @click="() => {
                        item.fields.splice(cIndex, 1);
                        if (currentGroup && currentGroup.id === cItem.id) {
                          currentGroup = undefined
                        }
                        $forceUpdate();
                      }">删除</el-button>
                      <el-button :disabled="item.fields.length<= 1" size="mini" type="primary" @click="() => {item.fields.splice(cIndex, 1); item.fields.splice(cIndex - 1, 0, cItem)}">上移</el-button>
                      <el-button :disabled="item.fields.length<= 1" size="mini" type="primary" @click="() => {item.fields.splice(cIndex, 1); item.fields.splice(cIndex + 1, 0, cItem)}">下移</el-button>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-container>
    <el-aside style="overflow-y: hidden; width: 35% !important;">
      <div style="position: absolute; top: 80px; width: -webkit-fill-available">
        <el-row>
          <span style="color: #1482f0; font-size: 24px; margin: 20px">组件配置属性</span>
        </el-row>
        <el-row v-if="currentGroup">
          <el-row style="margin-bottom: 5px">
            <el-col :span="4" style="margin: 5px 0px">
              <label class="json-class">文本名称</label>
            </el-col>
            <el-col :span="17">
              <el-input v-model="currentGroup.fieldLabel" size="mini" placeholder="请输入文本名称" />
            </el-col>
          </el-row>
          <el-row v-if="options.noInputKeys.indexOf(currentGroup.fieldType) < 0" style=" margin-bottom: 10px">
            <el-col :span="4">
              <label class="json-class">是否必填</label>
            </el-col>
            <el-col :span="17">
              <el-radio-group v-model="currentGroup.required">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-col v-for="(item, key) in currentGroup.configJson" :key="key">
            <el-row style=" margin-bottom: 10px">
              <el-col :span="4" style="margin: 5px 0px;">
                <label class="json-class">{{ keyLabelMap[key] }}</label>
              </el-col>
              <el-col :span="17">
                <el-radio-group v-if="options.booKeys.indexOf(key) > -1" :disable="currentGroup.fieldType === 'dc-select'" v-model="currentGroup.configJson[key]">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <el-input v-else-if="options.strJKeys.indexOf(key) > -1" v-model="currentGroup.configJson[key]" size="mini" placeholder="请输入" />
                <el-input-number v-else-if="options.numKeys.indexOf(key) > -1" v-model="currentGroup.configJson[key]" size="mini" :control="false" :step="0" :precision="0" placeholder="请输入" />
                <el-select v-else-if="(key === 'include' || key==='includeAfter') && currentGroup.fieldType.indexOf('upload') > -1" v-model="currentGroup.configJson[key]" size="mini" multiple>
                  <el-option v-for="o in options.mediaOptions" :key="o.value" :label="o.label" :value="o.value" />
                </el-select>
                <div v-if="key === 'columns'">
                  <el-row v-for="(c, cIndex) in currentGroup.configJson[key]" :key="cIndex">
                    <el-col :span="9"><label>文本</label> <el-input v-model="c.label" size="mini" placeholder="请输入" style="width:100px" /></el-col>
                    <el-col :span="9"><label>值</label> <el-input v-model="c.value" size="mini" placeholder="请输入" style="width:100px" /></el-col>
                    <el-col :span="6">
                      <el-button type="text" plain size="mini" @click="() => { currentGroup.configJson[key].push({value: undefined, label: undefined}) }" icon="el-icon-plus" />
                      <el-button type="text" plain size="mini" :disabled="currentGroup.configJson[key].length== 1" @click="() => { currentGroup.configJson[key].splice(cIndex, 1); $forceUpdate(); }" icon="el-icon-delete" />
                      <el-button type="text" plain size="mini" :disabled="cIndex== 0" @click="() => { currentGroup.configJson[key].splice(cIndex, 1); currentGroup.configJson[key].splice(cIndex - 1, 0, c)}" icon="el-icon-top" />
                      <el-button type="text" plain size="mini" :disabled="cIndex==currentGroup.configJson[key].length-1" @click="() => { currentGroup.configJson[key].splice(cIndex, 1); currentGroup.configJson[key].splice(cIndex + 1, 0, c)}" icon="el-icon-bottom" />
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-row v-if="currentGroup.fieldType == 'data' && currentGroup.quotas" style=" margin-bottom: 10px">
            <el-col :span="4">
              <label class="json-class">展示字段</label>
            </el-col>
            <el-col :span="17">
              <el-row v-for="(c, cIndex) in currentGroup.quotas" :key="cIndex">
                <el-col :span="7" style="margin: 5px 0px"><label>{{ c.fieldName }}</label></el-col>
                <el-col :span="7"> <el-input v-model="c.fieldExplain" size="mini" style="width:100%" placeholder="请输入文本注释" /></el-col>
                <el-col :span="3"> <el-input v-model="c.target" size="mini" style="width:100%" placeholder="请输入目标" /></el-col>
                <el-col :span="4"> <el-switch v-model="c.deleted" :active-value="0" :inactive-value="1" /></el-col>
                <el-col :span="3">
                  <el-button type="text" plain size="mini" :disabled="cIndex== 0" @click="() => { currentGroup.quotas.splice(cIndex, 1); currentGroup.quotas.splice(cIndex - 1, 0, c)}" icon="el-icon-top" />
                  <el-button type="text" plain size="mini" :disabled="cIndex==currentGroup.quotas.length-1" @click="() => { currentGroup.quotas.splice(cIndex, 1); currentGroup.quotas.splice(cIndex + 1, 0, c)}" icon="el-icon-bottom" />
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-aside>
  </el-container>
</template>

<script>
import { dictSelect, getQhHxBasicOptions, getQdHxTable, getQdHxTableField } from "@/api/appTask";
import DictSelect from "@/components/DictSelect";
import OssUpload from "@/components/OssUpload";
import { parseTime, deepClone } from "@/utils"
import { v4 as uuidv4 } from "uuid";
import {Notification} from "element-ui";
export default {
  name: "index",
  components: { DictSelect, OssUpload },
  props: {
    data: {
      type: Object
    },
    timestamp: {
      type: Number,
    }
  },
  data() {
    return {
      deepClone,
      dictSelect,
      form:  {
        id: undefined,
        name: undefined,
        type: '1',
        flowType: '1',
        showDate: [],
        startDate: undefined,
        endDate: undefined,
        orgCode: undefined,
        orgName: undefined,
        tagName: undefined,
        iconUrl: undefined,
        deleted: 0,
        authOrgCodes: [ ],
        orgCodes: [ ],
        fields: []
      },
      rules: {
        name: [{ required: true, message: " ", trigger: "change" }],
        type: [{ required: true, message: " ", trigger: "change" }],
        flowType: [{ required: true, message: " ", trigger: "change" }],
        startDate: [{ required: true, message: " ", trigger: "change" }],
        orgCodes: [{ type: 'array', required: true, message: " ", trigger: "change" }]
      },
      defaultField: {
        id: undefined,
        hxId: undefined,
        moduleType: undefined,
        fieldLabel: undefined,
        fieldType: undefined,
        required: false,
        configJson: {},
        deleted: 0,
        sort: 0,
        desc: undefined,
        fields: [],
        quotas: []
      },
      configJson: {
        defaultJson: {
        },
        "input-number": {
          unit: undefined,
          placeholder: "请输入",
          showTable: false,
          showQuery: false,
          showAppQuery: false
        },
        input: {
          unit: undefined,
          placeholder: "请输入",
          showTable: false,
          showQuery: false,
          showAppQuery: false
        },
        select: {
          multiple: false,
          placeholder: "请选择",
          showTable: false,
          showQuery: false,
          showAppQuery: false,
          columns: [{ value: '保存的值', label: '显示的文本'}],
        },
        "dc-select": {
          multiple: false,
          dcAll: false,
          placeholder: "请选择",
          showTable: false,
          showQuery: false,
          showAppQuery: false
        },
        "choose-shop": {
          min: 1,
          max: 1,
          isOnlyTop: false
        },
        data: {
          description: undefined,
        },
        group: {
          min: 1,
          max: 1,
        },
        upload: {
          min: 1,
          max: 1,
          include: ['image-camera', 'image-gallery'],
          description: undefined,
        },
        "upload-module": {
          isOnlyBefore: false,
          min: 1,
          max: 1,
          description: undefined,
          include: ['image-camera', 'image-gallery'],
          afterMin: 1,
          afterMax: 1,
          includeAfter: ['image-camera', 'image-gallery'],
        },
        date: {
          placeholder: "请选择",
          showTable: false,
          showQuery: false,
          showAppQuery: false
        }
      },
      options: {
        flowTypeOptions: [{ value: '1', label: '仅分公司审核'}, { value: '2', label: '仅总部审核'},{ value: '3', label: '分公司+总部审核'},{ value: '4', label: '分公司审核+总部抽查'},],
        tagNames: [],
        groups:[{ type: "input", name: "文本"}, { type: "input-number", name: "数字"},{ type: "select", name: "下拉选项"}, { type: "date", name: "单选日期"},
          { type: "date-range", name: "日期范围"}, { type: "choose-shop", name: "选择终端组件"}, { type: "upload", name: "上传照片"},
          { type: "upload-module", name: "上传阵地照片"}, { type: "text-title", name: "标题"}, { type: "text-shop-info", name: "店铺信息"}, { type: "group", name: "分组组件"}],
        data: [{ type: "data", name: "数组展示示"}],
        mediaOptions: [ { value: 'image-camera', label: '拍照'}, { value: 'image-gallery', label: '相册照片'}, { value: 'video-camera', label: '录视频'}, { value: 'video-gallery', label: '视频相册'}],
        numKeys: ['min', 'max', 'afterMin', 'afterMax'],
        strJKeys: ['description', 'placeholder'],
        booKeys: ['isOnlyBefore', 'showQuery', 'showTable', 'showAppQuery', 'multiple', 'dcAll', 'isOnlyTop'],
        groupKeys: ['choose-shop', 'group'],
        noInputKeys: ['text-title', 'text-shop-info'],
        tables: [],
        fields: []
      },
      keyLabelMap: {
        placeholder: '空文本框提示',
        multiple: '是否多选',
        min: '最小数量',
        max: '最大数量',
        include: '照片类型',
        includeAfter: '打造后照片类型',
        description: '描述',
        afterMin: '打造后最小数量',
        afterMax: '打造后最大数量',
        isOnlyBefore: '仅打造前',
        showQuery: '是否作为后台查询条件',
        showTable: '是否在后台列表显示',
        showAppQuery: '是否在APP端作为查询条件',
        dcAll: '是否有"全部"选项',
        columns: '可选项',
        isOnlyTop: '是否仅重点'
      },
      currentGroup: undefined
    }
  },
  watch: {
    timestamp: {
      handler() {
        if (this.data && this.data.id) {
          getQdHxTable(this.data.id).then(res => {
            this.options.tables = res.data;
          })
          let tmp = deepClone(this.data);
          tmp.fields.forEach(e => {
            if (e.configJson) {
              e.configJson = Object.assign({}, this.configJson.defaultJson, this.configJson[e.fieldType]? this.configJson[e.fieldType]: {}, JSON.parse(e.configJson))
            } else {
              e.configJson = Object.assign({}, this.configJson.defaultJson, this.configJson[e.fieldType]? this.configJson[e.fieldType]: {})
            }
            if (e.fields && e.fields.length > 0) {
              e.fields.forEach(f => {
                if (f.configJson) {
                  f.configJson = Object.assign({}, this.configJson.defaultJson, this.configJson[f.fieldType]? this.configJson[f.fieldType]: {}, JSON.parse(f.configJson))
                } else {
                  f.configJson = Object.assign({}, this.configJson.defaultJson, this.configJson[f.fieldType]? this.configJson[f.fieldType]: {})
                }
              })
            }
          })
          tmp.showDate = [tmp.startDate, tmp.endDate]
          this.currentGroup = undefined;
          this.form = deepClone(tmp);
        } else {
          this.resetForm();
        }
      },
      immediate: true,
    }
  },
  mounted() {
    getQhHxBasicOptions().then(res => {
      if (res.code ==200) {
        this.options.tagNames = res.data.tagNames;
      }
    })
  },
  methods: {
    handleDateChange(val) {
      if (val) {
        this.form.startDate = parseTime(val[0], "{y}-{m}-{d} {h}:{i}:{s}");
        this.form.endDate = parseTime(val[1], "{y}-{m}-{d} {h}:{i}:{s}");
      } else {
        this.form.startDate = undefined;
        this.form.endDate = undefined;
      }
    },
    handleAddGroup(item) {
      let field = deepClone(this.defaultField);
      field.id = uuidv4().replaceAll('-', '');
      let configJson = deepClone(Object.assign({}, this.configJson.defaultJson, this.configJson[item.type]? this.configJson[item.type]: {}));
      field.configJson = configJson;
      field.fieldType = item.type;
      field.fieldLabel = item.name;
      field.moduleType = this.form.type === '2'? ' 1':'0';

      if (this.currentGroup && this.currentGroup.id && (this.options.groupKeys.indexOf(this.currentGroup.fieldType) > -1 || this.form.fields.filter(e => e.id == this.currentGroup.id).length <1)) {
        this.form.fields.forEach(e => {
          if (this.options.groupKeys.indexOf(e.fieldType) > -1) {
            field.moduleType='choose-shop' == e.fieldType? '0': '1'
            e.fields.push(field);
          }
        })
      } else {
        this.form.fields.push(field);
      }
    },
    disableGroup(type) {
      return (this.form.fields.filter(field => this.options.groupKeys.indexOf(field.fieldType) > -1 && this.options.groupKeys.indexOf(type) > -1).length > 0 || (this.form.type =='2' && 'choose-shop'==type))
    },
    handleTypeChange(val) {
      if (val =='2' && this.form.fields.filter(field => field.fieldType==='choose-shop').length > 0) {
        this.$confirm('终端核销不支持使用选择终端组件，请确认是否仍然调整为终端核销，如确认调整将自动移除该组件！', '提示', { type: 'warning' }).then(() => {
          this.form.fields = this.form.fields.filter(field => field.fieldType!=='choose-shop');
        }).catch(() => {
          this.form.type='1'
        })
      }
    },
    handleOrgChange(val) {
      if (val) {
        if (val.length >1) {
          this.form.orgCode = 'ALL';
          this.form.orgName = '全部'
        } else {
          this.form.orgCode = val[0];
          this.$nextTick(() => {
            const item = this.$refs.org.itemOptions.filter(e => e.value == val[0]);
            this.form.orgName = item.label;
          })
        }
      } else {
        this.form.orgCode =  undefined;
        this.form.orgName = undefined;
      }
    },
    resetForm() {
      this.form = {
        id: undefined,
        name: undefined,
        type: '1',
        flowType: '1',
        showDate: [],
        startDate: undefined,
        endDate: undefined,
        orgCode: undefined,
        orgName: undefined,
        tagName: undefined,
        iconUrl: undefined,
        deleted: 0,
        authOrgCodes: [ ],
        orgCodes: [ ],
        fields: []
      }
      this.form.fields.push({
        id: uuidv4(),
        hxId: undefined,
        moduleType: '0',
        fieldLabel: '配送中心',
        fieldType: 'dc-select',
        required: true,
        configJson: {
          multiple: false,
          dcAll: false,
          placeholder: "请选择",
          showTable: true,
          showQuery: true,
          showAppQuery: true
        },
        deleted: 0,
        sort: 0,
        fields: [],
        quotas: []
      });
      this.currentGroup = undefined
    },
    handleCheckAll(checked) {
      this.form.orgCodes = [];
      if (checked && this.$refs.org) {
        this.$nextTick(() => {
          this.form.orgCodes = this.$refs.org.itemOptions.map(e => e.value);
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-upload--picture-card {
    height:30px;
    width: 80px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:80px;
    width: 60px;
  }
  .select-group {
    border: black 1px solid;
  }
  .json-class {
    //width: 140px;
    //margin: 5px;
  }
}

</style>
