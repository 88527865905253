var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQueryAll, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQueryAll.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.defaultOrgCode
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c("DictSelect", {
                            ref: "org",
                            attrs: {
                              type: "ht-org",
                              value: _vm.queryForm.orgCode,
                              linkageRef: "dc",
                              init: "",
                              initOptions: _vm.orgDictInitOptions,
                              fullLabel: "",
                              clearable: false,
                              "default-index": 0,
                              placeholder: "请选择分公司",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "orgCode",
                                  $event
                                )
                              },
                              changeLinkageRef: _vm.handleQueryChangeLinkageRef,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "ht-dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.queryForm),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入用户账号或姓名",
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryForm.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "userName", $$v)
                          },
                          expression: "queryForm.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职级" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item filter-set-width",
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.queryForm.positionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "positionId", $$v)
                            },
                            expression: "queryForm.positionId",
                          },
                        },
                        _vm._l(_vm.positionOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否主要负责人" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item filter-set-width",
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.queryForm.principal,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "principal", $$v)
                            },
                            expression: "queryForm.principal",
                          },
                        },
                        _vm._l(_vm.options.principalOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item filter-set-width",
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status",
                          },
                        },
                        _vm._l(_vm.options.userStatusOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 7 } },
            [
              _c(
                "el-card",
                { staticStyle: { margin: "10px" } },
                [
                  _c(
                    "el-scrollbar",
                    { staticStyle: { height: "calc(100vh)", width: "100%" } },
                    [
                      _c("el-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.aside.loading,
                            expression: "aside.loading",
                          },
                        ],
                        ref: "tree",
                        staticClass: "unselect",
                        attrs: {
                          "node-key": "id",
                          "highlight-current": true,
                          data: _vm.aside.treeData,
                          props: _vm.aside.defaultProps,
                          "default-expanded-keys": _vm.aside.nodeKey,
                          "expand-on-click-node": false,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ node, data }) {
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c("span", [
                                    data.positionId === 1 ||
                                    data.positionId === 3
                                      ? _c("i", {
                                          staticClass: "el-icon-folder",
                                        })
                                      : _c("i", {
                                          staticClass: "el-icon-user",
                                        }),
                                    _vm._v(_vm._s(node.label) + " "),
                                    _c("i", { staticClass: "positionColor" }, [
                                      _vm._v(
                                        " [" +
                                          _vm._s(
                                            _vm.positionOptions
                                              .filter(
                                                (item) =>
                                                  item.value === data.positionId
                                              )
                                              .map((item) => item.label)[0]
                                          ) +
                                          "]"
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 17 } },
            [
              _c(
                "el-card",
                { staticStyle: { margin: "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit(undefined)
                        },
                      },
                    },
                    [_vm._v("新增组织用户")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: { click: _vm.handleImport },
                    },
                    [_vm._v("组织用户导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("组织用户导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-delete",
                        type: "warning",
                      },
                      on: { click: _vm.handleBatchDelete },
                    },
                    [_vm._v("删除组织用户")]
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        data: _vm.pageData.rows,
                        size: "small",
                        "row-class-name": "rowClassName",
                        "show-checkbox": true,
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "88" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "用户", prop: "userName" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.userName + "-" + row.nickName)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "账号状态", prop: "status" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "分公司", prop: "orgCode" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.orgCode + "-" + row.orgName)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "配送中心", prop: "dcCode" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.dcCode === "ALL"
                                        ? ""
                                        : row.dcCode + "-" + row.dcName
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "职级", prop: "position" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "是否主要负责人", prop: "principal" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "岗位名称", prop: "positionName" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "团队名称", prop: "teamName" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.positionId === 6 ? "" : row.teamName
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "主要负责人", prop: "principalUser" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.principalUserName === null
                                        ? ""
                                        : row.principalUserName +
                                            "-" +
                                            row.principalNickName
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "上级", prop: "parentUser" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.parentUserName === null
                                        ? ""
                                        : row.parentUserName +
                                            "-" +
                                            row.parentNickName
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          prop: "remark",
                          "show-tooltip-when-overflow": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          width: "180px",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelRow(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                                row.positionId < 6
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0px" },
                                        attrs: {
                                          disabled:
                                            row.principal === "是" &&
                                            row.positionId < 6,
                                          size: "mini",
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChangeMain(row)
                                          },
                                        },
                                      },
                                      [_vm._v("设为主负责人")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("Pagination", {
                    attrs: {
                      total: _vm.pageData.total,
                      page: _vm.queryForm.current,
                      limit: _vm.queryForm.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.queryForm, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.queryForm, "size", $event)
                      },
                      pagination: _vm.handleQuery,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialog.form.id ? "编辑组织人员" : "新增组织人员",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "450px",
            "close-on-click-modal": false,
            top: "5%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editFormRef",
              attrs: {
                size: "mini",
                "label-width": "120px",
                "label-position": "right",
                inline: true,
                model: _vm.editDialog.form,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "关联用户",
                    prop: "userName",
                    required: true,
                  },
                },
                [
                  _c("DictSelect", {
                    ref: "user",
                    attrs: {
                      type: "user",
                      disabled: _vm.editDialog.form.id !== undefined,
                      value: _vm.editDialog.form.userName,
                      dictFun: _vm.dictUserFun,
                      fullLabel: "",
                      remote: "",
                      version: "v3",
                      placeholder: "请输入用户账号或姓名",
                      "init-props": { label: "nickName", value: "userName" },
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.editDialog.form, "userName", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ pageSize: 50, pageNum: 1 }),
                      change: _vm.handleRespChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分公司", prop: "orgCode", required: true } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      disabled: _vm.editDialog.form.id !== undefined,
                      value: _vm.editDialog.form.orgCode,
                      linkageRef: "dc",
                      init: "",
                      initOptions: _vm.orgDictInitOptions,
                      fullLabel: "",
                      clearable: false,
                      placeholder: "请选择分公司",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.editDialog.form, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "职级", prop: "positionId", required: true },
                },
                [
                  _c(
                    "el-select",
                    {
                      on: {
                        change: (val) =>
                          _vm.handPositionChange(val, "position"),
                      },
                      model: {
                        value: _vm.editDialog.form.positionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "positionId", $$v)
                        },
                        expression: "editDialog.form.positionId",
                      },
                    },
                    _vm._l(_vm.positionOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editDialog.form.positionId >= 3,
                      expression: "editDialog.form.positionId >= 3",
                    },
                  ],
                  attrs: { label: "配送中心", prop: "dcCode", required: true },
                },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.editDialog.form.positionId &&
                            _vm.editDialog.form.positionId === 3,
                          expression:
                            "editDialog.form.positionId && editDialog.form.positionId === 3",
                        },
                      ],
                      attrs: { filterable: "", clearable: "" },
                      on: {
                        change: (val) => _vm.handPositionChange(val, "dc"),
                      },
                      model: {
                        value: _vm.editDialog.form.dcCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "dcCode", $$v)
                        },
                        expression: "editDialog.form.dcCode",
                      },
                    },
                    _vm._l(_vm.allDcOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.dcCode + "-" + item.dcName,
                          value: item.dcCode,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.editDialog.form.positionId &&
                            _vm.editDialog.form.positionId > 3,
                          expression:
                            "editDialog.form.positionId && editDialog.form.positionId > 3",
                        },
                      ],
                      attrs: { filterable: "", clearable: "" },
                      on: {
                        change: (val) => _vm.handPositionChange(val, "dc"),
                      },
                      model: {
                        value: _vm.editDialog.form.dcCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "dcCode", $$v)
                        },
                        expression: "editDialog.form.dcCode",
                      },
                    },
                    _vm._l(_vm.branchDcOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.value + "-" + item.label,
                          value: item.value,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editDialog.form.positionId > 1,
                      expression: "editDialog.form.positionId > 1",
                    },
                  ],
                  attrs: { label: "上级", required: true },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      on: {
                        change: (val) => {
                          _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.editDialog.form.parentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "parentId", $$v)
                        },
                        expression: "editDialog.form.parentId",
                      },
                    },
                    _vm._l(_vm.parentOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.userName +
                            (item.nickName ? "-" + item.nickName : ""),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.editDialog.form.positionId < 6
                ? _c(
                    "el-form-item",
                    { attrs: { label: "是否主要负责人", required: true } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled:
                              _vm.editDialog.principalDisabled &&
                              _vm.editDialog.form.positionId < 5 &&
                              _vm.editDialog.form.positionId !== 2,
                          },
                          on: {
                            input: () => {
                              _vm.handleGetDeputy()
                              _vm.$forceUpdate()
                            },
                          },
                          model: {
                            value: _vm.editDialog.form.principal,
                            callback: function ($$v) {
                              _vm.$set(_vm.editDialog.form, "principal", $$v)
                            },
                            expression: "editDialog.form.principal",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { width: "250px" } }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "非主要负责人，不能成为下一职级的上级，可作为副手，享有主要负责人同等数据权限。"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editDialog.form.positionId > 1 &&
              _vm.editDialog.form.positionId < 6 &&
              _vm.editDialog.form.principal !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "主要负责人", required: true } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", clearable: "" },
                          on: {
                            change: (val) => {
                              _vm.$forceUpdate()
                            },
                          },
                          model: {
                            value: _vm.editDialog.form.principalId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editDialog.form, "principalId", $$v)
                            },
                            expression: "editDialog.form.principalId",
                          },
                        },
                        _vm._l(_vm.editDialog.deputyList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                item.nickName +
                                (item.positionName
                                  ? "-" + item.positionName
                                  : ""),
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "岗位名称",
                    prop: "positionName",
                    required: true,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入岗位名称", clearable: "" },
                    on: {
                      input: (val) => {
                        _vm.editDialog.form.positionName = val
                        _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.editDialog.form.positionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDialog.form, "positionName", $$v)
                      },
                      expression: "editDialog.form.positionName",
                    },
                  }),
                ],
                1
              ),
              _vm.editDialog.form.positionId < 6
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "团队名称",
                        prop: "teamName",
                        required: true,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入团队名称", clearable: "" },
                        on: {
                          input: (val) => {
                            _vm.editDialog.form.teamName = val
                            _vm.$forceUpdate()
                          },
                        },
                        model: {
                          value: _vm.editDialog.form.teamName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "teamName", $$v)
                          },
                          expression: "editDialog.form.teamName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入备注",
                      clearable: "",
                    },
                    on: {
                      input: (val) => {
                        _vm.editDialog.form.remark = val
                        _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.editDialog.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDialog.form, "remark", $$v)
                      },
                      expression: "editDialog.form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.buttonLoading,
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "岗位人员",
            visible: _vm.viewDialog.visible,
            "append-to-body": "",
            width: "1000px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.viewDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.viewDialog.loading,
                  expression: "viewDialog.loading",
                },
              ],
              ref: "viewTableRef",
              staticStyle: { "margin-top": "5px" },
              attrs: {
                data: _vm.viewDialog.list,
                height: "500px",
                size: "mini",
                border: "",
              },
            },
            [
              _vm.viewDialog.queryParam.positionId < 6
                ? _c("el-table-column", {
                    attrs: { label: "主负责人", prop: "orgName", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  style:
                                    row.principal === 1 && row.positionId < 6
                                      ? { "font-weight": 600 }
                                      : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(row.principal === 1 ? "是" : "否")
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      545447201
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "用户", prop: "nickName", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [_vm._v(_vm._s(row.userName + "-" + row.nickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "关联组织", prop: "orgName", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.positionId < 3
                          ? _c(
                              "span",
                              {
                                style:
                                  row.principal === 1 && row.positionId < 6
                                    ? { "font-weight": 600 }
                                    : "",
                              },
                              [_vm._v(_vm._s(row.orgCode + "-" + row.orgName))]
                            )
                          : _c(
                              "span",
                              {
                                style:
                                  row.principal === 1 && row.positionId < 6
                                    ? { "font-weight": 600 }
                                    : "",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.dcCode
                                      ? row.dcCode +
                                          "-" +
                                          (row.dcName ? row.dcName : "")
                                      : ""
                                  )
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "上级", prop: "parentName", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.parentUserName && row.parentName
                                  ? row.parentUserName + "-" + row.parentName
                                  : ""
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "岗位", prop: "positionName", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [_vm._v(_vm._s(row.positionName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "备注",
                  prop: "remark",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [_vm._v(_vm._s(row.remark))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelRow(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        row.positionId < 6
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  disabled:
                                    row.principal === 1 && row.positionId < 6,
                                  size: "mini",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeMain(row)
                                  },
                                },
                              },
                              [_vm._v("设为主负责人")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                border: "1px dashed skyblue",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { border: "0px", width: "100%" },
                  attrs: { size: "mini", type: "text" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.viewDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("span", [_c("strong", [_vm._v("导入类型")])]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.importDialog.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.importDialog, "type", $$v)
                    },
                    expression: "importDialog.type",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { attrs: { label: "add" } },
                    [
                      _vm._v("新增 "),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-start",
                            width: "300",
                            trigger: "hover",
                            content:
                              "此方式导入，将在该职级下新增该用户，如果已经存在，导入不成功",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-question",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: "override" } },
                    [
                      _vm._v("覆盖 "),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-start",
                            width: "300",
                            trigger: "hover",
                            content:
                              "此方式导入，将清空本分公司所有组织用户数据，导入本文档中的数据",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-question",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }