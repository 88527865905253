<template>
  <div class="otherQuantity-page">
    <el-tabs class="otherQuantity-tabs" :tab-position="'top'" v-model="activeName" style="height: 200x;">
      <!-- 其他量 -- 在线填写 -->
      <el-tab-pane label="在线填写" class="otherQuantity-tabs-item" name="onlineWrite">
        <div class="otherQuantity-content" v-if="activeName == 'onlineWrite'">
          <v-signboard :title="'其他量导入列表'"/>
          <el-row>

            <el-form
              class="main-search"
              size="mini"
              label-width="auto"
              ref="otherQuantity-form"
              :inline="true"
              :model="form"
            >
            <el-col class="list-head">

              <el-form-item label="订单类型" size="mini">
                <el-select clearable v-model="form.order_type">
                  <el-option value="0" label="老品">老品</el-option>
                  <el-option value="1" label="其他量预留">其他量预留</el-option>
                  <el-option value="2" label="其他量已下">其他量已下</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单性质" size="mini">
                <el-select clearable v-model="form.race_name">
                  <el-option value="新品" label="新品">新品</el-option>
                  <el-option value="大学汛新品" label="大学汛新品">大学汛新品</el-option>
                  <el-option value="考试季新品" label="考试季新品">考试季新品</el-option>
                  <el-option value="外单" label="外单">外单</el-option>
                  <el-option value="OEM单" label="OEM单">OEM单</el-option>
                  <el-option value="新品预留" label="新品预留">新品预留</el-option>
                  <el-option value="订做预留" label="订做预留">订做预留</el-option>
                  <el-option value="外单预留" label="外单预留">外单预留</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="原始料号" size="mini">
                <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
              </el-form-item>
              <el-form-item label="换算料号" size="mini">
                <el-input v-model="form.trans_material_code" clearable placeholder="请输入换算料号"/>
              </el-form-item>
              <el-form-item label="单号" size="mini">
                <el-input v-model="form.order_no" clearable placeholder="请输入单号"/>
              </el-form-item>
              <el-form-item label="系列" size="mini">
                <el-input v-model="form.series" clearable placeholder="请输入系列"/>
              </el-form-item>

              <el-button
                size="mini"
                @click="handleTableHeadAction('search')"
              >查 询
              </el-button>
              <el-button
                size="mini"
                type="primary"
                :disabled="btnStatus.isSave"
                @click="handleTableHeadAction('save')">保 存
              </el-button>
              <el-button
                size="mini"
                type="warning"
                :disabled="btnStatus.isDelete"
                @click="handleTableHeadAction('delete')">删 除
              </el-button>
              <el-button
                size="mini"
                @click="reverseChecked()">反 选
              </el-button>
              <el-button
                size="mini"
                type="primary"
                @click="handleTableHeadAction('adjust')">确 认
              </el-button>
            </el-col>
            </el-form>
          </el-row>
          <el-table
            class="table"
            stripe
            border
            size="mini"
            height="500"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column type="selection" width="55"/>
            <el-table-column label="序号" align="center" width="50" fixed="left">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableObj.otherQuantityHead">
              <!-- 料号 / 本月需求数量 -- 可编辑 -->
              <el-table-column v-if="['material_code', 'trans_material_code', 'material_count'].includes(item.prop)"
                               v-bind="item" :key="index" align="center">
                <template slot-scope="scope">
                  <el-input class="tgqrcount-cell-ipt" v-if=" item.prop == 'material_code'"
                            v-model="scope.row.material_code" size="mini" clearable/>
                  <el-input class="tgqrcount-cell-ipt" v-if=" item.prop == 'trans_material_code'"
                            v-model="scope.row.trans_material_code" size="mini" clearable/>
                  <el-input class="tgqrcount-cell-ipt" type="number" v-if=" item.prop == 'material_count'"
                            v-model="scope.row.material_count" size="mini" clearable/>
                </template>
              </el-table-column>
              <el-table-column
                v-else-if="['import_month', 'order_type_text', 'material_type', 'is_keep_text' ].includes(item.prop)"
                fixed="left" v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
              <el-table-column v-else v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
      </el-tab-pane>
      <!-- 其他量 -- 表单上传 -->
      <el-tab-pane label="表单上传" class="otherQuantity-tabs-item" name="formWrite">
        <div clas="form-upload-box" v-if="activeName == 'formWrite'">
          <upload-excel
            ref="uploadExcelRef"
            :uploadDatas="uploadFileObj.tableData"
            :on-success="handleFileSuccess"
            :before-upload="beforeFileUpload"
            :tipsText="uploadFileObj.tipsText"
            @handlePreview="handlePreview"
            @handleConfirmUpload="handleConfirmUpload"
          />
          <template v-if="uploadFileObj.preTableData.length > 0">
            <div class="previewTable">
              <v-signboard :title="'文件预览'"/>
              <el-row>
                <el-col class="handleConfirmUpload">
                  <el-button size="mini" type="primary">确认上传</el-button>
                </el-col>
              </el-row>
              <el-table
                :data="uploadFileObj.preTableData"
                stripe
                border
                size="mini"
              >
                <el-table-column v-for="item of uploadFileObj.tableHeader" :key="item" :prop="item" :label="item"/>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="previewTable previewTable-sample">
              <v-signboard :title="'文件上传模板示例'"/>
              <el-table
                :data="uploadFileObj.templateSampleLists"
                stripe
                border
                size="mini"
              >
                <template v-for="(item, index) in uploadFileObj.templateSampleHead">
                  <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
                </template>
              </el-table>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {tableLoading, paginationParams, urlParams, optionsObj} from '@/mixins';
import {otherQuantityHead, otherQuantityTemplateSampleHead} from './config';
import {
  handleOtherQuantityLists,
  handleOtherQuantityConfirm,
  handleOtherQuantitySave,
  handleOtherQuantityDelete,
  handleOtherQuantityUpload
} from '@/api/otherQuantity';

export default {
  // 其他量导入
  name: 'otherQuantity',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      form:{
        order_type: '',
        race_name: '',
        material_code: '',
        trans_material_code: '',
        order_no: '',
        series: '',
      },
      btnStatus: {
        isSave: true,                   // 是否调整
        isDelete: true,                   // 是否调整
        isAdjust: true                  // 是否调整
      },
      lists: [],
      tableObj: {
        otherQuantityHead
      },
      uploadFileObj: {
        tipsText: '必需字段: 订单类型、订单性质、原始料号、换算料号、料号数量、单号、接单日期、成品交期、系列',
        tableData: [],
        preTableData: [],
        tableHeader: [],
        templateSampleHead: otherQuantityTemplateSampleHead,
        templateSampleLists: [
          {
            import_month: '',
            order_type: '其他量预留',
            race_name: '老品',
            material_code: 'QGP829402205E1H',
            trans_material_code: 'QGP829402205G2J',
            material_count: '130,000',
            order_no: '12345',
            accept_order_date: '2021-10-10',
            deliver_date: '2021-10-31',
            series: 'XX系列',
          }
        ]
      },
      orderTypeText: {
        0: '老品',
        1: '其他量预留',
        2: '其他量已下'
      },
      selectRows: [],
      urlMonth: '',
      activeName: 'onlineWrite'
    }
  },
  watch: {
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isDelete = false;
          btnStatus.isAdjust = false;
        }
        if (n.length === 0) {
          btnStatus.isSave = true;
          btnStatus.isDelete = true;
          btnStatus.isAdjust = true;
        }
      }
    }
  },
  created() {
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    })
  },
  methods: {
    // 获取数据
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          order_type: form.order_type || '',
          race_name: form.race_name || '',
          material_code: form.material_code || '',
          trans_material_code: form.trans_material_code || '',
          order_no: form.order_no || '',
          series: form.series || '',
        };
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
          ...formParams
        };
        const {code, data, msg} = await handleOtherQuantityLists(params);
        if (data) {
          const {other_material_list, total} = data;
          this.modifyData(other_material_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-----模具产能盘点列表有误');
      }
    },
    //实现反选
    reverseChecked() {
      this.lists.map(row => {
          for(let i = 0; i < this.selectRows.length; i++) {
            if(this.selectRows[i].id == row.id) {
              this.$refs['multipleTable'].toggleRowSelection(row, false);
              return;
            }
          }
        this.$refs['multipleTable'].toggleRowSelection(row, true);
      });
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        const orderTypeText = this.orderTypeText;
        lists.map(item => {
          let newItem = {
            id: item.id,
            import_month: item.import_month || '--',
            order_type: item.order_type || '--',
            order_type_text: orderTypeText[item.order_type] || '--',
            is_keep: item.is_keep || 0,
            is_keep_text: item.is_keep == 0 ? '否' : '是' || '--',
            race_name: item.race_name || '--',
            accept_order_date: item.accept_order_date || '--',
            deliver_date: item.deliver_date || '--',
            series: item.series || '--',
            order_no: item.order_no == 0 ? 0 : item.order_no || '--',
            trans_material_code: item.trans_material_code || '',
            material_type: item.material_type || '--',
            material_code: item.material_code || '',
            material_name: item.material_name || '--',
            material_count: item.material_count || 0
          };
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作项
    handleTableHeadAction(type) {
      const funcObj = {
        'save': async () => {             // 保存
          let newItems = [];
          this.selectRows.map(item => {
            let newItem = {
              id: item.id || '',
              import_month: item.import_month || '',
              material_code: item.material_code || '',
              trans_material_code: item.trans_material_code || '',
              material_count: item.material_count || ''
            };
            newItems.push(newItem);
          });
          const params = {
            operator: this.urlParams.operator,
            other_material_list: newItems
          };
          const {code, msg} = await handleOtherQuantitySave(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('修改成功');
          }
          if (code != '0000') this.getLists();
        },
        'delete': async () => {             // 删除
          let newItems = [];
          this.selectRows.map(item => {
            newItems.push(item.id || '');
          });
          const params = {
            operator: this.urlParams.operator,
            other_material_id_list: newItems
          };
          const {code, msg} = await handleOtherQuantityDelete(params);
          this.$refs['multipleTable'].clearSelection();
          if (code == '0000') {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('删除成功');
          }
          if (code != '0000') this.getLists();
        },
        'adjust': async () => {           // 调整
          const params = {
            month: this.urlMonth,
            operator: this.urlParams.operator
          };
          const {success} = await handleOtherQuantityConfirm(params);
          this.$refs['multipleTable'].clearSelection();
          if (success) {
            this.tableStatus.isLoading = true;
            this.getLists();
            return this.$message.success('其他量导入已确认');
          }
          ;
          if (!success) this.getLists();
        },
        'search': () => {               // 查询
          this.currentPage = 1;
          this.tableStatus.isLoading = true;
          this.getLists();
        },
        'reset': () => {            // 重置
          this.tableStatus.isLoading = true;
          this.getLists();
        }
      };
      funcObj[type]();
    },
    // 上传excel之前文件
    beforeFileUpload(file) {
      const uploadFileObj = this.uploadFileObj;
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (!this.$refs['uploadExcelRef'].isExcel(file)) return this.$message.error('文件格式仅限于( .xlsx | .xls | .csv )');

      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) return true;
      this.$message({
        message: '请不要上传超过1M的文件',
        type: 'warning'
      })
      return false
    },
    // 上传excel文件
    handleFileSuccess({results, header}) {
      const uploadFileObj = this.uploadFileObj;

      for(let i = 0; i < results.length; i++) {
        results[i]['接单日期'] = this.getFormatDate_XLSX(results[i]['接单日期']);
        results[i]['成品交期'] = this.getFormatDate_XLSX(results[i]['成品交期']);
      }

      uploadFileObj.tableData = results;
      uploadFileObj.tableHeader = header

    },
    // 预览上传文件
    handlePreview({data, isPreview}) {
      this.uploadFileObj.preTableData = data;
    },
    getFormatDate_XLSX(serial) {
      let utc_days = Math.floor(serial - 25569);
      let utc_value = utc_days * 86400;
      let date_info = new Date(utc_value * 1000);
      let fractional_day = serial - Math.floor(serial) + 0.0000001;
      let total_seconds = Math.floor(86400 * fractional_day);
      let seconds = total_seconds % 60;
      total_seconds -= seconds;
      let hours = Math.floor(total_seconds / (60 * 60));
      let minutes = Math.floor(total_seconds / 60) % 60;
      let d = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      let YYYY = d.getFullYear();
      let add0 = (m) => m < 10 ? '0' + m : m;
      let MM = add0(d.getMonth() + 1);
      let DD = add0(d.getDate());
      return `${YYYY}-${MM}-${DD}`;
    },
    // 确认上传
    async handleConfirmUpload() {
      const uploadFileObj = this.uploadFileObj;
      if (uploadFileObj.tableHeader.indexOf('订单类型') === -1) return this.$message.warning('缺少订单类型字段');
      if (uploadFileObj.tableHeader.indexOf('订单性质') === -1) return this.$message.warning('缺少订单性质字段');
      if (uploadFileObj.tableHeader.indexOf('原始料号') === -1) return this.$message.warning('缺少原始料号字段');
      if (uploadFileObj.tableHeader.indexOf('换算料号') === -1) return this.$message.warning('缺少换算料号字段');
      if (uploadFileObj.tableHeader.indexOf('料号数量') === -1) return this.$message.warning('缺少料号数量字段');
      if (uploadFileObj.tableHeader.indexOf('单号') === -1) return this.$message.warning('缺少单号字段');
      if (uploadFileObj.tableHeader.indexOf('接单日期') === -1) return this.$message.warning('缺少接单日期字段');
      if (uploadFileObj.tableHeader.indexOf('成品交期') === -1) return this.$message.warning('缺少成品交期字段');
      if (uploadFileObj.tableHeader.indexOf('系列') === -1) return this.$message.warning('缺少系列字段');
      let newItems = [];
      uploadFileObj.tableData.map(item => {
        let order_type = 0;
        if(item['订单类型'] === '老品') {
          order_type = 0;
        } else if(item['订单类型'] === '其它量预留' || item['订单类型'] === '其他量预留') {
          order_type = 1;
        } else if(item['订单类型'] === '其它量已下' || item['订单类型'] === '其他量已下') {
          order_type = 2;
        }

        let newItem = {
          import_month: item['年月'],
          order_type: order_type,
          race_name: item['订单性质'],
          order_no: item['单号'],
          accept_order_date: item['接单日期'],
          deliver_date: item['成品交期'],
          series: item['系列'],
          material_type: item['产品类型'],
          material_code: item['原始料号'],
          trans_material_code: item['换算料号'],
          material_count: item['料号数量']
        };
        newItems.push(newItem);
      });
      const param = {
        operator: this.urlParams.operator,
        capacity_list: newItems
      };
      const {code, success, msg} = await handleOtherQuantityUpload(param);
      uploadFileObj.tableData = [];
      uploadFileObj.preTableData = [];
      uploadFileObj.tableHeader = [];
      if (success) return this.$message.success(msg);
      if (!success) this.activeName = 'onlineWrite';
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/otherQuantity/layoutMain.scss'></style>
