var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-pagination", {
    staticClass: "table-pages table-paging-box",
    attrs: {
      background: "",
      layout: _vm.layout,
      "page-sizes": _vm.pageSizesArr,
      "current-page": _vm.paging.current_page,
      "page-size": _vm.paging.per_page,
      total: _vm.paging.total,
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.paging, "current_page", $event)
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.paging, "current_page", $event)
      },
      "update:pageSize": function ($event) {
        return _vm.$set(_vm.paging, "per_page", $event)
      },
      "update:page-size": function ($event) {
        return _vm.$set(_vm.paging, "per_page", $event)
      },
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }