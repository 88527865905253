<template>
    <mg-container>
        <!-- 头部搜索 -->
        <template #headerSearch>
            <div class="head-signboard-box">
                <mg-signboard title="库存设定" />
                <mg-signboard :title="branchCompanyInfos && branchCompanyInfos.name" :isHavBar="false" />
            </div>
            <mg-search-form
                ref="mgSearchFormRef"
                :form-item-list="formItemList"
                :row="2"
                label-width="70px"
                size="mini"
                @getSearchFormData="handleSearch"
            >
                <template #buttonScope>
                    <el-button
                        size="mini"
                        type="primary"
                        :disabled="btnStatus.isSave"
                        :loading="btnStatus.isSaveLoading"
                        @click="handleTableHeadAction('save')"
                    >保存</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        :loading="btnStatus.isExport"
                        @click="handleTableHeadAction('export')"
                    >导出</el-button>
                    <el-button
                        size="mini"
                        type="info"
                        :disabled="btnStatus.noSelectedRow"
                        :loading="btnStatus.noSelectedRowLoading"
                        @click="handleTableHeadAction('setDefaultAim')"
                    >默认库存目标</el-button>
                </template>
            </mg-search-form>
        </template>
        <!-- 列表 -->
        <template #table>
            <mg-table
                ref="mgTable"
                :auto-load="false"
                :columns.sync="inventorySettingColumns.treeColumns"
                :isHeaderMiddle="true"
                :isUseDefaultColumns="false"
                :api="handleStoreRatioGradeLists"
                :apiParams='queryForm'
                :configurable="false"
                business-type="BRANCHOFFICE_INVENTORYSETTING_LISTS"
                height="calc(100vh - 200px)"
                max-height="auto"
                :cell-style="columnCellStyle"
                @selection-change="handleSelectionChange"
            >
                <!--
                    ****************** TODO ******************
                    由于时间关系，这里仅仅针对内容嵌套了一个外壳, 
                    暂无法使用自定义列，后续需要针对表格组件针对列表的树形结构做进一步处理
                -->
                <el-table-column type="selection" width="40" fixed="left" />
                <el-table-column label="序号" type="index" align="center" width="50" fixed="left"></el-table-column>
                <template v-for="(item, index) in inventorySettingColumns.columns">
                <el-table-column v-if="item.prop.indexOf('Category') > -1 || item.prop === 'raceName'" v-bind="item" show-overflow-tooltip :key="index" align="center" fixed="left"></el-table-column>
                </template>

                <el-table-column :label="columnsLabels.currentRatioLabel" align="center" style="background-color: #6cf40a" label-class-name="current_store_ratio_title">
                <template v-for="(item, index) in inventorySettingColumns.columns">
                    <el-table-column
                        v-if="item.prop.indexOf('currentStoreRatio') > -1 "
                        v-bind="item"
                        :key="index"
                        show-overflow-tooltip
                        align="center"
                        label-class-name="current_store_ratio"
                    ></el-table-column>
                </template>
                </el-table-column>

                <el-table-column :label="columnsLabels.planMonthRatioLabel" align="center" label-class-name="plan_month_ratio_title">
                <template v-for="(item, index) in inventorySettingColumns.columns" >
                    <el-table-column
                        v-if="item.prop.indexOf('planMonthStoreRatio') > -1 "
                        v-bind="item"
                        :key="index"
                        show-overflow-tooltip
                        align="center"
                        label-class-name="plan_month_store_ratio"
                    ></el-table-column>
                </template>
                </el-table-column>

                <el-table-column :label="columnsLabels.storeRatioAimLabel" align="center">
                <template v-for="(item, index) in inventorySettingColumns.columns">
                    <el-table-column
                    v-if="
                        item.prop.indexOf('AimS') > -1 ||
                        item.prop.indexOf('AimA') > -1 ||
                        item.prop.indexOf('AimB') > -1 ||
                        item.prop.indexOf('AimCPlus') > -1 ||
                        item.prop === 'storeRatioAimC'"
                        v-bind="item"
                        :key="index"
                        align="center"
                    >
                    <template slot-scope="scope">
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimS < 0 ||
                                scope.row.storeRatioAimS > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimS'"
                            v-model="scope.row.storeRatioAimS"
                        />
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimAPlus < 0 ||
                                scope.row.storeRatioAimAPlus > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimAPlus'"
                            v-model="scope.row.storeRatioAimAPlus"
                        />
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimA < 0 ||
                                scope.row.storeRatioAimA > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimA'"
                            v-model="scope.row.storeRatioAimA"
                        />
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimBPlus < 0 ||
                                scope.row.storeRatioAimBPlus > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimBPlus'"
                            v-model="scope.row.storeRatioAimBPlus"
                        />
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimB < 0 ||
                                scope.row.storeRatioAimB > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimB'"
                            v-model="scope.row.storeRatioAimB"
                        />
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimCPlus < 0 ||
                                scope.row.storeRatioAimCPlus > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimCPlus'"
                            v-model="scope.row.storeRatioAimCPlus"
                        />
                        <el-input
                            type="number"
                            size="mini"
                            clearable
                            :disabled="scope.row.isEdit"
                            :class="
                                scope.row.storeRatioAimC < 0 ||
                                scope.row.storeRatioAimC > 1.5 ?
                                'tgqrcount-cell-ipt warning' : 'tgqrcount-cell-ipt'
                            "
                            v-if="item.prop === 'storeRatioAimC'"
                            v-model="scope.row.storeRatioAimC"
                            />
                    </template>
                    </el-table-column>
                    <el-table-column v-else-if="item.prop.indexOf('AimCMinus') > -1 || item.prop === 'storeRatioAimD'" v-bind="item" show-overflow-tooltip :key="index" align="center"></el-table-column>
                </template>
                </el-table-column>
            </mg-table>
        </template>
    </mg-container>
</template>

<script>
import {
    paginationParams,
    tableLoading,
    urlParams,
    branchCompanyInfos,
    optionsLists
} from '@/mixins';
import { excelExport } from "@/utils";
import { inventorySettingColumns } from '../config';
import { getCurrentShortYM, getPlanShortYM } from "@/utils";
import { handleStoreRatioGradeLists, handleStoreRatioGradePut } from '@/api/branchOffice';
export default {
    // 库存设定
    name: 'inventorySetting',
    mixins: [
        tableLoading,
        paginationParams,
        urlParams,
        optionsLists,
        branchCompanyInfos
    ],
    components: {
        mgContainer: () => import('@/mg-components/mg-container'),
        mgSignboard: () => import('@/components/Signboard'),
        mgSearchForm: () => import("@/mg-components/mg-search-form"),
        mgTable: () => import("@/mg-components/mg-table")
    },
    data() {
        return {
            formItemList: [
                {
                    type: 'select',
                    label: '产品赛道',
                    model: 'raceName',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'select',
                    label: '大类',
                    model: 'mainCategoryName',
                    initialValue: '',
                    options: [],
                    change: this.handleMainCategoryNameChange
                },
                {
                    type: 'select',
                    label: '中类',
                    model: 'midCategoryName',
                    initialValue: '',
                    options: []
                }
            ],
            urlMonth: '',
            btnStatus: {
                isSave: true,                   // 是否保存
                isSaveLoading: false,           // 保存loading

                noSelectedRow: true,            // 是否系数初始化
                noSelectedRowLoading: false,    // 系数初始化loading

                isExport: false,                // 是否导出
            },
            selectRows: [],
            queryForm: {
                page: 1,
                limit: 20,
                raceName: '',
                mainCategoryName: '',
                midCategoryName: ''
            },
            handleStoreRatioGradeLists,
            lists: [],
            inventorySettingColumns,
            columnsLabels: {
                currentRatioLabel : getCurrentShortYM() + "月品类库存系数",
                planMonthRatioLabel : getPlanShortYM() + "月品类库存系数(根据设定值计算)",
                storeRatioAimLabel : getPlanShortYM() + "月品类目标设定系数",
            }

        }
    },
    watch: {
        selectRows: {
            handler(n) {
                const btnStatus = this.btnStatus;
                if (n.length > 0) {
                    btnStatus.isSave = false;
                    btnStatus.noSelectedRow = false;
                }
                if (n.length === 0) {
                    btnStatus.isSave = true;
                    btnStatus.noSelectedRow = true;
                }
            }
        }
    },
    mounted() {
        const queryForm = this.queryForm;
        this.urlMonth = this.$route.query?.month || '';
        this.handleUrlParams().then(res => {
            if ( !(res && res['x-token']) ) {
                this.queryForm = {
                    ...res,
                    ...queryForm
                }
            }
            this.handleSelectOptions()
            setTimeout(() => {
                this.handleFetchData()
            }, 0)
        });
    },
    methods: {
        // 表头搜索
        handleSearch(params) {
            this.queryForm = {
                ...this.queryForm,
                ...params
            }
            this.handleFetchData()
        },
        // 加载列表数据
        handleFetchData() {
            this.$nextTick(() => {
                this.$refs['mgTable']?.loadData()
            })
        },
        // 表单顶部事件
        handleTableHeadAction(type) {
            const queryForm = this.queryForm;
            const tableStatus = this.tableStatus;
            const btnStatus = this.btnStatus
            const funcObj = {
                /**
                 * save -- 保存
                 * export -- 导出
                 * setDefaultAim -- 默认库存目标
                 */
                save: async () => {
                    let newItems = [];
                    let needWarning = false;
                    this.selectRows.map(item => {
                        if (item.storeRatioAimS < 0 || item.storeRatioAimS > 2.5 ||
                            item.storeRatioAimAPlus < 0 || item.storeRatioAimAPlus > 2.5 ||
                            item.storeRatioAimA < 0 || item.storeRatioAimA > 2.5 ||
                            item.storeRatioAimBPlus < 0 || item.storeRatioAimBPlus > 2.5 ||
                            item.storeRatioAimB < 0 || item.storeRatioAimB > 2.5 ||
                            item.storeRatioAimCPlus < 0 || item.storeRatioAimCPlus > 2.5 ||
                            item.storeRatioAimC < 0 || item.storeRatioAimC > 2.5 ||
                            item.storeRatioAimCMinus < 0 || item.storeRatioAimCMinus > 2.5 ||
                            item.storeRatioAimD < 0 || item.storeRatioAimD > 2.5) {
                            needWarning = true;
                        }
                        newItems.push(item);
                    });

                    if(needWarning) {
                        this.$confirm('存在系数范围异常(<0 or > 2.5)，确认要保存么?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(async () => {
                            const params = {
                                planMonth: this.urlMonth,
                                configList: newItems
                            };
                            const loading = this.$loading({
                                lock: true,
                                text: '处理中',
                                spinner: 'el-icon-loading',
                                background: 'rgba(0, 0, 0, 0.7)'
                            });
                            try {
                                btnStatus.isSaveLoading = true
                                const {code, msg} = await handleStoreRatioGradePut(params);
                                loading.close();
                                this.$refs['mgTable'].clearSelection();
                                if (code === 200) {
                                    this.handleFetchData();
                                    return this.$message.success('保存库存系数目标成功！');
                                }
                            } finally {
                                btnStatus.isSaveLoading = false
                            }
                            
                        }).catch(() => {
                            this.$message('库存系数目标未保存！');
                        });
                    } else {
                        try {
                            const params = {
                                planMonth: this.urlMonth,
                                configList: newItems
                            };
                            btnStatus.isSaveLoading = true
                            const {code, msg} = await handleStoreRatioGradePut(params);
                            this.$refs['mgTable'].clearSelection();
                            if (code === 200) {
                                this.handleFetchData();
                                return this.$message.success('保存库存系数目标成功！');
                            }
                        } finally {
                            btnStatus.isSaveLoading = false
                        }
                    }
                },
                export: async () => {
                    const queryForm = this.queryForm;
                    this.handleResetFields();
                    const topHeaders = {};
                    const filterInventorySettingColumns = this.inventorySettingColumns.columns.filter(item => item.prop)
                    filterInventorySettingColumns.map(item => {
                        return topHeaders[item.prop] = item.label
                    })
                    try {
                        btnStatus.isExport = true;
                        queryForm.page = 1;
                        queryForm.limit = 1000000;
                        const { code, data } = await handleStoreRatioGradeLists(queryForm);
                        if ( code === 200 && data?.datas.length > 0) {
                            const newItems = data.datas && data.datas.map(item => {
                                return {
                                    ...item,
                                }
                            }) || [];
                            excelExport({
                                topHeaders,
                                data: newItems,
                                title: '库存设定列表'
                            })
                        }
                    } catch(err) {
                        throw new Error(err)
                    } finally {
                        btnStatus.isExport = false;
                    }
                },
                setDefaultAim: () => {
                    this.selectRows.map(item => {
                        item.storeRatioAimS = 1;
                        item.storeRatioAimAPlus = 1;
                        item.storeRatioAimA = 1;
                        item.storeRatioAimBPlus = 1;
                        item.storeRatioAimB = 1;
                        item.storeRatioAimCPlus = 1;
                        item.storeRatioAimC = 1;
                        item.storeRatioAimCMinus = 0;
                        item.storeRatioAimD = 0;
                    });
                }

            };
            funcObj[type]()
        },
        // 重置表单字段
        handleResetFields() {
            const queryForm = this.queryForm;
            queryForm.raceName = '';
            queryForm.mainCategoryName = '';
            queryForm.midCategoryName = '';
        },
        // 表格单元格样式
        columnCellStyle(data) {
            let columnValue = data.row[data.column.property];
            if (typeof columnValue === "number" && !isNaN(columnValue)) {
                if (columnValue < 0 || columnValue > 1.5) {
                    return "color:red;background-color:#f0ffbe;";
                }
            }
            return '';
        },
        // 全选函数
        handleSelectionChange(e) {
            this.selectRows = e;
        },
        // 下拉列表选项
        handleSelectOptions() {
            // 赛道
            const raceField = {
                key: 'raceName',
                label: 'label',
                value: 'value'
            }
            // 大类
            const categoryField = {
                key: 'mainCategoryName',
                label: 'max_name',
                value: 'max_code'
            }
            const paramsObj = {
                dict_type: 'plu_grade',
                operator: this.urlParams?.operator || ''
            }
            Promise.all([
                this.handleRaceNamesOption({ formItemList: this.formItemList, field: raceField, paramsObj }),
                this.handleProductMaxCatagoryOption({ formItemList: this.formItemList, field: categoryField, paramsObj })
            ])
        },
        // 根据大类获取中类
        handleMainCategoryNameChange(val) {
            const queryForm = this.queryForm
            const mgSearchFormRef = this.$refs['mgSearchFormRef']
            const formItemList = this.formItemList
            queryForm.midCategoryCode = '';
            mgSearchFormRef.form['midCategoryName'] = ''
            if (!val) return;
            const paramsObj = {
                max_code: val,
                operator: this.urlParams?.operator || ''
            };
            const field = {
                key: 'midCategoryName',
                label: 'mid_name',
                value: 'mid_code'
            }
            this.handleProductMidCatagoryOption({ formItemList: formItemList, field, paramsObj })
        }
    }
}
</script>

<style lang="scss" scoped src="@/style/branchOffice/common.scss"></style>
<style lang="scss" scoped>
    ::v-deep .warning input {
        color: red;
        background-color: #f0ffbe;
    }
    ::v-deep th.current_store_ratio_title, ::v-deep th.current_store_ratio {
        background-color: cyan !important;
    }
    ::v-deep th.plan_month_ratio_title, ::v-deep th.plan_month_store_ratio {
        background-color: blanchedalmond !important;
    }
</style>