<template>
    <mg-container>
        <!-- 头部搜索 -->
        <template #headerSearch>
            <div class="head-signboard-box">
                <mg-signboard title="配置中心-商品" />
                <mg-signboard :title="branchCompanyInfos && branchCompanyInfos.name" :isHavBar="false" />
            </div>
            <mg-search-form
                ref="mgSearchFormRef"
                :form-item-list="formItemList"
                :row="2"
                label-width="110px"
                size="mini"
                @getSearchFormData="handleSearch"
            >
                <template #buttonScope>
<!--                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('importProductGrade')"
                    >批量导入产品等级</el-button>-->
                    <el-button type="primary" size="mini" :loading="uploadLoading" @click="handleImport" >分公司产品等级&库存系数导入</el-button>
<!--                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('importInventoryFactor')"
                    >批量导入库存系数</el-button>-->
                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('importEstimatedSales')"
                    >批量导入预估销量</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        @click="handleTableHeadAction('importOtherInventory')"
                    >批量导入其他库存</el-button>
                    <el-button
                        size="mini"
                        type="primary"
                        :loading="btnStatus.isExport"
                        @click="handleTableHeadAction('export')"
                    >导出</el-button>
                </template>
            </mg-search-form>
          </template>
         <!-- 列表 -->
        <template #table>
             <mg-table
                class="mg-list-box"
                ref="mgTable"
                :autoLoad="true"
                :configurable="true"
                businessType="BRANCHOFFICE_CONFIGURATIONCENTER_LISTS"
                :columns.sync="configurationCenterColumns"
                :api="handleConfigProductLists"
                :apiParams="queryForm"
                :extraParams="{ isEdit: false }"
                height="calc(100vh - 280px)"
                max-height="auto"
            >
                <template #branchGrade="{ row }">
                    <el-select
                        size="mini"
                        clearable
                        :disabled="!row.isEdit"
                        v-model="row.branchGrade"
                        @change="handleChangeRow('branchGrade', row)"
                    >
                        <el-option
                            v-for="item in productGradeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </template>
                <template #safeStockRatio="{ row }">
                    <el-input
                        size="mini"
                        type="number"
                        :disabled="!row.isEdit"
                        v-model.number="row.safeStockRatio"
                        @change="handleChangeRow('safeStockRatio', row)"
                    ></el-input>
                </template>
                <template #stockRatioAim="{ row }">
                    <el-input
                        size="mini"
                        type="number"
                        :disabled="!row.isEdit"
                        v-model.number="row.stockRatioAim"
                        @change="handleChangeRow('stockRatioAim', row)"
                    ></el-input>
                </template>
                <template #stockRatioUp="{ row }">
                    <el-input
                        size="mini"
                        type="number"
                        :disabled="!row.isEdit"
                        v-model.number="row.stockRatioUp"
                        @change="handleChangeRow('stockRatioUp', row)"
                    ></el-input>
                </template>
                <template #stockRatioDown="{ row }">
                    <el-input
                        size="mini"
                        type="number"
                        :disabled="!row.isEdit"
                        v-model.number="row.stockRatioDown"
                        @change="handleChangeRow('stockRatioDown', row)"
                    ></el-input>
                </template>
                <template #thisMthExpectSaleCount="{ row }">
                    <el-input
                        size="mini"
                        type="number"
                        :disabled="!row.isEdit"
                        v-model.number="row.thisMthExpectSaleCount"
                        @change="handleChangeRow('thisMthExpectSaleCount', row)"
                    ></el-input>
                </template>
                <template #nextMthExpectSaleCount="{ row }">
                    <el-input
                        size="mini"
                        type="number"
                        :disabled="!row.isEdit"
                        v-model.number="row.nextMthExpectSaleCount"
                        @change="handleChangeRow('nextMthExpectSaleCount', row)"
                    ></el-input>
                </template>

                <el-table-column show-overflow-tooltip label="操作" min-width="120px" fixed="right" align="center">
                    <template #default="{ row }">
                        <el-button-group>
                            <el-button size="mini" :type="!row.isEdit ? 'primary' : ''" @click="handleTableRowEvent('default', row)">默认</el-button>
                            <el-button size="mini" :type="row.isEdit ? 'primary' : ''" @click="handleTableRowEvent('edit', row)">编辑</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </mg-table>
        </template>
        <el-dialog title="分公司产品等级&库存系数导入" :visible.sync="ratioAndGradeImportDialog.visible" append-to-body width="400px">
          <el-upload
            ref="ratioAndGradeUpload"
            class="upload-demo"
            drag
            :limit="1"
            action="#"
            :auto-upload="false"
            :file-list="ratioAndGradeImportDialog.fileList"
            :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
            :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">
              <span>仅允许导入xls、xlsx格式文件。</span>
              <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="ratioAndGradeImportDialog.template">下载模板</el-link>
            </div>
          </el-upload>
          <div slot="footer" class="dialog-footer" style="text-align: right">
            <el-button type="primary" size="mini" @click="ratioAndGradeImportDialog.visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
          </div>
        </el-dialog>
        <!-- 导入弹窗 -->
        <mg-importDialog
            :urlParams="urlParams"
            :importBaseParams="importBaseParams(importDialog.importBaseParams)"
            @handleImportSuccess="handleImportSuccess"
        />
    </mg-container>
</template>

<script>
import {
    urlParams,
    branchCompanyInfos,
    optionsLists
} from '@/mixins';
import { configurationCenterColumns } from '../config';
import {
    handleConfigProductLists,
    handleConfigProductEdit,
    handleConfigProductUpdateGrade,
    handleConfigProductUpdateRatio,
    handleConfigProductUpdateMonth,
    handleOtherInventoryImport,
    ratioAndGradeImport
} from '@/api/branchOffice';
import { excelExport } from "@/utils";
import {Notification} from "element-ui";
export default {
    // 商品配置中心
    name: 'configurationCenter',
    mixins: [ urlParams, branchCompanyInfos, optionsLists ],
    components: {
        mgContainer: () => import('@/mg-components/mg-container'),
        mgSearchForm: () => import("@/mg-components/mg-search-form"),
        mgSignboard: () => import('@/components/Signboard'),
        mgTable: () => import("@/mg-components/mg-table"),
        mgImportDialog: () => import('../components/importDialog')
    },
    computed: {
        importBaseParams() {
            const importDialog = this.importDialog;
            return (importParams) => {
                return importParams.find(item => item.type === importDialog.currentImportType)
            }
        },
        productGradeOptions() {
            return this.formItemList.find(item => item.model === 'branchGrade')?.options
        }
    },
    data() {
        return {
            formItemList: [
                {
                    type: 'select',
                    label: '赛道',
                    model: 'raceName',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'select',
                    label: '大类',
                    model: 'mainCategoryCode',
                    initialValue: '',
                    options: [],
                    change: this.handleMainCategoryNameChange
                },
                {
                    type: 'select',
                    label: '中类',
                    model: 'midCategoryCode',
                    initialValue: '',
                    options: [],
                    change: this.handleMidCategoryNameChange
                },
                {
                    type: 'select',
                    label: '小类',
                    model: 'subCategoryCode',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'select',
                    label: '分公司产品等级',
                    model: 'branchGrade',
                    initialValue: '',
                    options: []
                },
                {
                    type: 'input',
                    label: '货号',
                    model: 'cargoNo',
                    initialValue: ''
                },
                {
                    type: 'input',
                    label: '品名',
                    model: 'skuName',
                    initialValue: ''
                },
            ],
            btnStatus: {
                isExport: false,
            },
            configurationCenterColumns,
            handleConfigProductLists,
            importDialog: {
                currentImportType: 'importProductGrade',
                importBaseParams: [
                    {
                        visible: false,
                        title: '批量导入产品等级',
                        type: 'importProductGrade',
                        tipsText: '必需字段: 商品编码、分公司产品等级',
                        templateSampleHead: [
                            {label: '商品编码', prop: 'skuCode', minWidth: '80px'},
                            {label: '分公司产品等级', prop: 'branchGrade', minWidth: '80px'},
                        ],
                        templateSampleLists: [
                            {
                                skuCode: '00000011',
                                branchGrade: 'S',
                            }
                        ],
                        api: handleConfigProductUpdateGrade,
                        apiParams: 'productGradeDtos',
                        templateFileDownloadUrl: `${ process.env.VUE_APP_OSS_TEMPLATE_PATH }/导入分公司产品等级模板.xlsx`
                    },
                    {
                        visible: false,
                        title: '批量导入库存系数',
                        type: 'importInventoryFactor',
                        tipsText: '必需字段: 商品编码、库存系数上限、库存系数下限、库存系数目标',
                        optionalTipsText: '选填字段: 加单安全库存系数、自动补单系数',
                        templateSampleHead: [
                            {label: '商品编码', prop: 'skuCode', minWidth: '80px'},
                            {label: '库存系数上限', prop: 'stockRatioUp', minWidth: '80px'},
                            {label: '库存系数下限', prop: 'stockRatioDown', minWidth: '80px'},
                            {label: '库存系数目标', prop: 'stockRatioAim', minWidth: '80px'},
                            {label: '加单安全库存系数', prop: 'safeStockRatio', minWidth: '80px'},
                            {label: '自动补单系数', prop: 'autoSupplyRatio', minWidth: '80px'},
                        ],
                        templateSampleLists: [
                            {
                                skuCode: '00000011',
                                stockRatioUp: 0,
                                stockRatioDown: 1,
                                stockRatioAim: 1,
                                safeStockRatio: 1,
                                autoSupplyRatio: 1
                            }
                        ],
                        api: handleConfigProductUpdateRatio,
                        apiParams: 'dorisCompanySkuRatioDtos',
                        templateFileDownloadUrl: `${ process.env.VUE_APP_OSS_TEMPLATE_PATH }/导入库存系数模板.xlsx`
                    },
                    {
                        visible: false,
                        title: '批量导入预估销量',
                        type: 'importEstimatedSales',
                        tipsText: '必需字段: 商品编码、本月待销量、次月预估销量',
                        optionalTipsText: '选填字段: 次次月预估销量',
                        templateSampleHead: [
                            {label: '商品编码', prop: 'skuCode', minWidth: '80px'},
                            {label: '本月待销量', prop: 'thisMthExpectSaleCount', minWidth: '80px'},
                            {label: '次月预估销量', prop: 'nextMthExpectSaleCount', minWidth: '80px'},
                            {label: '次次月预估销量', prop: 'nextTwoMthExpectSaleCount', minWidth: '80px'},
                        ],
                        templateSampleLists: [
                            {
                               skuCode: '00000011',
                               thisMthExpectSaleCount: 1,
                               nextMthExpectSaleCount: 1,
                               nextTwoMthExpectSaleCount: 1,
                            }
                        ],
                        api: handleConfigProductUpdateMonth,
                        apiParams: 'dorisCompanySalesDtos',
                        templateFileDownloadUrl: `${ process.env.VUE_APP_OSS_TEMPLATE_PATH }/导入预估销量模板.xlsx`
                    },
                    {
                        visible: false,
                        title: '批量导入其他库存',
                        type: 'importOtherInventory',
                        tipsText: '必需字段: 商品编码、其他库存',
                        templateSampleHead: [
                            {label: '商品编码', prop: 'skuCode', minWidth: '80px'},
                            {label: '其他库存', prop: 'otherKcCount', minWidth: '80px'}
                        ],
                        templateSampleLists: [
                            {
                               skuCode: '00000011',
                               otherKcCount: 1
                            }
                        ],
                        api: handleOtherInventoryImport,
                        apiParams: 'dorisCompanyOtherInventoryDtos',
                        templateFileDownloadUrl: `${ process.env.VUE_APP_OSS_TEMPLATE_PATH }/其它库存导入模板.xlsx`
                    },
                ]
            },
            queryForm: {
                page: 1,
                limit: 20,
                raceName: '',
                mainCategoryCode: '',
                midCategoryCode: '',
                subCategoryCode: '',
                branchGrade: '',
                cargoNo: '',
                skuName: ''
            },
            disabledEdit: true,
            loading: false,
            ratioAndGradeImportDialog: {
              visible: false,
              fileList: [],
              accept: ['.xls', '.xlsx'],
              template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%AF%BC%E5%85%A5%E5%88%86%E5%85%AC%E5%8F%B8%E4%BA%A7%E5%93%81%E7%AD%89%E7%BA%A7%26%E5%BA%93%E5%AD%98%E7%B3%BB%E6%95%B0%E6%A8%A1%E6%9D%BF.xlsx',
            },
            noticeDialog: {
              visible: false,
              msg: undefined,
            }
        }
    },
    mounted() {
        const queryForm = this.queryForm;
        this.handleUrlParams().then(res => {
            if ( !(res && res['x-token']) ) {
                this.queryForm = {
                    ...res,
                    ...queryForm
                }
            }
            this.handleSelectOptions()
        });
    },
    methods: {
        // 表头搜索
        handleSearch(params) {
            this.queryForm = {
                ...this.queryForm,
                ...params,
                boNextMthExpectKcRatioRange: params?.boNextMthExpectKcRatioRange?.join(';') || ''
            }
            this.handleFetchData()
        },
        // 加载列表数据
        handleFetchData() {
            this.$nextTick(() => {
                this.$refs['mgTable']?.loadData()
            })
        },
        // 表单顶部事件
        handleTableHeadAction(type) {
            const importDialog = this.importDialog;
            /**
             * importProductGrade       -- 导入产品等级
             * importInventoryFactor    -- 导入库存系数
             * importEstimatedSales     -- 导入预估销量
             * export                   -- 导出
             */
            if(['importProductGrade', 'importInventoryFactor', 'importEstimatedSales', 'importOtherInventory'].includes(type)) {
                const currentImportOption = importDialog.importBaseParams.find(item => item.type === type);
                importDialog.currentImportType = currentImportOption.type;
                currentImportOption.visible = true;
                return
            }
            const funcObj = {
                export: async () => {
                    const btnStatus = this.btnStatus;
                    const queryForm = this.queryForm;

                    this.handleResetFields();
                    const topHeaders = {}
                    const filterConfigurationCenterColumns = this.configurationCenterColumns.filter(item => item.prop)
                    filterConfigurationCenterColumns.map(item => {
                        return topHeaders[item.prop] = item.label
                    })
                    try {
                        btnStatus.isExport = true;
                        queryForm.page = 1;
                        queryForm.limit = 1000000;
                        const { code, data, msg } = await handleConfigProductLists(queryForm);
                        if ( code === 200 && data?.datas.length > 0) {
                            const newItems = data.datas && data.datas.map(item => {
                                return {
                                ...item,
                                }
                            }) || [];
                            excelExport({
                                topHeaders,
                                data: newItems,
                                title: '配置中心-商品 列表'
                            })
                        }
                    } catch(err) {
                        throw new Error(err)
                    } finally {
                        btnStatus.isExport = false;
                    }
                }
            };
            funcObj[type]();
        },
        // 重置表单字段
        handleResetFields() {
            const queryForm = this.queryForm
            queryForm.page = 1;
            queryForm.limit = 20;
            queryForm.raceName = '';
            queryForm.mainCategoryCode = '';
            queryForm.midCategoryCode = '';
            queryForm.subCategoryCode = '';
            queryForm.branchGrade = '';
            queryForm.cargoNo = '';
            queryForm.skuName = '';
            this.$refs['mgTable'].pagination.page = 1;
            this.$refs['mgTable'].pagination.limit = 10;
        },
        // 表格行操作事件
        handleTableRowEvent(type, row) {
            const funcObj = {
                default: () => {
                    if ( row.isEdit ) {
                        row.isEdit = false;
                        this.$refs['mgTable'].$refs['mgTable'].toggleRowSelection(row, false)
                    }
                },
                edit: () => {
                    if ( !row.isEdit ) {
                        row.isEdit = true;
                        this.$refs['mgTable'].$refs['mgTable'].toggleRowSelection(row, true)
                    }
                },
            };
            funcObj[type]();
        },
        // 导入成功回调
        handleImportSuccess(e) {
            const importDialog = this.importDialog;
            if(['importProductGrade', 'importInventoryFactor', 'importEstimatedSales', 'importOtherInventory'].includes(e.type)) {
                const currentImportOption = importDialog.importBaseParams.find(item => item.type === e.type);
                importDialog.currentImportType = currentImportOption.type;
                currentImportOption.visible = false;
            }
            this.handleFetchData();
        },
        // 监听可输入值变化事件
        handleChangeRow(type, row) {
            // 整型
            const intReg = /^\+?[1-9][0-9]*$/;
            // 保留一位小数
            const floatReg = /((^[1-9]\d*)|^0)(\.\d{0,1}){0,1}$/;
            const currentMonth = this.$dayjs().month() + 1;
            const nextMonth = this.$dayjs().month() + 2;
            const fileds = {
                branchGrade: '分公司产品等级',
                safeStockRatio: '安全库存系数',
                stockRatioAim: '库存系数目标',
                stockRatioUp: '库存系数上限',
                stockRatioDown: '库存系数下限',
                thisMthExpectSaleCount: `${ currentMonth }月预计销售`,
                nextMthExpectSaleCount: `${ nextMonth }下月预计销售`,
            };
            // 整数的字段
            const intFileds = [ 'thisMthExpectSaleCount', 'nextMthExpectSaleCount' ];
            // 小数的字段
            const floadFileds = [ 'safeStockRatio', 'stockRatioAim', 'stockRatioUp', 'stockRatioDown' ];
            // 可编辑字段须大于0
            if ( row[type] <= 0 ) return this.$message.error(`【${fileds[type]}】数据有误,须大于0且小于等于10的数字(仅保留1为小数)`, 3000)
            // 字段为小数时的判断
            if ( floadFileds.includes(type) ) {
                if ( !floatReg.test(''+row[type]) ) return this.$message.error(`【${fileds[type]}】数据有误,须大于0且小于等于10的数字(仅保留1为小数)`, 3000)
                if ( row[type] > 10 ) return this.$message.error(`【${fileds[type]}】数据有误,不能大于10`, 3000)
                if ( type === 'stockRatioAim' && !(row[type] >= row['stockRatioDown'] && row[type] <= row['stockRatioUp']) ) return this.$message.error(`【${fileds[type]}】数据有误,须>=【库存系数下限】且<=【库存系数上限】`, 5000)
                if ( type === 'stockRatioDown' && !(row[type] <= row['stockRatioAim'] && row['stockRatioAim'] <= row['stockRatioUp']) ) return this.$message.error(`【${fileds[type]}】数据有误,须>=【库存系数下限】且<=【库存系数上限】`, 5000)
                if ( type === 'stockRatioUp' && !(row['stockRatioAim'] >= row['stockRatioDown'] && row[type] >= row['stockRatioAim']) ) return this.$message.error(`【${fileds[type]}】数据有误,须>=【库存系数下限】且<=【库存系数上限】`, 5000)
            }
            // 字段为整数时的判断
            if ( intFileds.includes(type) && !intReg.test(''+row[type]) ) return this.$message.error(`【${fileds[type]}】数据有误,须大于0的整数`, 3000)
            let paramsObj = {};
            new Promise((resolve, reject) => {
                this.handleUrlParams().then(res => {
                    if ( !(res && res['x-token']) ) {
                        paramsObj = {
                            ...row,
                            ...res,
                            nextMthExpectSaleCount: row.nextMthExpectSaleCount
                        }
                    } else {
                        paramsObj = { ...row, nextMthExpectSaleCount: row.nextMthExpectSaleCount }
                    }
                    resolve(paramsObj)
                });
            }).then(async res => {
                try {
                    const { code, msg } = await handleConfigProductEdit(res);
                    if (code === 200) {
                        this.handleFetchData();
                        return this.$message.success(msg)
                    }
                    if (code !== 200) return this.$message.error(msg)
                } catch(err) {
                    throw new Error(err)
                }
            })
        },
        // 下拉列表选项
        handleSelectOptions() {
            // 产品等级
            const gradeField = {
                key: 'branchGrade',
                label: 'label',
                value: 'value'
            }
            // 赛道
            const raceField = {
                key: 'raceName',
                label: 'label',
                value: 'value'
            }
            // 大类
            const categoryField = {
                key: 'mainCategoryCode',
                label: 'max_name',
                value: 'max_code'
            }
            const paramsObj = {
                dict_type: 'plu_grade',
                operator: this.urlParams?.operator || ''
            }
            Promise.all([
                this.handleProductGradeOption({ formItemList: this.formItemList, field: gradeField, paramsObj }),
                this.handleRaceNamesOption({ formItemList: this.formItemList, field: raceField, paramsObj }),
                this.handleProductMaxCatagoryOption({ formItemList: this.formItemList, field: categoryField, paramsObj })
            ])
        },
        // 根据大类获取中类
        handleMainCategoryNameChange(val) {
            const queryForm = this.queryForm
            const mgSearchFormRef = this.$refs['mgSearchFormRef']
            const formItemList = this.formItemList
            queryForm.midCategoryCode = '';
            mgSearchFormRef.form['midCategoryCode'] = ''
            if (!val) return;
            const paramsObj = {
                max_code: val,
                operator: this.urlParams?.operator || ''
            };
            const field = {
                key: 'midCategoryCode',
                label: 'mid_name',
                value: 'mid_code'
            }
            this.handleProductMidCatagoryOption({ formItemList: formItemList, field, paramsObj })
        },
        // 根据中类获取小类
        handleMidCategoryNameChange(val) {
            const queryForm = this.queryForm
            const mgSearchFormRef = this.$refs['mgSearchFormRef']
            const formItemList = this.formItemList
            queryForm.subCategoryCode = '';
            mgSearchFormRef.form['subCategoryCode'] = ''
            if (!val) return;
            const paramsObj = {
                max_code: val,
                operator: this.urlParams?.operator || ''
            };
            const field = {
                key: 'subCategoryCode',
                label: 'mid_name',
                value: 'mid_code'
            }
            this.handleProductMinCatagoryOption({ formItemList: formItemList, field, paramsObj })
        },
        handleImport() {
          this.ratioAndGradeImportDialog.visible = true;
        },
        handleFileChange(file, fileList, type) {
          if (type === 'remove') {
            this.ratioAndGradeImportDialog.fileList = fileList;
            return false;
          }
          const suffix =
            file.name.indexOf(".") > 0
              ? file.name.substring(file.name.lastIndexOf("."))
              : undefined;
          if (this.ratioAndGradeImportDialog.accept.indexOf(suffix) < 0) {
            this.$message.error("文件格式不支持");
            this.ratioAndGradeImportDialog.fileList = [];
          } else {
            this.ratioAndGradeImportDialog.fileList = fileList;
          }
        },
        submitImport() {
          if (this.ratioAndGradeImportDialog.fileList.length === 0) {
            Notification.warning('请先上传文件！');
            return false;
          }
          let fd = new FormData();
          this.ratioAndGradeImportDialog.fileList.forEach(item=>{
            //文件信息中raw才是真的文件
            fd.append("file",item.raw);
          })
          this.loading = true;
          ratioAndGradeImport(fd).then((res) => {
            if (res && res.code !== 200) {
              this.noticeDialog.msg = res.msg;
              this.noticeDialog.visible = true;
            } else {
              this.noticeDialog.msg = res.msg;
              this.noticeDialog.visible = true;
              this.ratioAndGradeImportDialog.visible = false;
              this.handleFetchData();
            }
            this.loading = false;
            this.$refs.ratioAndGradeUpload.clearFiles();
          })
        }
    }
}
</script>

<style lang="scss" scoped src="@/style/branchOffice/common.scss"></style>
<style lang="scss" scoped>
    .mg-list-box {
        margin-top: 0;
        padding: 0;
        ::v-deep .el-table__empty-text {
            width: 31%;
            text-align: left;
        }
    }
</style>
