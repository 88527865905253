<template>
  <div>
    <h1 class="item default" :style="styles">
      <div class="taskInfo">
        <div class="taskInfoL">
          <p class="taskName text-1 closed-p" :style="{'color': task.configJson.theme?.taskName?.color}">{{task.taskName}}</p>
          <p class="taskRange closed-p" :style="'width: fit-content;'">{{task.taskRange === '1' ? "长期任务" : '任务时间' + parseShowTime(task?.startDate, '{m}.{d}')+'-' +parseShowTime(task?.endDate, '{m}.{d}') }}</p>
        </div>
        <div class="taskInfoR">
          <!--        <RightOutline />-->
        </div>
      </div>

      <div class="dataInfo">
        <div class="dataInfoTitle">
          <span class="badge">{{task.taskRange === 1 ? '本月数据' : '任务期间'}}</span>
          <span
            class="more" :style="'color:' + task.configJson.theme?.moreDataText?.color">
        更多数据
        </span>
        </div>
        <div class="dataBox">
          <div
            v-for="(item,index) in  (type === 'shop' ?task.configJson.shop :task.configJson.summary)"
            :key="index"
            class="numBox"
          >
            <p class="label closed-p" :style="'width: max-content;color:' + hexToRgba(item.value.color,0.7)">{{ item.title }}
            </p>
            <p class="value closed-p">
              <span :style="'font-size: 13; color:' + item.value.color"><span>{{item.prefix}}</span> 0 {{item.unit}}</span>
            </p>
          </div>
        </div>
      </div>
    </h1>
  </div>
</template>

<script>
import { parseTime, hexToRgba } from "@/utils";

export default {
  name: "TaskDefaultItem",
  props: {
    task: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: "shop"
    },
    styles: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      parseShowTime: parseTime,
      hexToRgba,
    }
  }
}

</script>

<style lang="scss" scoped src='@/style/appTask/groupItem.scss'></style>
