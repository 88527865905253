<template>
  <div class="salesCenter-page">

        <div class="salesCenter-search">
          <v-signboard :title="'请购单确认列表'"/>
          <el-form
            class="main-search"
            size="mini"
            label-width="auto"
            ref="buyingReq-form"
            :inline="true"
            :model="form"
            @keyup.enter.native="handleSubmit"
          >
            <el-form-item label="产品等级" size="mini" >
              <el-select clearable v-model="form.productGrade" multiple>
                <el-option
                  v-for="item in options.productGradeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="大类" size="mini">
              <el-select clearable v-model="form.productFirstCategorie" @change="handleOptionChange($event, 'max')">
                <el-option
                  v-for="item in options.productMaxCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中类" size="mini">
              <el-select clearable v-model="form.productMiddleCategorie" @change="handleOptionChange($event, 'mid')">
                <el-option
                  v-for="item in options.productMidCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小类" size="mini">
              <el-select clearable v-model="form.productLittleCategorie" @change="handleOptionChange($event, 'min')">
                <el-option
                  v-for="item in options.productMinCategorieOtions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生产类型" size="mini">
              <el-select clearable v-model="form.prod_type">
                <el-option label="生产中心" value="生产中心"></el-option>
                <el-option label="OEM中心" value="OEM中心"></el-option>
                <el-option label="生产中心/办公" value="生产中心/办公"></el-option>
                <el-option label="办公" value="办公"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="锁状态" size="mini">
              <el-select clearable v-model="form.lock_state">
                <el-option label="全部" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品赛道" size="mini">
              <el-select clearable v-model="form.race_name" style="width:90px">
                <el-option
                  v-for="item in options.raceNameLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="原始料号" size="mini">
              <el-input v-model="form.material_code" clearable placeholder="请输入原始料号"/>
            </el-form-item>
            <el-form-item label="是否有客订" size="mini">
              <el-select clearable v-model="form.order_count_type" style="width:90px">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="有客订" :key="1" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="理论请购量分类" size="mini">
              <el-select clearable v-model="form.theory_shipment_count_type" style="width:90px">
                <el-option label="全部" :key="0" :value="0"></el-option>
                <el-option label="为零" :key="1" :value="1"></el-option>
                <el-option label="超一倍(理论请购量超过分公司客订量*2)" :key="2" :value="2"></el-option>
                <el-option label="低一半(理论请购量小于分公司客订量/2)" :key="3" :value="3"></el-option>
                <el-option label="正常" :key="4" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-button
              class="form-btn-item"
              size="mini"
              type="primary"
              :loading="btnStatus.isSub"
              @click="handleSubmit"
            >查 询
            </el-button>
            <el-button
              class="form-btn-item"
              size="mini"
              :loading="btnStatus.isReset"
              @click="handleReset"
            >重 置
            </el-button>
            <el-button
              size="mini"
              :disabled="btnStatus.isExport"
              :loading="btnStatus.isExportLoading"
              @click="handleTableHeadAction('export')">导 出
            </el-button>
          </el-form>
        </div>
        <div class="salesCenter-content">
          <el-row>
            <el-col class="salesCenter-tips">根据您设置的库存系数目标，经数据中心计算，得到请购单如下：</el-col>
          </el-row>
          <el-table
            class="table"
            stripe
            border
            height="500"
            size="mini"
            ref="multipleTable"
            :data="lists"
            v-loading="tableStatus.isLoading"
            @selection-change="handleselection"
          >
            <el-table-column label="序号" align="center" width="50" fixed="left">
              <template slot-scope="scope">
                <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }} </span>
              </template>
            </el-table-column>
            <template v-for="(item, index) in tableObj.pluOrderDetailHead">
              <el-table-column v-bind="item" show-overflow-tooltip :key="index" align="center"/>
            </template>
          </el-table>
          <!-- 分页器 -->
          <v-pagination
            :total="total"
            :pageSize="pageSize"
            :currentPage="currentPage"
            @pageChange="pagingChange"
          />
        </div>
  </div>
</template>

<script>
import {optionsObj, paginationParams, tableLoading, urlParams} from '@/mixins';
import {pluOrderDetailHead} from '../config';
import {excelExport, keepTwoDecimals, thousandsSeparator} from "@/utils";
import {handleDownloadPluOrder, handlePluOrderLists} from '@/api/salesCenter';

export default {
  // 请购单页面
  name: 'pluOrderDetail',
  mixins: [tableLoading, paginationParams, urlParams, optionsObj],
  components: {
    vSignboard: () => import('@/components/Signboard'),
    UploadExcel: () => import('@/components/UploadExcel/index'),
    vPagination: () => import("@/components/Element/Pagination")
  },
  data() {
    return {
      originalForm: {},                   // 拷贝一份form表单数据, 用于监听校验表单值的变化
      btnStatus: {
        isSub: false,                   // 是否查询
        isDisable: true,                // 控制查询disabled
        isReset: false,                 // 重置
        isSave: true,                   // 是否保存
        isProdSetZero: true,                   // 是否请购单置零
        isExport: false,                // 是否导出
        isConfirm: true,                // 是否确认
        isResetSetting: true,           // 是否重新设定库存系数
        isExportLoading: false,         // 导出loading
      },
      flowStatusText: {
        1: '原始数据',
        2: '被修改过',
        3: '审核过'
      },
      lists: [],
      tableObj: {
        pluOrderDetailHead
      },
      dialogStatus: {
        isUpdateStoreRatio: false         // 是否修改库存目标系数
      },
      dialogFormModel: {
        update_type: 1,
        material_code: '',
        grade: '',
        main_category_name: '',
        mid_category_name: '',
        sub_category_name: '',
        store_ratio_up: 2,
        store_ratio_down: 0.5,
        store_ratio_aim: 1,
      },
      dialogFormModelRules: {
        update_type: [{required: true, message: '请选择修改类型', trigger: 'blur'}]
      },
      activeName: 'onlineWrite',
      selectRows: [],
      urlMonth: ''
    }
  },
  watch: {
    form: {
      handler: function (n) {
        let __that = this;
        for (let i in __that.originalForm) {
          let form_val = __that.form[i];
          if (form_val instanceof Array) {
            if (form_val && form_val.length == 0) {
              __that.btnStatus.isDisable = true;
            }
            if (form_val && form_val.length > 0) {
              __that.btnStatus.isDisable = false;
              break;
            }
          } else {
            if (n[i] != __that.originalForm[i]) {
              if (__that.originalForm[i] == null) {
                __that.btnStatus.isDisable = true;
                break;
              }
              if (n[i] != null) {
                __that.btnStatus.isDisable = false;
                break;
              }
            } else {
              __that.btnStatus.isDisable = true;
            }
          }
        }
      },
      deep: true
    },
    selectRows: {
      handler(n) {
        const btnStatus = this.btnStatus;
        if (n.length > 0) {
          btnStatus.isSave = false;
          btnStatus.isConfirm = false;
          btnStatus.isProdSetZero = false;
        }
        if (n.length == 0) {
          btnStatus.isSave = true;
          btnStatus.isConfirm = true;
          btnStatus.isProdSetZero = true;
        }
      }
    }
  },
  created() {
    let formPrams = {
      ...this.form,
      plu_code: '',
      order_count_type: 0,
      theory_shipment_count_type: 0,
    }
    this.form = formPrams;
    this.originalForm = Object.assign({}, this.form);
    this.urlMonth = this.$route.query?.month || '';
    this.handleUrlParams().then(res => {
      this.getLists();
    });
  },
  methods: {
    async getLists() {
      try {
        const form = this.form;
        const formParams = {
          order_count_type: form.order_count_type,
          theory_shipment_count_type: form.theory_shipment_count_type || '',
          grade: form.productGrade || '',
          lock_state: form.lock_state || '',
          main_category_name: form.productFirstCategorieName || '',
          mid_category_name: form.productMiddleCategorieName || '',
          sub_category_name: form.productLittleCategorieName || '',
          prod_type: form.prod_type || '',
          race_name: form.race_name || '',
          material_code: form.material_code,
          month: this.urlMonth || '',
          operator: this.urlParams.operator,
        }
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          ...formParams
        }
        const {code, data, msg} = await handlePluOrderLists(params);
        if (data) {
          const {plu_order_list, total} = data;
          this.modifyData(plu_order_list).then(res => {
            this.lists = res;
          });
          this.total = total;
          this.tableStatus.isLoading = false;
        }
      } catch (err) {
        this.lists = [];
        this.total = 0;
        this.tableStatus.isLoading = false;
        console.log(err, 'err-------请购单确认列表接口有误')
      }
    },
    // 修饰数据
    modifyData(lists) {
      return new Promise((resolve, reject) => {
        let newItems = [];
        lists.map(item => {
          let newItem = {
            order_month: item.order_month || '--',
            prod_type: item.prod_type || '--',
            plu_code: item.plu_code || '--',
            plu_id: item.plu_id || '--',
            plu_name: item.plu_name || '--',
            material_code: item.material_code || '--',
            cargono: item.cargono || '--',
            flow_status: this.flowStatusText[item.flow_status] || '--',

            race_name: item.race_name || '--',
            main_category_name: item.main_category_name || '--',
            mid_category_name: item.mid_category_name || '--',
            sub_category_name: item.sub_category_name || '--',
            stats_category_name: item.stats_category_name || '--',
            price: item.price || '--',
            lock_state: item.lock_state || '--',

            plu_mnemonic_code: item.plu_mnemonic_code || '--',
            grade: item.grade || '--',

            hq_before_last_month_stock_count: thousandsSeparator(item.hq_before_last_month_stock_count, 0) || 0,
            hq_last_month_sale_count: thousandsSeparator(item.hq_last_month_sale_count, 0) || 0,
            hq_last_month_ql_count: thousandsSeparator(item.hq_last_month_ql_count, 0) || 0,
            last_month_carryover_count: thousandsSeparator(item.last_month_carryover_count, 0) || 0,
            hq_last_month_pc_count: thousandsSeparator(item.hq_last_month_pc_count, 0) || 0,
            hq_this_month_ql: thousandsSeparator(item.hq_this_month_ql, 0) || 0,
            hq_last_month_stock_ratio: keepTwoDecimals(item.hq_last_month_stock_ratio) || 0,
            bo_last_month_stock_count: thousandsSeparator(item.bo_last_month_stock_count, 0) || 0,
            bo_last_month_stock_ratio: keepTwoDecimals(item.bo_last_month_stock_ratio) || 0,
            hq_this_month_stock_ratio: keepTwoDecimals(item.hq_this_month_stock_ratio) || 0,
            bo_last_month_order_count: thousandsSeparator(item.bo_last_month_order_count, 0) || 0,
            store_ratio_aim_count: thousandsSeparator(item.store_ratio_aim_count, 0) || 0,
            store_ratio_up_count: thousandsSeparator(item.store_ratio_up_count, 0) || 0,
            store_ratio_down_count: thousandsSeparator(item.store_ratio_down_count, 0) || 0,


            hg_last_month_store_count: thousandsSeparator(item.hg_last_month_store_count, 0) || 0,
            hg_month_avg_sale_count: thousandsSeparator(item.hg_month_avg_sale_count, 0) || 0,
            prod_count_up: thousandsSeparator(item.prod_count_up, 0) || 0,
            prod_count_down: thousandsSeparator(item.prod_count_down, 0) || 0,
            store_ratio_aim: keepTwoDecimals(item.store_ratio_aim) || 0,
            store_ratio_up: keepTwoDecimals(item.store_ratio_up) || 0,
            store_ratio_down: keepTwoDecimals(item.store_ratio_down) || 0,
            total_previous_put_count: thousandsSeparator(item.total_previous_put_count, 0) || 0,
            confirm_bo_kd_plu_count: thousandsSeparator(item.confirm_bo_kd_plu_count, 0) || 0,
            prepare_shipment_count: item.prepare_shipment_count || 0,
            hq_this_month_total_count: item.hq_this_month_total_count || 0,
            theory_shipment_count: thousandsSeparator(item.theory_shipment_count, 0) || 0,
            update_by: item.update_by || '--',
            update_time: item.update_time || '--',
            bottom_spec: item.bottom_spec || '1'
          }
          newItems.push(newItem);
        });
        resolve(newItems);
      })
    },
    // 导出数据
    async exportData() {

      let topHeaders = {}
      for(let i = 0; i < pluOrderDetailHead.length; i++) {
        let row = pluOrderDetailHead[i];
        topHeaders[row.prop] = row.label;
      }
      try {
        this.btnStatus.isExportLoading = true;
        const {code, data} = await handleDownloadPluOrder({operator: this.urlParams.operator});
        this.btnStatus.isExportLoading = false;
        if (code === '0000') {
          const {plu_order_list} = data;
          if (plu_order_list.length > 0) {
            excelExport({
              topHeaders,
              data: plu_order_list,
              title: '请购单确认'
            })
          }
        }
      } catch (err) {
        this.btnStatus.isExportLoading = false;
        console.log(err, 'err--------请购单确认列表导出接口有误');
      }
    },
    // 头部搜索提交
    handleSubmit() {
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 重置
    handleReset() {
      this.form = {
        productGrade: '',
        lock_state: '',
        productFirstCategorie: '',
        productFirstCategorieName: '',
        productMiddleCategorie: '',
        productMiddleCategorieName: '',
        productLittleCategorie: '',
        productLittleCategorieName: '',
        prod_type: ''
      }
      this.currentPage = 1;
      this.tableStatus.isLoading = true;
      this.getLists();
    },
    // 全选函数
    handleselection(e) {
      this.selectRows = e;
    },
    // 表格头部操作
    handleTableHeadAction(type) {
      const funcObj = {
        'export': () => {                     // 导出数据
          this.exportData();
        }
      }
      funcObj[type]();
    },
    // 分页监听函数
    pagingChange(e) {
      if (!!e.current_page) this.currentPage = e.current_page;
      if (!!e.current_page) this.pageSize = e.per_page;
      this.tableStatus.isLoading = true;
      this.getLists();
    }
  }
}
</script>

<style lang="scss" scoped src='@/style/salesCenter/layoutMain.scss'></style>
<style lang="scss" scoped>
.form-pluCode-item {
  ::v-deep .el-form-item__content {
    width: 200px;
  }
}
.salesCenter-content {
  ::v-deep .el-table__empty-text {
    width: 66%;
  }
}
</style>
