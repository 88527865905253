<template>
  <div style="padding: 20px">
    <label>{{ targetName }}</label>
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
    >
      <el-table-column label="人员" prop="nickName" width="150">
        <template #default="{ row }">
          <span v-if="row.userName">{{ row.userName }}-</span>
          <span>{{ row.nickName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="满分/金额" prop="maxNum" width="100"/>
      <el-table-column label="实际得分/金额" prop="boostNum" width="100" show-overflow-tooltip />
      <el-table-column label="操作" width="200" prop="action" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" plain @click="() => {
            currentRow = deepClone(scope.row);
            detailDrawer.visible = true;
          }">明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-drawer title="明细" :visible.sync="detailDrawer.visible" size="85%" direction="rtl" append-to-body modal-append-to-body :wrapperClosable="false">
      <SummaryDetail :id="id" :condition="condition" :target-name="targetName" :user-name="currentRow.userName" />
      <div style="text-align: right;position: absolute; bottom: 50px; right: 50px;">
        <el-button size="mini" @click="detailDrawer.visible = false">关 闭</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { dictSelect, userSelect, pageSummary, exportSummary } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
import SummaryDetail from "./summaryDetail";

export default {
  name: "UserTag",
  components: {  Pagination, SummaryDetail },
  props: {
    // 导入弹窗基础参数
    id: {
      type: Number,
      required: true
    },
    condition: {
      type: String,
      required: true
    },
    targetName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dictFun: dictSelect,
      dictUserFun: userSelect,
      deepClone,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        userName: undefined,
        tagId: undefined,
        orgCode: undefined,
      },
      loading: false,
      currentRow: {},
      detailDrawer: {
        visible: false,
      }
    };
  },
  watch: {
    id: {
      handler(nV, oV) {
        if (nV) {
          this.handleFilter();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageSummary(this.id, query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data.records;
        this.pageData.total = res.data.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    handleExport() {
      this.loading = true;
      exportSummary(this.id, this.queryForm).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', this.targetName + '绩效汇总导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
  }
}
</script>

<style scoped>

</style>
