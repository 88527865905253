<template>
  <div style="padding: 20px" v-loading="homeLoading">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="平台">
          <DictSelect
            ref="platform"
            type="platform"
            :value.sync="queryForm.platforms"
            multiple
            linkageRef="spuName,spuModel"
            :dictFun="dictFun"
          />
        </el-form-item>
        <el-form-item label="组合装名称">
          <DictSelect
            ref="spuName"
            type="spuName"
            parentLinkageRef
            :value.sync="queryForm.spuNames"
            :dictFun="dictFun"
            multiple
            remote
            @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
          />
        </el-form-item>
        <el-form-item label="型号">
          <DictSelect
            ref="spuModel"
            type="spuModel"
            parentLinkageRef
            :value.sync="queryForm.skuModels"
            :dictFun="dictFun"
            multiple
            remote
            @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleSpuAdd">新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleSpuImportNotice">导入组合装控价</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleSpuExportNotice">导出组合装控价</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      ref="tableRef"
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }">
      <el-table-column label="平台" prop="ptName" min-width="80" />
      <el-table-column label="组合装编码" prop="spuCode" min-width="60" />
      <el-table-column label="组合装名称" prop="spuName" min-width="200" />
      <el-table-column label="组合装型号" prop="skuModel" min-width="100" />
      <el-table-column label="售卖规格单位" prop="skuSpecifications" min-width="100" />
      <el-table-column label="规格含数量" prop="skuSpecificationsQuantity" min-width="100" />
      <el-table-column label="售卖总价" prop="spuSellPrice" min-width="100" />
      <el-table-column label="是否赠品" prop="ifGift" min-width="100" />
      <el-table-column label="操作" min-width="120" align="center" class-name="small-padding fixed-width">
        <template #default="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleSpuEdit(scope.row)"
          >编辑</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDeleteSpu(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />

    <el-dialog title="组合装控价导入" :visible.sync="spuImportDialog.visible" append-to-body width="400px">
      <el-upload
        ref="spuUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="spuImportDialog.fileList"
        :on-change="(file, fileList) => handleSpuFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleSpuFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="spuImportDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="spuSubmitDisabled" @click="submitSpuImport">确定</el-button>
        <el-button type="primary" size="mini" @click="spuImportDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="" :visible.sync="spuInfoUpdateDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="spuInfoUpdateDialog.form" :rules="spuInfoUpdateDialog.rules">
        <el-form-item label="平台" prop="ptName">
          <el-input v-model="spuInfoUpdateDialog.form.ptName" disabled="true"/>
        </el-form-item>
        <el-form-item label="组合装编码" prop="spuCode">
          <el-input v-model="spuInfoUpdateDialog.form.spuCode" disabled="true"/>
        </el-form-item>
        <el-form-item label="组合装名称" prop="spuName">
          <el-input v-model="spuInfoUpdateDialog.form.spuName" clearable placeholder="请输入组合装名称" />
        </el-form-item>
        <el-form-item label="型号" prop="skuModel">
          <DictSelect
            ref="skuModel"
            type="sku_model"
            :value.sync="spuInfoUpdateDialog.form.skuModel"
            :dictFun="dictSkuModelsFun"
            :init-props="{ label: 'skuModel', value: 'skuModel'}"
            remote
          />
        </el-form-item>
        <el-form-item label="售卖规格单位" prop="skuSpecifications">
          <el-input v-model="spuInfoUpdateDialog.form.skuSpecifications" clearable placeholder="请输入售卖规格单位" />
        </el-form-item>
        <el-form-item label="规格含数量" prop="skuSpecificationsQuantity">
          <el-input-number v-model="spuInfoUpdateDialog.form.skuSpecificationsQuantity" clearable placeholder="请输入规格含数量" />
        </el-form-item>
        <el-form-item label="售卖总价" prop="spuSellPrice">
          <el-input-number v-model="spuInfoUpdateDialog.form.spuSellPrice" clearable placeholder="请输入售卖总价" />
        </el-form-item>
        <el-form-item label="是否赠品" prop="ifGift">
          <el-select v-model="spuInfoUpdateDialog.form.ifGift" size="mini" clearable>
            <el-option value="是" label="是" /><el-option value="否" label="否" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="spuInfoUpdateDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitSpuInfoEdit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="spuInfoAddDialog.title" :visible.sync="spuInfoAddDialog.visible" width="1200px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="spuInfoAddDialog.form" :rules="spuInfoAddDialog.rules">
        <el-row>
          <el-col :span="8">
            <el-form-item label="平台" prop="ptName">
              <el-input v-model="spuInfoAddDialog.form.ptName" clearable placeholder="请输入平台" style="width: 200px"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="组合装编码" prop="spuCode">
              <el-input v-model="spuInfoAddDialog.form.spuCode" clearable placeholder="请输入组合装编码" style="width: 200px"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="组合装名称" prop="spuName">
              <el-input v-model="spuInfoAddDialog.form.spuName" clearable placeholder="请输入组合装名称" style="width: 200px"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        :data="spuInfoAddDialog.form.skuModelInfos"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="型号" prop="skuModel">
          <template slot-scope="{ row }">
            <DictSelect
              ref="skuModel"
              type="sku_model"
              :value.sync="row.skuModel"
              :dictFun="dictSkuModelsFun"
              :init-props="{ label: 'skuModel', value: 'skuModel'}"
              remote
            />
          </template>
        </el-table-column>
        <el-table-column label="售卖规格单位" prop="skuSpecifications">
          <template slot-scope="{ row }">
            <el-input v-model="row.skuSpecifications" placeholder="请输入售卖规格单位" size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="规格含数量" prop="skuSpecificationsQuantity">
          <template slot-scope="{ row }">
            <el-input-number v-model="row.skuSpecificationsQuantity" clearable placeholder="请输入规格含数量" />
          </template>
        </el-table-column>
        <el-table-column label="售卖总价" prop="spuSellPrice">
          <template slot-scope="{ row }">
            <el-input-number v-model="row.spuSellPrice" clearable placeholder="请输入售卖总价" />
          </template>
        </el-table-column>
        <el-table-column label="是否赠品" prop="ifGift">
          <template slot-scope="{ row }">
            <el-select v-model="row.ifGift" size="mini">
              <el-option value="是" label="是" />
              <el-option value="否" label="否" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="deleteSkuModelLine(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="text"
            icon="Plus"
            size="mini"
            @click="addSkuModelLine"
          >新增型号</el-button>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="spuInfoAddDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAddSpuForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  techSpuPriceControlPageList,
  getTechSpuPriceControlOptions,
  exportSpuNotice,
  dimEcSpuImport,
  deleteSpu, getSkuModelInfoList, updateSpu, addSpu
} from "@/api/eCommerce";
import { deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "TechSpuPriceControl",
  components: { Pagination, DictSelect},
  data() {
    return {
      homeLoading: false,
      tabName: 'tm',
      dictFun: getTechSpuPriceControlOptions,
      dictSkuModelsFun: getSkuModelInfoList,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        platforms: [],
        spuNames: [],
        skuModels: [],
      },
      loading: false,
      spuImportDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '导入组合装控价',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E7%BB%84%E5%90%88%E8%A3%85%E6%8E%A7%E4%BB%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV1.xlsx'
      },
      spuSubmitDisabled: false,
      spuInfoUpdateDialog: {
        visible: false,
        form: {
          ptName: undefined,
          spuCode: undefined,
          spuName: undefined,
          skuModel: undefined,
          skuSpecifications: undefined,
          skuSpecificationsQuantity: undefined,
          spuSellPrice: undefined,
          ifGift: undefined,
        },
        rules: {
          ptName: [{required: true, message: ' ', trigger: 'change'}],
          spuCode: [{required: true, message: '', trigger: 'change'}],
          spuName: [{required: true, message: '组合装名称不能为空！', trigger: 'change'}],
          skuModel: [{required: true, message: '型号不能为空！', trigger: 'change'}],
          skuSpecifications: [{required: true, message: '售卖规格单位不能为空！', trigger: 'change'}],
          skuSpecificationsQuantity: [{required: true, message: '规格含数量不能为空！', trigger: 'change'}],
          spuSellPrice: [{required: true, message: '售卖总价不能为空！', trigger: 'change'}],
          ifGift: [{required: true, message: '是否赠品不能为空！', trigger: 'change'}],
        }
      },
      oldSkuModel: undefined,
      spuInfoAddDialog: {
        visible: false,
        title: "新增组合装控价",
        form: {
          ptName: undefined,
          spuCode: undefined,
          spuName: undefined,
          skuModelInfos:[],
        },
        rules: {
          ptName: [{required: true, message: '平台不能为空！', trigger: 'change'}],
          spuCode: [{required: true, message: '组合装编码不能为空！', trigger: 'change'}],
          spuName: [{required: true, message: '组合装名称不能为空！', trigger: 'change'}],
        },
      },
    };
  },
  watch: {
  },
  created() {
    // this.handleFilter();
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      techSpuPriceControlPageList(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        limit: 10,
        platforms: [],
        spuNames: [],
        spuModels: [],
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleJumpQuery() {
      this.handleFilter();
    },
    handleSpuImportNotice() {
      this.spuImportDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.spuImportDialog.visible = true;
      this.spuSubmitDisabled = false;
    },
    submitSpuImport() {
      if (this.spuImportDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.spuImportDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.spuSubmitDisabled = true;
      dimEcSpuImport(fd).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
            window.open(res.data, '_blank');
          } else {
            Notification.success("操作成功!");
            this.spuImportDialog.visible = false;
            this.handleFilter()
          }
        }
        this.spuSubmitDisabled = false;
        this.$refs.upload.clearFiles();
      })
    },
    handleSpuFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.spuImportDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.spuImportDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.spuImportDialog.fileList = [];
      } else {
        this.spuImportDialog.fileList = fileList;
      }
    },
    handleSpuExportNotice() {
      this.homeLoading = true;
      exportSpuNotice(this.queryForm).then(res => {
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = "组合装控价导出";
          link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
          link.click()
        } else {
          Notification.info("无可下载数据！");
        }
        this.homeLoading = false;
      })
    },
    handleDeleteSpu(data) {
      const argument = Object.assign({}, data);
      deleteSpu(argument).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.handleQuery();
      });
    },
    handleSpuEdit(data) {
      this.rsetSpuInfoUpdateDialogForm();
      this.spuInfoUpdateDialog.visible = true;
      this.spuInfoUpdateDialog.form = deepClone(data);
      this.oldSkuModel = data.skuModel;
    },
    rsetSpuInfoUpdateDialogForm(){
      this.spuInfoUpdateDialog.form = {
        ptName: undefined,
        spuCode: undefined,
        spuName: undefined,
        skuModel: undefined,
        skuSpecifications: undefined,
        skuSpecificationsQuantity: undefined,
        spuSellPrice: undefined,
        ifGift: undefined,
        oldSkuModel: undefined,
      };
      this.oldSkuModel = undefined;
      this.spuInfoUpdateDialog.visible = false;
    },
    submitSpuInfoEdit() {
      const argument = deepClone(this.spuInfoUpdateDialog.form);
      argument.oldSkuModel = this.oldSkuModel;
      updateSpu(argument).then(res => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.rsetSpuInfoUpdateDialogForm();
          this.spuInfoUpdateDialog.visible = false;
          this.handleQuery();
        }
      })
    },
    handleSpuAdd() {
      this.rsetSpuInfoAddDialogForm();
      this.spuInfoAddDialog.visible = true;
    },
    addSkuModelLine(){
      var newValue = {
        skuModel: undefined,
        skuSpecifications: undefined,
        skuSpecificationsQuantity: undefined,
        spuSellPrice: undefined,
        ifGift: '否',
      };
      this.spuInfoAddDialog.form.skuModelInfos.push(newValue);
    },
    deleteSkuModelLine(index){
      this.spuInfoAddDialog.form.skuModelInfos.splice(index, 1)
    },
    submitAddSpuForm() {
      const argument = deepClone(this.spuInfoAddDialog.form);
      addSpu(argument).then(res => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error(res.data);
          } else {
            this.$message.success("操作成功！");
            this.rsetSpuInfoAddDialogForm();
            this.spuInfoAddDialog.visible = false;
            this.handleQuery();
          }
        }
      })
    },
    rsetSpuInfoAddDialogForm(){
      this.spuInfoAddDialog.form = {
        ptName: undefined,
        spuCode: undefined,
        spuName: undefined,
        skuModelInfos:[],
      };
      this.spuInfoAddDialog.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
    content: "本页全选";
    color: black;
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
  }
</style>
