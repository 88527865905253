var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        _vm._g(
          _vm._b(
            {
              ref: "upload",
              class:
                !_vm.alwaysShowButton && _vm.fileList.length >= _vm.limit
                  ? "hide"
                  : "",
              attrs: {
                "before-upload": _vm.beforeUpload,
                "http-request": _vm.handleHttpRequest,
                headers: _vm.uploadHeaders,
                "before-remove": _vm.clearFiles,
                limit: _vm.limit,
                disabled: _vm.disabled,
                multiple: "",
                action: "",
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.fileList,
                "show-file-list": _vm.showFiles,
                "on-preview": _vm.handlePictureCardPreview,
              },
            },
            "el-upload",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("el-button", { attrs: { size: "mini", type: _vm.buttonType } }, [
            _vm._v(_vm._s(_vm.buttonName)),
          ]),
          _c("template", { slot: "tip" }, [
            _vm.tipMessage &&
            (_vm.alwaysShowButton || _vm.fileList.length < _vm.limit)
              ? _c("div", {
                  staticClass: "el-upload__tip",
                  domProps: { innerHTML: _vm._s(_vm.tipMessage) },
                })
              : _vm._e(),
            _vm.tips &&
            (_vm.alwaysShowButton || _vm.fileList.length < _vm.limit)
              ? _c("div", {
                  staticClass: "el-upload__tip",
                  domProps: { innerHTML: _vm._s(_vm.tips) },
                })
              : _vm._e(),
          ]),
        ],
        2
      ),
      !_vm.showFiles
        ? _c(
            "draggable",
            {
              on: { update: _vm.draggableUpdate },
              model: {
                value: _vm.fileList,
                callback: function ($$v) {
                  _vm.fileList = $$v
                },
                expression: "fileList",
              },
            },
            [
              _c(
                "transition-group",
                { staticClass: "uploader" },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "div",
                    { key: item.url, staticClass: "upload-list" },
                    [
                      item.url
                        ? _c("img", {
                            staticStyle: { height: "90px" },
                            attrs: { src: item.url },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "icon-container" }, [
                        item.url
                          ? _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  staticStyle: { "margin-right": "10px" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        item.url
                          ? _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-preview",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePreview(index)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-zoom-in" })]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }