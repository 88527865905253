<template>
  <div style="padding: 20px" v-loading="homeLoading">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect
              ref="org"
              type="org"
              :value.sync="queryForm.orgCode"
              linkageRef="dc,area-tag"
              :dictFun="dictFun"
              fullLabel
              default-first-option
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
              ref="dc"
              type="dc"
              :value.sync="queryForm.dcCode"
              linkageRef="area-tag"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="区域分组">
          <DictSelect
              ref="area-tag"
              type="area-tag"
              :value.sync="queryForm.areaTag"
              :dictFun="dictFun"
              parentLinkageRef
              alwaysChange
              @getOtherJsonParam="(callback) => callback(shopJsonParam)"
              @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @sort-change="handleSortChange"
    >
      <el-table-column label="分公司编码-名称" prop="orgCode" width="150">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心编码-名称" prop="dcCode" width="200">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域分组" prop="areaTag" width="100" />
      <el-table-column label="操作" width="120" align="center">
        <template #default="{ row }">
          <el-row>
            <el-col :span="12">
              <el-button size="mini" type="text" style="border: 0px; " @click="handleEdit(row, 'edit')">编辑</el-button>
            </el-col>
            <el-col :span="12">
              <el-popconfirm
                confirm-button-text='确认'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                :title="'确认删除区域分组“'+ row.areaTag+'”? 删除后该区域分组下的店铺会归入“其它“分组'"
                @confirm="handleDel(row)"
              >
                <el-button slot="reference" size="mini" type="text" style="border: 0px; ">删除</el-button>
              </el-popconfirm>
            </el-col>
          </el-row>

        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog title="" :visible.sync="editDialog.visible" width="200px" append-to-body :close-on-click-modal="false">
      <el-form ref="form" :model="editDialog.form" :inline="true" :rules="editDialog.rules" label-width="130px">
        <el-form-item label="修改区域分组名称" prop="newAreaTag" size="mini">
          <el-input
            v-model="editDialog.form.newAreaTag"
            clearable
            placeholder="请输入区域分组"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="editDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitEditForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dictSelect, shopDict, pageDcAreaTag, updateDcAreaTag } from "@/api/appTask";
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "DcArea",
  components: { Pagination, ImportDialog: () => import("@/components/ImportDialog") , DictSelect},
  data() {
    return {
      homeLoading: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      },
      loading: false,
      currentUser: {},
      dictFun: dictSelect,
      shopJsonParam: {},
      editDialog: {
        visible: false,
        form: {
          orgCode: undefined,
          dcCode: undefined,
          oldAreaTag: undefined,
          newAreaTag: undefined,
        },
        rules: {
          newAreaTag: [{ required: true, message: "区域分组不能为空", trigger: "change" }],
        }
      }
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageDcAreaTag(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        areaTag: undefined,
        orgCode: undefined,
        dcCode: undefined,
        yearMth: undefined,
        yearMths: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleDateChange(val) {
      if (val) {
        this.queryForm.createTimeBegin = val[0];
        this.queryForm.createTimeEnd = val[1];
      } else {
        this.queryForm.createTimeBegin = undefined;
        this.queryForm.createTimeEnd = undefined;
      }
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleEdit(row) {
      this.editDialog.form = {
        orgCode: row.orgCode,
        dcCode: row.dcCode,
        oldAreaTag: row.areaTag,
        newAreaTag: undefined,
      }
      this.editDialog.visible = true;
    },
    submitEditForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.callUpdateDcAreaTag(this.editDialog.form).then(res => {
            this.editDialog.visible = false;
          });
        }
      })
    },
    handleDel(row) {
      let data = {
        orgCode: row.orgCode,
        dcCode: row.dcCode,
        oldAreaTag: row.areaTag,
        newAreaTag: '其它',
      }
      this.callUpdateDcAreaTag(data);
    },
    callUpdateDcAreaTag(data) {
      return new Promise(resolve => {
        this.homeLoading = true;
        updateDcAreaTag(data).then(res => {
          if (res.code === 200) {
            this.$refs['area-tag'].handleRefChange(undefined, 'hand');
            this.homeLoading = false;
            Notification.success("操作成功！");
            this.handleFilter()
            resolve();
          }
        })
      })

    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-input__inner {
  padding-left: 5px !important;
  max-width: 200px !important;
}
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
