<template>
  <div>
    <div style="padding-bottom: 15px">
      <el-button type="primary" size="mini" style="margin-top: 20px;" @click="handleQwGroupMsgContentConfig(undefined)" >配企微消息</el-button>
      <el-form ref="queryFormRef" size="mini" inline label-width="auto" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-row>
          <el-form-item label="创建时间" style="text-align: right;margin-top: 20px;" label-width="80">
            <el-date-picker
              v-model="queryForm.createTime"
              size="mini"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="消息标题">
            <el-select v-model="queryForm.msgTopic" size="mini" filterable placeholder="选择" allow-create clearable>
              <el-option v-for="item in options.msgTopicOptions" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(80vh - 200px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
    >
      <el-table-column type="index" width="40" />
      <el-table-column label="序号" prop="msgGroupId" width="120" />
      <el-table-column label="消息标题" prop="msgTopic" width="150" />
      <el-table-column label="消息创建时间" prop="msgCreateTime" width="230" />
      <el-table-column label="操作人" prop="updateBy" width="120" />
      <el-table-column label="更新时间" prop="updateTime" width="230" />
      <el-table-column label="消息匹配状态" prop="matchStatus" sortable width="120" align="center">
        <template #default="{ row }">
          <el-tag :type="matchStatusMap[row.matchStatus]">
            {{ row.matchStatus }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center" >
        <template #default="{ row }">
          <el-button size="mini" type="text" class="table-button" @click="handleQwGroupMsgContentConfig(row.msgGroupId)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleFilter" />

    <!-- 配企微消息 -->
    <el-dialog title="配企微消息" :visible.sync="qwMsgDialog.visible" append-to-body width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="消息标题" prop="msgTopic">
          <el-input v-model="form.msgTopic" :disabled="this.configMode === 'edit'" placeholder="请输入消息标题" clearable />
        </el-form-item>
        <el-form-item label="消息内容" prop="content">
          <el-input v-model="form.content" type="textarea" :autosize="{ minRows: 10, maxRows: 30 }" placeholder="请输入消息内容" />
        </el-form-item>
        <el-form-item label="消息创建时间" prop="msgCreateTime"  >
          <el-date-picker
            v-model="form.msgCreateTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="cancel">取消</el-button>
        <el-button size="mini" type="primary" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import {
  addCustomQwGroupMsgContent,
  queryCustomQwGroupMsgPageList,
  getCustomQwGroupMsgMsgTopicList,
  getCustomQwGroupMsg
} from "@/api/tagPlatform";
import {Notification} from "element-ui";
import Pagination from '@/components/Pagination';

export default {
  name: "Custom",
  components: { Pagination },
  data() {
    return {
      loading: false,
      pageData: {
        total: 0,
        rows: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        startDate: undefined,
        endDate: undefined,
        msgTopic: undefined,
        createTime: undefined,
      },
      options: {
        msgTopicOptions: [],
      },
      detailDrawer: {
        visible: false,
        form: {
          version: undefined,
        },
        ruleData: [],
      },
      form: {
        msgGroupId: undefined,
        msgTopic: undefined,
        content: undefined,
        msgCreateTime: undefined,
      },
      qwMsgDialog:{
        visible: false,
      },
      rules: {
        msgTopic: [{ required: true, message: "消息标题不能为空", trigger: "change" }],
        content: [{ required: true, message: "消息内容不能为空", trigger: "change" }],
        msgCreateTime: [{ required: true, message: "消息创建时间不能为空", trigger: "change" }],
      },
      matchStatusMap: {
        '已匹配': 'success',
        '匹配失败': 'danger',
      },
      configMode: 'add',
    }
  },
  created() {
    getCustomQwGroupMsgMsgTopicList().then(res => {
      this.options.msgTopicOptions = res.data;
    });
    this.handleQuery();
  },
  methods: {
    handleFilter() {
      this.loading =true;
      let param = {};
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Date) {
            param[key] = parseTime(this.queryForm[key]);
          } else if (this.queryForm[key] instanceof Array) {
            if (key === 'createTime') {
              param['startDate'] = this.queryForm[key][0];
              param['endDate'] = this.queryForm[key][1];
            }
          }else {
            param[key] = this.queryForm[key];
          }
        }
      }
      queryCustomQwGroupMsgPageList(param).then(res => {
        this.pageData.rows = res.data.datas;
        this.pageData.total = res.data.total;
        this.loading = false;
      })
    },
    handleQuery() {
      this.queryForm.page = 1;
      this.handleFilter();
    },
    handleQwGroupMsgContentConfig(msgGroupId) {
      this.configMode = "add";
      this.form = {
        msgGroupId: undefined,
        msgTopic: undefined,
        content: undefined,
        msgCreateTime: undefined,
      };
      if(msgGroupId){
        this.configMode = "edit";
        getCustomQwGroupMsg(msgGroupId).then(response => {
          if (response.code === 200) {
            this.form = response.data;
            this.form.msgGroupId = msgGroupId;
            this.qwMsgDialog.visible = true;
          }else {
            this.qwMsgDialog.visible = false;
            this.handleQuery();
            this.$message.error(response.msg);
          }
        });
      }else {
        this.qwMsgDialog.visible = true;
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addCustomQwGroupMsgContent(this.form).then((res) => {
            if (res.code === 200) {
              Notification.success('配置企微内容成功！');
              this.qwMsgDialog.visible = false;
              this.handleQuery();
            }else {
              this.qwMsgDialog.visible = false;
              this.handleQuery();
            }
          });
        }
      });
    },
    // 取消按钮
    cancel() {
      this.qwMsgDialog.visible = false;
      this.form = {
        msgTopic: undefined,
        content: undefined,
        msgCreateTime: undefined,
      }
    },
}
}
</script>

<style lang="scss" scoped>
.rule{
  ::v-deep .el-table{
    .cell {
      white-space: pre-line;
    }
  }
}
</style>

