var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticStyle: { padding: "20px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "型号" } },
                [
                  _c("DictSelect", {
                    ref: "spuModel",
                    attrs: {
                      type: "spuModel",
                      parentLinkageRef: "",
                      value: _vm.queryForm.spuModels,
                      dictFun: _vm.dictSpuModelFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "spuModels", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: { click: _vm.handleSpuSpecificationsAdd },
                    },
                    [_vm._v("新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: { click: _vm.handleSpuSpecificationsImportNotice },
                    },
                    [_vm._v("导入包装（套装）转化关系维护")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleSpuSpecificationsExportNotice },
                    },
                    [_vm._v("导出包装（套装）转化关系维护")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-delete-solid",
                        type: "primary",
                      },
                      on: { click: _vm.handleBatchDeleteSpuSpecifications },
                    },
                    [_vm._v("批量删除")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tableRef",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "90" } }),
          _c("el-table-column", {
            attrs: { label: "型号", prop: "spuModel", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "子规格",
              prop: "childSpecifications",
              "min-width": "60",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "上级规格",
              prop: "superiorsSpecifications",
              "min-width": "60",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "比例", prop: "ratio", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "零售价", prop: "price", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              prop: "createTime",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建人",
              prop: "createNickName",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "120",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleSpuSpecificationsEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteSpuSpecifications(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.page,
          limit: _vm.queryForm.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "limit", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "包装（套装）转化关系维护导入",
            visible: _vm.spuSpecificationsImportDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.spuSpecificationsImportDialog,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "spuSpecificationsUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.spuSpecificationsImportDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleSpuSpecificationsFileChange(
                    file,
                    fileList,
                    "change"
                  ),
                "on-remove": (file, fileList) =>
                  _vm.handleSpuSpecificationsFileChange(
                    file,
                    fileList,
                    "remove"
                  ),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.spuSpecificationsImportDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.spuSpecificationsSubmitDisabled,
                  },
                  on: { click: _vm.submitSpuSpecificationsImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuSpecificationsImportDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.spuSpecificationsInfoUpdateDialog.title,
            visible: _vm.spuSpecificationsInfoUpdateDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.spuSpecificationsInfoUpdateDialog,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.spuSpecificationsInfoUpdateDialog.form,
                rules: _vm.spuSpecificationsInfoUpdateDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "型号", prop: "spuModel" } },
                [
                  _c("DictSelect", {
                    ref: "skuModel",
                    attrs: {
                      type: "sku_model",
                      value:
                        _vm.spuSpecificationsInfoUpdateDialog.form.spuModel,
                      dictFun: _vm.dictSkuModelsFun,
                      "init-props": { label: "skuModel", value: "skuModel" },
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.spuSpecificationsInfoUpdateDialog.form,
                          "spuModel",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "子规格", prop: "childSpecifications" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入上级规格" },
                    model: {
                      value:
                        _vm.spuSpecificationsInfoUpdateDialog.form
                          .childSpecifications,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.spuSpecificationsInfoUpdateDialog.form,
                          "childSpecifications",
                          $$v
                        )
                      },
                      expression:
                        "spuSpecificationsInfoUpdateDialog.form.childSpecifications",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "上级规格", prop: "superiorsSpecifications" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入上级规格" },
                    model: {
                      value:
                        _vm.spuSpecificationsInfoUpdateDialog.form
                          .superiorsSpecifications,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.spuSpecificationsInfoUpdateDialog.form,
                          "superiorsSpecifications",
                          $$v
                        )
                      },
                      expression:
                        "spuSpecificationsInfoUpdateDialog.form.superiorsSpecifications",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "比例", prop: "ratio" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入比例" },
                    model: {
                      value: _vm.spuSpecificationsInfoUpdateDialog.form.ratio,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.spuSpecificationsInfoUpdateDialog.form,
                          "ratio",
                          $$v
                        )
                      },
                      expression:
                        "spuSpecificationsInfoUpdateDialog.form.ratio",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuSpecificationsInfoUpdateDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitSpuSpecificationsInfoEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.spuSpecificationsInfoAddDialog.title,
            visible: _vm.spuSpecificationsInfoAddDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.spuSpecificationsInfoAddDialog,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.spuSpecificationsInfoAddDialog.form,
                rules: _vm.spuSpecificationsInfoAddDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "型号", prop: "spuModel" } },
                [
                  _c("DictSelect", {
                    ref: "skuModel",
                    attrs: {
                      type: "sku_model",
                      value: _vm.spuSpecificationsInfoAddDialog.form.spuModel,
                      dictFun: _vm.dictSkuModelsFun,
                      "init-props": { label: "skuModel", value: "skuModel" },
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.spuSpecificationsInfoAddDialog.form,
                          "spuModel",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "子规格", prop: "childSpecifications" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入上级规格" },
                    model: {
                      value:
                        _vm.spuSpecificationsInfoAddDialog.form
                          .childSpecifications,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.spuSpecificationsInfoAddDialog.form,
                          "childSpecifications",
                          $$v
                        )
                      },
                      expression:
                        "spuSpecificationsInfoAddDialog.form.childSpecifications",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "上级规格", prop: "superiorsSpecifications" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入上级规格" },
                    model: {
                      value:
                        _vm.spuSpecificationsInfoAddDialog.form
                          .superiorsSpecifications,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.spuSpecificationsInfoAddDialog.form,
                          "superiorsSpecifications",
                          $$v
                        )
                      },
                      expression:
                        "spuSpecificationsInfoAddDialog.form.superiorsSpecifications",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "比例", prop: "ratio" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入比例" },
                    model: {
                      value: _vm.spuSpecificationsInfoAddDialog.form.ratio,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.spuSpecificationsInfoAddDialog.form,
                          "ratio",
                          $$v
                        )
                      },
                      expression: "spuSpecificationsInfoAddDialog.form.ratio",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuSpecificationsInfoAddDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitAddSpuSpecificationsForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }