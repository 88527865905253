var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入板块标题关键字",
                        },
                        model: {
                          value: _vm.queryForm.topicCollectionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "topicCollectionName", $$v)
                          },
                          expression: "queryForm.topicCollectionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.authOrgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "authOrgCode", $$v)
                                },
                                expression: "queryForm.authOrgCode",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "显示状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.ifShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "ifShow", $$v)
                            },
                            expression: "queryForm.ifShow",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "ALL" },
                          }),
                          _c("el-option", {
                            attrs: { label: "显示", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "不显示", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("创建新板块")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "板块ID",
                  prop: "topicCollectionId",
                  width: "200",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "板块标题",
                  prop: "topicCollectionName",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgAuthName", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "角色权限", prop: "rolePermission" },
              }),
              _c("el-table-column", {
                attrs: { label: "更新时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "话题数", prop: "topicCount" },
              }),
              _c("el-table-column", {
                attrs: { label: "帖子数", prop: "experienceCount" },
              }),
              _c("el-table-column", {
                attrs: { label: "浏览量", prop: "viewCount" },
              }),
              _c("el-table-column", {
                attrs: { label: "评论", prop: "answerCount" },
              }),
              _c("el-table-column", {
                attrs: { label: "点赞", prop: "likeCount" },
              }),
              _c("el-table-column", {
                attrs: { label: "收藏", prop: "collectCount" },
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "ifShow" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.ifShow === 1 ? "显示" : "不显示")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "250", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.orgAuthCode == _vm.queryForm.authOrgCode ||
                        _vm.authOrgAll
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row, "view")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.ifShow === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleShowUpdate(0, row)
                                  },
                                },
                              },
                              [_vm._v("不显示")]
                            )
                          : _vm._e(),
                        (row.orgAuthCode == _vm.queryForm.authOrgCode ||
                          _vm.authOrgAll) &&
                        row.ifShow === 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleShowUpdate(1, row)
                                  },
                                },
                              },
                              [_vm._v("显示")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            center: true,
            visible: _vm.saveDialog.visible,
            "append-to-body": "",
            width: "750px",
            "close-on-click-modal": false,
            "before-close": _vm.handleBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "overflow-y": "scroll", height: "600px" } },
            [
              _c(
                "el-form",
                {
                  ref: "saveFormRef",
                  attrs: {
                    size: "mini",
                    "label-width": "auto",
                    inline: true,
                    rules: _vm.saveDialog.rules,
                    model: _vm.saveDialog.form,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#1482f0",
                                  "font-size": "24px",
                                },
                              },
                              [_vm._v("基本信息")]
                            ),
                          ]),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "板块标题",
                                    prop: "topicCollectionName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder:
                                        "请输入板块标题,建议13字以内",
                                    },
                                    model: {
                                      value:
                                        _vm.saveDialog.form.topicCollectionName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.saveDialog.form,
                                          "topicCollectionName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "saveDialog.form.topicCollectionName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "板块描述",
                                    prop: "topicCollectionContent",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请输入板块描述",
                                    },
                                    model: {
                                      value:
                                        _vm.saveDialog.form
                                          .topicCollectionContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.saveDialog.form,
                                          "topicCollectionContent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "saveDialog.form.topicCollectionContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "分公司",
                                    prop: "orgAuthCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      model: {
                                        value: _vm.saveDialog.form.orgAuthCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.saveDialog.form,
                                            "orgAuthCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "saveDialog.form.orgAuthCode",
                                      },
                                    },
                                    _vm._l(_vm.authOrg, function (item) {
                                      return _c("el-option", {
                                        key: item.key,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "分组权限" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.saveDialog.form.authType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.saveDialog.form,
                                            "authType",
                                            $$v
                                          )
                                        },
                                        expression: "saveDialog.form.authType",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("不限制"),
                                      ]),
                                      _c("br"),
                                      _c(
                                        "el-radio",
                                        { ref: "radio", attrs: { label: 1 } },
                                        [
                                          _vm._v(" 指定角色可见 "),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                filterable: "",
                                                multiple: "",
                                                placeholder: "请选择角色",
                                              },
                                              on: {
                                                change: _vm.handleRoleChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.saveDialog.form.roleKeys,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.saveDialog.form,
                                                    "roleKeys",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "saveDialog.form.roleKeys",
                                              },
                                            },
                                            _vm._l(
                                              _vm.roleOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.roleKey,
                                                  attrs: {
                                                    label: item.roleName,
                                                    value: item.roleKey,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "是否显示", prop: "ifShow" },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.saveDialog.form.ifShow,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.saveDialog.form,
                                            "ifShow",
                                            $$v
                                          )
                                        },
                                        expression: "saveDialog.form.ifShow",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [_vm._v("显示")]
                                        ),
                                      ]),
                                      _c(
                                        "el-radio",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: { label: 0 },
                                        },
                                        [_vm._v("不显示")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "排序权重", prop: "weight" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "300px" } },
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "60px" },
                                        attrs: {
                                          min: 0,
                                          controls: false,
                                          precision: 0,
                                        },
                                        model: {
                                          value: _vm.saveDialog.form.weight,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.saveDialog.form,
                                              "weight",
                                              $$v
                                            )
                                          },
                                          expression: "saveDialog.form.weight",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            "margin-left": "2%",
                                            width: "150px",
                                          },
                                        },
                                        [_vm._v("数字越大,排序越靠前")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticStyle: { "margin-top": "2%" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "选择颜色", prop: "color" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.saveDialog.form.color,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.saveDialog.form,
                                            "color",
                                            $$v
                                          )
                                        },
                                        expression: "saveDialog.form.color",
                                      },
                                    },
                                    _vm._l(_vm.colorOptions, function (item) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: item.color,
                                          attrs: { label: item.color },
                                        },
                                        [
                                          _vm._v(_vm._s(item.colorName) + " "),
                                          _c("img", {
                                            style: {
                                              height: "80px",
                                              display: "block",
                                              "max-width": "220px",
                                            },
                                            attrs: {
                                              src: _vm.horizontal[item.color],
                                            },
                                          }),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    position: "absolute",
                    bottom: "5px",
                    width: "90%",
                    "background-color": "white",
                    height: "30px",
                  },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "inline-block", float: "right" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handleSave },
                        },
                        [_vm._v("提交")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.saveDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }