var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { attrs: { label: _vm.showLabel, size: "mini", prop: "value" } },
    [
      _c(
        "el-select",
        {
          attrs: {
            filterable: "",
            remote: _vm.remote,
            clearable: "",
            placeholder: _vm.placeholder,
            "remote-method": (query) => _vm.remoteMethod(query),
            loading: _vm.valueLoading,
            "allow-create": _vm.allowCreate,
            multiple: _vm.multiple,
          },
          model: {
            value: _vm.queryForm.value,
            callback: function ($$v) {
              _vm.$set(_vm.queryForm, "value", $$v)
            },
            expression: "queryForm.value",
          },
        },
        _vm._l(_vm.itemOptions, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: {
              label: _vm.fullLabel ? item.value + "-" + item.label : item.label,
              value: item.value,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }